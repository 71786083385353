import React, { useState, useEffect } from 'react'
import { IonRow } from '@ionic/react'

import { useAuth } from '../../hooks/useAuth'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'

import { useReportsData } from '../../hooks/reports/useReportsData'

import IncidentPopup from './IncidentPopup'
import IncidentPopupAlog from './IncidentPopupAlog'
import IncidentPopupInvestigations from './IncidentPopupInvestigations'

const IncidentPopupHandler : React.FC<Number> = ({ idx }) => {
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  const {
    setShowFieldSitrep,
    name,
    county,
    state,
    insight,
    setShowInternalIncident,
    setStep,
    step,
  } = useReportsData()

  const [isShowing, setIsShowing] = useState(true)

  switch (step) {
    default:
      return (
        <IonRow />
      )
    case 1:
      return isShowing ? (
        <IncidentPopup />
      ) : null
    case 2:
      return isShowing ? (
        <IncidentPopupAlog />
      ) : null
    case 3:
      return isShowing ? (
        <IncidentPopupInvestigations />
      ) : null
  }
}

export default IncidentPopupHandler
