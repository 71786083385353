import React, {
  createContext, useState, useContext, useRef, useReducer,
} from 'react'
import { zoomThreshold } from '../../components/maps/apps/AppMap'
import { selectedCountyReducers } from './utils/Reducers'

/**
 *  ===================================
 *      POVIDE DATA TO ANALYST APP
 *  ===================================
 */
const AnalystAppContext = createContext()

export const ProvideAnalystApp = ({ children }) => {
  const [counties, setCounties] = useState([])
  const [countyList, setCountyList] = useState([])
  const [mode, setMode] = useState('Alert States')
  const [rawCounties, setRawCounties] = useState([])
  const [view, setView] = useState('Map')
  const [showCrowd, setShowCrowd] = useState(false)
  const [scYearOptions, setScYearOptions] = useState()
  const [incidents, setIncidents] = useState([])
  const [regions, setRegions] = useState({})
  const [securityIncidents, setSecurityIncidents] = useState({})
  const [timeFilter, setTimeFilter] = useState(30)
  const [asList, setAsList] = useState([])
  const [incidentsList, setIncidentList] = useState([])
  const [selected, dispatchSelected] = useReducer(selectedCountyReducers, { counties: [], ids: [] })

  /* Dropdown lists of countries and regions */
  const [countryList, setCountryList] = useState([])

  const mapRef = useRef(null)

  /* For this app they will always be toggled on */
  const asFilters = {
    'AS-1': true,
    'AS-2': true,
    'AS-3': true,
    'AS-4': true,
    'AS-5': true,
  }

  /* Tack suggestions */
  const [selectedCounties, setSelectedCounties] = useState([])
  const [showMultiAS, setShowMultiAS] = useState(false)

  const drawLayers = () => {
    if (mapRef.current.getLayer('counties-layer')) return
    /* Add colors depending on data source */
    mapRef.current.addLayer({
      id: 'counties-layer',
      source: 'counties',
      minzoom: zoomThreshold,
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': ['get', 'Alert_color'],
        'fill-opacity': 1,
      },
    }, 'county-limits')

    mapRef.current.addLayer({
      id: 'regions-layer',
      source: 'regions',
      maxzoom: zoomThreshold,
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': ['get', 'Alert_color'],
        'fill-opacity': 1,
      },
    }, 'region-limits')
  }

  const addScLayer = () => {
    if (mapRef.current.getLayer('security-conditions-regions')) { return }
    mapRef.current.addLayer({
      id: 'security-conditions-regions',
      source: 'regions',
      type: 'fill',
      maxzoom: zoomThreshold,
      layout: {},
      paint: {
        'fill-color': ['get', 'security_condition'],
        'fill-opacity': 1,
      },
    }, 'region-limits')

    mapRef.current.addLayer({
      id: 'security-conditions',
      source: 'counties',
      type: 'fill',
      layout: {},
      minzoom: zoomThreshold,
      paint: {
        'fill-color': ['get', 'security_condition'],
        'fill-opacity': 1,
      },
    }, 'county-limits')
  }

  return (
    <AnalystAppContext.Provider value={{
      counties,
      setCounties,
      view,
      setView,
      mapRef,
      rawCounties,
      setRawCounties,
      mode,
      setMode,
      asFilters,
      drawLayers,
      asList,
      setAsList,
      addScLayer,
      incidentsList,
      setIncidentList,
      regions,
      setRegions,
      incidents,
      setIncidents,
      securityIncidents,
      setSecurityIncidents,
      timeFilter,
      setTimeFilter,
      showCrowd,
      setShowCrowd,
      scYearOptions,
      setScYearOptions,
      countryList,
      setCountryList,
      countyList,
      setCountyList,
      selectedCounties,
      setSelectedCounties,
      showMultiAS,
      setShowMultiAS,
      selected,
      dispatchSelected,
    }}
    >
      {
         children
       }
    </AnalystAppContext.Provider>
  )
}

export const useAnalystAppData = () => useContext(AnalystAppContext)
