import {
  IonButton, IonRow, IonSelect, IonSelectOption,
} from '@ionic/react'
import * as React from 'react'
import { Icon } from '@iconify/react'
import { useInsightsData } from '../../../hooks/insights/useInsightsData'
import { useWorkspace } from '../../../hooks/useWorkspace'
import AppHeader from '../AppHeader'
import { InsightViews } from '../../../hooks/insights/helpers/StateReducers'

const MobileInsightControls : React.FC = () => {
  const { workspace } = useWorkspace()
  const { view, handleViewChange, setShowMobileFilters } = useInsightsData()

  return (
    <div className='mobile-insight-controls'>
      <AppHeader className='mobile-workspace-app-header' app='Insights' workspace={workspace} />
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <IonSelect
          className='mobile-insights-dropdown'
          value={view}
          placeholder='Map View'
          onIonChange={(e) => {
            if (window.innerWidth > 768) return
            handleViewChange(e.detail.value)
          }}
        >
          <IonSelectOption value={InsightViews.INCIDENTS}>Security</IonSelectOption>
          <IonSelectOption value={InsightViews.ALERT_STATES}>Alert States</IonSelectOption>
          <IonSelectOption value={InsightViews.TEAM_SAFETY}>Team Safety</IonSelectOption>
        </IonSelect>
        <IonButton onClick={() => setShowMobileFilters(true)} style={{ '--background': '#326771' }}><Icon icon='fa:filter' /></IonButton>
      </IonRow>
    </div>
  )
}

export default MobileInsightControls
