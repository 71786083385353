import React from 'react'
import {
  IonPage, IonContent, IonCard, IonCardHeader,
  IonCardSubtitle, IonCardTitle,
  IonTitle,
  IonToolbar,
  IonCardContent,
} from '@ionic/react'
import PageHeader from '../components/PageHeader'
import IncidentReportSubmitterTest from '../components/IncidentReportSubmitterTest'

const Configuration = () => (
  <IonPage>
    <IonToolbar>
      <IonTitle>Dashboard</IonTitle>
    </IonToolbar>
    <PageHeader title='Sherpa' />
    <IonContent>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
          <IonCardTitle>Card Title</IonCardTitle>
        </IonCardHeader>

        <IncidentReportSubmitterTest />

        <IonCardContent>
          This is where a widget can go
        </IonCardContent>
      </IonCard>
    </IonContent>
  </IonPage>
)

export default Configuration
