import {
  IonContent, IonGrid, IonRow, IonCol, IonSpinner,
} from '@ionic/react'
import React from 'react'

const Loading = () => (
  <IonContent>
    <IonGrid style={{ height: '100%' }}>
      <IonRow style={{
        display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <IonCol className='ion-text-center'>
          <IonSpinner color='secondary' style={{ width: '5rem', height: '5rem' }} />
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonContent>
)

export default Loading
