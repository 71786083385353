import * as React from 'react'
import { getAssetIcon } from '../../../hooks/insights/helpers/Utils'
import { BackendAsset } from '../../../hooks/risks/types/BackendData'

const AssetPopup : React.FC<{ data: BackendAsset }> = ({ data }) => (
  <div>
    <div className='disp-flex-start asset-popup-heading'>
      <p>{data.name}</p>
      <img alt='Asset icon' src={getAssetIcon(data.asset_type)} />
    </div>
    <p className='asset-popup-property'><span>Asset Type: </span> {data.asset_type}</p>
    <p className='asset-popup-property'><span>Coordinates: </span> {data.longitude.toFixed(4)}, {data.latitude.toFixed(4)}</p>
  </div>
)

export default AssetPopup
