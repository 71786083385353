import * as moment from 'moment'
import * as turf from '@turf/turf'
import { IncidentFilters } from '../../hooks/insights/helpers/FilterReducers'
import {
  ASRegion, BackendCountryAreas, CountryAreas, County, FetchIncidentsConfig, Incident, IncidentTimeFilter, IncidentTimesOrder, Region, RestrictionZone, SafetyUser,
} from '../types/OptimizedMaps'

import { incidentCategories } from '../maps/apps/incident_types.json'
import { BackendAsset } from '../../hooks/risks/types/BackendData'
import { JourneyChapter } from '../types/BackendTypes'
import { User } from '../types/GlobalTypes'

/**
 * Get Source ids
 *
 * @param country -> Country name
 * @returns The ids of the counties and regions sources for the provided country
 */
export const getCountrySourceId = (country: string) : { countyId: string, regionId: string } => ({
  countyId: `${country}-counties`,
  regionId: `${country}-regions`,
})

/**
 * Get list of regions with avg alert state of their counties
 *
 * @param counties -> Backend counties
 * @param regions -> Backend regions
 * @returns -> List of regions with the avg alert state of their counties
 */
export const getRegionAvgAS = (counties: County[], regions: Region[]) : ASRegion[] => {
  const formattedRegions : ASRegion[] = regions.map((val) => {
    const filteredCounties = counties.filter(({ region_id }) => region_id === val.id)
    const count = filteredCounties.reduce((valCount, current) => valCount + current.alert_state, 0)

    return { ...val, avg_as: (count === 0) ? 0 : Math.ceil(count / filteredCounties.length) }
  })
  return formattedRegions
}

/**
 * Add average alert state to regions
 *
 * @param areas -> Areas fetched from the backend
 * @returns The same list but regions contain the average alert state of their counties
 */
export const processAreas = (areas: BackendCountryAreas[]) : CountryAreas[] => areas.map((val) => {
  const regions = getRegionAvgAS(val.counties, val.regions)
  return { ...val, regions }
})

type LayerIds = { regionLayers: string[], countyLayers: string[] }

/**
 * Get Mapbox layer ids
 *
 * @param areas -> Countries, regions and counties
 * @returns Ids of the mapbox layers
 */
export const getMapboxLayers = (areas: CountryAreas[]) : LayerIds => {
  const layerIds = areas.reduce<LayerIds>((list, { country }) => {
    const { countyId, regionId } = getCountrySourceId(country)
    list.regionLayers.push(`${regionId}-fill`)
    list.countyLayers.push(`${countyId}-fill`)

    return list
  }, { regionLayers: [], countyLayers: [] })

  return layerIds
}

/**
 * Search county object using shapeID
 *
 * @param areas -> Countries, regions and counties
 * @param shapeID -> ShapeID that needs to be found
 * @returns A county if it's in the list of areas or null if can't be found
 */
export const findCountyInAreas = (areas: CountryAreas[], shapeID: string) : County | null => {
  /* Declare temp variable to avoid looping more than once */
  let county: County | null = null
  areas.find(({ counties }) => {
    const result = counties.find(({ shape_id }) => shape_id === shapeID)
    if (result) {
      county = result
    }
    return result
  })

  return county
}

/**
 * Search county object using shapeID
 *
 * @param areas -> Countries, regions and counties
 * @param shapeID -> ShapeID that needs to be found
 * @returns A county if it's in the list of areas or null if can't be found
 */
export const findRegionsInAreas = (areas: CountryAreas[], shapeID: string) : ASRegion | null => {
  /* Declare temp variable to avoid looping more than once */
  let region: ASRegion | null = null
  areas.find(({ regions }) => {
    const result = regions.find(({ shape_id }) => shape_id === shapeID)
    if (result) {
      region = result
    }
    return result
  })

  return region
}

/**
 * Get incident request config
 *
 * @param fetchTime -> Server time returned by last fetch
 * @param oldestFetch -> Oldest fetch done in the frontend
 * @param newFilter -> New user selection
 * @returns Null if no fetch needs to be done or alternatively the request body for the subsequent fetch
 */
export const getNextIncidentsConfig = (fetchTime: string, oldestFetch: IncidentTimeFilter, newFilter: IncidentTimeFilter) : FetchIncidentsConfig | null => {
  /* Determine whether there's a need to do any fetch at all */
  if (IncidentTimesOrder.indexOf(newFilter) <= IncidentTimesOrder.indexOf(oldestFetch)) {
    return null
  }

  return {
    time_to: fetchTime,
    time_from: newFilter,
  }
}

const getFilterDate = (time: IncidentTimeFilter) : string => {
  if (time === IncidentTimeFilter.LAST_WEEK) {
    return moment().subtract(1, 'week').format()
  }

  if (time === IncidentTimeFilter.LAST_MONTH) {
    return moment().subtract(1, 'month').format()
  }

  if (time === IncidentTimeFilter.SIX_MONTHS) {
    return moment().subtract(6, 'months').format()
  }

  if (time === IncidentTimeFilter.LAST_YEAR) {
    return moment().subtract(1, 'year').format()
  }

  return moment().format()
}

/**
 * Filter all Incidents by time and category
 *
 * @param filters -> Updated Incident filter object
 * @param incidents -> List of all incidents
 * @param time -> Time defined in days
 * @returns -> A list of filtered incidents by time and category
 */
export const filterIncidents = (filters: IncidentFilters, incidents: Incident[], time: IncidentTimeFilter) => {
  const filteredIncidents = (time === IncidentTimeFilter.ALL_FETCH) ? [...incidents] : [...incidents].filter(({ reported }) => moment(reported).isSameOrAfter(getFilterDate(time)))
  /* Incidents to show in the map */
  const selectedFilters = Object.entries(filters.filters).reduce((memo, [k, v]) => (v ? [...memo, k] : memo), [])
  const filterdIncidentCategories = Object.entries(incidentCategories).reduce((m, [k, v]) => (selectedFilters.includes(k) ? [...m, ...v] : m), [])
  return filteredIncidents.filter((i) => (filterdIncidentCategories.includes(i.incident_type)))
}

/**
 * Get GeoJSON FC of assets
 *
 * @param assets -> List of plain backend assets (Not cross-referenced)
 * @returns A geojson feature collection made out of assets
 */
export const assetsToGeoJSON = (assets: BackendAsset[]) : turf.FeatureCollection<turf.Point, BackendAsset> => ({
  type: 'FeatureCollection',
  features: assets.map((val) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [
        val.longitude, val.latitude,
      ],
    },
    properties: {
      ...val,
      type: val.asset_type,
    },
  })),
})

/**
 * Get lists of all counties and regions returned from backend
 *
 * @param areas -> Areas returned from backend
 * @returns Flat list of all regions and counties
 */
export const getFlatRegionsAndCounties = (areas: CountryAreas[]) : { counties: County[], regions: ASRegion[] } => {
  const data = areas.reduce<{ counties: County[], regions: ASRegion[] }>((prev, current) => ({ counties: [...prev.counties, ...current.counties], regions: [...prev.regions, ...current.regions] }),
    { counties: [], regions: [] })

  return data
}

/**
 * Get Incidents in GeoJSON format
 *
 * @param incidents -> List of backend incidents
 * @returns A GeoJSON Feature collection with the incident points
 */
export const incidentsToGeoJSON = (incidents: Incident[]) : turf.FeatureCollection<turf.Point, Incident> => ({
  type: 'FeatureCollection',
  features: incidents.map((val) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [val.longitude, val.latitude],
    },
    properties: { ...val },
  })),
})

/*
* Get GeoJSON collection object for a list of restriction boxes.
*
* @param boxes -> List of processed restriction zones
* @returns A GeoJSON Feature Collection with the polygons of every box
*/
export const restrictionZonesToGeoJSON = (boxes: RestrictionZone[]) : turf.FeatureCollection<turf.LineString, { id: number, type: string }> => {
  const features = boxes.map(({ geodata }) => geodata)
  return turf.featureCollection(features)
}

/**
 * Get GeoJSON Feature Collection of safeties
 *
 * @param safeties -> List of backend safeties
 * @returns A GeoJSON feature collection that displays the safeties
 */
export const getMapSafeties = (safeties: SafetyUser[]) : turf.FeatureCollection => ({
  type: 'FeatureCollection',
  features: safeties.filter((val) => val.safety.level !== 'Unknown').map((val) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [
        val.safety.longitude, val.safety.latitude,
      ],
    },
    properties: {
      ...val, status: val.safety.level,
    },
  })),
})

/**
 * Get new Journey chapter
 *
 * @param id -> Id of new chapter
 * @param content -> Text of new chapter
 * @param category -> Category of chapter
 * @param journeyId -> Journey where the chapter was made
 * @param user -> Current user
 * @returns A formatted journey chapter
 */
export const generateChapter = (id: number, content: string, category: string, journeyId: number, user: User) : JourneyChapter => ({
  id,
  category,
  content,
  journey_id: journeyId,
  user_id: user.id,
  updated_at: moment().format(),
  created_at: moment().format(),
  user,
})
