import {
  IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonList, IonListHeader, IonRow, IonSelect, IonSelectOption,
} from '@ionic/react'
import React, { FC } from 'react'
import { ColumnContainer, MeasureContainer } from '../../riskregister/StyledContainers'
import { ControlMeasure } from '../../riskregister/types/RiskAssessments'
import { SettingField } from '../../settings/StyledContainers'
import { IncidentCause } from '../../types/GlobalTypes'
import { SimpleButton } from '../StyledContainers'
import { FormContainer } from './MapForm'

const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

interface Props {
  selectedMeasures: ControlMeasure[]
  onCancel: () => void,
  editing: boolean,
  onShowRestrictions: () => void,
  handleChange: (label: string, value: any) => void,
  input: { description: string, review: number },
  onNext: () => void,
  canSubmit: boolean,
  selectedUnderlying: IncidentCause[],
  selectedThreats: string[],
  onShowThreats: () => void,
  onShowUnderlying: () => void,
}

const RestritionZoneForm: FC<Props> = ({
  selectedMeasures, onCancel, editing, onShowRestrictions, handleChange, input, canSubmit, onNext,
  selectedThreats, selectedUnderlying, onShowThreats, onShowUnderlying,
}) => (
  <FormContainer style={{ backgroundColor: 'white' }}>
    <div style={{ height: '100%' }} className='risk-assessment-list'>
      <IonList style={{ flex: 1, overflowY: 'auto' }}>
        <IonListHeader>
          <IonRow className='ion-align-items-center ion-justify-content-between' style={{ width: '90%' }}>
            <SettingField style={{ width: 'auto' }}> <h4 style={{ margin: '0' }}> { editing ? 'Edit Restriction Zone' : 'Add Restriction Zone'} </h4> </SettingField>
            <SimpleButton
              onClick={onCancel}
            >Close X
            </SimpleButton>
          </IonRow>
        </IonListHeader>
        <div style={{ padding: '0 10px' }}>
          <p style={{ marginTop: '0' }}>Use the map to drag and drop pins at the vertices of the polygon</p>
        </div>
        <IonListHeader>
          <SettingField> <h5 className='blue'>Restrictions</h5> </SettingField>
        </IonListHeader>
        <div style={{ padding: '0 20px' }}>
          <ColumnContainer>
            {
            selectedMeasures.map((val, index) => (
              <MeasureContainer
                className='single-control-measure'
                key={val.id}
                draggable
                style={{ backgroundColor: getColor(index) }}
              >
                <p> {val.control_measure} </p>
              </MeasureContainer>
            ))
          }
          </ColumnContainer>
        </div>
        <IonRow className='ion-justify-content-end' style={{ paddingRight: '20px' }}>
          <IonButton style={{ '--background': '#0C9500' }} onClick={onShowRestrictions}>Add</IonButton>
        </IonRow>
        <IonListHeader>
          <SettingField> <h5 className='blue'>Main Threats</h5> </SettingField>
        </IonListHeader>
        <div style={{ padding: '0 20px' }}>
          <ColumnContainer>
            {
              selectedThreats.map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                >
                  <p> {val} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </div>
        <IonRow className='ion-justify-content-end' style={{ paddingRight: '20px' }}>
          <IonButton style={{ '--background': '#0C9500' }} onClick={onShowThreats}>Add</IonButton>
        </IonRow>
        <IonListHeader>
          <SettingField> <h5 className='blue'>Underlying Issues</h5> </SettingField>
        </IonListHeader>
        <div style={{ padding: '0 20px' }}>
          <ColumnContainer>
            {
              selectedUnderlying.map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val.id}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                >
                  <p> {val.name} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </div>
        <IonRow className='ion-justify-content-end' style={{ paddingRight: '20px' }}>
          <IonButton style={{ '--background': '#0C9500' }} onClick={onShowUnderlying}>Add</IonButton>
        </IonRow>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Review period: </IonLabel>
                <IonSelect name='review' value={input.review} placeholder='Select One' onIonChange={(e) => handleChange('review', e.detail.value)}>
                  <IonSelectOption value={1}>1 Day</IonSelectOption>
                  <IonSelectOption value={7}>1 Week</IonSelectOption>
                  <IonSelectOption value={14}>2 Weeks</IonSelectOption>
                  <IonSelectOption value={30}>4 Weeks</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonListHeader>
          <SettingField> <h5 className='blue'>Description (Optional)</h5> </SettingField>
        </IonListHeader>
        <IonItem>
          <IonInput
            type='text'
            name='description'
            id='restriction-box-description'
            value={input.description}
            placeholder='Add a description for this restriction box'
            onIonChange={(e) => handleChange('description', e.detail.value)}
          />
        </IonItem>
      </IonList>
      <IonRow
        style={{
          alignItems: 'center', padding: '0 10px', backgroundColor: 'white', margin: '10px 0',
        }}
        class='ion-justify-content-around'
      >
        <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>Cancel</IonButton>
        {
          canSubmit ? (
            <IonButton id='submit-restriction-box' style={{ '--background': '#0C9500' }} onClick={onNext}>Submit</IonButton>
          ) : (
            <IonButton id='next-restriction-box' style={{ '--background': '#326771' }} onClick={onNext}>Next</IonButton>
          )
        }
      </IonRow>
    </div>
  </FormContainer>
)

export default RestritionZoneForm
