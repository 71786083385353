import React from 'react'
import { IonRow } from '@ionic/react'
import { Icon } from '@iconify/react'
import { HeaderContainer } from './StyledMobile'

/**
 *  ==================================
 *        DISPLAY APP IN MOBILE
 *  ==================================
 */
const AppHeader = ({
  app, workspace, icon = null, className = '',
}) => (
  <HeaderContainer className={className}>
    <IonRow className='ion-justify-content-between ion-align-items-center'>
      <IonRow className='ion-align-items-center'>
        {
          icon && (
            <Icon icon={icon} />
          )
        }
        <h3>{ app } </h3>
      </IonRow>
      <h3>{workspace.name}</h3>
    </IonRow>
  </HeaderContainer>
)

export default AppHeader
