/* eslint-disable react/no-array-index-key */
/* eslint-disable no-trailing-spaces */
import React, { useState } from 'react'
import { IonRow, IonButton, IonCol } from '@ionic/react'
import { ColumnContainer, MeasureContainer } from '../../riskregister/StyledContainers'
import { useIncidentsData } from '../../../hooks/incident-management/useIncidentData'
import { SOP, SOPLevel } from '../../types/GlobalTypes'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

/**
 *  ============================
 *      RESPONSE LEVELS VIEW
 *  ============================
 */
const ResponseView = () => {
  /* Get app data from hook */
  const { sopList, setSopList, setLoading } = useIncidentsData()
  const [updatedSOPs, setUpdatedSOPs] = useState<(SOP & { edited?: boolean })[]>([...sopList])
  const [editing, setEditing] = useState<boolean>(false)
  const { workspace } = useWorkspace()
  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  const handleDropRemove = (e, newLevel: SOPLevel) => {
    const id = Number(e.dataTransfer.getData('id'))
    const copy = updatedSOPs.slice()

    const index = copy.findIndex((val) => val.id === id)
    copy.splice(index, 1, { ...copy[index], level: newLevel, edited: true })
    setUpdatedSOPs(copy)
  }

  const cancelEdit = () => {
    setUpdatedSOPs([...sopList])
    setEditing(false)
  }

  const handleSubmit = () => {
    const editedSOPs = updatedSOPs.filter(({ edited }) => edited)
    const {
      nonCritical, emergency, management, crisis, 
    } = editedSOPs.reduce<{
      nonCritical: string[],
      emergency: string[],
      management: string[],
      crisis: string[]
    }>((current, val) => {
      if (val.level === SOPLevel.NCI) {
        current.nonCritical.push(val.type)
        return current
      }

      if (val.level === SOPLevel.CMT) {
        current.crisis.push(val.type)
        return current
      }

      if (val.level === SOPLevel.ERT) {
        current.emergency.push(val.type)
        return current
      }

      if (val.level === SOPLevel.IMT) {
        current.management.push(val.type)
        return current
      }

      return current
    }, {
      nonCritical: [], emergency: [], management: [], crisis: [], 
    })

    setLoading(true)
    axios.post('/api/v1/internal_incident/setResponseLevels', {
      domain_id: workspace.id,
      non_critical: nonCritical,
      emergency_response: emergency,
      incident_management: management,
      crisis_management: crisis,
    }).then(() => {
      setSopList([...updatedSOPs])
    }).finally(() => {
      setEditing(false)
      setLoading(false)
    })
  }

  return (
    <div className='risk-assessment-list' style={{ height: '100%' }}>
      <div className='title-margin-15 flex-direction-column' style={{ overflowY: 'auto', flex: 1 }}>
        <h4>Response Levels</h4>
        <p>Response levels refer to the kind of response that a given incident triggers. Incident types are divided based on which Emergency Team will be tasked with managing them.</p>
        <p>You can set up or edit these teams by using the <span style={{ color: '#326771' }}>Emergency Team</span> tab.
        </p>
        <p>By default, events are listed in the Noncritial column. They can be moved by clicking the Edit button and dragging them to the desired column.</p>
        <IonRow style={{ flex: 1, overflow: 'hidden' }}>
          <IonCol className='flex-direction-column' size='3'>
            <h5>Noncritical Incidents</h5>
            <ColumnContainer style={{ flex: 1, overflowY: 'auto' }} onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDropRemove(e, SOPLevel.NCI)}>
              {
                updatedSOPs.filter(({ level }) => level === SOPLevel.NCI).map(({ type, id }, index) => (
                  <MeasureContainer key={index} draggable={editing} style={{ backgroundColor: getColor(index) }} onDragStart={(e) => e.dataTransfer.setData('id', id)}>
                    <p> {type} </p>
                  </MeasureContainer>
                ))
              }
            </ColumnContainer> 
          </IonCol>
          <IonCol className='flex-direction-column' size='3'>
            <h5>Emergency Response Team</h5>
            <ColumnContainer style={{ flex: 1, overflowY: 'auto' }} onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDropRemove(e, SOPLevel.ERT)}>
              {
                updatedSOPs.filter(({ level }) => level === SOPLevel.ERT).map(({ id, type }, index) => (
                  <MeasureContainer key={index} draggable={editing} style={{ backgroundColor: getColor(index) }} onDragStart={(e) => e.dataTransfer.setData('id', id)}>
                    <p> {type} </p>
                  </MeasureContainer>
                ))
              }
            </ColumnContainer>            
          </IonCol>
          <IonCol className='flex-direction-column' size='3'>
            <h5>Incident Management Team</h5>
            <ColumnContainer style={{ flex: 1, overflowY: 'auto' }} onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDropRemove(e, SOPLevel.IMT)}>
              {
                updatedSOPs.filter(({ level }) => level === SOPLevel.IMT).map(({ id, type }, index) => (
                  <MeasureContainer key={index} draggable={editing} style={{ backgroundColor: getColor(index) }} onDragStart={(e) => e.dataTransfer.setData('id', id)}>
                    <p> {type} </p>
                  </MeasureContainer>
                ))
              }
            </ColumnContainer>   
          </IonCol>
          <IonCol className='flex-direction-column' size='3'>
            <h5>Crisis Management Team</h5>
            <ColumnContainer style={{ flex: 1, overflowY: 'auto' }} onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDropRemove(e, SOPLevel.CMT)}>
              {
                updatedSOPs.filter(({ level }) => level === SOPLevel.CMT).map(({ id, type }, index) => (
                  <MeasureContainer key={index} draggable={editing} style={{ backgroundColor: getColor(index) }} onDragStart={(e) => e.dataTransfer.setData('id', id)}>
                    <p> {type} </p>
                  </MeasureContainer>
                ))
              }
            </ColumnContainer>  
          </IonCol>
        </IonRow>
      </div>
      <IonRow className='ion-justify-content-end'>
        {
          (editing) ? (
            <>
              <IonButton onClick={cancelEdit} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
              <IonButton onClick={handleSubmit} style={{ '--background': '#4197A9' }}>Save</IonButton>
            </>
          ) : (
            <IonButton onClick={() => setEditing(true)} style={{ '--background': '#4197A9' }}>Edit</IonButton>
          )
        }
      </IonRow>
    </div>
  )
}

export default ResponseView
