import * as moment from 'moment'

/**
 * Get date string for an event
 *
 * @param date -> Date when event happened
 * @returns -> String specifying how long ago the event happened compared to today's date
 */
export const getDateString = (date: string) => {
  const difference = moment().diff(moment(date))
  const duration = moment.duration(difference)

  if (duration.asSeconds() <= 60) {
    return `${Math.trunc(duration.asSeconds())} seconds ago`
  }

  if (duration.asMinutes() <= 60) {
    return `${Math.trunc(duration.asMinutes())} minute${(Math.trunc(duration.asMinutes()) === 1) ? '' : 's'} ago`
  }

  if (duration.asHours() <= 24) {
    return `${Math.trunc(duration.asHours())} hour${(Math.trunc(duration.asHours()) === 1) ? '' : 's'} ago`
  }

  if (Math.trunc(duration.asDays()) === 1) {
    return `${Math.trunc(duration.asDays())} day ago`
  }

  return `${Math.trunc(duration.asDays())} days ago`
}

/* Get a random color for a user icon */
export const getColor = (id: number) => {
  const shortNumber = id % 10

  if (shortNumber >= 0 && shortNumber < 2) return '#CFE5E9'
  if (shortNumber >= 2 && shortNumber < 4) return '#E0C3E8'
  if (shortNumber >= 4 && shortNumber < 6) return '#FAF7AD'
  if (shortNumber >= 6 && shortNumber < 8) return '#C3E8C7'
  if (shortNumber >= 8 && shortNumber < 10) return '#EDBBBB'

  return '#EDBBBB'
}
