/* eslint-disable react/no-array-index-key */
import {
  IonCol, IonContent, IonRow, IonSpinner,
} from '@ionic/react'
import * as React from 'react'
import { Icon } from '@iconify/react'
import { SimpleButton } from '../maps/StyledContainers'
import { SettingField } from '../settings/StyledContainers'

import { CenterForm, ColumnContainer, RiskPoint } from './StyledContainers'
import { RiskRatingMatrix } from './forms/utils/RiskAssessmentCalculations'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'

import { getNumericalValue } from './forms/utils/Categories'
import { DisplayAssetAction, FocusedActionKind } from '../../hooks/risks/helpers/StateReducers'

/**
 *  =====================================
 *    Heat Map View of Risk Assesments
 *  =====================================
 */
const RiskMatrixView = () : JSX.Element => {
  const {
    focusedAsset, fetchRiskAssessments, dispatchAsset, dispatchDisplayAsset,
  } = useRiskRegister()
  const [inherentRisk, setInherentRisk] = React.useState<boolean>(true)
  const getColor = (index: number) : string => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const getRiskColor = (riskLevel: number) => ({
    1: '#00b050',
    2: '#00b0f0',
    3: '#ffff00',
    4: '#ffc000',
    5: '#c00000',
  }[riskLevel])

  const RiskType = (index: number) : string => ['Severe', 'Major', 'Moderate', 'Minor', 'Low'][index]

  const getSortedRisks = () => {
    if (inherentRisk) {
      return [...focusedAsset.risks].sort((a, b) => getNumericalValue(b.impact) - getNumericalValue(a.impact))
    }
    return [...focusedAsset.risks].sort((a, b) => getNumericalValue(b.residualImpact) - getNumericalValue(a.residualImpact))
  }

  /* Check if  the risk assessments have been fetched otherwise fetch them */
  React.useEffect(() => {
    if (focusedAsset.risks) return
    fetchRiskAssessments(focusedAsset, () => console.log('Hello'))
  }, [])

  return (
    <CenterForm style={{ zIndex: '20', width: '100%', height: '100%' }}>
      <IonContent>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <SettingField style={{ width: 'auto' }}><h4 style={{ margin: '0' }}>{ focusedAsset.name }</h4></SettingField>
          <SimpleButton onClick={() => {
            dispatchAsset({ type: FocusedActionKind.FORGET })
            dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
          }}
          >Close X
          </SimpleButton>
        </IonRow>
        <IonRow style={{ marginTop: '20px' }}>
          <IonCol size='6' style={{ fontSize: '.7rem', textAlign: 'center' }}>
            <SettingField><h5 className='blue'> Impact vs Likelihood Matrix </h5></SettingField>
            {
              RiskRatingMatrix.map((row, index) => (
                <IonRow className='ion-align-items-center' key={index}>
                  <IonCol size='2'>{ RiskType(index) }</IonCol>
                  {
                    row.map((level, index2) => (
                      <IonCol key={index2} style={{ backgroundColor: getRiskColor(level), padding: '40px 20px', position: 'relative' }}>
                        {
                          focusedAsset.risks && inherentRisk
                          && focusedAsset.risks.filter((risk) => getNumericalValue(risk.impact) === (4 - index + 1)
                            && getNumericalValue(risk.inherentThreatLikelihood) === index2 + 1).map((risk, index3) => (
                              <RiskPoint key={index3} style={{ top: `${Math.random() * 70}%`, left: `${Math.random() * 70}%` }}>{ risk.visibleId }</RiskPoint>
                          ))
                        }
                        {
                          focusedAsset.risks && !inherentRisk
                          && focusedAsset.risks.filter((risk) => getNumericalValue(risk.residualImpact) === (4 - index + 1)
                            && getNumericalValue(risk.residualThreatLikelihood) === index2 + 1).map((risk, index3) => (
                              <RiskPoint key={index3} style={{ top: `${Math.random() * 70}%`, left: `${Math.random() * 70}%` }}>{ risk.visibleId }</RiskPoint>
                          ))
                        }
                      </IonCol>
                    ))
                  }
                </IonRow>
              ))
            }
            <IonRow>
              <IonCol size='2' />
              <IonCol size='2'>
                <SettingField style={{ width: 'auto' }}><p>Rare</p></SettingField>
              </IonCol>
              <IonCol size='2'>
                <SettingField style={{ width: 'auto' }}><p>Unlikely</p></SettingField>
              </IonCol>
              <IonCol size='2'>
                <SettingField style={{ width: 'auto' }}><p>Possible</p></SettingField>
              </IonCol>
              <IonCol size='2'>
                <SettingField style={{ width: 'auto' }}><p>Likely</p></SettingField>
              </IonCol>
              <IonCol size='2'>
                <SettingField style={{ width: 'auto' }}><p>Almost Certain</p></SettingField>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol size='6' style={{ paddingLeft: '20px' }}>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <SimpleButton onClick={() => setInherentRisk(!inherentRisk)}>{(inherentRisk) ? 'Inherent' : 'Residual' } Risk <Icon icon='fluent:arrow-sort-16-filled' /></SimpleButton>
              <SettingField style={{ width: 'auto' }}><p style={{ margin: '0' }}>Click to toggle between Inherent and Residual Risk views.</p></SettingField>
            </IonRow>
            <ColumnContainer style={{ cursor: 'inherit' }}>
              {
                focusedAsset.risks && getSortedRisks().map((risk, index) => (
                  <IonRow key={risk.id} style={{ backgroundColor: getColor(index) }}>
                    <IonCol style={{ borderRight: '1px solid #747474', paddingRight: '10px' }} size='3'>{ risk.visibleId }</IonCol>
                    <IonCol size='9'>{ risk.riskEvent }</IonCol>
                  </IonRow>
                ))
              }
              {
                !focusedAsset.risks && (
                  <IonRow className='ion-justify-content-center' style={{ paddingTop: '40px' }}>
                    <IonSpinner />
                  </IonRow>
                )
              }
            </ColumnContainer>
          </IonCol>
        </IonRow>
      </IonContent>
    </CenterForm>
  )
}

export default RiskMatrixView
