import { IonContent, IonGrid, IonPage } from '@ionic/react'
import React, { FC } from 'react'
import PageHeader from '../components/PageHeader'
import AppWrapper from '../components/terrain-mapping/AppWrapper'
import { ProvideTerrainData } from '../hooks/terrain-mapping/useTerrainMapping'

const TerrainMappingApp : FC = () => (
  <ProvideTerrainData>
    <IonPage>
      <PageHeader title='Communities App ' />
      <IonContent>
        <IonGrid
          className='mobile-padding risk-assessment-list'
          style={{
            height: '100%',
          }}
        >
          <AppWrapper />
        </IonGrid>
      </IonContent>
    </IonPage>
  </ProvideTerrainData>
)
export default TerrainMappingApp
