import React from 'react'
import { IonRow } from '@ionic/react'
import moment from 'moment'
import { useAnalystAppData } from '../../../hooks/analyst/useAnalystData'
import Map from '../Map'
import { fetchAlerStates, drawCountyShapesIncidents, updateCountyColors } from './AppMap'
import { getIncidentsDictionary } from './SecurityConditions'
import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import AlertStateList from '../../analyst/AlertStateList'
import IncidentList from '../../analyst/IncidentList'

/**
 *  ================================
 *      ALERT STATE ANALYST APP
 *  ================================
 */
const AnalystMap = ({ setLoading }) => {
  /* Import data from hook */
  const {
    setCounties, setRawCounties, mapRef, asFilters, setScYearOptions, view,
    setRegions, setIncidents, drawLayers, setSecurityIncidents, mode, setCountryList, setCountyList,
  } = useAnalystAppData()
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  const createYearsArray = (oldestIncidentDate) => {
    const oldest = moment(oldestIncidentDate).year()
    const newest = moment().year()

    const years = [oldest]
    for (let i = oldest + 1; i <= newest; i += 1) {
      years.push(i)
    }
    setScYearOptions(years)
  }

  /* Add map listeners -> Fetch data on load  */
  const setUpListeners = () => {
    mapRef.current.on('load', async () => {
      const [{
        counties, asCounties, regions, countryRegions,
      }, incidentData] = await Promise.all([fetchAlerStates(true, workspace.id), apiHook.getIncidents(workspace.id)])
      setCounties(counties)
      setRawCounties(asCounties)
      setRegions(regions)

      let oldestIncidentDate
      /* Cross-reference submitters  */
      const list = incidentData.incidents.map((incident) => {
        const user = incidentData.submitters.find((dbUser) => dbUser.id === incident.user_id)

        if (!oldestIncidentDate || moment(incident.reported).isBefore(oldestIncidentDate)) {
          oldestIncidentDate = incident.reported
        }

        return {
          ...incident,
          user,
          username: `${user.first_name} ${user.final_name}`,
        }
      })
      setIncidents(list)
      createYearsArray(oldestIncidentDate)

      /* Security Condition format for incidents */
      const dictionaryVersion = getIncidentsDictionary(incidentData.incidents)
      setSecurityIncidents(dictionaryVersion)

      const updateCounties = (geojson) => {
        const visibleCounties = geojson.features.filter(({ properties }) => properties.Alert_color !== 'rgba(255,255,255, 0)').map(({ properties }) => properties)
        setCountyList(visibleCounties)
        setCounties(geojson)
      }

      drawCountyShapesIncidents(counties, mapRef, null, true, regions)
      updateCountyColors(asFilters, updateCounties, counties, mapRef, drawLayers, regions, setRegions)

      setCountryList(countryRegions)
      setLoading(false)
      mapRef.current.resize()
    })
  }

  return (
    <IonRow className='ion-justify-content-center' style={{ flex: '1 1 auto' }}>
      <Map
        mapRef={mapRef}
        zoom={5}
        listeners={setUpListeners}
        className='flexible-map'
      >
        {
          view === 'List' && mode === 'Alert States' && (
            <AlertStateList />
          )
        }
        {
          view === 'List' && mode === 'Incidents' && (
            <IncidentList />
          )
        }
      </Map>
    </IonRow>
  )
}

export default AnalystMap
