import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

import addinsight from './AddInsight.jpg'
import insightstep from './InsightStep.jpg'
import insightstep2 from './InsightStep2.jpg'
import insightstep3 from './InsightStep3.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */
const InsightUseMap = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-insights')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' />Insights Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Adding an Insight Report
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonCol>
                  <IonText>An insight report is a weekly overview of the security situation in the county in which your organization operates.
                    It involves assessing and answering several security-related questions. The responses to these will then be analysed by the
                    Trubshaw team and will inform ‘alert state’ levels. For more information on alert states, click here (link to alert state article).
                  </IonText>
                  <IonText><br /><br />Step1.<br /><br />
                    The first step in submitting an incident report is to move your cursor to the top right-hand side of the Insights App map and click on ‘Add+’.
                    Next, scroll down and click on ‘Add Insights’.
                  </IonText>
                  <IonImg src={addinsight} />
                  <IonText><br /><br />Step2.<br /><br />
                    After this, the box below will come up. You will have to select the state in which your organization operates and for which you will provide a security update,
                    as well as the county within that state. To do so, click on the drop-down icon for each respective category.
                    <br /><br />Firstly, scroll down and select your state. Once you have done this, you will be able to scroll down and select your county.
                  </IonText>
                  <IonImg src={insightstep} />
                  <IonText><br /><br />Step3.<br /><br />
                    You will then have a series of questions related to the security situation in your county for the week. Select the response which best represents
                    the situation by clicking on one of the circles (green, blue, yellow, orange, and red). These circles represent increasingly more severe security issues from green to red.
                    <br /><br />Once you have selected the appropriate response, scroll down (using the scroll bar indicated by red arrows), and click ‘next’ to move on to the next question.
                    <br /><br />NB: You must answer all questions before a response can be submitted. There are about 12 questions in total.
                  </IonText>
                  <IonImg src={insightstep2} />
                  <IonText><br /><br />Step4.<br />
                    Once you have answered all the security-related questions, a preview box will come up. Here you can review your answers prior to submitting your incident report.
                    If you want to edit any of your responses, click on the ‘edit; button which is highlighted in red below. Then simply follow the same procedure explained in
                    Step 3 to update your answer.
                  </IonText>
                  <IonImg src={insightstep3} />
                  <IonText><br /><br />Step5.<br />
                    If you are satisfied with all your responses, scroll down to the bottom of the preview box, and click submit.
                  </IonText>
                  <IonImg src={insightstep3} />

                </IonCol>
              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-usemap'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Using the Map
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-views'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Filters and Views
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-alertstates'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Alert States
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addincident'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Adding an Incident
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default InsightUseMap
