import * as React from 'react'

import {
  IonRow, IonContent, IonButton, IonList,
} from '@ionic/react'
import { SimpleButton } from '../maps/StyledContainers'
import { SettingField } from '../settings/StyledContainers'

import { CenterForm } from './StyledContainers'
import RiskSummary from './overview/RiskSummary'

import ControlMeasures from './overview/ControlMeasures'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'
import { DisplayAssetAction } from '../../hooks/risks/helpers/StateReducers'

/**
 *  ===============================
 *      Risk Assessment Overview
 *  ===============================
 */
const RiskOverview = () : JSX.Element => {
  const {
    focusedRisk, focusedAsset, fetchMitigations, rawMeasures, dispatchDisplayAsset,
  } = useRiskRegister()
  /* Question step */
  const [question, setQuestion] = React.useState(1)

  /* Retrieve mitigations  when form pops up for the first time */
  React.useEffect(() => {
    if (focusedRisk.mitigations) return
    fetchMitigations(focusedRisk, focusedAsset)
  }, [])

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '95%' }}>
      <IonContent>
        <IonList className='risk-assessment-list risk-assessment-list' style={{ padding: '20px', height: '100%' }}>
          <IonRow className='ion-justify-content-between'>
            <SettingField style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
              <h4 style={{ margin: '0' }}>{ focusedRisk.riskEvent } - Overview</h4>
              <p style={{ margin: '0 0 0 10px' }}>{question} of 3</p>
            </SettingField>
            <SimpleButton onClick={() => {
              dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
            }}
            >Close X
            </SimpleButton>
          </IonRow>
          <div style={{ overflowY: 'auto' }}>
            {
              question === 1 && (
                <RiskSummary risk={focusedRisk} />
              )
            }
            {
              question === 2 && (
                <ControlMeasures title='Existing' measures={focusedRisk.existingMeasures} controlMeasures={rawMeasures} />
              )
            }
            {
              question === 3 && (
                <ControlMeasures title='New' measures={focusedRisk.newMeasures} controlMeasures={rawMeasures} />
              )
            }
          </div>
          <IonRow style={{ marginTop: '20px' }} className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} disabled={question === 1} onClick={() => setQuestion(question - 1)}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} disabled={question === 3} onClick={() => setQuestion(question + 1)}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default RiskOverview
