import React, { useState, useEffect } from 'react'
import axios from 'axios'

import {
  IonRow, IonButton, IonList, IonItem, IonLabel, IonDatetime,
  IonSelect, IonSelectOption, IonInput,
} from '@ionic/react'
import moment from 'moment'
import { FormContainer, FormContainerMobile } from './MapForm'

import { InstructionButtons, MapInstructions } from '../StyledContainers'

/**
 *  ===========================
 *     MOBILE INCIDENT FORM
 *  ===========================
 */
const MobileIncident = ({
  location, handleSubmit, showInstructions, setShowInstructions, input, setInput,
  setShowAddButton,
}) => {
  /* Control which popup to show */
  const [showDetailsForm, setShowDetailsForm] = useState(false)
  const [incidentTypes, setIncidentTypes] = useState([])

  useEffect(() => {
    if (incidentTypes && incidentTypes.length) return

    /* Fecth incidents */
    axios.get('/api/v1/types')
      .then((response) => {
        setIncidentTypes(response.data.types)
        setInput({ ...input, incident_type: response.data.types[0] })
      })
  })

  const handleChange = (e) => setInput({ ...input, [e.target.name]: e.detail.value })

  const handleClick = () => {
    if (!location[0]) {
      setShowInstructions(false)
    } else {
      setShowAddButton(false)
      setShowDetailsForm(true)
    }
  }

  return (
    <>
      {
        (!showDetailsForm) ? (
          <>
            {
              (showInstructions) && (
                <FormContainerMobile className='mobile-incident-intruction-popup'>
                  <MapInstructions>
                    <h2 style={{ color: '#326771' }}>Add Incident</h2>
                    <div style={{ marginTop: '20px', padding: '0 10px' }}>
                      {
                        (!location[0]) ? (
                          <>
                            <p>Touch the map to drop a pin where the incident took place.</p>
                            <p>You can drag and move the pin around to change the location of the incident.</p>
                          </>
                        ) : (
                          <p>Drag the pin to move its location or click continue to accept. </p>
                        )
                      }
                    </div>
                    <IonRow className='ion-justify-content-center'>
                      <IonButton style={{ '--background': '#326771' }} onClick={handleClick}>Continue</IonButton>
                    </IonRow>
                  </MapInstructions>
                </FormContainerMobile>
              )
            }
          </>
        ) : (
          <FormContainer style={{ width: '100%' }}>
            <IonList style={{ contain: 'initial', height: '100%' }}>
              <IonItem>
                <IonLabel>Coordinates: </IonLabel>
                <p> {`${(location[0]) ? location[0].toFixed(3) : 'Longitude'}, ${(location[1]) ? location[1].toFixed(3) : 'Latitude'}`} </p>
              </IonItem>
              <IonItem>
                <IonLabel>Incident Type</IonLabel>
                <IonSelect
                  name='state'
                  value={input.incident_type}
                  placeholder='Pick a Type'
                  onIonChange={(e) => setInput({ ...input, incident_type: e.detail.value })}
                >
                  {
                    incidentTypes.map((incident) => (
                      <IonSelectOption value={incident} key={incident}>{incident}</IonSelectOption>
                    ))
                  }
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel>Date of Incident</IonLabel>
                <IonDatetime
                  max={moment().format('YYYY-MM-DD')}
                  pickerFormat='YYYY-MM-DD HH:mm'
                  displayFormat='YYYY-MM-DD HH:mm'
                  value={input.date}
                  placeholder='Pick a date'
                  onIonChange={(e) => setInput({ ...input, date: e.detail.value })}
                />
              </IonItem>
              <IonItem>
                <IonLabel>Fatalities:</IonLabel>
                <IonInput name='fatalities' value={input.fatalities} onIonChange={handleChange} type='number' min='0' max='1000' style={{ textAlign: 'right' }} required />
              </IonItem>
              <IonItem>
                <IonLabel>Nearest village/town/city to event:</IonLabel>
                <IonInput name='nearest' value={input.nearest} onIonChange={handleChange} type='text' />
              </IonItem>
              <IonItem>
                <IonLabel>Description (optional):</IonLabel>
                <IonInput name='description' value={input.description} onIonChange={handleChange} type='text' />
              </IonItem>
              <InstructionButtons>
                <IonRow className='ion-justify-content-between' style={{ padding: '10px 0' }}>
                  <IonButton
                    style={{ '--background': '#8E151F' }}
                    onClick={() => {
                      setShowDetailsForm(false)
                      setShowAddButton(true)
                    }}
                  >Cancel
                  </IonButton>
                  <IonButton style={{ '--background': '#4197A9' }} onClick={handleSubmit}>Submit</IonButton>
                </IonRow>
              </InstructionButtons>
            </IonList>
          </FormContainer>
        )
      }
    </>
  )
}
export default MobileIncident
