import { IonCol, IonRow } from '@ionic/react'
import React, { FC, useState } from 'react'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { BlueButton, RedButton } from '../../incident-management/StyledContainers'
import { getColor } from '../../maps/apps/AppMap'
import { Details } from '../../maps/features/Stakeholder'
import { StakeholderDetails } from '../../terrain-mapping/popovers/StakeholderScreen'
import { Stakeholder, StakeholderGroup } from '../../types/OptimizedMaps'
import { ViewContainer } from '../StyledContainers'

const StakeholderList : FC = () => {
  const { stakeholders } = useEngagementData()
  const [focusedStakeholder, setFocusedStakeholder] = useState<Stakeholder | null>(null)
  const { workspace } = useWorkspace()
  const [input, setInput] = useState<Details>({
    name: '',
    alias: '',
    type: '',
    role: '',
    organisations: [],
    areas: [],
    analysis: '',
    whatsapp_number: '',
    phone_number: '',
    email: '',
  })
  const [groupSelections, setGroupSelections] = useState<(StakeholderGroup & { selected: boolean })[]>([])

  const handleDelete = (e) => {
    console.log('Delete', e)
  }

  if (focusedStakeholder) {
    return (
      <StakeholderDetails
        onBack={() => setFocusedStakeholder(null)}
        editing={false}
        focusedStakeholder={focusedStakeholder}
        input={input}
        setInput={setInput}
        stakeholders={[]}
        communities={[]}
        groupSelections={groupSelections}
        workspace={workspace}
        setGroupSelections={setGroupSelections}
      />
    )
  }

  return (
    <ViewContainer>
      <div style={{ height: '100%', width: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow style={{ padding: '10px' }} className='terrain-list-title  ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Name</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Type</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Role/Position</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Organisations</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Locations</h5>
          </IonCol>
          <IonCol size='2' />
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            stakeholders.map((val, index) => (
              <IonRow
                className='ion-justify-content-between terrain-register-row ion-align-items-center'
                style={{ backgroundColor: getColor(index) }}
                key={val.id}
              >
                <IonCol size='2'>{ val.name }</IonCol>
                <IonCol size='2'>{ val.type }</IonCol>
                <IonCol size='2'>{ val.role }</IonCol>
                <IonCol size='2'>{ (val.organisations.length > 0) ? val.organisations.map(({ name }) => name).join(', ') : 'No organisations' }</IonCol>
                <IonCol size='2'>{ (val.areas.length > 0) ? val.areas.map(({ name }) => name).join(', ') : 'No areas' }</IonCol>
                <IonCol size='2'>
                  <BlueButton onClick={() => setFocusedStakeholder(val)}>View</BlueButton> / <RedButton onClick={handleDelete(val)}>Delete</RedButton>
                </IonCol>
              </IonRow>
            ))
          }
          {
            stakeholders.length === 0 && (
              <p style={{ textAlign: 'center' }}>No stakeholders available</p>
            )
          }
        </div>
      </div>
    </ViewContainer>
  )
}

export default StakeholderList
