import * as React from 'react'
import { IonRow, IonButton, IonLabel } from '@ionic/react'
import { Icon } from '@iconify/react'
import { useEffect } from 'react'

import { useJourneysData2 } from '../../hooks/journeys/useJourneysData2'
import MapControls from './MapControls'
import JourneyMap2 from '../maps/apps/JourneyMap2'
import LoadingModal from '../modals/LoadingModal'

const AppWrapper2 : React.FC = () => {
  const {
    view, mapRef, showAirJourneyForm, showBackToJourneysMapButton, setShowBackToJourneysMapButton,
    setShowJourneysList, loading,
  } = useJourneysData2()

  useEffect(() => {
    mapRef.current.resize()
  }, [view, showAirJourneyForm])

  return (
    <>
      <LoadingModal isOpen={loading} />
      {
        showBackToJourneysMapButton && (
          <>
            <IonRow>
              <IonButton
                id='add-air-journey-button'
                onClick={() => {
                  setShowJourneysList(false)
                  setShowBackToJourneysMapButton(false)
                }}
                style={{
                  '--background': '#FFFFFF',
                  textTransform: 'none',
                  marginBottom: '2.3em',
                  fontWeight: 'normal',
                }}
              >
                <Icon style={{ color: '#326771', fontSize: '1.3em' }} icon='icons8:angle-left' />
                <IonLabel style={{ color: '#326771', letterSpacing: '-0.02em', fontSize: '1.1em' }}>Back to Journeys Map</IonLabel>
              </IonButton>
            </IonRow>
          </>
        )
      }
      {
        !showBackToJourneysMapButton && !showAirJourneyForm && (
          <MapControls />
        )
      }
      <JourneyMap2 />
    </>
  )
}

export default AppWrapper2
