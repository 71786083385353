/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import CountySelection from './CountySelection'
import InsightsReport from './InsightsReport'
import { updateAlertStates } from '../maps/apps/AppMap'
import useApi from '../../hooks/testHooks'

import { CenterForm } from '../riskregister/StyledContainers'
import FormPopup from '../maps/forms/FormPopup'
import { useWorkspace } from '../../hooks/useWorkspace'

/**
 *  ================================
 *      MULTI EDIT ALERT STATE
 *  ================================
 */
const MultiEditAS = ({
  selectedCounties, onBack, geojsonCounties, updateCounties, analyst, setLoading,
}) => {
  /* new alert state */
  const [newAlertState, setNewAlertState] = useState(
    // eslint-disable-next-line comma-dangle
    selectedCounties.length > 0 && selectedCounties.every(({ Alert_state }) => Alert_state === selectedCounties[0].Alert_state) ? selectedCounties[0].Alert_state : 'mixed',
  )
  const [step, setStep] = useState(0)
  const [counties, setCounties] = useState(selectedCounties.map((county) => ({ ...county, selected: true })))
  const [keyphrases, setKeyphrases] = useState([])
  const [categories, setCategories] = useState([])
  const [reports, setReports] = useState([])

  /* API hook to request data */
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  const moveToReport = () => {
    /* Set all selected ones has chosen */
    const copy = counties.map((county) => ((county.selected && !county.completed) ? ({ ...county, chosen: true }) : ({ ...county })))
    setCounties(copy)
    setStep(step + 1)
  }

  const updateMapData = () => {
    /* Name of the properties depending on the type of consultant app */
    const attr = (analyst) ? 'my_suggestion' : 'Alert_state'
    const copy = counties.filter(({ selected }) => selected).map((county) => ({ ...county, [attr]: Number(newAlertState), edited: true }))

    if (analyst) {
      const features = geojsonCounties.features.slice()
      copy.forEach((val) => {
        const index = features.findIndex(({ properties }) => properties.id === val.id)
        features.splice(index, 1, { ...features[index], properties: { ...features[index].properties, my_suggestion: val.my_suggestion } })
      })
      updateCounties({ ...geojsonCounties, features })
    } else {
      const geojson = updateAlertStates(copy, geojsonCounties)
      updateCounties(geojson)
    }
  }

  /* Submit a single report for all the counties selected */
  const submitReport = (report) => {
    setLoading(true)
    /* Create a backend record of the suggestion made by the analyst or change alert-state if consultant */
    if (analyst) {
      apiHook.addAnalyses(Number(newAlertState), report.counties.map((val) => ({ county_id: val })), report.keyphrases).then(() => {
        updateMapData()
        setLoading(false)
      })

      return
    }

    const requestData = report.counties.map((id) => ({ county_id: id, new_alert_state: Number(newAlertState) }))
    apiHook.editAlertState({
      domain_id: workspace.id,
      alerts: requestData,
      keyphrases: report.keyphrases,
    }).then((response) => {
      console.log(response)
      /* If Consultant then change alert states in the backend */
      updateMapData()
      setLoading(false)
    })
  }

  /* Fetch Keyphrases and categories */
  useEffect(() => {
    apiHook.getKeyphrases().then((data) => {
      /* Cross-reference categories */
      const formatted = data.keyphrases.map((keyphrase) => {
        const category = data.phrasegroups.find((val) => val.id === keyphrase.phrasegroups_id).name
        return { ...keyphrase, category }
      })
      setKeyphrases(formatted)
      setCategories(data.phrasegroups)
    })
  }, [])

  const views = [
    <CountySelection
      next={moveToReport}
      setCounties={setCounties}
      counties={counties}
      newAlertState={newAlertState}
      setNewAlertState={setNewAlertState}
      onBack={onBack}
    />,
    <InsightsReport
      counties={counties}
      setCounties={setCounties}
      back={() => setStep(step - 1)}
      reports={reports}
      setReports={setReports}
      finishSubmission={submitReport}
      categories={categories}
      keyphrases={keyphrases}
    />,
    <CenterForm style={{ height: 'auto', width: '30%' }}>
      <FormPopup
        message='Alert States updated'
        onClose={onBack}
      />
    </CenterForm>,
  ]

  return views[step]
}

export default MultiEditAS
