/* eslint-disable no-trailing-spaces */
import React from 'react'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, MeasureContainer, ColumnContainer } from '../../StyledContainers'
/**
 *  ===============================
 *      SUMMARY OF EXISTING CM
 *  ===============================
 */
const SummaryExisting = ({
  children, input, onClose, next,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Existing Control Measures - Summary</h5>
              <p>Listed below are the measures you have selected as exisitng contol measures. 
                Click <span className='highlighted-blue'>‘Next’</span> to continue or <span className='highlighted-blue'>‘Back’</span> 
                to return to the previous page and add or delete control measures.
              </p>
            </SettingField>
            <IonRow className='ion-align-items-center ion-justify-content-center'>
              <IonCol size={6}>
                <SettingField><h4>Existing Control Measures</h4></SettingField>
                <ColumnContainer style={{ cursor: 'inherit' }}>
                  {
                    input.existingMeasures && input.existingMeasures.map((measure, index) => (
                      <MeasureContainer className='existing-control-measures' key={measure.id} style={{ backgroundColor: getColor(index) }}>
                        <p> {measure.control_measure} </p>
                      </MeasureContainer>
                    ))
                  }
                  {
                    input.addedExistingMeasures && input.addedExistingMeasures.map(({ measure }, index) => (
                      <MeasureContainer key={measure.id} style={{ backgroundColor: getColor(input.existingMeasures.length + index) }}>
                        <p> {measure.control_measure} </p>
                      </MeasureContainer>
                    ))
                  }
                </ColumnContainer>
              </IonCol>
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default SummaryExisting
