import {
  IonGrid, IonRow, IonButton, IonLabel, IonCheckbox, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { Dropdown } from 'react-bootstrap'
import * as React from 'react'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'
import {
  Views, ListModes, Filters, FocusedActionKind, DisplayAssetAction, ViewsActionKind,
} from '../../hooks/risks/helpers/StateReducers'
import { SelectView } from '../maps/StyledContainers'
import { ConsultantFilter } from '../consultant/StyledContainers'

/**
 *  ==========================================
 *          RISK ASSESSMENT MAP CONTROLS
 *  ======================================s====
 */
const MapControls = () : JSX.Element => {
  /* import hook data */
  const {
    viewMode, dispatchViews, displayAsset, dispatchRisk, filterMarkersByState, toggleAllAssets,
    editing, filters, dispatchAsset, setEditing, dispatchDisplayAsset, focusedAsset, forgetEditingAsset,
  } = useRiskRegister()
  const [ionAlert] = useIonAlert()

  /* How many states have been selected in the dropdown */
  const [show, setShow] = React.useState<boolean>(false)
  const currentlySelected = (filter: Filters) : string => {
    const selected = Object.values(filter.states).filter((param) => !!param).length
    return (selected === 0) ? 'Nothing Selected' : `${selected} items selected`
  }

  /* Toggle fitler dropdown handler */
  const onToggleHandler = (isOpen : boolean, metadata: { source: string }) => {
    if (metadata.source !== 'select') {
      setShow(isOpen)
    }
  }

  const finishEdit = () => {
    if (viewMode.view === Views.MAP_VIEW && focusedAsset) {
      ionAlert({
        header: 'Cancel map edit?',
        message: 'Do you wish to continue? Changes will be lost.',
        buttons: [
          { text: 'No' },
          {
            text: 'Yes',
            handler: () => {
              forgetEditingAsset()
              dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
            },
          },
        ],
      })
      return
    }
    setEditing(false)
  }

  /** handle view changes between map and lists */
  const handleViewChange = (e: React.FormEvent<HTMLSelectElement>) : void => {
    setEditing(false)
    dispatchAsset({ type: FocusedActionKind.FORGET })
    dispatchRisk({ type: FocusedActionKind.FORGET })
    dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)

    if (e.currentTarget.value === Views.MAP_VIEW) {
      dispatchViews({ type: ViewsActionKind.MAP_VIEW })
    } else {
      dispatchViews({ type: ViewsActionKind.LIST_ASSETS })
    }
  }

  return (
    <IonGrid
      style={{
        backgroundColor: '#FFFFFF',
        padding: '10px 30px',
        marginBottom: '5px',
        boxShadow: '0px 4px 4px 0px #00000040',
        position: 'relative',
        borderRadius: '5px',
      }}
    >
      <IonRow className={`ion-align-items-center ${(viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.ASSETS) ? 'ion-justify-content-start' : 'ion-justify-content-between'}`}>
        <IonRow className='align-items-center'>
          <SelectView
            value={viewMode.view}
            onChange={handleViewChange}
            disabled={displayAsset.showAssetForm || editing}
            id='select-app-view'
          >
            <option value={Views.MAP_VIEW}>Asset Map</option>
            <option value={Views.REGISTER_VIEW}>Asset Register</option>
          </SelectView>
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.RISKS && (
              <IonButton
                style={{ '--ion-color-primary': '#326771' }}
                onClick={() => dispatchViews({ type: ViewsActionKind.LIST_ASSETS })}
                id='risk-register-back-button'
              > <Icon icon='akar-icons:arrow-back' /> Back
              </IonButton>
            )
          }
        </IonRow>
        {
        viewMode.view === Views.MAP_VIEW && !editing && (
          <ConsultantFilter>
            <h4>States: </h4>
            <Dropdown show={show} onToggle={(isOpen : boolean, metadata : { source: string }) => onToggleHandler(isOpen, metadata)}>
              <Dropdown.Toggle id='toggle-assets-filter-dropdown'>{ currentlySelected(filters) }</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item id='toggle-all-assets-filter' onClick={() => toggleAllAssets(!filters.toggleAll)} className='task-filter-option'>
                  <IonLabel style={{ padding: '0 10px' }}>Toggle all</IonLabel>
                  <IonCheckbox
                    checked={filters.toggleAll}
                    onClick={(e) => {
                      if (e.stopPropagation) e.stopPropagation()
                      toggleAllAssets(!filters.toggleAll)
                    }}
                  />
                </Dropdown.Item>
                {
                  Object.keys(filters.states).map((key) => (
                    <Dropdown.Item onClick={() => filterMarkersByState(key)} className='task-filter-option' key={key}>
                      <IonLabel style={{ padding: '0 10px' }}> {key} </IonLabel>
                      <IonCheckbox
                        name={key}
                        checked={filters.states[key]}
                        onClick={(e) => {
                          if (e.stopPropagation) e.stopPropagation()
                          filterMarkersByState(key)
                        }}
                      />
                    </Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
          </ConsultantFilter>
        )
      }
        <IonRow className='ion-align-items-center'>
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.RISKS && (
              <>
                {
                  !editing && (
                    <IonButton
                      style={{ '--ion-color-primary': '#0C9500' }}
                      id='add-risk-assessment-button'
                      onClick={() => {
                        dispatchDisplayAsset(DisplayAssetAction.OPEN_RISK_FORM)
                        dispatchDisplayAsset(DisplayAssetAction.OPEN_ASSET_FORM)
                      }}
                    >Add Risk
                    </IonButton>
                  )
                }
                <IonButton
                  style={{ '--ion-color-primary': '#326771' }}
                  onClick={() => setEditing(!editing)}
                  disabled={displayAsset.showAssetForm}
                >
                  {(editing) ? 'Done' : 'Edit/Review'}
                </IonButton>
              </>
            )
          }
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.ASSETS && (
              <IonButton
                style={{ '--ion-color-primary': '#326771' }}
                onClick={() => setEditing(!editing)}
                id='assets-edit-button'
                disabled={displayAsset.showAssetForm}
              >
                {(editing) ? 'Done' : 'Edit'}
              </IonButton>
            )
          }
          {
          viewMode.view === Views.MAP_VIEW && (
            <>
              <IonButton
                style={{ '--ion-color-primary': '#0C9500' }}
                onClick={() => dispatchDisplayAsset(DisplayAssetAction.OPEN_ASSET_TYPE)}
                disabled={displayAsset.showAssetForm}
                id='add-asset-button'
              >Add Asset
              </IonButton>
              <IonButton
                style={{ '--ion-color-primary': '#326771' }}
                onClick={() => {
                  if (editing) {
                    finishEdit()
                    return
                  }
                  setEditing(!editing)
                  dispatchAsset({ type: FocusedActionKind.FORGET })
                  dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
                }}
              >
                {(editing) ? 'Done' : 'Edit'}
              </IonButton>
            </>
          )
        }
        </IonRow>
      </IonRow>

    </IonGrid>
  )
}

export default MapControls
