/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/no-array-index-key */
import { IonButton, IonRow, useIonAlert } from '@ionic/react'
import React, {
  FC, useState, useRef, useEffect,
} from 'react'
import { useHistory } from 'react-router-dom'
import { RegisterContainer, SimpleButton } from '../maps/StyledContainers'
import axios from '../../utils/axios'
import FormPopup from '../maps/forms/FormPopup'
import { useAuth } from '../../hooks/useAuth'

interface Props {
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const VerifyPhoneNumber : FC<Props> = ({ loading, setLoading }) => {
  const [sent, setSent] = useState<boolean>(false)
  const [verified, setVerified] = useState<boolean>(false)
  const [ionAlert] = useIonAlert()
  const { setVerifySms, couldVerifySms } = useAuth()
  const history = useHistory()

  const handleSendCode = () => {
    setSent(true)
    axios.post('/verify_sms')
  }

  const submitCode = (code: string) => {
    setLoading(true)
    axios.post('/check_sms_verification', { code }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Invalid OTP',
          message: data.message,
          buttons: [{ text: 'Try Again', handler: () => setSent(false) }],
        })
        return
      }

      setVerified(true)
    }).catch(() => {
      ionAlert({
        header: 'Unknown Server Error',
        message: 'A server error has occurred while processing your request.',
        buttons: [{ text: 'ok' }],
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const finishVerification = () => {
    setVerifySms(false)
    history.push('/workspaces')
  }

  const handleDoitLater = () => {
    setVerifySms(false)
    axios.post('/bypass_sms_verification')
    history.push('/workspaces')
  }

  useEffect(() => {
    if (!couldVerifySms) { history.push('/workspaces') }
  }, [])

  return (
    <RegisterContainer style={{ width: '500px' }}>
      <h2 style={{ textAlign: 'left', color: '#8E151F' }}>Verify Phone Number</h2>
      {
        !verified && sent && (
          <>
            <InputCode
              length={6}
              label='Please type the OTP sent to your phone number in here.'
              loading={loading}
              onComplete={submitCode}
            />
          </>
        )
      }

      {
        !verified && !sent && (
          <>
            <p>To verify your phone number we will send you a One Time Passcode (OTP). Make sure you have your phone with you.</p>
            <IonRow className='ion-justify-content-center'>
              <IonButton style={{ '--background': '#326771' }} onClick={handleSendCode} title='login'>Send OTP</IonButton>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={handleDoitLater}>Do it later</SimpleButton>
            </IonRow>
          </>
        )
      }
      {
        verified && (
          <FormPopup message='Phone number verified successfully' onClose={finishVerification} />
        )
      }
    </RegisterContainer>
  )
}

export default VerifyPhoneNumber

const InputCode = ({
  length, label, loading, onComplete,
}) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''))
  const inputs = useRef([])
  // Typescript
  // useRef<(HTMLInputElement | null)[]>([])

  const processInput = (e: React.ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = e.target.value
    if (/[^0-9]/.test(num)) return
    const newCode = [...code]
    newCode[slot] = num
    setCode(newCode)
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus()
    }
    if (newCode.every((val) => val !== '')) {
      onComplete(newCode.join(''))
    }
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code]
      newCode[slot - 1] = ''
      setCode(newCode)
      inputs.current[slot - 1].focus()
    }
  }

  return (
    <div className='code-input'>
      <p className='code-label'>{label}</p>
      <div className='code-inputs'>
        {code.map((num, idx) => (
          <input
            key={idx}
            type='text'
            inputMode='numeric'
            maxLength={1}
            value={num}
            autoFocus={!code[0].length && idx === 0}
            readOnly={loading}
            onChange={(e) => processInput(e, idx)}
            onKeyUp={(e) => onKeyUp(e, idx)}
            ref={(ref) => inputs.current.push(ref)}
          />
        ))}
      </div>
    </div>
  )
}
