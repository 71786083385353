import { IonButton, IonRow, useIonAlert } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import axios from '../../../utils/axios'
import { PopoverField } from '../StyledContainer'
import { useWorkspace } from '../../../hooks/useWorkspace'

const POIPopup : FC = () => {
  const {
    focusedElems, dispatchFocused, POITypes, updatePOI,
    setSubmittedMessage, deletePOI, stakeholders, setLoading, communities,
  } = useTerrainMapping()
  const { focusedPOI } = focusedElems
  const [name, setName] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [selectedStakeholders, setSelectedStakeholders] = useState<{ value: number, label: string }[]>([])
  const [selectedAreas, setSelectedAreas] = useState<{ value: number, label: string }[]>([])
  const [editing, setEditing] = useState<boolean>(false)
  const [ionAlert] = useIonAlert()
  const { workspace } = useWorkspace()
  console.log('poi', focusedPOI)

  useEffect(() => {
    setName(focusedPOI.name.slice())
    setCategory(focusedPOI.category.slice())
    setDescription(focusedPOI.description.slice())
    if (communities && focusedPOI?.poi_areas) {
      // Cross-reference poi_areas with communities to build the selected areas
      const currentSelectedAreas = focusedPOI.poi_areas.map((poiArea) => {
        const community = communities.find((comm) => comm.id === poiArea.community_id)
        // Ensure the community is found, otherwise return a fallback or null
        return community ? { value: community.id, label: community.name } : null
      }).filter((area) => area !== null) // Remove any nulls resulted from not finding a community
      setSelectedAreas(currentSelectedAreas)
    }
  }, [editing])

  const handleDelete = () => {
    const handler = () => {
      setLoading(true)
      axios.post('/api/v2/poi/archive_poi', { domain_id: workspace.id, poi_id: focusedPOI.id }).then(({ data }) => {
        if (!data.ok) {
          ionAlert({
            header: 'Server Error',
            message: data.message,
            buttons: [{ text: 'Ok' }],
          })
          return
        }
        deletePOI(focusedPOI)
        setSubmittedMessage('Point of Interest deleted successfully.')
      }).catch(() => {
        ionAlert({
          header: 'Server Error',
          message: 'Unknown server error',
          buttons: [{ text: 'Ok' }],
        })
      }).finally(() => {
        setLoading(false)
        dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
      })
    }

    ionAlert({
      header: 'Delete POI',
      message: 'Are you sure you want to delete this PoI?',
      buttons: [{ text: 'Yes', handler }, { text: 'No' }],
    })
  }

  const getCommunityNameById = (id) => {
    const community = communities.find((comm) => comm.id === id)
    return community ? community.name : 'Unknown' // or any other fallback
  }

  const handleSubmit = () => {
    if (name === '') {
      ionAlert({
        header: 'No Name',
        message: 'You need to specify the name of this POI.',
        buttons: [{ text: 'ok' }],
      })
      return
    }

    if (category === '') {
      ionAlert({
        header: 'Error: No Category',
        message: 'You need to specify the category of this POI.',
        buttons: [{ text: 'ok' }],
      })
      return
    }

    const newStakeholders = stakeholders.filter(({ id }) => selectedStakeholders.find(({ value }) => value === id))
    const stakeholdersToDelete = focusedPOI.stakeholders.filter(({ id }) => !selectedStakeholders.find(({ value }) => value === id))
    const stakeholdersToAdd = selectedStakeholders.filter(({ value }) => !focusedPOI.stakeholders.find(({ id }) => value === id))

   // const areasToDelete = focusedPOI?.poi_areas?.filter(({ id }) => !selectedAreas.find(({ value }) => value === id))
    const areasToDelete = focusedPOI?.poi_areas?.filter(({ community_id, id }) => !selectedAreas.find(({ value }) => value === community_id)).map(({ id }) => id)
    //const areasToAdd = selectedAreas?.filter(({ value }) => !focusedPOI?.poi_areas?.find(({ id }) => value === id))
    const areasToAdd = selectedAreas?.filter(({ value }) => !focusedPOI?.poi_areas?.find(({ community_id }) => value === community_id)).map(({ value }) => value)

    setLoading(true)
    axios.put('/api/v2/poi/edit_poi', {
      domain_id: workspace.id,
      poi_id: focusedPOI.id,
      name,
      description,
      category,
      remove_stakeholders: stakeholdersToDelete.map(({ id }) => id),
      add_stakeholders: stakeholdersToAdd.map(({ value }) => value),
      remove_areas: areasToDelete,
      add_areas: areasToAdd,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      updatePOI({
        ...focusedPOI, name, description, category, stakeholders: newStakeholders,
      })
      setSubmittedMessage('Point of Interest updated successfully.')
    }).catch(() => {
      ionAlert({
        header: 'Server error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setLoading(false)
      dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
    })
  }

  useEffect(() => {
    setSelectedStakeholders(focusedPOI.stakeholders.map((val) => ({ value: val.id, label: val.name })))
  }, [])

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <OverlayContainer
        style={{
          zIndex: 25, height: '80%', overflow: 'hidden', width: '60%',
        }}
        className='terrain-add-popup'
      >
        <div style={{ maxHeight: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
          <IonRow style={{ marginBottom: '10px' }} className='ion-justify-content-between ion-align-items-center'>
            <h5 style={{ marginBottom: 0 }}>Pin POI</h5>
            <SimpleButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })}>Close X</SimpleButton>
          </IonRow>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <div className='terrain-form-field'>
              <h6>Name</h6>
              {
                  editing ? (
                    <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
                  ) : (
                    <p>{focusedPOI.name}</p>
                  )
              }
            </div>
            <div className='terrain-form-field'>
              <h6>Category</h6>
              {
                  editing ? (
                    <select
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      style={{ padding: '5px 10px' }}
                    >
                      <option value='' disabled>Select Category</option>
                      {
                          POITypes.map((val) => (
                            <option key={val} value={val}>{val}</option>
                          ))
                      }
                    </select>
                  ) : (
                    <p>{focusedPOI.category}</p>
                  )
              }
            </div>
            <div className='terrain-form-field'>
              <h6>Associated Stakeholders</h6>
              {
                  editing ? (
                    <CreatableSelect
                      isMulti
                      placeholder='Select Stakeholders'
                      name='colors'
                      className='select-container'
                      id='journey-passengers'
                      menuPortalTarget={document.body}
                      options={stakeholders.map((val) => ({ value: val.id, label: val.name }))}
                      value={selectedStakeholders}
                      onChange={(selected) => setSelectedStakeholders(selected)}
                    />
                  ) : (
                    <>
                      {
                          focusedPOI.stakeholders.length === 0 ? (
                            <p>No Stakeholders</p>
                          ) : (
                            <p>{focusedPOI.stakeholders.map((val) => val.name).join(', ')}</p>
                          )
                      }
                    </>
                  )
              }
            </div>
            <div className='terrain-form-field'>
              <h6>Associated Areas</h6>
              {
                  editing ? (
                    <CreatableSelect
                      isMulti
                      placeholder='Select Areas'
                      name='colors'
                      className='select-container'
                      id='journey-passengers'
                      menuPortalTarget={document.body}
                      options={communities.map((val) => ({ value: val.id, label: val.name }))}
                      value={selectedAreas}
                      onChange={(selected) => setSelectedAreas(selected)}
                    />
                  ) : (
                    <>
                      {
                          focusedPOI?.areas?.length === 0 ? (
                            <p>No Areas</p>
                          ) : (
                            <p>{ focusedPOI?.poi_areas?.map((val) => getCommunityNameById(val.community_id)).join(', ') }</p>
                          )
                      }
                    </>
                  )
              }
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Description</h6>
              {
                  editing ? (
                    <textarea style={{ height: '10em' }} value={description} onChange={(e) => setDescription(e.target.value)} />
                  ) : (
                    <p>{focusedPOI.description}</p>
                  )
              }
            </div>
          </div>
          <IonRow className='ion-justify-content-end ion-align-items-center'>
            {
                editing ? (
                  <>
                    <IonButton onClick={() => dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })} style={{ '--background': '#4197A9' }}>Back</IonButton>
                    <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Submit</IonButton>
                  </>
                ) : (
                  <>
                    <IonButton onClick={handleDelete} style={{ '--background': '#8E151F' }}>Delete</IonButton>
                    <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
                  </>
                )
            }
          </IonRow>
        </div>
      </OverlayContainer>
    </>
  )
}

export default POIPopup
