import React from 'react'
import {
  IonContent, IonRow, IonCol, IonSpinner,
} from '@ionic/react'

import moment from 'moment'
import { WidgetTitles } from '../maps/WidgetContainers'

import { ObscureBackground, CenterLoader } from '../GlobalContainers'

/**
 *  ========================
 *    Archive Tasks List
 *  ========================
 */
const ArchiveList = ({ tasks, loading }) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  return (
    <div style={{ height: '85%', position: 'relative' }}>
      {
        loading && (
          <>
            <ObscureBackground />
            <CenterLoader>
              <IonSpinner color='light' />
            </CenterLoader>
          </>
        )
      }
      <WidgetTitles style={{ height: '10%', paddingRight: '0' }}>
        <IonRow className='ion-align-items-center'>
          <IonCol size={2}>Task Title</IonCol>
          <IonCol size={2}>Assigned To</IonCol>
          <IonCol size={2}>Due Date</IonCol>
          <IonCol size={1}>High Priority</IonCol>
          <IonCol size={1}>App</IonCol>
          <IonCol size={4}>Description</IonCol>
        </IonRow>
      </WidgetTitles>
      <div style={{ height: '90%', '--ion-background-color': 'white' }}>
        <IonContent>
          {
            tasks.map((task, index) => (
              <IonRow key={task.id} style={{ backgroundColor: getColor(index), height: '10%' }} className='ion-align-items-center widget-row incident-row ion-text-center'>
                <IonCol size={2}>
                  {
                    task.title
                  }
                </IonCol>
                <IonCol size={2}>
                  {
                    task.assignee.email
                  }
                </IonCol>
                <IonCol size={2}>
                  {
                    moment(task.due).format('DD/MM/YY')
                  }
                </IonCol>
                <IonCol size={1}>
                  {(task.highPriority) ? 'Yes' : 'No'}
                </IonCol>
                <IonCol size={1}>
                  {
                    task.app
                  }
                </IonCol>
                <IonCol size={4}>
                  { task.description }
                </IonCol>
              </IonRow>
            ))
          }
        </IonContent>
      </div>
    </div>
  )
}

export default ArchiveList
