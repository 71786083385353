import React from 'react'
import {
  IonSelect, IonListHeader, IonContent, IonLabel,
  IonRow, IonButton, IonSelectOption, IonItem,
} from '@ionic/react'

import CreatableSelect from 'react-select/creatable'
import { FormTitle, InstructionButtons } from '../../../maps/StyledContainers'

/**
 *  ===================================
 *     SELECT PATHS AND PASSENGERS
 *  ===================================
 */
const Paths = ({
  onBack, routes, input, handleInput, setInput, users, children,
  onNext,
}) => (
  <IonContent
    style={{
      '--ion-background-color': 'white',
      '--padding-bottom': '15px',
      '--padding-top': '15px',
      '--padding-end': '15px',
    }}
  >
    {
      children
    }
    <IonItem>
      <IonLabel>Select Routes:</IonLabel>
      <IonSelect
        multiple
        name='routes'
        interface='popover'
        value={input.routes}
        onIonChange={handleInput}
      >
        {
          routes.map((route) => (
            <IonSelectOption key={route.id} value={route.id}>{ route.name }</IonSelectOption>
          ))
        }
      </IonSelect>
    </IonItem>
    <IonListHeader>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Passenger Details</h3>
      </FormTitle>
    </IonListHeader>
    <CreatableSelect
      isMulti
      placeholder='Passengers'
      name='colors'
      className='select-container mobile-select-journey'
              // eslint-disable-next-line no-undef
      menuPortalTarget={document.body}
      options={users.map((user) => ({ value: user.email, label: user.email }))}
      value={input.passengers}
      onChange={(selected) => setInput({ ...input, passengers: selected })}
    />
    <InstructionButtons>
      <IonRow style={{ paddingLeft: '15px', marginTop: '15px' }} className='ion-justify-content-between'>
        <IonButton style={{ '--background': '#4197A9' }} onClick={onBack}>Back</IonButton>
        <IonButton style={{ '--background': '#326771' }} onClick={onNext}>Next</IonButton>
      </IonRow>
    </InstructionButtons>
  </IonContent>
)
export default Paths
