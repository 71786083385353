import React, { useState } from 'react'

import {
  IonCol, IonRow, IonList, IonItem, IonButton,
} from '@ionic/react'

import Select from 'react-select'
import { SettingField, SettingTitle } from '../StyledContainers'

const options = [{ label: 'Default - Admin', value: 'admin' }, { label: 'All', value: 'all' }]

/**
 *  =========================
 *    Journey App Requests
 *  =========================
 */

const JourneySettings = () => {
  const [authorise, setAuthorise] = useState(options[0])
  const [addRoutes, setAddRoutes] = useState(options[0])
  const [addRB, setAddRB] = useState(options[0])

  return (
    <>
      <SettingTitle>Journeys Settings</SettingTitle>
      <IonRow style={{ height: '90%' }}>
        <IonCol size={6}>
          <IonList style={{ '--ion-item-background': 'white', padding: 'white' }}>
            <IonItem lines='none'>
              <SettingField>
                <h4>Journey Management</h4>
                <p>Choose who has permission to authorise a Journey Request</p>
                <Select
                  value={authorise}
                  options={options}
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  className='settings-select'
                  onChange={(selected) => setAuthorise(selected)}
                />
              </SettingField>
            </IonItem>
            <IonItem lines='none'>
              <SettingField>
                <h4>Routes</h4>
                <p>Choose who can add Routes in the Journeys App</p>
                <Select
                  value={addRoutes}
                  options={options}
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  className='settings-select'
                  onChange={(selected) => setAddRoutes(selected)}
                />
              </SettingField>
            </IonItem>
          </IonList>
        </IonCol>
        <IonCol size={6} style={{ height: '100%' }}>
          <IonList style={{ '--ion-item-background': 'white' }}>
            <SettingField>
              <h4>Restriction Boxes</h4>
              <p>Choose who can add a restriction box in the Journeys App</p>
              <Select
                value={addRB}
                options={options}
                // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                className='settings-select'
                onChange={(selected) => setAddRB(selected)}
              />
            </SettingField>
          </IonList>
          <IonRow style={{ position: 'absolute', bottom: 0, width: '100%' }} className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#326771' }}>Save</IonButton>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  )
}

export default JourneySettings
