import { IonButton, IonLoading } from '@ionic/react'
import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import { useWorkspace } from '../../hooks/useWorkspace'
import { InternalIncident } from '../types/GlobalTypes'
import { WidgetContainer } from './StyleContainers'
import useApi from '../../hooks/testHooks'
import { processInternalIncidents, processLogs } from '../../hooks/incident-management/helpers/Utils'

const IncidentData : FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [incidentData, setIncidentData] = useState<InternalIncident>()
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  useEffect(() => {
    Promise.all([
      apiHook.fetchInternalIncident({ domain_id: workspace.id, incident_id: workspace.incident_id }),
      apiHook.getUsers(workspace.id),
    ])
      .then(([{
        secondary_incidents, action_log, incident,
      }, { users }]) => {
        const formattedIncident : InternalIncident = {
          ...processInternalIncidents([incident], [])[0],
          additionalIncidents: secondary_incidents,
          peopleInvolved: [],
          logs: processLogs(action_log, users),
          actors: [],
          causes: [],
          additionalComments: [],
        }
        setIncidentData(formattedIncident)
        setLoading(false)
      })
  }, [])

  return (
    <div>
      <WidgetContainer style={{ backgroundColor: 'white' }}>
        {
          loading ? (
            <IonLoading isOpen={loading} />
          ) : (
            <>
              <h4 style={{ color: '#8E151F', textAlign: 'center', fontWeight: 'bold' }}>Incident Ticket Overview</h4>
              <div style={{ margin: '20px 0' }}>
                <h5>Date of Incident</h5>
                <p style={{ margin: 0 }}>{ moment(incidentData.reported).format('DD/MM/YY') }</p>
              </div>
              <div style={{ margin: '20px 0' }}>
                <h5>Primary Incident</h5>
                <p style={{ margin: 0 }}>{ incidentData.primary_title }</p>
              </div>
              <div style={{ margin: '20px 0' }}>
                <h5>Additional Incidents</h5>
                {
                  incidentData.additionalIncidents.map((val) => (
                    <p key={val.id} style={{ margin: 0 }}>{ val.title }</p>
                  ))
                }
                {
                  incidentData.additionalIncidents.length === 0 && (
                    <p style={{ margin: 0 }}>No additional Incidents</p>
                  )
                }
              </div>
              <IonButton routerLink='/crisis' style={{ '--background': '#326771', width: '100%' }}>
                View Ticket
              </IonButton>
            </>
          )
        }
      </WidgetContainer>
      <WidgetContainer>
        {
          loading ? (
            <IonLoading isOpen={loading} />
          ) : (
            <>
              <h4 style={{ color: 'white', marginBottom: '10px' }}>Action Log Latest</h4>
              {
                incidentData.logs.map((val) => (
                  <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '2.5px' }} key={val.id}>
                    <p style={{ margin: 0 }}>{ val.log }</p>
                  </div>
                ))
              }
              {
                incidentData.logs.length === 0 && (
                  <p style={{ margin: '10px', color: 'white', textAlign: 'center' }}>No logs submitted.</p>
                )
              }
            </>
          )
        }
      </WidgetContainer>
    </div>
  )
}

export default IncidentData
