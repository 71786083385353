import * as React from 'react'
import { AuthUser } from '../components/types/GlobalTypes'
import axios from '../utils/axios'
import { AuthInterface } from './helpers/HookInterfaces'

export const authContext = React.createContext<AuthInterface>({} as AuthInterface)
export const useAuth = () => React.useContext(authContext)

/* JWT version of useAuth */
const useProvideAuth = () : AuthInterface => {
  const [user, setUser] = React.useState<AuthUser | null>(null)
  const [resetPassword, setResetPassword] = React.useState<boolean>(false)
  const [verifySms, setVerifySms] = React.useState<boolean>(false)
  const [couldVerifySms, setCouldVerifySms] = React.useState<boolean>(false)

  const fetchProfile = () : AuthUser | null => {
    if (!localStorage.getItem('token')) { return null }
    const userData: AuthUser = JSON.parse(Buffer.from(localStorage.getItem('token').split('.')[1], 'base64').toString()) as AuthUser
    setUser(userData)

    return userData
  }

  const login = (email: string, password: string) : Promise<AuthUser | string> => axios.post('/auth/login', { email, password })
    .then((response) => {
      if (response.data.ok) {
        localStorage.setItem('token', response.data.token)
        return fetchProfile()
      }
      return Promise.resolve(response.data)
    })

  const register = (email: string, password: string, first_name: string, final_name: string, phone_number: string, alternative_phone_number: string) => axios.post('/register', {
    email, password, first_name, final_name, phone_number, alternative_phone_number,
  })
    .then((response) => Promise.resolve(response.data))

  const logout = () => {
    if (!localStorage.getItem('token')) { return }
    setUser(null)
    localStorage.removeItem('token')
  }

  return {
    fetchProfile,
    user,
    login,
    logout,
    register,
    setUser,
    resetPassword,
    setResetPassword,
    verifySms,
    setVerifySms,
    couldVerifySms,
    setCouldVerifySms,
  }
}

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}
