import React, { useState } from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid, IonContent,
  IonCol, useIonAlert,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import useApi from '../../hooks/testHooks'
import { SimpleButton } from '../maps/StyledContainers'
import { useSituationReport } from '../../hooks/insights/useSituationReport'
import FieldSitrepPopup from './FieldSitrepPopup'
//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SitRepInsights = ({
  step, setStep, county, insights, users,
}) => {
  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const [presentAlert] = useIonAlert()
  const apiHook = useApi()

  const handleDownload = () => {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  const [, setIsShowing, , setCountyId] = useSituationReport()
  const [showFieldSitrep, setShowFieldSitrep] = useState(false)
  const [name, setName] = useState()
  // const [county, setCounty] = useState()
  const [state, setState] = useState()
  const [insight, setInsight] = useState({})

  const handleClose = () => {
    setIsShowing(false)
    setCountyId(null)
    setStep(1)
  }

  function handleInsight(val) {
    setInsight(val)
    apiHook.ReportsIncidentPopup({ user_id: val.user_id, county_id: val.county_id }).then((result) => {
      setName(result.user)
      setState(result.state)
      setShowFieldSitrep(true)
    })
  }

  function getColor(level) {
    if (level === 1) {
      return '#14C105'
    } if (level === 2) {
      return '#349DFF'
    } if (level === 3) {
      return '#FFE500'
    } if (level === 4) {
      return '#FF8A00'
    } if (level === 5) {
      return '#FF0000'
    }
    return '#FFFFFF'
  }

  function getTextColor(level) {
    if (level === 3) {
      return 'black'
    }
    return 'white'
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '95%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Situation Report - ${county.name} County`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonGrid class='ion-justify-content-center' style={{ width: '95%', marginTop: '1em' }}>
          <IonList>
            <IonRow>
              <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontWeight: 'bold', fontSize: '1.15em' }}>Field Sitrep Reports Breakdown</IonLabel></IonCol>
            </IonRow>
          </IonList>
        </IonGrid>

        <IonContent style={{ height: '68%', '--ion-background-color': '#FFFFFF' }}>
          <IonGrid class='ion-justify-content-center' style={{ width: '100%' }}>
            <IonGrid class='ion-justify-content-center' style={{ backgroundColor: '#FFFFFF' }}>
              <IonRow style={{ outline: 'solid 1px' }}>

                <IonCol size='1' style={{ backgroundColor: '#8E151F', outline: 'solid 1px', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Date</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>By</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Ops</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Road Travel</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Supplies</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Security</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Ethnic Tension</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Community</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Armed Conflict</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Air Travel</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Local Gov.</IonLabel>
                </IonCol>

                <IonCol size='1' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#FFFFFF' }}>Env and Health</IonLabel>
                </IonCol>

              </IonRow>
              {
                insights.length === 0 && (
                  <IonRow style={{ height: '65%' }}>
                    <IonLabel style={{
                      marginTop: '1em', marginLeft: '1em', fontWeight: 'bold', fontSize: '1em',
                    }}
                    >There have been no Field Sitreps in the past week.
                    </IonLabel>
                  </IonRow>
                )
              }
              {
                insights.length > 0 && (
                  insights.map(
                    (val) => (
                      <button
                        type='button'
                        style={{
                          height: '100%', width: '100%', boxShadow: 'none', '-webkit-box-shadow': 'l', padding: '0 0 0 0',
                        }}
                        onClick={() => handleInsight(val)}
                      >
                        <IonRow style={{ outline: 'solid 1px' }}>
                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center', outline: 'solid 1px', backgroundColor: '#FFFFFF', paddingLeft: '0.6em', outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{moment(val.reported).format('DD/MM/YY')}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center', outline: 'solid 1px', backgroundColor: '#FFFFFF', paddingLeft: '0.6em', outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{`${(users.find((e) => e.id === val.user_id)).first_name} ${(users.find((e) => e.id === val.user_id)).final_name}`}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center', outline: 'solid 1px', backgroundColor: getColor(val.needed[0]), paddingLeft: '0.6em', color: getTextColor(val.needed[0]), outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.needed[0]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center', outline: 'solid 1px', backgroundColor: getColor(val.needed[1]), paddingLeft: '0.6em', color: getTextColor(val.needed[1]), outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.needed[1]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center', outline: 'solid 1px', backgroundColor: getColor(val.needed[2]), paddingLeft: '0.6em', color: getTextColor(val.needed[2]), outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0/9em' }}>{val.needed[2]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center', outline: 'solid 1px', backgroundColor: getColor(val.needed[3]), paddingLeft: '0.6em', color: getTextColor(val.needed[3]), outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.needed[3]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign:
                              'center',
                              outline: 'solid 1px',
                              backgroundColor: getColor(val.optional[0]),
                              paddingLeft: '0.6em',
                              color: getTextColor(val.optional[0]),
                              outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.optional[0]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center',
                              outline: 'solid 1px',
                              backgroundColor: getColor(val.optional[1]),
                              paddingLeft: '0.6em',
                              color: getTextColor(val.optional[1]),
                              outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.optional[1]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center',
                              outline: 'solid 1px',
                              backgroundColor: getColor(val.optional[2]),
                              paddingLeft: '0.6em',
                              color: getTextColor(val.optional[2]),
                              outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.optional[2]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center',
                              outline: 'solid 1px',
                              backgroundColor: getColor(val.optional[3]),
                              paddingLeft: '0.6em',
                              color: getTextColor(val.optional[3]),
                              outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.optional[3]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center',
                              outline: 'solid 1px',
                              backgroundColor: getColor(val.optional[4]),
                              paddingLeft: '0.6em',
                              color: getTextColor(val.optional[4]),
                              outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.optional[4]}</IonLabel>
                          </IonCol>

                          <IonCol
                            size='1'
                            style={{
                              textAlign: 'center',
                              outline: 'solid 1px',
                              backgroundColor: getColor(val.optional[5]),
                              paddingLeft: '0.6em',
                              color: getTextColor(val.optional[5]),
                              outlineColor: '#C4C4C4',
                            }}
                          >
                            <IonLabel style={{ fontSize: '0.9em' }}>{val.optional[5]}</IonLabel>
                          </IonCol>

                        </IonRow>
                      </button>

                    ),
                  )
                )
              }

            </IonGrid>
          </IonGrid>
        </IonContent>

        <IonRow style={{}} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext} disabled>Next</IonButton>
          </IonCol>
        </IonRow>
        {
          showFieldSitrep && (
            <FieldSitrepPopup
              setShowFieldSitrep={setShowFieldSitrep}
              name={name}
              county={county}
              state={state}
              insight={insight}
            />
          )
        }
      </OverlayContainer>

    </>
  )
}

export default SitRepInsights
