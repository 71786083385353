import { IonRow, IonButton, useIonAlert } from '@ionic/react'
import React, { useState } from 'react'
import Select from 'react-select'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { CenterForm } from '../../../riskregister/StyledContainers'
import { DescriptionArea } from '../../StyledContainers'

/**
 *  ==========================================
 *      SUBMIT LOG TO INCIDENT MANAGEMENT
 *  ==========================================
 */
const AddLogPopup = ({ onSubmit, onClose }) => {
  const { users } = useIncidentsData()
  const [input, setInput] = useState({ description: '', owner: undefined })
  const [ionAlert] = useIonAlert()

  const handleSubmit = () => {
    if (input.description === '') {
      ionAlert({
        header: 'Error',
        message: 'You need to add a description to the log.',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
      return
    }
    if (!input.owner) {
      ionAlert({
        header: 'Error',
        message: 'You must specify the owner of this log.',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
      return
    }
    const owner = users.find((user) => user.id === input.owner.value)
    onSubmit({ ...input, owner })
  }

  return (
    <CenterForm style={{ height: 'auto', padding: '20px' }}>
      <h4 style={{ color: '#8E151F' }}>Add Log</h4>
      <div style={{ margin: '10px 0' }}>
        <h5>Description</h5>
        <DescriptionArea style={{ width: '100%' }} className='wide-area' name='description' value={input.description} onChange={(e) => setInput({ ...input, description: e.target.value })} />
      </div>
      <div style={{ margin: '10px 0' }}>
        <h5>Owner</h5>
        <Select
          placeholder='Select user'
          name='owner'
          className='select-container'
          value={input.owner}
                // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
          onChange={(selected) => setInput({ ...input, owner: selected })}
        />
      </div>
      <IonRow className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
        <IonButton style={{ '--background': '#0C9500' }} onClick={handleSubmit}>Add</IonButton>
      </IonRow>
    </CenterForm>
  )
}

export default AddLogPopup
