import {
  Commitment,
  Community, EngagementNote, Flashpoint, Stakeholder, TerrainIssue, TerrainPOI,
} from '../../../components/types/OptimizedMaps'

export interface FocusedElemState {
  focusedFlashpoint: Flashpoint | null,
  focusedStakeholder: Stakeholder | null,
  focusedPOI: TerrainPOI | null,
  focusedCommunity: Community | null,
  focusedEngagement: EngagementNote | null,
  focusedIssue: TerrainIssue | null,
  focusedCommitment: Commitment | null,
}

export enum FocusedActionKind {
  SELECT_FLASHPOINT = 'flashpoint',
  SELECT_STAKEHOLDER = 'stakeholder',
  SELECT_POI = 'poi',
  FORGET_SELECTION = 'forget',
  SELECT_COMMUNITY = 'community',
  SELECT_ENGAGEMENT = 'engagement',
  SELECT_ISSUE = 'issue',
  SELECT_COMMITMENT = 'commitment',
}

export interface FocusedElemAction {
  type: FocusedActionKind,
  flashpoint?: Flashpoint,
  stakeholder?: Stakeholder,
  poi?: TerrainPOI,
  community?: Community,
  engagement?: EngagementNote
  issue?: TerrainIssue,
  commitment?: Commitment,
}

export const initialFocusedState : FocusedElemState = {
  focusedFlashpoint: null,
  focusedStakeholder: null,
  focusedPOI: null,
  focusedCommunity: null,
  focusedEngagement: null,
  focusedIssue: null,
  focusedCommitment: null,
}

export const focusedReducer = (state: FocusedElemState, action: FocusedElemAction) : FocusedElemState => {
  const {
    type, flashpoint, stakeholder, poi, community,
    engagement, issue, commitment,
  } = action

  switch (type) {
    case FocusedActionKind.SELECT_FLASHPOINT:
      return { ...initialFocusedState, focusedFlashpoint: flashpoint as Flashpoint }
    case FocusedActionKind.SELECT_STAKEHOLDER:
      return { ...initialFocusedState, focusedStakeholder: stakeholder as Stakeholder }
    case FocusedActionKind.FORGET_SELECTION:
      return { ...initialFocusedState }
    case FocusedActionKind.SELECT_POI:
      return { ...initialFocusedState, focusedPOI: poi as TerrainPOI }
    case FocusedActionKind.SELECT_COMMUNITY:
      return { ...initialFocusedState, focusedCommunity: community as Community }
    case FocusedActionKind.SELECT_ENGAGEMENT:
      return { ...initialFocusedState, focusedEngagement: engagement as EngagementNote }
    case FocusedActionKind.SELECT_ISSUE:
      return { ...initialFocusedState, focusedIssue: issue as TerrainIssue }
    case FocusedActionKind.SELECT_COMMITMENT:
      return { ...initialFocusedState, focusedCommitment: commitment }
    default:
      return { ...initialFocusedState }
  }
}

export enum FilterUpdate {
  SHOW_COMMUNITIES = 'showCommunities',
  SHOW_ASSETS = 'showAssets',
  SHOW_POIS = 'showPOIs',
}

export interface Filters {
  showAssets: boolean,
  showCommunities: boolean,
  showPOIs: boolean,
}

export const defaultFilters : Filters = {
  showAssets: false,
  showCommunities: false,
  showPOIs: false,
}
