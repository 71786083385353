import React, { FC } from 'react'
import {
  IonListHeader, IonRow,
} from '@ionic/react'

import moment from 'moment'
import { FormTitle } from '../StyledContainers'
import { Journey } from '../../types/OptimizedMaps'

interface Props {
  focussedJourney: Journey
}

/**
 *  =================================
 *    Show journey details in form
 *  =================================
 */
const JourneyDetails : FC<Props> = ({ focussedJourney }) => (
  <>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Journey Purpose:</h3>
      </FormTitle>
      <p id='ticket-journey-purpose'> { focussedJourney.purpose } </p>
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Journey Leader:</h3>
      </FormTitle>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <p id='ticket-journey-leader-name'> {(focussedJourney.leader) ? `${focussedJourney.leader.first_name} ${focussedJourney.leader.final_name}` : 'External User'} </p>
        <p id='ticket-journey-leader-mobile'> {focussedJourney.leader_phone} </p>
      </IonRow>
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Driver:</h3>
      </FormTitle>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <p id='ticket-journey-driver-name'> {focussedJourney.driver_name} </p>
        <p id='ticket-journey-driver-phone'> {focussedJourney.driver_phone} </p>
      </IonRow>
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Vehicle Details:</h3>
      </FormTitle>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <p><span>Vehicle Type:</span> <span id='ticket-journey-vehicle-type'>{focussedJourney.vehicle_type}</span></p>
        <p><span> Reg Number: </span><span id='ticket-journey-vehicle-registration'>{focussedJourney.vehicle_reg}</span></p>
      </IonRow>
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Emergency Communication:</h3>
      </FormTitle>
      {
        focussedJourney.emergency_sat && (
          <p> <span>Emergency Phone 1:</span> <span id='ticket-journey-emergency-1'>{focussedJourney.emergency_sat}</span></p>
        )
      }
      {
        focussedJourney.emergency_other && (
          <p> <span>Emergency Phone 2:</span><span id='ticket-journey-emergency-2'>{focussedJourney.emergency_other}</span></p>
        )
      }
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Departure (estimate)</h3>
      </FormTitle>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <p><span>location: </span> <span id='ticket-journey-from-location'>{focussedJourney.departure_location}</span></p>
        <p>{moment(focussedJourney.departure_time).format('DD-MM-YYYY hh:mm a')}</p>
      </IonRow>
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Arrival (estimate)</h3>
      </FormTitle>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <p><span>location: </span><span id='ticket-journey-to-location'>{focussedJourney.arrival_location}</span></p>
        <p>{moment(focussedJourney.arrival_time).format('DD-MM-YYYY hh:mm a')}</p>
      </IonRow>
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Route(s)</h3>
      </FormTitle>
      {
        focussedJourney.routes.map((route) => (
          <p className='journey-route-name' key={route.id}>- {route.name}</p>
        ))
      }
    </div>
    <div className='task-expand-subtitles'>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Passengers</h3>
      </FormTitle>
      {
      focussedJourney.passengers.split(', ').map((name) => (
        <p key={name}>- {name}</p>
      ))
    }
    </div>
    {
    focussedJourney.passenger_hotel !== ''
    && (
    <>
      <IonListHeader style={{ paddingLeft: '0' }}>
        <h2 style={{ fontSize: '1.4rem' }}>Accommodation Details</h2>
      </IonListHeader>
      <div className='task-expand-subtitles'>
        <FormTitle>
          <h3 style={{ fontSize: '1rem' }}>Driver Accomodation</h3>
        </FormTitle>
        <p> {focussedJourney.driver_hotel} </p>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <p><span>location: </span> { focussedJourney.driver_location } </p>
          <p> {focussedJourney.driver_contact} </p>
        </IonRow>
      </div>
      <div className='task-expand-subtitles'>
        <FormTitle>
          <h3 style={{ fontSize: '1rem' }}>passengers Accomodation</h3>
        </FormTitle>
        <p> {focussedJourney.passenger_hotel} </p>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <p><span>location: </span> {focussedJourney.passenger_location} </p>
          <p> {focussedJourney.passenger_contact} </p>
        </IonRow>
      </div>
    </>
    )
  }
  </>
)

export default JourneyDetails
