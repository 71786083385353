import { IonRow, IonButton } from '@ionic/react'
import React, { useState, FC } from 'react'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { OverlayContainer } from '../../../tasks/StyledContainers'
import { SOP, TemplateType } from '../../../types/GlobalTypes'
import { CategorySelect } from '../../StyledContainers'
import { getTemplateName } from '../SOPCard'
import { useWorkspace } from '../../../../hooks/useWorkspace'
import axios from '../../../../utils/axios'
import InheritSOPs from './InheritSOPs'

interface Props {
  focusedSOP: SOP,
  onClose: () => void,
}

export const getTemplateType = (template: string) : TemplateType => {
  if (template === 'default') return TemplateType.DEFAULT
  if (template === 'archangel') return TemplateType.ORGANISATION
  return TemplateType.WORKSPACE
}

/**
 *  ================================
 *      CHANGE SELECTED  TEMPLATE
 *  ================================
 */
const ChangeTemplate : FC<Props> = ({ focusedSOP, onClose }) => {
  /* App hook */
  const [selection, setSelection] = useState<string>(focusedSOP.template_type)
  const { updateSOP, setLoading } = useIncidentsData()
  const [showInherit, setShowInherit] = useState<boolean>(false)
  const { workspace } = useWorkspace()

  const handleSubmit = (inherit: boolean) => {
    setLoading(true)
    axios.post('/api/v1/internal_incident/createTemplateSop', {
      template_type: selection,
      domain_id: workspace.id,
      sop_type: focusedSOP.type,
      inherit,
    }).then(({ data }) => {
      updateSOP({
        ...focusedSOP, template_id: data.template.id, template_type: getTemplateType(selection), chosenTemplate: undefined,
      })
    }).finally(() => {
      onClose()
      setLoading(false)
    })
  }

  const handleNext = () => {
    if (!focusedSOP.hasDomainTemplate && focusedSOP.hasArchangelTemplate && selection === 'domain') {
      setShowInherit(true)
      return
    }

    handleSubmit(false)
  }

  if (showInherit) {
    return (
      <InheritSOPs
        onCancel={onClose}
        onAccept={() => handleSubmit(true)}
        onDeny={() => handleSubmit(true)}
      />
    )
  }

  return (
    <OverlayContainer style={{ zIndex: 20 }}>
      <h4 style={{ marginBottom: '15px' }} className='red-font'>Change Template</h4>
      <CategorySelect value={selection} onChange={(e) => setSelection(e.target.value)}>
        <option value={TemplateType.DEFAULT}> { getTemplateName(TemplateType.DEFAULT) } </option>
        {
          workspace.power !== 'God' && (
            <option value={TemplateType.ORGANISATION}> { getTemplateName(TemplateType.ORGANISATION) } </option>
          )
        }
        {
          workspace.power !== 'ArchAngel' && workspace.power !== 'God' && (
            <option value={TemplateType.WORKSPACE}> { getTemplateName(TemplateType.WORKSPACE) } </option>
          )
        }
      </CategorySelect>
      {
        selection === TemplateType.DEFAULT && (
          <p style={{ margin: '40px 0' }}>
            This is the default template for the selected SOP set by the Sherpa platform. It offers suggested procedures that should be followed if the selected event occurs. It can not be edited.
          </p>
        )
      }
      {
        selection === TemplateType.ORGANISATION && (
          <>
            <p style={{ margin: '20px 0' }}>
              This is the Organisation template. Procedures are prefilled by default but can be edited or added to.
            </p>
            <p>
              The Organisation template should be used to define a response to an event that users within that Organisation will follow in the event of that incident occuring.
            </p>
          </>
        )
      }
      {
        selection === TemplateType.WORKSPACE && (
          <>
            <p style={{ margin: '20px 0' }}>
              This is the Workspace template. Procedures are prefilled by default but can be edited or added to.
            </p>
            <p>
              The Workspace template should be used to define a response to an event that users within that workspace will follow in the event of that incident occuring.
            </p>
          </>
        )
      }
      <IonRow style={{ marginTop: '20px' }} className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
        <IonButton style={{ '--background': '#4197A9' }} onClick={handleNext}>Use Template</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default ChangeTemplate
