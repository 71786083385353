import * as turf from '@turf/turf'
import { ChaptersGeoJSON, DriversGeoJSON } from '../../../components/types/GeoJSONTypes'
import { DriverUpdate, JourneyStatus } from '../../../components/types/GlobalTypes'

/* ================ CHAPTER ICONS =================== */
import * as AllGood from '../../../components/journeys/ticket-icons/all-good.png'
import * as Checkpoint from '../../../components/journeys/ticket-icons/checkpoint.png'
import * as Comment from '../../../components/journeys/ticket-icons/comment.png'
import * as Emergency from '../../../components/journeys/ticket-icons/emergency.png'
import * as RestStop from '../../../components/journeys/ticket-icons/rest-stop.png'
import * as RoadBlocked from '../../../components/journeys/ticket-icons/road-blocked.png'
import * as OrangePin from '../../../components/journeys/ticket-icons/orangePin.png'

/**
 * Update Driver's location symbol layerd
 *
 * @param state -> Previous state
 * @param update -> PubNub update message
 * @returns -> An updated version of the feature collection
 */
export const updateDriversLocation = (state: DriversGeoJSON,
  update: DriverUpdate) : DriversGeoJSON => {
  const features = state.features.slice()
  const index = features.findIndex(({ properties }) => properties.id === update.journeyId)

  if (update.status === JourneyStatus.COMPLETE || update.status === JourneyStatus.ABORTED) {
    if (index < 0) {
      return state
    }

    features.splice(index, 1)
    return { ...state, features }
  }

  if (index < 0) {
    features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: update.coordinates as turf.Position,
      },
      properties: { id: update.journeyId },
    })

    return { ...state, features }
  }

  features.splice(index, 1, { ...features[index], geometry: { coordinates: update.coordinates as turf.Position, type: 'Point' } })
  return { ...state, features }
}

export const createChaptersLayer = (mapRef: React.RefObject<mapboxgl.Map>, chaptersGeoJSON: ChaptersGeoJSON = { type: 'FeatureCollection', features: [] }) : void => {
  /* Create map source */
  mapRef.current.addSource('active-journeys-chapters', {
    type: 'geojson',
    data: {
      ...chaptersGeoJSON,
    },
  })

  mapRef.current.addSource('completed-journeys-chapters', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  const chapterIcons = [{ name: 'all_good', img: AllGood }, { name: 'checkpoint', img: Checkpoint }, { name: 'rest', img: RestStop }, { name: 'road_blocked', img: RoadBlocked },
    { name: 'emergency', img: Emergency }, { name: 'comment', img: Comment }]
  const conditionalIcons : mapboxgl.Expression = ['match', ['get', 'type'], ['all_good'], 'all_good',
    ['checkpoint'], 'checkpoint', ['rest'], 'rest', ['road_bloacked'], 'road-blocked', ['emergency'], 'emergency', ['comment'], 'comment', 'comment']
  let loadedIcons = 0

  chapterIcons.forEach(({ name, img }) => {
    const iconImage = new Image()
    iconImage.onload = () => {
      mapRef.current.addImage(name, iconImage)
      // eslint-disable-next-line no-plusplus
      if (++loadedIcons >= chapterIcons.length) {
        mapRef.current.addLayer({
          id: 'active-journeys-chapters-layer',
          type: 'symbol',
          source: 'active-journeys-chapters',
          layout: {
            // get the title name from the source's "title" property
            'icon-image': conditionalIcons,
            'icon-size': 0.15,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
        mapRef.current.addLayer({
          id: 'completed-journeys-chapters-layer',
          type: 'symbol',
          source: 'completed-journeys-chapters',
          layout: {
            // get the title name from the source's "title" property
            'icon-image': conditionalIcons,
            'icon-size': 0.15,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      }
    }

    iconImage.src = img
  })
}

export const createJourneyLayer = (mapRef: React.RefObject<mapboxgl.Map>, journeys: any[] = []) => {
  //
  mapRef.current.addSource('journeys', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: journeys,
    },
  })
  mapRef.current.addLayer({
    id: 'journeyline-active',
    type: 'line',
    source: 'journeys',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#6AB43F',
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        12, 3,
        22, 12,
      ],
    },
  })
}

export const createStartEndJourneyLayer = (mapRef: React.RefObject<mapboxgl.Map>) => {
  mapRef.current.addSource('completed-journeys-startend', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  const icon = new Image()
  icon.onload = () => {
    mapRef.current.addImage('orangePin', icon)
    mapRef.current.addLayer({
      id: 'completed-journeys-startend-layer',
      source: 'completed-journeys-startend',
      type: 'symbol',
      layout: {
        // get the title name from the source's "title" property
        'icon-image': 'orangePin',
        'icon-size': 0.15,
        'text-allow-overlap': true,
        'text-ignore-placement': true,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    })
  }
  icon.src = OrangePin
}

export const createJourneyHistoriesLayer = (mapRef: React.RefObject<mapboxgl.Map>) => {
  //
  mapRef.current.addSource('journey-histories', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  mapRef.current.addLayer({
    id: 'journey-history-layer',
    type: 'line',
    source: 'journey-histories',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ['match', ['get', 'status'], ['offline'], '#b5987f', '#FF8A00'],
      'line-width': ['match', ['get', 'status'], ['offline'], 1.5, 3],
    },
  })
}
