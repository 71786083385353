/* eslint-disable default-case */
/* eslint-disable react/no-array-index-key */
import { IonButton, IonRow, IonCol } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import { TabOption } from '../../projects/StyledContainers'
import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { IncidentCause } from '../../types/GlobalTypes'
import { useInsightsData } from '../../../hooks/insights/useInsightsData'

const AssetProfileInsights : FC = () => {
  const {
    selectedAsset, setShowSelectedAsset, users, setLoading, allAssets,
  } = useInsightsData()

  const [view, setView] = useState<String>('Home')

  const alertOptions = [
    {
      label: 'Level 1 - Caution',
      value: 1,
      definition: 'There is a low risk of possible terrorist activity or civil unrest affecting operations or personnel. Business as usual.',
    },
    {
      label: 'Level 2 - Warning',
      value: 2,
      definition: 'A general or localised threat situation, the nature and extent of which are unpredictable, and circumstances do not justify full implementation of higher alert measures. Local travel restrictions may be necessary.',
    },
    {
      label: 'Level 3 - Standfast',
      value: 3,
      definition: 'A foreseen event or situation that could impact on the security of operations. There is potential for the security situation in the area to deteriorate rapidly. Local lockdowns and / or severely restricted staff movements may be necessary. An evacuation may be required imminently.',
    },
    {
      label: 'Level 4 - Standby',
      value: 4,
      definition: 'A ‘threat to life’ incident is highly likely. Security conditions are hostile and approaching a level where operations are not possible. Critical risk to staff. Partial evacuation should be considered.',
    },
    {
      label: 'Level 5 - Evacuation',
      value: 5,
      definition: 'There is an immediate / direct threat to personnel; or armed conflict has occurred in the area. There are extreme risks to personnel and assets. Full evacuation should be considered.',
    },
  ]

  const getUser = () => {
    const user = users.find((e) => e.id === selectedAsset.user_id)
    if (user) {
      return { value: user.id, label: `${user.first_name} ${user.final_name}` }
    }
    return { value: null, label: 'N/A' }
  }

  const getParent = () => {
    const parent = allAssets.find((e) => e.id === selectedAsset.parent_id)
    if (parent) {
      return { value: parent.id, label: parent.name }
    }
    return { value: null, label: 'N/A' }
  }

  type OptionType = {
    label: string;
    value: string;
  }

  const [owner, setOwner] = useState<any>(getUser)
  const [alert, setAlert] = useState(alertOptions.find((option) => option.value === selectedAsset.alert_state))
  const [comment, setComment] = useState<any>(selectedAsset.comment)
  const [date, setDate] = useState<any>(selectedAsset.review_date)
  const [factors, setFactors] = useState<{ label: string, value: string }[]>(selectedAsset.threats.map((threat) => ({ label: threat, value: threat })))
  const [name, setName] = useState<string>(selectedAsset.name)
  const [type, setType] = useState<OptionType>({ label: selectedAsset.asset_type, value: selectedAsset.asset_type })
  const [description, setDescription] = useState<string>(selectedAsset.description)
  const [associated, setAssociated] = useState<any>(selectedAsset.associated_asset_ids)
  const [subs, setSubs] = useState<any>(selectedAsset.sub_asset_ids)
  const [parent, setParent] = useState<any>(getParent)
  const [input, setInput] = useState<any>({ existingMeasures: selectedAsset.control_measures, newMeasures: [] })
  const [content, setContent] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [selectedNote, setSelectedNote] = useState<any>()
  const [selectedFlashpoint, setSelectedFlashpoint] = useState<any>()
  const [issues, setIssues] = useState<(IncidentCause & { selected: boolean })[]>([])
  const [flashpointInput, setFlashpointInput] = useState<any>({
    title: '',
    description: '',
    triggers: '',
    relevantAreas: [],
    comments: '',
    primaryManifestation: '',
    additionalManifestations: [''],
    primaryStakeholders: [],
    secondaryStakeholders: [],
    tertiaryStakeholders: [],
    status: '',
    assets: [],
  })

  useEffect(() => {
    console.log('input:', input)
    console.log('flashpointinput:', flashpointInput)
  }, [input, flashpointInput])

  console.log(selectedAsset)

  const apiHook = useApi()

  const { workspace } = useWorkspace()

  const getAlertLabel = (value) => {
    const alertOption = alertOptions.find((option) => option.value === value)
    return alertOption ? alertOption.label : 'Unknown'
  }

  switch (view) {
    case 'Home':
      return (
        <>
          <ObscureBackground style={{ zIndex: 20 }} />
          <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '2px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', color: '#8E151F', fontWeight: 'bold', fontSize: 16,
                  }}
                  onClick={() => {
                    setView('Home')
                  }}
                >Overview
                </TabOption>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-end'>
              <SimpleButton onClick={() => { setShowSelectedAsset(false); setEditing(false) }}>Close X</SimpleButton>
            </IonRow>
              <>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>{ name }</h5>
                </IonRow>
                <div style={{ height: '70%', overflow: 'auto' }}>
                  <IonRow style={{ marginLeft: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Asset Type</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ type.label }</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Current Alert State</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{getAlertLabel(alert?.value)}</p>
                    </IonCol>
                    <IonCol />
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Parent Asset</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ parent.label }</p>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Sub Assets</h5>
                      <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                        {subs.map((sub, index) => {
                          const asset = allAssets.find((asset) => asset.id === sub.value)
                          return (
                            <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                              {asset ? `${asset.name}, ${asset.asset_type}` : 'N/A'}
                            </li>
                          )
                        })}
                      </ul>
                    </IonCol>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Associated Assets</h5>
                      <ul style={{ margin: 0, padding: 0, listStyleType: 'disc', paddingLeft: '20px' }}>
                        {associated.map((assoc, index) => {
                          const asset = allAssets.find((asset) => asset.id === assoc.value)
                          return (
                            <li key={index} style={{ fontSize: 13, color: '#737475' }}>
                              {asset ? `${asset.name}, ${asset.asset_type}` : 'N/A'}
                            </li>
                          )
                        })}
                      </ul>
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                    <IonCol>
                      <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
                      <p style={{ margin: 0, fontSize: 13, color: '#737475' }}>{ description }</p>
                    </IonCol>
                  </IonRow>
                </div>
              </>
          </OverlayContainer>
        </>
      )
  }
}

export default AssetProfileInsights
