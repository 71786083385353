/**
 *  =========================================
 *    STRING NAME FOR NUMERICAL CATEGORIES
 *  =========================================
 */

import { ValidImpactRatings } from '../../../types/GlobalTypes'
import { ImpactRating, LikelihoodRating, RiskRating } from '../../types/RiskAssessments'

export const getNumericalValue = (category: RiskRating | ImpactRating | LikelihoodRating | ImpactRating & { None: string }) : ValidImpactRatings => {
  const getter : { [type: string] : ValidImpactRatings } = {
    'Very Low': 1,
    Low: 2,
    Medium: 3,
    High: 4,
    Extreme: 5,
    Insignificant: 1,
    Minor: 2,
    Moderate: 3,
    None: 0,
    Major: 4,
    Severe: 5,
    Rare: 1,
    Unlikely: 2,
    Possible: 3,
    Likely: 4,
    'Almost Certain': 5,
  }
  return getter[category]
}

export const getCategory = (number: number) : RiskRating => ({
  1: RiskRating.VeryLow,
  2: RiskRating.Low,
  3: RiskRating.Medium,
  4: RiskRating.High,
  5: RiskRating.Extreme,
}[number])

export const getImpactCategory = (number: number) : ImpactRating => ({
  0: ImpactRating.None,
  1: ImpactRating.Insignificant,
  2: ImpactRating.Minor,
  3: ImpactRating.Moderate,
  4: ImpactRating.Major,
  5: ImpactRating.Severe,
}[number])

export const translateToBackend = (category: string) : RiskRating => ({
  Insignificant: RiskRating.VeryLow,
  Minor: RiskRating.Low,
  Moderate: RiskRating.Medium,
  Major: RiskRating.High,
  Severe: RiskRating.Extreme,
}[category])

export const getLikelihoodCategory = (val: number) : LikelihoodRating => ({
  1: LikelihoodRating.Rare,
  2: LikelihoodRating.Unlikely,
  3: LikelihoodRating.Possible,
  4: LikelihoodRating.Likely,
  5: LikelihoodRating.AlmostCertain,
}[val])

export const colorCodeRisk = (riskLevel: string) : string => ({
  'Very Low': '#00b050',
  Low: '#00b0f0',
  Medium: '#ffff00',
  High: '#ffc000',
  Extreme: '#c00000',
}[riskLevel])
