import {
  IonButton, IonList, IonListHeader, IonRow,
} from '@ionic/react'
import moment from 'moment'
import React, { FC } from 'react'
import { ZoneLog } from '../../../hooks/journeys/types/HookInterface2'
import { SettingField } from '../../settings/StyledContainers'
import { RestrictionZone } from '../../types/OptimizedMaps'
import { getCurrentLevel } from '../apps/AppMap'
import { getLevelTitle } from '../apps/SecurityConditions'
import { FormContainer } from '../forms/MapForm'
import { SimpleButton, StoryLine } from '../StyledContainers'

interface Props {
  data: RestrictionZone,
  onCancel: () => void,
  onEdit: () => void,
  logs: ZoneLog[],
  onRemove: () => void,
}

const RestrictionZoneDetails : FC<Props> = ({
  data, onCancel, onEdit, onRemove, logs,
}) => (
  <FormContainer style={{ backgroundColor: 'white' }}>
    <div style={{ height: '100%' }} className='risk-assessment-list'>
      <IonList style={{ flex: 1, overflowY: 'auto' }}>
        <IonListHeader>
          <IonRow className='ion-align-items-center ion-justify-content-between' style={{ width: '90%' }}>
            <SettingField style={{ width: 'auto', margin: 0 }}> <h4 style={{ margin: '0' }}>{ data.county_data.name } County { data.zone_type } Zone</h4> </SettingField>
            <SimpleButton
              onClick={onCancel}
            >Close X
            </SimpleButton>
          </IonRow>
        </IonListHeader>
        <div style={{ padding: '0 20px' }} className='restriction-zone-ticket'>
          <h5 style={{ fontWeight: 'bold' }}>Details</h5>
          <div className='field'>
            <h6>Owner</h6>
            <p>{ data.user.first_name } { data.user.final_name }</p>
          </div>
          <div className='field'>
            <h6>Date Created</h6>
            <p>{ moment(data.created_at).format('DD/MM/YY') }</p>
          </div>
          <div className='field'>
            <h6>Review Period</h6>
            <p>{ moment(data.created_at).add(data.check_in_days, 'days').format('DD/MM/YY') }</p>
          </div>
          <div className='field'>
            <h6>Restriction</h6>
            {
              data.controlMeasures.map(({ control_measure, id }) => (
                <p key={id}>{ control_measure }</p>
              ))
            }
            {
              data.controlMeasures.length === 0 && (
                <p>No restrictions selected</p>
              )
            }
          </div>
          <div className='field'>
            <h6>Main Threats</h6>
            {
              data.threats.map((val) => (
                <p key={val}>{ val }</p>
              ))
            }
            {
              data.threats.length === 0 && (
                <p>No Threats selected</p>
              )
            }
          </div>
          <div className='field'>
            <h6>Underlying Issues</h6>
            {
              data.underlyings.map((val) => (
                <p key={val.id}>{ val.name }</p>
              ))
            }
            {
              data.underlyings.length === 0 && (
                <p>No issues selected</p>
              )
            }
          </div>
          <div className='field'>
            <h6>County Alert State</h6>
            <IonRow>
              <p
                className='journey-status'
                style={{ backgroundColor: getCurrentLevel(data.county_data.alert_state).background, color: getCurrentLevel(data.county_data.alert_state).color }}
              >
                { `Level ${data.county_data.alert_state}: ${getCurrentLevel(data.county_data.alert_state).title}` }
              </p>
            </IonRow>
          </div>
          <div className='field'>
            <IonRow>
              <p
                className='journey-status'
                style={{ backgroundColor: getCurrentLevel(data.county_data.sc_alert).background, color: getCurrentLevel(data.county_data.sc_alert).color }}
              >
                { `Level ${data.county_data.sc_alert}: ${getLevelTitle(data.county_data.sc_alert)}` }
              </p>
            </IonRow>
          </div>
          <div className='field'>
            <h6>Comments</h6>
            <p>{ data.description }</p>
          </div>
          <div className='restriction-zone-ticket'>
            <h5 style={{ fontWeight: 'bold' }}>Zone Log</h5>
            {
              logs.length === 0 ? (
                <p>No Logs</p>
              ) : (
                <StoryLine>
                  {
                    logs.map((val) => (
                      <li key={val.id} className='status-changes field'>
                        <p><span style={{ fontWeight: 'bold' }}>{ val.description }</span>: { val.user.first_name } { val.user.final_name }</p>
                      </li>
                    ))
                  }
                </StoryLine>
              )
            }
          </div>
        </div>
      </IonList>
      <IonRow
        style={{
          alignItems: 'center', padding: '0 10px', backgroundColor: 'white', margin: '10px 0',
        }}
        class='ion-justify-content-around'
      >
        <IonButton style={{ '--background': '#8E151F' }} onClick={onRemove}>Remove</IonButton>
        <IonButton id='submit-restriction-box' style={{ '--background': '#326771' }} onClick={onEdit}>Edit</IonButton>
      </IonRow>
    </div>
  </FormContainer>
)

export default RestrictionZoneDetails
