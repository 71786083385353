import React, { useEffect } from 'react'
import {
  IonPage, IonContent,
} from '@ionic/react'
import { useHistory } from 'react-router'
import PageHeader from '../components/PageHeader'
import { ProvideAnalystApp } from '../hooks/analyst/useAnalystData'
import AnalystContent from '../components/analyst/AnalystContent'

/**
 *  =================================
 *           ANALYST APP
 *  =================================
 */
const AnalystApp = () => {
  const history = useHistory()
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (JSON.parse(localStorage.getItem('current-workspace')).analyst !== 'Analyst') history.push('/dashboard')
  }, [])
  return (
    <ProvideAnalystApp>
      <IonPage>
        <PageHeader title='Risk Assessments' />
        <IonContent
          style={{
            '--padding-top': '20px',
            '--padding-bottom': '20px',
            '--padding-start': '20px',
            '--padding-end': '20px',
          }}
        >
          <AnalystContent />
        </IonContent>
      </IonPage>
    </ProvideAnalystApp>
  )
}
export default AnalystApp
