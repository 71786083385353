import React, { useState, useEffect } from 'react'
import { IonRow } from '@ionic/react'

import SecurityChangePopup from './SecurityChangePopup'
import SecurityChangePopupPt2 from './SecurityChangePopupPt2'
import SecurityChangePopupPt3 from './SecurityChangePopupPt3'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'

import { useSecCondPopup } from '../../hooks/safety-checks/useSecCondPopup'

/**
 *  =======================
 *      FORM TO ADD TASK
 *  =======================
 *
 */

const SecPopupHandler = () => {
  const [, hide, isShowing, notificationId] = useSecCondPopup()

  const apiHook = useApi()

  const [step, setStep] = useState(0)

  const [incidentData, setIncidentData] = useState([])
  const [data, setData] = useState({})

  // use hook to get data for popups and pass them in ass props
  const { workspace } = useWorkspace()

  useEffect(() => {
    if (isShowing) {
      apiHook.securityPopup({ domain_id: workspace.id, notification_id: notificationId }).then((secData) => {
        apiHook.getIncidentsForCounty({ county: secData.county }).then((incData) => {
          setIncidentData(incData.incidents)
        })
        setData(secData)
        setStep(1)
      })
    }
  }, [isShowing])
  switch (step) {
    case 0:
      return isShowing ? (
        <IonRow />
      ) : null
    case 1:
      return isShowing ? (
        <SecurityChangePopup
          setStep={setStep}
          hide={hide}
          sec_cond={data.sec_cond}
          previous_sec_cond={data.previous_sec_cond}
          county={data.county}
          date={data.date}
          noti_id={notificationId}
          domainId={workspace.id}
        />
      ) : null
    case 2:
      return isShowing ? (
        <SecurityChangePopupPt2
          setStep={setStep}
          hide={hide}
          noti_id={notificationId}
          domainId={workspace.id}
          date={data.date}
          incidents={incidentData}
          county={data.county}
        />
      ) : null
    case 3:
      return isShowing ? (
        <SecurityChangePopupPt3
          setStep={setStep}
          hide={hide}
          noti_id={notificationId}
          domainId={workspace.id}
          county={data.county}
          assets={data.assets}
          alert={data.alert}
          date={data.date}
        />
      ) : null
    default:
      return <IonRow />
  }
}

export default SecPopupHandler
