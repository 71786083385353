import { Keyphrase, Phrasegroups } from '../../../components/types/InsightTypes'

export interface CategoryPhrases {
  name: string,
  id: number,
  keyphrases: Keyphrase[],
}
/**
 * Format the keyphrases and order them by id to allow fast retrieval
 *
 * @param keyphrases -> All keyphrases stored in the db
 * @param phrasegroups -> Phrasegroups linked to keyphrases
 * @return a list of ordered phrases by id
 */
export const processKeyphrases = (keyphrases: Keyphrase[], phrasegroups: Phrasegroups[]) : Keyphrase[] => {
  const arr = new Array(keyphrases.length)
  keyphrases.forEach((keyphrase) => {
    const category = phrasegroups.find((val) => val.id === keyphrase.phrasegroups_id).name
    arr[keyphrase.id - 1] = { ...keyphrase, category }
  })

  return arr
}
