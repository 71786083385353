import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol, IonContent, useIonAlert,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { useSituationReport } from '../../hooks/insights/useSituationReport'

//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SitRepPt6 = ({
  step, setStep, county, journeys_in_progress, journeys_scheduled,
}) => {
  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  const [, setIsShowing,, setCountyId] = useSituationReport()

  const handleClose = () => {
    setIsShowing(false)
    setCountyId(null)
    setStep(1)
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '80%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Situation Report - ${county.name} County`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonContent style={{ height: '80%', '--ion-background-color': '#FFFFFF' }}>
          <IonGrid class='ion-justify-content-center' style={{ width: '85%' }}>
            <IonList style={{ marginTop: '1em' }}>
              <IonRow>
                <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.15em' }}>Journeys in transit</IonLabel></IonCol>
              </IonRow>
            </IonList>

            <IonGrid>
              <IonRow>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Start</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>End</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Requested by</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Departure date</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Arrival date</IonLabel>
                </IonCol>
              </IonRow>

              {journeys_in_progress.length === 0 && (
                <IonRow>
                  <IonLabel style={{ marginTop: '1em', fontWeight: 'bold', fontSize: '1em' }}>There are no journeys in transit at this moment.</IonLabel>
                </IonRow>
              )}

              {journeys_in_progress.length > 0 && (
                journeys_in_progress.map(
                  (journey) => (
                    <IonRow style={{ marginLeft: '0.6em' }}>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{`${journey.departure_location}`}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{`${journey.arrival_location}`}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{`${journey.submitter}`}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{moment(journey.departure_time).format('DD/MM/YY')}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{moment(journey.arrival_time).format('DD/MM/YY')}</IonLabel>
                      </IonCol>
                    </IonRow>
                  ),
                )
              )}
            </IonGrid>

            <IonList style={{ marginTop: '4em' }}>
              <IonRow>
                <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.15em' }}>Scheduled journeys (next 7 days)</IonLabel></IonCol>
              </IonRow>
            </IonList>

            <IonGrid>
              <IonRow>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Start</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>End</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Requested by</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Departure date</IonLabel>
                </IonCol>
                <IonCol style={{
                  textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                }}
                ><IonLabel>Arrival date</IonLabel>
                </IonCol>
              </IonRow>

              {journeys_scheduled.length === 0 && (
                <IonRow>
                  <IonLabel style={{ marginTop: '1em', fontWeight: 'bold', fontSize: '1em' }}>There are no scheduled journeys at this moment.</IonLabel>
                </IonRow>
              )}

              {journeys_scheduled.length > 0 && (
                journeys_scheduled.map(
                  (journey) => (
                    <IonRow style={{ marginLeft: '0.6em' }}>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{`${journey.departure_location}`}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{`${journey.arrival_location}`}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{`${journey.submitter}`}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{moment(journey.departure_time).format('DD/MM/YY')}</IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      ><IonLabel>{moment(journey.arrival_time).format('DD/MM/YY')}</IonLabel>
                      </IonCol>
                    </IonRow>
                  ),
                )
              )}
            </IonGrid>
          </IonGrid>
        </IonContent>

        <IonRow class='ion-align-items-end' style={{ marginTop: '1em' }}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev} enabled>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default SitRepPt6
