/* eslint-disable camelcase */
/* eslint-disable import/no-webpack-loader-syntax */
import React, {
  useEffect, useState, useRef, FC, useReducer,
} from 'react'
import {
  IonButton, IonList, IonRow, useIonAlert,
} from '@ionic/react'
import moment from 'moment'
import MapForm, { FormContainer } from '../forms/MapForm'

// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'

import testHook from '../../../hooks/testHooks'
import { createMB } from '../apps/AppMap'
import FormPopup from '../forms/FormPopup'
import BoxMeasures from '../forms/BoxMeasures'
import { ObscureBackground } from '../../GlobalContainers'
import ChooseZoneType from '../forms/ChooseZoneType'
import { IncidentCause, RestrictionZoneGeoJSON, RestrictionZoneType } from '../../types/GlobalTypes'
import RestritionZoneForm from '../forms/RestrictionZoneForm'
import { TrackedUsersActionKind, trackedUsersReducer } from '../../../hooks/journeys/helpers/StateReducers'
import { useJourneysData2 } from '../../../hooks/journeys/useJourneysData2'
import RestrictionZoneTracking from '../forms/RestrictionZoneTracking'
import { getRestrictionZoneColor } from '../../../hooks/journeys/helpers/RestrictionZoneUtils'
import { ControlMeasure } from '../../riskregister/types/RiskAssessments'
import { useAuth } from '../../../hooks/useAuth'
import BoxCauses from '../forms/BoxCauses'
import BoxThreats from '../forms/BoxThreats'
import { RestrictionZone } from '../../types/OptimizedMaps'

enum ZoneView {
  TYPE_SELECTION = 'Type',
  DRAW_SHAPE = 'Shape',
  SELECT_MEASURES = 'Measures',
  SELECT_USERS = 'Users',
  SUBMITTED = 'Submitted',
}

/**
 *  =====================================
 *    Restriction Box Form and Picking
 *  =====================================
 */
const RestrictionZoneComp = ({
  mapRef, setShowMbForm, domainId, enableZoom = true,
  onClose, setLoading, controlMeasures,
}) => {
  /* Fill form when the Box has been selected */
  const [canFillForm, setCanFillForm] = useState(false)
  const [currentView, setCurrentView] = useState<ZoneView>(ZoneView.TYPE_SELECTION)
  const [zoneType, setZoneType] = useState<RestrictionZoneType>()
  /* Store coordinates */
  const [coordinates, setCoordinates] = useState([])
  const [alert] = useIonAlert()
  const [layerDrawn, setLayerDrawn] = useState(false)

  const [selectedMeasures, setSelectedMeasures] = useState<ControlMeasure[]>([])
  const [selectedCauses, setSelectedCauses] = useState<IncidentCause[]>([])
  const [selectedThreats, setSelectedThreats] = useState<string[]>([])

  const [input, setInput] = useState<{ review: number, description: string }>({ review: 1, description: '' })

  const [showMeasures, setShowMeasures] = useState<boolean>(false)
  const [showThreats, setShowThreats] = useState<boolean>(false)
  const [showUnderlyings, setShowUnderlyings] = useState<boolean>(false)

  const [trackedUsers, dispatchTrackUsers] = useReducer(trackedUsersReducer, { toggleAll: false, users: [], domainUsers: [] })
  const {
    domainUsers, pushNewZone, users, causes, incidentTypes,
  } = useJourneysData2()
  const { user } = useAuth()

  /* Restriction Box markers */
  const [markers, setMarkers] = useState([])
  const apiHook = testHook()

  const handleProceedToForm = () => {
    setCurrentView(ZoneView.SELECT_MEASURES)
  }

  const handleSelectType = (selection: RestrictionZoneType) => {
    setZoneType(selection)
    setCurrentView(ZoneView.DRAW_SHAPE)
  }

  /* Change the coordinates of the current marker */
  const setCurrentPoint = ([lon, lat], index) => {
    if (!coordinates.length) return

    /* Update location */
    coordinates[index] = [lon, lat]
    setCoordinates(coordinates)

    /* Change last markers position */
    markers[index].setLngLat([lon, lat])

    if (index === 0 && layerDrawn) coordinates[coordinates.length - 1] = [lon, lat]

    /* Update layer */
    mapRef.current.getSource('new-restriction-box').setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates,
      },
    })
  }

  /* Use reference to keep the state updated */
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  const addMarker = (lngLat) => {
    const marker = (!coordinates.length) ? new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' }).setPopup(new mapboxgl.Popup({ offset: 25 }).setText(
      'Construction on the Washington Monument began in 1848.',
    )) : new mapboxgl.Marker()

    if (!coordinates.length) {
      // eslint-disable-next-line no-underscore-dangle
      marker._element.id = 'rb-first-marker'
    }

    marker.setLngLat(lngLat)
      .addTo(mapRef.current)

    return marker
  }

  const removeMarker = () => {
    const marker = markers.pop()
    marker.remove()

    setMarkers([...markers])

    const copy = coordinates.slice()
    copy.pop()

    if (canFillForm) {
      copy.pop()
      mapRef.current.removeLayer('new-layer')
      setCanFillForm(false)
    }

    setCoordinates(copy)

    const source = mapRef.current.getSource('new-restriction-box')
    source.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: copy,
      },
    })
  }

  const drawLayer = () => {
    if (coordinates.length < 3) {
      alert({
        header: 'Error',
        message: 'You need to draw a polygon with at least 3 vertices',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    const newCoordinates = [...coordinates, coordinates[0]]

    const source = mapRef.current.getSource('new-restriction-box')
    source.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })

    setCoordinates(newCoordinates)

    mapRef.current.addLayer({
      id: 'new-layer',
      type: 'fill',
      source: 'new-restriction-box',
      layout: {},
      paint: {
        'fill-color': getRestrictionZoneColor(zoneType), // blue color fill
        'fill-opacity': 0.5,
      },
    })

    setLayerDrawn(true)

    /* Can procede filling the form */
    setCanFillForm(true)
  }

  /* Use of reference to keep the state updated */
  const drawLayerRef = useRef(drawLayer)
  drawLayerRef.current = drawLayer

  const handleClick = (e) => {
    if (currentView !== ZoneView.DRAW_SHAPE || canFillForm) return

    if (enableZoom) { mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() }) }
    /* Add coordinates to the source */
    const marker = addMarker(Object.values(e.lngLat))

    /* Set draggable for the current marker */
    marker.setDraggable(true)

    /* Manage event */
    marker.on('drag', () => {
      setCurrentPointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], coordinates.length)
    })

    /* If it is the first marker in buffer add onclick to finish the polygon */
    if (!coordinates.length) {
      marker.getElement().onclick = (event) => {
        event.stopPropagation()
        drawLayerRef.current()
      }
    }

    /* Add marker to buffer */
    setMarkers([...markers, marker])

    const newCoordinates = [...coordinates, Object.values(e.lngLat)]
    setCoordinates(newCoordinates)

    const source = mapRef.current.getSource('new-restriction-box')

    source.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })
  }
  /* Use of reference to keep the state updated */
  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  const hideForm = () => {
    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })

    /* Reset coordinates and create new source */
    setCoordinates([])
    setMarkers([])
    setCanFillForm(false)

    setShowMbForm(false)

    if (onClose) onClose()
  }

  const handleSubmitMb = (isTracking: boolean) => {
    if (!layerDrawn) {
      alert({
        header: 'Error',
        message: 'You need to draw a polygon with at least 3 vertices on the map',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    const newFeature = {
      type: 'Feature',
      geometry: {
        coordinates,
        type: 'LineString',
      },
    }

    const usersToTrack = (isTracking) ? [...trackedUsers.domainUsers.filter(({ checked }) => checked).map(({ id }) => id),
      ...trackedUsers.users.filter(({ checked }) => checked).map(({ id }) => id)] : []

    setLoading(true)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    apiHook.addBox(false, false,
      false, false,
      false, false, false,
      false, JSON.stringify(newFeature), moment().format('DD/MM/YY HH:mm'),
      moment().format('DD/MM/YY HH:mm'), input.review, input.description,
      domainId, selectedMeasures.map(({ id }) => id), zoneType, usersToTrack,
      selectedThreats, selectedCauses.map(({ id }) => id)).then(({
      mb_id, message, county_data,
    }) => {
      if (mb_id < 0) {
        alert({
          header: 'Server Error',
          message,
          buttons: [
            { text: 'Ok' },
          ],
        })
        setShowMbForm(false)
      } else {
        const feature : RestrictionZoneGeoJSON = {
          ...newFeature,
          type: 'Feature',
          geometry: { ...newFeature.geometry, type: 'LineString' },
          properties: { id: mb_id, type: getRestrictionZoneColor(zoneType) },
        }
        const zone: RestrictionZone = {
          id: mb_id,
          geoData: JSON.stringify(feature),
          zone_threats: selectedThreats.map((val) => ({ threat: val })),
          zone_underlyings: selectedCauses.map(({ id }) => ({ cause_id: id, created_at: moment().format() })),
          box_measures: selectedMeasures.map(({ id }) => ({ control_measure_id: id, created_at: moment().format() })),
          geodata: feature,
          created_at: moment().format(),
          controlMeasures: selectedMeasures,
          county_data,
          user: {
            ...user,
            id: user.user_id,
          },
          zone_type: zoneType,
          description: input.description,
          users: trackedUsers.users,
          check_in_days: input.review,
          threats: selectedThreats,
          underlyings: selectedCauses,
        }
        markers.map((marker) => marker.remove())
        pushNewZone(zone)
      }

      /* Show movement boxes */
      setCurrentView(ZoneView.SUBMITTED)
      setLoading(false)
    })
  }

  /* Remove markers in buffer */
  const handleCancel = (typed: boolean) => {
    const close = () => {
      /* Remove every marker */
      markers.map((marker) => marker.remove())

      mapRef.current.easeTo({
        padding: {
          right: 0,
        },
      })

      setShowMbForm(false)
      if (onClose) onClose()
    }

    if (typed) {
      alert({
        header: 'Cancel Restriction Box?',
        message: 'Do you wish to continue? Your progress will be lost. ',
        buttons: [
          'Back',
          {
            text: 'Yes, continue',
            handler: close,
          },
        ],
      })

      return
    }
    close()
  }

  const handleNextUsers = () => {
    setCurrentView(ZoneView.SELECT_USERS)
  }

  useEffect(() => {
    createMB(mapRef, [])

    const onClick = (e) => {
      handleClickRef.current(e)
    }
    mapRef.current.on('click', onClick)
    return () => {
      mapRef.current.off('click', onClick)
      mapRef.current.removeLayer('new-restriction-box')
      if (mapRef.current.getLayer('new-layer')) mapRef.current.removeLayer('new-layer')
      mapRef.current.removeSource('new-restriction-box')
    }
  }, [])

  useEffect(() => {
    dispatchTrackUsers({ type: TrackedUsersActionKind.POPULATE_LIST, domainUsers, allUsers: users })
  }, [])

  if (currentView === ZoneView.TYPE_SELECTION) {
    return (
      <ChooseZoneType
        onCancel={() => setShowMbForm(null)}
        onSubmit={handleSelectType}
      />
    )
  }

  if (currentView === ZoneView.DRAW_SHAPE) {
    return (
      <BoxInstructions
        onCancel={() => handleCancel(markers.length !== 0)}
        onNext={handleProceedToForm}
        canProceed={canFillForm}
        canUndo={markers.length > 0}
        undoPin={removeMarker}
      />
    )
  }

  if (currentView === ZoneView.SELECT_MEASURES) {
    return (
      <>
        <RestritionZoneForm
          selectedMeasures={selectedMeasures}
          onCancel={() => handleCancel(true)}
          editing
          onShowRestrictions={() => setShowMeasures(true)}
          handleChange={(label, value) => setInput({ ...input, [label]: value })}
          input={input}
          canSubmit={zoneType === RestrictionZoneType.AMBER}
          onNext={(zoneType === RestrictionZoneType.AMBER) ? () => handleSubmitMb(false) : handleNextUsers}
          selectedThreats={selectedThreats}
          selectedUnderlying={selectedCauses}
          onShowThreats={() => setShowThreats(true)}
          onShowUnderlying={() => setShowUnderlyings(true)}
        />
        {
          showMeasures && (
            <>
              <ObscureBackground style={{ zIndex: 19 }} />
              <BoxMeasures
                allMeasures={controlMeasures}
                selectedMeasures={selectedMeasures}
                onClose={() => setShowMeasures(false)}
                onFinish={(measures) => {
                  setSelectedMeasures(measures)
                  setShowMeasures(false)
                }}
              />
            </>
          )
        }
        {
          showThreats && (
            <>
              <ObscureBackground style={{ zIndex: 19 }} />
              <BoxThreats
                allTypes={incidentTypes}
                onFinish={(selected) => {
                  setSelectedThreats(selected)
                  setShowThreats(false)
                }}
                selectedThreats={selectedThreats}
                onClose={() => setShowThreats(false)}
              />
            </>
          )
        }
        {
          showUnderlyings && (
            <>
              <ObscureBackground style={{ zIndex: 19 }} />
              <BoxCauses
                allCauses={causes}
                onClose={() => setShowUnderlyings(false)}
                onFinish={(selected) => {
                  setSelectedCauses(selected)
                  setShowUnderlyings(false)
                }}
                selectedCauses={selectedCauses}
              />
            </>
          )
        }
      </>
    )
  }

  if (currentView === ZoneView.SELECT_USERS) {
    return (
      <RestrictionZoneTracking
        trackUsers={trackedUsers}
        dispatchTrackUsers={dispatchTrackUsers}
        onClose={() => handleCancel(true)}
        onSubmit={() => handleSubmitMb(true)}
        onBack={(zoneType === RestrictionZoneType.GREEN || zoneType === RestrictionZoneType.RED) ? () => setCurrentView(ZoneView.SELECT_MEASURES) : () => setCurrentView(ZoneView.DRAW_SHAPE)}
      />
    )
  }

  return (
    <MapForm>
      <IonList style={{ padding: '20px', height: '100%' }}>
        <FormPopup
          message='You have successfully created a Restriction Box.'
          onClose={hideForm}
        />
      </IonList>
    </MapForm>
  )
}

export default RestrictionZoneComp

interface InstructionsProps {
  undoPin: () => void,
  onCancel: () => void,
  onNext: () => void,
  canProceed: boolean,
  canUndo: boolean,
}
const BoxInstructions: FC<InstructionsProps> = ({
  undoPin, onCancel, onNext, canProceed, canUndo,
}) => (
  <FormContainer style={{ backgroundColor: 'white' }}>
    <div className='risk-assessment-list' style={{ padding: '20px' }}>
      <div>
        <h2 style={{ color: '#326771' }}>Add Restriction Zone</h2>
        <div style={{ marginTop: '20px', padding: '0 10px' }}>
          <p>Click on the map to drop a pin in the area you wish to create a Restriction Zone.</p>
          <p>Draw a shape around the area you wish to restrict by clicking the map and dropping pins.</p>
          <p>Note: To close your Restriction Zone, click on the first pin that you dropped.</p>
        </div>
      </div>
      <IonRow className='ion-justify-content-between'>
        <IonButton style={{ '--background': '#4197A9' }} onClick={undoPin} disabled={!canUndo}>Undo Pin</IonButton>
        <IonRow>
          <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel()}>Cancel</IonButton>
          <IonButton id='next-restriction-box' style={{ '--background': '#0C9500' }} onClick={onNext} disabled={!canProceed}>Next</IonButton>
        </IonRow>
      </IonRow>
    </div>
  </FormContainer>
)
