import React, { useEffect, useState } from 'react'
import {
  IonLabel, IonCheckbox, IonRow, IonCol,
} from '@ionic/react'
import { Dropdown } from 'react-bootstrap'
import criminality from '../incidents/icons/criminality.png'
import socialUnrest from '../incidents/icons/social_unrest.png'
import travel from '../incidents/icons/travel.png'
import violentConflict from '../incidents/icons/violent_conflict.png'
import violentCrime from '../incidents/icons/violent_crime.png'

/**
 *  ===============================
 *      DROPDOWN INCIDENT FILTER
 *  ===============================
 */
const IncidentsFilter = ({
  update, incidentFilters, toggle, useIcons,
}) => {
  /* Keep track of the toggle all checkbox for this filter */
  const [toggleAll, setToggleAll] = useState(toggle)
  const icons = {
    'Violent Conflict': violentConflict, 'Violent Crime': violentCrime, Criminality: criminality, 'Social Unrest': socialUnrest, Travel: travel, other: criminality,
  }

  /* Toggle all checkbox listener */
  const handleToggleAll = (e) => {
    if (e.stopPropagation) e.stopPropagation()

    /* Set all checkboxes to true and call for update */
    setToggleAll(e.target.checked)
    const newFilters = Object.fromEntries(Object.keys(incidentFilters).map((key) => [key, e.target.checked]))
    update(newFilters)
  }

  /* Handle change for every other checkbox */
  const handleChange = (e) => {
    if (e.stopPropagation) e.stopPropagation()
    if (!e.target.checked) setToggleAll(false)

    /* Update the incident filter's state and call for update */
    const tmpFilters = { ...incidentFilters, [e.target.name]: e.target.checked }

    /* If all checkboxes are clicked then update toggle all */
    if (Object.values(tmpFilters).findIndex((param) => !param) < 0) setToggleAll(true)

    update(tmpFilters)
  }

  useEffect(() => {
    setToggleAll(toggle)
  }, [toggle])

  return (

    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onKeyDown={() => {}}>
      <Dropdown.Item onClick={() => handleToggleAll({ target: { checked: !toggleAll } })}>
        <IonRow>
          <IonCol size={10}>
            <IonLabel style={{ padding: '0 10px' }}>Toggle all</IonLabel>
          </IonCol>
          <IonCol size={2}>
            <IonCheckbox id='toggle-all-incidents' checked={toggleAll} onClick={handleToggleAll} />
          </IonCol>
        </IonRow>
      </Dropdown.Item>
      {
        Object.keys(incidentFilters).map((key) => (
          <Dropdown.Item onClick={() => handleChange({ target: { checked: !incidentFilters[key], name: key } })} key={key}>
            <IonRow>
              {
                (useIcons) ? (
                  <>
                    <IonCol size={2}>
                      <img style={{ width: '20px' }} src={icons[key]} alt={key} />
                    </IonCol>
                    <IonCol size={8}>
                      <IonLabel style={{ padding: '0 10px' }}> {key} </IonLabel>
                    </IonCol>
                  </>
                ) : (
                  <IonCol size={10}>
                    <IonLabel style={{ padding: '0 10px' }}> {key} </IonLabel>
                  </IonCol>
                )
              }
              <IonCol size={2}>
                <IonCheckbox id={key.split(' ').join('-')} name={key} checked={incidentFilters[key]} onClick={handleChange} />
              </IonCol>
            </IonRow>
          </Dropdown.Item>
        ))
      }
    </div>
  )
}

export default IncidentsFilter
