import React from 'react'
import {
  IonHeader, IonRow, IonButtons, IonMenuButton, IonToolbar, IonCol,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { MobileButton } from './StyledMobile'

/**
 *  ================================
 *      PAGE HEADER FOR MOBILE
 *  ================================
 */
const MobileHeader = ({ className = '' }) => (
  <IonHeader className={`mobile-header ${className}`}>
    <IonRow className='ion-justify-content-between'>
      <IonCol>
        <IonToolbar style={{ '--background': '#8E151F' }}>
          <IonButtons slot='start'>
            <IonMenuButton style={{ '--color': 'white' }} data-testid='main-menu-button' />
          </IonButtons>
        </IonToolbar>
      </IonCol>
      <IonCol style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IonRow>
          <MobileButton><Icon icon='bx:bx-message' /></MobileButton>
          <MobileButton><Icon icon='akar-icons:bell' /></MobileButton>
        </IonRow>
      </IonCol>
    </IonRow>
  </IonHeader>
)

export default MobileHeader
