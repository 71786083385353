import React, {
  createContext, useContext, FC, ReactNode, useState,
} from 'react'
import {
  Community, Flashpoint, InternalProject, RecipientList, Stakeholder, StakeholderGroup, EngagementCampaign, StakeholderChat, StakeholderMessage,
} from '../../components/types/OptimizedMaps'
import { useWorkspace } from '../useWorkspace'
import { AppView, HookInterface } from './types/HookInterface'
import axios from '../../utils/axios'
import { processFlashpoints } from '../terrain-mapping/helpers/Processors'
import { updateCampaignRecipients } from './utils/Utils'

export const engagementContext = createContext({} as HookInterface)
export const useEngagementData = () => useContext(engagementContext)

const useProvideEngagementData = () : HookInterface => {
  const [view, setView] = useState<AppView>(AppView.HOME)
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([])
  const [communities, setCommunities] = useState<Community[]>([])
  const [flashpoints, setFlashpoints] = useState<Flashpoint[]>([])
  const [projects, setProjects] = useState<InternalProject[]>([])
  const [stakeholderGroups, setStakeholderGroups] = useState<StakeholderGroup[]>([])
  const [campaigns, setCampaigns] = useState<EngagementCampaign[]>([])
  const [stakeholderDMs, setStakeholderDMs] = useState<StakeholderChat[]>([])
  const [recipientLists, setRecipientLists] = useState<RecipientList[]>([])

  const [loading, setLoading] = useState<boolean>(true)
  const [submittedMessage, setSubmittedMessage] = useState<string | null>(null)
  const { workspace } = useWorkspace()

  const fetchAppData = () => {
    Promise.all([
      axios.post('/api/v2/flashpoint/get_flashpoints', { domain_id: workspace.id }),
      axios.post('/api/v2/community/get_communities', { domain_id: workspace.id }),
      axios.post('/api/v2/stakeholder/get_stakeholders', { domain_id: workspace.id }),
      axios.post('/api/v2/internal_project/get_projects', { domain_id: workspace.id, archived: false }),
      axios.get('/api/v2/stakeholder/get_groups'),
      axios.post('/api/v2/campaign/get_campaigns', { domain_id: workspace.id }),
      axios.post('/api/v2/campaign/get_lists', { domain_id: workspace.id }),
      axios.post('/api/v2/campaign/getLatestDMs', { domain_id: workspace.id }),
    ]).then(([dbFlashpoints, dbCommunities, dbStakeholders, dbProjects, dbGroups, dbCampaigns, dbRecipientLists, dbLatestDMs]) => {
      /* Store all data in frontend states */
      setStakeholders(dbStakeholders.data.stakeholders)
      setProjects(dbProjects.data.projects)
      setCampaigns(dbCampaigns.data.campaigns)
      setFlashpoints(processFlashpoints(dbFlashpoints.data.flashpoints))
      setRecipientLists(dbRecipientLists.data.lists)
      setStakeholderGroups(dbGroups.data.groups)
      setCommunities(dbCommunities.data.communities)
      setStakeholderDMs(dbLatestDMs.data.latest_messages)
    }).finally(() => {
      setLoading(false)
    })
  }
//
  const pushNewStakeholder = (newStakeholder: Stakeholder) => {
    const copy = stakeholders.slice()
    copy.push(newStakeholder)

    setStakeholders(copy)
  }

  const pushNewStakeholderDM = (newChat: StakeholderChat) => {
    setStakeholderDMs([...stakeholderDMs, newChat])
  }

  const updateRoom = (room: StakeholderChat) => {
    const index = stakeholderDMs.findIndex(({ id }) => id === room.id)
    if (index < 0) { return }
    const copy = stakeholderDMs.slice()
    copy.splice(index, 1, { ...room })

    setStakeholderDMs(copy)
  }

  const pushLatestMessage = (message: StakeholderMessage, room: StakeholderChat) => {
    updateRoom({ ...room, latestMessage: message })
  }

  const pushNewCampaign = (campaign: EngagementCampaign) => {
    setCampaigns([...campaigns, campaign])
  }

  const pushNewRecipientLists = (lists: RecipientList[]) => {
    setRecipientLists([...recipientLists, ...lists])
  }

  const updateStakeholder = (stakeholder: Stakeholder) : Stakeholder[] => {
    const copy = stakeholders.slice()
    const index = copy.findIndex(({ id }) => id === stakeholder.id)

    if (index < 0) { return copy }
    copy.splice(index, 1, { ...stakeholder })
    setStakeholders(copy)

    return copy
  }

  const updateFlashpoint = (flashpoint: Flashpoint) => {
    const copy = flashpoints.slice()
    const index = copy.findIndex(({ id }) => id === flashpoint.id)

    if (index < 0) { return }

    copy.splice(index, 1, flashpoint)
    setFlashpoints(copy)
  }

  const updateProject = (project: InternalProject) => {
    const copy = projects.slice()
    const index = copy.findIndex(({ id }) => id === project.id)

    if (index < 0) { return }

    copy.splice(index, 1, project)
    setProjects(copy)
  }

  const deleteCampaign = (campaign: EngagementCampaign) => {
    setCampaigns(campaigns.filter(({ id }) => id !== campaign.id))
  }

  const updateRecipientList = (edited: RecipientList) => {
    const copy = recipientLists.slice()
    const index = copy.findIndex(({ id }) => id === edited.id)

    const updatedCampaigns = updateCampaignRecipients(campaigns, edited)
    setCampaigns(updatedCampaigns)

    if (index < 0) { return }

    copy.splice(index, 1, { ...edited })
    setRecipientLists(copy)
  }

  const deleteRecipientList = (list: RecipientList) => {
    const copy = recipientLists.filter(({ id }) => id !== list.id)
    setRecipientLists(copy)
  }

  const handleViewChange = (newView: AppView) => {
    setView(newView)
  }

  return {
    view,
    handleViewChange,
    stakeholders,
    communities,
    flashpoints,
    fetchAppData,
    updateStakeholder,
    loading,
    setLoading,
    submittedMessage,
    setSubmittedMessage,
    projects,
    updateFlashpoint,
    updateProject,
    stakeholderGroups,
    pushNewStakeholder,
    campaigns,
    pushNewCampaign,
    pushNewRecipientLists,
    recipientLists,
    updateRecipientList,
    deleteRecipientList,
    deleteCampaign,
    stakeholderDMs,
    pushNewStakeholderDM,
    pushLatestMessage,
  }
}

export const ProvideEngagementData : FC<{ children: ReactNode }> = ({ children }) => {
  const data = useProvideEngagementData()

  return (
    <engagementContext.Provider value={data}>
      {
        children
      }
    </engagementContext.Provider>
  )
}
