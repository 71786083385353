/* eslint-disable no-return-assign */
import * as moment from 'moment'
import * as turf from '@turf/turf'
import { AssetInput } from './AddAssetReducers'
import useApi from '../../testHooks'
import { Asset, AssetList } from '../../../components/riskregister/types/Assets'
import { GeoLocation } from '../../../components/types/GlobalTypes'
import { Filters } from './StateReducers'
import { RiskAssessment } from '../../../components/riskregister/types/RiskAssessments'
import { ProcessedMeasures } from '../types/BackendData'

const apiHook = useApi()

export interface AddAssetBackend {
  domain_id: number,
  name: string,
  longitude: number,
  latitude: number,
  description: string,
  asset_type: string,
  county_id: number,
  geodata: any,
  marker_type: string,
}

/* Returns the data in a format that the backend can process */
export const formatAssetCreationData = (input: AssetInput, workspaceId, feature, marker_type) : AddAssetBackend => ({
  domain_id: workspaceId,
  name: input.name,
  longitude: input.coordinates.lng,
  latitude: input.coordinates.lat,
  description: '',
  asset_type: input.type,
  county_id: input.county_id,
  geodata: feature,
  marker_type,
})

export interface CreateAssetResponse {
  message: string,
  asset_id: number
}

export const formatNewAsset = (input: AssetInput, id: number) : Asset => ({
  id,
  name: input.name,
  type: input.type,
  coordinates: input.coordinates,
  location: input.location,
  highestRisk: 'Unknown',
  updated_at: moment().format('DD/MM/YY'),
  alert_state: 2,
  county_id: input.county_id,
  risks: [],
  longitude: input.longitude,
  latitude: input.latitude,
  geodata: input.geodata,
  asset_type: input.asset_type,
  description: input.description,
  marker_type: input.marker_type,
})

export const createAsset = async (input: AssetInput, workspaceId: number, feature: any, marker_type: string) : Promise<CreateAssetResponse> => {
  const formattedData : AddAssetBackend = formatAssetCreationData(input, workspaceId, feature, marker_type)
  return apiHook.createAsset(formattedData).then((response : CreateAssetResponse) => Promise.resolve(response))
}

/**
 *
 * @param distance {number} => Distance between two points in the map (Represented in Kilometers)
 * @param zoom {number} => Map zoom
 * @returns Whether the two points are identified as close
 */
export const checkColocationDistance = (distance: number, zoom: number) : boolean => {
  if (zoom <= 5 && distance <= 30) {
    return true
  }
  if (zoom <= 7 && distance <= 15) {
    return true
  }
  if (zoom <= 10 && distance <= 5) {
    return true
  }
  if (zoom > 10 && distance <= 2) {
    return true
  }
  return false
}

export const formatColocatedLocation = (colocatedLoc: string) : GeoLocation => ({ lng: Number(colocatedLoc.split(',')[0]), lat: Number((colocatedLoc.split(',')[1])) })

/** */
export const getAssetWithinRadius = (assets: AssetList, newLocation: GeoLocation, zoomLevel: number) : { coordinates: GeoLocation, assetNames: string[] } | null => {
  /* List of all asset locations */
  const locations : turf.helpers.FeatureCollection<turf.helpers.Point> = turf.featureCollection<turf.helpers.Point>(Object.keys(assets).map((locationKey) => {
    const geoLoc : GeoLocation = formatColocatedLocation(locationKey)
    return turf.point([geoLoc.lng, geoLoc.lat])
  }))
  const targetPoint = turf.point([newLocation.lng, newLocation.lat])

  if (locations.features.length <= 0) {
    return null
  }
  /* Calculate nearest location within asset list  */
  const nearest = turf.nearestPoint(targetPoint, locations)

  if (checkColocationDistance(turf.distance(nearest, targetPoint, { units: 'kilometers' }), zoomLevel)) {
    const { coordinates } = nearest.geometry
    const assetNames = assets[`${coordinates[0]},${coordinates[1]}`].assets.map((asset) => asset.name)
    return { coordinates: { lng: coordinates[0], lat: coordinates[1] }, assetNames }
  }

  return null
}

/**
 *  Returns a new list of assets with the filters applied
 *
 * @param assets -> List of all assets
 * @param filters -> New filters to be applied
 * @returns new list of filtered assets
 */
export const filterAssets = (assets: AssetList, filters: Filters) : AssetList => {
  const filteredAssets = Object.keys(assets).filter((assetLoc) => {
    const firstAsset = assets[assetLoc].assets[0]
    if (firstAsset && firstAsset.location.state) {
      return filters.states[firstAsset.location.state]
    }
    return false
  }).reduce((res, key) => ((res[key] = assets[key], res)), {})
  return filteredAssets
}

/**
 * Remove colocated asset from old position
 *
 * @param assets -> List of all assets
 * @param asset -> Asset that is to be edited/removed
 */
export const removeOldAssetFromLocation = (assets: AssetList, asset: Asset) : AssetList => {
  const updatedAssets = { ...assets }
  const coordinateKey = `${asset.coordinates.lng},${asset.coordinates.lat}`

  if (updatedAssets[coordinateKey].assets.length > 1) {
    const assetList = [...updatedAssets[coordinateKey].assets].filter((val: Asset) => val.id !== asset.id)
    return { ...updatedAssets, [coordinateKey]: { ...updatedAssets[coordinateKey], assets: assetList } }
  }

  delete updatedAssets[coordinateKey]
  return updatedAssets
}

/**
 * Add new asset to list of assets
 *
 * @param assetList -> List of all assets
 * @param asset -> Asset to be added to data structure
 * @returns
 */
export const pushNewAsset = (assetList: AssetList, asset: Asset) : AssetList => {
  /* Define coordinate key for the assetList location */
  const coordinateKey = `${asset.coordinates.lng},${asset.coordinates.lat}`
  if (assetList[coordinateKey]) {
    const newList : Asset[] = [...assetList[coordinateKey].assets, asset]
    const updatedAssets : AssetList = { ...assetList, [coordinateKey]: { ...assetList[coordinateKey], assets: newList } }
    return updatedAssets
  }

  /* If asset is added to new location then create key */
  const assetCopy = { ...assetList }
  assetCopy[coordinateKey] = { assets: [asset] }
  return assetCopy
}

/**
 * Edit an asset's location or data in the map
 *
 * @param assets -> List of all assets
 * @param asset -> Edited asst
 * @param newLocation -> New location of the asset that's being edited
 * @returns -> A new asset list with the edited asset added
 */
export const editAsset = (assets: AssetList, asset: Asset, newLocation: GeoLocation) : AssetList => {
  const updatedAssets = removeOldAssetFromLocation(assets, asset)
  const newAsset : Asset = { ...asset, coordinates: newLocation }

  const finalAssets = pushNewAsset(updatedAssets, newAsset)
  return finalAssets
}

/**
 * Remove assets from hook data and return ids for backend request
 *
 * @param assets -> List of assets in colocated format
 * @param assetsToRemove -> List of assets to remove
 * @returns a new list of assets in colocated format and an array of ids of removed assets
 */
export const removeListOfAssets = (assets: AssetList, assetsToRemove: Asset[]) : { assetList: AssetList, ids: number[] } => {
  let updated: AssetList = { ...assets }
  const ids: number[] = []

  assetsToRemove.forEach((val: Asset) => {
    updated = removeOldAssetFromLocation(updated, val)
    ids.push(val.id)
  })

  return { assetList: updated, ids }
}

/**
 *
 * @param assets -> List of colocated assets
 * @param asset -> Asset to be updated
 * @param risks -> Updated list of risks
 * @returns
 */
const updateRisks = (assets: AssetList, asset: Asset, risks: RiskAssessment[]) : { updatedAsset: Asset, assetList: AssetList } => {
  const coordinateKey = `${asset.coordinates.lng},${asset.coordinates.lat}`
  const assetsCopy = assets[coordinateKey].assets.slice()
  const assetIndex = assetsCopy.findIndex(({ id }) => id === asset.id)

  const updatedAsset = { ...assetsCopy[assetIndex], risks }
  assetsCopy.splice(assetIndex, 1, updatedAsset)

  return { updatedAsset, assetList: { ...assets, [coordinateKey]: { ...assets[coordinateKey], assets: assetsCopy } } }
}

/**
 * Add a new risk assessment to asset
 *
 * @param assets -> list of colocated assets
 * @param asset -> Focused Asset
 * @param risk -> New Risk to be added
 * @returns an updated version of the list of assets and the focused asset
 */
export const pushNewRiskAssessment = (assets: AssetList, asset: Asset, risk: RiskAssessment) : { updatedAsset: Asset, assetList: AssetList } => {
  /* update list of risk assessments */
  const risksCopy = (asset.risks as RiskAssessment[]).slice()
  risksCopy.push(risk)

  return updateRisks(assets, asset, risksCopy)
}

/**
 * Update risk assessment of a given  asset
 *
 * @param assets -> List of colocated assets
 * @param asset -> Focused Asset
 * @param risk -> Updated risk assessment
 * @returns An updated version of the focused asset and list of assets
 */
export const updateRiskAssessment = (assets: AssetList, asset: Asset, risk: RiskAssessment) : { updatedAsset: Asset, assetList: AssetList } => {
  /* update list of risk assessments */
  const risksCopy = (asset.risks as RiskAssessment[]).slice()
  const index = risksCopy.findIndex(({ id }) => id === risk.id)
  risksCopy.splice(index, 1, risk)

  return updateRisks(assets, asset, risksCopy)
}

/**
 *  Add risk assessments to asset
 *
 * @param assets -> List of colocated assets
 * @param risks -> Risk assessments to be added to asset
 * @returns Updated colocated asset list and updated asset separately
 */
export const addAssessments = (assets: AssetList, risks: RiskAssessment[], asset: Asset) : { assetList: AssetList, updatedAsset: Asset } => {
  /* Updated array of assets */
  const { assetList, updatedAsset } = updateRisks(assets, asset, risks)
  return { assetList, updatedAsset }
}

/**
 *  Remove subset of risk assessments from asset
 *
 * @param assets -> List of colocated assets
 * @param asset -> ASset to be pdated
 * @param ids -> Risk assessment ids to be removed
 * @returns An updated list of colocated asset and the updated asset itself
 */
export const removeAssessments = (assets: AssetList, asset: Asset, ids: number[]) : { assetList: AssetList, updatedAsset: Asset } => {
  if (!asset.risks) return { assetList: { ...assets }, updatedAsset: { ...asset } }

  /* Remove risk assessments with the ids provided */
  const updatedRisks = asset.risks.filter(({ id }) => ids.indexOf(id) < 0)
  const { assetList, updatedAsset } = updateRisks(assets, asset, updatedRisks)

  return { assetList, updatedAsset }
}

/**
 * Add fetched control measures to the app
 *
 * @param assets -> List of all assets
 * @param asset -> Focused asset
 * @param risk -> Focused risk
 * @param measures -> Control measures added to the risk
 * @returns Updated versions of the asset list, focused asset and risk with the control measures added
 */
export const addMitigations = (
  assets: AssetList,
  asset: Asset,
  risk: RiskAssessment,
  measures: ProcessedMeasures,
) : { assetList: AssetList, updatedAsset: Asset, updatedRisk: RiskAssessment } => {
  /* Start from the inside elements -> Update risk, then asset then list of assets */
  const updatedRisk : RiskAssessment = { ...risk, ...measures }
  const riskIndex = asset.risks.findIndex(({ id }) => risk.id === id)

  const risksCopy = asset.risks.slice()
  risksCopy.splice(riskIndex, 1, updatedRisk)

  const { updatedAsset, assetList } = updateRisks(assets, asset, risksCopy)

  return { assetList, updatedAsset, updatedRisk }
}
