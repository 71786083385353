import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as mapboxgl from 'mapbox-gl'
import { Feature, LineString, Point, Polygon, featureCollection, point } from '@turf/turf'
import { zoomThreshold } from '../../../components/maps/apps/AppMap'
import { Incident, SafetyUser } from '../../../components/types/OptimizedMaps'
import { incidentCategories } from '../../../components/maps/apps/incident_types.json'
import {
  CountiesGeoJSON, CountyProperties, LinePOIFeatures, PolygonFeatures, RegionsGeoJSON,
} from '../../../components/types/GeoJSONTypes'

/* ================== KEY ASSET ICONS ===================== */
import * as Operations from '../../../components/insights/asset-icons/operations.png'
import * as People from '../../../components/insights/asset-icons/people.png'
import * as Facilities from '../../../components/insights/asset-icons/facilities.png'
import * as Equipment from '../../../components/insights/asset-icons/equipment.png'
import * as Product from '../../../components/insights/asset-icons/product.png'
import * as Reputation from '../../../components/insights/asset-icons/reputation.png'
import * as Project from '../../../components/insights/asset-icons/project.png'
import * as Default from '../../../components/insights/asset-icons/pin.png'
import { Asset } from '../../../components/riskregister/types/Assets'
import IncidentPopup from '../../../components/insights/popups/IncidentPopup'
import AssetPopup from '../../../components/insights/popups/AssetPopup'
import CountyPopup from '../../../components/insights/popups/CountyPopup'

/* ================== SAFETY CHECK ICONS =================== */
import * as GreenCheck from '../../../components/insights/safety-checks/icons/green-icon.png'
import * as AmberCheck from '../../../components/insights/safety-checks/icons/amber-icon.png'
import * as RedCheck from '../../../components/insights/safety-checks/icons/red-icon.png'
import SafetyCheckPopup from '../../../components/insights/popups/SafetyCheckPopup'
import DriverCar from '../../../components/journeys/route-icons/car.png'

import * as RoadTravel from '../../../components/journeys/route-icons/Road.png'
import * as OffRoadTravel from '../../../components/journeys/route-icons/Off-Road.png'
import * as WaterTravel from '../../../components/journeys/route-icons/Water.png'

import highlyNegative from '../../../components/maps/apps/icons/highlyNegative.png'
import somewhatNegative from '../../../components/maps/apps/icons/somewhatNegative.png'
import neutral from '../../../components/maps/apps/icons/Neutral.png'
import somewhatPositive from '../../../components/maps/apps/icons/somewhatPositive.png'
import highlyPositive from '../../../components/maps/apps/icons/highlyPositive.png'

const pulsepointIcons = [{ name: 'Pulsepoint-Red', img: highlyNegative }, { name: 'Pulsepoint-Amber', img: somewhatNegative },
  { name: 'Pulsepoint-Yellow', img: neutral }, { name: 'Pulsepoint-Blue', img: somewhatPositive }, { name: 'Pulsepoint-Green', img: highlyPositive }
]

const icons = [{ name: 'Operations', img: Operations }, { name: 'Reputation', img: Reputation }, { name: 'Project', img: Project },
  { name: 'People', img: People }, { name: 'Facilities', img: Facilities }, { name: 'Equipment', img: Equipment }, { name: 'Product', img: Product }, { name: '', img: Default }]

const routeIcons = [{ name: 'Road', img: RoadTravel }, { name: 'Off-Road', img: OffRoadTravel }, { name: 'Water', img: WaterTravel }]

export const createAssetsLayer = (mapRef: React.RefObject<mapboxgl.Map>) : void => {
  /* Create map source */
  mapRef.current.addSource('assets-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  /* Mapbox syntaxis to display conditonal icosn depending on the asset type */
  const conditionalIcons : mapboxgl.Expression = ['match', ['get', 'type'], ['Operations'], 'Operations',
    ['Reputation'], 'Reputation', ['Project'], 'Project', ['People'], 'People', ['Facilities'], 'Facilities', ['Equipment'], 'Equipment', ['Product'], 'Product', 'Default']
  let loadedIcons = 0
  icons.forEach(({ name, img }) => {
    // eslint-disable-next-line no-undef
    const iconImage = new Image()
    iconImage.onload = () => {
      mapRef.current.addImage(name, iconImage)
      // eslint-disable-next-line no-plusplus
      if (++loadedIcons >= icons.length) {
        // Add a symbol layer
        mapRef.current.addLayer({
          id: 'asset-markers',
          type: 'symbol',
          source: 'assets-source',
          layout: {
            // get the title name from the source's "title" property
            'icon-image': conditionalIcons,
            'icon-size': 0.15,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      }
    }

    iconImage.src = img
  })
}

export const createPulsePointLayer = (mapRef: React.RefObject<mapboxgl.Map>) : void => {
  /* Create map source */
  mapRef.current.addSource('pulsepoints-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  /* Mapbox syntaxis to display conditional icons depending on the asset type */
  const conditionalIcons: mapboxgl.Expression = [
    'match',
    ['get', 'level'],
    'Red', 'Pulsepoint-Red',
    'Amber', 'Pulsepoint-Amber',
    'Yellow', 'Pulsepoint-Yellow',
    'Blue', 'Pulsepoint-Blue',
    'Green', 'Pulsepoint-Green',
    'Default',
  ]
  let loadedIcons = 0
  pulsepointIcons.forEach(({ name, img }) => {
    // eslint-disable-next-line no-undef
    const iconImage = new Image()
    iconImage.onload = () => {
      mapRef.current.addImage(name, iconImage)
      // eslint-disable-next-line no-plusplus
      if (++loadedIcons >= pulsepointIcons.length) {
        // Add a symbol layer
        mapRef.current.addLayer({
          id: 'pulsepoint-markers',
          type: 'symbol',
          source: 'pulsepoints-source',
          layout: {
            // get the title name from the source's "title" property
            'icon-image': conditionalIcons,
            'icon-size': 0.30,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      }
    }

    iconImage.src = img
  })
}

export const createRoutesLayer = (mapRef: React.RefObject<mapboxgl.Map>) : void => {
  mapRef.current.addSource('routes-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  mapRef.current.addLayer({
    id: 'routeline-active',
    type: 'line',
    source: 'routes-source',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        12, 3,
        22, 12,
      ],
    },
  })
}

export const createDriverLayer = (mapRef: React.RefObject<mapboxgl.Map>) : void => {
  mapRef.current.addSource('drivers-symbol', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  const iconImage = new Image()
  iconImage.onload = () => {
    mapRef.current.addImage('driver-car', iconImage)
    mapRef.current.addLayer({
      id: 'driver-symbols-layer',
      type: 'symbol',
      source: 'drivers-symbol',
      layout: {
        'icon-image': 'driver-car',
        'icon-size': 0.75,
        'text-allow-overlap': true,
        'text-ignore-placement': true,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    })
  }
  iconImage.src = DriverCar
}

export const createSafetyChecksLayer = (mapRef: React.RefObject<mapboxgl.Map>) : void => {
  mapRef.current.addSource('safety-checks', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })
  //
  const safetyCheckIcons = [{ name: 'SafetyCheck-Green', img: GreenCheck }, { name: 'SafetyCheck-Amber', img: AmberCheck }, { name: 'SafetyCheck-Red', img: RedCheck }]
  const conditionalIcons: mapboxgl.Expression = [
    'match',
    ['get', 'status'],
    ['SafetyCheck-Green'], 'SafetyCheck-Green',
    ['SafetyCheck-Amber'], 'SafetyCheck-Amber',
    ['SafetyCheck-Red'], 'SafetyCheck-Red',
    'SafetyCheck-Amber',
  ]
  let loadedIcons = 0
  safetyCheckIcons.forEach(({ name, img }) => {
    // eslint-disable-next-line no-undef
    const iconImage = new Image()
    iconImage.onload = () => {
      mapRef.current.addImage(name, iconImage)
      // eslint-disable-next-line no-plusplus
      if (++loadedIcons >= safetyCheckIcons.length) {
        // Add a symbol layer
        mapRef.current.addLayer({
          id: 'safety-check-markers',
          type: 'symbol',
          source: 'safety-checks',
          layout: {
            // get the title name from the source's "title" property
            'icon-image': conditionalIcons,
            'icon-size': 0.25,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      }
    }

    iconImage.src = img
  })
}

export const drawLayers = (mapRef: React.RefObject<mapboxgl.Map>) => {
  if (mapRef.current.getLayer('counties-layer')) return
  /* Add colors depending on data source */
  mapRef.current.addLayer({
    id: 'counties-layer',
    source: 'counties',
    minzoom: zoomThreshold,
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': ['get', 'Alert_color'],
      'fill-opacity': 1,
    },
  }, 'county-limits')

  mapRef.current.addLayer({
    id: 'regions-layer',
    source: 'regions',
    maxzoom: zoomThreshold,
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': ['get', 'Alert_color'],
      'fill-opacity': 1,
    },
  }, 'region-limits')

  /* Display assets on map from the source */
  createAssetsLayer(mapRef)
  createRoutesLayer(mapRef)
  createSafetyChecksLayer(mapRef)
  createRouteSymbolLayer(mapRef)
}

/**
 * Draw incidents on a mapbox map
 *
 * @param mapRef -> Ref to the map element
 * @param incidents -> List of already filtered incidents
 */
export const drawIncidents = (mapRef: React.RefObject<mapboxgl.Map>, incidents: Incident[]) : void => {
  /* Update source with new set of filtered incidents */
  (mapRef.current.getSource('incidents') as mapboxgl.GeoJSONSource).setData({
    type: 'FeatureCollection',
    features: incidents.map((val) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          val.longitude, val.latitude,
        ],
      },
      properties: {
        category: Object.keys(incidentCategories).find((key) => incidentCategories[key].find((type: string) => type === val.incident_type)),
        ...val,
      },
    })),
  })
}

/* Choose appropiate colors */
const alertStateColor = (alertState: number) => ({
  1: '#21ed39',
  2: '#217aed',
  3: '#f5f531',
  4: '#f58331',
  5: '#f53731',
  6: '#b5b3b3',
}[alertState])

/* Color code counties based on alert state and filters */
const colorCodeCounties = (counties: CountiesGeoJSON | RegionsGeoJSON) => {
  /* Coordinate based shapes for each county */
  counties.features.forEach((val) => {
    // eslint-disable-next-line no-param-reassign
    val.properties.Alert_color = alertStateColor(val.properties.Alert_state)
  })

  return counties
}

/**
 *  Color code counties based based on alert states
 *
 * @param counties -> Counties geojson object
 * @param mapRef -> React map reference
 * @param regions -> Regions geojson object
 * @returns Updated source data for counties and regions
 */
export const updateCountyColors = (
  counties: CountiesGeoJSON,
  mapRef: React.RefObject<mapboxgl.Map>,
  regions: RegionsGeoJSON,
) :
{ regionsSource: RegionsGeoJSON, countiesSource: CountiesGeoJSON } => {
  /* Change layer content */
  const colouredCounties = colorCodeCounties(counties) as CountiesGeoJSON
  const colouredRegions = colorCodeCounties(regions) as RegionsGeoJSON

  const regionSource = (mapRef.current.getSource('regions') as mapboxgl.GeoJSONSource)
  regionSource.setData(colouredRegions)

  const countySource = (mapRef.current.getSource('counties') as mapboxgl.GeoJSONSource)
  countySource.setData(colouredCounties)

  return { regionsSource: colouredRegions, countiesSource: colouredCounties }
}

/**
 * Set layer colors to transparent
 *
 * @param counties -> GeoJSON Feature Collection of counties
 * @param regions -> GeoJSON feature Collection of Regions
 * @param mapRef -> React reference to mapbox map
 */
export const hideCountiesRegions = (
  counties: CountiesGeoJSON,
  regions: RegionsGeoJSON,
  mapRef: React.RefObject<mapboxgl.Map>,
) => {
  const transparent = (geojson: CountiesGeoJSON | RegionsGeoJSON) : CountiesGeoJSON | RegionsGeoJSON => (
    {
      ...geojson,
      features: geojson.features.map((val) => ({ ...val, properties: { ...val.properties, Alert_color: 'rgba(255,255,255, 0)' } })),
    })
  const updatedCounties = transparent(counties) as CountiesGeoJSON
  const updatedRegions = transparent(regions) as RegionsGeoJSON

  const regionSource = (mapRef.current.getSource('regions') as mapboxgl.GeoJSONSource)
  const countySource = (mapRef.current.getSource('counties') as mapboxgl.GeoJSONSource)

  regionSource.setData(updatedRegions)
  countySource.setData(updatedCounties)
}

export const getProperties = (e: mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData) : { coordinates: mapboxgl.LngLat, feature: { [name: string] : any } } => {
  const coordinates = e.lngLat
  const feature = e.features[0].properties
  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
  }

  return { feature, coordinates }
}

/**
 * Create and display a mapbox popup
 *
 * @param e -> Click event
 * @param mapRef -> React map reference
 * @returns -> A mapbox popup
 */
export const displayAssetPopup = (e: mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData, mapRef: React.RefObject<mapboxgl.Map>) : mapboxgl.Popup => {
  console.log('in displayAssetPopup')
  const { feature, coordinates } = getProperties(e)
  const asset = { ...feature, location: JSON.parse(feature.location), coordinates: JSON.parse(feature.coordinates) } as Asset
  /* Create React component and render it before adding it to the mapbox popup */
  const placeholder = document.createElement('div')
  const component = (
    <AssetPopup data={asset} />
  )
  ReactDOM.render(component, placeholder)

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setDOMContent(placeholder)
    .addTo(mapRef.current)
}

/**
 * Create popup for incident
 *
 * @param e -> Click event
 * @param mapRef -> React map reference
 * @returns A mapbox popup
 */
export const displayIncidentPopup = (e: mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData, mapRef: React.RefObject<mapboxgl.Map>) : mapboxgl.Popup => {
  const { feature, coordinates } = getProperties(e)
  const incident = feature as Incident & { submitter: string, category: string }
  /* Create React component and render it before adding it to the mapbox popup */
  const placeholder = document.createElement('div')
  const component = (
    <IncidentPopup data={incident} />
  )
  ReactDOM.render(component, placeholder)

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setDOMContent(placeholder)
    .addTo(mapRef.current)
}

/**
 * Create mapbox popup for counties
 *
 * @param e -> Mapbox click event
 * @param mapRef -> React map reference
 * @param onClick -> onClick callback
 * @returns A mapbox popup object
 */
export const displayAlertStatePopup = (e: mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData, mapRef: React.RefObject<mapboxgl.Map>, onClick: (county: CountyProperties) => void) : mapboxgl.Popup => {
  const { feature, coordinates } = getProperties(e)
  const county = feature as CountyProperties
  /* Create React component and render it before adding it to the mapbox popup */
  const placeholder = document.createElement('div')
  const component = (
    <CountyPopup data={county} onClick={onClick} />
  )
  ReactDOM.render(component, placeholder)

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setDOMContent(placeholder)
    .addTo(mapRef.current)
}

/**
 * Create a popup for clicked safety check
 *
 * @param e -> Mapbox event data
 * @param mapRef -> React reference to mapbox map
 * @returns A mapbox popup displayed on the map
 */
export const displaySafetyPopup = (e: mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[];
} & mapboxgl.EventData, mapRef: React.RefObject<mapboxgl.Map>, workspaceId: number) : mapboxgl.Popup => {
  const { feature, coordinates } = getProperties(e)
  const safetyCheck = { ...feature, safety: JSON.parse(feature.safety) } as SafetyUser

  /* Create React component and render it before adding it to the mapbox popup */
  const placeholder = document.createElement('div')

  const component = (
    <SafetyCheckPopup data={safetyCheck} workspaceId={workspaceId} />
  )
  ReactDOM.render(component, placeholder)

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setDOMContent(placeholder)
    .addTo(mapRef.current)
}

/**
 * Load Route Icons
 *
 * @param mapRef -> React reference to map
 */
export const createRouteSymbolLayer = (mapRef: React.RefObject<mapboxgl.Map>) => {
  mapRef.current.addSource('route-symbols', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  const conditionalIcons : mapboxgl.Expression = [
    'match', ['get', 'icon'], ['Road'], 'Road', ['Off-Road'], 'Off-Road', ['Water'], 'Water', 'Road',
  ]

  let loadedIcons = 0
  routeIcons.forEach(({ name, img }) => {
    const iconImage = new Image()
    iconImage.onload = () => {
      mapRef.current.addImage(name, iconImage)
      // eslint-disable-next-line no-plusplus
      if (++loadedIcons >= routeIcons.length) {
        // Add a symbol layer
        mapRef.current.addLayer({
          id: 'route-symbols-layer',
          type: 'symbol',
          source: 'route-symbols',
          layout: {
            // get the title name from the source's "title" property
            'icon-image': conditionalIcons,
            'icon-size': 1,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      }
    }
    iconImage.src = img
  })
}

export const createAssetShapeLayers = (
  mapRef: React.RefObject<mapboxgl.Map>,
  assets: any[] = [],
) => {

  mapRef.current.addSource('assets-lines', {
    type: 'geojson',
    data: [],
  })

  mapRef.current.addSource('assets-polygons', {
    type: 'geojson',
    data: [],
  })

  mapRef.current.addLayer({
    id: 'assets-lines-layer',
    type: 'line',
    source: 'assets-lines',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#8E151F',
      'line-width': 4,
    },
  })

  mapRef.current.addLayer({
    id: 'assets-polygon-layer',
    type: 'fill',
    source: 'assets-polygons',
    paint: {
      'fill-color': 'transparent',
      'fill-opacity': 0,
    },
  })

  mapRef.current.addLayer({
    id: 'assets-polygon-line-layer',
    source: 'assets-polygons',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#8E151F',
      'line-width': 2,
    },
  })
}

/**
 * Get GeoJSON data for POIs
 *
 * @param assets -> List of assets
 * @returns FeatureCollection of points and linestrings
 */
export const assetsToGeoJSON = (assets: any[]) : {
  featureLines: LinePOIFeatures,
  featurePolygons: PolygonFeatures,
} => {
  console.log('assets in geoJSON conversion', assets)
  const lineFeatures : Feature<LineString, { id: number }>[] = assets.filter(({ marker_type }) => marker_type === 'line').map(({ geodata, id }) => ({
    ...geodata as Feature<LineString>, properties: { id },
  }))

  const polygonFeatures : Feature<Polygon, { id: number }>[] = assets.filter(({ marker_type }) => marker_type === 'polygon').map(({ geodata, id }) => ({
    ...geodata as Feature<Polygon>, properties: { id },
  }))

  return {
    featureLines: featureCollection(lineFeatures),
    featurePolygons: featureCollection(polygonFeatures),
  }
}

/**
 * Returns a GeoJSON Feature Collection object containing the pulsepoints
 * @param pulsepoints -> List of formatted pulsepoints
 */
export const pulsepointsToGeoJSON = (pulsepoints: any[]) : any => {
  /* Loop through features and build the map properties object as well as the GeoJSON feature elements */
  const features = pulsepoints.map((val) => {
    const mapProperties : any = {
      ...val,
    }
    const pulsepointFeature = point([val.longitude, val.latitude], mapProperties)
    return pulsepointFeature
  })
  return featureCollection<Point, any>(features)
}
