import React, { useState } from 'react'
import {
  IonCol, IonRow, IonList, IonItem, IonInput, IonButton,
  useIonAlert, useIonLoading,
} from '@ionic/react'
import { SettingField, SettingTitle } from '../StyledContainers'

import useApi from '../../../hooks/testHooks'

/**
 *  =========================
 *    Change Password View
 *  =========================
 */

const SecuritySettings = () => {
  /* Input state */
  const [passwordFields, setPasswordFields] = useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  })
  const [ionAlert] = useIonAlert()
  const [present, dismiss] = useIonLoading()
  const apiHook = useApi()

  const submitNewPassword = async () => {
    if (passwordFields.newPassword !== passwordFields.repeatPassword) {
      ionAlert({
        header: 'Error',
        message: 'Passwords do not match.',
        buttons: [
          { text: 'OK' },
        ],
      })

      return
    }

    /* Send request to the server and display loader while waiting for response */
    present()

    const message = await apiHook.changePassword({
      password: passwordFields.oldPassword,
      password1: passwordFields.newPassword,
      password2: passwordFields.repeatPassword,
    })

    dismiss()
    ionAlert({
      header: message.header,
      message: message.message,
      buttons: [
        { text: 'OK' },
      ],
    })

    setPasswordFields({
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    })
  }

  const handlePasswordEdit = (e) => {
    setPasswordFields({ ...passwordFields, [e.target.name]: e.detail.value })
  }

  return (
    <>
      <SettingTitle>Security & Privacy</SettingTitle>
      <IonRow>
        <IonCol style={{ padding: '15px' }}>
          <SettingField>
            <h4>Change your password</h4>
            <p>In order to change your password you must enter the current password associated with your account</p>
            <p>Passwords must contain 8 characters with at least one letter and one number</p>
          </SettingField>
        </IonCol>
        <IonCol>
          <IonList style={{ '--ion-item-background': 'white' }}>
            <SettingField>
              <h4>Change your password</h4>
            </SettingField>
            <IonItem style={{ marginBottom: '30px' }}>
              <IonInput
                type='password'
                value={passwordFields.oldPassword}
                name='oldPassword'
                placeholder='Current password'
                onIonChange={handlePasswordEdit}
              />
            </IonItem>
            <IonItem>
              <IonInput
                type='password'
                value={passwordFields.newPassword}
                name='newPassword'
                placeholder='New password'
                onIonChange={handlePasswordEdit}
              />
            </IonItem>
            <IonItem>
              <IonInput
                type='password'
                value={passwordFields.repeatPassword}
                name='repeatPassword'
                placeholder='Repeat New password'
                onIonChange={handlePasswordEdit}
              />
            </IonItem>
          </IonList>
          <IonRow className='ion-justify-content-end'>
            <IonButton onClick={submitNewPassword} style={{ '--background': '#326771' }}>Save</IonButton>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  )
}

export default SecuritySettings
