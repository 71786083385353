export interface IncidentFilters {
  toggleAll: boolean,
  filters: {
    'Violent Conflict': boolean,
    'Violent Crime': boolean,
    Criminality: boolean,
    'Social Unrest': boolean,
    Travel: boolean,
    other: boolean,
  }
}

/* When starting the app all filters for incidents will be toggled on */
export const defaultFilters : IncidentFilters = {
  toggleAll: true,
  filters: {
    'Violent Conflict': true,
    'Violent Crime': true,
    Criminality: true,
    'Social Unrest': true,
    Travel: true,
    other: true,
  },
}

export enum InsightFiltersActionKind {
  TOGGLE_ALL = 'TOGGLE ALL',
  TOGGLE_SINGLE = 'TOGGLE SINGLE',
  TOGGLE_ALL_FALSE = 'TOGGLE FALSE',
  TOGGLE_ALL_TRUE = 'TOGGLE TRUE',
}

export interface FilterActions {
  type: InsightFiltersActionKind,
  payload?: string
}

export const filtersReducer = (state: IncidentFilters, action: FilterActions) : IncidentFilters => {
  const { type, payload } = action
  switch (type) {
    case InsightFiltersActionKind.TOGGLE_SINGLE: {
      if (!payload) {
        return { ...state }
      }
      const updated = { ...state.filters, [payload]: !state.filters[payload] }
      const toggleAll = Object.values(updated).every((val) => val)
      return { filters: { ...updated }, toggleAll }
    }

    case InsightFiltersActionKind.TOGGLE_ALL: {
      return {
        toggleAll: !state.toggleAll,
        filters: {
          'Violent Conflict': !state.toggleAll,
          'Violent Crime': !state.toggleAll,
          Criminality: !state.toggleAll,
          'Social Unrest': !state.toggleAll,
          Travel: !state.toggleAll,
          other: !state.toggleAll,
        },
      }
    }

    case InsightFiltersActionKind.TOGGLE_ALL_FALSE: {
      return {
        toggleAll: false,
        filters: {
          'Violent Conflict': false,
          'Violent Crime': false,
          Criminality: false,
          'Social Unrest': false,
          Travel: false,
          other: false,
        },
      }
    }

    case InsightFiltersActionKind.TOGGLE_ALL_TRUE: {
      return {
        toggleAll: true,
        filters: {
          'Violent Conflict': true,
          'Violent Crime': true,
          Criminality: true,
          'Social Unrest': true,
          Travel: true,
          other: true,
        },
      }
    }
    default:
      return { ...state }
  }
}
