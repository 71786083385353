import React from 'react'
import { CreateAnimation } from '@ionic/react'
import styled from 'styled-components'

export const FormContainer = styled.div`
    background-color: rgb(255, 255, 255, 0.8);
    --ion-background-color: white;
    box-shadow: 0px 0px 13px -2px #000000;
    width: 35%;
    height: 100%;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
`

export const FormContainerMobile = styled.div`
  height: auto;
  background-color: white;
  width: 80%;
  text-align: center;
  position: absolute;
  right: 50%;
  box-shadow: 0px 0px 13px -2px #000000;
  transform: translateX(50%);
  top: 10%;
`

const MapForm = ({ children, className = '' }) => (
  <>
    <CreateAnimation
      duration={800}
      fromTo={[
        { property: 'transform', fromValue: 'translateX(100px)', toValue: 'translateX(0px)' },
      ]}
      easing='ease-out'
      play
    >
      <FormContainer className={className}>
        {
                        children
                    }
      </FormContainer>
    </CreateAnimation>
  </>
)

export default MapForm
