import { IonButton, IonRow, useIonAlert } from '@ionic/react'
import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import Datetime from 'react-datetime'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import { IncidentCause, User } from '../../types/GlobalTypes'
import { CommitmentType } from '../../types/OptimizedMaps'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

export interface Details {
  title: string,
  type: '' | CommitmentType,
  date: moment.Moment,
  description: string,
  stakeholders: { value: number, label: string }[],
  areas: { value: number, label: string }[],
  owner?: User,
}

export const DropdownTypes = [CommitmentType.SOCIAL_ECONOMIC_DEVELOPMENT, CommitmentType.POLICY, CommitmentType.CONTRACTUAL,
  CommitmentType.CUSTOMARY_COMMUNITARY, CommitmentType.REGULATORY, CommitmentType.RESETTLEMENT, CommitmentType.STAKEHOLDER_REQUEST,
  CommitmentType.VERBAL_COMMITMENT, CommitmentType.OTHER]

const CommitmentForm : FC = () => {
  const [input, setInput] = useState<Details>({
    title: '',
    type: '',
    date: moment(),
    description: '',
    stakeholders: [],
    areas: [],
  })
  const { workspace } = useWorkspace()
  const [selectedIssues, setSelectedIssues] = useState<(IncidentCause & { selected: boolean })[]>([])
  const {
    causes, setShowCommitmentForm, stakeholders, setLoading,
    communities, users, setSubmittedMessage, pushNewCommitment,
  } = useTerrainMapping()
  const [ionAlert] = useIonAlert()

  const errorMessages = {
    title: 'You need to choose a title for this commitment',
    type: 'No type has been selected',
    description: 'Please, briefly describe this commitment',
    owner: 'Choose the owner of this commitment',
  }

  const selectCause = (index: number) => {
    const copy = selectedIssues.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setSelectedIssues(copy)
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const keyError = keys.find((val) => !input[val] || input[val] === '')

    if (keyError) {
      ionAlert({
        header: 'Form Error',
        message: errorMessages[keyError],
        buttons: [{ text: 'ok' }],
      })
      return
    }

    const filteredIssues = selectedIssues.filter(({ selected }) => selected)

    setLoading(true)
    axios.post('/api/v2/commitment/create_commitment', {
      domain_id: workspace.id,
      title: input.title,
      type: input.type,
      date: input.date.format(),
      description: input.description,
      owner: input.owner.id,
      issues: filteredIssues.map(({ id }) => id),
      community: input.areas.map(({ value }) => value),
      stakeholders: input.stakeholders.map(({ value }) => value),
    }).then(({ data }) => {
      if (data.message === 'you have not created a commitment.') {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      pushNewCommitment({
        id: data.commitment.id,
        title: input.title,
        commitment_type: input.type as CommitmentType,
        date: input.date.format(),
        description: input.description,
        communities: input.areas.map(({ value, label }) => ({ id: value, name: label })),
        stakeholders: input.stakeholders.map(({ value, label }) => ({ id: value, name: label })),
        issues: filteredIssues,
        owner: input.owner as User,
      })
      setSubmittedMessage('Commitment created successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setShowCommitmentForm(false)
      setLoading(false)
    })
  }

  useEffect(() => {
    setSelectedIssues(causes.map((val) => ({ ...val, selected: false })))
  }, [])

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <OverlayContainer style={{ zIndex: 25, height: '80%', overflow: 'hidden' }} className='terrain-add-popup'>
        <div style={{ maxHeight: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
          <IonRow style={{ marginBottom: '10px' }} className='ion-justify-content-between ion-align-items-center'>
            <h5 style={{ marginBottom: 0 }}>Add Commitment</h5>
            <SimpleButton onClick={() => setShowCommitmentForm(false)}>Close X</SimpleButton>
          </IonRow>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <div className='terrain-form-field'>
              <h6>Title</h6>
              <input
                placeholder='Give this issue a title'
                type='text'
                value={input.title}
                onChange={(e) => setInput({ ...input, title: e.target.value })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Commitment Type</h6>
              <select
                value={input.type}
                onChange={(e) => setInput({ ...input, type: e.target.value as CommitmentType })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select a type</option>
                {
                  DropdownTypes.map((val) => (
                    <option key={val} value={val}>{ val }</option>
                  ))
                }
              </select>
            </div>
            <div className='terrain-form-field'>
              <h6>Date Received</h6>
              <Datetime
                dateFormat='YYYY-MM-DD'
                inputProps={{
                  placeholder: 'Select Date',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                value={input.date}
                onChange={(e) => setInput({ ...input, date: e as moment.Moment })}
              />
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Description</h6>
              <textarea value={input.description} onChange={(e) => setInput({ ...input, description: e.target.value })} />
            </div>
            <div className='terrain-form-field'>
              <h6>Associated Stakeholders</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Areas'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                value={input.stakeholders}
                onChange={(selected) => setInput({ ...input, stakeholders: selected })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Relevant Areas</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Areas'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                value={input.areas}
                onChange={(selected) => setInput({ ...input, areas: selected })}
              />
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Underlying Causes</h6>
              {
                selectedIssues.map((val, index) => (
                  <TagButton
                    key={val.id}
                    onClick={() => selectCause(index)}
                    style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                  >
                    { val.name }
                  </TagButton>
                ))
              }
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Owner</h6>
              <Select
                placeholder='Select user'
                name='supervisor'
                id='select-supervisor-container'
                className='select-container'
                  // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={users.map((user) => ({ value: user, label: user.email }))}
                onChange={(selected) => setInput({ ...input, owner: selected.value })}
              />
            </div>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton onClick={() => setShowCommitmentForm(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Submit</IonButton>
          </IonRow>
        </div>
      </OverlayContainer>
    </>
  )
}

export default CommitmentForm
