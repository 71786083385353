/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable no-param-reassign */
import moment from 'moment'
import * as turf from '@turf/turf'
import useApi from '../../../hooks/testHooks'
// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'
import securityIndicators from '../widgets/security_indicators.json'
import Criminality from '../incidents/pins/Criminality.png'
import SocialUnrest from '../incidents/pins/Social Unrest.png'
import Travel from '../incidents/pins/Travel.png'
import ViolentConflict from '../incidents/pins/Violent Conflict.png'
import ViolentCrime from '../incidents/pins/Violent Crime.png'

const zoomThreshold = 5

const icons = [{ name: 'Criminality', img: Criminality },
  { name: 'Social Unrest', img: SocialUnrest }, { name: 'Travel', img: Travel }, { name: 'Violent Conflict', img: ViolentConflict }, { name: 'Violent Crime', img: ViolentCrime }]

/**
 *  ==============================
 *          County Shapes
 *  ==============================
 */

export const drawLimitsAndNames = (mapRef, allowRegions) => {
  mapRef.current.addLayer({
    id: 'counties-background',
    source: 'counties',
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': 'rgba(255,255,255, 0)',
      'fill-opacity': 1,
    },
  })

  const countyLimits = {
    id: 'county-limits',
    source: 'counties',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 0.5,
    },
  }

  const countyNames = {
    id: 'county-names',
    source: 'counties',
    type: 'symbol',
    layout: {
      'text-field': ['get', 'shapeName'],
      'text-size': ['match', ['get', 'Alert_color'], ['rgba(255,255,255, 0)'], 0, 12],
    },
  }

  if (allowRegions) {
    countyLimits.minzoom = zoomThreshold
    countyNames.minzoom = zoomThreshold

    mapRef.current.addLayer({
      id: 'regions-background',
      source: 'regions',
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': 'rgba(255,255,255, 0)',
        'fill-opacity': 1,
      },
    })

    const regionLimits = {
      id: 'region-limits',
      source: 'regions',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#888',
        'line-width': 0.5,
      },
      maxzoom: zoomThreshold,
    }

    const regionNames = {
      id: 'region-names',
      source: 'regions',
      type: 'symbol',
      layout: {
        'text-field': ['get', 'shapeName'],
        'text-size': ['match', ['get', 'Alert_color'], ['rgba(255,255,255, 0)'], 0, 12],
      },
      maxzoom: zoomThreshold,
    }

    mapRef.current.addLayer(regionLimits)
    mapRef.current.addLayer(regionNames)
  }

  mapRef.current.addLayer(countyLimits)
  /* Add county names */
  mapRef.current.addLayer(countyNames)
}

const drawCountyShapesIncidents = (counties, mapRef, paintIncidents, allowRegions, regions) => {
  /** Add county shapes and names */
  mapRef.current.addSource('counties', {
    type: 'geojson',
    data: counties,
  })

  if (allowRegions) {
    mapRef.current.addSource('regions', {
      type: 'geojson',
      data: regions,
    })
  }

  drawLimitsAndNames(mapRef, allowRegions)

  const conditionalIcons = ['match', ['get', 'category'], ['Violent Conflict'], 'Violent Conflict',
    ['Violent Crime'], 'Violent Crime', ['Criminality'], 'Criminality', ['Social Unrest'], 'Social Unrest', ['Travel'], 'Travel', 'Criminality']
  let loadedIcons = 0

  icons.forEach(({ name, img }) => {
    // eslint-disable-next-line no-undef
    const iconImage = new Image()
    iconImage.onload = () => {
      mapRef.current.addImage(name, iconImage)
      // eslint-disable-next-line no-plusplus
      if (++loadedIcons >= icons.length) {
        // Add a symbol layer
        mapRef.current.addLayer({
          id: 'incident-markers-fusion',
          type: 'symbol',
          source: 'incidents-fusion',
          layout: {
            'icon-image': conditionalIcons,
            'icon-size': 0.15,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
        // Add a symbol layer
        mapRef.current.addLayer({
          id: 'incident-markers',
          type: 'symbol',
          source: 'incidents',
          layout: {
            'icon-image': conditionalIcons,
            'icon-size': 0.15,
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      }
    }

    iconImage.src = img
  })

  /* Add empty source of incidents -> will depend on the filters */
  mapRef.current.addSource('incidents', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  mapRef.current.addSource('incidents-fusion', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  })

  if (paintIncidents) paintIncidents()
}

/* Choose appropiate colors */
const alertStateColor = (alertState, filters) => ({
  1: (filters['AS-1']) ? '#21ed39' : 'rgba(255,255,255, 0)',
  2: (filters['AS-2']) ? '#217aed' : 'rgba(255,255,255, 0)',
  3: (filters['AS-3']) ? '#f5f531' : 'rgba(255,255,255, 0)',
  4: (filters['AS-4']) ? '#f58331' : 'rgba(255,255,255, 0)',
  5: (filters['AS-5']) ? '#f53731' : 'rgba(255,255,255, 0)',
  6: '#b5b3b3',
}[alertState])

const showAlertState = (val1, val2, dropdownOption) => {
  if (dropdownOption === 'all') {
    return true
  }

  if (dropdownOption === 'higher' && val2 > val1) {
    return true
  }

  if (dropdownOption === 'lower' && val2 < val1) {
    return true
  }

  if (dropdownOption === 'unchanged' && val2 === val1) {
    return true
  }

  return false
}

/* Color code counties based on alert state and filters */
const colorCodeCounties = (filters, counties, idAttr, focusedCountry, focusedRegion, crowdFilter = 'all', suggestedFilter = 'all') => {
  /* Coordinate based shapes for each county */
  counties.features.forEach((val) => {
    if (focusedCountry && focusedCountry !== 'All') {
      if (focusedRegion !== undefined && focusedRegion !== null && focusedRegion !== 'All') {
        if (val.properties[idAttr] === focusedRegion
          && showAlertState(val.properties.Alert_state, val.properties.crowd_alert, crowdFilter) && showAlertState(val.properties.Alert_state, val.properties.average_suggestion, suggestedFilter)) {
          val.properties.Alert_color = alertStateColor(val.properties.Alert_state, filters)
        } else {
          val.properties.Alert_color = 'rgba(255,255,255, 0)'
        }
      } else {
        if (val.properties.country === focusedCountry
          && showAlertState(val.properties.Alert_state, val.properties.crowd_alert, crowdFilter) && showAlertState(val.properties.Alert_state, val.properties.average_suggestion, suggestedFilter)) {
          val.properties.Alert_color = alertStateColor(val.properties.Alert_state, filters)
        } else {
          val.properties.Alert_color = 'rgba(255,255,255, 0)'
        }
      }
    } else {
      if (showAlertState(val.properties.Alert_state, val.properties.crowd_alert, crowdFilter) && showAlertState(val.properties.Alert_state, val.properties.average_suggestion, suggestedFilter)) {
        val.properties.Alert_color = alertStateColor(val.properties.Alert_state, filters)
      } else {
        val.properties.Alert_color = 'rgba(255,255,255, 0)'
      }
    }
  })

  return counties
}

/** Alert State current level */
const getCurrentLevel = (alertState) => ({
  1: {
    title: 'Green - Caution',
    name: 'Caution',
    color: '#ffffff',
    background: '#25bf23',
  },
  2: {
    title: 'Blue - Warning',
    name: 'Warning',
    color: '#ffffff',
    background: '#115efb',
  },
  3: {
    title: 'Yellow - Standfast',
    name: 'Standfast',
    color: '#000000',
    background: '#fef336',
  },
  4: {
    title: 'Orange - Standby',
    name: 'Standby',
    color: '#ffffff',
    background: '#fd8a25',
  },
  5: {
    title: 'Red - Evacuation',
    name: 'Evacuation',
    color: '#ffffff',
    background: '#db2b17',
  },
}[alertState])

const getSafetyColor = (level) => ({
  Red: {
    title: 'Red - I feel unsafe',
    background: '#DD2800',
  },
  Amber: {
    title: 'Amber - I am cautious',
    background: '#FF8A00',
  },
  Green: {
    title: 'Green - I feel safe',
    background: '#14C105',
  },
  Dismissed: {
    title: 'Dismissed - No response',
    background: '#b5b3b3',
  },
  Unknown: {
    title: 'Unknown status',
    background: '#b5b3b3',
  },
}[level])

/** Get current alert state description */
const getDescription = (alertState) => ({
  1: 'There is a low risk of possible terrorist activity or civil unrest affecting operations or personnel. Business as usual. ',
  2: `A general or localised threat situation, the nature and extent of which are unpredictable, 
  and circumstances do not justify full implementation of higher alert measures. Local travel restrictions may be necessary.`,
  3: `A foreseen event or situation that could impact on the security of operations. 
  There is potential for the security situation in the area to deteriorate rapidly. Local lockdowns and / or severely restricted staff movements may be necessary.`,
  4: `A ‘threat to life’ incident is highly likely. Security conditions are hostile and approaching a level where operations are not possible. 
  Critical risk to staff. Partial evacuation should be considered.`,
  5: 'There is an immediate / direct threat to personnel. There are extreme risks to personnel and assets. Operations are not possible. Full evacuation should be considered.​',
}[alertState])

const getIndicator = (as, county) => {
  const currentAs = getCurrentLevel(as)

  return (
    `<p class="heading">${county}</p>
    <p class="heading">Alert State ${as}: <span class="alert-state-display" style="color: ${currentAs.color}; background-color: ${currentAs.background};">${currentAs.title}</span></p>
    <p class="heading">Appropriate Control Measures</p>
    <div class="sub-measures">
      <p class="heading">Personnel security:</p>
      ${securityIndicators[as]['Personnel security'].map((indicator) => (`<p> - ${indicator}</p>`)).join('')}
    </div>
    <div class="sub-measures">
      <p class="heading">Journey Management:</p>
      ${securityIndicators[as]['Journey Management'].map((indicator) => (`<p> - ${indicator}</p>`)).join('')}
    </div>
    <div class="sub-measures">
      <p class="heading">Evacuation:</p>
      ${securityIndicators[as].Evacuation.map((indicator) => (`<p> - ${indicator}</p>`)).join('')}
    </div>
  `
  )
}

const processConsultantAnalyses = (data) => {
  const countyMap = {}
  data.forEach(({ analyses, clues, inquest }) => {
    const keyphraseIds = clues.map(({ keyphrase_id }) => keyphrase_id)
    analyses.forEach(({ county_id }) => {
      if (countyMap[county_id]) {
        countyMap[county_id] = {
          ...countyMap[county_id],
          suggestions: [...countyMap[county_id].suggestions, inquest.recommendation],
          reports: countyMap[county_id].reports + 1,
          keyphrases: [...countyMap[county_id].keyphrases, ...keyphraseIds],
        }
        return
      }
      countyMap[county_id] = {
        suggestions: [inquest.recommendation],
        reports: 1,
        keyphrases: [...keyphraseIds],
      }
    })
  })
  Object.keys(countyMap).forEach((county_id) => {
    const { keyphrases } = countyMap[county_id]
    const repeated = keyphrases.reduce((accumulator, value) => ({ ...accumulator, [value]: (accumulator[value] || 0) + 1 }), {})
    countyMap[county_id] = { ...countyMap[county_id], keyphrases: Object.keys(repeated).map((id) => ({ id: Number(id), count: repeated[id] })) }
  })

  return countyMap
}

const processAnalystReports = (data) => {
  const countyMap = {}
  data.forEach(({ analyses, inquest }) => {
    analyses.forEach(({ county_id }) => {
      if (countyMap[county_id] && moment(inquest.created_at).isAfter(countyMap[county_id].created_at)) {
        countyMap[county_id] = {
          created_at: inquest.created_at,
          suggestion: inquest.recommendation,
        }
        return
      }
      countyMap[county_id] = {
        created_at: inquest.created_at,
        suggestion: inquest.recommendation,
      }
    })
  })
  return countyMap
}

const fetchAnalyses = async (consultant) => {
  const apiHook = useApi()
  if (consultant) {
    return apiHook.getAnalyses().then((response) => processConsultantAnalyses(response.data))
  }
  return apiHook.getMyAnalyses().then((response) => processAnalystReports(response.data))
}

const fetchAlerStates = async (insights, domain_id, consultant) => {
  /* Coordinate based shapes for each county */
  const apiHook = useApi()
  const regionsAS = {}
  const countryRegions = {}

  let data = {}
  /* fetch alert states */
  // if (!consultant) {
  //  const allRegions = await apiHook.getAllRegions()
  //  console.log('after regions')
  //  const allCounties = await apiHook.getAllCounties()
  //  console.log('after counties')
  //  const allCountries = await apiHook.getAllCountries()
  //  console.log('after countries')
  //  data = {
  //    regions: allRegions.regions, countries: allCountries.countries, counties: allCounties.counties,
  //  }
  // }
  // if (consultant) {
  data = await apiHook.getEfficientCounties({ domain_id })
  // }
  /* Having a hashmap allows to get a faster retrieval */
  const countriesMap = {}
  data.countries.forEach((country) => {
    countriesMap[country.id] = { ...country }
    countryRegions[country.name] = { id: country.id, regions: [] }
  })

  const regionsMap = {}
  data.regions.forEach((region) => {
    regionsMap[region.id] = { ...region }
    /* Add region to dropdown object */
    countryRegions[countriesMap[region.country_id].name].regions.push({ name: region.name, id: region.id })
  })

  /* Add region to each county record */
  data.counties.forEach((dbCounty) => {
    if (!dbCounty.region_id) {
      dbCounty.region = 'Western Equatoria'
      dbCounty.country = 'SouthSudan'
      return
    }
    dbCounty.region = regionsMap[dbCounty.region_id].name
    dbCounty.country = countriesMap[regionsMap[dbCounty.region_id].country_id].name
  })

  /* Fetch crowd insights */
  const [crowdAlert, analyses] = (insights) ? (await Promise.all([apiHook.get_avg_alert(domain_id, 24 * 30), fetchAnalyses(consultant)])) : []
  const features = data.counties.map((dbCounty) => {
    const parsedData = dbCounty.geoData
      ? (typeof dbCounty.geoData === 'string' ? JSON.parse(dbCounty.geoData) : dbCounty.geoData)
      : {}

    if (regionsAS[dbCounty.region_id]) {
      regionsAS[dbCounty.region_id].push(dbCounty.alert_state)
    } else {
      regionsAS[dbCounty.region_id] = [dbCounty.alert_state]
    }

    const properties = {
      ...parsedData.properties,
      Alert_state: dbCounty.alert_state,
      modified: dbCounty.updated_at,
      Alert_color: 'rgba(255,255,255, 0)',
      shapeName: dbCounty.name,
      id: dbCounty.id,
      region_id: dbCounty.region_id,
      county: dbCounty.name,
      region: dbCounty.region,
      country: (dbCounty.region_id) ? countriesMap[regionsMap[dbCounty.region_id].country_id].name : 'Cameroon',
      description: getIndicator(dbCounty.alert_state, dbCounty.name),
    }
    if (insights) {
      const crowd = crowdAlert.message.find(({ county_ID }) => county_ID === dbCounty.id)
      properties.crowd_alert = (crowd) ? crowd.avg : dbCounty.alert_state
      if (consultant) {
        let average = dbCounty.alert_state
        if (analyses[dbCounty.id]) {
          average = Math.ceil(analyses[dbCounty.id].suggestions.reduce((acc, val) => acc + val, 0) / analyses[dbCounty.id].suggestions.length)
        }
        properties.average_suggestion = average
      } else {
        properties.my_suggestion = (analyses[dbCounty.id]) ? analyses[dbCounty.id].suggestion : dbCounty.alert_state
      }
    }

    return { ...parsedData, properties }
  })

  const regionFeatures = data.regions.map((region) => {
    /* Get the average alert state of all counties in the region */
    const avgAS = (regionsAS[region.id]) ? Math.ceil(regionsAS[region.id].reduce((acc, AS) => acc + AS, 0) / regionsAS[region.id].length) : 1
    const parsedData = region.geodata
      ? (typeof region.geodata === 'string' ? JSON.parse(region.geodata) : region.geodata)
      : {}

    const properties = {
      ...parsedData.properties,
      Alert_color: 'rgba(255,255,255, 0)',
      Alert_state: avgAS,
      id: region.id,
      country: countriesMap[regionsMap[region.id].country_id].name,
      description: getIndicator(avgAS, region.name),
    }

    return { ...parsedData, properties }
  })

  /* Counties final GeoJSON file */
  const counties = {
    type: 'FeatureCollection',
    name: 'counties',
    crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
    features,
  }

  /* Regions final GeoJSON file */
  const regions = {
    type: 'FeatureCollection',
    name: 'regions',
    crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
    features: regionFeatures,
  }

  /* Add crowd insights to the alert states list */
  if (insights) {
    data.counties.forEach((county) => {
      const alert = crowdAlert.message.find(({ county_ID }) => county_ID === county.id)
      county.crowd_alert = (alert) ? alert.avg : county.alert_state
    })
  }

  return {
    counties, asCounties: data.counties, regions, countryRegions, analyses,
  }
}

const updateAlertStates = (countyList, counties) => {
  /* Coordinate based shapes for each county */
  countyList.forEach((val) => {
    if (!val.edited) return
    const index = counties.features.findIndex(({ properties }) => properties.id === val.id)
    counties.features.splice(index, 1, {
      ...counties.features[index],
      properties: {
        ...counties.features[index].properties,
        Alert_state: val.Alert_state,
        modified: moment().format('YYYY-MM-DD HH:mm'),
        description: getIndicator(val.Alert_state, val.county),
      },
    })
  })

  return counties
}

const updateCountyColors = (filters, update, counties, mapRef, drawLayer, regions, updateRegions, focusedCountry, focusedRegion, crowdFilter, suggestedFilter) => {
  /* Change layer content */
  const colouredCounties = colorCodeCounties(filters, { ...counties }, 'region_id', focusedCountry, focusedRegion, crowdFilter, suggestedFilter)
  update(colouredCounties)

  if (regions) {
    const colouredRegions = colorCodeCounties(filters, regions, 'id', focusedCountry, focusedRegion)
    updateRegions(colouredRegions)
    mapRef.current.getSource('regions').setData(colouredRegions)
  }
  mapRef.current.getSource('counties').setData(colouredCounties)
  if (drawLayer) drawLayer()
}

const showCrowdInsights = (filters, update, counties, mapRef, analyst) => {
  counties.features.forEach((val) => {
    if (analyst) {
      val.properties.Alert_color = (val.properties.Alert_color !== 'rgba(255,255,255, 0)') ? alertStateColor(val.properties.crowd_alert, filters) : 'rgba(255,255,255, 0)'
    } else {
      // eslint-disable-next-line no-param-reassign
      val.properties.Alert_color = alertStateColor(val.properties.crowd_alert, filters)
    }
  })

  update(counties)
  mapRef.current.getSource('counties').setData(counties)
}

const incidentIndicator = (incident) => (
  `<p class="heading">${incident.incident_type}</p>
  <p class="incident-detail"><span>Description: </span><span class='incident-popup-description'> ${incident.description || 'No description provided'}</span></p>
  <p class="incident-detail"><span>Date: </span> ${moment(incident.reported).format('DD-MM-YYYY')}</p>
  <p class="incident-detail"><span>County: </span> ${incident.countyName}</p>
  <p class="incident-detail"><span>Region: </span> ${incident.regionName}</p>
  <p class="incident-detail"><span>Fatalities: </span> ${incident.fatalities}</p>
  <p class="incident-detail"><span>Latitude: </span> ${incident.latitude.toFixed(3)}</p>
  <p class="incident-detail"><span>longitude: </span> ${incident.longitude.toFixed(3)}</p>
  `
)

const restrictionBoxIndicator = (mb) => (
  `<p class="heading">Restriction Box</p>
  <p class="incident-detail"></p>
  <p class="incident-detail"><span>Conditions for travel</span></p>
  ${(!mb.senior_manager_approval && !mb.anti_vampire_rules && !mb.forbidden) ? '<p class="incident-detail"> No Conditions stablished </p>' : ''}
  ${(mb.senior_manager_approval) ? '<p class="incident-detail">- Senior Manager Approval</p>' : ''}
  ${(mb.anti_vampire_rules) ? '<p class="incident-detail">- No Night Travel</p>' : ''}
  ${(mb.forbidden) ? '<p class="incident-detail">- Out Of Bounds</p>' : ''}
  <p class="incident-detail"><span>End date:</span></p>
  <p class="incident-detail">${moment(mb.updated_at).add(mb.check_in_days, 'days').format('DD-MMM-YYYY')}</p>
  `
)

const mapPopup = (e, mapRef) => {
  const coordinates = e.lngLat
  const incident = e.features[0]
  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
  }

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setHTML(incident.properties.popupdata)
    .addTo(mapRef.current)
}

/* Reset new restriction box source */
const createMB = (mapRef, coordinates) => {
  /* Add GeoJSON source that will represent the movement box */
  mapRef.current.addSource('new-restriction-box', {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates,
      },
    },
  })

  mapRef.current.addLayer({
    id: 'new-restriction-box',
    type: 'line',
    source: 'new-restriction-box',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 2,
    },
  })
}

const checkColocationDistance = (distance, zoom) => {
  if (zoom <= 5 && distance <= 30) {
    return true
  }
  if (zoom <= 7 && distance <= 15) {
    return true
  }
  if (zoom <= 10 && distance <= 5) {
    return true
  }
  if (zoom > 10 && distance <= 2) {
    return true
  }
  return false
}

const formatUsers = (users, workspaceUsers) => {
  const domainUsers = workspaceUsers.map(({ user_id, type }) => {
    const userData = users.find((user) => user.id === user_id)
    if (userData) {
      userData.permission = type
    } else {
      return { id: user_id, first_name: 'Unknown', final_name: 'User' }
    }

    return userData
  })
  return domainUsers
}

const createPath = (from, to, mapRef) => {
  const start = [parseFloat(from.center[0]), parseFloat(from.center[1])]
  const end = [
    parseFloat(to.center[0]),
    parseFloat(to.center[1]),
  ]

  const distance = turf.distance(start, end, { units: 'miles' })
  const midpoint = turf.midpoint(start, end)
  const destination = turf.destination(midpoint, distance / 5, 20, { units: 'miles' })

  // curvedLine gets rendered to the page
  const curvedLine = turf.bezierSpline(
    turf.lineString([start, destination.geometry.coordinates, end]),
  )

  mapRef.current.getSource('flight-source').setData({
    type: 'FeatureCollection',
    features: [curvedLine],
  })
}

const generateChapter = (content, category, journeyId, userId, user) => ({
  category,
  content,
  journey_id: journeyId,
  user_id: userId,
  created_at: moment().format(),
  user,
})

const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

export {
  drawCountyShapesIncidents,
  colorCodeCounties,
  getCurrentLevel,
  getIndicator,
  fetchAlerStates,
  updateCountyColors,
  mapPopup,
  incidentIndicator,
  restrictionBoxIndicator,
  updateAlertStates,
  showCrowdInsights,
  createMB,
  getDescription,
  checkColocationDistance,
  formatUsers,
  getSafetyColor,
  zoomThreshold,
  createPath,
  generateChapter,
  getColor,
  alertStateColor,
}
