/* eslint-disable react/no-array-index-key */
import React from 'react'
import Select from 'react-select'

import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { Icon } from '@iconify/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, MeasureContainer, ColumnContainer } from '../../StyledContainers'

/**
 *  ===============================
 *      Add new Control Measures
 *  ===============================
 */
const NewMeasures = ({
  input, setInput, children, onClose, next, measures, edit, categories,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Add measure */
  const handleDropAdd = (e) => {
    /* Get measure name */
    const measure = JSON.parse(e.dataTransfer.getData('measure'))

    if (input.newMeasures.findIndex((val) => val.id === measure.id) >= 0) return
    const copy = input.newMeasures.slice()
    copy.push(measure)

    setInput({ ...input, newMeasures: copy })
  }

  /* Remove measure */
  const handleDropRemove = (e) => {
    /* Get measure name */
    const measure = JSON.parse(e.dataTransfer.getData('measure'))

    const index = input.newMeasures.findIndex((val) => val.id === measure.id)
    if (index < 0) return

    const copy = input.newMeasures.slice()
    copy.splice(index, 1)

    setInput({ ...input, newMeasures: copy })
  }

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>New Control Measures</h5>
              {
                (edit) ? (
                  <>
                    <p>If you would like to add any new control measures that you wish to have installed following this review you can do so here.</p>
                    <p>Choose a category and drag and drop items from the list on the left to the right column to add them as new control measures to be installed. </p>
                  </>
                ) : (
                  <p>New Control Measures refer to measures you intend to put in place for this Key Asset.
                    Select new control measures that you wish to implement by dragging and dropping from the left table to the right.
                    A project will be generated for this Key Asset  in the Projects App with these measures becoming objectives.
                  </p>
                )
              }
            </SettingField>
            <div style={{ width: '200px' }}>
              <Select
                placeholder='Select Category'
                id='new-control-measure-categories'
                name='newCategory'
                className='select-container'
                value={input.newCategory}
                        // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={categories.map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setInput({ ...input, newCategory: selected, edit: true })}
              />
            </div>
            <IonRow className='ion-align-items-center ion-jsutify-content-between'>
              <IonCol size={5}>
                <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropRemove}>
                  {
                    input.newCategory && measures.filter(({ category }) => input.newCategory.value === category)
                      .filter((val) => !input.newMeasures.find((measure) => val.id === measure.id)).map((measure, index) => (
                        <MeasureContainer
                          className='single-control-measure'
                          key={index}
                          draggable
                          style={{ backgroundColor: getColor(index) }}
                          onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(measure))}
                        >
                          <p> {measure.control_measure} </p>
                        </MeasureContainer>
                      ))
                  }
                  {
                    !input.newCategory && (
                      <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                        <SettingField style={{ textAlign: 'center', width: 'auto' }}>
                          <h4 style={{ fontWeight: 'normal', margin: '20px 0' }}>Choose a category to select measures</h4>
                          <p>To start adding control measures, choose a category from the drop-down menu above. </p>
                        </SettingField>
                      </IonRow>
                    )
                  }
                </ColumnContainer>
              </IonCol>
              <IonCol style={{ textAlign: 'center', fontSize: '2.6rem' }} size={2}>
                <Icon icon='ic:round-swipe-right' />
              </IonCol>
              <IonCol size={5}>
                <ColumnContainer id='cm-residual-drop' onDragOver={(e) => e.preventDefault()} onDrop={handleDropAdd}>
                  {
                    input.newCategory && input.newMeasures.filter((selected) => selected.category === input.newCategory.value).map((measure, index) => (
                      <MeasureContainer
                        className='selected-control-measure'
                        key={index}
                        draggable
                        style={{ backgroundColor: getColor(index) }}
                        onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(measure))}
                      >
                        <p> {measure.control_measure} </p>
                      </MeasureContainer>
                    ))
                  }
                  {
                    (!input.newCategory || input.newMeasures.filter((selected) => selected.category === input.newCategory.value).length === 0) && (
                      <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                        <SettingField style={{ textAlign: 'center', width: 'auto' }}>
                          <h4 style={{ fontWeight: 'normal', margin: '20px 0' }}>Drag and drop items from the left to add them to your Key Asset’s profile</h4>
                          <p>Control measures that you add here will be added as objectives in a project generated for this Key Asset.</p>
                        </SettingField>
                      </IonRow>
                    )
                  }
                </ColumnContainer>
              </IonCol>
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default NewMeasures
