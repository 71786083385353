import React, { useEffect, useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'

import {
  IonHeader, IonToolbar, IonButtons, IonMenuButton, IonCol, IonGrid, IonRow, IonBadge,
} from '@ionic/react'
import styled from 'styled-components'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import {
  faBell, faCog, faArrowRight, faEllipsisH,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from '@iconify/react'
import { useWorkspace } from '../hooks/useWorkspace'
import NotificationCard from './NotificationCard'

import { DashboardButton, SherpaButton } from './GlobalContainers'
import { useNotification } from '../hooks/useNotification'
import { useAuth } from '../hooks/useAuth'
import { SimpleButton } from './maps/StyledContainers'

const ProfileContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & button {
      padding: 0 15px;
    }
`

const PageHeader = ({ title, className = '' }) => {
  const { workspace } = useWorkspace()
  const { notifications, notifCount } = useNotification()
  const auth = useAuth()
  const history = useHistory()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHidden, setHidden] = useState(true)
  const bellRef = useRef(null)

  const { user } = useAuth()
  const notifHook = useNotification()

  const sortedNotifList = [].concat(notifications).sort((a, b) => a.id - b.id)

  useEffect(async () => {
    if (notifCount <= 0) {
      setHidden(true)
    } else {
      setHidden(false)
    }
  })

  const markAllPopover = (
    <Popover id='popover-basic'>
      <Popover.Body>
        <SimpleButton style={{ textDecoration: 'underline' }} onClick={() => markAllRead()}>Mark all as read</SimpleButton>
      </Popover.Body>
    </Popover>
  )

  const markAllRead = (async () => {
    console.log('markAllRead')
    await notifHook.markAllNotificationAsRead(user.id)
  })

  // popover
  const popover = (
    <Popover id='popover-basic'>
      <Popover.Header
        as='h3'
        style={{
          color: '#8E151F', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        }}
      >
        Notifications
        <OverlayTrigger trigger='click' placement='bottom' overlay={markAllPopover}>
          <div style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </div>
        </OverlayTrigger>
      </Popover.Header>
      <Popover.Body>
        {sortedNotifList.reverse().slice(0, 3).map((notification, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <NotificationCard key={index} onDisplayPopup={() => bellRef.current.click()} notification={notification} />
        ))}
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className='ion-text-end'>
                <Link to='/notifications'>See All</Link>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </Popover.Body>
    </Popover>
  )

  return (
    <IonHeader className={`dashboard-header ${className}`}>
      <IonGrid style={{ padding: '0' }}>
        <IonRow style={{ alignItems: 'center' }}>
          <IonCol>
            <IonToolbar>
              <IonButtons slot='start'>
                <DashboardButton>
                  <IonMenuButton data-testid='main-menu-button' />
                </DashboardButton>
              </IonButtons>
              <IonRow className='ion-align-items-center'>
                <h3 className='header-workspace-text' style={{ margin: '0 20px', fontSize: '18px', color: '#326771' }}>{workspace.name}</h3>
                <FontAwesomeIcon icon={faArrowRight} />
                <h3 className='header-workspace-text' style={{ margin: '0 20px', fontSize: '18px', color: '#326771' }}>{title}</h3>
              </IonRow>
            </IonToolbar>
          </IonCol>
          <IonCol style={{ marginRight: '5%' }}>
            <ProfileContainer>
              <OverlayTrigger trigger='click' placement='left' overlay={popover}>
                <SherpaButton ref={bellRef}>
                  {
                    notifCount > 0 && (
                      <IonBadge style={{ position: 'absolute' }} color='danger' mode='ios'> {String(notifCount)} </IonBadge>
                    )
                  }
                  <FontAwesomeIcon icon={faBell} />
                </SherpaButton>
              </OverlayTrigger>
              <Link className='header-link' to='/settings'>
                <FontAwesomeIcon icon={faCog} />
              </Link>
              <SherpaButton onClick={() => {
                auth.logout()
                history.push('/login')
              }}
              >
                <Icon icon='ls:logout' />
              </SherpaButton>
            </ProfileContainer>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonHeader>
  )
}

export default PageHeader
