import React, { useState, useEffect } from 'react'
import {
  IonContent, IonRow, IonCol, IonCheckbox, IonDatetime, IonInput, IonSpinner,
} from '@ionic/react'

import moment from 'moment'
import { Icon } from '@iconify/react'
import { WidgetTitles } from '../maps/WidgetContainers'
import { useAuth } from '../../hooks/useAuth'

import { ObscureBackground, CenterLoader } from '../GlobalContainers'
import { SimpleButton } from '../maps/StyledContainers'

/**
 *  ===========================
 *      Task List View
 *  ===========================
 */
const TaskList = ({
  tasks, editing, loading, updateTasks, changeStatus, deleteTasks,
}) => {
  /* Keep a record of the changes */
  const [input, setInput] = useState({})
  const [flag, setFlag] = useState(false)
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const { user } = useAuth()
  const [toggleAll, setToggleAll] = useState(false)

  const editTask = (e, taskId) => {
    setInput({ ...input, [taskId]: { ...input[taskId], [e.target.name]: e.detail.value, edited: true } })
  }

  const createInputs = () => {
    const hashMap = {}
    const allTasks = [...tasks['To-Do'].map((task) => ({ ...task, status: 'To-Do' })),
      ...tasks['In-Progress'].map((task) => ({ ...task, status: 'In-Progress' })),
      ...tasks.Complete.map((task) => ({ ...task, status: 'Complete' })),
    ]

    allTasks.forEach(({
      id, priority, description, title, assignee, due, app, status, assigner,
    }) => {
      hashMap[id] = {
        id,
        title,
        highPriority: priority,
        description,
        edited: false,
        delete: false,
        assignee,
        app,
        due,
        status,
        assigner,
      }
    })
    return hashMap
  }

  const handleToggleAll = (e) => {
    /* Toggle All deletes */
    const newInput = Object.fromEntries(Object.keys(input).map((id) => [id, { ...input[id], delete: e.target.checked }]))
    setToggleAll(e.target.checked)
    setInput(newInput)
  }

  const handleToggle = (e, taskId) => {
    if (!e.target.checked) setToggleAll(false)
    const newInput = { ...input, [taskId]: { ...input[taskId], delete: e.target.checked } }
    if (Object.keys(newInput).findIndex((id) => !newInput[id].delete) < 0) setToggleAll(true)

    setInput(newInput)
  }

  useEffect(() => {
    setInput(createInputs())
  }, [tasks])

  useEffect(() => {
    if (!editing && flag) {
      updateTasks(input)
    }

    setFlag(true)
  }, [editing])

  return (
    <div style={{ height: '85%', position: 'relative' }}>
      {
        loading && (
          <>
            <ObscureBackground />
            <CenterLoader>
              <IonSpinner color='light' />
            </CenterLoader>
          </>
        )
      }
      <WidgetTitles style={{ height: '10%', paddingRight: '0' }}>
        <IonRow className='ion-align-items-center'>
          <IonCol size={1}>
            {
              editing && (
                <IonCheckbox
                  checked={toggleAll}
                  onClick={handleToggleAll}
                />
              )
            }
          </IonCol>
          <IonCol size={2}>Task Title</IonCol>
          <IonCol size={1}>Assigned To</IonCol>
          <IonCol size={2}>Due Date</IonCol>
          <IonCol size={1}>High Priority</IonCol>
          <IonCol size={1}>App</IonCol>
          <IonCol size={2}>Description</IonCol>
          <IonCol size={1}>Status</IonCol>
          <IonCol size={1}>
            {
              editing && (
                <SimpleButton onClick={() => deleteTasks(input)}>
                  <Icon icon='bi:trash' />
                </SimpleButton>
              )
            }
          </IonCol>
        </IonRow>
      </WidgetTitles>
      <div style={{ height: '90%', '--ion-background-color': 'white' }}>
        <IonContent>
          {
            Object.keys(input).map((taskId, index) => (
              <IonRow key={taskId} style={{ backgroundColor: getColor(index), height: '20%' }} className='ion-align-items-center widget-row incident-row ion-text-center'>
                <IonCol size={1}>
                  {
                    (editing && (input[taskId].assigner === user.email || input[taskId].assignee.email === user.email)) && (
                      <IonCheckbox
                        checked={input[taskId].delete}
                        onClick={(e) => handleToggle(e, taskId)}
                      />
                    )
                  }
                </IonCol>
                <IonCol className='task-item-title' size={2}>
                  {
                    input[taskId].title
                  }
                </IonCol>
                <IonCol size={1}>
                  {
                    input[taskId].assignee.email
                  }
                </IonCol>
                <IonCol size={2}>
                  {
                    (editing && (input[taskId].assigner === user.email || input[taskId].assignee.email === user.email)) ? (
                      <IonDatetime
                        name='due'
                        value={input[taskId].due}
                        onIonChange={(e) => editTask(e, taskId)}
                        display-format='YYYY-MM-DDTHH:mm'
                        picker-format='DD:MM:YYYYTHH:mm'
                        placeholder='YYYY-MM-DD HH:mm'
                        minuteValues='0,15,30,45'
                      />
                    ) : moment(input[taskId].due).format('DD/MM/YYYY')
                  }
                </IonCol>
                <IonCol className={`${input[taskId].title.split(' ').join('-')}-priority`} size={1}>
                  {
                    (editing && (input[taskId].assigner === user.email || input[taskId].assignee.email === user.email)) ? (
                      <IonCheckbox
                        checked={input[taskId].highPriority}
                        onClick={(e) => setInput({ ...input, [taskId]: { ...input[taskId], highPriority: e.target.checked, edited: true } })}
                      />
                    ) : (
                      <>
                        {(input[taskId].highPriority) ? 'Yes' : 'No'}
                      </>
                    )
                  }
                </IonCol>
                <IonCol size={1}>
                  {
                    input[taskId].app
                  }
                </IonCol>
                <IonCol className='task-item-description' size={2}>
                  {
                    (editing && (input[taskId].assigner === user.email || input[taskId].assignee.email === user.email)) ? (
                      <IonInput
                        type='text'
                        name='description'
                        value={input[taskId].description}
                        onIonChange={(e) => editTask(e, taskId)}
                      />
                    ) : input[taskId].description
                  }
                </IonCol>
                <IonCol size={1}>
                  {
                    (input[taskId].assigner === user.email || input[taskId].assignee.email === user.email) ? (
                      <select onChange={(e) => changeStatus(taskId, e.target.value)} defaultValue={input[taskId].status}>
                        <option value='To-Do'>To Do</option>
                        <option value='In-Progress'>In Progress</option>
                        <option value='Complete'>Complete</option>
                      </select>
                    ) : input[taskId].status
                  }

                </IonCol>
                <IonCol size={1} />
              </IonRow>
            ))
          }
        </IonContent>
      </div>
    </div>
  )
}

export default TaskList
