import { IonButton, IonCol, IonRow } from '@ionic/react'
import * as React from 'react'
import { Icon } from '@iconify/react'
import { CenterForm, ColumnContainer, MeasureContainer } from '../../riskregister/StyledContainers'
import { IncidentCause } from '../../types/GlobalTypes'

interface Props {
  onFinish: (measure: IncidentCause[]) => void,
  allCauses: IncidentCause[],
  selectedCauses: IncidentCause[],
  onClose: () => void
}

const BoxCauses : React.FC<Props> = ({
  allCauses, onClose, onFinish, selectedCauses,
}) => {
  const [selected, setSelected] = React.useState<IncidentCause[]>([...selectedCauses])
  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Add measure */
  const handleDropAdd = (e) => {
    /* Get measure name */
    const measure : IncidentCause = JSON.parse(e.dataTransfer.getData('measure'))
    if (selected.find((val) => val.id === measure.id)) return
    const copy = selected.slice()
    copy.push(measure)

    setSelected(copy)
  }

  /* Remove measure */
  const handleDropRemove = (e) => {
    /* Get measure name */
    const measure : IncidentCause = JSON.parse(e.dataTransfer.getData('measure'))
    const copy = selected.slice()
    const index = copy.findIndex(({ id }) => id === measure.id)

    copy.splice(index, 1)
    setSelected(copy)
  }

  return (
    <CenterForm style={{
      zIndex: '20', width: '90%', height: 'auto', padding: '20px',
    }}
    >
      <h4>Underlying Issues</h4>
      <p>List the underlying issues present in this restriction zone by dragging and dropping from the library on the left into the right hand column.</p>
      <IonRow className='ion-align-items-center' style={{ marginBottom: '20px' }}>
        <IonCol size='5'>
          <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropRemove}>
            {
              allCauses.filter(({ id }) => !selected.find((val) => val.id === id)).map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val.id}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                  onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(val))}
                >
                  <p> {val.name} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </IonCol>
        <IonCol style={{ textAlign: 'center', fontSize: '2.6rem' }} size='2'>
          <Icon icon='ic:round-swipe-right' />
        </IonCol>
        <IonCol size='5'>
          <ColumnContainer id='cm-existing-drop' onDragOver={(e) => e.preventDefault()} onDrop={handleDropAdd}>
            {
              selected.map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val.id}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                  onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(val))}
                >
                  <p> {val.name} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
        <IonButton style={{ '--background': '#0C9500' }} onClick={() => onFinish(selected)}>Next</IonButton>
      </IonRow>
    </CenterForm>
  )
}

export default BoxCauses
