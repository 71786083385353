/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-webpack-loader-syntax */
import * as React from 'react'
import {
  IonRow, IonCol, IonButton, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import Datetime from 'react-datetime'
import * as moment from 'moment'
import Select from 'react-select'
import { Form } from 'react-bootstrap'
import mapboxgl from '!mapbox-gl'
import Map from '../../maps/Map'
import {
  BlueButton, CategorySelect, DescriptionArea, SimpleButton, TagButton,
} from '../StyledContainers'
import { OverlayContainer } from '../../tasks/StyledContainers'
import { ObscureBackground } from '../../GlobalContainers'
import { useIncidentsData } from '../../../hooks/incident-management/useIncidentData'
import { operations, people, property } from '../../riskregister/forms/FormOptions'
import { SelectionDetail } from '../../riskregister/StyledContainers'
import {
  AdditionalIncident,
  IncidentActor, IncidentCause, InternalIncident, User,
} from '../../types/GlobalTypes'
import { useAuth } from '../../../hooks/useAuth'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { getNumericalValue } from '../../riskregister/forms/utils/Categories'
import useApi from '../../../hooks/testHooks'
import { drawLimitsAndNames } from '../../maps/apps/AppMap'
import DragDropFiles from '../DragDropFiles'
import axios from '../../../utils/axios'

interface FormAdditionalIncident {
  title: string,
  category: string,
  type: string,
}

const propertyImpact = { None: 'No Impact on Properties', ...property }
const peopleImpact = { None: 'No Impact on people.', ...people }
const operationsImpact = { None: 'No Impact on operations.', ...operations }

export const categoryValuesMapping = {
  'Conflict & Unrest': [
    'Asset damaged by armed conflict',
    'Asset damaged by ethnic violence',
    'Asset damaged by industrial action',
    'Asset damaged by social unrest',
    'Asset damaged by terror attack',
    'Hibernation',
    'Local evacuation',
    'Staff injury resulting from armed conflict',
    'Staff injury resulting from ethnic violence',
    'Staff injury resulting from industrial action',
    'Staff injury resulting from social unrest',
    'Staff injury resulting from terror attack',
    'Travel disrupted by armed conflict',
    'Travel disrupted by ethnic violence',
    'Travel disrupted by industrial action',
    'Travel disrupted by social unrest',
    'Travel disrupted by terror attack',
    'Work disrupted by armed conflict',
    'Work disrupted by ethnic violence',
    'Work disrupted by industrial action',
    'Work disrupted by social unrest',
    'Work disrupted by terror attack',
  ],
  Facilities: [
    'Damage - facilities, equipment, assets',
    'Electrical fault',
    'Fire',
    'Local flooding',
    'Personal protective equipment (PPE) violation',
    'Security equipment damage / fault',
    'Water leakage',
  ],
  'Protest & Activism': [
    'Activist threat of harm / violence',
    'Allegations of Human Rights violations',
    'Community Coercion',
    'Industrial action / strike',
    'Legal challenge',
    'Negative media coverage',
    'NGO scrutiny',
    'Peaceful protest',
    'Rock throwing / vandalism',
    'Site blockade / Work disruption',
    'Town hall meeting / Community mobilisation',
    'Violent protest',
  ],
  'Public Sector': [
    'Coup d\'état in progress',
    'Government rent seeking / bribery / corruption',
    'Police / Emergency services site visit',
    'Regulatory censure / penalty',
    'Security Force Abuse / Arrest / Detention',
    'State of emergency declared',
    'Unscheduled politician / local official visit',
    'Unscheduled Regulator / Security force inspection',
  ],
  'Site Security': [
    'Access control violation',
    'Armed intruder',
    'Arson',
    'Bladed weapon attack',
    'Bomb attack',
    'Burglary',
    'Coercion / Extortion',
    'Complex armed attack',
    'Drone attack',
    'Equipment sabotage',
    'Fraud',
    'IED found',
    'Indirect fire attack',
    'Lethal substance attack',
    'Non-specific bomb threat',
    'Non-specific threat of attack',
    'Product tampering',
    'Ramming attack',
    'Robbery',
    'Single active shooter',
    'Site regulation violation',
    'Static guard misconduct',
    'Suspect mail / package',
    'Theft',
    'Unarmed trespasser',
    'Unauthorised drone surveillance',
    'Unauthorised site entry or exit',
    'Use of force by site security forces',
    'Vandalism',
  ],
  'Transport & Travel': [
    'Aircraft incident',
    'Assault-in-transit',
    'Crime-in-transit',
    'Checkpoint Harassment',
    'Driving under the influence (DUI)',
    'Drone attack on means of transport',
    'Hijack',
    'IED attack along transport route',
    'Kidnap',
    'Lost communications',
    'Missing persons',
    'Projectile / heavy weapons attack on aircraft in transit',
    'Ramming attack',
    'Road blockade / restriction of movement',
    'Road traffic incident',
    'Shelter-in-place',
    'Speeding / reckless driving off site',
    'Speeding / reckless driving on site',
    'Spill of hazardous materials off site (via road, rail, sea, air)',
    'Spill of hazardous materials on site (including at associated facilities)',
    'Tiger kidnap',
    'Vehicle breakdown (high security risk area)',
    'Vehicle breakdown (high security risk area)',
  ],
  'Workplace Safety': [
    'Contact with electricity or electrical discharge',
    'Contact with moving machinery',
    'Drowning or asphyxiation',
    'Exposure to an explosion',
    'Exposure to fire',
    'Exposure to, or contact with, a harmful substance',
    'Exposure to, or contact with, high heat surface',
    'Fall from a height',
    'Injured by an animal',
    'Injured while handling, lifting or carrying',
    'Other kind of incident',
    'Pinch point injury',
    'Repetitive strain injury (RSI)',
    'Sexual Assault',
    'Sexual Offence',
    'Slip, trip or fall on same level',
    'Stress & mental health',
    'Struck against something fixed or stationary',
    'Struck by moving vehicle',
    'Struck by moving, including flying/falling, object',
    'Trapped by something collapsing/overturning',
    'Work Place Aggression / Verbal Abuse',
    'Work Place Violence / Physical Assault',
  ],
  'Supply Chain': [
    'Subcontractor fatality / serious injury',
    'Work stoppage',
    'Allegations of Human Rights violations',
    'Other critical incident',
    'Other non-critical incident',
  ],
}

const IncidentForm : React.FC<{ onClose: () => void, categories: { [type: string]: string[] } }> = ({ onClose, categories }) => {
  const [incidentInput, setIncidentInput] = React.useState({
    title: '',
    category: '',
    date: moment(),
    comments: '',
    coordinates: { lat: 0, lng: 0 },
    description: '',
    assistance: '',
    actions: '',
    involved: undefined,
    incidentType: '',
    incidentTitle: '',
    additionalIncidents: [{
      title: '',
      category: '',
      type: '',
    }],
    peopleInvolved: [{
      user: undefined,
      type: '',
    }],
    people: undefined,
    operations: undefined,
    property: undefined,
  })
  const [choosenCauses, setChoosenCauses] = React.useState<(IncidentCause & { selected: boolean })[]>([])
  const [choosenActors, setChoosenActors] = React.useState<(IncidentActor & { selected: boolean })[]>([])
  const [countyData, setCountyData] = React.useState<{
    name: string,
    id: number,
  }>({ name: '', id: 0 })
  const [marker, setMarker] = React.useState(null)
  const [showMap, setShowMap] = React.useState(false)
  const [ionAlert] = useIonAlert()
  const {
    users, actors, causes, pushIncident, setLoading,
    countiesGeoJSON,
  } = useIncidentsData()
  const mapRef = React.useRef(null)
  const { user } = useAuth()
  const { workspace } = useWorkspace()
  const [files, setFiles] = React.useState<FileList | null>(null)
  const apiHook = useApi()
  const [dragging, setDragging] = React.useState(false)

  /* On input change */
  const handleChange = (e) => {
    setIncidentInput({ ...incidentInput, [e.target.name]: e.target.value })
  }

  /* Handle Click -> Create a marker */
  const handleClick = (e) => {
    if (marker || e.features.length === 0) return
    /* Place new marker on the specified zone */
    const markerTemp = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      .setLngLat(Object.values(e.lngLat))
      .addTo(mapRef.current)

    /* Add draggable functionalities */
    markerTemp.setDraggable(true)
    setCountyData({ id: e.features[0].properties.id, name: e.features[0].properties.shapeName })

    markerTemp.on('dragstart', () => {
      setDragging(true)
    })

    markerTemp.on('dragend', () => {
      setDragging(false)
    })
    setMarker(markerTemp)
  }
  const handleClickRef = React.useRef(handleClick)
  handleClickRef.current = handleClick

  const handleMouseUp = (e) => {
    if (!dragging) return
    setCountyData({ id: e.features[0].properties.id, name: e.features[0].properties.shapeName })
  }

  const handleMouseUpRef = React.useRef(handleMouseUp)
  handleMouseUpRef.current = handleMouseUp

  const listeners = () => {
    mapRef.current.on('load', async () => {
      mapRef.current.addSource('counties', {
        type: 'geojson',
        data: countiesGeoJSON,
      })

      drawLimitsAndNames(mapRef, false)
      mapRef.current.resize()
    })

    mapRef.current.on('mouseup', 'counties-background', (e) => {
      handleMouseUpRef.current(e)
    })
    mapRef.current.on('click', 'counties-background', (e) => {
      handleClickRef.current(e)
    })
  }

  const returnMessage = (key: string) => ({
    category: 'Please specify an incident category',
    title: 'Please specify an incident title',
    date: 'Please indicate the date of the incident',
    involved: 'Please indicate whether you were involved in the incident or no',
    description: 'Please provide a brief description about the incident',
    assistance: 'Please indicate the srot of assistance that require',
    actions: 'Please provide information about the actions taken for this incident',
    people: 'Please specify the impact that the selected Risk Event would have on People',
    operations: 'Please specify the impact that the selected Risk Event would have on Operations',
    property: 'Please specify the impact that the selected Risk Event would have on Property & Equipment',
    incidentType: 'Please specify an incident type for the primary incident.',
  }[key])

  const submitForm = () => {
    /* Find fields  that haven't been filled in */
    const notFilled = Object.keys(incidentInput).find((key) => (incidentInput[key] === undefined || incidentInput[key] === '') && key !== 'comments')
    if (notFilled) {
      ionAlert({
        header: 'Error',
        message: returnMessage(notFilled),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })

      return
    }

    /* All fields must be either not filled or filled compltely */
    const peopleValid = incidentInput.peopleInvolved.every((val) => (val.user && val.type !== '') || (!val.user && val.type === ''))
    const additionalIncidentsValid = incidentInput.additionalIncidents
      .every(({ title, type, category }) => (title !== '' && type !== '' && category !== '') || (title === '' && type === '' && category === ''))

    if (!peopleValid) {
      ionAlert({
        header: 'Error',
        message: 'Details of people involved are incomplete.',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
      return
    }

    if (!additionalIncidentsValid) {
      ionAlert({
        header: 'Error',
        message: 'Details of additional incidents are incomplete.',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })

      return
    }

    if (incidentInput.coordinates.lng === 0 || incidentInput.coordinates.lat === 0) {
      ionAlert({
        header: 'Error',
        message: 'Please specify the location of the incident',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })

      return
    }

    /* Filter out them tags that have been selected by the user */
    const filteredCauses = choosenCauses.filter(({ selected }) => selected)
    const filteredActors = choosenActors.filter(({ selected }) => selected)

    const filteredPeople = incidentInput.peopleInvolved.filter((val) => val.user && val.type)
    const filteredAdditional = incidentInput.additionalIncidents.filter(({ title, type, category }) => title !== '' && type !== '' && category !== '')

    const peopleInvolved = filteredPeople.map((val) => ({ id: val.user.value.id, type: val.type }))
    setLoading(true)

    const formData = new FormData()
    if (files) {
      Array.from(files).forEach((val) => {
        formData.append('files[]', val)
      })
    }

    apiHook.createInternalIncident({
      domain_id: workspace.id,
      user_id: user.user_id,
      incident_type: incidentInput.incidentType,
      reported: incidentInput.date.format(),
      involved: incidentInput.involved,
      longitude: incidentInput.coordinates.lng,
      latitude: incidentInput.coordinates.lat,
      description: incidentInput.description,
      taken: incidentInput.actions,
      required: incidentInput.assistance,
      people: getNumericalValue(incidentInput.people.value),
      operations: getNumericalValue(incidentInput.operations.value),
      property: getNumericalValue(incidentInput.property.value),
      archived: false,
      county_id: countyData.id,
      comments: incidentInput.comments,
      title: incidentInput.title,
      primary_category: incidentInput.category,
      primary_title: incidentInput.title,
      primary_type: incidentInput.incidentType,
      secondary_incidents: filteredAdditional,
      internal_actors: peopleInvolved,
      actors: filteredActors.map(({ id }) => id),
      causes: filteredCauses.map(({ id }) => id),
    })
      .then(({ incident_id }) => {
        const secondaryIncidents : AdditionalIncident[] = filteredAdditional.map((val) => ({
          title: val.title,
          secondary_type: val.type,
          category: val.category,
          internal_incident_id: incident_id,
          created_at: moment().format(),
          updated_at: moment().format(),
        }))

        const internalIncident : InternalIncident = {
          id: incident_id,
          user_id: user.user_id,
          incident_type: incidentInput.incidentType,
          reported: incidentInput.date.format(),
          involved: incidentInput.involved,
          longitude: incidentInput.coordinates.lng,
          latitude: incidentInput.coordinates.lat,
          domain_id: workspace.id,
          description: incidentInput.description,
          taken: incidentInput.actions,
          comments: incidentInput.comments,
          required: incidentInput.assistance,
          people: getNumericalValue(incidentInput.people.value),
          operations: getNumericalValue(incidentInput.operations.value),
          property: getNumericalValue(incidentInput.property.value),
          archived: false,
          created_at: moment().format(),
          updated_at: moment().format(),
          county_id: countyData.id,
          submitter: { ...user, id: user.user_id },
          county: countyData,
          additionalIncidents: secondaryIncidents,
          additionalComments: [],
          causes: filteredCauses,
          actors: filteredActors,
          title: incidentInput.title,
          primary_category: incidentInput.category,
          primary_title: incidentInput.incidentTitle,
          primary_type: incidentInput.incidentType,
          logs: [],
        }

        formData.append('internal_incident_id', incident_id)
        // eslint-disable-next-line no-restricted-globals
        axios.post('/api/v1/internal_incident/upload_incident_images', formData)

        pushIncident(internalIncident)
      }).finally(() => {
        onClose()
        setLoading(false)
      })
  }

  const submitMap = () => {
    setIncidentInput({ ...incidentInput, coordinates: { ...marker.getLngLat() } })
    setMarker(null)
    setShowMap(false)
    mapRef.current = null
  }

  const addAdditionalIncident = () => {
    const copy = incidentInput.additionalIncidents.slice()
    copy.push({
      title: '',
      type: '',
      category: '',
    })

    setIncidentInput({ ...incidentInput, additionalIncidents: copy })
  }

  const addActor = () => {
    const copy = incidentInput.peopleInvolved.slice()
    copy.push({ user: null, type: '' })

    setIncidentInput({ ...incidentInput, peopleInvolved: copy })
  }

  const handleInvolvementType = (type: string, index: number) => {
    const copy = incidentInput.peopleInvolved.slice()
    copy.splice(index, 1, { ...copy[index], type })

    setIncidentInput({ ...incidentInput, peopleInvolved: copy })
  }

  const selectActor = (index: number) => {
    const copy = choosenActors.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setChoosenActors(copy)
  }

  const selectCause = (index: number) => {
    const copy = choosenCauses.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setChoosenCauses(copy)
  }

  const chooseUserInvolved = (addUser: { value: User, label: string }, index: number) => {
    const copy = incidentInput.peopleInvolved.slice()
    copy.splice(index, 1, { ...copy[index], user: addUser })

    setIncidentInput({ ...incidentInput, peopleInvolved: copy })
  }

  const handleChangeAdditional = (value: FormAdditionalIncident, index: number) => {
    const copy = incidentInput.additionalIncidents.slice()
    copy.splice(index, 1, { ...value })

    setIncidentInput({ ...incidentInput, additionalIncidents: copy })
  }

  const cancelMap = () => {
    setMarker(null)
    setShowMap(false)
    mapRef.current = null
  }

  React.useEffect(() => {
    const causesFormatted = causes.map((val) => ({ ...val, selected: false }))
    const actorsFormatted = actors.map((val) => ({ ...val, selected: false }))
    setChoosenCauses(causesFormatted)
    setChoosenActors(actorsFormatted)
  }, [])

  return (
    <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
      {
        showMap && (
          <>
            <ObscureBackground />
            <OverlayContainer>
              <h4 className='red-font'>Where did the Incident take Place?</h4>
              <p>Click to drop a pin on the map in the location the incident took place.  You can drag and drop the pin to adjust the position. </p>
              <Map zoom={4} mapRef={mapRef} className='popup-map-container' listeners={listeners}>
                <></>
              </Map>
              <IonRow className='ion-justify-content-end'>
                <IonButton style={{ '--background': '#8E151F' }} onClick={cancelMap}>Cancel</IonButton>
                <IonButton style={{ '--background': '#4197A9' }} onClick={submitMap}>Next</IonButton>
              </IonRow>
            </OverlayContainer>
          </>
        )
      }
      <div>
        <h4 className='red-font'>Incident Report</h4>
        <p className='incident-management-form-spacing'>
          Fill out the form below in as much detail as possible to give the Incident Management Team the information they need to properly deal with the incident.
        </p>
        <IonRow className='incident-management-form-spacing'>
          <IonCol style={{ padding: '0' }} size='6'>
            <h5>Report Title</h5>
            <input className='title-input' name='title' onChange={handleChange} value={incidentInput.title} placeholder='Give this report a title' />
          </IonCol>
          <IonCol style={{ padding: '0' }} size='6'>
            <div style={{ width: '200px' }}>
              <h5> Date of Incident* </h5>
              <Datetime
                dateFormat='YYYY-MM-DD'
                className='incident-management-date-picker'
                inputProps={{
                  placeholder: 'Date of incident',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                isValidDate={(current) => current.isBefore(moment())}
                value={incidentInput.date}
                onChange={(e) => setIncidentInput({ ...incidentInput, date: e as moment.Moment })}
              />
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol style={{ paddingLeft: 0 }} size='4'>
            <h5>Primary Incident</h5>
            <input className='title-input incident-title' name='incidentTitle' onChange={handleChange} value={incidentInput.incidentTitle} placeholder='Give this incident a title' />
          </IonCol>
          <IonCol size='4'>
            <h5>Category</h5>
            <CategorySelect name='category' onChange={handleChange} value={incidentInput.category}>
              <option value='' disabled>Choose</option>
              {
                categoryValuesMapping && Object.keys(categoryValuesMapping).map((category) => (
                  <option key={category} value={category}> { category } </option>
                ))
              }
            </CategorySelect>
          </IonCol>
          <IonCol size='4'>
            <h5>Type</h5>
            <CategorySelect name='incidentType' onChange={handleChange} value={incidentInput.incidentType}>
              <option value='' disabled>Choose</option>
              {
                incidentInput.category && categoryValuesMapping[incidentInput.category].map((category) => (
                  <option key={category} value={category}> { category } </option>
                ))
              }
            </CategorySelect>
          </IonCol>
        </IonRow>
        {
          incidentInput.additionalIncidents.map((val, index) => (
            <IonRow key={index} style={{ margin: '10px 0' }}>
              <IonCol style={{ paddingLeft: 0 }} size='4'>
                <h5>Additional Incident (optional)</h5>
                <input
                  className='title-input incident-title'
                  name='title'
                  onChange={(e) => handleChangeAdditional({ ...incidentInput.additionalIncidents[index], [e.target.name]: e.target.value }, index)}
                  value={val.title}
                  placeholder='Give this incident a title'
                />
              </IonCol>
              <IonCol size='4'>
                <h5>Category</h5>
                <CategorySelect
                  name='category'
                  onChange={(e) => handleChangeAdditional({ ...incidentInput.additionalIncidents[index], [e.target.name]: e.target.value }, index)}
                  value={val.category}
                >
                  {
                    categoryValuesMapping && Object.keys(categoryValuesMapping).map((category) => (
                      <option key={category} value={category}> { category } </option>
                    ))
                  }
                </CategorySelect>
              </IonCol>
              <IonCol size='4'>
                <h5>Type</h5>
                <CategorySelect
                  name='type'
                  onChange={(e) => handleChangeAdditional({ ...incidentInput.additionalIncidents[index], [e.target.name]: e.target.value }, index)}
                  value={val.type}
                >
                  <option value='' disabled>Choose</option>
                  {
                    val.category && categoryValuesMapping[val.category].map((category) => (
                      <option key={category} value={category}> { category } </option>
                    ))
                  }
                </CategorySelect>
              </IonCol>
            </IonRow>
          ))
        }
        <IonRow className='ion-justify-content-end'>
          <IonCol size='3'>
            <SimpleButton onClick={addAdditionalIncident}>Additional Incident <Icon icon='ant-design:plus-outlined' /> </SimpleButton>
          </IonCol>
        </IonRow>
        <div className='incident-management-form-spacing'>
          <h5> Where did the incident take place?* </h5>
          <IonRow>
            <IonCol style={{ paddingLeft: 0 }} size='2'>
              <input
                type='number'
                className='incident-coordinates'
                onChange={(e) => setIncidentInput({ ...incidentInput, coordinates: { ...incidentInput.coordinates, lng: Number(e.target.value) } })}
                value={incidentInput.coordinates.lng}
                placeholder='Input Coordinates'
              />
            </IonCol>
            <IonCol size='2'>
              <input
                className='incident-coordinates'
                onChange={(e) => setIncidentInput({ ...incidentInput, coordinates: { ...incidentInput.coordinates, lat: Number(e.target.value) } })}
                value={incidentInput.coordinates.lat}
                placeholder='Input Coordinates'
              />
            </IonCol>
            <IonCol size='8'>
              Or find using <BlueButton onClick={() => setShowMap(true)}>Map</BlueButton> <Icon style={{ color: '#C30101' }} icon='ic:round-pin-drop' />
            </IonCol>
          </IonRow>
        </div>
        <div className='incident-management-form-spacing'>
          <h5>Were you involved?*</h5>
          <IonRow>
            <Form.Check
              inline
              label='Yes'
              name='involved'
              value='yes'
              type='radio'
              onChange={() => setIncidentInput({ ...incidentInput, involved: 'Yes' })}
            />
            <Form.Check
              inline
              label='No'
              name='involved'
              type='radio'
              onChange={() => setIncidentInput({ ...incidentInput, involved: 'No' })}
            />
          </IonRow>
        </div>
        <div className='incident-management-form-spacing'>
          <h5>People Involved</h5>
          {
            incidentInput.peopleInvolved.map((val, index) => (
              <IonRow key={index} style={{ margin: '10px 0' }}>
                <IonCol size='3'>
                  <Select
                    placeholder='Select user'
                    name='supervisor'
                    id='select-supervisor-container'
                    className='select-container'
                    // eslint-disable-next-line no-undef
                    value={val.user}
                    menuPortalTarget={document.body}
                    options={users.map((userVal) => ({ value: userVal, label: `${userVal.first_name} ${userVal.final_name}` }))}
                    onChange={(selected) => chooseUserInvolved(selected, index)}
                  />
                </IonCol>
                <IonCol size='3'>
                  <input
                    className='title-input incident-title'
                    style={{ maxWidth: '100%' }}
                    name='title'
                    onChange={(e) => handleInvolvementType(e.target.value, index)}
                    value={val.type}
                    placeholder='Involment Type'
                  />
                </IonCol>
                <IonCol size='2'>
                  <p className='incident-management-text-field'>{ val.user ? val.user.value.email : 'Email' }</p>
                </IonCol>
                <IonCol size='2'>
                  <p className='incident-management-text-field'>{ val.user && val.user.value.phone_number ? val.user.value.phone_number : 'Phone No.' }</p>
                </IonCol>
                <IonCol size='2'>
                  <p className='incident-management-text-field'>{ val.user && val.user.value.alternative_phone_number ? val.user.value.alternative_phone_number : 'Alternative Phone No.' }</p>
                </IonCol>
              </IonRow>
            ))
          }
          <IonRow className='ion-justify-content-end'>
            <IonCol size='3'>
              <SimpleButton onClick={addActor}>Add Person <Icon icon='ant-design:plus-outlined' /> </SimpleButton>
            </IonCol>
          </IonRow>
        </div>
        <div className='incident-management-form-spacing'>
          <h5 style={{ marginBottom: '10px' }}>External Actors Involved</h5>
          <IonRow>
            {
              choosenActors.map((val, index) => (
                <TagButton
                  key={val.id}
                  onClick={() => selectActor(index)}
                  style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                >
                  { val.name }
                </TagButton>
              ))
            }
          </IonRow>
        </div>
        <div className='incident-management-form-spacing'>
          <h5>Incident Details</h5>
          <DescriptionArea className='wide-area' name='description' placeholder='Describe the incident that occured' value={incidentInput.description} onChange={handleChange} />
        </div>
        <div className='incident-management-form-spacing'>
          <h5>Attach Images</h5>
          <DragDropFiles setFiles={setFiles} files={files} />
        </div>
        <div className='incident-management-form-spacing'>
          <h5>Consequences</h5>
          <p>Use the drop-downs below to determine the impact that the selected Risk Event would have on the following factors</p>
          <IonRow style={{ margin: '10px 0' }} className='ion-justify-content-between'>
            <IonCol style={{ padding: 0 }} size='4'>
              <h5 style={{ color: '#326771' }}>People</h5>
              <Select
                placeholder='Select People'
                className='select-container'
                value={incidentInput.people}
                      // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={Object.keys(peopleImpact).map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setIncidentInput({ ...incidentInput, people: selected })}
              />
            </IonCol>
            {
              incidentInput.people && (
                <IonCol size='8'>
                  <SelectionDetail>
                    <p>{ peopleImpact[incidentInput.people.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <IonRow style={{ margin: '10px 0' }} className='ion-justify-content-between'>
            <IonCol style={{ padding: 0 }} size='4'>
              <h5 style={{ color: '#326771' }}>Operations</h5>
              <Select
                placeholder='Select Operations'
                className='select-container'
                value={incidentInput.operations}
                      // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={Object.keys(operationsImpact).map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setIncidentInput({ ...incidentInput, operations: selected })}
              />
            </IonCol>
            {
              incidentInput.operations && (
                <IonCol size='8'>
                  <SelectionDetail>
                    <p>{ operationsImpact[incidentInput.operations.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <IonRow style={{ margin: '10px 0' }} className='ion-justify-content-between'>
            <IonCol style={{ padding: 0 }} size='4'>
              <h5 style={{ color: '#326771' }}>Property & Equipment</h5>
              <Select
                placeholder='Select Property'
                className='select-container'
                value={incidentInput.property}
                      // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={Object.keys(propertyImpact).map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setIncidentInput({ ...incidentInput, property: selected })}
              />
            </IonCol>
            {
              incidentInput.property && (
                <IonCol size='8'>
                  <SelectionDetail>
                    <p>{ propertyImpact[incidentInput.property.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
        </div>
        <div className='incident-management-form-spacing'>
          <h5>Actions Taken</h5>
          <DescriptionArea className='wide-area' name='actions' placeholder='What actions have been taken so far?' value={incidentInput.actions} onChange={handleChange} />
        </div>
        <div className='incident-management-form-spacing'>
          <h5>Assistance Required</h5>
          <DescriptionArea className='wide-area' name='assistance' placeholder='What help do you require?' value={incidentInput.assistance} onChange={handleChange} />
        </div>
        <div className='incident-management-form-spacing'>
          <h5>Additional Comments</h5>
          <DescriptionArea className='wide-area' name='comments' placeholder='Comments (optional)' value={incidentInput.comments} onChange={handleChange} />
        </div>
      </div>
      <IonRow className='ion-justify-content-end'>
        <IonButton onClick={onClose} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        <IonButton style={{ '--background': '#4197A9' }} onClick={submitForm}>Submit Report</IonButton>
      </IonRow>
    </div>
  )
}

export default IncidentForm
