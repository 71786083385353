import React, { createContext, useState, useContext } from 'react'
import { Notification } from '../../components/types/GlobalTypes'
/**
 *  ===============================
 *      SAFETY CHECK CONTEXT
 *  ===============================
 */
const PopupContext = createContext([])

export const ProvideFSitRep = ({ children }) => {
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState<Notification>(null)

  /* Show/Hide popup handlers */
  const displayPopup = (noti: Notification) => {
    setNotification(noti)
    setShow(true)
  }
  const hidePopup = () => {
    setNotification(null)
    setShow(false)
  }

  return (
    <PopupContext.Provider value={[displayPopup, hidePopup, show, notification]}>
      {
        children
      }
    </PopupContext.Provider>
  )
}

export const useFSitRepPopup = () => useContext(PopupContext)
