import { IonButton, IonRow } from '@ionic/react'
import * as React from 'react'
import { OverlayContainer } from '../StyledContainers'

interface Props {
  onConfirm: () => void,
  onCancel: () => void,
}

const RemoveUser : React.FC<Props> = ({ onConfirm, onCancel }) => (
  <OverlayContainer>
    <h4>Remove User</h4>
    <p>Are you sure you wish to remove this user from the group?</p>
    <IonRow className='ion-justify-content-end'>
      <IonButton onClick={onCancel} style={{ '--background': '#8E151F', color: 'white' }}>Cancel</IonButton>
      <IonButton onClick={onConfirm} style={{ '--background': '#326771', color: 'white' }}>Confirm</IonButton>
    </IonRow>
  </OverlayContainer>
)

export default RemoveUser
