import {
  IonRow, IonButton, useIonAlert,
} from '@ionic/react'
import React, { useState } from 'react'

import { SettingField } from '../settings/StyledContainers'
import TaskInput from './TaskInput'

/**
 *  ===========================================
 *    Form to create a task for an objective
 *  ===========================================
 */
const AddObjectiveTask = ({
  users, onSubmit, onCancel, dates,
}) => {
  const [input, setInput] = useState({
    title: '',
    assignee: null,
    due: '',
    highPriority: false,
    description: '',
  })
  /* Display Popup message */
  const [ionAlert] = useIonAlert()

  const returnMessage = (key) => {
    if (key === 'title') return { header: 'Error - Title', message: 'Please fill in the task title.' }
    if (key === 'assignee') return { header: 'Error - Assignee', message: 'Please select the assignee of this task.' }
    if (key === 'due') return { header: 'Error - Due date', message: 'Please select a due date for this task.' }
    if (key === 'description') return { header: 'Error - Description', message: 'Please add a description to this task.' }
    return null
  }

  const handleSubmit = () => {
    const notFilled = Object.keys(input).find((key) => !input[key] && key !== 'highPriority')
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    // TODO: Messages when something is incomplete
    onSubmit({ ...input, new: true })
  }

  return (
    <div style={{ padding: '10px' }}>
      <SettingField>
        <h4>Add Task</h4>
      </SettingField>
      <TaskInput
        users={users}
        input={input}
        dates={dates}
        setInput={setInput}
      />
      <IonRow style={{ width: '100%' }} className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F', 'text-transform': 'none' }} onClick={onCancel}>Cancel</IonButton>
        <IonButton style={{ '--background': '#0C9500', 'text-transform': 'none' }} onClick={handleSubmit}>Add</IonButton>
      </IonRow>
    </div>
  )
}

export default AddObjectiveTask
