import { IncidentTimeFilter } from '../types/OptimizedMaps'

export interface FetchState {
  loading: boolean,
  serverTime: string,
  oldestFetch: IncidentTimeFilter,
}

export enum FetchActionKind {
  FETCH_INCIDENTS = 'FETCH',
  RECEIVED_INCIDENTS = 'RECEIVED',
}

export interface FetchAction {
  type: FetchActionKind,
  payload: {
    oldestFetch?: IncidentTimeFilter,
    serverTime?: string
  }
}

export const fetchIncidentsReducer = (state: FetchState, action: FetchAction) : FetchState => {
  const { type, payload } = action

  switch (type) {
    case FetchActionKind.FETCH_INCIDENTS:
      return { ...state, loading: true, oldestFetch: payload.oldestFetch as IncidentTimeFilter }
    case FetchActionKind.RECEIVED_INCIDENTS:
      return { ...state, loading: false, serverTime: payload.serverTime as string }
    default:
      return { ...state }
  }
}
