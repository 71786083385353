import React from 'react'
import {
  IonLabel, IonButton, IonRow, IonGrid, IonContent, IonCol,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import useApi from '../../hooks/testHooks'
import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'

/**
 *  =======================
 *      3rd Popup SLide
 *  =======================
 */
const SecurityChangePopup = ({
  setStep, noti_id, hide, domainId, county, assets, alert, date,
}) => {
  const handlePrev = () => {
    setStep(2)
  }

  const handleClose = () => {
    setStep(0)
    hide()
  }

  const apiHook = useApi()

  const handleDownload = () => {
    apiHook.downloadSecurityChange({
      domain_id: domainId,
      notification_id: noti_id,
    })
      .then((data) => {
        const when = moment(date).format('DD_MM_YYYY')
        const filename = (`Security Change - ${county} ${when}.pdf`)
        apiHook.downloader2(data, filename)
      })
  }

  function getColor(level) {
    if (level === 'Level 1 - Warning') {
      return '#14C105'
    } if (level === 'Level 2 - Caution') {
      return '#349DFF'
    } if (level === 'Level 3 - Standfast') {
      return '#FFE500'
    } if (level === 'Level 4 - Standby') {
      return '#FF8A00'
    }
    return '#FF0000'
  }

  const alertDefs = [['Level 1 - Caution', 'There is a low risk of possible terrorist activity or civil unrest affecting operations or personnel. Business as usual.'],
    [`Level 2 - Warning', 'A general or localised threat situation, the nature and extent of which are unpredictable, and circumstances do not justify full implementation of higher alert measures. 
    Local travel restrictions may be necessary.`],
    [`Level 3 - Standfast', 'A foreseen event or situation that could impact on the security of operations. There is potential for the security situation in the area to deteriorate rapidly. 
    Local lockdowns and / or severely restricted staff movements may be necessary. An evacuation may be required imminently.`],
    [`Level 4 - Standby', 'A ‘threat to life’ incident is highly likely. Security conditions are hostile and approaching a level where operations are not possible. 
    Critical risk to staff. Partial evacuation should be considered.`],
    [`Level 5 - Evacuation', 'There is an immediate / direct threat to personnel; or armed conflict has occurred in the area. 
    There are extreme risks to personnel and assets. Full evacuation should be considered.`]]

  const alertState = [[alertDefs[alert - 1][0]], [alertDefs[alert - 1][1]]]

  return (
    <>
      <PopupBackground style={{ zIndex: 1 }} />
      <OverlayContainer style={{ height: '550px', width: '650px' }}>

        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '20px' }}>Security Condition Change</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonRow style={{
          paddingTop: '20px', paddingLeft: '10px', paddingBottom: '20px', paddingRight: '10px',
        }}
        >
          <IonLabel>Current Alert State</IonLabel>
        </IonRow>

        <IonGrid style={{ paddingTop: '7px', paddingLeft: '10px', paddingRight: '10px' }}>
          <IonRow style={{ outline: 'solid 2px' }}>
            <IonCol size='3' style={{ backgroundColor: getColor(alertState[0]) }}>
              <IonLabel style={{ fontSize: '12px', color: (alertState[0] === 'Level 3 - Standfast') ? '#000000' : '#FFFFFF' }}>{alertState[0]}</IonLabel>
            </IonCol>
            <IonCol size='9' style={{ outline: 'solid 2px' }}>
              <IonLabel style={{ fontSize: '12px' }}>{alertState[1]}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonRow style={{
          paddingTop: '10px', paddingLeft: '10px', paddingBottom: '10px', paddingRight: '10px',
        }}
        >
          <IonLabel>{`Key Assets in ${county} County: `}</IonLabel>
        </IonRow>

        <IonContent style={{ height: '181px', '--background': '#FFFFFF' }}>
          <IonGrid style={{ paddingTop: '7px', paddingLeft: '10px', paddingRight: '10px' }}>
            <IonRow style={{ outline: 'solid 2px' }}>
              <IonCol size='4'>
                <IonLabel style={{ fontSize: '12px' }}>Asset</IonLabel>
              </IonCol>
              <IonCol size='2' style={{ outline: 'solid 2px' }}>
                <IonLabel style={{ fontSize: '12px' }}>Asset Type</IonLabel>
              </IonCol>
              <IonCol size='6' style={{ outline: 'solid 2px' }}>
                <IonLabel style={{ fontSize: '12px' }}>Coordinates</IonLabel>
              </IonCol>
            </IonRow>
            {
              assets.map(
                (val) => (
                  <IonRow style={{ outline: 'solid 2px' }}>
                    <IonCol size='4'>
                      <IonLabel style={{ fontSize: '12px' }}>{val.name}</IonLabel>
                    </IonCol>
                    <IonCol size='2' style={{ outline: 'solid 2px' }}>
                      <IonLabel style={{ fontSize: '12px' }}>{val.asset_type}</IonLabel>
                    </IonCol>
                    <IonCol size='6' style={{ outline: 'solid 2px' }}>
                      <IonLabel style={{ fontSize: '12px' }}>{`${val.longitude} , ${val.latitude}`}</IonLabel>
                    </IonCol>
                  </IonRow>

                ),
              )
            }
          </IonGrid>
        </IonContent>

        <IonRow style={{ paddingTop: '15px' }}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9', paddingLeft: '10px' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} disabled>Next</IonButton>
          </IonCol>
        </IonRow>

      </OverlayContainer>
    </>
  )
}

export default SecurityChangePopup
