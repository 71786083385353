import { IonRow } from '@ionic/react'
import React, { FC, useState, useReducer } from 'react'
import axios from 'axios'
import {
  initialSOPViewState, SOPAction, SOPViewActionKind, sopViewReducer, SOPViewState, SOPViewStateAction,
} from '../../../hooks/incident-management/helpers/StateReducers'
import { useIncidentsData } from '../../../hooks/incident-management/useIncidentData'
import { SOP } from '../../types/GlobalTypes'
import { GreenButton, ObscureBackground } from '../StyledContainers'
import AddSOP from './popups/AddSOP'
import ChangeTemplate from './popups/ChangeTemplate'
import SOPComponent from './SOPCard'
import SOPViewEdit from './SOPViewEdit'
import DeleteSOP from './popups/DeleteSOP'
import { useWorkspace } from '../../../hooks/useWorkspace'

const SOPView : FC = () => {
  const [viewState, dispatchView] = useReducer(sopViewReducer, { ...initialSOPViewState })
  const updateFocused = (updatedSOP: SOP) => {
    dispatchView({ type: SOPViewActionKind.UPDATE_SOP, payload: updatedSOP })
  }

  if (viewState.focusedSOP && viewState.action === SOPAction.VIEW) {
    return (
      <SOPViewEdit
        onClose={() => dispatchView({ type: SOPViewActionKind.FORGET_SOP })}
        sopData={viewState.focusedSOP}
        onSubmitEdit={updateFocused}
      />
    )
  }

  return (
    <SOPInitial
      dispatchView={dispatchView}
      viewState={viewState}
    />
  )
}

export default SOPView

interface Props {
  dispatchView: React.Dispatch<SOPViewStateAction>,
  viewState: SOPViewState,
}

const SOPInitial : FC<Props> = ({ dispatchView, viewState }) => {
  const [showAddPopup, setShowAddPopup] = useState<boolean>(false)
  const {
    sopList, SOPTypes, deleteSOP, setLoading,
  } = useIncidentsData()
  const { workspace } = useWorkspace()

  const handleDelete = () => {
    setLoading(true)
    axios.post('/api/v1/internal_incident/deleteSop', { domain_id: workspace.id, sop_id: viewState.focusedSOP.id })
      .then(() => {
        deleteSOP(viewState.focusedSOP)
        dispatchView({ type: SOPViewActionKind.FORGET_SOP })
      }).finally(() => {
        dispatchView({ type: SOPViewActionKind.FORGET_SOP })
        setLoading(false)
      })
  }

  return (
    <div className='risk-assessment-list' style={{ height: '100%' }}>
      {
        showAddPopup && (
          <>
            <ObscureBackground />
            <AddSOP onClose={() => setShowAddPopup(false)} />
          </>
        )
      }
      {
        viewState.focusedSOP && viewState.action === SOPAction.CHANGE && (
          <>
            <ObscureBackground />
            <ChangeTemplate
              focusedSOP={viewState.focusedSOP}
              onClose={() => dispatchView({ type: SOPViewActionKind.FORGET_SOP })}
            />
          </>
        )
      }
      {
        viewState.focusedSOP && viewState.action === SOPAction.DELETE && (
          <>
            <ObscureBackground />
            <DeleteSOP
              onClose={() => dispatchView({ type: SOPViewActionKind.FORGET_SOP })}
              onContinue={handleDelete}
            />
          </>
        )
      }
      <div style={{ overflowY: 'auto' }}>
        <div style={{ margin: '10px 0' }}>
          <h4 style={{ marginBottom: '10px' }}>Standard Operating Procedures</h4>
          <p>Standard Operating Procedures are the procedures that should be followed in the event of an incident .
            In this section you can set the procedures that your team will follow in order to effectively handle a situation.
          </p>
          <p>Choose the SOPs you wish to create procedures for. By default, each SOP will have a set of response procedures created by the Sherpa platform.
            You can choose to use this default template or create an Organisation or Workspace specific template.
          </p>
        </div>
        {
        sopList.length === 0 ? (
          <div className='ion-text-center' style={{ marginTop: '60px' }}>
            <h5>No SOPs Selected</h5>
            <h5>Click “Add SOPs” to add Standard Operating Procedures</h5>
            <IonRow className='ion-justify-content-center'>
              <GreenButton onClick={() => setShowAddPopup(true)}>Add SOPs</GreenButton>
            </IonRow>
          </div>
        ) : (
          <>
            {
              sopList.filter(({ type }) => SOPTypes['Conflict & Unrest'].indexOf(type) >= 0).length > 0 && (
                <>
                  <h4>Conflict and Unrest SOPs</h4>
                  {
                    sopList.filter(({ type }) => SOPTypes['Conflict & Unrest'].indexOf(type) >= 0).map((val) => (
                      <SOPComponent
                        key={val.id}
                        data={val}
                        onChange={() => dispatchView({ type: SOPViewActionKind.CHANGE_SOP, payload: val })}
                        onDelete={() => dispatchView({ type: SOPViewActionKind.DELETE_SOP, payload: val })}
                        onView={() => dispatchView({ type: SOPViewActionKind.VIEW_SOP, payload: val })}
                      />
                    ))
                  }
                </>
              )
            }
          </>
        )
      }
      </div>
      <IonRow className='ion-justify-content-end' style={{ marginTop: '15px' }}>
        <GreenButton onClick={() => setShowAddPopup(true)}>Add SOPs</GreenButton>
      </IonRow>
    </div>
  )
}
