import { InternalIncident, SOP } from '../../../components/types/GlobalTypes'

export enum IncidentManagementView {
  INCIDENT_BOARD = 'Incident Board',
  INCIDENT_TICKET = 'Incident Ticket',
  ACTION_LOG = 'Action Log',
  INVESTIGATIONS = 'Investigations',
  SOPS = 'SOPs',
  RESPONSE_LEVELS = 'Response Levels',
  EMERGENCY_TEAMS = 'Emergency Teams',
  BREAKOUT_ROOMS = 'Breakout Rooms',
}

export interface IncidentViewState {
  view: IncidentManagementView,
  focusedIncident: null | InternalIncident,
}

export enum IncidentViewActionKind {
  SELECT_INCIDENT = 'SELECT INCIDENT',
  FORGET_INCIDENT = 'FORGET INCIDENT',
  SWITCH_VIEW = 'SWITCH VIEW',
  UPDATE_INCIDENT = 'UPDATE INCIDENT',
}

export interface IncidentViewAction {
  type: IncidentViewActionKind,
  incident?: InternalIncident,
  newView?: IncidentManagementView,
}

export const initialViewState: IncidentViewState = {
  view: IncidentManagementView.INCIDENT_BOARD,
  focusedIncident: null,
}

export const viewsReducer = (state: IncidentViewState, action: IncidentViewAction) : IncidentViewState => {
  const { newView, incident, type } = action

  switch (type) {
    case IncidentViewActionKind.SWITCH_VIEW: {
      return { ...state, view: newView }
    }
    case IncidentViewActionKind.SELECT_INCIDENT: {
      if (!incident) {
        return { ...state }
      }
      return { view: IncidentManagementView.INCIDENT_TICKET, focusedIncident: incident }
    }
    case IncidentViewActionKind.FORGET_INCIDENT: {
      return { view: IncidentManagementView.INCIDENT_BOARD, focusedIncident: null }
    }
    case IncidentViewActionKind.UPDATE_INCIDENT: {
      return { ...state, focusedIncident: incident }
    }
    default: {
      return { ...state }
    }
  }
}

export enum SOPViewActionKind {
  VIEW_SOP = 'VIEW SOP',
  FORGET_SOP = 'FORGET SOP',
  CHANGE_SOP = 'CHANGE SOP',
  DELETE_SOP = 'DELETE SOP',
  UPDATE_SOP = 'UPDATE SOP',
}

export interface SOPViewStateAction {
  type: SOPViewActionKind
  payload?: SOP,
}

export enum SOPAction {
  CHANGE = 'Change',
  VIEW = 'View',
  DELETE = 'Delete',
}

export interface SOPViewState {
  focusedSOP?: SOP
  action: SOPAction
}

export const initialSOPViewState : SOPViewState = {
  focusedSOP: undefined,
  action: SOPAction.VIEW,
}

export const sopViewReducer = (state: SOPViewState, action: SOPViewStateAction) : SOPViewState => {
  const { type, payload } = action

  switch (type) {
    case SOPViewActionKind.VIEW_SOP:
      return { focusedSOP: payload as SOP, action: SOPAction.VIEW }
    case SOPViewActionKind.FORGET_SOP:
      return { ...initialSOPViewState }
    case SOPViewActionKind.CHANGE_SOP:
      return { focusedSOP: payload as SOP, action: SOPAction.CHANGE }
    case SOPViewActionKind.DELETE_SOP:
      return { focusedSOP: payload as SOP, action: SOPAction.DELETE }
    case SOPViewActionKind.UPDATE_SOP:
      return { focusedSOP: payload as SOP, action: SOPAction.VIEW }
    default:
      return { ...initialSOPViewState }
  }
}
