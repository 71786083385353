/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'

import { IonPage, IonContent } from '@ionic/react'
import moment from 'moment'
import PageHeader from '../components/PageHeader'

import KanbanBoard from '../components/tasks/KanbanBoard'
import KanbanControls from '../components/tasks/KanbanControls'
import TaskList from '../components/tasks/TaskList'
import { useAuth } from '../hooks/useAuth'

import useApi from '../hooks/testHooks'
import { useWorkspace } from '../hooks/useWorkspace'
import ArchivedView from '../components/tasks/ArchivedView'

import ArchiveList from '../components/tasks/ArchiveList'
import ExpandTask from '../components/tasks/popups/ExpandTask'

import { ObscureBackground } from '../components/GlobalContainers'
/**
  ======================
        TASKS APP
  ======================
  Create new tasks, view them, edit them and change their status
 */
const TasksApp = () => {
  /* Tasks grouped according to their status */
  const [tasks, setTasks] = useState({
    'To-Do': [],
    'In-Progress': [],
    Complete: [],
  })
  const [archived, setArchived] = useState([])
  const [visibleTasks, setVisibleTasks] = useState({
    'To-Do': [],
    'In-Progress': [],
    Complete: [],
  })
  const [filters, setFilters] = useState({
    myTasks: true,
    assigned: true,
    other: true,
    Insight: true,
    Project: true,
    Journey: true,
    Misc: true,
  })
  const [editing, setEditing] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [visibleArchived, setVisibleArchived] = useState([])

  const [fetchArchived, setFetchArchived] = useState(true)
  const [loading, setLoading] = useState(false)

  const [focussedTask, setFocussedTask] = useState(null)

  const [listView, setListView] = useState(false)

  const [showAddTask, setShowAddTask] = useState(false)
  const auth = useAuth()
  const { email } = auth.user
  const { workspace } = useWorkspace()
  const [users, setUsers] = useState([])

  /* Consume API hook */
  const apiHook = useApi()

  /* Flag to fecth tasks only once */
  const [fetchTasks, setFetchTasks] = useState(true)

  // url variable
  const queryParams = new URLSearchParams(window.location.search)
  const notifTaskId = queryParams.get('task')

  const sortPriority = (taskA, taskB) => {
    if (!taskA.priority && taskB.priority) return 1
    if (taskA.priority && !taskB.priority) return -1
    return 0
  }

  // eslint-disable-next-line consistent-return
  const formatTasks = (dbTasks, dbUsers, isArchived) => {
    /* Format all tasks then change the state */
    if (isArchived) {
      const formatted = dbTasks.map((task) => {
        const taskAssignee = dbUsers.find((user) => user.id === task.assignee)
        const taskAssigner = dbUsers.find((user) => user.id === task.assigner)

        return {
          ...task,
          assigner: (taskAssigner) ? taskAssigner.email : 'Unknown',
          assignee: (taskAssignee) ? {
            id: taskAssignee.id,
            email: taskAssignee.email,
          } : 'Unknown',
        }
      })
      setArchived(formatted)
      return formatted
    }

    const tempTasks = {
      'To-Do': [],
      'In-Progress': [],
      Complete: [],
    }

    dbTasks.forEach((task) => {
      if (task.status === 'Cancelled') return

      const temp = task
      const taskAssignee = dbUsers.find((user) => user.id === temp.assignee)
      const taskAssigner = dbUsers.find((user) => user.id === temp.assigner)

      /* Replace id's with actual user data */
      temp.assignee = (taskAssignee) ? {
        id: taskAssignee.id,
        email: taskAssignee.email,
      } : 'Unknown'

      temp.assigner = (taskAssigner) ? taskAssigner.email : 'Unknown'

      if (temp.id === notifTaskId) {
        setFocussedTask(temp)
      }

      tempTasks[temp.status].push(temp)
    })

    const sorted = {
      'To-Do': tempTasks['To-Do'].sort(sortPriority),
      'In-Progress': tempTasks['In-Progress'].sort(sortPriority),
      Complete: tempTasks.Complete.sort(sortPriority),
    }
    setTasks(sorted)
    setVisibleTasks(sorted)
  }

  useEffect(() => {
    if (!fetchTasks) return

    apiHook.getSomeTasks(workspace.id).then((data) => {
      apiHook.getUsers(workspace.id).then((dbUsers) => {
        formatTasks(data.tasks, dbUsers.users, false)
        setFetchTasks(false)
        setUsers(dbUsers.users.map((val) => ({ id: val.id, email: val.email })))
      })
    })
  })

  const filterTasks = (newFilters, newTasks) => {
    const doFilter = (task) => {
      if (newFilters.myTasks && task.assignee.email === email) {
        if (newFilters[task.app]) return true
      }

      if (newFilters.assigned && task.assigner === email) {
        if (newFilters[task.app]) return true
      }

      if (newFilters.other && task.assigner !== email && task.assignee !== email) {
        if (newFilters[task.app]) return true
      }
      return false
    }

    let oldTasks
    if (newTasks) oldTasks = newTasks
    else if (showArchived) {
      setVisibleArchived(archived.filter(doFilter).sort(sortPriority))
      return
    } else oldTasks = tasks

    /* Firstly filter according to Assigner and Assignee */
    const filteredTasks = {
      'To-Do': oldTasks['To-Do'].filter(doFilter).sort(sortPriority),
      'In-Progress': oldTasks['In-Progress'].filter(doFilter).sort(sortPriority),
      Complete: oldTasks.Complete.filter(doFilter).sort(sortPriority),
    }

    setVisibleTasks(filteredTasks)
  }

  const changeStatus = (id, columnName) => {
    Object.keys(tasks).some((key) => {
      const index = tasks[key].findIndex((task) => task.id === Number(id))
      if (index >= 0 && columnName !== key) {
        setLoading(true)
        /* Change Status on the db */
        apiHook.updateStatus(id, columnName, workspace.id).then(() => {
          /* Change Tasks */
          const changedTask = tasks[key].splice(index, 1)[0]
          const updated = tasks[columnName]
          updated.push(changedTask)
          setTasks({ ...tasks, [columnName]: updated })
          filterTasks(filters, { ...tasks, [columnName]: updated })
          setLoading(false)
        })
        return true
      }
      return false
    })
  }

  const updateTask = (input) => {
    Object.keys(tasks).some((key) => {
      const index = tasks[key].findIndex((val) => val.id === Number(input.id))
      if (index >= 0) {
        /* Send a request to the server before proceding */
        setLoading(true)
        apiHook.editTask([
          {
            id: input.id,
            priority: input.priority,
            description: input.description,
            due: `${input.due.split('T')[0]} ${input.due.split('T')[1].split('.')[0]}`,
          }])
          .then(() => {
            const updated = tasks[key]
            updated[index] = input
            setTasks({ ...tasks, [key]: updated })
            filterTasks(filters, { ...tasks, [key]: updated })
            setLoading(false)
          })

        return true
      }
      return false
    })
  }

  const archiveItem = (input) => {
    Object.keys(tasks).some((key) => {
      const index = tasks[key].findIndex((task) => task.id === Number(input.id))
      if (index >= 0) {
        setLoading(true)
        /* Change archived status on the database */
        apiHook.updateStatus(input.id, 'Archived', workspace.id).then(() => {
          /* Change Tasks */
          const changedTask = tasks[key].splice(index, 1)[0]
          const updated = archived.slice()
          updated.push(changedTask)
          setArchived(updated.sort(sortPriority))
          filterTasks(filters, { ...tasks })
          setTasks({ ...tasks })

          setLoading(false)
        })
        return true
      }
      return false
    })
  }

  const deleteTask = (input) => {
    Object.keys(tasks).some((key) => {
      const index = tasks[key].findIndex((task) => task.id === Number(input.id))
      if (index >= 0) {
        setLoading(true)
        /* Send a request to change the status to cancelled */

        apiHook.updateStatus(input.id, 'Cancelled', workspace.id).then(() => {
          /* Change Tasks */
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          tasks[key].splice(index, 1)[0]
          filterTasks(filters, { ...tasks })
          setTasks({ ...tasks })

          setLoading(false)
        })

        return true
      }
      return false
    })
  }

  /* Add new task to the TODO list  */
  const addNewTask = ({
    title, assignees, due, time, priority, app, description, repeat,
  }) => {
    const updated = tasks['To-Do']
    setLoading(true)

    apiHook.assignTask(title, description, priority, `${due.split('T')[0]} ${due.split('T')[1].split('.')[0]}`, app, repeat, assignees, workspace.id).then(({ successes }) => {
      successes.map((val) => {
        updated.push({
          id: val[0],
          title,
          description,
          time,
          app,
          due,
          assignee: users.find((user) => user.id === val[1]),
          priority,
          assigner: email,
          status: 'To-Do',
          repeat,
        })

        return null
      })

      /* Update based on priority */
      updated.sort(sortPriority)

      setShowAddTask(false)
      setTasks({ ...tasks, 'To-Do': updated })
      setVisibleTasks({ ...tasks, 'To-Do': updated })

      setFilters(Object.fromEntries(Object.keys(filters).map((key) => [key, true])))
      setShowArchived(false)
      setLoading(false)
    })
  }

  const updateMultipleTasks = (tasksInput) => {
    /* Copy of all tasks */
    const copy = {
      'To-Do': tasks['To-Do'].slice(),
      'In-Progress': tasks['In-Progress'].slice(),
      Complete: tasks.Complete.slice(),
    }

    /* Tasks to delete */
    const request = []

    Object.keys(tasksInput).forEach((id) => {
      if (!tasksInput[id].edited) return

      const index = copy[tasksInput[id].status].findIndex((task) => task.id === Number(id))
      const old = { ...copy[tasksInput[id].status][index] }

      const updated = { ...old, ...tasksInput[id] }
      copy[tasksInput[id].status][index] = updated

      request.push(updated)
    })

    setLoading(true)
    apiHook.editTask(request.map((task) => ({
      id: task.id,
      priority: task.priority,
      description: task.description,
      due: moment(task.due).format('YYYY-MM-DD HH:mm'),
    }))).then(() => {
      /* Change the current tasks list */
      setTasks(copy)
      filterTasks(filters, copy)
      setLoading(false)
    })
  }

  const deleteTasks = (input) => {
    /* Copy of all tasks */
    const copy = {
      'To-Do': tasks['To-Do'].slice(),
      'In-Progress': tasks['In-Progress'].slice(),
      Complete: tasks.Complete.slice(),
    }

    const tasksToDelete = Object.keys(input).filter((id) => input[id].delete).map((id) => ({ id: Number(id), status: input[id].status }))

    tasksToDelete.forEach((task) => {
      const index = copy[task.status].findIndex(({ id }) => id === task.id)
      copy[task.status].splice(index, 1)
    })

    setTasks(copy)
    filterTasks(filters, copy)
  }

  const showHideArchived = async (show) => {
    if (show) {
      if (fetchArchived) {
        setLoading(true)

        // Send request and get alll tasks archived
        const dbTask = await apiHook.getArchivedTasks(workspace.id)
        const archivedTasks = formatTasks(dbTask.tasks, users, true)

        /* Fetch archived tasks only once */
        setArchived(archivedTasks)
        setFetchArchived(false)
        setLoading(false)
        setVisibleArchived(archivedTasks)
      } else {
        setVisibleArchived(archived)
      }
      setShowArchived(show)
      return
    }

    setVisibleTasks({
      'To-Do': tasks['To-Do'].sort(sortPriority),
      'In-Progress': tasks['In-Progress'].sort(sortPriority),
      Complete: tasks.Complete.sort(sortPriority),
    })
    setShowArchived(show)
    /* Reset filters */
    const newFilters = Object.fromEntries(Object.keys(filters).map((key) => [key, true]))
    setFilters(newFilters)
  }

  return (
    <IonPage>
      <PageHeader title='Tasks' />
      <IonContent style={{
        '--padding-top': '20px',
        '--padding-bottom': '20px',
        '--padding-start': '20px',
        '--padding-end': '20px',
      }}
      >
        <KanbanControls
          editing={editing}
          setEditing={setEditing}
          setShowAddTask={setShowAddTask}
          setVisibleTasks={setVisibleTasks}
          visibleTasks={visibleTasks}
          showArchived={showArchived}
          filterTasks={filterTasks}
          filters={filters}
          setFilters={setFilters}
          setFocussedTask={setFocussedTask}
          showHideArchived={showHideArchived}
          sortPriority={sortPriority}
          loading={loading}
          listView={listView}
          setListView={setListView}
        />
        {
          (showArchived) ? (
            <>
              {
                (listView) ? (
                  <ArchiveList
                    tasks={visibleArchived}
                  />
                ) : (
                  <ArchivedView
                    tasks={visibleArchived}
                    setFocussedTask={setFocussedTask}
                  />
                )
              }
              {
                focussedTask
                && (
                  <>
                    <ExpandTask
                      task={focussedTask}
                      showArchived={showArchived}
                      onClose={() => setFocussedTask(false)}
                      updateTask={updateTask}
                      changeStatus={changeStatus}
                      archiveTask={archiveItem}
                      deleteTask={deleteTask}
                    />
                    <ObscureBackground />
                  </>
                )
              }
            </>
          ) : (
            <>
              {
                (listView) ? (
                  <TaskList
                    tasks={visibleTasks}
                    editing={editing}
                    loading={loading}
                    changeStatus={changeStatus}
                    updateTasks={updateMultipleTasks}
                    deleteTasks={deleteTasks}
                  />
                ) : (
                  <KanbanBoard
                    tasks={visibleTasks}
                    setTasks={setTasks}
                    showAddTask={showAddTask}
                    setShowAddTask={setShowAddTask}
                    showArchived={showArchived}
                    changeStatus={changeStatus}
                    updateTask={updateTask}
                    archiveTask={archiveItem}
                    addNewTask={addNewTask}
                    setFocussedTask={setFocussedTask}
                    focussedTask={focussedTask}
                    deleteTask={deleteTask}
                    loading={loading}
                    users={users}
                  />
                )
              }
            </>
          )
        }
      </IonContent>
    </IonPage>
  )
}

export default TasksApp
