/* eslint-disable no-trailing-spaces */
import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

import img1 from './TaskSort1.jpg'
import img2 from './TaskSort2.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const TasksSort = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-tasks')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' routerLink='/helpcenter-insights' onClick={handleBack} />Tasks Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Sorting/Filters with Tasks
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonText>Sorting and filtering are useful tools for organising your tasks within the Tasks App. Sorting allows for the ordering of tasks by due date, 
                  whilst filtering allows you to view only specific tasks which may be more relevant than others.
                </IonText>
                <IonText><br />Sorting<br /><br /></IonText>
                <IonText>Step 1.<br />You will be able to arrange tasks on your ‘To-Do’ list by the date they are due. To do this, click on the ‘Sort by’ button in the app. </IonText>
                <IonText><br /><br />Step 2.<br />This will bring up two options: ‘Date Due – Ascending’ and ‘Date Due – Descending’. Simply click on the option you would prefer, 
                  and your ‘To-Do’ list will rearrange automatically according to your preference.
                </IonText>
                <IonText>‘Ascending’ will arrange your tasks so that those due most imminently appear at the top of your ‘To-Do’ list. By contrast, sorting by ‘Descending’ 
                  order will arrange your tasks such that those due furthest in the future will be at the top of your ‘To-Do’ list.
                </IonText>
                <IonImg src={img1} />
                <IonText><br /><br />Filtering<br /></IonText>
                <IonText>Filtering allows you to view specific tasks by filtering out others which may not be as relevant.</IonText>
                <IonText><br />Step 1.<br /><br />To start the process of filtering tasks, click on the ‘Filter’ button. This will bring up several options, each of which is explained below.</IonText>
                <IonText><br />Step 2.<br /><br />From the options, select your preferences. Only the tasks which match the preferences you selected will appear in your Task App; 
                  the others will automatically be filtered out. One or multiple preferences can be selected, and in different combinations. For instance, to only view ‘Your Tasks’ related 
                  to ‘Insights’, the combination below would be selected, and only tasks with these characteristics would appear in the app.
                </IonText>
                <IonImg src={img2} />

                <IonText><br />The filter options are briefly explained below:</IonText>

                <IonText><br />-  ‘Toggle all on/off’ – this selects/deselects all the options from the filter drop-down menu.</IonText>
                <IonText><br />-  ‘My Tasks’ – This will filter tasks that you need to complete/tasks that were assigned to you.</IonText>
                <IonText><br />-  ‘Tasks I Assigned’ – This will filter the tasks that you added and assigned to other colleagues.</IonText>
                <IonText><br />-  ‘Insights’ – This will filter all tasks related to the ‘Insights’ app.</IonText>
                <IonText><br />-  ‘Journeys’ – This will filter all tasks related to the ‘Journeys’ app.</IonText>
                <IonText><br />-  ‘Misc.’ – This will filter other tasks i.e., those not related to ‘Insights’ or ‘Journeys’</IonText>

              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-use'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Using the Tasks App
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-addtask'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Adding a Task
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-edit'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Editing/Deleting a Task
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default TasksSort
