import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { useReportsData } from '../../hooks/reports/useReportsData'
import { useWorkspace } from '../../hooks/useWorkspace'

import { SimpleButton } from '../maps/StyledContainers'

import useApi from '../../hooks/testHooks'

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const WeeklyReport1 = ({
  step, setStep, setIsShowing, end, start, active, inactive, unregistered, idx,
}) => {
  const { workspace } = useWorkspace()

  const {
    setShowWeeklyReport,
  } = useReportsData()

  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const handleClose = () => {
    setIsShowing(false)
    setShowWeeklyReport(false)
    setStep(1)
  }

  const apiHook = useApi()

  const handleDownload = () => {
    apiHook.weeklyReportPDF({
      domain_id: workspace.id,
      index: idx,
    })
      .then((result) => {
        apiHook.downloader2(result, `Weekly_Report_${moment(end).format('DD/MM/YY')}`)
      })
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '95%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Weekly Report - ${workspace.name}`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonLabel style={{ fontSize: '1em' }}>
            {`The following is your Weekly Report for ${moment(start).format('DD/MM/YY')} - ${moment(end).format('DD/MM/YY')}`}
          </IonLabel>
        </IonRow>

        <IonList>
          <IonRow style={{ marginLeft: '3.3em', marginTop: '1em' }}>
            <IonCol style={{ textAlign: 'left' }}>
              <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.15em' }}>User Activity</IonLabel>
              <IonLabel style={{ color: 'black', fontSize: '1.15em' }}>{` - ${moment(end).format('DD/MM/YY')}`}</IonLabel>
            </IonCol>
          </IonRow>
        </IonList>

        <IonRow class='ion-justify-content-center' style={{ width: '100%', height: '65%' }}>
          <IonCol size='4'>
            <IonGrid>
              <IonRow>
                <IonCol
                  size='12'
                  style={{
                    textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                  }}
                >
                  <IonLabel>Active Users</IonLabel>
                </IonCol>
              </IonRow>

              {
                active.map(
                  (val) => (
                    <IonRow>
                      <IonCol
                        size='12'
                        style={{
                          textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel>{`${val.first_name} ${val.final_name}`}</IonLabel>
                      </IonCol>
                    </IonRow>
                  ),
                )
              }

            </IonGrid>
          </IonCol>

          <IonCol size='4'>
            <IonGrid>
              <IonRow>
                <IonCol
                  size='12'
                  style={{
                    textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                  }}
                >
                  <IonLabel>In-Active Users</IonLabel>
                </IonCol>
              </IonRow>
              {
                inactive.map(
                  (val) => (
                    <IonRow>
                      <IonCol
                        size='12'
                        style={{
                          textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel>{`${val.first_name} ${val.final_name}`}</IonLabel>
                      </IonCol>
                    </IonRow>
                  ),
                )
              }
            </IonGrid>
          </IonCol>

          <IonCol size='4'>
            <IonGrid>
              <IonRow>
                <IonCol
                  size='12'
                  style={{
                    textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
                  }}
                >
                  <IonLabel>Not Yet Registered</IonLabel>
                </IonCol>
              </IonRow>
              {
                unregistered.map(
                  (val) => (
                    <IonRow>
                      <IonCol
                        size='12'
                        style={{
                          textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel>{val}</IonLabel>
                      </IonCol>
                    </IonRow>
                  ),
                )
              }
            </IonGrid>
          </IonCol>
        </IonRow>

        <IonRow style={{}} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev} disabled>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default WeeklyReport1
