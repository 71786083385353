const vulnerability = {
  'Very Low': 'Little-to-no opportunities for external threats to exploit. Security apparatus provides more than adequate measures to deter, detect, delay and deny a threat source.',
  Low: `External threats presented with a few opportunities to exploit. Security apparatus provides adequate measures to deter, detect, delay and deny a threat source,
  but areas exist for minor enhancements.`,
  Medium: `Moderate opportunities and minor gaps in security apparatus for external threats to exploit. Security apparatus provides adequate measures to deter, 
  detect, delay and deny a threat source, but areas exist for moderate enhancements.`,
  High: 'Sizeable number of opportunities and gaps in security apparatus for external threats to exploit. Security apparatus requires sizeable enhancements.',
  Extreme: 'Significant opportunities and gaps in security apparatus for external threat to exploit. Security apparatus requires significant enhancements.',
}

const people = {
  Insignificant: 'No injury or First Aid Case (FAC)',
  Minor: 'Medical Treatment Case (MTC), Restricted Work Case (RWC)',
  Moderate: 'Loss Time Incident (LTI)  < 4 / hospitalisation or debilitating injury',
  Major: 'A fatality (FAT) or 5 - 10 LTIs',
  Severe: 'Multiple FATs or 11+ LTIs',
}
const operations = {
  Insignificant: 'Negligible impact on operations. Potential disruption: < 1 week.',
  Minor: 'Impact on operations but able to be dealt with at site level. Potential disruption: < 1 month',
  Moderate: 'Impact causing disruption to wider business operations. Potential disruption: < 3 months.',
  Major: 'Key business operations compromised. Potential disruption: < 6 months.',
  Severe: 'Serious long lasting impact on operations. Potential disruption: > 6 months.',
}
const property = {
  Insignificant: 'Minor damage up to $6.5k or 5k euro',
  Minor: 'Moderate damage up to $65k or 50k euro',
  Moderate: 'Extensive damage up to  $320k or 250k euro',
  Major: 'Extensive damage over  $320k or 250k euro',
  Severe: 'Damage over $1.5m or 1m euro',
}

const threat = {
  'Very Low': 'It is highly unlikely that the source will carry out a risk event with the potential for impact on assets.',
  Low: 'It is unlikely that the source will carry out a risk event with the potential for impact on assets.',
  Medium: 'There is a moderate possibility that the source will carry out a risk event with the potential for impact on assets.',
  High: 'There is a significant possibility that the source will carry out a risk event with the potential for impact on assets.',
  Extreme: 'There is a very strong possibility that the source will carry out a risk event with the potential for impact on assets.',
}

const capability = {
  'Very Low': 'Necessary resources & skill set is inaccessible to threat actors ¦ Significant internal and external constraints preventing an actions against the asset',
  Low: 'Necessary resources & skill set is potentially accessible to threat actors ¦ Numerous internal and external constraints preventing an actions against the asset',
  Medium: `Evidence that a capability is possessed by threat actors but is of a basic nature with limited effectiveness ¦ Some internal and external constraints
   preventing an action against the asset`,
  High: `Evidence that a sophisticated capability is possessed by threat actors but is likely to be in scarce supply ¦ Few internal and external constraints preventing
   an action against the asset`,
  Extreme: `Evidence that a sophisticated capability is possessed by threat actors and is in abundant supply ¦ No internal and external constraints preventing an
  action against the asset ¦ No specialist skill required`,
}

const intent = {
  'Very Low': 'Threat source has not expressly stated intent ¦ No history of source carrying out such actions ¦ Very limited reward for action.',
  Low: 'Threat sources has not expressly stated intent, it may be implied ¦ No history of sources carrying out such actions ¦ Minimal reward for action',
  Medium: 'Threat sources has not expressly stated intent, it may be implied ¦ Limited history of source carrying out such actions ¦ Moderate reward for action',
  High: `Threat sources has expressly stated intent ¦ Sporadic examples where threat actors have engaged in this activity against similar entities to the subject ¦
   High reward for action`,
  Extreme: `Threat sources has expressly stated intent ¦ Numerous examples where threat actors have engaged in this activity against similar entities to the subject ¦ 
  Very high reward for action`,
}

const threatLikelihood = {
  1: 'May occur only in exceptional circumstances',
  2: 'Not likely to occur within the review period',
  3: 'Might occur at some time',
  4: 'Will probably occur in most circumstances',
  5: 'Is expected to occur in most circumstances',
}

const impactRating = {
  Insignificant: 'The event would have an insignificant impact on the project, with no measureable effect on its capability.',
  Minor: 'The event would have a minor impact on the project but would not reduce its capability to function.',
  Moderate: 'The event would have a moderate impact on the project that reduces its capability to achieve objectives and perform functions over the short term.',
  Major: 'The event would have a major impact that affects one or more parts of the project over a short term period preventing its capability to function.',
  Severe: 'The event would have an extreme impact reducing or removing the capability of the project to function.',
}

export {
  people,
  operations,
  property,
  vulnerability,
  threat,
  capability,
  intent,
  threatLikelihood,
  impactRating,
}
