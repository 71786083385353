import { IonCol, IonRow, IonContent } from '@ionic/react'
import React, { useState } from 'react'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { useAnalystAppData } from '../../hooks/analyst/useAnalystData'
import { ListContainer } from '../consultant/StyledContainers'
import { WidgetTitles } from '../maps/WidgetContainers'

/**
 *  ====================================
 *         INCIDENT LIST VIEW
 *  ====================================
 */
const IncidentList = () => {
  /* Retrieved friltered incidents  from application hook */
  const { incidentsList } = useAnalystAppData()
  const [sortedBy, setSortedBy] = useState({ field: '' })
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Sort counties by field and ascending or descending order */
  const sortIncidents = (incidentA, incidentB) => {
    if (sortedBy.ascending) {
      if (incidentA[sortedBy.field] >= incidentB[sortedBy.field]) {
        return 1
      }
      return -1
    }

    if (incidentB[sortedBy.field] >= incidentA[sortedBy.field]) {
      return 1
    }
    return -1
  }

  /** Sorting UX and functionality */
  const getArrow = () => ((sortedBy.ascending) ? (
    <Icon icon='fluent:arrow-sort-down-16-filled' />
  ) : (
    <Icon icon='fluent:arrow-sort-up-16-filled' />
  ))

  const handleTitle = (fieldName) => {
    /* If this field has already been selected then revert the order */
    if (sortedBy.field === fieldName) {
      setSortedBy({ ...sortedBy, ascending: !sortedBy.ascending })
      return
    }

    setSortedBy({
      field: fieldName,
      ascending: true,
    })
  }

  return (
    <ListContainer style={{ height: '100%' }}>
      <WidgetTitles style={{ height: '10%' }}>
        <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('username')} className='ion-text-center' size={2}>
            Submitted By
            {
              sortedBy.field === 'username' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('reported')} className='ion-text-center' size={2}>
            Date
            {
              sortedBy.field === 'reported' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('incident_type')} className='ion-text-center' size={2}>
            Incident Type
            {
              sortedBy.field === 'incident_type' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('fatalities')} className='ion-text-center' size={1}>
            Fatalities
            {
              sortedBy.field === 'fatalities' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('description')} className='ion-text-center' size={3}>
            Description
            {
              sortedBy.field === 'description' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('updated_at')} className='ion-text-center' size={2}>
            Updated At
            {
              sortedBy.field === 'updated_at' && getArrow()
            }
          </IonCol>
        </IonRow>
      </WidgetTitles>
      <div style={{ height: '90%' }}>
        <IonContent style={{ '--background': 'white' }}>
          {
            incidentsList.sort(sortIncidents).map((incident, index) => (
              <IonRow
                key={incident.id}
                style={{ backgroundColor: getColor(index), height: '10%' }}
                className='ion-align-items-center'
              >
                <IonCol className='ion-text-center' size={2}> { `${incident.user.first_name} ${incident.user.final_name}` } </IonCol>
                <IonCol className='ion-text-center' size={2}>{ moment(incident.reported).format('DD/MM/YY') }</IonCol>
                <IonCol className='ion-text-center' size={2}>{ incident.incident_type }</IonCol>
                <IonCol className='ion-text-center' size={1}>{ incident.fatalities }</IonCol>
                <IonCol size={3}> { incident.description } </IonCol>
                <IonCol className='ion-text-center' size={2}> { moment(incident.updated_at).format('DD/MM/YY') } </IonCol>
              </IonRow>
            ))
          }
        </IonContent>
      </div>
    </ListContainer>
  )
}

export default IncidentList
