import { User } from '../../../components/types/GlobalTypes'

export enum JourneyViews {
  TRANSIT = 'Transit',
  ROUTES = 'Routes',
  AIR_TRAVEL = 'Air Travel',
  JOURNEY = 'Journey',
  ROAD_TRAVEL = 'Road Travel',
  HISTORY = 'History',
  QUICKGO = 'QuickGo Journeys',
}

export enum TrackRequestStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
}

export interface TrackedUsersState {
  toggleAll: boolean,
  users: (User & { checked: boolean, status?: TrackRequestStatus })[]
  domainUsers: (User & { checked: boolean, status?: TrackRequestStatus })[]
}

export enum TrackedUsersActionKind {
  SELECT_ONE_DOMAIN = 'SELECT ONE',
  SELECT_ONE_REST = 'SELECT ONE REST',
  TOGGLE_ALL = 'TOGGLE ALL',
  POPULATE_LIST = 'POPULATE LIST',
}

export interface TrackedUsersAction {
  type: TrackedUsersActionKind,
  userId?: number,
  domainUsers?: (User & { status?: TrackRequestStatus })[],
  allUsers?: (User & { status?: TrackRequestStatus })[],
}

export const trackedUsersReducer = (state: TrackedUsersState, action: TrackedUsersAction) : TrackedUsersState => {
  const {
    type, userId, domainUsers, allUsers,
  } = action

  switch (type) {
    case TrackedUsersActionKind.SELECT_ONE_DOMAIN: {
      const copy = state.domainUsers.slice()
      const index = copy.findIndex(({ id }) => id === userId)
      copy.splice(index, 1, { ...copy[index], checked: !copy[index].checked })

      const toggleAll = copy.every(({ checked }) => checked)
      return { ...state, toggleAll, domainUsers: copy }
    }
    case TrackedUsersActionKind.SELECT_ONE_REST: {
      const copy = state.users.slice()
      const index = copy.findIndex(({ id }) => id === userId)
      copy.splice(index, 1, { ...copy[index], checked: !copy[index].checked })

      return { ...state, users: copy }
    }
    case TrackedUsersActionKind.TOGGLE_ALL: {
      const copy = state.domainUsers.map((val) => ({ ...val, checked: !state.toggleAll }))
      return { ...state, toggleAll: !state.toggleAll, domainUsers: copy }
    }
    case TrackedUsersActionKind.POPULATE_LIST: {
      const copy = domainUsers.map((val) => ({ ...val, checked: !((!val.status)) }))
      const rest = allUsers.filter((val) => !domainUsers.find(({ id }) => val.id === id))
      return { users: rest.map((val) => ({ ...val, checked: !((!val.status)) })), toggleAll: false, domainUsers: copy }
    }
    default:
      return { ...state }
  }
}
