/* eslint-disable consistent-return */
import React from 'react'
import { IonContent, IonCol, useIonAlert } from '@ionic/react'
import styled from 'styled-components'
import TaskCard from './TaskCard'

const KanbanTitle = styled.div`
  width: 100%;
  background-color: #326771;
`

/**
 *  =============================
 *      Task Cards Container
 *  =============================
 *
 *  There's one column for every statu
 */
const KanbanColumn = ({
  tasks, columnName, expand, showArchived, changeStatus,
}) => {
  const [ionAlert] = useIonAlert()
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (showArchived) {
      return ionAlert({
        header: 'Error',
        message: 'This task has been archived',
        buttons: [
          { text: 'Ok' },
        ],
      })
    }

    const id = e.dataTransfer.getData('id')

    changeStatus(id, columnName)
  }

  return (
    <IonCol id={`column-${columnName}`} onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} className='kanban-column' style={{ height: '100%' }}>
      <IonContent style={{ '--ion-background-color': '#326771' }}>
        <KanbanTitle style={{ visibility: 'hidden' }}>
          <h2>{ columnName }</h2>
        </KanbanTitle>
        <KanbanTitle slot='fixed'>
          <h2>{ columnName }</h2>
        </KanbanTitle>
        {
          tasks[columnName].map((task) => (
            <TaskCard key={task.id} {...task} expand={expand} status={columnName} showArchived={showArchived} />
          ))
        }
      </IonContent>
    </IonCol>
  )
}

export default KanbanColumn
