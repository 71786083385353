import styled from 'styled-components'

const HeaderContainer = styled.div`
  padding: 10px;
  margin: 20px 0;

  & h3 {
    color: #8E151F;
    font-size: 1.2rem;
    margin: 0
  }
`

const MobileButtons = styled.div`

  padding: 10px 20px;

  & h4 {
    font-size: 1rem;
    color: #326771;
    border-bottom: .5px solid #747474;
  }

  & .mobile-buttons{
    padding: 20px 0;
  }

  & button {
    width: 90%;
    display: block;
    margin: 15px auto;
    background-color: #326771;
    color: white;
    padding: 10px 0;
    border-radius: 2.5px;
  }
`

const MobileButton = styled.button`
  color: white;
  background-color: transparent;
  border: none;

  font-size: 1.2rem;
`

const JourneysContainer = styled.div`
  margin: 20px 0;

  & h4 {
    border-bottom: .5px solid #747474;
    font-size: 1.1rem;
  }

  & h4.transit {
    color: #0C9500;
  }

  & h4.pre-departure {
    color: #FF8A00;
  }

  & h4.pending {
    color: #DD2800;
  }

  p.none {
    color: #747474;
    font-size: 1.1rem;
    margin: 0;
    padding: 20px 0;
    text-align: center;
  }
`

const JourneyButton = styled.button`
  width: 100%;
  background-color: #B3D5DD;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 2.5px;
  margin: 15px 0;

  padding: 10px 0;

  & p {
    margin: 0;
  }
`

const SelectStatus = styled.select`
  padding: 10px;
  text-align: center;
  width: 90%;
  display: block;
  margin: 0 auto;
`

export {
  HeaderContainer,
  MobileButtons,
  MobileButton,
  JourneysContainer,
  JourneyButton,
  SelectStatus,
}
