/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react'
import {
  IonRow, IonCol, IonCheckbox, useIonAlert,
} from '@ionic/react'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { ListContainer } from './StyledContainers'

import { WidgetTitles } from '../maps/WidgetContainers'
import { getCurrentLevel } from '../maps/apps/AppMap'
import { BlueButton } from '../incident-management/StyledContainers'

const scrollerDefault = { prev: 0, next: 20 }

/**
 *  ============================
 *      Alert State List View
 *  ============================
 */
const AlertList = ({
  countyList, selected, dispatchSelected, suggestedReports, setSelectedCounty,
}) => {
  const [renderedCounties, setRenderedCounties] = useState([])
  const [sortedCounties, setSortedCounties] = useState([])
  const [count, setCount] = useState({ prev: 0, next: 20 })
  const [sortedBy, setSortedBy] = useState({ field: 'region', ascending: true })
  const [ionAlert] = useIonAlert()
  /* Alternatively could loop through the array and check how many counties have the checked attr set to true.
    However, there are going to be a considerable amount of counties meaning that every time the array is looped a small
    delay can be created.
  */
  const listInnerRef = useRef()
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  const sortCounties = (listOfCounties, sortParams = sortedBy) => listOfCounties.sort((countyA, countyB) => {
    if (sortParams.ascending) {
      if (countyA[sortParams.field] >= countyB[sortParams.field]) {
        return 1
      }
      return -1
    }

    if (countyB[sortParams.field] >= countyA[sortParams.field]) {
      return 1
    }
    return -1
  })

  useEffect(() => {
    /* Filter counties based on the alert states */
    const updatedCount = { ...scrollerDefault }
    const sorted = sortCounties(countyList)
    setSortedCounties(sorted)
    setCount(updatedCount)
    setRenderedCounties(sorted.slice(updatedCount.prev, updatedCount.next))
  }, [countyList])

  const getMoreCounties = () => {
    const updatedCount = { prev: count.prev + 20, next: count.next + 20 }
    setRenderedCounties(renderedCounties.concat(sortedCounties.slice(updatedCount.prev, updatedCount.next)))
    setCount(updatedCount)
  }

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
    if (scrollTop + clientHeight === scrollHeight && renderedCounties.length < countyList.length) {
      getMoreCounties()
    }
  }

  /** Sorting UX and functionality */
  const getArrow = () => ((sortedBy.ascending) ? (
    <Icon icon='fluent:arrow-sort-down-16-filled' />
  ) : (
    <Icon icon='fluent:arrow-sort-up-16-filled' />
  ))

  const handleTitle = (fieldName) => {
    /* If this field has already been selected then revert the order */
    const sortParams = (sortedBy.field === fieldName) ? { ...sortedBy, ascending: !sortedBy.ascending } : {
      field: fieldName,
      ascending: true,
    }
    setSortedBy(sortParams)

    const sorted = sortCounties(countyList, sortParams)
    const updatedCount = { ...scrollerDefault }

    setSortedCounties(sorted)
    setCount(updatedCount)
    setRenderedCounties(sorted.slice(updatedCount.prev, updatedCount.next))
  }

  /** Handle multiple selections for alert state change */
  const handleMultiSelection = (e, county) => {
    if (e.target.checked) {
      if (selected.ids.length >= 7) {
        ionAlert({
          header: 'Error - Number of counties exceeded',
          message: 'You can only edit 7 different counties at the same time.',
          buttons: [
            { text: 'Ok' },
          ],
        })
        /* This'll trigger the default case which will just trigger a re-render, needed to untoggle the ion-checkbox */
        dispatchSelected({ type: 'refresh' })
        return
      }
      dispatchSelected({ type: 'select_county', payload: county })
      return
    }
    dispatchSelected({ type: 'unselect_county', payload: county })
  }

  const getASIndicator = (currentAs, suggestedAs) => {
    if (currentAs > suggestedAs) {
      return (<Icon style={{ color: '#0C9500' }} icon='entypo:triangle-down' />)
    }

    if (currentAs < suggestedAs) {
      return (<Icon style={{ color: '#C30101' }} icon='entypo:triangle-up' />)
    }
    return <Icon style={{ color: '#FF7A00' }} icon='entypo:triangle-right' />
  }

  return (
    <ListContainer style={{ height: '100%', background: 'white' }}>
      <div style={{ height: '100%', overflowY: 'auto' }} ref={listInnerRef} onScroll={handleScroll}>
        <WidgetTitles style={{
          height: '10%', position: 'sticky', top: 0, zIndex: 10, paddingRight: 0,
        }}
        >
          <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
            <IonCol className='ion-text-center' size={1} />
            <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('region')} className='ion-text-center' size={2}>
              State
              {
              sortedBy.field === 'region' && getArrow()
            }
            </IonCol>
            <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('county')} className='ion-text-center' size={2}>
              County
              {
              sortedBy.field === 'county' && getArrow()
            }
            </IonCol>
            <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('Alert_state')} className='ion-text-center' size={2}>
              Current Alert State
              {
              sortedBy.field === 'Alert_state' && getArrow()
            }
            </IonCol>
            <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('crowd_alert')} className='ion-text-center' size={2}>
              Crowd Insights
              {
              sortedBy.field === 'crowd_alert' && getArrow()
            }
            </IonCol>
            <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('average_suggestion')} className='ion-text-center' size={2}>
              Average Suggested Level
              {
              sortedBy.field === 'average_suggestion' && getArrow()
            }
            </IonCol>
            <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('modified')} className='ion-text-center' size={1}>
              Last Modified
              {
              sortedBy.field === 'modified' && getArrow()
            }
            </IonCol>
          </IonRow>
        </WidgetTitles>
        {
          renderedCounties.map((val, index) => (
            <IonRow
              key={val.id}
              style={{ backgroundColor: getColor(index), minHeight: '10%', padding: '10px 0' }}
              className='ion-align-items-center consultants-as-list'
            >
              <IonCol className='ion-text-center' size={1}>
                <IonCheckbox checked={!!(selected.ids.indexOf(val.id) >= 0)} onClick={(e) => handleMultiSelection(e, val)} />
              </IonCol>
              <IonCol className='ion-text-center' size={2}> { val.region } </IonCol>
              <IonCol className='ion-text-center' size={2}>{ val.county }</IonCol>
              <IonCol className='ion-text-center' size={2}>{ `Alert State ${val.Alert_state} - ${getCurrentLevel(val.Alert_state).name}` }</IonCol>
              <IonCol className='ion-text-center' size={2}>
                { `Alert State ${val.crowd_alert} - ${getCurrentLevel(val.crowd_alert).name}` } { getASIndicator(val.Alert_state, val.crowd_alert) }
              </IonCol>
              <IonCol className='ion-text-center' size={2}>
                {
                  suggestedReports[val.id] ? (
                    <BlueButton onClick={() => setSelectedCounty(val)}>
                      {`Alert State ${val.average_suggestion} - ${getCurrentLevel(val.average_suggestion).name}`} <Icon style={{ color: '#008AEE' }} icon='bi:box-arrow-in-up-right' />
                    </BlueButton>
                  ) : (
                    <>{ `Alert State ${val.average_suggestion} - ${getCurrentLevel(val.average_suggestion).name}` }</>
                  )
                }
                {
                  getASIndicator(val.Alert_state, val.average_suggestion)
                }
              </IonCol>
              <IonCol className='ion-text-center' size={1}> { moment(val.modified).format('DD/MM/YY') } </IonCol>
            </IonRow>
          ))
        }
      </div>
    </ListContainer>
  )
}

export default AlertList
