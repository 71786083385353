/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
  IonRow, IonContent, IonCol, IonButton,
} from '@ionic/react'
import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import moment from 'moment'
import { FrappeGantt } from 'frappe-gantt-react'
import { CircularProgressbar } from 'react-circular-progressbar'

import { ListContainer } from '../consultant/StyledContainers'
import { WidgetTitles } from '../maps/WidgetContainers'
import { BlueButton } from '../incident-management/StyledContainers'
import 'react-circular-progressbar/dist/styles.css'

/**
   *  =============================
   *      TabBar to change views
   *  =============================
   */
const ProjectHome = ({
  setCreating, internalProjects, externalProjects, completedProjects, setFocussed, setEditing,
  setView,
}) => {
  const [showChart, setShowChart] = useState(false)

  const handleClick = (id) => {
    setFocussed(id)
    setView('Details')
    setEditing(false)
  }

  const handleCreate = () => {
    setCreating(true)
    setView('Details')
  }

  function getStatusColor(status) {
    if (status === 'Planning') {
      return '#326771'
    } if (status === 'Complete') {
      return '#0C9500'
    }
    return '#FF8A00'
  }

  const projects = [...internalProjects, ...externalProjects, ...completedProjects]

  return (
    <ListContainer>
      <IonRow>
        <IonCol style={{
          color: '#8E151F', fontWeight: 'bold', fontSize: '18px', paddingLeft: '40px', paddingTop: '20px',
        }}
        >My Projects
        </IonCol>
        <IonCol
          style={{ textAlign: 'right', paddingRight: '40px', paddingTop: '20px' }}
        >
          <IonButton style={{ '--background': '#0C9500', textTransform: 'none' }} onClick={() => handleCreate()}>New Project +</IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        {
          showChart && (
            <IonCol style={{
              textAlign: 'right', color: '#8E151F', paddingRight: '60px', paddingTop: '20px', fontSize: '16px',
            }}
            >
              <IonButton style={{ '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF', '--box-shadow': 'none' }} onClick={() => { setShowChart(false) }}>
                <p style={{
                  margin: '0', fontSize: '16px', color: '#8E151F', 'text-transform': 'none',
                }}
                >List View<Icon icon='ant-design:bar-chart-outlined' color='#326771' width='25' height='25' style={{ paddingLeft: '5px' }} />
                </p>
              </IonButton>
            </IonCol>
          )
        }
        {
          !showChart && (
            <IonCol style={{
              textAlign: 'right', color: '#8E151F', paddingRight: '30px', paddingTop: '5px', fontSize: '16px',
            }}
            >
              <IonButton style={{ '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF', '--box-shadow': 'none' }} onClick={() => { setShowChart(true) }}>
                <p style={{
                  margin: '0', fontSize: '16px', color: '#8E151F', 'text-transform': 'none',
                }}
                >Chart View<Icon icon='ant-design:bar-chart-outlined' color='#326771' width='25' height='25' style={{ paddingLeft: '5px' }} />
                </p>
              </IonButton>
            </IonCol>
          )
        }
      </IonRow>
      {
          !showChart && (
            <WidgetTitles style={{ height: '10%', paddingRight: '15', paddingLeft: '15px' }}>
              <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                <IonCol className='ion-text-center' size={4} style={{ fontWeight: 'bold' }}>Project Title</IonCol>
                <IonCol className='ion-text-center' size={2} style={{ fontWeight: 'bold' }}>Project Category</IonCol>
                <IonCol className='ion-text-center' size={1.5} style={{ fontWeight: 'bold' }}>Start Date</IonCol>
                <IonCol className='ion-text-center' size={1.5} style={{ fontWeight: 'bold' }}>End Date</IonCol>
                <IonCol className='ion-text-center' size={2} style={{ fontWeight: 'bold' }}>Status</IonCol>
              </IonRow>
            </WidgetTitles>
          )
        }
      <div style={{ height: showChart ? '80%' : '70%' }}>
        {
          !showChart && (
            <IonContent style={{ '--background': 'white', paddingRight: '0', paddingLeft: '0' }}>
              {
              projects.map((project) => (
                <IonRow
                  key={project.id}
                  style={{
                    margin: '25px 10px', border: '1px solid black', borderRadius: '2.5px', padding: '10px',
                  }}
                  className='ion-align-items-center'
                >
                  <IonCol className='ion-text-center' size={4}>
                    <BlueButton onClick={() => handleClick(project.id)}>
                      { project.title }
                    </BlueButton>
                  </IonCol>
                  <IonCol className='ion-text-center' size={2}>{project.category}</IonCol>
                  <IonCol className='ion-text-center' size={1.5}>{moment(project.start).format('DD/MM/YY')}</IonCol>
                  <IonCol className='ion-text-center' size={1.5}>{moment(project.deadline).format('DD/MM/YY')}</IonCol>
                  <IonCol className='ion-text-center' style={{ backgroundColor: getStatusColor(project.status), color: 'white' }} size={2}>{project.status}</IonCol>
                  <IonCol size={1}>
                    <div style={{ width: 50, height: 50 }}>
                      <CircularProgressbar
                        value={project.status === 'Complete' ? 100 : project.status === 'In-Progress' ? 50 : 0}
                        text={project.status === 'Complete' ? '100%' : project.status === 'In-Progress' ? '50%' : '0%'}
                        strokeWidth={15}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              ))
            }
            </IonContent>
          )
        }
        {
          showChart && (
            <IonContent style={{ '--background': 'white' }}>
              {
                projects.length > 0 ? (
                  <FrappeGantt
                    tasks={projects.map((project) => ({
                      id: project.id, name: project.title, start: new Date(project.start || project.startDate), end: new Date(project.endDate), progress: null,
                    }))}
                    // viewMode={this.state.mode}
                    onClick={(task) => handleClick(task.id)}
                  />
                ) : (
                  <p style={{ textAlign: 'center' }}>You have no projects</p>
                )
              }
            </IonContent>
          )
        }
      </div>
    </ListContainer>

  )
}

export default ProjectHome
