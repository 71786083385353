import React, { useEffect, useState } from 'react'
import {
  IonItem, IonGrid, IonRow, IonCol, IonButton, IonContent, IonLabel, IonList, IonListHeader,
  IonSelect, IonSelectOption, useIonAlert, IonInput,
} from '@ionic/react'

import {
  MapInstructions, InstructionButtons, SimpleButton,
} from '../StyledContainers'
import { SettingField } from '../../settings/StyledContainers'
import { ColumnContainer, MeasureContainer } from '../../riskregister/StyledContainers'

/**
 *  ===========================
 *      Movement Box Form
 *  ===========================
 *
 *  Pop up displayed everytime a Movement Box has to be registered
 */

const defaultVals = {
  review: undefined,
  description: '',
}

const MBform = ({
  onCancel, onSubmit, canFillForm, fillDetails = false, selectedMeasures,
  onRemoveMarker, markers, defaultInput = defaultVals,
  startEdit, editing = true, edit = false, deleteMb, showRestrictions = () => {},
}) => {
  const [ionAlert] = useIonAlert()
  const [fillForm, setFillForm] = useState(fillDetails)
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Component states */
  const [input, setInput] = useState(defaultInput)

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  const translateDate = (days) => ({
    1: '1 day',
    7: '1 Week',
    14: '2 Weeks',
    30: '4 Weeks',
  }[days])

  const handleSubmit = () => {
    if (!input.review) {
      return ionAlert({
        header: 'Error',
        message: 'Please specify a review period',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    return onSubmit(input)
  }

  const handleDeleteMb = () => {
    ionAlert({
      header: 'Delete Restriction Box?',
      message: 'Are you sure you want to delete this restriction box?',
      buttons: [
        'No',
        {
          text: 'Yes',
          handler: () => deleteMb(),
        },
      ],
    })
  }

  useEffect(() => {
    setInput(defaultInput)
  }, [defaultInput])

  return (
    <>
      {
        (fillForm)
          ? (
            <>
              <IonContent style={{ height: '85%' }}>
                <IonList>
                  <IonListHeader>
                    <IonRow className='ion-align-items-center ion-justify-content-between' style={{ width: '90%' }}>
                      <SettingField style={{ width: 'auto' }}> <h4 style={{ margin: '0' }}> Restriction Box </h4> </SettingField>
                      <SimpleButton
                        onClick={() => onCancel(true)}
                      >Close X
                      </SimpleButton>
                    </IonRow>
                  </IonListHeader>
                  <div style={{ padding: '0 10px' }}>
                    <p style={{ marginTop: '0' }}>Use the map to drag and drop pins at the vertices of the polygon</p>
                  </div>
                </IonList>
                <IonList>
                  <IonListHeader>
                    <SettingField> <h5 className='blue'>Restrictions</h5> </SettingField>
                  </IonListHeader>
                  <div style={{ padding: '20px' }}>
                    <ColumnContainer>
                      {
                        selectedMeasures.map((val, index) => (
                          <MeasureContainer
                            className='single-control-measure'
                            key={val.id}
                            draggable
                            style={{ backgroundColor: getColor(index) }}
                          >
                            <p> {val.control_measure} </p>
                          </MeasureContainer>
                        ))
                      }
                    </ColumnContainer>
                  </div>
                  <IonRow className='ion-justify-content-end' style={{ paddingRight: '20px' }}>
                    {
                      editing && (
                        <IonButton style={{ '--background': '#0C9500' }} onClick={showRestrictions}>Add</IonButton>
                      )
                    }
                  </IonRow>
                </IonList>
                <IonList>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonLabel>Review period: </IonLabel>
                          {
                          (editing) ? (
                            <IonSelect name='review' value={input.review} placeholder='Select One' onIonChange={handleChange} required>
                              <IonSelectOption value={1}>1 Day</IonSelectOption>
                              <IonSelectOption value={7}>1 Week</IonSelectOption>
                              <IonSelectOption value={14}>2 Weeks</IonSelectOption>
                              <IonSelectOption value={30}>4 Weeks</IonSelectOption>
                            </IonSelect>
                          ) : (
                            <SettingField style={{ width: 'auto' }}> <p id='restriction-box-review' style={{ padding: '0 20px', margin: '0' }}> { translateDate(input.review) } </p> </SettingField>
                          )
                        }
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonList>
                <IonList>
                  <IonListHeader>
                    <SettingField> <h5 className='blue'>Description (Optional)</h5> </SettingField>
                  </IonListHeader>
                  {
                  (editing) ? (
                    <IonItem>
                      <IonInput
                        type='text'
                        name='description'
                        id='restriction-box-description'
                        value={input.description}
                        placeholder='Add a description for this restriction box'
                        onIonChange={handleChange}
                      />
                    </IonItem>

                  ) : (
                    <SettingField style={{ width: 'auto' }}> <p id='restriction-box-description' style={{ padding: '0 20px' }}> { input.description } </p> </SettingField>
                  )
                }
                </IonList>
              </IonContent>
              {
                (!edit) ? (
                  <IonRow
                    style={{
                      alignItems: 'center', padding: '0 10px', height: '15%', backgroundColor: 'white',
                    }}
                    class='ion-justify-content-around'
                  >
                    <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel(true)}>Cancel</IonButton>
                    <IonButton id='submit-restriction-box' style={{ '--background': '#4197A9' }} onClick={handleSubmit}>Submit</IonButton>
                  </IonRow>
                ) : (
                  <IonRow
                    style={{
                      alignItems: 'center', padding: '0 10px', height: '15%', backgroundColor: 'white',
                    }}
                    class='ion-justify-content-around'
                  >
                    <IonRow>
                      <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel(true)}>Cancel</IonButton>
                      <IonButton id='delete-restriction-box' style={{ '--background': '#4197A9' }} onClick={handleDeleteMb}>Delete</IonButton>
                    </IonRow>
                    {
                      (!editing) ? (
                        <IonButton id='edit-restriction-box' style={{ '--background': '#4197A9' }} onClick={startEdit}>Edit</IonButton>
                      ) : (
                        <IonButton id='submit-restriction-box' style={{ '--background': '#4197A9' }} onClick={handleSubmit}>Submit</IonButton>
                      )
                    }
                  </IonRow>
                )
              }
            </>
          )
          : (
            <IonContent>
              <MapInstructions>
                <h2 style={{ color: '#326771' }}>Add Restriction Box</h2>
                <div style={{ marginTop: '20px', padding: '0 10px' }}>
                  <p>Click on the map to drop a pin in the area you wish to create a Restriction Box.</p>
                  <p>Draw a box around the area you wish to restrict by clicking the map and dropping pins.</p>
                  <p>Note: To close your Restriction Box, click on the first pin that you dropped. </p>
                </div>
                <InstructionButtons>
                  <IonRow className={(onRemoveMarker) ? 'ion-justify-content-between' : 'ion-justify-content-end'}>
                    {
                      onRemoveMarker && (
                        <IonButton style={{ '--background': '#4197A9' }} onClick={onRemoveMarker} disabled={!markers.length}>Undo Pin</IonButton>
                      )
                    }
                    <IonRow>
                      <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel(markers.length)}>Cancel</IonButton>
                      <IonButton id='next-restriction-box' style={{ '--background': '#0C9500' }} onClick={() => setFillForm(true)} disabled={!canFillForm}>Next</IonButton>
                    </IonRow>
                  </IonRow>
                </InstructionButtons>
              </MapInstructions>
            </IonContent>
          )
      }
    </>
  )
}

export default MBform
