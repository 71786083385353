import {
  IonList, IonInput, IonButton, IonRow,
} from '@ionic/react'
import moment from 'moment'
import React, { useState } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { generateChapter } from '../apps/AppMap'
import {
  AirTicketButton, StoryLine, TicketContainer, TicketEntry,
} from '../StyledContainers'
import useApi from '../../../hooks/testHooks'

/**
 *  ==============================
 *      DISPLAY  JOURNEY UPDATES
 *  ==============================
 */
const AirJourneyTicket = ({
  focusedJourney, setLoading, updateAirJourney, domainId, loading,
  scrollToBottom, onTransit, onComplete,
}) => {
  /* Get current user's data */
  const { user } = useAuth()
  const [comment, setComment] = useState('')
  const apiHook = useApi()

  const handleComment = () => {
    setLoading(true)

    apiHook.commentAirJourney(focusedJourney.id, comment, domainId)
      .then(({ story }) => {
        /* Create chapter instance to be pushed to the journey story */
        const chapter = generateChapter(story, 'comment', focusedJourney.id, user.user_id)
        const newStory = [...focusedJourney.story, chapter]
        updateAirJourney({ ...focusedJourney, story: newStory })

        /* Clean input area */
        setComment('')
        setLoading(false)
        scrollToBottom()
      })
  }

  return (
    <div className='flex-direction-column'>
      <div className='flex-grow'>
        <IonList style={{ padding: '15px', paddingTop: '0px' }}>
          <TicketContainer>
            <TicketEntry>
              <p><span>Pre Travel Request Submitter: </span> <span id='air-ticket-submitter'>{ `${focusedJourney.submitter.first_name} ${focusedJourney.submitter.final_name}` }</span></p>
            </TicketEntry>
            <TicketEntry>
              <p><span>From:</span><span id='air-ticket-origin'>{ focusedJourney.from }</span></p>
            </TicketEntry>
            <TicketEntry>
              <p><span>To:</span> <span id='air-ticket-destination'>{ focusedJourney.to }</span></p>
            </TicketEntry>
            <TicketEntry>
              <p><span>Date of Departure: </span> <span id='air-ticket-departure-date'>{ moment(focusedJourney.departure_date).format('DD/MM/YY') }</span></p>
            </TicketEntry>
            <TicketEntry>
              <p><span>Reasons for travel: </span> <span id='air-ticket-purpose'>{ focusedJourney.purpose }</span></p>
            </TicketEntry>
            <TicketEntry>
              <p><span>Additional comments: </span> <span id='air-ticket-comment'>{ focusedJourney.comment }</span></p>
            </TicketEntry>
          </TicketContainer>
          {
            (focusedJourney.story.filter(({ category }) => category.includes('Pre-Travel')).length > 0) && (
              <StoryLine style={{ marginBottom: (focusedJourney.stage === 'Pre-Travel') ? '0px' : '1rem' }}>
                {
                  focusedJourney.story.filter(({ category }) => category === 'Pre-Travel').map((chapter, index) => (
                    chapter.content.split('/n').map((storyLine) => (
                      <li
                        style={{
                          marginBottom: (focusedJourney.stage === 'Pre-Travel' && index + 1 === focusedJourney.story.filter(({ category }) => category === 'Pre-Travel').length) ? '0' : '10px',
                        }}
                        key={storyLine}
                      >
                        {
                          storyLine.split('/b').map((val) => (
                            <p style={{ margin: '0' }} key={val}>{val}</p>
                          ))
                        }
                      </li>
                    ))
                  ))
                }
              </StoryLine>
            )
          }
          {
            (focusedJourney.airline_name) && (
              <TicketContainer>
                <TicketEntry>
                  <p><span>Flight Request submitter: </span> <span id='air-ticket-flight-submitter'>{ `${focusedJourney.submitter.first_name} ${focusedJourney.submitter.final_name}` }</span></p>
                </TicketEntry>
                <TicketEntry>
                  <p><span>Airline:</span> <span id='air-ticket-airline-name'>{ focusedJourney.airline_name }</span></p>
                </TicketEntry>
                <TicketEntry>
                  <p><span>Flight No.:</span> <span id='air-ticket-flight-number'>{ focusedJourney.flight_no }</span></p>
                </TicketEntry>
                <TicketEntry>
                  <p><span>Date of Departure: </span> <span id='air-ticket-flight-departure-date'>{ moment(focusedJourney.flight_departure_date).format('DD/MM/YY') }</span></p>
                </TicketEntry>
                <TicketEntry>
                  <p><span>Passengers: </span> <span id='air-ticket-passengers'>{ focusedJourney.passengers }</span> </p>
                </TicketEntry>
              </TicketContainer>
            )
          }
          {
            (focusedJourney.story.filter(({ category }) => category !== 'Pre-Travel').length > 0) && (
              <StoryLine style={{ marginBottom: (focusedJourney.stage === 'Flight-Request') ? '0px' : '1rem' }}>
                {
                  focusedJourney.story.filter(({ category }) => category !== 'Pre-Travel').map((chapter, index) => (
                    chapter.content.split('/n').map((storyLine) => (
                      <li
                        style={{
                          marginBottom: (focusedJourney.stage === 'Flight-Request' && focusedJourney.story.filter(({ category }) => category !== 'Pre-Travel').length === index + 1) ? '0' : '10px',
                        }}
                        className='air-travel-ticket-entry'
                        key={storyLine}
                      >
                        {
                          storyLine.split('/b').map((val) => (
                            <p style={{ margin: '0' }} key={val}>{val}</p>
                          ))
                        }
                      </li>
                    ))
                  ))
                }
              </StoryLine>
            )
          }
        </IonList>
      </div>
      <div>
        {
          focusedJourney.submitter.id === user.user_id && (focusedJourney.stage === 'In-Transit' || focusedJourney.stage === 'Pre-Departure') && (
            <>
              <IonRow style={{ margin: '5px 20px' }}>
                <p className='journey-status' style={{ backgroundColor: '#326771', margin: '0 5px' }}>My Status: </p>
                <AirTicketButton
                  disabled={focusedJourney.stage !== 'Pre-Departure'}
                  className={`${((focusedJourney.stage !== 'Pre-Departure')) ? 'boarded' : 'not-boarded'}`}
                  onClick={onTransit}
                >Boarding Flight
                </AirTicketButton>
                <AirTicketButton
                  disabled={focusedJourney.stage !== 'In-Transit'}
                  className={`${focusedJourney.stage !== 'In-Transit' ? 'arrived' : 'not-arrived'}`}
                  onClick={onComplete}
                >
                  Arrived Safely
                </AirTicketButton>
              </IonRow>
              <IonInput type='text' value={comment} onIonChange={(e) => setComment(e.detail.value)} className='ticket-comment' placeholder='Comment' />
              <IonRow style={{ margin: '5px 20px' }} className='ion-justify-content-end'>
                <IonButton
                  id='publish-comment'
                  style={{
                    '--background': '#0C9500',
                    fontSize: '1.1em',
                    textTransform: 'none',
                    borderRadius: '0.2em',
                    letterSpacing: '-0.02em',
                  }}
                  disabled={loading}
                  onClick={handleComment}
                >Comment
                </IonButton>
              </IonRow>
            </>
          )
        }
      </div>
    </div>
  )
}

export default AirJourneyTicket
