import { Icon } from '@iconify/react'
import { IonButton, IonInput, IonRow } from '@ionic/react'
import React, { FC, useState } from 'react'
import moment from 'moment'
import { getDateString } from '../../helpers/GlobalFunctions'
import { FeedPost, PostReply } from '../types/GlobalTypes'
import { PostCardContainer } from './StyleContainers'
import axios from '../../utils/axios'

interface Props {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  data: FeedPost,
  onUpdate: (updated: FeedPost) => void
}

const PostCard : FC<Props> = ({ data, onUpdate, setLoading }) => {
  const [showComments, setShowComments] = useState<boolean>(false)
  const [commentText, setCommentText] = useState<string>('')

  const handleThumbsUp = () => {
    const likeUpdate = !data.has_liked
    const updatedPost : FeedPost = { ...data, has_liked: likeUpdate, thumbs_up: (likeUpdate) ? data.thumbs_up + 1 : data.thumbs_up - 1 }

    onUpdate(updatedPost)
    if (likeUpdate) {
      axios.post('/api/v1/feedpost/reactToFeedPost', { post_id: data.id })
      return
    }

    axios.put('/api/v1/feedpost/deleteReactionFromFeedPost', { post_id: data.id })
  }

  const handleSubmitComment = () => {
    setLoading(true)
    setCommentText('')

    axios.post('/api/v1/feedpost/commentFeedPost', { post_id: data.id, text: commentText }).then((response) => {
      if (!response.data.ok) {
        return
      }

      const reply : PostReply = response.data.comment
      const copy = data.post_replies.slice()
      copy.push(reply)

      onUpdate({ ...data, post_replies: copy })
    }).finally(() => {
      setLoading(false)
    })
  }

  const sortingFunction = (articleA: FeedPost, articleB: FeedPost) => {
    if (moment(articleA.created_at).isAfter(articleB.created_at)) {
      return -1
    }

    return 1
  }

  return (
    <PostCardContainer id={`post-id-${data.id}`}>
      <div className='post-content'>
        <IonRow className='ion-align-items-center' style={{ margin: '15px 0' }}>
          <h5>{ `${data.user.first_name} ${data.user.final_name}` }</h5>
          <p className='post-time'>{ getDateString(data.created_at) }</p>
        </IonRow>
        <p style={{ margin: '10px 0', color: 'black' }}>{ data.text }</p>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {
            data.img_urls.map(({ url }) => (
              <img key={url} src={url} alt='Post submission' className='incident-ticket-image' />
            ))
           }
        </div>
      </div>
      <IonRow style={{ padding: '5px' }} className='ion-justify-content-between'>
        <IonRow className='ion-align-items-center'>
          <IonButton
            style={{
              '--background': 'white',
              marginRight: '10px',
              color: (data.has_liked) ? '#4B99F4' : '#326771',
              display: 'flex',
              alignItems: 'center',
              textTransform: 'capitalize',
            }}
            onClick={handleThumbsUp}
          >
            <Icon icon='mdi:thumbs-up' />
            <p style={{ margin: 0 }}>Like</p>
          </IonButton>
          <IonButton
            style={{
              '--background': 'white',
              color: '#326771',
              display: 'flex',
              alignItems: 'center',
              textTransform: 'capitalize',
            }}
            onClick={() => setShowComments(!showComments)}
          >
            <Icon icon='material-symbols:comment-outline' />
            <p style={{ margin: 0 }}>Comment</p>
          </IonButton>
        </IonRow>
        <IonRow className='ion-align-items-center'>
          <div className='post-counter' style={{ marginRight: '5px' }}>
            <p style={{ margin: 0, marginRight: '5px' }}>{ data.post_replies.length }</p>
            <Icon icon='material-symbols:comment-outline' />
          </div>
          <div className='post-counter'>
            <p style={{ margin: 0, marginRight: '5px' }}>{ data.thumbs_up }</p>
            <Icon icon='mdi:thumbs-up' />
          </div>
        </IonRow>
      </IonRow>
      {
        showComments && (
          <div className='post-content'>
            <IonRow style={{ marginBottom: '20px' }}>
              <IonInput
                placeholder='Write a comment'
                style={{
                  flex: 1,
                  borderRadius: '10px',
                  backgroundColor: '#F1F1F1',
                  border: 'none',
                  color: '#747474',
                }}
                value={commentText}
                className='chats-input-field'
                onIonChange={(e) => setCommentText(e.detail.value)}
              />
              <button onClick={handleSubmitComment} type='button' className='submit-comment'>
                <Icon style={{ fontSize: '2rem' }} icon='material-symbols:send-rounded' />
              </button>
            </IonRow>
            {
              data.post_replies.sort(sortingFunction).map((comment) => (
                <div key={comment.id} className='comment-container'>
                  <IonRow className='ion-align-items-center' style={{ marginBottom: '10px' }}>
                    <h6>{ `${comment.user.first_name} ${comment.user.final_name}` }</h6>
                    <p className='post-time'>{ getDateString(comment.created_at) }</p>
                  </IonRow>
                  <p style={{ margin: '10px 0', color: 'black' }}>{ comment.text }</p>
                </div>
              ))
            }
          </div>
        )
      }
    </PostCardContainer>
  )
}

export default PostCard
