/* eslint-disable import/no-webpack-loader-syntax */
import * as React from 'react'
import { IonLoading, useIonAlert, IonList } from '@ionic/react'
import * as moment from 'moment'
import * as mapboxgl from 'mapbox-gl'
import FormPopup from '../forms/FormPopup'
import IncidentsForm from '../forms/IncidentsForm'

import MapForm from '../forms/MapForm'
import MobileIncident from '../forms/MobileIncident'
import testHook from '../../../hooks/testHooks'
import { useAuth } from '../../../hooks/useAuth'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { useInsightsData } from '../../../hooks/insights/useInsightsData'
import { Incident } from '../../types/OptimizedMaps'

/**
 *  ======================
 *    Submit an Incident
 *  ======================
 */
const IncidentLogic : React.FC<{
  incidentMarker: mapboxgl.Marker,
  setShowAddButton: React.Dispatch<React.SetStateAction<boolean>>,
  setIncidentMarker: React.Dispatch<React.SetStateAction<mapboxgl.Marker>> }> = ({ incidentMarker, setIncidentMarker, setShowAddButton }) => {
  const {
    mapRef, setShowIncidentF, pushNewIncident, mobile,
  } = useInsightsData()
  /* Hard coded ids for the new created incidents */
  const [submitted, setSubmitted] = React.useState(false)
  const { user } = useAuth()

  /* Form inputs */
  const [input, setInput] = React.useState({
    incident_type: null,
    date: null,
    fatalities: 0,
    description: '',
    county_id: null,
    nearest: '',
    reported: null,
    latitude: null,
    longitude: null,
    id: null,
  })
  const [loading, setLoading] = React.useState(false)

  const apiHook = testHook()
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()
  const [showInstructions, setShowInstructions] = React.useState(true)
  const [pick, setPick] = React.useState(true)

  /**
   *  ===========================
   *      HANDLE CLICK ON MAP
   *  ===========================
   */
  const handleClick = (e: { lngLat: mapboxgl.LngLat }) => {
    /* Center map on click */
    mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() })

    if (!pick) return

    setShowInstructions(true)
    /* Place new marker on the specified zone */
    const marker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      .setLngLat({ ...e.lngLat })
      .addTo(mapRef.current)

    /* Add draggable functionalities */
    marker.setDraggable(true)
    marker.on('drag', () => {
      setInput({ ...input, longitude: marker.getLngLat().lng, latitude: marker.getLngLat().lat })
    })

    setIncidentMarker(marker)
    setInput({ ...input, longitude: e.lngLat.lng, latitude: e.lngLat.lat })
    setPick(false)
  }

  const handleClickRef = React.useRef(handleClick)
  handleClickRef.current = handleClick

  const onCancel = (typed : boolean) => {
    const close = () => {
      setShowIncidentF(false)
      mapRef.current.easeTo({
        padding: {
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
        },
      })

      if (incidentMarker) incidentMarker.remove()
    }
    if (typed) {
      ionAlert({
        header: 'Cancel Incident Report?',
        message: 'Do you wish to continue? Your progress will be lost. ',
        buttons: [
          'Back',
          {
            text: 'Yes, continue', handler: close,
          },
        ],
      })

      return
    }

    close()
  }

  const returnMessage = (key: string) => ({
    incident_type: 'Please specify an incident type',
    date: 'Please indicate the date of the incident',
    fatalities: 'please select a number of fatalities',
    nearest: 'Please specify the nearest location to where the event occurred.',
    description: 'Please write a brief description about the event.',
  }[key])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!input.latitude || !input.longitude) {
      return ionAlert({
        header: 'Error',
        message: 'No location selected, please drop a pin on the map',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    const notFilled = Object.keys(input).find((key) => input[key] === null || input[key] === '')

    if (notFilled && returnMessage(notFilled)) {
      return ionAlert({
        header: 'Error',
        message: returnMessage(notFilled),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    if (!mobile) {
      const date = input.date as unknown as moment.Moment
      // eslint-disable-next-line no-param-reassign
      input.date = date.format('YYYY-MM-DD HH:mm')
    } else {
      const { date } = input
      // eslint-disable-next-line no-param-reassign
      input.date = moment(date).format('YYYY-MM-DD HH:mm')
    }

    setLoading(true)
    apiHook.tryIncident('', input.description, input.longitude, input.latitude, input.date, input.incident_type, input.fatalities,
      workspace.id, input.county_id, input.nearest).then(({ id }) => {
      const finalIncident : Incident = {
        ...input, id, reported: input.date, updated_at: moment().format(), created_at: moment().format(), user_id: user.user_id,
      }
      if (incidentMarker) incidentMarker.remove()
      mapRef.current.easeTo({
        padding: {
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
        },
      })
      pushNewIncident(finalIncident)
      setSubmitted(true)
      setLoading(false)
      setShowAddButton(true)
      if (mobile) { setShowIncidentF(false) }
    })
    return true
  }

  React.useEffect(() => {
    const callback = (e) => {
      handleClickRef.current(e)
    }
    mapRef.current.on('click', callback)
  }, [])

  return (
    <>
      <IonLoading
        isOpen={loading}
      />
      {
        (mobile) ? (
          <MobileIncident
            location={[input.longitude, input.latitude]}
            handleSubmit={handleSubmit}
            showInstructions={showInstructions}
            setShowInstructions={setShowInstructions}
            input={input}
            setInput={setInput}
            setShowAddButton={setShowAddButton}
          />
        ) : (
          <MapForm>
            {
              (!submitted) ? (
                <IncidentsForm
                  handleSubmit={handleSubmit}
                  loading={loading}
                  location={[input.longitude, input.latitude]}
                  onCancel={onCancel}
                  input={input}
                  setInput={setInput}
                  incidentMarker={incidentMarker}
                />
              ) : (
                <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
                  <FormPopup
                    message='You have successfully submitted an incident.'
                    onClose={() => setShowIncidentF(false)}
                  />
                </IonList>
              )
            }
          </MapForm>
        )
      }
    </>
  )
}

export default IncidentLogic
