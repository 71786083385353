import * as React from 'react'
import moment from 'moment'

import { IonCol, IonContent, IonRow, IonLabel, IonGrid, IonButton, IonImg, useIonAlert } from '@ionic/react'

import { PopupBackground } from '../popups/StyledContainers'

import { OverlayContainer } from '../GlobalContainers'

import logo from './logo.png'

import { useWorkspace } from '../../hooks/useWorkspace'

import { useReportsData } from '../../hooks/reports/useReportsData'

const SecurityReportPopup = () : JSX.Element => {
  const workspace = useWorkspace()

  const {
    setShowSecurityReport,
    name,
    county,
    state,
    incident,
  } = useReportsData()

  function handleClose() {
    setShowSecurityReport(false)
  }

  const [presentAlert] = useIonAlert()

  function handleDownload() {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }


  return (
    <>
      <PopupBackground style={{ zIndex: 1 }} />
      <OverlayContainer style={{ height: '550px', width: '70%' }}>
        <IonRow style={{ height: '10%' }}>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.5em', marginLeft: '1em' }}>Security Report</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right', marginRight: '3em'}}>
            <img src={logo} style={{ width: '8.75em', height: '2.5em' }} alt='logo' />
          </IonCol>
        </IonRow>

        <IonContent style={{ height: '80%', '--ion-background-color': '#FFFFFF' }}>

          <IonRow>
            <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.3em', marginTop: '2em', marginLeft: '1.3em', marginBottom: '1em' }}>Report submitted by</IonLabel>
          </IonRow>

          <IonGrid>
            <IonRow>
              <IonCol class='ion-no-padding'><IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>Name:</IonLabel></IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}><IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>State:</IonLabel></IonCol>
            </IonRow>
            <IonRow>
              <IonCol class='ion-no-padding'>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{name}</IonLabel>
              </IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{state}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow style={{ paddingTop: '0.5em' }}>
              <IonCol class='ion-no-padding'>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>Date:</IonLabel>
              </IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>County:</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class='ion-no-padding'>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{moment(incident.created_at).format('DD/MM/YYYY')}</IonLabel>
              </IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000'}}>{county + " County"}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow style={{ paddingTop: '0.5em' }}>
              <IonCol class='ion-no-padding'><IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>Organisation:</IonLabel></IonCol>
            </IonRow>
            <IonRow>
              <IonCol class='ion-no-padding'><IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{workspace.workspace.name}</IonLabel></IonCol>
            </IonRow>
          </IonGrid>

          <IonGrid style={{ marginTop: '1em' }}>

            <IonRow>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em' }}>Event Type</IonLabel>
              </IonCol>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ fontSize: '1em', color: '#000000' }}>{incident.incident_type}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em' }}>Date of Incident</IonLabel>
              </IonCol>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{fontSize: '1em', color: '#000000' }}>{moment(incident.reported).format('DD/MM/YYYY')}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em' }}>Coordinates</IonLabel>
              </IonCol>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ fontSize: '1em', color: '#000000' }}>{incident.latitude + " , " +  incident.longitude}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em' }}>Fatalities</IonLabel>
              </IonCol>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ fontSize: '1em', color: '#000000' }}>{incident.fatalities}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em' }}>Description</IonLabel>
              </IonCol>
              <IonCol style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ fontSize: '1em', color: '#000000' }}>{incident.description}</IonLabel>
              </IonCol>
            </IonRow>


          </IonGrid>
        </IonContent>

        <IonRow class='ion-align-items-end' style={{ marginTop: '1em' }}>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
            <IonButton style={{ '--ion-color-primary': '#8E151F' }} onClick={handleClose}>Close</IonButton>
          </IonCol>
        </IonRow>

      </OverlayContainer>
    </>
  )
}

export default SecurityReportPopup
