import * as React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
  IonPage, IonContent, IonGrid, createAnimation, useIonAlert,
} from '@ionic/react'
import PageHeader from '../components/PageHeader'

import JourneyWidget from '../components/maps/widgets/JourneyWidget'
import JourneyMap from '../components/maps/apps/JourneyMap'
import useApi from '../hooks/testHooks'
import AirJourneyControls from '../components/maps/controls/AirJourneyControls'
import { useJourneysData } from '../hooks/journeys/useJourneysData'
import { JourneyViews } from '../hooks/journeys/helpers/StateReducers'

import { ProvideJourneysData } from '../hooks/journeys/useJourneysData'
import { ProvideJourneysData2 } from '../hooks/journeys/useJourneysData2'
import AppWrapper from '../components/journeys/AppWrapper'
import AppWrapper2 from '../components/journeys/AppWrapper2'

const JourneysApp2 = () => {
  return (
    <ProvideJourneysData2>
      <IonPage>
        <PageHeader title='Journeys' />
        <IonContent
          style={{
            '--padding-top': '20px',
            '--padding-bottom': '20px',
            '--padding-start': '20px',
            '--padding-end': '20px',
          }}
        >
          <IonGrid style={{
            height: '100%',
          }}
          >
            <AppWrapper2 />
          </IonGrid>
        </IonContent>
      </IonPage>
    </ProvideJourneysData2>
  )
}

export default JourneysApp2
