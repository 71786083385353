import React, { useState, useRef, useEffect } from 'react'

import {
  IonContent, IonList, IonRow, IonButton, useIonAlert,
} from '@ionic/react'

import moment from 'moment'
import MapForm from '../forms/MapForm'
import { SimpleButton } from '../StyledContainers'

import JourneyDetails from './JourneyDetails'
import JourneyForm2 from '../forms/JourneyForm2'

import useApi from '../../../hooks/testHooks'
import { useJourneysData2 } from '../../../hooks/journeys/useJourneysData2'
import { BackendJourney } from '../../types/OptimizedMaps'
import { useAuth } from '../../../hooks/useAuth'

/**
 *  =========================
 *       Journey Edit Form
 *  =========================
 */

const JourneyEdit2 = ({
  setShowEditJourney, routes, journeyDisplay,
  startRoute, mapRef, onFinish, journeyMode, viewTransit,
  setLoading,
}) => {
  const { focussedJourney, users, updateJourneyDetails } = useJourneysData2()
  const [editing, setEditing] = useState(false)
  const content = useRef(null)
  const { user } = useAuth()

  const [alert] = useIonAlert()
  const apiHook = useApi()

  /* Close form */
  const onClose = () => {
    const close = () => {
      setEditing(false)
      mapRef.current.removeLayer('journey-routes-layer')
      mapRef.current.removeSource('journey-routes')
      viewTransit()
    }

    alert({
      header: 'Cancel Editing?',
      message: 'Do you wish to continue? You have unsaved changes.',
      buttons: [
        'Back',
        {
          text: 'Yes, continue',
          handler: close,
        },
      ],
    })
  }

  const closeForm = () => {
    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })
    onFinish()
    setShowEditJourney(false)
  }

  const onSubmit = async (input) => {
    const approver = users.find((val) => val.id === input.approver)
    const leader = users.find((val) => input.leader_id === val.id)

    const journey : BackendJourney = {
      id: focussedJourney.id,
      name: '',
      created_at: moment().format(),
      updated_at: moment().format(),
      submitter: {
        ...user,
        id: user.user_id,
      },
      approver,
      purpose: input.purpose,
      departure_time: input.departure_time,
      departure_location: input.departure_location,
      leader_phone: input.leader_phone,
      driver_name: input.driver_name,
      driver_phone: input.driver_phone,
      vehicle_type: input.vehicle,
      vehicle_reg: input.vehicle_reg,
      emergency_sat: input.emergency_sat,
      emergency_other: input.emergency_other,
      arrival_time: input.arrival_time,
      arrival_location: input.arrival_location,
      passengers: input.passengers,
      passenger_hotel: input.passenger_hotel,
      passenger_location: input.passenger_location,
      passenger_contact: input.passenger_contact,
      driver_hotel: input.driver_hotel,
      driver_location: input.driver_location,
      driver_contact: input.driver_contact,
      status: focussedJourney.status,
      chapters: [],
      journey_stages: input.stages.map((val: number) => ({
        path_id: val,
        created_at: moment().format(),
      })),
      leader,
    }

    const journeyEdited = { id: focussedJourney.id, ...input }

    setLoading(true)
    /* Send update to the backend */
    await apiHook.editJourney(focussedJourney.id, journeyEdited)

    updateJourneyDetails(journey)
    setEditing(false)
    setLoading(false)
  }

  const startEditing = () => {
    if (!mapRef.current.getSource('journey-routes')) {
      journeyMode()
    }
    journeyDisplay(focussedJourney.routes.map((val) => val.id))
    setEditing(true)
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    content.current && content.current.scrollToTop()
  }

  useEffect(() => {
    mapRef.current.easeTo({
      padding: {
        right: 300,
      },
    })
  }, [])

  useEffect(() => () => {
    mapRef.current.removeLayer('journey-routes-layer')
    mapRef.current.removeSource('journey-routes')
  }, [])

  return (
    <MapForm>
      {
        editing && (
        <JourneyForm2
          routes={routes}
          drawRoutes={journeyDisplay}
          onCancel={onClose}
          onSubmit={onSubmit}
          users={users}
          startRoute={startRoute}
          defaultValues={{
            supervisor: '',
            purpose: focussedJourney.purpose,
            leaderMobile: focussedJourney.leader_phone,
            driverName: focussedJourney.driver_name,
            driverMobile: focussedJourney.driver_phone,
            vehicleType: focussedJourney.vehicle_type,
            regNumber: focussedJourney.vehicle_reg,
            arrivalTime: moment(focussedJourney.arrival_time),
            to: focussedJourney.arrival_location,
            departureTime: moment(focussedJourney.departure_time),
            from: focussedJourney.departure_location,
            passengers: focussedJourney.passengers.split(', ').map((email) => ({ value: email, label: email })),
            routes: focussedJourney.routes.map((route) => ({ value: route.id, label: route.name })),
          }}
          defaultEmergency={{
            emergencyPhone: focussedJourney.emergency_sat,
            altEmergencyPhone: focussedJourney.emergency_other,
          }}
          defaultAccommodation={{
            driverAccommodation: focussedJourney.driver_hotel,
            driverAccommodationLocation: focussedJourney.driver_location,
            driverAccommodationPhone: focussedJourney.driver_contact,
            passengerAccommodation: focussedJourney.passenger_hotel,
            passengerAccommodationLocation: focussedJourney.passenger_location,
            passengerAccommodationPhone: focussedJourney.passenger_contact,
          }}
        >
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <h2 style={{ fontSize: '1.4rem' }}>Journey Details</h2>
            <SimpleButton
              onClick={(editing) ? onClose : closeForm}
            >Close X
            </SimpleButton>
          </IonRow>
        </JourneyForm2>
        )
      }
      {
        !editing && (
        <IonContent>
          <IonList style={{ padding: '20px' }}>
            <IonRow className='ion-align-items-center ion-justify-content-between'>
              <h2 style={{ fontSize: '1.4rem' }}>Journey Details</h2>
              <SimpleButton
                onClick={(editing) ? onClose : closeForm}
              >Close X
              </SimpleButton>
            </IonRow>
            <JourneyDetails focussedJourney={focussedJourney} />
            <IonRow style={{ marginTop: '20px' }} className='ion-justify-content-end'>
              <IonButton id='edit-journey-request' style={{ '--background': '#0C9500' }} onClick={startEditing}>Edit</IonButton>
              <IonButton id='done-edit-journey' style={{ '--background': '#326771' }} onClick={closeForm}>Done</IonButton>
            </IonRow>
          </IonList>
        </IonContent>
        )
      }
    </MapForm>
  )
}

export default JourneyEdit2
