/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import { Icon } from '@iconify/react'
import {
  IonButton, IonCol, IonRow, useIonAlert,
} from '@ionic/react'
import * as React from 'react'
import Select from 'react-select'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { operations, people, property } from '../../../riskregister/forms/FormOptions'
import { getImpactCategory, getNumericalValue } from '../../../riskregister/forms/utils/Categories'
import { SelectionDetail } from '../../../riskregister/StyledContainers'
import { IncidentInvestigation, User } from '../../../types/GlobalTypes'
import { DescriptionArea, SimpleButton } from '../../StyledContainers'
import useApi from '../../../../hooks/testHooks'

interface Props {
  data?: IncidentInvestigation,
  onSubmit: (input: IncidentInvestigation) => void,
  onCancel: () => void,
}

const propertyImpact = { None: 'No Impact on Properties', ...property }
const peopleImpact = { None: 'No Impact on people.', ...people }
const operationsImpact = { None: 'No Impact on operations.', ...operations }

const InvestigationForm : React.FC<Props> = ({ data, onSubmit, onCancel }) => {
  const [input, setInput] = React.useState({
    id: 0,
    name: '',
    summary: '',
    people: undefined,
    property: undefined,
    operations: undefined,
    peopleInvolved: [{
      user: undefined,
      type: '',
    }],
    findings: '',
    lessons: '',
    remedials: '',
    comments: '',
  })
  const { users, displayView, setLoading } = useIncidentsData()
  const apiHook = useApi()
  const [ionAlert] = useIonAlert()

  const returnMessage = (key: string) => ({
    name: 'Please specify the name of the investigator',
    summary: 'Please provide summary of the events',
    findings: 'Please indicate the findings of the investigation',
    lessons: 'Please describe the lessons learnt during the process',
    remedials: 'Please indicate the actions required',
    people: 'Please specify the impact that the selected Risk Event would have on People',
    operations: 'Please specify the impact that the selected Risk Event would have on Operations',
    property: 'Please specify the impact that the selected Risk Event would have on Property & Equipment',
  }[key])

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
  }

  const handleInvolvementType = (type: string, index: number) => {
    const copy = input.peopleInvolved.slice()
    copy.splice(index, 1, { ...copy[index], type })

    setInput({ ...input, peopleInvolved: copy })
  }

  const handleSubmit = () => {
    const notFilled = Object.keys(input).find((key) => (input[key] === undefined || input[key] === '') && key !== 'comments')

    if (notFilled) {
      ionAlert({
        header: 'Error',
        message: returnMessage(notFilled),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })

      return
    }
    const peopleValid = input.peopleInvolved.every((val) => (val.user && val.type !== '') || (!val.user && val.type === ''))
    if (!peopleValid) {
      ionAlert({
        header: 'Error',
        message: 'Details of people involved are incomplete.',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
      return
    }
    const filteredPeople = input.peopleInvolved.filter((val) => val.user && val.type)
    const processedPeople = filteredPeople.map((val) => ({ id: val.user.value.id, type: val.type }))

    const requestData = {
      name: input.name,
      summary: input.summary,
      people_involved: processedPeople,
      internal_incident_id: displayView.focusedIncident.id,
      people: getNumericalValue(input.people.value),
      operations: getNumericalValue(input.operations.value),
      property: getNumericalValue(input.property.value),
      findings: input.findings,
      lessons: input.lessons,
      remedials: input.remedials,
      comments: input.comments,
    }

    setLoading(true)
    if (!data) {
      apiHook.createInvestigation(requestData)
        .then(({ investigation }) => {
          onSubmit({
            ...input,
            id: investigation.id,
            people: getNumericalValue(input.people.value),
            operations: getNumericalValue(input.operations.value),
            property: getNumericalValue(input.property.value),
            peopleInvolved: filteredPeople.map(({ user, type }) => ({ user: user.value, type })),
            internal_incident_id: displayView.focusedIncident.id,
          })
        }).finally(() => {
          setLoading(false)
        })
      return
    }

    apiHook.editInvestigation({ ...requestData, investigation_id: data.id })
      .then(() => {
        onSubmit({
          id: data.id,
          ...input,
          people: getNumericalValue(input.people.value),
          operations: getNumericalValue(input.operations.value),
          property: getNumericalValue(input.property.value),
          peopleInvolved: [...data.peopleInvolved, ...filteredPeople.map(({ user, type }) => ({ user: user.value, type }))],
          internal_incident_id: displayView.focusedIncident.id,
        })
      }).finally(() => {
        setLoading(false)
      })
  }

  const addActor = () => {
    const copy = input.peopleInvolved.slice()
    copy.push({ user: null, type: '' })

    setInput({ ...input, peopleInvolved: copy })
  }

  const chooseUserInvolved = (addUser: { value: User, label: string }, index: number) => {
    const copy = input.peopleInvolved.slice()
    copy.splice(index, 1, { ...copy[index], user: addUser })

    setInput({ ...input, peopleInvolved: copy })
  }

  React.useEffect(() => {
    if (data) {
      const updatedInput = {
        ...data,
        peopleInvolved: [{
          user: undefined,
          type: '',
        }],
        operations: { value: getImpactCategory(data.operations), label: getImpactCategory(data.operations) },
        people: { value: getImpactCategory(data.people), label: getImpactCategory(data.people) },
        property: { value: getImpactCategory(data.property), label: getImpactCategory(data.property) },
        id: data.id,
      }
      setInput(updatedInput)
    }
  }, [])

  return (
    <div className='risk-assessment-list' style={{ height: '100%' }}>
      <div style={{ flex: '1 1 0%', overflow: 'auto' }}>
        <div className='incident-management-form-spacing'>
          <h4>Investigations</h4>
          <p>The investigations tab is where a review of the incident can take place prior to the closing of the incident</p>
        </div>
        <div className='incident-management-form-spacing'>
          <h4>Investigator Name</h4>
          <input
            className='title-input'
            name='name'
            onChange={handleChange}
            value={input.name}
            placeholder='Name'
          />
        </div>
        <div className='incident-management-form-spacing'>
          <h4>Summary Of Events</h4>
          <DescriptionArea className='wide-area' name='summary' placeholder='Give a summary of events' value={input.summary} onChange={handleChange} />
        </div>
        <div className='incident-management-form-spacing'>
          <h4>Actual Consequences</h4>
          <p>Use the drop-downs below to determine the impact that the selected Risk Event would have on the following factors</p>
          <IonRow style={{ margin: '10px 0' }} className='ion-justify-content-between'>
            <IonCol style={{ padding: 0 }} size='4'>
              <h5 style={{ color: '#326771' }}>People</h5>
              <Select
                placeholder='Select People'
                className='select-container'
                value={input.people}
                      // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={Object.keys(peopleImpact).map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setInput({ ...input, people: selected })}
              />
            </IonCol>
            {
            input.people && (
            <IonCol size='8'>
              <SelectionDetail>
                <p>{ peopleImpact[input.people.value] }</p>
              </SelectionDetail>
            </IonCol>
            )
          }
          </IonRow>
          <IonRow style={{ margin: '10px 0' }} className='ion-justify-content-between'>
            <IonCol style={{ padding: 0 }} size='4'>
              <h5 style={{ color: '#326771' }}>Operations</h5>
              <Select
                placeholder='Select Operations'
                className='select-container'
                value={input.operations}
                      // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={Object.keys(operationsImpact).map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setInput({ ...input, operations: selected })}
              />
            </IonCol>
            {
              input.operations && (
                <IonCol size='8'>
                  <SelectionDetail>
                    <p>{ operationsImpact[input.operations.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <IonRow style={{ margin: '10px 0' }} className='ion-justify-content-between'>
            <IonCol style={{ padding: 0 }} size='4'>
              <h5 style={{ color: '#326771' }}>Property & Equipment</h5>
              <Select
                placeholder='Select Property'
                className='select-container'
                value={input.property}
                      // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={Object.keys(propertyImpact).map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setInput({ ...input, property: selected })}
              />
            </IonCol>
            {
              input.property && (
                <IonCol size='8'>
                  <SelectionDetail>
                    <p>{ propertyImpact[input.property.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <div className='incident-management-form-spacing'>
            <h5>People Involved</h5>
            {
              data && (
                <>
                  {
                    data.peopleInvolved.map((val, index) => (
                      <IonRow key={index} style={{ margin: '10px 0' }}>
                        <IonCol size='3'>
                          <p>{ `${val.user.first_name} ${val.user.final_name}` }</p>
                        </IonCol>
                        <IonCol size='3'>
                          <p>{ val.type }</p>
                        </IonCol>
                        <IonCol size='2'>
                          <p className='incident-management-text-field'>{ val.user ? val.user.email : 'Email' }</p>
                        </IonCol>
                        <IonCol size='2'>
                          <p className='incident-management-text-field'>{ val.user && val.user.phone_number ? val.user.phone_number : 'Phone No.' }</p>
                        </IonCol>
                        <IonCol size='2'>
                          <p className='incident-management-text-field'>{ val.user && val.user.alternative_phone_number ? val.user.alternative_phone_number : 'Alternative Phone No.' }</p>
                        </IonCol>
                      </IonRow>
                    ))
                  }
                </>
              )
            }
            {
            input.peopleInvolved.map((val, index) => (
              <IonRow key={index} style={{ margin: '10px 0' }}>
                <IonCol size='3'>
                  <Select
                    placeholder='Select user'
                    id='select-supervisor-container'
                    className='select-container'
                    // eslint-disable-next-line no-undef
                    value={val.user}
                    menuPortalTarget={document.body}
                    options={users.map((userVal) => ({ value: userVal, label: `${userVal.first_name} ${userVal.final_name}` }))}
                    onChange={(selected) => chooseUserInvolved(selected, index)}
                  />
                </IonCol>
                <IonCol size='3'>
                  <input
                    className='title-input incident-title'
                    style={{ maxWidth: '100%' }}
                    name='title'
                    onChange={(e) => handleInvolvementType(e.target.value, index)}
                    value={val.type}
                    placeholder='Involment Type'
                  />
                </IonCol>
                <IonCol size='2'>
                  <p className='incident-management-text-field'>{ val.user ? val.user.value.email : 'Email' }</p>
                </IonCol>
                <IonCol size='2'>
                  <p className='incident-management-text-field'>{ val.user && val.user.value.phone_number ? val.user.value.phone_number : 'Phone No.' }</p>
                </IonCol>
                <IonCol size='2'>
                  <p className='incident-management-text-field'>{ val.user && val.user.value.alternative_phone_number ? val.user.value.alternative_phone_number : 'Alternative Phone No.' }</p>
                </IonCol>
              </IonRow>
            ))
          }
            <IonRow className='ion-justify-content-end'>
              <IonCol size='3'>
                <SimpleButton onClick={addActor}>Add Person <Icon icon='ant-design:plus-outlined' /> </SimpleButton>
              </IonCol>
            </IonRow>
          </div>
          <div className='incident-management-form-spacing'>
            <h4>Key Findings</h4>
            <DescriptionArea placeholder='What were the key findings of the investigation?' className='wide-area' name='findings' value={input.findings} onChange={handleChange} />
          </div>
          <div className='incident-management-form-spacing'>
            <h4>Lessons Learned</h4>
            <DescriptionArea placeholder='What lessons were learned during the investigation?' className='wide-area' name='lessons' value={input.lessons} onChange={handleChange} />
          </div>
          <div className='incident-management-form-spacing'>
            <h4>Remedial Actions Required</h4>
            <DescriptionArea placeholder='Actions required' className='wide-area' name='remedials' value={input.remedials} onChange={handleChange} />
          </div>
          <div className='incident-management-form-spacing'>
            <h4>Investigator Comments</h4>
            <DescriptionArea placeholder='Comments' className='wide-area' name='comments' value={input.comments} onChange={handleChange} />
          </div>
        </div>
      </div>
      <IonRow className='ion-justify-content-end'>
        {
          (!data) ? (
            <IonButton onClick={handleSubmit} style={{ '--background': '#326771' }}>Save</IonButton>
          ) : (
            <>
              <IonButton onClick={handleSubmit} style={{ '--background': '#326771' }}>Save</IonButton>
              <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            </>
          )
        }
      </IonRow>
    </div>
  )
}

export default InvestigationForm
