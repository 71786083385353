import React from 'react'

import { faClock, faExclamationCircle, faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonCol, IonRow } from '@ionic/react'
import { CardContainer, HighPriorityLabel } from './StyledContainers'

import { SherpaButton } from '../GlobalContainers'
import { useAuth } from '../../hooks/useAuth'

/**
 *  ===============================
 *    Individual Task container
 *  ===============================
 */
const TaskCard = ({
  id, title, description, app, due, time, assignee, assigner, priority, expand, status, showArchived,
}) => {
  const { user } = useAuth()
  const handleExpand = () => {
    expand({
      id,
      title,
      description,
      app,
      due,
      time,
      assignee,
      status,
      assigner,
      priority,
    })
  }

  const colorCodeApp = () => (
    {
      Insight: '#29C379',
      Journey: '#0086E7',
      Misc: '#FF8A00',
      Project: '#a81aab',
    }[app]
  )
  return (
    <CardContainer
      onDragStart={(e) => e.dataTransfer.setData('id', id)}
      className={`${title.split(' ').join('-')}-card`}
      draggable={(!showArchived && ((assignee !== 'Unknown' && user.email === assignee.email) || (assigner !== 'Unknown' && user.email === assigner)))}
    >
      <IonRow>
        <IonCol>
          <h3 style={{ fontSize: '1.3rem' }}>{ title }</h3>
          <p
            style={{
              backgroundColor: colorCodeApp(),
              padding: '5px',
              color: 'white',
              borderRadius: '5px',
              width: 'fit-content',
              textTransform: 'capitalize',
            }}
            className='task-card-app'
          >{ (app === 'Misc') ? `${app}.` : `${app}s app` }
          </p>
        </IonCol>
        <IonCol>
          {
            priority && (
              <HighPriorityLabel><FontAwesomeIcon icon={faExclamationCircle} /> High Priority</HighPriorityLabel>
            )
          }
        </IonCol>
      </IonRow>
      <p className='task-card-description'>{ description }</p>
      <p><FontAwesomeIcon icon={faClock} /> { due.split('T')[0] }</p>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <p className='task-card-assignee' style={{ margin: '0' }}>{ (assignee === 'Unknown') ? 'Unknown' : assignee.email }</p>
        <SherpaButton className='task-expand-button' onClick={handleExpand}><FontAwesomeIcon icon={faExpand} /></SherpaButton>
      </IonRow>
    </CardContainer>
  )
}

export default TaskCard
