import * as React from 'react'
import {
  IonRow, IonCol, IonContent, IonCheckbox,
} from '@ionic/react'

import { Icon } from '@iconify/react'
import { ListContainer } from '../../consultant/StyledContainers'
import { WidgetTitles, ViewButton } from '../../maps/WidgetContainers'
import { getCurrentLevel, getColor } from '../../maps/apps/AppMap'
import { SherpaButton } from '../../GlobalContainers'

import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'
import { Asset } from '../types/Assets'
import { getListOfAssets } from '../utils/FormatListViews'
import { FocusedActionKind, ViewsActionKind } from '../../../hooks/risks/helpers/StateReducers'

/**
 *  ===========================
 *       Asset List View
 *  ===========================
 */

const AssetRegister = () : JSX.Element => {
  /* Retrieve  hooks data/functionality */
  const {
    assets, dispatchAsset, dispatchViews, editing, deleteAssetList,
  } = useRiskRegister()
  const [assetList, setAssetList] = React.useState<Asset[]>(getListOfAssets(assets))

  const [deleteAssets, setDeleteAssets] = React.useState<{ [id: number] : boolean }>(Object.fromEntries(getListOfAssets(assets).map((risk) => [risk.id, false])))

  const handleFocus = (asset: Asset) : void => {
    dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: asset })
    dispatchViews({ type: ViewsActionKind.LIST_RISKS })
  }

  const handleCheckboxes = (checked: boolean, id: number) => {
    setDeleteAssets({ ...deleteAssets, [id]: checked })
  }
  const handleDelete = () => {
    const ids = Object.keys(deleteAssets).filter((id) => deleteAssets[id])
    const assetsToDelete = assetList.filter(({ id }) => ids.indexOf(String(id)) >= 0)
    deleteAssetList(assetsToDelete)
  }

  /* Subscribe list view assets to hook */
  React.useEffect(() => {
    setAssetList(getListOfAssets(assets))
  }, [assets])

  return (

    <ListContainer>
      <WidgetTitles style={{ height: '10%', paddingRight: '0', paddingLeft: (assetList.length > 10) ? '10px' : '0' }}>
        <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
          <IonCol className='ion-text-center' size='1'>
            {
              editing && (
                <SherpaButton
                  onClick={handleDelete}
                  id='archive-selected-assets-button'
                  style={{ padding: '0' }}
                >
                  <IonRow className='ion-align-items-center ion-justify-content-center'>
                    <Icon icon='bi:trash' />
                  </IonRow>
                </SherpaButton>
              )
            }
          </IonCol>
          <IonCol className='ion-text-center' size='2'>Key Asset</IonCol>
          <IonCol className='ion-text-center' size='2'>Type</IonCol>
          <IonCol className='ion-text-center' size='2'>Location</IonCol>
          <IonCol className='ion-text-center' size='3'>Alert State</IonCol>
          <IonCol className='ion-text-center' size='2'>Date Modified</IonCol>
        </IonRow>
      </WidgetTitles>
      <div style={{ height: '95%' }}>
        <IonContent style={{ '--background': 'white' }}>
          {
            assetList.map((asset: Asset, index: number) => (
              <IonRow
                key={asset.id}
                style={{ backgroundColor: getColor(index), height: '10%' }}
                className='ion-align-items-center'
              >
                <IonCol className='ion-text-center' size='1'>
                  {
                    editing && (
                      <IonCheckbox className={`toggle-delete-${asset.name}`} onClick={(e) => handleCheckboxes(e.currentTarget.checked, asset.id)} checked={deleteAssets[asset.id]} />
                    )
                  }
                </IonCol>
                <IonCol className='ion-text-center' size='2'>
                  <ViewButton onClick={() => handleFocus(asset)}>
                    { asset.name }
                  </ViewButton>
                </IonCol>
                <IonCol className='ion-text-center' size='2'>{ asset.type }</IonCol>
                <IonCol className='ion-text-center' size='2'>{ asset.location.country }</IonCol>
                <IonCol className='ion-text-center' size='2'>
                  <IonRow
                    className='ion-align-items-center ion-justify-content-center'
                  >
                    <p
                      className='alert-state-display'
                      style={{ backgroundColor: getCurrentLevel(asset?.alert_state)?.background, color: getCurrentLevel(asset?.alert_state)?.color }}
                    > { asset?.alert_state }
                    </p>
                  </IonRow>
                </IonCol>
                <IonCol className='ion-text-center' size='2'>{ asset.updated_at}</IonCol>
              </IonRow>
            ))
          }
        </IonContent>
      </div>
    </ListContainer>
  )
}

export default AssetRegister
