import React, { useEffect, useState } from 'react'

import moment from 'moment'

import { IonCol, IonContent, IonRow, IonLabel, IonGrid, IonButton, IonImg, useIonAlert } from '@ionic/react'

import { PopupBackground } from '../popups/StyledContainers'

import { OverlayContainer } from '../GlobalContainers'

import logo from './logo.png'

import { useReportsData } from '../../hooks/reports/useReportsData'

import { indicators } from '../maps/forms/Indicators.json'

import { useWorkspace } from '../../hooks/useWorkspace'

import { SimpleButton } from '../incident-management/StyledContainers'

import {
  people, property, operations,
} from './FormOptions'

import useApi from '../../hooks/testHooks'

const IncidentPopup = () : JSX.Element => {
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  const {
    setShowFieldSitrep,
    name,
    county,
    state,
    insight,
    setShowInternalIncident,
    setStep,
    step,
    internalIncident,
    data,
  } = useReportsData()

  const handleClose = () => {
    setShowInternalIncident(false)
    setStep(1)
  }

  console.log(data)

  const [presentAlert] = useIonAlert()

  const [users, setUsers] = React.useState<[]>([])
  //
  const handleDownload = () => {
    apiHook.downloadInternalIncident({
      domain_id: workspace.id,
      incident_id: internalIncident.id,
    })
      .then((result) => {
        apiHook.downloader2(result, internalIncident.title)
      })
  }

  const handleNext = () => {
    setStep(2)
    console.log(step)
  }

  const handlePrev = () => {
    setStep(1)
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 0 }} />
      <OverlayContainer style={{ height: '550px', width: '70%' }}>
        <IonRow style={{ height: '10%' }}>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>Incident Report</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonContent style={{ height: '80%', '--ion-background-color': '#FFFFFF' }}>

          <IonRow>
            <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.3em', marginLeft: '1.5em', marginBottom: '0.5em' }}>Incident Ticket</IonLabel>
          </IonRow>

          <IonGrid style={{ marginTop: '1em' }}>

            <IonRow>
              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Report Title</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.title}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Date of Incident</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{moment(internalIncident.created_at).format('DD/MM/YYYY')}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Date Reported</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{moment(internalIncident.reported).format('DD/MM/YYYY')}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Primary Incident</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.primary_title}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Category</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.primary_category}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Type</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.primary_type}</IonLabel>
              </IonCol>

              {
                data.secondary_incidents.map(
                  (inc) => (
                    <>
                      <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                        <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Additional Incident</IonLabel>
                      </IonCol>
                      <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                        <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{inc.title}</IonLabel>
                      </IonCol>
                      <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                        <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Category</IonLabel>
                      </IonCol>
                      <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                        <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{inc.category}</IonLabel>
                      </IonCol>
                      <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                        <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Type</IonLabel>
                      </IonCol>
                      <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                        <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{inc.secondary_type}</IonLabel>
                      </IonCol>
                    </>
                  ),
                )
              }

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Location of primary incident</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`${internalIncident.latitude}, ${internalIncident.longitude}`}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Was the reporter directly involved?</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                {
                  (internalIncident.involved === false) && (
                    <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>No</IonLabel>
                  )
                }
                {
                  (internalIncident.involved === true) && (
                    <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>Yes</IonLabel>
                  )
                }
              </IonCol>

              {
                  data.internal_actors.map(
                    (person, idx) => (
                      <>
                        {
                          (idx === 0) ? (
                            <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>People Involved</IonLabel>
                            </IonCol>
                          ) : (
                            <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }} />
                            </IonCol>
                          )
                        }
                        <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`${(data.users.find((e) => e.id === person.user_id)).first_name} ${(data.users.find((e) => e.id === person.user_id)).final_name}`}</IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{person.involvement_type}</IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{(data.users.find((e) => e.id === person.user_id)).email}</IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{(data.users.find((e) => e.id === person.user_id)).phone}</IonLabel>
                          </IonRow>
                        </IonCol>
                      </>
                    ),
                  )
                }

              {
                (data.external_actors.length > 0) && (
                  <>
                    <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>External Actors Involved</IonLabel>
                    </IonCol>
                    <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      {
                        data.external_actors.map(
                          (person) => (
                            <>
                              <IonRow>
                                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{(data.actors.find((e) => e.id === person.actor_id)).name}</IonLabel>
                              </IonRow>
                            </>
                          ),
                        )
                      }
                    </IonCol>
                  </>
                )
              }

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Incident details</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.description}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Actual Consequences</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`People: ${Object.values(people)[internalIncident.people]}`}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }} />
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`Operations: ${Object.values(operations)[internalIncident.operations]}`}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }} />
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`Property: ${Object.values(property)[internalIncident.property]}`}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Actions Taken</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.taken}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Assistance Required</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.required}</IonLabel>
              </IonCol>

              <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Reporter Comments</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{internalIncident.comments}</IonLabel>
              </IonCol>

            </IonRow>
          </IonGrid>

          {
            (data.incident_comments.length > 0) && (
              <IonRow>
                <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.2em', marginLeft: '1.5em', marginTop: '0.5em' }}>Additional Information(added to ticket)</IonLabel>
              </IonRow>
            )
          }

          <IonGrid style={{ marginTop: '1em' }}>
            {
              data.incident_comments.map(
                (comment) => (
                  <IonRow class='ion-align-items-end'>
                    <IonCol size='12' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.5em', paddingBottom: '0.9em' }}>
                      <IonRow>
                        <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{comment.comment}</IonLabel>
                      </IonRow>
                      <IonRow style={{ textAlign: 'right', marginTop: '0.4em' }}>
                        <IonLabel style={{ color: '#000000', fontSize: '0.7em', paddingLeft: '0.4em' }}>{`${(data.users.find((e) => e.id === comment.user_id)).first_name} ${(data.users.find((e) => e.id === comment.user_id)).final_name}, ${moment(comment.created_at).format("DD/MM/YY hh:mm")}`}</IonLabel>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                ),
              )
            }
          </IonGrid>

        </IonContent>

        <IonRow class='ion-align-items-end' style={{marginTop: '1em'}}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev} disabled>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>

      </OverlayContainer>
    </>
  )
}

export default IncidentPopup
