/* eslint-disable no-undef */
import React, {
  useState, useContext, createContext,
} from 'react'

export const domainContext = createContext()
export const useWorkspace = () => useContext(domainContext)

const useProvideWorkspace = () => {
  const [workspace, setWorkspace] = useState(null)
  const [domains, setDomains] = useState([])
  const [mapView, setMapView] = useState(null)

  const setDomain = (domain) => {
    localStorage.setItem('current-workspace', JSON.stringify(domain))
    setWorkspace(domain)
  }

  /* Store domain in localStorage */
  const fetchDomain = () => {
    try {
      const domain = JSON.parse(localStorage.getItem('current-workspace'))
      setWorkspace(domain)

      return domain
    } catch (e) {
      localStorage.removeItem('current-workspace')
    }

    return null
  }

  return {
    workspace,
    setDomain,
    fetchDomain,
    domains,
    setDomains,
    mapView,
    setMapView,
  }
}

export const ProvideWorkspace = ({ children }) => {
  const domain = useProvideWorkspace()

  return (
    <domainContext.Provider value={domain}>
      {children}
    </domainContext.Provider>
  )
}
