import { IonButton, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { ZoneLog } from '../../../hooks/journeys/types/HookInterface2'
import { SettingField } from '../../settings/StyledContainers'
import { FormContainer } from '../forms/MapForm'
import { SimpleButton, StoryLine } from '../StyledContainers'

interface Props {
  onEdit: () => void,
  onRemove: () => void,
  onClose: () => void,
  logs: ZoneLog[],
}

const RestrictionZoneLog : FC<Props> = ({
  onEdit, onRemove, onClose, logs,
}) => (
  <FormContainer style={{ backgroundColor: 'white' }}>
    <div style={{ height: '100%', padding: '20px', overflow: 'hidden' }} className='risk-assessment-list'>
      <div style={{ minHeight: 'auto', flex: '1', overflow: 'hidden' }}>
        <IonRow>
          <SettingField style={{ width: 'auto' }}> <h4 style={{ margin: '0' }}> Edit Restriction Zone </h4> </SettingField>
          <SimpleButton
            onClick={onClose}
          >Close X
          </SimpleButton>
        </IonRow>
        <p>This zone is marked as red, meaning that users are prohibited from moving within the zones borders.</p>
        <div className='restriction-zone-ticket'>
          <h5>Zone Log</h5>
          {
            logs.length === 0 ? (
              <p>No Logs</p>
            ) : (
              <StoryLine>
                {
                  logs.map((val) => (
                    <li key={val.id} className='status-changes field'>
                      <p><span style={{ fontWeight: 'bold' }}>{ val.description }</span>: { val.user.first_name } { val.user.final_name }</p>
                    </li>
                  ))
                }
              </StoryLine>
            )
          }
        </div>
      </div>
      <IonRow className='ion-justify-content-end' style={{ margin: '15px 0' }}>
        <IonButton style={{ '--background': '#4197A9' }} onClick={onRemove}>Remove</IonButton>
        <IonButton id='submit-restriction-box' style={{ '--background': '#0C9500' }} onClick={onEdit}>Edit</IonButton>
      </IonRow>
    </div>
  </FormContainer>
)

export default RestrictionZoneLog
