import React from 'react'
import {
  IonList, IonItem, IonListHeader, IonInput, IonContent,
  IonRow, IonButton,
} from '@ionic/react'

import { FormTitle } from '../../../maps/StyledContainers'

/**
 *  =====================================
 *      VEHICLE AND EMERGENCY CONTACT
 *  =====================================
 */
const Vehicle = ({
  handleInput, input, setEmergency, emergency, children,
  onBack, onNext,
}) => (
  <IonContent
    style={{
      '--ion-background-color': 'white',
      '--padding-bottom': '15px',
      '--padding-top': '15px',
      '--padding-end': '15px',
    }}
  >
    {
      children
    }
    <IonList>
      <IonListHeader>
        <FormTitle>
          <h3 style={{ fontSize: '1rem' }}>Vehicle</h3>
        </FormTitle>
      </IonListHeader>
      <IonItem>
        <IonInput onIonChange={handleInput} value={input.vehicleType} name='vehicleType' type='text' placeholder='Type' />
      </IonItem>
      <IonItem>
        <IonInput onIonChange={handleInput} value={input.regNumber} name='regNumber' type='text' placeholder='Reg Number' />
      </IonItem>
    </IonList>
    <IonListHeader>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Emergency Contact Details</h3>
      </FormTitle>
    </IonListHeader>
    <IonItem>
      <IonInput
        onIonChange={(e) => setEmergency({ ...emergency, [e.target.name]: e.detail.value })}
        value={emergency.emergencyPhone}
        name='emergencyPhone'
        type='text'
        placeholder='Emergency Phone No. 1'
      />
    </IonItem>
    <IonItem>
      <IonInput
        onIonChange={(e) => setEmergency({ ...emergency, [e.target.name]: e.detail.value })}
        value={emergency.altEmergencyPhone}
        name='altEmergencyPhone'
        type='text'
        placeholder='Emergency Phone No. 2'
      />
    </IonItem>
    <IonRow style={{ paddingLeft: '15px', marginTop: '15px' }} className='ion-justify-content-between'>
      <IonButton style={{ '--background': '#4197A9' }} onClick={onBack}>Back</IonButton>
      <IonButton style={{ '--background': '#326771' }} onClick={onNext}>Next</IonButton>
    </IonRow>
  </IonContent>
)

export default Vehicle
