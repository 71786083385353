import React, { useEffect, useState } from 'react'
import {
  IonPage, IonContent, IonGrid, IonRow, IonCol,
} from '@ionic/react'
import PageHeader from '../components/PageHeader'
import NotificationCard from '../components/NotificationCard'
import { useNotification } from '../hooks/useNotification'
import { useAuth } from '../hooks/useAuth'
import { SimpleButton } from '../components/maps/StyledContainers'

const NotificationsList = () => {
  const { notifications } = useNotification()
  const { user } = useAuth()

  const [sortedList, setSortedList] = useState([])

  const sortedNotifList = () => {
    setSortedList([...notifications].sort((a, b) => a.id - b.id))
  }

  const notifHook = useNotification()

  useEffect(() => {
    sortedNotifList()
  }, [notifications])

  return (
    <IonPage>
      <PageHeader title='Notifications' />
      <IonContent>
        <IonGrid style={{
          height: '100%',
          width: '120%',
          padding: '20px 300px',
          backgroundColor: '#E5E5E5',
        }}
        >

          <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
            <IonCol
              size={8}
              style={{
                backgroundColor: 'white', color: 'white', borderRadius: '2.5px', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
              }}
            >
              <IonRow>
                <IonCol>
                  <h1 style={{ color: 'black' }}> Notifications </h1>
                </IonCol>
                <IonCol>
                  <SimpleButton style={{ textDecoration: 'underline' }} onClick={() => notifHook.markAllNotificationAsRead(user.id)}>Mark all as read</SimpleButton>
                </IonCol>
              </IonRow>
              {
                  sortedList.reverse().map((notification) => (
                    <NotificationCard notification={notification} />
                  ))
                }
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default NotificationsList
