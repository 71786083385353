import React from 'react'
import {
  IonButton, IonContent, IonRow, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { SimpleButton } from '../../maps/StyledContainers'

import JourneyDetails from '../../maps/features/JourneyDetails'
import useApi from '../../../hooks/testHooks'

/**
 *  ===============================
 *      Authorization Form
 *  ===============================
 */
const JourneyAuthorization = ({
  focusedJourney, onBack, updateJourneyStatus, domainId, setLoading,
}) => {
  const [alert] = useIonAlert()
  const apiHook = useApi()

  const handleApprove = () => {
    const approve = async (message) => {
      setLoading(true)
      const { story } = await apiHook.progressJourney(focusedJourney.id, message, domainId)
      updateJourneyStatus(focusedJourney.id, { status: 'Pre-Departure', story })
      setLoading(false)
      onBack()
    }

    alert({
      header: 'Approve Journey',
      message: 'Are you sure you want to approve this journey?',
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: 'Additional requirements before travelling.',
        },
      ],
      buttons: [
        'cancel',
        { text: 'Ok', handler: (data) => approve(data.reason) },
      ],
    })
  }

  const handleDeny = () => {
    const deny = async (message) => {
      setLoading(true)
      const { story } = await apiHook.denyJourney(focusedJourney.id, message, domainId)
      updateJourneyStatus(focusedJourney.id, { status: 'Declined', story })
      setLoading(false)
      onBack()
    }

    alert({
      header: 'Approve Journey',
      message: 'Are you sure you want to approve this journey?',
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: 'Additional requirements before travelling.',
        },
      ],
      buttons: [
        'cancel',
        { text: 'Ok', handler: (data) => deny(data.reason) },
      ],
    })
  }

  return (
    <>
      <div style={{ padding: '10px 0' }}>
        <IonRow style={{ margin: '10px 0' }} className='ion-align-items-center ion-justify-content-start'>
          <SimpleButton onClick={onBack}>
            <Icon icon='fe:arrow-left' />
            Back
          </SimpleButton>
        </IonRow>
        <IonButton
          style={{
            width: '90%', display: 'block', margin: '5px auto', '--background': '#326771', '--border-radius': '2.5px',
          }}
          onClick={handleApprove}
        > Authorise
        </IonButton>
        <IonButton
          style={{
            width: '90%', display: 'block', margin: '10px auto', '--background': '#8E151F', '--border-radius': '2.5px',
          }}
          onClick={handleDeny}
        > Deny
        </IonButton>
      </div>
      <IonContent
        style={{
          '--background': 'white',
          '--padding-top': '15px',
          '--padding-bottom': '15px',
          '--padding-start': '15px',
          '--padding-end': '15px',
        }}
      >
        <JourneyDetails
          focussedJourney={focusedJourney}
        />
      </IonContent>
    </>
  )
}

export default JourneyAuthorization
