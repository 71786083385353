import React, {
  useState, useContext, createContext,
} from 'react'

export const geolocationContext = createContext()
export const useGeolocation = () => useContext(geolocationContext)

const useProvideGeolocation = () => {
  const [geolocation, setGeolocation] = useState({
    lat: null,
    lng: null,
  })

  const setGeodata = (position) => {
    setGeolocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    })
  }

  return {
    setGeodata,
    geolocation,
  }
}

export const ProvideGeolocation = ({ children }) => {
  const geolocation = useProvideGeolocation()

  return (
    <geolocationContext.Provider value={geolocation}>
      {children}
    </geolocationContext.Provider>
  )
}
