import React from 'react'

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonButton, IonRow } from '@ionic/react'
import { PopupContainer } from '../StyledContainers'

/**
 *  ============================
 *       SUBMISSION POPUP
 *  ============================
 */
const FormPopup = ({ message, onClose }) => (
  <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
    <PopupContainer>
      <FontAwesomeIcon
        style={{
          fontSize: '60px',
          marginBottom: '40px',
          color: '#326771',
        }}
        icon={faCheckCircle}
      />
      <h4 id='submission-pop-up-message' style={{ color: '#326771', fontSize: '1rem' }}> { message } </h4>
      <IonButton id='close-submission-popup' style={{ '--background': '#326771' }} onClick={onClose}>close</IonButton>
    </PopupContainer>
  </IonRow>
)

export default FormPopup
