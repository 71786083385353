import styled from 'styled-components'

const TabOption = styled.button`
  background: white;
  color: #8E151F;
  text-transform: capitalize;
  margin: 3px;

  &.selected {
    margin-bottom: 0px;
  }
`

const GreenButton = styled.button`
  color: white;
  background: #0C9500;
  border-radius: 2.5px;
`

const CategorySelect = styled.select`
  min-width: 150px;
  padding: 5px;
  border: 1px solid #ced4da;
  max-width: 200px;
`

const ContentContainer = styled.div`
  background: white;
  padding: 20px 35px;
  flex: 1 1 auto;
  max-height: 100%;

  & h4, h5, h6 {
    color: #326771;
    margin: 0;
  }
  & h4 {
    font-size: 1.2rem;
    margin: 10px 0 !important;
    font-weight: bold;
  }

  & h6.procedure-field-tite {
    color: black;
    font-size: 0.9rem;
  }

  & p {
    font-size: .8rem;
  }

  & h4.red-font {
    color: #8E151F;
  }

  & h5 {
    font-size: 1rem;
    margin: 5px 0;
  }

  & h5 .grey {
    color: #747474;
  }

  & .empty-lists {
    padding: 30px 0;
    font-size: 1rem;
    text-align: center;
    margin: 0;
  }

  & input.title-input {
    padding: 5px;
    border: 1px solid #ced4da;
  }

  & input.incident-title {
    width: 100%;
  }

  & input.incident-coordinates {
      width: 100%;
      border: 1px solid #ced4da;
  }

  & .procedure-field-title {
    color: black;
  }

  & .procedure-table-border {
    border-bottom: 0.5px solid black;
    border-right: 0.5px solid black;
    border-left: 0.5px solid black;
  }

  & p.procedure-table-input {
    margin: 0;
  }

  & .procedure-row-editing {
    padding: 10px;
    border: 0.5px solid black;
    margin: 10px 0;
  }

  & .procedure-select {
    width: 100%;
    padding: 10px;
  }

  & .no-procedures-row {
    border-bottom: 0.5px solid black;
    border-right: 0.5px solid black;
    border-left: 0.5px solid black;
    justify-content: center;
    padding: 15px;
  }
`

const IncidentList = styled.div`
  overflow-y: auto;
`

const BlueButton = styled.button`
  color: #4B99F4;
  background: transparent;
  padding: 0;
  text-decoration: underline;
`

const RedButton = styled.button`
  color: #8E151F;
  background: transparent;
  padding: 0;
  text-decoration: underline;
`

const SimpleButton = styled.button`
  background: transparent;
  padding: 0;
  color: #4B99F4;
`

const SOPCard = styled.div`
  border: 1px solid #7E7E7E;
  margin: 10px 0;
`

const RemoveFile = styled.button`
  position: absolute;
  color: white;
  background-color: #326771;
  top: 0;
  right: 0;
  padding: 5px;
  transform: translate(50%, -50%);
  display: flex;
  border-radius: 50%;
`

const ObscureBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(69, 69, 68, .5);
  z-index: 10;
  border-radius: 5px;
`

const DescriptionArea = styled.textarea`
  height: 150px;
  padding: 10px;
  resize: none;
  &.wide-area {
    width: 80%;
  }
`

const NoUserSelected = styled.div`
  border: 1px solid #C30101;
  color: #C30101;
  border-radius: 2.5px;
  width: fit-content;
  padding: 5px 10px;
`

const UserField = styled.div`
  padding: 5px;
  border: 1px solid #ced4da;
  color: #747474;
  border-radius: 2.5px;
`

const ChatIcon = styled.div`
  background: #EDD1B1;
  border-radius: 50%;
  position: relative;
  width: 35px;
  height: 35px;
  margin: 0 15px;
  text-transform: uppercase;

  & p {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const UserButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;

  & p {
    margin: 0;
  }
`

const TagButton = styled.button`
  border-radius: 2.5px;
  border: 1px solid #326771;
  padding: 3px;
  color: white;
  margin: 5px;
`

export {
  TabOption,
  GreenButton,
  CategorySelect,
  ContentContainer,
  IncidentList,
  BlueButton,
  SOPCard,
  SimpleButton,
  ObscureBackground,
  DescriptionArea,
  NoUserSelected,
  ChatIcon,
  UserButton,
  TagButton,
  UserField,
  RemoveFile,
  RedButton,
}
