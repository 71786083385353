import React from 'react'
import { IonPage, IonContent } from '@ionic/react'
import PageHeader from '../components/PageHeader'

const Configuration = () => (
  <IonPage>
    <PageHeader title='Configuration' />
    <IonContent />
  </IonPage>
)

export default Configuration
