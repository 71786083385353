import { IonButton, IonRow } from '@ionic/react'
import * as React from 'react'
import * as moment from 'moment'
import { ListModes, Views, DisplayAssetAction } from '../../../hooks/risks/helpers/StateReducers'
import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'
import { fetchAlerStates, drawCountyShapesIncidents } from './AppMap'
import { colorCodeCounties, getIncidentsDictionary } from './SecurityConditions'
import Map from '../Map'
import { useWorkspace } from '../../../hooks/useWorkspace'
import useApi from '../../../hooks/testHooks'
import AddAsset from '../../riskregister/forms/AddAsset'
import EditAsset from '../../riskregister/forms/EditAsset'
import AssetRegister from '../../riskregister/listviews/AssetRegister'
import RiskRegister from '../../riskregister/listviews/RiskRegister'
import AssetProfile from '../../riskregister/AssetProfile'
import { OverlayContainer } from '../../GlobalContainers'
import { ZoneTypeButton } from '../../riskregister/StyledContainers'
import Pin from '../../terrain-mapping/icons/poi.svg'
import LinePOI from '../../terrain-mapping/icons/line-poi.svg'
import PolygonPOI from '../forms/images/restriction-zone-amber.svg'
import { createAssetLayers } from '../../../hooks/risks/helpers/MapUtils'
import { useEffect, useState } from 'react'

/**
 *  ================================
 *        RISK REGISTER MAPBOX
 *  ================================
 */
const RiskRegisterMap = () => {
  /* Application hooks */
  const {
    viewMode, mapRef, formatAssets, drawAssets, displayAsset,
    editing, setAllAssets, focusedAsset, dispatchDisplayAsset, setAssetType, setLoading, loading,
  } = useRiskRegister()
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  /* When map component has been loaded request data from backend */
  const onLoadListeners = () : void => {
    setLoading(false)
    mapRef.current.on('load', () => {
      Promise.all([
        fetchAlerStates(false, workspace.id),
        apiHook.getIncidents(workspace.id),
        apiHook.getAssetsByCounty({ domain_id: workspace.id }),
      ]).then(([{ counties, regions }, { incidents }, assetsCounty]) => {
        createAssetLayers(mapRef, assetsCounty.assets)
        /* Add alert states and security conditions to map */
        drawCountyShapesIncidents(counties, mapRef, false, true, regions)
        const incidentsDict = getIncidentsDictionary(incidents)
        colorCodeCounties(counties, incidentsDict, false, moment().format('YYYY-MM-DD hh:mm'))

        /* Format assets and draw them */
        const assets = formatAssets({ ...counties, type: 'FeatureCollection' }, assetsCounty.assets)
        setAllAssets(assets)
        mapRef.current.resize()
        drawAssets(assets)
        setLoading(false)
        mapRef.current.resize()
      })
    })
  }

  const onSelect = (assetType) => {
    setAssetType(assetType)
    dispatchDisplayAsset(DisplayAssetAction.OPEN_ASSET_FORM)
  }

  const onCancel = () => {
    mapRef.current.resize()
    dispatchDisplayAsset(DisplayAssetAction.CLOSE_ASSET_TYPE)
  }

  return (
    <IonRow className='ion-justify-content-center' style={{ height: (viewMode.view === Views.REGISTER_VIEW) ? '80%' : '85%' }}>
      <Map
        mapRef={mapRef}
        zoom={5}
        listeners={onLoadListeners}
        className='risk-register-map'
      >
        {
          viewMode.view === Views.MAP_VIEW && displayAsset.showAssetType && (
            <OverlayContainer style={{ zIndex: 25, width: '650px' }} className='terrain-add-popup'>
              <h5>Choose Asset Type</h5>
              <p>Choose the type of Asset you wish to add to the map.</p>
              <div>
                <ZoneTypeButton onClick={() => onSelect('pin')}>
                  <img src={Pin} alt='Blue Polygon' />
                  <div className='zone-type-text'>
                    <h6>Pin</h6>
                    <p>A pin can be dropped to indicate an Asset. Useful for making single points on the map.
                    </p>
                  </div>
                </ZoneTypeButton>
                <ZoneTypeButton onClick={() => onSelect('line')}>
                  <img src={LinePOI} alt='Blue Polygon' />
                  <div className='zone-type-text'>
                    <h6>Line Marker</h6>
                    <p>A line can be drawn on the map to indicate an asset that is not specific to a single coordinate.
                    </p>
                  </div>
                </ZoneTypeButton>
                <ZoneTypeButton onClick={() => onSelect('polygon')}>
                  <img src={PolygonPOI} alt='Blue Polygon' />
                  <div className='zone-type-text'>
                    <h6>Polygon</h6>
                    <p>An area can be drawn on the map to indicate an asset that is not specific to a single coordinate.
                    </p>
                  </div>
                </ZoneTypeButton>
              </div>
              <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            </OverlayContainer>
          )
        }
        {
          viewMode.view === Views.MAP_VIEW && displayAsset.showAssetForm && (
            <AddAsset />
          )
        }
        {
          viewMode.view === Views.MAP_VIEW && editing && (
            <EditAsset />
          )
        }
        {
          viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.ASSETS && (
            <AssetRegister />
          )
        }
        {
          viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.RISKS && (
            <RiskRegister />
          )
        }
        {
          displayAsset.showProfile && focusedAsset && (
            <AssetProfile />
          )
        }
      </Map>
    </IonRow>
  )
}

export default RiskRegisterMap
