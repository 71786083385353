/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import {
  IonList, IonItem, IonInput, IonButton, useIonAlert, IonRow,
} from '@ionic/react'

import { Icon } from '@iconify/react'

import { Link, useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import axios from '../../utils/axios'
import useApi from '../../hooks/testHooks'
import { SherpaButton } from '../GlobalContainers'
import { RegisterContainer } from '../maps/StyledContainers'

const LoginForm = ({ setDomains, loading, setLoading }) => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const [showPassword, setShowPassword] = useState(false)

  const auth = useAuth()
  const apiHook = useApi()
  const [ionAlert] = useIonAlert()
  const history = useHistory()

  const handleSubmit = () => {
    setLoading(true)
    auth.login(email, password)
      .then((data) => {
        if (data.status !== 'error') {
          axios.defaults.headers.common.Authorization = localStorage.getItem('token')
          apiHook.getRolesbyUser(-1).then(({
            roledata, reset_password, need_to_verify_sms, could_verify_sms,
          }) => {
            setDomains(roledata)
            auth.setResetPassword(reset_password)
            auth.setCouldVerifySms(could_verify_sms)
            auth.setVerifySms(need_to_verify_sms)
            history.push('/workspaces')
          })
        } else {
          ionAlert({
            header: 'Sign In Failed',
            message: data.message,
            buttons: [
              { text: 'OK' },
            ],
          })
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    const callback = (e) => {
      if (e.keyCode === 13) {
        document.getElementById('do-login').click()
      }
    }
    document.addEventListener('keypress', callback)

    return () => {
      document.removeEventListener('keypress', callback)
    }
  }, [])

  return (
    <RegisterContainer>
      <h2 style={{ textAlign: 'left', color: '#8E151F' }}>Login</h2>
      <IonList>
        <IonItem>
          <IonInput id='Email-Address' placeholder='email' type='email' title='Email Address' value={email} onIonChange={(e) => setEmail(e.detail.value)} />
        </IonItem>
        <IonItem>
          <IonInput id='Password' placeholder='password' type={(showPassword) ? 'text' : 'password'} title='Password' value={password} onIonChange={(e) => setPassword(e.detail.value)} />
          <SherpaButton className='show-password' onClick={() => setShowPassword(!showPassword)}>{(showPassword) ? <Icon icon='codicon:eye' /> : <Icon icon='codicon:eye-closed' />} </SherpaButton>
        </IonItem>
      </IonList>
      <IonRow className='ion-justify-content-end'>
        <IonButton id='do-login' disabled={loading} style={{ '--background': '#326771' }} onClick={handleSubmit} title='login'>Log in</IonButton>
      </IonRow>
      <p>Forgot your password? <Link style={{ color: '#8E151F', textDecoration: 'none' }} to='/recovery'>Reset Password</Link> </p>
    </RegisterContainer>
  )
}

export default LoginForm
