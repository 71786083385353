/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

const DashboardButton = styled.div`
  --ion-color-primary: #4197A9;
  display: inline;
`

const OverlayContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  --ion-item-background: white;
  z-index: 2;
  width: 600px;
`

const ObscureBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(69, 69, 68, .2);
  z-index: 1;
  border-radius: 5px;
`

const CenterLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const VerifyEmailContainer = styled.div`
  text-align: center;
  padding: 50px;
`

const SherpaButton = styled.button`
  color: #326771;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
`

const SubTitle = styled.h4`
  color: #326771;
  font-size: 16px;
`

const SimpleDropdown = styled.select`
  padding: 5px;
  border-radius: 5px;
  max-width: 100%;
`

const MetricContainer = styled.div`
  border: 1px solid #326771;
  color: #326771;
  border-radius: 2.5px;
  padding: 10px;
  margin: 10px 0;
  max-width: 300px;
  
  & h4 {
    color: #326771;
    font-size: 16px;
    font-weight: bold;
  }

  & p{
    color: #747474;
  }

  & svg {
    font-size: 3rem;
    text-align: center;
  }

  & p.current {
    font-size: 2rem;
    margin: 0;
    color: #C30101;
    text-align: center;
  }

  & p.theme {
    margin: 0;
  }
`

const LineDivider = styled.div`
  padding: 0.5px;
  background-color: black;
  margin: 10px;
`

export {
  DashboardButton,
  ObscureBackground,
  CenterLoader,
  VerifyEmailContainer,
  SherpaButton,
  SubTitle,
  SimpleDropdown,
  MetricContainer,
  OverlayContainer,
  LineDivider,
}
