import { Icon } from '@iconify/react'
import * as React from 'react'

const GroupIcon = () => (
  <div className='app-icon-container'>
    <Icon icon='ic:baseline-groups' style={{ fontSize: '2rem', color: 'white' }} />
  </div>
)

export default GroupIcon
