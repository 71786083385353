import {
  IonGrid, IonRow, IonButton, IonLabel, IonCheckbox, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { Dropdown } from 'react-bootstrap'
import * as React from 'react'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'
import {
  Views, ListModes, Filters, FocusedActionKind, DisplayAssetAction, ViewsActionKind,
} from '../../hooks/risks/helpers/StateReducers'
import { SelectView } from '../maps/StyledContainers'
import { ConsultantFilter } from '../consultant/StyledContainers'
import { AppView } from '../../hooks/risks/types/HookInterfaces'

/**
 *  ==========================================
 *          RISK ASSESSMENT MAP CONTROLS
 *  ======================================s====
 */
const MapControlsV2 = () : JSX.Element => {
  /* import hook data */
  const {
    viewMode, dispatchViews, displayAsset, dispatchRisk, filterMarkersByState, toggleAllAssets, setView, setShowAssetForm, showAssetForm,
    editing, filters, dispatchAsset, setEditing, dispatchDisplayAsset, focusedAsset, forgetEditingAsset, view,
  } = useRiskRegister()
  const [ionAlert] = useIonAlert()

  /* How many states have been selected in the dropdown */

  /** handle view changes between map and lists */
  const handleViewChange = (newView: AppView) => {
    setView(newView)
  }

  return (
    <IonGrid
      style={{
        backgroundColor: '#FFFFFF',
        padding: '10px 30px',
        marginBottom: '25px',
        boxShadow: '0px 4px 4px 0px #00000040',
        position: 'relative',
        borderRadius: '5px',
      }}
      className='insight-controls-bar'
    >
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <IonRow className='ion-align-items-center'>
          <SelectView
            value={view}
            onChange={(e) => handleViewChange(e.target.value)}
            id='select-app-view'
          >
            <option value={AppView.ASSET_MAP}>{ AppView.ASSET_MAP }</option>
            <option value={AppView.ASSET_REGISTER}>{ AppView.ASSET_REGISTER }</option>
          </SelectView>
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.RISKS && (
              <IonButton
                style={{ '--ion-color-primary': '#326771' }}
                onClick={() => dispatchViews({ type: ViewsActionKind.LIST_ASSETS })}
                id='risk-register-back-button'
              > <Icon icon='akar-icons:arrow-back' /> Back
              </IonButton>
            )
          }
        </IonRow>
        <IonRow className='ion-align-items-center'>
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.RISKS && (
              <>
                {
                  !editing && (
                    <IonButton
                      style={{ '--ion-color-primary': '#0C9500' }}
                      id='add-risk-assessment-button'
                      onClick={() => {
                        dispatchDisplayAsset(DisplayAssetAction.OPEN_RISK_FORM)
                        dispatchDisplayAsset(DisplayAssetAction.OPEN_ASSET_FORM)
                      }}
                    >Add Risk
                    </IonButton>
                  )
                }
                <IonButton
                  style={{ '--ion-color-primary': '#326771' }}
                  onClick={() => setEditing(!editing)}
                  disabled={displayAsset.showAssetForm}
                >
                  {(editing) ? 'Done' : 'Edit/Review'}
                </IonButton>
              </>
            )
          }
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.ASSETS && (
              <IonButton
                style={{ '--ion-color-primary': '#326771' }}
                onClick={() => setEditing(!editing)}
                id='assets-edit-button'
                disabled={displayAsset.showAssetForm}
              >
                {(editing) ? 'Done' : 'Edit'}
              </IonButton>
            )
          }
          {
          viewMode.view === Views.MAP_VIEW && (
            <>
              <IonButton
                style={{ '--ion-color-primary': '#0C9500' }}
                onClick={() => setShowAssetForm(true)}
                disabled={showAssetForm}
                id='add-asset-button'
              >Add Asset
              </IonButton>
            </>
          )
        }
        </IonRow>
      </IonRow>

    </IonGrid>
  )
}

export default MapControlsV2
