/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import moment from 'moment'
import {
  IonButton, IonRow, IonGrid, IonCol,
} from '@ionic/react'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { IncidentList, SimpleButton } from '../../StyledContainers'
import AddLogPopup from './AddLogPopup'
import { ObscureBackground } from '../../../GlobalContainers'
import { useAuth } from '../../../../hooks/useAuth'
import { IncidentViewActionKind } from '../../../../hooks/incident-management/helpers/StateReducers'
import { IncidentLogRecord, User } from '../../../types/GlobalTypes'
import useApi from '../../../../hooks/testHooks'

/**
 *  =====================================
 *      LOG DECISIONS AND EVENTS
 *  =====================================
 */
const LogBook = () => {
  const [showPopup, setShowPopup] = useState(false)
  /* Get selected incident's data */
  const {
    updateIncident, displayView, dispatchView, setLoading,
  } = useIncidentsData()
  const { user } = useAuth()
  const apiHook = useApi()
  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  const addNewLog = (input: { owner: User, description: string }) => {
    setShowPopup(false)
    setLoading(true)
    apiHook.createActionLog({ log: input.description, owner: input.owner.id, incident_id: displayView.focusedIncident.id })
      .then(({ alog }) => {
        const newLog : IncidentLogRecord = {
          log: input.description,
          created_at: moment().format(),
          owner: input.owner.id,
          ownerObj: input.owner,
          submitterObj: { ...user, id: user.user_id },
          user_id: user.user_id,
          internal_incident_id: displayView.focusedIncident?.id,
          updated_at: moment().format(),
          id: alog.id,
          void: false,
        }
        const updated = { ...displayView.focusedIncident, logs: [...displayView.focusedIncident?.logs, newLog] }
        updateIncident(updated)
        dispatchView({ type: IncidentViewActionKind.UPDATE_INCIDENT, incident: updated })
      }).finally(() => {
        setLoading(false)
      })
  }

  const voidLog = (log: IncidentLogRecord, index: number) => {
    apiHook.voidActionLog({ alog_id: log.id })
      .then(() => {
        const updated = { ...log, void: !log.void }
        const copy = displayView.focusedIncident.logs?.slice()
        copy.splice(index, 1, updated)

        updateIncident({ ...displayView.focusedIncident, logs: copy })
        dispatchView({ type: IncidentViewActionKind.UPDATE_INCIDENT, incident: { ...displayView.focusedIncident, logs: copy } })
      })
  }

  return (
    <>
      {
        showPopup && (
          <>
            <ObscureBackground />
            <AddLogPopup
              onClose={() => setShowPopup(false)}
              onSubmit={addNewLog}
            />
          </>
        )
      }
      <div className='risk-assessment-list' style={{ height: '100%' }}>
        <div>
          <h4>Log Book</h4>
          <IonRow style={{ margin: '15px 0' }} className='ion-justify-content-between'>
            <p>The log book is a space for key decisions and events to be logged for record keeping purposes. </p>
            {
              !displayView.focusedIncident.archived && (
                <IonButton style={{ '--background': '#0C9500' }} onClick={() => setShowPopup(true)}>Add Log</IonButton>
              )
            }
          </IonRow>
        </div>
        <IonGrid style={{
          width: '100%',
          padding: '0',
          border: '1px solid #7E7E7E',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          overflow: 'hidden',
        }}
        >
          <IonRow>
            <IonCol size='2'><h5> Date  </h5></IonCol>
            <IonCol size='2'><h5> Time </h5> </IonCol>
            <IonCol size='3'><h5> Log </h5></IonCol>
            <IonCol size='2'><h5> Owner </h5></IonCol>
            <IonCol size='2'><h5> Submitted By </h5></IonCol>
            <IonCol size='1' />
          </IonRow>
          <IncidentList style={{ flex: 1, overflow: 'auto' }}>
            {
              displayView.focusedIncident?.logs && displayView.focusedIncident?.logs.map((log, index) => (
                <IonRow key={log.id} className='ion-align-items-center' style={{ background: getColor(index) }}>
                  <IonCol size='2'><p style={{ margin: '0', textDecoration: (log.void) ? 'line-through' : 'none' }}> { moment(log.created_at).format('DD/MM/YY') } </p></IonCol>
                  <IonCol size='2'><p style={{ margin: '0', textDecoration: (log.void) ? 'line-through' : 'none' }}> { moment(log.created_at).format('HH:mm') } </p></IonCol>
                  <IonCol size='3'><p style={{ margin: '0', textDecoration: (log.void) ? 'line-through' : 'none' }}> { log.log } </p></IonCol>
                  <IonCol size='2'><p style={{ margin: '0', textDecoration: (log.void) ? 'line-through' : 'none' }}> { `${log.ownerObj?.first_name} ${log.ownerObj?.final_name}` } </p></IonCol>
                  <IonCol size='2'><p style={{ margin: '0', textDecoration: (log.void) ? 'line-through' : 'none' }}> { `${log.submitterObj?.first_name} ${log.submitterObj?.final_name}` } </p></IonCol>
                  <IonCol size='1'>
                    <SimpleButton onClick={() => voidLog(log, index)} style={{ color: (log.void) ? '#4B99F4' : '#C30101', textDecoration: 'underline' }}>
                      { (log.void) ? 'Undo' : 'Void' }
                    </SimpleButton>
                  </IonCol>
                </IonRow>
              ))
            }
          </IncidentList>
        </IonGrid>
      </div>
    </>
  )
}

export default LogBook
