import * as React from 'react'

import { IonCol, IonContent, IonRow } from '@ionic/react'
import { Icon } from '@iconify/react'

import { SettingField } from '../settings/StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { CenterForm } from './StyledContainers'
import { SherpaButton } from '../GlobalContainers'
import AssetMeasures from './Assetmeasures'
import { getCurrentLevel, getDescription } from '../maps/apps/AppMap'
import { getLevelDescription, getLevelTitle } from '../maps/apps/SecurityConditions'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'
import { Asset } from './types/Assets'
import { DisplayAssetAction, FocusedActionKind, ViewsActionKind } from '../../hooks/risks/helpers/StateReducers'

/**
 *  =============================
 *          Asset Details
 *  =============================
 * Displays a risk matrix with the risk assessments to this asset.
 */

const AssetProfile = () : JSX.Element => {
  const {
    focusedAsset, dispatchAsset, dispatchDisplayAsset, dispatchViews, fetchAssetDetails,
  } = useRiskRegister()

  const assetDetails = focusedAsset as Asset
  const [showMeasures, setShowMeasures] = React.useState<boolean>(false)
  const [showLoading, setShowLoading] = React.useState<boolean>(false)

  const fetchDetails = () => {
    setShowLoading(true)
    fetchAssetDetails().then(() => {
      setShowLoading(false)
    })
    setShowMeasures(true)
  }

  return (
    <CenterForm style={{ padding: '0', height: '90%', width: '55%' }}>
      <IonContent>
        <IonRow style={{ backgroundColor: '#8E151F', padding: '10px' }} className='ion-justify-content-between ion-align-items-center'>
          <h3 style={{
            color: 'white', fontWeight: 'bold', margin: '0', fontSize: '1.3rem',
          }}
          > { assetDetails.name }
          </h3>
          <SimpleButton
            style={{ color: 'white' }}
            onClick={() => {
              dispatchAsset({ type: FocusedActionKind.FORGET })
              dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
            }}
          >Close X
          </SimpleButton>
        </IonRow>
        {
          (showMeasures) ? (
            <AssetMeasures
              showLoading={showLoading}
              focusedAsset={assetDetails}
              onReturn={() => setShowMeasures(false)}
            />
          ) : (
            <>
              <IonRow style={{ padding: '10px', paddingBottom: 0 }}>
                <IonCol size='6'>
                  <SettingField style={{ width: 'auto' }}>
                    <h4>Asset Type</h4>
                    <p>{ assetDetails.type }</p>
                  </SettingField>
                  <SettingField className='text-no-margin' style={{ width: 'auto', marginTop: '10px' }}>
                    <h4>Location</h4>
                    <p>{ assetDetails.location.county }</p>
                    <p>{ assetDetails.location.state }</p>
                    <p>{ assetDetails.location.country }</p>
                  </SettingField>
                  <SettingField style={{ width: 'auto', marginTop: '10px' }}>
                    <h4>Coordinates</h4>
                    <p>{assetDetails.coordinates.lng}, {assetDetails.coordinates.lat}</p>
                  </SettingField>
                </IonCol>
                <IonCol size='6'>
                  <SherpaButton
                    onClick={fetchDetails}
                    style={{
                      fontSize: '.8rem', fontWeight: 'bold', marginTop: '20px', display: 'block',
                    }}
                  >
                    View Control Measures <Icon icon='fe:arrow-right' />
                  </SherpaButton>
                  <SherpaButton
                    onClick={() => {
                      dispatchViews({ type: ViewsActionKind.LIST_RISKS })
                    }}
                    style={{ fontSize: '.8rem', fontWeight: 'bold', display: 'block' }}
                  >
                    View Risk Register <Icon icon='fe:arrow-right' />
                  </SherpaButton>
                  <SherpaButton
                    onClick={() => {
                      dispatchDisplayAsset(DisplayAssetAction.OPEN_MATRIX)
                    }}
                    style={{ fontSize: '.8rem', fontWeight: 'bold', display: 'block' }}
                  >
                    View Heat Map <Icon icon='fe:arrow-right' />
                  </SherpaButton>
                  <SherpaButton style={{ fontSize: '.8rem', fontWeight: 'bold', display: 'block' }}>
                    View Works in Progress <Icon icon='fe:arrow-right' />
                  </SherpaButton>
                </IonCol>
              </IonRow>
              {
                assetDetails.alert_state && (
                  <SettingField style={{ width: 'auto', padding: '0 12px', margin: '0 10px' }}>
                    <h4>Current Alert State</h4>
                    <IonRow style={{ alignItems: 'flex-start' }}>
                      <IonCol size='4'>
                        <p
                          className='alert-state-display'
                          style={{
                            fontSize: '.8rem',
                            textAlign: 'center',
                            width: 'fit-content',
                            color: getCurrentLevel(assetDetails.alert_state).color,
                            backgroundColor: getCurrentLevel(assetDetails.alert_state).background,
                          }}
                        >
                          {getCurrentLevel(assetDetails.alert_state).title}
                        </p>
                      </IonCol>
                      <IonCol size='8'>
                        <p style={{ padding: '0 10px' }}>{ getDescription(assetDetails.alert_state) }</p>
                      </IonCol>
                    </IonRow>
                  </SettingField>
                )
              }
              {
                assetDetails.security_condition && (
                  <SettingField style={{ width: 'auto', padding: '0 12px', margin: '0 10px' }}>
                    <h4>Security Condition Level</h4>
                    <IonRow style={{ alignItems: 'flex-start' }}>
                      <IonCol size='4'>
                        <p
                          className='alert-state-display'
                          style={{
                            fontSize: '.8rem',
                            width: 'fit-content',
                            color: getCurrentLevel(assetDetails.security_condition).color,
                            backgroundColor: getCurrentLevel(assetDetails.security_condition).background,
                            textAlign: 'center',
                          }}
                        >
                          {getLevelTitle(assetDetails.security_condition)}
                        </p>
                      </IonCol>
                      <IonCol size='8'>
                        <p style={{ padding: '0 10px' }}>{ getLevelDescription(assetDetails.alert_state) }</p>
                      </IonCol>
                    </IonRow>
                  </SettingField>
                )
              }
            </>
          )
        }
      </IonContent>
    </CenterForm>
  )
}

export default AssetProfile
