import React, { FC } from 'react'
import { BlueButton } from '../../incident-management/StyledContainers'
import { Community } from '../../types/OptimizedMaps'
import FlashpointIcon from '../icons/flashpoint.svg'
import StakeholderIcon from '../icons/stakeholder.svg'
import IssueIcon from '../icons/issue.svg'
import EngIcon from '../icons/eng-note.svg'

interface Props {
  community: Community,
  hasFlashpoints: boolean,
  hasStakeholders: boolean,
  hasEngagements: boolean,
  hasIssues: boolean,
  onClick: () => void
}

const CommunityPopup : FC<Props> = ({
  community, hasFlashpoints, hasStakeholders, hasEngagements, hasIssues, onClick,
}) => (
  <div style={{ borderRadius: '4em', width: '200px' }}>
    <h4 style={{ color: '#326771' }}>{ community.name }</h4>
    <p>{community.description.length > 120 ? `${community.description.slice(0, 120)}...` : community.description}</p>
    {
      (hasFlashpoints || hasStakeholders || hasEngagements || hasIssues) && (
        <div className='sit-icons'>
          {
            hasFlashpoints && (
              <img src={FlashpointIcon} alt='Flashpoint Icon' />
            )
          }
          {
            hasEngagements && (
              <img src={EngIcon} alt='Flashpoint Icon' />
            )
          }
          {
            hasStakeholders && (
              <img src={StakeholderIcon} alt='Flashpoint Icon' />
            )
          }
          {
            hasIssues && (
              <img src={IssueIcon} alt='Issue Icon' />
            )
          }
        </div>
      )
    }
    <div className='view-container'>
      <BlueButton onClick={onClick}>View</BlueButton>
    </div>
  </div>
)

export default CommunityPopup
