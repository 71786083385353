import React, { useState } from 'react'
import {
  IonCol, IonItem, IonLabel,
} from '@ionic/react'
import { TabBarContainer, ViewMarker } from '../StyledContainers'
import WorkspaceUsers from './WorkspaceUsers'
import NewWorkspace from './NewWorkspace'
import WorkspaceDetails from './WorkspaceDetails'

/**
 *  ===============================
 *    Current Workspace settings
 *  ===============================
 */
const WorkspaceSettings = ({
  domainDetails, users, setDomain, addDomain, updateDomain,
}) => {
  const [view, setView] = useState('details')
  const [newDomain, setNewDomain] = useState(false)

  return (
    <>
      <IonCol className='ion-no-padding' size={3}>
        <TabBarContainer>
          <IonItem
            button
            className='ion-no-padding'
            style={{ '--background': (view === 'details') ? 'white' : '#F2F2F2', color: '#8E151F' }}
            onClick={() => setView('details')}
          >
            {
              view === 'details' && (
                <ViewMarker />
              )
            }
            <IonLabel className='ion-text-center'>Workspace Details</IonLabel>
          </IonItem>
          <IonItem
            button
            className='ion-no-padding'
            style={{ '--background': (view === 'users') ? 'white' : '#F2F2F2', color: '#8E151F' }}
            onClick={() => setView('users')}
          >
            {
              view === 'users' && (
                <ViewMarker />
              )
            }
            <IonLabel className='ion-text-center'>Workspace Users</IonLabel>
          </IonItem>
        </TabBarContainer>
      </IonCol>
      <IonCol style={{ height: '100%' }} size={9}>
        {
          view === 'details' && !newDomain && (
            <WorkspaceDetails
              domainDetails={domainDetails}
              setNewWorkspace={setNewDomain}
              setWorkspace={setDomain}
              updateDomain={updateDomain}
            />
          )
        }
        {
          view === 'details' && newDomain && (
            <NewWorkspace
              setNewWorkspace={setNewDomain}
              users={users}
              addDomain={addDomain}
            />
          )
        }
        {
          view === 'users' && (
            <WorkspaceUsers
              domainDetails={domainDetails}
              dbUsers={users}
              setDomainDetails={setDomain}
            />
          )
        }
      </IonCol>
    </>
  )
}

export default WorkspaceSettings
