import React from 'react'
import ReactDOM from 'react-dom'

import { IonApp } from '@ionic/react'

import '@ionic/react/css/core.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import '../stylesheets/colours.css'
import '../stylesheets/Styles.css'

import Providers from './Providers'
import Router from '../components/Router'

const App = () => (
  <Providers>
    <IonApp>
      <Router />
    </IonApp>
  </Providers>
)

/* eslint-disable */
document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App name="Sherpa" />,
    document.body.appendChild(document.createElement('div')),
  )
})
/* eslint-enable */
