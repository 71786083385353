/* eslint-disable camelcase */
import {
  IonCol, IonContent, IonGrid, IonPage, IonRow,
} from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { SettingsContainer } from '../components/settings/StyledContainers'
import PageHeader from '../components/PageHeader'
import TabBar from '../components/settings/TabBar'
import TabContent from '../components/settings/TabContent'

import axios from '../utils/axios'

import useApi from '../hooks/testHooks'
import { useAuth } from '../hooks/useAuth'
import { useWorkspace } from '../hooks/useWorkspace'
import LoadingModal from '../components/modals/LoadingModal'
import { ProvideSettings, useSettings } from '../hooks/settings/useSettings'
import { ObscureBackground } from '../components/GlobalContainers'
import InviteUsers from '../components/popups/InviteUsers'

/**
 *  ==========================================
 *     Update user and worspace preferences
 *  ==========================================
 */

const Settings = ({ addDomain, updateDomain }) => (
  <ProvideSettings>
    <AppWrapper addDomain={addDomain} updateDomain={updateDomain} />
  </ProvideSettings>
)

const AppWrapper = ({ addDomain, updateDomain }) => {
  const [view, setView] = useState('account')
  const { workspace } = useWorkspace()
  const [domain, setDomain] = useState({
    id: workspace.id,
    name: workspace.name,
    country: workspace.country,
    users: [],
  })
  const {
    loading, setLoading, setWorkspaceUsers, setUsers, showInvitePopup, users,
  } = useSettings()
  const apiHook = useApi()
  const auth = useAuth()

  useEffect(() => {
    Promise.all([apiHook.getUsers(workspace.id), apiHook.getWorkspace(workspace.id), axios.post('/user/other_users', { domain_id: workspace.id })])
      .then(([dbUsers, workspaceData, allUsers]) => {
        const domainUsers = workspaceData.users.map(({ user_id, type }) => {
          const userData = dbUsers.users.find((user) => user.id === user_id)
          if (userData) {
            userData.permission = type
          }

          return userData
        })
        const filteredUsers = domainUsers.filter((val) => val)

        setWorkspaceUsers(filteredUsers.filter((user) => user.email !== auth.user.email))
        setDomain({ ...domain, users: filteredUsers.filter((user) => user.email !== auth.user.email) })
        setUsers(allUsers.data)
        setLoading(false)
      })
  }, [])

  const changeView = (newView) => {
    setView(newView)
  }
  return (
    <IonPage>
      <PageHeader title='Settings' />
      <IonContent>
        <SettingsContainer>
          <IonGrid style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            padding: 0,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
          >
            <LoadingModal isOpen={loading} />
            {
              showInvitePopup && (
                <>
                  <ObscureBackground style={{ zIndex: 24 }} />
                  <InviteUsers />
                </>
              )
            }
            <IonRow style={{ height: '100%', padding: 0 }}>
              <IonCol style={{ height: '100%', padding: 0 }} size={1}>
                <TabBar
                  view={view}
                  setView={changeView}
                  permission={workspace.type}
                />
              </IonCol>
              <IonCol style={{ height: '100%', position: 'relative' }} size={11}>
                <TabContent
                  view={view}
                  userDetails={{ ...auth.user }}
                  domainDetails={domain}
                  setWorkspace={setDomain}
                  users={users}
                  addDomain={addDomain}
                  updateDomain={updateDomain}
                  setLoading={setLoading}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </SettingsContainer>
      </IonContent>
    </IonPage>
  )
}

export default Settings
