/* eslint-disable react/no-array-index-key */
import React, { useState, useRef, useEffect } from 'react'
import {
  IonRow, IonCol, IonInput, IonButton, useIonAlert, IonItem, IonLabel, IonContent,
} from '@ionic/react'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { useAuth } from '../../../../hooks/useAuth'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { operations, people, property } from '../../../riskregister/forms/FormOptions'
import { getImpactCategory } from '../../../riskregister/forms/utils/Categories'
import { IncidentViewActionKind } from '../../../../hooks/incident-management/helpers/StateReducers'
import { getDateString } from '../../../../helpers/GlobalFunctions'
import axios from '../../../../utils/axios'
import useApi from '../../../../hooks/testHooks'
import AddImage from '../popups/AddImage'
import { processIncidentComments } from '../../../../hooks/incident-management/helpers/Utils'
import { ObscureBackground } from '../../StyledContainers'
import { useWorkspace } from '../../../../hooks/useWorkspace'

const propertyImpact = { None: 'No Impact on Properties', ...property }
const peopleImpact = { None: 'No Impact on people.', ...people }
const operationsImpact = { None: 'No Impact on operations.', ...operations }

/**
 *  ==============================
 *        INCIDENT TICKET
 *  ==============================
 */
const IncidentTicket = () => {
  const {
    updateIncident, displayView, dispatchView,
    fetchIncidentData, setLoading, closeIncident, users,
  } = useIncidentsData()
  const [input, setInput] = useState('')
  const contentRef = useRef<HTMLIonContentElement>(null)
  const { workspace } = useWorkspace()
  const { user } = useAuth()
  const [alert] = useIonAlert()
  const apiHook = useApi()
  const [showFilePopup, setShowFilePopup] = useState<boolean>(false)

  /* Auto scrolling */
  const scrollToBottom = () => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      contentRef.current && contentRef.current?.scrollToBottom()
    }, 300)
  }

  const handleSubmitImages = (files: FileList) => {
    const formData = new FormData()
    Array.from(files).forEach((val) => {
      formData.append('files[]', val)
    })
    formData.append('internal_incident_id', String(displayView.focusedIncident.id))

    setLoading(true)
    axios.post('/api/v1/internal_incident/upload_incident_images', formData)
      .then(({ data }) => {
        const updated = { ...displayView.focusedIncident, additionalComments: processIncidentComments(users, data.incident_comments) }
        dispatchView({ type: IncidentViewActionKind.UPDATE_INCIDENT, incident: updated })
        updateIncident(updated)
        setShowFilePopup(false)
        setLoading(false)
      })
  }

  const handleComment = () => {
    /* Check if something has been typed in by the user */
    if (input === '') {
      alert({
        header: 'Error',
        message: 'You have not typed any comment.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    setLoading(true)
    apiHook.incidentComment({ comment: input, internal_incident_id: displayView.focusedIncident.id })
      .then(() => {
        const additionalComments = displayView.focusedIncident.additionalComments?.slice()
        additionalComments.push({
          user: { ...user, id: user.user_id },
          user_id: user.user_id,
          comment: input,
          internal_incident_id: displayView.focusedIncident?.id,
          created_at: moment().format(),
          updated_at: moment().format(),
          image_urls: [],
        })

        const updated = { ...displayView.focusedIncident, additionalComments }
        dispatchView({ type: IncidentViewActionKind.UPDATE_INCIDENT, incident: updated })
        updateIncident(updated)
        setInput('')
      }).finally(() => {
        setLoading(false)
      })
  }

  const handleClose = () => {
    setLoading(true)
    apiHook.closeIncident({ incident_id: displayView.focusedIncident.id })
      .then(() => {
        closeIncident()
        if (!workspace.is_incident_domain) {
          dispatchView({ type: IncidentViewActionKind.FORGET_INCIDENT })
        }
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    scrollToBottom()
    contentRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [displayView])

  /* Fetch data in case the incident doesn't have it */
  useEffect(() => {
    if (!displayView.focusedIncident.peopleInvolved) {
      fetchIncidentData()
    }
  }, [])

  return (
    <div className='risk-assessment-list' style={{ height: '100%' }}>
      {
        !displayView.focusedIncident.archived && (
          <IonRow style={{ marginBottom: '10px' }} className='ion-justify-content-end'>
            <IonButton onClick={handleClose} style={{ '--background': '#0C9500' }}>Close Incident Report</IonButton>
          </IonRow>
        )
      }
      {
        showFilePopup && (
          <>
            <ObscureBackground />
            <AddImage
              onClose={() => setShowFilePopup(false)}
              onSubmit={handleSubmitImages}
            />
          </>
        )
      }
      <IonContent ref={contentRef} scrollEvents style={{ maxHeight: '90%', overflowY: 'auto', '--background': 'white' }}>
        <div style={{
          borderTop: '1px dashed #000000', borderBottom: '1px dashed #000000', padding: '0 25px', marginBottom: '30px',
        }}
        >
          <IonRow className='incident-management-form-spacing'>
            <IonCol size='4'>
              <div>
                <h4>Title</h4>
                <p> { displayView.focusedIncident.title } </p>
              </div>
            </IonCol>
            <IonCol size='4'>
              <div>
                <h4>Date of Incident</h4>
                <p> { moment(displayView.focusedIncident.reported).format('DD/MM/YY') } </p>
              </div>
            </IonCol>
            <IonCol size='4'>
              <div>
                <h4>Date Reported</h4>
                <p> { moment(displayView.focusedIncident.created_at).format('DD/MM/YY') } </p>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className='incident-management-form-spacing'>
            <IonCol size='4'>
              <div>
                <h4>Primary Incident</h4>
                <p> { displayView.focusedIncident.primary_title } </p>
              </div>
            </IonCol>
            <IonCol size='4'>
              <div>
                <h4>Category</h4>
                <p> { displayView.focusedIncident.primary_category } </p>
              </div>
            </IonCol>
            <IonCol size='4'>
              <div>
                <h4>Type</h4>
                <p> { displayView.focusedIncident.primary_type } </p>
              </div>
            </IonCol>
          </IonRow>
          <div className='incident-management-form-spacing'>
            <IonRow>
              <IonCol style={{ padding: '0' }} size='4'>
                <h4>Additional Incident(s)</h4>
              </IonCol>
              <IonCol style={{ padding: '0' }} size='4'>
                <h4>Category</h4>
              </IonCol>
              <IonCol style={{ padding: '0' }} size='4'>
                <h4>Type</h4>
              </IonCol>
            </IonRow>
            {
              displayView.focusedIncident.additionalIncidents?.map((val, index) => (
                <IonRow key={index}>
                  <IonCol size='4'>
                    <p>{ val.title }</p>
                  </IonCol>
                  <IonCol size='4'>
                    <p>{ val.category }</p>
                  </IonCol>
                  <IonCol size='4'>
                    <p>{ val.secondary_type }</p>
                  </IonCol>
                </IonRow>
              ))
            }
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>Location of Primary Incident</h4>
            <p>Coordinates: { `${displayView.focusedIncident.longitude.toFixed(4)}, ${displayView.focusedIncident.latitude.toFixed(4)}` }</p>
            <p>County: { displayView.focusedIncident.county?.name }</p>
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>Was the reporter involved?</h4>
            <p> { (displayView.focusedIncident.involved) ? 'Yes' : 'No' } </p>
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>People Involved</h4>
            {
              displayView.focusedIncident.peopleInvolved?.map((val, index) => (
                <IonRow key={index}>
                  <IonCol size='3'>
                    <p>{ `${val.user.first_name} ${val.user.final_name}` }</p>
                  </IonCol>
                  <IonCol size='3'>
                    <p>{ val.type }</p>
                  </IonCol>
                  <IonCol size='3'>
                    <p>{ val.user.email }</p>
                  </IonCol>
                  <IonCol size='3'>
                    <p>{ val.user.phone_number }</p>
                  </IonCol>
                </IonRow>
              ))
            }
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>External Actors Involved</h4>
            {
              displayView.focusedIncident.actors?.map(({ name, id }) => (
                <p key={id} style={{ margin: '5px 0' }}> {name} </p>
              ))
            }
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>Incident Details</h4>
            <p> { displayView.focusedIncident.description } </p>
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>Consequences</h4>
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h5>People</h5>
            { displayView.focusedIncident.people !== undefined && peopleImpact[getImpactCategory(displayView.focusedIncident.people)] }
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h5>Operations</h5>
            { displayView.focusedIncident.people !== undefined && operationsImpact[getImpactCategory(displayView.focusedIncident.operations)] }
          </div>
          <div style={{ padding: '5px', marginBottom: '20px' }} className='incident-management-form-spacing'>
            <h5>Property & Equipment</h5>
            { displayView.focusedIncident.people !== undefined && propertyImpact[getImpactCategory(displayView.focusedIncident.property)] }
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>Actions Taken</h4>
            <p> { displayView.focusedIncident.taken } </p>
          </div>
          <div style={{ padding: '5px' }} className='incident-management-form-spacing'>
            <h4>Assistance Required</h4>
            <p> { displayView.focusedIncident.required } </p>
          </div>
        </div>
        <div style={{ padding: '5px' }}>
          <h4 style={{ margin: '20px 0' }} className='incident-management-form-spacing incident-ticket-title-spacing'>Additional Information</h4>
          {
            displayView.focusedIncident.additionalComments?.map((val, index) => (
              <IonRow key={index} className='ion-align-items-center'>
                <IonCol size='3'>
                  { `${val.user.first_name} ${val.user.final_name}` }
                </IonCol>
                <IonCol size='9'>
                  <IonItem style={{ maxHeight: '100%', borderRadius: '2.5px' }} color='usermessage' lines='none' text-left text-wrap class='message-size incident-ticket-additional'>
                    <IonLabel style={{ textAlign: 'left' }} text-wrap>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        {
                          val.image_urls.map(({ url }) => (
                            <img key={url} src={url} alt='Incident Evidence' className='incident-ticket-image' />
                          ))
                        }
                      </div>
                      {val.comment}
                      <IonLabel style={{ padding: '0px' }} class='ion-text-right'>
                        <p style={{ fontSize: '0.8em', padding: 'none' }}> { getDateString(val.created_at) } </p>
                      </IonLabel>
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            ))
            }
          {
            displayView.focusedIncident.additionalComments && displayView.focusedIncident.additionalComments?.length === 0 && (
            <p style={{ textAlign: 'center' }}> No additional comments </p>
            )
          }
        </div>
      </IonContent>
      {
        !displayView.focusedIncident.archived && (
          <IonRow className='ion-align-items-center'>
            <IonCol size='9'>
              <IonInput type='text' value={input} onIonChange={(e) => setInput(e.detail.value)} className='ticket-comment no-margin' placeholder='Description' />
            </IonCol>
            <IonCol size='3'>
              <IonRow>
                <IonButton style={{ '--background': '#326771' }} onClick={() => setShowFilePopup(true)}><Icon icon='material-symbols:attach-file' fontSize='1rem' /> Image</IonButton>
                <IonButton style={{ '--background': '#0C9500' }} onClick={handleComment}>Comment</IonButton>
              </IonRow>
            </IonCol>
          </IonRow>
        )
      }
    </div>
  )
}
export default IncidentTicket
