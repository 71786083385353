/* eslint-disable import/no-webpack-loader-syntax */
import * as React from 'react'
import {
  IonRow, IonButton, useIonAlert,
} from '@ionic/react'
import axios from '../../../utils/axios'

import * as mapboxgl from '!mapbox-gl'

import { SimpleButton, MapInstructions } from '../../maps/StyledContainers'
import { FormContainer } from '../../maps/forms/MapForm'
import AssetDetails from './AssetDetails'

import useApi from '../../../hooks/testHooks'
import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'
import { EditAssetActionKind } from '../../../hooks/risks/helpers/StateReducers'
/**
 *  ===============================
 *      Edit Key asset form
 *  ===============================
 */
const EditAsset = () : JSX.Element => {
  /* Integrate app hooks */
  const {
    editAssetInput, dispatchEditingAsset, deleteAsset, submitEditAsset, forgetEditingAsset,
  } = useRiskRegister()
  const [ionAlert] = useIonAlert()
  const apiHook = useApi()

  const handleNext = () => {
    editAssetInput.marker.togglePopup()
    dispatchEditingAsset({ type: EditAssetActionKind.SHOW_DETAILS_FORM })
    /* Get location details */
    if (editAssetInput.newLocation) {
      axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${editAssetInput.newLocation.lng},${editAssetInput.newLocation.lat}.json?access_token=${mapboxgl.accessToken}`)
        .then(({ data }) => {
          const loc = {
            state: data.features[0].text,
            country: data.features[1].text,
          }
          dispatchEditingAsset({ type: EditAssetActionKind.EDIT_DETAILS, payload: { ...editAssetInput, asset: { ...editAssetInput.asset, location: loc } } })
        })
    }

    // create edit metrics
    apiHook.createRiskMetric('edit', editAssetInput.asset.id)
  }

  /* Delete/Archived selected asset */
  const removeAsset = () => {
    /* Display popup to check if user really wishes to delete the asset */
    ionAlert({
      header: 'Delete Asset?',
      message: 'Do you wish to delete this asset?',
      buttons: [
        { text: 'No' },
        {
          text: 'Yes',
          handler: () => {
            deleteAsset(editAssetInput.asset)
          },
        },
      ],
    })
  }

  const backForm = () => {
    dispatchEditingAsset({ type: EditAssetActionKind.HIDE_DETAILS_FORM })
  }

  const handleSubmit = () => {
    if (editAssetInput.asset.name === '' || !editAssetInput.asset.name) {
      ionAlert({
        header: 'Error - Asset Name',
        message: 'Please name your asset.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    if (!editAssetInput.asset.type) {
      ionAlert({
        header: 'Error - Asset Type',
        message: 'Please select a type for your asset',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }
    submitEditAsset()
  }

  return (
    <>
      {
        (editAssetInput.showEditDetails) ? (
          <AssetDetails
            handleSubmit={handleSubmit}
            backForm={backForm}
          />
        ) : (
          <>
            {
              !editAssetInput.asset && (
                <FormContainer style={{ height: 'auto', backgroundColor: 'white' }}>
                  <MapInstructions>
                    <h2 style={{ color: '#326771' }}>Edit Key Asset</h2>
                    <div style={{ marginTop: '20px', padding: '0 10px' }}>
                      <p>Click on the  Key Asset you wish to edit. </p>
                    </div>
                  </MapInstructions>
                </FormContainer>
              )
            }
            {
              editAssetInput.asset && (
                <FormContainer style={{ height: 'auto', backgroundColor: 'white' }}>
                  <MapInstructions>
                    <h2 style={{ color: '#326771' }}>Edit Key Asset</h2>
                    <div style={{ marginTop: '20px', padding: '0 10px' }}>
                      <p>Drag the pin to  change the asset’s position on the map. </p>
                      <p> Click ‘Next’ to edit the Asset’s details.</p>
                    </div>
                    <IonRow className='ion-justify-content-between'>
                      <SimpleButton onClick={removeAsset} style={{ color: '#E70000FE', textDecoration: 'underline' }}>Delete Asset</SimpleButton>
                      <IonRow>
                        <IonButton style={{ '--background': '#8E151F' }} onClick={forgetEditingAsset}>Cancel</IonButton>
                        <IonButton style={{ '--background': '#0C9500' }} onClick={handleNext}>Next</IonButton>
                      </IonRow>
                    </IonRow>
                  </MapInstructions>
                </FormContainer>
              )
            }
          </>
        )
      }
    </>
  )
}

export default EditAsset
