/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { IonContent, IonRow, IonCol } from '@ionic/react'
import TaskCard from './TaskCard'

/**
 *  ============================
 *      Archived Tasks Board
 *  ============================
 */
const ArchivedView = ({ tasks, setFocussedTask }) => {
  const [groupedTasks, setGroupedTasks] = useState({})
  /* Group tasks if they happened in the same day  */
  const GroupByDate = () => {
    if (!tasks.length) { return {} }

    return tasks.reduce((r, task) => {
      r[moment(task.due).format('DD/MM/YY')] = [...r[moment(task.due).format('DD/MM/YY')] || [], task]
      return r
    }, {})
  }

  const getRows = (arr) => arr.reduce((acc, curr, i) => {
    if (!(i % 3)) { // if index is 0 or can be divided by the `size`...
      acc.push(arr.slice(i, i + 3)) // ..push a chunk of the original array to the accumulator
    }
    return acc
  }, [])

  useEffect(() => {
    setGroupedTasks(GroupByDate())
  }, [tasks])

  return (
    <div style={{ height: '85%', backgroundColor: 'white', padding: '20px' }}>
      <IonContent style={{
        '--ion-background-color': '#326771',
        padding: '20px',
        '--padding-bottom': '20px',
        '--padding-end': '20px',
        '--padding-start': '20px',
        '--padding-top': '20px',
      }}
      >
        <h4 style={{ color: '#FFFFFF' }}>Archived Tasks</h4>
        {
          Object.keys(groupedTasks).map((date) => (
            <div key={date} style={{ padding: '20px' }}>
              <h5 style={{
                color: '#FFFFFF', borderBottom: '1px solid white', textAlign: 'center', paddingBottom: '10px',
              }}
              > { date }
              </h5>
              {
                getRows(groupedTasks[date]).map(([taskA, taskB, taskC]) => (
                  <IonRow key={taskA.id}>
                    <IonCol size={4}>
                      <TaskCard key={taskA.id} {...taskA} expand={setFocussedTask} showArchived />
                    </IonCol>
                    {
                      taskB && (
                        <IonCol size={4}>
                          <TaskCard key={taskB.id} {...taskB} expand={setFocussedTask} showArchived />
                        </IonCol>
                      )
                    }
                    {
                      taskC && (
                        <IonCol size={4}>
                          <TaskCard key={taskC.id} {...taskC} expand={setFocussedTask} showArchived />
                        </IonCol>
                      )
                    }
                  </IonRow>
                ))
              }
            </div>
          ))
        }
      </IonContent>
    </div>
  )
}

export default ArchivedView
