/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
import React, {
  useState, useEffect, FC, useRef,
} from 'react'
import {
  IonButton, IonCol, IonRow, IonSpinner, useIonAlert,
} from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { Icon } from '@iconify/react'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { ObscureBackground, CenterLoader } from '../GlobalContainers'
import ArticleCard from './ArticleCard'

import NotificationCard from '../NotificationCard'
import { useNotification } from '../../hooks/useNotification'
import {
  FeedPost, NewsArticle, Notification, Workspace, Incident,
} from '../types/GlobalTypes'
import { getGDELTReqURL, getReliefReqOptions } from './NewsArticlesFetch'
import { useWorkspace } from '../../hooks/useWorkspace'
import { GDeltArticle, ReliefArticle } from '../types/ArticleTypes'
import { AttachButton, PostBar, PostTextArea } from './StyleContainers'
import { FileSquare } from '../incident-management/DragDropFiles'
import axios from '../../utils/axios'
import PostCard from './PostCard'
import IncidentCard from './IncidentCard'
import CardNoti from './NotiTest'

interface Props {
  files: FileList | null,
  onShowPopup: () => void,
  cleanFiles: () => void
  setRequestLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

type AnyPost = Notification | NewsArticle | FeedPost | Incident

const sortFunction = (articleA: AnyPost, articleB: AnyPost) => {
  if (moment(articleA.created_at).isAfter(articleB.created_at)) {
    return -1
  }

  return 1
}

/**
 *  ====================================
 *     News Feed from GDELT Project
 *  ====================================
 */
const NewsFeed : FC<Props> = ({
  files, onShowPopup, cleanFiles, setRequestLoading,
}) => {
  /* News article array */
  const [loading, setLoading] = useState(true)
  const [articleList, setArticleList] = useState<(Notification | NewsArticle | FeedPost | Incident)[] >([])
  const [postText, setPostText] = useState<string>('')
  const [ionAlert] = useIonAlert()
  const { notifications } = useNotification()
  const postsCount = useRef<number>(0)
  const fetchedAllPosts = useRef<boolean>(false)
  const fetchedAllNotis = useRef<boolean>(false)
  const fetchedAllIncidents = useRef<boolean>(false)
  const fetchingPosts = useRef<boolean>(false)
  const listInnerRef = useRef<HTMLDivElement>()
  const messagesEndRef = useRef<HTMLDivElement>()
  const [postsLoading, setPostsLoading] = useState<boolean>(true)
  const [firstNotiFetch, setFirstNotiFetch] = useState<boolean>(false)
  const history = useHistory()
  const { workspace, setDomain, domains } = useWorkspace()
  const limit = 10

  const pushNewElems = (data: AnyPost[], newData: AnyPost[]) : { allFetched: boolean, posts: AnyPost[] } => ({ posts: [...data, ...newData], allFetched: (newData.length < limit) })

  const getMorePosts = () => {
    fetchingPosts.current = true

    Promise.all([
      (fetchedAllPosts.current) ? new Promise<void>((resolve) => resolve()) : axios.post('/api/v1/feedpost/fetchDomainPosts',
        { domain_id: workspace.id, offset: postsCount.current, limit: postsCount.current + limit }),
      (fetchedAllIncidents.current) ? new Promise<void>((resolve) => resolve())
        : axios.post('/api/v2/incident/infinite_newsfeed_incidents', { offset: postsCount.current, limit: postsCount.current + limit }),
    ]).then(([postsData, incidentsData]) => {
      /* All new posts fetched across different categories */
      let fetchedPosts : AnyPost[] = []
      let stopFetching = true

      /* Append new posts */
      if (postsData) {
        const { posts, allFetched } = pushNewElems(fetchedPosts, (postsData as AxiosResponse<any>).data)
        if (allFetched) { fetchedAllPosts.current = true } else { stopFetching = false }

        fetchedPosts = posts
      }

      /* Append new  incidents */
      if (incidentsData) {
        const { posts, allFetched } = pushNewElems(fetchedPosts, (incidentsData as AxiosResponse<any>).data)
        if (allFetched) { fetchedAllIncidents.current = true } else { stopFetching = false }

        fetchedPosts = posts
      }

      if (!fetchedAllNotis.current) {
        const notis = notifications.sort(sortFunction).slice(postsCount.current, postsCount.current + limit)
        fetchedPosts = [...fetchedPosts, ...notis]

        console.log('notis next')
        console.log(notis)

        if (notis.length < limit) { fetchedAllNotis.current = true } else { stopFetching = false }
      }

      const sortedPosts = fetchedPosts.sort(sortFunction)
      setArticleList([...articleList, ...sortedPosts])

      console.log(stopFetching)
      if (stopFetching) { setPostsLoading(false) }
      postsCount.current += limit
      fetchingPosts.current = false
    })
  }

  useEffect(() => {
    if (!workspace) { return }
    Promise.all([
      fetch(getGDELTReqURL(workspace), {
        method: 'GET',
      }).then((response) => response.json()),
      fetch('https://api.reliefweb.int/v1/reports?appname=sherpa', {
        method: 'POST',
        body: JSON.stringify(getReliefReqOptions(workspace as Workspace)),
      }).then((response) => response.json()),
      axios.post('/api/v1/feedpost/fetchDomainPosts', { domain_id: workspace.id, offset: 0, limit }),
      axios.post('/api/v2/incident/infinite_newsfeed_incidents', { offset: 0, limit, domain_id: workspace.id }),
    ]).then(([gdeltData, reliefData, { data }, incidentData]) => {
      const formatted = (reliefData.data as ReliefArticle[]).map(({ fields, id }) => ({
        id, url: fields.url, domain: fields.source[0].name, title: fields.title, img: fields.file[0].preview['url-small'], created_at: fields.date.original,
      }))

      const gFormatted = (gdeltData.articles) ? (gdeltData.articles as GDeltArticle[]).map((fields) => ({
        id: fields.url, url: fields.url, domain: fields.domain, title: fields.title, img: fields.socialimage, created_at: fields.seendate,
      })) : []

      postsCount.current += limit

      const firstNotis = notifications.sort(sortFunction).slice(0, limit)
      if (firstNotis.length < limit) { fetchedAllNotis.current = true }
      if (incidentData.data.length < limit) { fetchedAllIncidents.current = true }

      console.log(firstNotis)

      const sortedArts = [...data, ...formatted, ...gFormatted, ...incidentData.data, ...firstNotis].sort(sortFunction)
      setArticleList(sortedArts)
      setLoading(false)
    })
  }, [workspace])

  useEffect(() => {
    if (notifications.length === 0 || firstNotiFetch) { return }
    setFirstNotiFetch(true)
    if (notifications.length > postsCount.current) { fetchedAllNotis.current = false }
    const firstNotis = notifications.sort(sortFunction).slice(0, limit)
    setArticleList([...articleList, ...firstNotis].sort(sortFunction))
  }, [notifications])

  const goToPost = (noti: Notification) => {
    if (noti.taskDomain !== workspace.id) {
      const domainCheck = domains.roledata.find((domain) => domain.id === noti.taskDomain)
      setDomain(domainCheck)
      history.push('/home')
      return
    }

    const elem = document.querySelector(`#post-id-${noti.taskId}`)
    if (!elem) {
      messagesEndRef.current.scrollIntoView()
      return
    }

    elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handlePostUpdate = (post: FeedPost) => {
    const copy = articleList.slice()
    const index = copy.findIndex((val: FeedPost) => val.post_replies && val.id === post.id)

    if (index < 0) { return }
    copy.splice(index, 1, { ...post })
    setArticleList(copy)
  }

  const onSubmitPost = () => {
    if (postText === '') {
      ionAlert({
        header: 'Error - No Content',
        message: "Your post doesn't have any text.",
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    const formData = new FormData()
    if (files) {
      Array.from(files).forEach((val) => {
        formData.append('images[]', val)
      })
    }

    formData.append('text', postText)
    formData.append('domain_id', workspace.id)

    setPostText('')
    cleanFiles()
    setRequestLoading(true)

    axios.post('/api/v1/feedpost/createPost', formData).then(({ data }) => {
      if (!data.ok) {
        return
      }
      const newPost : FeedPost = data.post
      const copy = articleList.slice()

      copy.unshift(newPost)
      copy.push(newPost)
      setArticleList(copy)
    }).finally(() => {
      setRequestLoading(false)
    })
  }

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
    if (scrollTop + clientHeight >= (scrollHeight - 100) && (!fetchedAllPosts.current || !fetchedAllIncidents.current || !fetchedAllNotis.current) && !fetchingPosts.current) {
      getMorePosts()
    }
  }

  return (
    <IonCol
      size='8'
      className='newsfeed-container'
      style={{
        backgroundColor: '#326771',
        color: 'white',
        borderRadius: '2.5px',
        boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        maxHeight: '100%',
        overflow: 'auto',
        padding: '5px 20px',
        paddingTop: '0',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
      >
        <div style={{
          paddingTop: '5px', position: 'sticky', top: 0, backgroundColor: '#326771', zIndex: 5, paddingBottom: '10px',
        }}
        >
          <h3 style={{ padding: '10px', fontSize: '1.2rem' }}> <Icon icon='bx:bxs-news' /> News Feed</h3>
          <PostTextArea
            placeholder='Type your post here'
            onChange={(e) => setPostText(e.target.value)}
            value={postText}
          />
          {
            files && files.length > 0 && (
              <div style={{
                display: 'flex',
                maxWidth: '100%',
                overflowX: 'auto',
                padding: '20px 5px',
                alignItems: 'center',
                backgroundColor: 'white',
                marginBottom: '10px',
                borderRadius: '2.5px',
              }}
              >
                {
                  Array.from(files).map((val) => (
                    <FileSquare key={val.name} name={val.name} />
                  ))
                }
              </div>
            )
          }
          <div style={{
            display: 'flex',
            width: '100%',
          }}
          >
            <PostBar>
              <AttachButton onClick={onShowPopup}>
                <Icon icon='material-symbols:attach-file' />
                <p>Attach File</p>
              </AttachButton>
            </PostBar>
            <IonButton onClick={() => onSubmitPost()} style={{ '--background': '#0C9500', margin: '0 10px' }}>Post</IonButton>
          </div>
        </div>
        <div
          ref={listInnerRef}
          onScroll={handleScroll}
          style={{ flex: 1, maxHeight: '100%', overflow: 'auto' }}
        >
          {
            (loading) ? (
              <>
                <ObscureBackground style={{ zIndex: '9' }} />
                <CenterLoader style={{ zIndex: '10' }}>
                  <IonSpinner color='light' />
                </CenterLoader>
              </>
            ) : (
              articleList.map((article, index) => (
                <ElemCard
                  key={index}
                  data={article}
                  setRequestLoading={setLoading}
                  handlePostUpdate={handlePostUpdate}
                  goToPost={goToPost}
                />
              ))
            )
        }
          {
           postsLoading && (
           <IonRow className='ion-justify-content-center'>
             {
              !loading && (
                <IonSpinner color='light' />
              )
            }
           </IonRow>
           )
        }
          <div ref={messagesEndRef} />

        </div>
      </div>
    </IonCol>
  )
}

export default NewsFeed

interface CardProps {
  data: Notification | NewsArticle | FeedPost | Incident,
  setRequestLoading: React.Dispatch<React.SetStateAction<boolean>>,
  handlePostUpdate: (post: FeedPost) => void,
  goToPost: (noti: Notification) => void,
}

const ElemCard : FC<CardProps> = ({
  data, setRequestLoading, handlePostUpdate, goToPost,
}) => {
  if ((data as NewsArticle).url) {
    return (
      <ArticleCard
        {...data as NewsArticle}
      />
    )
  }

  if ((data as FeedPost).text) {
    return (
      <PostCard
        data={data as FeedPost}
        setLoading={setRequestLoading}
        onUpdate={handlePostUpdate}
      />
    )
  }

  if ((data as Notification).note_type) {
    return (
      <NotificationCard
        onGoToPost={goToPost}
        notification={data as Notification}
        dashboard
        onDisplayPopup={() => console.log('Notification clicked')}
      />
    )
  }

  return (
    <IncidentCard
      data={data as Incident}
    />
  )
}
