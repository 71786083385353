import React, { useState } from 'react'
import {
  IonRow, IonButton, IonList, IonInput, IonCol, IonItem, useIonAlert,
} from '@ionic/react'
import Select from 'react-select'
import Datetime from 'react-datetime'
import moment from 'moment'

import { SettingField } from '../settings/StyledContainers'

const values = {
  name: '',
  startDate: null,
  endDate: null,
  owner: null,
  description: '',
}
/**
 *  ===========================
 *      Add Objective Form
 *  ===========================
 */
const AddObjective = ({
  editObjective, index, defaultValues, onCancel, users, project,
}) => {
  const [input, setInput] = useState(defaultValues || values)
  const [ionAlert] = useIonAlert()
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value, edit: true })
  }

  const returnMessage = (key) => {
    if (key === 'name') return { header: 'Error - name', message: 'Please fill in the objective name.' }
    if (key === 'startDate') return { header: 'Error - Start Date', message: 'Please select a start date for this objective.' }
    if (key === 'endDate') return { header: 'Error - End Date', message: 'Please select an end date for this objective.' }
    if (key === 'description') return { header: 'Error', message: 'Please give this objective a description.' }
    if (key === 'owner') return { header: 'Error - Owner', message: 'Please select an owner for this objective' }
    return null
  }
  const handleSubmit = () => {
    const notFilled = Object.keys(input).find((key) => !input[key] && key !== 'highPriority')
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'OK' },
        ],
      })

      return
    }

    /* Check dates */
    if (input.endDate.isBefore(input.startDate.format('YYYY-MM-DD'))) {
      ionAlert({
        header: 'Date Error',
        message: 'The end date of an objective cant be before the start date',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }
    editObjective(index, { ...input, new: !(defaultValues) })
  }
  return (
    <div style={{ padding: '20px' }}>
      <IonRow className='ion-justify-content-between'>
        <SettingField style={{ width: 'auto' }}>
          <h5>Add Objective</h5>
        </SettingField>
        <IonRow>
          <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>Cancel</IonButton>
          <IonButton style={{ '--background': '#0C9500' }} onClick={handleSubmit}>done</IonButton>
        </IonRow>
      </IonRow>
      <IonList style={{ contain: 'inherit', '--ion-background-color': 'white', padding: '15px 20px' }}>
        <IonRow>
          <IonCol size={6}>
            <IonItem>
              <SettingField>
                <h5>Title</h5>
                <IonInput
                  type='text'
                  placeholder='What is this objective called?'
                  name='name'
                  value={input.name}
                  onIonChange={handleChange}
                />
              </SettingField>
            </IonItem>
          </IonCol>
          <IonCol size={6}>
            <SettingField>
              <h5>Owner</h5>
              <Select
                placeholder='Select user'
                name='owner'
                className='select-container'
                value={input.owner}
                // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                onChange={(selected) => setInput({ ...input, owner: selected, edit: true })}
              />
            </SettingField>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size={6}>
            <SettingField>
              <h5>Start Date</h5>
              <Datetime
                dateFormat='YYYY-MM-DD'
                inputProps={{
                  placeholder: 'Start Date',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                timeFormat={false}
                isValidDate={(project.startDate && project.endDate)
                  ? (current) => (current.isSameOrAfter(project.startDate) && current.isSameOrBefore(project.endDate))
                  : (current) => (current.isSameOrAfter(moment()))}
                name='startDate'
                value={input.startDate}
                onChange={(e) => setInput({ ...input, startDate: e, edit: true })}
              />
            </SettingField>
          </IonCol>
          <IonCol size={6}>
            <SettingField>
              <h5>End Date</h5>
              <Datetime
                dateFormat='YYYY-MM-DD'
                inputProps={{
                  placeholder: 'Target Date',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                timeFormat={false}
                isValidDate={(project.startDate && project.endDate)
                  ? (current) => (current.isSameOrAfter(project.startDate) && current.isSameOrBefore(project.endDate))
                  : (current) => (current.isSameOrAfter(moment()))}
                name='endDate'
                value={input.endDate}
                onChange={(e) => setInput({ ...input, endDate: e, edit: true })}
              />
            </SettingField>
          </IonCol>
        </IonRow>
        <IonItem>
          <SettingField>
            <IonInput
              type='text'
              placeholder='Add a description to this objective'
              name='description'
              value={input.description}
              onIonChange={handleChange}
            />
          </SettingField>
        </IonItem>
      </IonList>
    </div>
  )
}

export default AddObjective
