/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useState, useRef } from 'react'
import { IonList } from '@ionic/react'
// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'
import { createMB } from '../apps/AppMap'
import FormPopup from '../forms/FormPopup'

import MapForm from '../forms/MapForm'
import MBform from '../forms/MBform'

import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import BoxMeasures from '../forms/BoxMeasures'
import { ObscureBackground } from '../../GlobalContainers'

const getFormattedInput = (movementBox) => ({
  review: movementBox.properties.check_in_days,
  description: movementBox.properties.description,
})
/**
 *  ===============================
 *        EDIT MOVEMENT BOX
 *  ===============================
 */
const EditMovementBox = ({
  mapRef, focusedMb, onCancel, setLoading, updateMovementBox, deleteMb,
  setFocusedMb, controlMeasures,
}) => {
  const [markers, setMarkers] = useState([])
  const [coordinates, setCoordinates] = useState([])
  const [submitted, setSubmitted] = useState(false)
  const [editing, setEditing] = useState(false)
  const [selectedMeasures, setSelectedMeasures] = useState([])
  /* Extra layer of cashing to force the form to be updated too */
  const [movementBox, setMovementBox] = useState(getFormattedInput(focusedMb))
  const [showMeasures, setShowMeasures] = useState(false)

  /* Use Api and workspace logic */
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  /* Submit movement box edit */
  const handleSubmit = async (input) => {
    /* Prepare GeoJson for the db */
    const newFeature = {
      type: 'Feature',
      geometry: {
        coordinates,
        type: 'LineString',
      },
    }

    const alreadyPresent = JSON.parse(focusedMb.properties.restrictions)
    const add = selectedMeasures.filter((val) => !alreadyPresent.find(({ id }) => val.id === id))
    const remove = alreadyPresent.filter((val) => !selectedMeasures.find(({ id }) => val.id === id))

    setLoading(true)
    await apiHook.editMovementBox({
      movement_box_id: focusedMb.properties.id,
      domain_id: workspace.id,
      geoData: JSON.stringify(newFeature),
      measures_remove: remove.map(({ id }) => id),
      measures_add: add.map(({ id }) => id),
    })
    setLoading(false)

    newFeature.properties = {
      ...focusedMb.properties,
      description: input.description,
      review: input.review,
      restrictions: selectedMeasures,
    }

    markers.map((marker) => marker.remove())
    updateMovementBox(focusedMb.properties.id, newFeature)
    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })
    setSubmitted(true)
  }

  const handleClose = () => {
    markers.map((marker) => marker.remove())
    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })
    onCancel()
  }

  /* Change the coordinates of the current marker */
  const setCurrentPoint = ([lon, lat], index) => {
    if (!coordinates.length) return

    /* Update location */
    coordinates[index] = [lon, lat]

    /* Change last markers position */
    markers[index].setLngLat([lon, lat])

    if (index === 0) coordinates[coordinates.length - 1] = [lon, lat]

    /* Update layer */
    mapRef.current.getSource('new-restriction-box').setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates,
      },
    })
    setMarkers(markers)
    setCoordinates(coordinates)
  }
  /* Use reference to keep the state updated */
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  /* Add pins and set up draggable functionality */
  const addPins = (points) => {
    markers.map((marker) => marker.remove())
    const tempMarkers = []
    points.forEach((lngLat, index) => {
      if (index + 1 === points.length) { return }
      const marker = new mapboxgl.Marker()
      marker.setLngLat(lngLat).addTo(mapRef.current)

      marker.setDraggable(true)
      marker.on('drag', () => {
        setCurrentPointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], index)
      })

      tempMarkers.push(marker)
    })
    setMarkers(tempMarkers)
    setCoordinates(points)
  }

  const startEdit = () => {
    setEditing(true)
    addPins(focusedMb.coordinates)
  }

  useEffect(() => {
    const source = mapRef.current.getSource('new-restriction-box')
    setEditing(false)
    if (source) {
      source.setData(focusedMb)
    } else {
      /* Add markers */
      createMB(mapRef, focusedMb.coordinates)
      mapRef.current.addLayer({
        id: 'new-layer',
        type: 'fill',
        source: 'new-restriction-box',
        layout: {},
        paint: {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5,
        },
      })
    }

    return () => {
      mapRef.current.removeLayer('new-restriction-box')
      if (mapRef.current.getLayer('new-layer')) mapRef.current.removeLayer('new-layer')
      mapRef.current.removeSource('new-restriction-box')
    }
  }, [focusedMb])

  useEffect(() => {
    setSelectedMeasures(JSON.parse(focusedMb.properties.restrictions))
    setMovementBox(getFormattedInput(focusedMb))
  }, [focusedMb])

  return (
    <>
      {
        showMeasures && (
          <>
            <ObscureBackground style={{ zIndex: 19 }} />
            <BoxMeasures
              allMeasures={controlMeasures}
              selectedMeasures={selectedMeasures}
              onClose={() => setShowMeasures(false)}
              onFinish={(measures) => {
                setSelectedMeasures(measures)
                setShowMeasures(false)
              }}
            />
          </>
        )
      }
      <MapForm>
        {
          (!submitted) ? (
            <MBform
              editing={editing}
              onCancel={handleClose}
              startEdit={startEdit}
              onSubmit={handleSubmit}
              defaultInput={movementBox}
              markers={markers}
              selectedMeasures={selectedMeasures}
              fillDetails
              canFillForm
              showRestrictions={() => setShowMeasures(true)}
              edit
              deleteMb={() => deleteMb(focusedMb.properties.id)}
            />
          ) : (
            <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
              <FormPopup
                message='You have successfully edited a Restriction Box.'
                onClose={() => {
                  mapRef.current.easeTo({
                    padding: {
                      right: 0,
                    },
                  })
                  setFocusedMb(null)
                }}
              />
            </IonList>
          )
        }
      </MapForm>
    </>
  )
}

export default EditMovementBox
