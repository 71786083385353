import * as React from 'react'
import {
  IonRow, IonSpinner, IonCol,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { SettingField } from '../settings/StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { Asset } from './types/Assets'

/**
 *  =========================================
 *      View Existing Measures of an Asset
 *  =========================================
 */
const AssetMeasures : React.FC<{ showLoading: boolean, focusedAsset: Asset, onReturn: () => void }> = ({
  showLoading, focusedAsset, onReturn,
}) => {
  const uniqMeasures = (category : string) : string [] => {
    let values: string [] = []
    focusedAsset.risks.forEach((assetRisk) => {
      const filtered = assetRisk.existingMeasures.filter((measure) => measure.category === category).map(({ control_measure }) => control_measure)
      values = [...values, ...filtered]
    })

    /* Filter out duplicates */
    return values.filter((value, index, self) => self.indexOf(value) === index)
  }

  return (
    <>
      <SimpleButton style={{ color: '#8E151F', marginTop: '10px', textDecoration: 'underline' }} onClick={onReturn}><Icon icon='fe:arrow-left' /> Back To Asset profile</SimpleButton>
      <SettingField style={{ width: 'auto' }}><h4>Control Measures</h4></SettingField>
      {
        (showLoading) ? (
          <IonRow className='ion-justify-content-center' style={{ paddingTop: '40px' }}>
            <IonSpinner />
          </IonRow>
        ) : (
          <>
            <IonRow>
              <IonCol size='3'>
                <SettingField style={{ width: 'auto' }}><h4 style={{ fontWeight: 'normal' }}>Access Control</h4></SettingField>
              </IonCol>
              <IonCol style={{ paddingLeft: '15px' }} size='3'>
                {
                  uniqMeasures('Access Control').map((measure) => (
                    <p key={measure}> { measure } </p>
                  ))
                }
                {
                  uniqMeasures('Access Control').length === 0 && (
                    <p> No Measures Selected </p>
                  )
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='3'>
                <SettingField style={{ width: 'auto' }}><h4 style={{ fontWeight: 'normal' }}>Access Control (Employees)</h4></SettingField>
              </IonCol>
              <IonCol style={{ paddingLeft: '15px' }} size='9'>
                {
                  uniqMeasures('Access Control (Employees)').map((measure) => (
                    <p key={measure}> { measure } </p>
                  ))
                }
                {
                  uniqMeasures('Access Control (Employees)').length === 0 && (
                    <p> No Measures Selected </p>
                  )
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='3'>
                <SettingField style={{ width: 'auto' }}><h4 style={{ fontWeight: 'normal' }}>Access Control (Visitors)</h4></SettingField>
              </IonCol>
              <IonCol style={{ paddingLeft: '15px' }} size='9'>
                {
                  uniqMeasures('Access Control (Visitors)').map((measure) => (
                    <p key={measure}> { measure } </p>
                  ))
                }
                {
                  uniqMeasures('Access Control (Visitors)').length === 0 && (
                    <p> No Measures Selected </p>
                  )
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='3'>
                <SettingField style={{ width: 'auto' }}><h4 style={{ fontWeight: 'normal' }}>Perimeter Protection</h4></SettingField>
              </IonCol>
              <IonCol style={{ paddingLeft: '15px' }} size='9'>
                {
                  uniqMeasures('Perimeter Protection').map((measure) => (
                    <p key={measure}> { measure } </p>
                  ))
                }
                {
                  uniqMeasures('Perimeter Protection').length === 0 && (
                    <p> No Measures Selected </p>
                  )
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='3'>
                <SettingField style={{ width: 'auto' }}><h4 style={{ fontWeight: 'normal' }}>Security Lighting</h4></SettingField>
              </IonCol>
              <IonCol style={{ paddingLeft: '15px' }} size='9'>
                {
                  uniqMeasures('Security Lighting').map((measure) => (
                    <p key={measure}> { measure } </p>
                  ))
                }
                {
                  uniqMeasures('Security Lighting').length === 0 && (
                    <p> No Measures Selected </p>
                  )
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size='3'>
                <SettingField style={{ width: 'auto' }}><h4 style={{ fontWeight: 'normal' }}>Vehicle Parking</h4></SettingField>
              </IonCol>
              <IonCol style={{ paddingLeft: '15px' }} size='9'>
                {
                  uniqMeasures('Vehicle Parking').map((measure) => (
                    <p> { measure } </p>
                  ))
                }
                {
                  uniqMeasures('Vehicle Parking').length === 0 && (
                    <p> No Measures Selected </p>
                  )
                }
              </IonCol>
            </IonRow>
          </>
        )
      }
    </>
  )
}

export default AssetMeasures
