import React, { useState } from 'react'
import {
  IonRow, IonCol, IonContent, IonLabel,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import moment from 'moment'
import { ListContainer } from '../../consultant/StyledContainers'
import { WidgetTitles } from '../WidgetContainers'
/**
 *  ===============================
 *     LIST AIR TRAVEL REQUESTS
 *  ===============================
 */
const AirTravelList = ({ airJourneys, onClick }) => {
  /* Determine sorting for selected column */
  const [sortedBy, setSortedBy] = useState({ field: 'id', ascending: true })
  const colorCodeStatus = (status) => ({
    Pending: '#326771',
    Approved: '#0C9500',
    Declined: '#DD2800',
    Cancelled: '#E70000',
  }[status])

  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const getArrow = () => ((sortedBy.ascending) ? (
    <Icon icon='fluent:arrow-sort-down-16-filled' />
  ) : (
    <Icon icon='fluent:arrow-sort-up-16-filled' />
  ))

  /* Sort function ascending or descending an attribute specific */
  const getSortedjourneys = () => {
    const copy = airJourneys.slice()
    if (sortedBy.ascending) {
      return copy.sort((a, b) => {
        if (a[sortedBy.field] >= b[sortedBy.field]) return 1
        return -1
      })
    }

    return copy.sort((a, b) => {
      if (b[sortedBy.field] >= a[sortedBy.field]) return 1
      return -1
    })
  }

  const handleTitle = (fieldName) => {
    /* If this field has already been selected then revert the order */
    if (sortedBy.field === fieldName) {
      setSortedBy({ ...sortedBy, ascending: !sortedBy.ascending })
      return
    }

    setSortedBy({
      field: fieldName,
      ascending: true,
    })
  }

  return (
    <ListContainer style={{ height: '100%' }}>
      <WidgetTitles style={{ height: '10%', paddingRight: '0' }}>
        <IonRow style={{ height: '100%', fontSize: '1.4em' }} className='ion-align-items-center'>
          <IonCol onClick={() => handleTitle('stage')} className='ion-text-center clickable-column' size={2}>
            Stage
            {
              sortedBy.field === 'stage' && getArrow()
            }
          </IonCol>
          <IonCol onClick={() => handleTitle('from')} className='ion-text-center clickable-column' size={2}>
            From
            {
              sortedBy.field === 'from' && getArrow()
            }
          </IonCol>
          <IonCol onClick={() => handleTitle('to')} className='ion-text-center clickable-column' size={2}>
            To
            {
              sortedBy.field === 'to' && getArrow()
            }
          </IonCol>
          <IonCol onClick={() => handleTitle('submitterName')} className='ion-text-center clickable-column' size={2}>
            Requested By
            {
              sortedBy.field === 'submitterName' && getArrow()
            }
          </IonCol>
          <IonCol onClick={() => handleTitle('departure_date')} className='ion-text-center clickable-column' size={2}>
            Departure Date
            {
              sortedBy.field === 'departure_date' && getArrow()
            }
          </IonCol>
          <IonCol onClick={() => handleTitle('status')} className='ion-text-center clickable-column' size={1}>
            Status
            {
              sortedBy.field === 'status' && getArrow()
            }
          </IonCol>
        </IonRow>
      </WidgetTitles>
      <div style={{ height: '90%' }}>
        <IonContent style={{ '--background': 'white' }}>
          {
            getSortedjourneys().map((journey, index) => (
              <IonRow
                key={journey.id}
                style={{
                  backgroundColor: getColor(index), minheight: '10%', height: '10%', cursor: 'pointer', fontSize: '1.1em', color: 'black',
                }}
                className='ion-align-items-center ion-text-center'
                onClick={() => onClick(journey)}
              >
                <IonCol size={2}>{ journey.stage }</IonCol>
                <IonCol size={2}>{ journey.from }</IonCol>
                <IonCol size={2}>{ journey.to }</IonCol>
                <IonCol size={2}>{ `${journey.submitter.first_name} ${journey.submitter.final_name}` }</IonCol>
                <IonCol size={2}>{ moment(journey.departure_date).format('DD/MM/YYYY') } </IonCol>
                <IonCol size={1}>
                  <IonLabel className='journey-status' style={{ backgroundColor: colorCodeStatus(journey.status) }}>{ journey.status }</IonLabel>
                </IonCol>
              </IonRow>
            ))
          }
        </IonContent>
      </div>
    </ListContainer>
  )
}
export default AirTravelList
