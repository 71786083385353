import React, { FC } from 'react'
import { TerrainMapElem } from '../../hooks/terrain-mapping/types/HookInterface'
import { useTerrainMapping } from '../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../consultant/StyledContainers'
import CommitmentList from './listviews/CommitmentList'
import EngagementList from './listviews/EngagementList'
import FlashpointList from './listviews/FlashpointList'
import IssuesList from './listviews/IssueList'
import POIList from './listviews/POIList'
import StakeholderList from './listviews/StakeholderList'
import FlashpointForm from '../maps/features/Flashpoint'

const Register : FC = () => {
  const { registerDisplay, showFlashpointForm } = useTerrainMapping()

  if (registerDisplay === TerrainMapElem.FLASHPOINT) {
    return (
      <FlashpointList />
    )
  }

  if (showFlashpointForm) {
    return (
      <FlashpointForm />
    )
  }

  if (registerDisplay === TerrainMapElem.POINT_OF_INTEREST) {
    return (
      <POIList />
    )
  }

  if (registerDisplay === TerrainMapElem.STAKEHOLDER) {
    return (
      <StakeholderList />
    )
  }

  if (registerDisplay === TerrainMapElem.COMMITMENT_FORM) {
    return (
      <CommitmentList />
    )
  }

  if (registerDisplay === TerrainMapElem.ENGAGEMENT_NOTE) {
    return (
      <EngagementList />
    )
  }

  if (registerDisplay === TerrainMapElem.ISSUE) {
    return (
      <IssuesList />
    )
  }

  return (
    <ListContainer style={{ height: '100%' }} />
  )
}

export default Register
