import React, { useState } from 'react'
import {
  IonCol, IonRow, IonList, IonItem, IonButton,
} from '@ionic/react'

import Select from 'react-select'
import { SettingField, SettingTitle } from '../StyledContainers'

const receiveOptions = [{ label: 'Default - Admin', value: 'admin' }, { label: 'All', value: 'all' }]
const frequencyOptions = [{ label: 'Weekly', value: '7' }, { label: 'Fortnightly', value: '14' }, { label: 'Monthly', value: 30 }]
const submitOptions = [{ label: 'Default - All', value: 'all' }, { label: 'Admin', value: 'admin' }]

/**
 *  ==========================
 *    Insights App settings
 *  ==========================
 */
const InsightSettings = () => {
  const [submit, setSubmit] = useState(submitOptions[0])
  const [receive, setReceive] = useState(receiveOptions[0])
  const [frequency, setFrequency] = useState(frequencyOptions[0])

  return (
    <>
      <SettingTitle>Insights Permissions</SettingTitle>
      <IonRow style={{ height: '90%' }}>
        <IonCol size={6}>
          <IonList style={{ '--ion-item-background': 'white', padding: 'white' }}>
            <IonItem lines='none'>
              <SettingField>
                <h4>Insight Report</h4>
                <p>Choose who has permission to submit Insight Reports in this domain</p>
                <Select
                  value={submit}
                  options={submitOptions}
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  className='settings-select'
                  onChange={(selected) => setSubmit(selected)}
                />
              </SettingField>
            </IonItem>
            <IonItem lines='none'>
              <SettingField>
                <h4>Weekly Report</h4>
                <p>Choose which users in this domain receive the Weekly Report</p>
                <Select
                  value={receive}
                  options={receiveOptions}
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  className='settings-select'
                  onChange={(selected) => setReceive(selected)}
                />
              </SettingField>
            </IonItem>
          </IonList>
        </IonCol>
        <IonCol size={6} style={{ height: '100%' }}>
          <IonList style={{ '--ion-item-background': 'white' }}>
            <SettingField>
              <h4>Report Frequency</h4>
              <Select
                value={frequency}
                options={frequencyOptions}
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                className='settings-select'
                onChange={(selected) => setFrequency(selected)}
              />
            </SettingField>
          </IonList>
          <IonRow style={{ position: 'absolute', bottom: 0, width: '100%' }} className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#326771' }}>Save</IonButton>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  )
}

export default InsightSettings
