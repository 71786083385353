import React, { createContext, useState, useContext } from 'react'
/**
 *  ===============================
 *      SAFETY CHECK CONTEXT
 *  ===============================
 */
const PopupContext = createContext(null)

export const ProvideWeeklyReport = ({ children }) => {
  const [isShowing, setIsShowing] = useState(false)
  const [step, setStep] = useState(0)

  return (
    <PopupContext.Provider value={[isShowing, setIsShowing, step, setStep]}>
      {
        children
      }
    </PopupContext.Provider>
  )
}

export const useWeeklyReport = () => useContext(PopupContext)
