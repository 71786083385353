/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import {
  IonList, IonItem, IonInput, IonButton, useIonAlert, IonRow,
} from '@ionic/react'

import { Link, useHistory } from 'react-router-dom'
import useApi from '../../hooks/testHooks'
import { RegisterContainer } from '../maps/StyledContainers'

const RecoveryForm = () => {
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)

  const apiHook = useApi()
  const [ionAlert] = useIonAlert()
  const history = useHistory()
  const [, setRecovered] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    if (email === '') {
      ionAlert({
        header: 'Error',
        message: 'The email entered does not exist on our system. Please try again.',
        buttons: [
          'Cancel',
          { text: 'OK' },
        ],
      })
      setLoading(false)
      return
    }
    setLoading(true)
    apiHook.resetPassword(email)
      .then((data) => {
        if (data.ok) setRecovered(true)
        // console.log(data)
        else {
          ionAlert({
            header: data.header,
            message: data.message,
            buttons: [
              {
                text: 'OK',
                handler: () => {
                  if (data.header === 'Password Reset') {
                    history.push('/login')
                  }
                },
              },
            ],
          })
          setLoading(false)
        }
      })
  }

  return (
    <RegisterContainer>
      <h2 style={{ textAlign: 'left', color: '#8E151F' }}>Recover Password</h2>
      <IonList>
        <IonItem>
          <IonInput placeholder='email' type='email' title='E-mail Address' value={email} onIonChange={(e) => setEmail(e.detail.value)} />
        </IonItem>
      </IonList>
      <IonRow className='ion-justify-content-end'>
        <IonButton disabled={loading} style={{ '--background': '#326771' }} onClick={handleSubmit} title='login'>Submit</IonButton>
      </IonRow>
      <p>Return to <Link style={{ color: '#8E151F', textDecoration: 'none' }} to='/login'>Login</Link> </p>

    </RegisterContainer>
  )
}

export default RecoveryForm
