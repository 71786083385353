import { Marker } from 'mapbox-gl'
import { AlertState, GeoLocation, SecurityCondition } from '../../types/GlobalTypes'
import { RiskAssessment } from './RiskAssessments'

export interface Asset {
  latitude: any
  longitude: any
  id: number,
  name: string,
  type: string,
  coordinates: GeoLocation,
  location: {
    country: string,
    state?: string,
    county?: string,
  },
  highestRisk: string,
  updated_at: string,
  alert_state?: AlertState,
  county_id: number,
  security_condition?: SecurityCondition,
  risks?: RiskAssessment[],
  geodata?: any,
  category?: string,
  marker_type: string,
  asset_type?: string,
  description?: string,
}

/* Note: assets are handled by their physical locations
  in order to implement colocated assets in the front end,
  a single marker corresponds to all colocated assets in the same location
*/
export interface AssetList {
  [location: string] : {
    marker?: Marker,
    assets: Asset[]
  }
}

export enum ASSETTYPE {
  PIN = 'pin',
  LINE_MARKER = 'line',
  POLYGON = 'polygon',
}
