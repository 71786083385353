/* eslint-disable react/no-array-index-key */
import { Icon } from '@iconify/react'
import {
  IonButton, IonCheckbox, IonRow, useIonAlert,
} from '@ionic/react'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { ObscureBackground, OverlayContainer, SherpaButton } from '../../GlobalContainers'
import { BlueButton, ContentContainer } from '../../incident-management/StyledContainers'
import { getColor } from '../../maps/apps/AppMap'
import LoadingModal from '../../modals/LoadingModal'
import axios from '../../../utils/axios'
import {
  AnswerType, CampaignStatus, CampaignType, EngagementCampaign, RecipientList, Stakeholder,
} from '../../types/OptimizedMaps'
import { VerticalFlexContainer } from '../StyledContainers'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { CenterForm } from '../../riskregister/StyledContainers'
import FormPopup from '../../maps/forms/FormPopup'

interface Details {
  title: string,
  type: CampaignType | '',
  launch_date: moment.Moment,
  yes_no_message: string,
  message: string,
  question: string,
  answer_type: AnswerType,
}

enum FormView {
  DETAILS = 'details',
  PREVIEW = 'preview',
  RECIPIENTS = 'recipients',
}

interface FormProps {
  onClose: () => void,
}

const CampaignForm : FC<FormProps> = ({ onClose }) => {
  const [input, setInput] = useState<Details>({
    title: '',
    type: '',
    launch_date: moment(),
    question: '',
    answer_type: AnswerType.YES_NO,
    message: '',
    yes_no_message: 'Please respond with YES or NO',
  })
  const [options, setOptions] = useState<string[]>(['', ''])
  const { workspace } = useWorkspace()
  const {
    pushNewCampaign, pushNewRecipientLists, setLoading, setSubmittedMessage,
  } = useEngagementData()
  const [ionAlert] = useIonAlert()
  const [currentView, setCurrentView] = useState<FormView>(FormView.DETAILS)

  const handleUpdateOptions = (newText: string, index: number) => {
    const copy = options.slice()
    copy.splice(index, 1, newText)
    setOptions(copy)
  }

  const addOption = () => {
    setOptions([...options, ''])
  }

  const handleSubmit = (recipients: (RecipientList & { new: boolean })[]) => {
    const newLists = recipients.filter((val) => val.new)
    const oldLists = recipients.filter((val) => !val.new)
    pushNewRecipientLists(newLists)

    setLoading(true)
    if (input.type === CampaignType.BULLETIN) {
      axios.post('api/v2/campaign/create_campaign', {
        domain_id: workspace.id,
        title: input.title,
        campaign_type: input.type,
        date: input.launch_date.format(),
        message: input.message,
        option_1: '',
        option_2: '',
        option_3: '',
        option_4: '',
        new_lists: newLists.map(({ name, stakeholders }) => ({ name, stakeholders: stakeholders.map(({ id }) => id) })),
        recipient_lists: oldLists.map(({ id }) => id),
      }).then(({ data }) => {
        const newBulletin : EngagementCampaign = {
          id: data.campaign.id,
          title: input.title,
          type: input.type as CampaignType,
          recipient_lists: recipients,
          created_at: moment().format(),
          launch_date: input.launch_date.format(),
          status: CampaignStatus.SCHEDULED,
          message: input.message,
          option_1: '',
          option_2: '',
        }
        pushNewCampaign(newBulletin)
      }).catch(() => {
        ionAlert({
          header: 'Server Error',
          message: 'Unknown Server error',
          buttons: [{ text: 'OK' }],
        })
      }).finally(() => {
        onClose()
        setLoading(true)
      })
      return
    }

    axios.post('api/v2/campaign/create_campaign', {
      domain_id: workspace.id,
      title: input.title,
      campaign_type: input.type,
      launch_date: input.launch_date.format(),
      answer_type: input.answer_type,
      message: (input.answer_type === AnswerType.YES_NO) ? `${input.question}\n${input.yes_no_message}` : input.question,
      option_1: (input.answer_type === AnswerType.YES_NO) ? 'Yes' : options[0],
      option_2: (input.answer_type === AnswerType.YES_NO) ? 'No' : options[1],
      option_3: (input.answer_type === AnswerType.OPTIONS && options.length > 2) ? options[2] : undefined,
      option_4: (input.answer_type === AnswerType.OPTIONS && options.length > 3) ? options[3] : undefined,
      new_lists: newLists.map(({ name, stakeholders }) => ({ name, stakeholders: stakeholders.map(({ id }) => id) })),
      recipient_lists: oldLists.map(({ id }) => id),
    }).then(({ data }) => {
      const newSurvey : EngagementCampaign = {
        id: data.campaign.id,
        title: input.title,
        type: input.type as CampaignType,
        recipient_lists: recipients,
        created_at: moment().format(),
        launch_date: input.launch_date.format(),
        answer_type: input.answer_type,
        status: CampaignStatus.SCHEDULED,
        message: input.question,
        option_1: (input.answer_type === AnswerType.YES_NO) ? 'Yes' : options[0],
        option_2: (input.answer_type === AnswerType.YES_NO) ? 'No' : options[1],
        option_3: (input.answer_type === AnswerType.OPTIONS && options.length > 2) ? options[2] : undefined,
        option_4: (input.answer_type === AnswerType.OPTIONS && options.length > 3) ? options[3] : undefined,
      }
      pushNewCampaign(newSurvey)
      setSubmittedMessage('Campaign created successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown Server error',
        buttons: [{ text: 'OK' }],
      })
    }).finally(() => {
      onClose()
      setLoading(false)
    })
  }

  const moveToPreview = () => {
    if (input.title === '') {
      ionAlert({
        header: 'Error title',
        message: 'Title missing',
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    if (input.type === '') {
      ionAlert({
        header: 'No Campaign type',
        message: 'You must select a campaign type before moving on',
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    if (input.type === CampaignType.BULLETIN && input.message === '') {
      ionAlert({
        header: 'Bulletin message error',
        message: 'Bulletin text missing',
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    if (input.type === CampaignType.SURVEY) {
      if (input.question === '') {
        ionAlert({
          header: 'Survey Error',
          message: 'No survey question provided',
          buttons: [{ text: 'Ok' }],
        })
        return
      }

      if (input.answer_type === AnswerType.YES_NO && input.yes_no_message === '') {
        ionAlert({
          header: 'Survey Error',
          message: 'Message for yes/no choice was left in blank',
          buttons: [{ text: 'Ok' }],
        })
      }

      if (input.answer_type === AnswerType.OPTIONS) {
        const undefinedOptions = options.some((val) => val === '')
        if (undefinedOptions) {
          ionAlert({
            header: 'Survey Error',
            message: 'Some survey options were left in blank',
            buttons: [{ text: 'Ok' }],
          })
          return
        }

        const hasDuplicates = options.some((item) => options.indexOf(item) !== options.lastIndexOf(item))
        if (hasDuplicates) {
          ionAlert({
            header: 'Survery Error',
            message: 'Options are repeated',
            buttons: [{ text: 'Ok' }],
          })
          return
        }
      }
    }

    setCurrentView(FormView.PREVIEW)
  }

  if (currentView === FormView.PREVIEW) {
    return (
      <PreviewScreen
        input={input}
        options={options}
        onNext={() => setCurrentView(FormView.RECIPIENTS)}
        onBack={() => setCurrentView(FormView.DETAILS)}
      />
    )
  }

  if (currentView === FormView.RECIPIENTS) {
    return (
      <Recipients
        onBack={() => setCurrentView(FormView.PREVIEW)}
        onCreate={handleSubmit}
      />
    )
  }

  return (
    <ContentContainer>
      <VerticalFlexContainer>
        <h4 style={{ marginBottom: '20px' }} className='red-font'>New Campaign</h4>
        <div className='terrain-add-popup' style={{ flex: 1, overflow: 'auto' }}>
          <IonRow>
            <div className='terrain-form-field'>
              <h6>Campaign Title</h6>
              <input
                placeholder='Campaign title'
                type='text'
                value={input.title}
                onChange={(e) => setInput({ ...input, title: e.target.value })}
              />
            </div>
            <div style={{ marginLeft: '20px' }} className='terrain-form-field'>
              <h6>Type</h6>
              <select
                value={input.type}
                onChange={(e) => setInput({ ...input, type: e.target.value as CampaignType })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select a type</option>
                <option value={CampaignType.SURVEY}>Survey</option>
                <option value={CampaignType.BULLETIN}>Bulletin</option>
              </select>
            </div>
          </IonRow>
          <div className='terrain-form-field'>
            <h6>Launch Date</h6>
            <p style={{ marginBottom: '20px' }}>Set the date you wish the campaign to begin on. Messages will be sent out to selected stakeholders on this date. </p>
            <Datetime
              dateFormat='YYYY-MM-DD'
              inputProps={{
                placeholder: 'Select Date',
                readOnly: true,
                style: { backgroundColor: 'white' },
              }}
              value={input.launch_date}
              onChange={(e) => setInput({ ...input, launch_date: e as moment.Moment })}
            />
          </div>
          {
            input.type === CampaignType.BULLETIN && (
              <div className='terrain-form-field form-desc'>
                <h6>Message</h6>
                <textarea value={input.message} onChange={(e) => setInput({ ...input, message: e.target.value })} />
              </div>
            )
          }
          {
            input.type === CampaignType.SURVEY && (
              <>
                <div className='terrain-form-field'>
                  <h6>Answer Type</h6>
                  <p>Set the answer type your recipients should respond with. </p>
                  <select
                    value={input.answer_type}
                    onChange={(e) => setInput({ ...input, answer_type: e.target.value as AnswerType })}
                    style={{ padding: '5px 10px' }}
                  >
                    <option value={AnswerType.YES_NO}>Yes/No</option>
                    <option value={AnswerType.OPTIONS}>Options</option>
                  </select>
                </div>
                <div className='terrain-form-field form-desc'>
                  <h6>Question</h6>
                  <textarea
                    placeholder='Input the survey question you wish to send to stakeholders'
                    value={input.question}
                    style={{ height: '10em' }}
                    onChange={(e) => setInput({ ...input, question: e.target.value })}
                  />
                </div>
                {
                  input.answer_type === AnswerType.OPTIONS ? (
                    <div className='terrain-form-field'>
                      <h6>Answer Option</h6>
                      <p>Input the answer options you wish to give recipients</p>
                      {
                        options.map((text, index) => (
                          <input
                            style={{ margin: '10px 0', width: '60%' }}
                            key={index}
                            placeholder='Option text'
                            type='text'
                            value={text}
                            onChange={(e) => handleUpdateOptions(e.target.value, index)}
                          />
                        ))
                      }
                      {
                        options.length < 4 && (
                          <IonRow className='ion-justify-content-end'>
                            <SherpaButton onClick={addOption} style={{ fontSize: '1rem' }}>Add Option <Icon icon='ic:baseline-plus' /></SherpaButton>
                          </IonRow>
                        )
                      }
                    </div>
                  ) : (
                    <div className='terrain-form-field'>
                      <h6>Answer Option</h6>
                      <p>The following will appear below your question. </p>
                      <input
                        placeholder='Please respond with YES or NO'
                        type='text'
                        value={input.yes_no_message}
                        onChange={(e) => setInput({ ...input, yes_no_message: e.target.value })}
                      />
                    </div>
                  )
                }
              </>
            )
        }
        </div>
        <IonRow className='ion-align-items-center ion-justify-content-end'>
          <IonButton onClick={onClose} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
          <IonButton onClick={moveToPreview} style={{ '--background': '#326771' }}>Next</IonButton>
        </IonRow>
      </VerticalFlexContainer>
    </ContentContainer>
  )
}

interface Props {
  onBack: () => void,
  onNext: () => void,
  options: string[],
  input: Details,
}
const PreviewScreen : FC<Props> = ({
  onBack, onNext, input, options,
}) => (
  <ContentContainer className='terrain-add-popup'>
    <VerticalFlexContainer>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <h4 className='red-font'>New Campaign</h4>
        <h5 style={{ marginTop: '40px' }}>Message Preview</h5>
        <p>The following is a preview of how your message will appear to recipients.</p>
        <div style={{
          margin: '15px 0', padding: '10px', color: '#747474', border: '1px solid #747474',
        }}
        >
          {
          input.type === CampaignType.BULLETIN ? (
            <p style={{ whiteSpace: 'pre-wrap' }}>{ input.message }</p>
          ) : (
            <>
              <p>{ input.question }</p>
              {
                input.answer_type === AnswerType.YES_NO ? (
                  <p>{ input.yes_no_message }</p>
                ) : (
                  <>
                    {
                      options.map((val, index) => (
                        <p key={index}>Option { index }: { val }</p>
                      ))
                    }
                  </>
                )
              }
            </>
          )
        }
        </div>
      </div>
      <IonRow className='ion-align-items-center ion-justify-content-end'>
        <IonButton onClick={onBack} style={{ '--background': '#4197A9' }}>Back</IonButton>
        <IonButton onClick={onNext} style={{ '--background': '#326771' }}>Next</IonButton>
      </IonRow>
    </VerticalFlexContainer>
  </ContentContainer>
)

interface RecipientProps {
  onCreate: (recipients: (RecipientList & { new: boolean })[]) => void,
  onBack: () => void,
}

const Recipients : FC<RecipientProps> = ({ onBack, onCreate }) => {
  const {
    recipientLists, loading, updateRecipientList, setLoading, submittedMessage,
    setSubmittedMessage, deleteRecipientList,
  } = useEngagementData()
  const [ionAlert] = useIonAlert()
  const { workspace } = useWorkspace()
  const [focusedList, setFocusedList] = useState<(RecipientList & { new: boolean, selected: boolean })>(null)
  const [showNewListForm, setShowNewListForm] = useState<boolean>(false)
  const [selectedLists, setSelectedLists] = useState<(RecipientList & { selected: boolean, new: boolean })[]>([])

  const onHandleToggle = (index: number) => {
    const copy = selectedLists.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })
    setSelectedLists(copy)
  }

  const onSubmit = () => {
    const stakeholderLists = selectedLists.filter(({ selected }) => selected)
    if (stakeholderLists.length === 0) {
      ionAlert({
        header: 'Selection Error',
        message: 'No lists selected',
        buttons: [{ text: 'Ok' }],
      })
      return
    }
    onCreate(stakeholderLists)
    setShowNewListForm(false)
  }

  const handleNewList = (newList: RecipientList) => {
    setSelectedLists([...selectedLists, { ...newList, selected: true, new: true }])
    setShowNewListForm(false)
  }

  const getAddRemove = (newRecords: Stakeholder[], oldRecords: Stakeholder[]) : { toRemove: number[], toAdd: number[] } => {
    const toAdd = newRecords.filter(({ id }) => !oldRecords.find((val) => val.id === id))
    const toRemove = oldRecords.filter(({ id }) => !newRecords.find((val) => val.id === id))

    return {
      toAdd: toAdd.map(({ id }) => id),
      toRemove: toRemove.map(({ id }) => id),
    }
  }

  const handleDelete = (list: RecipientList) => {
    const handler = () => {
      setLoading(true)
      axios.put('/api/v2/campaign/archive_list', {
        domain_id: workspace.id,
        list_id: list.id,
      }).then(({ data }) => {
        if (!data.ok) {
          ionAlert({
            header: 'Server Error',
            message: data.message,
            buttons: [{ text: 'Ok' }],
          })
          return
        }

        setSubmittedMessage('Recipient List deleted successfully')
        deleteRecipientList(list)
        setSelectedLists(selectedLists.filter(({ id }) => id !== list.id))
      }).catch(() => {
        ionAlert({
          header: 'Server Error',
          message: 'Unknown server error',
          buttons: [{ text: 'Ok' }],
        })
      }).finally(() => {
        setLoading(false)
      })
    }

    ionAlert({
      header: 'Delete recipient list',
      message: 'Do you want to delete this recipient list?',
      buttons: [{ text: 'No' }, { text: 'Yes', handler }],
    })
  }

  const editRecipientList = (newList: RecipientList) => {
    setLoading(true)

    const stakeholderRecords = getAddRemove(newList.stakeholders, focusedList.stakeholders)
    axios.put('/api/v2/campaign/edit_list', {
      domain_id: workspace.id,
      list_id: newList.id,
      name: newList.name,
      remove_stakeholders: stakeholderRecords.toRemove,
      add_stakeholders: stakeholderRecords.toAdd,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      const copy = selectedLists.slice()
      const index = copy.findIndex(({ id }) => newList.id === id)

      updateRecipientList(newList)
      setFocusedList(null)
      if (index < 0) { return }
      copy.splice(index, 1, { ...newList, new: false, selected: focusedList.selected })
      setSelectedLists(copy)

      setSubmittedMessage('Recipient List updated successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    setSelectedLists(recipientLists.map((val) => ({ ...val, selected: false, new: false })))
  }, [])

  return (
    <ContentContainer className='terrain-add-popup'>
      <LoadingModal isOpen={loading} />
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
      {
        showNewListForm && (
          <NewListForm onSubmit={handleNewList} onCancel={() => setShowNewListForm(false)} editing={false} />
        )
      }
      {
        focusedList && (
          <NewListForm
            onSubmit={(newList) => editRecipientList({ ...newList, id: focusedList.id })}
            onCancel={() => setFocusedList(null)}
            editing
            defaultRecipients={[...focusedList.stakeholders]}
            defaultName={focusedList.name}
          />
        )
      }
      <VerticalFlexContainer>
        <h4 className='red-font'>New Campaign</h4>
        <div>
          <h5>Choose Recipients</h5>
          <p>Select the recipients you wish to be part of this SMS campaign. </p>
        </div>
        <h5 style={{ marginTop: '15p' }}>Recipient List</h5>
        <div style={{
          flex: 1, border: '1px solid #747474', borderRadius: '2.5px', marginBottom: '20px',
        }}
        >
          {
            selectedLists.map((val, index) => (
              <IonRow className='ion-align-items-center ion-justify-content-between' key={val.id} style={{ backgroundColor: getColor(index), padding: '10px' }}>
                <IonRow>
                  <IonCheckbox onClick={() => onHandleToggle(index)} checked={val.selected} />
                  <p style={{ margin: 0, marginLeft: '15px' }}>{ val.name }</p>
                </IonRow>
                <IonRow className='ion-align-items-center'>
                  <BlueButton onClick={() => setFocusedList(val)}>Edit</BlueButton>
                  <BlueButton onClick={() => handleDelete(val)} style={{ color: '#C30101', marginLeft: '20px' }}>Delete</BlueButton>
                </IonRow>
              </IonRow>
            ))
          }
        </div>
        <IonRow className='ion-justify-content-end'>
          <IonButton onClick={() => setShowNewListForm(true)} style={{ '--background': '#326771' }}>New List</IonButton>
        </IonRow>
        <IonRow className='ion-justify-content-end'>
          <IonButton onClick={onBack} style={{ '--background': '#4197A9' }}>Back</IonButton>
          <IonButton onClick={onSubmit} style={{ '--background': '#0C9500' }}>Create</IonButton>
        </IonRow>
      </VerticalFlexContainer>
    </ContentContainer>
  )
}

interface NewListProps {
  onSubmit: (newList: RecipientList) => void,
  onCancel: () => void,
  defaultName?: string,
  defaultRecipients?: Stakeholder[],
  editing: boolean,
}

const NewListForm : FC<NewListProps> = ({
  onSubmit, onCancel, defaultName, defaultRecipients, editing,
}) => {
  const [name, setName] = useState<string>(defaultName)
  const [searchText, setSearchText] = useState<string>('')
  const [selections, setSelections] = useState<(Stakeholder & { selected: boolean })[]>([])
  const { stakeholders, loading } = useEngagementData()
  const [ionAlert] = useIonAlert()

  const onHandleToggle = (index: number) => {
    const copy = selections.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })
    setSelections(copy)
  }

  const handleSubmit = () => {
    const filtered = selections.filter(({ selected }) => selected)

    if (filtered.length === 0) {
      ionAlert({
        header: 'Empty List',
        message: "Can't create an empty stakeholder list",
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    const newRecipientList = { id: Math.random(), name, stakeholders: filtered }
    onSubmit(newRecipientList)
  }

  useEffect(() => {
    setSelections(stakeholders.map((val) => ({ ...val, selected: !!defaultRecipients.find(({ id }) => id === val.id) })))
  }, [])

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <LoadingModal isOpen={loading} />
      <OverlayContainer
        style={{
          zIndex: 25, maxHeight: '80%', overflow: 'hidden', width: '450px', overflowY: 'auto',
        }}
        className='terrain-add-popup'
      >
        <div style={{ maxHeight: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
          <h4 className='red-font'>{ editing ? 'Edit' : 'New' } Recipient List</h4>
          <div className='terrain-form-field form-desc'>
            <h6>List Name</h6>
            <input
              placeholder='Title'
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <p style={{ margin: '10px 0' }}>Select the recipients you wish to be add to this list. </p>
          <input
            placeholder='Search'
            type='text'
            style={{ width: '100%', margin: '15px 0' }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div style={{
            flex: 1, overflow: 'auto', border: '1px solid black', borderRadius: '2.5px', margin: '20px 0',
          }}
          >
            {
              selections.filter((val) => val.name.includes(searchText)).map((val, index) => (
                <IonRow className='ion-align-items-center' key={val.id} style={{ backgroundColor: getColor(index), padding: '10px' }}>
                  <IonCheckbox onClick={() => onHandleToggle(index)} checked={val.selected} />
                  <p style={{ margin: 0, marginLeft: '15px' }}>{ val.name }</p>
                </IonRow>
              ))
            }
            {
              selections.length === 0 && (
                <p style={{ textAlign: 'center' }}>No stakeholders available</p>
              )
            }
          </div>
          <IonRow className='ion-justify-content-end ion-align-items-center'>
            <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            {
              editing ? (
                <IonButton onClick={handleSubmit} style={{ '--background': '#326771' }}>Update</IonButton>
              ) : (
                <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Create</IonButton>
              )
            }
          </IonRow>
        </div>
      </OverlayContainer>
    </>
  )
}

NewListForm.defaultProps = {
  defaultRecipients: [],
  defaultName: '',
}

export default CampaignForm
