import { BackendChatRoom, BackendDomainUser, BackendRecipient } from '../../../components/types/BackendTypes'
import { ChatRoom, Message, User } from '../../../components/types/GlobalTypes'

export type BackendChatElem = [BackendChatRoom, Message | null, BackendRecipient[]]

/**
 * Format data from backend into self-container objects
 *
 * @param rooms -> Backend room objects
 * @param userId -> Id of user in session
 * @returns A frontend formatted version containing all data
 */
export const processChats = (
  rooms: BackendChatElem[],
  users: User[],
  userId: number,
)
: ChatRoom[] => {
  const formattedRooms : ChatRoom[] = rooms.map(([chatRoom, message, recipients]) => {
    /* Determine recipient objects in the room */
    const recipientIds = recipients.filter(({ user_id }) => user_id !== userId).map(({ user_id }) => user_id)
    const recipientUsers = users.filter((val) => recipientIds.indexOf(val.id) >= 0)
    const currentRecipient = recipients.find(({ user_id }) => user_id === userId)

    const recipientUpdates = recipients.filter(({ user_id }) => user_id !== userId).map(({ updated_at, user_id, read }) => ({ updated_at, user_id, read }))

    return {
      id: chatRoom.id,
      recipients: recipientUsers,
      name: (!chatRoom.is_group) ? `${recipientUsers[0].first_name} ${recipientUsers[0].final_name}` : chatRoom.name,
      latestMessage: message,
      read: currentRecipient?.read || false,
      created_at: chatRoom.created_at,
      isGroupChat: chatRoom.is_group,
      recipientUpdates,
    }
  })
  return formattedRooms
}

export const processChatUsers = (users: User[], workspaceUsers: BackendDomainUser[]) : (User & { inWorkspace: boolean })[] => {
  const idsInWorkspace = workspaceUsers.map(({ user_id }) => user_id)
  const formattedUsers : (User & { inWorkspace: boolean })[] = users.map((val) => ({
    ...val,
    inWorkspace: (idsInWorkspace.indexOf(val.id) >= 0),
  }))

  const sorted = formattedUsers.sort((userA, userB) => `${userA.first_name} ${userA.final_name}`.localeCompare(`${userB.first_name} ${userB.final_name}`)).sort((userA, userB) => {
    if (userA.inWorkspace && !userB.inWorkspace) return -1
    return 1
  })

  return sorted
}
