import React, { useState } from 'react'
import {
  IonRow, IonList, IonListHeader, IonItem, IonInput, IonButton, useIonAlert,
} from '@ionic/react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useHistory } from 'react-router-dom'
import { SettingField } from '../StyledContainers'
import { useAuth } from '../../../hooks/useAuth'
import { useWorkspace } from '../../../hooks/useWorkspace'

import axios from '../../../utils/axios'
import { SimpleButton } from '../../maps/StyledContainers'

/**
 *  =======================
 *    Update User Details
 *  =======================
 */
const ProfileSettings = ({ userDetails, setLoading }) => {
  /* Whether the view is editable */
  const [editing, setEditing] = useState(false)

  const { workspace } = useWorkspace()

  /* Original user settings */
  const [user, setUser] = useState(userDetails)
  const history = useHistory()

  /* New input fields */
  const [userFields, setUserFields] = useState(userDetails)
  const [file, setFile] = useState()
  const [ionAlert] = useIonAlert()
  const auth = useAuth()

  /* Handle userFields's data change */
  const handleEdit = (e) => {
    setUserFields({ ...userFields, [e.target.name]: e.detail.value })
  }

  const handlePhone = (event) => {
    const newUserFields = { ...userFields, phone_number: event }
    setUserFields(newUserFields)
  }

  const onCancel = () => {
    setUserFields(user)
    setEditing(false)
  }

  const onSubmit = async () => {
    if (userFields.first_name === '' || userFields.final_name === '' || userFields.email === '') {
      ionAlert({
        header: 'Error',
        message: 'Please fill out every field',
        buttons: [
          'cancel',
        ],
      })

      return
    }

    setLoading(true)
    const formData = new FormData()
    Object.keys(userFields).forEach((prop) => {
      formData.append(prop, userFields[prop])
    })
    formData.append('avatar', file)

    await axios.put('/edit_profile', formData)
    await auth.fetchProfile()

    setUser(userFields)
    setEditing(false)
    setLoading(false)
    ionAlert({
      header: 'Details Updated',
      message: 'Your account details have been updated successfully.',
      buttons: [
        'OK',
      ],
    })
  }

  return (
    <>
      <IonList style={{ '--ion-item-background': 'white', padding: 'white' }}>
        <IonListHeader style={{ borderBottom: '1px solid', marginBottom: '25px' }}>
          <h3 style={{ color: '#8E151F', fontSize: '1.3rem' }}>Account Details</h3>
        </IonListHeader>
        <IonItem lines={(editing) ? 'inset' : 'none'}>
          <SettingField>
            <h4>Name</h4>
            {
                (editing)
                  ? (
                    <IonRow>
                      <IonInput
                        type='text'
                        value={userFields.first_name}
                        placeholder={user.first_name}
                        name='first_name'
                        onIonChange={handleEdit}
                      />
                      <IonInput
                        type='text'
                        value={userFields.final_name}
                        placeholder={user.final_name}
                        name='final_name'
                        onIonChange={handleEdit}
                      />
                    </IonRow>
                  )
                  : <p> {`${user.first_name} ${user.final_name}`} </p>
                      }
          </SettingField>
        </IonItem>
        <IonItem lines={(editing) ? 'inset' : 'none'}>
          <SettingField>
            <h4>Email</h4>
            {
                (editing)
                  ? (
                    <IonInput
                      type='email'
                      value={userFields.email}
                      placeholder={user.email}
                      name='email'
                      onIonChange={handleEdit}
                    />
                  )
                  : <p> {user.email} </p>
                      }
          </SettingField>
        </IonItem>
        <IonItem lines={(editing) ? 'inset' : 'none'}>
          <SettingField>
            <h4>Phone</h4>
            {
                (editing)
                  ? (
                    <PhoneInput
                      international
                      defaultCountry={workspace.country && workspace.country}
                      type='text'
                      value={userFields.phone_number}
                      placeholder={user.phone_number}
                      onChange={handlePhone}
                      name='phone_number'
                    />
                  )
                  : (
                    <IonRow className='ion-align-items-center'>
                      <p style={{ margin: 0 }}>{user.phone_number}</p>
                      {
                        auth.couldVerifySms && (
                          <SimpleButton onClick={() => history.push('/verifysms')}>Verify phone number</SimpleButton>
                        )
                      }
                    </IonRow>
                  )
                }
          </SettingField>
        </IonItem>
        {
          editing && (
            <IonItem>
              <input type='file' onChange={(e) => setFile(e.target.files[0])} />
            </IonItem>
          )
        }
        <IonItem lines={(editing) ? 'inset' : 'none'}>
          <SettingField>
            <h4>Location</h4>
            <p>User location</p>
          </SettingField>
        </IonItem>
        <IonItem lines={(editing) ? 'inset' : 'none'}>
          <SettingField>
            <h4>Job Title</h4>
            <p>Job Title</p>
          </SettingField>
        </IonItem>
      </IonList>
      <IonRow style={{ position: 'absolute', bottom: 0, width: '95%' }} className='ion-justify-content-end'>
        {
          (editing)
            ? (
              <>
                <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>cancel</IonButton>
                <IonButton onClick={onSubmit} style={{ '--background': '#326771' }}>Save Changes</IonButton>
              </>
            )
            : <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
        }
      </IonRow>
    </>
  )
}

export default ProfileSettings
