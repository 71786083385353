import { Icon } from '@iconify/react'
import * as React from 'react'

import { getDateString } from '../../../helpers/GlobalFunctions'
import { Message } from '../../types/GlobalTypes'
import { StakeholderMessage } from '../../types/OptimizedMaps'

const MessageCard : React.FC<Message & { read: boolean }> = ({
  user_id, content, created_at, read, sender,
}) => (
  <div style={{
    maxWidth: '80%',
    alignSelf: (sender === 'domain') ? 'flex-end' : 'flex-start',
    backgroundColor: (sender === 'domain') ? '#B3D5DD' : '#dcddde',
    margin: '10px 0',
    padding: 15,
    borderRadius: '2.5px',
    width: 'fit-content',
  }}
  >
    <p> { content } </p>
    <p style={{ textAlign: 'right', margin: 0, fontSize: '.8rem' }}>
      { getDateString(created_at) }
      {
        sender === 'domain' && (
          <Icon icon={(read) ? 'charm:tick-double' : 'charm:tick'} />
        )
      }
    </p>
  </div>
)

export const DMMessage : React.FC<StakeholderMessage> = ({ content, user, created_at, sender }) => (
  <div style={{
    maxWidth: '80%',
    alignSelf: (sender === 'domain') ? 'flex-end' : 'flex-start',
    backgroundColor: (sender === 'domain') ? '#B3D5DD' : '#dcddde',
    margin: '10px 0',
    padding: 15,
    borderRadius: '2.5px',
    width: 'fit-content',
  }}
  >
    <p> { content } </p>
    <p style={{ textAlign: 'right', margin: 0, fontSize: '.8rem' }}>
      { getDateString(created_at) }
    </p>
  </div>
)

export default MessageCard
