import {
  IonButton, IonCol, IonRow, useIonAlert,
} from '@ionic/react'
import React, { FC, useState } from 'react'
import UserIcon from '../../../../helpers/UserIcon'
import { OverlayContainer } from '../../../GlobalContainers'
import { User } from '../../../types/GlobalTypes'
import { BlueButton } from '../../StyledContainers'
import { UserSelection } from './AddRoom'

interface Props {
  onClose: () => void,
  users: User[],
  onSubmit: (users: (User & { new?: boolean })[], title: string, usersToDelete: User[]) => void
  members: User[],
  name: string
}

const EditRoom : FC<Props> = ({
  onClose, users, onSubmit, name, members,
}) => {
  const [title, setTitle] = useState<string>(name)
  const [participants, setParticipants] = useState<(User & { new?: boolean })[]>(members)
  const [membersToDelete, setMembersToDelete] = useState<User[]>([])
  const [ionAlert] = useIonAlert()

  const addParticipant = (user: User) => {
    const copy = participants.slice()
    copy.push({ ...user, new: true })

    setParticipants(copy)
  }

  const removeParticipant = (user: User) => {
    const copy = participants.slice()
    const index = copy.findIndex(({ id }) => user.id === id)
    copy.splice(index, 1)
    setParticipants(copy)

    /* If it wasn't in the original list of users then just remove them */
    if (!members.find(({ id }) => id === user.id)) { return }

    const copyToDelete = membersToDelete.slice()
    copyToDelete.push(user)

    setMembersToDelete(copyToDelete)
  }

  const handleSubmit = () => {
    if (participants.length === 0) {
      ionAlert({
        header: 'Participants Error',
        message: 'You need to add at least one participant.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    if (title === '') {
      ionAlert({
        header: 'Title Error',
        message: 'Your new breakout room must have a name.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    onSubmit(participants, title, membersToDelete)
  }

  const getRows = (arr: User[]) => arr.reduce<User[][]>((acc, curr, i) => {
    if (!(i % 3)) { // if index is 0 or can be divided by the `size`...
      acc.push(arr.slice(i, i + 3)) // ..push a chunk of the original array to the accumulator
    }
    return acc
  }, [])

  return (
    <OverlayContainer style={{ zIndex: 20, width: '550px' }}>
      <h4 className='red-font' style={{ marginBottom: '15px' }}>Edit Breakout Room</h4>
      <div style={{ margin: '20px 0' }}>
        <h5>Title</h5>
        <input className='title-input incident-title' name='incidentTitle' onChange={(e) => setTitle(e.target.value)} value={title} placeholder='What is this room called?' />
      </div>
      <div style={{ margin: '20px 0' }}>
        <h5 style={{ marginBottom: '15px' }}>Participants</h5>
        {
          participants.map((val) => (
            <IonRow key={val.id} style={{ margin: '10px 0' }} className='ion-align-items-center'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <UserIcon {...val} />
                <p style={{ margin: '0 10px' }}>{ `${val.first_name} ${val.final_name}` }</p>
              </div>
              <BlueButton onClick={() => removeParticipant(val)} style={{ color: '#C30101' }}>
                Remove User
              </BlueButton>
            </IonRow>
          ))
        }
        {
          participants.length === 0 && (
            <p style={{ margin: '20px 0' }}>No Participantes selected</p>
          )
        }
      </div>
      <div style={{ margin: '20px 0' }}>
        <IonRow style={{ marginBottom: '10px' }} className='ion-align-items-center'>
          <h5>Example Incident Workspace Members</h5>
          <IonCol style={{ flex: 1 }}>
            <div style={{ padding: '0.5px', backgroundColor: '#326771' }} />
          </IonCol>
        </IonRow>
        {
          getRows(users.filter(({ id }) => !members.find((val) => val.id === id))).map(([userA, userB, userC]) => (
            <IonRow key={userA.id} style={{ margin: '10px 0' }} className='ion-align-items-center'>
              <IonCol size='4'>
                <UserSelection user={userA} onClick={() => addParticipant(userA)} />
              </IonCol>
              <IonCol size='4'>
                {
                  userB && (
                    <UserSelection user={userB} onClick={() => addParticipant(userB)} />
                  )
                }
              </IonCol>
              <IonCol size='4'>
                {
                  userC && (
                    <UserSelection user={userC} onClick={() => addParticipant(userC)} />
                  )
                }
              </IonCol>
            </IonRow>
          ))
        }
      </div>
      <IonRow className='ion-justify-content-end'>
        <IonButton onClick={onClose} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Update</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default EditRoom
