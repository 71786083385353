import React, { useState, useEffect } from 'react'
import {
  IonListHeader, IonList, IonInput, IonItem, IonContent,
  IonButton, IonRow, useIonAlert, IonToggle, IonCol,
} from '@ionic/react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'
import moment from 'moment'
import { FormTitle, SimpleButton } from '../StyledContainers'

/**
 *  ==========================
 *    JOURNEY REQUEST FORM
 *  ==========================
 */
const empty = {
  supervisor: '',
  purpose: '',
  leader: '',
  leaderMobile: '',
  driverName: '',
  driverMobile: '',
  vehicleType: '',
  regNumber: '',
  arrivalTime: '',
  to: '',
  departureTime: '',
  from: '',
  passengers: [],
  routes: [],
}
const emptyEmergency = {
  emergencyPhone: '',
  altEmergencyPhone: '',
}

const emptyAccommodation = {
  driverAccommodation: '',
  driverAccommodationLocation: '',
  driverAccommodationPhone: '',
  passengerAccommodation: '',
  passengerAccommodationLocation: '',
  passengerAccommodationPhone: '',
}

const JourneyForm2 = ({
  routes, drawRoutes, startRoute, onCancel, onSubmit, users,
  defaultValues = empty, defaultEmergency = emptyEmergency, defaultRoutes = [],
  defaultAccommodation = emptyAccommodation, children,
}) => {
  /* Manage form data */
  const [input, setInput] = useState(defaultValues)

  const [emergency, setEmergency] = useState(defaultEmergency)
  const [showOvernight, setShowOvernight] = useState(false)

  /* New route added must directly be included in routes selected */
  const [newRoutes, setNewRoutes] = useState(defaultRoutes)
  const [loaded, setLoaded] = useState(false)

  const [optionalInput, setOptionalInput] = useState(defaultAccommodation)

  const [ionAlert] = useIonAlert()

  /* Obtains the properties attribute of each GeoJSON route object */
  function getFormattedRoutes(routeFeatures) {
    const formattedRoutes = []
    const { length } = Object.keys(routeFeatures)
    for (let i = 0; i < length; i += 1) {
      formattedRoutes.push(routeFeatures[i].properties)
    }
    return formattedRoutes
  }

  const routeFeatures = { ...routes.features }
  const formattedRoutes = getFormattedRoutes(routeFeatures)

  const returnMessage = (key) => {
    /* Purpose details */
    if (key === 'purpose') return { message: 'Please specify the purpose of the journey' }
    /* Leader Details */
    if (key === 'leader' || key === 'leaderMobile') return { header: 'Error - Journey Leader', message: 'Please fill in the Journey Leader’s details.' }
    /* Driver details */
    if (key === 'driverName' || key === 'driverMobile') return { header: 'Error - Driver', message: 'Please fill in the driver’s details.' }
    /* Car details */
    if (key === 'vehicleType' || key === 'regNumber') return { header: 'Error - Vehicle', message: 'Please fill in the Vehicle details.' }
    /* Arrival details */
    if (key === 'to' || key === 'arrivalTime') return { header: 'Error - Arrival (estimate)', message: 'Please fill in the Arrival details.' }
    /* Departure details */
    if (key === 'from' || key === 'departureTime') return { header: 'Error - Departure', message: 'Please fill in the Departure details.' }
    if (key === 'supervisor') return { header: 'Error - Authorised By', message: 'The Authorised By field is empty. Please select a user from the drop down.' }

    return null
  }

  /* Handle submit form -> Check required inputs */
  const handleSubmit = () => {
    if (!input.passengers.length) {
      ionAlert({
        header: 'Error - Passenger Details',
        message: 'You must add at least one passenger.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    if (!input.routes.length) {
      ionAlert({
        header: 'Error - Route',
        message: 'You must select at least one route to complete a journey request.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    const notFilled = Object.keys(input).find((key) => !input[key])
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    /* Emergency Contact  */
    if (emergency.emergencyPhone === '' && emergency.altEmergencyPhone === '') {
      ionAlert({
        header: 'Error - Emergency Contact',
        message: 'You must submit at least one emergency contact number.',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    if (!Object.values(optionalInput).every((key) => key === '') && !Object.values(optionalInput).every((key) => key !== '')) {
      ionAlert({
        header: 'Error',
        message: 'Fill in with all acommodation details',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    /* Fix formatting issues for some of the fields */
    input.passengers = input.passengers.map((selected) => selected.value).join(', ')
    const stages = input.routes.map((route) => route.value)
    input.emergencyPhone = emergency.emergencyPhone

    onSubmit({
      approver: input.supervisor.id,
      purpose: input.purpose,
      leader_id: input.leader.id,
      leader_phone: input.leaderMobile,
      driver_name: input.driverName,
      driver_phone: input.driverMobile,
      vehicle: input.vehicleType,
      vehicle_reg: input.regNumber,
      emergency_sat: emergency.emergencyPhone,
      emergency_other: emergency.altEmergencyPhone,
      arrival_time: input.arrivalTime.format('YYYY-MM-DD HH:mm'),
      arrival_location: input.to,
      departure_time: input.departureTime.format('YYYY-MM-DD HH:mm'),
      departure_location: input.from,
      passengers: input.passengers,
      driver_location: optionalInput.driverAccommodationLocation,
      driver_contact: optionalInput.driverAccommodationPhone,
      driver_hotel: optionalInput.driverAccommodation,
      passenger_contact: optionalInput.passengerAccommodationPhone,
      passenger_hotel: optionalInput.passengerAccommodation,
      passenger_location: optionalInput.passengerAccommodationLocation,
      stages,
    })
  }

  /* Handle change on passengers  */
  const handlePassengers = (selected) => {
    setInput({ ...input, passengers: selected })
  }

  /* Handle change on routes */
  const handleRoutes = (selected) => {
    setInput({ ...input, routes: selected })
    drawRoutes(selected.map((route) => route.value))
  }

  /* Chand input state */
  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  const handleOptionalInput = (e) => {
    setOptionalInput({ ...optionalInput, [e.target.name]: e.detail.value })
  }

  useEffect(() => {
    if (routes.length > newRoutes.length && loaded) {
      const newRoute = routes[routes.length - 1]
      /* Updated routes */
      const updatedRoutes = [...input.routes, {
        value: newRoute.properties.id,
        label: newRoute.properties.name,
      }]
      setInput({
        ...input,
        routes: updatedRoutes,
      })
      drawRoutes(updatedRoutes.map((route) => route.value))
    }
    setNewRoutes(routes)

    /* Flag that prevents from executing this logic when the component was initially downloaded */
    setLoaded(true)
  })

  return (
    <>
      <IonContent style={{ height: '85%', '--offset-bottom': '20px' }}>
        <IonList style={{ padding: '10px' }}>
          {
            children
          }
          <IonItem>
            <IonInput id='journey-purpose' onIonChange={handleInput} value={input.purpose} name='purpose' type='text' placeholder='Journey Purpose' />
          </IonItem>
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Journey Leader</h3>
            </FormTitle>
          </IonListHeader>
          <p style={{ padding: '0 15px' }}>The Journey Leader is in charge of starting and providing updates on the journey during its course.</p>
          <Select
            placeholder='Select Leader'
            name='leader'
            id='select-leader-container'
            className='select-container'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={users.map((user) => ({ value: user, label: `${user.first_name} ${user.final_name}` }))}
            onChange={(selected) => setInput({ ...input, leader: selected.value })}
          />
          <IonItem>
            <IonInput id='journey-leader-mobile' onIonChange={handleInput} value={input.leaderMobile} name='leaderMobile' type='text' placeholder='Mobile' />
          </IonItem>
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Driver</h3>
            </FormTitle>
          </IonListHeader>
          <IonItem>
            <IonInput id='journey-driver-name' onIonChange={handleInput} value={input.driverName} name='driverName' type='text' placeholder='Name' />
          </IonItem>
          <IonItem>
            <IonInput id='journey-driver-mobile' onIonChange={handleInput} value={input.driverMobile} name='driverMobile' type='text' placeholder='Mobile' />
          </IonItem>
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Vehicle</h3>
            </FormTitle>
          </IonListHeader>
          <IonItem>
            <IonInput id='journey-vehicle-type' onIonChange={handleInput} value={input.vehicleType} name='vehicleType' type='text' placeholder='Type' />
          </IonItem>
          <IonItem>
            <IonInput id='journey-vehicle-rn' onIonChange={handleInput} value={input.regNumber} name='regNumber' type='text' placeholder='Reg Number' />
          </IonItem>
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Emergency Contact Details</h3>
            </FormTitle>
          </IonListHeader>
          <IonItem>
            <IonInput
              onIonChange={(e) => setEmergency({ ...emergency, [e.target.name]: e.detail.value })}
              value={emergency.emergencyPhone}
              name='emergencyPhone'
              id='journey-emergency-phone1'
              type='text'
              placeholder='Emergency Phone No. 1'
            />
          </IonItem>
          <IonItem>
            <IonInput
              onIonChange={(e) => setEmergency({ ...emergency, [e.target.name]: e.detail.value })}
              value={emergency.altEmergencyPhone}
              name='altEmergencyPhone'
              id='journey-emergency-phone2'
              type='text'
              placeholder='Emergency Phone No. 2'
            />
          </IonItem>
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Departure</h3>
            </FormTitle>
          </IonListHeader>
          <IonItem>
            <IonInput id='journey-from-location' onIonChange={handleInput} value={input.from} name='from' type='text' placeholder='Where are you travelling from?' />
          </IonItem>
          <Datetime
            dateFormat='YYYY-MM-DD'
            inputProps={{
              placeholder: 'Departure date/time',
              readOnly: true,
              style: { backgroundColor: 'white' },
            }}
            value={input.departureTime}
            className='journey-departure-time'
            onChange={(e) => setInput({ ...input, departureTime: e })}
            isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
          />
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Arrival (estimate)</h3>
            </FormTitle>
          </IonListHeader>
          <IonItem>
            <IonInput id='journey-to-location' onIonChange={handleInput} value={input.to} name='to' type='text' placeholder='Where are you travelling to?' />
          </IonItem>
          <Datetime
            dateFormat='YYYY-MM-DD'
            inputProps={{
              placeholder: 'Arrival date/time',
              readOnly: true,
              style: { backgroundColor: 'white' },
            }}
            className='journey-arrival-time'
            value={input.arrivalTime}
            onChange={(e) => setInput({ ...input, arrivalTime: e })}
            isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
          />
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Route</h3>
            </FormTitle>
          </IonListHeader>
          <Select
            isMulti
            id='select-route-dropdown'
            placeholder='Select Routes'
            className='select-container'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={formattedRoutes.map((route) => ({ value: route.id, label: route.name }))}
            onChange={handleRoutes}
            value={input.routes}
          />
          <SimpleButton
            onClick={startRoute}
            id='journey-add-new-route'
            style={{ marginTop: '10px', paddingLeft: '15px' }}
          >Add Route +
          </SimpleButton>
          <IonListHeader>
            <FormTitle>
              <h3 style={{ fontSize: '1rem' }}>Passenger Details</h3>
            </FormTitle>
          </IonListHeader>
          <CreatableSelect
            isMulti
            placeholder='Passengers'
            name='colors'
            className='select-container'
            id='journey-passengers'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={users.map((user) => ({ value: user.email, label: user.email }))}
            value={input.passengers}
            onChange={handlePassengers}
          />
          <IonListHeader style={{ marginTop: '20px' }}>
            <IonRow style={{ width: '100%' }} className='ion-justify-content-between ion-align-items-center'>
              <IonCol size={8}>
                <h2 style={{ fontSize: '1.4rem' }}>Overnight Details</h2>
              </IonCol>
              <IonCol size={4}>
                <IonToggle onIonChange={(e) => setShowOvernight(e.target.checked)} checked={showOvernight} />
              </IonCol>
            </IonRow>
          </IonListHeader>
          {
          showOvernight && (
            <>
              <IonListHeader>
                <FormTitle>
                  <h3 style={{ fontSize: '1rem' }}>Driver Accommodation</h3>
                </FormTitle>
              </IonListHeader>
              <IonItem>
                <IonInput onIonChange={handleOptionalInput} value={optionalInput.driverAccommodation} name='driverAccommodation' type='text' placeholder='Accomodation Name' />
              </IonItem>
              <IonItem>
                <IonInput onIonChange={handleOptionalInput} value={optionalInput.driverAccommodationLocation} name='driverAccommodationLocation' type='text' placeholder='Accommodation address' />
              </IonItem>
              <IonItem>
                <IonInput onIonChange={handleOptionalInput} value={optionalInput.driverAccommodationPhone} name='driverAccommodationPhone' type='text' placeholder='Phone' />
              </IonItem>
              <IonListHeader>
                <FormTitle>
                  <h3 style={{ fontSize: '1rem' }}>Passengers Accommodation</h3>
                </FormTitle>
              </IonListHeader>
              <IonItem>
                <IonInput onIonChange={handleOptionalInput} value={optionalInput.passengerAccommodation} name='passengerAccommodation' type='text' placeholder='Accomodation Name' />
              </IonItem>
              <IonItem>
                <IonInput
                  onIonChange={handleOptionalInput}
                  value={optionalInput.passengerAccommodationLocation}
                  name='passengerAccommodationLocation'
                  type='text'
                  placeholder='Accommodation address'
                />
              </IonItem>
              <IonItem>
                <IonInput onIonChange={handleOptionalInput} value={optionalInput.passengerAccommodationPhone} name='passengerAccommodationPhone' type='text' placeholder='Phone' />
              </IonItem>
            </>
          )
        }
          <IonListHeader>
            <h2 style={{ fontSize: '1.2rem', marginTop: '10px' }}>Authorised By</h2>
          </IonListHeader>
          <Select
            placeholder='Select user'
            name='supervisor'
            id='select-supervisor-container'
            className='select-container'
              // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={users.map((user) => ({ value: user, label: user.email }))}
            onChange={(selected) => setInput({ ...input, supervisor: selected.value })}
          />
        </IonList>
      </IonContent>
      <IonRow
        style={{
          height: '15%', alignItems: 'center', backgroundColor: 'white', padding: '0 10px',
        }}
        className='ion-justify-content-end'
      >
        <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>Cancel</IonButton>
        <IonButton id='submit-road-journey' style={{ '--background': '#4197A9' }} onClick={handleSubmit}>Submit</IonButton>
      </IonRow>
    </>
  )
}

export default JourneyForm2
