// Action Cable provides the framework to deal with WebSockets in Rails. Force Push
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from '@rails/actioncable'

export default createConsumer(getWebSocketURL)

export const getWebSocketURL = () => {
  const token = localStorage.getItem('token')
  return `wss://dev-sherpa.herokuapp.com/cable?token=${token}`
}
