import {
  IonCol,
  IonContent, IonGrid, IonPage, IonRow,
} from '@ionic/react'
import React, { FC, useState } from 'react'
import LoadingModal from '../components/modals/LoadingModal'
import PasswordReset from '../components/session/PasswordReset'

const FirstPassword : FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
            <LoadingModal isOpen={loading} />
            <IonCol offsetMd='3' sizeMd='6' offsetLg='4' sizeLg='4'>
              <PasswordReset setLoading={setLoading} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default FirstPassword
