import {
  IonButton, IonRow, IonToggle,
} from '@ionic/react'
import React, { FC, useState } from 'react'
import { TrackedUsersState, TrackedUsersAction, TrackedUsersActionKind } from '../../../hooks/journeys/helpers/StateReducers'
import { SettingField } from '../../settings/StyledContainers'
import { SimpleButton } from '../StyledContainers'
import { FormContainer } from './MapForm'

interface Props {
  trackUsers: TrackedUsersState,
  dispatchTrackUsers: React.Dispatch<TrackedUsersAction>,
  onClose: () => void,
  onBack: () => void,
  onSubmit: () => void,
}

const RestrictionZoneTracking: FC<Props> = ({
  dispatchTrackUsers, trackUsers, onClose, onBack, onSubmit,
}) => {
  const [search, setSearch] = useState<string>('')

  return (
    <FormContainer style={{ backgroundColor: 'white' }}>
      <div style={{ height: '100%', padding: '20px', overflow: 'hidden' }} className='risk-assessment-list'>
        <div style={{ minHeight: 'auto', flex: '1', overflow: 'hidden' }} className='risk-assessment-list'>
          <IonRow>
            <SettingField style={{ width: 'auto' }}> <h4 style={{ margin: '0' }}> Add Restriction Zone </h4> </SettingField>
            <SimpleButton
              onClick={onClose}
            >Close X
            </SimpleButton>
          </IonRow>
          <p style={{ margin: '15px 0' }}>Select the users you wish to track. When a selected user leaves the green zone an alert will be sent to them and any managers in this workspace.</p>
          <SettingField style={{ margin: '5px 0' }}> <h5 className='blue'>User List</h5> </SettingField>
          <input
            className='title-input incident-title'
            style={{ maxWidth: '100%' }}
            name='title'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder='Search user'
          />
          <div style={{
            margin: '5px 0', flex: 1, border: '1px solid #747474', overflowY: 'auto',
          }}
          >
            <h6 className='tracking-user-title'>Domain Users</h6>
            <IonRow style={{ padding: '10px' }} className='ion-justify-content-between ion-align-items-center'>
              <p style={{ margin: 0, fontSize: '0.9rem' }}>Toggle All Domain users On/Off</p>
              <IonToggle color='success' checked={trackUsers.toggleAll} onClick={() => dispatchTrackUsers({ type: TrackedUsersActionKind.TOGGLE_ALL })} />
            </IonRow>
            {
              trackUsers.domainUsers.filter((val) => `${val.first_name} ${val.final_name}`.includes(search)).map((val) => (
                <IonRow key={val.id} style={{ padding: '10px 0', margin: '0 10px', borderBottom: '1px solid #747474' }} className='ion-justify-content-between ion-align-items-center'>
                  <p style={{ margin: 0, fontSize: '0.9rem' }}>{ val.first_name } { val.final_name }</p>
                  <IonToggle color='success' checked={val.checked} onClick={() => dispatchTrackUsers({ type: TrackedUsersActionKind.SELECT_ONE_DOMAIN, userId: val.id })} />
                </IonRow>
              ))
            }
            <h6 className='tracking-user-title'>All Other Users</h6>
            {
              trackUsers.users.filter((val) => `${val.first_name} ${val.final_name}`.includes(search)).map((val) => (
                <IonRow key={val.id} style={{ padding: '10px 0', margin: '0 10px', borderBottom: '1px solid #747474' }} className='ion-justify-content-between ion-align-items-center'>
                  <p style={{ margin: 0, fontSize: '0.9rem' }}>{ val.first_name } { val.final_name }</p>
                  <IonToggle color='success' checked={val.checked} onClick={() => dispatchTrackUsers({ type: TrackedUsersActionKind.SELECT_ONE_REST, userId: val.id })} />
                </IonRow>
              ))
            }
          </div>
        </div>
        <IonRow className='ion-justify-content-end' style={{ margin: '15px 0' }}>
          <IonButton style={{ '--background': '#4197A9' }} onClick={onBack}>Back</IonButton>
          <IonButton id='submit-restriction-box' style={{ '--background': '#0C9500' }} onClick={onSubmit}>Submit</IonButton>
        </IonRow>
      </div>
    </FormContainer>
  )
}

export default RestrictionZoneTracking
