import { IonCol, IonRow, useIonAlert } from '@ionic/react'
import React, { FC } from 'react'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { BlueButton, RedButton } from '../../incident-management/StyledContainers'
import StakeholderScreen from '../popovers/StakeholderScreen'
import { getColor } from './FlashpointList'
import { useWorkspace } from '../../../hooks/useWorkspace'
import axios from 'axios'

const StakeholderList : FC = () => {
  const { focusedElems } = useTerrainMapping()

  if (focusedElems.focusedStakeholder) {
    return (
      <StakeholderScreen />
    )
  }

  return (
    <List />
  )
}

const List : FC = () => {
  const { dispatchFocused, filteredStakeholders } = useTerrainMapping()
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()

  const handleDelete = (e: { id: string }) => {
    ionAlert({
      header: 'Confirmation',
      message: 'Are you sure you want to delete this stakeholder?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            console.log('Delete canceled')
            return
          }
        },
        {
          text: 'Yes',
          handler: () => {
            console.log('Delete', e)
            axios.put('/api/v2/stakeholder/delete_stakeholder', {
              domain_id: workspace.id,
              stakeholder_id: e.id,
            }).then(({ data }) => {
              console.log('Success:', data)
              // Handle success case here
            }).catch((error) => {
              console.error('Error:', error)
              // Handle error case here
            })
          }
        }
      ],
    })
  }

  return (
    <ListContainer style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow style={{ padding: '10px' }} className='terrain-list-title  ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Name</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Type</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Role/Position</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Organisations</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Locations</h5>
          </IonCol>
          <IonCol size='2' />
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            filteredStakeholders.map((val, index) => (
              <IonRow
                className='ion-justify-content-between terrain-register-row ion-align-items-center'
                style={{ backgroundColor: getColor(index) }}
                key={val.id}
              >
                <IonCol size='2'>{ val.name }</IonCol>
                <IonCol size='2'>{ val.type }</IonCol>
                <IonCol size='2'>{ val.role }</IonCol>
                <IonCol size='2'>{ (val.organisations.length > 0) ? val.organisations.map(({ name }) => name).join(', ') : 'No organisations' }</IonCol>
                <IonCol size='2'>{ (val.areas.length > 0) ? val.areas.map(({ name }) => name).join(', ') : 'No areas' }</IonCol>
                <IonCol size='2'>
                  <BlueButton onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_STAKEHOLDER, stakeholder: val })}>View</BlueButton> / <RedButton onClick={() => handleDelete(val)}>Delete</RedButton>
                </IonCol>
              </IonRow>
            ))
          }
          {
            filteredStakeholders.length === 0 && (
              <p style={{ textAlign: 'center' }}>No stakeholders available</p>
            )
          }
        </div>
      </div>
    </ListContainer>
  )
}

export default StakeholderList
