/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react'
import {
  IonPage, IonContent, IonGrid, IonRow, IonCol, IonSpinner,
} from '@ionic/react'
import { useHistory } from 'react-router'
import useApi from '../hooks/testHooks'
import { RegisterContainer } from '../components/maps/StyledContainers'

/**
 *  =============================
 *      Activate account Page
 *  =============================
 */

const Activate = () => {
  /* Consume API hook */
  const apiHook = useApi()
  const history = useHistory()

  useEffect(() => {
    apiHook.activateAccount(location.search).then(() => {
      history.push('/login')
    })
  }, [])

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
            <IonCol offsetMd='3' sizeMd='6' offsetLg='4' sizeLg='4'>
              <RegisterContainer style={{ textAlign: 'center' }}>
                <IonSpinner style={{ marginBottom: '30px' }} />
                <p>Authorising... Please Wait</p>
              </RegisterContainer>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Activate
