import React from 'react'
import { IonRow } from '@ionic/react'
import { useIncidentsData } from '../../hooks/incident-management/useIncidentData'
import { TabOption } from './StyledContainers'
import { IncidentManagementView, IncidentViewActionKind } from '../../hooks/incident-management/helpers/StateReducers'
import { useWorkspace } from '../../hooks/useWorkspace'

/**
 *  ===============================
 *      TAB BAR (SELECT VIEWS)
 *  ===============================
 */
const TabBar = () => {
  /* App hook to get data */
  const {
    dispatchView, displayView,
  } = useIncidentsData()
  const { workspace } = useWorkspace()

  return (
    <IonRow style={{ maxheight: '10%', flex: '0 1 auto', width: '100%' }} className={(displayView.focusedIncident) ? 'ion-justify-content-between' : 'ion-justify-content-start'}>
      {
        (displayView.focusedIncident) ? (
          <>
            <IonRow>
              <TabOption
                style={{ marginLeft: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.INCIDENT_TICKET })}
                className={(displayView.view === IncidentManagementView.INCIDENT_TICKET) ? 'selected' : ''}
              > Incident Ticket
              </TabOption>
              <TabOption
                style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}
                onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.ACTION_LOG })}
                className={(displayView.view === IncidentManagementView.ACTION_LOG) ? 'selected' : ''}
              > Action Log
              </TabOption>
              <TabOption
                style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.INVESTIGATIONS })}
                className={(displayView.view === IncidentManagementView.INVESTIGATIONS) ? 'selected' : ''}
              > Investigations
              </TabOption>
              {
                workspace.is_incident_domain && (
                  <TabOption
                    style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                    onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.BREAKOUT_ROOMS })}
                    className={(displayView.view === IncidentManagementView.BREAKOUT_ROOMS) ? 'selected' : ''}
                  >
                    Breakout Rooms
                  </TabOption>
                )
              }
            </IonRow>
            {
              !workspace.is_incident_domain && (
                <TabOption style={{ backgroundColor: '#326771', color: 'white' }} onClick={() => dispatchView({ type: IncidentViewActionKind.FORGET_INCIDENT })}>
                  Back to Incident Board
                </TabOption>
              )
            }
          </>
        ) : (
          <>
            <IonRow>
              <TabOption
                style={{ marginLeft: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.INCIDENT_BOARD })}
                className={(displayView.view === IncidentManagementView.INCIDENT_BOARD) ? 'selected' : ''}
              > Incident Board
              </TabOption>
              <TabOption
                style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.SOPS })}
                className={(displayView.view === IncidentManagementView.SOPS) ? 'selected' : ''}
              > SOPs
              </TabOption>
              <TabOption
                style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.RESPONSE_LEVELS })}
                className={(displayView.view === IncidentManagementView.RESPONSE_LEVELS) ? 'selected' : ''}
              > Response Levels
              </TabOption>
              <TabOption
                style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                onClick={() => dispatchView({ type: IncidentViewActionKind.SWITCH_VIEW, newView: IncidentManagementView.EMERGENCY_TEAMS })}
                className={(displayView.view === IncidentManagementView.EMERGENCY_TEAMS) ? 'selected' : ''}
              > Emergency Teams
              </TabOption>
            </IonRow>
          </>
        )
      }
    </IonRow>
  )
}

export default TabBar
