import { IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import Map from '../Map'
import CommunityArea from '../features/CommunityArea'
import POI from '../features/POI'
import FlashpointForm from '../features/Flashpoint'
import { AppView } from '../../../hooks/terrain-mapping/types/HookInterface'
import Register from '../../terrain-mapping/Register'
import Stakeholder from '../features/Stakeholder'
import EngagementNote from '../../terrain-mapping/forms/EngagementNote'
import CommitmentForm from '../../terrain-mapping/forms/CommitmentForm'
import Issue from '../../terrain-mapping/forms/Issue'
import CommunityEdit from '../../terrain-mapping/popups/CommunityEdit'

const TerrainMap : FC = () => {
  const {
    mapRef, fetchAppData, showCommunityForm, showPOIForm, showCommitmentForm, showIssueForm,
    showFlashpointForm, view, showStakeholderForm, showEngagementForm, focusedElems, editing,
    communities, setShowStakeholderForm, setLoading, pushNewStakeholder, setSubmittedMessage,
    stakeholderGroups, stakeholders, fromCommunityAdd,
  } = useTerrainMapping()
  const setUpListeners = () => {
    fetchAppData()
  }

  console.log(fromCommunityAdd)

  return (
    <IonRow style={{ flex: '1 1 auto' }} className='ion-justify-content-center'>
      <Map
        mapRef={mapRef}
        listeners={setUpListeners}
        zoom={5}
        style={{ height: '100%' }}
        className='insights-map-2'
      >
        {
          showCommunityForm && (
            <CommunityArea />
          )
        }
        {
          focusedElems.focusedCommunity && editing && (
            <CommunityEdit />
          )
        }
        {
          showPOIForm && (
            <POI />
          )
        }
        {
          showEngagementForm && (
            <EngagementNote />
          )
        }
        {
          showFlashpointForm && (
            <FlashpointForm />
          )
        }
        {
          showStakeholderForm && (
            <Stakeholder
              communities={communities}
              onClose={() => setShowStakeholderForm(false)}
              stakeholders={stakeholders}
              setLoading={setLoading}
              pushNewStakeholder={pushNewStakeholder}
              setSubmittedMessage={setSubmittedMessage}
              stakeholderGroups={stakeholderGroups}
            />
          )
        }
        {
          showCommitmentForm && (
            <CommitmentForm />
          )
        }
        {
          showIssueForm && (
            <Issue />
          )
        }
        {
          view === AppView.REGISTERS && (
            <Register />
          )
        }
      </Map>
    </IonRow>
  )
}

export default TerrainMap
