/* eslint-disable import/prefer-default-export */
import {
  Feature, FeatureCollection, LineString, Point, Position,
} from '@turf/turf'
import * as turf from '@turf/turf'
import { BackendRoute } from '../../hooks/journeys/types/HookInterface2'
import { ControlMeasure } from '../riskregister/types/RiskAssessments'
import { IncidentCause, JourneyStatus, RestrictionZoneGeoJSON } from '../types/GlobalTypes'
import {
  BackendJourney, BackendRestrictionZone, ChapterProperties, ChaptersGeoJSON, Journey, RestrictionZone, Route,
} from '../types/OptimizedMaps'
import { getChapterType } from '../../hooks/journeys/helpers/Utils'
import { BackendJourneyHistory } from '../types/GeoJSONTypes'
import { getRestrictionZoneColor } from '../../hooks/journeys/helpers/RestrictionZoneUtils'

/**
 * Format backend boxes
 *
 * @param zones -> Array of backend zones
 * @param underlyings -> List of causes
 * @param measures -> List of control measures
 */
export const processRestrictionZones = (zones: BackendRestrictionZone[], underlyings: IncidentCause[], measures: ControlMeasure[]) : RestrictionZone[] => zones.map((val) => {
  const measureIds = val.box_measures.map((measure) => measure.control_measure_id)
  const underlyingIds = val.zone_underlyings.map((underlying) => underlying.cause_id)

  return {
    ...val,
    controlMeasures: measures.filter(({ id }) => measureIds.indexOf(id) >= 0),
    underlyings: underlyings.filter(({ id }) => underlyingIds.indexOf(id) >= 0),
    threats: val.zone_threats.map(({ threat }) => threat),
    geodata: { ...JSON.parse(val.geoData) as RestrictionZoneGeoJSON, properties: { id: val.id, type: getRestrictionZoneColor(val.zone_type) } },
  }
})

/**
 * Parse Geo data
 *
 * @param routes -> List of backend routes
 * @returns The same list but with the geoData attribute parsed
 */
export const routesToGeoJSON = (routes: BackendRoute[]) : Route[] => routes.map((val) => ({ ...val, geoData: JSON.parse(val.geoData) as Feature<LineString> }))

/**
 * Process journeys data
 *
 * @param journeys -> List of backend journeys
 * @param routes -> Routes to cross-reference
 * @returns A frontend formatted list of journeys
 */
export const processJourneys = (journeys: BackendJourney[], routes: Route[], histories: BackendJourneyHistory[]) : Journey[] => journeys.map((val) => {
  const routeIds = val.journey_stages.map(({ path_id }) => path_id)
  const journeyRoutes = routes.filter(({ id }) => routeIds.indexOf(id) >= 0)
  console.log('js', journeys)

  let firstPoint : Position = null

  if (val.status === JourneyStatus.COMPLETE) {
    const history = histories.find(({ journey_id }) => journey_id === val.id)

    if (history) {
      const firstFeature = history.path.features.find(({ properties }) => properties.position === 'first')

      if (firstFeature) {
        [firstPoint] = firstFeature?.geometry?.coordinates || [];
      }
    }
  }

  if (val.status !== 'Complete') {
    [firstPoint] = journeyRoutes[0]?.geoData?.geometry?.coordinates || []
  }

  return {
    ...val,
    routes: journeyRoutes.map(({ id, name }) => ({ id, name })),
    firstPoint,
  }
})

/**
 * Get GeoJSON Feature collection of journeys
 *
 * @param journeys -> Formatted Journeys
 * @param backendRoutes -> Backend route objects
 * @returns A GeoJSON feature collection with all routes of active journeys
 */
export const journeyRoutesToGeoJSON = (journeys: Journey[], backendRoutes: Route[]) : FeatureCollection<LineString> => {
  const features = journeys.reduce<Feature<LineString>[]>((list, { routes }) => {
    const routeIds = routes.map(({ id }) => id)
    const filteredRoutes = backendRoutes.filter(({ id }) => routeIds.indexOf(id) >= 0)

    return [...list, ...filteredRoutes.map(({ geoData }) => geoData)]
  }, [])

  return turf.featureCollection(features)
}

/**
 * Get GeoJSON feature colleciton of active and completed journeys' chapters
 *
 * @param journeys -> List of journeys
 * @returns GeoJSON Feature collection of the chapters
 */
export const processChapters = (journeys: Journey[]) : { active: ChaptersGeoJSON, completed: ChaptersGeoJSON } => {
  const activeFeatures : Feature<Point, ChapterProperties>[] = []
  const completedFeatures : Feature<Point, ChapterProperties>[] = []

  /* Loop through journeys and add the chapters to their respective lists */
  journeys.forEach((journey) => {
    journey.chapters.forEach((chapter) => {
      if (!chapter.latitude || !chapter.longitude || chapter.user_id !== journey.leader.id) {
        return
      }

      const properties : ChapterProperties = {
        journey_id: journey.id,
        type: getChapterType(chapter),
        id: chapter.id,
        user_id: chapter.user_id,
      }

      const feature = turf.point([chapter.longitude, chapter.latitude], properties)
      if (journey.status !== 'Complete') {
        activeFeatures.push(feature)
        return
      }

      completedFeatures.push(feature)
    })
  })

  return { active: turf.featureCollection(activeFeatures), completed: turf.featureCollection(completedFeatures) }
}
