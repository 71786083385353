import {
  IonButton, IonInput, IonItem, IonList, IonRow, useIonAlert,
} from '@ionic/react'
import React, { FC, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { SherpaButton } from '../GlobalContainers'
import { RegisterContainer } from '../maps/StyledContainers'
import axios from '../../utils/axios'
import { useAuth } from '../../hooks/useAuth'

interface Props {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const PasswordReset : FC<Props> = ({ setLoading }) => {
  const [passwordFields, setPasswordFields] = useState({
    newPassword: '',
    repeatPassword: '',
  })

  const { resetPassword, setResetPassword } = useAuth()

  const history = useHistory()

  const [ionAlert] = useIonAlert()

  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const atLeastOneNumeric = /[0-9]/g // numbers from 0 to 9
  const eightCharsOrMore = /.{8,}/g // eight characters or more

  const passwordTracker = {
    number: passwordFields.newPassword.match(atLeastOneNumeric),
    eightCharsOrGreater: passwordFields.repeatPassword.match(eightCharsOrMore),
  }

  const handlePasswordEdit = (e) => {
    setPasswordFields({ ...passwordFields, [e.target.name]: e.detail.value })
  }

  const submitNewPassword = async () => {
    if (passwordFields.newPassword !== passwordFields.repeatPassword) {
      ionAlert({
        header: 'Error',
        message: 'Passwords do not match.',
        buttons: [
          { text: 'OK' },
        ],
      })

      return
    }

    if (!passwordTracker.number) {
      ionAlert({
        header: 'Error',
        message: 'Passwords must have at least 1 number',
        buttons: [
          { text: 'OK' },
        ],
      })
      return
    }

    if (!passwordTracker.eightCharsOrGreater) {
      ionAlert({
        header: 'Error',
        message: 'Passwords must have at least 8 characters',
        buttons: [
          { text: 'OK' },
        ],
      })
    }

    setLoading(true)
    axios.put('/first_password_reset', { new_password: passwordFields.newPassword }).then(() => {
      setResetPassword(false)
      history.push('/workspaces')
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (resetPassword) {
      return
    }

    history.push('/workspaces')
  }, [])

  return (
    <RegisterContainer>
      <h2 style={{ textAlign: 'left', color: '#8E151F' }}>Password Reset</h2>
      <p style={{ margin: '20px 0' }}>The password used to sign in was only a temporary one. Please create a new password below. </p>
      <p style={{ margin: '20px 0' }}>This password will be used to sign in to the Sherpa platform going forward. So make sure to remember it. </p>
      <IonList>
        <IonItem>
          <IonInput
            placeholder='enter new password'
            type={(showPassword) ? 'text' : 'password'}
            title='Password'
            value={passwordFields.newPassword}
            name='newPassword'
            onIonChange={handlePasswordEdit}
          />
          <SherpaButton className='show-password' onClick={() => setShowPassword(!showPassword)}>{(showPassword)
            ? <Icon icon='codicon:eye' /> : <Icon icon='codicon:eye-closed' />}
          </SherpaButton>
        </IonItem>
        <IonItem style={{ marginTop: '2em' }}>
          <IonInput
            type={(showRepeatPassword) ? 'text' : 'password'}
            title='Password'
            value={passwordFields.repeatPassword}
            name='repeatPassword'
            placeholder='Repeat New password'
            onIonChange={handlePasswordEdit}
          />
          <SherpaButton className='show-password' onClick={() => setShowRepeatPassword(!showRepeatPassword)}>{(showRepeatPassword)
            ? <Icon icon='codicon:eye' /> : <Icon icon='codicon:eye-closed' />}
          </SherpaButton>
        </IonItem>
      </IonList>
      <IonRow className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#326771' }} onClick={submitNewPassword} title='login'>Update Password</IonButton>
      </IonRow>
    </RegisterContainer>
  )
}

export default PasswordReset
