/* eslint-disable import/prefer-default-export */
import React from 'react'
import * as ReactDOM from 'react-dom'
import mapboxgl, { Point } from 'mapbox-gl'
import { Feature, FeatureCollection, Polygon } from '@turf/turf'
import POIIcon from '../../../components/terrain-mapping/icons/poi.svg'
import LibraryIcon from '../../../components/terrain-mapping/icons/Library.png'
import AircraftLandingIcon from '../../../components/terrain-mapping/icons/Aircraft Landing.png'
import BankIcon from '../../../components/terrain-mapping/icons/Bank.png'
import BusinessIcon from '../../../components/terrain-mapping/icons/Business.png'
import CommunityAdminIcon from '../../../components/terrain-mapping/icons/community administration.png'
import ConservationIcon from '../../../components/terrain-mapping/icons/conservation.png'
import EducationCentreIcon from '../../../components/terrain-mapping/icons/Education centre.png'
import ElectricityIcon from '../../../components/terrain-mapping/icons/electricity infrastructure.png'
import FarmlandIcon from '../../../components/terrain-mapping/icons/Farmland.png'
import FishingIcon from '../../../components/terrain-mapping/icons/Fishing.png'
import HeritageIcon from '../../../components/terrain-mapping/icons/historic heritage.png'
import LandHousingIcon from '../../../components/terrain-mapping/icons/Land & housing.png'
import MarketplaceIcon from '../../../components/terrain-mapping/icons/marketplace.png'
import MedicalIcon from '../../../components/terrain-mapping/icons/medical centre.png'
import OtherIcon from '../../../components/terrain-mapping/icons/Other.png'
import WorshipIcon from '../../../components/terrain-mapping/icons/Place of worship.png'
import PublicAdminIcon from '../../../components/terrain-mapping/icons/Public Administration.png'
import PublicBenefitIcon from '../../../components/terrain-mapping/icons/public benefit.png'
import PublicTransportIcon from '../../../components/terrain-mapping/icons/Public transport.png'
import RecreationRouteIcon from '../../../components/terrain-mapping/icons/recreation route.png'
import SportsIcon from '../../../components/terrain-mapping/icons/Sports & Recreation.png'
import TouristIcon from '../../../components/terrain-mapping/icons/Tourist Interest.png'
import WaterIcon from '../../../components/terrain-mapping/icons/Water Source.png'
import RenewableEnergyIcon from '../../../components/terrain-mapping/icons/Renewable energy generation.png'
import EmergencyServicesIcon from '../../../components/terrain-mapping/icons/Emergency Services.png'

import { Community, TerrainPOI } from '../../../components/types/OptimizedMaps'
import CommunityPopup from '../../../components/terrain-mapping/popups/CommunityPopup'
import { poisToGeoJSON } from './Utils'
import { assetsToGeoJSON, createAssetPointLayers } from '../../risks/helpers/MapUtils'

/**
 * Create Community areas source and render them
 *
 * @param mapRef -> React ref to map object
 * @param features -> Array of communities
 */
export const createCommunityLayer = (
  mapRef: React.RefObject<mapboxgl.Map>,
  features: Feature<Polygon, { id: number }>[] = [],
) => {
  mapRef.current.addSource('community-areas', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features,
    },
  })

  mapRef.current.addLayer({
    id: 'community-area-lines',
    type: 'line',
    source: 'community-areas',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#000000',
      'line-width': 2,
    },
  })

  mapRef.current.addLayer({
    id: 'community-area-layers',
    source: 'community-areas',
    type: 'fill',
    paint: {
      'fill-color': 'rgb(65, 151, 169)',
      'fill-opacity': 0.5,
    },
  })
}
//
export const createPOILayers = (
  mapRef: React.RefObject<mapboxgl.Map>,
  poiList: TerrainPOI[] = [],
) => {
  const { featurePoints, featureLines, featurePolygons } = poisToGeoJSON(poiList)
  console.log('featurepoints', featurePoints)
  mapRef.current.addSource('poi-points', {
    type: 'geojson',
    data: featurePoints,
  })

  mapRef.current.addSource('poi-lines', {
    type: 'geojson',
    data: featureLines,
  })

  mapRef.current.addSource('poi-polygons', {
    type: 'geojson',
    data: featurePolygons,
  })

  mapRef.current.addLayer({
    id: 'poi-lines-layer',
    type: 'line',
    source: 'poi-lines',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#000000',
      'line-width': 2,
    },
  })

  mapRef.current.addLayer({
    id: 'poi-polygon-layer',
    type: 'fill',
    source: 'poi-polygons',
    paint: {
      'fill-color': '#02C221', // blue color fill
      'fill-opacity': 0.5,
    },
  })

  mapRef.current.addLayer({
    id: 'poi-polygon-line-layer',
    source: 'poi-polygons',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#016311',
      'line-width': 2,
    },
  })

  /* Load Symbol layer icon */
  const defaultIcon = new Image()
  defaultIcon.src = POIIcon // Your default POI icon
  const libraryIcon = new Image()
  libraryIcon.src = LibraryIcon
  const aircraftIcon = new Image()
  aircraftIcon.src = AircraftLandingIcon
  const bankIcon = new Image()
  bankIcon.src = BankIcon
  const businessIcon = new Image()
  businessIcon.src = BusinessIcon
  const communityAdminIcon = new Image()
  communityAdminIcon.src = CommunityAdminIcon
  const conservationIcon = new Image()
  conservationIcon.src = ConservationIcon
  const educationCentreIcon = new Image()
  educationCentreIcon.src = EducationCentreIcon
  const electricityIcon = new Image()
  electricityIcon.src = ElectricityIcon
  const farmlandIcon = new Image()
  farmlandIcon.src = FarmlandIcon
  const fishingIcon = new Image()
  fishingIcon.src = FishingIcon
  const heritageIcon = new Image()
  heritageIcon.src = HeritageIcon
  const landHousingIcon = new Image()
  landHousingIcon.src = LandHousingIcon
  const marketplaceIcon = new Image()
  marketplaceIcon.src = MarketplaceIcon
  const medicalIcon = new Image()
  medicalIcon.src = MedicalIcon
  const otherIcon = new Image()
  otherIcon.src = OtherIcon
  const worshipIcon = new Image()
  worshipIcon.src = WorshipIcon
  const publicAdminIcon = new Image()
  publicAdminIcon.src = PublicAdminIcon
  const publicBenefitIcon = new Image()
  publicBenefitIcon.src = PublicBenefitIcon
  const recreationRouteIcon = new Image()
  recreationRouteIcon.src = RecreationRouteIcon
  const sportsIcon = new Image()
  sportsIcon.src = SportsIcon
  const touristIcon = new Image()
  touristIcon.src = TouristIcon
  const waterIcon = new Image()
  waterIcon.src = WaterIcon
  const publicTransportIcon = new Image()
  publicTransportIcon.src = PublicTransportIcon
  const renewableEnergyIcon = new Image()
  renewableEnergyIcon.src = RenewableEnergyIcon
  const emergencyServicesIcon = new Image()
  emergencyServicesIcon.src = EmergencyServicesIcon

  const loadIconsAndLayer = () => {
    if (!defaultIcon.complete || !libraryIcon.complete) {
      // If not all icons are loaded, wait a bit and try again
      setTimeout(loadIconsAndLayer, 100)
      return
    }
    if (!mapRef.current.isStyleLoaded()) {
      setTimeout(loadIconsAndLayer, 100)
      return
    }

    // Add images to map
    mapRef.current.addImage('defaultIcon', defaultIcon)
    mapRef.current.addImage('libraryIcon', libraryIcon)
    mapRef.current.addImage('aircraftIcon', aircraftIcon)
    mapRef.current.addImage('bankIcon', bankIcon)
    mapRef.current.addImage('businessIcon', businessIcon)
    mapRef.current.addImage('communityAdminIcon', communityAdminIcon)
    mapRef.current.addImage('conservationIcon', conservationIcon)
    mapRef.current.addImage('educationCentreIcon', educationCentreIcon)
    mapRef.current.addImage('electricityIcon', electricityIcon)
    mapRef.current.addImage('farmlandIcon', farmlandIcon)
    mapRef.current.addImage('fishingIcon', fishingIcon)
    mapRef.current.addImage('heritageIcon', heritageIcon)
    mapRef.current.addImage('landHousingIcon', landHousingIcon)
    mapRef.current.addImage('marketplaceIcon', marketplaceIcon)
    mapRef.current.addImage('medicalIcon', medicalIcon)
    mapRef.current.addImage('otherIcon', otherIcon)
    mapRef.current.addImage('worshipIcon', worshipIcon)
    mapRef.current.addImage('publicAdminIcon', publicAdminIcon)
    mapRef.current.addImage('publicBenefitIcon', publicBenefitIcon)
    mapRef.current.addImage('recreationRouteIcon', recreationRouteIcon)
    mapRef.current.addImage('sportsIcon', sportsIcon)
    mapRef.current.addImage('touristIcon', touristIcon)
    mapRef.current.addImage('waterIcon', waterIcon)
    mapRef.current.addImage('publicTransportIcon', publicTransportIcon)
    mapRef.current.addImage('renewableEnergyIcon', renewableEnergyIcon)
    mapRef.current.addImage('emergencyServicesIcon', emergencyServicesIcon)

    // Add layer with conditional 'icon-image'
    mapRef.current.addLayer({
      id: 'poi-markers',
      type: 'symbol',
      source: 'poi-points',
      layout: {
        'icon-image': [
          'match',
          ['get', 'category'],
          'Aircraft landing site', 'aircraftIcon',
          'Bank / currency exchange / money lender', 'bankIcon',
          'Business', 'businessIcon',
          'Civil society / public benefit', 'publicBenefitIcon',
          'Communication / electricity infrastructure', 'electricityIcon',
          'Cultural / historic heritage', 'heritageIcon',
          'Education centre', 'educationCentreIcon',
          'Fishing', 'fishingIcon',
          'Grazing / Farmland', 'farmlandIcon',
          'Health / medical centre', 'medicalIcon',
          'Informal economy / marketplace', 'marketplaceIcon',
          'Land & housing', 'landHousingIcon',
          'Library', 'libraryIcon',
          'Nature reserve / conservation', 'conservationIcon',
          'Place of worship', 'worshipIcon',
          'Public / emergency service', 'emergencyServicesIcon',
          'Public access / recreation route', 'recreationRouteIcon',
          'Public administration centre', 'publicAdminIcon',
          'Public transport', 'publicTransportIcon',
          'Renewable energy generation', 'renewableEnergyIcon',
          'Sports & Recreation', 'sportsIcon',
          'Tourist Interest', 'touristIcon',
          'Traditional / community administration', 'communityAdminIcon',
          'Water source', 'waterIcon',
          'Other', 'otherIcon',
          'defaultIcon',
        ],
        'icon-size': 0.8,
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
    })
  }

  loadIconsAndLayer()
}

/**
 * Display area map popup
 *
 * @param community -> Neighbourhood/Community object
 * @param mapRef -> React ref to mapbox map
 * @param coordinates -> Coordintates of clicked location
 * @param hasStakeholders -> Whether the community has been used for any stakeholder
 * @param hasFlashpoints -> Whether any flashpoint has been recorded in this area
 * @param onClick -> Onclick view (When the user wants to display the area profile)
 * @returns A mapbox popup object
 */
export const displayCommunityPopup = (
  community: Community,
  mapRef: React.RefObject<mapboxgl.Map>,
  coordinates: mapboxgl.LngLat,
  hasStakeholders: boolean,
  hasFlashpoints: boolean,
  hasEngagements: boolean,
  hasIssues: boolean,
  onClick: () => void,
) : mapboxgl.Popup => {
  const placeholder = document.createElement('div')

  const component = (
    <CommunityPopup
      community={community}
      hasFlashpoints={hasFlashpoints}
      hasStakeholders={hasStakeholders}
      hasEngagements={hasEngagements}
      hasIssues={hasIssues}
      onClick={onClick}
    />
  )
  ReactDOM.render(component, placeholder)

  return new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setDOMContent(placeholder)
    .addTo(mapRef.current)
}

export const createAssetLayers = (
  mapRef: React.RefObject<mapboxgl.Map>,
  assets: any[] = [],
) => {
  mapRef.current.addSource('assets-lines', {
    type: 'geojson',
    data: [],
  })

  mapRef.current.addSource('assets-polygons', {
    type: 'geojson',
    data: [],
  })

  mapRef.current.addLayer({
    id: 'assets-lines-layer',
    type: 'line',
    source: 'assets-lines',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#8E151F',
      'line-width': 4,
    },
  })

  mapRef.current.addLayer({
    id: 'assets-polygon-layer',
    type: 'fill',
    source: 'assets-polygons',
    paint: {
      'fill-color': 'transparent', // Transparent fill for polygons
      'fill-opacity': 0, // Ensuring fill is fully transparent
    },
  })

  mapRef.current.addLayer({
    id: 'assets-polygon-line-layer',
    source: 'assets-polygons',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#8E151F',
      'line-width': 2,
    },
  })
}
