import * as React from 'react'
import { IonRow, IonCol } from '@ionic/react'
import { SettingField } from '../../settings/StyledContainers'
import { RiskAssessment } from '../types/RiskAssessments'

/**
 *  ===============================
 *      Page 1: RISK SUMMARY
 *  ===============================
 */
const RiskSummary : React.FC<{ risk: RiskAssessment }> = ({ risk }) => (
  <IonRow>
    <IonCol size='6'>
      <SettingField style={{ width: 'auto' }}>
        <h5 className='blue' style={{ marginBottom: '15px' }}>Inherent Risk Assessment - Summary</h5>
        <h5>Thread Rating</h5>
        <p id='risk-overview-threat-rating'>{ risk.threatRating }</p>
        <h5 style={{ marginTop: '15px' }}>Likelihood Rating</h5>
        <p id='risk-overview-inherent-likelihood'>{ risk.inherentThreatLikelihood }</p>
        <h5 style={{ marginTop: '15px' }}>Impact Rating</h5>
        <p id='risk-overview-inherent-impact'>{ risk.impact }
        </p>
        <h5 style={{ marginTop: '15px' }}>Inherent Risk Rating</h5>
        <p id='risk-overview-inherent-risk'>{ risk.riskRating }</p>
      </SettingField>
    </IonCol>
    <IonCol size='6'>
      <SettingField style={{ width: 'auto' }}>
        <h5 className='blue' style={{ marginBottom: '15px' }}>Residual Risk Assessment - Summary</h5>
        <h5>Thread Rating</h5>
        <p>{ risk.threatRating }</p>
        <h5 style={{ marginTop: '15px' }}>Likelihood Rating</h5>
        <p id='risk-overview-residual-likelihood'>{ risk.residualThreatLikelihood }</p>
        <h5 style={{ marginTop: '15px' }}>Impact Rating</h5>
        <p id='risk-overview-residual-impact'>{ risk.residualImpact }
        </p>
        <h5 style={{ marginTop: '15px' }}>Residual Risk Rating</h5>
        <p id='risk-overview-residual-risk'>{ risk.residualRisk }</p>
      </SettingField>
    </IonCol>
  </IonRow>
)

export default RiskSummary
