import * as React from 'react'
import { useEffect } from 'react'
import ReportsHome from './ReportsHome'
import FieldSitreps from './FieldSitreps'
import SecurityReports from './SecurityReports'
import WeeklyReports from './WeeklyReports'
import Incidents from './Incidents'
import { useReportsData } from '../../hooks/reports/useReportsData'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'

const AppWrapper : React.FC = () => {
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  const {
    setIncidents,
    showSitreps,
    showReports,
    showHome,
    setInsights,
    showWeeklys,
    setDates,
    showInternalIncidents,
    setInternalIncidents,
  } = useReportsData()

  useEffect(() => {
    const get = apiHook.getIncidentsForDomain({
      domain_id: workspace.id,
    })
      .then((data) => {
        setIncidents(data.incidents)
      })
    const req = apiHook.getInsightsForDomain({
      domain_id: workspace.id,
    })
      .then((data) => {
        setInsights(data.insights)
      })
    const req2 = apiHook.weeklyDates()
      .then((data) => {
        setDates(data.dates)
      })
    const req3 = apiHook.fetchInternalIncidents({
      domain_id: workspace.id,
    })
      .then((data) => {
        setInternalIncidents(data.archived)
        console.log(data.incidents)
      })
  }, [])

  return (
    <>
      {
        showHome && (
          <ReportsHome onReturn={null} />
        )
      }
      {
        showSitreps && (
          <FieldSitreps onReturn={null} />
        )
      }
      {
        showReports && (
          <SecurityReports onReturn={null} />
        )
      }
      {
        showWeeklys && (
          <WeeklyReports />
        )
      }
      {
        showInternalIncidents && (
          <Incidents />
        )
      }

    </>
  )
}

export default AppWrapper
