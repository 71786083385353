/* eslint-disable max-len */

import { ReliefRequestParams } from '../types/ArticleTypes'
import { Workspace } from '../types/GlobalTypes'

/**
 * Get the country code that GDELT uses in their API
 *
 * @param countryCode -> Country code in Sherpa
 * @returns Country code according to GDELT's API
 */
export const translateToGDELTCountryCode = (countryCode: string) : string => {
  if (countryCode === 'CM' || countryCode === 'ET' || countryCode === 'PK' || countryCode === 'MZ' || countryCode === 'SA' || countryCode === 'OM') { return countryCode }
  if (countryCode === 'DRC') { return 'CD' }
  if (countryCode === 'UK') { return 'GB' }
  if (countryCode === 'TZA') { return 'TZ' }
  if (countryCode === 'CAN') { return 'CA' }

  return 'OD'
}
/**
 * Request news articles depending on the workspace's country
 *
 * @param workspace -> Current Workspace
 * @returns An API endpoint to request news articles
 */
export const getGDELTReqURL = (workspace: Workspace) : string => {
  if (workspace.power === 'God' || workspace.power === 'ArchAngel') {
    return 'https://api.gdeltproject.org/api/v2/doc/doc?query=%20(sourcecountry:OD%20OR%20sourcecountry:CM%20OR%20sourcecountry:ET%20OR%20sourcecountry:PK%20OR%20sourcecountry:CG%20OR%20sourcecountry:MZ)%20sourcelang:eng&mode=ArtList&maxrecords=75&timespan=2d&format=json'
  }

  return `https://api.gdeltproject.org/api/v2/doc/doc?query=%20sourcecountry:${translateToGDELTCountryCode(workspace.country)}%20sourcelang:eng&mode=ArtList&maxrecords=75&timespan=2d&format=json`
}

/**
 * Get full country name from code
 *
 * @param countryCode -> Workspace country code
 * @returns Workspace country name
 */
export const getCountryName = (countryCode: string) : string => (
  {
    CM: 'Cameroon',
    ET: 'Ethiopia',
    PK: 'Pakistan',
    SS: 'South Sudan',
    DRC: 'DRC',
    MZ: 'Mozambique',
    UK: 'UK',
    TZA: 'Tanzania',
    CAN: 'Canada',
    SA: 'Saudi Arabia',
    OM: 'Oman',
  }[countryCode] as string
)

/**
 * Get countries to request data about
 *
 * @param workspace -> Current workspace
 * @returns Country or list of countries that will be added to the request
 */
export const getReliefCountries = (workspace: Workspace) : (string | string[]) => {
  if (workspace.power === 'God' || workspace.power === 'ArchAngel') {
    return ['South Sudan', 'Cameroon', 'Ethiopia', 'Pakistan', 'Mozambique',
      'Democratic Republic of the Congo', 'United Kingdom',
      'Tanzania', 'Canada', 'Saudi Arabia', 'Oman',
    ]
  }

  if (!getCountryName(workspace.country)) {
    return 'South Sudan'
  }

  return getCountryName(workspace.country)
}

/**
 * Get request body for relief API request
 *
 * @param workspace -> Current workspace
 * @returns Relief request data
 */
export const getReliefReqOptions = (workspace: Workspace) : ReliefRequestParams => {
  const countries : (string | string[]) = getReliefCountries(workspace)

  return {
    limit: 10,
    preset: 'latest',
    fields: {
      include: ['url', 'source.name', 'date.original', 'file.preview.url-small'],
    },
    filter: {
      operator: 'AND',
      conditions: [
        {
          field: 'country',
          value: countries,
          operator: 'OR',
        },
        {
          field: 'file',
        },
      ],
    },
  }
}
