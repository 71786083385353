import {
  Community, EngagementCampaign, Flashpoint, InternalProject, RecipientList, Stakeholder, StakeholderChat, StakeholderGroup, StakeholderMessage,
} from '../../../components/types/OptimizedMaps'

export enum AppView {
  HOME = 'home',
  STAKEHOLDERS_SCREEN = 'stakeholders',
  MESSAGING_SCREEN = 'messaging',
  CAMPAIGN_SCREEN = 'campaigns',
  NOTICE_BOARD = 'notice_board',
  ENGAGEMENT_SCREEN = 'engagement',
}

export interface HookInterface {
  view: AppView,
  handleViewChange: (newView: AppView) => void,
  communities: Community[],
  flashpoints: Flashpoint[],
  stakeholders: Stakeholder[],
  updateStakeholder: (stakeholder: Stakeholder) => Stakeholder[],
  fetchAppData: () => void,
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  submittedMessage: null | string,
  setSubmittedMessage: React.Dispatch<React.SetStateAction<null | string>>,
  projects: InternalProject[],
  updateFlashpoint: (flashpoint: Flashpoint) => void,
  updateProject: (project: InternalProject) => void,
  stakeholderGroups: StakeholderGroup[],
  pushNewStakeholder: (newStakeholder: Stakeholder) => void,
  campaigns: EngagementCampaign[],
  pushNewCampaign: (campaing: EngagementCampaign) => void,
  recipientLists: RecipientList[],
  pushNewRecipientLists: (lists: RecipientList[]) => void,
  updateRecipientList: (edited: RecipientList) => void,
  deleteRecipientList: (list: RecipientList) => void,
  deleteCampaign: (campaign: EngagementCampaign) => void,
  stakeholderDMs: StakeholderChat[],
  pushNewStakeholderDM: (newChat: StakeholderChat) => void,
  pushLatestMessage: (message: StakeholderMessage, room: StakeholderChat) => void,
}
