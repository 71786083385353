import { IonButton, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { OverlayContainer } from '../../../GlobalContainers'

interface Props {
  onClose: () => void,
  onSubmit: () => void
}

const LeaveRoom : FC<Props> = ({ onClose, onSubmit }) => (
  <OverlayContainer>
    <h4>Leave Group</h4>
    <p>Are you sure you wish to leave this group?</p>
    <IonRow className='ion-justify-content-end'>
      <IonButton onClick={onClose} style={{ '--background': '#8E151F', color: 'white' }}>Cancel</IonButton>
      <IonButton onClick={onSubmit} style={{ '--background': '#326771', color: 'white' }}>Confirm</IonButton>
    </IonRow>
  </OverlayContainer>
)

export default LeaveRoom
