import { IonCol, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { getPoILocation } from '../../../hooks/terrain-mapping/helpers/Utils'
import { AppView } from '../../../hooks/terrain-mapping/types/HookInterface'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { BlueButton } from '../../incident-management/StyledContainers'
import { TerrainPOI } from '../../types/OptimizedMaps'
import { getColor } from './FlashpointList'

const POIList : FC = () => {
  const {
    poiList, mapRef, dispatchFocused, handleViewChange,
  } = useTerrainMapping()

  const viewPin = (PoI: TerrainPOI) => {
    const { lat, lng } = getPoILocation(PoI)
    dispatchFocused({ type: FocusedActionKind.SELECT_POI, poi: PoI })
    handleViewChange(AppView.TERRAIN_MAP)
    mapRef.current.easeTo({ center: [lng, lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() })
  }

  return (
    <ListContainer style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow style={{ padding: '10px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Name</h5>
          </IonCol>
          <IonCol size='4'>
            <h5>Description</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Category</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Type</h5>
          </IonCol>
          <IonCol size='2' />
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            poiList.map((val, index) => (
              <IonRow
                className='ion-justify-content-between terrain-register-row ion-align-items-center'
                style={{ backgroundColor: getColor(index) }}
                key={val.id}
              >
                <IonCol size='2'>{ val.name }</IonCol>
                <IonCol size='4'>{ val.description }</IonCol>
                <IonCol size='2'>{ val.category }</IonCol>
                <IonCol size='2'>{ val.type }</IonCol>
                <IonCol size='2'><BlueButton onClick={() => viewPin(val)}>View</BlueButton></IonCol>
              </IonRow>
            ))
          }
          {
            poiList.length === 0 && (
              <p style={{ textAlign: 'center' }}>No Points of Interest available</p>
            )
          }
        </div>
      </div>
    </ListContainer>
  )
}

export default POIList
