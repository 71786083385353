import styled from 'styled-components'

const SettingsContainer = styled.div`
  padding: 20px;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2.5px;
`

const TabBarContainer = styled.div`
  background: #F2F2F2;
  height: 100%;
`

const ViewMarker = styled.div`
  height: 100%;
  width: 10px;
  background-color: #8E151F;
`

const SettingField = styled.div`
  margin: 10px;
  width: 100%;

  & h4 {
    color: #8E151F;
    font-size: 1.2rem;
    font-weight: bold;
  }

  & h4.blue {
    color: #326771;
  }

  & h5.blue {
    color: #326771;
  }

  & h5 {
    color: #747474;
    font-size: 16px;
    font-weight: bold;
  }

  & p{
    color: #747474;
  }

  & p span.highlighted-blue {
    color: #326771;
  }

  &.text-no-margin p {
    margin: 0;
  }

  & h6.blue {
    font-size: 13px;
    color: #4197A9;
  }
`

const UserCard = styled.div`
  background-color: #326771;
  color: white;
  margin: 5px;
  border-radius: 5px;
  padding: 2px 5px;
  display: flex;
  align-items: center;

  & button {
    font-size: 1rem !important;
  }
`

const UsersContainer = styled.div`
  height: 90px;
  overflow-y: auto;
`

const SettingTitle = styled.h3`
  color: #8E151F;
  border-bottom: 1px solid #747474;
  font-size: 1.2rem;
  margin-left: 20px;
  padding: 10px;
`

const DomainUsersContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

  & .title-container h5{
    color: #8E151F;
    margin-bottom: 0;
  }

  & .title-container {
    padding-top: 10px;
    border-bottom: 1px solid #747474;
  }

  & input.search-bar {
    padding: 5px;
    border: 1px solid #747474;
    border-radius: 2.5px;
    width: 40%;
  }

  & .workspace-member {
    padding: 10px 5px;
  }

  & .workspace-member h6 {
    color: black;
    font-size: 1rem;
    margin-bottom: 5px;
  }

  & .workspace-member p {
    color: #747474;
    font-size: 0.8rem;
    margin: 0;
  }

  & h6 {
    color: #326771;
    margin: 0;
  }
`
const InvitationContainer = styled.button`
  border-radius: 2.5px;
  border: 1px solid #747474;
  background-color: white;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  text-align: left;

  & h6 {
    color: #326771;
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: bold;
  }

  & p {
    font-size: 0.8rem;
    color: black;
  }
`

const SelectRole = styled.select`
  border-radius: 2.5px;
  border: 1px solid #747474;
  padding: 5px;
  color: #747474;
  margin: 20px 0;
  width: 30%;
`

export {
  SettingsContainer,
  TabBarContainer,
  ViewMarker,
  SettingField,
  UserCard,
  UsersContainer,
  SettingTitle,
  DomainUsersContainer,
  InvitationContainer,
  SelectRole,
}
