import React, { useState } from 'react'
import {
  IonGrid, IonRow, IonPage, IonSpinner,
} from '@ionic/react'

import PageHeader from '../components/PageHeader'
import NewsFeed from '../components/dashboard/NewsFeed'
import Widgets from '../components/dashboard/Widgets'
import { ProvideWeeklyReport } from '../hooks/insights/useWeeklyReport'
import { CenterLoader, ObscureBackground } from '../components/GlobalContainers'
import AddImage from '../components/incident-management/views/popups/AddImage'

const Dashboard = () => {
  const [showFilesPopup, setShowFilesPopup] = useState(false)
  const [files, setFiles] = useState(null)
  const [requestLoading, setRequestLoading] = useState(false)

  const handleSubmitImages = (selected) => {
    setFiles(selected)
    setShowFilesPopup(false)
  }

  return (
    <ProvideWeeklyReport>
      <IonPage>
        <PageHeader title='Home' />
        <IonGrid
          className='dashboard-grid-container'
          style={{
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            padding: '20px 30px',
            backgroundColor: '#E5E5E5',
          }}
        >
          {
            requestLoading && (
              <>
                <ObscureBackground style={{ zIndex: '9' }} />
                <CenterLoader style={{ zIndex: '10' }}>
                  <IonSpinner color='light' />
                </CenterLoader>
              </>
            )
          }
          {
            showFilesPopup && (
              <>
                <ObscureBackground style={{ zIndex: 15 }} />
                <AddImage
                  onClose={() => setShowFilesPopup(false)}
                  onSubmit={handleSubmitImages}
                />
              </>
            )
          }
          <IonRow
            style={{
              height: '100%',
            }}
          >
            <NewsFeed
              files={files}
              cleanFiles={() => setFiles(null)}
              onShowPopup={() => setShowFilesPopup(true)}
              setRequestLoading={setRequestLoading}
            />
            <Widgets />
          </IonRow>
        </IonGrid>
      </IonPage>
    </ProvideWeeklyReport>
  )
}
export default Dashboard
