import { IonCol, IonRow } from '@ionic/react'
import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Icon } from '@iconify/react'
import * as moment from 'moment'
import { useInsightsData } from '../../../hooks/insights/useInsightsData'
import { SimpleButton } from '../../maps/StyledContainers'
import { BlueButton } from '../../incident-management/StyledContainers'
import { getColor, getSafetyColor } from '../../maps/apps/AppMap'

import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { SafetyUser } from '../../types/OptimizedMaps'

/* Popup that shows current states of user's safety */
const MapPopup : React.FC = () => {
  const [show, setShow] = React.useState<boolean>(false)
  const [sortAscending, setSortAscending] = React.useState<boolean>(true)
  const apiHook = useApi()
  const { workspace } = useWorkspace()
  /* Get hooks data about users' safety in current workspace */
  const { workspaceUsers } = useInsightsData()
  const onToggleHandler = (isOpen : boolean, metadata: { source: string }) => {
    if (metadata.source !== 'select') {
      setShow(isOpen)
    }
  }

  const getSorted = () : SafetyUser[] => {
    if (sortAscending) {
      return workspaceUsers.sort((userA, userB) => {
        if (`${userA.first_name} ${userA.final_name}` > `${userB.first_name} ${userB.final_name}`) {
          return 1
        }
        return -1
      })
    }
    return workspaceUsers.sort((userA, userB) => {
      if (`${userB.first_name} ${userB.final_name}` > `${userA.first_name} ${userA.final_name}`) {
        return 1
      }
      return -1
    })
  }

  const promptAll = () => {
    apiHook.promptAllSafeties(workspace.id)
  }

  const promptUser = (id: number) => {
    apiHook.promptOneSafety(id, workspace.id)
  }

  const getArrow = () : JSX.Element => ((sortAscending) ? (
    <Icon icon='fluent:arrow-sort-down-16-filled' />
  ) : (
    <Icon icon='fluent:arrow-sort-up-16-filled' />
  ))

  return (
    <Dropdown align='start' style={{ margin: '0 10px' }} show={show} onToggle={(isOpen : boolean, metadata : { source: string }) => onToggleHandler(isOpen, metadata)}>
      <Dropdown.Toggle style={{ backgroundColor: '#326771' }} id='toggle-safety-checks'> <Icon icon='charm:menu-hamburger' /> User List</Dropdown.Toggle>
      <Dropdown.Menu style={{
        width: '400px', maxHeight: '500px', overflowY: 'auto', paddingTop: 0,
      }}
      >
        <IonRow className='ion-align-items-center sticky-table-title' style={{ color: '#326771', padding: '10px 0', paddingLeft: '20px' }}>
          <IonCol size='6'>
            <SimpleButton style={{ padding: '0' }} onClick={() => setSortAscending(!sortAscending)}>
              Last Updated { getArrow() }
            </SimpleButton>
          </IonCol>
          <IonCol size='6'>
            <BlueButton onClick={() => promptAll()}>Request Update from All</BlueButton>
          </IonCol>
        </IonRow>
        {
          getSorted().map((user, index) => (
            <IonRow key={user.id} style={{ backgroundColor: getColor(index), padding: '10px 20px' }} className='ion-align-items'>
              <IonCol size='6'>
                <p> <Icon icon='bxs:user' /> {user.first_name} {user.final_name}</p>
                <p> <Icon icon='ci:location' /> {user.safety.county.name} </p>
              </IonCol>
              <IonCol size='6'>
                <IonRow className='ion-align-items-center ion-justify-content-end'>
                  <p
                    className='alert-state-display'
                    style={{
                      backgroundColor: getSafetyColor(user.safety.level).background, color: 'white', fontSize: '.8rem', textAlign: 'right',
                    }}
                  > { getSafetyColor(user.safety.level).title }
                  </p>
                </IonRow>
                <p style={{ textAlign: 'right', margin: '10px 0' }}>
                  <Icon icon='ant-design:calendar-filled' /> { (user.safety.created_at === 'N/A') ? 'N/A' : moment(user.safety.created_at).format('DD/MM/YY') }
                </p>
                <IonRow className='ion-justify-content-end'>
                  <BlueButton onClick={() => promptUser(user.id)}>Request Update</BlueButton>
                </IonRow>
              </IonCol>
            </IonRow>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MapPopup
