/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton, IonCol, IonRow, useIonAlert } from '@ionic/react'
import React, { FC, useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import { FlashpointStatus, IncidentCause } from '../../types/GlobalTypes'
import { Flashpoint } from '../../types/OptimizedMaps'
import { SimpleButton } from '../StyledContainers'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

export interface Details {
  title: string,
  description: string,
  triggers: string,
  status?: (FlashpointStatus | ''),
  ongoing?: 'Yes' | 'No',
  relevantAreas: { value: number, label: string }[],
  primaryStakeholders: { value: number, label: string }[],
  secondaryStakeholders: { value: number, label: string }[],
  tertiaryStakeholders: { value: number, label: string }[],
  comments: string,
  primaryManifestation: string,
  additionalManifestations: string[],
  assets: { value: string, label: string }[],
}

const FlashpointForm : FC = () => {
  const [input, setInput] = useState<Details>({
    title: '',
    description: '',
    triggers: '',
    relevantAreas: [],
    comments: '',
    primaryManifestation: '',
    additionalManifestations: [''],
    primaryStakeholders: [],
    secondaryStakeholders: [],
    tertiaryStakeholders: [],
    status: '',
    assets: [],
  })
  const { workspace } = useWorkspace()
  const [issues, setIssues] = useState<(IncidentCause & { selected: boolean })[]>([])
  const {
    causes, communities, pushNewFlashpoint, stakeholders, manifestations,
    setShowFlashpointForm, setSubmittedMessage, setLoading, assets,
  } = useTerrainMapping()
  const [ionAlert] = useIonAlert()

  const updateAdditionalManifest = (newVal: string, index: number) => {
    const copy = input.additionalManifestations.slice()
    copy.splice(index, 1, newVal)

    setInput({ ...input, additionalManifestations: copy })
  }

  const addManifestation = () => {
    const copy = input.additionalManifestations.slice()
    copy.push('')

    setInput({ ...input, additionalManifestations: copy })
  }

  const selectCause = (index: number) => {
    const copy = issues.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setIssues(copy)
  }

  const errorMessages = {
    title: 'You need to provide a title for this flashpoint',
    description: 'No description provided',
    triggers: 'You must specify what triggers this flashpoint',
    status: 'No status selected',
    primaryManifestation: 'The primary manifestation is required',
    ongoing: 'Is the situation ongoing/seasonal?',
    assets: 'You must specify the associated asset(s)',
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const notFilled = keys.find((val) => input[val] === '' || !input[val])

    if (notFilled) {
      ionAlert({
        header: 'Error',
        message: errorMessages[notFilled],
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    const selectedIssues = issues.filter((val) => val.selected)

    setLoading(true)
    axios.post('api/v2/flashpoint/create_flashpoint', {
      domain_id: workspace.id,
      title: input.title,
      description: input.description,
      triggers: input.triggers,
      status: input.status,
      primary_manifestation: input.primaryManifestation,
      ongoing: input.ongoing === 'Yes',
      comments: input.comments,
      primary_stakeholders: input.primaryStakeholders.map(({ value }) => value),
      secondary_stakeholders: input.secondaryStakeholders.map(({ value }) => value),
      tertiary_stakeholders: input.tertiaryStakeholders.map(({ value }) => value),
      manifestations: input.additionalManifestations.filter((val) => val !== ''),
      issues: selectedIssues.map(({ id }) => id),
      community: input.relevantAreas.map(({ value }) => value),
      assets: input.assets.map(({ value }) => value),
    }).then(({ data }) => {
      if (data.message === 'you have not created a flashpoint.') {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }

      const stakeholdersPrimary = stakeholders.filter(({ id }) => input.primaryStakeholders.find((val) => val.value === id))
      const stakeholdersSecondary = stakeholders.filter(({ id }) => input.secondaryStakeholders.find((val) => val.value === id))
      const stakeholdersTertiary = stakeholders.filter(({ id }) => input.tertiaryStakeholders.find((val) => val.value === id))

      const newFlashpoint : Flashpoint = {
        id: data.flashpoint.id,
        title: input.title,
        description: input.description,
        triggers: input.triggers,
        status: input.status as FlashpointStatus,
        primaryManifestation: input.primaryManifestation,
        additionalManifestation: input.additionalManifestations.map((val, index) => ({ id: index, name: val })),
        ongoing: (input.ongoing === 'Yes'),
        issues: selectedIssues,
        primary_groups: stakeholdersPrimary,
        secondary_groups: stakeholdersSecondary,
        tertiary_groups: stakeholdersTertiary,
        relevant_areas: input.relevantAreas.map(({ value, label }) => ({ id: value, name: label })),
        comments: input.comments,
      }
      setSubmittedMessage('Flashpoint submitted succesfully')
      pushNewFlashpoint(newFlashpoint)
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setLoading(false)
      setShowFlashpointForm(false)
    })
  }

  useEffect(() => {
    setIssues(causes.map((val) => ({ ...val, selected: false })))
  }, [])

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <OverlayContainer style={{ zIndex: 25, width: '90%', height: '90%', borderRadius: '1em' }} className='terrain-add-popup'>
        <IonRow className='ion-justify-content-end'>
          <SimpleButton onClick={() => setShowFlashpointForm(false) }>Close X</SimpleButton>
        </IonRow>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <h5 style={{ marginLeft: '2em', fontSize: 16, color: '#326771' }}>Add Flashpoint</h5>
        </IonRow>
        <div style={{ height: '73%', overflow: 'auto' }}>
          <IonRow style={{ marginLeft: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Title</h5>
              <input placeholder='Give this flashpoint a title' type='text' value={input.title} onChange={(e) => setInput({ ...input, title: e.target.value })} />
            </IonCol>
          </IonRow>
          <div className='terrain-form-field'>
            <h6>Associated Asset</h6>
            <CreatableSelect
              isMulti
              placeholder='Select Assets'
              name='colors'
              className='select-container'
              id='journey-passengers'
            // eslint-disable-next-line no-undef
              menuPortalTarget={document.body}
              options={assets.map(({ id, name }) => ({ label: name, value: id }))}
              value={input.assets}
              onChange={(selected) => setInput({ ...input, assets: selected })}
            />
          </div>
          <IonRow style={{ marginLeft: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Description</h5>
              <textarea
                style={{ height: '10em', width: '100%' }}
                value={input.description}
                onChange={(e) => setInput({ ...input, description: e.target.value })}
                placeholder='Enter Description'
              />
            </IonCol>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Triggers</h5>
              <textarea
                style={{ height: '10em', width: '100%' }}
                value={input.triggers}
                onChange={(e) => setInput({ ...input, triggers: e.target.value })}
                placeholder='Enter what triggers the flashpoint'
              />
            </IonCol>
          </IonRow>
          <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Status</h5>
              <select
                value={input.status}
                onChange={(e) => setInput({ ...input, status: e.target.value as FlashpointStatus })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select Status</option>
                <option value='low'>Low</option>
                <option value='guarded'>Guarded</option>
                <option value='elevated'>Elevated</option>
                <option value='high'>High</option>
                <option value='severe'>Severe</option>
              </select>
            </IonCol>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Is this ongoing/seasonal?</h5>
              <IonRow>
                <label style={{ textAlign: 'center', marginRight: '10px' }}>
                  <input
                    type='radio'
                    value='yes'
                    checked={input.ongoing === 'Yes'}
                    onChange={() => setInput({ ...input, ongoing: 'Yes' })}
                  />
                  Yes
                </label>
                <label style={{ textAlign: 'center' }}>
                  <input
                    type='radio'
                    value='No'
                    checked={input.ongoing === 'No'}
                    onChange={() => setInput({ ...input, ongoing: 'No' })}
                  />
                  No
                </label>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Manifestation</h5>
              <select
                value={input.primaryManifestation}
                onChange={(e) => setInput({ ...input, primaryManifestation: e.target.value as FlashpointStatus })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select manifestation</option>
                {
                  manifestations?.map((val) => (
                    <option key={val} value={val}>{ val }</option>
                  ))
                }
              </select>
            </IonCol>
            <IonCol>
              {
                input.additionalManifestations.map((val, index) => (
                  <div key={index} className='terrain-form-field'>
                    <h6>Additional Manifestation (optional)</h6>
                    <select
                      value={val}
                      onChange={(e) => updateAdditionalManifest(e.target.value, index)}
                      style={{ padding: '5px 10px' }}
                    >
                      <option value='' disabled>Select manifestation</option>
                      {
                        manifestations?.map((manifestation) => (
                          <option key={manifestation} value={manifestation}>{ manifestation }</option>
                        ))
                      }
                    </select>
                  </div>
                ))
              }
              <SimpleButton style={{ fontSize: '0.8rem' }} onClick={addManifestation}>Add Additional Manifestation +</SimpleButton>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Proximate Issues</h5>
              <>
                {
                  issues.map((val, index) => (
                    <TagButton
                      key={val.id}
                      onClick={() => selectCause(index)}
                      style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                    >
                      { val.name }
                    </TagButton>
                  ))
                }
              </>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Primary Associated Stakeholders</h5>
              <CreatableSelect
                isMulti
                placeholder='Select Stakeholders'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                value={input.primaryStakeholders}
                onChange={(selected) => setInput({ ...input, primaryStakeholders: selected })}
              />
            </IonCol>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Secondary Associated Stakeholders</h5>
              <CreatableSelect
                isMulti
                placeholder='Select Stakeholders'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                value={input.secondaryStakeholders}
                onChange={(selected) => setInput({ ...input, secondaryStakeholders: selected })}
              />
            </IonCol>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Tertiary Associated Stakeholders</h5>
              <CreatableSelect
                isMulti
                placeholder='Select Stakeholders'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                value={input.tertiaryStakeholders}
                onChange={(selected) => setInput({ ...input, tertiaryStakeholders: selected })}
              />
            </IonCol>
          </IonRow>
          <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Relevant Areas</h5>
              <CreatableSelect
                isMulti
                placeholder='Select Areas'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                value={input.relevantAreas}
                onChange={(selected) => setInput({ ...input, relevantAreas: selected })}
              />
            </IonCol>
            <IonCol />
            <IonCol />
          </IonRow>
          <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
            <IonCol>
              <h5 style={{ color: '#326771', fontSize: 16, marginBottom: '4px' }}>Additional Comments</h5>
              <textarea
                style={{ width: '100%', height: '59%', fontSize: 13, color: '#737475', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                value={input.comments}
                onChange={(e) => setInput({ ...input, comments: e.target.value })}
                placeholder='Enter comments'
              />
            </IonCol>
          </IonRow>
        </div>
        <IonRow className='ion-justify-content-end'>
          <IonButton onClick={handleSubmit} style={{ '--background': '#326771' }}>Save</IonButton>
          <IonButton onClick={() => setShowFlashpointForm(false)} style={{ '--background': '#326771' }}>Cancel</IonButton>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default FlashpointForm
