import { Icon } from '@iconify/react'
import {
  IonButton, IonInput, IonRow,
} from '@ionic/react'
import * as React from 'react'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { useAuth } from '../../../../hooks/useAuth'
import ChatCard from '../../../messaging/ChatCard'
import { TabBarContainer } from '../../../messaging/StyledContainers'

interface Props {
  onAddUser: () => void
}
const TabBarChats : React.FC<Props> = ({ onAddUser }) => {
  const [searchText, setSearchText] = React.useState<string>('')
  const {
    chatRooms, setFocusedRoom, focusedRoom,
  } = useIncidentsData()
  const { user } = useAuth()

  return (
    <TabBarContainer style={{ maxHeight: '100%', overflow: 'auto' }}>
      <div style={{ padding: '10px', margin: '30px 15px' }}>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <h3 className='chat-title'>Chat</h3>
          <IonButton
            fill='clear'
            class='ion-justify-content-end'
            onClick={() => {
              onAddUser()
            }}
          >
            <Icon type='button' icon='entypo:new-message' color='#326771' style={{ fontSize: '1.5em' }} />
          </IonButton>
        </IonRow>
        <IonInput
          type='text'
          placeholder='Search Messages'
          className='chats-input-field'
          name='name'
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value)}
        />
      </div>
      {
        chatRooms.length === 0 ? (
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3 style={{ textAlign: 'center' }}>No Current Conversations</h3>
            <p>Your conversations will appear here. </p>
          </div>
        ) : (
          <div style={{ height: '100%', overflowY: 'auto' }}>
            {
              chatRooms.map((val) => (
                <ChatCard userId={user.user_id} key={val.id} {...val} onClick={() => setFocusedRoom({ ...val })} selected={focusedRoom && focusedRoom.id === val.id} />
              ))
            }
          </div>
        )
      }
    </TabBarContainer>
  )
}

export default TabBarChats
