export interface AlertStateFilters {
  showIncidents: boolean,
  showAssets: boolean,
  showRoutes: boolean,
  showPulsepoints: boolean,
}

export enum AsFilterUpdate {
  SHOW_INCIDENTS = 'showIncidents',
  SHOW_ROUTES = 'showRoutes',
  SHOW_ASSETS = 'showAssets',
  SHOW_PULSEPOINTS = 'showPulsepoints',
}

export const defaultASFilters : AlertStateFilters = {
  showAssets: false,
  showIncidents: false,
  showRoutes: false,
  showPulsepoints: false,
}

export enum InsightViews {
  INCIDENTS = 'Incidents',
  ALERT_STATES = 'Alert States',
  TEAM_SAFETY = 'Safety Checks',
}
