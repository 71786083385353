import {
  IonGrid, IonRow,
} from '@ionic/react'
import React from 'react'

import AccountSettings from './account/AccountSettings'
import AppSettings from './app/AppSettings'
import WorkspaceSettings from './workspace/WorkspaceSettings'

/**
 *  =======================
 *       Setting Pages
 *  =======================
 */

const TabContent = ({
  view, userDetails, domainDetails, users,
  addDomain, setWorkspace, updateDomain, setLoading,
}) => (
  <>
    <IonGrid style={{ height: '100%' }}>
      <IonRow style={{ height: '100%' }}>
        {
          view === 'account' && (
            <AccountSettings userDetails={userDetails} setLoading={setLoading} />
          )
        }
        {
          view === 'workspace' && (
            <WorkspaceSettings
              domainDetails={domainDetails}
              users={users}
              addDomain={addDomain}
              setDomain={setWorkspace}
              updateDomain={updateDomain}
            />
          )
        }
        {
          view === 'app' && (
            <AppSettings />
          )
        }
      </IonRow>
    </IonGrid>
  </>
)

export default TabContent
