import styled from 'styled-components'

const WidgetContainer = styled.div`
  border-radius: 5px;
  background-color: #326771;
  padding: 10px;
  margin-bottom: 20px;

  & h4 {
    font-size: 1.2rem;
  }

  & h5 {
    font-size: 1rem;
    color: #326771;
  }

  & button{
    font-size: .8rem;
  }
`

const WidgetElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;

  & p {
    font-size: .8rem;
    margin: 0;
  }
`

const ArticleLink = styled.a`
  text-decoration: none;
  background-color: #4197A9;
  padding: 20px;
`

const ArticleInformation = styled.div`
  padding: 10px;
  & h4 {
    font-size: 1rem;
  }

  p {
    font-size: .8rem; 
  }
`

const PostTextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 2.5px;
  resize: none;
  margin: 5px 0;
  color: black;
  padding: 5px;
`

const PostBar = styled.div`
  flex: 1;
  border-radius: 2.5px;
  background-color: #DCDCDC;
  padding: 5px;
  margin-bottom: 10px;
`

const AttachButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;

  & p {
    margin: 0;
  }
`

const PostCardContainer = styled.div`
  margin: 20px 0;
  background-color: cadetblue;
  border-radius: 2.5px;

  & .post-content {
    padding: 15px;
    background-color: #FFFFFF;
    border-radius: 2.5px;
  }

  & h5 {
    color: #326771;
    font-size: 1.1rem;
    margin: 0;
  }

  & p.post-time {
    color: #747474;
    margin: 0;
    margin-left: 10px;
    font-size: 0.7rem;
  }

  & .post-counter {
    padding: 5px;
    display: flex;
    color: #326771;
    border-radius: 2.5px;
    align-items: center;
    background-color: white;
  }

  & button.submit-comment {
    padding: 10px;
    background-color: transparent;
    color: #326771;
  }

  & .comment-container {
    background-color: #F1F1F1;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px;
  }

  & .comment-container h6 {
    font-size: 1rem;
    color: #326771;
    margin: 0;
  }
`

export {
  WidgetContainer,
  WidgetElement,
  ArticleLink,
  ArticleInformation,
  PostTextArea,
  PostBar,
  AttachButton,
  PostCardContainer,
}
