import { Icon } from '@iconify/react'
import { IonButton, IonRow } from '@ionic/react'
import * as React from 'react'
import { RouteTypeButton } from '../StyledContainers'

interface Props {
  onChoose: (type: string) => void,
  onCancel: () => void
}

const SelectRouteType : React.FC<Props> = ({ onChoose, onCancel }) => (
  <div className='risk-assessment-list' style={{ padding: '20px' }}>
    <div>
      <h3 style={{ marginBottom: '20px', fontSize: '1.5rem', color: '#8E151F' }}>Add Route</h3>
      <p>Choose a Route type:</p>
      <RouteTypeButton onClick={() => onChoose('Road')}>
        <Icon icon='fa6-solid:car-side' style={{ color: '#13D132' }} className='road-icon' />
        <div>
          <h5 style={{ color: '#326771' }}>Road Route</h5>
          <p>Draw a route that follows an existing road on the map.</p>
        </div>
        <Icon icon='dashicons:arrow-right-alt2' style={{ fontSize: '2rem' }} />
      </RouteTypeButton>
      <RouteTypeButton onClick={() => onChoose('Off-Road')}>
        <Icon icon='fa-solid:truck-pickup' style={{ color: '#FF8A00' }} className='road-icon' />
        <div>
          <h5 style={{ color: '#326771' }}>Off-Road Route</h5>
          <p>Draw a route that follows an unmarked road or trail.</p>
        </div>
        <Icon icon='dashicons:arrow-right-alt2' style={{ fontSize: '2rem' }} />
      </RouteTypeButton>
      <RouteTypeButton onClick={() => onChoose('Water')}>
        <Icon icon='ion:boat-sharp' style={{ color: '#F85353' }} className='road-icon' />
        <div>
          <h5 style={{ color: '#326771' }}>Water Route</h5>
          <p>Draw a route that follows a river or waterway.</p>
        </div>
        <Icon icon='dashicons:arrow-right-alt2' style={{ fontSize: '2rem' }} />
      </RouteTypeButton>
    </div>
    <IonRow className='ion-justify-content-start'>
      <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
    </IonRow>
  </div>
)

export default SelectRouteType
