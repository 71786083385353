import React, { useState, useEffect } from 'react'
import { IonRow } from '@ionic/react'

import SitRep from './SitRep'
import SitRepPt2 from './SitRepPt2'

import SitRepPt7 from './SitRepPt7'

import SitRepPt8 from './SitRepPt8'
import SitRepPt9 from './SitRepPt9'
import SitRepInsights from './SitRepInsights'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'
import SitRepPt6 from './SitRepPt6'

import { useSituationReport } from '../../hooks/insights/useSituationReport'

/**
 *  =======================
 *      FORM TO ADD TASK
 *  =======================
 *
 */
const SitRepHandler = () => {
  const [step, setStep] = useState(0)
  const [data, setData] = useState({})
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  const [isShowing,, countyId] = useSituationReport()

  useEffect(() => {
    // make county id dynamic
    apiHook.situationReport({ domain_id: workspace.id, county_id: countyId }).then((sitData) => {
      setData(sitData)
      setStep(1)
    })
  }, [])

  switch (step) {
    default:
      return (
        <IonRow />
      )
    case 1:
      return isShowing ? (
        <SitRep
          step={step}
          setStep={setStep}
          alert_state={data.current_alert_state}
          security_condition={data.current_security_condition}
          county={data.county}
        />
      ) : null
    /*    case 3:
      return (
        <SitRepPt3
            step={step}
            setStep={setStep}
            county={data.county}
        />
      ) */
    case 2:
      return isShowing ? (
        <SitRepPt6
          step={step}
          setStep={setStep}
          county={data.county}
          journeys_in_progress={data.journeys_in_progress}
          journeys_scheduled={data.journeys_scheduled}
        />
      ) : null
    case 3:
      return isShowing ? (
        <SitRepPt7
          step={step}
          setStep={setStep}
          county={data.county}
          incidents={data.incidents_yearly}
        />
      ) : null
    case 4:
      return isShowing ? (
        <SitRepPt9
          step={step}
          setStep={setStep}
          county={data.county}
          incidents={data.incidents_by_type}
        />
      ) : null

    case 5:
      return isShowing ? (
        <SitRepPt2
          step={step}
          setStep={setStep}
          county={data.county}
          manifest={data.user_manifest}
          users={data.users}
        />
      ) : null

    case 6:
      return isShowing ? (
        <SitRepPt8
          step={step}
          setStep={setStep}
          county={data.county}
          incidents={data.incidents_past_week}
        />
      ) : null
    case 7:
      return isShowing ? (
        <SitRepInsights
          step={step}
          setStep={setStep}
          county={data.county}
          insights={data.insights}
          users={data.insights_users}
        />
      ) : null
  }
}

export default SitRepHandler
