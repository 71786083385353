import React, { FC, useState } from 'react'
import { AppView } from '../../../hooks/engagement/types/HookInterface'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { EngagementCampaign } from '../../types/OptimizedMaps'
import CampaignForm from '../forms/CampaignForm'
import CampaignBoard from './CampaignBoard'
import CampaignScreen from './CampaignScreen'

const Campaigns : FC = () => {
  const [showCampaignForm, setShowCampaignForm] = useState<boolean>(false)
  const { handleViewChange } = useEngagementData()
  const [focusedCampaign, setFocusedCampaign] = useState<EngagementCampaign | null>(null)

  if (focusedCampaign) {
    return (
      <CampaignScreen campaign={focusedCampaign} onBack={() => setFocusedCampaign(null)} />
    )
  }

  if (showCampaignForm) {
    return (
      <CampaignForm onClose={() => setShowCampaignForm(false)} />
    )
  }

  return (
    <CampaignBoard
      onNewCampaign={() => setShowCampaignForm(true)}
      onBack={() => handleViewChange(AppView.HOME)}
      onSelectCampaign={(val: EngagementCampaign) => setFocusedCampaign(val)}
    />
  )
}

export default Campaigns
