import * as React from 'react'
import { getDateString } from '../../../helpers/GlobalFunctions'
import GroupIcon from '../../../helpers/GroupIcon'
import UserIcon, { StakeholderIcon } from '../../../helpers/UserIcon'
import { ChatRoom } from '../../types/GlobalTypes'
import { Stakeholder, StakeholderMessage } from '../../types/OptimizedMaps'
import { ChatCardButton } from '../../messaging/StyledContainers'

interface Props extends ChatRoom {
  onClick: () => void,
  selected: boolean,
  userId: number
}

const getBgColor = (read: boolean, selected: boolean) => {
  if (selected) {
    return '#B3D5DD'
  }

  if (read) {
    return 'white'
  }

  return '#B3D5DD'
}

const ChatCard : React.FC<Props> = ({
  name, latestMessage, selected, onClick, userId, created_at, isGroupChat, recipients, read,
}) => (
  <ChatCardButton onClick={onClick} style={{ backgroundColor: getBgColor(read, selected) }}>
    {
      isGroupChat ? (
        <GroupIcon />
      ) : (
        <UserIcon {...recipients[0]} />
      )
    }
    <div style={{ margin: '0 10px' }} className='long-and-truncated'>
      <h5 style={{ fontSize: '1rem', color: '#326771' }}>{ name }</h5>
      <p style={{ fontSize: '.8rem', color: '#747474' }}>{(latestMessage?.user_id === userId) ? 'You: ' : ''}{ latestMessage?.content }</p>
    </div>
    <p style={{ fontSize: '.8rem' }} className='short-and-fixed'>{ getDateString(latestMessage?.created_at || created_at) }</p>
  </ChatCardButton>
)

interface DMProps {
  onClick: () => void,
  selected: boolean,
  stakeholder: Stakeholder,
  userId: number,
  created_at: string,
  latestMessage: StakeholderMessage,
}

const getMessageName = (latestMessage: StakeholderMessage, userId: number) => {
  if (!latestMessage.user) {
    return ''
  }

  if (latestMessage.user.id === userId) {
    return 'You: '
  }

  return `${latestMessage.user.first_name} ${latestMessage.user.final_name}`
}

export const DMCard : React.FC<DMProps> = ({
  latestMessage, selected, onClick, created_at, stakeholder, userId,
}) => (
  <ChatCardButton onClick={onClick} style={{ backgroundColor: (selected) ? '#B3D5DD' : '#EEEEEE' }}>
    <StakeholderIcon {...stakeholder} selected={selected} />
    <div style={{ margin: '0 10px' }} className='long-and-truncated'>
      <h5 style={{ fontSize: '1rem', color: '#326771' }}>{ stakeholder.name }</h5>
      {
        latestMessage && (
          <p>{ getMessageName(latestMessage, userId) }{ latestMessage?.content }</p>
        )
      }
    </div>
    <p style={{ fontSize: '.8rem' }} className='short-and-fixed'>{ getDateString(latestMessage?.created_at || created_at) }</p>
  </ChatCardButton>
)

export default ChatCard
