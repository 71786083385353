/* eslint-disable no-alert */
import React, { useState, useRef, useEffect } from 'react'
import {
  IonContent, IonRow, IonInput, IonCol, IonButton,
  IonList, IonItem, IonPopover, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'

import { SimpleButton, StoryLine, TicketContainer } from '../../maps/StyledContainers'
import { SelectStatus } from '../StyledMobile'
import JourneyDetails from '../../maps/features/JourneyDetails'
import useApi from '../../../hooks/testHooks'
import { useAuth } from '../../../hooks/useAuth'

/**
 *  ===============================
 *      DISPLAY ACTIVE JOURNEY
 *  ===============================
 */
const JourneyTicket = ({
  onBack, focusedJourney, setLoading, domainId, updateJourneyStatus,
}) => {
  const [comment, setComment] = useState('')
  const [showPopover, setShowPopover] = useState({ show: false, event: undefined })
  const [journey, setJourney] = useState(focusedJourney)
  const contentRef = useRef()
  const auth = useAuth()
  const { email } = auth.user

  const scrollToBottom = () => contentRef.current && contentRef.current.scrollToBottom()

  const [alert] = useIonAlert()
  const apiHook = useApi()

  /* Submit comment */
  const addComment = async (text) => {
    setLoading(true)

    /* Send comment request to the server */
    const { story } = await apiHook.commentJourney(focusedJourney.id, text, domainId)
    updateJourneyStatus(journey.id, { story })

    /* update ticket in real time */
    journey.story = story
    setJourney(journey)

    /* Clean input area */
    setComment('')
    setLoading(false)
    scrollToBottom()
    setShowPopover({ show: false, event: undefined })
  }

  /* Change status of a  journey */
  const changeStatus = (e) => {
    const progress = async (newStatus) => {
      setLoading(true)
      /* Change status in the db */
      const { story } = await apiHook.progressJourney(focusedJourney.id, '', domainId)
      updateJourneyStatus(journey.id, { status: newStatus, story })

      /* Update current ticket */
      journey.status = newStatus
      journey.story = story
      setJourney(journey)
      scrollToBottom()
      setLoading(false)
    }

    if (e.target.value === 'Abort') {
      // eslint-disable-next-line no-undef
      alert({
        header: 'Abort Journey',
        message: 'Are you sure you wish to abort this journey? This change cannot be undone.',
        buttons: [
          {
            text: 'Cancel',
            handler: () => {
              setLoading(false)
            },
          },
          {
            text: 'Ok',
            handler: async () => {
              setLoading(true)
              const { story } = await apiHook.denyJourney(focusedJourney.id, '', domainId)
              updateJourneyStatus(journey.id, { status: 'Abort', story })

              /* Update current ticket  */
              journey.status = 'Abort'
              journey.story = story
              setJourney(journey)
              scrollToBottom()
              setLoading(false)
            },
          },
        ],
      })
    } else if (e.target.value === 'In-Transit') {
      alert({
        header: 'Attention',
        message: 'Has the Pre-Departure checklist been completed?',
        buttons: [
          {
            text: 'no',
          },
          {
            text: 'yes',
            handler: () => progress('In-Transit')
            ,
          },
        ],
      })
    } else progress('Complete')
  }

  const defineOptions = (status) => {
    const options = ['Pre-Departure', 'In-Transit', 'Complete']
    const statusIndex = options.indexOf(status)

    const current = options.slice(statusIndex, statusIndex + 2)
    current.push('Abort')

    return current
  }

  const colorCodeStatus = (status) => (
    {
      Pending: '#747474',
      'Pre-Departure': '#4197A9',
      'In-Transit': '#FF8A00',
      Complete: '#0C9500',
      Abort: '#eb4034',
      Declined: '#eb4034',
    }[status]
  )

  useEffect(() => {
    scrollToBottom()
  }, [])

  return (
    <>
      <div style={{ padding: '10px 0' }}>
        <IonRow style={{ margin: '10px 0' }} className='ion-align-items-center ion-justify-content-start'>
          <SimpleButton onClick={onBack}>
            <Icon icon='fe:arrow-left' />
            Back
          </SimpleButton>
        </IonRow>
        {
          (journey.status === 'Pending' || journey.status === 'Complete' || journey.status === 'Abort' || journey.status === 'Declined' || journey.submitter.email !== email)
            ? (
              <p className='journey-status-mobile' style={{ backgroundColor: colorCodeStatus(journey.status) }}>{ journey.status }</p>
            ) : (
              <SelectStatus onChange={changeStatus} style={{ backgroundColor: colorCodeStatus(journey.status) }} value={journey.status}>
                {
                  defineOptions(journey.status).map((status) => (
                    <option key={status} value={status}>{ status }</option>
                  ))
                }
              </SelectStatus>
            )
        }
      </div>
      <IonContent
        style={{
          '--background': 'white',
          '--padding-top': '15px',
          '--padding-bottom': '15px',
          '--padding-start': '15px',
          '--padding-end': '15px',
        }}
        ref={contentRef}
      >
        <IonPopover
          cssClass='my-custom-class'
          event={showPopover.event}
          isOpen={showPopover.show}
          onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
        >
          <IonList>
            <IonItem onClick={() => addComment('Checking in.')} button>Checking in.</IonItem>
            <IonItem onClick={() => addComment('All okay.')} button>All okay.</IonItem>
            <IonItem onClick={() => addComment('Arriving shortly.')} button>Arriving shortly.</IonItem>
          </IonList>
        </IonPopover>
        <TicketContainer>
          <JourneyDetails
            focussedJourney={journey}
          />
        </TicketContainer>
        <StoryLine style={{ fontSize: '.8rem' }}>
          {
            journey.story.split('/n').map((storyLine) => (
              <li style={{ marginBottom: '10px' }} key={storyLine}>
                {
                      storyLine.split('/b').map((val) => (
                        <p style={{ margin: '0' }} key={val}>{val}</p>
                      ))
                    }
              </li>
            ))
          }
        </StoryLine>
      </IonContent>
      {
        journey.status === 'In-Transit' && journey.submitter.email === email && (
          <form onSubmit={() => addComment(comment)}>
            <IonRow className='ion-align-items-center'>
              <IonCol size={9}>
                <IonInput type='text' value={comment} onIonChange={(e) => setComment(e.detail.value)} className='ticket-comment mobile-version' placeholder='Description' />
              </IonCol>
              <IonCol size={3}>
                <IonButton
                  onClick={(e) => {
                    e.persist()
                    setShowPopover({ show: true, event: e })
                  }}
                  style={{ '--background': '#0C9500' }}
                >
                  <Icon icon='bi:broadcast' />
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        )
      }
    </>
  )
}

export default JourneyTicket
