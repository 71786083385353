import * as React from 'react'
import CreatableSelect from 'react-select/creatable'
import * as moment from 'moment'
import { IonButton, IonRow } from '@ionic/react'
import { useChatsData } from '../../../hooks/chats/useChatsData'
import { useAuth } from '../../../hooks/useAuth'
import { OverlayContainer } from '../StyledContainers'
import axios from '../../../utils/axios'
import { ChatRoom } from '../../types/GlobalTypes'

const AddChat : React.FC = () => {
  const {
    users, setLoading, pushNewRoom, setFocusedRoom, setShowAddChat, rooms,
  } = useChatsData()
  const [recipients, setRecipients] = React.useState<{ value: number, label: string }[]>([])
  const { user } = useAuth()

  const createRoom = () => {
    if (recipients.length === 1) {
      const alreadyExisting = rooms.find((val) => val.recipients.length === 1 && val.recipients[0].id === recipients[0].value)
      if (alreadyExisting) {
        setFocusedRoom(alreadyExisting)
        setShowAddChat(false)

        return
      }
    }

    setLoading(true)
    const recipientIds = recipients.map(({ value }) => value)
    axios.post('/create_room', { recipients: [...recipientIds, user.user_id] })
      .then(({ data }) => {
        const userObjs = users.filter(({ id }) => recipientIds.indexOf(id) >= 0)
        userObjs.push({ ...user, id: user.user_id, inWorkspace: true })
        const chatName = userObjs.length === 2 ? `${userObjs[0].first_name} ${userObjs[0].final_name}` : userObjs.map(({ first_name }) => first_name).join(', ')

        const newRoom : ChatRoom = {
          id: data.room_id,
          recipients: userObjs.filter(({ id }) => user?.user_id !== id),
          name: chatName,
          read: true,
          latestMessage: null,
          created_at: moment().format(),
          isGroupChat: userObjs.length > 2,
          recipientUpdates: userObjs.filter(({ id }) => user?.user_id !== id).map(({ id }) => ({ user_id: id, read: false, updated_at: moment().format() })),
        }
        console.log(newRoom)
        setLoading(false)
        pushNewRoom(newRoom)
        setFocusedRoom(newRoom)
      }).catch((e) => {
        console.log(e)
      }).finally(() => {
        setShowAddChat(false)
      })
  }

  return (
    <OverlayContainer>
      <h4>New Chat</h4>
      <h5 style={{ fontSize: '1.1rem' }}>Choose Participant(s)</h5>
      <p>Selecte the users you wish to chat with</p>
      <CreatableSelect
        isMulti
        placeholder='Chat participants'
        name='colors'
        className='select-container select-chat-recipients'
        id='chat-participants'
              // eslint-disable-next-line no-undef
        menuPortalTarget={document.body}
        options={users.filter(({ id }) => id !== user.user_id).map((val) => ({ value: val.id, label: `${val.first_name} ${val.final_name}` }))}
        value={recipients}
        onChange={(val) => setRecipients(val)}
      />
      <IonRow className='ion-justify-content-end ion-align-items-center'>
        <IonButton onClick={() => setShowAddChat(false)} style={{ '--background': '#8E151F', color: 'white' }}>Cancel</IonButton>
        <IonButton disabled={recipients.length === 0} onClick={createRoom} style={{ '--background': '#326771', color: 'white' }}>Message</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default AddChat
