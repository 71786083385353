import React from 'react'
import Select from 'react-select'
import {
  IonList, IonContent, IonRow, IonButton,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, SelectionDetail } from '../../StyledContainers'
import { InstructionButtons } from '../../../maps/StyledContainers'

/**
 *  ===============================
 *      Risk Event Q. No 1
 *  ===============================
 */

const RiskSelection = ({
  input, setInput, onClose, next, children, riskEvents, edit,
}) => (
  <CenterForm style={{ zIndex: '20' }}>
    <IonContent>
      <IonList style={{ height: '100%', padding: '20px' }}>
        {
          children
        }
        <IonRow className='ion-justify-content-between'>
          <SettingField style={{ width: '150px' }}>
            <h5>Risk Event</h5>
            {
              (edit) ? (
                <p id='review-risk-event'> { input.riskEvent.value } </p>
              ) : (
                <Select
                  placeholder='Select Risk'
                  name='event'
                  className='select-container'
                  id='ra-select-event'
                  value={input.riskEvent}
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={Object.keys(riskEvents).map((name) => ({ value: name, label: name }))}
                  onChange={(selected) => setInput({ ...input, riskEvent: selected, edit: true })}
                />
              )
            }
          </SettingField>
        </IonRow>
        {
          input.riskEvent && (
            <SelectionDetail>
              <p> { riskEvents[input.riskEvent.value] } </p>
            </SelectionDetail>
          )
        }
        <InstructionButtons>
          <IonRow style={{ paddingRight: '20px' }} className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next} disabled={!(input.riskEvent)}>Next</IonButton>
          </IonRow>
        </InstructionButtons>
      </IonList>
    </IonContent>
  </CenterForm>
)

export default RiskSelection
