import { Icon } from '@iconify/react'
import {
  IonButton, IonCol, IonContent, IonInput, IonLabel, IonList, IonRow,
} from '@ionic/react'
import * as moment from 'moment'
import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { getWebSocketURL } from '../../../../channels/consumer'
import { useAuth } from '../../../../hooks/useAuth'
import {
  BreakoutRoom, Message, User,
} from '../../../types/GlobalTypes'
import axios from '../../../../utils/axios'
import { useActionCable } from '../../../../hooks/channels/useActionCable'
import { useChannel } from '../../../../hooks/channels/useChannel'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { ChatChannelConfig } from '../../../../hooks/channels/ChannelConfigs'
import GroupIcon from '../../../../helpers/GroupIcon'
import UserIcon from '../../../../helpers/UserIcon'
import { SherpaButton } from '../../../GlobalContainers'
import MessageCard from '../../../messaging/MessageCard'

interface Props {
  room: BreakoutRoom,
  onDeleteUser: (user: User) => void,
  setShowAddUsers: React.Dispatch<React.SetStateAction<boolean>>,
  setShowEditName: React.Dispatch<React.SetStateAction<boolean>>,
  setShowDeleteChat: React.Dispatch<React.SetStateAction<boolean>>,
  setShowLeaveGroup: React.Dispatch<React.SetStateAction<boolean>>,
}

const Conversation : React.FC<Props> = ({
  room, onDeleteUser, setShowAddUsers, setShowEditName, setShowDeleteChat, setShowLeaveGroup,
}) => {
  const [comment, setComment] = React.useState<string>('')
  const [messages, setMessages] = React.useState<Message[]>([])
  const [showUsers, setShowUsers] = React.useState<boolean>(false)
  const [showOptions, setShowOptions] = React.useState<boolean>(false)
  const [roomId, setRoomId] = React.useState<number>(null)
  const { user } = useAuth()
  /* Connect to action cable */
  const { actionCable } = useActionCable(getWebSocketURL())
  const { subscribe, unsubscribe, subscribed } = useChannel(actionCable)
  const {
    focusedRoom, updateRoom, setFocusedRoom, pushLatestMessage, setChatAsRead,
  } = useIncidentsData()

  const messagesScroll = React.useRef(null)

  const handleMessage = () => {
    if (comment === '') return
    axios.post('/messages', {
      message: comment,
      room_id: room.id,
    })
    const recipientList = focusedRoom.recipientUpdates.map((val) => ({ ...val, read: false }))
    const chat : BreakoutRoom = { ...focusedRoom, recipientUpdates: recipientList }
    updateRoom(chat)
    setFocusedRoom(chat)
    setComment('')
  }

  const onToggleHandler = (isOpen : boolean, metadata: { source: string }) => {
    if (metadata.source !== 'select') {
      setShowUsers(isOpen)
    }
  }

  const onToggleOptionsHandler = (isOpen : boolean, metadata: { source: string }) => {
    if (metadata.source !== 'select') {
      setShowOptions(isOpen)
    }
  }

  React.useEffect(() => {
    if (room.id === roomId) {
      return
    }
    setRoomId(room.id)
    unsubscribe()
  }, [room])

  React.useEffect(() => {
    if (subscribed) return
    subscribe<ChatChannelConfig>({ channel: 'MessagesChannel', room_id: room.id }, {
      received: (x) => {
        const newMessages : Message[] = x.messages
        if (newMessages.length === 0) {
          setMessages([])
          return
        }
        const latestMessage = newMessages.reduce((prev, current) => (moment(prev.created_at).isAfter(current.created_at) ? prev : current))
        pushLatestMessage(latestMessage, room)
        setMessages(newMessages.sort((a, b) => a.id - b.id))

        /* Auto read messages when in chat */
        axios.post('/read_room', { room_id: room.id })
      },
      disconnected: () => {
        setMessages([])
      },
    })
    setChatAsRead(room)
    /* Send request to set chat as read */
    axios.post('/read_room', { room_id: room.id })
  }, [subscribed])

  const isRead = (message: Message) : boolean => {
    if (room.isGroupChat) {
      return room.recipientUpdates.every(({ updated_at }) => moment(updated_at).isSameOrAfter(message.created_at))
    }

    if (moment(room.recipientUpdates[0].updated_at).isSameOrAfter(message.created_at)) {
      return true
    }

    return false
  }

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    messagesScroll.current && messagesScroll.current.scrollToBottom()
  }, [messages])

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: '20px',
    }}
    >
      <IonRow className='ion-justify-content-between ion-align-items-center' style={{ borderBottom: '0.5px solid #747474', padding: '10px', marginBottom: '10px' }}>
        <IonRow className='ion-align-items-center long-and-truncated'>
          {
            room.isGroupChat ? (
              <GroupIcon />
            ) : (
              <UserIcon {...room.recipients[0]} />
            )
          }
          <h5 style={{
            color: 'black', fontSize: '1rem', flex: 1, marginLeft: '10px', maxWidth: 'fit-content',
          }}
          >{ room.name }
          </h5>
          {
            room.isGroupChat && user.user_id === room.host_id && (
              <SherpaButton onClick={() => setShowEditName(true)}><Icon icon='clarity:edit-solid' /></SherpaButton>
            )
          }
        </IonRow>
        <IonRow className='ion-align-items-center'>
          <Dropdown align='end' show={showUsers} onToggle={(isOpen : boolean, metadata : { source: string }) => onToggleHandler(isOpen, metadata)}>
            <Dropdown.Toggle
              className='remove-arrow'
              style={{
                backgroundColor: 'transparent', border: 'none', color: '#326771', fontSize: '1.5rem',
              }}
            ><Icon icon='fa6-solid:user-group' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div>
                {
                  room.recipients.map((val) => (
                    <React.Fragment key={val.id}>
                      {
                        room.isGroupChat ? (
                          <Dropdown.Item
                            onClick={() => {
                              setShowUsers(false)
                              onDeleteUser(val)
                            }}
                            className='task-filter-option'
                          >
                            <IonRow style={{ flex: 1 }} className='ion-justify-content-between ion-align-items-center'>
                              <IonCol size='10' className='force-truncated-text'>
                                {val.first_name} {val.final_name}
                              </IonCol>
                              {
                                room.host_id === user.user_id && (
                                  <IonCol size='2'>
                                    <Icon icon='carbon:close' style={{ color: '#326771' }} />
                                  </IonCol>
                                )
                              }
                            </IonRow>
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            className='task-filter-option'
                          >
                            <p>{val.first_name} {val.final_name}</p>
                          </Dropdown.Item>
                        )
                      }
                    </React.Fragment>
                  ))
                }
              </div>
              {
                room.host_id === user.user_id && (
                  <Dropdown.Item onClick={() => setShowAddUsers(true)} style={{ borderTop: '1px solid #747474', paddingTop: '10px' }} className='task-filter-option'>
                    Add Users <Icon icon='eva:person-add-fill' style={{ color: '#326771', fontSize: '1.4rem' }} />
                  </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown show={showOptions} onToggle={(isOpen : boolean, metadata : { source: string }) => onToggleOptionsHandler(isOpen, metadata)}>
            <Dropdown.Toggle
              className='remove-arrow'
              style={{
                backgroundColor: 'transparent', border: 'none', color: '#326771', fontSize: '1.5rem',
              }}
            ><Icon icon='akar-icons:more-vertical' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                room.host_id === user.user_id && (
                  <Dropdown.Item className='task-filter-option'>
                    <IonRow
                      onClick={() => {
                        setShowOptions(false)
                        setShowDeleteChat(true)
                      }}
                      style={{ flex: 1, borderBottom: (room.isGroupChat) ? '1px solid #747474' : 'none', paddingBottom: '5px' }}
                      className='ion-justify-content-between ion-align-items-center'
                    >
                      <IonLabel>Delete Chat</IonLabel>
                      <Icon icon='bi:trash' style={{ color: '#326771' }} />
                    </IonRow>
                  </Dropdown.Item>
                )
              }
              {
                room.isGroupChat && (
                  <Dropdown.Item
                    onClick={() => {
                      setShowOptions(false)
                      setShowLeaveGroup(true)
                    }}
                    className='task-filter-option'
                  >
                    <IonRow style={{ flex: 1 }} className='ion-justify-content-between ion-align-items-center'>
                      <IonLabel>Leave Group</IonLabel>
                      <Icon icon='pepicons:leave' style={{ color: '#326771' }} />
                    </IonRow>
                  </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
          <SherpaButton />
        </IonRow>
      </IonRow>
      <IonContent ref={messagesScroll} style={{ '--background': 'white' }} scrollEvents>
        <IonList style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
          {
            messages.map((val) => (
              <MessageCard key={val.id} {...val} sessionId={user.user_id} read={isRead(val)} />
            ))
            }
        </IonList>
      </IonContent>
      <IonRow style={{ margin: '10px 0' }}>
        <IonInput
          placeholder='Type message...'
          style={{ flex: 1 }}
          value={comment}
          className='chats-input-field'
          onIonChange={(e) => setComment(e.detail.value)}
        />
        <IonButton onClick={handleMessage} style={{ '--background': '#0C9500', color: 'white', margin: '0 10px' }}>Send</IonButton>
      </IonRow>
    </div>
  )
}

export default Conversation
