import {
  IonCol,
  IonGrid, IonImg, IonRow, IonIcon, useIonAlert,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { logOutOutline } from 'ionicons/icons'
import { Container, SelectionContainer } from '../components/domain/StyledContainers'
import DomainCard from '../components/domain/DomainCard'

import trubshawLogo from '../../assets/images/trubshaw_logo.png'
import { useAuth } from '../hooks/useAuth'
import { useWorkspace } from '../hooks/useWorkspace'

import useApi from '../hooks/testHooks'

/**
 *  ========================
 *    SELECT A WORKSPACE
 *  ========================
 */
const ChooseDomain = ({ workspaces, setDomains, addDomain }) => {
  const auth = useAuth()
  const workspace = useWorkspace()
  const history = useHistory()
  const apiHook = useApi()
  const [ionAlert] = useIonAlert()
  const [declineAlert] = useIonAlert()
  const [invites, setInvites] = useState([])

  const handleClick = (domain) => {
    workspace.setDomain(domain)
    history.push('/home')
  }

  const acceptInvite = (domain) => {
    apiHook.processWorkspaceInvite(domain.invite_id, true).then(() => {
      const newDomain = {
        country: domain.country,
        id: domain.domain_id,
        name: domain.name,
        power: domain.power,
        type: domain.type,
      }
      workspace.setDomain(newDomain)
      addDomain(newDomain)
      history.push('/home')
    })
  }

  const declineInvite = (domain) => {
    const decline = () => {
      apiHook.processWorkspaceInvite(domain.invite_id, false)
        .then(() => {
          const copy = invites.slice()
          const index = copy.findIndex((invitation) => invitation.id === domain.domain_id)
          copy.splice(index, 1)
          setInvites(copy)
        })
    }

    setTimeout(() => {
      declineAlert({
        header: 'Decline invitation',
        message: 'Are you sure you want to decline this invitation?',
        buttons: [
          'cancel',
          { text: 'Yes', handler: decline },
        ],
      })
    }, 250)
  }

  const processInvite = (domain) => {
    ionAlert({
      header: 'Accept Invitation',
      message: `You have been invited to join '${domain.name}'`,
      buttons: [
        { text: 'Decline', handler: () => declineInvite(domain) },
        { text: 'Accept', handler: () => acceptInvite(domain) },
      ],
    })
  }

  useEffect(async () => {
    const data = await apiHook.getRolesbyUser(-1)
    setInvites(data.invitedata)
    setDomains(data.roledata)
  }, [])

  return (
    <Container>
      <IonRow style={{ height: '10%' }} className='ion-justify-content-between ion-align-items-center'>
        <IonImg style={{ height: '100px' }} src={trubshawLogo} />
        <button
          onClick={() => {
            auth.logout()
            history.push('/login')
          }}
          style={{ color: '#326771', border: 'none', backgroundColor: 'transparent' }}
          type='button'
        >
          <IonRow className='ion-align-items-center'>
            <IonIcon icon={logOutOutline} />
            <p style={{ margin: 0 }}>Log out</p>
          </IonRow>
        </button>
      </IonRow>
      <div style={{ height: '75%', display: ((workspaces.length > 0 && invites.length > 0) ? 'block' : 'flex'), alignItems: 'center' }}>
        {
          workspaces.length > 0 && (
            <SelectionContainer>
              <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                <IonCol>
                  <h2 style={{ marginBottom: '20px' }}>Choose Your Workspace</h2>
                  <IonGrid>
                    <IonRow className='ion-justify-content-around'>
                      {
                        workspaces.map((domain) => (
                          <DomainCard key={domain.name} onClick={() => handleClick(domain)} name={domain.name} />
                        ))
                      }
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </SelectionContainer>
          )
        }
        {
        invites.length > 0 && (
          <SelectionContainer>
            <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
              <IonCol>
                <h2 style={{ marginBottom: '20px' }}>Invites</h2>
                <IonGrid>
                  <IonRow className='ion-justify-content-around'>
                    {
                      invites.map((domain) => (
                        <DomainCard key={domain.name} onClick={() => processInvite(domain)} name={domain.name} />
                      ))
                    }
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </SelectionContainer>
        )
        }
      </div>
    </Container>
  )
}

export default ChooseDomain
