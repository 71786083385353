import { IonButton, IonGrid, IonRow } from '@ionic/react'
import React, { FC, useState } from 'react'
import { AppView } from '../../../hooks/engagement/types/HookInterface'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { ObscureBackground } from '../../GlobalContainers'
import FormPopup from '../../maps/forms/FormPopup'
import { SelectView } from '../../maps/StyledContainers'
import LoadingModal from '../../modals/LoadingModal'
import { CenterForm } from '../../riskregister/StyledContainers'
import StakeholderList from '../stakeholder-views/StakeholderList'
import StakeholderMap from '../stakeholder-views/StakeholderMap'
import StakeholderForm from '../../maps/features/Stakeholder'

enum ViewType {
  LIST = 'Stakeholder List',
  MAP = 'Stakeholder Map',
}

const Stakeholders : FC = () => {
  const [viewType, setViewType] = useState<ViewType>(ViewType.LIST)
  const [showStakeholderForm, setShowStakeholderForm] = useState<boolean>(false)
  const {
    handleViewChange, loading, submittedMessage, setSubmittedMessage, communities,
    stakeholders, setLoading, stakeholderGroups, pushNewStakeholder,
  } = useEngagementData()

  return (
    <IonGrid
      className='risk-assessment-list'
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {
        showStakeholderForm && (
          <StakeholderForm
            communities={communities}
            onClose={() => setShowStakeholderForm(false)}
            stakeholders={stakeholders}
            setLoading={setLoading}
            pushNewStakeholder={pushNewStakeholder}
            setSubmittedMessage={setSubmittedMessage}
            stakeholderGroups={stakeholderGroups}
          />
        )
      }
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
      <LoadingModal isOpen={loading} />
      <StakeholderControls onAdd={() => setShowStakeholderForm(true)} onBack={() => handleViewChange(AppView.HOME)} viewType={viewType} setViewType={setViewType} />
      <IonRow style={{ flex: '1 1 auto', width: '100%', overflow: 'auto' }} className='ion-justify-content-center'>
        {
          viewType === ViewType.LIST && (
            <StakeholderList />
          )
        }
        {
          viewType === ViewType.MAP && (
            <StakeholderMap />
          )
        }
      </IonRow>
    </IonGrid>
  )
}

export default Stakeholders

interface ControlProps {
  viewType: ViewType,
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>,
  onBack: () => void,
  onAdd: () => void,
}

const StakeholderControls : FC<ControlProps> = ({
  viewType, setViewType, onBack, onAdd,
}) => (
  <IonGrid
    style={{
      backgroundColor: '#FFFFFF',
      padding: '10px 30px',
      marginBottom: '25px',
      boxShadow: '0px 4px 4px 0px #00000040',
      position: 'relative',
      borderRadius: '5px',
    }}
    className='insight-controls-bar'
  >
    <IonRow className='ion-align-items-center ion-justify-content-between'>
      <IonRow>
        <IonButton onClick={onBack}>Back</IonButton>
        <SelectView
          value={viewType}
          onChange={(e) => setViewType(e.target.value)}
          id='select-app-view'
        >
          <option value={ViewType.MAP}>{ ViewType.MAP }</option>
          <option value={ViewType.LIST}>{ ViewType.LIST }</option>
        </SelectView>
      </IonRow>
      <IonButton onClick={onAdd} style={{ '--background': '#0C9500' }}>Add Stakeholder</IonButton>
    </IonRow>
  </IonGrid>
)
