import {
  IonRow, IonCol, IonInput, IonButton,
} from '@ionic/react'
import React from 'react'
import moment from 'moment'
import Datetime from 'react-datetime'
import { CenterForm } from '../riskregister/StyledContainers'
import { SettingField } from '../settings/StyledContainers'
import { SelectIncidentType } from '../maps/StyledContainers'
import { DescriptionArea } from '../incident-management/StyledContainers'

/**
 *  ==============================
 *    MODIFY INCIDENT DETAILS
 *  ==============================
 */
const IncidentDetails = ({
  input, setInput, incidentTypes, onClose, onSubmit,
}) => {
  const handleChange = (e) => setInput({ ...input, [e.target.name]: e.detail.value })
  return (
    <CenterForm style={{ height: 'auto', padding: '10px', width: '35%' }}>
      <SettingField style={{ width: 'auto' }}>
        <h4>Incident Details</h4>
      </SettingField>
      <IonRow className='ion-align-items-center'>
        <IonCol size={4}>
          <SettingField style={{ width: 'auto' }}>
            <h5>Incident Type:</h5>
          </SettingField>
        </IonCol>
        <IonCol style={{ textAlign: 'right' }} size={8}>
          <SelectIncidentType style={{ border: '1px solid #747474' }} value={input.incident_type} onChange={(e) => setInput({ ...input, incident_type: e.target.value })}>
            <option value='' disabled>Select Incident</option>
            {
              incidentTypes.map((incident) => (
                <option value={incident} key={incident}>{incident}</option>
              ))
            }
          </SelectIncidentType>
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center'>
        <IonCol size={4}>
          <SettingField style={{ width: 'auto' }}>
            <h5>Date of Incident:</h5>
          </SettingField>
        </IonCol>
        <IonCol size={8}>
          <Datetime
            dateFormat='YYYY-MM-DD'
            timeFormat={false}
            inputProps={{
              placeholder: 'Date of incident',
              readOnly: true,
              style: { backgroundColor: 'white' },
            }}
            isValidDate={(current) => current.isBefore(moment())}
            value={input.date}
            onChange={(e) => setInput({ ...input, date: e })}
          />
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center'>
        <IonCol size={4}>
          <SettingField style={{ width: 'auto' }}>
            <h5>Fatalities:</h5>
          </SettingField>
        </IonCol>
        <IonCol size={8}>
          <IonInput name='fatalities' value={input.fatalities} onIonChange={handleChange} type='number' min='0' max='1000' style={{ textAlign: 'right' }} required />
        </IonCol>
      </IonRow>
      <SettingField style={{ width: 'auto', padding: '5px' }}>
        <h5>Description (optional):</h5>
      </SettingField>
      <div style={{ margin: '0 15px 10px 15px' }}>
        <DescriptionArea
          style={{ width: '100%', border: '1px solid #747474' }}
          className='wide-area'
          name='description'
          placeholder='Provide a description for this incident.'
          value={input.description}
          onChange={(e) => setInput({ ...input, description: e.target.value })}
        />
      </div>
      <IonRow className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
        <IonButton style={{ '--background': '#0C9500' }} onClick={onSubmit}>Update</IonButton>
      </IonRow>
    </CenterForm>
  )
}

export default IncidentDetails
