/* eslint-disable import/no-unresolved */
import React, { useState, useRef, useEffect } from 'react'
import {
  IonRow, IonButton, IonInput, useIonAlert,
} from '@ionic/react'
import { FormTitle } from '../StyledContainers'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'

/**
 *  ==============================
 *      ADD NEW AIRPORT PIN
 *  ==============================
 */
const AddAirport = ({ mapRef, onSubmit, onCancel }) => {
  const [pin, setPin] = useState(null)
  const [locationName, setLocationName] = useState('')
  const [ionAlert] = useIonAlert()

  /* Add new pin in clicked location */
  const handleClick = (e) => {
    if (pin) return

    /* If the form is visible and no pin has been added then create a marker */
    const marker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      .setLngLat(Object.values(e.lngLat))
      .addTo(mapRef.current)
    marker.setDraggable(true)

    setPin(marker)
  }

  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  const handleSubmit = () => {
    if (locationName === '') {
      ionAlert({
        header: 'Error - No airport name',
        message: 'You must provide a name for the marker.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    if (!pin) {
      ionAlert({
        header: 'Error - No location provided',
        message: 'You must provide a location on the map for the airport.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    onSubmit(locationName, pin)
  }

  /* add Listeners to map and remove then when component is deleted */
  useEffect(() => {
    /* Map listener to add pins */
    const clickHandler = (e) => {
      handleClickRef.current(e)
    }

    mapRef.current.on('click', clickHandler)

    return () => {
      mapRef.current.off('click', clickHandler)
    }
  }, [])

  return (
    <div style={{
      display: 'flex', flexDirection: 'column', height: '100%', background: 'white', padding: '15px',
    }}
    >
      <div style={{ overflowY: 'auto', flexGrow: '1' }}>
        <h2 style={{ fontSize: '1.3rem', color: '#8E151F' }}>Find on Map</h2>
        <p style={{ margin: '15px 0' }}>Use the map to find the location of the airport or airfield you wish to travel to. Drop a pin to mark its location.</p>
        <div style={{ margin: '25px 0' }}>
          <FormTitle>
            <h3 style={{ fontSize: '1rem' }}>Pin Name</h3>
          </FormTitle>
          <IonInput
            style={{ border: '1px solid #747474', borderRadius: '2.5px' }}
            onIonChange={(e) => setLocationName(e.detail.value)}
            value={locationName}
            name='location'
            type='text'
            placeholder='Airport name'
            id='airport-name'
          />
        </div>
        <p>Note: The pin name should be the name of the airport or airfield you are travelling to.</p>
      </div>
      <IonRow className='ion-justify-content-end ion-align-items-center'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>Cancel</IonButton>
        <IonButton id='submit-airport-name' style={{ '--background': '#0C9500' }} onClick={handleSubmit}>Submit</IonButton>
      </IonRow>
    </div>
  )
}

export default AddAirport
