import React from 'react'
import {
  IonButton, IonRow, IonGrid, IonCol, IonLabel,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import useApi from '../../hooks/testHooks'

import { scSingleCounty } from '../maps/apps/SecurityConditions'
import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'

/**
 *  =======================
 *      2nd Popup SLide
 *  =======================
 */
const SecurityChangePopup = ({
  setStep, noti_id, hide, domainId, date, incidents, county,
}) => {
  const handlePrev = () => {
    setStep(1)
  }

  const handleNext = () => {
    setStep(3)
  }

  const handleClose = () => {
    setStep(0)
    hide()
  }

  const apiHook = useApi()

  function getColor(level) {
    if (level === 'Level 1 - Low') {
      return '#14C105'
    } if (level === 'Level 2 - Guarded') {
      return '#349DFF'
    } if (level === 'Level 3 - Elevated') {
      return '#FFE500'
    } if (level === 'Level 4 - High') {
      return '#FF8A00'
    }
    return '#FF0000'
  }

  const secConds = [['Level 1 - Low', 'Serious security incidents occurring less than once a year.'],
    ['Level 2 - Guarded', 'Serious security incidents occurring more than once a year.'],
    ['Level 3 - Elevated', 'Serious security incidents occurring more than once a month.​'],
    ['Level 4 - High', 'Serious security conditions occurring more than once a week.​'],
    ['Level 5 - Severe', 'Serious security incidents occurring on a daily basis.​']]

  let filters = {
    'Violent Conflict': false, 'Violent Crime': false, Criminality: false, 'Social Unrest': false, Travel: true, other: false,
  }
  const travel = scSingleCounty(incidents, filters, date)

  filters = {
    'Violent Conflict': false, 'Violent Crime': false, Criminality: false, 'Social Unrest': true, Travel: false, other: false,
  }
  const civilUnrest = scSingleCounty(incidents, filters, date)

  filters = {
    'Violent Conflict': true, 'Violent Crime': false, Criminality: false, 'Social Unrest': false, Travel: false, other: false,
  }
  const violentConflict = scSingleCounty(incidents, filters, date)

  filters = {
    'Violent Conflict': true, 'Violent Crime': false, Criminality: false, 'Social Unrest': false, Travel: false, other: false,
  }
  const violentCrime = scSingleCounty(incidents, filters, date)

  const civun = secConds[civilUnrest - 1][0]
  const vcon = secConds[violentConflict - 1][0]
  const vcrim = secConds[violentCrime - 1][0]
  const trvl = secConds[travel - 1][0]

  const handleDownload = () => {
    apiHook.downloadSecurityChange({
      domain_id: domainId,
      notification_id: noti_id,
    })
      .then((data) => {
        const when = moment(date).format('DD_MM_YYYY')
        const filename = (`Security Change - ${county} ${when}.pdf`)
        apiHook.downloader2(data, filename)
      })
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 1 }} />
      <OverlayContainer style={{ height: '550px', width: '650px' }}>

        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '20px' }}>Security Condition Change</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonRow style={{
          paddingTop: '67px', paddingLeft: '40px', paddingBottom: '20px', paddingRight: '40px',
        }}
        >
          <IonLabel>{`Listed below is a breakdown of the Security Condition Categories and their levels in ${county} County.`}</IonLabel>
        </IonRow>

        <IonGrid style={{ paddingTop: '27px', paddingLeft: '100px', paddingRight: '100px' }}>
          <IonRow style={{ outline: 'solid 2px' }}>
            <IonCol size='6'>
              <IonLabel style={{ fontSize: '12px' }}>Civil Unrest</IonLabel>
            </IonCol>
            <IonCol size='6' style={{ outline: 'solid 2px', backgroundColor: getColor(civun) }}>
              <IonLabel style={{ fontSize: '12px', color: (civun === 'Level 3 - Elevated') ? '#000000' : '#FFFFFF' }}>{civun}</IonLabel>
            </IonCol>
          </IonRow>

          <IonRow style={{ outline: 'solid 2px' }}>
            <IonCol size='6'>
              <IonLabel style={{ fontSize: '12px' }}>Violent Conflict</IonLabel>
            </IonCol>
            <IonCol size='6' style={{ outline: 'solid 2px', backgroundColor: getColor(vcon) }}>
              <IonLabel style={{ fontSize: '12px', color: (vcon === 'Level 3 - Elevated') ? '#000000' : '#FFFFFF' }}>{vcon}</IonLabel>
            </IonCol>
          </IonRow>

          <IonRow style={{ outline: 'solid 2px' }}>
            <IonCol size='6'>
              <IonLabel style={{ fontSize: '12px' }}>Violent Crime</IonLabel>
            </IonCol>
            <IonCol size='6' style={{ outline: 'solid 2px', backgroundColor: getColor(vcrim) }}>
              <IonLabel style={{ fontSize: '12px', color: (vcrim === 'Level 3 - Elevated') ? '#000000' : '#FFFFFF' }}>{vcrim}</IonLabel>
            </IonCol>
          </IonRow>

          <IonRow style={{ outline: 'solid 2px' }}>
            <IonCol size='6'>
              <IonLabel style={{ fontSize: '12px' }}>Travel</IonLabel>
            </IonCol>
            <IonCol size='6' style={{ outline: 'solid 2px', backgroundColor: getColor(trvl) }}>
              <IonLabel style={{ fontSize: '12px', color: (trvl === 'Level 3 - Elevated') ? '#000000' : '#FFFFFF' }}>{trvl}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonRow style={{ paddingTop: '120px' }}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9', paddingLeft: '10px' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>

      </OverlayContainer>
    </>
  )
}

export default SecurityChangePopup
