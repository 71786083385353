import { Consumer, Mixin, Subscription } from '@rails/actioncable'
import { useState, useEffect, useRef } from 'react'

export interface ChannelCallbacks {
  received?: (data: any) => void,
  connected?: () => void,
  disconnected?: () => void,
  initialized?: () => void,
}

export interface DefaultChannelData {
  channel: string
}
export type ChannelSubscription = Subscription<Consumer> & Mixin & ChannelCallbacks

export const useChannel = (actionCable: Consumer) => {
  const [connected, setConnected] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const channelRef = useRef<ChannelSubscription | null>()

  const subscribe = <C extends DefaultChannelData>(data: C, callbacks: ChannelCallbacks) => {
    console.log(`useChannel - INFO: Connecting to ${data.channel}`)
    const channel = actionCable.subscriptions.create(data, {
      received: (x) => {
        if (callbacks.received) callbacks.received(x)
      },
      initialized: () => {
        console.log(`useChannel - INFO: Init ${data.channel}`)
        setSubscribed(true)
        if (callbacks.initialized) callbacks.initialized()
      },
      connected: () => {
        console.log(`useChannel - INFO: Connected to ${data.channel}`)
        setConnected(true)
        if (callbacks.connected) callbacks.connected()
      },
      disconnected: () => {
        console.log(`useChannel - INFO: Disconnected ${data.channel}`)
        setConnected(false)
        if (callbacks.disconnected) callbacks.disconnected()
      },
    })
    channelRef.current = channel
  }

  const unsubscribe = () => {
    setSubscribed(false)
    if (channelRef.current) {
      console.log(`useChannel - INFO: Unsubscribing from ${channelRef.current.identifier}`)
      actionCable.subscriptions.consumer.disconnect()
      channelRef.current.unsubscribe()
      channelRef.current = null
    }
  }

  useEffect(() => () => {
    unsubscribe()
  }, [])

  return {
    subscribe, unsubscribe, connected, subscribed,
  }
}
