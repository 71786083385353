import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'

import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

import img1 from './InsightIncident1.jpg'
import img2 from './InsightIncident2.jpg'
import img3 from './InsightIncident3.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const InsightUseMap = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-insights')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' routerLink='/helpcenter-insights' onClick={handleBack} />Insights Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Adding an Incident
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonText />
                <IonText>Step1.<br />To add an incident, the first step is to zoom in on the map to the area where the incident occurred.
                  You will find that the map becomes more detailed as you zoom in, with locations of cities and towns within counties becoming visible.
                  To learn how to zoom in, click here (link to article on using the map).
                </IonText>
                <IonText />
                <IonText><br /><br />Step2.<br />Once you are satisfied that you have identified the area where the incident occurred on the map,
                  click on the ‘Add+’ button in the top right-hand corner.
                </IonText>
                <IonImg src={img1} />
                <IonText />
                <IonText><br /><br />Step 3.<br />After this, hover back over the map and click on the area of the map where the incident took place.
                  A pin will be dropped at that location. Click ‘Next’ on the box that pops up to add details of the incident.
                </IonText>
                <IonImg src={img2} />
                <IonText />
                <IonText><br /><br />Step4.<br />A box (shown below) will come up on screen. Fill in all the relevant incident details, including what type of incident occurred,
                  the date and time, the number of fatalities, and a brief description of the incident.
                </IonText>
                <IonText><br />Please note, incident reports require that all fields below are filled in before they can be submitted.
                  If information on dates and times are unknown, provide a rough estimate of the date and the time will automatically be set at midday for that date.
                  Similarly, if the number of fatalities is unknown, just set this to 0 (zero).
                </IonText>
                <IonText><br />The coordinates will automatically update depending on where you dropped the pin on the map.</IonText>
                <IonImg src={img3} />
                <IonText />
                <IonText><br /><br />Step5.<br />Once all the fields have been filled in, select ‘Submit’ in the bottom right-hand corner of the box </IonText>

              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addinsight'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Add an Insight Report
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-usemap'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Using the Map
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addinsight'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Add an Insight
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-alertstates'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Alert States
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default InsightUseMap
