import React, { useState } from 'react'
import {
  IonGrid, IonRow, IonCol, IonCheckbox, IonButton,
} from '@ionic/react'
import { CenterForm, ColumnContainer } from '../riskregister/StyledContainers'
import { SettingField } from '../settings/StyledContainers'

/**
 *  =================================
 *     CHOOSE COUNTIES FOR REPORT
 *  =================================
 */
const SelectCounties = ({
  counties, setCounties, back, next, finishSubmission,
}) => {
  const [toggleAll, setToggleAll] = useState(true)
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Handle toggle all selected counties */
  const handleToggleAll = (e) => {
    const copy = counties.map((county) => ((!county.completed) ? ({ ...county, chosen: e.target.checked }) : (county)))
    setCounties(copy)
    setToggleAll(e.target.checked)
  }

  /* Change chosen counties for insights report */
  const handleSelection = (e, county) => {
    /* Update array of counties */
    const copy = counties.slice()
    const index = copy.findIndex((val) => val.id === county.id)
    copy.splice(index, 1, { ...county, chosen: e.target.checked })

    if (!e.target.checked) {
      setToggleAll(false)
    } else if (copy.filter(({ selected, completed }) => selected && !completed).every(({ chosen }) => chosen)) {
      /* If all non-completed projects have been toggle on then toggle on the TOGGLE ALL button */
      setToggleAll(true)
    }
    setCounties(copy)
  }

  return (
    <CenterForm style={{ padding: '20px', width: '50%', height: '100%' }}>
      <SettingField style={{ width: 'auto' }}>
        <h4>Insights Report</h4>
        <p>Before completing, you are required to justify the Alert State changes you have made by filling out an Insight Report for each county.</p>
        <p>You can report on multiple counties at once or review them individually by selecting them below and clicking ‘Report on selected’.</p>
      </SettingField>
      <IonGrid>
        <IonRow className='ion-align-items-center' style={{ height: '10%' }}>
          <IonCol size={1}> <IonCheckbox checked={toggleAll} onClick={(e) => handleToggleAll(e)} /> </IonCol>
          <IonCol size={7}> County </IonCol>
          <IonCol size={4}> Report Status</IonCol>
        </IonRow>
        <ColumnContainer style={{ height: '225px' }}>
          {
            counties.filter((county) => county.selected).map((county, index) => (
              <IonRow key={county.id} className='ion-align-items-center' style={{ backgroundColor: getColor(index), minHeight: '10%' }}>
                <IonCol size={1}>
                  {
                    (!county.completed) && (
                      <IonCheckbox checked={county.chosen} disabled={county.completed} onClick={(e) => handleSelection(e, county)} />
                    )
                  }
                </IonCol>
                <IonCol size={7}> { county.county } </IonCol>
                <IonCol style={{ color: (county.completed) ? '#0C9500' : '#C30101' }} size={3}> { (county.completed) ? 'Complete' : 'Incomplete' } </IonCol>
              </IonRow>
            ))
          }
        </ColumnContainer>
        <IonRow className='ion-justify-content-end'>
          <IonButton style={{ '--background': '#326771' }} disabled={!counties.find(({ chosen }) => chosen)} onClick={next}>Report on Selected</IonButton>
        </IonRow>
        <IonRow className='ion-justify-content-end'>
          <IonButton style={{ '--background': '#4197A9' }} onClick={back}>Back</IonButton>
          <IonButton style={{ '--background': '#326771' }} onClick={finishSubmission} disabled={!counties.filter(({ selected }) => selected).every((county) => county.completed)}>Finish</IonButton>
        </IonRow>
      </IonGrid>
    </CenterForm>
  )
}

export default SelectCounties
