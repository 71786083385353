import { Icon } from '@iconify/react'
import { IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { AppView } from '../../../hooks/engagement/types/HookInterface'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { ObscureBackground } from '../../GlobalContainers'
import { ContentContainer } from '../../incident-management/StyledContainers'
import FormPopup from '../../maps/forms/FormPopup'
import LoadingModal from '../../modals/LoadingModal'
import { CenterForm } from '../../riskregister/StyledContainers'
import { HomeButton, HomeButtonContainer, VerticalFlexContainer } from '../StyledContainers'

const Home : FC = () => {
  const {
    handleViewChange, loading, submittedMessage, setSubmittedMessage,
  } = useEngagementData()

  return (
    <ContentContainer>
      <LoadingModal isOpen={loading} />
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
      <VerticalFlexContainer>
        <IonRow style={{ borderBottom: '1px solid' }} className='vertical-child'>
          <HomeButtonContainer>
            <HomeButton onClick={() => handleViewChange(AppView.STAKEHOLDERS_SCREEN)}>
              <Icon style={{ fontSize: '5rem', color: '#326771' }} icon='mdi:user-details' />
              <h4 className='red-font'>Stakeholders</h4>
              <p>View a register and map of known stakeholders. </p>
            </HomeButton>
          </HomeButtonContainer>
          <HomeButtonContainer style={{ padding: '20px 35px' }}>
            <HomeButton onClick={() => handleViewChange(AppView.MESSAGING_SCREEN)}>
              <Icon style={{ fontSize: '5rem', color: '#326771' }} icon='bxs:message-rounded-dots' />
              <h4 className='red-font'>Messaging</h4>
              <p>Directly engage with stakeholders via direct messaging.</p>
            </HomeButton>
          </HomeButtonContainer>
          <HomeButtonContainer style={{ borderRight: 'none' }}>
            <HomeButton onClick={() => handleViewChange(AppView.CAMPAIGN_SCREEN)}>
              <Icon style={{ fontSize: '5rem', color: '#326771' }} icon='ic:round-send-to-mobile' />
              <h4 className='red-font'>Campaign</h4>
              <p>Create a campaign to send to stakeholders via SMS. </p>
            </HomeButton>
          </HomeButtonContainer>
        </IonRow>
        <IonRow className='vertical-child'>
          <HomeButtonContainer>
            <HomeButton>
              <Icon style={{ fontSize: '5rem', color: '#326771' }} icon='mdi:bulletin-board' />
              <h4 className='red-font'>Public Notice Board</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
            </HomeButton>
          </HomeButtonContainer>
          <HomeButtonContainer style={{ padding: '20px 35px' }}>
            <HomeButton>
              <Icon style={{ fontSize: '5rem', color: '#326771' }} icon='material-symbols:edit-calendar-rounded' />
              <h4 className='red-font'>Engagement Plan</h4>
              <p>Create and manage plans for stakeholder engagement.</p>
            </HomeButton>
          </HomeButtonContainer>
          <HomeButtonContainer style={{ borderRight: 'none' }} />
        </IonRow>
      </VerticalFlexContainer>
    </ContentContainer>
  )
}

export default Home
