import React, { useEffect, useState } from 'react'
import {
  IonRow, useIonAlert, IonList,
} from '@ionic/react'
import * as moment from 'moment'
import MapForm from '../forms/MapForm'
import JourneyForm2 from '../forms/JourneyForm2'
import { SimpleButton } from '../StyledContainers'
import useApi from '../../../hooks/testHooks'
import FormPopup from '../forms/FormPopup'
import { useJourneysData2 } from '../../../hooks/journeys/useJourneysData2'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { useAuth } from '../../../hooks/useAuth'

/**
 *  ===========================
 *        Journey Form
 *  ===========================
 */
const Journey2 = () => {
  /* Api hook and Movement boxes retrieved from the server */
  const apiHook = useApi()
  const {
    mapRef, setLoading, startRoute, routes, pushNewJourney,
    setShowJourneyForm, finishJourney, users, journeyDisplay,
  } = useJourneysData2()
  const { workspace } = useWorkspace()
  const { user } = useAuth()
  const [alert] = useIonAlert()
  const [submitted, setSubmitted] = useState(false)

  /* Close form */
  const onClose = () => {
    const close = () => {
      mapRef.current.easeTo({
        padding: {
          right: 0,
        },
      })
      setShowJourneyForm(false)
      finishJourney()
    }
    alert({
      header: 'Cancel Journey Request?',
      message: 'Do you wish to continue? Your progress will be lost. ',
      buttons: [
        'Back',
        {
          text: 'Yes, continue',
          handler: close,
        },
      ],
    })
  }

  const hideForm = () => {
    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })
    setShowJourneyForm(false)
    finishJourney()
  }

  /* Submit journey request */
  const onSubmit = async (input) => {
    /* Fixing some params */
    setLoading(true)
    const { message, id } = await (apiHook.requestJourney('', ...Object.values(input), workspace.id))

    console.log(input)
    /* If successful add new entry to the arrays that contain journeys data */
    if (id) {
      const approver = users.find((val) => val.id === input.approver)
      const leader = users.find((val) => input.leader_id === val.id)
      const journey = {
        id,
        name: '',
        created_at: moment().format(),
        updated_at: moment().format(),
        submitter: {
          ...user,
          id: user.user_id,
        },
        approver,
        purpose: input.purpose,
        departure_time: input.departure_time,
        departure_location: input.departure_location,
        leader_phone: input.leader_phone,
        driver_name: input.driver_name,
        driver_phone: input.driver_phone,
        vehicle_type: input.vehicle,
        vehicle_reg: input.vehicle_reg,
        emergency_sat: input.emergency_sat,
        emergency_other: input.emergency_other,
        arrival_time: input.arrival_time,
        arrival_location: input.arrival_location,
        passengers: input.passengers,
        passenger_hotel: input.passenger_hotel,
        passenger_location: input.passenger_location,
        passenger_contact: input.passenger_contact,
        driver_hotel: input.driver_hotel,
        driver_location: input.driver_location,
        driver_contact: input.driver_contact,
        status: 'Pending',
        chapters: [],
        journey_stages: input.stages.map((val) => ({
          path_id: val,
          created_at: moment().format(),
        })),
        leader,
      }

      pushNewJourney(journey)
    } else {
      // eslint-disable-next-line no-undef
      alert(message)
    }
    setLoading(false)
    setSubmitted(true)
  }

  useEffect(() => () => {
    mapRef.current.removeLayer('journey-routes-layer')
    mapRef.current.removeSource('journey-routes')
  }, [])

  return (
    <MapForm>
      {
        (!submitted) ? (
          <JourneyForm2 onCancel={onClose} onSubmit={onSubmit} drawRoutes={journeyDisplay} startRoute={startRoute} routes={routes} users={users}>
            <IonRow style={{ padding: '20px 10px' }} className='ion-align-items-center ion-justify-content-between'>
              <h2 style={{ fontSize: '1.3rem', color: '#8E151F' }}>Journey Request</h2>
              <SimpleButton
                onClick={onClose}
              >Close X
              </SimpleButton>
            </IonRow>
          </JourneyForm2>
        ) : (
          <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
            <FormPopup
              message='You have successfully submitted a Journey Request.'
              onClose={hideForm}
            />
          </IonList>
        )
      }
    </MapForm>
  )
}

export default Journey2
