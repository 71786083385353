import { IonRow, IonButton } from '@ionic/react'
import React, { FC } from 'react'
import { OverlayContainer } from '../../../tasks/StyledContainers'

interface Props {
  onClose: () => void,
  onContinue: () => void,
}

/**
 *  ==============================
 *      DELETE PROCEDURE POPUP
 *  ==============================
 */
const DeleteProcedure : FC<Props> = ({ onClose, onContinue }) => (
  <OverlayContainer style={{ zIndex: 20 }}>
    <h4 style={{ marginBottom: '15px' }} className='red-font'>Remove SOP?</h4>
    <p>Are you sure you wish to remove this SOP from the list?</p>
    <IonRow>
      <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
      <IonButton style={{ '--background': '#4197A9' }} onClick={onContinue}>Yes, Continue</IonButton>
    </IonRow>
  </OverlayContainer>
)

export default DeleteProcedure
