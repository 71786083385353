import React from 'react'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, ControlMeasureList } from '../../StyledContainers'
/**
 *  ===============================
 *     Review Form Selections
 *  ===============================
 */
const Review = ({
  input, onClose, onSubmit, children, onSubmitAdd, edit,
}) => (
  <CenterForm style={{ zIndex: '20', width: '90%' }}>
    <IonContent>
      <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
        <div>
          {
            children
          }
          <SettingField>
            <h5 className='blue'>Review</h5>
            <p>The following is a review of the Risk Event you have just created.</p>
          </SettingField>
          <IonRow style={{ marginBottom: '60px' }} className='ion-text-center non-margin-row'>
            <IonCol style={{ border: '1px solid #747474' }} size={2}>
              <SettingField><h5 className='blue'>Risk Event</h5></SettingField>
              <SettingField><p id='review-risk-event'> { input.riskEvent.value } </p></SettingField>
            </IonCol>
            <IonCol style={{ border: '1px solid #747474' }} size={2}>
              <SettingField><h5 className='blue'>Inherent Risk</h5></SettingField>
              <SettingField><p id='review-inherent-risk'> { input.inherentRisk } </p></SettingField>
            </IonCol>
            <IonCol style={{ border: '1px solid #747474' }} size={3}>
              <SettingField><h5 className='blue'>Existing Controls</h5></SettingField>
              <ControlMeasureList>
                {
                  input.existingMeasures.map((measure) => (
                    <li className='existing-measures-review' key={measure.id}> { measure.control_measure } </li>
                  ))
                }
              </ControlMeasureList>
            </IonCol>
            <IonCol style={{ border: '1px solid #747474' }} size={3}>
              <SettingField><h5 className='blue'>New Controls</h5></SettingField>
              <ControlMeasureList>
                {
                  input.newMeasures.map((measure) => (
                    <li className='new-measures-review' key={measure.id}> { measure.control_measure } </li>
                  ))
                }
              </ControlMeasureList>
            </IonCol>
            <IonCol style={{ border: '1px solid #747474' }} size={2}>
              <SettingField><h5 className='blue'>Residual Risk</h5></SettingField>
              <SettingField><p id='review-residual-risk'> { input.residualRisk } </p></SettingField>
            </IonCol>
          </IonRow>
          {
            (edit) ? (
              <p>Click update to submit this Risk Review.</p>
            ) : (
              <p>Click Submit & Add New to submit this Risk Event and add another. Click Submit & Exit to submit this Risk Event and return to the Risk Register screen.</p>
            )
          }
        </div>
        <IonRow className='ion-justify-content-between'>
          <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
          {
            (edit) ? (
              <IonButton id='update-risk-assessment' style={{ '--background': '#326771' }} onClick={onSubmit}>Update</IonButton>
            ) : (
              <IonRow>
                <IonButton id='submit-and-new-button' style={{ '--background': '#326771' }} onClick={onSubmitAdd}>Submit & Add New</IonButton>
                <IonButton id='submit-assessment-button' style={{ '--background': '#326771' }} onClick={onSubmit}>Submit & Exit</IonButton>
              </IonRow>
            )
          }
        </IonRow>
      </IonList>
    </IonContent>
  </CenterForm>
)

export default Review
