import * as React from 'react'
import {
  IonGrid, IonRow, IonLabel, IonCheckbox,
  IonButton,
} from '@ionic/react'
import { Dropdown } from 'react-bootstrap'
import { Icon } from '@iconify/react'
import { useInsightsData } from '../../hooks/insights/useInsightsData'
import { SelectView } from '../maps/StyledContainers'
import { AsFilterUpdate, InsightViews } from '../../hooks/insights/helpers/StateReducers'
import { InsightFiltersActionKind } from '../../hooks/insights/helpers/FilterReducers'
import { LineDivider } from '../GlobalContainers'

import useApi from '../../hooks/testHooks'
import { useAuth } from '../../hooks/useAuth'
import { useWorkspace } from '../../hooks/useWorkspace'
import { IncidentTimeFilter } from '../types/OptimizedMaps'
import { getNextIncidentsConfig } from '../utils/MapboxUtils'

import axios from '../../utils/axios'
import { FetchActionKind } from '../utils/Reducers'

/**
 *  ================================
 *      NEW INSIGHT CONTROLS
 *  ================================
 */
const MapControls : React.FC = () => {
  const [show, setShow] = React.useState<boolean>(false)
  const {
    view, handleViewChange, timeFilter, incidentFilters, updateIncidents, asFilters, incidentFetch, dispatchIncidentFetch,
    updateAsFilters, setShowSafetyChecks, setMobile, setShowIncidentF, mapRef,
    setShowInsightF, incidents, setIncidents, setFieldSitrepRequest, setShowMapFilterPopup,
  } = useInsightsData()
  const apiHook = useApi()

  const { user } = useAuth()
  const { workspace } = useWorkspace()

  const startIncidentForm = () => {
    setMobile(false)
    setShowIncidentF(true)
    handleViewChange(InsightViews.INCIDENTS)
    updateIncidents({ type: InsightFiltersActionKind.TOGGLE_ALL_FALSE }, null, null)
    mapRef.current.easeTo({
      padding: {
        right: 300,
        top: 0,
        left: 0,
        bottom: 0,
      },
    })
  }

  /* Toggle fitler dropdown handler */
  const onToggleHandler = (isOpen : boolean, metadata: { source: string }) => {
    if (metadata.source !== 'select') {
      setShow(isOpen)
    }
  }

  const handleTimeChange = (newTime: IncidentTimeFilter) => {
    const reqConfig = getNextIncidentsConfig(incidentFetch.serverTime, incidentFetch.oldestFetch, newTime)
    /* If null then incidents have already been fetched so just need to update map */
    if (!reqConfig) {
      updateIncidents(null, newTime, null)
      return
    }

    dispatchIncidentFetch({ type: FetchActionKind.FETCH_INCIDENTS, payload: { oldestFetch: newTime } })
    axios.post('/api/v2/incident/incidents', { domain_id: workspace.id, ...reqConfig }).then(({ data }) => {
      dispatchIncidentFetch({ type: FetchActionKind.RECEIVED_INCIDENTS, payload: { serverTime: data.server_from } })

      const updatedIncidents = [...incidents, ...data.incidents]
      setIncidents(updatedIncidents)
      updateIncidents(null, newTime, updatedIncidents)
    })
  }

  const promptSelf = () => {
    apiHook.promptOneSafety(user.user_id, workspace.id)
  }

  return (
    <IonGrid
      style={{
        backgroundColor: '#FFFFFF',
        padding: '10px 30px',
        marginBottom: '5px',
        boxShadow: '0px 4px 4px 0px #00000040',
        position: 'relative',
        borderRadius: '5px',
      }}
      className='insight-controls-bar'
    >
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <SelectView
          value={view}
          onChange={(e) => handleViewChange(e.target.value)}
          id='select-app-view'
        >
          <option value={InsightViews.INCIDENTS}>Security</option>
          <option value={InsightViews.ALERT_STATES}>Alert States</option>
          <option value={InsightViews.TEAM_SAFETY}>Team Safety</option>
        </SelectView>
        <IonRow className='ion-align-items-center'>
          <SelectView style={{ margin: '0 10px' }} id='incident-time-filter' value={timeFilter} onChange={(e) => handleTimeChange(e.target.value)}>
            <option value={IncidentTimeFilter.LAST_WEEK}>Last Week</option>
            <option value={IncidentTimeFilter.LAST_MONTH}>Last Month</option>
            <option value={IncidentTimeFilter.SIX_MONTHS}>Last 6 Months</option>
            <option value={IncidentTimeFilter.LAST_YEAR}>Last Year</option>
            <option value={IncidentTimeFilter.ALL_FETCH}>All Time</option>
          </SelectView>
          <>
            <IonButton onClick={() => setShowMapFilterPopup(true)} style={{ '--background': '#326771' }}>Map Filters<Icon icon='fa:filter' /></IonButton>
          </>
          <Dropdown className='desktop-app-header' style={{ margin: '0 10px' }}>
            <Dropdown.Toggle style={{ backgroundColor: '#0C9500' }} className='map-button remove-arrow'> Add <Icon icon='fa-plus' /></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={startIncidentForm}> Security Report </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowInsightF(true)}> Field Sitrep </Dropdown.Item>
              <Dropdown.Item onClick={() => promptSelf()}> My Safety Update </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowSafetyChecks(true)}> Team Safety Request </Dropdown.Item>
              <Dropdown.Item onClick={() => setFieldSitrepRequest(true)}>Field SitRep Request</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </IonRow>
      </IonRow>
    </IonGrid>
  )
}

export default MapControls
