/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import {
  IonRow, IonContent, useIonAlert, IonSpinner,
} from '@ionic/react'
import React, { useState, useEffect, useRef } from 'react'
import { Icon } from '@iconify/react'
import { createPath, generateChapter } from '../apps/AppMap'
import MapForm from '../forms/MapForm'
import PreTravelForm from '../forms/PreTravelForm'
import { AirJourneyStageContainer, SimpleButton } from '../StyledContainers'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import useApi from '../../../hooks/testHooks'
import { useAuth } from '../../../hooks/useAuth'
import FlightRequestForm from '../forms/FlightRequestForm'
import AirJourneyTicket from './AirJourneyTicket'
import { ObscureBackground, CenterLoader } from '../../GlobalContainers'
import { BlueButton } from '../../incident-management/StyledContainers'

/**
 *  ==============================
 *       AIR  JOURNEY ITINERARY
 *  ==============================
 */
const AirJourney = ({
  onClose, focusedJourney, addAirJourney, domainId, displaySubmitted, dbUsers,
  mapRef, setFromPin, setToPin, fromPin, toPin, setFocusedJourney, updateAirJourney,
}) => {
  /* States to keep track of which form/ticket to show */
  const [showPreTravelForm, setShowPreTravelForm] = useState(!(focusedJourney))
  const [showFlightRequest, setShowFlightRequest] = useState(false)
  const [showFlightTicket, setShowFlightTicket] = useState(false)
  const [currentJourney, setCurrentJourney] = useState(null)
  const [loading, setLoading] = useState(false)
  const contentRef = useRef()

  const apiHook = useApi()
  const { user } = useAuth()
  const [ionAlert] = useIonAlert()

  const stages = ['Pre-Travel', 'Flight-Request', 'Pre-Departure', 'In-Transit', 'Complete']
  const ticketStages = ['Pre-Departure', 'In-Transit', 'Complete']

  const canOpen = (expected) => {
    if (!focusedJourney) {
      if (expected === 'Pre-Travel') return true
      return false
    }
    if (focusedJourney.status === 'Cancelled') return false

    if (expected === 'Flight-Request') {
      if (focusedJourney.stage === 'Pre-Travel' && focusedJourney.status === 'Approved' && focusedJourney.submitter.id === user.user_id) return true
    }

    if (stages.indexOf(focusedJourney.stage) < stages.indexOf(expected)) {
      return false
    }

    return true
  }

  const relativeStatus = (expected) => {
    /* When journey's stage is greater than all previous stages have been approved */
    if (stages.indexOf(focusedJourney.stage) > stages.indexOf(expected)) {
      return 'Approved'
    }
    if (stages.indexOf(focusedJourney.stage) === stages.indexOf(expected)) {
      return focusedJourney.status
    }

    return 'Not-Submitted'
  }

  const colorCodeStatus = (status) => (
    {
      Pending: '#747474',
      'Pre-Departure': '#4197A9',
      'In-Transit': '#FF8A00',
      Complete: '#0C9500',
      Abort: '#eb4034',
      Declined: '#eb4034',
      Cancelled: '#DD2800',
    }[status]
  )

  const pendingOrApproved = (expected) => {
    if (stages.indexOf(focusedJourney.stage) > stages.indexOf(expected)) {
      return '#0C9500'
    }

    if (focusedJourney.stage === expected) {
      if (focusedJourney.status === 'Pending') {
        return '#326771'
      }
      if (focusedJourney.status === 'Approved') {
        return '#0C9500'
      }
      if (focusedJourney.status === 'Declined') {
        return '#DD2800'
      }
    }

    return '#C4C4C4'
  }

  const formatJourneyInputs = (input, id, stage = 'Pre-Travel', status = 'Pending') => ({
    stage,
    status,
    from: input.from,
    fromLng: input.fromLng,
    fromLat: input.fromLat,
    to: input.to,
    toLng: input.toLng,
    toLat: input.toLat,
    purpose: input.purpose,
    comment: input.comment,
    approver: input.approver,
    departure_date: input.departureTime,
    submitter: {
      first_name: user.first_name,
      final_name: user.final_name,
      id: user.user_id,
    },
    submitterName: `${user.first_name} ${user.final_name}`,
    id,
    story: input.story ? input.story : [],
  })

  /* Submit air journey pre-travel request */
  const onPreTravelAirSubmit = (input) => {
    /* Fixing some params */
    setLoading(true)
    apiHook.requestAirPreTravel(
      input.approver, input.purpose, input.departure_date, input.from,
      input.to, null, input.comment, input.fromLng, input.fromLat, input.toLng, input.toLat, domainId,
    ).then(({ id, story }) => {
      /* Create journey object to be handled in the frontend */
      const approver = dbUsers.find((dbUser) => dbUser.id === input.approver)
      const chapters = [generateChapter(story, 'Pre-Travel', id, user.user_id)]
      const journey = formatJourneyInputs({ ...input, story: chapters, approver: { first_name: approver.first_name, final_name: approver.final_name, id: input.approver } }, id)
      addAirJourney(journey)
      setLoading(false)
      displaySubmitted('Pre-Travel Request submitted successfully')
      setShowPreTravelForm(false)
    })
  }

  /* Approve an Air journey request */
  const handleApprove = (newStatus, newStage) => {
    ionAlert({
      header: 'Flight request approval',
      message: 'Are you sure you want to approve this flight request?',
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: 'Additional comments (optional)',
        },
      ],
      buttons: [
        'Cancel',
        {
          text: 'Approve',
          handler: (data) => {
            setLoading(true)
            apiHook.progressAirJourney(focusedJourney.id, data.reason, domainId).then(({ story }) => {
              const newChapter = generateChapter(story, `${(newStage === 'Pre-Departure' ? 'Flight-Request' : newStage)} Approved`, focusedJourney.id, user.user_id)
              const updatedJourney = {
                ...focusedJourney, story: [...focusedJourney.story, newChapter], status: newStatus, stage: newStage,
              }
              /* TODO: Send request to approve journey on the backend */
              updateAirJourney(updatedJourney)
              setFocusedJourney(updatedJourney)
              displaySubmitted('Pre-Travel Request Authorised successfully')
              setShowPreTravelForm(false)
              setShowFlightRequest(false)
              setLoading(false)
            })
          },
        },
      ],
    })
  }

  const handleSubmitPreTravelEdit = (input) => {
    setLoading(true)
    apiHook.editAirPreTravel(
      input.approver, input.purpose, input.departure_date,
      input.from, input.to, input.comment, input.fromLng, input.fromLat, input.toLng,
      input.toLat, domainId, input.id,
    ).then(() => {
      const updatedJourney = formatJourneyInputs(input, input.id)
      updateAirJourney(updatedJourney)
      setFocusedJourney(updatedJourney)
      displaySubmitted('Pre-Travel Request edited successfully')
      setShowPreTravelForm(false)
      setLoading(false)
    })
  }

  const handleSubmitFlightReq = (input) => {
    setLoading(true)
    apiHook.requestAirFlightReq(input.airline_name, input.flight_no, input.departureTime, input.passengers, focusedJourney.id, domainId)
      .then(() => {
        const updatedJourney = {
          ...focusedJourney,
          airline_name: input.airline_name,
          flight_no: input.flight_no,
          flight_departure_date: input.departureTime,
          passengers: input.passengers,
          stage: 'Flight-Request',
          status: 'Pending',
        }
        updateAirJourney(updatedJourney)
        setFocusedJourney(updatedJourney)
        displaySubmitted('Flight Request submitted successfully')
        setShowFlightRequest(false)
        setLoading(false)
      })
  }

  /* Decline Air journey  stage */
  const handleDecline = (stage) => {
    ionAlert({
      header: 'Decline flight request?',
      message: 'Are you sure you want to decline this request and allow the user to resubmit a new flight request?',
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: 'Additional comments (optional)',
        },
      ],
      buttons: [
        'Cancel',
        {
          text: 'decline',
          handler: (data) => {
            setLoading(true)
            apiHook.denyAirJourney(focusedJourney.id, data.reason, domainId).then(({ story }) => {
              const newChapter = generateChapter(story, `${stage} Declined`, focusedJourney.id, user.user_id)
              const updatedJourney = { ...focusedJourney, story: [...focusedJourney.story, newChapter], status: 'Declined' }
              updateAirJourney(updatedJourney)
              setShowFlightRequest(false)
              setShowPreTravelForm(false)
              setFocusedJourney(updatedJourney)
              setLoading(false)
            })
          },
        },
      ],
    })
  }

  /* Cancel an Air journey request */
  const cancelRequest = () => {
    ionAlert({
      header: 'Are you sure?',
      message: 'Clicking continue will decline this request and cancel the journey completely?. This action cannot be undone.',
      buttons: [
        'Back',
        {
          text: 'Yes, continue',
          handler: () => {
            setLoading(true)
            apiHook.abortAirJourney(focusedJourney.id, domainId).then(({ story }) => {
              const newChapter = generateChapter(story, 'Status Change', focusedJourney.id, user.user_id)
              const updatedJourney = {
                ...focusedJourney, story: [...focusedJourney.story, newChapter], status: 'Cancelled', stage: 'Aborted',
              }
              /* TODO: Send request to decline  journey on the backend */
              updateAirJourney(updatedJourney)
              setFocusedJourney(updatedJourney)
              displaySubmitted('Journey Cancelled')
              setShowFlightRequest(false)
              setShowPreTravelForm(false)
              setShowFlightTicket(false)
              setLoading(false)
            })
          },
        },
      ],
    })
  }

  const handleStatus = (e) => {
    if (e.stopPropagation) e.stopPropagation()
    setLoading(true)
    const progress = async (newStatus) => {
      /* Change status in the db */
      apiHook.progressAirJourney(focusedJourney.id, '', domainId).then(({ story }) => {
        const chapter = generateChapter(story, 'status_change', focusedJourney.id, user.user_id)
        const newStory = (focusedJourney.story) ? [...focusedJourney.story, chapter] : [chapter]

        const updatedJourney = { ...focusedJourney, stage: newStatus, story: newStory }
        updateAirJourney(updatedJourney)
        setFocusedJourney(updatedJourney)
        setLoading(false)
      })
    }

    if (e.target.value === 'Cancel') {
      ionAlert({
        header: 'Abort Journey?',
        message: 'Do you wish to continue? This action cannot be undone. ',
        buttons: [
          {
            text: 'Back',
            handler: () => {
              setLoading(false)
            },
          },
          {
            text: 'Yes, continue',
            handler: async () => {
              apiHook.denyAirJourney(focusedJourney.id, '', domainId).then(({ story }) => {
                const chapter = generateChapter(story, 'status_change', focusedJourney.id, user.user_id)
                const newStory = [...focusedJourney.story, chapter]

                const updatedJourney = {
                  ...focusedJourney, stage: 'Aborted', status: 'Cancelled', story: newStory,
                }
                updateAirJourney(updatedJourney)
                setFocusedJourney(focusedJourney)
                setLoading(false)
              })
            },
          },
        ],
      })
    } else if (e.target.value === 'In-Transit') {
      ionAlert({
        header: 'Progress journey?',
        message: 'Clicking "Yes" will change the journey\'s status from pre-departure to in transit. This action cannot be undone.',
        buttons: [
          {
            text: 'cancel',
            handler: () => {
              setLoading(false)
            },
          },
          {
            text: 'yes',
            handler: (async () => {
              progress('In-Transit')
            }),
          },
        ],
      })
    } else if (e.target.value === 'Complete') {
      ionAlert({
        header: 'Progress journey?',
        message: 'Clicking continue will move the journey\'s status from In Transit to Completed.\n\n '
        + 'This action cannot be undone',
        buttons: [
          {
            text: 'Back',
            handler: () => {
              setLoading(false)
            },
          },
          {
            text: 'Yes, continue',
            handler: () => progress('Complete'),
          },
        ],
      })
    }
  }
  const defineOptions = (status) => {
    const statusIndex = ticketStages.indexOf(status)

    const current = ticketStages.slice(statusIndex, statusIndex + 2)
    current.push('Cancel')

    return current
  }
  const scrollToBottom = () => contentRef.current && contentRef.current.scrollToBottom()

  /* Draw Air Journey route */
  useEffect(() => {
    if (focusedJourney && (!currentJourney || currentJourney.id !== focusedJourney.id)) {
      /* If marker already exists then remove and add new one */
      const fromMarker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
        .setLngLat([focusedJourney.fromLng, focusedJourney.fromLat])
        .addTo(mapRef.current)
      if (fromPin) fromPin.remove()
      setFromPin(fromMarker)

      const toMarker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
        .setLngLat([focusedJourney.toLng, focusedJourney.toLat])
        .addTo(mapRef.current)
      if (toPin) toPin.remove()
      setToPin(toMarker)

      createPath({ center: [focusedJourney.fromLng, focusedJourney.fromLat] }, { center: [focusedJourney.toLng, focusedJourney.toLat] }, mapRef)
      setCurrentJourney(focusedJourney)
    }
  }, [focusedJourney])

  useEffect(() => () => {
    mapRef.current.getSource('flight-source').setData({
      type: 'FeatureCollection',
      features: [],
    })
  }, [])

  return (
    <MapForm>
      {
        loading && (
          <ObscureBackground>
            <CenterLoader>
              <IonSpinner color='light' />
            </CenterLoader>
          </ObscureBackground>
        )
      }
      <IonContent ref={contentRef}>
        <div className='flex-direction-column'>
          <div className='flex-grow'>
            <AirJourneyStageContainer>
              <SimpleButton
                disabled={!canOpen('Pre-Travel')}
                className='air-journey-button available'
                onClick={() => setShowPreTravelForm(!showPreTravelForm)}
                id='display-pre-travel'
              >
                <IonRow className='ion-align-items-center ion-justify-content-between'>
                  <h3 style={{ fontSize: '1.3rem' }}>Pre Travel Request</h3>
                  <IonRow className='ion-align-items-center'>
                    {
                      focusedJourney && (
                        <>
                          {
                            (focusedJourney.status !== 'Cancelled') ? (
                              <p className='journey-status' style={{ backgroundColor: pendingOrApproved('Pre-Travel') }}>
                                { relativeStatus('Pre-Travel') }
                              </p>
                            ) : (
                              <p className='journey-status' style={{ backgroundColor: '#DD2800' }}>
                                Cancelled
                              </p>
                            )
                          }
                        </>
                      )
                    }
                    {
                      (showPreTravelForm) ? (
                        <Icon icon='ep:arrow-up' />
                      ) : (
                        <Icon icon='ep:arrow-down' />
                      )
                    }
                  </IonRow>
                </IonRow>
              </SimpleButton>
              <div style={{ display: (showPreTravelForm) ? 'block' : 'none' }}>
                <PreTravelForm
                  onCancel={onClose}
                  onSubmit={onPreTravelAirSubmit}
                  onSubmitEdit={handleSubmitPreTravelEdit}
                  cancelRequest={cancelRequest}
                  users={dbUsers.map(({ id, first_name, final_name }) => ({ id, username: `${first_name} ${final_name}` }))}
                  mapRef={mapRef}
                  defaultValues={focusedJourney}
                  setFromPin={setFromPin}
                  fromPin={fromPin}
                  setToPin={setToPin}
                  toPin={toPin}
                  onDecline={handleDecline}
                  onApprove={handleApprove}
                />
              </div>
            </AirJourneyStageContainer>
            <AirJourneyStageContainer>
              <SimpleButton
                disabled={!(canOpen('Flight-Request')
              && focusedJourney && focusedJourney.status !== 'Cancelled')}
                className={`air-journey-button ${(canOpen('Flight-Request')
              && focusedJourney) ? 'available' : ''}`}
                onClick={() => setShowFlightRequest(!showFlightRequest)}
                id='display-flight-request'
              >
                <IonRow className='ion-align-items-center ion-justify-content-between'>
                  <h3 style={{ fontSize: '1.3rem' }}>Flight Request</h3>
                  <IonRow className='ion-align-items-center'>
                    {
                      focusedJourney && (
                        <>
                          {
                            (focusedJourney.status === 'Cancelled') ? (
                              <p className='journey-status' style={{ backgroundColor: '#DD2800' }}>
                                Cancelled
                              </p>
                            ) : (
                              <>
                                {
                                  !(focusedJourney.stage === 'Pre-Travel' && (focusedJourney.status === 'Pending' || focusedJourney.status === 'Declined')) && (
                                    <p id='flight-request-status-label' className='journey-status' style={{ backgroundColor: pendingOrApproved('Flight-Request') }}>
                                      { relativeStatus('Flight-Request') }
                                    </p>
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                    {
                      (showFlightRequest) ? (
                        <Icon icon='ep:arrow-up' />
                      ) : (
                        <Icon icon='ep:arrow-down' />
                      )
                    }
                  </IonRow>
                </IonRow>
              </SimpleButton>
              {
                focusedJourney && (
                  <div style={{ display: (showFlightRequest) ? 'block' : 'none' }}>
                    <FlightRequestForm
                      users={dbUsers}
                      onSubmit={handleSubmitFlightReq}
                      defaultValues={focusedJourney}
                      onDecline={handleDecline}
                      onApprove={handleApprove}
                    />
                  </div>
                )
              }
            </AirJourneyStageContainer>
            <AirJourneyStageContainer>
              <SimpleButton
                disabled={!(focusedJourney || (focusedJourney && focusedJourney.status === 'Cancelled'))}
                className={`air-journey-button ${(focusedJourney) ? 'available' : ''}`}
                onClick={() => setShowFlightTicket(!showFlightTicket)}
                id='display-air-ticket'
              >
                <IonRow className='ion-align-items-center ion-justify-content-between'>
                  <h3 style={{ fontSize: '1.3rem' }}>Journey Ticket</h3>
                  <IonRow className='ion-align-items-center'>
                    {
                      focusedJourney && (
                        <>
                          {
                            (ticketStages.indexOf(focusedJourney.stage) >= 0 && focusedJourney.submitter.id === user.user_id
                            && focusedJourney.stage !== 'Complete' && focusedJourney.stage !== 'Aborted')
                              ? (
                                <select
                                  onClick={(e) => e.stopPropagation()}
                                  className='journey-status'
                                  style={{ backgroundColor: colorCodeStatus(focusedJourney.stage) }}
                                  value={focusedJourney.stage}
                                  id='air-journey-status-dropdown'
                                  onChange={handleStatus}
                                >
                                  {
                                    defineOptions(focusedJourney.stage).map((status) => (
                                      <option key={status} value={status}>{ status }</option>
                                    ))
                                  }
                                </select>
                              )
                              : (
                                <p className='journey-status' style={{ backgroundColor: colorCodeStatus(focusedJourney.stage) }}>{ focusedJourney.stage }</p>
                              )
                          }
                        </>
                      )
                    }
                    {
                      (showFlightTicket) ? (
                        <Icon icon='ep:arrow-up' />
                      ) : (
                        <Icon icon='ep:arrow-down' />
                      )
                    }
                  </IonRow>
                </IonRow>
              </SimpleButton>
              {
                focusedJourney && (
                  <div style={{ display: (showFlightTicket) ? 'block' : 'none' }}>
                    <AirJourneyTicket
                      focusedJourney={focusedJourney}
                      setLoading={setLoading}
                      loading={loading}
                      updateAirJourney={(journey) => {
                        updateAirJourney(journey)
                        setFocusedJourney(journey)
                      }}
                      domainId={domainId}
                      scrollToBottom={scrollToBottom}
                      onTransit={() => handleStatus({ target: { value: 'In-Transit' } })}
                      onComplete={() => handleStatus({ target: { value: 'Complete' } })}
                    />
                  </div>
                )
              }
            </AirJourneyStageContainer>
          </div>
          {
            focusedJourney && focusedJourney.submitter.id === user.user_id && !showFlightRequest && !showPreTravelForm && !showFlightTicket && (
              <IonRow style={{ padding: '10px' }} className='ion-justify-content-end'>
                <BlueButton style={{ color: '#DD2800', fontSize: '1rem' }} onClick={cancelRequest}>Withdraw Journey Request</BlueButton>
              </IonRow>
            )
          }
        </div>
      </IonContent>
    </MapForm>
  )
}

export default AirJourney
