/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  IonList, IonInput, IonItem,
  IonButton, IonRow, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import AsyncSearch from 'react-select/async'
import Select from 'react-select'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'
import moment from 'moment'
import { BlueButton } from '../../incident-management/StyledContainers'
import { FormTitle } from '../StyledContainers'
import axios from '../../../utils/axios'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import AddAirport from './AddAirport'
import { createPath, getCurrentLevel } from '../apps/AppMap'
import { useAuth } from '../../../hooks/useAuth'

/**
 *  ==========================
 *    PRE TRAVEL REQUEST FORM
 *  ==========================
 */
const empty = {
  supervisor: '',
  purpose: '',
  airlineName: '',
  flightNo: '',
  arrivalTime: '',
  to: '',
  departureTime: '',
  from: '',
  passengers: [],
}

const PreTravelForm = ({
  onCancel, onSubmit, users, onDecline, onApprove,
  defaultValues, mapRef, onSubmitEdit,
  setFromPin, setToPin, toPin, fromPin, cancelRequest,
}) => {
  /* Manage form data */
  const [input, setInput] = useState(defaultValues || empty)
  const [editing, setEditing] = useState(false)
  const [readonly, setReadonly] = useState(!!defaultValues)
  const [pinField, setPinField] = useState('')
  const [showAddPin, setShowAddPin] = useState(false)
  const [ionAlert] = useIonAlert()
  const { user } = useAuth()

  const returnMessage = (key) => {
    /* Purpose details */
    if (key === 'purpose') return { header: 'Error Purpose', message: 'Please specify the purpose of the journey' }

    if (key === 'from') return { header: 'Error Origin Location', message: 'Please specify where you want to fly from' }

    if (key === 'to') return { header: 'Error Destination', message: 'Please specify where you want to fly to' }
    if (key === 'supervisor') return { header: 'Error Supervisor', message: 'Please Specify a supervisor for this journey request.' }
    if (key === 'departureTime') return { header: 'Error Departure date', message: 'Please Specify a departure date.' }
    return null
  }

  const editJourney = () => {
    const inputField = {
      ...input,
      from: { value: input.from, label: input.from, center: [input.fromLng, input.fromLat] },
      to: { value: input.to, label: input.to, center: [input.toLng, input.toLat] },
      departureTime: moment(input.departure_date),
    }
    setEditing(true)
    setReadonly(false)
    setInput(inputField)
  }

  /* Handle submit form -> Check required inputs */
  const handleSubmit = () => {
    const notFilled = Object.keys(input).find((key) => !input[key])
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    if (toPin && !editing) toPin.remove()
    if (fromPin && !editing) fromPin.remove()

    const newJourney = {
      approver: input.supervisor.value,
      purpose: input.purpose,
      departure_date: input.departureTime.format('YYYY-MM-DD HH:mm'),
      from: input.from.value,
      to: input.to.value,
      stages: null,
      comment: input.comment,
      fromLng: input.from.center[0],
      fromLat: input.from.center[1],
      toLng: input.to.center[0],
      toLat: input.to.center[1],
    }

    if (editing) {
      onSubmitEdit({ ...newJourney, id: input.id })
      return
    }
    onSubmit(newJourney)
  }

  /* Chand input state */
  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  const loadMapboxLocations = (inputvalue, callback) => {
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${inputvalue}.json?access_token=${mapboxgl.accessToken}`)
      .then(({ data }) => {
        const options = data.features.map(({ place_name, center }) => ({ value: place_name, label: place_name, center }))
        callback(options)
      })
  }

  const addDropdownPin = (field, selected) => {
    setInput({ ...input, [field]: selected })
    /* add marker to the map */
    /* If the form is visible and no pin has been added then create a marker */
    const marker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      .setLngLat(selected.center)
      .addTo(mapRef.current)

    if (field === 'to') {
      if (toPin) toPin.remove()
      if (fromPin) createPath(input.from, selected, mapRef)
      setToPin(marker)
    } else {
      if (toPin) createPath(selected, input.to, mapRef)
      if (fromPin) fromPin.remove()
      setFromPin(marker)
    }
  }

  const onClose = () => {
    setShowAddPin(false)
    setPinField('')
  }

  /**
   *  ============================
   *        CUSTOM PINS
   *  ============================
   */
  const addFromPin = (locationName, marker) => {
    const fromInput = { value: locationName, label: locationName, center: [marker.getLngLat().lng, marker.getLngLat().lat] }
    setInput({ ...input, from: fromInput })
    onClose()

    if (fromPin) fromPin.remove()
    if (toPin) createPath(fromInput, input.to, mapRef)
    marker.setDraggable(false)
    setFromPin(marker)
  }

  const addToPin = (locationName, marker) => {
    const toInput = { value: locationName, label: locationName, center: [marker.getLngLat().lng, marker.getLngLat().lat] }
    setInput({ ...input, to: toInput })
    onClose()

    if (toPin) toPin.remove()
    if (fromPin) createPath(input.from, toInput, mapRef)

    marker.setDraggable(false)
    setToPin(marker)
  }

  useEffect(() => {
    if (defaultValues) {
      setInput(defaultValues)
      setReadonly(true)
    }
  }, [defaultValues])

  // pre travel request
  return (
    <>
      {
        (showAddPin) ? (
          <AddAirport
            onSubmit={(pinField === 'from') ? addFromPin : addToPin}
            mapRef={mapRef}
            onCancel={onClose}
          />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ overflowY: 'auto', flexGrow: '1' }}>
              <IonList style={{ padding: '15px', paddingTop: '0' }}>
                <div>
                  <FormTitle style={{ margin: '0' }}>
                    <h3 style={{ fontSize: '1rem' }}>From</h3>
                  </FormTitle>
                  <div style={{ margin: '10px 0' }}>
                    {
                      (readonly) ? (
                        <p id='pre-travel-from-location'>{input.from}</p>
                      ) : (
                        <>
                          <AsyncSearch
                            placeholder='Type an airport name'
                            // eslint-disable-next-line no-undef
                            menuPortalTarget={document.body}
                            className='settings-select'
                            value={input.from}
                            loadOptions={loadMapboxLocations}
                            onChange={(selected) => addDropdownPin('from', selected)}
                          />
                          Find on
                          <BlueButton
                            style={{ marginLeft: '5px' }}
                            onClick={() => {
                              setPinField('from')
                              setShowAddPin(true)
                            }}
                            id='pick-from-airport'
                          >Map
                          </BlueButton>
                          <Icon style={{ color: '#C30101' }} icon='ic:round-pin-drop' />
                        </>
                      )
                    }
                  </div>
                </div>
                <div>
                  <FormTitle>
                    <h3 style={{ fontSize: '1rem' }}>To</h3>
                  </FormTitle>
                  {
                    (readonly) ? (
                      <p id='pre-travel-to-location'>{input.to}</p>
                    ) : (
                      <>
                        <AsyncSearch
                          placeholder='Type an airport name'
                          // eslint-disable-next-line no-undef
                          menuPortalTarget={document.body}
                          className='settings-select'
                          value={input.to}
                          loadOptions={loadMapboxLocations}
                          onChange={(selected) => addDropdownPin('to', selected)}
                        />
                        Find on
                        <BlueButton
                          style={{ marginLeft: '5px' }}
                          onClick={() => {
                            setPinField('to')
                            setShowAddPin(true)
                          }}
                          id='pick-to-airport'
                        >Map
                        </BlueButton>
                        <Icon style={{ color: '#C30101' }} icon='ic:round-pin-drop' />
                      </>
                    )
                  }
                </div>
                <FormTitle>
                  <h3 style={{ fontSize: '1rem' }}>Date of departure</h3>
                </FormTitle>
                {
                  (readonly) ? (
                    <p>{moment(input.departureTime).format('DD/MM/YYYY')}</p>
                  ) : (
                    <>
                      <Datetime
                        dateFormat='YYYY-MM-DD'
                        inputProps={{
                          placeholder: 'Departure date/time',
                          readOnly: true,
                          style: { backgroundColor: 'white' },
                        }}
                        className='departure-date'
                        value={input.departureTime}
                        onChange={(e) => setInput({ ...input, departureTime: e })}
                        isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
                      />
                    </>
                  )
                }
                <FormTitle>
                  <h3 style={{ fontSize: '1rem' }}>Reason for travel</h3>
                </FormTitle>
                {
                  (readonly) ? (
                    <p>{input.purpose}</p>
                  ) : (
                    <IonItem>
                      <IonInput id='air-travel-purpose' onIonChange={handleInput} value={input.purpose} name='purpose' type='text' placeholder='Reason for travel' />
                    </IonItem>
                  )
                }
                <FormTitle>
                  <h3 style={{ fontSize: '1rem' }}>Additional comments</h3>
                </FormTitle>
                {
                  (readonly) ? (
                    <p>{input.comment}</p>
                  ) : (
                    <IonItem>
                      <IonInput id='air-travel-comments' onIonChange={handleInput} value={input.comment} name='comment' type='text' placeholder='Additional comments' />
                    </IonItem>
                  )
                }
                {
                  (!readonly) && (
                    <>
                      <FormTitle>
                        <h2 style={{ fontSize: '1.2rem', marginTop: '10px' }}>Authorised By</h2>
                      </FormTitle>
                      <Select
                        placeholder='Select user'
                        name='supervisor'
                        className='select-container'
                        id='select-supervisor-container'
                        // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={users.map((dbUser) => ({ value: dbUser.id, label: dbUser.username }))}
                        onChange={(selected) => setInput({ ...input, supervisor: selected })}
                      />
                    </>
                  )
                }
              </IonList>
            </div>
            {
              (readonly) ? (
                <>
                  {
                    defaultValues && defaultValues.alert_state && (
                      <div style={{ margin: '15px', borderTop: '0.5px solid #747474' }}>
                        <FormTitle>
                          <h3 style={{ fontSize: '1rem', color: '#326771' }}>Destination Information</h3>
                        </FormTitle>
                        <IonRow style={{ marginTop: '10px' }} className='ion-align-items-center ion-justify-content-between'>
                          <p style={{ margin: '0' }}>Alert State:</p>
                          <p
                            className='journey-status'
                            style={{ backgroundColor: getCurrentLevel(defaultValues.alert_state).background, color: getCurrentLevel(defaultValues.alert_state).color }}
                          >
                            { `Level ${defaultValues.alert_state}: ${getCurrentLevel(defaultValues.alert_state).title}` }
                          </p>
                        </IonRow>
                        <IonRow style={{ marginTop: '10px' }} className='ion-align-items-center ion-justify-content-between'>
                          <p style={{ margin: '0' }}>Security Condition:</p>
                          <p
                            className='journey-status'
                            style={{ backgroundColor: getCurrentLevel(defaultValues.sc_level).background, color: getCurrentLevel(defaultValues.sc_level).color }}
                          >
                            { `Level ${defaultValues.sc_level}: ${getCurrentLevel(defaultValues.sc_level).title}` }
                          </p>
                        </IonRow>
                      </div>
                    )
                  }
                  {
                    (input.stage === 'Pre-Travel' && input.status === 'Pending' && input.approver.id === user.user_id) && (
                      <IonRow
                        style={{
                          backgroundColor: 'white', padding: '15px',
                        }}
                        className='ion-justify-content-between ion-align-items-center'
                      >
                        <IonButton
                          id='deny-pre-travel'
                          style={{
                            '--background': '#8E151F',
                            fontSize: '1.1em',
                            textTransform: 'none',
                            borderRadius: '0.2em',
                            letterSpacing: '-0.02em',
                          }}
                          onClick={() => onDecline('Pre-Travel')}
                        >Decline Request
                        </IonButton>
                        <IonButton
                          id='approve-pre-travel'
                          style={{
                            '--background': '#0C9500', fontSize: '1.1em', textTransform: 'none', borderRadius: '0.2em', letterSpacing: '-0.02em',
                          }}
                          onClick={() => onApprove('Approved', 'Pre-Travel')}
                        >Approve Request
                        </IonButton>
                      </IonRow>
                    )
                  }
                  {
                    (input.stage === 'Pre-Travel' && input.status === 'Pending' && input.submitter.id === user.id && input.approver.id !== user.id) && (
                    <IonRow
                      style={{
                        alignItems: 'center', backgroundColor: 'white', padding: '15px',
                      }}
                      className='ion-justify-content-end'
                    >
                      <BlueButton style={{ fontSize: '1rem', marginRight: '10px' }} onClick={editJourney}>Edit</BlueButton>
                      <BlueButton style={{ color: '#DD2800', fontSize: '1rem' }} onClick={cancelRequest}>Cancel Request</BlueButton>
                    </IonRow>
                    )
                  }
                  {
                    defaultValues && defaultValues.story.find(({ category }) => category === 'Pre-Travel Approved') && (
                      <div style={{ margin: '15px', borderTop: '0.5px solid #747474' }}>
                        <FormTitle>
                          <h3 style={{ fontSize: '1rem', color: '#0C9500' }}>Approved by</h3>
                        </FormTitle>
                        <p> { `${defaultValues.approver.first_name} ${defaultValues.approver.final_name}` } </p>
                        <p>
                          {
                            defaultValues.story && (
                              <>
                                {
                                  defaultValues.story.find(({ category }) => category === 'Pre-Travel Approved')
                                    ? defaultValues.story.find(({ category }) => category === 'Pre-Travel Approved').content.split('/b')[0] : 'Unknown reason'
                                }

                              </>
                            )
                          }
                        </p>
                      </div>
                    )
                  }
                </>
              ) : (
                <IonRow
                  style={{
                    alignItems: 'center', backgroundColor: 'white', padding: '15px',
                  }}
                  className='ion-justify-content-end'
                >
                  <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>Cancel</IonButton>
                  <IonButton id='submit-air-travel-request' style={{ '--background': '#0C9500' }} onClick={handleSubmit}>Submit</IonButton>
                </IonRow>
              )
            }
          </div>
        )
      }
    </>
  )
}

export default PreTravelForm
