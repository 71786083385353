/* eslint-disable react/no-array-index-key */
import React from 'react'
import Select from 'react-select'

import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { Icon } from '@iconify/react'
import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, MeasureContainer, ColumnContainer } from '../../StyledContainers'

/**
 *  ===================================
 *     Add Existing Control Measures
 *  ===================================
 *  Drag and drop interface for adding control measures
 */
const ExistingMeasures = ({
  children, onClose, next, input, setInput, measures, edit, categories,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Add measure */
  const handleDropAdd = (e) => {
    /* Get measure name */
    const measure = JSON.parse(e.dataTransfer.getData('measure'))

    if (input.existingMeasures.find((val) => val.id === measure.id)) return
    const copy = input.existingMeasures.slice()
    copy.push(measure)

    if (edit && Object.keys(input.removeNew).find((val) => val === measure.id)) {
      setInput({ ...input, removeNew: { ...input.removeNew, [measure.id]: true }, existingMeasures: copy })
      return
    }

    if (edit && Object.keys(input.removeExisting).find((val) => val === measure.id)) {
      setInput({ ...input, removeExisting: { ...input.removeExisting, [measure.id]: true }, existingMeasures: copy })
      return
    }
    setInput({ ...input, existingMeasures: copy })
  }

  /* Remove measure */
  const handleDropRemove = (e) => {
    /* Get measure name */
    const measure = JSON.parse(e.dataTransfer.getData('measure'))

    const index = input.existingMeasures.findIndex((val) => val.id === measure.id)
    if (index < 0) return

    const copy = input.existingMeasures.slice()
    copy.splice(index, 1)

    if (edit && Object.keys(input.removeNew).find((val) => val === measure.id)) {
      setInput({ ...input, removeNew: { ...input.removeNew, [measure.id]: false }, existingMeasures: copy })
      return
    }

    if (edit && Object.keys(input.removeExisting).find((val) => val === measure.id)) {
      setInput({ ...input, removeExisting: { ...input.removeExisting, [measure.id]: false }, existingMeasures: copy })
      return
    }
    setInput({ ...input, existingMeasures: copy })
  }

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent className='risk-assessment-list' style={{ position: 'relative' }}>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Existing Control Measures</h5>
              <p>Existing Control Measures are measures that are currently in place for this Key Asset. Use the drop-down to select a category,
                and then <span style={{ color: '#326771' }}>drag and drop</span> items from the left table to the right.
              </p>
              {
                edit && (
                  <p> <span style={{ color: '#326771' }}>Note: </span> If new Control Measures have been implemented since the last assessment, they should be added here.</p>
                )
              }
            </SettingField>
            <div style={{ width: '200px' }}>
              <Select
                placeholder='Select Category'
                name='existingCategory'
                id='existing-control-measure-categories'
                className='select-container'
                value={input?.existingCategory}
                      // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={categories.map((level) => ({ value: level, label: level }))}
                onChange={(selected) => setInput({ ...input, existingCategory: selected, edit: true })}
              />
            </div>
            <IonRow className='ion-align-items-center'>
              <IonCol size={5}>
                <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropRemove}>
                  {
                    input.existingCategory
                    && measures.filter(({ category }) => category === input.existingCategory.value)
                      .filter((val) => !input.existingMeasures.find((measure) => measure.id === val.id)).map((measure, index) => (
                        <MeasureContainer
                          className='single-control-measure'
                          key={index}
                          draggable
                          style={{ backgroundColor: getColor(index) }}
                          onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(measure))}
                        >
                          <p> {measure.control_measure} </p>
                        </MeasureContainer>
                      ))
                  }
                  {
                    !input.existingCategory && (
                      <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                        <SettingField style={{ textAlign: 'center', width: 'auto' }}>
                          <h4 style={{ fontWeight: 'normal', margin: '20px 0' }}>Choose a category to select measures</h4>
                          <p>To start adding control measures, choose a category from the drop-down menu above. </p>
                        </SettingField>
                      </IonRow>
                    )
                  }
                </ColumnContainer>
              </IonCol>
              <IonCol style={{ textAlign: 'center', fontSize: '2.6rem' }} size={2}>
                <Icon icon='ic:round-swipe-right' />
              </IonCol>
              <IonCol size={5}>
                <ColumnContainer id='cm-existing-drop' onDragOver={(e) => e.preventDefault()} onDrop={handleDropAdd}>
                  {
                    input.existingCategory && input.existingMeasures.filter((selected) => selected.category === input.existingCategory.value).map((measure, index) => (
                      <MeasureContainer
                        className='selected-control-measure'
                        key={index}
                        draggable
                        style={{ backgroundColor: getColor(index) }}
                        onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(measure))}
                      >
                        <p> {measure.control_measure} </p>
                      </MeasureContainer>
                    ))
                  }
                  {
                    (!input.existingCategory || input.existingMeasures.filter((selected) => selected.category === input.existingCategory.value).length === 0) && (
                      <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                        <SettingField style={{ textAlign: 'center', width: 'auto' }}>
                          <h4 style={{ fontWeight: 'normal', margin: '20px 0' }}>Drag and drop items from the left to add them to your Key Asset’s profile</h4>
                          <p>Control measures that you add here will appear in the Key Assets profile as existing measures.</p>
                        </SettingField>
                      </IonRow>
                    )
                  }
                </ColumnContainer>
              </IonCol>
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default ExistingMeasures
