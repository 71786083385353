/* eslint-disable no-trailing-spaces */
import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'
import img1 from './TasksUse1.jpg'
import img2 from './TasksUse2.jpg'
import img3 from './TasksUse3.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const TasksUse = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-tasks')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' routerLink='/helpcenter-insights' onClick={handleBack} />Tasks Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Using the Tasks App
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonText>The Tasks App is a useful tool for organising your team’s workload by listing tasks which need to be completed, 
                  as well as by keeping track of those which have already been completed. It also allows you to allocate different tasks to different users. 
                  The Tasks App homepage is shown below:<br /><br />
                </IonText>
                <IonImg src={img1} />
                <IonText><br /><br />As shown above, there are three columns where tasks will be displayed: ‘To-Do’, ‘In-Progress’ and ‘Complete’. 
                  Read how to add a task to the app here (link to that article) and how to edit the details of that task here (link to article on editing/deleting task).<br /><br />
                </IonText>
                <IonText>Once tasks have been added, they will appear in the columns. Tasks can then be moved between columns according to their progress. 
                  This can be done by clicking on and dragging a task from, for example, the ‘To-Do’ column to the ‘In-Progress’ column. 
                  Another way of doing this is explained below:<br /><br />
                </IonText>
                <IonText>Moving Tasks between Columns<br /><br />Step 1</IonText>
                <IonText>Locate the task you wish to move, and click on the square symbol circled in red below:</IonText>
                <IonImg src={img2} />

                <IonText />
                <IonText><br /><br />Step 2<br />This will then bring up the box shown below. Click on the drop-down list which will display the three options 
                  (To Do, In Progress, and Complete). Select whichever option you want the task to move to, and this will update automatically.
                </IonText>
                <IonImg src={img3} />
                <IonText><br />You will also be able to sort and filter your tasks so that those most relevant to you are most visible within the Tasks App. 
                  For information on using the sorting and filtering features, click here (link to sorting and filtering article).
                </IonText>
              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-edit'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Editing/Deleting a Task
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-addtask'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Adding a Task
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-sort'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Sorting Tasks
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default TasksUse
