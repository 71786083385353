import * as React from 'react'
import { useIncidentsData } from '../../hooks/incident-management/useIncidentData'
import { ContentContainer } from './StyledContainers'
import IncidentBoard from './views/IncidentBoard'
import IncidentTicket from './views/incident/IncidentTicket'
import LogBook from './views/incident/LogBook'
import { IncidentManagementView } from '../../hooks/incident-management/helpers/StateReducers'
import LoadingModal from '../modals/LoadingModal'
import Investigations from './views/incident/Investigations'
import SOPView from './views/SOPView'
import ResponseView from './views/ResponseView'
import TeamsView from './views/TeamsView'
import { useWorkspace } from '../../hooks/useWorkspace'
import BreakoutRooms from './views/BreakoutRooms'
import { useNotification } from '../../hooks/useNotification'

/**
 *  =================================
 *      TAB CONTENT - SHOW VIEWS
 *  =================================
 */
const TabContent = () => {
  /* Get current view using the hook */
  const {
    displayView, fetchAppData, loading, refreshRooms,
  } = useIncidentsData()
  const { workspace } = useWorkspace()
  const { notifications } = useNotification()

  React.useEffect(() => {
    fetchAppData()
  }, [])

  React.useEffect(() => {
    if (!notifications || notifications.length < 2) return
    const newestNoti = notifications.reduce((a, b) => (a.id > b.id ? a : b))
    if (newestNoti.title !== 'New Chat Message') return
    refreshRooms()
  }, [notifications])

  return (
    <ContentContainer style={{ padding: (displayView.view !== IncidentManagementView.BREAKOUT_ROOMS) ? '20px 35px' : '0px', overflow: 'hidden' }}>
      <LoadingModal isOpen={loading} />
      {
        displayView.view === IncidentManagementView.INCIDENT_BOARD && !workspace.is_incident_domain && (
          <IncidentBoard />
        )
      }
      {
        displayView.view === IncidentManagementView.INCIDENT_TICKET && (
          <IncidentTicket />
        )
      }
      {
        displayView.view === IncidentManagementView.ACTION_LOG && (
          <LogBook />
        )
      }
      {
        displayView.view === IncidentManagementView.INVESTIGATIONS && (
          <Investigations />
        )
      }
      {
        displayView.view === IncidentManagementView.SOPS && (
          <SOPView />
        )
      }
      {
        displayView.view === IncidentManagementView.RESPONSE_LEVELS && (
          <ResponseView />
        )
      }
      {
        displayView.view === IncidentManagementView.EMERGENCY_TEAMS && (
          <TeamsView />
        )
      }
      {
        displayView.view === IncidentManagementView.BREAKOUT_ROOMS && (
          <BreakoutRooms />
        )
      }
    </ContentContainer>
  )
}

export default TabContent
