import { IonButton, IonRow } from '@ionic/react'
import * as React from 'react'
import { useChatsData } from '../../../hooks/chats/useChatsData'
import { OverlayContainer } from '../StyledContainers'
import axios from '../../../utils/axios'

interface Props {
  onClose: () => void,
}

const DeleteChat : React.FC<Props> = ({ onClose }) => {
  const { leaveRoom, setLoading, focusedRoom } = useChatsData()
  const handleSubmit = () => {
    onClose()
    setLoading(true)
    axios.put('/archive_chat_room', { room_id: focusedRoom.id })
      .then(({ data }) => {
        if (!data.ok) {
          reportError(data.message)
          setLoading(false)
          return
        }
        leaveRoom(focusedRoom)
      })
      .catch(() => {
        reportError('An unexpected error has ocurred.')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <OverlayContainer style={{ zIndex: 20 }}>
      <h4>Delete Chat</h4>
      <p>Are you sure you wish to delete this chat?</p>
      <p>All users in this group will lose access to messages in this chat.</p>
      <IonRow className='ion-justify-content-end'>
        <IonButton onClick={onClose} style={{ '--background': '#8E151F', color: 'white' }}>Cancel</IonButton>
        <IonButton onClick={handleSubmit} style={{ '--background': '#326771', color: 'white' }}>Confirm</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}
export default DeleteChat
