import * as React from 'react'
import {
  IonSpinner, IonCol, IonRow, IonButton,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { ProvideAddAsset } from '../../hooks/risks/useAddAsset'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'
import MapControls from './MapControls'
import RiskRegisterMap from '../maps/apps/RiskRegisterMap'
import RiskMatrixView from './RiskMatrixView'
import RiskOverview from './RiskOverview'

import { downloader } from './forms/utils/RiskAssessmentCalculations'

import { ObscureBackground, CenterLoader } from '../GlobalContainers'
import RiskAssessmentForm from './forms/RiskAssessment'
import {
  DisplayAssetAction, FocusedActionKind, ListModes, Views,
} from '../../hooks/risks/helpers/StateReducers'
import { useWorkspace } from '../../hooks/useWorkspace'

import useApi from '../../hooks/testHooks'
import LoadingModal from '../modals/LoadingModal'

/**
 *  ====================================================
 *        APP CONTAINER -> PROVIDERS AND MAP/CONTROLS
 *  ====================================================
 */

const AppWrapper = () : JSX.Element => {
  /* Shared functionality between hooks */
  const {
    fetchAppData, displayAsset, loading, dispatchDisplayAsset, dispatchRisk, pushRiskAssessment,
    users, riskEvents, rawMeasures, focusedRisk, fetchMitigations, focusedAsset, updateRisk, viewMode, mapRef,
  } = useRiskRegister()
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  const closeRiskForm = () => {
    dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
    dispatchRisk({ type: FocusedActionKind.FORGET })
  }

  const handleDownload = (risk: boolean) => {
    if (risk) {
      apiHook.downloadRisks({
        domain_id: workspace.id,
        asset_id: focusedAsset.id, // changed to focused asset
      })
        .then((data) => {
          downloader(data, 'Risk Register.xlsx')
        })
    } else {
      apiHook.downloadAssets({
        domain_id: workspace.id,
      })
        .then((data) => {
          downloader(data, 'Asset Register.xlsx')
        })
    }
  }

  /* Format risk assessment inputs ->  Pending for refactoring */
  const getRiskInput = (risk: any) : any => {
    const dropdowns = ['inherentOperations', 'inherentPeople', 'inherentReputation', 'inherentProperty', 'inherentVulnerability',
      'intent', 'capability', 'riskEvent', 'residualOperations', 'residualPeople', 'residualProperty', 'residualVulnerability']
    const optionValues = () => Object.fromEntries(dropdowns.map((key) => ([key, { value: risk[key], label: risk[key] }])))
    if (risk.mitigations) {
      return ({
        ...risk,
        ...optionValues(),
        removeExisting: Object.fromEntries(risk.existingMeasures.map((measure) => [measure.id, false])),
        removeNew: Object.fromEntries(risk.newMeasures.map((measure) => [measure.id, false])),
      })
    }
    return ({
      ...risk,
      ...optionValues(),
    })
  }

  /* Fetch data that doesn't require the map to be laoded */
  React.useEffect(() => {
    fetchAppData()
    mapRef.current.resize()
  }, [])

  return (
    <ProvideAddAsset>
      <MapControls />
      <IonRow class='ion-text-end'>
        <IonCol />
        <IonCol>
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode === ListModes.RISKS && (
              <IonButton style={{ '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF' }} onClick={() => handleDownload(true)}>
                <p style={{ margin: '0', fontSize: '12px', color: 'black' }}><Icon icon='vscode-icons:file-type-excel' inline />Download Risk Register</p>
              </IonButton>
            )
          }
          {
            viewMode.view === Views.REGISTER_VIEW && viewMode.listMode !== ListModes.RISKS && (
              <IonButton style={{ '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF' }} onClick={() => handleDownload(false)}>
                <p style={{ margin: '0', fontSize: '12px', color: 'black' }}><Icon icon='vscode-icons:file-type-excel' inline />Download Asset Register</p>
              </IonButton>
            )
          }
        </IonCol>
      </IonRow>
      <RiskRegisterMap />
      {
        displayAsset.showRiskForm && (
          <>
            <ObscureBackground style={{ zIndex: '9', backgroundColor: 'rgb(69,69,68,.2)' }} />
            <RiskAssessmentForm
              onClose={closeRiskForm}
              users={users}
              riskEvents={riskEvents}
              controlMeasures={rawMeasures}
              defaultInput={focusedRisk && getRiskInput({
                ...focusedRisk,
                inherentOperations: focusedRisk.inherentImpactCategories.operations,
                inherentPeople: focusedRisk.inherentImpactCategories.people,
                inherentProperty: focusedRisk.inherentImpactCategories.property,
                residualOperations: focusedRisk.residualImpactCategories.operations,
                residualPeople: focusedRisk.residualImpactCategories.people,
                residualProperty: focusedRisk.residualImpactCategories.property,
              })}
              edit={!!focusedRisk}
              fetchMitigations={fetchMitigations}
              focusedAsset={focusedAsset}
              categories={rawMeasures.map(({ category }) => category).filter((val, pos, arr) => arr.indexOf(val) === pos)}
              onSubmit={(focusedRisk) ? updateRisk : pushRiskAssessment}
              focusedRisk={focusedRisk}
            />
          </>
        )
      }
      {
        displayAsset.showRiskMatrix && (
          <RiskMatrixView />
        )
      }
      {
        displayAsset.showRiskOverview && (
          <RiskOverview />
        )
      }
      {
        loading && (
          <>
            <ObscureBackground style={{ zIndex: '30' }} />
            <CenterLoader style={{ zIndex: '31' }}>
              <IonSpinner color='light' />
            </CenterLoader>
          </>
        )
      }
    </ProvideAddAsset>
  )
}

export default AppWrapper
