import React, { useState, useEffect } from 'react'
import { IonSpinner } from '@ionic/react'
import { CenterLoader } from '../GlobalContainers'
import { CenterForm } from '../riskregister/StyledContainers'
import CategoryView from './insight-views/CategoryView'
import SelectCounties from './SelectCounties'

/**
 *  =========================
 *    FILL INSIGHTS REPORT
 *  =========================
 */
const InsightsReport = ({
  back, counties, setCounties, reports, setReports, finishSubmission, categories, keyphrases,
}) => {
  /* Copy counties and create a new state */
  /* Define views and steps to keep record of progress */
  const [step, setStep] = useState(1)
  /* Start from false keyphrases for each category */
  const [survey, setSurvey] = useState(keyphrases.map((val) => ({ ...val, ticked: false })))

  /* Originally the UX was designed to be handle multiple reports but for now a single report can be created */
  const submitSingleReport = () => {
    const countyIds = counties.filter((county) => county.selected).map(({ id }) => id)
    const selected = survey.filter(({ ticked }) => ticked).map(({ id }) => id)
    const report = { keyphrases: selected, counties: countyIds }

    finishSubmission(report)
  }

  const submitReport = () => {
    /* Toggle off chosen counties */
    const countyIds = counties.filter((county) => county.chosen).map(({ id }) => id)
    const copy = counties.map((county) => ((county.chosen) ? ({ ...county, chosen: false, completed: true }) : ({ ...county })))
    setCounties(copy)

    /* Submit new report */
    setReports([...reports, { ...survey, counties: countyIds }])
  }

  const goBack = () => setStep(step - 1)

  const goNext = () => setStep(step + 1)

  useEffect(() => {
    setSurvey(keyphrases.map((val) => ({ ...val, ticked: false })))
  }, [keyphrases])

  const views = [
    <SelectCounties
      back={back}
      counties={counties}
      setCounties={setCounties}
      next={() => submitReport({ data: 'data' })}
      finishSubmission={finishSubmission}
    />,
    ...categories.map((val, index) => (
      <CategoryView
        categoryName={val.name}
        survey={survey}
        setSurvey={setSurvey}
        back={index === 0 ? back : goBack}
        next={(index + 1 === categories.length) ? submitSingleReport : goNext}
        onCancel={back}
        last={index + 1 === categories.length}
        counties={counties.filter(({ chosen }) => chosen)}
      />
    )),
  ]

  /* in case categories haven't been fecthed yet display loader */
  if (categories.length === 0) {
    return (
      <CenterForm>
        <CenterLoader>
          <IonSpinner color='light' />
        </CenterLoader>
      </CenterForm>
    )
  }

  return views[step]
}

export default InsightsReport
