import * as React from 'react'
import { IonPage, IonContent, IonGrid } from '@ionic/react'
import TabBar from '../components/incident-management/TabBar'
import { ProvideIncidentData } from '../hooks/incident-management/useIncidentData'
import TabContent from '../components/incident-management/TabContent'
import PageHeader from '../components/PageHeader'

/**
 *  =================================
 *      INCIDENT MANAGEMENT PAGE
 *  =================================
 *  This component will render the appropiate view of the app.
 */

/* Define context for the app -> This will allow for an easier data sharing process through the hierarchy of components */
const IncidentManagementApp = () => (
  <ProvideIncidentData>
    <IonPage>
      <PageHeader title='Incident Management' />
      <IonContent>
        <IonGrid
          className='mobile-padding risk-assessment-list'
          style={{
            height: '100%',
          }}
        >
          <TabBar />
          <TabContent />
        </IonGrid>
      </IonContent>
    </IonPage>
  </ProvideIncidentData>
)

export default IncidentManagementApp
