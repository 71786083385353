import * as React from 'react'

import moment from 'moment'

import { IonCol, IonContent, IonRow, IonLabel, IonGrid, IonButton, IonImg, useIonAlert } from '@ionic/react'

import { PopupBackground } from '../popups/StyledContainers'

import { OverlayContainer } from '../GlobalContainers'

import logo from './logo.png'

import { useReportsData } from '../../hooks/reports/useReportsData'

import { indicators } from '../maps/forms/Indicators.json'

import { useWorkspace } from '../../hooks/useWorkspace'

import { SimpleButton } from '../incident-management/StyledContainers'

import {
  people, property, operations,
} from './FormOptions'

import useApi from '../../hooks/testHooks'

const IncidentPopupInvestigations = () : JSX.Element => {
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  const {
    setShowFieldSitrep,
    name,
    county,
    state,
    insight,
    setShowInternalIncident,
    setStep,
    step,
    data,
    internalIncident,
  } = useReportsData()

  function handleClose() {
    setShowInternalIncident(false)
    setStep(1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    apiHook.downloadInternalIncident({
      domain_id: workspace.id,
      incident_id: internalIncident.id,
    })
      .then((result) => {
        apiHook.downloader2(result, internalIncident.title)
      })
  }

  const handleNext = () => {
    setStep(2)
  }

  const handlePrev = () => {
    setStep(2)
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 0 }} />
      <OverlayContainer style={{ height: '550px', width: '70%' }}>
        <IonRow style={{ height: '10%' }}>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>Incident Report</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonContent style={{ height: '80%', '--ion-background-color': '#FFFFFF' }}>

          <IonRow>
            <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.3em', marginLeft: '1.5em', marginBottom: '0.5em' }}>Investigation</IonLabel>
          </IonRow>

          <IonGrid style={{ marginTop: '1em' }}>
            {
              (data.investigation.length === 0) && (
                <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>There is no active Investigation.</IonLabel>
              )
            }

            {
              (data.investigation.length > 0) && (
              <IonRow>
                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Investigator Name</IonLabel>
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{data.investigation[0].name}</IonLabel>
                </IonCol>

                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Summary of Events</IonLabel>
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{data.investigation[0].summary}</IonLabel>
                </IonCol>

                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Actual Consequences</IonLabel>
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`People: ${Object.values(people)[data.investigation[0].people]}`}</IonLabel>
                </IonCol>

                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }} />
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`Operations: ${Object.values(operations)[data.investigation[0].operations]}`}</IonLabel>
                </IonCol>

                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }} />
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`Property: ${Object.values(property)[data.investigation[0].property]}`}</IonLabel>
                </IonCol>

                {
                  data.investigation_people.map(
                    (person, idx) => (
                      <>
                        {
                          (idx === 0) ? (
                            <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>People Involved</IonLabel>
                            </IonCol>
                          ) : (
                            <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                              <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }} />
                            </IonCol>
                          )
                        }
                        <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`${(data.users.find((e) => e.id === person.user_id)).first_name} ${(data.users.find((e) => e.id === person.user_id)).final_name}`}</IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{person.involvement_type}</IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{(data.users.find((e) => e.id === person.user_id)).email}</IonLabel>
                          </IonRow>
                          <IonRow>
                            <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{(data.users.find((e) => e.id === person.user_id)).phone}</IonLabel>
                          </IonRow>
                        </IonCol>
                      </>
                    ),
                  )
                }

                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Key Findings</IonLabel>
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{data.investigation[0].findings}</IonLabel>
                </IonCol>
                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Lessons Learned</IonLabel>
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{data.investigation[0].lessons}</IonLabel>
                </IonCol>

                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Remedial Actions Required</IonLabel>
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{data.investigation[0].remedials}</IonLabel>
                </IonCol>

                <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>Investigator Comments</IonLabel>
                </IonCol>
                <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                  <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{data.investigation[0].comments}</IonLabel>
                </IonCol>

              </IonRow>
              )
            }
          </IonGrid>

        </IonContent>

        <IonRow class='ion-align-items-end' style={{marginTop: '1em'}}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext} disabled>Next</IonButton>
          </IonCol>
        </IonRow>

      </OverlayContainer>
    </>
  )
}

export default IncidentPopupInvestigations
