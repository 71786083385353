/* eslint-disable import/prefer-default-export */
import {
  BackendBreakoutRoom,
  BackendChatRoom,
  BackendCounty, BackendInternalPerson, BackendProcedure, BackendRecipient, BackendResponseLevel, BackendSOP, BackendTeamMember, BackendTemplate, ProcedureType,
} from '../../../components/types/BackendTypes'
import {
  BackendIncidentComment,
  BackendInternalIncident,
  BackendInvolvement, BackendUnderlaying, BreakoutRoom, IncidentActor, IncidentCause,
  IncidentComment, IncidentLogRecord, IncidentTeam, InternalIncident, Message, Procedure,
  ProcedureResponsability, SOP, SOPLevel, Template, User,
} from '../../../components/types/GlobalTypes'

/**
 * Add county data to internal incidents
 *
 * @param incidents -> Internal incidents in backend format
 * @param counties -> List of counties stored in backend
 * @returns A list of internal icnidents with the county data attached
 */
export const processInternalIncidents = (incidents: BackendInternalIncident[], counties: BackendCounty[]) : InternalIncident[] => {
  const formatted = incidents.map((val) => {
    const county = counties.find(({ id }) => id === val.county_id)

    return {
      ...val,
      county: {
        name: (county) ? county.name : 'Undefined',
        id: val.county_id,
      },
    }
  })

  return formatted
}

/**
 * Add user objects to involvement
 *
 * @param users -> Archangel users
 * @param internalPeople -> Internal people involved with a given incident
 * @returns Formatted version of involment with user objects
 */
export const processInternalActors = (users: User[], internalPeople: BackendInternalPerson[]) : { user: User, type: string }[] => {
  const formattedPeople = internalPeople.map((val) => {
    const user = users.find(({ id }) => id === val.user_id)

    return {
      user: user || {
        id: val.user_id,
        first_name: 'External',
        final_name: 'User',
        email: 'unknown',
      },
      type: val.involvement_type,
    }
  })

  return formattedPeople
}

/**
 * Attach user object to comment records
 *
 * @param users -> List of archangel users
 * @param comments -> List of backend comments
 * @returns Referenced version of comments with user object
 */
export const processIncidentComments = (users: User[], comments: BackendIncidentComment[]) : IncidentComment[] => {
  const formattedComments = comments.map((val) => {
    const user = users.find(({ id }) => id === val.user_id)

    return {
      ...val,
      user: user || {
        id: val.user_id,
        first_name: 'External',
        final_name: 'User',
        email: 'Unknown',
      },
    }
  })

  return formattedComments
}

/**
 * Get Cause objects for joint record
 *
 * @param underlaying -> Joint record that links incidents with causes
 * @param causes -> Cause tags
 * @returns A filtered list of causes that only contains those present in a given incident
 */
export const processCauses = (underlaying: BackendUnderlaying[], causes: IncidentCause[]) : IncidentCause[] => {
  const presentIds = underlaying.map(({ cause_id }) => cause_id)
  const filtered = causes.filter(({ id }) => presentIds.indexOf(id) >= 0)

  return filtered
}

/**
 * Get actor objects present in a incident
 *
 * @param involvements -> Joint record that links incidents with external actors
 * @param actors -> Actor tags
 * @returns A filtered list of actors that only contains those present in a given incident
 */
export const processActors = (involvements: BackendInvolvement[], actors: IncidentActor[]) => {
  const presentIds = involvements.map(({ actor_id }) => actor_id)
  const filtered = actors.filter(({ id }) => presentIds.indexOf(id) >= 0)

  return filtered
}

/**
 * Attach user object to incident logs
 *
 * @param logs -> List of incident logs
 * @param users -> Archangel users
 * @returns Cross-referenced version with user objects embeded
 */
export const processLogs = (logs: IncidentLogRecord[], users: User[]) : IncidentLogRecord[] => {
  const formatted = logs.map((val) => {
    const user = users.find(({ id }) => val.owner === id)
    const submitter = users.find(({ id }) => val.user_id === id)

    return {
      ...val,
      ownerObj: user || {
        id: val.user_id,
        first_name: 'External',
        final_name: 'User',
        email: 'Unknown',
      },
      submitterObj: submitter || {
        id: val.user_id,
        first_name: 'External',
        final_name: 'User',
        email: 'Unknown',
      },
    }
  })

  return formatted
}

/**
 * Use hash map data structure for faster retrieval
 * @param levels -> level records returned from backend
 * @returns a hash map where key is the SOP type and value the actual SOPLevel
 */
export const getLevelsMap = (levels: BackendResponseLevel[]) : { [type: string]: SOPLevel } => {
  const map = Object.fromEntries(levels.map(({ team_type, incident_type }) => ([incident_type, team_type])))
  return map
}

/**
 * Cross-reference SOPs
 *
 * @param sops -> List of Backend SOPs
 * @param levels -> Listo of backend Respone levels
 * @returns A fully formatted list of frontend SOPs
 */
export const processSOPs = (sops: BackendSOP[], levels: BackendResponseLevel[]) : SOP[] => {
  const levelsMap = getLevelsMap(levels)
  const formatted : SOP[] = sops.map(({
    template_sop_id, template_type, sop_type, domain_template_exists, archangel_template_exists, id,
  }) => ({
    template_id: template_sop_id,
    template_type,
    level: levelsMap[sop_type],
    hasDomainTemplate: domain_template_exists,
    hasArchangelTemplate: archangel_template_exists,
    type: sop_type,
    id,
  }))

  return formatted
}

/**
 * Add template to SOP object.
 *
 * @param template -> Backend template object
 * @param procedures -> List of backend procedures
 * @param sop -> Frontend formatted procedure
 * @returns An updated SOP object with the chosen template added as a parameter
 */
export const addChosenTemplate = (template: BackendTemplate, procedures: BackendProcedure[], sop: SOP) : SOP => {
  const { followupProcedures, immediateProcedures, recoveryProcedures } = procedures.reduce<{
    followupProcedures: Procedure[],
    immediateProcedures: Procedure[],
    recoveryProcedures: Procedure[]
  }>((current, val) => {
    const formattedProcedure: Procedure = {
      id: val.id,
      action: val.action,
      description: val.description,
      level: val.level,
      responsibility: val.responsibility,
    }
    if (val.procedure_type === ProcedureType.FOLLOW_UP) {
      current.followupProcedures.push(formattedProcedure)
      return current
    }

    if (val.procedure_type === ProcedureType.IMMEDIATE) {
      current.immediateProcedures.push(formattedProcedure)
      return current
    }

    if (val.procedure_type === ProcedureType.RECOVERY) {
      current.recoveryProcedures.push(formattedProcedure)
      return current
    }

    return current
  }, { followupProcedures: [], immediateProcedures: [], recoveryProcedures: [] })

  const chosenTemplate : Template = {
    id: template.id,
    templateType: template.template_type,
    type: template.sop_type,
    immediateProcedures,
    recoveryProcedures,
    followupProcedures,
  }

  return { ...sop, chosenTemplate }
}

export const processEmergencyTeam = (teamMembers: BackendTeamMember[], users: User[], level: SOPLevel) : IncidentTeam => {
  const members: (User & { role: ProcedureResponsability, rel_id: number }) [] = teamMembers.map(({ user_id, role, id }) => {
    const user = users.find((val) => val.id === user_id)

    return ({
      ...user,
      role,
      rel_id: id,
    })
  })

  return {
    type: level,
    members,
  }
}

export type BackendBreakoutElem = [BackendChatRoom, Message | null, BackendRecipient[], BackendBreakoutRoom]
/**
 * Format data from backend into self-container objects
 *
 * @param rooms -> Backend room objects
 * @param userId -> Id of user in session
 * @returns A frontend formatted version containing all data
 */
export const processBreakoutRooms = (
  rooms: BackendBreakoutElem[],
  users: User[],
  userId: number,
)
: BreakoutRoom[] => {
  const formattedRooms : BreakoutRoom[] = rooms.map(([chatRoom, message, recipients, breakout]) => {
    /* Determine recipient objects in the room */
    const recipientIds = recipients.filter(({ user_id }) => user_id !== userId).map(({ user_id }) => user_id)
    const recipientUsers = users.filter((val) => recipientIds.indexOf(val.id) >= 0)
    const currentRecipient = recipients.find(({ user_id }) => user_id === userId)

    const recipientUpdates = recipients.filter(({ user_id }) => user_id !== userId).map(({ updated_at, user_id, read }) => ({ updated_at, user_id, read }))

    return {
      id: chatRoom.id,
      recipients: recipientUsers,
      name: (!chatRoom.is_group) ? `${recipientUsers[0].first_name} ${recipientUsers[0].final_name}` : chatRoom.name,
      latestMessage: message,
      read: currentRecipient?.read || false,
      created_at: chatRoom.created_at,
      isGroupChat: chatRoom.is_group,
      recipientUpdates,
      host_id: breakout.host_id,
    }
  })
  return formattedRooms
}
