import * as mapboxgl from 'mapbox-gl'
import * as ReactDOM from 'react-dom'
import * as React from 'react'
import {
  Feature, FeatureCollection, featureCollection, LineString, Point, Polygon,
} from '@turf/turf'
import { Asset, AssetList } from '../../../components/riskregister/types/Assets'
import { GeoLocation } from '../../../components/types/GlobalTypes'

/* KEY ASSET icons */
import * as Operations from '../../../components/riskregister/icons/operations.png'
import * as People from '../../../components/riskregister/icons/people.png'
import * as Facilities from '../../../components/riskregister/icons/facilities.png'
import * as Equipment from '../../../components/riskregister/icons/equipment.png'
import * as Product from '../../../components/riskregister/icons/product.png'
import * as Reputation from '../../../components/riskregister/icons/reputation.png'
import * as Project from '../../../components/riskregister/icons/project.png'
import * as Default from '../../../components/riskregister/icons/pin.png'

import { assetsToGeoJSONV2, formatColocatedLocation } from './utils'

import ColocatedPopup from '../../../components/riskregister/ColocatedPopup'
import { LinePOIFeatures, PolygonFeatures } from '../../../components/types/GeoJSONTypes'

const icons = [{ name: 'Operations', img: Operations }, { name: 'Reputation', img: Reputation }, { name: 'Project', img: Project },
  { name: 'People', img: People }, { name: 'Facilities', img: Facilities }, { name: 'Equipment', img: Equipment }, { name: 'Product', img: Product }]

/**
 *  ======================================
 *      MAP FUNCTIONALITY FOR ASSETS
 *  ======================================
 *
 * This file accommodates all functionality related to drawing
 * assets on the map and calculations made.
 */

const colocatedPopup = (colocatedAssets: Asset[], onClick: React.MutableRefObject<(asset: Asset) => void>) => {
  const popup = new mapboxgl.Popup({ className: 'colocated-assets', closeButton: false })
  const placeholder = document.createElement('div')
  const component = (
    <ColocatedPopup
      assets={colocatedAssets}
      setFocussedAsset={(asset: Asset) => {
        onClick.current(asset)
      }}
      popup={popup}
    />
  )

  ReactDOM.render(component, placeholder)
  return popup.setDOMContent(placeholder)
}

/**
 *  Create an HTML pin for the list of assets in a given location
 *
 * @param assets -> List of colocated assets
 * @returns -> HTML pin with the appropriate icon
 */
const getAssetPin = (assets: Asset[]) : HTMLDivElement => {
  const el = document.createElement('div')

  /* Properties of pin */
  el.style.width = '30px'
  el.style.height = '40px'
  el.style.backgroundSize = '100%'
  el.className = 'asset-pin-element'

  if (assets.length > 1) {
    el.style.backgroundImage = `url(${Default})`
    const counter = document.createElement('div')
    counter.className = 'asset-pin-counter'
    counter.innerHTML = String(assets.length)
    el.appendChild(counter)
  } else {
    const icon = icons.find(({ name }) => name === assets[0].type)
    if (icon) {
      el.style.backgroundImage = `url(${icon.img})`
    }
  }

  return el
}

/**
 *  Create mapbox marker with listeners and popups for colocated assets.
 *
 * @param assetPin -> HTML pin Component
 * @param handleAssetClickRef -> onClick handler
 * @param setCurrentPointRef -> onDrag handler
 * @param coordinates -> Location coordinates
 * @param popup -> Mapbox popup component
 * @param assets -> List of colocated assets
 * @returns A mapbox marker with a popup and listeners included
 */
const createAssetMarker = (
  assetPin: HTMLDivElement,
  handleAssetClickRef: React.MutableRefObject<(marker: mapboxgl.Marker, coordinateKey: string) => void>,
  setCurrentPointRef: React.MutableRefObject<(location: GeoLocation, marker: mapboxgl.Marker, asset: Asset | null) => void>,
  coordinates: GeoLocation,
  popup: mapboxgl.Popup,
  assets: Asset[],
) : mapboxgl.Marker => {
  /* Define marker and its listeners */
  const marker = new mapboxgl.Marker(assetPin)
  marker.setLngLat(coordinates)

  marker.getElement().onclick = () => {
    handleAssetClickRef.current(marker, `${coordinates.lng},${coordinates.lat}`)
  }
  marker.on('dragend', () => {
    setCurrentPointRef.current(marker.getLngLat(), marker, null)
    marker.togglePopup()
  })

  /* Depending on the number of assets in the same location return a different popup */
  if (assets.length > 1) {
    marker.setPopup(popup)
  } else {
    const markerLabel = new mapboxgl.Popup({ closeButton: false }).setText(assets[0].name)
    const handler = () => {
      marker.togglePopup()
    }
    marker.getElement().addEventListener('mouseenter', handler)
    marker.getElement().addEventListener('mouseleave', handler)
    marker.setPopup(markerLabel)
  }

  return marker
}

/**
 *  Draw assets on a map
 *
 * @param assets -> List of assets to be drawn
 * @param assetOnClick -> Callback when a an asset is clciked from popup
 * @param handleAssetClickRef -> Callback when
 * @returns a new asset list with the new markers that have been added to the map
 */
export const drawAssetsOnMap = (
  mapRef: React.RefObject<mapboxgl.Map> | null,
  assets: AssetList,
  assetOnClick: React.MutableRefObject<(asset: Asset) => void>,
  handleAssetClickRef: React.MutableRefObject<(marker: mapboxgl.Marker, coordinateKey: string) => void>,
  setCurrentPointRef: React.MutableRefObject<(location: GeoLocation, marker: mapboxgl.Marker, asset: Asset | null) => void>,
) : AssetList => {
  const assetsCopy = { ...assets }
  Object.keys(assetsCopy).forEach((assetLoc) => {
    /* ICON HTML for asset types */
    const colocatedAssets : Asset[] = assetsCopy[assetLoc].assets
    const assetPin = getAssetPin(colocatedAssets)

    const popup = colocatedPopup(colocatedAssets, assetOnClick)
    const coordinates : GeoLocation = formatColocatedLocation(assetLoc)

    const marker = createAssetMarker(assetPin, handleAssetClickRef, setCurrentPointRef, coordinates, popup, colocatedAssets)

    /* If map has been drawn then add marker */
    if (mapRef && mapRef.current) {
      marker.addTo(mapRef.current)
      assetsCopy[assetLoc] = { ...assetsCopy[assetLoc], marker }
    }
  })

  return assetsCopy
}

/**
 *  Remove asset markers from the map
 *
 * @param assets -> List of drawn assets
 */
export const removeAllDrawnAssets = (assets: AssetList) : void => {
  Object.values(assets).forEach(({ marker }) => {
    if (marker) marker.remove()
  })
}

export const createAssetPointLayers = (
  mapRef: React.RefObject<mapboxgl.Map>,
  featurePoints: FeatureCollection<Point, { id: number, name: string, asset_type?: string }>,
) => {
  console.log('featurecollectionpoints', featurePoints)

  // Add the GeoJSON source to the map
  if (mapRef.current) {
    mapRef.current.addSource('assets-points', {
      type: 'geojson',
      data: featurePoints,
    })
  }

  const loadImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => resolve(img)
    img.onerror = () => reject(new Error(`Failed to load image: ${url}`))
  })

  // Load all images
  Promise.all([
    loadImage(Default),
    loadImage(Project),
    loadImage(Reputation),
    loadImage(Product),
    loadImage(Equipment),
    loadImage(Facilities),
    loadImage(People),
    loadImage(Operations),
  ])
    .then((images) => {
      const [
        defaultIcon,
        projectIcon,
        reputationIcon,
        productIcon,
        equipmentIcon,
        facilitiesIcon,
        peopleIcon,
        operationsIcon,
      ] = images;

      if (mapRef.current) {
        const map = mapRef.current

        const addIconsAndLayer = () => {
          if (!map.isStyleLoaded()) {
            setTimeout(addIconsAndLayer, 100)
            return;
          }

          // Add images to map
          map.addImage('defaultIcon', defaultIcon)
          map.addImage('projectIcon', projectIcon)
          map.addImage('reputationIcon', reputationIcon)
          map.addImage('productIcon', productIcon)
          map.addImage('equipmentIcon', equipmentIcon)
          map.addImage('facilitiesIcon', facilitiesIcon)
          map.addImage('peopleIcon', peopleIcon)
          map.addImage('operationsIcon', operationsIcon)

          // Add layer with conditional 'icon-image'
          map.addLayer({
            id: 'asset-point-markers',
            type: 'symbol',
            source: 'assets-points',
            layout: {
              'icon-image': [
                'match',
                ['get', 'asset_type'],
                'People', 'peopleIcon',
                'Operations', 'operationsIcon',
                'Facilities', 'facilitiesIcon',
                'Equipment', 'equipmentIcon',
                'Product', 'productIcon',
                'Project', 'projectIcon',
                'Reputation', 'reputationIcon',
                'defaultIcon',
              ],
              'icon-size': 0.2,
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
            },
          })
        }
        addIconsAndLayer()
      }
    })
    .catch((error) => {
      console.error('Error loading images:', error)
    })
}

export const createAssetLayers = (
  mapRef: React.RefObject<mapboxgl.Map>,
  assets: any[] = [],
) => {
  const { featureLines, featurePolygons } = assetsToGeoJSON(assets)

  const pointFeatures: Feature<Point, { id: number, name: string, asset_type?: string }>[] = assets
    .filter((val) => val.marker_type === 'pin') // Filter assets whose marker_type is 'pin'
    .map((val) => {
    // Check if both longitude and latitude are present
      if (val.longitude && val.latitude) {
      // Build and return the Point feature
        return {
          type: 'Feature',
          properties: { id: val.id, name: val.name, asset_type: val.asset_type },
          geometry: {
            type: 'Point',
            coordinates: [val.longitude, val.latitude],
          },
        } as Feature<Point, { id: number, name: string, asset_type?: string }>
      }
      // Skip this asset by returning null
      return null
    })
    .filter((feature) => feature !== null) as Feature<Point, { id: number, name: string, asset_type?: string }>[]

  const points: FeatureCollection<Point, { id: number, name: string, asset_type?: string }> = {
    type: 'FeatureCollection',
    features: pointFeatures,
  }

  console.log(points)

  mapRef.current.addSource('assets-lines', {
    type: 'geojson',
    data: featureLines,
  })

  mapRef.current.addSource('assets-polygons', {
    type: 'geojson',
    data: featurePolygons,
  })

  mapRef.current.addLayer({
    id: 'assets-lines-layer',
    type: 'line',
    source: 'assets-lines',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#8E151F',
      'line-width': 4,
    },
  })

  mapRef.current.addLayer({
    id: 'assets-polygon-layer',
    type: 'fill',
    source: 'assets-polygons',
    paint: {
      'fill-color': '#8E151F', // blue color fill
      'fill-opacity': 0.5,
    },
  })

  mapRef.current.addLayer({
    id: 'assets-polygon-line-layer',
    source: 'assets-polygons',
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#8E151F',
      'line-width': 2,
    },
  })

  createAssetPointLayers(mapRef, points)
}

/**
 * Get GeoJSON data for POIs
 *
 * @param assets -> List of assets
 * @returns FeatureCollection of points and linestrings
 */
export const assetsToGeoJSON = (assets: any[]) : {
  featureLines: LinePOIFeatures,
  featurePolygons: PolygonFeatures,
} => {
  console.log('assets in geoJSON conversion', assets)
  const lineFeatures : Feature<LineString, { id: number }>[] = assets.filter(({ marker_type }) => marker_type === 'line').map(({ geodata, id }) => ({
    ...geodata as Feature<LineString>, properties: { id },
  }))

  const polygonFeatures : Feature<Polygon, { id: number }>[] = assets.filter(({ marker_type }) => marker_type === 'polygon').map(({ geodata, id }) => ({
    ...geodata as Feature<Polygon>, properties: { id },
  }))

  return {
    featureLines: featureCollection(lineFeatures),
    featurePolygons: featureCollection(polygonFeatures),
  }
}
