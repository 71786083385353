import * as React from 'react'
import {
  IonRow, IonCol, IonContent, IonSpinner, IonCheckbox,
} from '@ionic/react'

import { Icon } from '@iconify/react'

import { ListContainer } from '../../consultant/StyledContainers'
import { WidgetTitles } from '../../maps/WidgetContainers'
import { SherpaButton } from '../../GlobalContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import { getNumericalValue, colorCodeRisk } from '../forms/utils/Categories'

import useApi from '../../../hooks/testHooks'
import { getColor } from '../../maps/apps/AppMap'
import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'

import { DisplayAssetAction, FocusedActionKind } from '../../../hooks/risks/helpers/StateReducers'
import { RiskAssessment } from '../types/RiskAssessments'

/**
 *  ========================
 *      Risk List View
 *  ========================
 */
const RiskRegister = () : JSX.Element => {
  /* import hook data/functionality */
  const {
    dispatchRisk, dispatchDisplayAsset, focusedAsset, editing, fetchRiskAssessments, deleteRiskAssessments,
  } = useRiskRegister()
  const [sortedBy, setSortedBy] = React.useState({ field: 'id', ascending: true })
  const [deleteAssessments, setDeleteAssessments] = React.useState((focusedAsset.risks) ? Object.fromEntries(focusedAsset.risks.map((risk: RiskAssessment) => [risk.id, false])) : { })
  const apiHook = useApi()

  const handleReview = (risk: RiskAssessment) : void => {
    dispatchRisk({ type: FocusedActionKind.CHOOSE, chosenRisk: risk })
    dispatchDisplayAsset(DisplayAssetAction.OPEN_RISK_FORM)
    // create review started metrics
    apiHook.createRiskMetric('edit', focusedAsset.id)
  }

  const removeAssessments = () => {
    const ids = Object.keys(deleteAssessments).filter((id) => deleteAssessments[id])
    deleteRiskAssessments(ids.map((val) => Number(val)))
  }

  const overviewRisk = (risk: RiskAssessment) => {
    dispatchDisplayAsset(DisplayAssetAction.OPEN_RISK_OVERVIEW)
    dispatchRisk({ type: FocusedActionKind.CHOOSE, chosenRisk: risk })
  }

  const createDeleteCheckBoxes = (risks: RiskAssessment[]) => {
    setDeleteAssessments(Object.fromEntries(risks.map((risk) => [risk.id, false])))
  }

  const handleCheckboxes = (checked: boolean, id: number) => {
    setDeleteAssessments({ ...deleteAssessments, [id]: checked })
  }

  const getSortedRisks = () => {
    const copy = focusedAsset.risks.slice()
    if (sortedBy.ascending) {
      return copy.sort((a, b) => {
        if (typeof a[sortedBy.field] === 'number') {
          return a[sortedBy.field] - b[sortedBy.field]
        }
        return getNumericalValue(a[sortedBy.field]) - getNumericalValue(b[sortedBy.field])
      })
    }

    return copy.sort((a, b) => {
      if (typeof a[sortedBy.field] === 'number') return b[sortedBy.field] - a[sortedBy.field]
      return getNumericalValue(b[sortedBy.field]) - getNumericalValue(a[sortedBy.field])
    })
  }

  const handleTitle = (fieldName: string) : void => {
    /* If this field has already been selected then revert the order */
    if (sortedBy.field === fieldName) {
      setSortedBy({ ...sortedBy, ascending: !sortedBy.ascending })
      return
    }

    setSortedBy({
      field: fieldName,
      ascending: true,
    })
  }

  const getArrow = () : JSX.Element => ((sortedBy.ascending) ? (
    <Icon icon='fluent:arrow-sort-down-16-filled' />
  ) : (
    <Icon icon='fluent:arrow-sort-up-16-filled' />
  ))

  React.useEffect(() => {
    if (focusedAsset.risks) return
    fetchRiskAssessments(focusedAsset, createDeleteCheckBoxes)
  }, [])
  return (
    <ListContainer>
      <WidgetTitles style={{ height: '10%', paddingRight: '0' }}>
        <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
          <IonCol size='1' />
          <IonCol
            style={{
              cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            onClick={() => handleTitle('id')}
            className='ion-text-center'
            size='1'
          >
            Risk ID
            {
              sortedBy.field === 'id' && getArrow()
            }
          </IonCol>
          <IonCol className='ion-text-center' size='2'>Risk</IonCol>
          <IonCol className='ion-text-center' size='2'>Risk Assessor</IonCol>
          <IonCol style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => handleTitle('riskRating')} className='ion-text-center' size='1'>
            Inherent Risk
            {
              sortedBy.field === 'riskRating' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => handleTitle('residualRisk')} className='ion-text-center' size='1'>
            Residual Risk
            {
              sortedBy.field === 'residualRisk' && getArrow()
            }
          </IonCol>
          <IonCol className='ion-text-center' size='2'>Date Modified</IonCol>
          <IonCol className='ion-text-center' size='2'>
            {
              editing && (
                <SherpaButton
                  onClick={removeAssessments}
                  id='delete-all-selected-assessments'
                  style={{ padding: '0' }}
                >
                  <IonRow className='ion-align-items-center ion-justify-content-center'>

                    <p style={{ margin: '0', fontSize: '12px' }}>Delete all selected</p>
                    <Icon icon='bi:trash' />
                  </IonRow>

                </SherpaButton>
              )
            }
          </IonCol>
        </IonRow>
      </WidgetTitles>
      <div style={{ height: '95%' }}>
        <IonContent style={{ '--background': 'white' }}>
          {
            (focusedAsset.risks) ? getSortedRisks().map((risk, index) => (
              <IonRow
                key={risk.id}
                style={{ backgroundColor: getColor(index), height: '10%' }}
                className='ion-align-items-center'
              >
                <IonCol className='ion-text-center' size='1'>
                  {
                    editing && (
                      <IonCheckbox id={`delete-risk-event-${index + 1}`} onClick={(e) => handleCheckboxes(e.currentTarget.checked, risk.id)} checked={deleteAssessments[risk.id]} />
                    )
                  }
                </IonCol>
                <IonCol className='ion-text-center' size='1'>{ risk.visibleId }</IonCol>
                <IonCol
                  id={`risk-event-${index + 1}`}
                  className='ion-text-center'
                  size='2'
                ><SimpleButton style={{ fontSize: '12px' }} onClick={() => overviewRisk(risk)}>{ risk.riskEvent }</SimpleButton>
                </IonCol>
                <IonCol className='ion-text-center' size='2'>{ (!risk.owner) ? 'Unknown' : risk.owner.email }</IonCol>
                <IonCol id={`inherent-risk-${index + 1}`} className='ion-text-center' style={{ color: colorCodeRisk(risk.riskRating) }} size='1'>{ risk.riskRating }</IonCol>
                <IonCol id={`residual-risk-${index + 1}`} className='ion-text-center' style={{ color: colorCodeRisk(risk.residualRisk) }} size='1'>{ risk.residualRisk } </IonCol>
                <IonCol className='ion-text-center' size='2'>{ risk.updated }</IonCol>
                <IonCol className='ion-text-center' size='2'>
                  {
                    editing && <SimpleButton id={`review-risk-${index + 1}`} style={{ fontSize: '12px' }} onClick={() => handleReview(risk)}>Review</SimpleButton>
                  }
                </IonCol>
              </IonRow>
            )) : (
              <IonRow className='ion-justify-content-center' style={{ paddingTop: '40px' }}>
                <IonSpinner />
              </IonRow>
            )
          }
        </IonContent>
      </div>
    </ListContainer>
  )
}

export default RiskRegister
