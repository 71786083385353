import React, { useState } from 'react'
import {
  IonContent, IonListHeader, IonList, IonItem, IonLabel, IonInput, IonRow, IonButton, useIonAlert,
} from '@ionic/react'

import {
  MapInstructions, InstructionButtons, SimpleButton,
} from '../StyledContainers'
import SelectRouteType from './SelectRouteType'

/**
 *  ==============================
 *    Submit an optimised route
 *  ==============================
 */
const RouteForm = ({
  onSubmit, onCancel, canFillForm, onRemoveMarker, markers, setRouteType,
}) => {
  /* Form inputs object */
  const [input, setInput] = useState({
    to: undefined,
    from: undefined,
    routeName: undefined,
  })

  const [displayView, setDisplayView] = useState('Route Type')
  const [ionAlert] = useIonAlert()

  /* Handle changes on every input field */
  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  const returnMessage = (key) => ({
    to: 'Please specify the address you are going to travel to',
    from: 'Please specify the address you are going to travel from',
    routeName: 'Please name your route',
  }[key])

  const handleTypeChoosen = (type) => {
    setRouteType(type)
    setDisplayView('Instructions')
  }

  const handleSubmit = () => {
    /* First check that all required fields have been filled */
    const notFilled = Object.keys(input).find((key) => input[key] === undefined)

    if (notFilled) {
      return ionAlert({
        header: 'Error',
        message: returnMessage(notFilled),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    onSubmit(input)
    return null
  }

  return (
    <IonContent>
      {
        displayView === 'Fill Form' && (
          <>
            <IonList>
              <IonListHeader>
                <IonRow className='ion-align-items-center ion-justify-content-between' style={{ width: '90%' }}>
                  <h3>Add Route</h3>
                  <SimpleButton
                    onClick={() => onCancel(true)}
                  >Close X
                  </SimpleButton>
                </IonRow>
              </IonListHeader>
            </IonList>
            <IonItem>
              <IonLabel>From:</IonLabel>
              <IonInput id='route-from-location' placeholder='Type the name of the location you are travelling from' value={input.from} name='from' onIonChange={handleChange} type='text' />
            </IonItem>
            <IonItem>
              <IonLabel>To:</IonLabel>
              <IonInput id='route-to-location' placeholder='Type the name of the location you are travelling to' value={input.to} name='to' onIonChange={handleChange} type='text' />
            </IonItem>
            <IonItem>
              <IonLabel>Route Name:</IonLabel>
              <IonInput id='route-name' placeholder='What is this route called?' value={input.routeName} name='routeName' onIonChange={handleChange} type='text' />
            </IonItem>
            <InstructionButtons style={{ paddingLeft: '20px' }}>
              <IonRow className='ion-justify-content-between' style={{ padding: '10px 0' }}>
                <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel(true)}>Cancel</IonButton>
                <IonButton id='submit-route-form' style={{ '--background': '#4197A9' }} onClick={handleSubmit}>Submit</IonButton>
              </IonRow>
            </InstructionButtons>
          </>
        )
      }
      {
        displayView === 'Route Type' && (
          <SelectRouteType onCancel={onCancel} onChoose={handleTypeChoosen} />
        )
      }
      {
        displayView === 'Instructions' && (
          <MapInstructions>
            <h2 style={{ color: '#326771' }}>Add Route</h2>
            <div style={{ marginTop: '20px', padding: '0 10px' }}>
              <p>Click on the map to drop a pin at the point where the route will begin.</p>
              <p>You can drop multiple pins along your route to create a path.</p>
              <p>The last pin you drop will be the end point of the route.</p>
              <p>To remove a pin click the button below. </p>
            </div>
            <InstructionButtons>
              <IonRow className='ion-justify-content-between'>
                <IonButton id='route-undo-last-pin' style={{ '--background': '#4197A9' }} onClick={onRemoveMarker} disabled={!markers.length}>Undo Pin</IonButton>
                <IonRow>
                  <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel(markers.length)}>Cancel</IonButton>
                  <IonButton id='route-continue-to-form' style={{ '--background': '#0C9500' }} onClick={() => setDisplayView('Fill Form')} disabled={!canFillForm}>Next</IonButton>
                </IonRow>
              </IonRow>
            </InstructionButtons>
          </MapInstructions>
        )
      }

    </IonContent>
  )
}

export default RouteForm
