import { IonButton, IonRow, useIonAlert } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import PhoneInput from 'react-phone-number-input'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import {
  Community, Stakeholder, StakeholderGroup, StakeholderQuadrant,
} from '../../types/OptimizedMaps'
import { SimpleButton } from '../StyledContainers'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

export interface Details {
  subdivisions: any
  name: string,
  alias: string,
  type: 'Organisation' | 'Individual' | 'Household' | '',
  role: string,
  organisations: { label: string, value: number }[],
  areas: { label: string, value: number }[],
  parents: { label: string, value: number }[],
  subs: { label: string, value: number }[],
  leaders: { label: string, value: number }[],
  management: { label: string, value: number }[],
  analysis: string,
  whatsapp_number: string,
  phone_number: string,
  email: string,
}

interface Props {
  communities: Community[],
  onClose: () => void,
  stakeholders: Stakeholder[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  pushNewStakeholder: (stakeholder: Stakeholder) => void,
  setSubmittedMessage: React.Dispatch<React.SetStateAction<string | null>>,
  stakeholderGroups: StakeholderGroup[]
}

const StakeholderForm : FC<Props> = ({
  communities, onClose, stakeholderGroups, stakeholders, setLoading,
  pushNewStakeholder, setSubmittedMessage,
}) => {
  const [input, setInput] = useState<Details>({
    name: '',
    alias: '',
    type: '',
    role: '',
    organisations: [],
    areas: [],
    parents: [],
    subs: [],
    leaders: [],
    management: [],
    analysis: '',
    whatsapp_number: '',
    phone_number: '',
    email: '',
  })
  const [ionAlert] = useIonAlert()
  const { workspace } = useWorkspace()
  const [groupSelections, setGroupSelections] = useState<(StakeholderGroup & { selected: boolean })[]>([])

  const errorMessages = {
    name: 'You need to provide the name for this stakeholder',
    //alias: 'No alias provided for this stakeholder',
    //type: 'Type cannot be undefined',
    //role: 'No stakeholder role provided',
    //analysis: 'Give a brief analysis of the stakeholder',
    //whatsapp_number: 'Please provide a whatsapp number for this stakeholder',
    //phone_number: 'Please provide a phone number',
    //email: 'Stakeholder email missing',
  }

  console.log('stakeholders', stakeholders)
 
  const groups = [
    { id: 1, name: 'Advocacy group', selected: false },
    { id: 2, name: 'Affected landholder', selected: false },
    { id: 3, name: 'ASM', selected: false },
    { id: 4, name: 'Business / cooperative', selected: false },
    { id: 5, name: 'Community group / association', selected: false },
    { id: 6, name: 'Education / social services', selected: false },
    { id: 7, name: 'Elder / traditional leadership', selected: false },
    { id: 8, name: 'Farmers', selected: false },
    { id: 9, name: 'Government / political party', selected: false },
    { id: 10, name: 'Informal workers / daily labour', selected: false },
    { id: 11, name: 'Local scientists / professionals / elites', selected: false },
    { id: 12, name: 'Media', selected: false },
    { id: 13, name: 'Militia / insurgent group', selected: false },
    { id: 14, name: 'NGO / INGO', selected: false },
    { id: 15, name: 'Organised labour / Trade union', selected: false },
    { id: 16, name: 'Project Affected Person (PAP)', selected: false },
    { id: 17, name: 'Public sector organisation', selected: false },
    { id: 18, name: 'Regulatory agency', selected: false },
    { id: 19, name: 'Religious group', selected: false },
    { id: 20, name: 'Security forces', selected: false },
    { id: 21, name: 'Students / Youth', selected: false },
    { id: 22, name: 'Suppliers / service providers', selected: false },
    { id: 23, name: 'Women', selected: false },
  ]

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const messageKey = keys.find((val) => !input[val] || input[val] === '')

    if (messageKey) {
      ionAlert({
        header: 'Error',
        message: errorMessages[messageKey],
        buttons: [{
          text: 'Ok',
        }],
      })
      return
    }

    if (input.email && !input.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)) {
      ionAlert({
        header: 'Error',
        message: 'Email not valid',
        buttons: [{ text: 'Ok' }],
      })

      return
    }
    const selectedGroups = groupSelections.filter(({ selected }) => selected)

    setLoading(true)
    axios.post('/api/v2/stakeholder/create_stakeholder', {
      domain_id: workspace.id,
      alias_name: input.alias,
      name: input.name,
      role: input.role,
      type: input.type,
      communities: input.areas.map(({ value }) => value),
      comments: input.analysis,
      leaders: input.leaders.map(({ value }) => value),
      subs: input.subs.map(({ value }) => value),
      management: input.management.map(({ value }) => value),
      parents: input.parents.map(({ value }) => value),
      organisations: input.organisations.map(({ value }) => value),
      groups: selectedGroups.map(({ id }) => id),
      whatsapp_number: input.whatsapp_number,
      phone_number: input.phone_number,
      email: input.email,
    }).then(({ data }) => {
      if (data.message === 'you have not created a stakeholder.') {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }

      const stakeholder: Stakeholder = {
        id: data.stakeholder.id,
        name: input.name,
        alias: input.alias,
        type: input.type as ('Organisation' | 'Individual'),
        organisations: input.organisations.map(({ label, value }) => ({ id: value, name: label })),
        areas: communities.filter((val) => input.areas.find(({ value }) => value === val.id)).map((val) => ({ ...val, quadrant: StakeholderQuadrant.LOW_LOW })),
        analysis: input.analysis,
        role: input.role,
        groups: selectedGroups,
        email: input.email,
        whatsapp_number: input.whatsapp_number,
        phone_number: input.phone_number,
      }
      pushNewStakeholder(stakeholder)
      setSubmittedMessage('Stakeholder created successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setLoading(false)
      onClose()
    })
  }

  const selectCause = (index: number) => {
    const copy = [...groupSelections]
    copy[index] = { ...copy[index], selected: !copy[index].selected }
    setGroupSelections(copy)
  }

  useEffect(() => {
    const selectedGroups = groupSelections.filter(({ selected }) => selected)
  }, [groupSelections])

  useEffect(() => {
    setGroupSelections(groups.map((val) => ({ ...val, selected: false })))
  }, [])

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <OverlayContainer style={{ zIndex: 25, height: '80%', overflow: 'hidden' }} className='terrain-add-popup'>
        <div style={{ maxHeight: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <h5 style={{ marginBottom: 0 }}>Add Stakeholder</h5>
            <SimpleButton onClick={() => onClose()}>Close X</SimpleButton>
          </IonRow>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <div className='terrain-form-field'>
              <h6>Stakeholder Name</h6>
              <input placeholder='Give this stakeholder a name' type='text' value={input.name} onChange={(e) => setInput({ ...input, name: e.target.value })} />
            </div>
            <div className='terrain-form-field'>
              <h6>Alias</h6>
              <input
                placeholder='Stakeholder alias'
                type='text'
                value={input.alias}
                onChange={(e) => setInput({ ...input, alias: e.target.value })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Type</h6>
              <select
                value={input.type}
                onChange={(e) => setInput({ ...input, type: e.target.value as ('Organisation' | 'Individual' | 'Household') })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select Type</option>
                <option value='Organisation'> Organisation </option>
                <option value='Individual'> Individual </option>
                <option value='Household'> Household </option>
              </select>
            </div>
            {
              (input.type === 'Organisation') && (
                <>
                  <div className='terrain-form-field'>
                    <h6>Parent Body</h6>
                    <CreatableSelect
                      isMulti
                      placeholder='Select Parent Organisations'
                      name='colors'
                      className='select-container'
                      id='parents'
                    // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      options={stakeholders.filter(({ type }) => type === 'Organisation').map(({ id, name }) => ({ label: name, value: id }))}
                      value={input.parents}
                      onChange={(selected) => setInput({ ...input, parents: selected })}
                    />
                  </div>
                  <div className='terrain-form-field'>
                    <h6>Subdivisions</h6>
                    <CreatableSelect
                      isMulti
                      placeholder='Select Subdivisions'
                      name='colors'
                      className='select-container'
                      id='journey-passengers'
                    // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      options={stakeholders.filter(({ type }) => type === 'Organisation').map(({ id, name }) => ({ label: name, value: id }))}
                      value={input.subs}
                      onChange={(selected) => setInput({ ...input, subs: selected })}
                    />
                  </div>
                  <div className='terrain-form-field'>
                    <h6>Leader</h6>
                    <CreatableSelect
                      isMulti
                      placeholder='Select Leader'
                      name='colors'
                      className='select-container'
                      id='journey-passengers'
                    // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      options={stakeholders.filter(({ type }) => type === 'Individual').map(({ id, name }) => ({ label: name, value: id }))}
                      value={input.leaders}
                      onChange={(selected) => setInput({ ...input, leaders: selected })}
                    />
                  </div>
                  <div className='terrain-form-field'>
                    <h6>Management</h6>
                    <CreatableSelect
                      isMulti
                      placeholder='Select Managers'
                      name='colors'
                      className='select-container'
                      id='journey-passengers'
                    // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      options={stakeholders.filter(({ type }) => type === 'Individual').map(({ id, name }) => ({ label: name, value: id }))}
                      value={input.management}
                      onChange={(selected) => setInput({ ...input, management: selected })}
                    />
                  </div>
                </>
              )
            }
            <div className='terrain-form-field'>
              <h6>Role/Position</h6>
              <input
                placeholder='Add Role/Position'
                type='text'
                value={input.role}
                onChange={(e) => setInput({ ...input, role: e.target.value })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Primary Associated Stakeholder</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Organisation'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.filter(({ type }) => type === 'Organisation').map(({ id, name }) => ({ label: name, value: id }))}
                value={input.organisations}
                onChange={(selected) => setInput({ ...input, organisations: selected })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Associated Locations</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Areas'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                value={input.areas}
                onChange={(selected) => setInput({ ...input, areas: selected })}
              />
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Analysis</h6>
              <textarea
                style={{ height: '10em' }}
                value={input.analysis}
                onChange={(e) => setInput({ ...input, analysis: e.target.value })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Stakeholder Group</h6>
              {
                groupSelections.map((val, index) => (
                  <TagButton
                    key={val.id}
                    onClick={() => selectCause(index)}
                    style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                  >
                    { val.name }
                  </TagButton>
                ))
              }
            </div>
            <div className='terrain-form-field'>
              <h6>WhatsApp Number</h6>
              <PhoneInput
                international
                defaultCountry={workspace.country && workspace.country}
                type='text'
                value={input.whatsapp_number}
                placeholder='WhatsApp phone number'
                onChange={(val) => setInput({ ...input, whatsapp_number: val })}
                name='whatsapp_number'
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Phone Number</h6>
              <PhoneInput
                international
                defaultCountry={workspace.country && workspace.country}
                type='text'
                value={input.phone_number}
                placeholder='Phone number'
                onChange={(val) => setInput({ ...input, phone_number: val })}
                name='phone_number'
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Email</h6>
              <input
                placeholder='Stakeholder contact email'
                type='text'
                value={input.email}
                onChange={(e) => setInput({ ...input, email: e.target.value })}
              />
            </div>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton onClick={() => onClose()} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Submit</IonButton>
          </IonRow>
        </div>
      </OverlayContainer>
    </>
  )
}

export default StakeholderForm
