import styled from 'styled-components'

const WidgetContainer = styled.div`
  border-radius: 10px;
  position: relative;
  font-size: .9rem;
  max-height: 100%;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 4px rgba(0, 0, 0, 0.25);
`

const WidgetTitles = styled.div`
  border-bottom: 1px solid white;
  background-color: white;
  width: 100%;
  color: #326771;
  height: 25%;
  text-align: center;
  padding-right: 20px;
`

const ViewButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
`

const AlertStateIcon = styled.div`
  padding: 10px;
  border-radius: 50%;
  border: 1px solid darkgrey;
`

export {
  WidgetContainer,
  WidgetTitles,
  ViewButton,
  AlertStateIcon,
}
