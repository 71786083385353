import React, { useState } from 'react'
import { IonRow, IonCol, useIonAlert } from '@ionic/react'

import {
  FactorOption, QuadrantContainer, QuadrantRow, StakeholderPoint, ViewContainer,
} from '../engagement/StyledContainers'
import { SettingsContainer } from '../settings/StyledContainers'
import {
  InternalProject, StakeholderQuadrant, Stakeholder
} from '../types/OptimizedMaps'
import axios from '../../utils/axios'
import { useWorkspace } from '../../hooks/useWorkspace'
import { faStepBackward } from '@fortawesome/free-solid-svg-icons'

interface StakeholderData {
  id: number;
  stakeholder_id: number;
  internal_project_id: number;
  quadrant: null | any; // Update the type accordingly
  created_at: string;
  updated_at: string;
  stakeholder: {
    id: number;
    name: string;
    alias_name: string;
    stakeholder_type: string;
    comments: string;
    domain_id: number;
    created_at: string;
    updated_at: string;
    role: null | any; // Update the type accordingly
    phone_number: string | null;
    whatsapp_number: string | null;
    email: string | null;
  }
}

interface Props {
  stakeholders: StakeholderData[] // Modify the type of stakeholders according to your data structure
  project: InternalProject
  projects: InternalProject[]
  setInternalProjects: any
}

const StakeholderMap: React.FC<Props> = ({
  stakeholders, project, projects, setInternalProjects,
}) => {
  const [ionAlert] = useIonAlert()
  const { workspace } = useWorkspace()
  const [draggedId, setDraggedId] = useState<number>(null ?? 1)
  const [stakeholdersList, setStakeholdersList] = useState<any[]>(stakeholders)

  const updateProject = (project: InternalProject) => {
    console.log('updateProject')
    const copy = projects.slice()
    const index = copy.findIndex(({ id }) => id === project.id)

    if (index < 0) { return }

    copy.splice(index, 1, project)
    setInternalProjects(copy)
  }

  console.log(stakeholdersList)

  const updateProjectStakeholder = (stakeholderId: number, newQuadrant: StakeholderQuadrant) => {
    axios.put('/api/v2/internal_project/edit_stakeholder_quadrant', {
      domain_id: workspace.id,
      project_id: project.id,
      stakeholder_id: draggedId,
      new_quadrant: newQuadrant,
    }).then(({ data }) => {
      console.log(data)
      if (!data.ok) {
        ionAlert({
          header: 'Server error',
          message: data.message,
          buttons: [{ text: 'OK' }],
        })
        return
      }

      const updatedStakeholders = stakeholders.map((val) => {
        if (val.stakeholder_id === draggedId) {
          return { ...val, quadrant: newQuadrant }
        }
        return val
      })
      const copy = stakeholders
      const index = copy.findIndex(({ stakeholder_id }) => stakeholder_id === draggedId)
      copy.splice(index, 1, { ...copy[index], quadrant: newQuadrant })
      const updated = { ...project, stakeholders: copy }

      const newStakeholders = [...copy]
      setStakeholdersList(newStakeholders)
      updateProject(updated)
      //handleSelectFactor(selection.type, updated)
    }).catch(() => {
      // ionAlert({
      //   header: 'Server Erroreeeeeeee',
      //   message: 'Unknown server error',
      //   buttons: [{ text: 'Ok' }],
      // })
    }).finally(() => {

    })
  }

  const handleDrop = (e: DragEvent, newQuadrant: StakeholderQuadrant) => {
    console.log('drag event')

    e.preventDefault()
    e.stopPropagation()

    const id = Number(e.dataTransfer.getData('id'))

    updateProjectStakeholder(id, newQuadrant)
  }

  const handleFocus = (id: number) => {
    setDraggedId(id)
  }

  return (
    <SettingsContainer style={{ paddingTop: '0px', marginTop: '0px' }}>
      <IonRow style={{ height: '90%', backgroundColor: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
        <IonCol style={{ height: '100%', width: '100%', padding: '0' }}>
          <ViewContainer>
            <IonRow style={{ height: '100%' }}>
              <div style={{
                flex: '2', padding: '0px 40px', margin: '40px 0px', borderRight: '1px solid black',
              }}
              >
                <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
                  <IonRow className='ion-justify-content-between ion-align-items-center'>
                    <div className='quadrant-labels'>
                      <p>High Importance/</p>
                      <p>Low influence</p>
                    </div>
                    <div className='quadrant-labels'>
                      <p>High Importance/</p>
                      <p>High influence</p>
                    </div>
                  </IonRow>
                  <QuadrantContainer>
                    <QuadrantRow>
                      <QuadrantContainer
                        onDrop={(e) => handleDrop(e, StakeholderQuadrant.HIGH_LOW)}
                        onDragOver={(e) => e.preventDefault()}
                        style={{ backgroundColor: 'rgba(255, 153, 0, 0.25', border: '1px solid black' }}
                      >
                        {
                          stakeholdersList.filter(({ quadrant }) => quadrant === StakeholderQuadrant.HIGH_LOW).map((val) => (
                            <StakeholderPoint
                              key={val.id}
                              draggable
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                top: `${Math.random() * 80}%`,
                                left: `${Math.random() * 75}%`,
                              }}
                              title={val.stakeholder.name}
                              onDragStart={(e) => handleFocus(val.stakeholder_id)}
                              onDragEnd={(e) => handleFocus(val.stakeholder_id)}
                            >{ val.stakeholder.name }
                            </StakeholderPoint>
                          ))
                        }
                      </QuadrantContainer>
                      <QuadrantContainer
                        onDrop={(e) => handleDrop(e, StakeholderQuadrant.HIGH_HIGH)}
                        onDragOver={(e) => e.preventDefault()}
                        style={{ backgroundColor: 'rgba(255, 0, 0, 0.25)', borderTop: '1px solid black', borderRight: '1px solid black' }}
                      >
                        {
                          stakeholdersList.filter(({ quadrant }) => quadrant === StakeholderQuadrant.HIGH_HIGH).map((val) => (
                            <StakeholderPoint
                              key={val.id}
                              draggable
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                top: `${Math.random() * 80}%`,
                                left: `${Math.random() * 75}%`,
                              }}
                              title={val.stakeholder.name}
                              onDragStart={(e) => handleFocus(val.stakeholder_id)}
                              onDragEnd={(e) => handleFocus(val.stakeholder_id)}
                            >{ val.stakeholder.name }
                            </StakeholderPoint>
                          ))
                        }
                      </QuadrantContainer>
                    </QuadrantRow>
                    <QuadrantRow>
                      <QuadrantContainer
                        onDrop={(e) => handleDrop(e, StakeholderQuadrant.LOW_LOW)}
                        onDragOver={(e) => e.preventDefault()}
                        style={{ backgroundColor: 'rgba(12, 149, 0, 0.25)', borderLeft: '1px solid black', borderBottom: '1px solid black' }}
                      >
                        {
                          stakeholdersList.filter(({ quadrant }) => quadrant === StakeholderQuadrant.LOW_LOW).map((val) => (
                            <StakeholderPoint
                              key={val.id}
                              draggable
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                top: `${Math.random() * 80}%`,
                                left: `${Math.random() * 75}%`,
                              }}
                              title={val.stakeholder.name}
                              onDragStart={(e) => handleFocus(val.stakeholder_id)}
                              onDragEnd={(e) => handleFocus(val.stakeholder_id)}
                            >{ val.stakeholder.name }
                            </StakeholderPoint>
                          ))
                        }
                      </QuadrantContainer>
                      <QuadrantContainer
                        onDrop={(e) => handleDrop(e, StakeholderQuadrant.LOW_HIGH)}
                        onDragOver={(e) => e.preventDefault()}
                        style={{ backgroundColor: 'rgba(0, 117, 255, 0.25)', border: '1px solid black' }}
                      >
                        {
                          stakeholdersList.filter(({ quadrant }) => quadrant === StakeholderQuadrant.LOW_HIGH).map((val) => (
                            <StakeholderPoint
                              key={val.id}
                              draggable
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                top: `${Math.random() * 80}%`,
                                left: `${Math.random() * 75}%`,
                              }}
                              title={val.stakeholder.name}
                              onDragStart={(e) => handleFocus(val.stakeholder_id)}
                              onDragEnd={(e) => handleFocus(val.stakeholder_id)}
                            >{ val.stakeholder.name }
                            </StakeholderPoint>
                          ))
                        }
                      </QuadrantContainer>
                    </QuadrantRow>
                  </QuadrantContainer>
                  <IonRow className='ion-justify-content-between ion-align-items-center'>
                    <div className='quadrant-labels'>
                      <p>Low Importance/</p>
                      <p>Low influence</p>
                    </div>
                    <div className='quadrant-labels'>
                      <p>Low Importance/</p>
                      <p>High influence</p>
                    </div>
                  </IonRow>
                </div>
              </div>
              <div style={{ flex: 1, padding: '40px 20px' }}>
                <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
                  <h4 style={{ fontWeight: 'bold', color: '#326771', fontSize: '1.2rem' }}>Stakeholders</h4>
                  <p style={{ margin: '15px 0' }}>Drag a Stakeholder to set its Quadrant Value.</p>
                  <div style={{ flex: 1, overflow: 'auto' }}>
                    <>
                      {
                        stakeholdersList
                          .filter((val) => val.quadrant === null)
                          .map((val) => (
                            <FactorOption
                              draggable
                              key={val.id}
                              onDragStart={(e) => handleFocus(val.stakeholder_id)}
                              onDragEnd={(e) => handleFocus(val.stakeholder_id)}
                            >
                              {val.stakeholder.name}
                            </FactorOption>
                          ))
                      }
                    </>
                  </div>
                </div>
              </div>
            </IonRow>
          </ViewContainer>
        </IonCol>
      </IonRow>
    </SettingsContainer>
  )
}

export default StakeholderMap
