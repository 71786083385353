/* eslint-disable no-trailing-spaces */
import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

import img1 from './InsightFilter1.jpg'
import img2 from './InsightFilter2.jpg'
import img3 from './InsightFilter3.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const InsightUseMap = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-insights')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' />Insights Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Filters and Views
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonText>On the insights map, it is possible to change the settings so that the map view changes. In particular, the view can be 
                  changed from default to show the locations of where incidents occurred, as well as to show the alert state levels of different counties. Examples are shown below.
                </IonText>
                <IonText><br />To do this, click on the ‘Default View’ drop-down icon, shown in red below. Scroll down to whichever view you want to activate and click on this. 
                  The map will automatically update to show this new view.
                </IonText>
                <IonImg src={img1} />
                <IonText><br /><br />Alert States View - Filters<br /><br />For instance, if you select ‘Alert States’ view, you will get something similar to the below. 
                  If you are only interested in viewing certain alert state levels, you can use the filter function to highlight only those levels you are interested in. To do this, 
                  click on the filter icon in the top-right corner (shown in red below).
                </IonText>
                <IonText><br />Then, select the alert state levels you want to view on the map by clicking on the different options in the boxes. 
                  Those with a tick next to them will be visible on the map. The map below, for instance, depicts those counties at Alert State 1 and Alert State 3.
                </IonText>
                <IonText><br />•‘Toggle all’ selects or deselects all the options in the drop-down box when clicked on</IonText>
                <IonImg src={img2} />
                <IonText><br />Incidents View – Filters<br /><br />With ‘Incidents’ view, you should get something like what is depicted below. The blue pins correspond to a 
                  particular incident in a given location. Clicking on a pin will provide more information on that incident, as will the box below the map.
                </IonText>
                <IonText><br />Specific incidents can be filtered using the filter function (red box). To do this, click on the filter icon. Then, select the type of 
                  incident you want to view on the map by clicking on the different options in the boxes. Those with a tick next to them will be visible on the map; 
                  those with no tick will be filtered out. 
                </IonText>
                <IonText><br />For instance, if you are only interested in incidents related to travel, ensure that the ‘Travel’ box has a tick in it, and that all the other 
                  boxes are empty. To get rid of a tick, simply click on it.
                </IonText>
                <IonText src={img3} />
              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-usemap'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Using the Map
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addinsight'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Add an Insight
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-alertstates'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Alert States
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addincident'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Adding an Incident
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default InsightUseMap
