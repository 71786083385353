import { IonList, useIonAlert } from '@ionic/react'
import React, {
  FC, useEffect, useReducer, useRef, useState,
} from 'react'
import { useJourneysData2 } from '../../../hooks/journeys/useJourneysData2'
import { ObscureBackground } from '../../GlobalContainers'
import { ControlMeasure } from '../../riskregister/types/RiskAssessments'
import { IncidentCause, RestrictionZoneType } from '../../types/GlobalTypes'
import { createMB } from '../apps/AppMap'
import BoxMeasures from '../forms/BoxMeasures'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import FormPopup from '../forms/FormPopup'
import MapForm from '../forms/MapForm'
import RestritionZoneForm from '../forms/RestrictionZoneForm'
import RestrictionZoneDetails from './RestrictionZoneDetails'
import { getRestrictionZoneColor, processZoneLogs } from '../../../hooks/journeys/helpers/RestrictionZoneUtils'
import { TrackedUsersActionKind, trackedUsersReducer } from '../../../hooks/journeys/helpers/StateReducers'
import axios from '../../../utils/axios'
import EditRestrictionZoneUsers from './EditRestrictionZoneUsers'
import { BackendTrackedUser } from '../../types/BackendTypes'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { ZoneLog } from '../../../hooks/journeys/types/HookInterface2'
import RestrictionZoneLog from './RestrictionZoneLog'
import BoxCauses from '../forms/BoxCauses'
import BoxThreats from '../forms/BoxThreats'
import { restrictionZonesToGeoJSON } from '../../utils/MapboxUtils'
import { RestrictionZone } from '../../types/OptimizedMaps'

enum FlowViews {
  LOG_SCREEN = 'LOG',
  EDIT_MEASURES = 'EDIT MEASURES',
  EDIT_TRACKED_USERS = 'EDIT USERS',
  SUBMITTED_SCREEN = 'SUBMITTED SCREEN',
  DETAILS_SCREEN = 'DETAILS SCREEN',
}

const RestrictionZoneEdit: FC = () => {
  const {
    focusedMb, setFocusedMb, boxMeasures, mapRef, setEditingBox, restrictionZones, causes,
    domainUsers, users, updateRestrictionZone, setLoading, removeRestrictionZone, incidentTypes,
  } = useJourneysData2()
  const [currentView, setCurrentView] = useState<FlowViews>(FlowViews.LOG_SCREEN)
  const [input, setInput] = useState({ review: focusedMb.check_in_days, description: focusedMb.description })
  const [selectedMeasures, setSelectedMeasures] = useState<ControlMeasure[]>(focusedMb.controlMeasures.slice())
  const [selectedThreats, setSelectedThreats] = useState<string[]>(focusedMb.threats.slice())
  const [selectedCauses, setSelectedCauses] = useState<IncidentCause[]>(focusedMb.underlyings.slice())
  const [showThreats, setShowThreats] = useState<boolean>(false)
  const [showCauses, setShowCauses] = useState<boolean>(false)
  const [trackedUsers, dispatchTrackUsers] = useReducer(trackedUsersReducer, { toggleAll: false, users: [], domainUsers: [] })
  const [coordinates, setCoordinates] = useState([])
  const [editing, setEditing] = useState<boolean>(false)
  const [showMeasures, setShowMeasures] = useState<boolean>(false)
  const [markers, setMarkers] = useState([])
  const [zoneLogs, setZoneLogs] = useState<ZoneLog[]>([])
  const [alert] = useIonAlert()
  const { workspace } = useWorkspace()

  const handleCancel = (typed: boolean) => {
    const callback = () => {
      markers.map((marker) => marker.remove())
      const geojsonBoxes = restrictionZonesToGeoJSON(restrictionZones);
      (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData(geojsonBoxes)
      setFocusedMb(null)
      setEditingBox(false)
    }
    if (typed || editing) {
      alert({
        header: 'Cancel Restriction Box?',
        message: 'Do you wish to continue? Your progress will be lost. ',
        buttons: [
          'Back',
          {
            text: 'Yes, continue',
            handler: callback,
          },
        ],
      })

      return
    }
    setFocusedMb(null)
  }

  const handleSubmitMb = (isTracking: boolean) => {
    const usersToTrack = (isTracking) ? [...trackedUsers.domainUsers.filter(({ checked }) => checked).map(({ id }) => id),
      ...trackedUsers.users.filter(({ checked }) => checked).map(({ id }) => id)] : []

    const newFeature = {
      type: 'Feature',
      geometry: {
        coordinates,
        type: 'LineString',
      },
    }
    const measuresToRemove = focusedMb.controlMeasures.filter(({ id }) => !selectedMeasures.find((val) => val.id === id))
    const measuresToAdd = selectedMeasures.filter(({ id }) => !focusedMb.controlMeasures.find((val) => val.id === id))

    const threatsToRemove = focusedMb.threats.filter((threat) => !selectedThreats.find((val) => val === threat))
    const threatsToAdd = selectedThreats.filter((val) => !focusedMb.threats.find((threat) => val === threat))

    const causesToRemove = focusedMb.underlyings.filter(({ id }) => !selectedCauses.find((val) => val.id === id))
    const causesToAdd = selectedCauses.filter((val) => !focusedMb.underlyings.find(({ id }) => id === val.id))

    setLoading(true)
    axios.put('api/v1/movement_box/edit_movement_box', {
      movement_box_id: focusedMb.id,
      domain_id: workspace.id,
      user_list: usersToTrack,
      geoData: JSON.stringify(newFeature),
      check_in_days: input.review,
      description: input.description,
      threats_add: threatsToAdd,
      threats_remove: threatsToRemove,
      causes_add: causesToAdd.map(({ id }) => id),
      causes_remove: causesToRemove.map(({ id }) => id),
      measures_remove: measuresToRemove.map(({ id }) => id),
      measures_add: measuresToAdd.map(({ id }) => id),
    }).then(() => {
      const updated: RestrictionZone = {
        ...focusedMb,
        controlMeasures: [...selectedMeasures],
        geodata: {
          ...newFeature,
          type: 'Feature',
          geometry: { ...newFeature.geometry, type: 'LineString' },
          properties: { id: focusedMb.id, type: getRestrictionZoneColor(focusedMb.zone_type) },
        },
        description: input.description,
        check_in_days: input.review,
        threats: [...selectedThreats],
        underlyings: [...selectedCauses],
      }
      markers.map((marker) => marker.remove())
      setFocusedMb(null)
      setEditingBox(false)
      updateRestrictionZone(updated)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleStartEdit = () => {
    setEditingBox(true)
    startEdit(focusedMb.geodata.geometry.coordinates.slice())
    if (focusedMb.zone_type !== RestrictionZoneType.RED) {
      setCurrentView(FlowViews.EDIT_MEASURES)
      return
    }

    setCurrentView(FlowViews.EDIT_TRACKED_USERS)
  }

  const hideForm = () => {
    mapRef.current.easeTo({
      padding: {
        right: 0,
        top: 0,
        bottom: 0,
        left: 0,
      },
    })
    setFocusedMb(null)
  }

  const handleNextUsers = () => {
    setCurrentView(FlowViews.EDIT_TRACKED_USERS)
  }

  const setCurrentPoint = ([lon, lat], index) => {
    if (!coordinates.length) return

    /* Update location  */
    coordinates[index] = [lon, lat]

    /* Change last markers position */
    markers[index].setLngLat([lon, lat])

    if (index === 0) coordinates[coordinates.length - 1] = [lon, lat];

    /* Update layer */
    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates,
      },
    })
    setMarkers(markers)
    setCoordinates(coordinates)
  }
  /* Use reference to keep the state updated  */
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  const startEdit = (points: number[][]) => {
    setEditing(true)
    createMB(mapRef, focusedMb.geodata.geometry.coordinates)
    mapRef.current.addLayer({
      id: 'new-layer',
      type: 'fill',
      source: 'new-restriction-box',
      layout: {},
      paint: {
        'fill-color': getRestrictionZoneColor(focusedMb.zone_type), // blue color fill
        'fill-opacity': 0.5,
      },
    })

    const filteredBox = restrictionZones.filter(({ id }) => id !== focusedMb.id)
    const geoJSONBoxes = restrictionZonesToGeoJSON(filteredBox);

    (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData(geoJSONBoxes)

    const tempMarkers = []
    points.forEach((lngLat, index) => {
      if (index + 1 === points.length) { return }
      const marker = new mapboxgl.Marker()
      marker.setLngLat(lngLat).addTo(mapRef.current)

      marker.setDraggable(true)
      marker.on('drag', () => {
        setCurrentPointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], index)
      })

      tempMarkers.push(marker)
    })
    setMarkers(tempMarkers)
    setCoordinates(points)
  }

  useEffect(() => {
    if (focusedMb.zone_type !== RestrictionZoneType.RED) {
      setCurrentView(FlowViews.DETAILS_SCREEN)
      return
    }
    setCurrentView(FlowViews.LOG_SCREEN)
  }, [focusedMb])

  const cleanRestrictionZone = () => {
    if (!mapRef.current.getSource('new-restriction-box')) return
    mapRef.current.removeLayer('new-restriction-box')
    if (mapRef.current.getLayer('new-layer')) mapRef.current.removeLayer('new-layer')
    mapRef.current.removeSource('new-restriction-box')
  }

  const handleRemove = () => {
    const callback = () => {
      setLoading(true)
      axios.put('/api/v1/movement_box/archiveMovementBox', { movement_box_id: focusedMb.id, domain_id: workspace.id }).then(() => {
        removeRestrictionZone(focusedMb)
      }).finally(() => {
        setFocusedMb(null)
        setLoading(false)
      })
    }
    alert({
      header: 'Remove Restriction Zone?',
      message: 'Are you sure you want to remove this Restriction Zone?',
      buttons: [
        'Cancel',
        {
          text: 'Yes, continue',
          handler: callback,
        },
      ],
    })
  }

  useEffect(() => () => {
    cleanRestrictionZone()
  }, [])

  const handleCancelEdit = () => {
    setEditing(false)
    setCurrentView(FlowViews.LOG_SCREEN)
    markers.map((marker) => marker.remove())
    const geojsonBoxes = restrictionZonesToGeoJSON(restrictionZones);
    (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData(geojsonBoxes)
    cleanRestrictionZone()
  }

  useEffect(() => {
    Promise.all([axios.post('/api/v1/movement_box/getUserList', { domain_id: workspace.id, box_id: focusedMb.id }),
      axios.post('/api/v1/movement_box/getZoneLogs', { zone_id: focusedMb.id })]).then(([{ data }, zoneLogsData]) => {
      const dbTracked : BackendTrackedUser[] = data.users
      const formattedDomainUsers = domainUsers.map((val) => {
        const status = dbTracked.find(({ user_id }) => user_id === val.id)
        return { ...val, status: (status) ? status.consented : undefined }
      })
      const formattedAllUsers = users.map((val) => {
        const status = dbTracked.find(({ user_id }) => user_id === val.id)
        return { ...val, status: (status) ? status.consented : undefined }
      })

      const formattedLogs = processZoneLogs(zoneLogsData.data.logs, users)
      setZoneLogs(formattedLogs)
      dispatchTrackUsers({ type: TrackedUsersActionKind.POPULATE_LIST, domainUsers: formattedDomainUsers, allUsers: formattedAllUsers })
    })
  }, [focusedMb])

  if (currentView === FlowViews.DETAILS_SCREEN) {
    return (
      <RestrictionZoneDetails
        data={focusedMb}
        onCancel={() => handleCancel(false)}
        onEdit={handleStartEdit}
        onRemove={handleRemove}
        logs={zoneLogs}
      />
    )
  }

  if (currentView === FlowViews.EDIT_MEASURES) {
    return (
      <>
        <RestritionZoneForm
          selectedMeasures={selectedMeasures}
          onCancel={() => handleCancel(true)}
          editing
          onShowRestrictions={() => setShowMeasures(true)}
          handleChange={(label, value) => setInput({ ...input, [label]: value })}
          input={input}
          selectedThreats={selectedThreats}
          selectedUnderlying={selectedCauses}
          onShowThreats={() => setShowThreats(true)}
          onShowUnderlying={() => setShowCauses(true)}
          canSubmit={focusedMb.zone_type === RestrictionZoneType.AMBER}
          onNext={(focusedMb.zone_type === RestrictionZoneType.AMBER) ? () => handleSubmitMb(false) : handleNextUsers}
        />
        {
          showMeasures && (
            <>
              <ObscureBackground style={{ zIndex: 19 }} />
              <BoxMeasures
                allMeasures={boxMeasures}
                selectedMeasures={selectedMeasures}
                onClose={() => setShowMeasures(false)}
                onFinish={(measures) => {
                  setSelectedMeasures(measures)
                  setShowMeasures(false)
                }}
              />
            </>
          )
        }
        {
          showThreats && (
            <>
              <ObscureBackground style={{ zIndex: 19 }} />
              <BoxThreats
                allTypes={incidentTypes}
                onFinish={(selected) => {
                  setSelectedThreats(selected)
                  setShowThreats(false)
                }}
                selectedThreats={selectedThreats}
                onClose={() => setShowThreats(false)}
              />
            </>
          )
        }
        {
          showCauses && (
            <>
              <ObscureBackground style={{ zIndex: 19 }} />
              <BoxCauses
                allCauses={causes}
                onClose={() => setShowCauses(false)}
                onFinish={(selected) => {
                  setSelectedCauses(selected)
                  setShowCauses(false)
                }}
                selectedCauses={selectedCauses}
              />
            </>
          )
        }
      </>
    )
  }

  if (currentView === FlowViews.LOG_SCREEN) {
    return (
      <RestrictionZoneLog
        onEdit={handleStartEdit}
        onRemove={handleRemove}
        onClose={() => setFocusedMb(null)}
        logs={zoneLogs}
      />
    )
  }

  if (currentView === FlowViews.EDIT_TRACKED_USERS) {
    return (
      <EditRestrictionZoneUsers
        trackUsers={trackedUsers}
        dispatchTrackUsers={dispatchTrackUsers}
        onBack={() => setCurrentView(FlowViews.EDIT_MEASURES)}
        onClose={() => handleCancel(true)}
        onSubmit={() => handleSubmitMb(true)}
        canCancel={focusedMb.zone_type === RestrictionZoneType.RED}
        onCancel={handleCancelEdit}
      />
    )
  }

  return (
    <MapForm>
      <IonList style={{ padding: '20px', height: '100%' }}>
        <FormPopup
          message='You have successfully created a Restriction Box.'
          onClose={hideForm}
        />
      </IonList>
    </MapForm>
  )
}

export default RestrictionZoneEdit
