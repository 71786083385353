import React from 'react'
import Select from 'react-select'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, SelectionDetail } from '../../StyledContainers'

/**
 *  ===========================
 *     Inherent Risk Impact
 *  ===========================
 */
const InherentImpact = ({
  input, setInput, next, onClose, children,
  people, operations, property, edit,
}) => (
  <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
    <IonContent>
      <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
        <div>
          {
            children
          }
          <SettingField>
            <h5 className='blue'>Inherent Risk - Impact Assessment</h5>
            <p>{(edit) ? (`The following is the scoring you selected for the impact that this Risk Event occurring would have on the following factors. 
            You can adjust these values accordingly as part of this review.`)
              : 'Use the drop-downs below to assess the consequences the selected Risk Event would have on the following factors were it to take place.' }
            </p>
          </SettingField>
          <IonRow className='ion-justify-content-between'>
            <IonCol size={4}>
              <SettingField>
                <h5>People</h5>
                <Select
                  placeholder='Select People'
                  id='ra-inherent-people-impact'
                  name='inherentPeople'
                  className='select-container'
                  value={input.inherentPeople}
                      // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={Object.keys(people).map((level) => ({ value: level, label: level }))}
                  onChange={(selected) => setInput({ ...input, inherentPeople: selected, edit: true })}
                />
              </SettingField>
            </IonCol>
            {
              input.inherentPeople && (
                <IonCol size={8}>
                  <SelectionDetail>
                    <p>{ people[input.inherentPeople.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <IonRow>
            <IonCol size={4}>
              <SettingField>
                <h5>Operations</h5>
                <Select
                  placeholder='Select Operations'
                  id='ra-inherent-operations-impact'
                  name='inherentOperations'
                  className='select-container'
                  value={input.inherentOperations}
                  minMenuHeight={70}
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={Object.keys(operations).map((level) => ({ label: level, value: level }))}
                  onChange={(selected) => setInput({ ...input, inherentOperations: selected, edit: true })}
                />
              </SettingField>
            </IonCol>
            {
              input.inherentOperations && (
                <IonCol size={8}>
                  <SelectionDetail>
                    <p>{ operations[input.inherentOperations.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <IonRow>
            <IonCol size={4}>
              <SettingField>
                <h5>Property</h5>
                <Select
                  placeholder='Select Property'
                  id='ra-inherent-property-impact'
                  name='inherentProperty'
                  className='select-container'
                  value={input.inherentProperty}
                  minMenuHeight={70}
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={Object.keys(property).map((level) => ({ label: level, value: level }))}
                  onChange={(selected) => setInput({ ...input, inherentProperty: selected, edit: true })}
                />
              </SettingField>
            </IonCol>
            {
              input.inherentProperty && (
                <IonCol size={8}>
                  <SelectionDetail>
                    <p>{ property[input.inherentProperty.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
        </div>
        <IonRow className='ion-justify-content-end'>
          <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
          <IonButton
            style={{ '--background': '#326771' }}
            onClick={next}
            disabled={!(input.inherentPeople && input.inherentOperations && input.inherentProperty)}
          >Next
          </IonButton>
        </IonRow>
      </IonList>
    </IonContent>
  </CenterForm>
)

export default InherentImpact
