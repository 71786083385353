/* eslint-disable no-trailing-spaces */
import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

import map from './MapInsight.jpg'
import controls from './MapControls.jpg'
import location from './Location.jpg'
import compass from './Compass.jpg'
import fullscreen from './Fullscreen.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const InsightUseMap = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-insights')
  }

  return (

    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' />Insights Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Using the Map-Insights
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonCol>
                  <IonText>The map in the Insights App has several tools and functions. The aim of this article is to explain how to use the map to make the 
                    most of these functions. Below is an overview of the map from the Insights App:<br /><br />
                  </IonText>
                  <IonImg src={map} /><br />

                  <IonText>Highlighted in the red square is the ‘Default View’ dropdown box. Click on this to change the view to either ‘Incidents View’ or 
                    ‘Alert States View’. For more information on views and filters, click here (link to article).<br /><br />
                  </IonText>
                  <IonText>Highlighted in the green square is the ‘Add+’ function. This is used to add incidents or insight reports. For a full explanation 
                    on how to use this tool, click here.<br /><br />
                  </IonText>
                  <IonText>Highlighted in the yellow square is a toolbar for the map, which contains useful tools for map functions. These are listed below.<br /><br /></IonText>

                  <IonText>1. Zooming In and Out & Moving Around the Map</IonText>
                  <IonImg width='80' height='80' src={controls} style={{ width: '30%' }} />

                  <IonText>To zoom in and out, simply click on the plus or minus icon, respectively. As you zoom in, you will find that the map gets more detailed.</IonText>
                  <IonText>To move around the map, click anywhere on the map and hold down your click. Then move your cursor (with click still held down) in order to move around the map.</IonText>

                  <IonText><br /><br />2. Reset Bearing to North</IonText>
                  <IonImg src={compass} />
                  <IonText>Clicking on this icon above will show your location on the map. This can be useful if you want to report an incident which occurred nearby as it 
                    automatically takes you to your location on the map.
                  </IonText>
                  <IonText><br /><br />3. Find my Location</IonText>
                  <IonImg src={location} />
                  <IonText>This icon realigns the map so that it is oriented towards north. The map is set to this by default, but this can be useful if it happens that the bearing 
                    changes when you are working with the map.
                  </IonText>
                  <IonText><br /><br />4. Fullscreen</IonText>
                  <IonImg src={fullscreen} />
                  <IonText>Clicking on this icon will maximize the map to fit your whole screen. To exit this, simply click on the same icon. </IonText>

                </IonCol>

              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addinsight'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Add an Insight Report
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-views'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Filters and Views
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-alertstates'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Alert States
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addincident'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Adding an Incident
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default InsightUseMap
