import React, { useEffect } from 'react'
import Select from 'react-select'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, SelectionDetail } from '../../StyledContainers'

import { capability, threat, intent } from '../FormOptions'
import { computeThreatRating } from '../utils/RiskAssessmentCalculations'

/**
 *  ==========================
 *    Inherent Risk Thread
 *  ==========================
 *  Determine capability and  intent
 */
const InherentThread = ({
  input, setInput, children, onClose, next, edit,
}) => {
  const handleDropdowns = (selected, column) => {
    const newInput = { ...input, [column]: selected, edit: true }

    /* If both variables have been selected then calculate risk rating */
    if (newInput.capability && newInput.intent) {
      newInput.inherentThreatRating = computeThreatRating(newInput.intent.value, newInput.capability.value)
    }
    setInput(newInput)
  }

  useEffect(() => {
    if (input.intent && input.capability) {
      const inherentThreatRating = computeThreatRating(input.intent.value, input.capability.value)
      setInput({ ...input, inherentThreatRating })
    }
  }, [])
  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Inherent Risk - Threat Assessment</h5>
              {
            (edit) ? (
              <>
                <p>This is how Capability and Intent were scored in the previous assessment. You can adjust them accordingly as part of this Review.</p>
              </>
            ) : (
              <>
                <p>Threats are assessed, considering capability and intent of each threat source with a threat rating applied.</p>
                <p>Use the drop-downs below to determine the capability and intent of those who pose the threat to this asset.</p>
              </>
            )
          }
            </SettingField>
            <IonRow className='ion-justify-content-between'>
              <IonCol size={4}>
                <SettingField>
                  <h5>Capability</h5>
                  <Select
                    placeholder='Select Capability'
                    name='capability'
                    className='select-container'
                    id='capability-dropdown'
                    value={input.capability}
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={Object.keys(capability).map((level) => ({ value: level, label: level }))}
                    onChange={(selected) => handleDropdowns(selected, 'capability')}
                  />
                </SettingField>
              </IonCol>
              {
              input.capability && (
                <IonCol size={8}>
                  <SelectionDetail>
                    <p>{ capability[input.capability.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
            </IonRow>
            <IonRow>
              <IonCol size={4}>
                <SettingField>
                  <h5>Intent</h5>
                  <Select
                    placeholder='Select intent'
                    name='intent'
                    id='intent-dropdown'
                    className='select-container'
                    minMenuHeight={100}
                    value={input.intent}
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={Object.keys(intent).map((level) => ({ value: level, label: level }))}
                    onChange={(selected) => handleDropdowns(selected, 'intent')}
                  />
                </SettingField>
              </IonCol>
              {
                input.intent && (
                  <IonCol size={8}>
                    <SelectionDetail>
                      <p>{ intent[input.intent.value] }</p>
                    </SelectionDetail>
                  </IonCol>
                )
              }
            </IonRow>
            <IonRow>
              <IonCol size={4}>
                <SettingField style={{ width: 'auto' }}>
                  <h5>Threat Rating</h5>
                  <p id='ra-threat-rating'> { input.inherentThreatRating } </p>
                </SettingField>
              </IonCol>
              {
                input.inherentThreatRating && (
                  <IonCol size={8}>
                    <SelectionDetail>
                      <p>{ threat[input.inherentThreatRating] }</p>
                    </SelectionDetail>
                  </IonCol>
                )
              }
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next} disabled={!(input.capability && input.intent)}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default InherentThread
