/* eslint-disable no-trailing-spaces */
import React from 'react'
import {
  IonList, IonContent, IonRow, IonCol, IonCheckbox, IonButton,
} from '@ionic/react'
import { CenterForm, MeasureContainer, ColumnContainer } from '../../StyledContainers'
import { SettingField } from '../../../settings/StyledContainers'

/**
 *  ======================================
 *          REMOVE NEW MEASURES
 *  ======================================
 */
const RemoveExistingMeasures = ({
  children, input, onClose, next, setInput, measures,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const handleCheck = (e, measure) => {
    /* Change switch between existing or new measures list */
    const copy = input.existingMeasures.slice()
    if (e.target.checked) {
      if (!copy.find((val) => val.id === measure.id)) { copy.push(measure) }
    } else {
      const index = copy.findIndex((val) => val.id === measure.id)
      if (index >= 0) { copy.splice(index, 1) }
    }
    setInput({ ...input, removeExisting: { ...input.removeExisting, [measure.id]: e.target.checked }, existingMeasures: copy })
  }

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Review of Existing Control Measures</h5>
              <p> The following control measures were listed as being in place for this asset. Please check the boxes below to confirm that these measures are still in place.</p>
              <p> Unchecked measures will be removed from the list of Existing Control Measures. When you are finished, click <span className='highlighted-blue'>‘Next’</span> to continue.</p>
            </SettingField>
            <IonRow style={{ marginTop: '10px' }} className='ion-justify-content-center'>
              <ColumnContainer style={{ width: '350px' }}>
                {
                  input.removeExisting && measures.filter((val) => Object.keys(input.removeExisting).indexOf(String(val.id)) >= 0).map((measure, index) => (
                    <MeasureContainer key={measure.id} style={{ backgroundColor: getColor(index) }}>
                      <IonRow className='ion-justify-content-between ion-align-items-center'>
                        <IonCol size={11}>
                          <p>{ measure.control_measure }</p>
                        </IonCol>
                        <IonCol size={1}>
                          <IonCheckbox id={`checkbox-existing-${index}`} onClick={(e) => handleCheck(e, measure)} checked={input.removeExisting[measure.id]} />
                        </IonCol>
                      </IonRow>
                    </MeasureContainer>
                  ))
                }
              </ColumnContainer>
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end' style={{ paddingRight: '20px' }}>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton
              style={{ '--background': '#326771' }}
              onClick={next}
            >Next
            </IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default RemoveExistingMeasures
