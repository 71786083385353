import { Icon } from '@iconify/react'
import { IonCheckbox, IonLabel, IonRow } from '@ionic/react'
import * as React from 'react'
import { Dropdown } from 'react-bootstrap'
import { InsightFiltersActionKind } from '../../../hooks/insights/helpers/FilterReducers'
import { AsFilterUpdate, InsightViews } from '../../../hooks/insights/helpers/StateReducers'
import { useInsightsData } from '../../../hooks/insights/useInsightsData'
import { LineDivider, ObscureBackground } from '../../GlobalContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import { CenterForm } from '../../riskregister/StyledContainers'

const MobileFiltersPopup = () => {
  const {
    view, updateIncidents, incidentFilters, updateAsFilters,
    asFilters, setShowMobileFilters,
  } = useInsightsData()
  return (
    <div className='mobile-filters-container'>
      <ObscureBackground style={{ zIndex: 2 }} />
      <CenterForm style={{
        width: '250px', height: 'auto', padding: '10px', borderRadius: '2.5px',
      }}
      >
        <IonRow style={{ padding: '0.25rem 1rem' }} className='ion-justify-content-between ion-align-items-center'>
          <h4 style={{ color: '#326771' }}>Filters <Icon icon='fa:filter' /></h4>
          <SimpleButton style={{ paddingRight: 0 }} onClick={() => setShowMobileFilters(false)}>Done</SimpleButton>
        </IonRow>
        {
        (view === InsightViews.INCIDENTS) && (
        <>
          <Dropdown.Item id='toggle-all-assets-filter' onClick={() => updateIncidents({ type: InsightFiltersActionKind.TOGGLE_ALL }, null, null)} className='task-filter-option'>
            <IonLabel>Toggle all</IonLabel>
            <IonCheckbox
              checked={incidentFilters.toggleAll}
              onClick={(e) => {
                if (e.stopPropagation) e.stopPropagation()
                updateIncidents({ type: InsightFiltersActionKind.TOGGLE_ALL }, null, null)
              }}
            />
          </Dropdown.Item>
          {
            Object.keys(incidentFilters.filters).map((key) => (
              <Dropdown.Item onClick={() => updateIncidents({ type: InsightFiltersActionKind.TOGGLE_SINGLE, payload: key }, null, null)} className='task-filter-option' key={key}>
                <IonLabel> {key} </IonLabel>
                <IonCheckbox
                  name={key}
                  checked={incidentFilters.filters[key]}
                  onClick={(e) => {
                    if (e.stopPropagation) e.stopPropagation()
                    updateIncidents({ type: InsightFiltersActionKind.TOGGLE_SINGLE, payload: key }, null, null)
                  }}
                />
              </Dropdown.Item>
            ))
           }
          <LineDivider />
        </>
        )
      }
        {
        (view !== InsightViews.INCIDENTS) && (
        <>
          <Dropdown.Item onClick={() => updateAsFilters(AsFilterUpdate.SHOW_INCIDENTS)} className='task-filter-option'>
            <IonLabel> Show Incidents </IonLabel>
            <IonCheckbox
              name='Incidents'
              checked={asFilters.showIncidents}
              onClick={(e) => {
                if (e.stopPropagation) e.stopPropagation()
                updateAsFilters(AsFilterUpdate.SHOW_INCIDENTS)
              }}
            />
          </Dropdown.Item>
        </>
        )
      }
        <Dropdown.Item onClick={() => updateAsFilters(AsFilterUpdate.SHOW_ASSETS)} className='task-filter-option'>
          <IonLabel> Show Assets </IonLabel>
          <IonCheckbox
            name='Assets'
            checked={asFilters.showAssets}
            onClick={(e) => {
              if (e.stopPropagation) e.stopPropagation()
              updateAsFilters(AsFilterUpdate.SHOW_ASSETS)
            }}
          />
        </Dropdown.Item>
        <Dropdown.Item onClick={() => updateAsFilters(AsFilterUpdate.SHOW_ROUTES)} className='task-filter-option'>
          <IonLabel> Show Routes </IonLabel>
          <IonCheckbox
            name='Routes'
            checked={asFilters.showRoutes}
            onClick={(e) => {
              if (e.stopPropagation) e.stopPropagation()
              updateAsFilters(AsFilterUpdate.SHOW_ROUTES)
            }}
          />
        </Dropdown.Item>
      </CenterForm>
    </div>
  )
}

export default MobileFiltersPopup
