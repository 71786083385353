/* eslint-disable no-undef */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  IonCol, IonRow, IonList, IonItem, IonInput, IonButton, useIonAlert, useIonLoading,
} from '@ionic/react'

import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { SettingField, SettingTitle } from '../StyledContainers'

import { countries } from '../CountryList.json'
import { useWorkspace } from '../../../hooks/useWorkspace'
import useApi from '../../../hooks/testHooks'
import { useAuth } from '../../../hooks/useAuth'

/**
 *  ===========================
 *    CREATE CHILD WORKSPACE
 *  ===========================
 */

const NewWorkspace = ({ setNewWorkspace, users, addDomain }) => {
  const [input, setInput] = useState({
    title: '',
    location: '',
    users: [],
    emails: [],
  })

  const workspaceHook = useWorkspace()
  const [ionAlert] = useIonAlert()
  const [present, dismiss] = useIonLoading()
  const history = useHistory()
  const apiHook = useApi()
  const auth = useAuth()

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  const onSubmit = async () => {
    let message = ''
    if (input.title === '') message = 'You have to assign a name for your workspace'
    else if (input.location === '') message = 'No location has been assigned to the workspace'

    if (message) {
      ionAlert({
        header: 'Error',
        message,
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })

      return
    }

    present()
    /* Send a request to create a new workspace */
    const response = await apiHook.tryWorkspace(workspaceHook.workspace.id, input.title, input.location)

    if (response.newborn < 0) {
      ionAlert({
        header: 'Error',
        message: response.message,
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
      dismiss()
      return
    }

    const newDomain = { id: response.newborn, name: input.title, type: 'administrator' }
    addDomain(newDomain)
    workspaceHook.setDomain(newDomain)
    history.push('/dashboard')

    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

    /* Send invites to the uses */
    const emails = [...input.emails, ...input.users]
    const validEmails = emails.filter((email) => re.test(email))

    /* Send request to invite these users */
    await apiHook.inviteToWorkspace(response.newborn, validEmails)
    dismiss()
  }

  return (
    <>
      <SettingTitle>New Workspace</SettingTitle>
      <IonRow style={{ height: '90%' }}>
        <IonCol size={7}>
          <IonList style={{ '--ion-item-background': 'white', padding: 'white' }}>
            <IonItem>
              <SettingField>
                <h4>Name</h4>
                <IonInput
                  type='text'
                  value={input.title}
                  placeholder='What is this workspace called?'
                  name='title'
                  onIonChange={handleChange}
                />
              </SettingField>
            </IonItem>
            <IonItem>
              <SettingField>
                <h4>Location</h4>
                <Select
                  placeholder='What country is this project operating in?'
                  menuPortalTarget={document.body}
                  className='settings-select'
                  options={countries}
                  onChange={(selected) => setInput({ ...input, location: selected.value })}
                />
              </SettingField>
            </IonItem>
          </IonList>
        </IonCol>
        <IonCol size={5} style={{ height: '100%' }}>
          <IonList style={{ '--ion-item-background': 'white' }}>
            <SettingField>
              <h4>Add Users</h4>
              <Select
                isMulti
                placeholder='Add users'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                className='settings-select'
                options={users.filter((user) => user.email !== auth.user.email).map((user) => ({ value: user.email, label: `${user.first_name} ${user.final_name}` }))}
                onChange={(selected) => setInput({ ...input, users: selected.map((val) => val.value) })}
              />
            </SettingField>
            <SettingField>
              <h4>Invite Users</h4>
              <CreatableSelect
                isMulti
                placeholder='Type an email address'
                name='colors'
                className='settings-select'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                components={{ DropdownIndicator: null }}
                onChange={(selected) => setInput({ ...input, emails: selected.map((val) => val.value) })}
                noOptionsMessage={() => null}
              />
            </SettingField>
          </IonList>
          <IonRow style={{ position: 'absolute', bottom: 0, width: '95%' }} className='ion-justify-content-end'>
            <IonButton onClick={() => setNewWorkspace(false)} style={{ '--background': '#8E151F' }}>cancel</IonButton>
            <IonButton onClick={onSubmit} style={{ '--background': '#326771' }}>Create</IonButton>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  )
}

export default NewWorkspace
