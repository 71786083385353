import * as React from 'react'
import { IncidentViewActionKind } from '../../../../hooks/incident-management/helpers/StateReducers'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { IncidentInvestigation } from '../../../types/GlobalTypes'
import InvestigationDetails from './InvestigationDetails'
import InvestigationForm from './InvestigationForm'

const Investigations : React.FC = () => {
  const [showForm, setShowForm] = React.useState<boolean>(true)
  const { displayView, updateIncident, dispatchView } = useIncidentsData()

  const handleSubmit = (investigation: IncidentInvestigation) => {
    const updated = { ...displayView.focusedIncident, investigations: investigation }
    updateIncident(updated)
    dispatchView({ type: IncidentViewActionKind.UPDATE_INCIDENT, incident: updated })
    setShowForm(false)
  }

  React.useEffect(() => {
    if (displayView.focusedIncident?.investigations) {
      setShowForm(false)
    }
  }, [])

  return (
    <>
      {
        (!showForm && displayView.focusedIncident?.investigations) ? (
          <InvestigationDetails
            data={displayView.focusedIncident?.investigations}
            onEdit={() => setShowForm(true)}
            archived={displayView.focusedIncident.archived}
          />
        ) : (
          <InvestigationForm
            data={displayView.focusedIncident?.investigations}
            onSubmit={handleSubmit}
            onCancel={() => setShowForm(false)}
          />
        )
      }
    </>
  )
}

export default Investigations
