/* eslint-disable import/prefer-default-export */
import { BackendFlashpoint, Flashpoint } from '../../../components/types/OptimizedMaps'

/**
 * Get frontend stakeholder list
 *
 * @param stakeholders -> Format backend stakeholders
 * @returns A frontend formatted list of stakeholders
 */
export const processFlashpoints = (stakeholders: BackendFlashpoint[]) : Flashpoint[] => {
  const formatted : Flashpoint[] = stakeholders.map((record) => ({
    id: record.id,
    title: record.title,
    description: record.description,
    triggers: record.triggers,
    status: record.status,
    primaryManifestation: record.primary_manifestation,
    additionalManifestation: record.additional_manifestations,
    ongoing: record.ongoing,
    issues: record.issues,
    primary_groups: record.flashpoint_groups.filter(({ group_type }) => group_type === 'primary').map(({ stakeholder, quadrant }) => ({ ...stakeholder, quadrant })),
    secondary_groups: record.flashpoint_groups.filter(({ group_type }) => group_type === 'secondary').map(({ stakeholder, quadrant }) => ({ ...stakeholder, quadrant })),
    tertiary_groups: record.flashpoint_groups.filter(({ group_type }) => group_type === 'tertiary').map(({ stakeholder, quadrant }) => ({ ...stakeholder, quadrant })),
    relevant_areas: record.areas.map(({ id, name }) => ({ id, name })),
    comments: record.comments,
  }))
  return formatted
}
