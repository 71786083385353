import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  IonPage, IonContent, IonGrid, IonRow, IonCol,
} from '@ionic/react'

import LoginForm from '../components/session/LoginForm'
import LoadingModal from '../components/modals/LoadingModal'

const Login = ({ setDomains }) => {
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/home' } }
  const [loading, setLoading] = useState(false)

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
            <LoadingModal isOpen={loading} />
            <IonCol offsetMd='3' sizeMd='6' offsetLg='4' sizeLg='4'>
              <LoginForm loading={loading} setLoading={setLoading} setDomains={setDomains} returnTo={from} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Login
