import React, { useState, useEffect, FC } from 'react'
import {
  IonContent, IonList,
  IonButton, IonRow, useIonAlert,
} from '@ionic/react'

import MapForm from '../forms/MapForm'

import { SimpleButton } from '../StyledContainers'
import useApi from '../../../hooks/testHooks'
import JourneyDetails from './JourneyDetails'

import { useAuth } from '../../../hooks/useAuth'
import FormPopup from '../forms/FormPopup'
import { useJourneysData2 } from '../../../hooks/journeys/useJourneysData2'
import { Journey } from '../../types/OptimizedMaps'
import { generateChapter } from '../../utils/MapboxUtils'
import { JourneyStatus } from '../../types/GlobalTypes'

interface Props {
  focussedJourney: Journey,
  setShowJourneyAuthorization: React.Dispatch<React.SetStateAction<boolean>>,
}

/**
 *  =====================================
 *       Approve or decline a Journey
 *  =====================================
 */
const JourneyAuthorization : FC<Props> = ({
  setShowJourneyAuthorization, focussedJourney,
}) => {
  const [alert] = useIonAlert()
  const { user } = useAuth()
  const apiHook = useApi()
  const { updateJourneys, setLoading } = useJourneysData2()
  const [submitted, setSubmitted] = useState(null)
  const { mapRef } = useJourneysData2()

  const updateStatus = async (message: string) => {
    setLoading(true)
    const { story } = await apiHook.progressJourney(focussedJourney.id, message)
    const userData = { ...user, id: user.user_id }
    const chapter = generateChapter(Math.random(), story, 'status_change', focussedJourney.id, userData)
    const newStory = [...focussedJourney.chapters, chapter]
    updateJourneys({ ...focussedJourney, status: JourneyStatus.PRE_DEPARTURE, chapters: newStory })
    setLoading(false)
    setSubmitted('Authorised')
  }

  /* Approve the journey request */
  const onApprove = async () => {
    alert({
      header: 'Journey Authorisation',
      message: 'Are you sure you want to approve this journey?',
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: 'Additional comments (optional)',
        },
      ],
      buttons: [
        'Cancel',
        { text: 'Authorise', handler: (data) => updateStatus(data.reason) },
      ],
    })
  }

  const denyJourney = async (message: string) => {
    setLoading(true)
    const { story } = await apiHook.denyJourney(focussedJourney.id, message)
    const userData = { ...user, id: user.user_id }
    const chapter = generateChapter(Math.random(), story, 'status_change', focussedJourney.id, userData)
    const newStory = [...focussedJourney.chapters, chapter]
    updateJourneys({ ...focussedJourney, status: JourneyStatus.DENIED, chapters: newStory })
    setLoading(false)
    setSubmitted('Declined')
  }

  /* Decline the journey request */
  const onDecline = () => {
    alert({
      header: 'Decline Journey?',
      message: 'Are you sure you want to decline this journey?',
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: 'Reason for declining (optional)',
        },
      ],
      buttons: [
        'Cancel',
        {
          text: 'Decline',
          handler: (data) => denyJourney(data.reason),
        },
      ],
    })
  }

  useEffect(() => {
    if (focussedJourney.firstPoint) {
      mapRef.current.easeTo({ center: focussedJourney.firstPoint, zoom: 12 })
    }
  }, [focussedJourney])

  return (
    <MapForm>
      {
        (!submitted) ? (
          <>
            <IonContent style={{ height: '85%' }}>
              <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
                <IonRow className='ion-align-items-center ion-justify-content-between'>
                  <h2 style={{ fontSize: '1.4rem' }}>Journey Details</h2>
                  <SimpleButton
                    onClick={() => {
                      setShowJourneyAuthorization(false)
                    }}
                  >Close X
                  </SimpleButton>
                </IonRow>
                <JourneyDetails focussedJourney={focussedJourney} />
              </IonList>
            </IonContent>
            <IonRow
              style={{
                alignItems: 'center', padding: '0 10px', height: '15%', backgroundColor: 'white',
              }}
              className='ion-justify-content-between'
            >
              <IonButton id='decline-road-travel' style={{ '--background': '#8E151F' }} onClick={onDecline}>Decline</IonButton>
              <IonButton id='approve-road-travel' style={{ '--background': '#4197A9' }} onClick={onApprove}>Approve</IonButton>
            </IonRow>
          </>
        ) : (
          <IonContent>
            <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
              <FormPopup
                message={`You have successfully ${submitted} this journey request.`}
                onClose={() => {
                  setShowJourneyAuthorization(false)
                }}
              />
            </IonList>
          </IonContent>
        )
      }
    </MapForm>
  )
}

export default JourneyAuthorization
