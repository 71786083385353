/* eslint-disable react/no-array-index-key */
import { Icon } from '@iconify/react'
import {
  IonButton, IonCol, IonLoading, IonRow,
} from '@ionic/react'
import React, { FC, useState, useEffect } from 'react'
import { useIncidentsData } from '../../../hooks/incident-management/useIncidentData'
import { SherpaButton } from '../../GlobalContainers'
import {
  Procedure, ProcedureResponsability, SOP, SOPLevel, TemplateType,
} from '../../types/GlobalTypes'
import { ObscureBackground, SimpleButton } from '../StyledContainers'
import DeleteProcedure from './popups/DeleteProcedure'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { addChosenTemplate } from '../../../hooks/incident-management/helpers/Utils'
import { ProcedureType } from '../../types/BackendTypes'
import LoadingModal from '../../modals/LoadingModal'

interface Props {
  sopData: SOP,
  onClose: () => void,
  onSubmitEdit: (updated: SOP) => void
}

interface EditProcedure {
  id?: number,
  action: string,
  description: string,
  level: SOPLevel,
  responsibility: ProcedureResponsability,
  edited: boolean,
}

enum LocalProcedureType {
  IMMEDIATE = 'immediateProcedures',
  FOLLOW_UP = 'followupProcedures',
  RECOVERY = 'recoveryProcedures',
}

const SOPViewEdit : FC<Props> = ({ onClose, sopData, onSubmitEdit }) => {
  const [editingRecovery, setEditingRecovery] = useState<boolean>(false)
  const [editingImmediate, setEditingImmediate] = useState<boolean>(false)
  const [editingFollowup, setEditingFollowup] = useState<boolean>(false)
  const { workspace } = useWorkspace()
  const {
    loading, updateSOP, setLoading, risksDescriptions,
  } = useIncidentsData()

  const handleSubmit = (newProcedures: Procedure[], type: LocalProcedureType) => {
    const updated : SOP = { ...sopData, chosenTemplate: { ...sopData.chosenTemplate, [type]: newProcedures } }
    setEditingRecovery(false)
    setEditingFollowup(false)
    setEditingImmediate(false)
    updateSOP(updated)
    onSubmitEdit(updated)
  }

  useEffect(() => {
    if (sopData.chosenTemplate) { return }
    setLoading(true)
    axios.post('/api/v1/internal_incident/getTemplateSops', { domain_id: workspace.id, template_id: sopData.template_id }).then(({ data }) => {
      const sop = addChosenTemplate(data.template, data.procedures, sopData)
      onSubmitEdit(sop)
      updateSOP(sop)
      setLoading(false)
    })
  }, [])

  return (
    <div className='risk-assessment-list' style={{ height: '100%', justifyContent: 'flex-start' }}>
      <IonRow className='ion-justify-content-start'>
        <SimpleButton onClick={onClose}> <Icon icon='fe:arrow-left' /> Back </SimpleButton>
      </IonRow>
      <IonLoading
        isOpen={loading}
        message='Please wait...'
      />
      {
        !loading && sopData.chosenTemplate && (
          <div style={{ flex: 1, overflowY: 'auto' }}>
            <h4>{ sopData.type }</h4>
            <p style={{ margin: '20px 0' }}>{ risksDescriptions[sopData.type] }</p>
            <ProcedureList
              type='Immediate Procedures'
              procedures={sopData.chosenTemplate.immediateProcedures}
              sopData={sopData}
              editing={editingImmediate}
              procedureType={LocalProcedureType.IMMEDIATE}
              procedureName={ProcedureType.IMMEDIATE}
              onEdit={() => setEditingImmediate(true)}
              onCancel={() => setEditingImmediate(false)}
              onSubmit={(procedures) => handleSubmit(procedures, LocalProcedureType.IMMEDIATE)}
              canEdit={!editingFollowup && !editingRecovery && (sopData.template_type === TemplateType.WORKSPACE
                || (workspace.power === 'God' && sopData.template_type === TemplateType.DEFAULT) || (workspace.power === 'ArchAngel' && sopData.template_type === TemplateType.ORGANISATION))}
            />
            <ProcedureList
              type='Follow Up Procedures'
              procedures={sopData.chosenTemplate.followupProcedures}
              sopData={sopData}
              editing={editingFollowup}
              procedureType={LocalProcedureType.FOLLOW_UP}
              procedureName={ProcedureType.FOLLOW_UP}
              onEdit={() => setEditingFollowup(true)}
              onCancel={() => setEditingFollowup(false)}
              onSubmit={(procedures) => handleSubmit(procedures, LocalProcedureType.FOLLOW_UP)}
              canEdit={!editingImmediate && !editingRecovery && (sopData.template_type === TemplateType.WORKSPACE
                || (workspace.power === 'God' && sopData.template_type === TemplateType.DEFAULT) || (workspace.power === 'ArchAngel' && sopData.template_type === TemplateType.ORGANISATION))}
            />
            <ProcedureList
              type='Recovery Procedures'
              procedures={sopData.chosenTemplate.recoveryProcedures}
              sopData={sopData}
              editing={editingRecovery}
              procedureType={LocalProcedureType.RECOVERY}
              procedureName={ProcedureType.RECOVERY}
              onEdit={() => setEditingRecovery(true)}
              onCancel={() => setEditingRecovery(false)}
              onSubmit={(procedures) => handleSubmit(procedures, LocalProcedureType.RECOVERY)}
              canEdit={!editingImmediate && !editingFollowup && (sopData.template_type === TemplateType.WORKSPACE
                || (workspace.power === 'God' && sopData.template_type === TemplateType.DEFAULT) || (workspace.power === 'ArchAngel' && sopData.template_type === TemplateType.ORGANISATION))}
            />
          </div>
        )
      }
    </div>
  )
}

export default SOPViewEdit

interface ProcedureProps {
  procedures: Procedure[],
  type: string,
  editing: boolean,
  canEdit: boolean,
  sopData: SOP,
  procedureType: LocalProcedureType
  procedureName: ProcedureType,
  onEdit: () => void,
  onCancel: () => void,
  onSubmit: (procedures: Procedure[]) => void,
}

const ProcedureList : FC<ProcedureProps> = ({
  editing, onEdit, onSubmit, canEdit, procedures, type, sopData, procedureType, procedureName, onCancel,
}) => {
  const [procedureList, setProcedureList] = useState<EditProcedure[]>([])
  const [procedureToDelete, setProcedureToDelete] = useState<EditProcedure>()
  const { updateSOP } = useIncidentsData()
  const { workspace } = useWorkspace()
  const [loading, setLoading] = useState<boolean>(false)

  const addNewProcedure = () => {
    const newProcedure : EditProcedure = {
      action: '',
      description: '',
      level: SOPLevel.ERT,
      responsibility: ProcedureResponsability.LOGISTICS,
      edited: true,
    }

    const copy = procedureList.slice()
    copy.push(newProcedure)

    setProcedureList(copy)
  }

  const updateAction = (value: string, index: number) => {
    const copy = procedureList.slice()
    copy.splice(index, 1, { ...copy[index], action: value, edited: true })

    setProcedureList(copy)
  }

  const updateDescription = (value: string, index: number) => {
    const copy = procedureList.slice()
    copy.splice(index, 1, { ...copy[index], description: value, edited: true })

    setProcedureList(copy)
  }

  const updateLevel = (value: SOPLevel, index: number) => {
    const copy = procedureList.slice()
    copy.splice(index, 1, { ...copy[index], level: value, edited: true })

    setProcedureList(copy)
  }

  const updateResponsability = (value: ProcedureResponsability, index: number) => {
    const copy = procedureList.slice()
    copy.splice(index, 1, { ...copy[index], responsibility: value, edited: true })

    setProcedureList(copy)
  }

  const handleSubmit = () => {
    if (!procedureList.find(({ edited }) => edited)) {
      onCancel()
      return
    }
    const editedProcedures = procedureList.filter((val) => val.edited && val.id)
    const newProcedures = procedureList.filter((val) => !val.id)
    const nonEditedProcedures = procedureList.filter((val) => !val.edited)

    setLoading(true)
    axios.post('/api/v1/internal_incident/editProcedures',
      {
        domain_id: workspace.id,
        new: newProcedures.map((val) => ({ ...val, procedure_type: procedureName })),
        edit: editedProcedures.map((val) => ({ ...val, procedure_type: procedureName })),
        template_id: sopData.chosenTemplate.id,
      })
      .then(({ data }) => {
        onSubmit([...nonEditedProcedures, ...data.procedures])
      }).finally(() => {
        setLoading(false)
      })
  }

  const removeProcedure = () => {
    setLoading(true)
    axios.post('/api/v1/internal_incident/deleteProcedure', { domain_id: workspace.id, procedure_id: procedureToDelete.id })
      .then(() => {
        const proceduresCopy = sopData.chosenTemplate[procedureType].slice()
        const index = proceduresCopy.findIndex(({ id }) => id === procedureToDelete.id)

        if (index >= 0) {
          proceduresCopy.splice(index, 1)
          updateSOP({ ...sopData, chosenTemplate: { ...sopData.chosenTemplate, [procedureType]: proceduresCopy } })
        }

        const copy = procedureList.slice()
        const localIndex = copy.findIndex(({ id }) => id === procedureToDelete.id)

        copy.splice(localIndex, 1)

        console.log(proceduresCopy, copy)

        setProcedureList(copy)
      }).finally(() => {
        setLoading(false)
        setProcedureToDelete(undefined)
      })
  }

  const handleDelete = (procedure: EditProcedure, index: number) => {
    if (procedure.id) {
      setProcedureToDelete(procedure)
      return
    }

    const copy = procedureList.slice()
    copy.splice(index, 1)

    setProcedureList(copy)
  }

  useEffect(() => {
    if (!editing) {
      const formattedProcedures = procedures.map((val) => ({ ...val, edited: false }))
      setProcedureList(formattedProcedures)
    }
  }, [procedures])

  return (
    <div style={{ margin: '15px 0' }}>
      <LoadingModal isOpen={loading} />
      {
        procedureToDelete && (
          <>
            <ObscureBackground />
            <DeleteProcedure
              onClose={() => setProcedureToDelete(undefined)}
              onContinue={() => removeProcedure()}
            />
          </>
        )
      }
      <h5 style={{ fontWeight: 'bold', marginBottom: '15px' }}>{ type }</h5>
      <IonRow style={{ border: (!editing) ? '0.5px solid black' : 'none' }}>
        <IonCol style={{ borderRight: (!editing) ? '0.5px solid black' : 'none' }} size='4'>
          <h6 className='procedure-field-title'>Action</h6>
        </IonCol>
        <IonCol style={{ borderRight: (!editing) ? '0.5px solid black' : 'none' }} size='5'>
          <h6 className='procedure-field-title'>Description</h6>
        </IonCol>
        <IonCol style={{ borderRight: (!editing) ? '0.5px solid black' : 'none' }} size='2'>
          <h6 className='procedure-field-title'>Level</h6>
        </IonCol>
        <IonCol size='1'>
          <h6 className='procedure-field-title'>Responsibility</h6>
        </IonCol>
      </IonRow>
      {
        procedureList.map((val, index) => (
          <IonRow className={(editing) ? 'procedure-row-editing' : 'procedure-table-border'} key={index}>
            <IonCol style={{ borderRight: (editing) ? 'none' : '0.5px solid black' }} size='4'>
              {
                editing ? (
                  <input
                    id='incident-nearest'
                    name='nearest'
                    className='incident-field-input'
                    value={val.action}
                    onChange={(e) => updateAction(e.target.value, index)}
                    type='text'
                    placeholder='Type the action'
                  />
                ) : (
                  <p className='procedure-table-input'>{ val.action }</p>
                )
              }
            </IonCol>
            <IonCol style={{ borderRight: (editing) ? 'none' : '0.5px solid black' }} size='5'>
              {
                editing ? (
                  <input
                    id='incident-nearest'
                    name='nearest'
                    className='incident-field-input'
                    value={val.description}
                    onChange={(e) => updateDescription(e.target.value, index)}
                    type='text'
                    placeholder='Type a description'
                  />
                ) : (
                  <p className='procedure-table-input'>{ val.description }</p>
                )
              }
            </IonCol>
            <IonCol style={{ borderRight: (editing) ? 'none' : '0.5px solid black' }} size='2'>
              {
                editing ? (
                  <select className='procedure-select' onChange={(e) => updateLevel(e.target.value as SOPLevel, index)} value={val.level}>
                    <option value={SOPLevel.ERT}>{ SOPLevel.ERT }</option>
                    <option value={SOPLevel.IMT}>{ SOPLevel.IMT }</option>
                    <option value={SOPLevel.CMT}>{ SOPLevel.CMT }</option>
                  </select>
                ) : (
                  <p className='procedure-table-input'>{ val.level }</p>
                )
              }
            </IonCol>
            <IonCol size='1'>
              {
                editing ? (
                  <select className='procedure-select' onChange={(e) => updateResponsability(e.target.value as ProcedureResponsability, index)} value={val.responsibility}>
                    <option value={ProcedureResponsability.TEAM_LEADER}>{ ProcedureResponsability.TEAM_LEADER }</option>
                    <option value={ProcedureResponsability.LOGISTICS}>{ ProcedureResponsability.LOGISTICS }</option>
                  </select>
                ) : (
                  <p className='procedure-table-input'>{ val.responsibility }</p>
                )
              }
            </IonCol>
            {
              editing && (
                <IonCol style={{ textAlign: 'center' }} size='1'>
                  <SherpaButton onClick={() => handleDelete(val, index)} style={{ color: '#C30101' }}>
                    <Icon icon='ph:trash' />
                  </SherpaButton>
                </IonCol>
              )
            }
          </IonRow>
        ))
      }
      {
        !editing && procedureList.length === 0 && (
          <IonRow className='no-procedures-row'>
            <p style={{ margin: 0 }}>No Procedures selected</p>
          </IonRow>
        )
      }
      {
        editing && (
          <SherpaButton style={{ fontSize: '1rem', margin: '10px 0' }} onClick={addNewProcedure}>
            Add New Procedure +
          </SherpaButton>
        )
      }
      <IonRow style={{ marginTop: '10px' }} className='ion-justify-content-end'>
        {
          editing ? (
            <IonButton style={{ '--background': '#326771' }} onClick={handleSubmit}>Done</IonButton>
          ) : (
            <>
              {
                canEdit && (
                  <IonButton style={{ '--background': '#326771' }} onClick={onEdit}>Edit Procedures</IonButton>
                )
              }
            </>
          )
        }
      </IonRow>
    </div>
  )
}
