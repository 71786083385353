/* eslint-disable no-trailing-spaces */
import React, { FC } from 'react'
import moment from 'moment'
import { IonRow } from '@ionic/react'
import { Incident } from '../types/GlobalTypes'
import { PostCardContainer } from './StyleContainers'
import { getDateString } from '../../helpers/GlobalFunctions'

interface Props {
  data: Incident,
}

const IncidentCard : FC<Props> = ({ data }) => (
  <PostCardContainer>
    <div className='post-content'>
      <IonRow className='ion-align-items-center' style={{ margin: '15px 0' }}>
        <h5>Security Report</h5>
        <p className='post-time'>{ getDateString(data.created_at) }</p>
      </IonRow>
      <p style={{ margin: '10px 0', color: 'black' }}> { data.incident_type } has been reported near { data.nearest } in { data.county.name } County 
        that took place on the { moment(data.reported).format('DD/MM/YY') }.
      </p>
    </div>
  </PostCardContainer>
)

export default IncidentCard
