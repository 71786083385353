/* eslint-disable no-trailing-spaces */
import React from 'react'
import {
  IonList, IonContent, IonRow, IonCol, IonCheckbox, IonButton,
} from '@ionic/react'
import { CenterForm, MeasureContainer, ColumnContainer } from '../../StyledContainers'
import { SettingField } from '../../../settings/StyledContainers'

/**
 *  ======================================
 *          REMOVE NEW MEASURES
 *  ======================================
 */
const RemoveNewMeasures = ({
  children, input, onClose, next, setInput, measures,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const handleCheck = (e, measure) => {
    /* Change switch between existing or new measures list */
    const copy = input.newMeasures.slice()
    const existing = input.existingMeasures.slice()

    if (e.target.checked) {
      const index = copy.findIndex((val) => val.id === measure.id)

      if (index >= 0) { copy.splice(index, 1) }
      if (!existing.find((val) => val.id === measure)) existing.push(measure)
    } else {
      const index = existing.findIndex((val) => val.id === measure.id)

      if (copy.find((val) => val.id === measure)) { copy.push(measure) }
      if (index >= 0) { existing.splice(index, 1) }
    }
    setInput({
      ...input, removeNew: { ...input.removeNew, [measure.id]: e.target.checked }, existingMeasures: existing, newMeasures: copy,
    })
  }

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Review of newly installed Control Measures</h5>
              <p> In the previous assessment of this asset, the control measures listed below were listed 
                as <span style={{ color: '#326771' }}> New Control Measures </span> that were set to be installed.
              </p>
              <p>Please check the box next to the control measures below <span style={{ color: '#326771' }}> if they have been installed. </span>
                Unchecked measures will remain as control measures that are still to be installed following this review.
              </p>
            </SettingField>
            <IonRow style={{ marginTop: '10px' }} className='ion-justify-content-center'>
              <ColumnContainer style={{ width: '350px' }}>
                {
                  input.removeNew && measures.filter((val) => Object.keys(input.removeNew).indexOf(String(val.id)) >= 0).map((measure, index) => (
                    <MeasureContainer key={measure.id} style={{ backgroundColor: getColor(index) }}>
                      <IonRow className='ion-justify-content-between ion-align-items-center'>
                        <IonCol size={11}>
                          <p>{ measure.control_measure }</p>
                        </IonCol>
                        <IonCol size={1}>
                          <IonCheckbox id={`checkbox-new-${index}`} onClick={(e) => handleCheck(e, measure)} checked={input.removeNew[measure.id]} />
                        </IonCol>
                      </IonRow>
                    </MeasureContainer>
                  ))
                }
              </ColumnContainer>
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end' style={{ paddingRight: '20px' }}>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton
              style={{ '--background': '#326771' }}
              onClick={next}
            >Next
            </IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default RemoveNewMeasures
