import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { DomainContainer } from './StyledContainers'

/**
 *  ========================
 *      Workscape Card
 *  ========================
 */

const DomainCard = ({ name, onClick }) => (
  <DomainContainer onClick={onClick}>
    <FontAwesomeIcon icon={faMapMarkerAlt} />
    { name }
  </DomainContainer>
)

export default DomainCard
