import { IonButton, IonRow, useIonAlert } from '@ionic/react'
import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import Datetime from 'react-datetime'
import CreatableSelect from 'react-select/creatable'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import { IncidentCause } from '../../types/GlobalTypes'
import { TerrainType } from '../../types/OptimizedMaps'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

export interface Details {
  title: string,
  type: '' | TerrainType,
  date: moment.Moment,
  description: string,
  stakeholders: { value: number, label: string }[],
  areas: { value: number, label: string }[],
}

export const TerrainTypes = [TerrainType.QUESTION, TerrainType.REQUEST_FOR_INFORMATION, TerrainType.GENERAL_PERCEPTION, TerrainType.FRICTION, TerrainType.OTHER]

const Issue : FC = () => {
  const [input, setInput] = useState<Details>({
    title: '',
    type: '',
    date: moment(),
    description: '',
    stakeholders: [],
    areas: [],
  })
  const { workspace } = useWorkspace()
  const [selectedCauses, setSelectedCauses] = useState<(IncidentCause & { selected: boolean })[]>([])
  const {
    causes, setShowIssueForm, communities, pushNewIssue,
    stakeholders, setSubmittedMessage, setLoading,
  } = useTerrainMapping()
  const [ionAlert] = useIonAlert()

  const errorMessages = {
    title: 'No title provided',
    type: 'No type provided',
    description: 'Please, briefly describe the issue',
  }

  const selectCause = (index: number) => {
    const copy = selectedCauses.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setSelectedCauses(copy)
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const errorKey = keys.find((val) => !input[val] || input[val] === '')

    if (errorKey) {
      ionAlert({
        header: 'Form Error',
        message: errorMessages[errorKey],
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    const selectedStakeholders = stakeholders.filter(({ id }) => input.stakeholders.find(({ value }) => value === id))
    const filteredCauses = selectedCauses.filter(({ selected }) => selected)
    const selectedAreas = communities.filter(({ id }) => input.areas.find(({ value }) => value === id))

    setLoading(true)
    axios.post('/api/v2/issue/create_issue', {
      domain_id: workspace.id,
      title: input.title,
      type: input.type,
      received: input.date.format(),
      description: input.description,
      issues: filteredCauses.map(({ id }) => id),
      community: selectedAreas.map(({ id }) => id),
      stakeholders: selectedStakeholders.map(({ id }) => id),
    }).then(({ data }) => {
      if (data.message === 'you have not created a issue.') {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      pushNewIssue({
        id: data.issue.id,
        date: input.date.format(),
        title: input.title,
        type: input.type as TerrainType,
        description: input.description,
        stakeholders: selectedStakeholders.map(({ id, name }) => ({ id, name })),
        causes: filteredCauses,
        communities: selectedAreas.map(({ id, name }) => ({ id, name })),
      })
      setSubmittedMessage('Issue created succesfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setLoading(false)
      setShowIssueForm(false)
    })
  }

  useEffect(() => {
    setSelectedCauses(causes.map((val) => ({ ...val, selected: false })))
  }, [])

  const typeOptions = [
    { label: 'Economic & Livelihood', value: 'Economic & Livelihood' },
    { label: 'Community Relations', value: 'Community Relations' },
    { label: 'Health, Safety & Well-being', value: 'Health, Safety & Well-being' },
    { label: 'Security & Conflict', value: 'Security & Conflict' },
    { label: 'Environmental Impact', value: 'Environmental Impact' },
    { label: 'Legal & Regulatory Compliance', value: 'Legal & Regulatory Compliance' },
    { label: 'Cultural Sensitivity & Heritage Preservation', value: 'Cultural Sensitivity & Heritage Preservation' },
  ]

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <OverlayContainer style={{ zIndex: 25, height: '80%', overflow: 'hidden' }} className='terrain-add-popup'>
        <div style={{ maxHeight: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
          <IonRow style={{ marginBottom: '10px' }} className='ion-justify-content-between ion-align-items-center'>
            <h5 style={{ marginBottom: 0 }}>Add Issue</h5>
            <SimpleButton onClick={() => setShowIssueForm(false)}>Close X</SimpleButton>
          </IonRow>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <p>Issues are questions, requests for information, or general perceptions. If not addressed well, issues may become grievances. Issues do not have to be registered as grievances,
              but they should be recorded so that emerging trends can be identified and addressed before they escalate.
            </p>
            <div className='terrain-form-field'>
              <h6>Title</h6>
              <input
                placeholder='Give this issue a title'
                type='text'
                value={input.title}
                onChange={(e) => setInput({ ...input, title: e.target.value })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Issue Type</h6>
              <select
                value={input.type}
                onChange={(e) => setInput({ ...input, type: e.target.value as TerrainType })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select a type</option>
                {
                  typeOptions.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))
                }
              </select>
            </div>
            <div className='terrain-form-field'>
              <h6>Date Received</h6>
              <Datetime
                dateFormat='YYYY-MM-DD'
                inputProps={{
                  placeholder: 'Select Date',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                value={input.date}
                onChange={(e) => setInput({ ...input, date: e as moment.Moment })}
              />
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Description</h6>
              <textarea value={input.description} onChange={(e) => setInput({ ...input, description: e.target.value })} />
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Underlying Causes</h6>
              {
                selectedCauses.map((val, index) => (
                  <TagButton
                    key={val.id}
                    onClick={() => selectCause(index)}
                    style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                  >
                    { val.name }
                  </TagButton>
                ))
              }
            </div>
            <div className='terrain-form-field'>
              <h6>Associated Stakeholders</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Stakeholders'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                value={input.stakeholders}
                onChange={(selected) => setInput({ ...input, stakeholders: selected })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Relevant Areas</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Areas'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                value={input.areas}
                onChange={(selected) => setInput({ ...input, areas: selected })}
              />
            </div>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton onClick={() => setShowIssueForm(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Submit</IonButton>
          </IonRow>
        </div>
      </OverlayContainer>
    </>
  )
}

export default Issue
