import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol, IonContent, useIonAlert,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { useSituationReport } from '../../hooks/insights/useSituationReport'

//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SitRep = ({
  step, setStep, alert_state, security_condition, county,
}) => {
  const handleNext = () => {
    setStep(step + 1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  const [, setIsShowing, , setCountyId] = useSituationReport()

  const handleClose = () => {
    setIsShowing(false)
    setCountyId(null)
    setStep(1)
  }

  function getColor(level) {
    if (level === 'Level 1 - Low') {
      return '#14C105'
    } if (level === 'Level 2 - Guarded') {
      return '#349DFF'
    } if (level === 'Level 3 - Elevated') {
      return '#FFE500'
    } if (level === 'Level 4 - High') {
      return '#FF8A00'
    } if (level === 'Level 5 - Severe') {
      return '#FF0000'
    }
    if (level === 'Level 1 - Caution') {
      return '#14C105'
    } if (level === 'Level 2 - Warning') {
      return '#349DFF'
    } if (level === 'Level 3 - Standfast') {
      return '#FFE500'
    } if (level === 'Level 4 - Standby') {
      return '#FF8A00'
    }
    return '#FF0000'
  }

  // add one to also use alert state
  const secConds = [['Level 1 - Low', 'Serious security incidents occurring less than once a year.'],
    ['Level 2 - Guarded', 'Serious security incidents occurring more than once a year.'],
    ['Level 3 - Elevated', 'Serious security incidents occurring more than once a month.​'],
    ['Level 4 - High', 'Serious security conditions occurring more than once a week.​'],
    ['Level 5 - Severe', 'Serious security incidents occurring on a daily basis.​']]

  const alertDefs = [
    ['Level 1 - Caution', 'There is a low risk of possible terrorist activity or civil unrest affecting operations or personnel. Business as usual. '],
    // eslint-disable-next-line max-len
    ['Level 2 - Warning', 'A general or localised threat situation, the nature and extent of which are unpredictable, and circumstances do not justify full implementation of higher alert measures. Local travel restrictions may be necessary.'],
    // eslint-disable-next-line max-len
    ['Level 3 - Standfast', 'A foreseen event or situation that could impact on the security of operations. There is potential for the security situation in the area to deteriorate rapidly. Local lockdowns and / or severely restricted staff movements may be necessary.'],
    // eslint-disable-next-line max-len
    ['Level 4 - Standby', 'A ‘threat to life’ incident is highly likely. Security conditions are hostile and approaching a level where operations are not possible. Critical risk to staff. Partial evacuation should be considered. '],
    // eslint-disable-next-line max-len
    ['Level 5 - Evacuation', 'There is an immediate / direct threat to personnel. There are extreme risks to personnel and assets. Operations are not possible. Full evacuation should be considered. ']]

  const alert = alertDefs[alert_state - 1]
  const sec = secConds[security_condition.condition - 1]

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '80%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Situation Report - ${county.name} County`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonContent style={{ height: '80%', '--ion-background-color': '#FFFFFF' }}>
          <IonGrid style={{ width: '85%', marginTop: '1em' }}>
            <IonList>
              <IonRow>
                <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontWeight: 'bold', fontSize: '1.15em' }}>Alert State</IonLabel></IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontSize: '1em' }}>{`Last modified: ${moment(county.updated_at).format('DD/MM/YY')}`}</IonLabel></IonCol>
              </IonRow>

              <IonGrid style={{ marginTop: '0.6em' }}>
                <IonRow style={{ outline: 'solid 1px' }}>
                  <IonCol size='3' style={{ backgroundColor: '#8E151F', outline: 'solid 1px', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Alert State</IonLabel>
                  </IonCol>
                  <IonCol size='9' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Description</IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow style={{ outline: 'solid 1px' }}>
                  <IonCol size='3' style={{ backgroundColor: getColor(alert[0]), outline: 'solid 1px', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>{alert[0]}</IonLabel>
                  </IonCol>
                  <IonCol size='9' style={{ outline: 'solid 1px', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em' }}>{alert[1]}</IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonList>

            <IonList>
              <IonRow style={{ marginTop: '1em' }}>
                <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontWeight: 'bold', fontSize: '1.15em' }}>Security Condition</IonLabel></IonCol>
              </IonRow>
              <IonRow>
                <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontSize: '1em' }}>{`Last modified: ${moment(security_condition.created_at).format('DD/MM/YY')}`}</IonLabel></IonCol>
              </IonRow>

              <IonGrid style={{ marginTop: '0.6em' }}>
                <IonRow style={{ outline: 'solid 1px' }}>
                  <IonCol size='3' style={{ backgroundColor: '#8E151F', outline: 'solid 1px', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Security Condition</IonLabel>
                  </IonCol>
                  <IonCol size='9' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Description</IonLabel>
                  </IonCol>
                </IonRow>

                <IonRow style={{ outline: 'solid 1px' }}>
                  <IonCol size='3' style={{ backgroundColor: getColor(sec[0]), outline: 'solid 1px', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>{sec[0]}</IonLabel>
                  </IonCol>
                  <IonCol size='9' style={{ outline: 'solid 1px', paddingLeft: '0.6em' }}>
                    <IonLabel style={{ fontSize: '1em' }}>{sec[1]}</IonLabel>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonList>
          </IonGrid>
        </IonContent>

        <IonRow style={{ marginTop: '1em' }} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} disabled>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default SitRep
