import * as moment from 'moment'
import * as turf from '@turf/turf'
import { County, RestrictionZone } from '../../../components/types/GlobalTypes'
import { incidentCategories } from '../../../components/maps/apps/incident_types.json'
import { incidentIndicator, zoomThreshold } from '../../../components/maps/apps/AppMap'
import {
  BackendJourneyHistory,
  ChapterProperties, ChaptersGeoJSON, ChapterType, CountiesGeoJSON, JourneySegmentPosition, JourneyTrackerFC, RegionsGeoJSON,
} from '../../../components/types/GeoJSONTypes'
import { JourneyChapter, BackendMovementBox } from '../../../components/types/BackendTypes'
import { Journey } from '../../../components/types/Journeys'
import { ControlMeasure } from '../../../components/riskregister/types/RiskAssessments'
import { Feature, FeatureCollection, featureCollection, point, Point } from '@turf/turf'

/**
 * Formats the journeys to be displayed
 *
 * @param dbJourneys - Journeys fetched from database
 * @param routesFromDb - Routes fetched from database
 * @param dbUsers - Users fetched from database
 * @param chapters - Chapters related to a journey
 * @returns - A list of formatted journeys
 */
export const formatJourneys = (dbJourneys, routesFromDb, dbUsers, chapters = [], histories: BackendJourneyHistory[] = []) => {
  /* Prepare and store journeys for future display */
  const journeysFromDb = dbJourneys.map(({
    id, arrival_location, departure_location, submitter, departure_time, arrival_time, status, approver, stages, leader_id,
  }, index) => {
    /* Filter out paths that belong to this journey */
    const journeyPaths = routesFromDb.filter((path) => stages.indexOf(path.properties.id) >= 0)
    const journeyManager = (typeof submitter === 'number') ? dbUsers.find((user) => user.id === submitter) : submitter
    const supervisor = dbUsers.find((user) => user.id === approver)

    const leader = dbUsers.find((user) => user.id === leader_id)

    dbJourneys[index].submitter = (typeof submitter === 'number') ? {
      id: submitter,
      firstName: (journeyManager) ? journeyManager.first_name : 'Unknown',
      finalName: (journeyManager) ? journeyManager.final_name : 'Unknown',
      email: (journeyManager) ? journeyManager.email : 'Unknown',
    } : { ...submitter }

    dbJourneys[index].routes = journeyPaths.map((path) => path.properties)

    const history = histories.find(({ journey_id }) => journey_id === id)
    let firstPoint = null

    if (status === 'Complete' && history && history.path.features.length) {
      // eslint-disable-next-line prefer-destructuring
      const firstFeature = history.path.features.find(({ properties }) => properties.position === 'first')
      if (firstFeature) {
        // eslint-disable-next-line prefer-destructuring
        firstPoint = firstFeature.geometry.coordinates[0]
      }
    }

    if (status !== 'Complete') {
      firstPoint = dbJourneys[index].routes[0].firstPoint
    }
    dbJourneys[index].firstPoint = firstPoint
    dbJourneys[index].leader = {
      id: leader_id,
      firstName: (leader) ? leader.first_name : 'External',
      finalName: (leader) ? leader.final_name : 'User',
      email: (leader) ? leader.email : 'example.com',
    }

    const features = journeyPaths.map((path) => (
      {
        type: 'Feature',
        properties: {
          id,
          start: departure_location,
          end: arrival_location,
          submitter: {
            id: submitter,
            email: (journeyManager) ? journeyManager.email : 'Unknown',
          },
          leader: { ...leader },
          departureDate: departure_time,
          arrivalDate: arrival_time,
          status,
          approver: {
            id: approver,
            email: (supervisor) ? supervisor.email : 'Unknown',
          },
        },
        geometry: {
          type: 'LineString',
          coordinates: path.geometry.coordinates,
        },
      }
    ))

    return features
  }).flat(1)
  const journeys = dbJourneys.map((journey) => ({
    ...journey,
    story: chapters.filter(({ journey_id }) => journey_id === journey.id).map((chapter) => ({ ...chapter, user: dbUsers.find(({ id }) => chapter.user_id === id) })),
  }))

  const locationChapter = chapters.filter(({
    latitude, longitude, user_id, journey_id,
  }) => {
    if (!latitude || !longitude) {
      return false
    }
    const journey = journeys.find(({ id }) => id === journey_id)
    if (journey.leader_id !== user_id) {
      return false
    }

    return true
  })
  const activeJourneys = journeysFromDb.filter(({ properties }) => properties.status !== 'Complete')
  const activeIds = activeJourneys.map(({ properties }) => properties.id)
  const { complete, active } = locationChapter.reduce((prevValues, val) => {
    if (activeIds.indexOf(val.journey_id) >= 0) {
      prevValues.active.push(val)
      return prevValues
    }

    prevValues.complete.push(val)
    return prevValues
  }, { complete: [], active: [] })

  const completedChaptersGeoJSON = getChaptersGeoJSON(complete, journeys)
  const activeChaptersGeoJSON = getChaptersGeoJSON(active, journeys)

  return {
    journeys,
    journeysFromDb: activeJourneys,
    completedChaptersGeoJSON,
    activeChaptersGeoJSON,
  }
}

export const getChapterType = (chapter: JourneyChapter) : ChapterType => {
  if (chapter.content === 'Check In: All good.') {
    return ChapterType.CHECK_IN_ALL_GOOD
  }

  if (chapter.content === 'Check In: Approaching checkpoint.') {
    return ChapterType.CHECK_IN_CHECKPOINT
  }

  if (chapter.content === 'Check In: Rest stop.') {
    return ChapterType.CHECK_IN_REST
  }

  if (chapter.content === 'Check In: Road blocked.') {
    return ChapterType.CHECK_IN_BLOCKED
  }

  if (chapter.content === 'Emergency') {
    return ChapterType.EMERGENCY
  }

  return ChapterType.COMMENT
}

export const getChaptersGeoJSON = (chapters: JourneyChapter[], journeys: Journey[]) : ChaptersGeoJSON => {
  const features = chapters.map((val) => {
    const journey = journeys.find(({ id }) => id === val.journey_id)
    const properties : ChapterProperties = {
      journey,
      type: getChapterType(val),
      id: val.id,
      user_id: val.user_id,
    }

    return turf.point([val.longitude, val.latitude], properties)
  })
  return turf.featureCollection(features)
}

/**
 * Formats the air journeys to be displayed
 *
 * @param dbUsers - Users fetched from database
 * @param dbAirJourneys - Air journeys fetched from database
 * @param chapters - Chapters related to journeys
 * @param counties - Counties fetched from database
 * @returns - A list of formatted air journeys
 */
export const formatAirJourneys = (dbUsers: any[], dbAirJourneys: any[], chapters: any = [], counties: any[]) => dbAirJourneys.map((journey) => {
  /* Find the user object that corresponds to the submitter id */
  const user = dbUsers.find(({ id }) => id === journey.submitter)
  const approver = dbUsers.find(({ id }) => id === journey.approver)
  const county = (journey.destinationCountyId) ? counties.find((val) => val.properties.id === journey.destinationCountyId) : null

  /* Return cross-references version */
  return {
    ...journey,
    submitterName: `${user.first_name} ${user.final_name}`,
    submitter: {
      first_name: user.first_name,
      final_name: user.final_name,
      id: user.id,
    },
    approver: {
      first_name: approver.first_name,
      final_name: approver.final_name,
      id: approver.id,
    },
    story: chapters.filter(({ journey_id }) => journey_id === journey.id),
    alert_state: (county) ? county.properties.Alert_state : null,
    sc_level: (county) ? county.properties.sc_level : null,
  }
})

/**
 * Paints the incidents present
 *
 * @param draw - Whether to paint incidents or not
 * @param incidents - The incidents to paint
 * @param incidentList - The list of incidents to paint
 * @param mapRef - A reference to the map
 */
export const paintIncidents = (draw: boolean, incidents: any[], incidentList: any[], mapRef: any) => {
  if (draw) {
    const list = incidents || incidentList
    mapRef.current.getSource('incidents').setData({
      type: 'FeatureCollection',
      features: list.map((val) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            val.longitude, val.latitude,
          ],
        },
        properties: {
          title: val.name,
          category: Object.keys(incidentCategories).find((key) => incidentCategories[key].find((type) => type === val.incident_type)),
          popupdata: incidentIndicator(val),
        },
      })),
    })
  } else {
    mapRef.current.getSource('incidents').setData({
      type: 'FeatureCollection',
      features: [],
    })
  }
}

/**
 * Process the incidents returned by the database into GeoJSON format
 *
 * @param incidents -> Backend formatted incidents
 * @return List of frontend formatted incidents
 */
export const processBackendIncidents = (incidents: any[], countyMap: { [countyId: number] : County }) : turf.FeatureCollection<turf.Point, any> => {
  const formattedIncident : any[] = incidents.map((val : any) => ({
    id: val.id,
    name: val.name,
    type: val.asset_type,
    coordinates: {
      lat: val.latitude,
      lng: val.longitude,
    },
    location: {
      country: 'South Sudan',
      state: countyMap[val.county_id].ADM1_EN,
      county: countyMap[val.county_id].ADM2_EN,
    },
    county_id: val.county_id,
    highestRisk: 'Unknown',
    updated_at: moment(val.updated_at).format('DD/MM/YY'),
  }))
  return {
    type: 'FeatureCollection',
    features: formattedIncident.map((val) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          val.coordinates.lng, val.coordinates.lat,
        ],
      },
      properties: {
        ...val,
      },
    })),
  }
}

/* Draws county and region limits for alert states filter */
export const drawLimitsAndNames = (mapRef: React.RefObject<mapboxgl.Map>, allowRegions: boolean) => {
  mapRef.current.addLayer({
    id: 'counties-background',
    source: 'counties',
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': 'rgba(255,255,255, 0)',
      'fill-opacity': 1,
    },
  })

  const countyLimits = {
    id: 'county-limits',
    source: 'counties',
    type: 'line',
    // minzoom: Number = 0,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#888',
      'line-width': 0.5,
    },
  } as mapboxgl.AnyLayer

  const countyNames = {
    id: 'county-names',
    source: 'counties',
    type: 'symbol',
    // minzoom: 0,
    layout: {
      'text-field': ['get', 'ADM2_EN'],
      'text-size': 12,
    },
  } as mapboxgl.AnyLayer

  if (allowRegions) {
    // countyLimits.minzoom = zoomThreshold
    // countyNames.minzoom = zoomThreshold

    mapRef.current.addLayer({
      id: 'regions-background',
      source: 'regions',
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': 'rgba(255,255,255, 0)',
        'fill-opacity': 1,
      },
    })

    const regionLimits = {
      id: 'region-limits',
      source: 'regions',
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#888',
        'line-width': 0.5,
      },
      maxzoom: zoomThreshold,
    } as mapboxgl.AnyLayer

    const regionNames = {
      id: 'region-names',
      source: 'regions',
      type: 'symbol',
      layout: {
        'text-field': ['get', 'shapeName'],
        'text-size': 12,
      },
      maxzoom: zoomThreshold,
    } as mapboxgl.AnyLayer

    mapRef.current.addLayer(regionLimits)
    mapRef.current.addLayer(regionNames)
  }

  mapRef.current.addLayer(countyLimits)
  /* Add county names */
  mapRef.current.addLayer(countyNames)
}

export const createAlertStatesSourcesAndLayers = (mapRef: React.RefObject<mapboxgl.Map>, counties: CountiesGeoJSON, regions: RegionsGeoJSON, allowRegions: boolean) => {
  if (mapRef.current.getLayer('counties-layer')) return

  /** Add county shapes and names */
  mapRef.current.addSource('counties', {
    type: 'geojson',
    data: counties,
  })

  if (allowRegions) {
    mapRef.current.addSource('regions', {
      type: 'geojson',
      data: regions,
    })
  }

  drawLimitsAndNames(mapRef, allowRegions)

  /* Add colors depending on data source */
  mapRef.current.addLayer({
    id: 'counties-layer',
    source: 'counties',
    minzoom: zoomThreshold,
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': ['get', 'Alert_color'],
      'fill-opacity': 1,
    },
  }, 'county-limits')

  mapRef.current.addLayer({
    id: 'regions-layer',
    source: 'regions',
    maxzoom: zoomThreshold,
    type: 'fill',
    layout: {},
    paint: {
      'fill-color': ['get', 'Alert_color'],
      'fill-opacity': 1,
    },
  }, 'region-limits')
}

/**
 * Get starting and end point of a feature collection of journeys
 *
 * @param histories -> Feature collection of completed journeys
 * @returns A feature collection of containing the start and endpoint of completed journeys
 */
export const getStartEndPoints = (histories: JourneyTrackerFC): FeatureCollection<Point> => {
  const features: Feature<Point>[] = []

  const ensureValidCoordinates = (coords: any): [number, number][] => {
    if (!Array.isArray(coords)) {
      return [] // Return an empty array if not an array
    }

    return coords.map((coord) => {
      if (typeof coord === 'string') {
        try {
          // Try to parse string as JSON, and validate if it forms a numeric array
          const parsedCoord = JSON.parse(coord)
          if (Array.isArray(parsedCoord) && parsedCoord.length === 2) {
            const numberParsed = parsedCoord.map(Number).filter((num) => !Number.isNaN(num))
            return numberParsed.length === 2 ? numberParsed as [number, number] : null
          }
        } catch (e) {
          console.error('Invalid coordinate format:', coord)
          return null
        }
      } else if (Array.isArray(coord) && coord.length === 2) {
        const numberCoords = coord.map(Number).filter((num) => !Number.isNaN(num))
        return numberCoords.length === 2 ? numberCoords as [number, number] : null
      }
      console.error('Unhandled coordinate format:', coord)
      return null
    }).filter((coord) => coord !== null) // Remove invalid coordinates
  }

  histories.features.forEach(({ geometry, properties }) => {
    console.log('geometry:', geometry)
    console.log('properties:', properties)

    const validCoordinates = ensureValidCoordinates(geometry?.coordinates)
    if (validCoordinates.length <= 0) {
      return
    }

    if (properties.position === JourneySegmentPosition.FIRST) {
      features.push(point(validCoordinates[0]))
      return
    }

    if (properties.position === JourneySegmentPosition.LAST) {
      features.push(point(validCoordinates[validCoordinates.length - 1]))
    }
  })

  return featureCollection(features)
}
