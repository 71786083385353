import * as React from 'react'
import { IonPage, IonContent, IonGrid } from '@ionic/react'
import { ProvideRiskRegister } from '../hooks/risks/useRiskRegister'
import AppWrapper from '../components/riskregister/AppWrapper'
import PageHeader from '../components/PageHeader'

/**
 *  ===========================
 *      Risk Assessments App
 *  ===========================
 *  Evaluate risks for every company's assets
 *  Second version -> Implemented with useReducers and hooks + more tests
 */

const RiskAssessmentsApp = () : JSX.Element => (
  <ProvideRiskRegister>
    <IonPage>
      <PageHeader title='Risk Assessments' />
      <IonContent
        style={{
          '--padding-top': '20px',
          '--padding-bottom': '20px',
          '--padding-start': '20px',
          '--padding-end': '20px',
        }}
      >
        <IonGrid style={{ position: 'relative', height: '100%' }}>
          <AppWrapper />
        </IonGrid>
      </IonContent>
    </IonPage>
  </ProvideRiskRegister>

)

export default RiskAssessmentsApp
