import * as React from 'react'
import { IonSpinner } from '@ionic/react'
import { ObscureBackground, CenterLoader } from '../GlobalContainers'

interface Props {
  isOpen: boolean
}

const LoadingModal : React.FC<Props> = ({ isOpen }) => (isOpen ? (
  <>
    <ObscureBackground style={{ zIndex: '30' }} />
    <CenterLoader style={{ zIndex: '31' }}>
      <IonSpinner color='light' />
    </CenterLoader>
  </>
) : null)

export default LoadingModal
