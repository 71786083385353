import styled from 'styled-components'

const ConsultantFilter = styled.div`
  display: flex;
  align-items: center;

  & h4 {
    color: #326771;
    font-size: 1.2rem;
    margin: 0 5px;
  }

  & .dropdown button{
    border: 1px solid #747474;
    color: #747474;
    background: white;
  }
`

const ListContainer = styled.div`
  width: 100%;
  height: 95%;
  position: absolute;
  z-index: 30;
  right: 0;
  top: 0;
`

export {
  ConsultantFilter,
  ListContainer,
}
