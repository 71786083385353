/* eslint-disable react/no-array-index-key */
import {
  IonButton, IonRow, IonCol, IonInput, useIonAlert, useIonLoading, IonContent, IonLabel,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'

import Select from 'react-select'

import Datetime from 'react-datetime'
import moment from 'moment'
// import { Icon } from '@iconify/react'
import { SettingField } from '../settings/StyledContainers'
import { WidgetTitles } from '../maps/WidgetContainers'
import { getColor } from '../maps/apps/AppMap'
import Popup from './Popup'

import AddObjectiveTask from './AddObjectiveTask'

// import { SherpaButton } from '../GlobalContainers'
// import TaskInput from './TaskInput'
import useApi from '../../hooks/testHooks'

import { statuses, colorCodeStatus, defineOptions } from './StatusHelper'
import { useWorkspace } from '../../hooks/useWorkspace'

/**
 *  ============================
 *     Objective details view
 *  ============================
 */
const ObjectiveDetails = ({
  focussedObjective, users, onClose, updateObjective, setLoading,
  selectedProject, editingObjective, setEditingObjective, updateProject,
}) => {
  const [showAddTask, setShowAddTask] = useState(false)
  const [removedTasks, setRemovedTasks] = useState([])
  const [flag, setFlag] = useState(false)

  /* Applciation hooks */
  const [ionAlert] = useIonAlert()
  const [present, dismiss] = useIonLoading()
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  /* Display Popup message */
  const [input, setInput] = useState({
    name: focussedObjective.name,
    endDate: moment(focussedObjective.endDate),
    startDate: moment(focussedObjective.startDate),
    description: focussedObjective.description,
    owner: { value: focussedObjective.owner.id, label: `${focussedObjective.owner.first_name} ${focussedObjective.owner.final_name}` },
    status: focussedObjective.status,
    tasks: focussedObjective.tasks.map((task) => ({ ...task, due: moment(task.due), assignee: { value: task.assignee.id, label: task.assignee.email } })),
  })

  const handleChange = (key, value) => {
    setInput({ ...input, [key]: value })
  }

  const addTask = (task) => {
    const copy = input.tasks.slice()
    copy.push(task)

    setInput({ ...input, tasks: copy })
    setShowAddTask(false)
  }

  const handleEditTask = (edited, index) => {
    const copy = input.tasks.slice()
    copy[index] = { ...edited, edited: !(edited.new) }

    setInput({ ...input, tasks: copy })
  }

  const removeTask = (index, task) => {
    const copy = input.tasks.slice()
    const taskToRemove = copy.splice(index, 1)

    apiHook.updateStatus(task.id, 'Cancelled', workspace.id)

    if (!taskToRemove.new) setRemovedTasks([...removedTasks, taskToRemove.id])
    setInput({ ...input, tasks: copy })
  }

  const returnMessage = (key) => {
    if (key === 'name') return { header: 'Error - Title', message: 'Please fill in the name of the objective.' }
    if (key === 'date') return { header: 'Error - Due date', message: 'Please select a target date for this objective.' }
    if (key === 'owner') return { header: 'Error - Owner', message: 'Please select who is going to be in charge of this objective.' }
    if (key === 'status') return { header: 'Error - Status', message: 'Please select a valid status.' }
    if (key === 'title') return { header: 'Error - Task Title', message: 'One of your objective tasks is missing the title.' }
    if (key === 'assignee') return { header: 'Error - Task Assignee', message: 'One of your objective tasks is missing the assignee.' }
    if (key === 'due') return { header: 'Error - Task Due date', message: 'One of your objective tasks is missing the due date.' }
    if (key === 'description') return { header: 'Error - Task Description', message: 'One of your objective tasks is missing the description.' }
    return null
  }

  const handleStatus = async (e) => {
    const newStatus = e.target.value
    /* Progress objective */
    const progress = async () => {
      present()
      await apiHook.progress_objective(focussedObjective.id).then(() => {
        if (selectedProject.status === 'Planning') {
          updateProject({ ...selectedProject, status: 'In-Progress' }, false)
          updateObjective({ ...focussedObjective, owner: focussedObjective.owner.id, status: newStatus }, focussedObjective.id, { ...selectedProject, status: 'In-Progress' })
        } else {
          updateObjective({ ...focussedObjective, owner: focussedObjective.owner.id, status: newStatus }, focussedObjective.id)
        }
      })
      setInput({ ...input, status: newStatus })
      dismiss()
    }

    /* Regress objective */
    const regress = async () => {
      present()
      await apiHook.regress_objective(focussedObjective.id)
      setInput({ ...input, status: newStatus })
      updateObjective({ ...focussedObjective, owner: focussedObjective.owner.id, status: newStatus }, focussedObjective.id)
      dismiss()
    }

    /* Abort project (cancel) */
    const cancel = async () => {
      present()
      await apiHook.abort_objective(focussedObjective.id)
      setInput({ ...input, status: 'Aborted' })
      updateObjective({ ...focussedObjective, status: 'Aborted' }, focussedObjective.id)
      dismiss()
    }

    let message = ''
    let handler

    if (e.target.value === 'Cancel') {
      message = 'Do you wish to continue? This action cannot be undone.'
      handler = () => cancel()
    } else if (statuses.indexOf(e.target.value) > statuses.indexOf(focussedObjective.status)) {
      message = 'Do you want to progress the status of this Objective?'
      handler = () => progress()
    } else if (statuses.indexOf(e.target.value) < statuses.indexOf(focussedObjective.status)) {
      message = 'Do you want to regress the status of this Objective?'
      handler = () => regress()
    }

    ionAlert({
      header: 'Attention',
      message,
      buttons: [
        {
          text: 'Back',
          handler: () => {
            dismiss()
          },
        },
        {
          text: 'Yes, continue',
          handler,
        },
      ],
    })
  }

  // TODO: Workout which tasks need to be edited and which have to be created
  const handleSubmit = async () => {
    const notFilled = Object.keys(input).find((key) => !input[key])
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }
    let canSubmit = true

    input.tasks.forEach((task) => {
      const taskNotFilled = Object.keys(task).find((key) => !task[key] && key !== 'highPriority')
      if (taskNotFilled && returnMessage(taskNotFilled)) {
        const error = returnMessage(taskNotFilled)
        ionAlert({
          header: error.header,
          message: error.message,
          buttons: [
            { text: 'Ok' },
          ],
        })
        canSubmit = false
      }
    })

    if (!canSubmit) return

    setLoading(true)

    const formattedObj = {
      ...input, startDate: input.startDate.format('YYYY-MM-DD HH:mm'), endDate: input.endDate.format('YYYY-MM-DD HH:mm'), owner: input.owner.value, status: input.status,
    }

    const requestParam = {
      title: formattedObj.name,
      deadline: formattedObj.endDate,
      description: formattedObj.description,
      owner: formattedObj.owner,
      start: formattedObj.startDate,
      id: focussedObjective.id,
    }

    /* Send request to change the objective in the db */
    const { results } = await apiHook.editObjective({ domain_id: workspace.id, objectives: [requestParam] })

    if (results === -2) {
      ionAlert({
        header: 'Time Error',
        message: 'Check that the time for this objective falls within the project timeframe',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }
    /*
    const requestDataTasks = formattedObj.tasks.map((task) => {
      if (!task.edit && !task.new) return task
      task.due = task.due.format('YYYY-MM-DD HH:mm')
      task.assignee = task.assignee.value

      return task
    }) */

    const newTasks = formattedObj.tasks.filter((task) => task.new)
    const oldTasks = formattedObj.tasks.filter((task) => !task.new)

    await apiHook.addTaskToObjective(focussedObjective.id, newTasks.map(({
      description, title, due, highPriority, assignee,
    }) => ({
      description,
      title,
      due: due.format('YYYY-MM-DD HH:mm'),
      assignee: assignee.value,
      priority: highPriority,
    })))

    // TODO: send request and assign the new ids to the  tasks
    formattedObj.tasks = [...oldTasks, ...newTasks]

    const formattedTasks = formattedObj.tasks.map((task) => {
      if (!task.edit && !task.new) return task
      const assignee = users.find((user) => user.id === task.assignee.value)
      const copyTask = {
        ...task,
        due: task.due.format('YYYY-MM-DD HH:mm'),
        assignee: {
          id: assignee.id,
          email: assignee.email,
        },
      }

      delete copyTask.new
      delete copyTask.edit

      return copyTask
    })

    setLoading(false)
    onClose()
    updateObjective({ ...focussedObjective, ...formattedObj, tasks: formattedTasks }, focussedObjective.id)
  }

  useEffect(() => {
    if (flag) onClose()

    setFlag(true)
  }, [selectedProject])

  const [selectedTask, setSelectedTask] = useState(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const [taskIndex, setTaskIndex] = useState()

  const handleRowClick = (task, index) => {
    setSelectedTask(task)
    setIsPopupOpen(true)
    setTaskIndex(index)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClosePopup = (updatedTask) => {
    setIsPopupOpen(false)
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getAssigneeName = (assigneeId: string, users: any) => {
    const assignee = users.find((user) => user.id === assigneeId)
    return assignee ? `${assignee.first_name} ${assignee.final_name}` : 'Not Assigned'
  }

  return (
    <>
      {
        (showAddTask) ? (
          <AddObjectiveTask
            users={users}
            onSubmit={addTask}
            onCancel={() => setShowAddTask(false)}
            dates={{
              startDate: input.startDate,
              endDate: input.endDate,
            }}
          />
        )
          : (
            <>
              <IonRow className='ion-justify-content-between' style={{ padding: '10px' }}>
                <IonRow>
                  {
                  editingObjective && (
                    <IonButton style={{ '--background': '#8E151F', textTransform: 'none' }} onClick={() => setEditingObjective(false)}>Cancel</IonButton>
                  )
                }
                </IonRow>
              </IonRow>
              <IonContent style={{
                '--background': 'white',
                height: '85%',
                '--padding-bottom': '10px',
                '--padding-end': '10px',
                '--padding-start': '10px',
                '--padding-top': '10px',
              }}
              >
                <SettingField style={{ marginTop: '15px' }}>
                  <IonRow className='ion-align-items-center' style={{ width: '100%', padding: 0 }}>
                    <IonCol size={5}>
                      <h5 style={{ color: '#326771' }}>Objective Title</h5>
                      {
                        editingObjective ? (
                          <IonInput
                            value={input.name}
                            name='name'
                            placeholder='What is this objective called?'
                            type='text'
                            onIonChange={(e) => handleChange(e.target.name, e.detail.value)}
                            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.13)' }}
                          />
                        ) : (
                          <IonLabel style={{ }}>{input.name}</IonLabel>
                        )
                      }

                    </IonCol>
                    <IonCol size={2}>
                      <h5 style={{ color: '#326771' }}>Start Date</h5>
                      {
                        editingObjective ? (
                          <Datetime
                            dateFormat='DD/MM/YY'
                            inputProps={{
                              placeholder: 'Start Date',
                              readOnly: true,
                              style: { backgroundColor: 'white' },
                            }}
                            isValidDate={(current) => current.isAfter(moment())}
                            name='startDate'
                            value={moment(input.startDate).format('DD/MM/YY')}
                            onChange={(e) => handleChange('startDate', e)}
                          />
                        ) : (
                          <IonLabel>{moment(input.startDate).format('DD/MM/YY')}</IonLabel>
                        )
                      }
                    </IonCol>
                    <IonCol size={2}>
                      <h5 style={{ color: '#326771' }}>End Date</h5>
                      {
                        editingObjective ? (
                          <Datetime
                            dateFormat='DD/MM/YY'
                            inputProps={{
                              placeholder: 'Target Date',
                              readOnly: true,
                              style: { backgroundColor: 'white' },
                            }}
                            isValidDate={(current) => current.isAfter(moment())}
                            name='endDate'
                            value={moment(input.endDate).format('DD/MM/YY')}
                            onChange={(e) => handleChange('endDate', e)}
                          />
                        ) : (
                          <IonLabel>{moment(input.endDate).format('DD/MM/YY')}</IonLabel>
                        )
                      }
                    </IonCol>
                    <IonCol size={3}>
                      <h5 style={{ color: '#326771' }}>Status</h5>
                      <select
                        disabled={!editingObjective || input.status === 'Cancel'}
                        className='journey-status'
                        style={{ backgroundColor: colorCodeStatus(input.status) }}
                        value={input.status}
                        onChange={handleStatus}
                      >
                        {
                          defineOptions(input.status).map((status) => (
                            <option key={status} value={status}>{ status }</option>
                          ))
                        }
                      </select>
                    </IonCol>
                  </IonRow>
                </SettingField>
                <SettingField>
                  <IonRow>
                    <IonCol size={3}>
                      <h5 style={{ color: '#326771' }}>Owner</h5>
                      {
                        editingObjective ? (
                          <Select
                            placeholder='Who is in charge of this objective?'
                            name='owner'
                            className='select-container'
                            value={input.owner}
                          // eslint-disable-next-line no-undef
                            menuPortalTarget={document.body}
                            options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                            onChange={(selected) => handleChange('owner', selected)}
                          />
                        ) : (
                          <IonLabel>{input.owner.label}</IonLabel>
                        )
                    }

                    </IonCol>
                  </IonRow>
                </SettingField>

                <SettingField>
                  <IonRow className='ion-align-items-center' style={{ width: '100%', padding: 0, paddingBottom: '10px' }}>
                    <IonCol size={12}>
                      <h5 style={{ color: '#326771' }}>Description</h5>
                      {
                        editingObjective ? (
                          <IonInput
                            value={input.description}
                            name='description'
                            placeholder='Add an objective to the project'
                            type='textarea'
                            onIonChange={(e) => handleChange(e.target.name, e.detail.value)}
                            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.13)' }}
                          />
                        ) : (
                          <IonLabel>{input.description}</IonLabel>
                        )
                      }
                    </IonCol>
                  </IonRow>
                </SettingField>
                <IonRow
                  className='ion-align-items-center'
                  style={{
                    width: '100%', padding: 0, borderBottom: '1px dotted black', paddingBottom: '10px',
                  }}
                />

                <IonRow style={{ marginTop: '20px' }} className='ion-justify-content-between'>
                  <SettingField style={{ width: 'auto' }}><h5 style={{ color: '#326771' }}>Objective Tasks</h5></SettingField>
                  {
                    input.tasks.length !== 0 && (
                      // eslint-disable-next-line max-len, @typescript-eslint/no-unused-expressions, no-sequences
                      <IonButton style={{ fontSize: '1rem', '--background': '#326771', 'text-transform': 'none' }} onClick={() => { setShowAddTask(true), setEditingObjective(true) }}>Add Task</IonButton>
                    )
                  }
                </IonRow>
                <WidgetTitles style={{
                  height: '10%', position: 'sticky', top: 0, zIndex: 10, paddingRight: 0,
                }}
                >
                  <IonRow className='ion-align-items-center' style={{ border: '1px solid #ccc' }}>
                    <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                      Title
                    </IonCol>
                    <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                      Assigned To
                    </IonCol>
                    <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                      Due Date
                    </IonCol>
                    <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                      High Priority
                    </IonCol>
                    <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={4}>
                      Description
                    </IonCol>
                  </IonRow>
                </WidgetTitles>
                {
                  input.tasks.map((task, index) => (
                    <IonRow className='ion-align-items-center' style={{ backgroundColor: getColor(index) }} onClick={() => handleRowClick(task, index)}>
                      <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                        {task.title}
                      </IonCol>
                      <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                        {getAssigneeName(task.assignee.value, users)}
                      </IonCol>
                      <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                        {moment(task.due).format('MMM D, YYYY')}
                      </IonCol>
                      <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={2}>
                        {task.highPriority ? 'Yes' : 'No'}
                      </IonCol>
                      <IonCol style={{ cursor: 'pointer' }} className='ion-text-center' size={4}>
                        {task.description.length > 115 ? `${task.description.slice(0, 115)}...` : task.description}
                      </IonCol>
                    </IonRow>
                  ))
                }

                {
                  input.tasks.length === 0 && (
                    <SettingField style={{ margin: '15px 0', textAlign: 'center' }}>
                      <p style={{ color: '#326771' }}>This objective does not have any tasks assigned it. </p>
                      <IonButton
                        style={{ fontSize: '1rem', '--background': '#326771', 'text-transform': 'none' }}
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
                        onClick={() => { setShowAddTask(true), setEditingObjective(true) }}
                      >Add Task
                      </IonButton>
                    </SettingField>
                  )
                }
                {
                  editingObjective && (
                    <IonRow style={{ width: '100%', padding: '0 10px' }} className='ion-justify-content-end'>
                      <IonButton style={{ '--background': '#326771', textTransform: 'none' }} onClick={handleSubmit}>Save</IonButton>
                    </IonRow>
                  )
                }
              </IonContent>
            </>
          )
      }
      {
        isPopupOpen && (
          <Popup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            task={selectedTask}
            users={users}
            input={selectedTask}
            setInput={(edited) => handleEditTask(edited, taskIndex)}
            dates={{
              startDate: input.startDate,
              endDate: input.endDate,
            }}
            removeTask={removeTask}
            index={taskIndex}
          />
        )
      }

    </>
  )
}

export default ObjectiveDetails
