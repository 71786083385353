import React, { createContext, useState, useContext } from 'react'
/**
 *  ===============================
 *      SAFETY CHECK CONTEXT
 *  ===============================
 */
const PopupContext = createContext()

export const ProvideSecCond = ({ children }) => {
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState(null)

  /* Show/Hide popup handlers */
  const displaySecPopup = (notificationId) => {
    setNotification(notificationId)
    setShow(true)
  }
  const hidePopup = () => {
    setNotification(null)
    setShow(false)
  }

  return (
    <PopupContext.Provider value={[displaySecPopup, hidePopup, show, notification]}>
      {
        children
      }
    </PopupContext.Provider>
  )
}

export const useSecCondPopup = () => useContext(PopupContext)
