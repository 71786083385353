/** Handle state of the form */

import { GeoLocation } from '../../../components/types/GlobalTypes'

export interface AssetInput {
  name: string,
  type: string,
  location: {
    country: string,
    state?: string,
    county?: string,
  },
  coordinates: GeoLocation,
  county_id: number,
  showDetailsForm: boolean,
  marker_type: string,
  asset_type: string,
  geodata: any,
  longitude: any,
  latitude: any,
  description: string,
}

export enum AssetInputActionKind {
  EDIT,
  CLEAR,
}

export interface AssetInputActions {
  type: AssetInputActionKind,
  payload?: AssetInput
}

export const initialAssetInput: AssetInput = {
  name: '',
  type: '',
  location: {
    country: null,
    state: null,
    county: null,
  },
  coordinates: {
    lat: null,
    lng: null,
  },
  county_id: null,
  showDetailsForm: false,
}

export const addAssetsInputReducer = (state: AssetInput, action: AssetInputActions) : AssetInput => {
  switch (action.type) {
    case AssetInputActionKind.EDIT:
      if (!action.payload) return { ...state }
      return { ...state, ...action.payload }
    case AssetInputActionKind.CLEAR:
      return { ...initialAssetInput }
    default:
      return { ...state }
  }
}
