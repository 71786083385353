/* eslint-disable camelcase */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useState, useRef } from 'react'
import { IonList, useIonAlert } from '@ionic/react'
import moment from 'moment'
import MapForm from '../forms/MapForm'
import MBform from '../forms/MBform'

// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'

import testHook from '../../../hooks/testHooks'
import { restrictionBoxIndicator, createMB } from '../apps/AppMap'
import FormPopup from '../forms/FormPopup'
import BoxMeasures from '../forms/BoxMeasures'
import { ObscureBackground } from '../../GlobalContainers'

/**
 *  =====================================
 *    Restriction Box Form and Picking
 *  =====================================
 */
const RestrictionBox = ({
  mapRef, setShowMbForm, movementBoxes, domainId, enableZoom = true,
  setMovementBoxes, showMbs, onClose, setLoading, controlMeasures,
}) => {
  /* Fill form when the Box has been selected */
  const [canFillForm, setCanFillForm] = useState(false)
  /* Store coordinates */
  const [coordinates, setCoordinates] = useState([])
  const [alert] = useIonAlert()
  const [layerDrawn, setLayerDrawn] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [showMeasures, setShowMeasures] = useState(false)
  const [selectedMeasures, setSelectedMeasures] = useState([])

  /* Restriction Box markers */
  const [markers, setMarkers] = useState([])
  const apiHook = testHook()

  /* Change the coordinates of the current marker */
  const setCurrentPoint = ([lon, lat], index) => {
    if (!coordinates.length) return

    /* Update location */
    coordinates[index] = [lon, lat]
    setCoordinates(coordinates)

    /* Change last markers position */
    markers[index].setLngLat([lon, lat])

    if (index === 0 && layerDrawn) coordinates[coordinates.length - 1] = [lon, lat]

    /* Update layer */
    mapRef.current.getSource('new-restriction-box').setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates,
      },
    })
  }

  /* Use reference to keep the state updated */
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  const addMarker = (lngLat) => {
    const marker = (!coordinates.length) ? new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' }).setPopup(new mapboxgl.Popup({ offset: 25 }).setText(
      'Construction on the Washington Monument began in 1848.',
    )) : new mapboxgl.Marker()

    if (!coordinates.length) {
      // eslint-disable-next-line no-underscore-dangle
      marker._element.id = 'rb-first-marker'
    }

    marker.setLngLat(lngLat)
      .addTo(mapRef.current)

    return marker
  }

  const removeMarker = () => {
    const marker = markers.pop()
    marker.remove()

    setMarkers([...markers])

    const copy = coordinates.slice()
    copy.pop()

    if (canFillForm) {
      copy.pop()
      mapRef.current.removeLayer('new-layer')
      setCanFillForm(false)
    }

    setCoordinates(copy)

    const source = mapRef.current.getSource('new-restriction-box')
    source.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: copy,
      },
    })
  }

  const drawLayer = () => {
    if (coordinates.length < 3) {
      alert({
        header: 'Error',
        message: 'You need to draw a polygon with at least 3 vertices',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    const newCoordinates = [...coordinates, coordinates[0]]

    const source = mapRef.current.getSource('new-restriction-box')
    source.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })

    setCoordinates(newCoordinates)

    mapRef.current.addLayer({
      id: 'new-layer',
      type: 'fill',
      source: 'new-restriction-box',
      layout: {},
      paint: {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.5,
      },
    })

    setLayerDrawn(true)

    /* Can procede filling the form */
    setCanFillForm(true)
  }

  /* Use of reference to keep the state updated */
  const drawLayerRef = useRef(drawLayer)
  drawLayerRef.current = drawLayer

  const handleClick = (e) => {
    if (canFillForm) return

    if (enableZoom) { mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() }) }
    /* Add coordinates to the source */
    const marker = addMarker(Object.values(e.lngLat))

    /* Set draggable for the current marker */
    marker.setDraggable(true)

    /* Manage event */
    marker.on('drag', () => {
      setCurrentPointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], coordinates.length)
    })

    /* If it is the first marker in buffer add onclick to finish the polygon */
    if (!coordinates.length) {
      marker.getElement().onclick = (event) => {
        event.stopPropagation()
        drawLayerRef.current()
      }
    }

    /* Add marker to buffer */
    setMarkers([...markers, marker])

    const newCoordinates = [...coordinates, Object.values(e.lngLat)]
    setCoordinates(newCoordinates)

    const source = mapRef.current.getSource('new-restriction-box')

    source.setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })
  }
  /* Use of reference to keep the state updated */
  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  const hideForm = () => {
    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })

    /* Reset coordinates and create new source */
    setCoordinates([])
    setMarkers([])
    setCanFillForm(false)

    setShowMbForm(false)

    if (onClose) onClose()
  }

  const handleSubmitMb = async (input) => {
    if (!layerDrawn) {
      alert({
        header: 'Error',
        message: 'You need to draw a polygon with at least 3 vertices on the map',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    /* Mapp popup data */
    const popupdata = restrictionBoxIndicator({
      senior_manager_approval: input.seniorApproval,
      anti_vampire_rules: input.noNightTravel,
      forbidden: input.outOfBounds,
      updated_at: new Date(),
      check_in_days: input.review,
    })

    const newFeature = {
      type: 'Feature',
      geometry: {
        coordinates,
        type: 'LineString',
      },
    }

    setLoading(true)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { mb_id, message } = await apiHook.addBox(false, false,
      input.seniorApproval, false,
      false, input.noNightTravel, false,
      input.outOfBounds, JSON.stringify(newFeature), moment().format('DD/MM/YY HH:mm'),
      moment().format('DD/MM/YY HH:mm'), input.review, input.description, domainId, selectedMeasures.map(({ id }) => id))
    setLoading(false)

    if (mb_id < 0) {
      alert({
        header: 'Server Error',
        message,
        buttons: [
          { text: 'Ok' },
        ],
      })
    } else {
      newFeature.properties = {
        popupdata,
        senior_manager_approval: input.seniorApproval,
        no_night_travel: input.noNightTravel,
        out_of_bounds: input.outOfBounds,
        check_in_days: input.review,
        id: mb_id,
        description: input.description,
      }
      const mbs = [...movementBoxes, newFeature]

      /* Remove every marker */
      markers.map((marker) => marker.remove())
      setMovementBoxes(mbs)

      /* Add restriction box to the original source */
      mapRef.current.getSource('restriction-boxes').setData({
        type: 'FeatureCollection',
        features: mbs,
      })
    }

    /* Show movement boxes */
    showMbs()
    setSubmitted(true)
  }

  /* Remove markers in buffer */
  const handleCancel = (typed) => {
    const close = () => {
      /* Remove every marker */
      markers.map((marker) => marker.remove())

      mapRef.current.easeTo({
        padding: {
          right: 0,
        },
      })

      setShowMbForm(false)
      if (onClose) onClose()
    }

    if (typed) {
      alert({
        header: 'Cancel Restriction Box?',
        message: 'Do you wish to continue? Your progress will be lost. ',
        buttons: [
          'Back',
          {
            text: 'Yes, continue',
            handler: close,
          },
        ],
      })

      return
    }
    close()
  }

  useEffect(() => {
    createMB(mapRef, [])

    const onClick = (e) => {
      handleClickRef.current(e)
    }
    mapRef.current.on('click', onClick)
    return () => {
      mapRef.current.off('click', onClick)
      mapRef.current.removeLayer('new-restriction-box')
      if (mapRef.current.getLayer('new-layer')) mapRef.current.removeLayer('new-layer')
      mapRef.current.removeSource('new-restriction-box')
    }
  }, [])

  return (
    <>
      {
        showMeasures && (
          <>
            <ObscureBackground style={{ zIndex: 19 }} />
            <BoxMeasures
              allMeasures={controlMeasures}
              selectedMeasures={selectedMeasures}
              onClose={() => setShowMeasures(false)}
              onFinish={(measures) => {
                setSelectedMeasures(measures)
                setShowMeasures(false)
              }}
            />
          </>
        )
      }
      <MapForm>
        {
          (!submitted) ? (
            <MBform
              onCancel={handleCancel}
              onSubmit={handleSubmitMb}
              onRemoveMarker={removeMarker}
              markers={markers}
              canFillForm={canFillForm}
              showRestrictions={() => setShowMeasures(true)}
              selectedMeasures={selectedMeasures}
            />
          ) : (
            <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
              <FormPopup
                message='You have successfully created a Restriction Box.'
                onClose={hideForm}
              />
            </IonList>
          )
        }
      </MapForm>
    </>
  )
}

export default RestrictionBox
