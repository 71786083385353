import { IonCol, IonItem, IonRow } from '@ionic/react'
import React from 'react'
import moment from 'moment'
import { ArticleInformation } from './StyleContainers'

/**
 *  ============================
 *     Card for the news feed
 *  ============================
 */
const ArticleCard = ({
  url, domain, title, img = '', created_at,
}) => (
  <IonItem
    style={{
      margin: '20px 0',
      '--ion-background-color': 'white',
      '--border-radius': '5px',
      '--color': '#42505A',
    }}
    href={url}
    target='_blank'
  >
    <IonRow style={{ width: '100%' }} className='ion-justify-content-between ion-align-items-center'>
      <IonCol size={8}>
        <ArticleInformation>
          <h4 className='news-card-title'>{ title }</h4>
          <p><span>Source: </span> { domain }</p>
          <p><span>Date: </span>{ moment(created_at).format('DD-MMM-YYYY') }</p>
        </ArticleInformation>
      </IonCol>
      <IonCol size={4}>
        <IonRow className='ion-justify-content-end'>
          <img
            src={img}
            alt={title}
            style={{ maxHeight: '130px' }}
          />
        </IonRow>
      </IonCol>
    </IonRow>
  </IonItem>
)

export default ArticleCard
