import { IonButton, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { OverlayContainer } from '../../../GlobalContainers'

interface Props {
  onCancel: () => void,
  onDeny: () => void,
  onAccept: () => void
}

const InheritSOPs : FC<Props> = ({ onCancel, onDeny, onAccept }) => (
  <OverlayContainer style={{ zIndex: 20, width: '400px' }}>
    <h4 style={{ marginBottom: '15px' }} className='red-font'>Inherit SOPs?</h4>
    <div style={{ margin: '20px 0' }}>
      <p style={{ margin: '10px 0' }}>Do you wish to inherit the same SOPs from the Organisation template?</p>
      <p style={{ margin: '10px 0' }}>Selecting No will mean the workspace template will be blank on creation.</p>
    </div>
    <IonRow className='ion-justify-content-between'>
      <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
      <IonRow>
        <IonButton onClick={onDeny} style={{ '--background': '#4B99F4' }}>No</IonButton>
        <IonButton onClick={onAccept} style={{ '--background': '#326771' }}>Yes</IonButton>
      </IonRow>
    </IonRow>
  </OverlayContainer>
)

export default InheritSOPs
