import React, { useRef, useState, useReducer } from 'react'
import {
  IonPage, IonContent, IonGrid, IonRow,
} from '@ionic/react'
import * as mapboxgl from 'mapbox-gl'
import PageHeader from '../components/PageHeader'
import Map from '../components/maps/Map'
import axios from '../utils/axios'
import {
  BackendCountryAreas, CountryAreas, Incident, IncidentTimeFilter,
} from '../components/types/OptimizedMaps'
import {
  createSources, renderAlertStates, displayAlertStatePopup, createIncidentsLayer,
} from '../components/utils/MapboxRendering'
import { SelectView } from '../components/maps/StyledContainers'
import { InsightViews } from '../hooks/insights/helpers/StateReducers'
import {
  filterIncidents,
  findCountyInAreas, findRegionsInAreas, getMapboxLayers, getNextIncidentsConfig, processAreas,
} from '../components/utils/MapboxUtils'
import { useWorkspace } from '../hooks/useWorkspace'
import { defaultFilters, filtersReducer, FilterActions } from '../hooks/insights/helpers/FilterReducers'
import { displayIncidentPopup, drawIncidents } from '../hooks/insights/helpers/MapUtils'
import { FetchActionKind, fetchIncidentsReducer } from '../components/utils/Reducers'

const TestingMap = () => {
  const mapRef = useRef<mapboxgl.Map>()
  const time = Date.now()
  const [view, setView] = useState<InsightViews>(InsightViews.ALERT_STATES)
  const [areas, setAreas] = useState<CountryAreas[]>([])
  const [timeFilter, setTimeFilter] = useState<IncidentTimeFilter>(IncidentTimeFilter.LAST_MONTH)
  const { workspace } = useWorkspace()
  const [incidents, setIncidents] = useState<Incident[]>([])
  const [incidentFilters, dispatchIncidentFilters] = useReducer(filtersReducer, { ...defaultFilters })
  const [incidentFetch, dispatchIncidentFetch] = useReducer(fetchIncidentsReducer, { loading: true, oldestFetch: IncidentTimeFilter.LAST_MONTH, serverTime: '' })

  const updateIncidents = (filterAction: FilterActions | null, newTimeFilter: IncidentTimeFilter, incidentList: Incident[]) => {
    /* get new filters */
    if (filterAction) {
      dispatchIncidentFilters(filterAction)
    }
    const newFilters = (filterAction) ? filtersReducer(incidentFilters, filterAction) : incidentFilters

    if (newTimeFilter) { setTimeFilter(newTimeFilter) }

    const filtered = filterIncidents(newFilters, incidentList || incidents, newTimeFilter || timeFilter)
    drawIncidents(mapRef, filtered)
  }

  const handleTimeChange = (newTime: IncidentTimeFilter) => {
    const reqConfig = getNextIncidentsConfig(incidentFetch.serverTime, incidentFetch.oldestFetch, newTime)
    setTimeFilter(newTime)
    /* If null then incidents have already been fetched so just need to update map */
    if (!reqConfig) {
      updateIncidents(null, newTime, incidents)
      return
    }

    dispatchIncidentFetch({ type: FetchActionKind.FETCH_INCIDENTS, payload: { oldestFetch: newTime } })
    axios.post('/api/v2/incident/incidents', { domain_id: workspace.id, ...reqConfig }).then(({ data }) => {
      dispatchIncidentFetch({ type: FetchActionKind.RECEIVED_INCIDENTS, payload: { serverTime: data.server_from } })

      const updatedIncidents = [...incidents, ...data.incidents]
      setIncidents(updatedIncidents)
      updateIncidents(null, newTime, updatedIncidents)
      console.log(updatedIncidents.length)
    })
  }

  const handleViewChange = (newView: InsightViews) => {
    setView(newView)
    /* Clean previous state */
    if (view === InsightViews.ALERT_STATES) {
      renderAlertStates(mapRef, areas, false)
    }

    /* Render new states */
    if (newView === InsightViews.ALERT_STATES) {
      renderAlertStates(mapRef, areas, true)
    }
  }

  const handleCountiesClick = (e: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[];
  }) => {
    const feature = e.features[0]

    /* Skip if no feature */
    if (!feature) { return }
    const county = findCountyInAreas(areas, feature.properties.shapeID)
    if (!county) { return }

    displayAlertStatePopup(mapRef, county, e.lngLat, () => console.log('Hello'))
  }

  const handleCountiesClickRef = useRef(handleCountiesClick)
  handleCountiesClickRef.current = handleCountiesClick

  const handleRegionsClick = (e: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[];
  }) => {
    const feature = e.features[0]

    /* Skip if no feature */
    if (!feature) { return }
    const region = findRegionsInAreas(areas, feature.properties.shapeID)
    if (!region) { return }

    displayAlertStatePopup(mapRef, region, e.lngLat, () => console.log('Hello'))
  }

  const handleRegionsClickRef = useRef(handleRegionsClick)
  handleRegionsClickRef.current = handleRegionsClick

  const setUpListeners = () => {
    mapRef.current.on('load', () => {
      Promise.all([
        axios.post('/api/v2/county/counties', { domain_id: workspace.id }),
        axios.post('/api/v2/incident/incidents', { domain_id: workspace.id, time_from: '1month', time_to: 'today' }),
      ]).then(([{ data }, incidentData]) => {
        const { counties } : { counties: BackendCountryAreas[] } = data
        const formattedAreas : CountryAreas[] = processAreas(counties)
        /* Store state and render them counties */
        setAreas(formattedAreas)
        createSources(mapRef, formattedAreas)
        renderAlertStates(mapRef, formattedAreas, true)

        createIncidentsLayer(mapRef)

        const { regionLayers, countyLayers } = getMapboxLayers(formattedAreas)
        mapRef.current.resize()

        dispatchIncidentFetch({ type: FetchActionKind.RECEIVED_INCIDENTS, payload: { serverTime: incidentData.data.server_from } })
        updateIncidents(null, timeFilter, incidentData.data.incidents)
        setIncidents(incidentData.data.incidents)

        mapRef.current.on('click', 'incident-markers', (e) => {
          displayIncidentPopup(e, mapRef)
          e.originalEvent.preventDefault()
        })

        /* Add on click events for countries and regions alert state layers */
        mapRef.current.on('click', countyLayers, (e) => {
          if (!e.originalEvent.defaultPrevented) {
            handleCountiesClickRef.current(e)
          }
        })

        mapRef.current.on('click', regionLayers, (e) => {
          if (!e.originalEvent.defaultPrevented) {
            handleRegionsClickRef.current(e)
          }
        })

        alert(`It took ${(Date.now() - time) / 1000} Seconds to load this map`)
      })
    })
  }

  return (
    <IonPage>
      <PageHeader title='Risk Assessments' />
      <IonContent
        style={{
          '--padding-top': '20px',
          '--padding-bottom': '20px',
          '--padding-start': '20px',
          '--padding-end': '20px',
        }}
      >
        <IonGrid style={{ position: 'relative', height: '100%' }}>
          <IonRow>
            <SelectView
              value={view}
              onChange={(e) => handleViewChange(e.target.value)}
              id='select-app-view'
            >
              <option value={InsightViews.INCIDENTS}>Security</option>
              <option value={InsightViews.ALERT_STATES}>Alert States</option>
              <option value={InsightViews.TEAM_SAFETY}>Team Safety</option>
            </SelectView>
            <SelectView style={{ margin: '0 10px' }} id='incident-time-filter' value={timeFilter} onChange={(e) => handleTimeChange(e.target.value)}>
              <option value={IncidentTimeFilter.LAST_WEEK}>Last Week</option>
              <option value={IncidentTimeFilter.LAST_MONTH}>Last month</option>
              <option value={IncidentTimeFilter.SIX_MONTHS}>Last 6 months</option>
              <option value={IncidentTimeFilter.LAST_YEAR}>Last year</option>
              <option value={IncidentTimeFilter.ALL_FETCH}>All Time</option>
            </SelectView>
          </IonRow>
          <Map
            mapRef={mapRef}
            listeners={setUpListeners}
            zoom={5}
            style={{ height: '100%' }}
            className='insights-map-2'
          >
            <div />
          </Map>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default TestingMap
