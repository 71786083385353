import * as React from 'react'
import { IonRow, IonCol } from '@ionic/react'
import { SettingField } from '../../settings/StyledContainers'
import { ControlMeasure } from '../types/RiskAssessments'

/**
 *  =================================
 *     Page 2: EXISTING MEASURES
 *  =================================
 */

const ControlMeasures : React.FC<{ measures: ControlMeasure[], controlMeasures: ControlMeasure[], title: string }> = ({ measures, controlMeasures, title }) => {
  /* Generate rows of three control measure categories */
  const getRows = () : string[][] => {
    const uniqueCategories = controlMeasures.map(({ category }) => category).filter((val, pos, arr) => arr.indexOf(val) === pos)
    return uniqueCategories.reduce((acc, curr, i) => {
      if (!(i % 3)) { // if index is 0 or can be divided by the `size`...
        acc.push(uniqueCategories.slice(i, i + 3)) // ..push a chunk of the original array to the accumulator
      }
      return acc
    }, [])
  }

  return (
    <>
      <SettingField style={{ width: 'auto' }}><h5 className='blue'>{ title } Control Measures</h5></SettingField>
      {
      getRows().map(([catA, catB, catC]) => (
        <IonRow key={catA} className='ion-jusitfy-content-between'>
          <IonCol style={{ padding: '10px' }} size='4'>
            <SettingField style={{ width: 'auto' }}>
              <h6 className='blue'>{ catA }</h6>
              {
                controlMeasures && measures.filter((measure) => measure.category === catA).map((measure) => (
                  <p key={measure.id} className='overview-control-measure'>- { measure.control_measure }</p>
                ))
              }
              {
                controlMeasures && measures.filter((measure) => measure.category === catA).length === 0 && (
                <p>No Measures selected</p>
                )
              }
            </SettingField>
          </IonCol>
          {
            catB && (
              <IonCol style={{ padding: '10px' }} size='4'>
                <SettingField style={{ width: 'auto' }}>
                  <h6 className='blue'> { catB } </h6>
                  {
                    controlMeasures && measures.filter((measure) => measure.category === catB).map((measure) => (
                      <p key={measure.id} className='overview-control-measure'>- { measure.control_measure }</p>
                    ))
                  }
                  {
                    controlMeasures && measures.filter((measure) => measure.category === catB).length === 0 && (
                    <p>No Measures selected</p>
                    )
                  }
                </SettingField>
              </IonCol>
            )
          }
          {
            catC && (
              <IonCol style={{ padding: '10px' }} size='4'>
                <SettingField style={{ width: 'auto' }}>
                  <h6 className='blue'>{ catC }</h6>
                  {
                    controlMeasures && measures.filter((measure) => measure.category === catC).map((measure) => (
                      <p key={measure.id} className='overview-control-measure'>- { measure.control_measure }</p>
                    ))
                  }
                  {
                    controlMeasures && measures.filter((measure) => measure.category === catC).length === 0 && (
                    <p>No Measures selected</p>
                    )
                  }
                </SettingField>
              </IonCol>
            )
          }
        </IonRow>
      ))
    }
    </>
  )
}

export default ControlMeasures
