import * as React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'

import { IonPage, IonContent, IonGrid } from '@ionic/react'
import PageHeader from '../components/PageHeader'
import MobileHeader from '../components/mobile/MobileHeader'
import AppWrapper from '../components/insights/AppWrapper'
import { ProvideInsightsData } from '../hooks/insights/useInsightsData'
import { ProvideSituationReport } from '../hooks/insights/useSituationReport'

const InsightsApp = () => (
  <ProvideInsightsData>
    <ProvideSituationReport>
      <IonPage>
        <PageHeader className='desktop-app-header' title='Insights' />
        <MobileHeader className='mobile-app-header-insights' />
        <IonContent>
          <IonGrid
            className='mobile-padding risk-assessment-list'
            style={{
              height: '100%',
            }}
          >
            <AppWrapper />
          </IonGrid>
        </IonContent>
      </IonPage>
    </ProvideSituationReport>
  </ProvideInsightsData>
)

export default InsightsApp
