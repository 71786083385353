import React, { useEffect, useState } from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol, IonContent, IonPage, IonItem, IonInput,
} from '@ionic/react'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { OverlayContainer } from '../GlobalContainers'
import PageHeader from '../PageHeader'
import { SettingsContainer } from '../settings/StyledContainers'

import useApi from '../../hooks/testHooks'

import { useReportsData } from '../../hooks/reports/useReportsData'

import IncidentPopupHandler from './IncidentPopupHandler'

import { InternalIncident } from '../types/GlobalTypes'
import { useWorkspace } from '../../hooks/useWorkspace'

const Incidents : React.FC<{ onReturn: () => void }> = ({
    onReturn,
}) => {
  const {
    setShowFieldSitrep,
    showHome,
    setShowHome,
    setName,
    setCounty,
    setState,
    setInsight,
    setFromUrl,
    fromUrl,
    setShowInternalIncidents,
    setShowInternalIncident,
    setInternalIncident,
    showInternalIncident,
    internalIncidents,
    setData,
  } = useReportsData()

  const { workspace } = useWorkspace()

  const apiHook = useApi()

  function handleIncidentClick(data: InternalIncident) {
    setInternalIncident(data)
    const req = apiHook.fetchInternalIncident({ domain_id: workspace.id, incident_id: data.id }).then((result) => {
      setData(result)
      setShowInternalIncident(true)
      setInsightUrl(0)
      console.log(result)
    })
  }

  function handleBack() {
    setShowInternalIncidents(false)
    setShowInternalIncident(false)
    setShowHome(true)
    setFromUrl(false)
  }

  const [insightUrl, setInsightUrl] = useState(0)

  if (insightUrl > 0 && fromUrl === true) {
    const data = insights.find((e) => e.id === insightUrl)
    if (data) {
      console.log(data)
      setFromUrl(false)
      handleFieldClick(data)
    }
  }

  useEffect(() => {
    // Run! Like go get some data from an API.
    const queryParams = new URLSearchParams(window.location.search);
    const insightId = Number(queryParams.get('insight'))
    setInsightUrl(insightId)
    setShowInternalIncident(false)
  }, [])

  return (
    <>
      <IonPage>
        <PageHeader title="Reports" />
        <IonContent style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
          <SettingsContainer>
            <IonRow class="ion-justify-content-center" style={{ height: '100%', backgroundColor: '#FFFFFF', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)', width: '100%' }}>
              <IonGrid class="ion-justify-content-center" style={{ marginLeft: '4em' }}>
                <IonGrid class="ion-justify-content-center">
                  <IonRow class="ion-align-items-center" style={{ width: '95%', height: '15%', backgroundColor: '#FFFFFF', border: '1px', borderStyle: 'none none solid none' }}>
                    <IonCol class='ion-justify-content-start' style={{width: '70%'}}>
                      <IonButton onClick={handleBack} fill="clear">
                        <Icon icon="ep:arrow-left" style={{fontSize: '1.8em', color: 'grey'}}/>
                      </IonButton>
                      <IonButton fill="clear">
                        <Icon icon="ep:arrow-right" style={{fontSize: '1.8em', color: 'grey'}}/>
                      </IonButton>
                      <IonLabel style={{ marginLeft: '1em', marginRight: '1em', paddingTop: '2em', fontSize: '0.9em', color: 'black' }}>Incidents</IonLabel>
                      <Icon icon="ant-design:folder-open-filled" style={{fontSize: '1.8em', color: '#326771', marginTop: '0.3em'}}/>
                    </IonCol>
                    <IonCol class='ion-justify-content-end'>
                      <IonRow class="ion-align-items-center">
                        <IonCol class='ion-justify-content-start'>
                          <IonInput class="ion-justify-content-start" placeholder="Search" style={{ height: '2em', border: '1px solid', borderRadius: '2.5px' }}></IonInput>
                        </IonCol>
                        <IonCol class='ion-justify-content-end'>
                          <IonButton class="ion-justify-content-end" fill="clear" size="large" style={{ color: 'grey' }}>
                            <Icon icon="ph:magnifying-glass-thin" />
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow class="ion-align-items-center">
                    <IonCol class="ion-align-items-center">
                      <IonLabel style={{color: 'black'}}>Name</IonLabel>
                      <IonButton size="small" fill="clear" style={{color: '#326771', marginRight: '2em', paddingBottom: '0.9em'}}>
                        <Icon icon="bxs:down-arrow"></Icon>
                      </IonButton>
                      <IonLabel style={{color: 'black'}}>Last modified</IonLabel>
                      <IonButton size="small" fill="clear" style={{color: '#326771', paddingBottom: '0.9em'}}><Icon icon="bxs:down-arrow"></Icon></IonButton>
                      <IonButton class="ion-align-items-center" fill='clear' style={{marginBottom: '1em', paddingBottom: '0.4em', marginLeft: '2em', textTransform: 'none', letterSpacing: '-0.02em'}}>
                        <Icon icon="bi:list-ul" style={{fontSize: '1.8em', color: '#326771', marginTop: '0.3em'}}/>
                        <IonLabel style={{color: 'black', paddingTop: '0.4em', paddingLeft: '0.4em', fontWeight: 'normal', fontSize: '1.1em'}}>List view</IonLabel>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonContent style={{height: '67%', '--ion-background-color':'#FFFFFF'}}>
                  <IonGrid>
                    <IonRow style={{marginTop: '1em'}}>
                      <IonCol>
                        {
                          internalIncidents.map(
                            (incdnt) => (
                              <IonButton onClick={() => handleIncidentClick(incdnt)} fill="clear" style={{border: '1px solid', borderColor: '#747474', borderRadius: '0.2em', backgroundColor: '#F9F9F9', width: '10em', height: '10em', textTransform: 'none', letterSpacing: '-0.02em', marginRight: '1.9em' }}>
                                <IonGrid class="ion-align-items-center">
                                  <IonRow>
                                    <IonCol>
                                      <Icon icon="akar-icons:file" style={{fontSize: '5em', color: '#326771', marginBottom: '0.1em' }} />
                                    </IonCol>
                                  </IonRow>
                                  <IonRow>
                                    <IonCol>
                                      <IonLabel style={{color: 'black', fontSize: '1.1em', fontWeight: 'normal', margin: '0.2em 0em' }}>{moment(incdnt.updated_at).format('DDMMYY')  + "_" + incdnt.title }</IonLabel>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </IonButton>
                            ),
                          )
                        }
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonContent>
              </IonGrid>
            </IonRow>
          </SettingsContainer>
        </IonContent>
        {
        showInternalIncident && (
          <IncidentPopupHandler />
        )
        }
      </IonPage>
    </>
  )
}

export default Incidents
