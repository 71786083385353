import React from 'react'
import { Link } from 'react-router-dom'
import { WidgetContainer, WidgetElement } from './StyleContainers'

/**
 *  ==============================
 *      Small Incident Widget
 *  ==============================
 */
const IncidentWidget = ({ incidents }) => (
  <WidgetContainer>
    <h4 style={{ color: 'white' }}>Latest Reports</h4>
    {
      incidents.map((incident) => (
        <WidgetElement key={incident.id}>
          <p>{ incident.incident_type }  { incident.reported.split('T')[0] }</p>
          <Link to={`insights?incident=${incident.id}`}>View</Link>
        </WidgetElement>
      ))
    }
  </WidgetContainer>
)

export default IncidentWidget
