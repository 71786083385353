import { useIonAlert } from '@ionic/react'
import { Feature, Polygon, Position } from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import React, {
  FC, useEffect, useState, useRef,
} from 'react'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { communitiesToGeoJSON } from '../../../hooks/terrain-mapping/helpers/Utils'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { createMB } from '../../maps/apps/AppMap'
import { AreaDetails, DrawShapeView, FlowView } from '../../maps/features/CommunityArea'
import axios from '../../../utils/axios'

const CommunityEdit : FC = () => {
  const [currentView, setCurrentView] = useState<FlowView>(FlowView.DRAW_SHAPE)
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([])
  const [coordinates, setCoordinates] = useState<Position[]>([])
  const [layerDrawn, setLayerDrawn] = useState<boolean>(true)
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()
  const {
    focusedElems, dispatchFocused, setEditing, mapRef, setLoading,
    communities, stakeholders, updateCommunity, setSubmittedMessage,
  } = useTerrainMapping()
  const { focusedCommunity } = focusedElems

  const handleCancel = () => {
    if (markers.length > 0) { markers.map((val) => val.remove()) }
    const geoJSONBoxes = communitiesToGeoJSON(communities);
    (mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource).setData(geoJSONBoxes)

    dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
    setEditing(false)
  }

  const updateLayer = (positions: Position[]) => {
    /* Update layer */
    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: (layerDrawn) ? 'Polygon' : 'LineString',
        coordinates: ((layerDrawn) ? [positions] : positions as any),
      },
    })
  }

  const handleSubmit = (
    name: string, description: string, selected: { value: number, label: string }[],
    history: string, facts: string, socialProfile: string, econProfile: string, landTenure: string,
    ldr: string,
    type: string,
    selectedParent: { value: number, label: string },
    selectedGov: { value: number, label: string }[],
    selectedCommHead: { value: number, label: string },
  ) => {
    const newFeature : Feature<Polygon> = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
      properties: {},
    }
    const filteredStakeholders = stakeholders.filter(({ id }) => selected.find(({ value }) => id === value))
    const oldStakeholders = stakeholders.filter(({ areas }) => areas.find(({ id }) => id === focusedCommunity.id))

    const stakeholdersToDelete = oldStakeholders.filter(({ id }) => !selected.find(({ value }) => value === id))
    const stakeholdersToAdd = selected.filter(({ value }) => !oldStakeholders.find(({ id }) => id === value))

    const selectedGovIds = selectedGov.map((gov) => gov.value)
    const oldGovernors = focusedCommunity.governors.map((gov) => gov.governor_id)
    const filteredGovernors = stakeholders.filter(({ id }) => selectedGov.find(({ value }) => id === value))
    const governorsToAdd = selectedGov.filter((gov) => !oldGovernors.includes(gov.value)).map((gov) => gov.value)
    const currentGovernorIds = focusedCommunity.governors.map((gov) => gov.governor_id)
    const governorsToDelete = currentGovernorIds.filter((id) => !selectedGovIds.includes(id))

    setLoading(true)
    axios.put('api/v2/community/edit_community', {
      domain_id: workspace.id,
      community_id: focusedCommunity.id,
      geoData: newFeature,
      name,
      description,
      type,
      head: selectedCommHead?.value,
      parent: selectedParent?.value,
      remove_stakeholders: stakeholdersToDelete.map(({ id }) => id),
      add_stakeholders: stakeholdersToAdd.map(({ id }) => id),
      remove_governors: governorsToDelete,
      add_governors: governorsToAdd,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }

      updateCommunity({
        id: focusedCommunity.id, name, description, geoData: newFeature,
      }, filteredStakeholders)

      setSubmittedMessage('Community edited successfully')
    }).catch(() => {

    }).finally(() => {
      markers.map((val) => val.remove())
      setLoading(false)
      dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
      setEditing(false)
    })
  }

  const setCurrentPoint = ([lon, lat] : Position, index: number) => {
    if (!coordinates.length) return

    const positions = coordinates.slice()

    /* Change last markers position */
    markers[index].setLngLat([lon, lat])

    /* Update location */
    positions.splice(index, 1, [lon, lat])
    if (index === 0 && layerDrawn) positions.splice(coordinates.length - 1, 1, [lon, lat])

    setCoordinates(positions)
    updateLayer(positions)
  }

  /* Use reference to keep the state updated */
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  const drawLayer = () => {
    if (layerDrawn) { return }
    if (coordinates.length < 3) {
      ionAlert({
        header: 'Error',
        message: 'You need to draw a polygon with at least 3 vertices',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    const newCoordinates = [...coordinates, coordinates[0]];

    (mapRef.current.getSource('new-restriction-box') as mapboxgl.GeoJSONSource).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: newCoordinates,
      },
    })

    mapRef.current.addLayer({
      id: 'new-layer',
      type: 'fill',
      source: 'new-restriction-box',
      layout: {},
      paint: {
        'fill-color': 'rgb(65, 151, 169)', // blue color fill
        'fill-opacity': 0.5,
      },
    })

    setCoordinates(newCoordinates)
    setLayerDrawn(true)
  }

  /* Use of reference to keep the state updated */
  const drawLayerRef = useRef(drawLayer)
  drawLayerRef.current = drawLayer

  const startEdit = () => {
    const points = focusedCommunity.geoData.geometry.coordinates[0]
    createMB(mapRef, points)
    mapRef.current.addLayer({
      id: 'new-layer',
      type: 'fill',
      source: 'new-restriction-box',
      layout: {},
      paint: {
        'fill-color': 'rgb(65, 151, 169)', // blue color fill
        'fill-opacity': 0.5,
      },
    })

    const filteredBox = communities.filter(({ id }) => id !== focusedCommunity.id)
    const geoJSONBoxes = communitiesToGeoJSON(filteredBox);

    (mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource).setData(geoJSONBoxes)

    const tempMarkers = []
    points.forEach((lngLat, index) => {
      if (index + 1 === points.length) { return }
      const marker = (tempMarkers.length === 0) ? new mapboxgl.Marker({ draggable: true, color: 'rgb(109, 0, 235)' }) : new mapboxgl.Marker({ draggable: true })
      marker.setLngLat((lngLat as [number, number])).addTo(mapRef.current)

      marker.setDraggable(true)
      marker.on('drag', () => {
        setCurrentPointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], index)
      })

      if (tempMarkers.length === 0) {
        marker.getElement().onclick = (event) => {
          event.stopPropagation()
          drawLayerRef.current()
        }
      }

      tempMarkers.push(marker)
    })
    setMarkers(tempMarkers)
    setCoordinates(points)
  }

  useEffect(() => {
    startEdit()

    return () => {
      mapRef.current.removeLayer('new-restriction-box')
      if (mapRef.current.getLayer('new-layer')) mapRef.current.removeLayer('new-layer')
      mapRef.current.removeSource('new-restriction-box')
    }
  }, [])

  if (currentView === FlowView.DRAW_SHAPE) {
    return (
      <DrawShapeView
        markers={markers}
        setMarkers={setMarkers}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        onCancel={handleCancel}
        onNext={() => setCurrentView(FlowView.AREA_DETAILS)}
        layerDrawn={layerDrawn}
        setLayerDrawn={setLayerDrawn}
        editing
      />
    )
  }

  return (
    <AreaDetails
      onSubmit={handleSubmit}
      onBack={handleCancel}
      defaultDescription={focusedCommunity.description}
      defaultName={focusedCommunity.name}
      defaultStakeholders={stakeholders.filter(({ areas }) => areas.find(({ id }) => id === focusedCommunity.id)).map(({ id, name }) => ({ value: id, label: name }))}
      defaultComm={focusedCommunity}
    />
  )
}

export default CommunityEdit
