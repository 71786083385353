import {
  IonContent, IonGrid, IonPage, IonRow,
} from '@ionic/react'
import React, { FC, useState } from 'react'
import LoadingModal from '../components/modals/LoadingModal'
import VerifyPhoneNumber from '../components/session/VerifyPhoneNumber'

const VerifySMS: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <LoadingModal isOpen={loading} />
          <IonRow style={{ height: '100%' }} className='ion-align-items-center ion-justify-content-center'>
            <VerifyPhoneNumber loading={loading} setLoading={setLoading} />
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default VerifySMS
