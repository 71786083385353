import React from 'react'
import {
  IonContent, IonListHeader, IonItem, IonInput,
  IonRow, IonButton,
} from '@ionic/react'

import { FormTitle } from '../../../maps/StyledContainers'

/**
 *  ======================================
 *        ACCOMMODATION DETAILS
 *  ======================================
 *   (Optional)
 */
const Accommodation = ({
  onNext, onBack, handleOptionalInput, optionalInput,
}) => (
  <IonContent
    style={{
      '--ion-background-color': 'white',
      '--padding-bottom': '15px',
      '--padding-top': '15px',
      '--padding-end': '15px',
    }}
  >
    <IonListHeader>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Driver Accommodation</h3>
      </FormTitle>
    </IonListHeader>
    <IonItem>
      <IonInput onIonChange={handleOptionalInput} value={optionalInput.driverAccommodation} name='driverAccommodation' type='text' placeholder='Accomodation Name' />
    </IonItem>
    <IonItem>
      <IonInput onIonChange={handleOptionalInput} value={optionalInput.driverAccommodationLocation} name='driverAccommodationLocation' type='text' placeholder='Accommodation address' />
    </IonItem>
    <IonItem>
      <IonInput onIonChange={handleOptionalInput} value={optionalInput.driverAccommodationPhone} name='driverAccommodationPhone' type='text' placeholder='Phone' />
    </IonItem>
    <IonListHeader>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Passengers Accommodation</h3>
      </FormTitle>
    </IonListHeader>
    <IonItem>
      <IonInput onIonChange={handleOptionalInput} value={optionalInput.passengerAccommodation} name='passengerAccommodation' type='text' placeholder='Accomodation Name' />
    </IonItem>
    <IonItem>
      <IonInput
        onIonChange={handleOptionalInput}
        value={optionalInput.passengerAccommodationLocation}
        name='passengerAccommodationLocation'
        type='text'
        placeholder='Accommodation address'
      />
    </IonItem>
    <IonItem>
      <IonInput onIonChange={handleOptionalInput} value={optionalInput.passengerAccommodationPhone} name='passengerAccommodationPhone' type='text' placeholder='Phone' />
    </IonItem>
    <IonRow style={{ paddingLeft: '15px', marginTop: '15px' }} className='ion-justify-content-between'>
      <IonButton style={{ '--background': '#4197A9' }} onClick={onBack}>Back</IonButton>
      <IonButton style={{ '--background': '#326771' }} onClick={onNext}>Next</IonButton>
    </IonRow>
  </IonContent>
)

export default Accommodation
