/* eslint-disable camelcase */
import {
  IonCol, IonContent, IonDatetime, IonInput, IonRow, IonCheckbox,
} from '@ionic/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Icon } from '@iconify/react'
import { WidgetTitles } from '../maps/WidgetContainers'

import { ListContainer } from './StyledContainers'
import { SelectIncidentType } from '../maps/StyledContainers'
import { useWorkspace } from '../../hooks/useWorkspace'
import { incidentIndicator } from '../maps/apps/AppMap'

import useApi from '../../hooks/testHooks'

/**
 *  ====================================
 *     Incidents List for Consultants
 *  ====================================
 */
const IncidentList = ({
  incidents, setIncidents, editing, incidentFilters, filterIncidents,
  mapRef, timeFilter, setShowLoading,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const [flag, setFlag] = useState(false)
  const [incidentsInput, setIncidentsInput] = useState({})
  const [incidentTypes, setIncidentTypes] = useState([])
  const [sortedBy, setSortedBy] = useState({ field: 'username', ascending: true })

  const apiHook = useApi()
  const { workspace } = useWorkspace()

  const editIncident = (e, id) => {
    /* Set the incident to edited so it's send to the server */
    setIncidentsInput({ ...incidentsInput, [id]: { ...incidentsInput[id], edited: true, [e.target.name]: e.detail.value } })
  }

  const deleteIncident = (e, id) => {
    setIncidentsInput({ ...incidentsInput, [id]: { ...incidentsInput[id], delete: e.target.checked } })
  }

  /* Set up useState hooks for every incident */
  useEffect(() => {
    /* Fecth incident types */
    axios.get('/api/v1/types')
      .then((response) => {
        setIncidentTypes(response.data.types)
      })
  }, [])

  const createInputs = (filteredIncidents) => {
    const hashMap = {}
    filteredIncidents.forEach(({
      // eslint-disable-next-line camelcase
      id, incident_type, description, fatalities, reported, user, regionName, countyName,
    }) => {
      hashMap[id] = {
        incident_type,
        description,
        fatalities,
        reported,
        edited: false,
        delete: false,
        regionName,
        countyName,
        user,
      }
    })

    return hashMap
  }

  /* Sort counties by field and ascending or descending order */
  const sortIncidents = (incidentA, incidentB) => {
    if (sortedBy.ascending) {
      if (incidentsInput[incidentA][sortedBy.field] >= incidentsInput[incidentB][sortedBy.field]) {
        return 1
      }
      return -1
    }

    if (incidentsInput[incidentB][sortedBy.field] >= incidentsInput[incidentA][sortedBy.field]) {
      return 1
    }
    return -1
  }

  /** Sorting UX and functionality */
  const getArrow = () => ((sortedBy.ascending) ? (
    <Icon icon='fluent:arrow-sort-down-16-filled' />
  ) : (
    <Icon icon='fluent:arrow-sort-up-16-filled' />
  ))

  const handleTitle = (fieldName) => {
    /* If this field has already been selected then revert the order */
    if (sortedBy.field === fieldName) {
      setSortedBy({ ...sortedBy, ascending: !sortedBy.ascending })
      return
    }

    setSortedBy({
      field: fieldName,
      ascending: true,
    })
  }

  useEffect(() => {
    const filteredIncidents = filterIncidents(incidentFilters)
    setIncidentsInput(createInputs(filteredIncidents))
  }, [incidentFilters, timeFilter])

  useEffect(async () => {
    /* When finished editing update incidents  */
    if (!editing && flag) {
      const copy = incidents.slice()
      const request = []
      const deleteIncidents = []

      Object.keys(incidentsInput).forEach((id) => {
        if (incidentsInput[id].delete) deleteIncidents.push(Number(id))
        if (!incidentsInput[id].edited) return

        const index = copy.findIndex((incident) => incident.id === Number(id))
        const old = { ...copy[index] }

        const updated = { ...old, ...incidentsInput[id], updated_at: moment().format() }
        copy[index] = updated

        request.push(updated)
      })

      setShowLoading(true)
      if (request.length) {
        await apiHook.editIncident({
          domain_id: workspace.id,
          incidents: request,
        })
      }

      if (deleteIncidents.length) {
        await apiHook.deleteIncident({
          domain_id: workspace.id,
          incidents: deleteIncidents,
        })
      }
      setShowLoading(false)

      /* Remove deleted incidents  */
      deleteIncidents.forEach((incident_id) => {
        const index = copy.findIndex(({ id }) => id === incident_id)
        copy.splice(index, 1)
      })

      /* Update incidents list */
      setIncidents(copy)

      /* Update map data */
      const filtered = filterIncidents(incidentFilters, copy)
      setIncidentsInput(createInputs(filtered))

      /* Update source with new set of filtered incidents */
      mapRef.current.getSource('incidents').setData({
        type: 'FeatureCollection',
        features: filtered.map((val) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              val.longitude, val.latitude,
            ],
          },
          properties: {
            incident_type: val.incident_type,
            fatalities: val.fatalities,
            date: val.reported || val.date,
            description: val.description,
            popupdata: incidentIndicator(val),
            id: val.id,
          },
        })),
      })
    }
    setFlag(true)
  }, [editing])

  return (
    <ListContainer>
      <WidgetTitles style={{ height: '10%', paddingRight: '0' }}>
        <IonRow style={{ height: '100%' }}>
          <IonCol size={1} />
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('username')} size={1}>
            Submitted By
            {
              sortedBy.field === 'username' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('reported')} size={1}>
            Date
            {
              sortedBy.field === 'reported' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('regionName')} size={2}>
            Region
            {
              sortedBy.field === 'regionName' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('countyName')} size={2}>
            County
            {
              sortedBy.field === 'countyName' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('incident_type')} size={1}>
            Type
            {
              sortedBy.field === 'incident_type' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('fatalities')} size={1}>
            Fatalities
            {
              sortedBy.field === 'fatalities' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('description')} size={2}>
            Description
            {
              sortedBy.field === 'description' && getArrow()
            }
          </IonCol>
          <IonCol style={{ cursor: 'pointer' }} onClick={() => handleTitle('updated_at')} size={1}>
            Modified
            {
              sortedBy.field === 'updated_at' && getArrow()
            }
          </IonCol>
        </IonRow>
      </WidgetTitles>
      <div style={{ height: '95%' }}>
        <IonContent>
          {
            Object.keys(incidentsInput).sort(sortIncidents).map((incidentId, index) => (
              <IonRow style={{ backgroundColor: getColor(index), height: '10%' }} className='ion-align-items-center widget-row incident-row' key={incidentId}>
                <IonCol className='ion-text-center' size={1}>
                  {
                    (editing) && (
                      <IonCheckbox
                        checked={incidentsInput[incidentId].delete}
                        onClick={(e) => deleteIncident(e, incidentId)}
                      />
                    )
                  }
                </IonCol>
                <IonCol className='ion-text-center' size={1}>
                  {
                    `${incidentsInput[incidentId].user.first_name} ${incidentsInput[incidentId].user.final_name}`
                  }
                </IonCol>
                <IonCol className='ion-text-center' size={1}>
                  {
                    (editing)
                      ? (
                        <IonDatetime
                          dateFormat='YYYY-MM-DD'
                          value={incidentsInput[incidentId].reported}
                          name='reported'
                          onIonChange={(e) => editIncident(e, incidentId)}
                        />
                      )
                      : moment(incidentsInput[incidentId].reported).format('DD/MM/YYYY')
                  }
                </IonCol>
                <IonCol className='ion-text-center' size={2}> { incidentsInput[incidentId].regionName } </IonCol>
                <IonCol className='ion-text-center' size={2}> { incidentsInput[incidentId].countyName } </IonCol>
                <IonCol className='ion-text-center' size={1}>
                  {
                    (editing)
                      ? (
                        <SelectIncidentType
                          style={{ border: ' 1px solid #747474' }}
                          name='incident_type'
                          value={incidentsInput[incidentId].incident_type}
                          onChange={(e) => setIncidentsInput({ ...incidentsInput, [incidentId]: { ...incidentsInput[incidentId], edited: true, [e.target.name]: e.target.value } })}
                        >
                          {
                            incidentTypes.map((incident) => (
                              <option value={incident} key={incident}>{incident}</option>
                            ))
                          }
                        </SelectIncidentType>
                      )
                      : incidentsInput[incidentId].incident_type
                  }
                </IonCol>
                <IonCol className='ion-text-center' size={1}>
                  {
                    (editing)
                      ? (
                        <IonInput
                          type='number'
                          name='fatalities'
                          value={incidentsInput[incidentId].fatalities}
                          onIonChange={(e) => editIncident(e, incidentId)}
                        />
                      )
                      : incidentsInput[incidentId].fatalities
                  }
                </IonCol>
                <IonCol size={2}>
                  {
                    (editing)
                      ? (
                        <IonInput
                          type='text'
                          name='description'
                          value={incidentsInput[incidentId].description}
                          onIonChange={(e) => editIncident(e, incidentId)}
                        />
                      )
                      : incidentsInput[incidentId].description
                  }
                </IonCol>
                <IonCol className='ion-text-center' size={1}>
                  {
                    moment(incidentsInput[incidentId].updated_at).format('DD/MM/YYYY')
                  }
                </IonCol>
              </IonRow>
            ))
          }
        </IonContent>
      </div>
    </ListContainer>
  )
}

export default IncidentList
