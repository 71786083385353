/* eslint-disable no-param-reassign */
import moment from 'moment'
import { incidentCategories } from './incident_types.json'
import { serious } from './serious_incidents.json'

const zoomThreshold = 5

/**
 *  ==================================
 *      Security Conditions Logic
 *  ==================================
 * This file will contain the business logic required to run
 * incident condition features in the insights app.
 */

/** Alert State current level */
const getCurrentLevel = (alertState) => ({
  1: {
    title: 'Green - Caution',
    color: '#000000',
    background: '#25bf23',
  },
  2: {
    title: 'Blue - Warning',
    color: '#ffffff',
    background: '#115efb',
  },
  3: {
    title: 'Yellow - Standfast',
    color: '#000000',
    background: '#fef336',
  },
  4: {
    title: 'Orange - Standby',
    color: '#ffffff',
    background: '#fd8a25',
  },
  5: {
    title: 'Red - Evacuation',
    color: '#ffffff',
    background: '#db2b17',
  },
}[alertState])

const scColor = (level) => ({
  1: '#21ed39',
  2: '#217aed',
  3: '#f5f531',
  4: '#f58331',
  5: '#f53731',
}[level])

const getLevelTitle = (scLevel) => ({
  1: 'Low Condition',
  2: 'Guarded Condition',
  3: 'Elevated Condition',
  4: 'High Condition',
  5: 'Severe Condition',
}[scLevel])

const getLevelDescription = (scLevel) => ({
  1: 'Serious security incidents occurring less than once a year.',
  2: 'Serious security incidents occurring more than once a year.',
  3: 'Serious security incidents occurring more than once a month.',
  4: 'Serious security incidents occurring more than once a week.',
  5: 'Serious security incidents occurring on a daily basis.',
}[scLevel])

/**
 *  Produces the HTML that will be displayed by the map popup
 *
 * @param {Number} scLevel
 * @param {String} county
 * @returns Popup HTML code.
 */
const getIndicator = (scLevel, county) => {
  /* get UX colors -> same as alert states */
  const { color, background } = getCurrentLevel(scLevel)
  const title = getLevelTitle(scLevel)
  const description = getLevelDescription(scLevel)

  return (
    `<p class="heading">${county}</p>
    <p class="heading"><span class="alert-state-display" style="color: ${color}; background-color: ${background};">${title}</span></p>
    <p> ${description} </p>
  `
  )
}

/**
 * Get security contion for a county in a given point in time
 *
 * @param {Array} incidents -> Filtered incidents for a county in a given point in time
 */
const getSecurityCondition = (incidents, time) => {
  /* Only loop once through the incidents -> Note: It could be better to use array.filter but that would require
    to loop through the array several times.
  */
  const { last10Days, last30Days, last365Days } = incidents.reduce((acc, incident) => {
    const today = moment(time).format()

    /* Incidents that happened in the last 10 days */
    if (moment(incident.reported).isSameOrAfter(moment(time).subtract(10, 'days').format()) && moment(incident.reported).isSameOrBefore(today)) {
      acc.last10Days += 1
    }

    /* Incidents that happened in the last 30 days */
    if (moment(incident.reported).isSameOrAfter(moment(time).subtract(30, 'days').format()) && moment(incident.reported).isSameOrBefore(today)) {
      acc.last30Days += 1
    }

    /* Incidents that happened in the last 365 days */
    if (moment(incident.reported).isSameOrAfter(moment(time).subtract(1, 'year').format()) && moment(incident.reported).isSameOrBefore(today)) {
      acc.last365Days += 1
    }
    return acc
  }, { last10Days: 0, last30Days: 0, last365Days: 0 })

  /* Start from highest level down */
  if (last10Days > 8) {
    return 5
  }

  if (last30Days > 4) {
    return 4
  }

  if (last30Days > 1) {
    return 3
  }

  if (last365Days > 1) {
    return 2
  }

  return 1
}

const scSingleCounty = (incidents, filters, time = moment().format('YYYY/MM/DD HH:mm')) => {
  const selectedFilters = Object.entries(filters).reduce((memo, [k, v]) => (v ? [...memo, k] : memo), [])
  const filterdIncidentCategories = Object.entries(incidentCategories).reduce((m, [k, v]) => (selectedFilters.includes(k) ? [...m, ...v] : m), [])
  const filteredIncidents = incidents.filter((i) => (filterdIncidentCategories.includes(i.incident_type)))

  return getSecurityCondition(filteredIncidents, time)
}

/**
 * Loop through every county in GeoJSON data source and attach a new value to the
 * Security Condition depending on the moment in time and the incidents.
 *
 * @param {Object} counties -> GeoJSON data source for counties
 * @param {Object} incidents -> List of all incidents
 * @param {Object} filters -> Incident filters for Security Conditions
 * @param {String} time -> Point in time to calculate Security Conditions
 */
const colorCodeCounties = (counties, incidents, filters, time) => {
  const filteredIncidents = {}

  Object.keys(incidents).forEach((county) => {
    const filteredSerious = (incidents[county]) ? incidents[county].filter(({ incident_type }) => serious.indexOf(incident_type) >= 0) : []
    filteredIncidents[county] = filteredSerious
  })

  counties.features.forEach((feature) => {
    if (filteredIncidents[String(feature.properties.id)]) {
      const sc = getSecurityCondition(filteredIncidents[String(feature.properties.id)], time)
      feature.properties.sc_level = sc
    } else {
      feature.properties.sc_level = 1
    }
  })

  return counties
}

/**
 * This is a slightly simpler implementation of
 * Security conditions for mobile devices.
 *
 * @param {Array} asCounties -> Raw counties from database
 * @param {Object} incidents -> Incidents By county
 */
const getScMobile = (asCounties, incidents) => {
  const time = moment().format()
  return asCounties.map((county) => {
    const sc = (incidents[county.id]) ? getSecurityCondition(incidents[county.id], time) : 1
    return { ...county, securityCondition: sc }
  })
}

/**
 * Transforms array of incidents into a fictionary
 *
 * @param {Array} incidents -> Array of raw incidents from database
 * @returns A dictionary version of the array of incidents using counties as keys.
 */
const getIncidentsDictionary = (incidents) => {
  /* Empty dictionary that will be populated using counties as keys */
  const dict = {}

  incidents.forEach((incident) => {
    if (incident.county_id) {
      if (dict[incident.county_id]) {
        dict[incident.county_id].push(incident)
      } else {
        dict[incident.county_id] = [incident]
      }
    }
  })

  return dict
}

/**
 *  Similar to updateCountyColors but for Security Conditions instead of Alert States
 *
 * @param {Object} mapRef -> Mapbox map reference
 * @param {function} update -> setState to update counties
 * @param {Object} counties -> GeoJSON data source for counties
 * @param {Object} filters -> Incident filters for Security Conditions
 * @param {Array} incidents -> List of all incidents
 * @param {Date} time -> Point in time to calculate Security Conditions
 */
const updateSecurityColors = (mapRef, update, counties, filters, incidents, time, regions, updateRegions) => {
  const colouredCounties = colorCodeCounties(counties, incidents, filters, time)
  update(colouredCounties)
  mapRef.current.getSource('counties').setData(colouredCounties)

  if (regions) {
    const regionsSC = {}
    colouredCounties.features.forEach(({ properties }) => {
      if (regionsSC[properties.ADM1_EN]) {
        regionsSC[properties.ADM1_EN].push(properties.sc_level)
      } else {
        regionsSC[properties.ADM1_EN] = [properties.sc_level]
      }
    })

    regions.features.forEach((region) => {
      const avgSC = (regionsSC[region.properties.shapeName]) ? Math.ceil(regionsSC[region.properties.shapeName].reduce((acc, AS) => acc + AS, 0) / regionsSC[region.properties.shapeName].length) : 1
      region.properties.security_condition = scColor(avgSC)
      region.properties.sc_level = avgSC
      region.properties.scDescription = getIndicator(1, region.properties.shapeName)
    })
    updateRegions(regions)
    mapRef.current.getSource('regions').setData(regions)

    if (!mapRef.current.getLayer('security-conditions-regions')) {
      mapRef.current.addLayer({
        id: 'security-conditions-regions',
        source: 'regions',
        type: 'fill',
        maxzoom: zoomThreshold,
        layout: {},
        paint: {
          'fill-color': ['get', 'security_condition'],
          'fill-opacity': 1,
        },
      }, 'region-limits')
    }
  }

  if (mapRef.current.getLayer('security-conditions')) {
    return
  }
  mapRef.current.addLayer({
    id: 'security-conditions',
    source: 'counties',
    type: 'fill',
    layout: {},
    minzoom: zoomThreshold,
    paint: {
      'fill-color': ['get', 'security_condition'],
      'fill-opacity': 1,
    },
  }, 'county-limits')
}

export {
  getSecurityCondition,
  colorCodeCounties,
  updateSecurityColors,
  scColor,
  getIncidentsDictionary,
  getLevelDescription,
  getLevelTitle,
  getScMobile,
  scSingleCounty,
}
