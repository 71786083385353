/* eslint-disable react/no-array-index-key */
import React, { useState, FC, useEffect } from 'react'
import { IonRow, IonCol, IonButton } from '@ionic/react'
import Select from 'react-select'
import { Icon } from '@iconify/react'
import { useIncidentsData } from '../../../../hooks/incident-management/useIncidentData'
import { OverlayContainer } from '../../../tasks/StyledContainers'
import { ColumnContainer, MeasureContainer } from '../../../riskregister/StyledContainers'
import { SettingField } from '../../../settings/StyledContainers'
import axios from '../../../../utils/axios'
import {
  SOP, SOPLevel, TemplateType,
} from '../../../types/GlobalTypes'
import { useWorkspace } from '../../../../hooks/useWorkspace'
import { BackendSOP } from '../../../types/BackendTypes'
import { categoryValuesMapping } from '../IncidentForm'

interface Props {
  onClose: () => void
}

/**
 *  ==============================
 *       DRAG and DROP SOPs
 *  ==============================
 * Popup to add new SOPs
 */
const AddSOP : FC<Props> = ({ onClose }) => {
  /* Get current SOPs from hook */
  const {
    sopList, SOPTypes, pushNewSOPs, setLoading,
  } = useIncidentsData()
  const [sopOptions, setSopOptions] = useState<{ name: string, category: string }[]>([])
  const [selections, setSelections] = useState<string[]>([])
  const { workspace } = useWorkspace()
  const [selectedCategory, setSelectedCategory] = useState<{ label: string, value: string }>({ label: 'Select Category', value: 'Select Category' })
  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  const handleDropRemove = (e) => {
    /* Get measure name */
    const id = e.dataTransfer.getData('id')
    const copy = selections.slice()
    const index = copy.indexOf(id)
    copy.splice(index, 1)
    setSelections(copy)
  }

  const handleDropAdd = (e) => {
    /* Get measure name */
    const id = e.dataTransfer.getData('id')
    const copy = selections.slice()
    copy.push(id)
    setSelections(copy)
  }

  const submitSOPs = () => {
    if (selections.length === 0) {
      onClose()
      return
    }
    setLoading(true)
    /* Work out which types have been added or removed */
    axios.post('/api/v1/internal_incident/createSops', {
      domain_id: workspace.id,
      sop_types: selections.map((type) => type),
    }).then(({ data }) => {
      const newSops: BackendSOP[] = data.new_sops
      const processedSOPs : SOP[] = newSops.map(({
        id, sop_type, template_sop_id, archangel_template_exists,
      }) => ({
        template_type: TemplateType.DEFAULT,
        template_id: template_sop_id,
        hasDomainTemplate: false,
        hasArchangelTemplate: archangel_template_exists,
        level: SOPLevel.NCI,
        type: sop_type,
        id,
      }))
      console.log(newSops)
      onClose()
      pushNewSOPs(processedSOPs)
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    /* Filter out SOPs that ave already been created */
    const existingTypes = sopList.map(({ type }) => type)
    const options = Object.keys(categoryValuesMapping).reduce((list, category) => {
      const formatted = categoryValuesMapping[category].filter((type) => existingTypes.indexOf(type) < 0).map((name) => ({ name, category }))
      return [...list, ...formatted]
    }, [])
    console.log(options)
    setSopOptions(options)
  }, [])

  return (
    <OverlayContainer style={{ width: '650px', zIndex: 20 }}>
      <h4 style={{ marginBottom: '15px' }} className='red-font'>Add SOP</h4>
      <p>Drag and drop the SOPs you wish to add by moving them from the left column to the right. You can add multiple SOPs at once or return and add further SOPs at a later time. </p>
      <div style={{ width: '200px' }}>
        <Select
          placeholder='Select Category'
          name='existingCategory'
          id='existing-control-measure-categories'
          className='select-container'
          value={selectedCategory}
                      // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          options={Object.keys(categoryValuesMapping).map((level) => ({ value: level, label: level }))}
          onChange={(selected) => setSelectedCategory(selected)}
        />
      </div>
      <IonRow className='ion-align-items-center'>
        <IonCol size='5'>
          <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropRemove}>
            {
              selectedCategory && sopOptions.filter(({ category, name }) => category === selectedCategory.value && !selections.find((type) => type === name)).map((type, index) => (
                <MeasureContainer key={index} draggable style={{ backgroundColor: getColor(index) }} onDragStart={(e) => e.dataTransfer.setData('id', type.name)}>
                  <p> {type.name} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </IonCol>
        <IonCol style={{ textAlign: 'center', fontSize: '2.6rem' }} size='2'>
          <Icon icon='ic:round-swipe-right' />
        </IonCol>
        <IonCol size='5'>
          <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropAdd}>
            {
              selections.map((type, index) => (
                <MeasureContainer key={index} draggable style={{ backgroundColor: getColor(index) }} onDragStart={(e) => e.dataTransfer.setData('id', type)}>
                  <p> {type} </p>
                </MeasureContainer>
              ))
            }
            {
              selections.length === 0 && (
                <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                  <SettingField style={{ textAlign: 'center', width: 'auto' }}>
                    <h4 style={{ fontWeight: 'normal', margin: '20px 0' }}>Drag items here to add them to your list of SOPs.</h4>
                  </SettingField>
                </IonRow>
              )
            }
          </ColumnContainer>
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
        <IonButton style={{ '--background': '#4197A9' }} onClick={submitSOPs}>Confirm</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default AddSOP
