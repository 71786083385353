/* eslint-disable import/no-unresolved */
/* eslint-disable no-param-reassign */
import React, {
  useState, useRef, useEffect, useReducer,
} from 'react'
import {
  IonPage, IonContent, useIonAlert, IonSpinner, IonGrid,
} from '@ionic/react'
import { useHistory } from 'react-router'
import moment from 'moment'
import PageHeader from '../components/PageHeader'
import ConsultantControls from '../components/consultant/ConsultantControls'

import ConsultantMap from '../components/maps/apps/ConsultantsMap'
import { updateCountyColors, incidentIndicator, zoomThreshold } from '../components/maps/apps/AppMap'

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'

import { incidentCategories } from '../components/maps/apps/incident_types.json'

import useApi from '../hooks/testHooks'

import { ObscureBackground, CenterLoader } from '../components/GlobalContainers'
import MultiEditAS from '../components/consultant/MultiEditAS'
import { useWorkspace } from '../hooks/useWorkspace'
import { selectedCountyReducers } from '../hooks/analyst/utils/Reducers'
import SuggestedPopup from '../components/consultant/SuggestedPopup'
import { processKeyphrases } from '../hooks/analyst/utils/Utils'
import { hideCountiesRegions } from '../hooks/insights/helpers/MapUtils'
import { CenterForm } from '../components/riskregister/StyledContainers'
import FormPopup from '../components/maps/forms/FormPopup'

/**
 *  ============================
 *    Analysis/Consultant App
 *  ============================
 */
const ConsultantsApp = () => {
  /* Views and modes */
  const [view, setView] = useState('map')
  const [mode, setMode] = useState('Alert States')
  const mapRef = useRef(null)
  const [counties, setCounties] = useState([])
  const [routes, setRoutes] = useState([])
  const [restrictionBoxes, setRestrictionBoxes] = useState([])
  const [editing, setEditing] = useState(false)
  const [popup, setPopup] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState('All')
  const [selectedCountry, setSelectedCountry] = useState('All')
  const [regions, setRegions] = useState([])
  const [countryList, setCountryList] = useState([])
  const [crowdFilter, setCrowdFilter] = useState('all')
  const [suggestedFilter, setSuggestedFilter] = useState('all')
  const [countiesArr, setCountiesArr] = useState([])
  const [orderedPhrases, setOrderedPhrases] = useState([])
  const [phraseCategories, setPhraseCategories] = useState([])

  const [suggestedReports, setSuggestedReports] = useState({})
  const [selectedCounty, setSelectedCounty] = useState(null)
  const [submittedMessage, setSubmittedMessage] = useState(null)

  const history = useHistory()
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  const [showMultiAS, setShowMultiAS] = useState(false)
  const [submittedChanges, setSubmittedChanges] = useState(false)
  const [showLoading, setShowLoading] = useState(true)

  const [canChange, setCanChange] = useState(true)
  const [ionAlert] = useIonAlert()
  const [selected, dispatchSelected] = useReducer(selectedCountyReducers, { counties: [], ids: [] })

  /**
   *  =========================
   *         Show Forms
   *  =========================
   */
  const [showRouteForm, setShowRouteForm] = useState(false)
  const [showMbForm, setShowMbForm] = useState(false)
  const [showIncidentForm, setShowIncidentForm] = useState(false)

  /**
   *  =========================
   *      Alert State Filters
   *  =========================
   */
  const asFilters = {
    'AS-1': true,
    'AS-2': true,
    'AS-3': true,
    'AS-4': true,
    'AS-5': true,
  }

  const [fromMap, setFromMap] = useState(false)

  /**
   *  ============================
   *            Incidents
   *  ============================
   */
  const [incidents, setIncidents] = useState([])
  const [incidentFilters, setIncidentFilters] = useState({})
  const [incidentTimeFilter, setIncidentTimeFilter] = useState('all')
  const [focusedIncident, setFocusedIncident] = useState(null)
  const [incidentMarker, setIncidentMarker] = useState(null)

  const filterIncidents = (filters, timeFilter, newIncidents, selectedIncident) => {
    /* Incidents to show in the map */
    const showing = Object.keys(incidentFilters).filter((key) => filters[key])
    const filterer = (incident) => (showing.includes(incident.incident_type)
    && incident.id !== selectedIncident && (timeFilter === 'all' || moment(incident.reported).isAfter(moment().subtract(timeFilter, 'day'))))
    return (newIncidents) ? newIncidents.filter(filterer) : incidents.filter(filterer)
  }

  /**
   *  =======================
   *        Fusion View
   *  =======================
   */
  const [showIncidents, setShowIncidents] = useState(true)
  const [showMbs, setShowMbs] = useState(true)
  const [showRoutes, setShowRoutes] = useState(true)

  const updateIncidents = (filters, timeFilter, newIncident, selectedIncident = focusedIncident, preventRender = false) => {
    const filtered = filterIncidents(filters, timeFilter, null, (selectedIncident) ? selectedIncident.id : null)

    /* get new filters */
    setIncidentFilters(filters)
    setIncidentTimeFilter(timeFilter)

    if (!preventRender) {
      /* Update source with new set of filtered incidents */
      mapRef.current.getSource('incidents').setData({
        type: 'FeatureCollection',
        features: filtered.map((val) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              val.longitude, val.latitude,
            ],
          },
          properties: {
            incident_type: val.incident_type,
            category: Object.keys(incidentCategories).find((key) => incidentCategories[key].find((type) => type === val.incident_type)),
            fatalities: val.fatalities,
            date: val.reported || val.date,
            description: val.description,
            popupdata: incidentIndicator(val),
            id: val.id,
          },
        })),
      })
    }

    if (newIncident) {
      mapRef.current.getSource('incidents-fusion').setData({
        type: 'FeatureCollection',
        features: filtered.map((val) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              val.longitude, val.latitude,
            ],
          },
          properties: {
            incident_type: val.incident_type,
            category: Object.keys(incidentCategories).find((key) => incidentCategories[key].find((type) => type === val.incident_type)),
            fatalities: val.fatalities,
            date: val.reported || val.date,
            description: val.description,
            popupdata: incidentIndicator(val),
            id: val.id,
          },
        })),
      })
    }
  }

  const drawLayers = () => {
    if (mapRef.current.getLayer('counties-layer')) return
    /* Add colors depending on data source */
    mapRef.current.addLayer({
      id: 'counties-layer',
      source: 'counties',
      minzoom: zoomThreshold,
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': ['get', 'Alert_color'],
        'fill-opacity': 1,
      },
    }, 'county-limits')

    mapRef.current.addLayer({
      id: 'regions-layer',
      source: 'regions',
      maxzoom: zoomThreshold,
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': ['get', 'Alert_color'],
        'fill-opacity': 1,
      },
    }, 'region-limits')
  }

  const updateCountiesArr = (geojson) => {
    const visibleCounties = geojson.features.filter(({ properties }) => properties.Alert_color !== 'rgba(255,255,255, 0)').map(({ properties }) => properties)
    setCountiesArr(visibleCounties)
    setCounties(geojson)
  }

  const filterCountries = (country) => {
    updateCountyColors(asFilters, updateCountiesArr, counties, mapRef, drawLayers, regions, setRegions, country, null, crowdFilter, suggestedFilter)
    setSelectedRegion('All')
    setSelectedCountry(country)
  }

  const filterRegions = (region) => {
    updateCountyColors(asFilters, updateCountiesArr, counties, mapRef, drawLayers, regions, setRegions, selectedCountry, region, crowdFilter, suggestedFilter)
    setSelectedRegion(region)
  }

  const showFusionIncidents = (show) => {
    if (show) {
      mapRef.current.getSource('incidents-fusion').setData({
        type: 'FeatureCollection',
        features: incidents.map((val) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              val.longitude, val.latitude,
            ],
          },
          properties: {
            incident_type: val.incident_type,
            fatalities: val.fatalities,
            date: val.reported || val.date,
            description: val.description,
            popupdata: incidentIndicator(val),
            id: val.id,
          },
        })),
      })
    } else {
      mapRef.current.getSource('incidents-fusion').setData({
        type: 'FeatureCollection',
        features: [],
      })
    }
  }

  const showFusionMbs = (show) => {
    if (show) {
      mapRef.current.getSource('restriction-boxes').setData({
        type: 'FeatureCollection',
        features: restrictionBoxes,
      })
    } else {
      mapRef.current.getSource('restriction-boxes').setData({
        type: 'FeatureCollection',
        features: [],
      })
    }
  }

  const showFusionRoutes = (show) => {
    if (show) {
      mapRef.current.getSource('routes').setData({
        type: 'FeatureCollection',
        features: routes,
      })
    } else {
      mapRef.current.getSource('routes').setData({
        type: 'FeatureCollection',
        features: [],
      })
    }
  }

  /**
   *  =======================
   *      Change Modes
   *  =======================
   */
  const handleModeChange = (e) => {
    setMode(e.target.value)
    setEditing(false)

    if (popup) popup.remove()

    if (e.target.value !== 'Alert States') {
      hideCountiesRegions(counties, regions, mapRef)
    } else {
      updateCountyColors(asFilters, setCounties, counties, mapRef, drawLayers, regions, setRegions, selectedCountry, selectedRegion, crowdFilter, suggestedFilter)
    }

    if (e.target.value === 'Incidents') updateIncidents(incidentFilters, incidentTimeFilter)
    else {
      mapRef.current.getSource('incidents').setData({
        type: 'FeatureCollection',
        features: [],
      })
    }

    if (e.target.value === 'Fusion') {
      showFusionIncidents(showIncidents)
      showFusionMbs(showMbs)
      showFusionRoutes(showRoutes)
    } else {
      showFusionIncidents(false)
      showFusionMbs(false)
      showFusionRoutes(false)
    }
  }
  const popupForm = () => {
    if (showIncidentForm || showRouteForm || showMbForm) {
      ionAlert({
        header: 'New Form',
        message: "You are already filling a form, are you sure you don't want",
      })
    }

    /* Hide other forms */
    setShowIncidentForm(false)
    setShowRouteForm(false)
    setShowMbForm(false)

    mapRef.current.easeTo({
      padding: {
        right: 300,
      },
    })
  }

  const filterCrowd = (e) => {
    const selection = e.target.value
    setCrowdFilter(selection)
    updateCountyColors(asFilters, updateCountiesArr, counties, mapRef, drawLayers, regions, setRegions, selectedCountry, selectedRegion, selection, suggestedFilter)
  }
  const filterSuggested = (e) => {
    const selection = e.target.value
    setSuggestedFilter(selection)
    updateCountyColors(asFilters, updateCountiesArr, counties, mapRef, drawLayers, regions, setRegions, selectedCountry, selectedRegion, crowdFilter, selection)
  }

  const setCurrentPoint = ([lng, lat]) => {
    setFocusedIncident({ ...focusedIncident, latitude: lat, longitude: lng })
  }
  const setCurrentPointRef = useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  /* When a pin has been clicked then start edit by transforming it into a marker */
  const startIncidentEdit = (incident) => {
    /* Update incidents to remove selected incident from symbol layer */
    updateIncidents(incidentFilters, incidentTimeFilter, null, incident)

    /* Create new marker at incident's location */
    const newMarker = new mapboxgl.Marker({ color: 'rgb(3, 198, 252)' })
    newMarker.setDraggable(true)
    newMarker.setLngLat({ lng: incident.longitude, lat: incident.latitude })
      .addTo(mapRef.current)

    /* Update location on dragend */
    newMarker.on('dragend', () => {
      setCurrentPointRef.current([newMarker.getLngLat().lng, newMarker.getLngLat().lat])
    })

    setFocusedIncident(incident)
    setIncidentMarker(newMarker)
  }

  /* Disregard incident pin selected */
  const disregardIncident = () => {
    /* Update incident map and remove marker */
    updateIncidents(incidentFilters, incidentTimeFilter, null, false)
    if (incidentMarker) {
      incidentMarker.remove()
      setIncidentMarker(null)
    }
    setEditing(false)
    setFocusedIncident(null)
  }

  const finishIncidentEdit = (incident, callBack) => {
    /* Set loader and send request to server */
    setShowLoading(true)
    apiHook.editIncident({ domain_id: workspace.id, incidents: [incident] }).then(() => {
      const copy = incidents.slice()
      const index = incidents.findIndex(({ id }) => id === incident.id)

      /* Update record with new data */
      copy.splice(index, 1, { ...incidents[index], ...incident })
      const filtered = filterIncidents(incidentFilters, incidentTimeFilter, copy)

      /* Update source with new set of filtered incidents */
      mapRef.current.getSource('incidents').setData({
        type: 'FeatureCollection',
        features: filtered.map((val) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              val.longitude, val.latitude,
            ],
          },
          properties: {
            incident_type: val.incident_type,
            popupdata: incidentIndicator(val),
            fatalities: val.fatalities,
            date: val.reported || val.date,
            description: val.description,
            id: val.id,
          },
        })),
      })

      if (incidentMarker) {
        incidentMarker.remove()
        setIncidentMarker(null)
      }

      setIncidents(copy)
      setShowLoading(false)
      callBack()
    })
  }

  const handleDeleteIncident = (input, callback) => {
    setShowLoading(true)
    apiHook.deleteIncident({ domain_id: workspace.id, incidents: [input.id] }).then(() => {
      const copy = incidents.slice()
      const updated = copy.filter(({ id }) => id !== input.id)

      /* Update Map */
      /* Update source with new set of filtered incidents */
      mapRef.current.getSource('incidents').setData({
        type: 'FeatureCollection',
        features: updated.map((val) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              val.longitude, val.latitude,
            ],
          },
          properties: {
            incident_type: val.incident_type,
            popupdata: incidentIndicator(val),
            fatalities: val.fatalities,
            date: val.reported || val.date,
            description: val.description,
            id: val.id,
          },
        })),
      })
      if (incidentMarker) {
        incidentMarker.remove()
        setIncidentMarker(null)
      }

      setIncidents(updated)
      setShowLoading(false)
      callback()
    })
  }

  const formatPhrases = (keyphrases, categories) => {
    const phrases = processKeyphrases(keyphrases, categories)
    setOrderedPhrases(phrases)
    setPhraseCategories(categories)
  }

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (JSON.parse(localStorage.getItem('current-workspace')).power !== 'God' || JSON.parse(localStorage.getItem('current-workspace')).type === 'member') history.push('/dashboard')
  }, [])

  return (
    <IonPage>
      <PageHeader title='Consultant' />
      <IonContent
        style={{
          '--padding-top': '20px',
          '--padding-bottom': '20px',
          '--padding-start': '20px',
          '--padding-end': '20px',
        }}
      >
        <IonGrid className='risk-assessment-list'>
          {
            showLoading && (
              <>
                <ObscureBackground style={{ zIndex: '20' }} />
                <CenterLoader style={{ zIndex: '21' }}>
                  <IonSpinner color='light' />
                </CenterLoader>
              </>
            )
          }
          {
            submittedMessage && (
              <>
                <ObscureBackground style={{ zIndex: '10' }} />
                <CenterForm style={{ height: 'auto', width: '30%' }}>
                  <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
                </CenterForm>
              </>
            )
          }
          {
            selectedCounty && (
              <>
                <ObscureBackground style={{ zIndex: '20' }} />
                <SuggestedPopup
                  onClose={() => setSelectedCounty(null)}
                  county={selectedCounty}
                  data={suggestedReports[selectedCounty.id]}
                  keyphrases={orderedPhrases}
                  categories={phraseCategories}
                />
              </>
            )
          }
          {
            showMultiAS && (
              <>
                <ObscureBackground style={{ zIndex: '9' }} />
                <MultiEditAS
                  selectedCounties={selected.counties}
                  onBack={() => setShowMultiAS(false)}
                  geojsonCounties={counties}
                  updateCounties={(countyList) => {
                    setShowMultiAS(false)
                    dispatchSelected({ type: 'forget_selections' })
                    updateCountyColors(asFilters, updateCountiesArr, countyList, mapRef, drawLayers, regions, setRegions, selectedCountry, selectedRegion, crowdFilter, suggestedFilter)
                    setSubmittedChanges(true)
                    setSubmittedMessage('Alert States Updated.')
                    setView('map')
                  }}
                  setLoading={setShowLoading}
                />
              </>
            )
          }
          <ConsultantControls
            view={view}
            setView={setView}
            mode={mode}
            crowdFilter={crowdFilter}
            suggestedFilter={suggestedFilter}
            editing={editing}
            setEditing={setEditing}
            handleModeChange={handleModeChange}
            asFilters={asFilters}
            incidentFilters={incidentFilters}
            updateIncidents={updateIncidents}
            showIncidents={showIncidents}
            canChange={canChange}
            handleIncidents={(e) => {
              showFusionIncidents(e.target.checked)
              setShowIncidents(e.target.checked)
            }}
            showMbs={showMbs}
            handleMbs={(e) => {
              showFusionMbs(e.target.checked)
              setShowMbs(e.target.checked)
            }}
            showRoutes={showRoutes}
            handleRoutes={(e) => {
              showFusionRoutes(e.target.checked)
              setShowRoutes(e.target.checked)
            }}
            addMbs={() => {
              setShowMbForm(true)
              handleModeChange({ target: { value: 'Fusion' } })
              setCanChange(false)
            }}
            addRoutes={() => {
              setShowRouteForm(true)
              handleModeChange({ target: { value: 'Fusion' } })
              setCanChange(false)
            }}
            addIncidents={() => {
              setShowIncidentForm(true)
              handleModeChange({ target: { value: 'Fusion' } })
              setCanChange(false)
            }}
            popupForm={popupForm}
            incidentTimeFilter={incidentTimeFilter}
            setIncidentTimeFilter={setIncidentTimeFilter}
            filterCountries={filterCountries}
            filterRegions={filterRegions}
            selectedRegion={selectedRegion}
            selectedCountry={selectedCountry}
            countryList={countryList}
            fromMap={fromMap}
            filterCrowd={filterCrowd}
            filterSuggested={filterSuggested}
            setFromMap={setFromMap}
            selected={selected}
            setShowMultiAS={setShowMultiAS}
            setCanChange={setCanChange}
          />
          <ConsultantMap
            mapRef={mapRef}
            view={view}
            mode={mode}
            setCounties={setCounties}
            focusedIncident={focusedIncident}
            counties={counties}
            asFilters={asFilters}
            incidentList={incidents}
            filterIncidents={filterIncidents}
            setIncidents={setIncidents}
            incidentFilters={incidentFilters}
            setIncidentFilters={setIncidentFilters}
            setRestrictionBoxes={setRestrictionBoxes}
            routes={routes}
            setPopup={setPopup}
            setRoutes={setRoutes}
            setShowRouteForm={setShowRouteForm}
            showRouteForm={showRouteForm}
            setShowMbForm={setShowMbForm}
            showMbForm={showMbForm}
            editing={editing}
            finish={() => {
              mapRef.current.easeTo({
                padding: {
                  right: 0,
                },
              })
              setCanChange(true)
            }}
            movementBoxes={restrictionBoxes}
            showIncidentForm={showIncidentForm}
            setShowIncidentForm={setShowIncidentForm}
            updateIncidents={updateIncidents}
            incidentTimeFilter={incidentTimeFilter}
            submitIncident={() => setCanChange(true)}
            showMbs={() => {
              setShowMbs(true)
            }}
            showIncidents={() => {
              setShowIncidents(true)
            }}
            showRoutes={() => {
              setShowRoutes(true)
            }}
            setShowLoading={setShowLoading}
            updateCounties={(countyList) => {
              updateCountyColors(asFilters, setCounties, countyList, mapRef, drawLayers, regions, setRegions, selectedCountry, selectedRegion, crowdFilter, suggestedFilter)
            }}
            fromMap={fromMap}
            setFromMap={setFromMap}
            setView={setView}
            setRegions={setRegions}
            setCountryList={setCountryList}
            drawLayers={drawLayers}
            setShowMultiAS={setShowMultiAS}
            submittedChanges={submittedChanges}
            setSubmittedChanges={setSubmittedChanges}
            startIncidentEdit={startIncidentEdit}
            disregardIncident={disregardIncident}
            finishIncidentEdit={finishIncidentEdit}
            handleDeleteIncident={handleDeleteIncident}
            setCountiesArr={setCountiesArr}
            countiesArr={countiesArr}
            updateCountiesArr={updateCountiesArr}
            selected={selected}
            dispatchSelected={dispatchSelected}
            suggestedReports={suggestedReports}
            setSuggestedReports={setSuggestedReports}
            setSelectedCounty={setSelectedCounty}
            formatPhrases={formatPhrases}
            closeIncidentEdit={() => {
              setEditing(false)
              setCanChange(true)
              setFocusedIncident(null)
            }}
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default ConsultantsApp
