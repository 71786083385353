/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import {
  IonPage, IonLoading, useIonAlert,
} from '@ionic/react'

import { MobileButtons } from '../../components/mobile/StyledMobile'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'
import MobileHeader from '../../components/mobile/MobileHeader'
import { useAuth } from '../../hooks/useAuth'
import JourneyList from '../../components/mobile/journeys/JourneyList'
import JourneyTicket from '../../components/mobile/journeys/JourneyTicket'
import JourneyAuthorization from '../../components/mobile/journeys/JourneyAuthorization'
import JourneyForm from '../../components/mobile/journeys/JourneyForm'
import SubmittedPopup from '../../components/mobile/SubmittedPopup'
import { ObscureBackground } from '../../components/GlobalContainers'
import AppHeader from '../../components/mobile/AppHeader'

/**
 *  ===============================
 *      JOURNEYS FOR MOBILE
 *  ===============================
 */
const JourneysMobile = () => {
  /* DB journeys -> No new journeys can be added from this version  */
  const [journeys, setJourneys] = useState([])
  const [view, setView] = useState('Home')
  const [paths, setPaths] = useState([])
  const [users, setUsers] = useState([])
  const [focusedJourney, setFocusedJourney] = useState(null)
  const [showSubmittedPopup, setShowSubmittedPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  /* API hooks */
  const apiHook = useApi()
  const { workspace } = useWorkspace()
  const { user } = useAuth()
  /* display popups */
  const [ionAlert] = useIonAlert()

  const formatJourneys = (dbJourneys, routesFromDb, dbUsers) => {
    /* Prepare and store journeys for future display */
    const journeysFromDb = dbJourneys.map(({
      id, arrival_location, departure_location, submitter, departure_time, arrival_time, status, approver, stages, leader_name,
      leader_phone, driver_name, driver_phone, vehicle_type, vehicle_reg, emergency_sat, emergency_other, purpose, passengers,
      driver_hotel, driver_contact, driver_location, passenger_hotel, passenger_contact, passenger_location, story,
    }, index) => {
      /* Filter out paths that belong to this journey */
      const journeyPaths = routesFromDb.filter((path) => stages.indexOf(path.id) >= 0)
      const journeyManager = (typeof submitter === 'number') ? dbUsers.find((dbUser) => dbUser.id === submitter) : submitter
      const supervisor = dbUsers.find((dbUser) => dbUser.id === approver)

      dbJourneys[index].submitter = journeyManager
      dbJourneys[index].routes = journeyPaths.map((path) => path.properties)

      return {
        id,
        departure_location,
        arrival_location,
        leader_name,
        leader_phone,
        submitter: {
          id: journeyManager.id,
          email: journeyManager.email,
        },
        departure_time,
        arrival_time,
        status,
        purpose,
        story,
        routes: journeyPaths,
        emergency_other,
        emergency_sat,
        passengers,
        approver: {
          id: supervisor.id,
          email: supervisor.email,
        },
        driver_name,
        driver_phone,
        driver_hotel,
        driver_contact,
        driver_location,
        passenger_hotel,
        passenger_contact,
        passenger_location,
        vehicle_reg,
        vehicle_type,
      }
    }).flat(1)
    return journeysFromDb
  }

  /* Handle selection of journey */
  const handleSelected = (journey) => {
    setFocusedJourney(journey)
  }

  const unselectJourney = () => {
    setFocusedJourney(null)
  }

  /* Handle updates on the status of a journey */
  const updateJourneyStatus = (id, updated) => {
    const copy = journeys.slice()
    const journeyIndex = copy.findIndex((journey) => journey.id === id)

    const temp = copy.splice(journeyIndex, 1)[0]
    copy.push({ ...temp, ...updated })

    setJourneys(copy)
  }

  /* Submit journey request */
  const onSubmit = async (input) => {
    /* Fixing some params */
    setLoading(true)
    const { message, id } = await (apiHook.requestJourney('', ...Object.values(input), workspace.id))

    /* If successful add new entry to the arrays that contain journeys data */
    if (id) {
      const dbJourneys = await apiHook.getJourneys(workspace.id)
      const journeysPath = dbJourneys.journeys.map((journey, index) => ({
        ...journey,
        stages: dbJourneys.paths[index],
      }))
      const newJourneys = formatJourneys(journeysPath, paths, users)
      setJourneys(newJourneys)
      setLoading(false)
      setShowSubmittedPopup(true)
    } else {
      // eslint-disable-next-line no-undef
      ionAlert(message)
    }
  }

  /* Fetch Journeys and routes */
  useEffect(async () => {
    /* Fetch incidents, Mbs and Paths */
    const [dbPaths, dbJourneys, dbUsers] = await Promise.all([apiHook.getPaths(workspace.id), apiHook.getJourneys(workspace.id), apiHook.getUsers(workspace.id)])
    const journeysPath = dbJourneys.journeys.map((journey, index) => ({
      ...journey,
      stages: dbJourneys.paths[index],
    }))
    setPaths(dbPaths.routes)
    setUsers(dbUsers.users)
    setJourneys(formatJourneys(journeysPath, dbPaths.routes, dbUsers.users))
  }, [])

  return (
    <IonPage style={{ justifyContent: 'inherit' }}>
      <MobileHeader />
      <IonLoading
        isOpen={loading}
      />
      {
        !focusedJourney && view === 'Home' && (
          <>
            <AppHeader workspace={workspace} app='Journeys' icon='carbon:location-filled' />
            <MobileButtons>
              <h4>Transit</h4>
              <div className='mobile-buttons'>
                <button onClick={() => setView('My Journeys')} type='button'>My Journeys</button>
                <button onClick={() => setView('All')} type='button'>All Journeys</button>
              </div>
            </MobileButtons>
            <MobileButtons>
              <h4>Add</h4>
              <button onClick={() => setView('Request Form')} type='button'>Journey Request</button>
            </MobileButtons>
          </>
        )
      }
      {
        !focusedJourney && view === 'My Journeys' && (
          <JourneyList
            journeys={journeys.filter((journey) => journey.submitter.id === user.id)}
            selectJourney={handleSelected}
            title='My Journeys'
            onBack={() => setView('Home')}
          />
        )
      }
      {
        !focusedJourney && view === 'Request Form' && (
          <JourneyForm
            onBack={() => setView('Home')}
            routes={paths}
            users={users}
            addJourney={onSubmit}
          />
        )
      }
      {
        !focusedJourney && view === 'Request Form' && showSubmittedPopup && (
          <>
            <ObscureBackground />
            <SubmittedPopup
              message='Journey Request Submitted'
              onView={() => {
                setView('My Journeys')
                setShowSubmittedPopup(false)
              }}
              dismiss={() => {
                setView('Home')
                setShowSubmittedPopup(false)
              }}
            />
          </>
        )
      }
      {
        !focusedJourney && view === 'All' && (
          <JourneyList
            journeys={journeys}
            selectJourney={handleSelected}
            title='All Journeys'
            onBack={() => setView('Home')}
          />
        )
      }
      {
        focusedJourney && (
          <>
            {
              (focusedJourney.status === 'Pending' && focusedJourney.approver.id === user.id)
                ? (
                  <JourneyAuthorization
                    focusedJourney={focusedJourney}
                    onBack={unselectJourney}
                    setLoading={setLoading}
                    domainId={workspace.id}
                    updateJourneyStatus={updateJourneyStatus}
                  />
                ) : (
                  <JourneyTicket
                    focusedJourney={focusedJourney}
                    onBack={unselectJourney}
                    setLoading={setLoading}
                    domainId={workspace.id}
                    updateJourneyStatus={updateJourneyStatus}
                  />
                )
            }
          </>
        )
      }
    </IonPage>
  )
}

export default JourneysMobile
