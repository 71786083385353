const people = {
  None: 'No impact on people.',
  Insignificant: 'No injury or First Aid Case (FAC)',
  Minor: 'Medical Treatment Case (MTC), Restricted Work Case (RWC)',
  Moderate: 'Loss Time Incident (LTI)  < 4 / hospitalisation or debilitating injury',
  Major: 'A fatality (FAT) or 5 - 10 LTIs',
  Severe: 'Multiple FATs or 11+ LTIs',
}
const operations = {
  None: 'No impact on operations.',
  Insignificant: 'Negligible impact on operations. Potential disruption: < 1 week.',
  Minor: 'Impact on operations but able to be dealt with at site level. Potential disruption: < 1 month',
  Moderate: 'Impact causing disruption to wider business operations. Potential disruption: < 3 months.',
  Major: 'Key business operations compromised. Potential disruption: < 6 months.',
  Severe: 'Serious long lasting impact on operations. Potential disruption: > 6 months.',
}
const property = {
  None: 'No impact on property.',
  Insignificant: 'Minor damage up to $6.5k or 5k euro',
  Minor: 'Moderate damage up to $65k or 50k euro',
  Moderate: 'Extensive damage up to  $320k or 250k euro',
  Major: 'Extensive damage over  $320k or 250k euro',
  Severe: 'Damage over $1.5m or 1m euro',
}

export {
  people,
  operations,
  property,
}
