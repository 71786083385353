import React from 'react'
import { IonItem, IonLabel } from '@ionic/react'
import { Icon } from '@iconify/react'
import { TabBarContainer, ViewMarker } from './StyledContainers'

/**
 *  ========================
 *    Switch Settings views
 *  ========================
 */

const TabBar = ({ view, setView, permission }) => (
  <TabBarContainer>
    <IonItem
      button
      className='ion-no-padding'
      style={{ '--background': (view === 'account') ? 'white' : '#F2F2F2', color: '#8E151F' }}
      onClick={() => setView('account')}
    >
      {
        view === 'account' && (
          <ViewMarker />
        )
      }
      <IonLabel className='ion-text-center'><Icon style={{ fontSize: '25px' }} icon='grommet-icons:user-settings' /></IonLabel>
    </IonItem>
    {
      (permission === 'administrator' || permission === 'manager') && (
        <>
          <IonItem
            button
            className='ion-no-padding'
            style={{ '--background': (view === 'workspace') ? 'white' : '#F2F2F2', color: '#8E151F' }}
            onClick={() => setView('workspace')}
          >
            {
              view === 'workspace' && (
                <ViewMarker />
              )
            }
            <IonLabel className='ion-text-center'><Icon style={{ fontSize: '25px' }} icon='system-uicons:hierarchy' /></IonLabel>
          </IonItem>
        </>
      )
    }
  </TabBarContainer>
)

export default TabBar
