import styled from 'styled-components'

const PopupContainer = styled.div`
  position: fixed;
  z-index: 1050;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2.5px;
  background: white;
  padding: 20px;
  outline: 0;

  & h4 {
    color: #8E151F;
    font-size: 1.2rem;
  }

  & p {
    font-size: 1rem;
  }

  & .safety-options div {
    text-align: center;
  }
`

const MobilePopupContainer = styled.div`
  position: fixed;
  width: 100%;
  background: #8E151F;
  z-index: 1050;
  padding: 20px;

  & h3 {
    color: white;
  }

  & p {
    color: white;
    font-size: .9rem;
  }

  & button.dismiss-button {
    border: 1px solid white;
    background-color: #8E151F;
    color: white;
    padding: 5px 10px;
    border-radius: 2.5px;
  }

  & a.open-app-button {
    color: #8E151F;
    background-color: white;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 2.5px;
  }
`

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(69, 69, 68, .5);
  z-index: 1000;
`

const SafetyButton = styled.button`
  border: none;
  background: transparent;
  font-size: .8rem;

  & svg {
    font-size: 2rem;
  }
`

export {
  PopupContainer,
  PopupBackground,
  SafetyButton,
  MobilePopupContainer,
}
