import * as turf from '@turf/turf'
import { TrackRequestStatus } from '../../hooks/journeys/helpers/StateReducers'
import {
  ProcedureResponsability, RestrictionZoneType, SOPLevel, TemplateType, User,
} from './GlobalTypes'

export interface BackendDomainUser {
  user_id: number,
  type: string,
}

export interface BackendChatRoom {
  id: number,
  name: string,
  recipients: number[],
  created_at: string,
  is_group: boolean,
}

export interface BackendMovementBox {
  id: number
  geoData: string,
  created_at: string,
  updated_at: string,
  check_in_days: number,
  domain_id: number,
  description: string,
  county_id: number,
  user_id: number,
  zone_type: RestrictionZoneType,
}

export interface JourneyChapter {
  user_id: number,
  user?: User,
  id: number,
  content: string,
  journey_id: number,
  latitude?: number,
  longitude?: number,
  category: string,
  created_at: string,
  updated_at: string,
}

/* Joint table that connects users and rooms */
export interface BackendRecipient {
  room_id: number,
  user_id: number,
  id: number,
  created_at: string,
  updated_at: string,
  read: boolean,
}

export interface BackendInternalPerson {
  id: number,
  internal_incident_id: number,
  involvement_type: string,
  user_id: number,
}

export interface BackendBreakoutRoom {
  domain_id: number,
  id: number,
  room_id: number,
  host_id: number,
  incident_id: number
}

export interface BackendCounty {
  id: number,
  name: string
  alert_state: number
  created_at: string
  updated_at: string
  geoData: turf.Feature<turf.Polygon>
}

export interface BackendSOP {
  id: number,
  sop_type: string,
  domain_id: number,
  template_sop_id: number,
  template_type: TemplateType,
  domain_template_exists: boolean,
  archangel_template_exists: boolean,
}

export interface BackendResponseLevel {
  id: number,
  incident_type: string,
  team_type: SOPLevel,
}

export interface BackendTrackedUser {
  id: number,
  user_id: number,
  movement_box_id: number,
  domain_id: number,
  consented: TrackRequestStatus
}

export interface BackendTemplate {
  id: number,
  domain_id: number,
  created_at: string,
  updated_at: string,
  sop_type: string,
  template_type: TemplateType
}

export enum ProcedureType {
  IMMEDIATE = 'Immediate',
  FOLLOW_UP = 'Follow-Up',
  RECOVERY = 'Recovery',
}

export interface BackendProcedure {
  id: number,
  action: string,
  description: string,
  level: SOPLevel,
  responsibility: ProcedureResponsability,
  procedure_type: ProcedureType
}

export interface BackendTeamMember {
  id: number,
  domain_id: number,
  user_id: number,
  role: ProcedureResponsability,
}
