import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonButton, IonCard, IonCardHeader, IonText, IonCardTitle, IonTextarea, useIonAlert,
} from '@ionic/react'
import React, { useState } from 'react'

import { useHistory } from 'react-router'

import PageHeader from '../PageHeader'

import useApi from '../../hooks/testHooks'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const TasksAdd = () => {
  const [text, setText] = useState()

  const apiHook = useApi()

  const history = useHistory()

  const [ionAlert] = useIonAlert()

  const handleBack = () => {
    history.push('/helpcenter')
  }

  const handleSubmit = () => {
    const message = text

    if (message !== undefined) {
      apiHook.help({
        message,
      }).then(ionAlert({
        header: 'Message',
        message: 'Your submission has been sent to the team. ',
        buttons: [
          { text: 'OK' },
        ],
      }))
    }
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' routerLink='/helpcenter-insights' onClick={handleBack} />Helpcenter
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Help is Coming Soon
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonText>We’re sorry. There is currently no help available for this section. Our team are working hard to put together articles for this app. </IonText>
                <IonText><br />In the meantime, if you have a question or need help with an issue, message us below and a member of the Sherpa team will get back to you as soon as possible. </IonText>

                <IonText style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
                }}
                ><br /><br />Message
                </IonText>
              </IonRow>

            </IonItem>

            <IonItem style={{ '--background': 'white', paddingLeft: '40px', paddingRight: '40px' }} lines='none'>
              <IonTextarea placeholder='How can we Help?' value={text} onIonChange={(e) => setText(e.detail.value)} style={{ width: '100%', border: '1px solid #747474', '--background': 'white' }} />
            </IonItem>

            <IonItem lines='none' style={{ '--background': 'white' }}>
              <IonButton slot='end' style={{ paddingLeft: '40px', paddingRight: '40px' }} onClick={handleSubmit}>
                Submit
              </IonButton>
            </IonItem>

          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Insights Help
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Tasks Help
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default TasksAdd
