import { IonButton, IonRow } from '@ionic/react'
import * as React from 'react'
import * as moment from 'moment'
import { ListModes, Views, DisplayAssetAction } from '../../../hooks/risks/helpers/StateReducers'
import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'
import { fetchAlerStates, drawCountyShapesIncidents } from './AppMap'
import { colorCodeCounties, getIncidentsDictionary } from './SecurityConditions'
import Map from '../Map'
import { useWorkspace } from '../../../hooks/useWorkspace'
import useApi from '../../../hooks/testHooks'
import AddAsset from '../../riskregister/forms/AddAsset'
import EditAsset from '../../riskregister/forms/EditAsset'
import AssetRegister from '../../riskregister/listviews/AssetRegister'
import RiskRegister from '../../riskregister/listviews/RiskRegister'
import AssetProfile from '../../riskregister/AssetProfile'
import { OverlayContainer } from '../../GlobalContainers'
import { ZoneTypeButton } from '../../riskregister/StyledContainers'
import Pin from '../../terrain-mapping/icons/poi.svg'
import LinePOI from '../../terrain-mapping/icons/line-poi.svg'
import PolygonPOI from '../forms/images/restriction-zone-amber.svg'
import { createAssetLayers } from '../../../hooks/risks/helpers/MapUtils'
import { useEffect, useState } from 'react'
import AssetV2 from '../features/AssetV2'
import FlashpointForm from '../features/Flashpoint'

/**
 *  ================================
 *        RISK REGISTER MAPBOX
 *  ================================
 */
const RiskRegisterMapV2 = () => {
  /* Application hooks */
  const {
    viewMode, mapRef, formatAssets, drawAssets, displayAsset, fetchAppDataV2, showAssetForm,
    editing, setAllAssets, focusedAsset, dispatchDisplayAsset, setAssetType, setLoading, loading, showFlashpointForm,
  } = useRiskRegister()
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  /* When map component has been loaded request data from backend */

  const onSelect = (assetType) => {
    setAssetType(assetType)
    dispatchDisplayAsset(DisplayAssetAction.OPEN_ASSET_FORM)
  }

  const onCancel = () => {
    mapRef.current.resize()
    dispatchDisplayAsset(DisplayAssetAction.CLOSE_ASSET_TYPE)
  }

  return (
    <IonRow className='ion-justify-content-center' style={{ height: (viewMode.view === Views.REGISTER_VIEW) ? '80%' : '85%' }}>
      <Map
        mapRef={mapRef}
        zoom={5}
        listeners={fetchAppDataV2}
        className='risk-register-map'
      >
        {
          showAssetForm && (
            <AssetV2 />
          )
        }
        {
          showFlashpointForm && (
            <FlashpointForm />
          )
        }
      </Map>
    </IonRow>
  )
}

export default RiskRegisterMapV2
