import React from 'react'
import { IonContent, IonRow, IonCol } from '@ionic/react'
import { Icon } from '@iconify/react'
import moment from 'moment'

import { JourneysContainer, JourneyButton } from '../StyledMobile'
import { SimpleButton } from '../../maps/StyledContainers'
/**
 *  =====================================
 *      MOBILE DISPLAY OF JOURNEYS
 *  =====================================
 */
const JourneyList = ({
  journeys, selectJourney, title, onBack,
}) => (
  <IonContent style={{
    '--background': 'white',
    '--padding-top': '15px',
    '--padding-bottom': '15px',
    '--padding-start': '15px',
    '--padding-end': '15px',
  }}
  >
    <IonRow className='ion-align-items-center ion-justify-content-between'>
      <SimpleButton onClick={onBack}>
        <Icon icon='fe:arrow-left' />
        Back
      </SimpleButton>
      <p style={{ fontSize: '1rem', color: '#326771', margin: '0' }}>{ title }</p>
    </IonRow>
    <JourneysContainer>
      <h4 className='transit'>In Transit</h4>
      {
        journeys.filter((journey) => journey.status === 'In-Transit').map((journey) => (
          <JourneyButton key={journey.id} onClick={() => selectJourney(journey)}>
            <IonRow>
              <IonCol size={4}>
                <p>{ journey.departure_location }</p>
              </IonCol>
              <IonCol size={4}>
                <p>{ journey.arrival_location }</p>
              </IonCol>
              <IonCol size={4}>
                <p>{ moment(journey.departure_time).format('DD/MM/YY') }</p>
              </IonCol>
            </IonRow>
          </JourneyButton>
        ))
      }
      {
        journeys.filter((journey) => journey.status === 'In-Transit').length === 0 && (
          <p className='none'>None</p>
        )
      }
    </JourneysContainer>
    <JourneysContainer>
      <h4 className='pre-departure'>Pre Departure</h4>
      {
        journeys.filter((journey) => journey.status === 'Pre-Departure').map((journey) => (
          <JourneyButton key={journey.id} onClick={() => selectJourney(journey)}>
            <IonRow>
              <IonCol size={4}>
                <p>{ journey.departure_location }</p>
              </IonCol>
              <IonCol size={4}>
                <p>{ journey.arrival_location }</p>
              </IonCol>
              <IonCol size={4}>
                <p>{ moment(journey.departure_time).format('DD/MM/YY') }</p>
              </IonCol>
            </IonRow>
          </JourneyButton>
        ))
      }
      {
        journeys.filter((journey) => journey.status === 'Pre-Departure').length === 0 && (
          <p className='none'>None</p>
        )
      }
    </JourneysContainer>
    <JourneysContainer>
      <h4 className='pending'>Pending</h4>
      {
        journeys.filter((journey) => journey.status === 'Pending').map((journey) => (
          <JourneyButton key={journey.id} onClick={() => selectJourney(journey)}>
            <IonRow>
              <IonCol size={4}>
                <p>{ journey.departure_location }</p>
              </IonCol>
              <IonCol size={4}>
                <p>{ journey.arrival_location }</p>
              </IonCol>
              <IonCol size={4}>
                <p>{ moment(journey.departure_time).format('DD/MM/YY') }</p>
              </IonCol>
            </IonRow>
          </JourneyButton>
        ))
      }
      {
        journeys.filter((journey) => journey.status === 'Pending').length === 0 && (
          <p className='none'>None</p>
        )
      }
    </JourneysContainer>
  </IonContent>
)

export default JourneyList
