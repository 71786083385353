import * as React from 'react'
import { useEffect, useState } from 'react'
import {
  IonLabel, IonButton, IonRow, IonGrid,
  IonCol, IonContent, IonPage, IonInput,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import PageHeader from '../PageHeader'
import { SettingsContainer } from '../settings/StyledContainers'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'

import { useReportsData } from '../../hooks/reports/useReportsData'

import FieldSitrepPopup from './FieldSitrepPopup'

const ReportsHome : React.FC<{ onReturn: () => void }> = ({
  onReturn,
}) => {
  const { workspace } = useWorkspace()
  const apiHook = useApi()
  const [insightUrl, setInsightUrl] = useState(0)
  const [weeklyUrl, setWeeklyUrl] = useState(0)

  const {
    showFieldSitrep,
    setShowSitreps,
    setShowReports,
    setShowHome,
    setShowWeeklys,
    setFromUrl,
    setShowInternalIncidents,
    fromUrl,
  } = useReportsData()

  function handleFieldClick() {
    setShowHome(false)
    setShowSitreps(true)
  }

  function handleSecurityClick() {
    setShowHome(false)
    setShowReports(true)
  }

  function handleWeeklyClick() {
    setShowHome(false)
    setShowWeeklys(true)
  }

  function handleIncidentClick() {
    setShowHome(false)
    setShowInternalIncidents(true)
  }

  // url variable
  useEffect(() => {
    // Run! Like go get some data from an API.
    const queryParams = new URLSearchParams(window.location.search);
    const insightId = Number(queryParams.get('insight'))
    const date = Number(queryParams.get('weekly'))
    setInsightUrl(insightId)
    setWeeklyUrl(date)
  }, [])

  if (insightUrl > 0 && fromUrl === true) {
    handleFieldClick()
  }

  if (weeklyUrl > 0 && fromUrl === true) {
    setFromUrl(false)
    handleWeeklyClick()
  }

  const showSitrepCard : boolean = true

  return (
    <>
        <IonPage>
          <PageHeader title="Reports" />
          <IonContent style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
            <SettingsContainer>
              <IonRow class="ion-justify-content-center" style={{ height: '100%', backgroundColor: '#FFFFFF', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)', width: '100%' }}>
                <IonGrid class="ion-justify-content-center" style={{ marginLeft: '4em' }}>
                  <IonGrid class="ion-justify-content-center">
                    <IonRow class="ion-align-items-center" style={{ width: '95%', height: '15%', backgroundColor: '#FFFFFF', border: '1px', borderStyle: 'none none solid none' }}>
                      <IonCol class='ion-justify-content-start' style={{ width: '70%' }}>
                        <IonButton fill="clear">
                          <Icon icon="ep:arrow-left" style={{ fontSize: '1.8em', color: 'grey' }}/>
                        </IonButton>
                        <IonButton fill="clear">
                          <Icon icon="ep:arrow-right" style={{ fontSize: '1.8em', color: 'grey' }}/>
                        </IonButton>
                      </IonCol>
                      <IonCol class='ion-justify-content-end' style={{ width: '30%' }}>
                        <IonRow class="ion-align-items-center">
                          <IonCol class='ion-justify-content-start'>
                            <IonInput class="ion-justify-content-end" placeholder="Search" style={{ width:'100%', height: '2em', border: '1px solid', borderRadius: '2.5px' }}></IonInput>
                          </IonCol>
                          <IonCol class='ion-justify-content-end'>
                            <IonButton class="ion-justify-content-end" fill="clear" size="large" style={{ color: 'grey' }}>
                              <Icon icon="ph:magnifying-glass-thin"></Icon>
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    <IonRow class="ion-align-items-center">
                      <IonCol class="ion-align-items-center">
                        <IonLabel style={{color: 'black'}}>Name</IonLabel>
                        <IonButton size="small" fill="clear" style={{ color: '#326771', marginRight: '2em', paddingBottom: '0.9em' }}><Icon icon="bxs:down-arrow"></Icon></IonButton>
                        <IonLabel style={{color: 'black'}}>Last modified</IonLabel>
                        <IonButton size="small" fill="clear" style={{ color: '#326771', paddingBottom: '0.9em' }}><Icon icon="bxs:down-arrow"></Icon></IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    <IonRow style={{marginTop: '1em'}}>
                      <IonCol>
                        <IonButton onClick={handleSecurityClick} fill="clear" style={{ border: '1px solid', borderColor: '#747474', borderRadius: '0.2em', backgroundColor: '#F9F9F9', width: '11em', height: '9em', textTransform: 'none', letterSpacing: '-0.02em', marginRight: '3em' }}>
                          <IonGrid class="ion-align-items-center">
                            <IonRow>
                              <IonCol><Icon icon="ant-design:folder-open-filled" style={{ fontSize: '5em', color: '#326771' }}></Icon></IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol><IonLabel style={{ color: 'black', fontSize: '1.1em', fontWeight: 'normal' }}>Security reports</IonLabel></IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonButton>
                        <IonButton onClick={handleFieldClick} fill="clear" style={{ border: '1px solid', borderColor: '#747474', borderRadius: '0.2em', backgroundColor: '#F9F9F9', width: '11em', height: '9em', textTransform: 'none', letterSpacing: '-0.02em', marginRight: '3em' }} >
                          <IonGrid class="ion-align-items-center">
                            <IonRow>
                              <IonCol><Icon icon="ant-design:folder-open-filled" style={{ fontSize: '5em', color: '#326771' }}></Icon></IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol><IonLabel style={{ color: 'black', fontSize: '1.1em', fontWeight: 'normal' }}>Field Sitreps</IonLabel></IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonButton>
                        <IonButton onClick={handleWeeklyClick} fill="clear" style={{ border: '1px solid', borderColor: '#747474', borderRadius: '0.2em', backgroundColor: '#F9F9F9', width: '11em', height: '9em', textTransform: 'none', letterSpacing: '-0.02em', marginRight: '3em' }} >
                          <IonGrid class="ion-align-items-center">
                            <IonRow>
                              <IonCol><Icon icon="ant-design:folder-open-filled" style={{ fontSize: '5em', color: '#326771' }}></Icon></IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol><IonLabel style={{ color: 'black', fontSize: '1.1em', fontWeight: 'normal' }}>Weekly Reports</IonLabel></IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonButton>
                        <IonButton onClick={handleIncidentClick} fill="clear" style={{ border: '1px solid', borderColor: '#747474', borderRadius: '0.2em', backgroundColor: '#F9F9F9', width: '11em', height: '9em', textTransform: 'none', letterSpacing: '-0.02em', marginRight: '3em' }} >
                          <IonGrid class="ion-align-items-center">
                            <IonRow>
                              <IonCol><Icon icon="ant-design:folder-open-filled" style={{ fontSize: '5em', color: '#326771' }}></Icon></IonCol>
                            </IonRow>
                            <IonRow>
                              <IonCol><IonLabel style={{ color: 'black', fontSize: '1.1em', fontWeight: 'normal' }}>Incidents</IonLabel></IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonGrid>
              </IonRow>
            </SettingsContainer>
          </IonContent>
        </IonPage>
    </>
  )
}

export default ReportsHome