import * as React from 'react'
import {
  BreakoutRoom,
  IncidentActor, IncidentCause, IncidentTeam, InternalIncident, Message, SOP, SOPLevel, User,
} from '../../components/types/GlobalTypes'
import { IncidentViewActionKind, initialViewState, viewsReducer } from './helpers/StateReducers'
import useApi from '../testHooks'
import { useWorkspace } from '../useWorkspace'
import { IncidentManagementDataInterface } from './types/HookInterface'
import axios from '../../utils/axios'
import { fetchAlerStates } from '../../components/maps/apps/AppMap'
import { CountiesGeoJSON } from '../../components/types/GeoJSONTypes'
import {
  processActors, processCauses, processIncidentComments, processInternalActors, processInternalIncidents, processLogs, processSOPs, processEmergencyTeam, processBreakoutRooms,
} from './helpers/Utils'
import { useAuth } from '../useAuth'
import { chatsSorting } from '../chats/useChatsData'
import { BackendRecipient } from '../../components/types/BackendTypes'
import { useActionCable } from '../channels/useActionCable'
import { getWebSocketURL } from '../../channels/consumer'
import { useChannel } from '../channels/useChannel'
import { ReadChannelConfig } from '../channels/ChannelConfigs'
import { categoryValuesMapping } from '../../components/incident-management/views/IncidentForm'

/**
 *  =====================================
 *      INCIDENT MANAGEMENT HOOK
 *  =====================================
 *  The aim is to share the state of the app using a hook
 *  to reduce complexity when sending props through the hierarchy.
 */

export const incidentManagementContext = React.createContext({} as IncidentManagementDataInterface)
export const useIncidentsData = () => React.useContext(incidentManagementContext)

const useProvideIncidentsData = () : IncidentManagementDataInterface => {
  const [activeIncidents, setActiveIncidents] = React.useState<InternalIncident[]>([])
  const [closedIncidents, setClosedIncidents] = React.useState<InternalIncident[]>([])
  const [nonCriticalTeam, setNonCriticalTeam] = React.useState<IncidentTeam>()
  const [emergencyTeam, setEmergencyTeam] = React.useState<IncidentTeam>()
  const [managementTeam, setManagementTeam] = React.useState<IncidentTeam>()
  const [crisisTeam, setCrisisTeam] = React.useState<IncidentTeam>()
  const [users, setUsers] = React.useState<User[]>([])
  const [domainUsers, setDomainUsers] = React.useState<User[]>([])
  const [SOPTypes, setSOPTypes] = React.useState<{ [category: string] : string[] }>({})
  const [sopList, setSopList] = React.useState<SOP[]>([])
  const [displayView, dispatchView] = React.useReducer(viewsReducer, { ...initialViewState })
  const [incidentTypes, setIncidentTypes] = React.useState<string[]>([])
  const [actors, setActors] = React.useState<IncidentActor[]>([])
  const [causes, setCauses] = React.useState<IncidentCause[]>([])
  const [countiesGeoJSON, setCountiesJSON] = React.useState<CountiesGeoJSON>({ type: 'FeatureCollection', features: [] })
  const [risksDescriptions, setRisksDescriptions] = React.useState<{ [riskName: string]: string }>({})
  const [loading, setLoading] = React.useState<boolean>(true)
  const { user } = useAuth()

  /* Global Hooks */
  const apiHook = useApi()
  const { workspace } = useWorkspace()
  const { actionCable } = useActionCable(getWebSocketURL())
  const { subscribe } = useChannel(actionCable)

  /* Breakout rooms states -> NON-MVP */
  const [focusedRoom, setFocusedRoom] = React.useState<BreakoutRoom>(null)
  const [chatRooms, setChatRooms] = React.useState<BreakoutRoom[]>([])

  const updateReadChat = (recipientUpdated: BackendRecipient, roomId: number) => {
    const chat = chatRooms.find(({ id }) => id === roomId)

    if (!chat) {
      return
    }
    const recipientUpdates = chat.recipientUpdates.slice()
    const index = recipientUpdates.findIndex(({ user_id }) => user_id === recipientUpdated.user_id)

    if (index < 0) {
      return
    }

    recipientUpdates.splice(index, 1, { user_id: recipientUpdated.user_id, read: recipientUpdated.read, updated_at: recipientUpdated.updated_at })
    if (chat.id === focusedRoom.id) {
      setFocusedRoom({ ...focusedRoom, recipientUpdates })
    }
    updateRoom({ ...chat, recipientUpdates })
  }

  const setUpReadChannels = (roomLists: BreakoutRoom[]) => {
    roomLists.forEach((val) => {
      subscribe<ReadChannelConfig>({ channel: 'ConversationsChannel', room_id: val.id }, {
        received: (x) => {
          const recipientUpdated : BackendRecipient = x.recipient
          updateReadChatRef.current(recipientUpdated, val.id)
        },
      })
    })
  }

  const updateReadChatRef = React.useRef(null)
  updateReadChatRef.current = updateReadChat

  const fetchAppData = () => {
    if (workspace.is_incident_domain) {
      Promise.all([apiHook.riskTypes(workspace.id), apiHook.getUsers(workspace.id), fetchAlerStates(false, workspace.id),
        apiHook.fetchInternalIncident({ domain_id: workspace.id, incident_id: workspace.incident_id }),
        axios.get('/api/v1/internal_incident/fetchCausesActors'), apiHook.getBreakouts({ domain_id: workspace.id }),
        axios.post('/api/v1/domain/get_domain_users', { domain_id: workspace.id }),
      ]).then(([{ risks }, dbUsers, { counties, asCounties }, {
        secondary_incidents, internal_actors, external_actors, underlying_causes,
        incident_comments, action_log, investigation_people, investigation, incident,
      }, dbCausesActors, breakoutsData, domainUsersData]) => {
        const incidents = processInternalIncidents([incident], asCounties)
        setActiveIncidents(incidents)
        setUsers(dbUsers.users)
        const formattedChats = processBreakoutRooms(breakoutsData.chatrooms, dbUsers.users, user.user_id).sort(chatsSorting)
        setChatRooms(formattedChats)

        if (formattedChats.length > 0) {
          setFocusedRoom(formattedChats[0])
        }

        setUpReadChannels(formattedChats)
        setCountiesJSON(counties as CountiesGeoJSON)

        setDomainUsers(domainUsersData.data.users)

        setSOPTypes({
          'Security Incidents': risks.map(({ name }) => name),
        })
        setRisksDescriptions(Object.fromEntries(risks.map(({ name, description }) => [name, description])))

        const formattedInvestigation = investigation.length === 0 ? undefined : {
          ...investigation[0],
          peopleInvolved: processInternalActors(users, investigation_people),
        }

        /* Set cause and actor tags */
        setCauses(dbCausesActors.data.causes)
        setActors(dbCausesActors.data.actors)

        const formattedIncident : InternalIncident = {
          ...incidents[0],
          additionalIncidents: secondary_incidents,
          peopleInvolved: processInternalActors(users, internal_actors),
          logs: processLogs(action_log, users),
          actors: processCauses(underlying_causes, causes),
          causes: processActors(external_actors, actors),
          additionalComments: processIncidentComments(users, incident_comments),
          investigations: formattedInvestigation,
        }
        dispatchView({ type: IncidentViewActionKind.SELECT_INCIDENT, incident: formattedIncident })
      }).finally(() => {
        setLoading(false)
      })
      return
    }
    Promise.all([apiHook.riskTypes(workspace.id), apiHook.getUsers(workspace.id),
      axios.get('/api/v1/internal_incident/fetchCausesActors'), axios.get('/api/v1/types'),
      fetchAlerStates(false, workspace.id), apiHook.fetchInternalIncidents({ domain_id: workspace.id }),
      axios.post('/api/v1/internal_incident/getSops', { domain_id: workspace.id }),
      axios.post('/api/v1/internal_incident/getResponseLevels', { domain_id: workspace.id }),
      axios.post('/api/v1/internal_incident/getEmergencyTeams', { domain_id: workspace.id }),
    ])
      .then(([{ risks }, dbUsers, dbCausesActors, dbTypes, { counties, asCounties },
        dbIncidents, dbSops, dbResponseLevels, dbEmergencyTeams]) => {
        setSOPTypes(categoryValuesMapping)
        setRisksDescriptions(Object.fromEntries(risks.map(({ name, description }) => [name, description])))
        setUsers(dbUsers.users)

        const formattedSops = processSOPs(dbSops.data.sops, dbResponseLevels.data.responselevels)
        setSopList(formattedSops)

        /* Sort incident types alphabetically */
        setIncidentTypes(dbTypes.data.types.sort((a: string, b: string) => {
          if (a < b) return -1
          if (b < a) return 1
          return 0
        }))

        /* Set cause and actor tags */
        setCauses(dbCausesActors.data.causes)
        setActors(dbCausesActors.data.actors)

        const incidentsFormatted = processInternalIncidents(dbIncidents.incidents, asCounties)

        const { active, closed } = incidentsFormatted.reduce((val, incident) => {
          if (!incident.archived) {
            val.active.push(incident)
            return val
          }

          val.closed.push(incident)
          return val
        }, { active: [], closed: [] })

        setActiveIncidents(active)
        setClosedIncidents(closed)
        setCountiesJSON(counties as CountiesGeoJSON)

        /* Process emergency teams */
        const formattedEmergency = processEmergencyTeam(dbEmergencyTeams.data.emergency, dbUsers.users, SOPLevel.ERT)
        const formattedCrisis = processEmergencyTeam(dbEmergencyTeams.data.crisis, dbUsers.users, SOPLevel.CMT)
        const formattedNonCritical = processEmergencyTeam(dbEmergencyTeams.data.noncritical, dbUsers.users, SOPLevel.NCI)
        const formattedManagement = processEmergencyTeam(dbEmergencyTeams.data.incidentmanagment, dbUsers.users, SOPLevel.IMT)

        setEmergencyTeam(formattedEmergency)
        setCrisisTeam(formattedCrisis)
        setNonCriticalTeam(formattedNonCritical)
        setManagementTeam(formattedManagement)
      }).finally(() => {
        setLoading(false)
      })
  }

  const updateIncidentList = (list: InternalIncident[], record: InternalIncident) : InternalIncident[] | null => {
    const copy = list.slice()
    const index = copy.findIndex(({ id }) => id === record.id)

    if (index < 0) {
      return null
    }

    copy.splice(index, 1, record)
    return copy
  }

  const leaveRoom = (room: BreakoutRoom) => {
    const updated = chatRooms.filter(({ id }) => id !== room.id)
    setChatRooms(updated)

    if (updated.length === 0) {
      setFocusedRoom(null)
      return
    }
    setFocusedRoom(updated[0])
  }

  const refreshRooms = () => {
    Promise.all([
      apiHook.getBreakouts({ domain_id: workspace.id }),
    ]).then(([breakoutsData]) => {
      const formattedChats = processBreakoutRooms(breakoutsData.chatrooms, users, user?.user_id as number)
      setChatRooms(formattedChats.sort(chatsSorting))
    })
  }

  const closeIncident = () => {
    const updated = { ...displayView.focusedIncident, archived: true }
    const index = activeIncidents.findIndex(({ id }) => id === updated.id)

    const copy = activeIncidents.slice()
    copy.splice(index, 1)

    const closedCopy = closedIncidents.slice()
    closedCopy.push(updated)

    setClosedIncidents(closedCopy)
    setActiveIncidents(copy)
    dispatchView({ type: IncidentViewActionKind.UPDATE_INCIDENT, incident: updated })
  }

  const fetchIncidentData = () => {
    apiHook.fetchInternalIncident({ domain_id: workspace.id, incident_id: displayView.focusedIncident.id })
      .then(({
        secondary_incidents, internal_actors, external_actors, underlying_causes,
        incident_comments, action_log, investigation_people, investigation,
      }) => {
        const formattedInvestigation = investigation.length === 0 ? undefined : {
          ...investigation[0],
          peopleInvolved: processInternalActors(users, investigation_people),
        }

        const formattedIncident : InternalIncident = {
          ...displayView.focusedIncident,
          additionalIncidents: secondary_incidents,
          peopleInvolved: processInternalActors(users, internal_actors),
          logs: processLogs(action_log, users),
          actors: processCauses(underlying_causes, causes),
          causes: processActors(external_actors, actors),
          additionalComments: processIncidentComments(users, incident_comments),
          investigations: formattedInvestigation,
        }

        dispatchView({ type: IncidentViewActionKind.UPDATE_INCIDENT, incident: formattedIncident })

        if (displayView.focusedIncident.archived) {
          const copy = updateIncidentList(closedIncidents, formattedIncident)
          if (!copy) return
          setClosedIncidents(copy)
          return
        }

        const copy = updateIncidentList(activeIncidents, formattedIncident)
        if (!copy) return
        setActiveIncidents(copy)
      })
  }

  const updateIncident = (incident: InternalIncident) => {
    const copy = activeIncidents.slice()
    const index = activeIncidents.findIndex((val) => val.id === incident.id)

    /* update position with new incident */
    copy.splice(index, 1, incident)
    setActiveIncidents(copy)
  }

  const updateRoom = (room: BreakoutRoom) => {
    const copy = chatRooms.slice()
    const index = copy.findIndex(({ id }) => id === room.id)

    copy.splice(index, 1, { ...room })
    setChatRooms(copy.sort(chatsSorting))
  }

  const pushLatestMessage = (message: Message, room: BreakoutRoom) => {
    updateRoom({ ...room, read: true, latestMessage: message })
  }

  const setChatAsRead = (room: BreakoutRoom) => {
    updateRoom({ ...room, read: true })
  }

  const updateTeam = (updated: IncidentTeam, type: SOPLevel) => {
    if (type === SOPLevel.NCI) {
      setNonCriticalTeam({ ...updated })
      return
    }

    if (type === SOPLevel.IMT) {
      setManagementTeam({ ...updated })
      return
    }

    if (type === SOPLevel.ERT) {
      setEmergencyTeam({ ...updated })
      return
    }

    if (type === SOPLevel.CMT) {
      setCrisisTeam({ ...updated })
    }
  }

  const pushIncident = (incident: InternalIncident) => {
    const copy = activeIncidents.slice()
    copy.push(incident)

    setActiveIncidents(copy)
  }

  const pushNewRoom = (newChat: BreakoutRoom) => {
    const copy = chatRooms.slice()
    const chats = [newChat, ...copy]

    /* On Read socket messaging */
    subscribe<ReadChannelConfig>({ channel: 'ConversationsChannel', room_id: newChat.id }, {
      received: (x) => {
        const recipientUpdated : BackendRecipient = x.recipient
        updateReadChatRef.current(recipientUpdated, newChat.id)
      },
    })

    /* On Read socket messaging */
    setChatRooms(chats.sort(chatsSorting))
  }

  const pushNewSOPs = (newSOPs: SOP[]) => {
    const copy = sopList.slice()
    setSopList([...copy, ...newSOPs])
  }

  const updateSOP = (updated: SOP) => {
    const copy = sopList.slice()
    const index = copy.findIndex(({ id }) => id === updated.id)

    if (index < 0) { return }
    copy.splice(index, 1, { ...updated })
    setSopList(copy)
  }

  const deleteSOP = (sop: SOP) => {
    const copy = sopList.slice()
    const index = sopList.findIndex(({ id }) => id === sop.id)
    copy.splice(index, 1)

    setSopList(copy)
  }

  return {
    activeIncidents,
    closedIncidents,
    displayView,
    dispatchView,
    SOPTypes,
    users,
    updateIncident,
    focusedRoom,
    setFocusedRoom,
    chatRooms,
    updateTeam,
    pushNewRoom,
    pushIncident,
    fetchAppData,
    incidentTypes,
    actors,
    causes,
    loading,
    setLoading,
    countiesGeoJSON,
    fetchIncidentData,
    closeIncident,
    sopList,
    pushNewSOPs,
    updateSOP,
    deleteSOP,
    setSopList,
    nonCriticalTeam,
    emergencyTeam,
    crisisTeam,
    managementTeam,
    risksDescriptions,
    refreshRooms,
    updateRoom,
    pushLatestMessage,
    setChatAsRead,
    domainUsers,
    leaveRoom,
  }
}

export const ProvideIncidentData = ({ children }) => {
  const data = useProvideIncidentsData()
  return (
    <incidentManagementContext.Provider value={data}>
      {
        children
      }
    </incidentManagementContext.Provider>
  )
}
