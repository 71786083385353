import React, { useState } from 'react'
import { Icon } from '@iconify/react'
import { IonRow, IonCol } from '@ionic/react'
import { PopupContainer, PopupBackground, SafetyButton } from './StyledContainers'
import { useSafetyCheckPopup } from '../../hooks/safety-checks/useSafetyCheckPopup'
import { useGeolocation } from '../../hooks/useGeolocation'
import { useNotification } from '../../hooks/useNotification'
import { useAuth } from '../../hooks/useAuth'
import { SimpleButton } from '../maps/StyledContainers'
import FormPopup from '../maps/forms/FormPopup'
import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'

/**
 *  ===============================
 *     SAFETY CHECK POPUP UI
 *  ===============================
 */
const SafetyCheckPopup = () => {
  const [, hide, isShowing, notificationId] = useSafetyCheckPopup()
  const [submitted, setSubmitted] = useState(false)
  const auth = useAuth()
  const apiHook = useApi()
  const geoData = useGeolocation()
  const notification = useNotification()
  const { workspace } = useWorkspace()

  const { user } = auth

  /* Send request to store the safety check update */
  const handleClick = (level, preventSubmit) => {
    apiHook.submitOneSafety(
      {
        user_id: user.user_id,
        latitude: geoData.geolocation.lat,
        longitude: geoData.geolocation.lng,
        level,
        domain_id: workspace.id,
      },
    ).then((data) => console.log(data))
    notification.markNotificationAsRead(notificationId, true)

    if (!preventSubmit) {
      setSubmitted(true)
    }
  }

  return isShowing ? (
    <>
      <PopupBackground />
      <PopupContainer className='safety-check-popup'>
        {
          (submitted) ? (
            <FormPopup
              message='Safety Update Submitted'
              onClose={() => {
                setSubmitted(false)
                hide()
              }}
            />
          ) : (
            <>
              <h4>Safety Update</h4>
              <p>You have been requested to submit a Safety Update.</p>
              <p>Which status  best reflects how you feel about your safety and security?</p>
              <IonRow className='ion-justify-content-between safety-options' style={{ marginTop: '10px' }}>
                <IonCol size={4}>
                  <SafetyButton onClick={() => handleClick('Green')} className='safety-check-option-green'>
                    <Icon icon='charm:face-smile' />
                    <p>I feel safe</p>
                  </SafetyButton>
                </IonCol>
                <IonCol size={4}>
                  <SafetyButton onClick={() => handleClick('Amber')} className='safety-check-option-yellow'>
                    <Icon icon='charm:face-neutral' />
                    <p>I feel cautious</p>
                  </SafetyButton>
                </IonCol>
                <IonCol size={4}>
                  <SafetyButton onClick={() => handleClick('Red')} className='safety-check-option-red'>
                    <Icon icon='charm:face-frown' />
                    <p>I feel unsafe</p>
                  </SafetyButton>
                </IonCol>
              </IonRow>
              <IonRow className='ion-justify-content-center'>
                <SimpleButton onClick={() => {
                  handleClick('Dismissed', true)
                  hide()
                }}
                >Dismiss
                </SimpleButton>
              </IonRow>
            </>
          )
        }
      </PopupContainer>
    </>
  ) : null
}

export default SafetyCheckPopup
