import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import useApi from '../../hooks/testHooks'
import { SimpleButton } from '../maps/StyledContainers'

//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SecurityChangePopup = ({
  setStep, noti_id, sec_cond, previous_sec_cond, county, hide, domainId, date,
}) => {
  const handleNext = () => {
    setStep(2)
  }

  const apiHook = useApi()

  const handleDownload = () => {
    apiHook.downloadSecurityChange({
      domain_id: domainId,
      notification_id: noti_id,
    })
      .then((data) => {
        const when = moment(date).format('DD_MM_YYYY')
        const filename = (`Security Change - ${county} ${when}.pdf`)
        apiHook.downloader2(data, filename)
      })
  }

  const handleClose = () => {
    setStep(0)
    hide()
  }

  function getColor(level) {
    if (level === 'Level 1 - Low') {
      return '#14C105'
    } if (level === 'Level 2 - Guarded') {
      return '#349DFF'
    } if (level === 'Level 3 - Elevated') {
      return '#FFE500'
    } if (level === 'Level 4 - High') {
      return '#FF8A00'
    }
    return '#FF0000'
  }

  const secConds = [['Level 1 - Low', 'Serious security incidents occurring less than once a year.'],
    ['Level 2 - Guarded', 'Serious security incidents occurring more than once a year.'],
    ['Level 3 - Elevated', 'Serious security incidents occurring more than once a month.​'],
    ['Level 4 - High', 'Serious security conditions occurring more than once a week.​'],
    ['Level 5 - Severe', 'Serious security incidents occurring on a daily basis.​']]

  const secs = [[secConds[sec_cond.condition - 1]], [secConds[previous_sec_cond.condition - 1]]]
  const now = secs[0][0]
  const prev = secs[1][0]

  return (
    <>
      <PopupBackground style={{ zIndex: 1 }} />
      <OverlayContainer style={{ height: '550px', width: '650px' }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '20px' }}>Security Condition Change</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonList>
          <IonRow style={{ paddingTop: '25px', paddingLeft: '10px' }}>
            <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ color: '#8E151F', fontWeight: 'bold' }}>County:</IonLabel></IonCol>
            <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ color: '#8E151F', fontWeight: 'bold' }}>Date of Change:</IonLabel></IonCol>
          </IonRow>
          <IonRow style={{ paddingLeft: '10px' }}>
            <IonCol style={{ textAlign: 'left' }}><IonLabel>{`${county} County`}</IonLabel></IonCol>
            <IonCol style={{ textAlign: 'left' }}><IonLabel>{moment(date).format('DD/MM/YYYY')}</IonLabel></IonCol>
          </IonRow>
        </IonList>

        <IonRow style={{ paddingTop: '10px', paddingLeft: '10px' }}>
          <IonLabel>{`The following report has been generated as a result of a Security Condition change for ${county} County.`}</IonLabel>
        </IonRow>

        <IonRow style={{ paddingTop: '20px', paddingLeft: '10px' }}>
          <IonLabel>Previous Security Condition:</IonLabel>
        </IonRow>

        <IonGrid style={{ paddingTop: '7px', paddingLeft: '10px' }}>
          <IonRow style={{ outline: 'solid 2px' }}>
            <IonCol size='3' style={{ backgroundColor: getColor(prev[0]) }}>
              <IonLabel style={{ fontSize: '12px', color: (prev[0] === 'Level 3 - Elevated') ? '#000000' : '#FFFFFF' }}>{prev[0]}</IonLabel>
            </IonCol>
            <IonCol size='9' style={{ outline: 'solid 2px' }}>
              <IonLabel style={{ fontSize: '12px' }}>{ prev[1] }</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonRow style={{ paddingTop: '10px', paddingLeft: '10px' }}>
          <IonLabel>New Security Condition:</IonLabel>
        </IonRow>

        <IonGrid style={{ paddingTop: '7px', paddingLeft: '10px' }}>
          <IonRow style={{ outline: 'solid 2px' }}>
            <IonCol size='3' style={{ backgroundColor: getColor(now[0]) }}>
              <IonLabel style={{ fontSize: '12px', color: (now[0] === 'Level 3 - Elevated') ? '#000000' : '#FFFFFF' }}>{now[0]}</IonLabel>
            </IonCol>
            <IonCol size='9' style={{ outline: 'solid 2px' }}>
              <IonLabel style={{ fontSize: '12px' }}>{now[1]}</IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonRow style={{ paddingTop: '85px' }}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9', paddingLeft: '10px' }} disabled>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default SecurityChangePopup
