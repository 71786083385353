import { IonButton, IonRow } from '@ionic/react'
import React, { FC, useEffect } from 'react'
import { OverlayContainer } from '../../../GlobalContainers'
import DragDropComm from '../../DragDropComm'
import { useTerrainMapping } from '../../../../hooks/terrain-mapping/useTerrainMapping'

interface Props {
  onClose: () => void,
  onSubmit: (data: FileList) => void,
}

const ImportComm : FC<Props> = ({ onClose, onSubmit }) => {
  const {
    files, setFiles,
  } = useTerrainMapping()

  useEffect(() => {
    setFiles(null)
  }, [])

  return (
    <OverlayContainer style={{ zIndex: 20, width: 'auto' }}>
      <h4 style={{ marginBottom: '15px' }} className='red-font'>Import Community (.KML)</h4>
      <DragDropComm setFiles={setFiles} files={files} />
      <IonRow style={{ marginTop: '20px' }} className='ion-justify-content-end'>
        <IonButton onClick={onClose} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        <IonButton style={{ '--background': '#4197A9' }} disabled={!files || files.length === 0} onClick={() => onSubmit(files)}>Upload</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default ImportComm
