/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import { IonRow, useIonAlert } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import { Details } from '../../maps/features/Flashpoint'
import { SimpleButton } from '../../maps/StyledContainers'
import { FlashpointStatus, IncidentCause } from '../../types/GlobalTypes'
import axios from '../../../utils/axios'
import { Flashpoint, Stakeholder } from '../../types/OptimizedMaps'
import {
  BackButton, IssueTag, PopoverContainer, PopoverField,
} from '../StyledContainer'
import { useWorkspace } from '../../../hooks/useWorkspace'

const FlashpointScreen : FC = () => {
  const {
    focusedElems, dispatchFocused, setEditing, updateFlashpoint, setSaving, manifestations,
    saving, editing, causes, stakeholders, communities, setLoading, setSubmittedMessage,
  } = useTerrainMapping()
  const { focusedFlashpoint } = focusedElems

  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()
  const [issues, setIssues] = useState<(IncidentCause & { selected: boolean })[]>([])
  const [input, setInput] = useState<Details>({
    title: '',
    description: '',
    triggers: '',
    relevantAreas: [],
    comments: '',
    primaryManifestation: '',
    additionalManifestations: [''],
    primaryStakeholders: [],
    secondaryStakeholders: [],
    tertiaryStakeholders: [],
    status: '',
  })

  const addManifestation = () => {
    const copy = input.additionalManifestations.slice()
    copy.push('')

    setInput({ ...input, additionalManifestations: copy })
  }

  const selectCause = (index: number) => {
    const copy = issues.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setIssues(copy)
  }

  const updateAdditionalManifest = (newVal: string, index: number) => {
    const copy = input.additionalManifestations.slice()
    copy.splice(index, 1, newVal)

    setInput({ ...input, additionalManifestations: copy })
  }

  const getAddRemove = (newRecords: (Stakeholder | { id: number, name: string } | IncidentCause)[], oldRecords:
  (Stakeholder | { id: number, name: string } | IncidentCause)[]) : { toRemove: number[], toAdd: number[] } => {
    const toAdd = newRecords.filter(({ id }) => !oldRecords.find((val) => val.id === id))
    const toRemove = oldRecords.filter(({ id }) => !newRecords.find((val) => val.id === id))

    return {
      toAdd: toAdd.map(({ id }) => id),
      toRemove: toRemove.map(({ id }) => id),
    }
  }

  const errorMessages = {
    title: 'You need to provide a title for this flashpoint',
    description: 'No description provided',
    triggers: 'You must specify what triggers this flashpoint',
    status: 'No status selected',
    primaryManifestation: 'The primary manifestation is required',
    ongoing: 'Is the situation ongoing/seasonal?',
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const notFilled = keys.find((val) => input[val] === '' || !input[val])

    if (notFilled) {
      ionAlert({
        header: 'Error',
        message: errorMessages[notFilled],
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    const stakeholdersPrimary = stakeholders.filter(({ id }) => input.primaryStakeholders.find((val) => val.value === id))
    const stakeholdersSecondary = stakeholders.filter(({ id }) => input.secondaryStakeholders.find((val) => val.value === id))
    const stakeholdersTertiary = stakeholders.filter(({ id }) => input.tertiaryStakeholders.find((val) => val.value === id))

    const selectedIssues = issues.filter((val) => val.selected)

    const primaryRecords = getAddRemove(stakeholdersPrimary, focusedFlashpoint.primary_groups)
    const secondaryRecords = getAddRemove(stakeholdersSecondary, focusedFlashpoint.secondary_groups)
    const tertiaryRecords = getAddRemove(stakeholdersTertiary, focusedFlashpoint.tertiary_groups)

    const issueRecords = getAddRemove(selectedIssues, focusedFlashpoint.issues)
    const communityRecords = getAddRemove(input.relevantAreas.map(({ value, label }) => ({ id: value, name: label })), focusedFlashpoint.relevant_areas)

    const additionalMani = input.additionalManifestations.filter((val) => val !== '').map((val, index) => ({ id: index, name: val }))

    setLoading(true)
    axios.put('/api/v2/flashpoint/edit_flashpoint', {
      flashpoint_id: focusedFlashpoint.id,
      domain_id: workspace.id,
      title: input.title,
      description: input.description,
      triggers: input.triggers,
      status: input.status,
      primary_manifestation: input.primaryManifestation,
      ongoing: input.ongoing === 'Yes',
      comments: input.comments,
      primary_stakeholders_add: primaryRecords.toAdd,
      primary_stakeholders_remove: primaryRecords.toRemove,
      secondary_stakeholders_add: secondaryRecords.toAdd,
      secondary_stakeholders_remove: secondaryRecords.toRemove,
      tertiary_stakeholders_add: tertiaryRecords.toAdd,
      tertiary_stakeholders_remove: tertiaryRecords.toRemove,
      issues_add: issueRecords.toAdd,
      issues_remove: issueRecords.toRemove,
      community_add: communityRecords.toAdd,
      community_remove: communityRecords.toRemove,
      manifestations_add: additionalMani.filter(({ name }) => !focusedFlashpoint.additionalManifestation.find((val) => val.name === name)).map(({ name }) => name),
      manifestations_remove: focusedFlashpoint.additionalManifestation.filter(({ name }) => !additionalMani.find((val) => val.name === name)).map(({ name }) => name),
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }

      const newFlashpoint : Flashpoint = {
        id: focusedFlashpoint.id,
        title: input.title,
        description: input.description,
        triggers: input.triggers,
        status: input.status as FlashpointStatus,
        primaryManifestation: input.primaryManifestation,
        additionalManifestation: additionalMani,
        ongoing: (input.ongoing === 'Yes'),
        issues: selectedIssues,
        primary_groups: stakeholdersPrimary,
        secondary_groups: stakeholdersSecondary,
        tertiary_groups: stakeholdersTertiary,
        relevant_areas: input.relevantAreas.map(({ value, label }) => ({ id: value, name: label })),
        comments: input.comments,
      }
      setSubmittedMessage('Flashpoint updated Successfully')
      updateFlashpoint(newFlashpoint)
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown Server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setEditing(false)
      setSaving(false)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!saving) { return }
    handleSubmit()
  }, [saving])

  useEffect(() => {
    if (!editing) {
      return
    }

    setInput({
      title: focusedFlashpoint.title,
      description: focusedFlashpoint.description,
      triggers: focusedFlashpoint.triggers,
      relevantAreas: focusedFlashpoint.relevant_areas.map(({ id, name }) => ({ value: id, label: name })),
      comments: focusedFlashpoint.comments,
      primaryManifestation: focusedFlashpoint.primaryManifestation,
      additionalManifestations: focusedFlashpoint.additionalManifestation.map(({ name }) => name),
      primaryStakeholders: focusedFlashpoint.primary_groups.map(({ id, name }) => ({ value: id, label: name })),
      secondaryStakeholders: focusedFlashpoint.secondary_groups.map(({ id, name }) => ({ value: id, label: name })),
      tertiaryStakeholders: focusedFlashpoint.tertiary_groups.map(({ id, name }) => ({ value: id, label: name })),
      status: focusedFlashpoint.status,
      ongoing: focusedFlashpoint.ongoing ? 'Yes' : 'No',
    })
    setIssues(causes.map((val) => ({ ...val, selected: !!focusedFlashpoint.issues.find(({ id }) => val.id === id) })))
  }, [editing])

  return (
    <ListContainer style={{ height: '100%', background: '#DCDCDC' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <BackButton onClick={() => {
          dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
          setEditing(false)
        }}
        >Back to List
        </BackButton>
        <PopoverContainer>
          <PopoverField>
            <h6>Title</h6>
            {
              editing ? (
                <input placeholder='Give this flashpoint a title' type='text' value={input.title} onChange={(e) => setInput({ ...input, title: e.target.value })} />
              ) : (
                <p>{ focusedFlashpoint.title }</p>
              )
            }
          </PopoverField>
          <PopoverField className='terrain-form-field form-desc'>
            <h6>Description</h6>
            {
              editing ? (
                <textarea
                  style={{ height: '10em' }}
                  value={input.description}
                  onChange={(e) => setInput({ ...input, description: e.target.value })}
                />
              ) : (
                <p>{ focusedFlashpoint.description }</p>
              )
            }
          </PopoverField>
          <PopoverField className='terrain-form-field form-desc'>
            <h6>Triggers</h6>
            {
              editing ? (
                <textarea
                  style={{ height: '10em' }}
                  value={input.triggers}
                  onChange={(e) => setInput({ ...input, triggers: e.target.value })}
                />
              ) : (
                <p>{ focusedFlashpoint.triggers }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Status</h6>
            {
              editing ? (
                <select
                  value={input.status}
                  onChange={(e) => setInput({ ...input, status: e.target.value as FlashpointStatus })}
                  style={{ padding: '5px 10px' }}
                >
                  <option value='' disabled>Select Status</option>
                  <option value={FlashpointStatus.ACTIVE}>{ FlashpointStatus.ACTIVE }</option>
                  <option value={FlashpointStatus.BUILDING}>{ FlashpointStatus.BUILDING }</option>
                  <option value={FlashpointStatus.DORMANT}>{ FlashpointStatus.DORMANT }</option>
                </select>
              ) : (
                <p>{ focusedFlashpoint.status }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Primary Manifestation</h6>
            {
              editing ? (
                <select
                  value={input.primaryManifestation}
                  onChange={(e) => setInput({ ...input, primaryManifestation: e.target.value })}
                  style={{ padding: '5px 10px' }}
                >
                  <option value='' disabled>Select manifestation</option>
                  {
                    manifestations.map((manifestation) => (
                      <option key={manifestation} value={manifestation}>{ manifestation }</option>
                    ))
                  }
                </select>
              ) : (
                <p>{ focusedFlashpoint.primaryManifestation }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Additional Manifestations</h6>
            {
              editing ? (
                <>
                  {
                    input.additionalManifestations.map((val, index) => (
                      <div key={index} style={{ margin: '20px 0' }} className='terrain-form-field'>
                        <h6>Additional Manifestation (optional)</h6>
                        <select
                          value={val}
                          onChange={(e) => updateAdditionalManifest(e.target.value, index)}
                          style={{ padding: '5px 10px' }}
                        >
                          <option value='' disabled>Select manifestation</option>
                          {
                            manifestations.map((manifestation) => (
                              <option key={manifestation} value={manifestation}>{ manifestation }</option>
                            ))
                          }
                        </select>
                      </div>
                    ))
                  }
                  <SimpleButton style={{ fontSize: '0.8rem' }} onClick={addManifestation}>Add Additional Manifestation +</SimpleButton>
                </>
              ) : (
                <>
                  {
                    focusedFlashpoint.additionalManifestation.length === 0 ? (
                      <p>No additional manifestations</p>
                    ) : (
                      <ul>
                        {
                          focusedFlashpoint.additionalManifestation.map((val) => (
                            <li key={val.id}><p>{ val.name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Is this flashpoint ongoing/seasonal?</h6>
            {
              editing ? (
                <IonRow>
                  <label style={{ textAlign: 'center', marginRight: '10px' }}>
                    <input
                      type='radio'
                      value='yes'
                      checked={input.ongoing === 'Yes'}
                      onChange={() => setInput({ ...input, ongoing: 'Yes' })}
                    />
                    Yes
                  </label>
                  <label style={{ textAlign: 'center' }}>
                    <input
                      type='radio'
                      value='No'
                      checked={input.ongoing === 'No'}
                      onChange={() => setInput({ ...input, ongoing: 'No' })}
                    />
                    No
                  </label>
                </IonRow>
              ) : (
                <p>{ (focusedFlashpoint.ongoing) ? 'Yes' : 'No' }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Proximate Issues</h6>
            {
              editing ? (
                <>
                  {
                    issues.map((val, index) => (
                      <TagButton
                        key={val.id}
                        onClick={() => selectCause(index)}
                        style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                      >
                        { val.name }
                      </TagButton>
                    ))
                  }
                </>
              ) : (
                <>
                  {
                    focusedFlashpoint.issues.map((val) => (
                      <IssueTag key={val.id}>{ val.name }</IssueTag>
                    ))
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Primary Associated Stakeholder</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Areas'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                  value={input.primaryStakeholders}
                  onChange={(selected) => setInput({ ...input, primaryStakeholders: selected })}
                />
              ) : (
                <>
                  {
                    !focusedFlashpoint.primary_groups?.length ? (
                      <p>No primary stakeholders.</p>
                    ) : (
                      <ul>
                        {
                          focusedFlashpoint.primary_groups.map((val) => (
                            <li key={val.id}><p>{ val.name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Secondary Associated Stakeholder</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Areas'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                  value={input.secondaryStakeholders}
                  onChange={(selected) => setInput({ ...input, secondaryStakeholders: selected })}
                />
              ) : (
                <>
                  {
                    !focusedFlashpoint.secondary_groups?.length ? (
                      <p>No secondary stakeholders.</p>
                    ) : (
                      <ul>
                        {
                          focusedFlashpoint.secondary_groups.map((val) => (
                            <li key={val.id}><p>{ val.name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Tertiary Associated Stakeholder</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Areas'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                  value={input.tertiaryStakeholders}
                  onChange={(selected) => setInput({ ...input, tertiaryStakeholders: selected })}
                />
              ) : (
                <>
                  {
                    !focusedFlashpoint.tertiary_groups?.length ? (
                      <p>No tertiary stakeholders.</p>
                    ) : (
                      <ul>
                        {
                          focusedFlashpoint.tertiary_groups.map((val) => (
                            <li key={val.id}><p>{ val.name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Relevant Areas</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Areas'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                  value={input.relevantAreas}
                  onChange={(selected) => setInput({ ...input, relevantAreas: selected })}
                />
              ) : (
                <>
                  {
                    !focusedFlashpoint.relevant_areas.length ? (
                      <p>No relevant areas.</p>
                    ) : (
                      <ul>
                        {
                          focusedFlashpoint.relevant_areas.map((val) => (
                            <li key={val.id}><p>{ val.name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField className='terrain-form-field form-desc'>
            <h6>Additional Comments</h6>
            {
              editing ? (
                <textarea value={input.comments} onChange={(e) => setInput({ ...input, comments: e.target.value })} />
              ) : (
                <p>{ focusedFlashpoint.comments }</p>
              )
            }
          </PopoverField>
        </PopoverContainer>
      </div>
    </ListContainer>
  )
}

export default FlashpointScreen
