import { IonRow, useIonAlert } from '@ionic/react'
import React, { FC, useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import PhoneInput from 'react-phone-number-input'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { Details } from '../../maps/features/Stakeholder'
import {
  Community, Stakeholder, StakeholderGroup, StakeholderQuadrant,
} from '../../types/OptimizedMaps'
import {
  BackButton, IssueTag, PopoverContainer, PopoverField,
} from '../StyledContainer'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { TagButton } from '../../incident-management/StyledContainers'

const StakeholderScreen : FC = () => {
  const {
    focusedElems, dispatchFocused, editing, saving, communities, stakeholders,
    setSaving, setEditing, setLoading, stakeholderGroups, updateStakeholder, setSubmittedMessage,
  } = useTerrainMapping()
  const { focusedStakeholder } = focusedElems
  const { workspace } = useWorkspace()
  const [input, setInput] = useState<Details>({
    name: '',
    alias: '',
    type: '',
    role: '',
    organisations: [],
    areas: [],
    leaders: [],
    parents: [],
    subdivisions: [],
    managements: [],
    analysis: '',
    whatsapp_number: '',
    phone_number: '',
    email: '',
  })
  console.log('stakeholder', focusedStakeholder)
  const [groupSelections, setGroupSelections] = useState<(StakeholderGroup & { selected: boolean })[]>([])
  const [ionAlert] = useIonAlert()

  const errorMessages = {
    name: 'You need to provide the name for this stakeholder',
    //alias: 'No alias provided for this stakeholder',
    //type: 'Type cannot be undefined',
    //role: 'No stakeholder role provided',
    //analysis: 'Give a brief analysis of the stakeholder',
    //whatsapp_number: 'Please provide a whatsapp number for this stakeholder',
    //phone_number: 'Please provide a phone number',
    //email: 'Stakeholder email missing',
  }

  const getAddRemove = (newRecords: ({ id: number, name: string } | Community | StakeholderGroup)[], oldRecords:
  ({ id: number, name: string } | Community | StakeholderGroup)[]) : { toRemove: number[], toAdd: number[] } => {
    const toAdd = newRecords.filter(({ id }) => !oldRecords.find((val) => val.id === id))
    const toRemove = oldRecords.filter(({ id }) => !newRecords.find((val) => val.id === id))

    return {
      toAdd: toAdd.map(({ id }) => id),
      toRemove: toRemove.map(({ id }) => id),
    }
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const messageKey = keys.find((val) => !input[val] || input[val] === '')

    if (messageKey) {
      ionAlert({
        header: 'Error',
        message: errorMessages[messageKey],
        buttons: [{
          text: 'Ok',
        }],
      })
      return
    }

    // if (!input.email.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)) {
    //   ionAlert({
    //     header: 'Error',
    //     message: 'Email not valid',
    //     buttons: [{ text: 'Ok' }],
    //   })
    //   return
    // }

    const selectedGroups = groupSelections.filter(({ selected }) => selected)
    const selectedCommunities = communities.filter(({ id }) => input.areas.find((val) => val.value === id))
    const selectedOrganisations = input.organisations.map(({ value, label }) => ({ id: value, name: label }))

    const selectedLeaders = input.leaders.map(({ value, label }) => ({ id: value, name: label }))
    const selectedParents = input.parents.map(({ value, label }) => ({ id: value, name: label }))
    const selectedManagement = input.managements.map(({ value, label }) => ({ id: value, name: label }))
    const selectedSubdivisions = input.subdivisions.map(({ value, label }) => ({ id: value, name: label }))

    const communityRecords = getAddRemove(selectedCommunities, focusedStakeholder.areas)
    const groupRecords = getAddRemove(selectedGroups, focusedStakeholder.groups)
    const organisationRecords = getAddRemove(selectedOrganisations, focusedStakeholder.organisations)

    const leaderRecords = getAddRemove(selectedLeaders, focusedStakeholder.leaders)
    const parentRecords = getAddRemove(selectedParents, focusedStakeholder.parents)
    const managementRecords = getAddRemove(selectedManagement, focusedStakeholder.managements)
    const subsRecords = getAddRemove(selectedSubdivisions, focusedStakeholder.subdivisions)
    const quadrantCommunities = selectedCommunities.map((val) => {
      const previous = focusedStakeholder.areas.find((area) => area.id === val.id)

      if (previous) {
        return { ...val, quadrant: previous.quadrant }
      }

      return { ...val, quadrant: StakeholderQuadrant.LOW_LOW }
    })

    setLoading(true)
    axios.put('api/v2/stakeholder/edit_stakeholder', {
      domain_id: workspace.id,
      alias_name: input.alias,
      name: input.name,
      type: input.type,
      comments: input.analysis,
      role: input.role,
      whatsapp_number: input.whatsapp_number,
      phone_number: input.phone_number,
      email: input.email,
      stakeholder_id: focusedStakeholder.id,
      organisations_remove: organisationRecords.toRemove,
      organisations_add: organisationRecords.toAdd,
      community_add: communityRecords.toAdd,
      community_remove: communityRecords.toRemove,
      groups_add: groupRecords.toAdd,
      groups_remove: groupRecords.toRemove,
      parents_add: parentRecords.toAdd,
      parents_remove: parentRecords.toRemove,
      leaders_add: leaderRecords.toAdd,
      leaders_remove: leaderRecords.toRemove,
      management_add: managementRecords.toAdd,
      management_remove: managementRecords.toRemove,
      subs_add: subsRecords.toAdd,
      subs_remove: subsRecords.toRemove,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      updateStakeholder({
        id: focusedStakeholder.id,
        name: input.name,
        alias: input.alias,
        type: input.type as ('Organisation' | 'Individual'),
        organisations: selectedOrganisations,
        areas: quadrantCommunities,
        analysis: input.analysis,
        leaders: input.leaders,
        parents: input.parents,
        managements: input.managements,
        subdivisions: input.subdivisions,
        role: input.role,
        groups: selectedGroups,
        email: input.email,
        whatsapp_number: input.whatsapp_number,
        phone_number: input.phone_number,
      })
      setSubmittedMessage('Stakeholder updated successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setEditing(false)
      setSaving(false)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!saving) { return }
    handleSubmit()
  }, [saving])

  useEffect(() => {
    if (!editing) { return }
    setInput({
      name: focusedStakeholder.name,
      alias: focusedStakeholder.alias,
      type: focusedStakeholder.type,
      role: focusedStakeholder.role,
      areas: focusedStakeholder.areas.map(({ id, name }) => ({ label: name, value: id })),
      leaders: focusedStakeholder?.leaders?.map((stakeholder) => ({
        ...stakeholder, // spread all properties of the stakeholder
        label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
        value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
      })),
      parents: focusedStakeholder?.parents?.map((stakeholder) => ({
        ...stakeholder, // spread all properties of the stakeholder
        label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
        value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
      })),
      managements: focusedStakeholder?.managements?.map((stakeholder) => ({
        ...stakeholder, // spread all properties of the stakeholder
        label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
        value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
      })),
      subdivisions: focusedStakeholder?.subdivisions?.map((stakeholder) => ({
        ...stakeholder, // spread all properties of the stakeholder
        label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
        value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
      })),
      organisations: focusedStakeholder.organisations.map(({ id, name }) => ({ label: name, value: id })),
      analysis: focusedStakeholder.analysis,
      whatsapp_number: focusedStakeholder.whatsapp_number,
      phone_number: focusedStakeholder.phone_number,
      email: focusedStakeholder.email,
    })
    setGroupSelections(stakeholderGroups.map((val) => ({ ...val, selected: !!focusedStakeholder.groups.find(({ id }) => id === val.id) })))
  }, [editing])

  return (
    <ListContainer style={{ background: '#DCDCDC', height: '100%' }}>
      <StakeholderDetails
        onBack={() => {
          dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
          setEditing(false)
        }}
        editing={editing}
        focusedStakeholder={focusedStakeholder}
        input={input}
        setInput={setInput}
        stakeholders={stakeholders}
        communities={communities}
        groupSelections={groupSelections}
        workspace={workspace}
        setGroupSelections={setGroupSelections}
      />
    </ListContainer>
  )
}

export default StakeholderScreen

interface DetailProps {
  onBack: () => void,
  editing: boolean,
  focusedStakeholder: Stakeholder,
  input: Details,
  setInput: React.Dispatch<React.SetStateAction<Details>>,
  stakeholders: Stakeholder[],
  communities: Community[],
  groupSelections: (StakeholderGroup & { selected: boolean })[],
  workspace: any,
  setGroupSelections: React.Dispatch<React.SetStateAction<(StakeholderGroup & { selected: boolean })[]>>,
}

export const StakeholderDetails : FC<DetailProps> = ({
  onBack, editing, focusedStakeholder, input, setInput, stakeholders,
  communities, groupSelections, setGroupSelections, workspace,
}) => {
  const selectCause = (index: number) => {
    const copy = groupSelections.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setGroupSelections(copy)
  }

  return (
    <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
      <BackButton onClick={onBack}>Back to List
      </BackButton>
      <PopoverContainer>
        <IonRow className='ion-align-items-center'>
          <PopoverField style={{ flex: 1 }}>
            <h6>Stakeholder Name</h6>
            {
            editing ? (
              <input placeholder='Give this stakeholder a name' type='text' value={input.name} onChange={(e) => setInput({ ...input, name: e.target.value })} />
            ) : (
              <p>{ focusedStakeholder.name }</p>
            )
          }
          </PopoverField>
          <PopoverField style={{ flex: 1 }}>
            <h6>Alias</h6>
            {
            editing ? (
              <input
                placeholder='Stakeholder alias'
                type='text'
                value={input.alias}
                onChange={(e) => setInput({ ...input, alias: e.target.value })}
              />
            ) : (
              <p>{ focusedStakeholder.alias }</p>
            )
          }
          </PopoverField>
          <PopoverField style={{ flex: 1 }}>
            <h6>Type</h6>
            {
            editing ? (
              <select
                value={input.type}
                onChange={(e) => setInput({ ...input, type: e.target.value as ('Organisation' | 'Individual' | 'Household') })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select Type</option>
                <option value='Organisation'> Organisation </option>
                <option value='Individual'> Individual </option>
                <option value='Household'> Household </option>
              </select>
            ) : (
              <p>{ focusedStakeholder.type }</p>
            )
          }
          </PopoverField>
        </IonRow>
        {
          focusedStakeholder.type === 'Organisation' && (
            <>
              <IonRow className='ion-align-items-center'>
                <PopoverField style={{ flex: 1 }}>
                  <h6>Parent Body</h6>
                  {
                    editing ? (
                      <CreatableSelect
                        isMulti
                        placeholder='Select Parent Organisations'
                        name='colors'
                        className='select-container'
                        id='parents'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={
                          stakeholders
                            .filter(({ type }) => type === 'Organisation')
                            .map((stakeholder) => ({
                              ...stakeholder, // spread all properties of the stakeholder
                              label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
                              value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
                            }))
                        }
                        value={input.parents}
                        onChange={(selected) => setInput({ ...input, parents: selected })}
                      />
                    ) : (
                      <>
                        {
                          focusedStakeholder?.parents?.length === 0 ? (
                            <p>No stakeholders</p>
                          ) : (
                            <ul>
                              {
                                  focusedStakeholder?.parents?.map(({ name, id }) => (
                                    <li key={id}><p>{ name}</p></li>
                                  ))
                                }
                            </ul>
                          )
                        }
                      </>
                    )
                  }
                </PopoverField>
                <PopoverField style={{ flex: 1 }}>
                  <h6>Subdivisions</h6>
                  {
                    editing ? (
                      <CreatableSelect
                        isMulti
                        placeholder='Select Subdivisions'
                        name='colors'
                        className='select-container'
                        id='journey-passengers'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={
                          stakeholders
                            .filter(({ type }) => type === 'Organisation')
                            .map((stakeholder) => ({
                              ...stakeholder, // spread all properties of the stakeholder
                              label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
                              value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
                            }))
                        }
                        value={input.subdivisions}
                        onChange={(selected) => setInput({ ...input, subdivisions: selected })}
                      />
                    ) : (
                      <>
                        {
                          focusedStakeholder?.subdivisions?.length === 0 ? (
                            <p>No stakeholders</p>
                          ) : (
                            <ul>
                              {
                                  focusedStakeholder?.subdivisions?.map(({ name, id }) => (
                                    <li key={id}><p>{ name}</p></li>
                                  ))
                                }
                            </ul>
                          )
                        }
                      </>
                    )
                  }
                </PopoverField>
                <PopoverField style={{ flex: 1 }}>
                  <h6>Leaders</h6>
                  {
                    editing ? (
                      <CreatableSelect
                        isMulti
                        placeholder='Select Leader'
                        name='colors'
                        className='select-container'
                        id='journey-passengers'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={
                          stakeholders
                            .filter(({ type }) => type === 'Individual')
                            .map((stakeholder) => ({
                              ...stakeholder, // spread all properties of the stakeholder
                              label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
                              value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
                            }))
                        }                        
                        value={input.leaders}
                        onChange={(selected) => setInput({ ...input, leaders: selected })}
                      />
                    ) : (
                      <>
                        {
                          focusedStakeholder?.leaders?.length === 0 ? (
                            <p>No stakeholders</p>
                          ) : (
                            <ul>
                              {
                                  focusedStakeholder?.leaders?.map(({ name, id }) => (
                                    <li key={id}><p>{ name}</p></li>
                                  ))
                                }
                            </ul>
                          )
                        }
                      </>
                    )
                  }
                </PopoverField>
              </IonRow>
              <IonRow>
                <PopoverField style={{ flex: 1 }}>
                  <h6>Management</h6>
                  {
                    editing ? (
                      <CreatableSelect
                        isMulti
                        placeholder='Select Managers'
                        name='colors'
                        className='select-container'
                        id='journey-passengers'
                      // eslint-disable-next-line no-undef
                        menuPortalTarget={document.body}
                        options={
                          stakeholders
                            .filter(({ type }) => type === 'Individual')
                            .map((stakeholder) => ({
                              ...stakeholder, // spread all properties of the stakeholder
                              label: stakeholder.name, // ensure label is set to the name, as required by CreatableSelect
                              value: stakeholder.id, // ensure value is set to the id, as required by CreatableSelect
                            }))
                        }
                        value={input.managements}
                        onChange={(selected) => setInput({ ...input, managements: selected })}
                      />
                    ) : (
                      <>
                        {
                          focusedStakeholder?.managements?.length === 0 ? (
                            <p>No stakeholders</p>
                          ) : (
                            <ul>
                              {
                                  focusedStakeholder?.managements?.map(({ name, id }) => (
                                    <li key={id}><p>{ name}</p></li>
                                  ))
                                }
                            </ul>
                          )
                        }
                      </>
                    )
                  }
                </PopoverField>
              </IonRow>
            </>
          )
        }
        <IonRow className='ion-align-items-center'>
          <PopoverField style={{ flex: 1 }}>
            <h6>Role/Position</h6>
            {
            editing ? (
              <input
                placeholder='Add Role/Position'
                type='text'
                value={input.role}
                onChange={(e) => setInput({ ...input, role: e.target.value })}
              />
            ) : (
              <p>{ focusedStakeholder.role }</p>
            )
          }
          </PopoverField>
          <PopoverField style={{ flex: 1 }}>
            <h6>Associated Organisations</h6>
            {
            editing ? (
              <CreatableSelect
                isMulti
                placeholder='Select Organisation'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.filter(({ type }) => type === 'Organisation').map(({ id, name }) => ({ label: name, value: id }))}
                value={input.organisations}
                onChange={(selected) => setInput({ ...input, organisations: selected })}
              />
            ) : (
              <>
                {
                  focusedStakeholder.organisations.length === 0 ? (
                    <p>No stakeholders</p>
                  ) : (
                    <ul>
                      {
                          focusedStakeholder.organisations.map(({ name, id }) => (
                            <li key={id}><p>{ name}</p></li>
                          ))
                        }
                    </ul>
                  )
                }
              </>
            )
              }
          </PopoverField>
          <PopoverField style={{ flex: 1 }}>
            <h6>Associated Location</h6>
            {
            editing ? (
              <CreatableSelect
                isMulti
                placeholder='Select Areas'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                value={input.areas}
                onChange={(selected) => setInput({ ...input, areas: selected })}
              />
            ) : (
              <>
                {
                  focusedStakeholder.areas.length === 0 ? (
                    <p>No Areas</p>
                  ) : (
                    <ul>
                      {
                        focusedStakeholder.areas.map(({ name, id }) => (
                          <li key={id}><p>{ name}</p></li>
                        ))
                      }
                    </ul>
                  )
                    }
              </>
            )
        }
          </PopoverField>
        </IonRow>
        <PopoverField className='terrain-form-field form-desc' style={{ flex: 1 }}>
          <h6>Analysis</h6>
          {
          editing ? (
            <textarea
              style={{ height: '10em' }}
              value={input.analysis}
              onChange={(e) => setInput({ ...input, analysis: e.target.value })}
            />
          ) : (
            <p>{ focusedStakeholder.analysis }</p>
          )
        }
        </PopoverField>
        <PopoverField style={{ flex: 1 }}>
          <h6>Stakeholder Groups</h6>
          {
          editing ? (
            <>
              {
                groupSelections.map((val, index) => (
                  <TagButton
                    key={val.id}
                    onClick={() => selectCause(index)}
                    style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                  >
                    { val.name }
                  </TagButton>
                ))
              }
            </>
          ) : (
            <>
              {
                    focusedStakeholder.groups.map((val) => (
                      <IssueTag key={val.id}>{ val.name }</IssueTag>
                    ))
                  }
            </>
          )
          }
        </PopoverField>
        <PopoverField style={{ flex: 1 }}>
          <h6>WhatsApp Number</h6>
          {
          editing ? (
            <PhoneInput
              international
              defaultCountry={workspace.country && workspace.country}
              type='text'
              value={input.whatsapp_number}
              placeholder='WhatsApp phone number'
              onChange={(val) => setInput({ ...input, whatsapp_number: val })}
              name='whatsapp_number'
            />
          ) : (
            <p>{ focusedStakeholder.whatsapp_number || 'No whatsapp phone number' }</p>
          )
        }
        </PopoverField>
        <PopoverField style={{ flex: 1 }}>
          <h6>Phone Number</h6>
          {
          editing ? (
            <PhoneInput
              international
              defaultCountry={workspace.country && workspace.country}
              type='text'
              value={input.phone_number}
              placeholder='Phone number'
              onChange={(val) => setInput({ ...input, phone_number: val })}
              name='phone_number'
            />
          ) : (
            <p>{ focusedStakeholder.phone_number || 'No phone number' }</p>
          )
        }
        </PopoverField>
        <PopoverField style={{ flex: 1 }}>
          <h6>Email</h6>
          {
          editing ? (
            <input
              placeholder='Stakeholder contact email'
              type='text'
              value={input.email}
              onChange={(e) => setInput({ ...input, email: e.target.value })}
            />
          ) : (
            <p>{ focusedStakeholder.email || 'No email provided' }</p>
          )
        }
        </PopoverField>
      </PopoverContainer>
    </div>
  )
}
