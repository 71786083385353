import React, { FC } from 'react'
import LoadingModal from '../modals/LoadingModal'
import { useRiskRegister } from '../../hooks/risks/useRiskRegister'
import MapControlsV2 from './MapControlsV2'
import RiskRegisterMapV2 from '../maps/apps/RiskRegisterMapV2'
import { AppView } from '../../hooks/risks/types/HookInterfaces'
import AssetProfileV2 from './AssetProfileV2'
import { ObscureBackground } from '../GlobalContainers'
import { CenterForm } from './StyledContainers'
import FormPopup from '../maps/forms/FormPopup'
import { ProvideAddAsset } from '../../hooks/risks/useAddAsset'

const AppWrapperV2 : FC = () => {
  const {
    loading, view, showSelectedAsset, submittedMessage, setSubmittedMessage,
  } = useRiskRegister()

  console.log(loading)

  return (
    <ProvideAddAsset>
      <LoadingModal isOpen={loading} />
      <MapControlsV2 />
      <RiskRegisterMapV2 />
      {
        view === AppView.ASSET_MAP && showSelectedAsset && (
          <AssetProfileV2 />
        )
      }
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
    </ProvideAddAsset>

  )
}

export default AppWrapperV2
