import * as turf from '@turf/turf'
import { AlertState } from './GlobalTypes'
import { Journey } from './Journeys'

export interface CountyProperties {
  Alert_state: number,
  modified: string,
  Alert_color: string,
  sc_level?: AlertState,
  county: string,
  shapeName: string,
  id: number,
  description: string
}

export enum ChapterType {
  CHECK_IN_ALL_GOOD = 'all_good',
  CHECK_IN_CHECKPOINT = 'checkpoint',
  CHECK_IN_REST = 'rest',
  CHECK_IN_BLOCKED = 'road_blocked',
  EMERGENCY = 'emergency',
  COMMENT = 'comment',
}

export interface ChapterProperties {
  journey: Journey,
  type: ChapterType,
  id: number,
  user_id: number,
}

export type ChaptersGeoJSON = turf.FeatureCollection<turf.Point, ChapterProperties>

export type JourneyHistory = turf.Feature<turf.LineString>

export enum JourneySegmentStatus {
  OFFLINE = 'offline',
  ONLINE = 'online',
}

export enum JourneySegmentPosition {
  FIRST = 'first',
  LAST = 'last',
  MIDDLE = 'middle',
}
export type JourneyTrackerFC = turf.FeatureCollection<turf.LineString, { status: JourneySegmentStatus, position: JourneySegmentPosition }>
export type SegmentFeatures = turf.Feature<turf.LineString, { status: JourneySegmentStatus, position: JourneySegmentPosition }>[]

export interface BackendJourneyHistory {
  journey_id: number,
  path: JourneyTrackerFC,
  created_at: string,
  updated_at: string,
}

export type CountiesGeoJSON = turf.FeatureCollection<turf.Polygon, CountyProperties>

export interface RegionProperties {
  Alert_color: string,
  Alert_state: number,
  description: string,
  shapeName: string,
  shapeID: string,
  pcode: string,
  shapeType: string,
  level: string,
  shapeGroup: string,
}

export type RegionsGeoJSON = turf.FeatureCollection<turf.Polygon, RegionProperties>

export interface DriverProperties {
  id: number,
}

export type DriversGeoJSON = turf.FeatureCollection<turf.Point, DriverProperties>

export type POIFeaturePoints = turf.FeatureCollection<turf.Point, { id: number, name: string }>
export type LinePOIFeatures = turf.FeatureCollection<turf.LineString, { id: number }>

export type LineAssetFeatures = turf.FeatureCollection<turf.LineString, { id: number }>
export type PolygonFeatures = turf.FeatureCollection<turf.Polygon, { id: number }>

export type AssetFeaturePoints = turf.FeatureCollection<turf.Point, { id: number, name: string }>
