/* eslint-disable import/prefer-default-export */
import { EngagementCampaign, RecipientList } from '../../../components/types/OptimizedMaps'

/**
 * Update recipient list for every campaign
 *
 * @param campaigns -> List of all campaigns
 * @param updated -> The recipient list that has been updated
 * @returns A list of campaigns with the updated version of the recipeitn list
 */
export const updateCampaignRecipients = (
  campaigns: EngagementCampaign[],
  updated: RecipientList,
) : EngagementCampaign[] => {
  const updatedCampaigns = campaigns.reduce<EngagementCampaign[]>((list, current) => {
    const listIndex = current.recipient_lists.findIndex(({ id }) => id === updated.id)
    if (listIndex < 0) {
      return [...list, current]
    }

    const copy = current.recipient_lists.slice()
    copy.splice(listIndex, 1, { ...updated })

    return [...list, { ...current, recipient_lists: copy }]
  }, [])

  return updatedCampaigns
}
