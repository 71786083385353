import {
  IonRow, IonGrid, IonCol,
} from '@ionic/react'
import React, { useState } from 'react'
import moment from 'moment'
import { Icon } from '@iconify/react'
import {
  BlueButton, GreenButton, IncidentList,
} from '../StyledContainers'
import { useIncidentsData } from '../../../hooks/incident-management/useIncidentData'
import IncidentForm from './IncidentForm'
import { IncidentViewActionKind } from '../../../hooks/incident-management/helpers/StateReducers'

/**
 *  ==============================
 *        INCIDENT BOARD
 *  ==============================
 * Show active and closed incidents
 */
const IncidentBoard = () => {
  /* Incident management hook */
  const {
    activeIncidents, SOPTypes, dispatchView, closedIncidents,
  } = useIncidentsData()
  /* Show incident report form popup */
  const [showForm, setShowForm] = useState(false)
  const [showActiveIncidents, setShowActiveIncidents] = useState(true)

  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  return (
    <>
      {
        (!showForm) ? (
          <div className='risk-assessment-list' style={{ height: '100%' }}>
            <IonRow style={{ marginBottom: '10px' }} className='ion-justify-content-between'>
              <GreenButton style={{ backgroundColor: '#326771', padding: '10px 30px' }} onClick={() => setShowActiveIncidents(!showActiveIncidents)}>
                { showActiveIncidents ? 'Active Incident Reports' : 'Closed Incident Reports' }
                <Icon icon='akar-icons:arrow-up-down' />
              </GreenButton>
              <GreenButton style={{ padding: '10px 30px' }} onClick={() => setShowForm(true)}>Report Incident</GreenButton>
            </IonRow>
            <IonGrid
              className='ion-text-center'
              style={{
                width: '100%',
                padding: '0',
                border: '1px solid #7E7E7E',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <IonRow style={{ borderBottom: '1px solid #7E7E7E' }}>
                <IonCol size='3'><h5> Title  </h5></IonCol>
                <IonCol size='2'><h5> Category </h5> </IonCol>
                <IonCol size='2'><h5> Date Reported </h5></IonCol>
                <IonCol size='2'><h5> Location </h5></IonCol>
                <IonCol size='3'><h5> Actions </h5></IonCol>
              </IonRow>
              {
                showActiveIncidents ? (
                  <>
                    {
                      (activeIncidents.length === 0) ? (
                        <p className='empty-lists'>No Active Incidents</p>
                      ) : (
                        <IncidentList style={{ flex: 1, overflow: 'auto' }}>
                          {
                            activeIncidents.map((incident, index) => (
                              <IonRow
                                onClick={() => dispatchView({ type: IncidentViewActionKind.SELECT_INCIDENT, incident })}
                                className='incident-management-row ion-align-items-center'
                                style={{ background: getColor(index) }}
                                key={incident.title}
                              >
                                <IonCol size='3'>
                                  <p>{ incident.title }</p>
                                </IonCol>
                                <IonCol size='2'><p> { incident.primary_category } </p> </IonCol>
                                <IonCol size='2'><p> { moment(incident.reported).format('DD/MM/YY') } </p></IonCol>
                                <IonCol size='2'><p> { incident.county.name } </p></IonCol>
                                <IonCol size='3'>
                                  <IonRow className='ion-justify-content-evenly'>
                                    <BlueButton className='vertical-aligned-button' style={{ textDecoration: 'none' }}>
                                      <Icon icon='akar-icons:download' />
                                      <p style={{ textDecoration: 'underline' }}>Download</p>
                                    </BlueButton>
                                    <BlueButton className='vertical-aligned-button' style={{ textDecoration: 'none' }}>
                                      <Icon icon='arcticons:mail' />
                                      <p style={{ textDecoration: 'underline' }}>Email</p>
                                    </BlueButton>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                            ))
                          }
                        </IncidentList>
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      (closedIncidents.length === 0) ? (
                        <p className='empty-lists'>No Active Incidents</p>
                      ) : (
                        <IncidentList style={{ flex: 1, overflow: 'auto' }}>
                          {
                            closedIncidents.map((incident, index) => (
                              <IonRow
                                onClick={() => dispatchView({ type: IncidentViewActionKind.SELECT_INCIDENT, incident })}
                                className='incident-management-row ion-align-items-center'
                                style={{ background: getColor(index) }}
                                key={incident.title}
                              >
                                <IonCol size='3'>
                                  <p>{ incident.title }</p>
                                </IonCol>
                                <IonCol size='2'><p> { incident.primary_category } </p> </IonCol>
                                <IonCol size='2'><p> { moment(incident.reported).format('DD/MM/YY') } </p></IonCol>
                                <IonCol size='2'><p> { incident.county.name } </p></IonCol>
                                <IonCol size='3'>
                                  <IonRow className='ion-justify-content-evenly'>
                                    <BlueButton className='vertical-aligned-button' style={{ textDecoration: 'none' }}>
                                      <Icon icon='akar-icons:download' />
                                      <p style={{ textDecoration: 'underline' }}>Download</p>
                                    </BlueButton>
                                    <BlueButton className='vertical-aligned-button' style={{ textDecoration: 'none' }}>
                                      <Icon icon='arcticons:mail' />
                                      <p style={{ textDecoration: 'underline' }}>Email</p>
                                    </BlueButton>
                                  </IonRow>
                                </IonCol>
                              </IonRow>
                            ))
                          }
                        </IncidentList>
                      )
                    }
                  </>
                )
              }
            </IonGrid>
          </div>
        ) : (
          <IncidentForm
            onClose={() => setShowForm(false)}
            categories={SOPTypes}
          />
        )
      }
    </>
  )
}

export default IncidentBoard
