import * as React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useIonAlert } from '@ionic/react'
import * as moment from 'moment'
import * as turf from '@turf/turf'
import * as mapboxgl from 'mapbox-gl'
import { serious } from '../../components/maps/apps/serious_incidents.json'
import * as PubNub from 'pubnub'
import {
  mapPopup,
} from '../../components/maps/apps/AppMap'
import { JourneyViews } from './helpers/StateReducers'
import { BackendRoute, JourneysInterface2 } from './types/HookInterface2'
import axios from '../../utils/axios'
import {
  getStartEndPoints,
} from './helpers/Utils'
import {
  AirJourney,
  BackendJourney,
  ChaptersGeoJSON,
  CountryAreas, Incident, Journey, RestrictionZone, Route,
} from '../../components/types/OptimizedMaps'
import {
  createDriverLayer, createRouteSymbolLayer, createAssetsLayer,
} from '../insights/helpers/MapUtils'

/* ================== KEY ASSET ICONS ===================== */
import * as Operations from '../../components/insights/asset-icons/operations.png'
import * as People from '../../components/insights/asset-icons/people.png'
import * as Facilities from '../../components/insights/asset-icons/facilities.png'
import * as Equipment from '../../components/insights/asset-icons/equipment.png'
import * as Product from '../../components/insights/asset-icons/product.png'
import * as Reputation from '../../components/insights/asset-icons/reputation.png'
import * as Project from '../../components/insights/asset-icons/project.png'
import * as Default from '../../components/insights/asset-icons/pin.png'
/* ======================================================== */

import testHook from '../testHooks'
import { useAuth } from '../useAuth'
import { useWorkspace } from '../useWorkspace'
import {
  IncidentCause, User,
} from '../../components/types/GlobalTypes'
import {
  BackendJourneyHistory,
  CountiesGeoJSON, DriversGeoJSON, RegionsGeoJSON, SegmentFeatures,
} from '../../components/types/GeoJSONTypes'

import { processRoutes } from '../insights/helpers/Utils'
import { ControlMeasure } from '../../components/riskregister/types/RiskAssessments'
import {
  createChaptersLayer,
  createJourneyHistoriesLayer,
  createStartEndJourneyLayer, updateDriversLocation,
} from './helpers/MapUtils'
import {
  assetsToGeoJSON, incidentsToGeoJSON, processAreas, restrictionZonesToGeoJSON,
} from '../../components/utils/MapboxUtils'
import { BackendAsset } from '../risks/types/BackendData'
import {
  createJourneyLayer, createSources, displayAssetPopup, renderAlertStates,
} from '../../components/utils/MapboxRendering'
import {
  journeyRoutesToGeoJSON, processChapters, processJourneys, processRestrictionZones, routesToGeoJSON,
} from '../../components/utils/Processors'

const defaultRoutesGeoJSON : turf.FeatureCollection<turf.LineString> = { type: 'FeatureCollection', features: [] }
const defaultAssetGeoJSON : turf.FeatureCollection<turf.Point, BackendAsset> = { type: 'FeatureCollection', features: [] }

/**
 *  ================================================
 *        HANDLE STATES FOR JOURNEYS APP
 *  ================================================
 *
 * Implements the business logic and react states for
 * the journeys app.
 */

export const journeysContext2 = React.createContext<JourneysInterface2>(null)
export const useJourneysData2 = () => React.useContext(journeysContext2)

const useProvideJourneysData2 = () : JourneysInterface2 => {
  // States for the JourneysApp page
  const [view, setView] = React.useState<JourneyViews>(JourneyViews.ROAD_TRAVEL)
  const controlsRef = React.useRef<any>(null)
  const mapRef = React.useRef<mapboxgl.Map>(null)
  const apiHook = testHook()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [routes, setRoutes] = React.useState<turf.FeatureCollection<turf.LineString>>({ ...defaultRoutesGeoJSON })
  const [routePins, setRoutePins] = React.useState<turf.FeatureCollection<turf.Point>>({ ...defaultAssetGeoJSON })
  const [showJourneyAuthorization, setShowJourneyAuthorization] = React.useState<boolean>(false)
  const [showTicket, setShowTicket] = React.useState<boolean>(false)
  const [showEditJourney, setShowEditJourney] = React.useState<boolean>(false)
  const [focussedJourney, setFocussedJourney] = React.useState<Journey>()
  const [showJourneyForm, setShowJourneyForm] = React.useState<boolean>(false)
  const [showAirJourneyForm, setShowAirJourneyForm] = React.useState<boolean>(false)
  const [boxMeasures, setBoxMeasures] = React.useState<ControlMeasure[]>([])
  const [views, setViews] = React.useState<JourneyViews[]>([JourneyViews.ROAD_TRAVEL, JourneyViews.AIR_TRAVEL, JourneyViews.HISTORY, JourneyViews.QUICKGO])
  const [domainUsers, setDomainUsers] = React.useState<User[]>([])
  const [editingBox, setEditingBox] = React.useState<boolean>(false)
  // States for the JourneyMap component

  const [geoJSONJourneys, setGeoJSONJourneys] = React.useState<turf.FeatureCollection<turf.LineString>>({ type: 'FeatureCollection', features: [] })

  /* References */
  const [mbDrawn, setMbDrawn] = React.useState<boolean>(false)
  const [submittedPopup, setSubmittedPopup] = React.useState<any>(null)

  /* Air Journeys list */
  const [airJourneys, setAirJourneys] = React.useState<AirJourney[]>([])

  /* Show different type of forms */
  const [showMbForm, setshowMbForm] = React.useState<any>(false)

  const [showRouteForm, setShowRouteForm] = React.useState<boolean>(false)
  const [showJourneyChoice, setShowJourneyChoice] = React.useState<boolean>(false)
  const [focusedAirJourney, setFocusedAirJourney] = React.useState<AirJourney>(null)
  /* Map Markers for the airports */
  const [fromPin, setFromPin] = React.useState<any>(null)
  const [toPin, setToPin] = React.useState<any>(null)

  const [causes, setCauses] = React.useState<IncidentCause[]>([])
  const [incidentTypes, setIncidentTypes] = React.useState<string[]>()

  const [filters, setFilters] = React.useState<any>({
    mb: false, routes: false, keyAssets: false, securityHeatMap: false, alertStates: false,
  })

  const [routeList, setRouteList] = React.useState<Route[]>([])
  const [assets, setAssets] = React.useState<turf.FeatureCollection<turf.Point, BackendAsset>>({ ...defaultAssetGeoJSON })

  const [regions, setRegions] = React.useState<RegionsGeoJSON>()
  const [counties, setCounties] = React.useState<CountiesGeoJSON>()
  const [areas, setAreas] = React.useState<CountryAreas[]>()
  const [driversLocationGeoJSON, setDriverLocationGeoJSON] = React.useState<DriversGeoJSON>({ ...defaultAssetGeoJSON })
  const [incidents, setIncidents] = React.useState<turf.FeatureCollection<turf.Point, Incident>>({ type: 'FeatureCollection', features: [] })
  const [journeyHistories, setJourneyHistories] = React.useState<SegmentFeatures>([])
  const [quickgoHistories, setQuickgoHistories] = React.useState<SegmentFeatures>([])
  const [activeGeoJSONChapters, setActiveGeoJSONChapters] = React.useState<ChaptersGeoJSON>({ type: 'FeatureCollection', features: [] })
  const [completedGeoJSONChapters, setCompletedGeoJSONChapters] = React.useState<ChaptersGeoJSON>({ type: 'FeatureCollection', features: [] })
  const [startEndGeoJSON, setStartEndGeoJSON] = React.useState<turf.FeatureCollection<turf.Point>>({ type: 'FeatureCollection', features: [] })
  const [qgStartEndGeoJSON, setQgStartEndGeoJSON] = React.useState<turf.FeatureCollection<turf.Point>>({ type: 'FeatureCollection', features: [] })

  /* Store  coordinates */
  const [newBox, setNewBox] = React.useState<boolean>(false)

  /* Routes that will be shown in the routes view */
  const [journeys, setJourneys] = React.useState<Journey[]>([])
  const [qgjourneys, setQgJourneys] = React.useState<Journey[]>([])

  /* Data fetched from the db */
  const [showLoading, setShowLoading] = React.useState<boolean>(false)

  /* Movement boxes retrieved from the server */
  const [movementBoxes, setMovementBoxes] = React.useState<any[]>([])
  const [restrictionZones, setRestrictionZones] = React.useState<RestrictionZone[]>([])
  const [showZoneForm, setShowRestrictionZone] = React.useState<boolean>(false)
  const [focusedMb, setFocusedMb] = React.useState<RestrictionZone | null>(null)
  const { workspace } = useWorkspace()

  const [users, setUsers] = React.useState<User[]>([])

  const [showMapControls, setShowMapControls] = React.useState<boolean>(true)

  const [showJourneysList, setShowJourneysList] = React.useState<boolean>(false)
  const [showAirTravelList, setShowAirTravelList] = React.useState<boolean>(false)

  const [showMap, setShowMap] = React.useState<boolean>(false)
  const [showBackToJourneysMapButton, setShowBackToJourneysMapButton] = React.useState<boolean>(false)

  const [showMapKey, setShowMapKey] = React.useState<boolean>(true)

  // States for the JourneyWidget  component
  const [selectedRoutes, setSelectedRoutes] = React.useState<any[]>([])

  // Common states
  const auth = useAuth()
  const [ionAlert] = useIonAlert()
  const { user_id, email } = auth.user

  const pubnub = new PubNub({
    publishKey: 'pub-c-89b6e1fe-c829-4a63-b3c7-da6f501be7e0',
    subscribeKey: 'sub-c-32b0b70e-bda0-4e92-a558-547b58432ded',
    uuid: String(user_id as number),
  })

  // Asset icons
  // Create assets source and add layer
  const icons = [{ name: 'Operations', img: Operations }, { name: 'Reputation', img: Reputation }, { name: 'Project', img: Project },
    { name: 'People', img: People }, { name: 'Facilities', img: Facilities }, { name: 'Equipment', img: Equipment }, { name: 'Product', img: Product }, { name: 'Default', img: Default }]

  // Functionality for JourneysApp page
  const removeRoute = (id: number) => {
    /* Check if any active journey is using the route that is to be removed */
    const usingRoute = journeys.find((journey) => journey.routes.find((val) => val.id === id) && journey.status !== 'Cancelled' && journey.status !== 'Complete')
    if (usingRoute) {
      ionAlert({
        header: 'Error - Route Being used',
        message: 'The route you are trying to delete is being used by at least one active journey.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    setLoading(true)
    apiHook.archivePath(id).then(({ message }) => {
      if (!message.includes('200')) {
        ionAlert({
          header: 'Server Error!',
          message,
          buttons: [
            { text: 'Ok' },
          ],
        })
        setLoading(false)
        return
      }
      /* Remove Route from app */
      const routesCopy = routes.features.filter((val) => val.properties.id !== id).slice();

      /* Update mapbox source and state data */
      (mapRef.current.getSource('incidents') as mapboxgl.GeoJSONSource).setData({
        type: 'FeatureCollection',
        features: routesCopy,
      })

      const formattedRoutes : turf.FeatureCollection<turf.LineString> = { type: 'FeatureCollection', features: routesCopy }
      setRoutes(formattedRoutes)
      ionAlert({
        header: 'Route Deleted',
        message,
        buttons: [
          { text: 'Ok' },
        ],
      })
      setLoading(false)
    })
  }

  const drawRoutes = (draw: boolean) => {
    setFilters({ ...filters, routes: draw })
    if (draw) {
      (mapRef.current.getSource('routes') as mapboxgl.GeoJSONSource).setData(routes);
      (mapRef.current.getSource('route-symbols') as mapboxgl.GeoJSONSource).setData(routePins)
      return
    }

    (mapRef.current.getSource('routes') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] });
    (mapRef.current.getSource('route-symbols') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] })
  }

  const drawAssets = (draw: boolean) => {
    setFilters({ ...filters, keyAssets: draw })
    if (draw) {
      (mapRef.current.getSource('assets-source') as mapboxgl.GeoJSONSource).setData(assets)
      return
    }

    (mapRef.current.getSource('assets-source') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] })
  }

  const drawAlertStates = (draw: boolean) => {
    setFilters({ ...filters, alertStates: draw })
    renderAlertStates(mapRef, areas, draw)
  }

  const drawJourneys = (show: boolean) => {
    if (show) {
      (mapRef.current.getSource('journeys') as mapboxgl.GeoJSONSource).setData(geoJSONJourneys);
      (mapRef.current.getSource('active-journeys-chapters') as mapboxgl.GeoJSONSource).setData(activeGeoJSONChapters)
      return
    }

    (mapRef.current.getSource('journeys') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] });
    (mapRef.current.getSource('active-journeys-chapters') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] })
  }

  const drawJourneyHistories = (show: boolean) => {
    //
    console.log('draw hiss', journeyHistories)
    if (show) {
      (mapRef.current.getSource('journey-histories') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: journeyHistories });
      (mapRef.current.getSource('completed-journeys-chapters') as mapboxgl.GeoJSONSource).setData(completedGeoJSONChapters);
      (mapRef.current.getSource('completed-journeys-startend') as mapboxgl.GeoJSONSource).setData(startEndGeoJSON)
      return
    }
    (mapRef.current.getSource('journey-histories') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] });
    (mapRef.current.getSource('completed-journeys-chapters') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] });
    (mapRef.current.getSource('completed-journeys-startend') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] })
  }

  const drawQgHistories = (show: boolean) => {
    console.log('draw qg hiss', quickgoHistories)
    if (show) {
      (mapRef.current.getSource('journey-histories') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: quickgoHistories });
      (mapRef.current.getSource('completed-journeys-chapters') as mapboxgl.GeoJSONSource).setData(completedGeoJSONChapters);
      (mapRef.current.getSource('completed-journeys-startend') as mapboxgl.GeoJSONSource).setData(qgStartEndGeoJSON)
      return
    }
    (mapRef.current.getSource('journey-histories') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] });
    (mapRef.current.getSource('completed-journeys-chapters') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] });
    (mapRef.current.getSource('completed-journeys-startend') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] })
  }

  const handleViewChange = (newView: JourneyViews): void => {
    /* Set new view  */
    setView(newView)

    if (view === JourneyViews.ROAD_TRAVEL) {
      drawJourneys(false)
    }

    if (view === JourneyViews.HISTORY) {
      drawJourneyHistories(false)
    }

    if (view === JourneyViews.QUICKGO) {
      drawQgHistories(false)
    }

    if (newView === JourneyViews.ROAD_TRAVEL) {
      setShowAirTravelList(false)
      drawJourneys(true)
      return
    }

    if (newView === JourneyViews.AIR_TRAVEL) {
      setShowAirTravelList(true)
      return
    }

    if (newView === JourneyViews.HISTORY) {
      drawJourneyHistories(true)
      return
    }

    if (newView === JourneyViews.QUICKGO) {
      drawQgHistories(true)
      return
    }
  }
  /* Functionality for the JourneyMap component */

  // url variable
  const queryParams = new URLSearchParams(window.location.search)
  const journeyId = queryParams.get('journey')

  const paintMBs = (draw: boolean, zones: RestrictionZone[] = restrictionZones) => {
    setFilters({ ...filters, mb: draw })
    if (draw) {
      const geoJSONZones = restrictionZonesToGeoJSON(zones);
      (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData(geoJSONZones)
      return
    }

    (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] })
  }

  const restrictionBoxPopup = (e) => {
    if (!showEditJourney && !showJourneyAuthorization && !showMbForm && !showTicket && !showRouteForm && !showJourneyForm && !showJourneyChoice && !editingBox) {
      const { properties } = e.features[0]
      const restrictionBox = restrictionZones.find(({ id }) => id === properties.id)
      setFocusedMb(restrictionBox)
    }
  }

  const cancelMBEdit = () => {
    const geoJSONBoxes = restrictionZonesToGeoJSON(restrictionZones);
    (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData(geoJSONBoxes)
    setFocusedMb(null)
  }

  const updateMovementBox = (id, feature) => {
    const copy = restrictionZones.slice()
    const index = restrictionZones.findIndex((box) => box.id === id)

    copy.splice(index, 1, { ...copy[index], geoData: feature })
    setRestrictionZones(copy)
    const geoJSONZones = restrictionZonesToGeoJSON(copy);
    (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData(geoJSONZones)
  }

  const restrictionBoxPopupRef = React.useRef<any>(restrictionBoxPopup)
  restrictionBoxPopupRef.current = restrictionBoxPopup

  const incidentsPopup = (e) => {
    mapPopup(e, mapRef)
  }

  const incidentsPopupRef = React.useRef<any>(incidentsPopup)
  incidentsPopupRef.current = incidentsPopup

  const handleChapterClick = (journey: Journey) => {
    showForm(journey)
  }

  const handleChapterClickRef = React.useRef(handleChapterClick)
  handleChapterClickRef.current = handleChapterClick

  const getRouteFrequency = ({ created_at }) => {
    if (created_at === 0) {
      return 'never'
    }

    const lastUsed = moment(created_at)
    if (lastUsed.isSameOrAfter(moment().subtract(1, 'day'))) {
      return 'daily'
    }

    if (lastUsed.isSameOrAfter(moment().subtract(7, 'days'))) {
      return 'weekly'
    }

    if (lastUsed.isSameOrAfter(moment().subtract(1, 'month'))) {
      return 'monthly'
    }

    return 'yearly'
  }

  const createFlightPath = () => {
    mapRef.current.addSource('flight-source', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    })

    mapRef.current.addLayer({
      id: 'new-flightline-active',
      type: 'line',
      source: 'flight-source',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#3887be',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12, 3,
          22, 12,
        ],
      },
    })
  }

  const createIncidentsSourceAndLayers = () => {
    mapRef.current.addSource('incidents', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    })

    mapRef.current.addLayer(
      {
        id: 'incidents-heat',
        type: 'heatmap',
        source: 'incidents',
        maxzoom: 15,
        paint: {
          'heatmap-weight': {
            property: 'nearest',
            type: 'exponential',
            stops: [
              [1, 0],
              [62, 1],
            ],
          },
          'heatmap-intensity': {
            stops: [
              [11, 1],
              [15, 3],
            ],
          },
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(236,222,239,0)',
            0.2,
            'rgb(208,209,230)',
            0.4,
            'rgb(166,189,219)',
            0.6,
            'rgb(103,169,207)',
            0.8,
            'rgb(28,144,153)',
          ],
          'heatmap-radius': {
            stops: [
              [11, 15],
              [15, 20],
            ],
          },
          'heatmap-opacity': {
            default: 1,
            stops: [
              [14, 1],
              [15, 0],
            ],
          },
        },
      },
      'waterway-label',
    )

    mapRef.current.addLayer(
      {
        id: 'incidents-point',
        type: 'circle',
        source: 'incidents',
        minzoom: 14,
        paint: {
          'circle-radius': {
            property: 'nearest',
            type: 'exponential',
            stops: [
              [{ zoom: 15, value: 1 }, 5],
              [{ zoom: 15, value: 62 }, 10],
              [{ zoom: 22, value: 1 }, 20],
              [{ zoom: 22, value: 62 }, 50],
            ],
          },
          'circle-color': {
            property: 'nearest',
            type: 'exponential',
            stops: [
              [0, 'rgba(236,222,239,0)'],
              [10, 'rgb(236,222,239)'],
              [20, 'rgb(208,209,230)'],
              [30, 'rgb(166,189,219)'],
              [40, 'rgb(103,169,207)'],
              [50, 'rgb(28,144,153)'],
              [60, 'rgb(1,108,89)'],
            ],
          },
          'circle-stroke-color': 'white',
          'circle-stroke-width': 1,
          'circle-opacity': {
            stops: [
              [14, 0],
              [15, 1],
            ],
          },
        },
      },
      'waterway-label',
    )
  }

  /* Shows the security heat map when the filter is checked */
  const showSecurityHeatMap = (show: boolean) => {
    setFilters({ ...filters, securityHeatMap: show })
    if (show) {
      (mapRef.current.getSource('incidents') as mapboxgl.GeoJSONSource).setData(incidents)
      return
    }

    (mapRef.current.getSource('incidents') as mapboxgl.GeoJSONSource).setData({ type: 'FeatureCollection', features: [] })
  }

  const setUpDriverChannels = (journeyIds: number[]) => {
    if (journeyIds.length === 0) {
      return
    }
    const channels = journeyIds.map((id) => `transit-journey-${id}`)
    pubnub.subscribe({ channels })

    /* Setup listeners */
    pubnub.addListener({
      message: ({ message }) => {
        setDriverLocationGeoJSON((prevState) => {
          const updatedGeoJSON = updateDriversLocation(prevState, message);
          (mapRef.current.getSource('drivers-symbol') as mapboxgl.GeoJSONSource).setData(updatedGeoJSON)
          return updatedGeoJSON
        })
      },
    })
  }

  const setUpListeners = () => {
    mapRef.current.on('load', async () => {
      /* Fetch incidents,  Mbs and Paths  */
      const [incidents, airJourneys, mb, paths, dbJourneys, dbUsers,
        countyAssets, boxMeasureData, domainUsersData, dbCausesActors,
        incidentTypes, areas] = await Promise.all([
        axios.post('/api/v2/incident/incidents', { domain_id: workspace.id, time_from: '1month', time_to: 'today' }),
        axios.post('/api/v2/journey/air_journeys', { id: workspace.id }),
        axios.post('/api/v2/movement_box/boxes', { id: workspace.id }),
        apiHook.getPaths(workspace.id),
        axios.post('/api/v2/journey/journeys', { id: workspace.id }), apiHook.getUsers(workspace.id),
        apiHook.getAssetsByCounty({ domain_id: workspace.id }), apiHook.getBoxMeasures(), axios.post('/api/v1/domain/get_domain_users', { domain_id: workspace.id }),
        axios.get('/api/v1/internal_incident/fetchCausesActors'), axios.get('/api/v1/types'),
        axios.post('/api/v2/county/counties', { domain_id: workspace.id }),
      ])
      /* Optimized GeoJSON shapes */
      const shapes = areas.data.counties
      const formattedAreas = processAreas(shapes)
      setAreas(formattedAreas)
      createSources(mapRef, formattedAreas)
      renderAlertStates(mapRef, formattedAreas, false)
      createJourneyHistoriesLayer(mapRef)

      /* Process journeys data */
      const routesGeoData = routesToGeoJSON(paths.routes)
      // Filter out journeys with purpose 'QuickGo Journey'
      const filteredJourneys = dbJourneys.data.journeys.filter((journey) => journey.purpose !== 'QuickGo Journey')
      const filteredQgJourneys = dbJourneys.data.journeys.filter((journey) => journey.purpose === 'QuickGo Journey')
      // Process the filtered journeys
      const formattedJourneys = processJourneys(filteredJourneys, routesGeoData, dbJourneys.data.histories)
      const formattedQgJourneys = processJourneys(filteredQgJourneys, routesGeoData, dbJourneys.data.histories)

      console.log('formattedQgJourneys', formattedQgJourneys)
      console.log('formattedJourneys', formattedJourneys)
      const geodataJourneys = journeyRoutesToGeoJSON(formattedJourneys, routesGeoData)

      const { active, completed } = processChapters(formattedJourneys)

      setJourneys(formattedJourneys)
      setQgJourneys(formattedQgJourneys)
      setCompletedGeoJSONChapters(completed)
      setActiveGeoJSONChapters(active)
      setRouteList(routesGeoData)
      setGeoJSONJourneys(geodataJourneys)
      createJourneyLayer(mapRef, geodataJourneys)

      setIncidentTypes(incidentTypes.data.types.sort((a, b) => {
        if (a < b) return -1
        if (b < a) return 1
        return 0
      }))

      setDomainUsers(domainUsersData.data.users)

      // drawCountyShapesIncidents(counties.counties, mapRef, () => paintIncidents(true, incidents.incidents, incidentList, mapRef))
      const formattedBoxes = processRestrictionZones(mb.data.boxes, dbCausesActors.data.causes, boxMeasureData.standard_measures)
      setRestrictionZones(formattedBoxes)
      setBoxMeasures(boxMeasureData.standard_measures)

      setCauses(dbCausesActors.data.causes)

      const historiesGeoJSON = (dbJourneys.data.histories as BackendJourneyHistory[])
      const filteredHistories = historiesGeoJSON.filter((history) => formattedQgJourneys.some((journey) => journey.id === history.journey_id))
      console.log('filtered histories: ', filteredHistories)
      console.log('journey history:', dbJourneys.data.histories)
      const trackerFeatures = historiesGeoJSON.reduce<SegmentFeatures>((features, current) => [...features, ...current.path.features], [])
      const QgTrackerFeatures = filteredHistories.reduce<SegmentFeatures>((features, current) => [...features, ...current.path.features], [])
      setJourneyHistories(trackerFeatures)
      setQuickgoHistories(QgTrackerFeatures)
      setStartEndGeoJSON(getStartEndPoints(turf.featureCollection(trackerFeatures)))
      setQgStartEndGeoJSON(getStartEndPoints(turf.featureCollection(QgTrackerFeatures)))
      createChaptersLayer(mapRef)

      /* Add feature collection source to the map */
      mapRef.current.addSource('restriction-boxes', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })

      createStartEndJourneyLayer(mapRef)

      /* Retrieve users for future cross-reference  */
      setUsers(dbUsers.users)

      const formattedRoutes = processRoutes(paths.routes)
      setRoutes(formattedRoutes)

      const routeSymbols : turf.Feature<turf.Point>[] = formattedRoutes.features.map(({ properties, geometry }) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: geometry.coordinates[0],
        },
        properties: {
          icon: properties.type,
        },
      }))

      setRoutePins({
        type: 'FeatureCollection',
        features: routeSymbols,
      })

      mapRef.current.addSource('routes', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })

      mapRef.current.addLayer({
        id: 'routeline-active',
        type: 'line',
        source: 'routes',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': ['get', 'color'],
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 3,
            22, 12,
          ],
        },
      })

      // Set up assets
      setAssets(assetsToGeoJSON(countyAssets.assets))
      createAssetsLayer(mapRef)
      createRouteSymbolLayer(mapRef)
      createDriverLayer(mapRef)

      setAirJourneys(airJourneys.data.journeys)

      const seriousIncidents = incidents.data.incidents.filter(({ incident_type }) => serious.indexOf(incident_type) >= 0)
      setIncidents(incidentsToGeoJSON(seriousIncidents))
      createIncidentsSourceAndLayers()

      const journeyIds = journeys.filter(({ status }) => status === 'In-Transit').map(({ id }) => id)
      setUpDriverChannels(journeyIds)
      createFlightPath()

      mapRef.current.addLayer({
        id: 'mb-layer',
        source: 'restriction-boxes',
        type: 'fill',
        paint: {
          'fill-color': ['get', 'type'], // blue color fill
          'fill-opacity': 0.5,
        },
      })

      mapRef.current.addLayer({
        id: 'border-mb',
        type: 'line',
        source: 'restriction-boxes',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': ['match', ['get', 'type'], ['#02C221'], '#016311',
            ['#FF9900'], '#855001', ['#FF0101'], '#690202', '#000000'],
          'line-width': 2,
        },
      })

      /* Default view will be transit  */
      setLoading(false)

      mapRef.current.on('click', 'asset-markers', (e) => {
        if (!e.originalEvent.defaultPrevented) {
          displayAssetPopup(e, mapRef)
          e.originalEvent.preventDefault()
        }
      })

      mapRef.current.on('click', 'incident-markers', (e) => {
        incidentsPopupRef.current(e)
      })

      mapRef.current.on('click', 'mb-layer', (e) => {
        restrictionBoxPopupRef.current(e)
      })

      mapRef.current.on('click', 'active-journeys-chapters-layer', (e) => {
        handleChapterClickRef.current(JSON.parse(e.features[0].properties.journey))
      })

      mapRef.current.on('click', 'completed-journeys-chapters-layer', (e) => {
        handleChapterClickRef.current(JSON.parse(e.features[0].properties.journey))
      })

      mapRef.current.resize()

      // bring up journey from url
      /*
      if (journeysFromDb.find((journey) => journey.properties.id === Number(journeyId))) {
        setFocussedJourney(Number(journeyId))
        const directedJourney = journeysFromDb.find((journey) => journey.properties.id === Number(journeyId))
        if (directedJourney.properties.approver.email === email && directedJourney.properties.status === 'Pending') setShowJourneyAuthorization(true)
        else if (directedJourney.properties.submitter.email === email && directedJourney.properties.status === 'Pending') setShowEditJourney(true)
        else setShowTicket(true)
      }
      */
    })
  }

  const startMB = () => {
    handleViewChange(JourneyViews.ROAD_TRAVEL)
    mapRef.current.easeTo({
      padding: {
        top: 0,
        right: 300,
        bottom: 0,
        left: 0,
      },
    })
    paintMBs(false)
    mapRef.current.resize()
    setFilters({ ...filters, mb: false })
    setshowMbForm(true)
  }

  const pushNewZone = (newZone: RestrictionZone) => {
    const copy = restrictionZones.slice()
    copy.push(newZone)

    setRestrictionZones(copy)
    paintMBs(true, copy)
  }

  const pushNewRoute = (route: Route) => {
    const copy = routeList.slice()
    copy.push(route)
    setRouteList(copy)
  }

  const updateRestrictionZone = (updatedZone: RestrictionZone) => {
    const copy = restrictionZones.slice()
    const index = copy.findIndex(({ id }) => id === updatedZone.id)

    copy.splice(index, 1, updatedZone)
    setRestrictionZones(copy)
    paintMBs(true, copy)
  }

  const pushNewJourney = (journey: BackendJourney) => {
    const copy = journeys.slice()
    const [formattedJourney] = processJourneys([journey], routeList, [])
    copy.push(formattedJourney)

    const geojsonData = journeyRoutesToGeoJSON(copy, routeList)
    setJourneys(copy)
    setGeoJSONJourneys(geojsonData);
    (mapRef.current.getSource('journeys') as mapboxgl.GeoJSONSource).setData(geojsonData)
  }

  const removeRestrictionZone = (zone: RestrictionZone) => {
    const copy = restrictionZones.filter(({ id }) => id !== zone.id)
    setRestrictionZones(copy)
    paintMBs(true, copy)
  }

  const journeyMode = () => {
    /* Selecting routes in a journey request */
    mapRef.current.addSource('journey-routes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    })

    mapRef.current.addLayer({
      id: 'journey-routes-layer',
      type: 'line',
      source: 'journey-routes',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#3887be',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12, 3,
          22, 12,
        ],
      },
    })
    /* Remove any layer */
    if (view === JourneyViews.ROUTES && mapRef.current.getLayer('routeline-active')) { drawRoutes(false) }
    if (view === JourneyViews.ROAD_TRAVEL && mapRef.current.getLayer('journeyline-active')) { drawJourneys(false) }
  }

  /* Start a journey request form */
  const startJourney = () => {
    journeyMode()

    handleViewChange(JourneyViews.ROAD_TRAVEL)
    setShowJourneyForm(true)

    mapRef.current.easeTo({
      padding: {
        top: 0,
        right: 300,
        bottom: 0,
        left: 0,
      },
    })
  }

  const startAirJourney = () => {
    handleViewChange(JourneyViews.AIR_TRAVEL)
    setShowAirJourneyForm(true)
    setShowMapKey(false)
  }

  const startRoute = () => {
    handleViewChange(JourneyViews.ROAD_TRAVEL)
    setShowAirTravelList(false)
    drawRoutes(true)
    setShowRouteForm(true)

    if (!showJourneyForm) {
      mapRef.current.easeTo({
        padding: {
          top: 0,
          right: 300,
          bottom: 0,
          left: 0,
        },
      })
    }
  }

  const finishJourney = () => {
    handleViewChange(JourneyViews.ROAD_TRAVEL)
  }

  const addAirJourney = (airJourney: AirJourney) => {
    /* update list of air journeys */
    const copy = airJourneys.slice()
    copy.push(airJourney)

    setAirJourneys(copy)
    setFocusedAirJourney(airJourney)
  }

  const updateJourneys = (updated: Journey) => {
    /* Change map's data */
    const copy = journeys.slice()
    const index = copy.findIndex((val) => val.id === updated.id)
    copy.splice(index, 1, { ...updated })

    setJourneys(copy)
  }

  const updateJourneyDetails = (updated: BackendJourney) => {
    const [formattedJourney] = processJourneys([updated], routeList, [])
    const copy = journeys.slice()
    const index = copy.findIndex((val) => val.id === updated.id)

    copy.splice(index, 1, { ...formattedJourney })
    const geojsonData = journeyRoutesToGeoJSON(copy, routeList)
    setGeoJSONJourneys(geojsonData);
    (mapRef.current.getSource('journeys') as mapboxgl.GeoJSONSource).setData(geojsonData)

    setFocussedJourney(formattedJourney)
    setGeoJSONJourneys(geojsonData)
    setJourneys(copy)
  }

  const journeyDisplay = (ids: number[]) => {
    /* Filtered routes based on selection */
    const features = routes.features.filter((route) => ids.indexOf(route.properties.id) >= 0);

    (mapRef.current.getSource('journey-routes') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features,
    })
  }

  const deleteMb = (id) => {
    setShowLoading(true)
    apiHook.archiveMovementBox({ movement_box_id: id, domain_id: workspace.id }).then(() => {
      /* Remove movement box from map and from array */
      const features = movementBoxes.filter((box) => box.properties.id !== id).slice();
      (mapRef.current.getSource('restriction-boxes') as mapboxgl.GeoJSONSource).setData({
        type: 'FeatureCollection',
        features,
      })
      setShowLoading(false)
      setMovementBoxes(features)
      setFocusedMb(null)
    })
  }

  const getHeights = () => {
    if (showAirJourneyForm) {
      return '100%'
    }

    return '92%'
  }

  const handleAirJourneyClick = (airJourney: AirJourney) => {
    console.log(airJourney)
    setFocusedAirJourney(airJourney)
    setShowMapKey(false)
    setShowAirJourneyForm(true)
    handleViewChange(JourneyViews.AIR_TRAVEL)
  }

  const updateAirJourney = (airJourney: AirJourney) => {
    const copy = airJourneys.slice()
    const index = copy.findIndex((val) => val.id === airJourney.id)

    if (index >= 0) {
      /* Update entry and set state */
      copy.splice(index, 1, { ...airJourney })
      setAirJourneys(copy)
    }
  }

  const removeAirPins = () => {
    if (fromPin) {
      fromPin.remove()
      setFromPin(null)
    }
    if (toPin) {
      toPin.remove()
      setToPin(null)
    }
  }

  // Functionality for the JourneyWidget component

  const getUniqueFeatures = (array, comparatorProperty) => {
    const existingFeatureKeys = {}
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    const uniqueFeatures = array.filter((el) => {
      if (existingFeatureKeys[el.properties[comparatorProperty]]) {
        return false
      }
      existingFeatureKeys[el.properties[comparatorProperty]] = true
      return true
    })

    return uniqueFeatures
  }

  const showForm = (journey: Journey) => {
    setShowJourneyAuthorization(false)
    setShowTicket(false)
    setShowBackToJourneysMapButton(false)
    setShowJourneysList(false)
    setShowMap(true)
    setFocussedJourney(journey)

    if (journey.status === 'Complete') {
      handleViewChange(JourneyViews.HISTORY)
    } else {
      handleViewChange(JourneyViews.ROAD_TRAVEL)
    }

    if (journey.approver.id === user_id && journey.status === 'Pending') {
      setShowJourneyAuthorization(true)
    } else if (journey.submitter.id === user_id && journey.status === 'Pending') {
      setShowEditJourney(true)
    } else {
      setShowTicket(true)
    }
  }

  const deletePath = (id: number) => {
    ionAlert({
      header: 'Delete Route?',
      message: 'Do you wish to continue? Your route will be deleted.',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {
          },
        },
        {
          text: 'Yes, delete',
          handler: () => removeRoute(id),
        },
      ],
    })
  }

  return {
    view,
    controlsRef,
    mapRef,
    setView,
    loading,
    setLoading,
    routes,
    setRoutes,
    showJourneyAuthorization,
    setShowJourneyAuthorization,
    showTicket,
    setShowTicket,
    showEditJourney,
    setShowEditJourney,
    focussedJourney,
    setFocussedJourney,
    showJourneyForm,
    setShowJourneyForm,
    showAirJourneyForm,
    setShowAirJourneyForm,
    removeRoute,
    drawRoutes,
    drawAssets,
    drawAlertStates,
    showSecurityHeatMap,
    drawJourneys,
    handleViewChange,
    views,
    mbDrawn,
    setMbDrawn,
    submittedPopup,
    setSubmittedPopup,
    airJourneys,
    setAirJourneys,
    showMbForm,
    setshowMbForm,
    showRouteForm,
    setShowRouteForm,
    showJourneyChoice,
    setShowJourneyChoice,
    focusedAirJourney,
    setFocusedAirJourney,
    fromPin,
    setFromPin,
    toPin,
    setToPin,
    filters,
    setFilters,
    assets,
    setAssets,
    regions,
    setRegions,
    counties,
    setCounties,
    incidents,
    setIncidents,
    pushNewRoute,
    newBox,
    setNewBox,
    journeys,
    setJourneys,
    qgjourneys,
    setQgJourneys,
    showLoading,
    setShowLoading,
    movementBoxes,
    setMovementBoxes,
    focusedMb,
    setFocusedMb,
    users,
    setUsers,
    showMapControls,
    setShowMapControls,
    showJourneysList,
    setShowJourneysList,
    showAirTravelList,
    setShowAirTravelList,
    showMap,
    setShowMap,
    showBackToJourneysMapButton,
    setShowBackToJourneysMapButton,
    showMapKey,
    setShowMapKey,
    paintMBs,
    restrictionBoxPopup,
    cancelMBEdit,
    updateMovementBox,
    incidentsPopup,
    getRouteFrequency,
    createFlightPath,
    setUpListeners,
    startMB,
    journeyMode,
    startJourney,
    startAirJourney,
    startRoute,
    finishJourney,
    addAirJourney,
    updateJourneys,
    journeyDisplay,
    deleteMb,
    getHeights,
    handleAirJourneyClick,
    updateAirJourney,
    removeAirPins,
    selectedRoutes,
    setSelectedRoutes,
    getUniqueFeatures,
    showForm,
    deletePath,
    boxMeasures,
    setRoutePins,
    routePins,
    restrictionZones,
    showZoneForm,
    domainUsers,
    pushNewZone,
    setEditingBox,
    editingBox,
    updateRestrictionZone,
    removeRestrictionZone,
    causes,
    incidentTypes,
    pushNewJourney,
    updateJourneyDetails,
  }
}

export const ProvideJourneysData2 = ({ children }) => {
  const data = useProvideJourneysData2()
  return (
    <journeysContext2.Provider value={data}>
      {
        children
      }
    </journeysContext2.Provider>
  )
}
