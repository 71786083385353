/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import { Icon } from '@iconify/react'
import {
  IonContent, IonList, IonRow, IonCol, IonSpinner,
  useIonAlert,
} from '@ionic/react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import useApi from '../../hooks/testHooks'

import { MetricContainer } from '../GlobalContainers'
import { SettingField } from '../settings/StyledContainers'
import ObjectiveDetails from './ObjectiveDetails'

import AddObjective from './AddObjective'
import {
  statuses, colorCodeStatus, defineOptions, crossReferenceObjectives,
} from './StatusHelper'
import { useWorkspace } from '../../hooks/useWorkspace'

/**
 *  ===========================
 *      Project Details View
 *  ===========================
 *  See project's information and edit it
 */
const ProjectDetails = ({
  selectedProject, users, updateObjective, updateProject, addObjectives, setLoading, isObjectiveView, setIsObjectiveView,
  focussedObjective, setFocussedObjective, editingObjective, setEditingObjective, createObjective, setCreateObjective,
}) => {
  /* Toggle Project details */
  const [showDetails, setShowDetails] = useState(true)
  /* Show specific objective view */

  /* Objectives delete checkbox */
  const [deleteObjectives, setDeleteObjectives] = useState((selectedProject && selectedProject.objectives)
    ? Object.fromEntries(selectedProject.objectives.map((objective) => [objective.id, false])) : {})
  /* Toggle all delete objectives */
  const [toggleAll, setToggleAll] = useState(false)

  /* Application hooks */
  const apiHook = useApi()
  /* Application hooks */
  const { workspace } = useWorkspace()

  /* Display Popup message */
  const [ionAlert] = useIonAlert()
  const popupObjective = (objective) => {
    setFocussedObjective(objective)
    setIsObjectiveView(true)
  }
  const closeObjective = () => {
    setFocussedObjective(null)
    setIsObjectiveView(false)
    setEditingObjective(false)
  }
  const handleCheckboxes = (e, id) => {
    const updated = { ...deleteObjectives, [id]: e.target.checked }

    if (!e.target.checked) setToggleAll(false)
    if (Object.values(updated).findIndex((param) => !param) < 0) setToggleAll(true)

    setDeleteObjectives({ ...deleteObjectives, [id]: e.target.checked })
  }

  const handleToggleAll = (e) => {
    /* Set all checkboxes to true and call for update */
    setToggleAll(e.target.checked)
    const updated = Object.fromEntries(Object.keys(deleteObjectives).map((key) => [key, e.target.checked]))
    setDeleteObjectives(updated)
  }

  const pushObjective = async (newObj) => {
    setLoading(true)

    const formattedObj = {
      ...newObj,
      owner: newObj.owner.value,
      startDate: newObj.startDate.format('YYYY-MM-DD HH:mm'),
      endDate: newObj.endDate.format('YYYY-MM-DD HH:mm'),
      tasks: [],
      status: 'Planning',
    }
    const requestParam = {
      title: formattedObj.name,
      deadline: formattedObj.endDate,
      description: formattedObj.description,
      owner: formattedObj.owner,
      start: formattedObj.startDate,
    }
    const [id] = (await apiHook.createObjective(selectedProject.id, [requestParam])).id

    setLoading(false)

    if (id === -2) {
      ionAlert({
        header: 'Server Error',
        message: 'The dates of this objective might be outside the projects date',
      })
      return
    }

    if (id === -1) return

    formattedObj.id = id

    /* Delete temp variable */
    delete formattedObj.edit
    delete formattedObj.new

    addObjectives(selectedProject.id, [...selectedProject.objectives, formattedObj])
    setDeleteObjectives(((selectedProject && selectedProject.objectives)
      ? Object.fromEntries([...selectedProject.objectives, formattedObj].map((objective) => [objective.id, false])) : {}))
    setCreateObjective(false)
  }

  const handleStatus = async (e) => {
    const newStatus = e.target.value

    const progress = async () => {
      setLoading(true)
      await apiHook.progress_project(selectedProject.id).then((data) => {
        if (data.message === 'Objectives remain') {
          setLoading(false)
        } else if (data.message === 'No objectives were found') {
          setLoading(false)
        } else {
          updateProject({ ...selectedProject, status: newStatus }, false)
          setLoading(false)
        }
      })
    }

    /* Regress project */
    const regress = async () => {
      setLoading(true)
      await apiHook.regress_project(selectedProject.id)
      updateProject({ ...selectedProject, status: newStatus }, false)
      setLoading(false)
    }

    /* Abort project (cancel) */
    const cancel = async () => {
      setLoading(true)
      await apiHook.abort_project(selectedProject.id)
      updateProject({ ...selectedProject, status: newStatus }, false)
      setLoading(false)
    }

    let message = ''
    let handler
    let header = 'Attention'

    if (e.target.value === 'Complete') {
      header = 'Note'
      message = 'Once a project is complete it cannot be reverted. Continue?'
      handler = () => progress()
    }
    if (e.target.value === 'Cancel') {
      message = 'Do you wish to continue? This action cannot be undone.'
      header = 'Abort Project?'
      handler = () => cancel()
    } else if (statuses.indexOf(e.target.value) > statuses.indexOf(selectedProject.status)) {
      if (e.target.value !== 'Complete') {
        message = 'Do you want to progress the status of this project?'
        handler = () => progress()
      }
    } else if (statuses.indexOf(e.target.value) < statuses.indexOf(selectedProject.status)) {
      message = 'Do you want to regress the status of this project?'
      handler = () => regress()
    } else if (e.target.value === 'Complete') {
      header = 'Note'
      message = 'Once a project is complete it cannot be reverted. Continue?'
      handler = () => progress()
    }

    ionAlert({
      header,
      message,
      buttons: [
        {
          text: 'Back',
          handler: () => {
            setLoading(false)
          },
        },
        {
          text: 'Yes, continue',
          handler,
        },
      ],
    })
  }

  const removeObjectives = () => {
    if (!Object.values(deleteObjectives).length) return

    const remove = () => {
      const ids = Object.keys(deleteObjectives).filter((id) => deleteObjectives[id])
      // TODO: send request with the ids
      apiHook.deleteObjectives({
        domain_id: workspace.id,
        ids,
      })
      const updatedObjectives = selectedProject.objectives.filter((objective) => (ids.indexOf(String(objective.id)) < 0))
      updateProject({ ...selectedProject, objectives: updatedObjectives }, false)
      setToggleAll(false)
    }

    ionAlert({
      header: 'Delete Objectives',
      message: 'Are you sure you want to delete the objective(s)?',
      buttons: [
        'cancel',
        {
          text: 'Ok', handler: remove,
        },
      ],
    })
  }

  const getMetricRows = (metrics) => {
    const formatted = metrics.reduce((rows, key, index) => (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows, [])

    return formatted
  }

  useEffect(async () => {
    if (!selectedProject || selectedProject.objectives) return
    /* If objectives haven't been fetched before then send a request to get them */
    const response = await apiHook.getProjectDetails(selectedProject.id)
    const { metric } = await apiHook.getMetricDetailed({ id: selectedProject.metric_ids })
    addObjectives(selectedProject.id, crossReferenceObjectives(response), metric)
    setCreateObjective(false)
  }, [selectedProject])

  // console.log(selectedProject)

  return (
    <>
      {
        (focussedObjective) ? (
          <ObjectiveDetails
            focussedObjective={focussedObjective}
            users={users}
            onClose={closeObjective}
            updateObjective={updateObjective}
            setLoading={setLoading}
            selectedProject={selectedProject}
            isObjectiveView={isObjectiveView}
            setIsObjectiveView={setIsObjectiveView}
            editingObjective={editingObjective}
            setEditingObjective={setEditingObjective}
            updateProject={updateProject}
          />
        )
          : (
            <IonContent style={{ '--background': 'white', overflow: 'hidden', height: '100%' }}>
              {
                (createObjective) ? (
                  <AddObjective
                    editObjective={(index, input) => pushObjective(input)}
                    index={null}
                    onCancel={() => {
                      setCreateObjective(false)
                      setIsObjectiveView(false)
                    }}
                    users={users}
                    project={{
                      startDate: selectedProject.startDate,
                      endDate: selectedProject.endDate,
                    }}
                  />
                ) : (
                  <div style={{ height: '100%' }}>
                    <IonRow style={{ backgroundColor: '#FFFFFF', padding: '15px' }} className='ion-justify-content-between'>
                      <SettingField style={{ width: 'auto' }}>
                        <h5 className='blue'>Project Title</h5>
                        <p>{(selectedProject) ? selectedProject.title : 'Project title will appear here'}</p>
                      </SettingField>
                      <SettingField style={{ width: 'auto' }}>
                        <h5 className='blue'>Project Category</h5>
                        <p>{(selectedProject) ? selectedProject.category : 'Project category will appear here'}</p>
                      </SettingField>
                      <SettingField style={{ width: 'auto' }}>
                        <h5 className='blue'>Start Date</h5>
                        <p>{(selectedProject) ? moment(selectedProject.start).format('DD/MM/YY') : 'Project start will appear here'}</p>
                      </SettingField>
                      <SettingField style={{ width: 'auto' }}>
                        <h5 className='blue'>End Date</h5>
                        <p>{(selectedProject) ? moment(selectedProject.deadline).format('DD/MM/YY') : 'Project end will appear here'}</p>
                      </SettingField>
                      <SettingField style={{ width: 'auto' }}>
                        <h5 className='blue'>Project Status</h5>
                        {
                          (selectedProject) ? (
                            <>
                              {
                                selectedProject.status === 'Cancel' && (
                                  <p
                                    className='journey-status'
                                    style={{ backgroundColor: colorCodeStatus(selectedProject.status), textAlign: 'center' }}
                                  >{ selectedProject.status }
                                  </p>
                                )
                              }
                              {
                                selectedProject.status === 'Complete' && (
                                  <p className='journey-status' style={{ backgroundColor: colorCodeStatus(selectedProject.status), textAlign: 'center', color: 'white' }}>
                                    { selectedProject.status }
                                  </p>
                                )
                              }
                              {
                                workspace.type === 'member' && (
                                  <p className='journey-status' style={{ backgroundColor: colorCodeStatus(selectedProject.status), textAlign: 'center', color: 'white' }}>
                                    { selectedProject.status }
                                  </p>
                                )
                              }
                              {
                                selectedProject.status !== 'Cancel' && selectedProject.status !== 'Complete' && workspace.type !== 'member' && (
                                  <select className='journey-status' style={{ backgroundColor: colorCodeStatus(selectedProject.status) }} value={selectedProject.status} onChange={handleStatus}>
                                    {
                                      defineOptions(selectedProject.status).map((status) => (
                                        <option key={status} value={status}>{ status }</option>
                                      ))
                                    }
                                  </select>
                                )
                              }
                            </>
                          ) : (
                            <p>Project status will appear here</p>
                          )
                        }
                      </SettingField>
                    </IonRow>

                    <IonList
                      style={{
                        '--ion-background-color': 'white', padding: '15px 20px', overflowY: 'scroll', maxHeight: 'calc(100vh - 280px)', overflowX: 'hidden',
                      }}
                    >
                      {showDetails && selectedProject && (
                      <>
                        <IonRow style={{ paddingLeft: '2%', paddingRight: '10%' }}>
                          {selectedProject.problem && (
                            <IonCol>
                              <SettingField>
                                <h5 className='blue'>Problem To be Solved</h5>
                                <p>{selectedProject.problem}</p>
                              </SettingField>
                            </IonCol>
                          )}
                          {selectedProject.goal && (
                            <IonCol>
                              <SettingField>
                                <h5 className='blue'>Project Goal</h5>
                                <p>{selectedProject.goal}</p>
                              </SettingField>
                            </IonCol>
                          )}
                        </IonRow>
                        <IonRow style={{ paddingLeft: '2%', paddingRight: '10%' }}>
                          {selectedProject.internal_project_stakeholders?.length > 0 && (
                            <IonCol>
                              <SettingField>
                                <h5 className='blue'>Associated Stakeholders</h5>
                                <ul>
                                  {selectedProject.internal_project_stakeholders.map((stakeholder) => (
                                    <li key={stakeholder.stakeholder.id}>{stakeholder.stakeholder.name}</li>
                                  ))}
                                </ul>
                              </SettingField>
                            </IonCol>
                          )}
                          {selectedProject.project_communities?.length > 0 && (
                            <IonCol>
                              <SettingField>
                                <h5 className='blue'>Associated Communities</h5>
                                <ul>
                                  {selectedProject.project_communities.map((community) => (
                                    <li key={community.community.id}>{community.community.name}</li>
                                  ))}
                                </ul>
                              </SettingField>
                            </IonCol>
                          )}
                        </IonRow>
                        <IonRow style={{ paddingLeft: '2%', paddingRight: '10%' }}>
                          {selectedProject.project_assets?.length > 0 && (
                            <IonCol>
                              <SettingField>
                                <h5 className='blue'>Associated Key Assets</h5>
                                <ul>
                                  {selectedProject.project_assets.map((projectAsset) => (
                                    <li key={projectAsset.asset.id}>{projectAsset.asset.name}</li>
                                  ))}
                                </ul>
                              </SettingField>
                            </IonCol>
                          )}
                          {selectedProject.project_team_members?.length > 0 && (
                            <IonCol>
                              <SettingField>
                                <h5 className='blue'>Team Members</h5>
                                <ul>
                                  {selectedProject.project_team_members.map((member) => (
                                    <li key={member.user.id}>{`${member.user.first_name} ${member.user.final_name}`}</li>
                                  ))}
                                </ul>
                              </SettingField>
                            </IonCol>
                          )}
                        </IonRow>
                        {selectedProject.summary && (
                          <SettingField style={{ marginLeft: '3%', marginRight: '10%' }}>
                            <h5 className='blue'>Summary of Activities</h5>
                            <p>{selectedProject.summary}</p>
                          </SettingField>
                        )}
                        {selectedProject.support && (
                          <SettingField style={{ marginLeft: '3%', marginRight: '10%' }}>
                            <h5 className='blue'>Support Required</h5>
                            <p>{selectedProject.support}</p>
                          </SettingField>
                        )}
                        {selectedProject.project_lead_id && (
                          <SettingField style={{ marginLeft: '3%', marginRight: '10%' }}>
                            <h5 className='blue'>Project Leader</h5>
                            <p>
                              {users.map((user) => user.id === selectedProject.project_lead_id && `${user.first_name} ${user.final_name}`)}
                            </p>
                          </SettingField>
                        )}
                      </>
                      )}
                      {selectedProject && selectedProject.type === 'External' && (
                      <SettingField>
                        <h4>Metrics</h4>
                        {!selectedProject && (
                        <p>The metrics set for the project will appear here </p>
                        )}
                        {
                          selectedProject && !selectedProject.metrics && (
                            <IonRow className='ion-justify-content-center' style={{ paddingTop: '40px' }}>
                              <IonSpinner />
                            </IonRow>
                          )
                        }
                        {
                          selectedProject && selectedProject.metrics && selectedProject.metrics.length > 0
                            && getMetricRows(selectedProject.metrics).map(([metricA, metricB], index) => (
                              <IonRow key={index} className='ion-align-items-center ion-justify-content-around'>
                                <MetricContainer>
                                  <IonRow className='ion-align-items-center ion-jusitfy-content-around'>
                                    <IonCol size={3}>
                                      <Icon icon='bx:bxs-bar-chart-alt-2' />
                                    </IonCol>
                                    <IonCol size={6}>
                                      <h4> { metricA.metric } </h4>
                                      <p className='theme'> { metricA.theme } </p>
                                    </IonCol>
                                    <IonCol size={3}>
                                      <p className='current'> { metricA.current } </p>
                                    </IonCol>
                                  </IonRow>
                                </MetricContainer>
                                {
                                  metricB && (
                                    <MetricContainer>
                                      <IonRow className='ion-align-items-center ion-jusitfy-content-around'>
                                        <IonCol size={3}>
                                          <Icon icon='bx:bxs-bar-chart-alt-2' />
                                        </IonCol>
                                        <IonCol size={6}>
                                          <h4> { metricB.metric } </h4>
                                          <p className='theme'> { metricB.theme } </p>
                                        </IonCol>
                                        <IonCol size={3}>
                                          <p className='current'> { metricB.current } </p>
                                        </IonCol>
                                      </IonRow>
                                    </MetricContainer>
                                  )
                                }
                              </IonRow>
                            ))
                        }
                      </SettingField>
                      )}
                      {
                        selectedProject && !selectedProject.objectives && (
                          <IonRow className='ion-justify-content-center' style={{ paddingTop: '40px' }}>
                            <IonSpinner />
                          </IonRow>
                        )
                      }
                    </IonList>
                  </div>
                )
              }
            </IonContent>
          )
      }
    </>
  )
}

export default ProjectDetails
