/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import {
  IonButton, IonGrid, IonRow, IonLabel, IonRadioGroup, IonItem,
  IonRadio, useIonAlert,
} from '@ionic/react'
import { Dropdown, Form } from 'react-bootstrap'
import Select from 'react-select'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from '@iconify/react'
import { ConsultantFilter } from './StyledContainers'
import { SherpaButton } from '../GlobalContainers'

import { SelectView } from '../maps/StyledContainers'
import IncidentsFilter from '../maps/Filters/IncidentsFilter'

export const currentlySelected = (filters) => {
  const selected = Object.values(filters).filter((param) => !!param).length
  return (selected === 0) ? 'Nothing Selected' : `${selected} items selected`
}

/**
 *  =============================
 *    Map and List controllers
 *  =============================
 */
const ConsultantControls = ({
  view, setView, mode, selectedCountry, selectedRegion,
  handleModeChange, incidentFilters, updateIncidents,
  showIncidents, showMbs, handleMbs, handleIncidents,
  showRoutes, handleRoutes, canChange, addMbs, addRoutes,
  addIncidents, popupForm, incidentTimeFilter, selected,
  setIncidentTimeFilter, setFromMap, crowdFilter, suggestedFilter,
  filterCountries, filterRegions, countryList, filterCrowd, filterSuggested,
  setShowMultiAS, editing, setEditing, setCanChange,
}) => {
  const views = ['Incidents', 'Alert States', 'Fusion']
  const incidentTimes = [
    { label: 'Show All', value: 'all' },
    { label: 'Last 24 hours', value: 1 },
    { label: 'Last 7 days', value: 7 },
    { label: 'Last Month', value: 30 },
    { label: 'Last 3 Months', value: 3 * 30 },
    { label: 'Last 6 Months', value: 6 * 30 },
    { label: 'Last Year', value: 365 },
  ]
  const [ionAlert] = useIonAlert()
  const [showCountries, setShowCountries] = useState(false)
  const [showRegions, setShowRegions] = useState(false)

  const handleEdit = () => {
    if (view === 'map') {
      setFromMap(true)
      setView('list')
    }

    if (view === 'list') {
      if (selected.counties.length > 0) {
        setShowMultiAS(true)
      } else {
        ionAlert({
          header: 'Error - No Counties selected.',
          message: 'You need to pick at least one county.',
          buttons: [
            { text: 'Ok' },
          ],
        })
      }
    }
  }

  const handleEditIncidents = () => {
    if (view === 'map' && !editing) {
      setFromMap(true)
    }
    if (!editing) {
      setCanChange(false)
    }
    setEditing(!editing)
  }

  const onToggleHandler = (isOpen, metadata, update) => {
    if (metadata.source !== 'select') {
      update(isOpen)
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxheight: '35px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '35px',
    }),
  }

  const handleTimeFilter = (selectedTime) => {
    updateIncidents(incidentFilters, selectedTime.value)
    setIncidentTimeFilter(selectedTime.value)
  }

  return (
    <IonGrid
      style={{
        backgroundColor: '#FFFFFF',
        padding: '10px 30px',
        marginBottom: '20px',
        boxShadow: '0px 4px 4px 0px #00000040',
        position: 'relative',
        borderRadius: '5px',
        flex: '0 1 auto',
        width: '100%',
      }}
    >
      <IonRow className='justify-content-between'>
        <SelectView
          value={mode}
          onChange={handleModeChange}
          disabled={!canChange}
        >
          {
            views.map((val) => (
              <option key={val} value={val}>{val}</option>
            ))
          }
        </SelectView>
        <IonRow>
          {
            mode !== 'Alert States' && (
              <Dropdown align='end' style={{ margin: '0 10px' }}>
                <Dropdown.Toggle style={{ backgroundColor: '#0C9500' }} className='map-button remove-arrow'> Add <FontAwesomeIcon icon={faPlus} /></Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    mode === 'Fusion' && (
                    <>
                      <Dropdown.Item onClick={() => {
                        popupForm()
                        addMbs()
                      }}
                      > Restriction Box
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        popupForm()
                        addRoutes()
                      }}
                      > Route
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => {
                        popupForm()
                        addIncidents()
                      }}
                      > Incident
                      </Dropdown.Item>
                    </>
                    )
                  }
                  {
                    mode === 'Incidents' && (
                      <>
                        <Dropdown.Item onClick={() => {
                          popupForm()
                          addIncidents()
                        }}
                        > Add Incident
                        </Dropdown.Item>
                      </>
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
            )
          }
          {
            (mode === 'Alert States') && (
              <IonButton onClick={handleEdit} style={{ '--background': '#4197A9', margin: '0' }}>Edit</IonButton>
            )
          }
          {
            mode === 'Incidents' && (view === 'list' || !editing) && (
              <IonButton onClick={handleEditIncidents} style={{ '--background': '#4197A9', margin: '0' }}>{ (editing) ? 'Done' : 'Edit' }</IonButton>
            )
          }
        </IonRow>
      </IonRow>
      <IonRow className='ion-justify-content-between ion-align-items-center' style={{ marginTop: '20px' }}>
        {
          mode !== 'Fusion' && (
            <SherpaButton
              style={{
                fontSize: '1.3rem',
                alignItems: 'center',
                display: 'flex',
              }}
              onClick={() => setView((view === 'list') ? 'map' : 'list')}
            >
              {
            (view === 'map') ? (
              <><Icon style={{ margin: '0 5px' }} icon='ant-design:unordered-list-outlined' />List View</>
            ) : (
              <><Icon style={{ margin: '0 5px' }} icon='carbon:map' />Map View</>
            )
          }
            </SherpaButton>
          )
        }
        {
          mode === 'Incidents' && (
            <>
              <ConsultantFilter>
                <h4>Incident Type: </h4>
                <Dropdown>
                  <Dropdown.Toggle>{ currentlySelected(incidentFilters) }</Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: '200px', overflowY: 'auto' }}
                  >
                    <IncidentsFilter
                      update={(filters) => updateIncidents(filters, incidentTimeFilter)}
                      incidentFilters={incidentFilters}
                      toggle={(Object.values(incidentFilters).findIndex((param) => !param) < 0)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </ConsultantFilter>
              <ConsultantFilter>
                <h4> Time </h4>
                <Select
                  options={incidentTimes}
                  className='consultants-dropdowns'
                  maxMenuHeight='50'
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  onChange={handleTimeFilter}
                  value={incidentTimes.find((option) => option.value === incidentTimeFilter)}
                />
              </ConsultantFilter>
            </>
          )
        }
        {
          mode === 'Alert States' && (
            <>
              <ConsultantFilter>
                <h4>Country:</h4>
                <Dropdown style={{ margin: '0 5px' }} align='end' show={showCountries} onToggle={(isOpen, metadata) => onToggleHandler(isOpen, metadata, setShowCountries)}>
                  <Dropdown.Toggle className='map-button remove-arrow'>{ (selectedCountry === 'All') ? 'Show All' : selectedCountry }</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <IonRadioGroup value={selectedCountry} onIonChange={(e) => filterCountries(e.detail.value)}>
                      <IonItem style={{ '--background': 'white' }}>
                        <IonLabel>Show All</IonLabel>
                        <IonRadio slot='end' value='All' />
                      </IonItem>
                      {
                        Object.keys(countryList).map((category) => (
                          <IonItem key={category} style={{ '--background': 'white' }}>
                            <IonLabel>{ category }</IonLabel>
                            <IonRadio slot='end' value={category} />
                          </IonItem>
                        ))
                      }
                    </IonRadioGroup>
                  </Dropdown.Menu>
                </Dropdown>
              </ConsultantFilter>
              <ConsultantFilter>
                <h4>Region:</h4>
                {
                  selectedCountry !== 'All' ? (
                    <Dropdown style={{ margin: '0 5px' }} align='end' show={showRegions} onToggle={(isOpen, metadata) => onToggleHandler(isOpen, metadata, setShowRegions)}>
                      <Dropdown.Toggle className='map-button remove-arrow'>
                        { (selectedRegion === 'All') ? 'Show All' : countryList[selectedCountry].regions.find(({ id }) => id === selectedRegion).name }
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <IonRadioGroup value={selectedRegion} onIonChange={(e) => filterRegions(e.detail.value)}>
                          <IonItem style={{ '--background': 'white' }}>
                            <IonLabel>Show All</IonLabel>
                            <IonRadio slot='end' value='All' />
                          </IonItem>
                          {
                            countryList[selectedCountry].regions.map(({ name, id }) => (
                              <IonItem key={id} style={{ '--background': 'white' }}>
                                <IonLabel>{ name }</IonLabel>
                                <IonRadio slot='end' value={id} />
                              </IonItem>
                            ))
                          }
                        </IonRadioGroup>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <p style={{ margin: 0 }}>No Country Selected</p>
                  )
                }
              </ConsultantFilter>
              <ConsultantFilter>
                <h4>Crowd Insights</h4>
                <select onChange={filterCrowd} value={crowdFilter}>
                  <option value='all'>Show All</option>
                  <option value='higher'>Higher</option>
                  <option value='lower'>Lower</option>
                  <option value='unchanged'>Unchanged</option>
                </select>
              </ConsultantFilter>
              <ConsultantFilter>
                <h4>Suggested</h4>
                <select onChange={filterSuggested} value={suggestedFilter}>
                  <option value='all'>Show All</option>
                  <option value='higher'>Higher</option>
                  <option value='lower'>Lower</option>
                  <option value='unchanged'>Unchanged</option>
                </select>
              </ConsultantFilter>
            </>
          )
        }
        {
          mode === 'Fusion' && (
            <>
              <Form.Check checked={showIncidents} onChange={handleIncidents} type='checkbox' label='Incidents' />
              <Form.Check checked={showMbs} onChange={handleMbs} type='checkbox' label='Restriction Boxes' />
              <Form.Check checked={showRoutes} onChange={handleRoutes} type='checkbox' label='Routes' />
            </>
          )
        }
      </IonRow>
    </IonGrid>
  )
}

export default ConsultantControls
