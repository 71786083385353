import React, { useEffect, useState } from 'react'
import {
  IonList, IonInput, IonItem,
  IonButton, IonRow, useIonAlert,
} from '@ionic/react'
import CreatableSelect from 'react-select/creatable'
import 'react-datetime/css/react-datetime.css'
import Datetime from 'react-datetime'
import moment from 'moment'
import { FormTitle } from '../StyledContainers'
import { useAuth } from '../../../hooks/useAuth'
import { BlueButton } from '../../incident-management/StyledContainers'
import { getCurrentLevel } from '../apps/AppMap'

/**
 *  ==========================
 *    FLIGHT REQUEST FORM
 *  ==========================
 */
const empty = {
  airline_name: '',
  flight_no: '',
  departureTime: '',
  passengers: [],
}

const FlightRequestForm = ({
  onSubmit, users, defaultValues, onApprove, onDecline,
}) => {
  /* Manage form data */
  const [input, setInput] = useState((defaultValues) ? ({ ...defaultValues, ...empty }) : empty)
  const [declineReason, setDeclineReason] = useState(null)
  const [readonly, setReadonly] = useState(true)
  const [ionAlert] = useIonAlert()
  const { user } = useAuth()

  /* Handle change on passengers */
  const handlePassengers = (selected) => {
    setInput({ ...input, passengers: selected })
  }

  const returnMessage = (key) => {
    /* Purpose details */
    if (key === 'purpose') return { message: 'Please specify the purpose of the journey' }

    if (key === 'airline_name') return { message: 'Please specify the airline name' }

    if (key === 'flight_no') return { message: 'Please specify the flight number' }
    return null
  }

  /* Handle submit form -> Check required inputs */
  const handleSubmit = () => {
    if (!input.passengers.length) {
      ionAlert({
        header: 'Error - Passenger Details',
        message: 'You must add at least one passenger.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    const notFilled = Object.keys(input).find((key) => !input[key])
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    onSubmit({
      ...input,
      departureTime: input.departureTime.format('YYYY-MM-DD HH:mm'),
      passengers: input.passengers.map((selected) => selected.value).join(', '),
    })
  }

  /* Chand input state */
  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  useEffect(() => {
    setInput(defaultValues)
    if (defaultValues && defaultValues.submitter.id === user.user_id
      && defaultValues.stage === 'Pre-Travel' && defaultValues.status === 'Approved') {
      setReadonly(false)
      return
    }

    /* If read only find the most up to date decline reason */
    const reasons = defaultValues.story.filter(({ category }) => category === 'Flight-Request Declined')
    setDeclineReason(reasons[reasons.length - 1])

    setReadonly(true)
  }, [defaultValues])

  // flight request
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ overflowY: 'auto', flexGrow: '1' }}>
        <IonList style={{ padding: '15px', paddingTop: '0', contain: 'initial' }}>
          <FormTitle>
            <h3 style={{ fontSize: '1rem' }}>Flight details</h3>
          </FormTitle>
          {
            (readonly) ? (
              <p id='flight-request-view-airline'>{ defaultValues.airline_name }</p>
            ) : (
              <IonItem>
                <IonInput id='fr-airline-name' onIonChange={handleInput} value={input.airline_name} name='airline_name' type='text' placeholder='Airline name' />
              </IonItem>
            )
          }
          {
            (readonly) ? (
              <p id='flight-request-view-flight-number'>{ input.flight_no }</p>
            ) : (
              <IonItem>
                <IonInput id='fr-flight-number' onIonChange={handleInput} value={input.flight_no} name='flight_no' type='text' placeholder='Flight no' />
              </IonItem>
            )
          }
          <FormTitle>
            <h3 style={{ fontSize: '1rem' }}>Date of departure</h3>
          </FormTitle>
          {
            (readonly) ? (
              <p> { moment(defaultValues.departure_date).format('DD/MM/YYYY') } </p>
            ) : (
              <Datetime
                dateFormat='YYYY-MM-DD'
                inputProps={{
                  placeholder: 'Departure date/time',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                className='departure-date'
                value={input.departureTime}
                onChange={(e) => setInput({ ...input, departureTime: e })}
                isValidDate={(current) => current.isAfter(moment().subtract(1, 'day'))}
              />
            )
          }
          <FormTitle>
            <h3 style={{ fontSize: '1rem' }}>Passenger Details</h3>
          </FormTitle>
          {
            (readonly) ? (
              <p> { defaultValues.passengers } </p>
            ) : (
              <CreatableSelect
                isMulti
                placeholder='Passengers'
                name='colors'
                className='select-container'
                id='fr-select-passengers'
                  // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={users.map((dbUser) => ({ value: dbUser.email, label: dbUser.email }))}
                value={input.passengers}
                onChange={handlePassengers}
              />
            )
          }
        </IonList>
      </div>
      {
        (!readonly) ? (
          <IonRow
            style={{
              height: '15%', alignItems: 'center', backgroundColor: 'white', padding: '15px',
            }}
            className='ion-justify-content-end'
          >
            <IonButton
              id='submit-flight-request'
              style={{
                '--background': '#0C9500',
                fontSize: '1.1em',
                textTransform: 'none',
                borderRadius: '0.2em',
                letterSpacing: '-0.02em',
              }}
              onClick={handleSubmit}
            >
              Submit
            </IonButton>
          </IonRow>
        ) : (
          <>
            {
              defaultValues && defaultValues.alert_state && (
              <div style={{ margin: '15px', borderTop: '0.5px solid #747474' }}>
                <FormTitle>
                  <h3 style={{ fontSize: '1rem', color: '#326771' }}>Destination Information</h3>
                </FormTitle>
                <IonRow style={{ marginTop: '10px' }} className='ion-align-items-center ion-justify-content-between'>
                  <p style={{ margin: '0' }}>Alert State:</p>
                  <p
                    className='journey-status'
                    style={{ backgroundColor: getCurrentLevel(defaultValues.alert_state).background, color: getCurrentLevel(defaultValues.alert_state).color }}
                  >
                    { `Level ${defaultValues.alert_state}: ${getCurrentLevel(defaultValues.alert_state).title}` }
                  </p>
                </IonRow>
                <IonRow style={{ marginTop: '10px' }} className='ion-align-items-center ion-justify-content-between'>
                  <p style={{ margin: '0' }}>Security Condition:</p>
                  <p
                    className='journey-status'
                    style={{ backgroundColor: getCurrentLevel(defaultValues.sc_level).background, color: getCurrentLevel(defaultValues.sc_level).color }}
                  >
                    { `Level ${defaultValues.sc_level}: ${getCurrentLevel(defaultValues.sc_level).title}` }
                  </p>
                </IonRow>
              </div>
              )
            }
            {
              defaultValues && defaultValues.story.find(({ category }) => category === 'Flight-Request Approved') && (
              <div style={{ margin: '15px', borderTop: '0.5px solid #747474' }}>
                <FormTitle>
                  <h3 style={{ fontSize: '1rem', color: '#0C9500' }}>Approved by</h3>
                </FormTitle>
                <p> { `${defaultValues.approver.first_name} ${defaultValues.approver.final_name}` } </p>
                <p id='flight-request-approve-reason'>
                  {
                    defaultValues.story && (
                    <>
                      {
                        defaultValues.story.find(({ category }) => category === 'Flight-Request Approved')
                          ? defaultValues.story.find(({ category }) => category === 'Flight-Request Approved').content.split('/b')[0] : 'Unknown reason'
                      }
                    </>
                    )
                  }
                </p>
              </div>
              )
            }
            {
              defaultValues && defaultValues.status === 'Declined' && defaultValues.stage === 'Flight-Request' && (
                <div style={{ margin: '15px', borderTop: '0.5px solid #747474' }}>
                  <FormTitle>
                    <h3 style={{ fontSize: '1rem', color: '#E70000' }}>Declined by</h3>
                  </FormTitle>
                  <p> { `${defaultValues.approver.first_name} ${defaultValues.approver.final_name}` } </p>
                  <p id='flight-request-decline-reason'>
                    {
                      defaultValues.story && (
                        <>
                          {
                            declineReason ? (
                              <> { declineReason.content.split('/b')[0] } </>
                            ) : (
                              <> Unknown Reason </>
                            )
                          }
                        </>
                      )
                    }
                  </p>
                  {
                    defaultValues.submitter.id === user.user_id && (
                      <IonRow className='ion-align-items-center ion-justify-content-center'>
                        <BlueButton id='resubmit-flight-request' onClick={() => setReadonly(false)}>Amend and Resubmit your flight request</BlueButton>
                      </IonRow>
                    )
                  }
                </div>
              )
            }
            {
              (defaultValues && input.stage === 'Flight-Request' && defaultValues.status === 'Pending' && input.approver.id === user.user_id) && (
                <IonRow
                  style={{
                    backgroundColor: 'white', padding: '15px',
                  }}
                  className='ion-justify-content-between ion-align-items-center'
                >
                  <IonButton
                    id='decline-flight-request'
                    style={{
                      '--background': '#8E151F', fontSize: '1.1em', textTransform: 'none', borderRadius: '0.2em', letterSpacing: '-0.02em',
                    }}
                    onClick={() => onDecline('Flight-Request')}
                  >Decline Request
                  </IonButton>
                  <IonButton
                    id='approve-flight-request'
                    style={{
                      '--background': '#0C9500', fontSize: '1.1em', textTransform: 'none', borderRadius: '0.2em', letterSpacing: '-0.02em',
                    }}
                    onClick={() => onApprove('Approved', 'Pre-Departure')}
                  >
                    Approve Request
                  </IonButton>
                </IonRow>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default FlightRequestForm
