import React from 'react'
import { WidgetContainer, WidgetElement } from './StyleContainers'

/**
 *  ======================
 *     Journey Widget
 *  ======================
 */
const JourneyWidget = ({ pending, predepature, transit }) => (
  <WidgetContainer>
    <h4 style={{ color: 'white' }}>Latest Journeys</h4>
    <WidgetElement>
      <p>Pending</p>
      <p>{ pending }</p>
    </WidgetElement>
    <WidgetElement>
      <p>Pre-departure</p>
      <p>{ predepature }</p>
    </WidgetElement>
    <WidgetElement>
      <p>Transit</p>
      <p>{ transit }</p>
    </WidgetElement>
  </WidgetContainer>
)

export default JourneyWidget
