import React, { useState } from 'react'
import { IonGrid, IonSpinner } from '@ionic/react'
import { useAnalystAppData } from '../../hooks/analyst/useAnalystData'
import AnalystControls from './AnalystControls'
import AnalystMap from '../maps/apps/AnalystMap'
import { ObscureBackground, CenterLoader } from '../GlobalContainers'
import MultiEditAS from '../consultant/MultiEditAS'
import { CenterForm } from '../riskregister/StyledContainers'
import FormPopup from '../maps/forms/FormPopup'

/**
 *  ===============================
 *        ANALYST APP CONTENT
 *  ===============================
 *  By having this component the hook's data is easily
 *  accesible.
 */
const AnalystContent = () => {
  /*  Hook's data to know whether to display the multi county selection */
  const {
    showMultiAS, selected, counties, setShowMultiAS, setView, setCounties, dispatchSelected,
    setCountyList,
  } = useAnalystAppData()
  const [loading, setLoading] = useState(true)
  const [submittedMessage, setSubmittedMessage] = useState(null)

  return (
    <IonGrid className='risk-assessment-list'>
      {
        loading && (
          <>
            <ObscureBackground style={{ zIndex: '20' }} />
            <CenterLoader style={{ zIndex: '21' }}>
              <IonSpinner color='light' />
            </CenterLoader>
          </>
        )
      }
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
      {
        showMultiAS && (
          <>
            <ObscureBackground style={{ zIndex: '9' }} />
            <MultiEditAS
              selectedCounties={selected.counties}
              onBack={() => setShowMultiAS(false)}
              analyst
              setLoading={setLoading}
              geojsonCounties={counties}
              updateCounties={(countyList) => {
                setShowMultiAS(false)
                dispatchSelected({ type: 'forget_selections' })
                const visibleCounties = countyList.features.filter(({ properties }) => properties.Alert_color !== 'rgba(255,255,255, 0)').map(({ properties }) => properties)
                setCountyList(visibleCounties)
                setCounties(countyList)
                setSubmittedMessage('Alert State updates submitted for review.')
                setView('map')
              }}
            />
          </>
        )
      }
      <AnalystControls setLoading={setLoading} />
      <AnalystMap setLoading={setLoading} />
    </IonGrid>
  )
}

export default AnalystContent
