import {
  IonButton, IonCol, IonGrid, IonRow, useIonAlert,
} from '@ionic/react'
import React, { FC, useState } from 'react'
import { useSettings } from '../../../hooks/settings/useSettings'
import { IncidentList } from '../../incident-management/StyledContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import { User } from '../../types/GlobalTypes'
import { DomainUsersContainer } from '../StyledContainers'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

const WorkspaceUsers : FC = () => {
  const [searchText, setSearchText] = useState<string>('')
  const {
    workspaceUsers, setShowInvitePopup, removeUser, setLoading,
  } = useSettings()
  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()

  const handleRemoveUser = (userToDelete: User) => {
    const remove = () => {
      setLoading(true)
      axios.put('/api/v1/domain/remove_user', { user_id: userToDelete.id, domain_id: workspace.id }).then(() => {
        removeUser(userToDelete)
      }).finally(() => {
        setLoading(false)
      })
    }

    ionAlert({
      header: 'Remove User',
      message: `Do you wish to remove ${userToDelete.first_name} ${userToDelete.final_name} from this workspace?`,
      buttons: [
        { text: 'yes', handler: remove },
        { text: 'no' },
      ],
    })
  }

  return (
    <DomainUsersContainer>
      <div className='title-container'>
        <h5>Workspace Users</h5>
      </div>
      <IonRow style={{ marginTop: '10px' }} className='ion-justify-content-end'>
        <IonButton onClick={() => setShowInvitePopup(true)} style={{ '--background': '#0C9500' }}>Invite Users</IonButton>
      </IonRow>
      <IonRow style={{ margin: '10px 0' }} className='ion-justify-content-end'>
        <input placeholder='Search Users' className='search-bar' type='text' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
      </IonRow>
      <IonGrid
        className='ion-text-center'
        style={{
          width: '100%',
          padding: '0',
          marginTop: '10px',
          border: '1px solid #7E7E7E',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <IonRow style={{ borderBottom: '1px solid #7E7E7E', textAlign: 'left' }}>
          <IonCol size='8'><h6> Name </h6></IonCol>
          <IonCol size='4'><h6> Action </h6> </IonCol>
        </IonRow>
        <IncidentList style={{ flex: 1, overflow: 'auto', textAlign: 'left' }}>
          {
            workspaceUsers.filter((val) => `${val.first_name} ${val.final_name}`.toLowerCase().includes(searchText)).map((val, index) => (
              <IonRow key={val.id} className='workspace-member ion-align-items-center' style={{ backgroundColor: getColor(index) }}>
                <IonCol size='8'>
                  <h6>{ `${val.first_name} ${val.final_name}` }</h6>
                  <p>{ val.email }</p>
                </IonCol>
                <IonCol size='4'>
                  <SimpleButton onClick={() => handleRemoveUser(val)} style={{ color: '#C30101', padding: 0 }}>
                    Remove User
                  </SimpleButton>
                </IonCol>
              </IonRow>
            ))
          }
        </IncidentList>
      </IonGrid>
    </DomainUsersContainer>
  )
}

export default WorkspaceUsers
