import * as React from 'react'
import * as turf from '@turf/turf'
import * as mapboxgl from 'mapbox-gl'
import { useIonAlert } from '@ionic/react'
import { AssetList, Asset } from '../../components/riskregister/types/Assets'
import { ControlMeasure, Mitigation, RiskAssessment } from '../../components/riskregister/types/RiskAssessments'
import { GeoLocation, IncidentCause, User } from '../../components/types/GlobalTypes'
import { SSstates } from '../../components/riskregister/States'
import {
  focusedAssetReducer,
  focusedRiskReducer,
  displayAssetReducer,
  filtersReducer,
  initialDisplayAsset,
  viewsReducer,
  ViewsActionKind,
  Views,
  ListModes,
  editAssetReducer,
  EditAssetActionKind,
  DisplayAssetAction,
  FocusedActionKind,
  FiltersActionKind,
  FilterActions,
} from './helpers/StateReducers'
import { addAssetsInputReducer, AssetInputActionKind, initialAssetInput } from './helpers/AddAssetReducers'
import { AppView, RiskRegisterInterface } from './types/HookInterfaces'
import useApi from '../testHooks'
import { useWorkspace } from '../useWorkspace'

import {
  addAssessments, addMitigations, editAsset,
  filterAssets, getAssetWithinRadius, pushNewAsset, pushNewRiskAssessment, removeAssessments, removeListOfAssets, removeOldAssetFromLocation, updateRiskAssessment,
} from './helpers/utils'
import { createAssetLayers, createAssetPointLayers, drawAssetsOnMap, removeAllDrawnAssets } from './helpers/MapUtils'
import {
  formatMitigations, formatRequestRisk, processBackendAssets, processBackendRiskAssessments,
} from './helpers/ProcessBackendData'

import {
  BackendAsset, BackendRiskAssessment, BackendRiskEvent, EditMeasures, ProcessedMeasures,
} from './types/BackendData'
import { Community, CountryAreas, Stakeholder } from '../../components/types/OptimizedMaps'
import { createSources } from '../../components/utils/MapboxRendering'
import { processAreas } from '../../components/utils/MapboxUtils'
import axios from 'axios'
import { drawCountyShapesIncidents, fetchAlerStates } from '../../components/maps/apps/AppMap'
import { colorCodeCounties, getIncidentsDictionary } from '../../components/maps/apps/SecurityConditions'
import { Feature, FeatureCollection, Point } from '@turf/turf'
import { useReducer, useRef } from 'react'

type BackendData = [
  { users: User[] },
  { risks: BackendRiskEvent[] },
  { types: string[] },
  { standard_measures: ControlMeasure[], user_measures: ControlMeasure[] },
]

/**
 *  ===========================================
 *      HANDLE STATES FOR RISK REGISTER APP
 *  ===========================================
 *
 *  Should implement the business logic along with the react
 *  components to render the UI
 */
export const riskRegisterContext = React.createContext<RiskRegisterInterface>(null)
export const useRiskRegister = () => React.useContext(riskRegisterContext)

const useProvideRiskRegister = () : RiskRegisterInterface => {
  const apiHook = useApi()
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()

  const [causes, setCauses] = React.useState<IncidentCause[]>([])
  const [regions, setRegions] = React.useState<any[]>([])

  /* Current Drawn (Filtered) assets */
  const [assets, setAssets] = React.useState<AssetList[]>()
  /* All Assets Retrieved from database */
  const [allAssets, setAllAssets] = React.useState<AssetList>({})
  /* Selected assets or risks */
  const [focusedAsset, dispatchAsset] = React.useReducer(focusedAssetReducer, null)
  const [selectedAsset, setSelectedAsset] = React.useState<any>()
  const [focusedRisk, dispatchRisk] = React.useReducer(focusedRiskReducer, null)

  /* Display forms, popups, loading wheels, etc. */
  const [loading, setLoading] = React.useState<boolean>(true)
  const [editing, setEditing] = React.useState<boolean>(false)
  const [showAssetForm, setShowAssetForm] = React.useState<boolean>(false)
  const [showFlashpointForm, setShowFlashpointForm] = React.useState<boolean>(false)
  const [stakeholders, setStakeholders] = React.useState<Stakeholder[]>([])
  const [communities, setCommunities] = React.useState<Community[]>([])

  /* Create reference to send this variable to map popup */
  const editRef = React.useRef(editing)
  editRef.current = editing

  /* Show asset profile and its popups */
  const [displayAsset, dispatchDisplayAsset] = React.useReducer(displayAssetReducer, initialDisplayAsset)
  /* Archangel users */
  const [users, setUsers] = React.useState<User[]>([])
  /* State filters */
  const [filters, dispatchFilters] = React.useReducer(filtersReducer, { states: { ...Object.fromEntries(SSstates.map((state) => [state, true])) }, toggleAll: true })
  const [viewMode, dispatchViews] = React.useReducer(viewsReducer, { view: Views.MAP_VIEW, listMode: ListModes.ASSETS })
  const [view, setView] = React.useState<AppView>(AppView.ASSET_MAP)
  const [showSelectedAsset, setShowSelectedAsset] = React.useState<boolean>(false)
  const [submittedMessage, setSubmittedMessage] = React.useState<string | null>(null)

  // const [focusedElems, dispatchFocused] = useReducer(focusedReducer, { ...initialFocusedState })

  /* Focused asset for relocation (Edit) */
  const [editAssetInput, dispatchEditingAsset] = React.useReducer(editAssetReducer, { asset: undefined, showEditDetails: false, newLocation: undefined })
  const [addAssetInput, dispatchAssetInput] = React.useReducer(addAssetsInputReducer, initialAssetInput)
  const mapRef = React.useRef<mapboxgl.Map>(null)

  const [rawMeasures, setRawMeasures] = React.useState<ControlMeasure[]>([])
  const [manifestations, setManifestations] = React.useState<string[]>([])

  /* Risk types for dropdowns */
  const [riskEvents, setRiskEvents] = React.useState<{ [eventName: string] : string }>({})
  const [assetTypes, setAssetTypes] = React.useState<string[]>([])
  const [eventTypes, setEventTypes] = React.useState<BackendRiskEvent[]>([])
  const [assetType, setAssetType] = React.useState<string>()
  const [incidentTypes, setIncidentTypes] = React.useState<string[]>()

  const [areas, setAreas] = React.useState<CountryAreas[]>([])

  const handleAssetPointClick = (event: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[];
  } & mapboxgl.EventData) => {
    if (event.features.length === 0) { return }
    const { properties } = event.features[0]
    const selected = assets.find(({ id }) => properties.id === id)
    console.log(selected)
    setSelectedAsset(selected)
    setShowSelectedAsset(true)

    if (!selected) { return }
  }

  const handleAssetPointClickRef = useRef(null)
  handleAssetPointClickRef.current = handleAssetPointClick

  const returnToSafe = () => {
    dispatchViews({ type: ViewsActionKind.MAP_VIEW })
  }

  const formatAssets = (counties: turf.FeatureCollection, assetArr: BackendAsset[]) : AssetList => {
    /* Get formatted version of backend assets */
    const processedAssets = processBackendAssets(assetArr, counties)
    setAllAssets(processedAssets)
    setAssets(processedAssets)

    return processedAssets
  }

  const fetchAppData = async (): Promise<void> => Promise.all(
    [apiHook.getUsers(workspace.id), apiHook.riskTypes(workspace.id), apiHook.assetTypes(), apiHook.getFormMeasures({ domain_id: workspace.id })],
  ).then(([usersResponse, { risks }, { types }, { standard_measures, user_measures }] : BackendData) => {
    const riskNames = Object.fromEntries(risks.map(({ name, description }) => ([name, description])))

    setRawMeasures([...standard_measures, ...user_measures])
    setRiskEvents(riskNames)
    setEventTypes(risks)
    setUsers(usersResponse.users)
    setAssetTypes(types)
    mapRef.current.resize()
  })

  /* Fetches all details for the current focused asset */
  const fetchAssetDetails = () : Promise<void> => apiHook.getAssetDetails(focusedAsset.id)
    .then(({ threats, mitigations }: { threats: BackendRiskAssessment[], mitigations: Mitigation[][] }) => {
      const formattedRisks = processBackendRiskAssessments(threats, focusedAsset, users, eventTypes)
      const risks : RiskAssessment[] = formattedRisks.map((risk, index) : RiskAssessment => {
        const {
          existingMeasures, newMeasures,
        } = formatMitigations(rawMeasures, mitigations[index])
        return {
          ...risk, existingMeasures, newMeasures, mitigations: true,
        }
      })
      const { updatedAsset, assetList } = addAssessments(allAssets, risks, focusedAsset)
      /* update all assets so that risks don't need to be fetched again */
      setAllAssets(assetList)
      redrawAssets(assetList)

      dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: updatedAsset })
    })

  /* Handle a specific lcoation being clicked -> Depnding on the data there might be one or multiple asets assigned to a single pin */
  const handleColocatedLocationClicked = (marker: mapboxgl.Marker, coordinateKey: string) => {
    const colocated = assets[coordinateKey].assets

    if (displayAsset.showAssetForm) {
      const { location, coordinates } = assets[coordinateKey].assets[0]
      const currentInput = {
        ...addAssetInput, coordinates, location, showDetailsForm: true,
      }
      dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: currentInput })
      marker.togglePopup()
      return
    }

    if (!editing) {
      if (colocated.length === 1 && colocated[0]) {
        dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: colocated[0] })
        marker.togglePopup()
        dispatchDisplayAsset(DisplayAssetAction.OPEN_PROFILE)
      }
      return
    }

    if (focusedAsset) {
      marker.togglePopup()
      return
    }
    /*  Get the asset data and marker's object */
    if (colocated.length === 1) {
      const asset = colocated[0]
      dispatchEditingAsset({ type: EditAssetActionKind.SELECT_ASSET, payload: { asset, marker } })

      /* Change marker so it's draggable */
      marker.setDraggable(true)
    }
  }

  const handleColocatedLocationClickedRef = React.useRef(handleColocatedLocationClicked)
  handleColocatedLocationClickedRef.current = handleColocatedLocationClicked

  /* Handle onDrag for map markers */
  const setCurrentPoint = (location: GeoLocation, marker: mapboxgl.Marker) => {
    if (!editAssetInput.asset) return
    const assetsToConsider = { ...assets }
    delete assetsToConsider[`${editAssetInput.asset.coordinates.lng},${editAssetInput.asset.coordinates.lat}`]
    const results = getAssetWithinRadius(assetsToConsider, location, (mapRef.current) ? mapRef.current.getZoom() : 5)

    if (results) {
      ionAlert({
        header: 'Combine Asset Location?',
        message: `Do you wish to move 
        '${editAssetInput.asset.name}' to the same coordinates as ${results.assetNames.join(', ')}. Clicking yes will join these assets to the same location pin on the map.`,
        buttons: [
          { text: 'No' },
          {
            text: 'Yes',
            handler: () => {
              dispatchEditingAsset({ type: EditAssetActionKind.COLOCATED_LOCATION, payload: { newLocation: results.coordinates } })
              marker.setLngLat(results.coordinates)
            },
          },
        ],
      })
    }
    dispatchEditingAsset({ type: EditAssetActionKind.CHANGE_LOCATION, payload: { newLocation: location } })
    marker.setLngLat(location)
  }
  const setCurrentPointRef = React.useRef(setCurrentPoint)
  setCurrentPointRef.current = setCurrentPoint

  /* Separte colocated asset pins */
  const startColocatedEdit = (asset : Asset) => {
    const newMarker = new mapboxgl.Marker({ color: 'rgb(3, 198, 252)' })
    newMarker.setDraggable(true)
    newMarker.setLngLat(asset.coordinates)
      .addTo(mapRef.current)

    /* Update location on dragend */
    newMarker.on('dragend', () => {
      setCurrentPointRef.current(newMarker.getLngLat(), newMarker)
    })

    dispatchEditingAsset({ type: EditAssetActionKind.SELECT_ASSET, payload: { asset, marker: newMarker } })
  }

  const handleAssetClicked = (asset: Asset) => {
    if (!editRef.current) {
      dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: asset })
      dispatchDisplayAsset(DisplayAssetAction.OPEN_PROFILE)
      return
    }
    ionAlert({
      header: 'Separate Asset location?',
      message: 'Are you sure you wish to move this asset from its current location? Doing so will create a new pin on the map for this asset.',
      buttons: [
        {
          text: 'No',
          handler: () => {
            setEditing(false)
          },
        },
        {
          text: 'Yes, continue',
          handler: () => {
            startColocatedEdit(asset)
          },
        },
      ],
    })
  }
  const handleAssetClickedRef = React.useRef(handleAssetClicked)
  handleAssetClickedRef.current = handleAssetClicked

  const drawAssets = (assetList: AssetList = assets) : void => {
    const drawnAssets: AssetList = drawAssetsOnMap(mapRef, assetList, handleAssetClickedRef, handleColocatedLocationClickedRef, setCurrentPointRef)
    setAssets(drawnAssets)
  }

  /* Redraw from list of all assets */
  const redrawAssets = (assetList : AssetList) : void => {
    removeAllDrawnAssets(assets)
    const filteredAssets = filterAssets(assetList, filters)

    drawAssets(filteredAssets)
  }

  const filterMarkersByState = (state: string) => {
    const action: FilterActions = { type: FiltersActionKind.TOGGLE_STATE, payload: state }
    const newFilters = filtersReducer(filters, action)
    /* Calculate new filtered assets */
    const filteredAssets = filterAssets(allAssets, newFilters)
    /* Update states and redraw assets */
    dispatchFilters(action)

    /* Redraw with new updated filters */
    removeAllDrawnAssets(assets)
    drawAssets(filteredAssets)
  }

  const toggleAllAssets = (toggleAll: boolean) => {
    /* In case the user toggles all the dispatch call is different  */
    dispatchFilters({ type: FiltersActionKind.TOGGLE_ALL })
    const filteredAssets = (toggleAll) ? { ...allAssets } : {}

    removeAllDrawnAssets(assets)
    drawAssets(filteredAssets)
  }

  const pushAsset = (newAsset: Asset) : void => {
    const updatedAssetList = pushNewAsset(allAssets, newAsset)
    setAllAssets(updatedAssetList)
    redrawAssets(updatedAssetList)
  }

  const pushRiskAssessment = (risk: RiskAssessment, asset: Asset) : Promise<void> => {
    /* Prepare backend request data */
    const requestData = {
      ...formatRequestRisk(asset, risk),
      existing: risk.existingMeasures.map(({ control_measure }) => control_measure),
      new: risk.newMeasures.map(({ control_measure }) => control_measure),
    }

    setLoading(true)
    return apiHook.createAssetRisk(requestData).then(({ id }) => {
      /* Push new risk assessment to asset */
      const { updatedAsset, assetList } = pushNewRiskAssessment(allAssets, asset, { ...risk, id })
      dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: updatedAsset })
      setAllAssets(assetList)

      redrawAssets(assetList)
      setLoading(false)
    })
  }

  const updateRisk = (risk: RiskAssessment, asset: Asset, formattedChanges: EditMeasures) : void => {
    /* Prepare backend request data */
    const requestData = {
      ...formatRequestRisk(asset, risk),
      existing_add: formattedChanges.existingAdd,
      existing_remove: formattedChanges.existingRemove,
      new_add: formattedChanges.newAdd,
      new_remove: formattedChanges.newRemove,
      id: risk.id,
    }

    setLoading(true)
    apiHook.editAssetRisk(requestData).then(() => {
      /* Update data structures */
      const { updatedAsset, assetList } = updateRiskAssessment(allAssets, asset, risk)
      dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: updatedAsset })
      setAllAssets(assetList)

      redrawAssets(assetList)
      setLoading(false)

      /* Close popups */
      dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
    })
  }

  const deleteAsset = (asset: Asset) => {
    const updatedAssets = removeOldAssetFromLocation(allAssets, asset)
    redrawAssets(updatedAssets)
  }

  const deleteAssetList = (colAssets: Asset[]) : void => {
    const { assetList, ids } = removeListOfAssets(allAssets, colAssets)
    setLoading(true)
    apiHook.archiveAssets(ids).then(() => {
      redrawAssets(assetList)
      setLoading(false)
    })
  }

  const forgetEditingAsset = () : void => {
    if (editAssetInput.marker) {
      editAssetInput.marker.setLngLat({ ...editAssetInput.asset.coordinates })
    }
    dispatchEditingAsset({ type: EditAssetActionKind.FORGET_ASSET })
    dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
  }

  const submitEditAsset = () : void => {
    setLoading(true)
    forgetEditingAsset()
    apiHook.editAsset({
      domain_id: workspace.id,
      asset_id: editAssetInput.asset.id,
      name: editAssetInput.asset.name,
      longitude: editAssetInput.newLocation.lng,
      latitude: editAssetInput.newLocation.lat,
      asset_type: editAssetInput.asset.type,
    }).then(() => {
      const updatedAssets = editAsset(allAssets, editAssetInput.asset, editAssetInput.newLocation)
      setAllAssets(updatedAssets)
      redrawAssets(updatedAssets)
      setEditing(false)
      setLoading(false)
    })
  }

  const fetchRiskAssessments = (asset: Asset, callback: (risks: RiskAssessment[]) => void) : void => {
    apiHook.getAsset(asset.id).then(({ threats } : { threats: BackendRiskAssessment[] }) => {
      /* Process backend data and add it to frontend data structures */
      const formattedRisks: RiskAssessment[] = processBackendRiskAssessments(threats, asset, users, eventTypes)
      const { updatedAsset, assetList } = addAssessments(allAssets, formattedRisks, asset)

      /* update all assets so that risks don't need to be fetched again */
      setAllAssets(assetList)
      redrawAssets(assetList)

      /* Update focused asset data to include risks as well */
      dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: updatedAsset })
      callback(formattedRisks)
    })
  }

  const fetchMitigations = (risk: RiskAssessment, asset: Asset) : Promise<ProcessedMeasures> => (
    apiHook.getMitigations(risk.id)
      .then(({ mitigations } : { mitigations: Mitigation[] }) => {
        /* Format and update component states */
        const measures = formatMitigations(rawMeasures, mitigations)
        const { assetList, updatedRisk, updatedAsset } = addMitigations(allAssets, asset, risk, measures)
        /* update all assets so that risks don't need to be fetched again */
        setAllAssets(assetList)
        redrawAssets(assetList)

        dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: updatedAsset })
        dispatchRisk({ type: FocusedActionKind.CHOOSE, chosenRisk: updatedRisk })

        return Promise.resolve(measures)
      })
  )

  const deleteRiskAssessments = (ids: number[]) : void => {
    setLoading(true)
    apiHook.archiveAssetRisks(ids).then(() => {
      const { assetList, updatedAsset } = removeAssessments(allAssets, focusedAsset, ids)
      const filteredAssets = filterAssets(assets, filters)

      /* Update states and current asset */
      setAllAssets(assetList)
      setAssets(filteredAssets)
      setLoading(false)
      dispatchAsset({ type: FocusedActionKind.CHOOSE, chosenAsset: updatedAsset })
    })
  }

  const fetchAppDataV2 = () => {
    mapRef.current.on('load', () => {
      Promise.all([
        fetchAlerStates(false, workspace.id),
        apiHook.getIncidents(workspace.id),
        apiHook.getAssets(workspace.id),
        axios.post('/api/v2/county/counties', { domain_id: workspace.id }),
        apiHook.getUsers(workspace.id),
        apiHook.riskTypes(workspace.id),
        apiHook.assetTypes(),
        apiHook.getFormMeasures({ domain_id: workspace.id }),
        axios.get('/api/v1/types'),
        axios.post('/api/v2/stakeholder/get_stakeholders', { domain_id: workspace.id }),
        axios.post('/api/v2/community/get_communities', { domain_id: workspace.id }),
        axios.get('api/v2/flashpoint/get_manifestations'),
        axios.get('/api/v1/internal_incident/fetchCausesActors'),
        axios.post('/api/v2/county/regionsCounties', { country: workspace.country }),
      ]).then(([
        { counties, regions },
        { incidents },
        assetsData,
        countyData,
        usersResponse,
        { risks },
        { types },
        { standard_measures, user_measures },
        dbTypes,
        dbStakeholders,
        dbCommunities,
        dbManifestations,
        dbCauses,
        dbRegions,
      ]) => {
        setRegions(dbRegions.data)
        const riskNames = Object.fromEntries(
          risks.map(({ name, description }) => [name, description])
        )
        setStakeholders(dbStakeholders.data.stakeholders)
        setCommunities(dbCommunities.data.communities)
        setCauses(dbCauses.data.causes)
        setManifestations(dbManifestations.data.types)
        setRawMeasures([...standard_measures, ...user_measures])
        setRiskEvents(riskNames)
        setEventTypes(risks)
        setUsers(usersResponse.users)
        setAssetTypes(types)
        const formattedAreas = processAreas(countyData.data.counties)
        setAreas(formattedAreas)
        setIncidentTypes(dbTypes.data.types.sort((a: string, b: string) => {
          if (a < b) return -1
          if (b < a) return 1
          return 0
        }))
        createSources(mapRef, formattedAreas)
        // create app specific layers
        // createPOILayers(mapRef, dbPoIs.data.pois)
        /* Add alert states and security conditions to map */
        /* Format assets and draw them */
        const { assets } = assetsData
        console.log('in fetch app', assets)
        createAssetLayers(mapRef, assets)
        // drawAssets(assets)
        setLoading(false)
        /* Add alert states and security conditions to map */
        /* Format assets and draw them */
        //createAssetLayers(mapRef, assetsCounty.assets)
        //const assets = formatAssets({ ...counties, type: 'FeatureCollection' }, assetsCounty.assets)
        setAllAssets(assets)
        setAssets(assets)

        //drawAssets(assets)
        mapRef.current.resize()

        mapRef.current.on('click', 'asset-point-markers', (e) => {
          console.log('click')
          handleAssetPointClickRef.current(e)
          e.originalEvent.preventDefault()
        })

        mapRef.current.on('click', 'assets-lines-layer', (e) => {
          console.log('click')
          handleAssetPointClickRef.current(e)
          e.originalEvent.preventDefault()
        })

        mapRef.current.on('click', 'assets-polygon-layer', (e) => {
          console.log('click')
          handleAssetPointClickRef.current(e)
          e.originalEvent.preventDefault()
        })
      })
    })
  }

  return {
    assets,
    formatAssets,
    allAssets,
    dispatchAssetInput,
    focusedAsset,
    dispatchAsset,
    focusedRisk,
    dispatchRisk,
    loading,
    setLoading,
    editing,
    setEditing,
    users,
    fetchAppData,
    fetchAppDataV2,
    displayAsset,
    dispatchDisplayAsset,
    filters,
    toggleAllAssets,
    filterMarkersByState,
    viewMode,
    dispatchViews,
    returnToSafe,
    drawAssets,
    pushAsset,
    mapRef,
    addAssetInput,
    editAssetInput,
    dispatchEditingAsset,
    assetTypes,
    deleteAsset,
    submitEditAsset,
    forgetEditingAsset,
    deleteAssetList,
    fetchRiskAssessments,
    deleteRiskAssessments,
    setAllAssets,
    fetchAssetDetails,
    rawMeasures,
    fetchMitigations,
    riskEvents,
    pushRiskAssessment,
    updateRisk,
    assetType,
    setAssetType,
    areas,
    view,
    setView,
    selectedAsset,
    setSelectedAsset,
    showSelectedAsset,
    setShowSelectedAsset,
    showAssetForm,
    setShowAssetForm,
    showFlashpointForm,
    setShowFlashpointForm,
    submittedMessage,
    setSubmittedMessage,
    pushNewAsset,
    incidentTypes,
    stakeholders,
    communities,
    manifestations,
    causes,
    regions,
  }
}

export const ProvideRiskRegister = ({ children }) => {
  const data = useProvideRiskRegister()
  return (
    <riskRegisterContext.Provider value={data}>
      {
        children
      }
    </riskRegisterContext.Provider>
  )
}
