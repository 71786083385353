import * as turf from '@turf/turf'
import { ControlMeasure } from '../../../components/riskregister/types/RiskAssessments'
import { BackendMovementBox } from '../../../components/types/BackendTypes'
import { CountiesGeoJSON } from '../../../components/types/GeoJSONTypes'
import {
  IncidentCause,
  RestrictionZone, RestrictionZoneGeoJSON, RestrictionZoneType, User,
} from '../../../components/types/GlobalTypes'
import { BackendZoneLog, ZoneLog } from '../types/HookInterface2'

/**
 * Process backend models into a concise data structure for restriction boxes
 *
 * @param boxes -> Backend movement boxes list
 * @param links -> Ids of the controle measures applicable to a restriction box. This array is in the same order of the boxes array
 * @param measures -> All control measures
 * @param underlyingIds -> IDs of the causes applicable to restriction zone.
 * @param backendCauses -> Incident Cause objects
 * @param mainThreats -> Array of threat strings
 * @returns A formatted version of restiction boxes
 */
export const processRestrictionZones = (boxes: BackendMovementBox[],
  links: number[][], measures: ControlMeasure[], counties: CountiesGeoJSON,
  users: User[], underlyingIds: number[][], backendCauses: IncidentCause[], mainThreats: string[][]) : RestrictionZone[] => {
  const formatted : RestrictionZone[] = boxes.map((val, index) => {
    const measureIds = links[index]
    const controlMeasures = measures.filter(({ id }) => measureIds.indexOf(id) >= 0)
    const geoData: RestrictionZoneGeoJSON = { ...JSON.parse(val.geoData), properties: { id: val.id, type: getRestrictionZoneColor(val.zone_type) } }
    const owner = users.find(({ id }) => id === val.user_id)

    const causeIds = underlyingIds[index]
    const causes = backendCauses.filter(({ id }) => causeIds.indexOf(id) >= 0)

    const county = counties.features.find(({ properties }) => properties.id === val.county_id)
    return {
      id: val.id,
      geoData,
      controlMeasures,
      type: val.zone_type,
      comments: val.description,
      review: val.check_in_days,
      county_id: val.county_id,
      owner: owner || {
        id: val.user_id,
        first_name: 'Unknown',
        final_name: 'User',
        email: 'notfound@example.com',
      },
      created_at: val.created_at,
      countyData: {
        name: (county) ? county.properties.shapeName : 'Unknown',
        alert_state: (county) ? county.properties.Alert_state : 1,
        sc_alert: (county) ? county.properties.sc_level : 1,
      },
      underlyings: causes,
      mainThreats: mainThreats[index],
    }
  })

  return formatted
}

/**
 * Get GeoJSON collection object for a list of restriction boxes.
 *
 * @param boxes -> List of processed restriction zones
 * @returns A GeoJSON Feature Collection with the polygons of every box
 */
export const restrictionZonesToGeoJSON = (boxes: RestrictionZone[]) : turf.FeatureCollection<turf.LineString, { id: number, type: string }> => {
  const features = boxes.map(({ geoData }) => geoData)
  return turf.featureCollection(features)
}

export const getRestrictionZoneColor = (zoneType: RestrictionZoneType) => {
  if (zoneType === RestrictionZoneType.GREEN) {
    return '#02C221'
  }

  if (zoneType === RestrictionZoneType.AMBER) {
    return '#FF9900'
  }

  return '#FF0101'
}

export const restrictionZoneTypeDesc = (type: RestrictionZoneType) : string => {
  if (type === RestrictionZoneType.GREEN) {
    return 'This zone is marked as green, meaning that users can move relatively safety within the zones borders. Some restrictions may apply.'
  }

  if (type === RestrictionZoneType.RED) {
    return 'This zone is marked as red, meaning that users are prohibited from moving within the zones borders.'
  }

  return ''
}

/**
 * Cross-reference users and logs
 *
 * @param logs -> Backend zone log record
 * @param users -> Archangel users
 * @returns A processed version of the data
 */
export const processZoneLogs = (logs: BackendZoneLog[], users: User[]) : ZoneLog[] => {
  const formatted : ZoneLog[] = logs.map((val) => {
    const user = users.find(({ id }) => id === val.user_id)

    return {
      ...val,
      user: user || {
        first_name: 'Unknown',
        final_name: 'User',
        email: 'unknown@example.org',
        id: val.user_id,
      },
    }
  })

  return formatted
}
