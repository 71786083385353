/* eslint-disable @typescript-eslint/no-unused-expressions */
import { IonRow, IonToggle } from '@ionic/react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
import React from 'react'
import TreeMenu from 'react-simple-tree-menu'
import 'react-simple-tree-menu/dist/main.css'
import { SettingField, SettingTitle } from '../StyledContainers'

const DEFAULT_PADDING = 16
const ICON_SIZE = 8
const LEVEL_SPACE = 16
/**
 *  ===============================
 *     My workspaces (visibility)
 *  ===============================
 */
const data = [
  {
    key: 'mammal',
    label: 'Mammal',
    nodes: [
      {
        key: 'canidae',
        label: 'Canidae',
        nodes: [
          {
            key: 'dog',
            label: 'Dog',
            nodes: [],
            url: 'https://www.google.com/search?q=dog',
          },
          {
            key: 'fox',
            label: 'Fox',
            nodes: [],
            url: 'https://www.google.com/search?q=fox',
          },
          {
            key: 'wolf',
            label: 'Wolf',
            nodes: [],
            url: 'https://www.google.com/search?q=wolf',
          },
        ],
        url: 'https://www.google.com/search?q=canidae',
      },
    ],
    url: 'https://www.google.com/search?q=mammal',
  },
  {
    key: 'reptile',
    label: 'Reptile',
    nodes: [
      {
        key: 'squamata',
        label: 'Squamata',
        nodes: [
          {
            key: 'lizard',
            label: 'Lizard',
            url: 'https://www.google.com/search?q=lizard',
          },
          {
            key: 'snake',
            label: 'Snake',
            url: 'https://www.google.com/search?q=snake',
          },
          {
            key: 'gekko',
            label: 'Gekko',
            url: 'https://www.google.com/search?q=gekko',
          },
        ],
        url: 'https://www.google.com/search?q=squamata',
      },
    ],
    url: 'https://www.google.com/search?q=reptile',
  },
]
const WorkspaceVisibility = () => (
  <>
    <SettingTitle>Workspace Details</SettingTitle>
    <div style={{ padding: '15px' }}>
      <SettingField style={{ width: 'auto' }}>
        <h4>Workspace Title</h4>
        <p>Use the on/off toggle to show or hide workspaces. Workspaces that are toggled off will no longer show in your ‘Choose Workspace’ list. </p>
      </SettingField>
      <TreeMenu data={data}>
        {({ items }) => (
          <>
            <ListGroup style={{ maxHeight: '450px', overflowY: 'auto' }}>
              {items.map((props) => (
              // You might need to wrap the third-party component to consume the props
              // check the story as an example
              // https://github.com/iannbing/react-simple-tree-menu/blob/master/stories/index.stories.js
                <ListItem {...props} />
              ))}
            </ListGroup>
          </>
        )}
      </TreeMenu>
    </div>
  </>
)
const ToggleIcon = ({ on }) => <span style={{ marginRight: 8 }}>{on ? '-' : '+'}</span>

const ListItem = ({
  hasNodes,
  isOpen,
  label,
  toggleNode,
  level = 0,
  focused,
}) => (
  <ListGroupItem
    style={{
      paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
      cursor: 'pointer',
      boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
      zIndex: focused ? 999 : 'unset',
      position: 'relative',
    }}
    onClick={(e) => {
      // eslint-disable-next-line no-unused-expressions
      hasNodes && toggleNode && toggleNode()
      e.stopPropagation()
    }}
  >
    <IonRow className='ion-align-items-center ion-justify-content-between'>
      <div>
        {hasNodes && (
          <div
            style={{ display: 'inline-block' }}
          >
            <ToggleIcon on={isOpen} />
          </div>
        )}
        {label}
      </div>
      <IonToggle color='success' onClick={(e) => e.stopPropagation()} />
    </IonRow>
  </ListGroupItem>
)

export default WorkspaceVisibility
