import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow,
  IonCol, useIonAlert,
} from '@ionic/react'

import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { useSituationReport } from '../../hooks/insights/useSituationReport'

//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SitRepPt9 = ({
  step, setStep, county, incidents,
}) => {
  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  const [, setIsShowing,, setCountyId] = useSituationReport()

  const handleClose = () => {
    setIsShowing(false)
    setCountyId(null)
    setStep(1)
  }

  const data = incidents

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '95%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Situation Report - ${county.name} County `}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonList>
          <IonRow style={{ paddingTop: '25px', paddingLeft: '10px' }}>
            <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontWeight: 'bold', fontSize: '1.15em' }}>Security Reports by Type (Last Month)</IonLabel></IonCol>
          </IonRow>
        </IonList>

        {
          incidents.length === 0 && (
            <IonRow style={{ height: '65%' }}>
              <IonLabel style={{
                marginTop: '1em', marginLeft: '1em', fontWeight: 'bold', fontSize: '1em',
              }}
              >There have been no Security Reports in the past week.
              </IonLabel>
            </IonRow>
          )
        }

        {
          incidents.length > 0 && (
          <ResponsiveContainer width='95%' height='65%'>
            <BarChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='1 1' />
              <XAxis dataKey='name' />
              <YAxis tickCount='3' />
              <Tooltip />
              <Bar dataKey='Incidents' fill='#2596be' />
            </BarChart>
          </ResponsiveContainer>
          )
        }

        <IonRow style={{}} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default SitRepPt9
