import React, { useState } from 'react'
import {
  IonInput, IonList, IonItem, IonLabel, IonDatetime,
  IonCheckbox, IonSelect, IonSelectOption, IonButton,
  useIonAlert,
} from '@ionic/react'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Select from 'react-select'
import { OverlayContainer } from '../StyledContainers'

import { useAuth } from '../../../hooks/useAuth'

/**
 *  =======================
 *      FORM TO ADD TASK
 *  =======================
 */
const AddTask = ({ onClose, onAddTask, users }) => {
  const [input, setInput] = useState({
    title: '',
    assignees: [],
    due: '',
    priority: false,
    app: '',
    description: '',
    repeat: 0,
  })
  const [ionAlert] = useIonAlert()

  const auth = useAuth()
  const { email } = auth.user

  /* Error messages */
  const returnMessage = (key) => (
    {
      title: 'Add a title to the task',
      assignees: 'No assignes selected',
      due: 'Please specify the due date of this task',
      app: 'Please choose an app that this task relates to.',
    }[key]
  )

  const handleSubmit = () => {
    const notFilled = Object.keys(input).find((key) => input[key] === undefined)

    if (input.assignees.length < 1) {
      ionAlert({
        header: 'Error',
        message: 'Please select an assignee for this task.',
        buttons: [
          'Cancel',
          { text: 'OK' },
        ],
      })
      return
    }

    if (input.title === '') {
      ionAlert({
        header: 'Error',
        message: 'Add a title to the task',
        buttons: [
          'Cancel',
          { text: 'OK' },
        ],
      })
      return
    }

    if (notFilled) {
      ionAlert({
        header: 'Error',
        message: returnMessage(notFilled),
        buttons: [
          'Cancel',
          { text: 'OK' },
        ],
      })
      return
    }
    const values = { ...input, assignees: input.assignees.map((val) => val.value.id) }
    onAddTask({ ...values, assigner: email })
  }

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  return (
    <OverlayContainer>
      <button
        type='button'
        color='light'
        size='small'
        style={{
          color: '#28464B',
          backgroundColor: 'transparent',
          border: 'none',
          display: 'block',
          marginLeft: 'auto',
          fontSize: '1.5rem',
        }}
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <IonList>
        <IonItem>
          <IonLabel>Task Title:</IonLabel>
          <IonInput id='task-title-input' name='title' value={input.title} onIonChange={handleChange} type='text' />
        </IonItem>
        <IonItem>
          <Select
            isMulti
            placeholder='Select Assignees'
            name='colors'
            id='select-task-assignees'
            className='select-container'
                // eslint-disable-next-line no-undef
            menuPortalTarget={document.body}
            options={users.map((user) => ({ value: user, label: user.email }))}
            onChange={(selected) => setInput({ ...input, assignees: selected })}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Due Date:</IonLabel>
          <IonDatetime
            name='due'
            id='task-due-date'
            value={input.due}
            onIonChange={handleChange}
            display-format='YYYY-MM-DDTHH:mm'
            picker-format='DD:MM:YYYYTHH:mm'
            placeholder='YYYY-MM-DD HH:mm'
            minuteValues='0,15,30,45'
          />
        </IonItem>
        <IonItem>
          <IonLabel>High Priority: </IonLabel>
          <IonCheckbox
            name='priority'
            id='task-priority'
            value={input.priority}
            color='primary'
            onIonChange={(e) => setInput({ ...input, [e.target.name]: e.target.checked })}
          />
        </IonItem>
        <IonItem>
          <IonLabel>App</IonLabel>
          <IonSelect id='select-task-app' name='app' value={input.app} onIonChange={handleChange} placeholder='Which app does this task apply to'>
            <IonSelectOption value='Insight'>Insights</IonSelectOption>
            <IonSelectOption value='Journey'>Journeys</IonSelectOption>
            <IonSelectOption value='Misc'>Misc.</IonSelectOption>
            <IonSelectOption value='Project'>Project</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonLabel>Description:</IonLabel>
          <IonInput id='task-description' name='description' value={input.description} onIonChange={handleChange} type='text' />
        </IonItem>
        <IonItem>
          <IonLabel>Repeat</IonLabel>
          <IonSelect id='select-task-repeat' name='repeat' value={input.repeat} onIonChange={handleChange}>
            <IonSelectOption value={0}>Never</IonSelectOption>
            <IonSelectOption value={1}>Daily</IonSelectOption>
            <IonSelectOption value={7}>Weekly</IonSelectOption>
            <IonSelectOption value={14}>Fortnightly</IonSelectOption>
            <IonSelectOption value={30}>Monthly</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonButton
          onClick={handleSubmit}
          id='submit-task-button'
          style={{
            '--ion-color-primary': '#0C9500',
            marginLeft: 'auto',
            display: 'block',
            maxWidth: '100px',
            marginTop: '10px',
          }}
        >Submit
        </IonButton>
      </IonList>
    </OverlayContainer>
  )
}

export default AddTask
