import * as moment from 'moment'
import * as React from 'react'
import { getWebSocketURL } from '../../channels/consumer'
import { BackendRecipient } from '../../components/types/BackendTypes'
import { ChatRoom, Message, User } from '../../components/types/GlobalTypes'
import axios from '../../utils/axios'
import { ReadChannelConfig } from '../channels/ChannelConfigs'
import { useActionCable } from '../channels/useActionCable'
import { useChannel } from '../channels/useChannel'
import { useAuth } from '../useAuth'
import { useWorkspace } from '../useWorkspace'
import { ChatsHookInterface } from './helpers/HookInterface'
import { processChats, processChatUsers } from './helpers/Utils'

export const chatsContext = React.createContext({} as ChatsHookInterface)
export const useChatsData = () => React.useContext<ChatsHookInterface>(chatsContext)

export const chatsSorting = (chatA: ChatRoom, chatB: ChatRoom) : number => {
  if (!chatA.read && chatB.read) return -1
  if (!chatB.read && chatA.read) return 1
  if (moment(chatA.latestMessage?.created_at || chatA.created_at).isAfter(chatB.latestMessage?.created_at || chatB.created_at)) return -1
  return 1
}

const useProvideChats = () : ChatsHookInterface => {
  const [rooms, setRooms] = React.useState<ChatRoom[]>([])
  const [users, setUsers] = React.useState<(User & { inWorkspace: boolean })[]>([])
  const [focusedRoom, setFocusedRoom] = React.useState<ChatRoom | null>(null)
  const [archangelUsers, setArchangelUsers] = React.useState<User[]>([])
  const [showLeaveGroup, setShowLeaveGroup] = React.useState<boolean>(false)
  const [showDeleteChat, setShowDeleteChat] = React.useState<boolean>(false)
  const [showEditName, setShowEditName] = React.useState<boolean>(false)
  const [showAddUsers, setShowAddUsers] = React.useState<boolean>(false)
  const [showAddChat, setShowAddChat] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(true)
  const { workspace } = useWorkspace()
  const { user } = useAuth()
  const { actionCable } = useActionCable(getWebSocketURL())
  const { subscribe } = useChannel(actionCable)

  const updateReadChat = (recipientUpdated: BackendRecipient, roomId: number) => {
    const chat = rooms.find(({ id }) => id === roomId)

    if (!chat) {
      return
    }
    const recipientUpdates = chat.recipientUpdates.slice()
    const index = recipientUpdates.findIndex(({ user_id }) => user_id === recipientUpdated.user_id)

    if (index < 0) {
      return
    }

    recipientUpdates.splice(index, 1, { user_id: recipientUpdated.user_id, read: recipientUpdated.read, updated_at: recipientUpdated.updated_at })
    if (chat.id === focusedRoom.id) {
      setFocusedRoom({ ...focusedRoom, recipientUpdates })
    }
    updateRoom({ ...chat, recipientUpdates })
  }

  const updateReadChatRef = React.useRef(null)
  updateReadChatRef.current = updateReadChat

  const setUpReadChannels = (roomLists: ChatRoom[]) => {
    roomLists.forEach((val) => {
      subscribe<ReadChannelConfig>({ channel: 'ConversationsChannel', room_id: val.id }, {
        received: (x) => {
          const recipientUpdated : BackendRecipient = x.recipient
          updateReadChatRef.current(recipientUpdated, val.id)
        },
      })
    })
  }

  const fetchData = () => {
    Promise.all([
      axios.post('/get_users', { domain_id: workspace.id }),
      axios.get('/get_rooms', {}),
      axios.post('/api/v1/domain/get_one', { id: workspace.id }),
    ]).then(([usersData, roomsData, workspaceData]) => {
      const formattedChats = processChats(roomsData.data.data, usersData.data.users, user.user_id)
      setArchangelUsers(usersData.data.users)
      setUsers(processChatUsers(usersData.data.users, workspaceData.data.users))

      setUpReadChannels(formattedChats)
      const sorted = formattedChats.sort(chatsSorting)
      setRooms(sorted)

      if (sorted.length > 0) {
        setFocusedRoom(sorted[0])
      }
      setLoading(false)
    }).catch((e) => {
      console.log(e.response.data)
    })
  }

  const pushNewRoom = (newChat: ChatRoom) => {
    const copy = rooms.slice()
    const chats = [newChat, ...copy]

    /* On Read socket messaging */
    subscribe<ReadChannelConfig>({ channel: 'ConversationsChannel', room_id: newChat.id }, {
      received: (x) => {
        const recipientUpdated : BackendRecipient = x.recipient
        updateReadChatRef.current(recipientUpdated, newChat.id)
      },
    })
    setRooms(chats.sort(chatsSorting))
  }

  const refreshRooms = () => {
    Promise.all([
      axios.get('/get_rooms', {}),
    ]).then(([roomsData]) => {
      const formattedChats = processChats(roomsData.data.data, archangelUsers, user?.user_id as number)
      setRooms(formattedChats.sort(chatsSorting))
    })
  }

  const updateRoom = (room: ChatRoom) => {
    const copy = rooms.slice()
    const index = copy.findIndex(({ id }) => id === room.id)

    copy.splice(index, 1, { ...room })
    setRooms(copy.sort(chatsSorting))
  }

  const pushLatestMessage = (message: Message, room: ChatRoom) => {
    updateRoom({ ...room, read: true, latestMessage: message })
  }

  const setChatAsRead = (room: ChatRoom) => {
    updateRoom({ ...room, read: true })
  }

  const updateRoomsName = (room: ChatRoom, newName: string) : ChatRoom => {
    const updated = { ...room, name: newName }
    updateRoom(updated)

    return updated
  }

  const leaveRoom = (room: ChatRoom) => {
    const updated = rooms.filter(({ id }) => id !== room.id)
    setRooms(updated)

    if (updated.length === 0) {
      setFocusedRoom(null)
      return
    }
    setFocusedRoom(updated[0])
  }

  const removeGroupUser = (userToDelete: User) => {
    setLoading(true)
    axios.put('/remove_user_from_chat', { room_id: focusedRoom?.id, user_id: userToDelete.id })
      .then(({ data }) => {
        if (!data.ok) {
          reportError(data.message)
          setLoading(false)
        }

        const copy = focusedRoom?.recipients.slice()
        const index = copy.findIndex(({ id }) => userToDelete.id === id)

        copy.splice(index, 1)
        const updatedRoom = { ...focusedRoom, recipients: copy }
        updateRoom(updatedRoom)
        setFocusedRoom(updatedRoom)
      })
      .catch(() => {
        reportError('An unexpected error has ocurred.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    rooms,
    fetchData,
    loading,
    setLoading,
    users,
    pushNewRoom,
    pushLatestMessage,
    refreshRooms,
    setChatAsRead,
    updateRoomsName,
    leaveRoom,
    updateRoom,
    focusedRoom,
    setFocusedRoom,
    showAddChat,
    setShowAddChat,
    showLeaveGroup,
    setShowLeaveGroup,
    removeGroupUser,
    showDeleteChat,
    setShowDeleteChat,
    showEditName,
    setShowEditName,
    showAddUsers,
    setShowAddUsers,
  }
}

export const ProvideChatsData : React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const data = useProvideChats()

  return (
    <chatsContext.Provider value={data}>
      {
        children
      }
    </chatsContext.Provider>
  )
}
