/* eslint-disable no-trailing-spaces */
import { IonButton } from '@ionic/react'
import React, { FC } from 'react'
import { OverlayContainer } from '../../GlobalContainers'
import { ZoneTypeButton } from '../../riskregister/StyledContainers'
import { RestrictionZoneType } from '../../types/GlobalTypes'
import GreenZone from './images/restriction-zone-green.svg'
import AmberZone from './images/restriction-zone-amber.svg'
import RedZone from './images/restriction-zone-red.svg'

interface Props {
  onCancel: () => void,
  onSubmit: (type: RestrictionZoneType) => void
}

const ChooseZoneType : FC<Props> = ({ onCancel, onSubmit }) => (
  <OverlayContainer style={{ width: '450px' }}>
    <h4 style={{ margin: '10px 0', color: '#8E151F', fontSize: '1.2rem' }}>Choose Restriction Zone Type</h4>
    <p style={{ margin: '10px 0' }}>Choose the type of restriction zone you wish to add to the map. </p>
    <div style={{ margin: '10px' }}>
      <ZoneTypeButton onClick={() => onSubmit(RestrictionZoneType.GREEN)}>
        <img src={GreenZone} alt='Green polygon' />
        <div className='zone-type-text'>
          <h6 style={{ color: '#0C9500' }}>Green Zone</h6>
          <p>Green zones are safe area where users can move with relative safety, 
            though some restrictions may apply. Moving outside of the zone will trigger an alert to managers and the user exiting.
          </p>
        </div>
      </ZoneTypeButton>
      <ZoneTypeButton onClick={() => onSubmit(RestrictionZoneType.AMBER)}>
        <img src={AmberZone} alt='Amber polygon' />
        <div className='zone-type-text'>
          <h6 style={{ color: '#FF8A00' }}>Amber Zone</h6>
          <p>Amber zones are zones in which there are conditions in place for travel. Moving inside an amber zone will trigger an alert to managers and the user entering.
          </p>
        </div>
      </ZoneTypeButton>
      <ZoneTypeButton onClick={() => onSubmit(RestrictionZoneType.RED)}>
        <img src={RedZone} alt='Red polygon' />
        <div className='zone-type-text'>
          <h6 style={{ color: '#DD2025' }}>Red Zone</h6>
          <p>Red zones are areas that are strictly off limits to all users without exception. Moving inside a red zone will trigger an alert to managers and the user entering.
          </p>
        </div>
      </ZoneTypeButton>
    </div>
    <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>
      Cancel
    </IonButton>
  </OverlayContainer>
)

export default ChooseZoneType
