import styled from 'styled-components'

const SelectionContainer = styled.div`
  padding: 30px 20px;
  max-width: 850px;
  margin: auto;
  width: 100%;
`
const DomainContainer = styled.button`
  height: 150px;
  width: 150px;
  border-radius: 5px;
  background: #8E151F;
  color: white;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  position: relative;
  margin: 20px;

  & svg{
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
`

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  height: 100%;
`

export {
  SelectionContainer,
  DomainContainer,
  Container,
}
