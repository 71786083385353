import mapboxgl from 'mapbox-gl'
import React, {
  createContext, FC, ReactNode, useContext, useState, useRef, useReducer,
} from 'react'
import * as turf from '@turf/turf'
import {
  Commitment,
  Community, CountryAreas, EngagementNote, Flashpoint, Stakeholder, StakeholderGroup, TerrainIssue, TerrainPOI,
} from '../../components/types/OptimizedMaps'
import { AppView, HookInterface, TerrainMapElem } from './types/HookInterface'
import axios from '../../utils/axios'
import { useWorkspace } from '../useWorkspace'
import { processAreas } from '../../components/utils/MapboxUtils'
import { createSources } from '../../components/utils/MapboxRendering'
import { communitiesToGeoJSON, poisToGeoJSON, updateCommunityStakeholders } from './helpers/Utils'
import {
  createAssetLayers,
  createCommunityLayer, createPOILayers, displayCommunityPopup,
} from './helpers/MapUtils'
import useApi from '../testHooks'
import { IncidentCause, User } from '../../components/types/GlobalTypes'
import { defaultFilters, Filters, FilterUpdate, FocusedActionKind, focusedReducer, initialFocusedState } from './helpers/StateReducers'
import { processFlashpoints } from './helpers/Processors'
import { Asset } from '../../components/riskregister/types/Assets'
import { assetsToGeoJSON } from '../insights/helpers/MapUtils'

export const terrainMappingContext = createContext({} as HookInterface)
export const useTerrainMapping = () => useContext(terrainMappingContext)

const defaultAssetGeoJSON : turf.FeatureCollection<turf.Point, Asset> = { type: 'FeatureCollection', features: [] }
const defaultRoutesGeoJSON : turf.FeatureCollection<turf.LineString> = { type: 'FeatureCollection', features: [] }

const useProvideTerrainMapping = () : HookInterface => {
  /* General App Data */
  const [loading, setLoading] = useState<boolean>(true)
  const [view, setView] = useState<AppView>(AppView.TERRAIN_MAP)
  const { workspace } = useWorkspace()
  const mapRef = useRef<mapboxgl.Map>(null)
  const [submittedMessage, setSubmittedMessage] = useState<string | null>(null)
  const [users, setUsers] = useState<User[]>([])
  const [showAddPopup, setShowAddPopup] = useState<boolean>(false)

  const [showEngagementForm, setShowEngagementForm] = useState<boolean>(false)
  const [showCommunityForm, setShowCommunityForm] = useState<boolean>(false)
  const [showFlashpointForm, setShowFlashpointForm] = useState<boolean>(false)
  const [showStakeholderForm, setShowStakeholderForm] = useState<boolean>(false)
  const [showIssueForm, setShowIssueForm] = useState<boolean>(false)
  const [showCommitmentForm, setShowCommitmentForm] = useState<boolean>(false)
  const [showFilePopup, setShowFilePopup] = useState<boolean>(false)
  const [files, setFiles] = React.useState<any | null>(null)
  const [imported, setImported] = React.useState<any | null>(null)

  const [showPOIForm, setShowPOIForm] = useState<boolean>(false)
  const [communities, setCommunities] = useState<Community[]>([])
  const [commitments, setCommitments] = useState<Commitment[]>([])
  const [poiList, setPoiList] = useState<TerrainPOI[]>([])
  const [stakeholders, setStakeholders] = useState<Stakeholder[]>([])
  const [stakeholderGroups, setStakeholderGroups] = useState<StakeholderGroup[]>([])
  const [causes, setCauses] = useState<IncidentCause[]>([])
  const [POITypes, setPOITypes] = useState<string[]>([])
  const [flashpoints, setFlashpoints] = useState<Flashpoint[]>([])
  const [engagementNotes, setEngagementNotes] = useState<EngagementNote[]>([])
  const [issues, setIssues] = useState<TerrainIssue[]>([])
  const [assets, setAssets] = useState<any[]>([])
  const [regions, setRegions] = useState<any[]>([])
  const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false)
  const [showMapFilterPopup, setShowMapFilterPopup] = useState<boolean>(false)
  const [filters, setFilters] = React.useState<Filters>({ ...defaultFilters })
  const [fromCommunityAdd, setFromCommunityAdd] = React.useState<any>()

  const [manifestations, setManifestations] = useState<string[]>([])

  const [focusedElems, dispatchFocused] = useReducer(focusedReducer, { ...initialFocusedState })
  const [editing, setEditing] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [registerDisplay, setRegisterDisplay] = useState<TerrainMapElem>(TerrainMapElem.FLASHPOINT)
  const [stakeholderFilters, setStakeholderFilters] = useState({
    types: [],
    orgs: [],
    comms: [],
    groups: [],
    parents: [],
  })
  const [mapFilters, setMapFilters] = useState({
    type: 'All',
    parent: 0,
  })
  const [filteredStakeholders, setFilteredStakeholders] = useState<Stakeholder[]>([])

  /* County data */
  const [areas, setAreas] = useState<CountryAreas[]>([])
  const apiHook = useApi()

  const handlePOIClick = (event: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[];
  } & mapboxgl.EventData) => {
    if (event.features.length === 0) { return }
    const { properties } = event.features[0]
    const selected = poiList.find(({ id }) => properties.id === id)

    if (!selected) { return }
    dispatchFocused({ type: FocusedActionKind.SELECT_POI, poi: selected })
  }

  const handlePOIClickRef = useRef(null)
  handlePOIClickRef.current = handlePOIClick

  const handleCommunityClick = (event: mapboxgl.MapMouseEvent & {
    features?: mapboxgl.MapboxGeoJSONFeature[];
  } & mapboxgl.EventData) => {
    if (!event.features || event.features.length === 0) { return }

    const { id } = event.features[0].properties
    const area = communities.find((val) => val.id === id)

    if (!area) { return }

    console.log('area: ', area)

    const hasFlashpoints = flashpoints.some(({ relevant_areas }) => relevant_areas.find((val) => val.id === id))
    const hasStakeholders = stakeholders.some((val) => val.areas.find((community) => community.id === id))
    console.log(engagementNotes)
    const hasEngagements = engagementNotes.some(({ community }) => community && community.id === id)
    const hasIssues = issues.some((issue) => issue.communities.find((val) => id === val.id))

    displayCommunityPopup(
      area,
      mapRef,
      event.lngLat,
      hasStakeholders,
      hasFlashpoints,
      hasEngagements,
      hasIssues,
      () => dispatchFocused({ type: FocusedActionKind.SELECT_COMMUNITY, community: area }),
    )
  }
  const handleCommunityClickRef = useRef(null)
  handleCommunityClickRef.current = handleCommunityClick

  const fetchAppData = () => {
    mapRef.current.on('load', () => {
      Promise.all([
        axios.post('/api/v2/county/counties', { domain_id: workspace.id }),
        axios.get('/api/v2/poi/get_types'),
        axios.get('/api/v1/internal_incident/fetchCausesActors'),
        apiHook.getUsers(workspace.id),
        axios.post('/api/v2/flashpoint/get_flashpoints', { domain_id: workspace.id }),
        axios.get('/api/v2/stakeholder/get_groups'),
        axios.post('/api/v2/community/get_communities', { domain_id: workspace.id }),
        axios.post('/api/v2/issue/get_issues', { domain_id: workspace.id }),
        axios.post('/api/v2/stakeholder/get_stakeholders', { domain_id: workspace.id }),
        axios.post('/api/v2/poi/get_pois', { domain_id: workspace.id }),
        axios.post('/api/v2/engagement_note/get_notes', { domain_id: workspace.id }),
        axios.post('/api/v2/commitment/get_commitments', { domain_id: workspace.id }),
        axios.get('api/v2/flashpoint/get_manifestations'),
        apiHook.getAssets(workspace.id),
        axios.post('/api/v2/county/regionsCounties', { country: workspace.country }),
      ]).then(([countyData, { data }, dbCauses, dbUsers, dbFlashpoints, dbStakeholderGroups,
        dbCommunities, dbIssues, dbStakeholders, dbPoIs, dbNotes, dbCommitments, dbManifestations, dbAssets, dbRegions]) => {
        /* Format county polygon data */
        const formattedAreas = processAreas(countyData.data.counties)
        setAreas(formattedAreas)
        setAssets(dbAssets.assets)
        setRegions(dbRegions.data)

        setManifestations(dbManifestations.data.types)
        setIssues(dbIssues.data.issues)
        setStakeholders(dbStakeholders.data.stakeholders)
        setFilteredStakeholders(dbStakeholders.data.stakeholders)
        setPoiList(dbPoIs.data.pois)
        setCommitments(dbCommitments.data.commitments)
        setEngagementNotes(dbNotes.data.notes)
        console.log('Notes', dbNotes.data.notes)

        setFlashpoints(processFlashpoints(dbFlashpoints.data.flashpoints))

        setStakeholderGroups(dbStakeholderGroups.data.groups)
        createSources(mapRef, formattedAreas)
        setLoading(false)

        setPOITypes(data.types)
        /* Create app specific source */
        createCommunityLayer(mapRef)
        createPOILayers(mapRef, dbPoIs.data.pois)

        setCauses(dbCauses.data.causes)
        setUsers(dbUsers.users)

        /* Populate communities */
        const geoJSONCommunities = communitiesToGeoJSON(dbCommunities.data.communities);
        (mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource).setData(geoJSONCommunities)
        setCommunities(dbCommunities.data.communities)

        createAssetLayers(mapRef, dbAssets.assets)

        mapRef.current.resize()
        /* Add onclick listeners */
        mapRef.current.on('click', 'poi-markers', (e) => {
          handlePOIClickRef.current(e)
          e.originalEvent.preventDefault()
        })

        mapRef.current.on('click', 'community-area-layers', (e) => {
          if (e.originalEvent.defaultPrevented) { return }
          handleCommunityClickRef.current(e)
        })
      })
    })
  }

  const handleViewChange = (newView: AppView) => {
    setView(newView)
  }

  const handleAddToMap = (option: TerrainMapElem) => {
    if (option === TerrainMapElem.COMMUNITY) {
      setShowCommunityForm(true)
    }

    if (option === TerrainMapElem.FLASHPOINT) {
      setShowFlashpointForm(true)
    }

    if (option === TerrainMapElem.POINT_OF_INTEREST) {
      setShowPOIForm(true)
    }

    if (option === TerrainMapElem.STAKEHOLDER) {
      setShowStakeholderForm(true)
    }

    if (option === TerrainMapElem.ENGAGEMENT_NOTE) {
      setShowEngagementForm(true)
    }

    if (option === TerrainMapElem.ISSUE) {
      setShowIssueForm(true)
    }

    if (option === TerrainMapElem.COMMITMENT_FORM) {
      setShowCommitmentForm(true)
    }
    setShowAddPopup(false)
  }

  const pushNewCommunity = (newArea: Community, newStakeholders: Stakeholder[]) => {
    const copy = communities.slice()
    copy.push(newArea)

    const updatedStakeholders = updateCommunityStakeholders(newStakeholders, newArea, stakeholders)
    setStakeholders(updatedStakeholders)

    const geoJSONCommunities = communitiesToGeoJSON(copy);
    (mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource).setData(geoJSONCommunities)

    setCommunities(copy)
  }

  const updateStakeholder = (stakeholder: Stakeholder) => {
    const copy = stakeholders.slice()
    const index = copy.findIndex(({ id }) => id === stakeholder.id)

    if (index < 0) { return }
    copy.splice(index, 1, { ...stakeholder })
    setStakeholders(copy)
    dispatchFocused({ type: FocusedActionKind.SELECT_STAKEHOLDER, stakeholder })
  }

  const updateCommunity = (edited: Community, records: Stakeholder[]) => {
    const copy = communities.slice()
    const index = copy.findIndex(({ id }) => id === edited.id)

    const updatedStakeholders = updateCommunityStakeholders(records, edited, stakeholders)
    setStakeholders(updatedStakeholders)

    if (index < 0) { return }

    copy.splice(index, 1, { ...edited })
    const geoJSONCommunities = communitiesToGeoJSON(copy);
    (mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource).setData(geoJSONCommunities)

    setCommunities(copy)
  }

  const pushNewPOI = (newPOI: TerrainPOI) => {
    const copy = poiList.slice()
    copy.push(newPOI)

    setPoiList(copy)
    const { featureLines, featurePoints, featurePolygons } = poisToGeoJSON(copy);
    (mapRef.current.getSource('poi-points') as mapboxgl.GeoJSONSource).setData(featurePoints);
    (mapRef.current.getSource('poi-lines') as mapboxgl.GeoJSONSource).setData(featureLines);
    (mapRef.current.getSource('poi-polygons') as mapboxgl.GeoJSONSource).setData(featurePolygons)
  }

  const pushNewFlashpoint = (newFlashpoint: Flashpoint) => {
    const copy = flashpoints.slice()
    copy.push(newFlashpoint)

    setFlashpoints(copy)
  }

  const updateFlashpoint = (flashpoint: Flashpoint) => {
    const copy = flashpoints.slice()
    const index = copy.findIndex(({ id }) => id === flashpoint.id)

    if (index < 0) { return }
    copy.splice(index, 1, flashpoint)
    setFlashpoints(copy)
    dispatchFocused({ type: FocusedActionKind.SELECT_FLASHPOINT, flashpoint })
  }

  const pushNewStakeholder = (newStakeholder: Stakeholder) => {
    const copy = stakeholders.slice()
    copy.push(newStakeholder)

    setStakeholders(copy)
  }

  const pushNewCommitment = (newCommitment: Commitment) => {
    setCommitments([...commitments, newCommitment])
  }

  const pushNewEngagementNote = (newNote: EngagementNote) => {
    const updated = [...engagementNotes, newNote]
    setEngagementNotes(updated)
  }

  const pushNewIssue = (newIssue: TerrainIssue) => {
    setIssues([...issues, newIssue])
  }

  const updateIssue = (issue: TerrainIssue) => {
    const copy = issues.slice()
    const index = copy.findIndex(({ id }) => id === issue.id)

    if (index < 0) { return }

    copy.splice(index, 1, { ...issue })
    setIssues(copy)
    dispatchFocused({ type: FocusedActionKind.SELECT_ISSUE, issue })
  }

  const removeFlashpoint = (flashpoint: Flashpoint) => {
    const copy = flashpoints.filter(({ id }) => id !== flashpoint.id)
    setFlashpoints(copy)
  }

  const updatePOISource = (pois: TerrainPOI[]) => {
    const { featureLines, featurePoints, featurePolygons } = poisToGeoJSON(pois);
    (mapRef.current.getSource('poi-points') as mapboxgl.GeoJSONSource).setData(featurePoints);
    (mapRef.current.getSource('poi-lines') as mapboxgl.GeoJSONSource).setData(featureLines);
    (mapRef.current.getSource('poi-polygons') as mapboxgl.GeoJSONSource).setData(featurePolygons)

    setPoiList(pois)
  }

  const updateCommitment = (commitment: Commitment) => {
    const copy = commitments.slice()
    const index = copy.findIndex(({ id }) => id === commitment.id)

    if (index < 0) { return }
    copy.splice(index, 1, { ...commitment })
    setCommitments(copy)
    dispatchFocused({ type: FocusedActionKind.SELECT_COMMITMENT, commitment })
  }

  const updatePOI = (updated: TerrainPOI) => {
    const copy = poiList.slice()
    const index = copy.findIndex(({ id }) => id === updated.id)

    if (index < 0) { return }
    copy.splice(index, 1, updated)

    /* Get updated map data */
    updatePOISource(copy)
  }

  const updateEngagementNote = (updated: EngagementNote) => {
    const copy = engagementNotes.slice()
    const index = copy.findIndex(({ id }) => id === updated.id)

    if (index < 0) { return }
    copy.splice(index, 1, { ...updated })
    setEngagementNotes(copy)
    dispatchFocused({ type: FocusedActionKind.SELECT_ENGAGEMENT, engagement: updated })
  }

  const deletePOI = (poi: TerrainPOI) => {
    const copy = poiList.filter(({ id }) => id !== poi.id)
    updatePOISource(copy)
  }

  const updateFilters = (option : FilterUpdate) => {
    /* Updated filter object */
    const updated = { ...filters, [option]: !filters[option] }
    setFilters(updated)

    if (option === FilterUpdate.SHOW_ASSETS) {
      if (mapRef.current) {
        // Type assertions and null checks for sources
        const linesSource = mapRef.current.getSource('assets-lines') as mapboxgl.GeoJSONSource | undefined
        const polygonsSource = mapRef.current.getSource('assets-polygons') as mapboxgl.GeoJSONSource | undefined
        // Ensure sources are not undefined before calling setData
        const { featureLines, featurePolygons } = assetsToGeoJSON(assets)
        if (linesSource) {
          linesSource.setData(updated[option] ? featureLines : defaultAssetGeoJSON)
        }
        if (polygonsSource) {
          polygonsSource.setData(updated[option] ? featurePolygons : defaultAssetGeoJSON)
        }
      }
      return
    }
    if (option === FilterUpdate.SHOW_POIS) {
      if (mapRef.current) {
        const pointsSource = mapRef.current.getSource('poi-points') as mapboxgl.GeoJSONSource | undefined
        const linesSource = mapRef.current.getSource('poi-lines') as mapboxgl.GeoJSONSource | undefined
        const polygonsSource = mapRef.current.getSource('poi-polygons') as mapboxgl.GeoJSONSource | undefined

        const { featurePoints, featureLines, featurePolygons } = poisToGeoJSON(poiList)

        if (pointsSource) {
          pointsSource.setData(updated[option] ? featurePoints : { type: 'FeatureCollection', features: [] })
        }
        if (linesSource) {
          linesSource.setData(updated[option] ? featureLines : defaultAssetGeoJSON)
        }
        if (polygonsSource) {
          polygonsSource.setData(updated[option] ? featurePolygons : defaultAssetGeoJSON)
        }
      }
      return
    }
    if (option === FilterUpdate.SHOW_COMMUNITIES) {
      if (mapRef.current) {
        const polygonsSource = mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource | undefined
        const geoJSONCommunities = communitiesToGeoJSON(communities)
        if (polygonsSource) {
          polygonsSource.setData(updated[option] ? geoJSONCommunities : defaultAssetGeoJSON)
        }
      }
    }
  }

  return {
    loading,
    setLoading,
    view,
    handleViewChange,
    mapRef,
    fetchAppData,
    areas,
    submittedMessage,
    setSubmittedMessage,
    showAddPopup,
    setShowAddPopup,
    handleAddToMap,
    setShowCommunityForm,
    showCommunityForm,
    pushNewCommunity,
    showPOIForm,
    setShowPOIForm,
    POITypes,
    pushNewPOI,
    showFlashpointForm,
    setShowFlashpointForm,
    causes,
    communities,
    flashpoints,
    pushNewFlashpoint,
    registerDisplay,
    setRegisterDisplay,
    focusedElems,
    dispatchFocused,
    removeFlashpoint,
    poiList,
    showStakeholderForm,
    setShowStakeholderForm,
    stakeholders,
    pushNewStakeholder,
    updatePOI,
    deletePOI,
    users,
    showEngagementForm,
    setShowEngagementForm,
    engagementNotes,
    pushNewEngagementNote,
    showIssueForm,
    setShowIssueForm,
    pushNewIssue,
    issues,
    showCommitmentForm,
    setShowCommitmentForm,
    pushNewCommitment,
    commitments,
    stakeholderGroups,
    editing,
    setEditing,
    updateCommunity,
    saving,
    setSaving,
    updateEngagementNote,
    updateFlashpoint,
    updateIssue,
    updateCommitment,
    updateStakeholder,
    manifestations,
    showFilePopup,
    setShowFilePopup,
    files,
    setFiles,
    imported,
    setImported,
    setStakeholderFilters,
    stakeholderFilters,
    filteredStakeholders,
    setFilteredStakeholders,
    assets,
    showFilterPopup,
    setShowFilterPopup,
    showMapFilterPopup,
    setShowMapFilterPopup,
    updateFilters,
    filters,
    setFilters,
    mapFilters,
    setMapFilters,
    fromCommunityAdd,
    setFromCommunityAdd,
    regions,
  }
}

export const ProvideTerrainData : FC<{ children: ReactNode }> = ({ children }) => {
  const data = useProvideTerrainMapping()
  return (
    <terrainMappingContext.Provider value={data}>
      {
        children
      }
    </terrainMappingContext.Provider>
  )
}
