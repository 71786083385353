import React from 'react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { VerifyEmailContainer } from '../GlobalContainers'

/**
 *  ==========================
 *     Verify Email message
 *  ==========================
 */
const VerifyEmail = ({ message }) => (
  <VerifyEmailContainer>
    <FontAwesomeIcon
      style={{
        fontSize: '60px',
        marginBottom: '40px',
        color: '#326771',
      }}
      icon={faCheckCircle}
    />
    <p>{message}</p>
    <p>Check out your email inbox to activate your account</p>
  </VerifyEmailContainer>
)

export default VerifyEmail
