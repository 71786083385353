import React, { useState } from 'react'
import {
  IonContent, IonRow, IonSelectOption, useIonAlert,
  IonItem, IonSelect, IonLabel, IonButton,
} from '@ionic/react'

import moment from 'moment'
import Purpose from './frames/Purpose'
import Vehicle from './frames/Vehicle'
import Departure from './frames/Departure'
import Paths from './frames/Paths'
import Accommodation from './frames/Accommodation'
import { InstructionButtons } from '../../maps/StyledContainers'

const empty = {
  supervisor: '',
  purpose: '',
  leaderName: '',
  leaderMobile: '',
  driverName: '',
  driverMobile: '',
  vehicleType: '',
  regNumber: '',
  arrivalTime: '',
  to: '',
  departureTime: '',
  from: '',
  passengers: [],
  routes: [],
}
const emptyEmergency = {
  emergencyPhone: '',
  altEmergencyPhone: '',
}

const emptyAccommodation = {
  driverAccommodation: '',
  driverAccommodationLocation: '',
  driverAccommodationPhone: '',
  passengerAccommodation: '',
  passengerAccommodationLocation: '',
  passengerAccommodationPhone: '',
}
/**
 *  ================================
 *       Journey Request Form
 *  ================================
 */
const JourneyForm = ({
  onBack, routes, users, addJourney,
}) => {
  /* Manage form data */
  const [input, setInput] = useState(empty)
  const [emergency, setEmergency] = useState(emptyEmergency)
  const [optionalInput, setOptionalInput] = useState(emptyAccommodation)
  const [frame, setFrame] = useState(1)

  /* display popups */
  const [ionAlert] = useIonAlert()

  /* Change input state */
  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  const returnMessage = (key) => {
    /* Purpose details */
    if (key === 'purpose') return { message: 'Please specify the purpose of the journey' }
    /* Leader Details */
    if (key === 'leaderName' || key === 'leaderMobile') return { header: 'Error - Journey Leader', message: 'Please fill in the Journey Leader’s details.' }
    /* Driver details */
    if (key === 'driverName' || key === 'driverMobile') return { header: 'Error - Driver', message: 'Please fill in the driver’s details.' }
    /* Car details */
    if (key === 'vehicleType' || key === 'regNumber') return { header: 'Error - Vehicle', message: 'Please fill in the Vehicle details.' }
    /* Arrival details */
    if (key === 'to' || key === 'arrivalTime') return { header: 'Error - Arrival (estimate)', message: 'Please fill in the Arrival details.' }
    /* Departure details */
    if (key === 'from' || key === 'departureTime') return { header: 'Error - Departure', message: 'Please fill in the Departure details.' }
    if (key === 'supervisor') return { header: 'Error - Authorised By', message: 'The Authorised By field is empty. Please select a user from the drop down.' }

    return null
  }

  /* handle submit */
  const handleSubmit = () => {
    if (!input.passengers.length) {
      ionAlert({
        header: 'Error - Passenger Details',
        message: 'You must add at least one passenger.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    if (!input.routes.length) {
      ionAlert({
        header: 'Error - Route',
        message: 'You must select at least one route to complete a journey request.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    const notFilled = Object.keys(input).find((key) => !input[key])
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      ionAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    /* Emergency Contact  */
    if (emergency.emergencyPhone === '' && emergency.altEmergencyPhone === '') {
      ionAlert({
        header: 'Error - Emergency Contact',
        message: 'You must submit at least one emergency contact number.',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    if (!Object.values(optionalInput).every((key) => key === '') && !Object.values(optionalInput).every((key) => key !== '')) {
      ionAlert({
        header: 'Error',
        message: 'Fill in with all acommodation details',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    /* Fix formatting issues for some of the fields */
    input.passengers = input.passengers.map((selected) => selected.value).join(', ')
    input.emergencyPhone = emergency.emergencyPhone

    addJourney({
      approver: input.supervisor,
      purpose: input.purpose,
      leader_name: input.leaderName,
      leader_phone: input.leaderMobile,
      driver_name: input.driverName,
      driver_phone: input.driverMobile,
      vehicle: input.vehicleType,
      vehicle_reg: input.regNumber,
      emergency_sat: emergency.emergencyPhone,
      emergency_other: emergency.altEmergencyPhone,
      arrival_time: moment(input.arrivalTime).format('YYYY-MM-DD HH:mm'),
      arrival_location: input.to,
      departure_time: moment(input.departureTime).format('YYYY-MM-DD HH:mm'),
      departure_location: input.from,
      passengers: input.passengers,
      driver_location: optionalInput.driverAccommodationLocation,
      driver_contact: optionalInput.driverAccommodationPhone,
      driver_hotel: optionalInput.driverAccommodation,
      passenger_contact: optionalInput.passengerAccommodationPhone,
      passenger_hotel: optionalInput.passengerAccommodation,
      passenger_location: optionalInput.passengerAccommodationLocation,
      stages: input.routes,
    })
  }

  /* Go back to previous frame */
  const previousFrame = () => setFrame(frame - 1)

  /* Go to the next frame */
  const nextFrame = () => setFrame(frame + 1)

  /* Shared header */
  const Header = (
    <IonRow style={{ color: '#326771', paddingLeft: '15px' }} className='ion-align-items-center ion-justify-content-between'>
      <p>Journey Request</p>
      <p> { frame } of 6 </p>
    </IonRow>
  )

  if (frame === 1) {
    return (
      <Purpose
        handleInput={handleInput}
        input={input}
        header={Header}
        onCancel={onBack}
        onNext={nextFrame}
      >
        {
          Header
        }
      </Purpose>
    )
  }

  if (frame === 2) {
    return (
      <Vehicle
        handleInput={handleInput}
        input={input}
        setEmergency={setEmergency}
        emergency={emergency}
        onBack={previousFrame}
        onNext={nextFrame}
      >
        {
          Header
        }
      </Vehicle>
    )
  }

  if (frame === 3) {
    return (
      <Departure
        handleInput={handleInput}
        input={input}
        onBack={previousFrame}
        onNext={nextFrame}
      >
        {
          Header
        }
      </Departure>
    )
  }

  if (frame === 4) {
    return (
      <Paths
        onBack={previousFrame}
        routes={routes}
        input={input}
        handleInput={handleInput}
        setInput={setInput}
        users={users}
        onNext={nextFrame}
      >
        {
          Header
        }
      </Paths>
    )
  }

  if (frame === 5) {
    return (
      <Accommodation
        onNext={nextFrame}
        onBack={previousFrame}
        handleOptionalInput={(e) => setOptionalInput({ ...optionalInput, [e.target.name]: e.detail.value })}
        optionalInput={optionalInput}
      >
        {
        Header
      }
      </Accommodation>
    )
  }

  return (
    <IonContent
      style={{
        '--ion-background-color': 'white',
        '--padding-bottom': '15px',
        '--padding-top': '15px',
        '--padding-end': '15px',
      }}
    >
      {
        Header
      }
      <IonItem>
        <IonLabel>Select Supervisor:</IonLabel>
        <IonSelect
          name='supervisor'
          value={input.supervisor}
          onIonChange={handleInput}
        >
          {
            users.map((user) => (
              <IonSelectOption key={user.id} value={user.id}> { user.email } </IonSelectOption>
            ))
          }
        </IonSelect>
      </IonItem>
      <InstructionButtons>
        <IonRow style={{ paddingLeft: '15px', marginTop: '15px' }} className='ion-justify-content-between'>
          <IonButton style={{ '--background': '#4197A9' }} onClick={previousFrame}>Back</IonButton>
          <IonButton style={{ '--background': '#326771' }} onClick={handleSubmit}>Submit</IonButton>
        </IonRow>
      </InstructionButtons>
    </IonContent>
  )
}

export default JourneyForm
