/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import {
  IonModal, IonTitle, IonContent, IonButton, IonLabel, IonInput, IonCheckbox, IonRow, IonCol, IonTextarea,
} from '@ionic/react'
import Datetime from 'react-datetime'
import moment from 'moment'
import useApi from '../../hooks/testHooks'

interface Props {
  isOpen: boolean
  onClose: () => void
  task: any
  users: any
  dates: any
  removeTask: (index: number, task: any) => void
  index: number
}

const Popup: React.FC<Props> = ({
  isOpen, onClose, task, users, dates, removeTask, index,
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [title, setTitle] = useState(task.title)
  const [description, setDescription] = useState(task.description)
  const [assignedTo, setAssignedTo] = useState(() => {
    const assignee = task.assignee && users.find((user) => user.id === task.assignee.value)
    return assignee ? { value: assignee.id, label: `${assignee.first_name} ${assignee.final_name}` } : null
  })
  const [dueDate, setDueDate] = useState(task.dueDate)
  const [highPriority, setHighPriority] = useState(task.highPriority)

  const apiHook = useApi()

  const handleEdit = () => {
    setIsEditable(true)
  }

  const handleSubmit = () => {
    const editedTask = [{
      id: task.id,
      title,
      description,
      assignee: assignedTo.value,
      due: dueDate ? new Date(dueDate) : null,
      priority: highPriority,
    }]
    setIsEditable(false)
    apiHook.editTask(editedTask)
      .then(() => {
        task.title = editedTask[0].title
        task.description = editedTask[0].description
        task.assignee = assignedTo[0].value
        task.due = editedTask[0].due
        task.highPriority = editedTask[0].priority
      })
  }

  const handleChange = (name: string, value: any) => {
    // eslint-disable-next-line default-case
    switch (name) {
      case 'title':
        setTitle(value)
        break
      case 'description':
        setDescription(value)
        break
      case 'assignee':
        setAssignedTo(value)
        break
      case 'dueDate':
        setDueDate(value)
        break
      case 'highPriority':
        setHighPriority(value)
        break
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const modal = document.querySelector('.modal') as HTMLElement
      const target = event.target as HTMLElement
      // Check if the click event target is outside the modal
      if (modal && !modal.contains(target) && !target.closest('.modal-button')) {
        // Do nothing since we want to disable click-away behavior
      }
    }
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getAssigneeName = (assigneeId: string, users: any) => {
    const assignee = users.find((user) => user.id === assigneeId)
    return assignee ? `${assignee.first_name} ${assignee.final_name}` : 'Not Assigned'
  }

  return (
    <IonModal isOpen={isOpen}>
      <IonRow style={{ marginTop: '2em', marginLeft: '2em' }}>
        <IonTitle style={{ color: '#8E151F', fontWeight: 'bold' }}>{isEditable ? 'Edit Task' : 'Task'}</IonTitle>
      </IonRow>
      {
        isEditable ? (
          <>
            <IonContent>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Title</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Due Date</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Assigned To</IonLabel>
                </IonCol>

              </IonRow>
              <IonRow style={{ marginLeft: '2em' }}>
                <IonCol>
                  <IonInput style={{ '--background': 'white' }} fill='outline' value={title} onIonChange={(e) => handleChange('title', e.detail.value)} />
                </IonCol>
                <IonCol>
                  <Datetime
                    dateFormat='YYYY-MM-DD'
                    inputProps={{
                      placeholder: task.due ? moment(task.due).format('YYYY/MM/DD HH:mm') : 'Due date',
                      readOnly: true,
                      style: { backgroundColor: 'white' },
                    }}
                    isValidDate={(dates) ? (current) => (current.isSameOrAfter(dates.startDate) && current.isSameOrBefore(dates.endDate))
                      : (current) => current.isAfter(moment())}
                    name='due'
                    value={dueDate}
                    onChange={(e) => handleChange('dueDate', e)}
                    style={{ position: 'absolute', zIndex: 999 }}
                  />
                </IonCol>
                <IonCol style={{ marginRight: '2em' }}>
                  <Select
                    placeholder={assignedTo}
                    name='assignee'
                    className='select-container'
                    value={assignedTo}
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                    onChange={(selected) => handleChange('assignee', selected)}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>High Priority</IonLabel>
                <IonCheckbox style={{ marginLeft: '1em' }} checked={highPriority} onIonChange={(e) => handleChange('highPriority', e.detail.checked)} />
              </IonRow>

              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Description</IonLabel>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginRight: '2em' }}>
                <IonTextarea rows={4} style={{ '--background': 'white' }} value={description} onIonChange={(e) => handleChange('description', e.detail.value)} />
              </IonRow>

            </IonContent>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IonButton style={{ '--background': '#8E151F', marginBottom: '2em', marginRight: '0.3em' }} onClick={isEditable ? handleSubmit : handleEdit}>{isEditable ? 'Submit' : 'Edit'}</IonButton>
              <IonButton style={{ '--background': '#326771', marginBottom: '2em', marginRight: '2em' }} onClick={onClose}>Close</IonButton>
            </div>
          </>
        ) : (
          <>
            <IonContent>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Title</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Due Date</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Assigned To</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em' }}>
                <IonCol>
                  <IonLabel>{task.title}</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel>{task.due.format('DD/MM/YY HH:mm')}</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel>{getAssigneeName(task.assignee.value, users)}</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>High Priority</IonLabel>
                <IonCheckbox style={{ marginLeft: '1em' }} disabled checked={highPriority} onIonChange={(e) => handleChange('highPriority', e.detail.checked)} />
              </IonRow>

              <IonRow style={{ marginLeft: '2em', marginTop: '2em' }}>
                <IonLabel style={{ color: '#326771', fontWeight: 'bold' }}>Description</IonLabel>
              </IonRow>
              <IonRow style={{ marginLeft: '2em', marginRight: '2em', marginTop: '0.5em' }}>
                <IonLabel style={{ marginRight: '2em !important', width: '100%' }}>{task.description}</IonLabel>
              </IonRow>

            </IonContent>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <IonButton style={{ '--background': 'red', marginBottom: '2em', marginLeft: '2em' }} onClick={() => removeTask(index, task)}>Delete</IonButton>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IonButton
                  style={{ '--background': '#8E151F', marginBottom: '2em', marginRight: '0.3em' }}
                  onClick={isEditable ? handleSubmit : handleEdit}
                >
                  {isEditable ? 'Submit' : 'Edit'}
                </IonButton>
                <IonButton style={{ '--background': '#326771', marginBottom: '2em', marginRight: '2em' }} onClick={onClose}>Close</IonButton>
              </div>
            </div>

          </>

        )
      }
    </IonModal>
  )
}

export default Popup
