/* eslint-disable no-trailing-spaces */
import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

import img1 from './InsightAlert1.jpg'
import img2 from './InsightAlert2.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const InsightUseMap = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-insights')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' />Insights Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Alert States
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonText>Alert States are an indicator of the security situation within each of the counties of South Sudan. Each county is given an alert level from 1 (lowest security risk) to 5 
                  (highest security risk), and security recommendations are provided according to its alert level. These alert levels are informed by insight reports; the Trubshaw Insight 
                  team updates alert states weekly based on the information obtained from these insight reports.
                </IonText>
                <IonText><br /><br />Overview of Alert Levels<br /></IonText>
                <IonText><br />Alert State 1 – Green – Caution: There is low risk of possible terrorist activity or civil unrest affecting operations or personnel. Business as usual. </IonText>
                <IonText><br />Alert State 2 – Blue – Warning: A general or localised threat situation, the nature and extent of which are unpredictable, and circumstances do not justify full 
                  implementation of higher alert measures.
                </IonText>
                <IonText><br />Alert State 3 – Yellow – Standfast: A foreseen event or situation that could impact on the security of operations. There is potential for the security situation 
                  in the area to deteriorate rapidly.
                </IonText>
                <IonText><br />Alert State 4 – Orange – Standby: A ‘threat to life’ attack is highly likely - an incident occurs, or information is received, which indicates that some terrorist 
                  action against the facility or facility personnel is imminent.
                </IonText>
                <IonText><br />Alert State 5 – Red – Evacuation: There is an immediate and direct threat to personnel, or an attack has occurred in the immediate area which may affect the 
                  facility.
                </IonText>
                <IonText><br /><br />Alert States on the Map</IonText>
                <IonText><br />A visual representation of the latest alert levels for all counties is available in the Insights App. Here are the steps to view this map: </IonText>
                <IonText><br />Step1.<br />Click on the ‘Default View’ icon in the top left-hand side and click on ‘Alert States’ from the list which pops up. </IonText>
                <IonImg src={img1} />
                <IonText />
                <IonText><br />Step2.<br />Once you have clicked on ‘Alert States’, the map will update to show the colour-coded alert levels for each state.</IonText>
                <IonText><br />The box below the map (shown in red) provides more detailed information on the alert states in different counties, including a description of the alert 
                  level and when the alert state was last modified. As you zoom in on the map, those counties which you zoom in on will appear in this box below the map. For help 
                  on zooming in, click here (link to article on using the map).
                </IonText>
                <IonImg src={img2} />

              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-usemap'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Using the Map
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addinsight'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Add an Insight
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-views'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Filters and Views
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-insights-addincident'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Adding an Incident
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default InsightUseMap
