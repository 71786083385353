/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  IonCol, IonContent, IonLabel, IonPage, IonRow, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { FrappeGantt } from 'frappe-gantt-react'

import PageHeader from '../components/PageHeader'
import { SettingsContainer } from '../components/settings/StyledContainers'
import { TabOption } from '../components/projects/StyledContainers'

import TabContent from '../components/projects/TabContent'

import ProjectHome from '../components/projects/ProjectHome'
import ObjectiveHome from '../components/projects/ObjectiveHome'

import useApi from '../hooks/testHooks'
import { useWorkspace } from '../hooks/useWorkspace'
import StakeholderMap from '../components/projects/ProjectStakeholderMap'

import { crossReferenceObjectives } from '../components/projects/StatusHelper'

/**
 *  ==============================
 *         Projects App
 *  ==============================
 */
const ProjectsApp = () => {
  /*  Editing Project */
  const [editing, setEditing] = useState(false)
  /* Show create project view */
  const [creating, setCreating] = useState(false)
  /* Db users */
  const [users, setUsers] = useState([])

  const [isObjectiveView, setIsObjectiveView] = useState(false)
  const [editingObjective, setEditingObjective] = useState(false)

  const [focussedObjective, setFocussedObjective] = useState(null)

  const [loading, setLoading] = useState(true)
  const [loadingMetrics, setLoadingMetrics] = useState(true)
  const [irisMetrics, setIrisMetrics] = useState([])

  const { workspace } = useWorkspace()
  const [focussed, setFocussed] = useState(0)
  const [, setType] = useState()

  const [view, setView] = useState('Home')
  const [objChart, setObjChart] = useState(false)

  const [createObjective, setCreateObjective] = useState(false)

  /* Projects */
  const [internalProjects, setInternalProjects] = useState([])
  const [externalProjects, setExternalProjects] = useState([])
  const [completedProjects, setCompletedProjects] = useState([])

  /* Hooks */
  const apiHook = useApi()
  const [alert] = useIonAlert()

  const crossReferenceUser = (project) => {
    /** Cross reference objective owner for the project created */
    const formatted = project.objectives.map((objective) => {
      const owner = (typeof objective.owner === 'number') ? users.find((user) => user.id === objective.owner) : objective.owner

      if (owner) {
        // eslint-disable-next-line no-param-reassign
        objective.owner = {
          id: owner?.id,
          first_name: owner?.first_name,
          final_name: owner?.final_name,
          email: owner?.email,
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        objective.owner = {
          id: objective.owner,
          unknown: true,
        }
      }

      /* Format tasks */
      const tasks = objective.tasks.map((task) => ({
        ...task,
        assignee: {
          id: task.assignee,
          first_name: owner.first_name,
          final_name: owner.final_name,
        },
      }))

      // eslint-disable-next-line no-param-reassign
      objective.tasks = tasks

      return objective
    })
    return { ...project, objectives: formatted }
  }

  const formatMetrics = (dbMetrics, formattedProject) => formattedProject.metric_ids.map((id, index) => ({
    id,
    metric: dbMetrics[index].category,
    category: dbMetrics[index].metric_name,
    theme: dbMetrics[index].theme,
    target: dbMetrics[index].target,
    current: dbMetrics[index].current,
    old: true,
  }))

  const addObjectives = (id, objectives, metrics) => {
    const internalIndex = internalProjects.findIndex((project) => project.id === id)
    if (internalIndex >= 0) {
      /* Create a copy and objectives to the project */
      const copy = internalProjects.slice()
      const formattedProject = crossReferenceUser({ ...copy[internalIndex], objectives })
      copy[internalIndex] = formattedProject
      setInternalProjects(copy)
      return
    }
    const index = externalProjects.findIndex((project) => project.id === id)
    if (index >= 0) {
      /* Create a copy and objectives to the project */
      const copy = externalProjects.slice()
      const formattedProject = crossReferenceUser({ ...copy[index], objectives })
      if (metrics) { formattedProject.metrics = formatMetrics(metrics, formattedProject) }
      copy[index] = formattedProject
      setExternalProjects(copy)
      return
    }

    const completedIndex = completedProjects.findIndex((project) => project.id === id)
    if (completedIndex >= 0) {
      const copy = completedProjects.slice()
      if (completedProjects[completedIndex].project_type === 'Internal') {
        const formattedProject = crossReferenceUser({ ...copy[completedIndex], objectives })
        copy[completedIndex] = formattedProject
      } else {
        const formattedProject = crossReferenceUser({ ...copy[completedIndex], objectives })
        if (metrics) { formattedProject.metrics = formatMetrics(metrics, formattedProject) }
        copy[completedIndex] = formattedProject
      }
      setCompletedProjects(copy)
    }
  }

  const addProject = (project) => {
    /*  Cross Reference and add Id */
    const formattedProject = crossReferenceUser(project)
    const owner = users.find((user) => user.id === project.owner)
    const newProject = {
      ...formattedProject,
      owner: {
        id: owner?.id,
        first_name: owner?.first_name,
        final_name: owner?.final_name,
        email: owner?.email,
      },
      status: 'Planning',
    }

    if (project.type === 'Internal') {
      const copy = internalProjects.slice()
      copy.push(newProject)
      setInternalProjects(copy)
    } else {
      const copy = externalProjects.slice()
      copy.push(newProject)
      setExternalProjects(copy)
    }
    setFocussed(project.id)
  }

  // TODO:  Right now it uses the index, it should use the project ID in the future
  const updateProject = (project, formatting = true) => {
    const newProject = (formatting) ? crossReferenceUser(project) : project
    const owner = (formatting) ? users.find((user) => user.id === project.owner) : project.owner

    const updated = {
      ...newProject,
      owner: {
        id: owner.id,
        first_name: owner.first_name,
        final_name: owner.final_name,
        email: owner.email,
      },
    }
    if (project.type === 'Internal') {
      const copy = internalProjects.slice()
      const index = copy.findIndex(({ id }) => id === project.id)

      if (project.status === 'Complete') {
        copy.splice(index, 1)
        setCompletedProjects([...completedProjects, project])
      } else {
        copy[index] = updated
      }
      setInternalProjects(copy)
    } else {
      const copy = externalProjects.slice()
      const index = copy.findIndex(({ id }) => id === project.id)

      if (project.status === 'Complete') {
        copy.splice(index, 1)
        setCompletedProjects([...completedProjects, project])
      } else {
        copy[index] = updated
      }
      setExternalProjects(copy)
    }

    setEditing(false)
  }

  const deleteProject = (projectId, type) => {
    /* Figure out the type of the focussed project before editing */
    if (type === 'Internal') {
      const projectIndex = internalProjects.findIndex((project) => project.id === projectId)
      const copy = internalProjects.slice()
      copy.splice(projectIndex, 1)
      setInternalProjects(copy)
    } else if (type === 'External') {
      const projectIndex = externalProjects.findIndex((project) => project.id === projectId)
      const copy = externalProjects.slice()
      copy.splice(projectIndex, 1)
      setExternalProjects(copy)
    } else {
      const projectIndex = completedProjects.findIndex((project) => project.id === projectId)
      const copy = completedProjects.slice()
      copy.splice(projectIndex, 1)
      setCompletedProjects(copy)
    }
  }

  const updateObjective = (editedObjective, id, projectData) => {
    /* Replaced the objectives for the current project */
    const replace = (project) => {
      const objectives = project.objectives.slice()
      const index = objectives.findIndex((objective) => objective.id === id)

      /* Format it to show first name and last name too */
      const owner = (typeof editedObjective.owner === 'number') ? users.find((user) => user.id === editedObjective.owner) : editedObjective.owner
      objectives[index] = {
        ...editedObjective,
        owner: {
          id: owner.id,
          first_name: owner.first_name,
          final_name: owner.final_name,
          email: owner.email,
        },
      }
      return objectives
    }

    /* Figure out the type of the focussed project before editing */
    const projectIndex = internalProjects.findIndex((project) => project.id === focussed)
    if (projectIndex >= 0) {
      const newObjectives = replace(internalProjects[projectIndex])
      const copy = internalProjects.slice()
      const project = projectData || { ...copy[projectIndex] }
      copy[projectIndex] = { ...project, objectives: newObjectives }

      setInternalProjects(copy)
    } else {
      const index = externalProjects.findIndex((project) => project.id === focussed)
      const newObjectives = replace(externalProjects[index])
      const copy = externalProjects.slice()
      const project = projectData || { ...copy[index] }
      copy[index] = { ...project, objectives: newObjectives }

      setExternalProjects(copy)
    }
  }

  const getFocussed = () => {
    const internal = internalProjects.find((project) => project.id === focussed)
    const external = externalProjects.find((project) => project.id === focussed)
    const complete = completedProjects.find((project) => project.id === focussed)

    /* Return the project id or null in case it doesn't exist */
    if (internal) return internal
    if (external) return external
    if (complete) return complete
    return null
  }

  const removeProject = async (id, type) => {
    await apiHook.abort_project(id)
    deleteProject(id, type)
    alert({
      header: 'Delete Project?',
      message: 'Do you wish to continue? Your progress will be lost. ',
      buttons: ['ok'],
    })
    setView('Home')
  }

  const removeObjective = async (id, project) => {
    await apiHook.deleteObjectives({
      domain_id: workspace.id,
      ids: [id.id],
    }).then(() => {
      // TODO: update objectives before refresh with delete
      const updatedObjectives = project.objectives.filter((objective) => (objective.id !== id.id))
      updateProject({ ...project, objectives: updatedObjectives }, false)

      // go back to project view
      setIsObjectiveView(false)
      setFocussedObjective(null)
      setEditingObjective(false)
    })
  }

  const current = getFocussed()

  useEffect(() => {
    apiHook.getUsers(workspace.id).then((dbUsers) => {
      apiHook.getProjects(workspace.id).then(async ({ projects }) => {
        const formatted = projects.map((project) => {
          const owner = dbUsers.users.find(({ id }) => id === project.user_id)
          return {
            ...project,
            type: project.project_type,
            startDate: project.start,
            endDate: project.deadline,
            owner: {
              id: project.user_id,
              email: (owner) ? owner.email : 'Unknown',
            },
          }
        })
        const dbInternals = formatted.filter(({ project_type, status }) => project_type === 'Internal' && status !== 'Complete')
        const dbExternals = formatted.filter(({ project_type, status }) => project_type === 'External' && status !== 'Complete')
        const dbCompleted = formatted.filter(({ status }) => status === 'Complete')

        const fetchInternalDetails = (project) => {
          apiHook.getProjectDetails(project.id).then((response) => {
            addObjectives(project.id, crossReferenceObjectives(response))
            setFocussed(project.id)
            setLoading(false)
          })
        }

        const fetchExternalDetails = (project) => {
          apiHook.getProjectDetails(project.id).then((response) => {
            apiHook.getMetricDetailed({ id: project.metric_ids }).then(({ metric }) => {
              addObjectives(project.id, crossReferenceObjectives(response), metric)
              setFocussed(project.id)
              setLoading(false)
            })
          })
        }

        if (dbInternals.length) {
          fetchInternalDetails(dbInternals[0])
        } else if (dbExternals.length) {
          fetchExternalDetails(dbExternals[0])
        } else if (dbCompleted.length) {
          if (dbCompleted[0].project_type === 'Internal') {
            fetchInternalDetails(dbCompleted[0])
          } else {
            fetchExternalDetails(dbCompleted[0])
          }
        } else { setLoading(false) }

        setInternalProjects(dbInternals)
        setExternalProjects(dbExternals)
        setCompletedProjects(dbCompleted)

        setUsers(dbUsers.users)
      })
    })
    /* load  metric after everything else is ready */
    apiHook.getIrisMetrics().then(({ metrics }) => {
      setIrisMetrics(metrics)
      setLoadingMetrics(false)
    })
  }, [])

  switch (view) {
    case 'Home':
      return (
        <IonPage>
          <PageHeader title='Projects' />
          <IonContent>
            <SettingsContainer>
              <IonRow style={{ height: '100%', backgroundColor: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
                <IonCol style={{ height: '100%', width: '100%', padding: '0' }}>
                  <ProjectHome
                    internalProjects={internalProjects}
                    externalProjects={externalProjects}
                    completedProjects={completedProjects}
                    selectedProject={getFocussed()}
                    setCreating={setCreating}
                    setFocussed={setFocussed}
                    setEditing={setEditing}
                    editing={editing}
                    loading={loading}
                    deleteProject={deleteProject}
                    setView={setView}
                    focussedObjective={focussedObjective}
                    setFocussedObjective={setFocussedObjective}
                  />
                </IonCol>
              </IonRow>
            </SettingsContainer>
          </IonContent>
        </IonPage>
      )

    case 'Details':
      return (
        <IonPage>
          <PageHeader title='Projects' />
          <IonContent style={{ paddingBottom: '0px', boxShadow: 'none' }}>
            {
            (!editing && !isObjectiveView && !creating && !createObjective) && (
              <IonRow style={{ paddingLeft: '18px !important', paddingTop: '10px !important' }}>
                <IonCol style={{ margin: '0', padding: '0' }}>
                  <TabOption
                    style={{ marginLeft: '20px', marginTop: '10px', marginBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                    onClick={() => {
                      setView('Home')
                      setCreating(false)
                      setCreateObjective(false)
                    }}
                    className='selected'
                  >Project Details
                  </TabOption>
                  <TabOption
                    style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                    onClick={() => {
                      setView('Objectives')
                      setObjChart(false)
                      setCreating(false)
                    }}
                    className=''
                  >Objectives
                  </TabOption>
                  <TabOption
                    style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                    onClick={() => {
                      setView('Objectives')
                      setObjChart(true)
                      setCreating(false)
                    }}
                  >Gantt Chart
                  </TabOption>
                  <TabOption
                    style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                    onClick={() => {
                      setView('Map')
                      setCreating(false)
                      setCreateObjective(false)
                      setObjChart(false)
                    }}
                  >Stakeholder Map
                  </TabOption>
                  <TabOption
                    style={{
                      marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', backgroundColor: '#326771', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    }}
                    onClick={() => {
                      setView('Home')
                      setCreating(false)
                      setCreateObjective(false)
                    }}
                  ><Icon icon='eva:arrow-ios-back-fill' color='#FFFFFF' width='20' height='20' />Back to My Projects
                  </TabOption>
                </IonCol>

                {
                (!creating && current.status === 'Complete') && (
                <IonCol
                  style={{
                    textAlign: 'right', paddingRight: '15px', margin: '0', padding: '0',
                  }}
                >
                  <TabOption
                    style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px' }}
                    onClick={() => alert({
                      header: 'Delete Project?',
                      message: 'Do you wish to continue? Your progress will be lost. ',
                      buttons: [
                        {
                          text: 'Cancel',
                          handler: () => {
                          },
                        },
                        {
                          text: 'Yes, delete',
                          handler: () => {
                            const project = getFocussed()
                            removeProject(focussed, project.project_type)
                          },
                        },
                      ],
                    })}
                  >Delete Project<Icon icon='fluent:delete-24-regular' color='#8e151f' width='20' height='20' />
                  </TabOption>
                </IonCol>

                )
              }

                {
                (!creating && current.status !== 'Complete' && !createObjective) && (
                <IonRow>
                  <IonCol
                    style={{
                      textAlign: 'right', paddingRight: '15px', margin: '0', padding: '0',
                    }}
                  >
                    <TabOption style={{ '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF' }} onClick={() => { setEditing(true) }}>
                      <p style={{
                        margin: '0', fontSize: '16px', color: '#326771', 'text-transform': 'none',
                      }}
                      >Edit Project<Icon icon='bx:edit' color='#326771' width='20' height='20' />
                      </p>
                    </TabOption>
                    <TabOption
                      style={{
                        marginRight: '20px', textAlign: 'right', '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF',
                      }}
                      onClick={() => alert({
                        header: 'Delete Project?',
                        message: 'Do you wish to continue? Your progress will be lost. ',
                        buttons: [
                          {
                            text: 'Cancel',
                            handler: () => {
                            },
                          },
                          {
                            text: 'Yes, delete',
                            handler: () => {
                              const project = getFocussed()
                              removeProject(focussed, project.project_type)
                            },
                          },
                        ],
                      })}
                    >
                      Delete Project
                    </TabOption>
                  </IonCol>
                </IonRow>

                )
              }

              </IonRow>
            )

          }

            {
            (editing && !isObjectiveView && !createObjective) && (
              <IonRow style={{ paddingLeft: '18px !important', paddingTop: '10px !important' }}>
                <IonCol style={{ margin: '0', padding: '0' }}>
                  <TabOption
                    style={{
                      marginLeft: '20px', marginTop: '10px', marginBottom: '0px', backgroundColor: '#326771', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    }}
                    onClick={() => { setEditing(false) }}
                  >
                    <p style={{
                      margin: '0', fontSize: '16px', color: '#FFFFFF', 'text-transform': 'none',
                    }}
                    ><Icon icon='eva:arrow-ios-back-fill' color='#FFFFFF' width='20' height='20' />Back to Project
                    </p>
                  </TabOption>
                </IonCol>

                <IonCol
                  style={{
                    textAlign: 'right', paddingRight: '15px', margin: '0', padding: '0',
                  }}
                >
                  <TabOption
                    style={{
                      marginRight: '20px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    }}
                    onClick={() => alert({
                      header: 'Delete Project?',
                      message: 'Do you wish to continue? Your progress will be lost. ',
                      buttons: [
                        {
                          text: 'Cancel',
                          handler: () => {
                          },
                        },
                        {
                          text: 'Yes, delete',
                          handler: () => {
                            const project = getFocussed()
                            removeProject(focussed, project.project_type)
                          },
                        },
                      ],
                    })}
                  >Delete Project
                  </TabOption>
                </IonCol>

              </IonRow>
            )

          }

            {
            (createObjective) && (
              <IonRow style={{ paddingLeft: '18px !important', paddingTop: '10px !important' }}>
                <IonCol style={{ margin: '0', padding: '0' }}>
                  <TabOption
                    style={{
                      marginLeft: '20px', marginTop: '10px', marginBottom: '0px', backgroundColor: '#326771', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    }}
                    onClick={() => {
                      setEditingObjective(false)
                      setCreateObjective(false)
                      setIsObjectiveView(false)
                      setView('Objectives')
                      setCreating(false)
                    }}
                  >
                    <p style={{
                      margin: '0', fontSize: '16px', color: '#FFFFFF', 'text-transform': 'none',
                    }}
                    ><Icon icon='eva:arrow-ios-back-fill' color='#FFFFFF' width='20' height='20' />Back to Objectives
                    </p>
                  </TabOption>
                </IonCol>

              </IonRow>

            )
          }

            {
            (isObjectiveView && !editingObjective && !createObjective) && (
              <IonRow style={{ paddingLeft: '18px !important', paddingTop: '10px !important' }}>
                <IonCol style={{ margin: '0', padding: '0' }}>
                  <TabOption
                    style={{
                      marginLeft: '20px', marginTop: '10px', marginBottom: '0px', backgroundColor: '#326771', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    }}
                    onClick={() => {
                      setEditingObjective(false)
                      setCreateObjective(false)
                      setIsObjectiveView(false)
                      setView('Objectives')
                      setCreating(false)
                    }}
                  >
                    <p style={{
                      margin: '0', fontSize: '16px', color: '#FFFFFF', 'text-transform': 'none',
                    }}
                    ><Icon icon='eva:arrow-ios-back-fill' color='#FFFFFF' width='20' height='20' />Back to Objectives
                    </p>
                  </TabOption>
                </IonCol>

                <IonCol style={{
                  textAlign: 'right', paddingRight: '15px', margin: '0', padding: '0',
                }}
                >
                  <TabOption
                    style={{ '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF' }}
                    onClick={() => {
                      if (editingObjective) {
                        setEditingObjective(false)
                      } else {
                        setEditingObjective(true)
                      }
                    }}
                  >
                    <p style={{
                      margin: '0', fontSize: '16px', color: '#326771', 'text-transform': 'none',
                    }}
                    >Edit Objective<Icon icon='bx:edit' color='#326771' width='20' height='20' />
                    </p>
                  </TabOption>
                  <TabOption
                    style={{
                      marginRight: '20px', textAlign: 'right', '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF',
                    }}
                    onClick={() => alert({
                      header: 'Delete Objective?',
                      message: 'Do you wish to continue? Your progress will be lost. ',
                      buttons: [
                        {
                          text: 'Cancel',
                          handler: () => {
                          },
                        },
                        {
                          text: 'Yes, delete',
                          handler: () => {
                            const project = getFocussed()
                            removeObjective(focussedObjective, project)
                          },
                        },
                      ],
                    })}
                  >
                    <p style={{
                      margin: '0', fontSize: '16px', color: '#8E151F', 'text-transform': 'none',
                    }}
                    >Delete Objective<Icon icon='fluent:delete-24-regular' color='#8e151f' width='20' height='20' />
                    </p>
                  </TabOption>
                </IonCol>

              </IonRow>

            )
          }

            {
            (isObjectiveView && editingObjective && !createObjective) && (
              <IonRow style={{ paddingLeft: '18px !important', paddingTop: '10px !important' }}>
                <IonCol style={{ margin: '0', padding: '0' }}>
                  <TabOption
                    style={{
                      marginLeft: '20px', marginTop: '10px', marginBottom: '0px', backgroundColor: '#326771', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    }}
                    onClick={() => {
                      setIsObjectiveView(false)
                      setFocussedObjective(null)
                      setEditingObjective(false)
                      setCreateObjective(false)
                    }}
                  >
                    <p style={{
                      margin: '0', fontSize: '16px', color: '#FFFFFF', 'text-transform': 'none',
                    }}
                    ><Icon icon='eva:arrow-ios-back-fill' color='#326771' width='20' height='20' />Back to Project
                    </p>
                  </TabOption>
                </IonCol>

                <IonCol style={{
                  textAlign: 'right', paddingRight: '15px', margin: '0', padding: '0',
                }}
                >
                  <TabOption
                    style={{
                      marginRight: '20px', textAlign: 'right', '---background': '#FFFFFF', '--ion-color-primary': '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                    }}
                    onClick={() => alert({
                      header: 'Delete Objective?',
                      message: 'Do you wish to continue? Your progress will be lost. ',
                      buttons: [
                        {
                          text: 'Cancel',
                          handler: () => {
                          },
                        },
                        {
                          text: 'Yes, delete',
                          handler: () => {
                            const project = getFocussed()
                            removeObjective(focussedObjective, project)
                          },
                        },
                      ],
                    })}
                  >
                    <p style={{
                      margin: '0', fontSize: '16px', color: '#8E151F', 'text-transform': 'none',
                    }}
                    >Delete Objective<Icon icon='fluent:delete-24-regular' color='#8e151f' width='20' height='20' />
                    </p>
                  </TabOption>
                </IonCol>

              </IonRow>

            )
          }

            <SettingsContainer style={{ paddingTop: '0px', marginTop: '0px', height: creating ? '100%' : '90%', boxShadow: 'none' }}>
              <IonRow style={{ height: '100%', backgroundColor: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
                <IonCol style={{ height: '100%', width: '100%', padding: '0' }}>
                  <TabContent
                    selectedProject={getFocussed()}
                    creating={creating}
                    setCreating={setCreating}
                    editing={editing}
                    setEditing={setEditing}
                    users={users}
                    addProject={addProject}
                    updateProject={updateProject}
                    updateObjective={updateObjective}
                    addObjectives={addObjectives}
                    loadingMetrics={loadingMetrics}
                    irisMetrics={irisMetrics}
                    setType={setType}
                    isObjectiveView={isObjectiveView}
                    setIsObjectiveView={setIsObjectiveView}
                    focussedObjective={focussedObjective}
                    setFocussedObjective={setFocussedObjective}
                    editingObjective={editingObjective}
                    setEditingObjective={setEditingObjective}
                    createObjective={createObjective}
                    setCreateObjective={setCreateObjective}
                    setView={setView}
                  />
                </IonCol>
              </IonRow>
            </SettingsContainer>
          </IonContent>
        </IonPage>
      )

    case 'Objectives':
      return (
        <IonPage>
          <PageHeader title='Projects' />
          <IonContent style={{ paddingBottom: '0px' }}>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '20px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                  }}
                  onClick={() => {
                    setIsObjectiveView(false)
                    setFocussedObjective(null)
                    setEditingObjective(false)
                    setView('Details')
                    setCreateObjective(false)
                    setObjChart(false)
                  }}
                >
                  Project Details
                </TabOption>
                {objChart ? (
                  <>
                    <TabOption
                      style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                      onClick={() => {
                        setObjChart(false)
                      }}
                    >Objectives
                    </TabOption>
                    <TabOption
                      style={{ marginTop: '10px', marginBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                      onClick={() => {
                        setObjChart(true)
                      }}
                    >Gantt Chart
                    </TabOption>
                    <TabOption
                      style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                      onClick={() => {
                        setView('Map')
                        setCreating(false)
                        setCreateObjective(false)
                        setObjChart(false)
                      }}
                    >Stakeholder Map
                    </TabOption>
                  </>
                ) : (
                  <>
                    <TabOption
                      style={{ marginTop: '10px', marginBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                      onClick={() => {
                        setObjChart(false)
                      }}
                    >Objectives
                    </TabOption>
                    <TabOption
                      style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                      onClick={() => {
                        setObjChart(true)
                      }}
                    >Gantt Chart
                    </TabOption>
                    <TabOption
                      style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                      onClick={() => {
                        setView('Map')
                        setCreating(false)
                        setCreateObjective(false)
                        setObjChart(false)
                      }}
                    >Stakeholder Map
                    </TabOption>
                  </>
                )}
                <TabOption
                  style={{
                    marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', backgroundColor: '#326771', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                  }}
                  onClick={() => {
                    setView('Home')
                    setCreating(false)
                    setCreateObjective(false)
                    setObjChart(false)
                  }}
                ><Icon icon='eva:arrow-ios-back-fill' color='#FFFFFF' width='20' height='20' />Back to My Projects
                </TabOption>
              </IonCol>

            </IonRow>

            <SettingsContainer style={{ paddingTop: '0px', marginTop: '0px' }}>
              <IonRow style={{ height: '90%', backgroundColor: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
                <IonCol style={{ height: '100%', width: '100%', padding: '0' }}>
                  {objChart ? (
                    getFocussed().objectives != null ? (
                      <>
                        <IonRow>
                          <IonLabel style={{
                            color: '#326771', fontWeight: 'bold', fontSize: '18px', paddingLeft: '4em', marginTop: '3em', marginBottom: '3em',
                          }}
                          >{`${getFocussed().title} - Gantt Chart`}
                          </IonLabel>
                        </IonRow>
                        <IonRow style={{ height: '75%' }}>
                          <div style={{
                            height: '100%', width: '100%', overflowY: 'auto', overflowX: 'hidden',
                          }}
                          >
                            <FrappeGantt
                              tasks={getFocussed().objectives.map((obj) => ({
                                id: obj.id, name: obj.name, start: new Date(obj.start || obj.startDate), end: new Date(obj.endDate), progress: 100,
                              }))}
                              // viewMode={this.state.mode}
                              // onClick={(task) => handleClick(task.id)}
                            />
                          </div>
                        </IonRow>

                      </>
                    ) : (
                      <IonRow>
                        <IonCol style={{
                          color: '#326771', fontWeight: 'bold', fontSize: '18px', paddingLeft: '40px', paddingTop: '20px',
                        }}
                        >There are currently no Objectives assigned to this project.
                        </IonCol>
                      </IonRow>
                    )
                  ) : (
                    <ObjectiveHome
                      internalProjects={internalProjects}
                      externalProjects={externalProjects}
                      completedProjects={completedProjects}
                      selectedProject={getFocussed()}
                      setCreating={setCreating}
                      setFocussed={setFocussed}
                      setEditing={setEditing}
                      editing={editing}
                      loading={loading}
                      deleteProject={deleteProject}
                      setView={setView}
                      focussedObjective={focussedObjective}
                      setFocussedObjective={setFocussedObjective}
                      setIsObjectiveView={setIsObjectiveView}
                      setCreateObjective={setCreateObjective}
                    />
                  )}
                </IonCol>
              </IonRow>
            </SettingsContainer>
          </IonContent>
        </IonPage>
      )

    case 'Map':
      return (

        <IonPage>
          <PageHeader title='Projects' />
          <IonContent style={{ paddingBottom: '0px' }}>
            <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
              <IonCol style={{ margin: '0', padding: '0' }}>
                <TabOption
                  style={{
                    marginLeft: '20px', marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                  }}
                  onClick={() => {
                    setIsObjectiveView(false)
                    setFocussedObjective(null)
                    setEditingObjective(false)
                    setView('Details')
                    setCreateObjective(false)
                    setObjChart(false)
                  }}
                >
                  Project Details
                </TabOption>
                <TabOption
                  style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                  onClick={() => {
                    setView('Objectives')
                    setObjChart(false)
                    setCreating(false)
                  }}
                  className=''
                >Objectives
                </TabOption>
                <TabOption
                  style={{ marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                  onClick={() => {
                    setView('Objectives')
                    setObjChart(true)
                    setCreating(false)
                  }}
                >Gantt Chart
                </TabOption>
                <TabOption
                  style={{ marginTop: '10px', marginBottom: '0px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                  onClick={() => {
                    setObjChart(true)
                  }}
                >Stakeholder Map
                </TabOption>
                <TabOption
                  style={{
                    marginTop: '10px', marginBottom: '0px', paddingBottom: '0px', backgroundColor: '#326771', color: '#FFFFFF', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',
                  }}
                  onClick={() => {
                    setView('Home')
                    setCreating(false)
                    setCreateObjective(false)
                    setObjChart(false)
                  }}
                ><Icon icon='eva:arrow-ios-back-fill' color='#FFFFFF' width='20' height='20' />Back to My Projects
                </TabOption>
              </IonCol>

            </IonRow>
            <StakeholderMap
              stakeholders={getFocussed().internal_project_stakeholders}
              project={getFocussed()}
              setInternalProjects={setInternalProjects}
            />
          </IonContent>
        </IonPage>
      )
    default:
      return (
        <IonPage />
      )
  }
}

export default ProjectsApp
