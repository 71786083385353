import * as turf from '@turf/turf'
import { ControlMeasure } from '../riskregister/types/RiskAssessments'
/**
 *  ==========================
 *      UNIVERSAL TYPES
 *  ==========================
 *
 *  Types that are common across every app.
 */
export enum AlertState {
  AlertState_0 = 0,
  AlertState_1 = 1,
  AlertState_2 = 2,
  AlertState_3 = 3,
  AlertState_4 = 4,
  AlertState_5 = 5,
}

export type ValidImpactRatings = 0 | 1 | 2 | 3 | 4 | 5

export enum SecurityCondition {
  SecurityCondition_1 = 1,
  SecurityCondition_2 = 2,
  SecurityCondition_3 = 3,
  SecurityCondition_4 = 4,
  SecurityCondition_5 = 5,
}

export enum SafetyStatus {
  GREEN = 'Green',
  AMBER = 'Amber',
  RED = 'Red',
  NOT_SUBMITTED = 'Unknown',
}

export type GeoLocation = {
  lat: number,
  lng: number
}

export enum JourneyStatus {
  PENDING = 'Pending',
  DENIED = 'Denied',
  PRE_DEPARTURE = 'Pre-Departure',
  CANCELLED = 'Cancelled',
  IN_TRANSIT = 'In-Transit',
  ABORTED = 'Aborted',
  COMPLETE = 'Complete',
}

export interface DriverUpdate {
  coordinates?: turf.Position,
  status: JourneyStatus,
  journeyId: number,
}

export interface User {
  id: number,
  first_name: string,
  final_name: string,
  email: string,
  phone_number?: string,
  alternative_phone_number?: string,
}

export interface SafetyUser extends User {
  status: SafetyStatus
  county: string,
  lastUpdated: string,
  latitude?: number,
  longitude?: number,
}

export interface AuthUser {
  user_id: number,
  first_name: string,
  final_name: string,
  email: string,
  phone_number: string,
  alternative_phone_number: string,
}

export interface County {
  id: number,
  modified: string,
  Alert_color: string,
  sc_level: SecurityCondition,
  description: string,
  Alert_state: AlertState,
  ADM1_EN?: string,
  ADM2_EN?: string,
  shapeName?: string,
}

export enum RestrictionZoneType {
  GREEN = 'Green',
  AMBER = 'Amber',
  RED = 'Red',
}

export type RestrictionZoneGeoJSON = turf.Feature<turf.LineString, { id: number, type: string }>
export type ZonesGeoJSON = turf.FeatureCollection<turf.LineString, { id: number, type: string }>

export interface RestrictionZone {
  id: number,
  geoData: RestrictionZoneGeoJSON,
  controlMeasures: ControlMeasure[],
  type: RestrictionZoneType,
  county_id: number,
  owner: User,
  countyData: {
    name: string,
    alert_state: AlertState,
    sc_alert?: AlertState,
  },
  comments: string,
  created_at: string,
  mainThreats: string[],
  underlyings: IncidentCause[],
  review: number,
  users?: User[],
  logs?: []
}

export interface Notification {
  id: number,
  title: string,
  content: string,
  actor: number,
  recipient: number,
  note_type: string,
  read: boolean,
  created_at: string,
  taskId: string,
  taskDomain: string
}

export interface NewsArticle {
  id: string,
  title: string,
  url: string,
  created_at: string,
  domain: string,
  img?: string
}

export interface PostReply {
  id: number,
  user: User,
  text: string,
  img_urls: { url: string }[]
  created_at: string
}

export interface FeedPost {
  id: number,
  post_replies: PostReply[],
  img_urls: { url: string }[],
  user: User,
  thumbs_up: number,
  text: string,
  domain_id: number,
  has_liked: boolean,
  created_at: string
}

export enum FlashpointStatus {
  ACTIVE = 'active',
  DORMANT = 'dormant',
  BUILDING = 'building',
}

export enum POIType {
  PIN = 'pin',
  LINE_MARKER = 'line',
  POLYGON = 'polygon',
}

export interface Workspace {
  id: number,
  type: string,
  power: string,
  name: string,
  country: string,
  invite_id?: number
}

export interface Incident {
  date?: string
  user: User,
  countyName?: string,
  regionName?: string,
  id: number,
  description: string,
  latitude: number,
  longitude: number,
  reported: string,
  incident_type: string,
  fatalities: number,
  county_id?: number,
  nearest?: string,
  domain_id?: number,
  created_at?: string,
  user_id?: number,
  county?: {
    id: number,
    name: string,
  },
}

export interface Insight {
  user: User,
  id: number,
  description: string,
  latitude: number,
  longitude: number,
  reported: string,
  county_id?: number,
  nearest?: string,
  domain_id?: number,
  created_at?: string,
  user_id?: number,
  needed: Array<number>,
  optional: Array<number>,
}

export interface Message {
  id: number,
  content: string,
  user_id: number,
  room_id: number,
  created_at: string,
  updated_at: string,
}

export interface ChatRoom {
  id: number,
  recipients: User[],
  name: string,
  latestMessage: Message | null,
  created_at: string,
  read: boolean,
  isGroupChat: boolean,
  recipientUpdates: {
    user_id: number,
    updated_at: string,
    read: boolean
  }[]
}

export interface BackendInternalIncident {
  id: number,
  user_id: number,
  incident_type: string,
  reported: string,
  involved: boolean,
  longitude: number,
  latitude: number,
  domain_id: number,
  description: string,
  comments: string,
  taken: string,
  required: string,
  people: ValidImpactRatings,
  operations: ValidImpactRatings,
  property: ValidImpactRatings,
  archived: boolean,
  created_at: string,
  updated_at: string,
  primary_type: string,
  primary_title: string,
  primary_category: string,
  title: string,
  county_id: number,
  county?: {
    name: string,
    id: number,
  },
}

export interface AdditionalIncident {
  id?: number,
  title: string,
  category: string,
  secondary_type: string,
  internal_incident_id: number,
  created_at: string,
  updated_at: string
}

export interface IncidentLogRecord {
  id: number,
  log: string,
  owner: number,
  user_id: number,
  void: boolean,
  internal_incident_id: number,
  created_at: string,
  updated_at: string,
  ownerObj?: User,
  submitterObj?: User
}

export interface IncidentActor {
  id: number,
  name: string,
}

export interface IncidentCause {
  id: number,
  name: string,
}

export interface BackendIncidentComment {
  id?: number,
  user_id: number,
  comment: string,
  internal_incident_id: number,
  created_at: string,
  updated_at: string,
  image_urls: { url: string }[]
}

export interface IncidentComment extends BackendIncidentComment {
  user: User,
}

export interface BreakoutRoom extends ChatRoom {
  host_id: number
}

export interface IncidentInvestigation {
  id: number,
  name: string,
  summary: string,
  people: ValidImpactRatings,
  operations: ValidImpactRatings,
  property: ValidImpactRatings,
  findings: string,
  lessons: string,
  remedials: string,
  comments: string,
  internal_incident_id: number,
  peopleInvolved?: {
    user: User,
    type: string,
  }[]
}

export interface InternalIncident extends BackendInternalIncident {
  submitter?: User,
  additionalIncidents?: AdditionalIncident[],
  peopleInvolved?: {
    user: User,
    type: string,
  }[],
  logs?: IncidentLogRecord[]
  actors?: IncidentActor[],
  causes?: IncidentCause[],
  additionalComments?: IncidentComment[],
  investigations?: IncidentInvestigation,
}

export interface BackendUnderlaying {
  cause_id: number,
  internal_incident_id: number,
  created_at: string,
  updated_at: string,
}

export interface BackendInvolvement {
  actor_id: number,
  internal_incident_id: number,
  created_at: string,
  updated_at: string,
}

export interface Procedure {
  id: number,
  action: string,
  description: string,
  level: SOPLevel,
  responsibility: ProcedureResponsability
}

export enum TemplateType {
  WORKSPACE = 'domain',
  DEFAULT = 'default',
  ORGANISATION = 'archangel',
}

export enum SOPField {
  SECURITY = 'Security',
  COMMUNITY = 'Community',
  ENVIRONMENT = 'Environment',
  HEALTH_SAFETY = 'Health & Safety',
  OTHER = 'Other',
}

export enum SOPLevel {
  NCI = 'Noncritical',
  ERT = 'Emergency Response',
  IMT = 'Incident Management',
  CMT = 'Crisis Management',
}

export interface IncidentTeam {
  type: SOPLevel,
  members: (User & { role: ProcedureResponsability, rel_id: number }) [],
}

export interface Template {
  id: number,
  type: string,
  templateType: TemplateType,
  immediateProcedures: Procedure[],
  followupProcedures: Procedure[],
  recoveryProcedures: Procedure[],
}

export interface SOP {
  chosenTemplate?: Template,
  template_id: number,
  template_type: TemplateType,
  level: SOPLevel,
  hasDomainTemplate: boolean
  hasArchangelTemplate: boolean,
  type: string,
  id: number,
}

export enum ProcedureLevel {
  ERT = 'ERT',
  IMT = 'IMT',
  CMT = 'CMT',
}

export enum ProcedureResponsability {
  TEAM_LEADER = 'Team Leader',
  LOGISTICS = 'Logistics',
  ADMINISTRATIVE_SUPPPORT = 'Administrative Support',
  FINANCE = 'Finance',
  HSE = 'HSE',
  HR = 'Human Resources',
  LEGAL = 'Legal',
  MEDICAL = 'Medical',
  PR = 'Public Relations',
  SECURITY = 'Security',
  STAKEHOLDER_ENGAGEMENT = 'Stakeholder Engagement',
  OTHER = 'Other',
}
