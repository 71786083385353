/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import {
  IonList, IonItem, IonInput, IonButton, useIonAlert, IonRow, IonNote,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'
import useApi from '../../hooks/testHooks'
import { RegisterContainer } from '../maps/StyledContainers'
import { SherpaButton } from '../GlobalContainers'

const ResetForm = () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const apiHook = useApi()
  const [ionAlert] = useIonAlert()
  const history = useHistory()

  const [passwordFields, setPasswordFields] = useState({
    newPassword: '',
    repeatPassword: '',
  })

  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const handlePassword = (e) => {
    setNewPassword(e.detail.value)
  }

  const handleRepeatPassword = (e) => {
    setRepeatPassword(e.detail.value)
  }

  const submitNewPassword = async () => {
    if (newPassword !== repeatPassword) {
      ionAlert({
        header: 'Error',
        message: 'Passwords do not match.',
        buttons: [
          { text: 'OK' },
        ],
      })

      return
    }

    let isLong = false
    if (newPassword.length >= 8) {
      isLong = true
    }
    let hasNumber = false
    if (/[0-9]/g.test(newPassword)) {
      hasNumber = true
    }

    const passwordTracker = {
      number: hasNumber,
      eightCharsOrGreater: isLong,
    }

    if (!passwordTracker.number) {
      ionAlert({
        header: 'Error',
        message: 'Passwords must have at least 1 number',
        buttons: [
          { text: 'OK' },
        ],
      })
      return
    }

    if (!passwordTracker.eightCharsOrGreater) {
      ionAlert({
        header: 'Error',
        message: 'Passwords must have at least 8 characters',
        buttons: [
          { text: 'OK' },
        ],
      })
      return
    }
    /* Send request to the server and display loader while waiting for response */

    apiHook.passwordRecovery({
      email,
      password1: newPassword,
      password2: repeatPassword,
      code,
    })
      .then((data) => {
        console.log(data)
      })

    ionAlert({
      header: 'Notice',
      message: 'Your password has been successfully changed. Returning to login.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            history.push('/login')
            window.location.reload()
          },
        },
      ],
    })

    setPasswordFields({
      newPassword: '',
      repeatPassword: '',
    })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const mail = queryParams.get('usrcrd')
    const reqCode = queryParams.get('code')

    // Decode the String
    const decoded = Buffer.from(mail, 'base64').toString('utf-8')
    if (mail) {
      setEmail(decoded)
      setCode(reqCode)
    }
  }, [])

  const isValidEmail = (email) => {
    // Regular expression for validating email
    const emailRegex = /\S+@\S+\.\S+/

    return emailRegex.test(email)
  }

  return (
    <RegisterContainer>
      <h2 style={{ textAlign: 'left', color: '#8E151F' }}>Recover Password</h2>
      <p style={{ margin: '20px 0' }}>Please create a new password below </p>
      <p>This password will be used to sign in to the Sherpa platform going forward. So make sure to remember it. </p>
      <IonList>
        <IonItem>
          <IonInput
            placeholder='enter new password'
            type={(showPassword) ? 'text' : 'password'}
            title='Password'
            value={newPassword}
            name='newPassword'
            onIonChange={handlePassword}
          />
          <IonNote slot="helper">Enter a valid email</IonNote>
          <SherpaButton className='show-password' onClick={() => setShowPassword(!showPassword)}>{(showPassword)
            ? <Icon icon='codicon:eye' /> : <Icon icon='codicon:eye-closed' />}
          </SherpaButton>
        </IonItem>
        <IonItem style={{ marginTop: '2em' }}>
          <IonInput
            type={(showRepeatPassword) ? 'text' : 'password'}
            title='Password'
            value={repeatPassword}
            name='repeatPassword'
            placeholder='Repeat New password'
            onIonChange={handleRepeatPassword}
          />
          <SherpaButton className='show-password' onClick={() => setShowRepeatPassword(!showRepeatPassword)}>{(showRepeatPassword)
            ? <Icon icon='codicon:eye' /> : <Icon icon='codicon:eye-closed' />}
          </SherpaButton>
        </IonItem>
      </IonList>
      <IonRow className='ion-justify-content-end'>
        <IonButton disabled={loading} style={{ '--background': '#326771' }} onClick={submitNewPassword} title='login'>Update Password</IonButton>
      </IonRow>

    </RegisterContainer>
  )
}

export default ResetForm
