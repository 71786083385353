/* eslint-disable semi */
/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useState, useRef } from 'react'
import { useIonAlert, IonLoading, IonList } from '@ionic/react'
import computeLength from '@turf/length'
import MapForm from '../forms/MapForm'
import RouteForm from '../forms/RouteForm'

import axios from '../../../utils/axios'
import FormPopup from '../forms/FormPopup'

// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'

import testHook from '../../../hooks/testHooks'
/**
 *  ==========================
 *    Route Form and Picking
 *  ==========================
 */
const Route = ({
  mapRef, handleViewChange, showJourneyForm, setShowRouteForm,
  setRoutes, routes, expandControls, onClose, onSubmit, domainId,
}) => {
  /* Fill form when the Box has been selected */
  const [canFillForm, setCanFillForm] = useState(false)
  const [buildRoute, setBuildRoute] = useState(true)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  /* Store coordinates */
  const [coordinates, setCoordinates] = useState([])
  const [alert] = useIonAlert()

  const [optimizedRoute, setOptimizedRoute] = useState(null)

  const apiHook = testHook()

  /* RouteBox markers */
  const [markers, setMarkers] = useState([])

  const addMarker = (lngLat) => {
    const marker = (!coordinates.length) ? new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' }) : new mapboxgl.Marker()

    marker.setLngLat(lngLat)
      .addTo(mapRef.current)

    return marker
  }

  /* Requests to the optimization API must of type GET */
  // eslint-disable-next-line arrow-body-style
  const prepareGetQuery = (points) => {
    // eslint-disable-next-line max-len
    return `https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${points.join(';')}?source=first&destination=last&roundtrip=false&overview=full&steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
  }

  const findOptimizedRoute = (newCoordinates) => {
    axios.get(prepareGetQuery(newCoordinates))
      .then(({ data }) => {
        mapRef.current.getSource('new-route').setData({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: data.trips[0].geometry.coordinates,
          },
        })

        setOptimizedRoute(data.trips[0].geometry.coordinates)
      })
  }

  const removeMarker = () => {
    const marker = markers.pop()
    marker.remove()

    setMarkers([...markers])

    const copy = coordinates.slice()
    copy.pop()

    setCoordinates(copy)

    if (copy.length > 1) findOptimizedRoute(copy)
    else {
      mapRef.current.getSource('new-route').setData({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      })
      setCanFillForm(false)
    }
  }

  const hideForm = () => {
    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })
    if (handleViewChange) handleViewChange({ target: { value: 'Routes' } })

    if (onClose) onClose()
    if (onSubmit) onSubmit()

    setShowRouteForm(false)
  }

  const handleSubmitRoute = async ({ routeName, to, from }) => {
    if (!optimizedRoute) {
      alert({
        header: 'No route submitted',
        message: 'You need to drop pins on the map to draw a route.',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    const feature = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: optimizedRoute,
      },
    }

    setLoading(true)
    const { id, message } = await apiHook.tryPath(routeName, from, to, JSON.stringify(feature), domainId)

    setCanFillForm(false)

    if (expandControls) expandControls()

    /* Remove every marker */
    markers.map((marker) => marker.remove())

    if (!id) {
      alert({
        header: 'Server Error',
        message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    feature.properties = {
      name: routeName,
      id,
      frequency: 'New route',
      lastUsed: 'Never',
      routeLength: computeLength(feature).toFixed(2),
    }

    const newRoutes = [...routes, feature]
    setRoutes(newRoutes)

    mapRef.current.getSource('routes').setData({
      type: 'FeatureCollection',
      features: newRoutes,
    })

    if (showJourneyForm) {
      if (handleViewChange) handleViewChange({ target: { value: 'Transit' } })
      if (onClose) onClose()
      if (onSubmit) onSubmit()

      setShowRouteForm(false)
    } else {
      setSubmitted(true)
      setLoading(false)
    }
  }

  const changeRoutePoint = (lngLat, index) => {
    /* Replace last element of the array */
    coordinates[index] = lngLat
    setCoordinates(coordinates)

    if (coordinates.length > 1) findOptimizedRoute(coordinates)
  }

  const changeRoutePointRef = useRef(changeRoutePoint)
  changeRoutePointRef.current = changeRoutePoint

  const handleClick = (e) => {
    if (!buildRoute) return

    mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() })
    /* Create marker and set it as a draggable feature */
    const marker = addMarker(Object.values(e.lngLat))
    marker.setDraggable(true)

    marker.on('dragend', () => {
      changeRoutePointRef.current([marker.getLngLat().lng, marker.getLngLat().lat], coordinates.length)
    })

    const newCoordinates = [...coordinates, Object.values(e.lngLat)]
    /* Store markers and coordinates in buffer */
    setMarkers([...markers, marker])
    setCoordinates(newCoordinates)

    /* If there are more than two points calculate a route for this points */
    if (newCoordinates.length > 1) {
      findOptimizedRoute(newCoordinates)
      setCanFillForm(true)
    }

    /* If the user reaches the 12 query points then don't allow them to add more */
    if (newCoordinates.length >= 11) {
      setBuildRoute(false)
      alert('You have reached the maximum number of query points try to drag and drop your last pin')
    }
  }

  /* Use of reference to keep the state updated */
  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  /* Add a tmp source that will be used to draw the new route */
  const createRoute = () => {
    mapRef.current.addSource('new-route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    })

    mapRef.current.addLayer({
      id: 'new-routeline-active',
      type: 'line',
      source: 'new-route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#3887be',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          12, 3,
          22, 12,
        ],
      },
    })
  }

  const handleRouteCancel = (typed) => {
    const close = () => {
      if (showJourneyForm && handleViewChange)handleViewChange({ target: { value: 'Transit' } })
      else {
        mapRef.current.easeTo({
          padding: {
            right: 0,
          },
        })
      }

      /* Remove every marker */
      markers.map((marker) => marker.remove())

      setShowRouteForm(false)
      if (expandControls) expandControls()
      if (onClose) onClose()
    }

    if (typed) {
      alert({
        header: 'Cancel Route?',
        message: 'Do you wish to continue? Your progress will be lost. ',
        buttons: [
          'Back',
          {
            text: 'Yes, continue',
            handler: close,
          },
        ],
      })

      return
    }

    close()
  }

  useEffect(() => {
    createRoute()
    const onClick = (e) => {
      handleClickRef.current(e)
    }

    mapRef.current.on('click', onClick)

    return () => {
      mapRef.current.off('click', onClick)
      mapRef.current.removeLayer('new-routeline-active')
      mapRef.current.removeSource('new-route')
    }
  }, [])

  return (
    <MapForm>
      <IonLoading
        id='route-loader'
        isOpen={loading}
        message='Please wait...'
      />
      {
        (!submitted) ? (
          <RouteForm onCancel={handleRouteCancel} markers={markers} onSubmit={handleSubmitRoute} onRemoveMarker={removeMarker} canFillForm={canFillForm} />
        ) : (
          <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
            <FormPopup
              message='You have successfully created a Route.'
              onClose={hideForm}
            />
          </IonList>
        )
      }
    </MapForm>
  )
}

export default Route
