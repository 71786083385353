import { Icon } from '@iconify/react'
import { IonButton, IonCol, IonRow } from '@ionic/react'
import moment from 'moment'
import React, { FC } from 'react'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { ObscureBackground } from '../../GlobalContainers'
import { BlueButton, ContentContainer } from '../../incident-management/StyledContainers'
import FormPopup from '../../maps/forms/FormPopup'
import { CenterForm } from '../../riskregister/StyledContainers'
import { CampaignStatus, EngagementCampaign } from '../../types/OptimizedMaps'
import { VerticalFlexContainer } from '../StyledContainers'

interface Props {
  onNewCampaign: () => void,
  onBack: () => void,
  onSelectCampaign: (val: EngagementCampaign) => void,
}

const CampaignBoard : FC<Props> = ({ onNewCampaign, onBack, onSelectCampaign }) => {
  const { campaigns, submittedMessage, setSubmittedMessage } = useEngagementData()
  console.log(campaigns)
  //

  return (
    <ContentContainer>
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
      <VerticalFlexContainer>
        <IonRow className='ion-justify-content-between'>
          <IonRow>
            <IonButton onClick={onBack}>Back</IonButton>
            <h4 className='red-font'>Campaigns</h4>
          </IonRow>
          <IonButton onClick={onNewCampaign} style={{ '--background': '#0C9500' }}>New Campaign <Icon icon='ic:baseline-plus' /> </IonButton>
        </IonRow>
        <IonRow style={{ padding: '0 10px', paddingTop: '20px' }} className='terrain-list-title ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Campaign Title</h5>
          </IonCol>
          <IonCol size='4'>
            <h5>Type</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Date Created</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Launch Date</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Status</h5>
          </IonCol>
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            campaigns.map((val) => (
              <IonRow
                style={{
                  margin: '15px 0', border: '1px solid black', borderRadius: '2.5px', padding: '10px',
                }}
                key={val.id}
              >
                <IonCol size='2'>
                  <BlueButton onClick={() => onSelectCampaign(val)}>
                    { val.title }
                  </BlueButton>
                </IonCol>
                <IonCol size='4'>{ val.type }</IonCol>
                <IonCol size='2'>{ moment(val.created_at).format('DD/MM/YY') }</IonCol>
                <IonCol size='2'>{ moment(val.launch_date).format('DD/MM/YY') }</IonCol>
                <IonCol style={{ fontWeight: 'bold', textTransform: 'capitalize', color: (val.status === CampaignStatus.SCHEDULED) ? '#FF9900' : '#0C9500' }} size='2'>{ val.status }</IonCol>
              </IonRow>
            ))
          }
          {
            campaigns.length === 0 && (
              <p style={{ textAlign: 'center' }}>No Campaigns available</p>
            )
          }
        </div>
      </VerticalFlexContainer>
    </ContentContainer>
  )
}

export default CampaignBoard
