import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
} from '@ionic/react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts'
import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { useReportsData } from '../../hooks/reports/useReportsData'
import { SimpleButton } from '../maps/StyledContainers'
import { useWorkspace } from '../../hooks/useWorkspace'
import { SettingsContainer } from '../settings/StyledContainers'

import useApi from '../../hooks/testHooks'

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const WeeklyReport7 = ({
  step, setStep, setIsShowing, data, idx,
}) => {
  const { workspace } = useWorkspace()

  const {
    setShowWeeklyReport,
  } = useReportsData()

  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const handleClose = () => {
    setIsShowing(false)
    setShowWeeklyReport(false)
    setStep(1)
  }

  const apiHook = useApi()

  const handleDownload = () => {
    apiHook.weeklyReportPDF({
      domain_id: workspace.id,
      index: idx,
    })
      .then((result) => {
        apiHook.downloader2(result, `Weekly_Report_${moment(data.end).format('DD/MM/YY')}`)
      })
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '95%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Weekly Report - ${workspace.name}`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonList>
          <IonRow style={{ marginLeft: '3.3em', marginTop: '1em' }}>
            <IonCol style={{ textAlign: 'left' }}>
              <IonLabel style={{ color: 'black', fontWeight: 'bold' }}>Incident Management</IonLabel>
              <IonLabel style={{ color: 'black' }}>{` - Week ending ${moment(data.end).format('DD/MM/YY')}`}</IonLabel>
            </IonCol>
          </IonRow>
        </IonList>

        <IonGrid class='ion-justify-content-center' style={{ width: '90%', height: '70.5%' }}>
          <IonRow>
            <IonCol>
              <SettingsContainer style={{
                width: '100%', height: '50%', padding: '0px', boxShadow: 'none',
              }}
              >
                <IonRow
                  style={{
                    height: '100%',
                    backgroundColor: '#FFFFFF',
                    width: '60%',
                  }}
                >
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle style={{ fontWeight: 'bold' }}>{data.internal_submitted}</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent style={{ color: '#8E151F' }}>Incident Reports Submitted</IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle style={{ fontWeight: 'bold' }}>{data.internal_closed}</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent style={{ color: '#8E151F' }}>Incident Reports Closed</IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </SettingsContainer>
            </IonCol>
          </IonRow>
          <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1em' }}>Incidents By Type</IonLabel>
          <ResponsiveContainer width='95%' height='50%'>
            <BarChart
              data={data.df_internal}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='1 1' />
              <XAxis dataKey='name' />
              <YAxis tickCount='3' />
              <Tooltip />
              <Bar dataKey='Incidents' fill='#2596be' />
            </BarChart>
          </ResponsiveContainer>
        </IonGrid>

        <IonRow style={{}} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default WeeklyReport7
