import { IonCol, IonRow } from '@ionic/react'
import moment from 'moment'
import React, { FC } from 'react'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { BlueButton } from '../../incident-management/StyledContainers'
import EngagementScreen from '../popovers/EngagementScreen'
import { getColor } from './FlashpointList'

const EngagementList : FC = () => {
  const { engagementNotes, focusedElems, dispatchFocused } = useTerrainMapping()

  if (focusedElems.focusedEngagement) {
    return (
      <EngagementScreen />
    )
  }

  return (
    <ListContainer style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow style={{ padding: '10px' }} className='terrain-list-title  ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Subject</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Type</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Aim</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Community</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Date</h5>
          </IonCol>
          <IonCol size='2' />
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            engagementNotes.map((val, index) => (
              <IonRow
                className='ion-justify-content-between terrain-register-row ion-align-items-center'
                style={{ backgroundColor: getColor(index) }}
                key={val.id}
              >
                <IonCol size='2'>{ val.subject }</IonCol>
                <IonCol size='2'>{ val.type }</IonCol>
                <IonCol size='2'>{ val.aim }</IonCol>
                <IonCol size='2'>{ val?.community?.name }</IonCol>
                <IonCol size='2'>{ moment(val.date).format('DD/MM/YY') }</IonCol>
                <IonCol size='2'>
                  <BlueButton
                    onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_ENGAGEMENT, engagement: val })}
                  >View
                  </BlueButton>
                </IonCol>
              </IonRow>
            ))
          }
          {
            engagementNotes.length === 0 && (
              <p style={{ textAlign: 'center' }}>No engagement notes available</p>
            )
          }
        </div>
      </div>
    </ListContainer>
  )
}

export default EngagementList
