import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol, useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { useSituationReport } from '../../hooks/insights/useSituationReport'

//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SitRepPt2 = ({
  step, setStep, county, manifest, users,
}) => {
  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  const [, setIsShowing, , setCountyId] = useSituationReport()

  const handleClose = () => {
    setIsShowing(false)
    setCountyId(null)
    setStep(1)
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '550px', width: '80%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Situation Report - ${county.name} County`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonList>
          <IonRow style={{ marginLeft: '3.3em', marginTop: '1em' }}>
            <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.15em' }}>Staff Safety Status</IonLabel></IonCol>
          </IonRow>
        </IonList>

        <IonGrid class='ion-justify-content-center' style={{ width: '90%', height: '55%' }}>
          <IonRow style={{ marginLeft: '0.6em' }}>
            <IonCol
              size='3'
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>Last Check In</IonLabel>
            </IonCol>

            <IonCol
              size='3'
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>Name</IonLabel>
            </IonCol>

            <IonCol
              size='6'
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>Status</IonLabel>
            </IonCol>
          </IonRow>

          <IonRow style={{ marginLeft: '0.6em' }}>
            {
              manifest.length > 0 ? (
                manifest.map(
                  (val) => (
                    <>
                      <IonCol
                        size='3'
                        style={{
                          textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel>{ moment(val.updated_at).format('DD/MM/YY, hh:mm') }</IonLabel>
                      </IonCol>

                      <IonCol
                        size='3'
                        style={{
                          textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel>{`${(users.find((e) => e.id === val.user_id)).first_name} ${(users.find((e) => e.id === val.user_id)).final_name}`}</IonLabel>
                      </IonCol>

                      <IonCol
                        size='2'
                        style={{
                          textAlign: 'center', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel style={{
                          color: '#14C105', borderRadius: '0.2em', padding: '0.2em', fontSize: '0.9em',
                        }}
                        >
                          {
                            val.level === 'Green' && (
                            <Icon icon='charm:face-smile' />
                            )
                          }
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        size='2'
                        style={{
                          textAlign: 'center', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel style={{
                          color: '#FF8A00', borderRadius: '0.2em', padding: '0.2em', fontSize: '0.9em',
                        }}
                        >
                          {
                            val.level === 'Amber' && (
                            <Icon icon='charm:face-neutral' />
                            )
                          }
                        </IonLabel>
                      </IonCol>
                      <IonCol
                        size='2'
                        style={{
                          textAlign: 'center', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                        }}
                      >
                        <IonLabel style={{
                          color: '#C30101', borderRadius: '0.2em', padding: '0.2em', fontSize: '0.9em',
                        }}
                        >
                          {
                            val.level === 'Red' && (
                            <Icon icon='charm:face-frown' />
                            )
                          }
                        </IonLabel>
                      </IonCol>
                    </>
                  ),
                )
              )
                : (
                  <IonRow>
                    <IonLabel style={{ marginTop: '1em', fontWeight: 'bold', fontSize: '1em' }}>There have been no Safety Checks submitted in this County.</IonLabel>
                  </IonRow>
                )
            }
          </IonRow>
        </IonGrid>

        <IonRow class='ion-align-items-end' style={{ marginTop: '3em', height: '15%' }}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9', paddingLeft: '10px' }} onClick={handlePrev} enabled>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default SitRepPt2
