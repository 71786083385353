import styled from 'styled-components'

const VerticalFlexContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  & .vertical-child {
    flex: 1;
  }
`

const HomeButtonContainer = styled.div`
  padding: 20px;
  margin: 45px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid black;

  & p {
    text-align: center;
    margin-bottom: 0;
    margin-top: 20px;
  }
`

const HomeButton = styled.button`
  background: transparent;
  border: none;
  text-align: center;
`

const ViewContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;

  h5 {
    font-size: 1.1rem;
  }
`

const StakeholderQuadrant = styled.div`
  flex: 1;
  height: 100%;
`

const QuadrantContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

const NoBackgroundButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  padding: 5px;

  & p {
    font-size: 0.9rem;
    margin: 0;
  }
`

const QuadrantRow = styled.div`
  flex: 1;
  display: flex;
`

const FactorOption = styled.button`
  padding: 10px 15px;
  background-color: #326771;
  color: white;
  text-align: center;
  width: 100%;
  margin: 5px 0;
  border-radius: 2.5px;

  &.selected-factor {
    background-color: white;
    color: #326771;
    border: 1px solid #326771;
  }
`

const StakeholderPoint = styled.div`
  width: 25%;
  height: 20%;
  position: absolute;
  text-align: center;
  border-radius: 2.5px;
  background-color: #4197A9;
  color: white;
  cursor: pointer;
`

export {
  VerticalFlexContainer,
  HomeButtonContainer,
  HomeButton,
  ViewContainer,
  QuadrantContainer,
  StakeholderQuadrant,
  QuadrantRow,
  FactorOption,
  StakeholderPoint,
  NoBackgroundButton,
}
