import { IonButton, IonCol, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { TerrainMapElem } from '../../../hooks/terrain-mapping/types/HookInterface'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { OverlayContainer } from '../../GlobalContainers'
import { ZoneTypeButton } from '../../riskregister/StyledContainers'
import BluePolygon from '../icons/blue-polygon.svg'
import POIIcon from '../icons/poi.svg'
import FlashPointIcon from '../icons/flashpoint.svg'
import StakeholderIcon from '../icons/stakeholder.svg'
import EngagementNoteIcon from '../icons/eng-note.svg'
import IssueIcon from '../icons/issue.svg'
import CommitmentIcon from '../icons/commitment.svg'

const AddPopup : FC = () => {
  const { handleAddToMap, setShowAddPopup } = useTerrainMapping()

  return (
    <OverlayContainer className='terrain-add-popup' style={{ zIndex: 45, width: '80%' }}>
      <h5>Add to Map</h5>
      <p style={{ margin: '20px 0' }}>Choose the type of marker you wish to add to the map. Once it is added you can fill out its details. </p>
      <IonRow className='ion-justify-content-between' style={{ margin: '20px 0' }}>
        <IonCol size='5'>
          <ZoneTypeButton onClick={() => handleAddToMap(TerrainMapElem.COMMUNITY)}>
            <img src={BluePolygon} alt='Blue Polygon' />
            <div className='zone-type-text'>
              <h6>Neighbourhood/Community</h6>
              <p>Draw a shape over an area of the map to represent a town or community.
              </p>
            </div>
          </ZoneTypeButton>
          <ZoneTypeButton onClick={() => handleAddToMap(TerrainMapElem.POINT_OF_INTEREST)}>
            <img src={POIIcon} alt='POI Icon' />
            <div className='zone-type-text'>
              <h6>Point of Interest</h6>
              <p>Drop a pin or draw a line on the map to mark a point of interest.
              </p>
            </div>
          </ZoneTypeButton>
          <ZoneTypeButton onClick={() => handleAddToMap(TerrainMapElem.FLASHPOINT)}>
            <img src={FlashPointIcon} alt='Flashpoint Icon' />
            <div className='zone-type-text'>
              <h6>Flashpoint</h6>
              <p>Create a flashpoint to highlight a particular issue or incident in a given area.
              </p>
            </div>
          </ZoneTypeButton>
          <ZoneTypeButton onClick={() => handleAddToMap(TerrainMapElem.STAKEHOLDER)}>
            <img src={StakeholderIcon} alt='Stakeholder Icon' />
            <div className='zone-type-text'>
              <h6>Stakeholder</h6>
              <p>Create a stakeholder that can be associated with an area of flashpoint.
              </p>
            </div>
          </ZoneTypeButton>
        </IonCol>
        <IonCol size='2'>
          <div style={{
            width: '1px', backgroundColor: 'black', height: '100%', margin: 'auto',
          }}
          />
        </IonCol>
        <IonCol size='5'>
          <ZoneTypeButton onClick={() => handleAddToMap(TerrainMapElem.ENGAGEMENT_NOTE)}>
            <img src={EngagementNoteIcon} alt='Engament icon' />
            <div className='zone-type-text'>
              <h6>Engagement Note</h6>
              <p>Create a engagement note to record  details of an interaction with a stakeholder or community.
              </p>
            </div>
          </ZoneTypeButton>
          <ZoneTypeButton onClick={() => handleAddToMap(TerrainMapElem.ISSUE)}>
            <img src={IssueIcon} alt='Blue Polygon' />
            <div className='zone-type-text'>
              <h6>Issue</h6>
              <p>Fill out a form to record  details of an issue with a stakeholder or community.
              </p>
            </div>
          </ZoneTypeButton>
          <ZoneTypeButton onClick={() => handleAddToMap(TerrainMapElem.COMMITMENT_FORM)}>
            <img src={CommitmentIcon} alt='Blue Polygon' />
            <div className='zone-type-text'>
              <h6>Commitment Form</h6>
              <p>Fill out a form to record  details of an issue with a stakeholder or community.
              </p>
            </div>
          </ZoneTypeButton>
        </IonCol>
      </IonRow>
      <IonButton onClick={() => setShowAddPopup(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
    </OverlayContainer>
  )
}

export default AddPopup
