import { Icon } from '@iconify/react'
import {
  IonContent, IonPage, IonCol, IonRow, IonItem, IonGrid, IonLabel, IonButton, IonCard, IonCardHeader, IonCardContent,
} from '@ionic/react'
import React from 'react'

import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 */
const TasksHelp = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>
            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' />Helpcenter
              </IonLabel>
            </IonRow>

            <IonRow class='ion-justify-content-center'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', paddingTop: '30px',
              }}
              >
                Tasks Help
              </IonLabel>
            </IonRow>

            <IonRow class='ion-justify-content-center' style={{ width: '100%' }}>
              <IonLabel style={{
                width: '100%', fontSize: '16px', textAlign: 'center', padding: '30px',
              }}
              >
                In this section you will find articles relating to the Tasks App
              </IonLabel>
            </IonRow>

            <IonRow justify-content-center class='ion-text-center' style={{ width: '100%' }}>

              <IonItem
                class='ion-text-center'
                style={{
                  '--background': '#CFE5E9', width: '100%', align: 'center', display: 'none',
                }}
                lines='none'
              >
                <IonLabel class='ion-text-center' style={{ width: '100%' }}>
                  <input style={{ width: '60%', align: 'center' }} />
                  <IonButton fill='clear'>
                    <Icon icon='icon-park-outline:message-search' color='#326771' size='25' />
                  </IonButton>
                </IonLabel>

              </IonItem>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>
      <IonGrid style={{
        width: '100%', height: '100%', marginBottom: '10px', boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)',
      }}
      >
        <IonContent style={{ height: '100%', '--background': 'white' }}>
          <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
            <IonCol style={{ width: '50%' }}>
              <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-tasks-use'>
                <IonCardHeader
                  class='ion-text-center'
                  style={{
                    color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                  }}
                >Using the Tasks App
                </IonCardHeader>
                <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }}>
                  <IonCardContent>This article outlines how to use the Tasks App to manage your team’s workflow.</IonCardContent>
                </IonItem>
              </IonCard>
            </IonCol>

            <IonCol style={{ width: '50%' }}>
              <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-tasks-edit'>
                <IonCardHeader
                  class='ion-text-center'
                  style={{
                    color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                  }}
                >Editing/Deleting a Task
                </IonCardHeader>
                <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }}>
                  <IonCardContent>This article outlines how to edit or delete a task.</IonCardContent>
                </IonItem>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow style={{ paddingTop: '10px' }}>
            <IonCol style={{ width: '50%' }}>
              <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-tasks-addtask'>
                <IonCardHeader
                  class='ion-text-center'
                  style={{
                    color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                  }}
                >Adding a Task
                </IonCardHeader>
                <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }}>
                  <IonCardContent>This article outlines how to add a task.</IonCardContent>
                </IonItem>
              </IonCard>
            </IonCol>

            <IonCol style={{ width: '50%' }} />
          </IonRow>

          <IonRow style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <IonCol style={{ width: '50%' }}>
              <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-tasks-sort'>
                <IonCardHeader
                  class='ion-text-center'
                  style={{
                    color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                  }}
                >Sorting/Filters
                </IonCardHeader>
                <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }}>
                  <IonCardContent>This article explains how to use the sort by and filter buttons to manage tasks.</IonCardContent>
                </IonItem>
              </IonCard>
            </IonCol>

            <IonCol style={{ width: '50%' }} />
          </IonRow>

        </IonContent>

      </IonGrid>
    </IonPage>
  )
}

export default TasksHelp
