/* eslint-disable react/no-array-index-key */
import { Icon } from '@iconify/react'
import {
  IonButton, IonCol, IonRow, useIonAlert,
} from '@ionic/react'
import 'react-datetime/css/react-datetime.css'
import * as moment from 'moment'
import React, { FC, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import mapboxgl from 'mapbox-gl'
import Select from 'react-select'
import Datetime from 'react-datetime'
import axios from '../../../utils/axios'
import { ObscureBackground, OverlayContainer } from '../../GlobalContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import { EngagementSupport, EngagementTone, EngagementType } from '../../types/OptimizedMaps'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { User } from '../../types/GlobalTypes'

export interface Details {
  subject: string,
  type?: EngagementType,
  date: moment.Moment,
  representatives: ({ value: User, label: string } | undefined) [],
  community: number,
  stakeholders: { value: number, label: string }[],
  orgs: { value: number, label: string }[],
  aim: string,
  tone?: EngagementTone,
  support?: EngagementSupport,
  summary: string,
  assets: any[],
}

export const buttonTypes = [EngagementType.PHONE_CALL, EngagementType.TEXT_MESSAGE_EXCHANGE, EngagementType.FACE_TO_FACE_MEETING, EngagementType.VIDEO_CALL, EngagementType.EMAIL]
export const buttonTones = [EngagementTone.AGGRESSIVE, EngagementTone.ANNOYED, EngagementTone.NORMAL, EngagementTone.POSITIVE, EngagementTone.ENTHUSIASTIC]
export const buttonSupports = [EngagementSupport.STRONGLY_OPPOSED, EngagementSupport.SOMEWHAT_OPPOSED, EngagementSupport.NORMAL, EngagementSupport.SOMEWHAT_IN_FAVOUR, EngagementSupport.VERY_IN_FAVOUR]

const EngagementNote : FC = () => {
  const {
    users, communities, stakeholders, setShowEngagementForm, setSubmittedMessage,
    pushNewEngagementNote, setLoading, assets,
  } = useTerrainMapping()
  const [ionAlert] = useIonAlert()
  const [input, setInput] = useState<Details>({
    subject: '',
    date: moment(),
    representatives: [],
    community: null,
    stakeholders: [],
    orgs: [],
    aim: '',
    summary: '',
    assets: [],
  })

  const { workspace } = useWorkspace()
  const mapRef = React.useRef<mapboxgl.Map>(null)
  const [marker, setMarker] = useState<mapboxgl.Marker>(null)

  const errorMessages = {
    subject: 'Engagement note must have a subject',
    // type: 'Engagement type not specified',
    // date: 'Date of the engagement not specified',
    // community: 'Engagement must have occurred at a given neighbourhood/community',
    // aim: 'Aim of engagement missing',
    // tone: 'Please specify the tone of the engagement',
    // support: 'Please specify the level of support toward the company',
    // summary: 'Summary of engagement missing',
  }

  const aims = [
    { id: 1, name: 'Communicating information' },
    { id: 2, name: 'Facilitating decision-making' },
    { id: 3, name: 'Identifying and mitigating impacts and risks' },
    { id: 4, name: 'Incident and grievance management' },
    { id: 4, name: 'Land access' },
    { id: 5, name: 'Managing commitments' },
    { id: 6, name: 'Maximising opportunities' },
    { id: 7, name: 'Understanding perceptions / managing expectations' },
  ]


  const handleClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (marker) return
    /* Place new marker on the specified zone */
    const markerTemp = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      .setLngLat(e.lngLat)
      .addTo(mapRef.current)

    /* Add draggable functionalities */
    markerTemp.setDraggable(true)

    setMarker(markerTemp)
  }
  const handleClickRef = React.useRef(handleClick)
  handleClickRef.current = handleClick

  const chooseUserInvolved = (addUser: { value: User, label: string }, index: number) => {
    const copy = input.representatives.slice()
    copy.splice(index, 1, addUser)

    setInput({ ...input, representatives: copy })
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const findError = keys.find((val) => !input[val] || input[val] === '')

    if (!input.representatives.every((val) => val)) {
      ionAlert({
        header: 'Form Error',
        message: 'One or more representatives data seem to not be filled',
        buttons: [{ text: 'Ok' }],
      })
      return
    }

    if (findError) {
      ionAlert({
        header: 'Form Error',
        message: errorMessages[findError],
        buttons: [{ text: 'Ok' }],
      })

      return
    }

    const filteredRepresentatives = input.representatives.reduce<{ value: User, label: string }[]>((list, current) => {
      if (list.find(({ value }) => value.id === current.value.id)) {
        return list
      }
      return [...list, current]
    }, [])

    setLoading(true)
    axios.post('/api/v2/engagement_note/create_note', {
      domain_id: workspace.id,
      alias_name: input.subject,
      subject: input.subject,
      type: input.type,
      date: input.date.format(),
      community_id: input.community,
      aim: input.aim,
      tone: input.tone,
      support: input.support,
      summary: input.summary,
      reps: filteredRepresentatives?.map(({ value }) => value.id) ?? null,
      stakeholders: input.stakeholders.map(({ value }) => value),
      orgs: input.orgs.map(({ value }) => value),
      assets: input.assets.map(({ value }) => value),
    }).then(({ data }) => {
      if (data.message === 'you have not created a note.') {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }

      const community = communities.find(({ id }) => input.community === id)
      const selectedStakeholders = stakeholders.filter(({ id }) => input.stakeholders.find(({ value }) => value === id))
      const selectedOrgs = stakeholders.filter(({ id }) => input.orgs.find(({ value }) => value === id))
      pushNewEngagementNote({
        id: data.note.id,
        subject: input.subject,
        aim: input.aim,
        type: input.type as EngagementType,
        date: input.date.format(),
        representatives: filteredRepresentatives.map(({ value }) => value),
        community,
        stakeholders: selectedStakeholders.map(({ id, name }) => ({ id, name })),
        orgs: selectedOrgs.map(({ id, name }) => ({ id, name })),
        tone: input.tone as EngagementTone,
        support: input.support as EngagementSupport,
        summary: input.summary,
        created_at: moment().format(),
        updated_at: moment().format(),
      })
      setSubmittedMessage('Engagement note created succesfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setShowEngagementForm(false)
      setLoading(false)
    })
  }

  return (
    <>
      <ObscureBackground style={{ zIndex: 20 }} />
      <OverlayContainer
        style={{
          zIndex: 25, height: '80%', overflow: 'hidden', width: '60%',
        }}
        className='terrain-add-popup'
      >
        <div style={{ maxHeight: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
          <IonRow style={{ marginBottom: '10px' }} className='ion-justify-content-between ion-align-items-center'>
            <h5 style={{ marginBottom: 0 }}>Add Engagement Note</h5>
            <SimpleButton onClick={() => setShowEngagementForm(false)}>Close X</SimpleButton>
          </IonRow>
          <div style={{ flex: 1, overflow: 'auto' }}>
            <div className='terrain-form-field'>
              <h6>Subject</h6>
              <input
                placeholder='Subject of engagement'
                type='text'
                value={input.subject}
                onChange={(e) => setInput({ ...input, subject: e.target.value })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Key Asset</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Key Asset(s)'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={assets.map(({ id, name }) => ({ label: name, value: id }))}
                value={input.assets}
                onChange={(selected) => setInput({ ...input, assets: selected })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Type</h6>
              {
                buttonTypes.map((val) => (
                  <TagButton
                    key={val}
                    onClick={() => setInput({ ...input, type: val })}
                    style={{ backgroundColor: (val === input.type) ? '#326771' : 'white', color: (val === input.type) ? 'white' : '#326771' }}
                  >
                    { val }
                  </TagButton>
                ))
              }
            </div>
            <div className='terrain-form-field'>
              <h6>Date of Engagement</h6>
              <Datetime
                dateFormat='YYYY-MM-DD'
                inputProps={{
                  placeholder: 'Select Date',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                value={input.date}
                onChange={(e) => setInput({ ...input, date: e as moment.Moment })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Company Representatives</h6>
              {
                input.representatives.map((val, index) => (
                  <IonRow key={index} style={{ margin: '10px 0' }}>
                    <IonCol size='3'>
                      <Select
                        placeholder='Select user'
                        name='supervisor'
                        id='select-supervisor-container'
                        className='select-container'
                    // eslint-disable-next-line no-undef
                        value={val}
                        menuPortalTarget={document.body}
                        options={users.map((userVal) => ({ value: userVal, label: `${userVal.first_name} ${userVal.final_name}` }))}
                        onChange={(selected) => chooseUserInvolved(selected, index)}
                      />
                    </IonCol>
                    <IonCol size='3'>
                      <p className='incident-management-text-field'>{ val ? val.value.email : 'Email' }</p>
                    </IonCol>
                    <IonCol size='3'>
                      <p className='incident-management-text-field'>{ val && val.value.phone_number ? val.value.phone_number : 'Phone No.' }</p>
                    </IonCol>
                    <IonCol size='3'>
                      <p className='incident-management-text-field'>{ val && val.value.alternative_phone_number ? val.value.alternative_phone_number : 'Alternative Phone No.' }</p>
                    </IonCol>
                  </IonRow>
                ))
              }
              <IonRow className='ion-justify-content-end'>
                <SimpleButton
                  onClick={() => setInput({ ...input, representatives: [...input.representatives, undefined] })}
                >
                  Add Person <Icon icon='ic:baseline-plus' style={{ marginLeft: '10px' }} />
                </SimpleButton>
              </IonRow>
            </div>
            <div className='terrain-form-field'>
              <h6>Neighbourhood/Community</h6>
              <CreatableSelect
                placeholder='Select a community'
                name='community'
                className='select-container'
                id='community-select'
                menuPortalTarget={document.body}
                options={communities.map(({ id, name }) => ({ value: id, label: name }))}
                value={input.community ? { value: input.community, label: communities.find((community) => community.id === input.community).name } : null}
                onChange={(selected) => setInput({ ...input, community: selected ? selected.value : null })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Attending Organisation</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Organisations'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.filter(({ type }) => type === 'Organisation').map(({ id, name }) => ({ label: name, value: id }))}
                value={input.orgs}
                onChange={(selected) => setInput({ ...input, orgs: selected })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>Attending Individuals</h6>
              <CreatableSelect
                isMulti
                placeholder='Select Individuals'
                name='colors'
                className='select-container'
                id='journey-passengers'
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={stakeholders.filter(({ type }) => type === 'Individual').map(({ id, name }) => ({ label: name, value: id }))}
                value={input.stakeholders}
                onChange={(selected) => setInput({ ...input, stakeholders: selected })}
              />
            </div>
            <div className='terrain-form-field'>
              <h6>What was the aim?</h6>
              <select
                value={input.aim}
                onChange={(e) => setInput({ ...input, aim: e.target.value })}
                style={{ padding: '5px 10px' }}
              >
                <option value='' disabled>Select the aim</option>
                {
                  aims.map((val) => (
                    <option key={val.id} value={val.name}>{ val.name }</option>
                  ))
                }
              </select>
            </div>
            <div className='terrain-form-field'>
              <h6>What was the general tone of the participants?</h6>
              {
                buttonTones.map((val) => (
                  <TagButton
                    key={val}
                    onClick={() => setInput({ ...input, tone: val })}
                    style={{ backgroundColor: (val === input.tone) ? '#326771' : 'white', color: (val === input.tone) ? 'white' : '#326771' }}
                  >
                    { val }
                  </TagButton>
                ))
              }
            </div>
            <div className='terrain-form-field'>
              <h6>What was the general level of support/opposition to the company?</h6>
              {
                buttonSupports.map((val) => (
                  <TagButton
                    key={val}
                    onClick={() => setInput({ ...input, support: val })}
                    style={{ backgroundColor: (val === input.support) ? '#326771' : 'white', color: (val === input.support) ? 'white' : '#326771' }}
                  >
                    { val }
                  </TagButton>
                ))
              }
            </div>
            <div className='terrain-form-field form-desc'>
              <h6>Summary</h6>
              <textarea value={input.summary} onChange={(e) => setInput({ ...input, summary: e.target.value })} />
            </div>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton onClick={() => setShowEngagementForm(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
            <IonButton onClick={handleSubmit} style={{ '--background': '#0C9500' }}>Submit</IonButton>
          </IonRow>
        </div>
      </OverlayContainer>
    </>
  )
}

export default EngagementNote
