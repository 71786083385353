import { IonCol, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { SOP, TemplateType } from '../../types/GlobalTypes'
import { SimpleButton, SOPCard } from '../StyledContainers'

interface Props {
  data: SOP,
  onChange: () => void,
  onView: () => void,
  onDelete: () => void
}

export const getTemplateName = (type: TemplateType) : string => {
  if (type === TemplateType.DEFAULT) {
    return 'Default'
  }

  if (type === TemplateType.ORGANISATION) {
    return 'Organisation'
  }

  return 'Workspace'
}

const SOPComponent : FC<Props> = ({
  data, onChange, onView, onDelete,
}) => (
  <SOPCard>
    <IonRow className='ion-align-items-center'>
      <IonCol size='3'>
        <h5 style={{ fontWeight: 'bold' }}> { data.type } </h5>
      </IonCol>
      <IonCol size='6'>
        <p style={{ margin: '0', padding: '0 20px', borderLeft: '0.5px solid' }}>
          Template - { getTemplateName(data.template_type) } <SimpleButton style={{ marginLeft: '10px' }} onClick={onChange}> Change </SimpleButton>
        </p>
      </IonCol>
      <IonCol size='1'>
        <SimpleButton onClick={onView}> View </SimpleButton>
      </IonCol>
      <IonCol size='2'>
        <SimpleButton style={{ color: '#C30101' }} onClick={onDelete}> Remove </SimpleButton>
      </IonCol>
    </IonRow>
  </SOPCard>
)

export default SOPComponent
