/* eslint-disable import/no-webpack-loader-syntax */
import * as React from 'react'
import { useIonAlert } from '@ionic/react'
import axios from 'axios'
import { AssetInputActionKind } from './helpers/AddAssetReducers'
import {
  getAssetWithinRadius, createAsset, CreateAssetResponse, formatNewAsset,
} from './helpers/utils'
import { GeoLocation } from '../../components/types/GlobalTypes'
import * as mapboxgl from '!mapbox-gl'

import { useWorkspace } from '../useWorkspace'
import { AddAssetInterface } from './types/HookInterfaces'
import { useRiskRegister } from './useRiskRegister'
import { DisplayAssetAction } from './helpers/StateReducers'
import { useEffect, useState } from 'react'

/**
 *  ================================================
 *        HANDLE ASSET CREATION STATES AND LOGIC
 *  ================================================
 */
export const addAssetContext = React.createContext<AddAssetInterface>(null)
export const useAddAsset = () => React.useContext(addAssetContext)

const useProvideAddAsset = () : AddAssetInterface => {
  /* Store state of the form */
  const [assetMarker, setAssetMarker] = React.useState<mapboxgl.Marker>(null)
  const [displayAlert] = useIonAlert()
  const [canFillForm, setCanFillForm] = React.useState<boolean>(false)
  const [markers, setMarkers] = React.useState<any[]>([])
  const [lineSource, setLineSource] = React.useState<any>()
  const [coords, setCoords] = React.useState<any>()
  const [closed, setClosed] = React.useState<boolean>(false)
  const [files, setFiles] = React.useState<any | null>(null)
  const [showFilePopup, setShowFilePopup] = React.useState<boolean>(false)
  const [imported, setImported] = React.useState<any | null>(null)

  const {
    pushAsset, assets, mapRef, returnToSafe, setLoading, addAssetInput, dispatchAssetInput, dispatchDisplayAsset, assetType, setAssetType, allAssets
  } = useRiskRegister()
  const { workspace } = useWorkspace()

  /* Remove marker from map and forget state */
  const forgetMarker = () : void => {
    // Remove the single marker if it exists
    if (assetMarker) {
      assetMarker.remove()
      setAssetMarker(null)
    }
    // Remove all markers
    if (markers && markers.length > 0) {
      markers.forEach(marker => marker.remove())
      setMarkers([])
    }

    // Remove the line or polygon layer if it exists
    if (lineSource) {
      if (mapRef.current.getLayer('line')) {
        mapRef.current.removeLayer('line')
      }
      if (mapRef.current.getLayer('polygon')) {
        mapRef.current.removeLayer('polygon')
      }
      setLineSource(null)
    }
    // Reset coordinates
    setCoords(null)
  }

  /* Continue to details form */
  const forwardForm = () : void => {
    /* Get location details */
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${addAssetInput.coordinates.lng},${addAssetInput.coordinates.lat}.json?access_token=${mapboxgl.accessToken}`)
      .then(({ data }) => {
        const loc = {
          ...addAssetInput.location,
          country: data.features[1].text,
        }
        dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, showDetailsForm: true, location: loc } })
      })
    if (assetMarker) { assetMarker.setDraggable(false) }
    setAssetMarker(assetMarker)
    dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, showDetailsForm: true } })
  }

  /* Change input locaotion when pin is dragged around */
  const updateLocation = (newLocation: GeoLocation) : void => {
    const results = getAssetWithinRadius(assets, newLocation, mapRef.current.getZoom())
    updateLine()
    /* In case an asset has been found to be within the specify radius */
    if (results) {
      const { coordinates, assetNames } = results
      displayAlert({
        header: 'Combine Asset Location?',
        message: `Do you wish to move your asset to the same coordinates as ${assetNames.join(', ')}. Clicking yes will join these assets to the same location pin on the map.`,
        buttons: [
          { text: 'No' },
          {
            text: 'Yes',
            handler: () => {
              dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates } })
              assetMarker.setLngLat(coordinates)
              forwardForm()
            },
          },
        ],
      })
    }
  }

  useEffect(() => {
    console.log('coords:', coords)
  }, [coords])

  const updateLocationRef = React.useRef(updateLocation)
  updateLocationRef.current = updateLocation

  /* Add pins when clicking on the map */
  const handleClick = (e) :void => {
    /* Can only add a single pin */
    if (assetType === 'pin') {
      console.log('click')
      if (assetMarker || addAssetInput.coordinates.lng) return

      const marker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      marker.setLngLat(e.lngLat)
        .addTo(mapRef.current)

      marker.setDraggable(true)
      console.log('Marker dragged to:', marker.getLngLat())
      const newCord = [[marker.getLngLat().lat, marker.getLngLat().lng]]
      setCoords([newCord])
      marker.on('dragend', () => {
        updateLocationRef.current({ lat: marker.getLngLat().lat, lng: marker.getLngLat().lng })
        console.log('Marker dragged to:', marker.getLngLat())
        const newCord = [[marker.getLngLat().lat, marker.getLngLat().lng]]
        setCoords([newCord])
      })
      setCanFillForm(true)
      dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates: { ...e.lngLat } } })
      setAssetMarker(marker)
    } else if (assetType === 'line') {
      // Allow placing and moving 2 pins for 'line' type and draw the line connecting them
      if (markers.length >= 2) return
      const marker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      marker.setLngLat(e.lngLat).addTo(mapRef.current)
      marker.setDraggable(true)
      marker.on('dragend', () => {
        updateLocationRef.current({ lat: marker.getLngLat().lat, lng: marker.getLngLat().lng })
        console.log('Marker dragged to:', marker.getLngLat())
        dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates: { ...e.lngLat } } })
        updateLine()
      })
      const newMarkers = [...markers, marker]
      setMarkers(newMarkers)

      if (newMarkers.length === 2) {
        drawLine(newMarkers)
        const coordinates = newMarkers.map((marker) => marker.getLngLat())
        const newCoord = coordinates.map((lngLat) => [lngLat.lng, lngLat.lat])
        console.log('Markers: ', newCoord)
      }
      setCanFillForm(true)
      const coordinates = newMarkers.map((marker) => marker.getLngLat())
      const newCoord = coordinates.map((lngLat) => [lngLat.lng, lngLat.lat])
      setCoords(newCoord)
      dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates: { ...e.lngLat } } })
      setAssetMarker(marker)
    } else if (assetType === 'polygon') {
      if (closed) return
      const firstMarker = markers.length > 0 ? markers[0] : null
      const newMarkerLngLat = e.lngLat
      if (firstMarker) {
        // Check if the new marker is close to the first marker
        const distanceToFirstMarker = Math.sqrt(
          ((firstMarker.getLngLat().lng - newMarkerLngLat.lng) ** 2) +
          ((firstMarker.getLngLat().lat - newMarkerLngLat.lat) ** 2)
        )
        // Define a threshold for snapping (in degrees, you might need to adjust this value)
        const snapThreshold = 0.5
        // If the new marker is close to the first marker, snap to the first marker
        if (distanceToFirstMarker < snapThreshold) {
          newMarkerLngLat.lng = firstMarker.getLngLat().lng
          newMarkerLngLat.lat = firstMarker.getLngLat().lat
          setClosed(true)
        }
      }
      const marker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      marker.setLngLat(newMarkerLngLat).addTo(mapRef.current)
      marker.setDraggable(true)
      marker.on('dragend', () => {
        updateLocationRef.current({ lat: marker.getLngLat().lat, lng: marker.getLngLat().lng })
        console.log('Marker dragged to:', marker.getLngLat())
        dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates: { lng: 7, lat: 7 } } })
        updateLine()
      })
      const newMarkers = [...markers, marker]
      setMarkers(newMarkers)

      setCanFillForm(true)
      setAssetMarker(newMarkers[0])
      const coordinates = newMarkers.map((marker) => marker.getLngLat())
      const newCoord = coordinates.map((lngLat) => [lngLat.lng, lngLat.lat])
      setCoords(newCoord)
      dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates: newCoord } })
    }
  }

  const drawLine = (markers) => {
    const coordinates = markers.map((marker) => marker.getLngLat())
    const lineData = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coordinates.map((lngLat) => [lngLat.lng, lngLat.lat]),
      },
    };

    if (!lineSource) {
      const lineLayer = {
        id: 'line',
        type: 'line',
        source: {
          type: 'geojson',
          data: lineData,
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#6d00eb',
          'line-width': 5,
        },
      }

      mapRef.current.addLayer(lineLayer)
      setLineSource(mapRef.current.getSource('line'))
    } else {
      lineSource.setData(lineData)
    }
  }

  const updateLine = () => {
    if (markers.length === 2) {
      console.log('update line function')
      const coordinates = markers.map((marker) => marker.getLngLat())
      setCoords(coordinates.map((lngLat) => [lngLat.lng, lngLat.lat]))
      lineSource.setData({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinates.map((lngLat) => [lngLat.lng, lngLat.lat]),
        },
      })

      updateLocationRef.current({
        coordinates: coordinates.map((lngLat) => ({
          lat: lngLat.lat,
          lng: lngLat.lng,
        })),
      })
    }
  }

  const handleClickRef = React.useRef(handleClick)
  handleClickRef.current = handleClick

  const returnMessage = (key: string) => {
    if (key === 'name') return { header: 'Error - Asset Name', message: 'Please name your asset.' }
    if (key === 'type') return { header: 'Error - Asset Type', message: 'Please select a type for your asset' }

    return null
  }

  const canSubmit = () => {
    /* Check that all fields have been filled */
    const notFilled = Object.keys(addAssetInput).find((key) => !addAssetInput[key])
    if (notFilled && returnMessage(notFilled)) {
      const error = returnMessage(notFilled)
      displayAlert({
        header: error.header,
        message: error.message,
        buttons: [
          { text: 'Ok' },
        ],
      })

      return false
    }

    return true
  }

  const getFeature = (type, coord) : any => {
    if (type === 'line') {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: coord,
        },
      }
    }

    if (type === 'pin') {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: coord[0],
        },
      }
    }

    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [coord],
      },
    }
  }

  const handleSubmit = () : void => {
    const submit : boolean = canSubmit()
    if (!submit) return

    forgetMarker()
    setLoading(true)
    let feature
    /* Submit request to create new asset in the backend  */
    if (coords) {
      feature = getFeature(assetType, coords)
    }
    console.log('feature in submit: ', feature)
    if (imported) {
      console.log('imported:', imported[0])
      feature = imported[0]
    }
    createAsset(addAssetInput, workspace.id, feature, assetType)
      .then((data: CreateAssetResponse) => {
        if (data.asset_id) {
          dispatchAssetInput({ type: AssetInputActionKind.CLEAR })
          dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
          //
          pushAsset({ ...formatNewAsset(addAssetInput, workspace.id), id: data.asset_id })
          setLoading(false)
          return
        }

        /* In case of an error display popup and close loader */
        displayAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [
            { text: 'Ok' },
          ],
        })

        returnToSafe()
        setLoading(false)
      })
      .catch(() => {
        displayAlert({
          header: 'Unexpected Error',
          message: 'An error has ocurred while processing your request.',
        })
        returnToSafe()
        setLoading(false)
      })
  }

  const backForm = () => {
    dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, showDetailsForm: false } })
    assetMarker.setDraggable(true)
  }

  return {
    handleClickRef,
    handleSubmit,
    forwardForm,
    updateLocationRef,
    canFillForm,
    backForm,
    assetMarker,
    forgetMarker,
    files,
    setFiles,
    showFilePopup,
    setShowFilePopup,
    imported,
    setImported,
  }
}

export const ProvideAddAsset = ({ children }) => {
  const data = useProvideAddAsset()

  return (
    <addAssetContext.Provider value={data}>
      {
        children
      }
    </addAssetContext.Provider>
  )
}
