import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonGrid, IonLabel, IonButton, IonCard, IonCardHeader,
} from '@ionic/react'
import React from 'react'
import PageHeader from '../PageHeader'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 */
const HelpCenter = () => (
  <IonPage>
    <PageHeader title='Help Center' />

    <IonGrid style={{ width: '100%', height: '100%', boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
      <IonRow style={{ width: '100%' }}>

        <IonItem style={{ '--background': '#CFE5E9', boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', width: '100%' }} lines='none'>

          <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
            <IonCol style={{ width: '100%' }} size='12'>

              <IonRow class='ion-justify-content-center'>
                <IonLabel style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', paddingTop: '30px',
                }}
                >
                  Looking for help?
                </IonLabel>
              </IonRow>

              <IonRow class='ion-justify-content-center' style={{ width: '100%' }}>
                <IonLabel style={{
                  width: '100%', fontSize: '16px', textAlign: 'center', padding: '30px',
                }}
                >
                  Choose a section below and we’ll help you find the solution to your problem.
                </IonLabel>
              </IonRow>

              <IonRow justify-content-center class='ion-text-center' style={{ width: '100%' }}>

                <IonItem
                  class='ion-text-center'
                  style={{
                    '--background': '#CFE5E9', width: '100%', align: 'center', display: 'none',
                  }}
                  lines='none'
                >
                  <IonLabel class='ion-text-center' style={{ width: '100%' }}>
                    <input style={{ width: '60%', align: 'center' }} />
                    <IonButton fill='clear'>
                      <Icon icon='icon-park-outline:message-search' color='#326771' size='25' />
                    </IonButton>
                  </IonLabel>
                </IonItem>

              </IonRow>

            </IonCol>
          </IonRow>

        </IonItem>

      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '30%' }}>
        <IonCol>
          <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-helpsoon'>
            <IonCardHeader
              class='ion-text-center'
              style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
              }}
            >Report an Issue
            </IonCardHeader>
            <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }} />
          </IonCard>
        </IonCol>

        <IonCol>
          <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-insights'>
            <IonCardHeader
              class='ion-text-center'
              style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
              }}
            >Insights
            </IonCardHeader>
            <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }} />
          </IonCard>
        </IonCol>

        <IonCol>
          <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-helpsoon'>
            <IonCardHeader
              class='ion-text-center'
              style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
              }}
            >Journeys
            </IonCardHeader>
            <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }} />
          </IonCard>
        </IonCol>

        <IonCol>
          <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-tasks'>
            <IonCardHeader
              class='ion-text-center'
              style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
              }}
            >Tasks
            </IonCardHeader>
            <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }} />
          </IonCard>
        </IonCol>
      </IonRow>

      <IonRow style={{ height: '30%' }}>

        <IonCol>
          <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-helpsoon'>
            <IonCardHeader
              class='ion-text-center'
              style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
              }}
            >Consultant
            </IonCardHeader>
            <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }} />
          </IonCard>
        </IonCol>

        <IonCol>
          <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-helpsoon'>
            <IonCardHeader
              class='ion-text-center'
              style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
              }}
            >Projects
            </IonCardHeader>
            <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }} />
          </IonCard>
        </IonCol>

        <IonCol>
          <IonCard style={{ height: '100%', '--background': 'white' }} routerLink='/helpcenter-helpsoon'>
            <IonCardHeader
              class='ion-text-center'
              style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
              }}
            >Risk Assessment
            </IonCardHeader>
            <IonItem class='ion-text-center' lines='none' style={{ '--background': 'white' }} />
          </IonCard>

        </IonCol>
        <IonCol />
      </IonRow>

    </IonGrid>

  </IonPage>
)

export default HelpCenter
