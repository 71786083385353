import React from 'react'

import { Icon } from '@iconify/react'

import { IonRow, IonCol } from '@ionic/react'
import { PopupContainer } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'

/**
 *  ========================================
 *    LIST OF ASSETS IN THE SAME LOCATION
 *  ========================================
 */
const ColocatedPopup = ({
  assets, setFocussedAsset, popup,
}) => {
  const handleClick = (asset) => {
    popup.remove()
    setFocussedAsset(asset)
  }

  return (
    <PopupContainer>
      <div className='title'>
        <IonRow className='ion-justify-content-between ion-align-items-center'>
          <IonCol size={7}>
            <p style={{ color: 'white', margin: '0', fontSize: '.9rem' }}> Choose Asset </p>
          </IonCol>
          <IonCol size={5}>
            <SimpleButton onClick={popup.remove} style={{ color: 'white', fontSize: '.7rem' }}>Close X</SimpleButton>
          </IonCol>
        </IonRow>
      </div>
      <div className='content'>
        {
          assets.map((asset) => (
            <p key={asset.id} style={{ margin: '5px 0' }}><SimpleButton onClick={() => handleClick(asset)}> View {asset.name} <Icon icon='fe:arrow-right' /></SimpleButton></p>
          ))
        }
      </div>
    </PopupContainer>
  )
}

export default ColocatedPopup
