import React from 'react'
import {
  IonList, IonItem, IonListHeader, IonInput, IonContent,
  IonRow, IonButton,
} from '@ionic/react'
import { FormTitle } from '../../../maps/StyledContainers'

/**
 *  ==============================
 *      PURPOSE AND DETAILS
 *  ==============================
 */
const Purpose = ({
  handleInput, input, header, onCancel, onNext,
}) => (
  <IonContent
    style={{
      '--ion-background-color': 'white',
      '--padding-bottom': '15px',
      '--padding-top': '15px',
      '--padding-end': '15px',
    }}
  >
    {
      header
    }
    <IonList>
      <IonItem>
        <IonInput onIonChange={handleInput} value={input.purpose} name='purpose' type='text' placeholder='Journey Purpose' />
      </IonItem>
      <IonListHeader>
        <FormTitle>
          <h3 style={{ fontSize: '1rem' }}>Journey Leader</h3>
        </FormTitle>
      </IonListHeader>
      <IonItem>
        <IonInput onIonChange={handleInput} value={input.leaderName} name='leaderName' type='text' placeholder='Name' />
      </IonItem>
      <IonItem>
        <IonInput onIonChange={handleInput} value={input.leaderMobile} name='leaderMobile' type='text' placeholder='Mobile' />
      </IonItem>
      <IonListHeader>
        <FormTitle>
          <h3 style={{ fontSize: '1rem' }}>Driver</h3>
        </FormTitle>
      </IonListHeader>
      <IonItem>
        <IonInput onIonChange={handleInput} value={input.driverName} name='driverName' type='text' placeholder='Name' />
      </IonItem>
      <IonItem>
        <IonInput onIonChange={handleInput} value={input.driverMobile} name='driverMobile' type='text' placeholder='Mobile' />
      </IonItem>
    </IonList>
    <IonRow style={{ paddingLeft: '15px', marginTop: '15px' }} className='ion-justify-content-between'>
      <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>Cancel</IonButton>
      <IonButton style={{ '--background': '#326771' }} onClick={onNext}>Next</IonButton>
    </IonRow>
  </IonContent>
)
export default Purpose
