/* eslint-disable import/prefer-default-export */
import { useEffect, useMemo } from 'react'
import { createConsumer } from '@rails/actioncable'

export const useActionCable = (url: string) => {
  const actionCable = useMemo(() => createConsumer(url), [])

  useEffect(() => () => {
    console.log('Disconnect Action Cable')
    actionCable.disconnect()
  }, [])

  return { actionCable }
}
