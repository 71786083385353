import styled from 'styled-components'

const CardContainer = styled.div`
  background: #FFFFFF;
  border-radius: 5px;
  padding: 10px;
  font-size: .8rem;
  margin: 10px;
  cursor: pointer;
`

const OverlayContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  --ion-item-background: white;
  z-index: 2;
  width: 450px;
`

const HighPriorityLabel = styled.p`
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  margin-left: auto;
`

export {
  CardContainer,
  OverlayContainer,
  HighPriorityLabel,
}
