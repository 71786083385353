import * as React from 'react'
import { IonRow, IonSpinner } from '@ionic/react'
import { SherpaButton } from '../GlobalContainers'
import { CenterForm } from '../riskregister/StyledContainers'
import { CountyProperties } from '../types/GeoJSONTypes'
import { Keyphrase, Phrasegroups } from '../types/InsightTypes'
import SuggestedCategory from './SuggestedCategory'

interface AlertStateSuggestions {
  keyphrases: { id: number, count: number }[],
  reports: number,
  suggestions: number[]
}

const SuggestedPopup : React.FC<{
  data: AlertStateSuggestions,
  county: CountyProperties,
  onClose: () => void,
  keyphrases: Keyphrase[],
  categories: Phrasegroups[],
}> = ({
  data, county, onClose, keyphrases, categories,
}) => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [step, setStep] = React.useState<number>(0)
  const [suggestedAS, setSuggestedAs] = React.useState<number>(1)
  const [countyPhrases, setCountyPhrases] = React.useState<Keyphrase[]>([])
  const [reportCategories, setReportCategories] = React.useState<Phrasegroups[]>([])

  React.useEffect(() => {
    const reportedCategories = []
    // TODO cross-reference keyphrase objects with ids
    const processed: Keyphrase[] = data.keyphrases.map(({ id, count }) => {
      const phrase : Keyphrase = keyphrases[id - 1]
      if (reportedCategories.indexOf(phrase.phrasegroups_id) < 0) { reportedCategories.push(phrase.phrasegroups_id) }
      return { ...phrase, count }
    })
    const filteredCategories = categories.filter(({ id }) => reportedCategories.indexOf(id) >= 0)
    setReportCategories(filteredCategories)
    setCountyPhrases(processed)
    const avg = Math.ceil(data.suggestions.reduce((acc, val) => acc + val, 0) / data.suggestions.length)
    setSuggestedAs(avg)

    setLoading(false)
  }, [])
  return (
    <CenterForm style={{
      padding: '25px', width: '40%', zIndex: '22', height: '70%',
    }}
    >
      <div className='risk-assessment-list'>
        {
          loading ? (
            <>
              <div>
                <IonRow className='ion-justify-content-between ion-align-items-center'>
                  <h4 className='red-font-color' style={{ margin: '0' }}>{ county.shapeName } - Overview</h4>
                  <SherpaButton style={{ fontSize: '1rem' }} onClick={onClose}>Close X</SherpaButton>
                </IonRow>
              </div>
              <IonRow className='ion-justify-content-center ion-align-items-center'>
                <IonSpinner color='light' />
              </IonRow>
            </>
          ) : (
            <SuggestedCategory
              category={reportCategories[step]}
              first={step === 0}
              last={step === (reportCategories.length - 1)}
              keyphrases={countyPhrases}
              next={() => setStep(step + 1)}
              prev={() => setStep(step - 1)}
              reports={data.reports}
              suggestedAS={suggestedAS}
              county={county}
              onClose={onClose}
            />
          )
        }
      </div>
    </CenterForm>
  )
}

export default SuggestedPopup
