import React, { useEffect } from 'react'
import Select from 'react-select'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, SelectionDetail } from '../../StyledContainers'
import { threatLikelihood } from '../FormOptions'

/**
 *  =============================
 *      INHERENT VULNERABILITY
 *  =============================
 */
const InherentVulnerability = ({
  children, input, edit, vulnerability, setInput, onClose, next, getNumericalValue, getCategory,
}) => {
  const handleChange = (selected) => {
    const inherentThreatLikelihood = Math.ceil((getNumericalValue(input.inherentThreatRating) * getNumericalValue(selected.value)) / 5)
    setInput({
      ...input, inherentVulnerability: selected, edit: true, inherentThreatLikelihood,
    })
  }

  useEffect(() => {
    if (input.inherentThreatRating && input.inherentVulnerability) {
      const inherentThreatLikelihood = Math.ceil((getNumericalValue(input.inherentThreatRating) * getNumericalValue(input.inherentVulnerability.value)) / 5)
      setInput({ ...input, inherentThreatLikelihood })
    }
  }, [])

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px', height: '100%' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Inherent Risk - Vulnerability Assessment</h5>
              {
                (edit) ? (
                  <>
                    <p>This is how Vulnerability was scored in the previous assessment. You can adjust it accordingly as part of this review.</p>
                  </>
                ) : (
                  <>
                    <p>Assessing how vulnerable an asset is to threat will determine the Threat Likelihood. Use the drop-down below to assess this asset’s vulnerability to those who pose a threat.</p>
                  </>
                )
              }
            </SettingField>
            <IonRow>
              <IonCol size={4}>
                <SettingField>
                  <p>Select how vulnerable this asset is to the selected Risk Event.</p>
                  <h5>Vulnerability</h5>
                  <Select
                    placeholder='Select Vulnerability'
                    id='ra-inherent-vulnerability'
                    name='intent'
                    className='select-container'
                    value={input.inherentVulnerability}
                      // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={Object.keys(vulnerability).map((level) => ({ label: level, value: level }))}
                    onChange={handleChange}
                  />
                </SettingField>
              </IonCol>
              {
                input.inherentVulnerability && (
                  <IonCol size={8}>
                    <SelectionDetail>
                      <p>{ vulnerability[input.inherentVulnerability.value] }</p>
                    </SelectionDetail>
                  </IonCol>
                )
              }
            </IonRow>
            <IonRow>
              <IonCol size={4}>
                <SettingField style={{ width: 'auto' }}>
                  <h5>Likelihood Rating</h5>
                  {
                    (input.inherentThreatLikelihood) && (
                      <p id='inherent-vulnerability'> { getCategory(input.inherentThreatLikelihood) } </p>
                    )
                  }
                </SettingField>
              </IonCol>
              {
                input.inherentThreatLikelihood && (
                  <IonCol size={8}>
                    <SelectionDetail>
                      <p>{ threatLikelihood[input.inherentThreatLikelihood] }</p>
                    </SelectionDetail>
                  </IonCol>
                )
              }
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next} disabled={!input.inherentVulnerability}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default InherentVulnerability
