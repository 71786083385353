import React, { useState } from 'react'
import {
  IonButton, IonCol, IonGrid, IonRow, IonCheckbox, IonLabel,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import {
  faFilter, faExchangeAlt, faArchive,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Dropdown } from 'react-bootstrap'

import { SimpleButton } from '../maps/StyledContainers'
import { DashboardButton, ObscureBackground } from '../GlobalContainers'

/**
 *  ==================================
 *    CONTROLS FOR THE KANBAN BOARD
 *  ==================================
 *
 *  Controls are going to be different for lists
 */

const KanbanControls = ({
  setShowAddTask, setVisibleTasks, visibleTasks,
  showArchived, filterTasks, filters, listView, setListView,
  setFilters, setFocussedTask, showHideArchived, sortPriority, loading,
  editing, setEditing,
}) => {
  const [toggleAll, setToggleAll] = useState(Object.values(filters).findIndex((param) => !param) < 0)
  const [show, setShow] = useState(false)

  const handleToggleAll = (e) => {
    setToggleAll(e.target.checked)

    const newFilters = Object.fromEntries(Object.keys(filters).map((key) => [key, e.target.checked]))
    filterTasks(newFilters)
    setFilters(newFilters)
  }

  const handleFilters = (e) => {
    if (!e.target.checked) setToggleAll(false)

    /* Updated filters */
    const newFilters = { ...filters, [e.target.name]: e.target.checked }
    filterTasks(newFilters)
    setFilters(newFilters)
    /* If all checkboxes are clicked then update toggle all */
    if (Object.values(newFilters).findIndex((param) => !param) < 0) setToggleAll(true)
  }

  const onToggleHandler = (isOpen, metadata) => {
    if (metadata.source !== 'select') {
      setShow(isOpen)
    }
  }

  /* Sort Tasks using Boostrap's dropdown */
  const handleSorting = (evtKey) => {
    let compare

    if (evtKey === '1') { compare = (task, task2) => (new Date(task.due) - new Date(task2.due)) } else { compare = (task, task2) => (new Date(task2.due) - new Date(task.due)) }

    const sorted = {
      'To-Do': visibleTasks['To-Do'].sort(compare).sort(sortPriority),
      'In-Progress': visibleTasks['In-Progress'].sort(compare).sort(sortPriority),
      Complete: visibleTasks.Complete.sort(compare).sort(sortPriority),
    }

    setVisibleTasks(sorted)
  }

  const handleArchived = () => {
    showHideArchived(!showArchived)
    setToggleAll(true)
  }

  return (
    <IonGrid style={{
      backgroundColor: '#FFFFFF', marginBottom: '10px', boxShadow: '0px 4px 4px 0px #00000040', position: 'relative',
    }}
    >
      {
        loading && (
          <ObscureBackground />
        )
      }
      <IonRow className='ion-align-items-center'>
        <IonCol size='4'>
          <SimpleButton
            id='switch-views-button'
            onClick={() => {
              setListView(!listView)
              setEditing(false)
            }}
            style={{
              display: 'flex', alignItems: 'center', fontSize: '1.4rem', '--ion-color-primary': '#4197A9',
            }}
          >
            {
              (!listView) ? (
                <>
                  <Icon style={{ margin: '0 5px' }} icon='dashicons:grid-view' />
                  Board
                </>
              ) : (
                <>
                  <Icon style={{ margin: '0 5px' }} icon='ant-design:unordered-list-outlined' />
                  List View
                </>
              )
              }
          </SimpleButton>
        </IonCol>
        <IonCol size='8' style={{ textAlign: 'right' }}>
          <IonRow className='ion-align-items-center ion-justify-content-end'>
            <Dropdown onSelect={handleSorting} style={{ margin: '0 5px' }} align='end'>
              <Dropdown.Toggle
                style={{ backgroundColor: '#326771' }}
                className='map-button remove-arrow'
              > Sort by <FontAwesomeIcon style={{ transform: 'rotate(90deg)' }} icon={faExchangeAlt} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey='1'>
                  Date Due - Ascending
                </Dropdown.Item>
                <Dropdown.Item eventKey='2'>
                  Date Due - Descending
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown style={{ margin: '0 5px' }} align='end' show={show} onToggle={(isOpen, metadata) => onToggleHandler(isOpen, metadata)}>
              <Dropdown.Toggle
                id='task-controls-filter'
                style={{ backgroundColor: '#326771' }}
                className='map-button remove-arrow'
                onClick={() => setShow(!show)}
              > Filter <FontAwesomeIcon icon={faFilter} />
              </Dropdown.Toggle>
              <Dropdown.Menu className='dropdown-menu-end'>
                <Dropdown.Item eventKey='1' className='task-filter-option'>
                  <IonLabel> Toggle all on/of </IonLabel>
                  <IonCheckbox id='toggle-all-tasks' name='toggleAll' onClick={handleToggleAll} checked={toggleAll} color='primary' />
                </Dropdown.Item>
                <Dropdown.Item className='task-filter-option'>
                  <IonLabel>My Tasks</IonLabel>
                  <IonCheckbox id='toggle-my-tasks' name='myTasks' onClick={handleFilters} checked={filters.myTasks} color='primary' />
                </Dropdown.Item>
                <Dropdown.Item className='task-filter-option'>
                  <IonLabel>Tasks I assigned</IonLabel>
                  <IonCheckbox id='toggle-assigned-tasks' name='assigned' onClick={handleFilters} checked={filters.assigned} color='primary' />
                </Dropdown.Item>
                <Dropdown.Item className='task-filter-option'>
                  <IonLabel>Other</IonLabel>
                  <IonCheckbox id='toggle-other-tasks' name='other' onClick={handleFilters} checked={filters.other} color='primary' />
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className='task-filter-option'>
                  <IonLabel>Insights</IonLabel>
                  <IonCheckbox id='toggle-insights-tasks' name='Insight' onClick={handleFilters} checked={filters.Insight} color='primary' />
                </Dropdown.Item>
                <Dropdown.Item className='task-filter-option'>
                  <IonLabel>Journeys</IonLabel>
                  <IonCheckbox id='toggle-journeys-tasks' name='Journey' onClick={handleFilters} checked={filters.Journey} color='primary' />
                </Dropdown.Item>
                <Dropdown.Item className='task-filter-option'>
                  <IonLabel>Projects</IonLabel>
                  <IonCheckbox id='toggle-projects-tasks' name='Project' onClick={handleFilters} checked={filters.Project} color='primary' />
                </Dropdown.Item>
                <Dropdown.Item className='task-filter-option'>
                  <IonLabel>Misc.</IonLabel>
                  <IonCheckbox id='toggle-miscelaneous-tasks' name='Misc' onClick={handleFilters} checked={filters.Misc} color='primary' />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown align='end' style={{ margin: '0 5px' }} onSelect={handleArchived}>
              <Dropdown.Toggle style={{ backgroundColor: '#326771' }} className='map-button remove-arrow'>More ...</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  {
                    showArchived && (
                      <>
                        Current items
                      </>
                    )
                  }
                  {
                    !showArchived && (
                      <>
                        <FontAwesomeIcon icon={faArchive} /> Archived items
                      </>
                    )
                  }
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <DashboardButton>
              <IonButton
                onClick={() => {
                  showHideArchived(false)
                  setShowAddTask(true)
                  setListView(false)
                  setFocussedTask(false)
                }}
                id='add-task-button'
                style={{ '--ion-color-primary': '#0C9500' }}
              >Add Task
              </IonButton>
            </DashboardButton>
            {
              listView && (
                <IonButton
                  style={{ '--ion-color-primary': '#326771' }}
                  onClick={() => setEditing(!editing)}
                >
                  {(editing) ? 'Done' : 'Edit'}
                </IonButton>
              )
            }
          </IonRow>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default KanbanControls
