/* eslint-disable no-trailing-spaces */
import { Icon } from '@iconify/react'
import {
  IonPage, IonCol, IonRow, IonItem, IonLabel, IonCard, IonCardHeader, IonText, IonCardTitle, IonImg,
} from '@ionic/react'
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from '../../PageHeader'

import img1 from './TaskAdd1.jpg'
import img2 from './TaskAdd2.jpg'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 *
 *
 */

const TasksAdd = () => {
  const history = useHistory()

  const handleBack = () => {
    history.push('/helpcenter-tasks')
  }

  return (
    <IonPage>
      <PageHeader title='Help Center' />
      <IonRow style={{ width: '100%' }}>
        <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
          <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

            <IonRow class='ion-justify-content-start'>
              <IonLabel
                onClick={handleBack}
                style={{
                  color: '#8E151F', fontSize: '13px', fontWeight: 'bold', textAlign: 'center', paddingTop: '20px', paddingBottom: '10px', paddingLeft: '20px',
                }}
              >
                <Icon icon='eva:arrow-ios-back-fill' color='#8e151f' width='26' height='26' routerLink='/helpcenter-insights' onClick={handleBack} />Tasks Help
              </IonLabel>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonRow>

      <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)', height: '100%', width: '100%' }}>
        <div style={{ overflowY: 'auto', width: '75%', height: '81%' }}>
          <IonCol style={{ width: '100%' }}>
            <IonItem
              style={{
                paddingLeft: '40px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Adding a Task
              </IonLabel>
            </IonItem>
            <IonItem
              style={{
                paddingLeft: '40px', paddingTop: '30px', paddingRight: '40px', width: '100%', '--background': 'white',
              }}
              lines='none'
            >
              <IonRow>
                <IonText>Step1.</IonText>
                <IonText>To add a task, click on the green ‘Add Task’ icon in the top right-hand side of the window.</IonText>
                <IonImg src={img1} />
                <IonText><br />Step2.<br />This will then bring up the following box:</IonText>
                <IonImg src={img2} />
                <IonText><br />Fill in the relevant information in order to submit the task to the ‘To-Do’ list:</IonText>
                <IonText><br />   -      Give your task a suitable title and select one or multiple assignees from your workspace. The list of assignees 
                  will be specific to the workspace through which you opened the App, so if you have multiple workspaces make sure you are setting the task for the correct one.
                </IonText>
                <IonText><br />   -      Select the ‘High Priority’ box if you want the task to appear at the top of the ‘to-do’ list.</IonText>
                <IonText><br />   -      If it is a recurring task, select how often the task needs to be done from the ‘Repeat’ options (e.g., daily, weekly etc.).</IonText>

                <IonText><br /><br />
                  Step 3.<br />Once all the information has been filled in for the task, click on the green ‘Submit’ button in the 
                  bottom right-hand corner. Your task will appear in the ‘To-Do’ column in the Tasks App.
                </IonText>

              </IonRow>
            </IonItem>
          </IonCol>
        </div>

        <IonCol style={{ width: '25%', borderLeft: '1px solid' }}>

          <IonRow>
            <IonItem style={{ width: '100%', '--background': 'white' }} lines='none'>
              <IonLabel style={{
                color: '#8E151F', fontSize: '16px', fontWeight: 'bold',
              }}
              >
                Related Articles
              </IonLabel>
            </IonItem>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-use'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Using the Task App
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-edit'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Editing/Deleting a Task
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

          <IonRow>
            <IonCard
              style={{
                width: '100%', '--background': 'white', border: '1px solid #747474', boxShadow: '0px 0px 0px -0px rgb(0 0 0 / 0%)',
              }}
              routerLink='/helpcenter-tasks-sort'
            >
              <IonCardHeader>
                <IonCardTitle style={{ fontSize: '16px' }}>
                  Sorting Tasks
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonRow>

        </IonCol>
      </IonRow>

    </IonPage>
  )
}

export default TasksAdd
