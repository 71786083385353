import { IonButton, IonCol, IonRow } from '@ionic/react'
import React, { FC, useState } from 'react'
import { Icon } from '@iconify/react'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { SimpleButton } from '../../maps/StyledContainers'
import { OverlayContainer } from '../../messaging/StyledContainers'
import { Stakeholder } from '../../types/OptimizedMaps'
import { NoBackgroundButton } from '../StyledContainers'
import { StakeholderIcon } from '../../../helpers/UserIcon'

interface Props {
  onSelect: (stakeholder: Stakeholder, isSMS: boolean) => void,
  onClose: () => void,
}

const AddChat : FC<Props> = ({ onClose, onSelect }) => {
  const [searchText, setSearchText] = useState<string>('')
  const { stakeholders } = useEngagementData()
  const [isSMS, setIsSMS] = useState<boolean>(true)

  const getStakeholderPairs = (): [Stakeholder, Stakeholder][] => stakeholders.reduce((acc, curr, i) => {
    if (!(i % 3)) { // if index is 0 or can be divided by the `size`...
      acc.push(stakeholders.slice(i, i + 3)) // ..push a chunk of the original array to the accumulator
    }
    return acc
  }, [])

  return (
    <OverlayContainer style={{ zIndex: '25' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow>
          <SimpleButton onClick={onClose}>Cancel</SimpleButton>
        </IonRow>
        <h5 style={{
          fontSize: '1.1rem', color: '#326771', fontWeight: 'bold', textAlign: 'center',
        }}
        >New Message
        </h5>
        <input
          value={searchText}
          type='text'
          onChange={(e) => setSearchText(e.target.value)}
          style={{
            width: '100%',
            border: '1px solid black',
            borderRadius: '2.5px',
            padding: '5px',
            margin: '10px 0',
          }}
        />
        <IonRow style={{ margin: '10px 0' }} className='ion-align-items-center'>
          <h6 style={{
            fontSize: '0.9rem', fontWeight: 'bold', color: '#326771', margin: '0',
          }}
          >Stakeholders
          </h6>
          <div style={{ flex: 1, padding: '1px', backgroundColor: '#326771' }} />
        </IonRow>
        <IonRow>
          <IonButton onClick={() => setIsSMS(!isSMS)} style={{ '--background': '#326771', marginBottom: '20px' }}>
            {
              isSMS ? (
                <p style={{ margin: '0' }}>SMS <Icon icon='fa-solid:sms' /> </p>
              ) : (
                <p style={{ margin: '0' }}>WhatsApp <Icon icon='ic:baseline-whatsapp' /> </p>
              )
            }
          </IonButton>
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            getStakeholderPairs().map(([stakeholderA, stakeholderB]) => (
              <IonRow key={stakeholderA.id} className='ion-align-items-center ion-justity-content-center'>
                <IonCol size='6'>
                  <NoBackgroundButton onClick={() => onSelect(stakeholderA, isSMS)}>
                    <StakeholderIcon {...stakeholderA} />
                    <p>{ stakeholderA.name }</p>
                  </NoBackgroundButton>
                </IonCol>
                {
                  stakeholderB && (
                    <IonCol size='6'>
                      <NoBackgroundButton onClick={() => onSelect(stakeholderB, isSMS)}>
                        <StakeholderIcon {...stakeholderB} />
                        <p>{ stakeholderB.name }</p>
                      </NoBackgroundButton>
                    </IonCol>
                  )
                }
              </IonRow>
            ))
          }
        </div>
      </div>
    </OverlayContainer>
  )
}

export default AddChat
