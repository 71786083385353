/* eslint-disable camelcase */
import React, {
  useState, useContext, createContext,
} from 'react'
import axios from '../utils/axios'
import { useActionCable } from './channels/useActionCable'
import { getWebSocketURL } from '../channels/consumer'
import { useChannel } from './channels/useChannel'
import { useFSitRepPopup } from './safety-checks/useFSitRepPopup'

export const notificationContext = createContext()
export const useNotification = () => useContext(notificationContext)

const useProvideNotification = () => {
  const [notifications, setNotifications] = useState([])
  const [notifCount, setNotifCount] = useState(0)
  const { actionCable } = useActionCable(getWebSocketURL())
  const [displayFSitrep] = useFSitRepPopup()
  const { subscribe } = useChannel(actionCable)

  const fetchNotifications = async (user, displayPopup) => {
    let firstFetch = false

    subscribe({ channel: 'NotificationsChannel', user_id: user }, {
      received: (data) => {
        if (firstFetch) {
          if (data.notifications.length > 0) {
            const newestNoti = data.notifications[data.notifications.length - 1]
            if (newestNoti.title === 'Safety' && !newestNoti.read && displayPopup) {
              displayPopup(newestNoti.id)
            }

            if (newestNoti.title === 'Field SitRep Request' && !newestNoti.read) {
              displayFSitrep(newestNoti)
            }
          }
        } else {
          const sortedNotifList = data.notifications.sort((a, b) => b.id - a.id)
          const firstSC = sortedNotifList.find((noti) => noti.title === 'Safety')
          if (firstSC && !firstSC.read && displayPopup) {
            displayPopup(firstSC.id)
          }

          const firstFS = sortedNotifList.find((noti) => noti.title === 'Field SitRep Request')
          if (firstFS && !firstFS.read) {
            displayFSitrep(firstFS)
          }
        }
        setNotifications(data.notifications)
        /* Check if a security condiiton notificaiton has been embedded in the URL */
        // if (notifId && displaySecPopup) {
        // displaySecPopup(notifId)
        // }
        countNotifications(user)
        firstFetch = true
      },
    })
  }
  const createNotification = (user_id, content, recipient, type) => axios.post('create_notification', {
    user_id, content, recipient, type,
  })
    .then((response) => Promise.resolve(response.data))
    .catch(() => {
    })

  const markNotificationAsRead = (notification_id, source_clicked) => axios.post('read_notification', {
    notification_id, source_clicked,
  })
    .then((response) => Promise.resolve(response.data))
    .catch(() => {
    })

  const markAllNotificationAsRead = (user_id) => {
    const notiArray = notifications.map((notification) => ({ ...notification, read: true }))
    setNotifications(notiArray)
    axios.post('read_all_notification', {
      user_id,
    })
      .then((response) => Promise.resolve(response.data))
      .catch(() => {
      })
  }

  const deleteNotification = (notification_id) => axios.post('delete_notification', {
    notification_id,
  })
    .then((response) => Promise.resolve(response.data))
    .catch(() => {
    })

  const deleteAllNotifications = (user_id) => axios.post('delete_all_notification', {
    user_id,
  })
    .then((response) => Promise.resolve(response.data))
    .catch(() => {
    })

  const thumbsUp = (noti_id) => axios.post('reactToNotification', {
    noti_id,
  })
    .then((response) => Promise.resolve(response.data))
    .catch(() => {
    })

  const thumbsDown = (noti_id) => axios.post('deleteReactionFromFeedPost', {
    noti_id,
  })
    .then((response) => Promise.resolve(response.data))
    .catch(() => {
    })

  const getAllNotifications = () => axios.get('get_all_notifications', {
  })
    .then((response) => Promise.resolve(response.data))
    .catch(() => {
    })

  const countNotifications = (user_id) => {
    axios.post('count_notifications', { user_id })
      .then(({ data }) => {
        setNotifCount(data.noti_count)
      })
  }

  return {
    notifications,
    notifCount,
    fetchNotifications,
    countNotifications,
    createNotification,
    markNotificationAsRead,
    markAllNotificationAsRead,
    deleteNotification,
    deleteAllNotifications,
    thumbsUp,
    thumbsDown,
    getAllNotifications,
  }
}

export const ProvideNotification = ({ children }) => {
  const notification = useProvideNotification()

  return (
    <notificationContext.Provider value={notification}>
      {children}
    </notificationContext.Provider>
  )
}
