/* eslint-disable import/prefer-default-export */
import * as moment from 'moment'
import * as turf from '@turf/turf'
import { IncidentFilters } from './FilterReducers'
import {
  County, SafetyStatus, SafetyUser, User,
} from '../../../components/types/GlobalTypes'
import { incidentCategories } from '../../../components/maps/apps/incident_types.json'
import { BackendAsset } from '../../risks/types/BackendData'
import { Incident } from '../../../components/types/OptimizedMaps'

/* ================== KEY ASSET ICONS ===================== */
import Operations from '../../../components/riskregister/icon-vectors/operations.png'
import People from '../../../components/riskregister/icon-vectors/people.png'
import Facilities from '../../../components/riskregister/icon-vectors/facilities.png'
import Equipment from '../../../components/riskregister/icon-vectors/equipment.png'
import Product from '../../../components/riskregister/icon-vectors/product.png'
import Reputation from '../../../components/riskregister/icon-vectors/reputation.png'
import Project from '../../../components/riskregister/icon-vectors/project.png'
import Default from '../../../components/riskregister/icons/pin.png'
import { Asset } from '../../../components/riskregister/types/Assets'

/* ================== INCIDENT ICONS ======================= */
import violentConflict from '../../../components/maps/incidents/icons/violent_conflict.png'
import violentCrime from '../../../components/maps/incidents/icons/violent_crime.png'
import criminality from '../../../components/maps/incidents/icons/criminality.png'
import socialUnrest from '../../../components/maps/incidents/icons/social_unrest.png'
import travel from '../../../components/maps/incidents/icons/travel.png'
import { BackendRoute, SafetyCheck } from '../types/HookInterface'
import { CountiesGeoJSON } from '../../../components/types/GeoJSONTypes'
import { createCountyMap } from '../../risks/helpers/ProcessBackendData'

/**
 * Filter all Incidents by time and category
 *
 * @param filters -> Updated Incident filter object
 * @param incidents -> List of all incidents
 * @param time -> Time defined in days
 * @returns -> A list of filtered incidents by time and category
 */
export const filterIncidents = (filters: IncidentFilters, incidents: Incident[], time: number) => {
  const filteredIncidents = (Number(time) === 0) ? [...incidents] : [...incidents].filter(({ reported }) => moment(reported).isSameOrAfter(moment().subtract(time, 'days').format()))
  /* Incidents to show in the map */
  const selectedFilters = Object.entries(filters.filters).reduce((memo, [k, v]) => (v ? [...memo, k] : memo), [])
  const filterdIncidentCategories = Object.entries(incidentCategories).reduce((m, [k, v]) => (selectedFilters.includes(k) ? [...m, ...v] : m), [])
  return filteredIncidents.filter((i) => (filterdIncidentCategories.includes(i.incident_type)))
}

export const getAssetIcon = (type: string) : string => ({
  Operations,
  People,
  Facilities,
  Equipment,
  Product,
  Reputation,
  Project,
  Default,
}[type])

export const getIncidentIcon = (type: string) : string => ({
  'Violent Conflict': violentConflict,
  'Violent Crime': violentCrime,
  Criminality: criminality,
  'Social Unrest': socialUnrest,
  Travel: travel,
  Other: violentConflict,
}[type])

/**
 * Process backend assets to be used in the frontend
 *
 * @param assets -> Backend formatted assets
 * @return List of frontend formatted assets
 */
export const processBackendAssets = (assets: BackendAsset[], countyMap: { [countyId: number] : County }) : turf.FeatureCollection<turf.Point, Asset> => {
  const formattedAsset : Asset[] = assets.map((val : BackendAsset) => ({
    id: val.id,
    name: val.name,
    type: val.asset_type,
    coordinates: {
      lat: val.latitude,
      lng: val.longitude,
    },
    location: {
      country: 'South Sudan',
      state: (countyMap[val.county_id]) ? countyMap[val.county_id].shapeName : 'Unknown',
      county: (countyMap[val.county_id]) ? countyMap[val.county_id].shapeName : 'Unknown',
    },
    county_id: val.county_id,
    highestRisk: 'Unknown',
    updated_at: moment(val.updated_at).format('DD/MM/YY'),
  }))
  return {
    type: 'FeatureCollection',
    features: formattedAsset.map((val) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          val.coordinates.lng, val.coordinates.lat,
        ],
      },
      properties: {
        ...val,
      },
    })),
  }
}

export const getRouteColor = (routeType: string) => ({
  Road: '#13D132',
  'Off-Road': '#FF8A00',
  Water: '#F85353',
}[routeType])

/**
 * Get GeoJSON object for routes
 *
 * @param routes -> Routes fetched directly from the backend
 * @returns A feature collection geojson object
 */
export const processRoutes = (routes: BackendRoute[]) : turf.FeatureCollection<turf.LineString> => ({
  type: 'FeatureCollection',
  features: routes.map((val) => ({
    ...JSON.parse(val.geoData),
    properties: {
      id: val.id, name: val.name, color: getRouteColor(val.route_type), type: val.route_type,
    },
  })),
})

/**
 * Format backend data into safety checks data for frontend
 *
 * @param arcUsers -> Archangel user objects
 * @param safetyChecks -> Safety checks of the users in the current workspace
 * @param counties -> GeoJSON object referring to counties
 */
export const processWorkspaceUsers = (arcUsers: User[], workspaceUsers: number[], safetyChecks: SafetyCheck[], counties: CountiesGeoJSON) : SafetyUser[] => {
  /* Turn geojson data into hashmap for faster retrieval */
  const countyMap = createCountyMap(counties)
  const notNullSafeties = safetyChecks.filter((val) => val)

  /* User objects limited to only those that are in the current domain */
  const filteredUsers = arcUsers.filter(({ id }) => workspaceUsers.indexOf(id) >= 0)
  const safetyChecksFormatted : SafetyUser[] = filteredUsers.map((user) => {
    const safety = notNullSafeties.find(({ user_id }) => user_id === user.id)
    if (safety && safety.county_id) {
      return {
        ...user, latitude: safety.latitude, longitude: safety.longitude, status: safety.level, county: countyMap[safety.county_id].shapeName, lastUpdated: safety.created_at,
      }
    }
    return {
      ...user,
      latitude: (safety) ? safety.latitude : undefined,
      longitude: (safety) ? safety.longitude : undefined,
      status: (safety) ? safety.level : SafetyStatus.NOT_SUBMITTED,
      county: 'Unknown',
      lastUpdated: (safety) ? safety.created_at : 'N/A',
    }
  })
  return safetyChecksFormatted
}

/*  */
export const getMapSafeties = (safeties: SafetyUser[]) : turf.FeatureCollection => ({
  type: 'FeatureCollection',
  features: safeties.filter((val) => val.latitude !== undefined && val.longitude !== undefined).map((val) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [
        val.longitude, val.latitude,
      ],
    },
    properties: {
      ...val,
    },
  })),
})
