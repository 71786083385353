import { IonButton, IonCol, IonRow, useIonAlert } from '@ionic/react'
import moment from 'moment'
import React, { FC, useState, useEffect } from 'react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend,
} from 'recharts'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { TabOption } from '../../incident-management/StyledContainers'
import LoadingModal from '../../modals/LoadingModal'
import { PopoverContainer, PopoverField } from '../../terrain-mapping/StyledContainer'
import { CampaignStatus, CampaignType, EngagementCampaign } from '../../types/OptimizedMaps'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'
import useApi from '../../../hooks/testHooks'

interface Props {
  campaign: EngagementCampaign,
  onBack: () => void,
}

export enum TabView {
  DETAILS = 'details',
  GRAPHS = 'graphs',
}

interface CampaignStats {
  message: string;
  data: { name: string; value: number }[];
  total: number;
  responses: number;
}

const CampaignScreen : FC<Props> = ({ campaign, onBack }) => {
  const [currentView, setCurrentView] = useState<TabView>(TabView.DETAILS)
  const {
    deleteCampaign, loading, setLoading, setSubmittedMessage,
  } = useEngagementData()
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()
  const apiHook = useApi()
  const [campaignStats, setCampaignStats] = useState<CampaignStats | null>(null);

  useEffect(() => {
    apiHook.getCampaignStats({
      domain_id: workspace.id,
      campaign_id: campaign.id,
    })
      .then((data) => {
        setCampaignStats(data)
      })
  }, [])

  const data = [
    { name: 'Jan', value: 100 },
    { name: 'Feb', value: 200 },
    { name: 'Mar', value: 150 },
    { name: 'Apr', value: 300 },
  ]

  const handleDelete = () => {
    const handler = () => {
      setLoading(true)
      axios.put('/api/v2/campaign/archive_campaign', {
        domain_id: workspace.id,
        campaign_id: campaign.id,
      }).then(({ data }) => {
        if (!data.ok) {
          ionAlert({
            header: 'Server Error',
            message: data.message,
            buttons: [{ text: 'Ok' }],
          })
          return
        }

        deleteCampaign(campaign)
        onBack()
        setSubmittedMessage('Campaign deleted successfully')
      }).catch(() => {
        ionAlert({
          header: 'Server Error',
          message: 'Unknown server error',
          buttons: [{ text: 'Ok' }],
        })
      }).finally(() => {
        setLoading(false)
      })
    }

    ionAlert({
      header: 'Delete Campaign',
      message: 'Are you sure you want to delete this campaign?',
      buttons: [{ text: 'No' }, { text: 'Yes', handler }],
    })
  }
  return (
    <>
      <LoadingModal isOpen={loading} />
      <IonRow style={{ maxheight: '10%', flex: '0 1 auto', width: '100%' }} className='ion-justify-content-between'>
        <IonRow>
          <TabOption
            style={{ marginLeft: '0px' }}
            onClick={() => setCurrentView(TabView.DETAILS)}
            className={(currentView === TabView.DETAILS) ? 'selected' : ''}
          > Details
          </TabOption>
          {
            (campaign.status === 'complete' && campaign.type === 'survey') && (
              <TabOption
                onClick={() => setCurrentView(TabView.GRAPHS)}
                className={(currentView === TabView.GRAPHS) ? 'selected' : ''}
              > Graphs
              </TabOption>
            )
          }
        </IonRow>
        <TabOption style={{ backgroundColor: '#326771', color: 'white' }} onClick={onBack}>
          Back to Campaign Board
        </TabOption>
      </IonRow>
      {
        currentView === TabView.DETAILS && (
          <CampaignDetails
            campaign={campaign}
            onDelete={handleDelete}
          />
        )
      }
      {
        currentView === TabView.GRAPHS && (
          <PopoverContainer style={{ alignItems: 'center' }}>
            <div className="ion-align-items-center red-font text-center">
              <h4 style={{ color: '#8E151F', fontWeight: 'bold' }}>Engagement Campaign Performance</h4>
            </div>
            <div style={{ alignItems: 'center' }}>
              <IonRow>
                <IonCol style={{ width: '40%' }}>
                  <IonRow>
                    <PopoverField style={{ flex: 1 }}>
                      <h6 style={{ marginTop: '1em' }}>Campaign Survey</h6>
                      <p>{ campaign.message }</p>
                      {campaign.option_1 && (
                        <>
                          <h6 style={{ marginTop: '1em' }}>Option 1</h6>
                          <p>{campaign.option_1}</p>
                        </>
                      )}
                      {campaign.option_2 && (
                        <>
                          <h6 style={{ marginTop: '1em' }}>Option 2</h6>
                          <p>{campaign.option_2}</p>
                        </>
                      )}
                      {campaign.option_3 && (
                        <>
                          <h6 style={{ marginTop: '1em' }}>Option 3</h6>
                          <p>{campaign.option_3}</p>
                        </>
                      )}
                      {campaign.option_4 && (
                        <>
                          <h6 style={{ marginTop: '1em' }}>Option 4</h6>
                          <p>{campaign.option_4}</p>
                        </>
                      )}
                      <h6 style={{ marginTop: '1em' }}>Surveys Sent Out</h6>
                      <p>{campaignStats.total}</p>
                      <h6 style={{ marginTop: '1em' }}>Total Responses</h6>
                      <p>{campaignStats.responses}</p>
                    </PopoverField>
                  </IonRow>
                </IonCol>
                <IonCol style={{ marginTop: '4em' }}>
                  <IonRow className='ion-align-items-center'>
                    <BarChart width={600} height={340} data={campaignStats.data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="responses" fill="#8884d8" />
                    </BarChart>
                  </IonRow>
                </IonCol>
              </IonRow>
            </div>
          </PopoverContainer>

        )
      }
    </>
  )
}
interface DetailProps {
  campaign: EngagementCampaign,
  onDelete: () => void,
}
const CampaignDetails : FC<DetailProps> = ({ campaign, onDelete }) => (
  <div
    style={{
      overflow: 'hidden', flex: 1, minHeight: 'auto', background: 'white',
    }}
    className='risk-assessment-list'
  >
    <IonRow className='ion-justify-content-end'>
      <IonButton onClick={onDelete} style={{ '--background': '#8E151F' }}>Delete</IonButton>
    </IonRow>
    <PopoverContainer>
      <IonRow className='ion-align-items-center'>
        <PopoverField style={{ flex: 1 }}>
          <h6>Campaign Name</h6>
          <p>{ campaign.title }</p>
        </PopoverField>
        <PopoverField style={{ flex: 1 }}>
          <h6>Type</h6>
          <p>{ campaign.type }</p>
        </PopoverField>
        <PopoverField style={{ flex: 1 }}>
          <h6>Status</h6>
          <p style={{ fontWeight: 'bold', textTransform: 'capitalize', color: (campaign.status === CampaignStatus.SCHEDULED) ? '#FF9900' : '#0C9500' }}>{ campaign.status }</p>
        </PopoverField>
      </IonRow>
      <PopoverField>
        <h6>Launch Date</h6>
        <p>{ moment(campaign.launch_date).format('DD/MM/YY') }</p>
      </PopoverField>
      <PopoverField>
        <h6>Recipient Lists</h6>
        <ul>
          {
            campaign.recipient_lists.map(({ id, name, stakeholders }) => (
              <li style={{ margin: '20px 0' }} key={id}>
                <h6>{ name }</h6>
                <p>Stakeholders:</p>
                <ul>
                  {
                    stakeholders.map((val) => (
                      <li key={val.id}>{ val.name }</li>
                    ))
                  }
                </ul>
              </li>
            ))
          }
        </ul>
      </PopoverField>
      <PopoverField>
        <h6>Preview Message</h6>
        <div style={{
          margin: '15px 0', padding: '10px', color: '#747474', border: '1px solid #747474',
        }}
        >
          <p style={{ whiteSpace: 'pre-wrap' }}>{ campaign.message }</p>
          {
            campaign.type === CampaignType.SURVEY && (
              <>
                {
                  campaign.option_1 && (
                    <p>Option 1: { campaign.option_1 }</p>
                  )
                }
                {
                  campaign.option_2 && (
                    <p>Option 2: { campaign.option_2 }</p>
                  )
                }
                {
                  campaign.option_3 && (
                    <p>Option 3: { campaign.option_2 }</p>
                  )
                }
                {
                  campaign.option_4 && (
                    <p>Option 4: { campaign.option_2 }</p>
                  )
                }
              </>
            )
          }
        </div>
      </PopoverField>
    </PopoverContainer>
  </div>
)

export default CampaignScreen
