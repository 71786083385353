import React, { useState } from 'react'
import {
  IonMenu, IonContent, IonList, IonItem, IonFooter, IonLabel, CreateAnimation,
} from '@ionic/react'

import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'

import { faHome, faSearch, faMapMarkedAlt, faTasks, faStickyNote, faEnvelopeOpenText, faComments, faBars, faTimes, faQuestionCircle, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAuth } from '../hooks/useAuth'
import { useWorkspace } from '../hooks/useWorkspace';
import { Icon } from '@iconify/react'

/** Styled components */
const LinkContainer = styled.div`
  padding-left: 15px;

  & ion-item{
    font-size: .9rem;
  }
`

const AppLink = styled.div`
  padding: 0 10px;
`

const MenuDrawer = ({ workspaces }) => {
  const auth = useAuth()
  const history = useHistory()
  const location = useLocation()
  const workspaceHook = useWorkspace()
  const { workspace } = useWorkspace()

  console.log(workspace)
  console.log(workspaceHook.workspace)

  const [workspaceSelection, setWorkspaceSelection] = useState(false)

  if (!auth.user || location.pathname === '/workspaces' || auth.resetPassword || location.pathname === '/verifysms') {
    // If we don't render a menu when the page loads Ion gets all wonky about when to show/hide it
    // so we render an empty menu and hide it. 
    return <IonMenu side='start' contentId='main' data-testid='main-menu' disabled='true' />
  }

  const { email } = auth.user

  const handleWorkspaceChange = (domain) => {
    workspaceHook.setDomain(domain)
    setWorkspaceSelection(false)
    history.push('/home')
  }

  return (
    <IonMenu
      side='start'
      contentId='main'
      data-testid='main-menu'
      style={{ '--side-max-width': '140px', '--menu-drawer-bg-color': (workspaceHook.workspace && workspaceHook.workspace.is_incident_domain) ? '#4197A9' : '#8E151F' }}>
      <IonContent className='menu-drawer'>
        <IonList style={{ paddingTop: '0' }}>
          <CreateAnimation
            duration={800}
            fromTo={[
              { property: 'transform', fromValue: 'translateX(-100px)', toValue: 'translateX(0px)' },
            ]}
            easing='ease-out'
            play
          >
            {
              (workspaceSelection)
              ?(
                <>
                  <IonItem button={true} onClick={() => setWorkspaceSelection(false)}>{workspaceHook.workspace.name} <AppLink> <FontAwesomeIcon icon={faTimes} /> </AppLink></IonItem>
                  {
                    workspaces.filter((domain) => domain.id !== workspaceHook.workspace.id).map((domain) => (
                      <IonItem key={domain.id} onClick={() => handleWorkspaceChange(domain)} button={true} lines='none'>{ domain.name }</IonItem>
                    ))
                  }
                </>
              ):(
                <>
                  <IonItem onClick={() => setWorkspaceSelection(true)} className='ion-justify-content-around choose-domain' button={true} lines='none' style={{ '--ion-item-background': (workspaceHook.workspace && workspaceHook.workspace.is_incident_domain) ? '#347F8E' : '#7A1019' }}>
                    Choose Workspace
                    <FontAwesomeIcon style={{ margin: '0 20px' }} icon={faBars} />
                  </IonItem>
                  <IonItem lines='none' routerLink='/home'><AppLink><FontAwesomeIcon icon={faHome} /></AppLink> Home</IonItem>
                  {
                    workspaceHook.workspace && !workspaceHook.workspace.is_incident_domain && (
                      <IonItem lines='none' routerLink='/dashboard-app'><AppLink><Icon icon="carbon:dashboard" /></AppLink>Dashboard</IonItem>
                    )
                  }
                  <IonItem lines='none' style={{ fontWeight: 'bold' }}>Apps</IonItem>
                  <LinkContainer>
                    {
                      workspaceHook.workspace && workspaceHook.workspace.analyst === 'Analyst' && (
                        <IonItem lines='none' routerLink='/analyst'><AppLink><Icon icon='icon-park-outline:analysis' /></AppLink>Analysts</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.insights && (
                        <IonItem lines='none' routerLink='/insights'>
                          <AppLink>
                            <FontAwesomeIcon icon={faSearch} />
                          </AppLink>
                          Insights
                        </IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.journeys && (
                        <IonItem lines='none' routerLink='/journeys'><AppLink><FontAwesomeIcon icon={faMapMarkedAlt} /></AppLink> Journeys</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.tasks && (
                        <IonItem lines='none' routerLink='/tasks'><AppLink><FontAwesomeIcon icon={faTasks} /></AppLink>Tasks</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.power === 'God' && workspaceHook.workspace.type !== 'member' && (
                        <IonItem lines='none' routerLink='/consultants'><AppLink><Icon icon='icon-park-outline:analysis' /></AppLink>Consultants</IonItem>
                      )
                    }
                    {
                      (workspaceHook.workspace && workspaceHook.workspace.power === 'God' && workspaceHook.workspace.type !== 'member') && (
                        <IonItem lines='none' routerLink='/analyticsdash'><AppLink><Icon icon='icon-park-outline:analysis' /></AppLink>Analytics</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.projects && (
                        <IonItem lines='none' routerLink='/projects'><AppLink><Icon icon='bx:bx-bar-chart-alt-2' /></AppLink>Projects</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && !workspaceHook.workspace.is_incident_domain && (
                        <IonItem lines='none' routerLink='/risks'><AppLink><Icon icon='fluent:text-bullet-list-square-warning-20-regular' /></AppLink>Risk Assessments</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && !workspaceHook.workspace.is_incident_domain && (
                        <IonItem lines='none' routerLink='/risksbeta'><AppLink><Icon icon='fluent:text-bullet-list-square-warning-20-regular' /></AppLink>Risk Assessments(Beta)</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.incident_m && (
                        <IonItem lines='none' routerLink='/crisis'><AppLink><Icon icon='fluent:text-bullet-list-square-warning-20-regular' /></AppLink>Incident Management</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.communities && (
                        <IonItem lines='none' routerLink='/communities'><AppLink><Icon icon='mdi:map-search-outline' /></AppLink>Communities</IonItem>
                      )
                    }
                    {
                      workspaceHook.workspace && workspaceHook.workspace.engagement && (
                        <IonItem lines='none' routerLink='/engagement'><AppLink><Icon icon='material-symbols:communication' /></AppLink>Engagement</IonItem>
                      )
                    }
                  </LinkContainer>
                  <IonItem lines='none' style={{ fontWeight: 'bold' }}>Resources</IonItem>
                  {
                    workspaceHook.workspace && !workspaceHook.workspace.is_incident_domain && (
                      <LinkContainer>
                        <IonItem lines='none' routerLink='/reports'><AppLink><FontAwesomeIcon icon={faEnvelopeOpenText} /></AppLink> Reports</IonItem>
                      </LinkContainer>  
                    )
                  }                
                  <IonItem lines='none' style={{ fontWeight: 'bold' }}>Messaging</IonItem>
                  <LinkContainer>
                    <IonItem lines='none' routerLink='/chats'><AppLink><FontAwesomeIcon icon={faComments} /></AppLink> Chats</IonItem>
                  </LinkContainer>

                </>
              )
            }
          </CreateAnimation>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonItem color='light'>
          <IonLabel>
            <p>Logged in as:</p>
            <h3>{email}</h3>
          </IonLabel>
        </IonItem>
      </IonFooter>
    </IonMenu>
  )
}

export default MenuDrawer
