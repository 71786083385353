/* eslint-disable react/no-array-index-key */
/* eslint-disable no-trailing-spaces */
import React, { useState, FC, useEffect } from 'react'
import {
  IonRow, IonButton, IonCol, useIonAlert, 
} from '@ionic/react'
import Select from 'react-select'
import { useIncidentsData } from '../../../hooks/incident-management/useIncidentData'
import { NoUserSelected, UserField } from '../StyledContainers'
import {
  IncidentTeam, ProcedureResponsability, SOPLevel, User, 
} from '../../types/GlobalTypes'
import { SherpaButton } from '../../GlobalContainers'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { processEmergencyTeam } from '../../../hooks/incident-management/helpers/Utils'

interface TeamMember {
  user: User | null,
  role?: ProcedureResponsability,
  id?: number,
  edited: boolean
}

/**
 *  =================================
 *      ASSIGN TEAMS FOR SOPs
 *  =================================
 */
const TeamsView = () => {
  /* Get app information through hook */
  const {
    emergencyTeam, managementTeam, setLoading,
    crisisTeam, nonCriticalTeam, updateTeam, users,
  } = useIncidentsData()
  const [emergencyMembers, setEmergencyMembers] = useState<TeamMember[]>([])
  const [managementMembers, setManagementMembers] = useState<TeamMember[]>([])
  const [crisisMembers, setCrisisMembers] = useState<TeamMember[]>([])
  const [nonCriticalMembers, setNonCriticalMembers] = useState<TeamMember[]>([])
  const [editing, setEditing] = useState<boolean>(false)
  const [editingTeam, setEditingTeam] = useState<string>('')
  const [activeEditing, setActiveEditing] = useState<boolean>(false)
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()

  const startEdit = () => {
    setEditing(true)
  }

  const populateDefaultVals = () => {
    const formattedEmergency = (emergencyTeam) ? emergencyTeam.members.map((val) => ({
      user: val, role: val.role, id: val.rel_id, edited: false, 
    })) : []
    setEmergencyMembers(formattedEmergency)

    const formattedNonCritical = (nonCriticalTeam) ? nonCriticalTeam.members.map((val) => ({
      user: val, role: val.role, id: val.rel_id, edited: false,
    })) : []
    setNonCriticalMembers(formattedNonCritical)

    const formattedManagement = (managementTeam) ? managementTeam.members.map((val) => ({
      user: val, role: val.role, id: val.rel_id, edited: false,
    })) : []
    setManagementMembers(formattedManagement)

    const formattedCrisis = (crisisTeam) ? crisisTeam.members.map((val) => ({
      user: val, role: val.role, id: val.rel_id, edited: false,
    })) : []
    setCrisisMembers(formattedCrisis)
  }

  const handleCancel = () => {
    populateDefaultVals()
    setEditing(false)
  }

  const updateSingleTeam = (updatedTeam: IncidentTeam) => {
    updateTeam(updatedTeam, updatedTeam.type)
  }

  const handleSubmit = () => {
    const displayErrorPopup = (message: string) => {
      ionAlert({
        header: 'Team Validation Error',
        message,
        buttons: [
          { text: 'Ok' },
        ],
      })
    }

    const validateTeam = (members: TeamMember[], errorMessage: string) : TeamMember[] | null => {
      const filtered = members.filter(({ user, role }) => user || role !== ('' as ProcedureResponsability))
      const emergencyValid = filtered.every(({ user, role }) => user && role !== ('' as ProcedureResponsability))
      if (!emergencyValid) {
        displayErrorPopup(`Some fields are empty or not valid (${errorMessage})`)
        return null
      }

      const leaders = filtered.filter(({ role }) => role === ProcedureResponsability.TEAM_LEADER)
      if (leaders.length > 1) {
        displayErrorPopup(`${errorMessage} can only have a single leader.`)
        return null
      }

      if (leaders.length < 1) {
        displayErrorPopup(`${errorMessage} must have a leader.`)
        return null
      }

      return filtered
    }

    interface Sending {
      emergency_response: any[],
      crisis_management: any[],
      incident_management: any[],
      non_critical: any[],
    }

    let toSend: Sending = {
      emergency_response: [/* Array elements here */],
      crisis_management: [/* Array elements here */],
      incident_management: [/* Array elements here */],
      non_critical: [/* Array elements here */],
    }

    if (editingTeam === 'Emergency Response Team') {
      const validatedEmergency = validateTeam(emergencyMembers, 'Emergency Response Team')
      if (!validatedEmergency) { return }
      toSend = {
        emergency_response: validatedEmergency.map(({ user, role, id }) => ({
          user_id: user.id, role, edit: !!id, rel_id: id, 
        })),
        crisis_management: [],
        incident_management: [],
        non_critical: [],
      }
    }

    if (editingTeam === 'Crisis Management Team') {
      const validatedCrisis = validateTeam(crisisMembers, 'Crisis Management Team')
      if (!validatedCrisis) { return }
      toSend = {
        crisis_management: validatedCrisis.map(({ user, role, id }) => ({
          user_id: user.id, role, edit: !!id, rel_id: id, 
        })),
        incident_management: [],
        emergency_response: [],
        non_critical: [],
      }
    }

    if (editingTeam === 'Incident Management Team') {
      const validatedManagement = validateTeam(managementMembers, 'Incident Management Team')
      if (!validatedManagement) { return }
      toSend = {
        incident_management: validatedManagement.map(({ user, role, id }) => ({
          user_id: user.id, role, edit: !!id, rel_id: id, 
        })),
        crisis_management: [],
        emergency_response: [],
        non_critical: [],
      }
    }

    if (editingTeam === 'Noncritical Incident') {
      const validatedNonCritical = validateTeam(nonCriticalMembers, 'Noncritical Team')
      if (!validatedNonCritical) { return }
      toSend = {
        non_critical: validatedNonCritical.map(({ user, role, id }) => ({
          user_id: user.id, role, edit: !!id, rel_id: id, 
        })),
        crisis_management: [],
        incident_management: [],
        emergency_response: [],
      }
    }

    const allUsers = [...emergencyMembers, ...crisisMembers, ...managementMembers]
    const hasRepeated = allUsers.some(({ user }, index, arr) => user?.id && arr.findIndex((val) => val.user?.id === user.id) !== index);

    if (hasRepeated) {
      if (editingTeam !== 'Noncritical Incident') {
        displayErrorPopup('A user seems to be part of two different teams.')
        return
      }
    }

    setLoading(true)
    axios.post('/api/v1/internal_incident/setEmergencyTeams', {
      domain_id: workspace.id,
      non_critical: toSend.non_critical,
      emergency_response: toSend.emergency_response,
      incident_management: toSend.incident_management,
      crisis_management: toSend.crisis_management,
    }).then(({ data }) => {
      updateSingleTeam(processEmergencyTeam(data.emergency, users, SOPLevel.ERT))
      updateSingleTeam(processEmergencyTeam(data.crisis, users, SOPLevel.CMT))
      updateSingleTeam(processEmergencyTeam(data.incidentmanagment, users, SOPLevel.IMT))
      updateSingleTeam(processEmergencyTeam(data.noncritical, users, SOPLevel.NCI))
    }).finally(() => {
      setEditing(false)
      setLoading(false)
    })
  }

  useEffect(() => {
    populateDefaultVals()
  }, [emergencyTeam, managementTeam, crisisTeam, nonCriticalTeam])

  return (
    <div className='risk-assessment-list' style={{ height: '100%' }}>
      <div className='title-margin-15' style={{ flex: 1, overflowY: 'auto' }}>
        <h4>Emergency Teams</h4>
        <p>Emergency Teams refer to the teams that are assembled when an incident is reported. Depending on the level of the incident reported,
          different teams may be mobilised to deal with it. A workspace may also be created to manage the incident. You can choose your teams below.
        </p>
        <TeamMembers
          members={nonCriticalMembers}
          setMembers={setNonCriticalMembers}
          editing={editing}
          name='Noncritical Incident'
          message='User(s) who are notified when a Noncritical Incident is reported.'
          startEdit={startEdit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          setEditingTeam={setEditingTeam}
          setActiveEditing={setActiveEditing}
          activeEditing={activeEditing}
        />
        <TeamMembers
          members={emergencyMembers}
          setMembers={setEmergencyMembers}
          editing={editing}
          name='Emergency Response Team'
          message='User(s) who are assembled when an incident requires the Emergency Response Team.'
          startEdit={startEdit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          setEditingTeam={setEditingTeam}
          setActiveEditing={setActiveEditing}
          activeEditing={activeEditing}
        />
        <TeamMembers
          members={managementMembers}
          setMembers={setManagementMembers}
          editing={editing}
          name='Incident Management Team'
          message='User(s) who are assembled when an incident requires the Incident Management Team.'
          startEdit={startEdit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          setEditingTeam={setEditingTeam}
          setActiveEditing={setActiveEditing}
          activeEditing={activeEditing}
        />
        <TeamMembers
          members={crisisMembers}
          setMembers={setCrisisMembers}
          editing={editing}
          name='Crisis Management Team'
          message='User(s) who are assembled when an incident requires the Crisis Management Team.'
          startEdit={startEdit}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          setEditingTeam={setEditingTeam}
          setActiveEditing={setActiveEditing}
          activeEditing={activeEditing}
        />
      </div>
    </div>
  )
}

export default TeamsView

interface Props {
  members: TeamMember[],
  name: string,
  message: string,
  setMembers: React.Dispatch<React.SetStateAction<TeamMember[]>>
  editing: boolean,
  startEdit: any,
  handleCancel: any,
  handleSubmit: any,
  setEditingTeam: React.Dispatch<React.SetStateAction<string>>,
  setActiveEditing: React.Dispatch<React.SetStateAction<boolean>>,
  activeEditing: boolean,
}

const TeamMembers : FC<Props> = ({
  members, setMembers, name, message, editing, startEdit, handleCancel, handleSubmit, setEditingTeam, setActiveEditing, activeEditing
}) => {
  const { users } = useIncidentsData()
  const addMember = () => {
    const copy = members.slice()
    copy.push({ role: '' as ProcedureResponsability, user: null, edited: true })
    setMembers(copy)
  }

  const handleEdit = (index: number, updated: TeamMember) => {
    const copy = members.slice()
    copy.splice(index, 1, updated)
    setMembers(copy)
  }

  const onSubmit = () => {
    setActiveEditing(false)
    setEditingInstance(false)
    setEditingTeam(name)
    handleSubmit()
  }

  const onCancel = () => {
    setActiveEditing(false)
    setEditingInstance(false)
    handleCancel()
  }

  useEffect(() => {
    if (editing && members.length === 0) {
      addMember()
    }
  }, [editing])

  const handleEditing = () => {
    startEdit()
    setActiveEditing(true)
    setEditingTeam(name)
    setEditingInstance(true)
  }

  const [editingInstance, setEditingInstance] = useState<boolean>(false)

  return (
    <div style={{ margin: '40px 0' }}>
      <IonRow>
        <IonCol>
          <h4>{name}</h4>
        </IonCol>
      </IonRow>
      <p style={{ margin: '10px 0' }}>{ message }</p>
      <IonRow>
        <IonCol style={{ paddingLeft: 0 }} size='3'>
          <h6>Name</h6>
        </IonCol>
        <IonCol size='3'>
          <h6>Email</h6>
        </IonCol>
        <IonCol size='2'>
          <h6>Role</h6>
        </IonCol>
        <IonCol size='2'>
          <h6>Mobile 1</h6>
        </IonCol>
        <IonCol size='2'>
          <h6>Mobile 2</h6>
        </IonCol>
      </IonRow>
      {
        !editing && members.length === 0 && (
          <IonRow>
            <IonCol size='3'>
              <NoUserSelected>
                No Users Selected
              </NoUserSelected>
            </IonCol>
            <IonCol size='3'>
              <NoUserSelected>
                No Users Selected
              </NoUserSelected>
            </IonCol>
            <IonCol size='2'>
              <NoUserSelected>
                No Users Selected
              </NoUserSelected>
            </IonCol>
            <IonCol size='2'>
              <NoUserSelected>
                No Users Selected
              </NoUserSelected>
            </IonCol>
            <IonCol size='2'>
              <NoUserSelected>
                No Users Selected
              </NoUserSelected>
            </IonCol>
          </IonRow>
        )
      }
      {
        members.length > 0 && members.map((val, index) => (
          <IonRow key={index}>
            <IonCol style={{ paddingLeft: 0 }} size='3'>
              {
                (editing && editingInstance) ? (
                  <Select
                    placeholder={
                      val.user 
                        ? `${val.user.first_name} ${val.user.final_name}`
                        : 'Select User'
                    }
                    name='supervisor'
                    id='select-supervisor-container'
                    className='select-container'
                  // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={users.map((user) => ({ value: user, label: `${user.first_name} ${user.final_name}` }))}
                    onChange={(selected) => handleEdit(index, { ...val, user: selected.value, edited: true })}
                  />
                ) : (
                  <UserField>
                    { (val.user) ? `${val.user.first_name} ${val.user.final_name}` : 'Input User' }
                  </UserField>
                )
              }
            </IonCol>
            <IonCol size='3'>
              <UserField>
                { (val.user) ? val.user.email : 'Input Email' }
              </UserField>
            </IonCol>
            <IonCol size='2'>
              {
                (editing && editingInstance) ? (
                  <select className='procedure-select' onChange={(e) => handleEdit(index, { ...val, role: e.target.value as ProcedureResponsability })} value={val.role}>
                    <option value='' disabled>Select Role</option>
                    <option value={ProcedureResponsability.TEAM_LEADER}>{ ProcedureResponsability.TEAM_LEADER }</option>
                    <option value={ProcedureResponsability.LOGISTICS}>{ ProcedureResponsability.LOGISTICS }</option>
                    <option value={ProcedureResponsability.ADMINISTRATIVE_SUPPPORT}>{ ProcedureResponsability.ADMINISTRATIVE_SUPPPORT }</option>
                    <option value={ProcedureResponsability.FINANCE}>{ ProcedureResponsability.FINANCE }</option>
                    <option value={ProcedureResponsability.HSE}>{ ProcedureResponsability.HSE }</option>
                    <option value={ProcedureResponsability.HR}>{ ProcedureResponsability.HR }</option>
                    <option value={ProcedureResponsability.LEGAL}>{ ProcedureResponsability.LEGAL }</option>
                    <option value={ProcedureResponsability.SECURITY}>{ ProcedureResponsability.SECURITY }</option>
                    <option value={ProcedureResponsability.OTHER}>{ ProcedureResponsability.OTHER }</option>
                  </select>
                ) : (
                  <UserField>
                    { val.role }
                  </UserField>
                )
              }
              
            </IonCol>
            <IonCol size='2'>
              <UserField>
                { (val.user && val.user.phone_number) ? val.user.phone_number : 'Mobile No.' }
              </UserField>
            </IonCol>
            <IonCol size='2'>
              <UserField>
                { (val.user && val.user.alternative_phone_number) ? val.user.alternative_phone_number : 'Mobile No.' }
              </UserField>
            </IonCol>
          </IonRow>
        ))
      }
      {
        (editing && editingInstance) && (
          <IonRow className='ion-justify-content-end'>
            <SherpaButton onClick={addMember} style={{ fontSize: '1.1rem' }}>
              Add User
            </SherpaButton>
          </IonRow>
        )
      }
      <IonRow className='ion-justify-content-end'>
        {
          (editing && editingInstance) ? (
            <>
              <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
              <IonButton onClick={onSubmit} style={{ '--background': '#4197A9' }}>Update Template</IonButton>
            </>
          ) : (
            <>
              {
                !activeEditing ? (
                  <IonButton onClick={handleEditing} style={{ '--background': '#4197A9' }}>Edit</IonButton>
                ) : null
              }
            </>
          )
        }
      </IonRow>
    </div>
  )
}
