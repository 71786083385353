/* eslint-disable no-trailing-spaces, @typescript-eslint/naming-convention */
import {
  IonButton,
  IonCol,
  IonContent, IonInput, IonList, IonRow, IonSpinner, useIonAlert,
} from '@ionic/react'
import React, {
  useState, useEffect, useRef, FC, 
} from 'react'

import * as moment from 'moment'
import { TicketEntry, TicketContainer, StoryLine } from '../StyledContainers'
import MapForm from '../forms/MapForm'

import { useAuth } from '../../../hooks/useAuth'
import { ObscureBackground, CenterLoader } from '../../GlobalContainers'
import useApi from '../../../hooks/testHooks'
import { useGeolocation } from '../../../hooks/useGeolocation'
import { useJourneysData2 } from '../../../hooks/journeys/useJourneysData2'
import { Journey } from '../../types/OptimizedMaps'
import { JourneyStatus } from '../../types/GlobalTypes'
import { generateChapter } from '../../utils/MapboxUtils'

interface Props {
  setShowTicket: React.Dispatch<React.SetStateAction<boolean>>,
  focussedJourney: Journey,
}

/**
 *  ================================
 *      Journey Ticket (Updates)
 *  ================================
 */
const JourneyTicket : FC<Props> = ({
  setShowTicket, focussedJourney,
}) => {
  const [loading, setLoading] = useState(false)
  const [journey, setJourney] = useState(focussedJourney)
  const [comment, setComment] = useState('')
  const [alert] = useIonAlert()
  const { mapRef } = useJourneysData2()

  /* Get session details  */
  const auth = useAuth()
  const apiHook = useApi()
  const { user_id } = auth.user
  const contentRef = useRef<HTMLIonContentElement>()
  const { geolocation } = useGeolocation()
  const { updateJourneys } = useJourneysData2()

  const scrollToBottom = () => contentRef.current && contentRef.current.scrollToBottom()

  const defineOptions = (status) => {
    const options = ['Pre-Departure', 'In-Transit', 'Complete']
    const statusIndex = options.indexOf(status)

    const current = options.slice(statusIndex, statusIndex + 2)
    current.push('Abort')

    return current
  }

  const handleStatus = async (e) => {
    setLoading(true)
    const progress = async (newStatus: JourneyStatus) => {
      /* Change status in the db */
      const { story } = await apiHook.progressJourney(focussedJourney.id, '')
      const user = { ...auth.user, id: user_id }
      const chapter = generateChapter(Math.random(), story, 'status_change', focussedJourney.id, user)
      const newStory = [...journey.chapters, chapter]
      updateJourneys(journey)

      /* Update current ticket */
      journey.status = newStatus
      journey.chapters = newStory
      setJourney(journey)
      setLoading(false)
    }

    if (e.target.value === 'Abort') {
      alert({
        header: 'Abort Journey?',
        message: 'Do you wish to continue? This action cannot be undone. ',
        buttons: [
          {
            text: 'Back',
            handler: () => {
              setLoading(false)
            },
          },
          {
            text: 'Yes, continue',
            handler: async () => {
              const { story } = await apiHook.denyJourney(focussedJourney.id, '')
              const user = { ...auth.user, id: user_id }
              const chapter = generateChapter(Math.random(), story, 'status_change', focussedJourney.id, user)
              const newStory = [...journey.chapters, chapter]
              updateJourneys(journey)

              /* Update current ticket  */
              journey.status = JourneyStatus.ABORTED
              journey.chapters = newStory
              setJourney(journey)
              setLoading(false)
            },
          },
        ],
      })
    } else if (e.target.value === 'In-Transit') {
      alert({
        header: 'Attention',
        message: 'Has the Pre-Departure checklist been completed?',
        buttons: [
          {
            text: 'no',
            handler: () => {
              setLoading(false)
            },
          },
          {
            text: 'yes',
            handler: (async () => {
              progress(JourneyStatus.IN_TRANSIT)
            }),
          },
        ],
      })
    } else if (e.target.value === 'Complete') {
      alert({
        header: 'Note',
        message: 'Once a journey is moved to status ‘Complete’ it cannot be undone. Continue?',
        buttons: [
          {
            text: 'Back',
            handler: () => {
              setLoading(false)
            },
          },
          {
            text: 'Yes, continue',
            handler: () => progress(JourneyStatus.COMPLETE),
          },
        ],
      })
    }
  }

  const handleComment = async () => {
    setLoading(true)

    /* Send comment request to the server */
    const { story } = await apiHook.commentJourney(focussedJourney.id, comment, geolocation.lat, geolocation.lng)
    const user = { ...auth.user, id: user_id }
    const chapter = generateChapter(Math.random(), story, 'comment', focussedJourney.id, user)
    const newStory = [...journey.chapters, chapter]
    updateJourneys(journey)

    /* update ticket in real time */
    journey.chapters = newStory
    setJourney({ ...journey })

    /* Clean input area */
    setComment('')
    setLoading(false)
    scrollToBottom()
  }

  const colorCodeStatus = (status) => (
    {
      Pending: '#747474',
      'Pre-Departure': '#4197A9',
      'In-Transit': '#FF8A00',
      Complete: '#0C9500',
      Abort: '#eb4034',
      Declined: '#eb4034',
    }[status]
  )

  const getPositionInJourney = (userId: number) => {
    if (userId === focussedJourney.leader.id) {
      return 'Journey Leader'
    }

    if (userId === focussedJourney.approver.id) {
      return 'Supervisor'
    }

    if (userId === focussedJourney.submitter.id) {
      return 'Manager'
    }

    return 'Member'
  }

  useEffect(() => {
    setJourney(focussedJourney)
    scrollToBottom()

    if (focussedJourney.firstPoint) {
      mapRef.current.easeTo({ center: focussedJourney.firstPoint, zoom: 12 })
    }
  }, [focussedJourney])

  return (
    <MapForm>
      {
        loading && (
          <ObscureBackground id='ticket-loader'>
            <CenterLoader>
              <IonSpinner color='light' />
            </CenterLoader>
          </ObscureBackground>
        )
      }
      <IonContent ref={contentRef}>
        <IonList>
          <IonRow style={{ padding: '20px 5px' }} className='ion-justify-content-between ion-align-items-center'>
            <h2 style={{ fontSize: '1.4rem' }}>Journey Ticket</h2>
            {
              (journey.status === 'Pending' || journey.status === 'Complete' || journey.status === 'Aborted' || journey.status === 'Denied' || journey.leader.id !== user_id)
                ? (
                  <p className='journey-status' style={{ backgroundColor: colorCodeStatus(journey.status) }}>{ journey.status }</p>
                )
                : (
                  <select className='journey-status' style={{ backgroundColor: colorCodeStatus(journey.status) }} value={journey.status} onChange={handleStatus}>
                    {
                      defineOptions(journey.status).map((status) => (
                        <option key={status} value={status}>{status}</option>
                      ))
                    }
                  </select>
                )
            }
          </IonRow>
          <TicketContainer>
            <TicketEntry>
              <p><span>Journey Requested By: </span><span id='journey-ticket-submitter-email'>{journey.submitter.email}</span></p>
            </TicketEntry>
            <TicketEntry>
              <p><span>Journey Purpose: </span> <span id='journey-ticket-purpose'>{journey.purpose}</span> </p>
            </TicketEntry>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <IonCol style={{ padding: '0' }} size='7'>
                <TicketEntry>
                  <p><span>Journey Leader: </span><span id='journey-ticket-leader-name'>{(journey.leader) ? `${journey.leader.first_name} ${journey.leader.final_name}` : 'External User'}</span></p>
                </TicketEntry>
              </IonCol>
              <IonCol style={{ padding: '0' }} size='5'>
                <TicketEntry>
                  <p>Mobile: <span id='journey-ticket-leader-phone'>{journey.leader_phone}</span></p>
                </TicketEntry>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <IonCol style={{ padding: '0' }} size='7'>
                <TicketEntry>
                  <p><span>Driver: </span><span id='journey-ticket-driver-name'>{journey.driver_name}</span></p>
                </TicketEntry>
              </IonCol>
              <IonCol style={{ padding: '0' }} size='5'>
                <TicketEntry>
                  <p>Mobile: <span id='journey-ticket-driver-phone'>{journey.driver_phone}</span></p>
                </TicketEntry>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <IonCol style={{ padding: '0' }} size='7'>
                <TicketEntry>
                  <p><span>Vehicle Type: </span> <span id='journey-ticket-vehicle-type'>{journey.vehicle_type}</span></p>
                </TicketEntry>
              </IonCol>
              <IonCol style={{ padding: '0' }} size='5'>
                <TicketEntry>
                  <p><span>Registration:</span> <span id='journey-ticket-vehicle-registration'>{journey.vehicle_reg}</span></p>
                </TicketEntry>
              </IonCol>
            </IonRow>
            {
              journey.emergency_sat && (
                <TicketEntry>
                  <p><span>Emergency Phone No. 1:</span> <span id='journey-ticket-emergency-1'>{journey.emergency_sat}</span></p>
                </TicketEntry>
              )
            }
            {
              journey.emergency_other && (
                <TicketEntry>
                  <p><span>Emergency Phone No. 2:</span><span id='journey-ticket-emergency-2'>{journey.emergency_other}</span></p>
                </TicketEntry>
              )
            }
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <IonCol style={{ padding: '0' }} size='7'>
                <TicketEntry>
                  <p><span>Departure Date/Time </span> {
                  (journey.departure_time.includes('T')) ? journey.departure_time.split('T')[0] : journey.departure_time.split(' ')[0]
                }
                  </p>
                </TicketEntry>
              </IonCol>
              <IonCol style={{ padding: '0' }} size='5'>
                <TicketEntry>
                  <p><span>Location:</span> <span id='journey-ticket-from-location'>{journey.departure_location}</span></p>
                </TicketEntry>
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <IonCol style={{ padding: '0' }} size='7'>
                <TicketEntry>
                  <p><span>Arrival Date/Time </span> {(journey.arrival_time.includes('T')) ? journey.arrival_time.split('T')[0] : journey.arrival_time.split(' ')[0]}</p>
                </TicketEntry>
              </IonCol>
              <IonCol style={{ padding: '0' }} size='5'>
                <TicketEntry>
                  <p><span>Location:</span><span id='journey-ticket-to-location'>{journey.arrival_location}</span></p>
                </TicketEntry>
              </IonCol>
            </IonRow>
            <TicketEntry>
              <p><span>Route:</span> {journey.routes.map((route) => route.name).join(', ')}</p>
            </TicketEntry>
            <TicketEntry>
              <p><span>Passenger Details:</span> {journey.passengers}</p>
            </TicketEntry>
          </TicketContainer>
          <StoryLine>
            {
              journey.chapters.map((chapter) => (
                <li key={chapter.id} className='status-changes'>
                  <p>
                    {
                      chapter.category === 'status_change' && (
                        <span style={{ fontWeight: 'bold' }}>Journey Status: </span>
                      )
                    }
                    {
                      chapter.content.startsWith('Check In:') && (
                        <span style={{ fontWeight: 'bold' }}>Check In: </span>
                      )
                    }
                    { chapter.content.startsWith('Check In:') ? chapter.content.split('Check In: ')[1] : chapter.content }
                  </p>
                  {
                    chapter.content.split('\n').length > 1 && (
                      <p>{chapter.content.split('\n')[1]}</p>
                    )
                  }
                  <p style={{ color: '#326771' }}>{ moment(chapter.created_at).format('DD/MM/YY HH:mm') } 
                    - { (chapter.user) ? `${chapter.user.first_name} ${chapter.user.final_name}` : 'External User' } ({getPositionInJourney(chapter.user_id)})
                  </p>
                </li>
              ))
            }
          </StoryLine>
          {
            (journey.submitter.id === user_id || journey.leader.id === user_id || journey.approver.id === user_id) && journey.status === 'In-Transit' && (
              <IonInput id='journey-ticket-comment-field' type='text' value={comment} onIonChange={(e) => setComment(e.detail.value)} className='ticket-comment' placeholder='Description' />
            )
          }
          <IonRow style={{ margin: '0 20px' }} className='ion-justify-content-end'>
            {
              (journey.submitter.id === user_id || journey.leader.id === user_id || journey.approver.id === user_id) && journey.status === 'In-Transit' && (
                <IonButton id='journey-ticket-add-comment' style={{ '--background': '#0C9500' }} disabled={loading} onClick={handleComment}>Add Comment</IonButton>
              )
            }
            <IonButton
              style={{ '--background': '#326771' }}
              id='journey-ticket-done'
              onClick={() => {
                setShowTicket(false)
              }}
            >Done
            </IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </MapForm>
  )
}

export default JourneyTicket
