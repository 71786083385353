import { IonButton, IonInput, IonRow } from '@ionic/react'
import * as React from 'react'
import { useChatsData } from '../../../hooks/chats/useChatsData'
import { OverlayContainer } from '../StyledContainers'
import axios from '../../../utils/axios'

const EditChatName : React.FC = () => {
  const {
    setShowEditName, setLoading, focusedRoom, updateRoomsName,
    setFocusedRoom,
  } = useChatsData()
  const [newName, setNewName] = React.useState<string>(focusedRoom.name)

  const handleSubmit = () => {
    setShowEditName(false)
    setLoading(true)
    axios.put('/update_room_name', { new_name: newName, room_id: focusedRoom.id })
      .then(({ data }) => {
        if (!data.ok) {
          reportError(data.message)
          setLoading(false)
          return
        }
        const updateRoom = updateRoomsName(focusedRoom, newName)
        setFocusedRoom(updateRoom)
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <OverlayContainer>
      <h4>Chat Name</h4>
      <IonInput
        type='text'
        placeholder='Type a name for this chat'
        className='chats-input-field'
        value={newName}
        onIonChange={(e) => setNewName(e.detail.value)}
      />
      <IonRow style={{ marginTop: '20px' }}>
        <IonButton onClick={() => setShowEditName(false)} style={{ '--background': '#8E151F', color: 'white' }}>Cancel</IonButton>
        <IonButton onClick={handleSubmit} style={{ '--background': '#326771', color: 'white' }}>Save</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default EditChatName
