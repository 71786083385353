import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid, IonContent,
  IonCol, useIonAlert,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { useSituationReport } from '../../hooks/insights/useSituationReport'

//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SitRepPt8 = ({
  step, setStep, county, incidents,
}) => {
  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  const [, setIsShowing,, setCountyId] = useSituationReport()

  const handleClose = () => {
    setIsShowing(false)
    setCountyId(null)
    setStep(1)
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '95%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Situation Report - ${county.name} County`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonGrid class='ion-justify-content-center' style={{ width: '95%', marginTop: '1em' }}>
          <IonList>
            <IonRow>
              <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontWeight: 'bold', fontSize: '1.15em' }}>List of Security Reports (Last Month)</IonLabel></IonCol>
            </IonRow>
          </IonList>
        </IonGrid>

        <IonContent style={{ height: '68%', '--ion-background-color': '#FFFFFF' }}>
          <IonGrid class='ion-justify-content-center' style={{ width: '95%' }}>
            <IonGrid class='ion-justify-content-center' style={{ backgroundColor: '#FFFFFF' }}>
              <IonRow style={{ outline: 'solid 1px' }}>

                <IonCol size='1.9' style={{ backgroundColor: '#8E151F', outline: 'solid 1px', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Date Reported</IonLabel>
                </IonCol>

                <IonCol size='1.9' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Incident Type</IonLabel>
                </IonCol>

                <IonCol size='2.3' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Location</IonLabel>
                </IonCol>

                <IonCol size='5.9' style={{ outline: 'solid 1px', backgroundColor: '#8E151F', paddingLeft: '0.6em' }}>
                  <IonLabel style={{ fontSize: '1em', color: '#FFFFFF' }}>Description</IonLabel>
                </IonCol>

              </IonRow>
              {
                incidents.length === 0 && (
                <IonRow>
                  <IonLabel style={{ marginTop: '1em', fontWeight: 'bold', fontSize: '1em' }}>There have been no Security Reports in the past week.</IonLabel>
                </IonRow>
                )
              }

              {
                incidents.length > 0 && (
                  incidents.map(
                    (val) => (
                      <IonRow style={{ outline: 'solid 1px' }}>
                        <IonCol size='1.9' style={{ outline: 'solid 1px', backgroundColor: '#FFFFFF', paddingLeft: '0.6em' }}>
                          <IonLabel style={{ fontSize: '1em' }}>{moment(val.reported).format('DD/MM/YY')}</IonLabel>
                        </IonCol>

                        <IonCol size='1.9' style={{ outline: 'solid 1px', backgroundColor: '#FFFFFF', paddingLeft: '0.6em' }}>
                          <IonLabel style={{ fontSize: '1em' }}>{val.incident_type}</IonLabel>
                        </IonCol>

                        <IonCol size='2.3' style={{ outline: 'solid 1px', backgroundColor: '#FFFFFF', paddingLeft: '0.6em' }}>
                          <IonLabel style={{ fontSize: '1em' }}>{val.nearest}</IonLabel>
                        </IonCol>

                        <IonCol size='5.9' style={{ outline: 'solid 1px', backgroundColor: '#FFFFFF', paddingLeft: '0.6em' }}>
                          <IonLabel style={{ fontSize: '1em' }}>{val.description}</IonLabel>
                        </IonCol>
                      </IonRow>

                    ),
                  )
                )
              }

            </IonGrid>
          </IonGrid>
        </IonContent>

        <IonRow style={{}} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default SitRepPt8
