/* eslint-disable camelcase */

import moment from 'moment'

/**
 *  =======================================
 *    Status Functionality for Projects
 *        and Objectives
 *  =======================================
 */

export const statuses = ['Planning', 'In-Progress', 'Complete']

export const colorCodeStatus = (status) => (
  {
    Planning: '#4197A9',
    'In-Progress': '#FF8A00',
    Complete: '#0C9500',
    Cancel: '#eb4034',
  }[status]
)

export const defineOptions = (status) => {
  const index = statuses.indexOf(status)
  const options = []

  if (index > 0) options.push(statuses[index - 1])
  options.push(status)
  if (index <= statuses.length - 2) options.push(statuses[index + 1])
  options.push('Cancel')

  return options
}

export const crossReferenceObjectives = ({ objectives, errands, tasks }) => objectives.map((obj) => {
  const objTasks = []
  errands.forEach(({ objective_id, task_id }) => {
    if (obj.id === objective_id) {
      const task = tasks.find(({ id }) => id === task_id)
      if (task) {
        objTasks.push({
          description: task.description,
          due: task.due,
          title: task.title,
          highPriority: task.priority,
          assignee: task.assignee,
          id: task_id,
        })
      }
    }
  })

  return {
    startDate: moment(obj.start).format('YYYY-MM-DD HH:mm'),
    endDate: moment(obj.deadline).format('YYYY-MM-DD HH:mm'),
    owner: obj.user_id,
    name: obj.title,
    status: obj.status,
    description: obj.description,
    id: obj.id,
    tasks: objTasks,
  }
})
