import { County } from '../../../components/types/GlobalTypes'

export enum SelectedActionKind {
  SELECT_COUNTY = 'select_county',
  UNSELECT_COUNTY = 'unselect_county',
  FORGET_SELECTIONS = 'forget_selections',
}

export interface SelectedActions {
  type: SelectedActionKind,
  payload: County
}

/* Keep a list of ids for fast retrieval and a list of cached counties for further forms */
export interface SelectedCounties {
  counties: County[],
  ids: number[]
}

export const selectedCountyReducers = (state: SelectedCounties, action:SelectedActions) : SelectedCounties => {
  const { type, payload } = action
  switch (type) {
    case SelectedActionKind.SELECT_COUNTY: {
      const copy = [...state.ids, payload.id]
      const counties = [...state.counties, payload]
      return { ...state, ids: copy, counties }
    }
    case SelectedActionKind.UNSELECT_COUNTY: {
      const copy = [...state.ids].filter((val) => val !== payload.id)
      const counties = [...state.counties].filter((val) => val.id !== payload.id)
      return { ...state, ids: copy, counties }
    }
    case SelectedActionKind.FORGET_SELECTIONS: {
      return { ids: [], counties: [] }
    }
    default:
      return { ...state }
  }
}
