import React from 'react'
import Select from 'react-select'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, SelectionDetail } from '../../StyledContainers'

/**
 *  ===============================
 *       Impact After Measures
 *  ===============================
 */
const ResidualImpact = ({
  children, input, setInput, next, onClose,
  people, operations, property, edit,
}) => (
  <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
    <IonContent>
      <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
        <div>
          {
            children
          }
          <SettingField>
            <h5 className='blue'>Residual Risk - Impact Assessment </h5>
            <p>{(edit) ? `Use the drop-downs below to determine the impact that the selected Risk Event would have on the following  factors after new controls measures are put in place.
            The scores from the previous assessment are shown below and can be adjusted.`
              : `Use the drop-downs below to assess the consequences the selected Risk Event would have on the following factors were it to take place, taking into account new control 
              measures you have chosen to add.`}
            </p>
            {
              edit && (
                <p>Vulnerability was scored as shown
                  in the previous assessment. You can adjust it accordingly for this Review.
                </p>
              )
            }
          </SettingField>
          <IonRow className='ion-justify-content-between'>
            <IonCol size={4}>
              <SettingField>
                <h5>People</h5>
                <Select
                  placeholder='Select People'
                  id='ra-residual-people-impact'
                  name='residualPeople'
                  className='select-container'
                  value={input.residualPeople}
                      // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={Object.keys(people).map((level) => ({ value: level, label: level }))}
                  onChange={(selected) => setInput({ ...input, residualPeople: selected, edit: true })}
                />
              </SettingField>
            </IonCol>
            {
              input.residualPeople && (
                <IonCol size={8}>
                  <SelectionDetail>
                    <p>{ people[input.residualPeople.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <IonRow>
            <IonCol size={4}>
              <SettingField>
                <h5>Operations</h5>
                <Select
                  placeholder='Select Operations'
                  name='residualOperations'
                  id='ra-residual-operations-impact'
                  className='select-container'
                  value={input.residualOperations}
                  minMenuHeight={70}
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={Object.keys(operations).map((level) => ({ label: level, value: level }))}
                  onChange={(selected) => setInput({ ...input, residualOperations: selected, edit: true })}
                />
              </SettingField>
            </IonCol>
            {
              input.residualOperations && (
                <IonCol size={8}>
                  <SelectionDetail>
                    <p>{ operations[input.residualOperations.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
          <IonRow>
            <IonCol size={4}>
              <SettingField>
                <h5>Property</h5>
                <Select
                  placeholder='Select Property'
                  id='ra-residual-property-impact'
                  name='residualProperty'
                  className='select-container'
                  value={input.residualProperty}
                  minMenuHeight={70}
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={Object.keys(property).map((level) => ({ label: level, value: level }))}
                  onChange={(selected) => setInput({ ...input, residualProperty: selected, edit: true })}
                />
              </SettingField>
            </IonCol>
            {
              input.residualProperty && (
                <IonCol size={8}>
                  <SelectionDetail>
                    <p>{ property[input.residualProperty.value] }</p>
                  </SelectionDetail>
                </IonCol>
              )
            }
          </IonRow>
        </div>
        <IonRow className='ion-justify-content-end'>
          <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
          <IonButton
            style={{ '--background': '#326771' }}
            onClick={next}
            disabled={!(input.residualPeople && input.residualOperations && input.residualProperty)}
          >Next
          </IonButton>
        </IonRow>
      </IonList>
    </IonContent>
  </CenterForm>
)

export default ResidualImpact
