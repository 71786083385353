import { IonRow, IonCol } from '@ionic/react'
import * as React from 'react'
import { useChatsData } from '../../hooks/chats/useChatsData'
import { useNotification } from '../../hooks/useNotification'
import { ObscureBackground } from '../GlobalContainers'
import LoadingModal from '../modals/LoadingModal'
import { User } from '../types/GlobalTypes'
import Conversation from './Conversation'
import DefaultScreen from './DefaultScreen'
import AddChat from './popups/AddChat'
import AddUsers from './popups/AddUsers'
import DeleteChat from './popups/DeleteChat'
import EditChatName from './popups/EditChatName'
import LeaveGroup from './popups/LeaveGroups'
import RemoveUser from './popups/RemoveUser'
import TabBarChats from './TabBarChats'

const AppWrapper : React.FC = () => {
  const {
    showLeaveGroup, setShowLeaveGroup, focusedRoom,
    setShowAddChat, fetchData, loading, showAddChat,
    removeGroupUser, showDeleteChat, setShowDeleteChat,
    showEditName, showAddUsers, refreshRooms,
  } = useChatsData()
  const [userToDelete, setUserToDelete] = React.useState<User | null>(null)
  const { notifications } = useNotification()

  const handleRemoveUser = () => {
    setUserToDelete(null)
    removeGroupUser(userToDelete)
  }
  React.useEffect(() => {
    fetchData()
  }, [])

  React.useEffect(() => {
    if (!notifications || notifications.length < 2) return
    const newestNoti = notifications.reduce((a, b) => (a.id > b.id ? a : b))
    if (newestNoti.title !== 'New Chat Message') return
    refreshRooms()
  }, [notifications])

  return (
    <IonRow class='ion-justify-content-start' style={{ height: '100%', backgroundColor: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
      <LoadingModal isOpen={loading} />
      {
        showLeaveGroup && (
          <>
            <ObscureBackground />
            <LeaveGroup onClose={() => setShowLeaveGroup(false)} />
          </>
        )
      }
      {
        userToDelete && (
          <>
            <ObscureBackground />
            <RemoveUser onCancel={() => setUserToDelete(null)} onConfirm={handleRemoveUser} />
          </>
        )
      }
      {
        showAddChat && (
          <>
            <ObscureBackground />
            <AddChat />
          </>
        )
      }
      {
        showDeleteChat && (
          <>
            <ObscureBackground />
            <DeleteChat onClose={() => setShowDeleteChat(false)} />
          </>
        )
      }
      {
        showEditName && (
          <>
            <ObscureBackground />
            <EditChatName />
          </>
        )
      }
      {
        showAddUsers && (
          <>
            <ObscureBackground />
            <AddUsers />
          </>
        )
      }
      <IonCol
        class='ion-justify-content-start'
        style={{
          height: '100%', width: '10em', padding: '0', margin: '0',
        }}
        size='3'
      >
        <TabBarChats />
      </IonCol>
      <IonCol
        class='ion-justify-content-end'
        style={{
          height: '100%', padding: '0', margin: '0', display: 'flex', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        }}
        size='9'
      >
        {
          focusedRoom ? (
            <Conversation room={focusedRoom} onDeleteUser={(user) => setUserToDelete(user)} />
          ) : (
            <DefaultScreen onOpen={() => setShowAddChat(true)} />
          )
        }
      </IonCol>
    </IonRow>
  )
}

export default AppWrapper
