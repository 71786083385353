import styled from 'styled-components'

const CenterForm = styled.div`
  background-color: rgb(255, 255, 255);
  --ion-background-color: white;
  box-shadow: 0px 0px 13px -2px #000000;
  width: 45%;
  height: 95%;
  position: absolute;
  z-index: 12;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.asset-profile {
    max-width: 450px;
    max-height: 300px;
  }

  & h4.red-font-color {
    color: #8E151F;
  }
`

const SelectionDetail = styled.div`
  padding: 10px;
  border: 1px solid #747474;
  border-radius: 2.5px;
  margin: 10px;
`

const MeasureContainer = styled.div`
  padding: 10px;
  width: 100%;
  
  & p {
    margin: 0;
  }
`

const ColumnContainer = styled.div`
  height: 250px;
  width: 100%;
  border: 1px solid #747474;
  overflow-y: auto;
  cursor: pointer;
`

const SelectMeasures = styled.select`
  padding: 5px;
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 2.5px;
`

const RiskPoint = styled.div`
  width: 30%;
  height: 30%;
  position: absolute;
  border-radius: 50%;
  background-color: #4197A9;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
`

const PopupContainer = styled.div`
  width: 100%;
  max-width: 280px;
  padding: 0;

  & .title {
    background-color: #8E151F;
    padding: 10px;
    color: white;
  }

  & p.key-asset {
    color: #326771;
    padding: 10px 25px;
  }

  & .content {
    padding: 15px;
  }
`

const ControlMeasureList = styled.ul`
  max-height: 100px;
  overflow-y: auto;
  padding-left: 1rem;

  & li {
    margin: 5px 0;
  }
`

const ZoneTypeButton = styled.button`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: transparent;
  align-items: center;
  text-align: left;
  border: none;

  & .zone-type-text {
    padding: 0 15px;
  }

  & h6 {
    font-size: 0.9rem;
    margin-bottom: 10px;
    color: #326771;
    font-weight: bold;
  }

  & img {
    height: 90px;
    height: auto;
  }

  & p {
    margin: 0;
  }
`

export {
  CenterForm,
  SelectionDetail,
  MeasureContainer,
  ColumnContainer,
  SelectMeasures,
  RiskPoint,
  PopupContainer,
  ControlMeasureList,
  ZoneTypeButton,
}
