import * as React from 'react'
import { useState } from 'react'
import {
  IonLabel, IonButton, IonRow, IonGrid,
  IonCol, IonContent, IonPage, IonInput,
  useIonAlert,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import PageHeader from '../PageHeader'
import { SettingsContainer } from '../settings/StyledContainers'

import useApi from '../../hooks/testHooks'

import { useReportsData } from '../../hooks/reports/useReportsData'

import WeeklyReportHandler from '../popups/WeeklyReportHandler'

import { Incident } from '../types/GlobalTypes'

import moment from 'moment'

const WeeklyReports : React.FC<{}> = () => {
  const {
    showSecurityReport,
    setShowSecurityReport,
    incidents,
    setShowReports,
    setShowHome,
    name,
    setName,
    county,
    setCounty,
    setState,
    setIncident,
    showWeeklyReport,
    setShowWeeklyReport,
    setShowWeeklys,
    dates,
  } = useReportsData()

  const [presentAlert] = useIonAlert()

  const [idx, setIdx] = useState<Number>(0)

  const apiHook = useApi()

  function handleWeeklyClick(index: Number) {
    setShowWeeklyReport(true)
    setIdx(index)
  }

  function handleBack() {
    setShowWeeklyReport(false)
    setShowWeeklys(false)
    setShowHome(true)
  }

  const rows = (arr) => arr.reduce((acc, curr, i) => {
    if (!(i % 6)) { // if index is 0 or can be divided by the `size`...
      acc.push(arr.slice(i, i + 6)) // ..push a chunk of the original array to the accumulator
    }
    return acc
  }, [])

  const rowArray : Array<Array<Incident>> = rows(incidents)

  return (
    <>
      <IonPage>
        <PageHeader title="Reports" />
        <IonContent style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
          <SettingsContainer>
            <IonRow class="ion-justify-content-center" style={{ height: '100%', backgroundColor: '#FFFFFF', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)', width: '100%' }}>
              <IonGrid class="ion-justify-content-center" style={{ marginLeft: '4em' }}>
                <IonGrid class="ion-justify-content-center">
                  <IonRow class="ion-align-items-center" style={{ width: '95%', height: '15%', backgroundColor: '#FFFFFF', border: '1px', borderStyle: 'none none solid none' }}>
                    <IonCol class='ion-justify-content-start' style={{ width: '70%' }}>
                      <IonButton onClick={handleBack} fill="clear">
                          <Icon  icon="ep:arrow-left" style={{ fontSize: '1.8em', color: 'grey' }}/>
                      </IonButton>
                      <IonButton fill="clear">
                          <Icon icon="ep:arrow-right" style={{ fontSize: '1.8em', color: 'grey' }}/>
                      </IonButton>
                      <IonLabel style={{ marginLeft: '1em', marginRight: '1em', paddingTop: '2em', fontSize: '0.9em', color: 'black' }}>Weekly Reports</IonLabel>
                      <Icon icon="ant-design:folder-open-filled" style={{ fontSize: '1.8em', color: '#326771', marginTop: '0.3em' }}/>
                    </IonCol>
                    <IonCol class='ion-justify-content-end'>
                      <IonRow class="ion-align-items-center">
                          <IonCol class='ion-justify-content-start'>
                              <IonInput class="ion-justify-content-start" placeholder="Search" style={{ height: '2em', border: '1px solid', borderRadius: '2.5px' }}></IonInput>
                          </IonCol>
                          <IonCol class='ion-justify-content-end'>
                              <IonButton class="ion-justify-content-end" fill="clear" size="large" style={{ color: 'grey' }}>
                                  <Icon icon="ph:magnifying-glass-thin"></Icon>
                              </IonButton>
                          </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  <IonRow class="ion-align-items-center">
                    <IonCol class="ion-align-items-center">
                      <IonLabel style={{ color: 'black' }}>Name</IonLabel>
                      <IonButton size="small" fill="clear" style={{ color: '#326771', marginRight: '2em', paddingBottom: '0.9em' }}>
                        <Icon icon="bxs:down-arrow"></Icon>
                      </IonButton>
                      <IonLabel style={{ color: 'black' }}>Last modified</IonLabel>
                      <IonButton size="small" fill="clear" style={{ color: '#326771', paddingBottom: '0.9em' }}><Icon icon="bxs:down-arrow"></Icon></IonButton>
                      <IonButton class="ion-align-items-center" fill='clear' style={{ marginBottom: '1em', paddingBottom: '0.4em', marginLeft: '2em', textTransform: 'none', letterSpacing: '-0.02em' }}>
                        <Icon icon="bi:list-ul" style={{ fontSize: '1.8em', color: '#326771', marginTop: '0.3em' }}/>
                        <IonLabel style={{ color: 'black', paddingTop: '0.4em', paddingLeft: '0.4em', fontWeight: 'normal', fontSize: '1.1em' }}>List view</IonLabel>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <IonContent style={{height: '67%', '--ion-background-color':'#FFFFFF'}}>
                  <IonGrid>
                    <IonRow style={{marginTop: '0em'}}>
                      <IonCol>
                        {
                          dates.map(
                            (date,index) => (
                                <IonButton onClick={() => handleWeeklyClick(index)} fill="clear" style={{border: '1px solid', borderColor: '#747474', borderRadius: '0.2em', backgroundColor: '#F9F9F9', width: '10em', height: '10em', textTransform: 'none', letterSpacing: '-0.02em', marginRight: '1.8em', marginBottom: '0.5em'}}>
                                  <IonGrid class="ion-align-items-center">
                                    <IonRow>
                                      <IonCol>
                                        <Icon icon="akar-icons:file" style={{fontSize: '5em', color: '#326771', marginBottom: '0.1em'}}></Icon>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol>
                                        <IonLabel style={{color: 'black', fontSize: '1.1em', fontWeight: 'normal', margin: '0.2em 0em', 'text-overflow': 'ellipsis' }}>
                                          {moment(date).format('DDMMYY') + '_report'}
                                        </IonLabel>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonButton>
                            ),
                          )
                        }

                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonContent>

              </IonGrid>
            </IonRow>
          </SettingsContainer>
        </IonContent>
        {
          showWeeklyReport && (
            <WeeklyReportHandler
              idx={idx}
            />
          )
        }
      </IonPage>
    </>
  )
}

export default WeeklyReports
