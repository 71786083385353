import React from 'react'
import {
  IonContent, IonListHeader, IonItem, IonInput, IonDatetime, IonRow,
  IonButton,
} from '@ionic/react'

import { FormTitle } from '../../../maps/StyledContainers'

/**
 *  ===========================
 *      DEPARTURE FIELDS
 *  ===========================
 */
const Departure = ({
  handleInput, input, children, onBack, onNext,
}) => (
  <IonContent
    style={{
      '--ion-background-color': 'white',
      '--padding-bottom': '15px',
      '--padding-top': '15px',
      '--padding-end': '15px',
    }}
  >
    {
      children
    }
    <IonListHeader>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Departure</h3>
      </FormTitle>
    </IonListHeader>
    <IonItem>
      <IonInput onIonChange={handleInput} value={input.from} name='from' type='text' placeholder='Where are you travelling from?' />
    </IonItem>
    <IonDatetime
      name='departureTime'
      value={input.departureTime}
      onIonChange={handleInput}
      display-format='YYYY-MM-DDTHH:mm'
      picker-format='DD:MM:YYYYTHH:mm'
      placeholder='YYYY-MM-DD HH:mm'
      minuteValues='0,15,30,45'
    />
    <IonListHeader>
      <FormTitle>
        <h3 style={{ fontSize: '1rem' }}>Arrival (estimate)</h3>
      </FormTitle>
    </IonListHeader>
    <IonItem>
      <IonInput onIonChange={handleInput} value={input.to} name='to' type='text' placeholder='Where are you travelling to?' />
    </IonItem>
    <IonDatetime
      name='arrivalTime'
      value={input.arrivalTime}
      onIonChange={handleInput}
      display-format='YYYY-MM-DDTHH:mm'
      picker-format='DD:MM:YYYYTHH:mm'
      placeholder='YYYY-MM-DD HH:mm'
      minuteValues='0,15,30,45'
    />
    <IonRow style={{ paddingLeft: '15px', marginTop: '15px' }} className='ion-justify-content-between'>
      <IonButton style={{ '--background': '#4197A9' }} onClick={onBack}>Back</IonButton>
      <IonButton style={{ '--background': '#326771' }} onClick={onNext}>Next</IonButton>
    </IonRow>
  </IonContent>
)

export default Departure
