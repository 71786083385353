import React, { useState } from 'react'
import {
  IonRow, IonCol, IonGrid, IonCheckbox, IonButton,
} from '@ionic/react'
import { SettingField } from '../settings/StyledContainers'
import { CenterForm, ColumnContainer } from '../riskregister/StyledContainers'
import { getCurrentLevel } from '../maps/apps/AppMap'
/**
 *  =================================
 *      SELECT COUNTIES TO UPDATE
 *  =================================
 */
const CountySelection = ({
  counties, newAlertState, setNewAlertState, setCounties, onBack, next,
}) => {
  const [toggleAll, setToggleAll] = useState(counties.every(({ selected }) => selected))
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  const handleSelection = (e, county, index) => {
    const copy = counties.slice()
    if (!e.target.checked) {
      setToggleAll(false)
    }
    copy.splice(index, 1, { ...county, selected: e.target.checked })
    if (copy.every(({ selected }) => selected)) { setToggleAll(true) }
    setCounties(copy)
  }

  const handleToggleAll = (e) => {
    const copy = counties.map((county) => ({ ...county, selected: e.target.checked }))
    setCounties(copy)
    setToggleAll(e.target.checked)
  }

  return (
    <CenterForm style={{ width: '85%', height: 'auto', padding: '10px' }}>
      <IonRow style={{ height: '100%' }}>
        <IonCol size={8}>
          <IonGrid style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center'>
              <IonCol size={1}> <IonCheckbox checked={toggleAll} onClick={(e) => handleToggleAll(e)} /> </IonCol>
              <IonCol size={3}> State </IonCol>
              <IonCol size={4}> County </IonCol>
              <IonCol size={4}> Current Alert State </IonCol>
            </IonRow>
            <ColumnContainer>
              {
              counties.map((county, index) => (
                <IonRow key={county.id} className='ion-align-items-center' style={{ backgroundColor: getColor(index), minHeight: '10%' }}>
                  <IonCol size={1}> <IonCheckbox checked={county.selected} onClick={(e) => handleSelection(e, county, index)} /> </IonCol>
                  <IonCol size={3}> { county.region } </IonCol>
                  <IonCol size={4}> { county.county } </IonCol>
                  <IonCol size={4}> Alert State { county.Alert_state } - {getCurrentLevel(county.Alert_state).name}` </IonCol>
                </IonRow>
              ))
            }
            </ColumnContainer>
          </IonGrid>
        </IonCol>
        <IonCol size={4}>
          <div className='risk-assessment-list'>
            <SettingField>
              <h4>Edit Selection</h4>
              <h5 style={{ marginTop: '15px' }}> Current Alert State </h5>
              <select
                style={{ maxWidth: '100%', padding: '5px' }}
                value={newAlertState}
                onChange={(e) => setNewAlertState(e.target.value)}
              >
                <option value='mixed' disabled>Mixed</option>
                <option value={1}>Alert State 1 - {getCurrentLevel(1).name}</option>
                <option value={2}>Alert State 2 - {getCurrentLevel(2).name}</option>
                <option value={3}>Alert State 3 - {getCurrentLevel(3).name}</option>
                <option value={4}>Alert State 4 - {getCurrentLevel(4).name}</option>
                <option value={5}>Alert State 5 - {getCurrentLevel(5).name}</option>
              </select>
              <p style={{ margin: '5px 0' }}>{(newAlertState === 'mixed') ? 'Note: Selected items have a mixed range of Alert States.'
                : `Selected items will be updated to Alert State ${newAlertState} - ${getCurrentLevel(newAlertState).name}`}
              </p>
            </SettingField>
            <IonRow className='ion-justify-content-between'>
              <IonButton style={{ '--background': '#8E151F' }} onClick={onBack}>Cancel</IonButton>
              <IonButton style={{ '--background': '#326771' }} onClick={next} disabled={newAlertState === 'mixed'}>Next</IonButton>
            </IonRow>
          </div>
        </IonCol>
      </IonRow>
    </CenterForm>
  )
}

export default CountySelection
