/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useState, useRef } from 'react'
import { IonLoading, useIonAlert, IonList } from '@ionic/react'
import moment from 'moment'
import FormPopup from '../forms/FormPopup'
import IncidentsForm from '../forms/IncidentsForm'

// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'

import MapForm from '../forms/MapForm'
import MobileIncident from '../forms/MobileIncident'
import testHook from '../../../hooks/testHooks'
import { useAuth } from '../../../hooks/useAuth'
import { useWorkspace } from '../../../hooks/useWorkspace'

/**
 *  ======================
 *    Submit an Incident
 *  ======================
 */
const Incident = ({
  mapRef, setShowIncidentF, incidentList, setIncidentList,
  setView, updateIncidents, incidentFilters, onClose, changeView, isMobile,
  markers, setMarkers, setShowAddButton,
}) => {
  /* Hard coded ids for the new created incidents */
  const [submitted, setSubmitted] = useState(false)

  /* Form inputs */
  const [input, setInput] = useState({
    incident_type: '',
    date: undefined,
    fatalities: 0,
    description: '',
    county_id: null,
    nearest: '',
  })
  const [loading, setLoading] = useState(false)

  const apiHook = testHook()
  const { workspace } = useWorkspace()
  const { user } = useAuth()
  const [ionAlert] = useIonAlert()
  /* Location of the incident -> Pin on the map */
  const [location, setLocation] = useState([])
  const [showInstructions, setShowInstructions] = useState(true)
  const [pick, setPick] = useState(true)

  /**
   *  ===========================
   *      HANDLE CLICK ON MAP
   *  ===========================
   */
  const handleClick = (e) => {
    /* Center map on click */
    mapRef.current.easeTo({ center: [e.lngLat.lng, e.lngLat.lat], zoom: (mapRef.current.getZoom() < 9) ? 9 : mapRef.current.getZoom() })

    if (!pick) return

    setShowInstructions(true)
    /* Place new marker on the specified zone */
    const marker = new mapboxgl.Marker({ color: 'rgb(109, 0, 235)' })
      .setLngLat(Object.values(e.lngLat))
      .addTo(mapRef.current)

    /* Add draggable functionalities */
    marker.setDraggable(true)
    marker.on('drag', () => {
      setLocation([marker.getLngLat().lng, marker.getLngLat().lat])
    })

    setMarkers([...markers, marker])

    /* Set initial location */
    setLocation(Object.values(e.lngLat))
    setPick(false)
  }

  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  const onCancel = (typed) => {
    const close = () => {
      setShowIncidentF(false)
      mapRef.current.easeTo({
        padding: {
          right: 0,
        },
      })

      if (markers.length) markers[markers.length - 1].remove()

      if (onClose) onClose()
    }
    if (typed) {
      ionAlert({
        header: 'Cancel Incident Report?',
        message: 'Do you wish to continue? Your progress will be lost. ',
        buttons: [
          'Back',
          {
            text: 'Yes, continue', handler: close,
          },
        ],
      })

      return
    }

    close()
  }

  const closeForm = () => {
    setShowIncidentF(false)
    if (onClose) onClose()
  }

  const finishIncidentForm = (incident) => {
    /* Remove draggable functionality */
    if (markers.length) markers[markers.length - 1].remove()

    setLoading(false)
    setLocation([])

    if (incident) {
      incidentList.push(incident)
      setIncidentList(incidentList)
    }

    mapRef.current.easeTo({
      padding: {
        right: 0,
      },
    })

    if (isMobile) {
      setShowIncidentF(false)
      return
    }
    setView('Incidents')
    updateIncidents(Object.fromEntries(Object.keys(incidentFilters).map((key) => [key, true])), incidentList)
    setSubmitted(true)
  }

  const returnMessage = (key) => ({
    incident_type: 'Please specify an incident type',
    date: 'Please indicate the date of the incident',
    fatalities: 'please select a number of fatalities',
    nearest: 'Please specify the nearest location to where the event occurred.',
  }[key])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!location.length) {
      return ionAlert({
        header: 'Error',
        message: 'No location selected, please drop a pin on the map',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    const notFilled = Object.keys(input).find((key) => input[key] === null || input[key] === '')

    if (notFilled && returnMessage(notFilled)) {
      return ionAlert({
        header: 'Error',
        message: returnMessage(notFilled),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    if (!input.incident_type) {
      return ionAlert({
        header: 'Error',
        message: returnMessage('incident_type'),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    if (input.nearest === '') {
      return ionAlert({
        header: 'Error',
        message: returnMessage('nearest'),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    if (!isMobile) {
      const { date } = input
      // eslint-disable-next-line no-param-reassign
      input.date = date.format('YYYY-MM-DD HH:mm')
    } else {
      const { date } = input
      // eslint-disable-next-line no-param-reassign
      input.date = moment(date).format('YYYY-MM-DD HH:mm')
    }

    setLoading(true)
    apiHook.tryIncident(input.name, input.description, location[0], location[1], input.date, input.incident_type, input.fatalities,
      workspace.id, input.county_id, input.nearest, workspace.id).then(({ id }) => {
      finishIncidentForm({
        ...input, reported: input.date, longitude: location[0], latitude: location[1], id, user: { first_name: user.first_name, final_name: user.final_name },
      })
      if (setShowAddButton) setShowAddButton(true)
    })
    return true
  }

  /* Determine county when dropping marker */
  const handleFindCounty = (e) => {
    if (e.features.length === 0) return

    /* Get County details */
    const { properties } = e.features[0]
    setInput({ ...input, county_id: properties.id, countyName: properties.ADM2_EN })
  }
  const handleFindCountyRef = useRef(handleFindCounty)
  handleFindCountyRef.current = handleFindCounty

  /* Determine region when dropping marker */
  const handleFindRegion = (e) => {
    if (e.features.length === 0) return

    /* Get Region details */
    const { properties } = e.features[0]
    setInput({ ...input, regionName: properties.shapeName })
  }
  const handleFindRegionRef = useRef(handleFindRegion)
  handleFindRegionRef.current = handleFindRegion

  useEffect(() => {
    const callback = (e) => {
      handleClickRef.current(e)
    }
    if (changeView) changeView()
    mapRef.current.on('click', callback)

    const countiesCallback = (e) => {
      handleFindCountyRef.current(e)
    }

    const regionsCallback = (e) => {
      handleFindRegionRef.current(e)
    }

    mapRef.current.on('mouseup', 'counties-background', countiesCallback)
    mapRef.current.on('mouseup', 'regions-background', regionsCallback)

    return () => {
      mapRef.current.off('click', callback)
      mapRef.current.off('mouseup', 'counties-background', countiesCallback)
      mapRef.current.off('mouseup', 'regions-background', regionsCallback)
    }
  }, [])
  return (
    <>
      <IonLoading
        isOpen={loading}
      />
      {
        (isMobile) ? (
          <MobileIncident
            onClose={finishIncidentForm}
            location={location}
            onCancel={onCancel}
            handleSubmit={handleSubmit}
            showInstructions={showInstructions}
            setShowInstructions={setShowInstructions}
            input={input}
            setInput={setInput}
            setShowAddButton={setShowAddButton}
          />
        ) : (
          <MapForm>
            {
              (!submitted) ? (
                <IncidentsForm
                  handleSubmit={handleSubmit}
                  loading={loading}
                  location={location}
                  onCancel={onCancel}
                  input={input}
                  setInput={setInput}
                />
              ) : (
                <IonList style={{ padding: '20px', height: (submitted) ? '100%' : 'auto' }}>
                  <FormPopup
                    message='You have successfully submitted an incident.'
                    onClose={closeForm}
                  />
                </IonList>
              )
            }
          </MapForm>
        )
      }
    </>
  )
}

export default Incident
