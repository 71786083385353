import styled from 'styled-components'

const ControlContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    text-align: right;
    padding: 5px 15px;
`

const Dropdown = styled.div`
    padding: 10px;
    border-radius: 10px;
    position: relative;
    background-color:rgba(0,0,0,0.3);
    display: grid;
`

const FilterOption = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    color: white!important;
`

const SelectView = styled.select`
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #326771;
    color: white;
`

const RegisterContainer = styled.div`
  padding: 30px 20px;
  background: #FDFDFD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  min-width: 270px;
  --ion-item-background: white;
`

const FormTitle = styled.div`
    margin-bottom: 0;
    margin-top: 20px;
    color: #326771;
    font-size: 1.2rem;
`

const MapInstructions = styled.div`
    padding: 10px;
    position: relative;
    height: 100%;

    h2 {
        margin-top: 15px;
        font-size: 1.5rem;
    }

    p {
        font-size: .9rem;
    }
`

const InstructionButtons = styled.div`
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px 20px 0;
`

const TicketEntry = styled.div`
    color: #4197A9;
    & p{
        margin: 0;
        margin-top: 5px;
    }
`

const TicketContainer = styled.div`
    padding: 10px 20px;
    border-bottom: 1px dashed #4197A9;
    border-top: 1px dashed #4197A9;
`

const SimpleButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: none;
  font-size: 1rem;
  color: #326771;

  &.small-fontsize {
    font-size: .8rem;
  }
`

const StoryLine = styled.ul`
    margin: 10px 0;
`

const PendingJourney = styled.p`
    padding: 10px;
    margin: 0;
    background: aliceblue;
    border-radius: 5px;
`

const SelectIncidentType = styled.select`
    max-width: 50%;
    padding: 5px;
    border: none;
    cursor: pointer;
`

const PopupContainer = styled.div`
    background: #FFFFFF;
    padding: 20px;
    width: 100%;
    text-align: center;
`

const DateSliderContainer = styled.div`
    height: 15%;
    width: 100%;
    padding: 0 30px;
`

const SelectYear = styled.select`
    background-color: #326771;
    border-radius: 2.5px;
    padding: 5px 10px;
    color: white;
`

const PromptText = styled.p`
  font-size: .8rem;
  margin: 0;

  & svg {
      color: #14C105;
  }
`

const MobileDropdown = styled.select`
    background: transparent;
    color: #326771;
    border: none;
    font-size: 1rem;
`

const ScrollableList = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`

const MobileListItem = styled.button`
    background: #F1F1F1;
    width: 90%;
    display: block;
    margin: 10px auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;

    & h5 {
        margin: 0;
    }
`

const PopoverIcon = styled.div`
    color: white;
    padding: 5px;
    font-size: 1.5rem;
    border-radius: 50%;
    display: flex;
    margin-left: 10px;

    &.option-green {
        background: #14C105;
    }

    &.option-yellow {
        background: #FF8A00;
    }

    &.option-red {
        background: #C30101;
    }
`

const AirJourneyStageContainer = styled.div`
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

    & button.air-journey-button {
        color: #C4C4C4;
        padding: 15px;
        width: 100%;
    }

    & button h3 {
        margin: 0;
    }

    & button.air-journey-button.available {
        color: #8E151F;
    }
`

const AirTicketButton = styled.button`
    border: none;
    margin: 0 5px;
    border-radius: 2.5px;

    &.not-boarded {
        color: #FF8A00;
        border: 0.5px solid #FF8A00;
        background-color: white;
    }

    &.boarded {
        color: white;
        background-color: #FF8A00;
    }

    &.not-arrived {
        color: #0C9500;
        background-color: white;
        border: 0.5px solid #0C9500;
    }

    &.arrived {
        color: white;
        background-color: #0C9500;
    }
`

const RouteTypeButton = styled.button`
    padding: 10px;
    border: 0.5px solid #747474;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2.5px;
    width: 100%;
    margin: 10px 0;
    text-align: left;

    & .road-icon {
        font-size: 2.5rem;
    }

    & div {
        margin: 0 10px;
    }
`

export {
  ControlContainer,
  Dropdown,
  FilterOption,
  SelectView,
  RegisterContainer,
  FormTitle,
  MapInstructions,
  InstructionButtons,
  TicketEntry,
  TicketContainer,
  SimpleButton,
  StoryLine,
  PendingJourney,
  SelectIncidentType,
  PopupContainer,
  DateSliderContainer,
  SelectYear,
  PromptText,
  MobileDropdown,
  ScrollableList,
  MobileListItem,
  PopoverIcon,
  AirJourneyStageContainer,
  AirTicketButton,
  RouteTypeButton,
}
