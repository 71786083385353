import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow, IonGrid,
  IonCol,
} from '@ionic/react'

import moment from 'moment'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { useReportsData } from '../../hooks/reports/useReportsData'
import { SimpleButton } from '../maps/StyledContainers'
import { useWorkspace } from '../../hooks/useWorkspace'

import useApi from '../../hooks/testHooks'

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const WeeklyReport5 = ({
  step, setStep, setIsShowing, journeys, idx, end,
}) => {
  const { workspace } = useWorkspace()

  const {
    setShowWeeklyReport,
  } = useReportsData()

  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const handleClose = () => {
    setIsShowing(false)
    setShowWeeklyReport(false)
    setStep(1)
  }

  const apiHook = useApi()

  const handleDownload = () => {
    apiHook.weeklyReportPDF({
      domain_id: workspace.id,
      index: idx,
    })
      .then((result) => {
        apiHook.downloader2(result, `Weekly_Report_${moment(end).format('DD/MM/YY')}`)
      })
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '95%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Weekly Report - ${workspace.name}`}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonList>
          <IonRow style={{ marginLeft: '3.3em', marginTop: '1em' }}>
            <IonCol style={{ textAlign: 'left' }}>
              <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.15em' }}>Scheduled Journeys (Next 7 Days)</IonLabel>
            </IonCol>
          </IonRow>
        </IonList>

        <IonGrid class='ion-justify-content-center' style={{ width: '90%', height: '70%' }}>
          <IonRow style={{ marginLeft: '0.6em' }}>

            <IonCol
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>Start</IonLabel>
            </IonCol>

            <IonCol
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>End</IonLabel>
            </IonCol>

            <IonCol
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>Requested By</IonLabel>
            </IonCol>

            <IonCol
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>Departure Date</IonLabel>
            </IonCol>

            <IonCol
              style={{
                textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em', backgroundColor: '#8E151F', color: 'white',
              }}
            >
              <IonLabel>Arrival Date</IonLabel>
            </IonCol>

          </IonRow>

          <IonRow style={{ marginLeft: '0.6em' }}>
            <>
              {journeys.length === 0 && (
                <IonRow>
                  <IonLabel style={{ marginTop: '1em', fontWeight: 'bold', fontSize: '1em' }}>There are no scheduled journeys at this moment.</IonLabel>
                </IonRow>
              )}

              {journeys.length > 0 && (
                journeys.map(
                  (journey) => (
                    <IonRow style={{ marginLeft: '0.6em' }}>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      >
                        <IonLabel>
                          {`${journey.departure_location}`}
                        </IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      >
                        <IonLabel>
                          {`${journey.arrival_location}`}
                        </IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      >
                        <IonLabel>
                          {`${journey.submitter}`}
                        </IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      >
                        <IonLabel>
                          {moment(journey.departure_time).format('DD/MM/YY')}
                        </IonLabel>
                      </IonCol>
                      <IonCol style={{
                        textAlign: 'left', fontSize: '1em', border: '0.2px solid', borderColor: 'grey', paddingLeft: '0.6em', height: '2.3em',
                      }}
                      >
                        <IonLabel>
                          {moment(journey.arrival_time).format('DD/MM/YY')}
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                  ),
                )
              )}
            </>
          </IonRow>
        </IonGrid>

        <IonRow style={{}} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default WeeklyReport5
