import React from 'react'
import {
  IonGrid, IonCol, IonRow, IonCheckbox, IonButton,
} from '@ionic/react'
import { CenterForm, ColumnContainer } from '../../riskregister/StyledContainers'
import { SettingField } from '../../settings/StyledContainers'

/**
 *  ==========================================
 *      COMMON Category view Questionnaire
 *  ==========================================
 */
const CategoryView = ({
  survey, setSurvey, back, next, onCancel, counties, categoryName, last,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
  const updateKeyphrase = (e, id) => {
    /* Copy array and update keyphrase depending on the event's data */
    const copy = survey.slice()
    const index = survey.findIndex((val) => val.id === id)
    copy.splice(index, 1, { ...copy[index], ticked: e.target.checked })

    setSurvey(copy)
  }
  return (
    <CenterForm style={{ padding: '20px', width: '50%', height: '85%' }}>
      <div className='risk-assessment-list'>
        <div>
          <SettingField style={{ width: 'auto' }}>
            <h4>Insight Report</h4>
            <p>The following is a suggestion report on the next counties: { counties.map(({ county }) => (county)).join(', ') }</p>
            <div style={{ margin: '25px 0' }}>
              <h5 className='blue'>{ categoryName }</h5>
              <p>Tick the boxes that are relevant to activity ongoing in the chosen county/counties.</p>
            </div>
          </SettingField>
          <IonGrid>
            <ColumnContainer style={{ maxHeight: '225px', height: 'auto' }}>
              {
                survey.filter(({ category }) => category === categoryName).map((phrase, index) => (
                  <IonRow key={phrase.id} className='ion-align-items-center' style={{ backgroundColor: getColor(index), minHeight: '10%' }}>
                    <IonCol size={11}> { phrase.tag } </IonCol>
                    <IonCol size={1}>
                      <IonCheckbox checked={phrase.ticked} onClick={(e) => updateKeyphrase(e, phrase.id)} />
                    </IonCol>
                  </IonRow>
                ))
              }
            </ColumnContainer>
          </IonGrid>
        </div>
        <IonRow className='ion-justify-content-between'>
          <IonButton style={{ '--background': '#8E151F' }} onClick={onCancel}>Cancel</IonButton>
          <IonRow>
            <IonButton style={{ '--background': '#4197A9' }} onClick={back}>Prev</IonButton>
            {
              (last) ? (
                <IonButton style={{ '--background': '#0C9500' }} disabled={survey.filter(({ category, ticked }) => category === categoryName && ticked).length <= 0} onClick={next}>Submit</IonButton>
              ) : (
                <IonButton style={{ '--background': '#326771' }} disabled={survey.filter(({ category, ticked }) => category === categoryName && ticked).length <= 0} onClick={next}>Next</IonButton>
              )
            }
          </IonRow>
        </IonRow>
      </div>
    </CenterForm>
  )
}

export default CategoryView
