/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'

import { IonRow } from '@ionic/react'

import moment from 'moment'
import RiskSelection from './assessment/RiskSelection'
import InherentThread from './assessment/InherentThreat'
import InherentImpact from './assessment/InherentImpact'
import Summary from './assessment/Summary'

import { SettingField } from '../../settings/StyledContainers'
import { SimpleButton } from '../../maps/StyledContainers'
import ExistingMeasures from './assessment/ExistingMeasures'
import NewMeasures from './assessment/NewMeasures'
import ResidualImpact from './assessment/ResidualImpact'
import Review from './assessment/Review'
import MeasureSummary from './assessment/MeasureSummary'
import SummaryExisting from './assessment/SummaryExisting'
import InherentVulnerability from './assessment/InherentVulnerability'
import ResidualVulnerability from './assessment/ResidualVulnerability'

import { useWorkspace } from '../../../hooks/useWorkspace'
import { useAuth } from '../../../hooks/useAuth'
import useApi from '../../../hooks/testHooks'

import {
  people, vulnerability, property, operations,
} from './FormOptions'
import RemoveNewMeasures from './assessment/RemoveNewMeasures'
import RemoveExistingMeasures from './assessment/RemoveExistingMeasures'
import {
  getNumericalValue, getImpactCategory, getLikelihoodCategory,
} from './utils/Categories'

import { computeRiskRating, getImpactRating } from './utils/RiskAssessmentCalculations'

/**
 *  ==============================
 *        Risk Assessment Form
 *  ==============================
 */
const RiskAssessmentForm = ({
  onClose, users, onSubmit, riskEvents, controlMeasures, focusedRisk,
  edit, defaultInput, fetchMitigations, categories, focusedAsset,
}) => {
  const [question, setQuestion] = useState(1)
  const [input, setInput] = useState((edit) ? { ...defaultInput, existingMeasures: [], newMeasures: [] } : {
    existingMeasures: [], newMeasures: [],
  })

  /* Api hooks */
  const { workspace } = useWorkspace()
  const apiHook = useApi()
  const { user } = useAuth()

  const moveToNext = () => {
    if ((!edit && question < 13) || (edit && question < 16)) setQuestion(question + 1)
  }

  const formatInputs = () => ({
    inherentVulnerability: input.inherentVulnerability.value,
    inherentImpactCategories: {
      people: input.inherentPeople.value,
      operations: input.inherentOperations.value,
      property: input.inherentProperty.value,
    },
    intent: input.intent.value,
    mitigations: true,
    owner: { ...user },
    threatRating: input.inherentThreatRating,
    riskRating: input.inherentRisk,
    residualRisk: input.residualRisk,
    residualThreatLikelihood: getLikelihoodCategory(input.residualThreatLikelihood),
    inherentThreatLikelihood: getLikelihoodCategory(input.inherentThreatLikelihood),
    impact: input.inherentImpactRating,
    residualImpact: input.residualImpactRating,
    capability: input.capability.value,
    riskEvent: input.riskEvent.value,
    residualImpactCategories: {
      people: input.residualPeople.value,
      operations: input.residualOperations.value,
      property: input.residualProperty.value,
    },
    residualVulnerability: input.residualVulnerability.value,
    newMeasures: input.newMeasures,
    existingMeasures: input.existingMeasures,
    updated: moment().format('DD/MM/YY'),
    visibleId: focusedAsset.id * 100 + focusedAsset.risks.length + 1,
  })

  const handleSubmit = (repeat) => {
    if (edit) {
      const existingAdd = input.existingMeasures.filter((measure) => !defaultInput.existingMeasures.find((val) => val.id === measure.id))
      const existingRemove = defaultInput.existingMeasures.filter((measure) => !input.existingMeasures.find((val) => val.id === measure.id))

      const newAdd = input.newMeasures.filter((measure) => !defaultInput.newMeasures.find((val) => val.id === measure.id))
      const newRemove = defaultInput.newMeasures.filter((measure) => !input.newMeasures.find((val) => val.id === measure.id))

      const formatted = {
        existingAdd: existingAdd.map(({ control_measure }) => control_measure),
        existingRemove: existingRemove.map(({ control_measure }) => control_measure),
        newAdd: newAdd.map(({ control_measure }) => control_measure),
        newRemove: newRemove.map(({ control_measure }) => control_measure),
      }

      onSubmit({ ...formatInputs(), id: defaultInput.id }, focusedAsset, formatted)
      return
    }
    if (repeat) {
      onSubmit(formatInputs(), focusedAsset).then(() => {
        setQuestion(1)
        setInput({
          existingMeasures: [], newMeasures: [], addedExistingMeasures: [], addedNewMeasures: [],
        })
      })
      return
    }
    onSubmit(formatInputs(), focusedAsset).then(() => {
      onClose()
    })
  }

  /* Retrieve mitigations  when form pops up for the first time */
  useEffect(() => {
    if (!edit || defaultInput.mitigations) {
      // create assessment start metric
      try {
        apiHook.createRiskMetric('', workspace.id)
      } catch (e) {
        console.debug(e)
      }
      return
    }
    fetchMitigations(focusedRisk, focusedAsset).then(({
      existingMeasures, newMeasures,
    }) => {
      setInput({
        ...input,
        existingMeasures: [],
        removeExisting: Object.fromEntries(existingMeasures.map((measure) => [measure.id, false])),
        newMeasures,
        removeNew: Object.fromEntries(newMeasures.map((measure) => [measure.id, false])),
      })
    })
  }, [])

  const Header = (
    <IonRow className='ion-justify-content-between'>
      <SettingField style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
        <h4 style={{ margin: '0' }}>Risk {(edit) ? 'Review' : 'Assessment'} - { (input.riskEvent) ? input.riskEvent.value : '' } </h4>
        <p style={{ margin: '0 0 0 10px' }}>{question} of 13</p>
      </SettingField>
      <SimpleButton onClick={() => onClose()}>Close X</SimpleButton>
    </IonRow>
  )

  if (question === 1) {
    return (
      <RiskSelection
        input={input}
        setInput={setInput}
        users={users}
        onClose={onClose}
        edit={edit}
        next={moveToNext}
        riskEvents={riskEvents}
      >
        <IonRow className='ion-justify-content-between'>
          <SettingField style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
            <h4 style={{ margin: '0' }}>Risk {(edit) ? 'Review' : 'Assessment'}</h4>
            <p style={{ margin: '0 0 0 10px' }}>{question} of { (edit) ? '16' : '13' }</p>
          </SettingField>
          <SimpleButton onClick={() => onClose()}>Close X</SimpleButton>
        </IonRow>
      </RiskSelection>
    )
  }

  if ((!edit && question === 2)) {
    return (
      <ExistingMeasures
        input={input}
        setInput={setInput}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        measures={controlMeasures}
        categories={categories}
        edit={edit}
      >
        {
          Header
        }
      </ExistingMeasures>
    )
  }

  if ((!edit && question === 3)) {
    return (
      <SummaryExisting
        input={input}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
      >
        {
          Header
        }
      </SummaryExisting>
    )
  }

  if ((!edit && question === 4) || (edit && question === 2)) {
    return (
      <InherentThread
        input={input}
        setInput={setInput}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        edit={edit}
      >
        {
          Header
        }
      </InherentThread>
    )
  }

  if ((!edit && question === 5) || (edit && question === 3)) {
    return (
      <InherentVulnerability
        input={input}
        setInput={setInput}
        vulnerability={vulnerability}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        edit={edit}
        getCategory={getLikelihoodCategory}
        getNumericalValue={getNumericalValue}
      >
        {
          Header
        }
      </InherentVulnerability>
    )
  }

  if ((!edit && question === 6) || (edit && question === 4)) {
    return (
      <InherentImpact
        input={input}
        setInput={setInput}
        vulnerability={vulnerability}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        people={people}
        property={property}
        operations={operations}
        edit={edit}
      >
        {
          Header
        }
      </InherentImpact>
    )
  }

  if ((!edit && question === 7) || (edit && question === 5)) {
    const impacts = [input.inherentOperations.value, input.inherentPeople.value, input.inherentProperty.value]
    const inherentImpactRating = getImpactRating(impacts)
    const inherentRisk = computeRiskRating(input.inherentThreatLikelihood, impacts)

    return (
      <Summary
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        message='The following is a summary of the Inherent Risk to the current Asset based on your assessments.'
        title='Inherent Risk Assessment - Summary'
        input={input}
        inherent
        measures={{
          risk: inherentRisk, impactRating: getImpactCategory(inherentImpactRating), threatLikelihood: input.inherentThreatLikelihood,
        }}
        onCreate={() => setInput({
          ...input,
          inherentRisk,
          inherentImpactRating: getImpactCategory(inherentImpactRating),
        })}
        getLikelihoodCategory={getLikelihoodCategory}
      >
        {
          Header
        }
      </Summary>
    )
  }

  if (edit && question === 6) {
    return (
      <RemoveNewMeasures
        input={input}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        setInput={setInput}
        measures={controlMeasures}
      >
        {
          Header
        }
      </RemoveNewMeasures>
    )
  }

  if (edit && question === 7) {
    return (
      <RemoveExistingMeasures
        input={input}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        setInput={setInput}
        measures={controlMeasures}
      >
        {
          Header
        }
      </RemoveExistingMeasures>
    )
  }

  if (question === 8) {
    return (
      <NewMeasures
        input={input}
        setInput={setInput}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        measures={controlMeasures}
        categories={categories}
        edit={edit}
      >
        {
          Header
        }
      </NewMeasures>
    )
  }

  if (question === 9) {
    return (
      <MeasureSummary
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        input={input}
      >
        {
          Header
        }
      </MeasureSummary>
    )
  }

  if (question === 10) {
    return (
      <ResidualVulnerability
        input={input}
        setInput={setInput}
        vulnerability={vulnerability}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        edit={edit}
        getCategory={getLikelihoodCategory}
        getNumericalValue={getNumericalValue}
      >
        {
          Header
        }
      </ResidualVulnerability>
    )
  }

  if (question === 11) {
    return (
      <ResidualImpact
        input={input}
        setInput={setInput}
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        people={people}
        property={property}
        operations={operations}
      >
        {
          Header
        }
      </ResidualImpact>
    )
  }

  if (question === 12) {
    const impacts = [input.residualOperations.value, input.residualPeople.value, input.residualProperty.value]
    const residualImpactRating = getImpactRating(impacts)
    const residualRisk = computeRiskRating(input.residualThreatLikelihood, impacts)

    return (
      <Summary
        onClose={() => setQuestion(question - 1)}
        next={moveToNext}
        message='The following is a summary of the Residual Risk to the current Asset based on your assessments.'
        title='Residual Risk Assessment - Summary'
        measures={{
          risk: residualRisk, impactRating: getImpactCategory(residualImpactRating), threatLikelihood: input.residualThreatLikelihood,
        }}
        onCreate={() => setInput({
          ...input, residualImpactRating: getImpactCategory(residualImpactRating), residualRisk,
        })}
        getLikelihoodCategory={getLikelihoodCategory}
        input={input}
      >
        {
          Header
        }
      </Summary>
    )
  }

  return (
    <Review
      input={input}
      onClose={() => setQuestion(question - 1)}
      onSubmit={() => handleSubmit()}
      edit={edit}
      onSubmitAdd={() => handleSubmit(true)}
    >
      {
        Header
      }
    </Review>
  )
}

export default RiskAssessmentForm
