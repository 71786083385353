import { useEffect } from 'react'
import * as React from 'react'
import {
  IonLoading, IonRow, IonButton,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import Map from '../Map'

import { ControlContainer } from '../StyledContainers'
import Route2 from '../features/Route2'
import Journey2 from '../features/Journey2'
import JourneyAuthorization from '../features/JourneyAuthorization'
import JourneyTicket from '../features/JourneyTicket'
import JourneyEdit2 from '../features/JourneyEdit2'
import AirTravelList from '../listviews/AirTravelList'

import { useWorkspace } from '../../../hooks/useWorkspace'
import { formatJourneys } from '../../../hooks/journeys/helpers/Utils'

import AirJourney from '../features/AirJourney'
import FormPopup from '../forms/FormPopup'
import { CenterForm } from '../../riskregister/StyledContainers'
import { ObscureBackground } from '../../incident-management/StyledContainers'
import { useJourneysData2 } from '../../../hooks/journeys/useJourneysData2'
import MapLegend from '../../insights/popovers/MapLegend'
import JourneysList from '../../journeys/JourneysList'
import RestrictionZone from '../features/RestrictionZone'
import RestrictionZoneEdit from '../features/RestrictionZoneEdit'

/**
 *  ================================
 *          JOURNEY APP MAP
 *  ================================
 *
 *  Allows to filter incidents and previous journeys.
 *  Movement Boxes are created from here
 */

const JourneyMap2 = () => {
  const {
    view, mapRef, showJourneyAuthorization,
    setShowJourneyAuthorization, setShowTicket, showTicket, setFocussedJourney, focussedJourney,
    showEditJourney, setShowEditJourney, routes, setRoutes,
    handleViewChange, showJourneyForm, setShowJourneyForm, showAirJourneyForm, setShowAirJourneyForm,
    submittedPopup, setSubmittedPopup, airJourneys, showMbForm, search,
    showRouteForm, setShowRouteForm, focusedAirJourney, setFocusedAirJourney,
    fromPin, setFromPin, toPin, setToPin, journeys,
    showLoading, setShowLoading, movementBoxes, setMovementBoxes, focusedMb,
    users, email, handleAirJourneyClick,
    removeAirPins, startRoute, finishJourney, journeyDisplay,
    addAirJourney, updateAirJourney, updateJourneys, journeyMode, setshowMbForm, resetFilters,
    setUpListeners, getHeights, setRawJourneys, setJourneys,
    showJourneysList, setShowJourneysList, showAirTravelList, setShowAirTravelList,
    setShowBackToJourneysMapButton, showMapKey, setShowMapKey, boxMeasures, setRoutePins, routePins,
  } = useJourneysData2()

  const { workspace } = useWorkspace()

  const pushJourney = (dbJourneys, routesFromDb, dbUsers, chapters = []) => {
    const data = formatJourneys(dbJourneys, routesFromDb, dbUsers, chapters)
    setJourneys(data.journeysFromDb)
    setRawJourneys(data.journeys)
    mapRef.current.getSource('journeys').setData({ type: 'FeatureCollection', features: data.journeysFromDb })
    return data.journeysFromDb
  }

  useEffect(() => {
    const id = new URLSearchParams(search).get('journey')
    if (journeys.find((journey) => journey.properties.id === Number(id))) {
      setFocussedJourney(Number(id))
      const directedJourney = journeys.find((journey) => journey.properties.id === Number(id))
      if (directedJourney.properties.approver.email === email && directedJourney.properties.status === 'Pending') setShowJourneyAuthorization(true)
      else if (directedJourney.properties.submitter.email === email && directedJourney.properties.status === 'Pending') setShowEditJourney(true)
      else setShowTicket(true)
    }
  }, [search])

  return (
    <IonRow style={{ height: getHeights() }} className='ion-justify-content-center'>
      <IonLoading
        isOpen={showLoading}
        message='Please wait...'
      />
      <Map
        mapRef={mapRef}
        listeners={setUpListeners}
        zoom={5}
      >
        <ControlContainer>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            {
              showMapKey && (
                <MapLegend />
              )
            }
            <IonButton
              onClick={() => {
                if (view !== 'Road Travel' && view !== 'QuickGo Journeys') {
                  handleViewChange('Road Travel')
                }
                setShowJourneysList(true)
                setShowBackToJourneysMapButton(true)
              }}
              style={{
                '--background': '#326771', textTransform: 'none', fontWeight: 'normal', fontSize: '1.3em', letterSpacing: '-0.02em',
              }}
            >
              <Icon style={{ marginRight: '0.4em', fontSize: '1.3em' }} icon='cil:menu' /> Journeys List
            </IonButton>
          </IonRow>
        </ControlContainer>
        {
          view === 'Road Travel' && showJourneysList && (
            <JourneysList />
          )
        }
        {
          view === 'QuickGo Journeys' && showJourneysList && (
            <JourneysList />
          )
        }
        {
            view === 'Air Travel' && !showAirJourneyForm && showAirTravelList && (
            <AirTravelList
              airJourneys={airJourneys}
              onClick={handleAirJourneyClick}
            />
            )
            }
        {
            view === 'Air Travel' && showAirJourneyForm && (
            <ControlContainer>
              <IonRow className='ion-align-items-center'>
                <IonButton
                  style={{ '--background': 'white', textTransform: 'none' }}
                  className='air-list-back-button'
                  onClick={() => {
                    setShowAirJourneyForm(false)
                    setShowMapKey(true)
                    setShowAirTravelList(true)
                    setFocusedAirJourney(null)
                    removeAirPins()
                  }}
                >
                  <Icon icon='ep:arrow-left' />
                  Back to Air Travel list
                </IonButton>
              </IonRow>
            </ControlContainer>
            )
            }
        {
            showJourneyForm
            && (
            <Journey2
              mapRef={mapRef}
              routes={routes}
              setShowJourneyForm={setShowJourneyForm}
              startRoute={startRoute}
              users={users}
              formatJourneys={pushJourney}
              onFinish={finishJourney}
              setLoading={setShowLoading}
              journeyDisplay={journeyDisplay}
              domainId={workspace.id}
            />
            )
            }
        {
            showAirJourneyForm && (
            <AirJourney
              onClose={() => {
                setFocusedAirJourney(null)
                setShowAirJourneyForm(false)
                setShowMapKey(true)
                setShowAirTravelList(true)
                removeAirPins()
              }}
              focusedJourney={focusedAirJourney}
              addAirJourney={addAirJourney}
              domainId={workspace.id}
              users={users.map((val) => ({ id: val.id, email: val.email }))}
              dbUsers={users}
              mapRef={mapRef}
              fromPin={fromPin}
              setFromPin={setFromPin}
              toPin={toPin}
              setToPin={setToPin}
              displaySubmitted={setSubmittedPopup}
              updateAirJourney={updateAirJourney}
              setFocusedJourney={setFocusedAirJourney}
            />
            )
            }
        {
            showJourneyAuthorization
            && (
            <JourneyAuthorization
              setLoading={setShowLoading}
              setShowJourneyAuthorization={setShowJourneyAuthorization}
              focussedJourney={focussedJourney}
              updateJourneys={updateJourneys}
              domainId={workspace.id}
            />
            )
            }
        {
            showTicket
            && (
            <JourneyTicket
              focussedJourney={focussedJourney}
              setShowTicket={setShowTicket}
              updateJourneys={updateJourneys}
              domainId={workspace.id}
            />
            )
            }
        {
            showEditJourney
            && (
            <JourneyEdit2
              focussedJourney={focussedJourney}
              setShowEditJourney={setShowEditJourney}
              routes={routes}
              users={users}
              journeyDisplay={journeyDisplay}
              mapRef={mapRef}
              onFinish={finishJourney}
              journeyMode={journeyMode}
              viewTransit={() => handleViewChange('Road Travel')}
              formatJourneys={pushJourney}
              setLoading={setShowLoading}
              domainId={workspace.id}
            />
            )
            }
        {
            showMbForm
            && (
            <RestrictionZone
              mapRef={mapRef}
              setShowMbForm={setshowMbForm}
              movementBoxes={movementBoxes}
              setMovementBoxes={setMovementBoxes}
              showMbs={resetFilters}
              domainId={workspace.id}
              setLoading={setShowLoading}
              controlMeasures={boxMeasures}
            />
            )
            }
        {
            showRouteForm
            && (
            <Route2
              mapRef={mapRef}
              handleViewChange={handleViewChange}
              showJourneyForm={showJourneyForm}
              setShowRouteForm={setShowRouteForm}
              routes={routes}
              setRoutes={setRoutes}
              domainId={workspace.id}
              setRoutePins={setRoutePins}
              routePins={routePins}
            />
            )
            }
        {
            focusedMb && (
              <RestrictionZoneEdit />
            )
            }
        {
            submittedPopup && (
            <>
              <ObscureBackground />
              <CenterForm style={{ height: 'auto', width: '30%', zIndex: '11' }}>
                <FormPopup message={submittedPopup} onClose={() => setSubmittedPopup(null)} />
              </CenterForm>
            </>
            )
            }
      </Map>
    </IonRow>
  )
}

export default JourneyMap2
