import React, { useState } from 'react'
import {
  IonItem, IonLabel, IonCol,
} from '@ionic/react'
import { TabBarContainer, ViewMarker } from '../StyledContainers'

import InsightSettings from './InsightSettings'
import JourneySettings from './JourneySettings'

/**
 *  ======================
 *      App settings
 *  ======================
 */
const AppSettings = () => {
  const [view, setView] = useState('insights')

  return (
    <>
      <IonCol className='ion-no-padding' size={3}>
        <TabBarContainer>
          <IonItem
            button
            className='ion-no-padding'
            style={{ '--background': (view === 'insights') ? 'white' : '#F2F2F2', color: '#8E151F' }}
            onClick={() => setView('insights')}
          >
            {
            view === 'insights' && (
              <ViewMarker />
            )
          }
            <IonLabel className='ion-text-center'>Insights Settings</IonLabel>
          </IonItem>
          <IonItem
            button
            className='ion-no-padding'
            style={{ '--background': (view === 'journeys') ? 'white' : '#F2F2F2', color: '#8E151F' }}
            onClick={() => setView('journeys')}
          >
            {
            view === 'journeys' && (
              <ViewMarker />
            )
          }
            <IonLabel className='ion-text-center'>Journey Settings</IonLabel>
          </IonItem>
        </TabBarContainer>
      </IonCol>
      <IonCol size={9}>
        {
          view === 'insights' && (
            <InsightSettings />
          )
        }
        {
          view === 'journeys' && (
            <JourneySettings />
          )
        }
      </IonCol>
    </>
  )
}

export default AppSettings
