import React, { createContext, useState, useContext } from 'react'
/**
 *  ===============================
 *      SAFETY CHECK CONTEXT
 *  ===============================
 */
const PopupContext = createContext([])

export const ProvideTrackerPopup = ({ children }) => {
  const [show, setShow] = useState(false)
  const [notification, setNotification] = useState<null | number>(null)
  const [trackerId, setTrackerId] = useState<null | number>(null)

  /* Show/Hide popup handlers */
  const displayPopup = (notificationId: number, tracker: number) => {
    setNotification(notificationId)
    setTrackerId(tracker)
    setShow(true)
  }
  const hidePopup = () => {
    setNotification(null)
    setTrackerId(null)
    setShow(false)
  }

  return (
    <PopupContext.Provider value={[displayPopup, hidePopup, show, notification, trackerId]}>
      {
        children
      }
    </PopupContext.Provider>
  )
}

export const useTrackerPopup = () => useContext(PopupContext)
