import React, { useState, useEffect } from 'react'
import { IonRow } from '@ionic/react'

import { useAuth } from '../../hooks/useAuth'

import useApi from '../../hooks/testHooks'
import { useWorkspace } from '../../hooks/useWorkspace'

import { useSituationReport } from '../../hooks/insights/useSituationReport'

import WeeklyReport1 from './WeeklyReport1'
import WeeklyReport2 from './WeeklyReport2'
import WeeklyReport3 from './WeeklyReport3'
import WeeklyReport4 from './WeeklyReport4'
import WeeklyReport5 from './WeeklyReport5'
import WeeklyReport6 from './WeeklyReport6'
import WeeklyReport7 from './WeeklyReport7'

import { useWeeklyReport } from '../../hooks/insights/useWeeklyReport'

/**
 *  =======================
 *      FORM TO ADD TASK
 *  =======================
 *
 */

const WeeklyReportHandler : React.FC<Number> = ({ idx }) => {
  const [step, setStep] = useState(0)
  const [data, setData] = useState({})
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  //const [isShowing, setIsShowing] = useWeeklyReport()
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    //setStep(1)
    //setIsShowing(true)
  }, [])

  useEffect(() => {
    // make county id dynamic
    const fetch = apiHook.weeklyReport({ domain_id: workspace.id, index: idx }).then((result) => {
      setData(result)
      setStep(1)
      setIsShowing(true)
      console.log(result)
    })
  }, [])

  switch (step) {
    default:
      return (
        <IonRow />
      )
    case 1:
      return isShowing ? (
        <WeeklyReport1
          step={step}
          setStep={setStep}
          setIsShowing={setIsShowing}
          end={data.end}
          start={data.start}
          active={data.active_users}
          inactive={data.inactive_users}
          unregistered={data.unregistered}
          idx={idx}
        />
      ) : null
    case 2:
      return isShowing ? (
        <WeeklyReport7
          step={step}
          setStep={setStep}
          setIsShowing={setIsShowing}
          data={data}
          idx={idx}
        />
      ) : null
    case 3:
      return isShowing ? (
        <WeeklyReport2
          step={step}
          setStep={setStep}
          setIsShowing={setIsShowing}
          data={data}
          idx={idx}
        />
      ) : null
    case 4:
      return isShowing ? (
        <WeeklyReport3
          step={step}
          setStep={setStep}
          setIsShowing={setIsShowing}
          manifest={data.user_manifest}
          users={data.users}
          idx={idx}
          end={data.end}
        />
      ) : null
    case 5:
      return isShowing ? (
        <WeeklyReport4
          step={step}
          setStep={setStep}
          setIsShowing={setIsShowing}
          data={data}
          idx={idx}
        />
      ) : null
    case 6:
      return isShowing ? (
        <WeeklyReport5
          step={step}
          setStep={setStep}
          setIsShowing={setIsShowing}
          journeys={data.journeys}
          idx={idx}
          end={data.end}
        />
      ) : null
    case 7:
      return isShowing ? (
        <WeeklyReport6
          step={step}
          setStep={setStep}
          setIsShowing={setIsShowing}
          data={data}
          idx={idx}
        />
      ) : null
  }
}

export default WeeklyReportHandler
