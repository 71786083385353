import { Icon } from '@iconify/react'
import { IonContent } from '@ionic/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { SettingsContainer } from './settings/StyledContainers'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 */
const ComingSoon = () => (
  <IonContent>
    <SettingsContainer>
      <div style={{ padding: '40px', backgroundColor: 'white', height: '100%' }}>
        <h3 style={{ color: '#8E151F' }}>App Coming Soon</h3>
        <p style={{ margin: '40px 0' }}>This app is currently in development. </p>
        <Link style={{ color: '#8E151F', textDecoration: 'none' }} to='/dashboard'>Back to dashboard <Icon icon='dashicons:arrow-right-alt2' /> </Link>
      </div>
    </SettingsContainer>
  </IonContent>
)

export default ComingSoon
