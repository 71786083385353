import { IonButton, IonCol, IonRow } from '@ionic/react'
import * as React from 'react'
import { Icon } from '@iconify/react'
import { CenterForm, ColumnContainer, MeasureContainer } from '../../riskregister/StyledContainers'

interface Props {
  onFinish: (measure: string[]) => void,
  allTypes: string[],
  selectedThreats: string[],
  onClose: () => void
}

const BoxThreats : React.FC<Props> = ({
  allTypes, onClose, onFinish, selectedThreats,
}) => {
  const [selected, setSelected] = React.useState<string[]>([...selectedThreats])
  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Add measure */
  const handleDropAdd = (e) => {
    /* Get measure name */
    const measure : string = e.dataTransfer.getData('measure')
    if (selected.find((val) => val === measure)) return
    const copy = selected.slice()
    copy.push(measure)

    setSelected(copy)
  }

  /* Remove measure */
  const handleDropRemove = (e) => {
    /* Get measure name */
    const measure : string = e.dataTransfer.getData('measure')
    const copy = selected.slice()
    const index = copy.findIndex((val) => val === measure)

    copy.splice(index, 1)
    setSelected(copy)
  }

  return (
    <CenterForm style={{
      zIndex: '20', width: '90%', height: 'auto', padding: '20px',
    }}
    >
      <h4>Main Threats</h4>
      <p>List the threats present in this restriction zone by dragging and dropping from the library on the left into the right hand column.</p>
      <IonRow className='ion-align-items-center' style={{ marginBottom: '20px' }}>
        <IonCol size='5'>
          <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropRemove}>
            {
              allTypes.filter((type) => !selected.find((val) => val === type)).map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                  onDragStart={(e) => e.dataTransfer.setData('measure', val)}
                >
                  <p> {val} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </IonCol>
        <IonCol style={{ textAlign: 'center', fontSize: '2.6rem' }} size='2'>
          <Icon icon='ic:round-swipe-right' />
        </IonCol>
        <IonCol size='5'>
          <ColumnContainer id='cm-existing-drop' onDragOver={(e) => e.preventDefault()} onDrop={handleDropAdd}>
            {
              selected.map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                  onDragStart={(e) => e.dataTransfer.setData('measure', val)}
                >
                  <p> {val} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
        <IonButton style={{ '--background': '#0C9500' }} onClick={() => onFinish(selected)}>Next</IonButton>
      </IonRow>
    </CenterForm>
  )
}

export default BoxThreats
