import styled from 'styled-components'

const CardContainer = styled.div`
  background: #FFFFFF;
  border-radius: 5px;
  padding: 10px;
  font-size: .8rem;
  margin: 10px;
  cursor: pointer;
`

const OverlayContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  --ion-item-background: white;
  z-index: 2;
  width: 450px;

  h4 {
    color: #8E151F;
    margin-bottom: 20px;
  }

  h5 {
    color: #326771;
    margin-bottom: 10px;
  }
`

const HighPriorityLabel = styled.p`
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  margin-left: auto;
`

const TabBarContainer = styled.div`

`

const ChatCardButton = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(238, 238, 238);
  align-items: flex-start;
  cursor: pointer;
`

const IconContainerElem = styled.div`
  padding: 5;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
`

export {
  CardContainer,
  OverlayContainer,
  HighPriorityLabel,
  TabBarContainer,
  ChatCardButton,
  IconContainerElem,
}
