import React, { FC, useEffect } from 'react'
import { AppView } from '../../hooks/engagement/types/HookInterface'
import { useEngagementData } from '../../hooks/engagement/useEngagementData'
import { ContentContainer } from '../incident-management/StyledContainers'
import Campaigns from './screens/Campaigns'
import DMScreen from './screens/DMScreen'
import Home from './screens/Home'
import Stakeholders from './screens/Stakeholders'

const AppWrapper : FC = () => {
  const { view, fetchAppData } = useEngagementData()

  useEffect(() => {
    fetchAppData()
  }, [])

  if (view === AppView.HOME) {
    return (
      <Home />
    )
  }

  if (view === AppView.STAKEHOLDERS_SCREEN) {
    return (
      <Stakeholders />
    )
  }

  if (view === AppView.CAMPAIGN_SCREEN) {
    return (
      <Campaigns />
    )
  }

  if (view === AppView.MESSAGING_SCREEN) {
    return (
      <DMScreen />
    )
  }

  return (
    <ContentContainer>
      <p>Hello</p>
    </ContentContainer>
  )
}

export default AppWrapper
