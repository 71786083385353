/* eslint-disable react/no-array-index-key */
/* eslint-disable no-trailing-spaces */
import { Icon } from '@iconify/react'
import {
  IonButton, IonCol, IonRow, useIonAlert, 
} from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import PhoneInput from 'react-phone-number-input'
import { Form } from 'react-bootstrap'
import { useSettings } from '../../hooks/settings/useSettings'
import { OverlayContainer } from '../GlobalContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { InvitationContainer, SelectRole } from '../settings/StyledContainers'
import { RoleType } from '../types/Roles'
import axios from '../../utils/axios'
import { useWorkspace } from '../../hooks/useWorkspace'

enum InvType {
  EXISTING_USER = 'existing',
  NEW_USER = 'new_user',
}

const InviteUsers : FC = () => {
  const [invType, setInvType] = useState<InvType>()
  const { setShowInvitePopup } = useSettings()
  const roles = [RoleType.MEMBER, RoleType.MANAGER]

  if (!invType) {
    return (
      <OverlayContainer style={{ zIndex: 25 }}>
        <h5 style={{ color: '#8E151F', marginBottom: '20px' }}>Invite Existing Users</h5>
        <InvitationContainer onClick={() => setInvType(InvType.EXISTING_USER)}>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <div>
              <h6>Invite Existing Users</h6>
              <p>Invite users that are already on the Sherpa platform to this workspace.</p>
            </div>
            <Icon icon='material-symbols:arrow-forward-ios' style={{ fontSize: '2rem' }} />
          </IonRow>
        </InvitationContainer>
        <InvitationContainer onClick={() => setInvType(InvType.NEW_USER)}>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <div>
              <h6>Invite New Users</h6>
              <p>Invite users that do not have an account on the Sherpa platform to this workspace.</p>
            </div>
            <Icon icon='material-symbols:arrow-forward-ios' style={{ fontSize: '2rem' }} />
          </IonRow>
        </InvitationContainer>
        <IonRow style={{ marginTop: '30px' }}>
          <IonButton onClick={() => setShowInvitePopup(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        </IonRow>
      </OverlayContainer>
    )
  }

  if (invType === InvType.EXISTING_USER) {
    return (
      <InviteExisting
        roles={roles}
      />
    )
  }

  return (
    <InviteNew />
  )
}

export default InviteUsers

interface ExistingProps {
  roles: RoleType[],
}

const InviteExisting : FC<ExistingProps> = ({ roles }) => {
  const [userRole, setUserRole] = useState<RoleType>(RoleType.MEMBER)
  const [invitees, setInvitees] = useState<{ value: number, label: string }[]>([])
  const {
    users, allUsers, setShowInvitePopup, pushNewInvites, setLoading, fetchUsers, fetchedUsers,
  } = useSettings()
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()

  const handleAddNewUsers = () => {
    if (invitees.length === 0) {
      ionAlert({
        header: 'No users selected.',
        message: 'You need to select at least one user to add to this workspace.',
        buttons: [
          { text: 'Ok' },
        ],
      })
      return
    }

    const selectedIds = invitees.map(({ value }) => value)

    setLoading(true)
    axios.post('/api/v1/domain/invite_existing', { domain_id: workspace.id, user_role: userRole, user_ids: selectedIds })
      .then(({ data }) => {
        if (!data.ok) {
          ionAlert({
            header: 'Server Error',
            message: data.message,
            buttons: [
              { text: 'Ok', handler: () => setShowInvitePopup(false) },
            ],
          })
          return
        }
        const selected = users.filter(({ id }) => selectedIds.indexOf(id) >= 0)
        pushNewInvites(selected)
        setShowInvitePopup(false)
      }).catch(() => {
        ionAlert({
          header: 'Unknown Error',
          message: 'An unknown error has ocurred.',
          buttons: [
            { text: 'Ok', handler: () => setShowInvitePopup(false) },
          ],
        })
      }).finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchUsers()
    console.log(users)
    console.log(fetchedUsers)
  }, [])

  return (
    <OverlayContainer style={{ zIndex: 25 }}>
      <h5 style={{ color: '#8E151F', marginBottom: '20px' }}>Invite Users</h5>
      <p>Select the role you wish to give the user(s) you are about to invite.</p>
      <SelectRole value={userRole} onChange={(e) => setUserRole(e.target.value)}>
        {
          roles.map((role) => (
            <option key={role} value={role}>{ role }</option>
          ))
        }
      </SelectRole>
      <p style={{ margin: 0 }}>Type the name or email of the user(s) you wish to invite to this workspace in the chosen role.</p>
      <p style={{ margin: '20px 0' }}>If the user has a Risk Sherpa account, they will appear in the dropdown.</p>
      <div style={{ marginBottom: '30px' }}>
        <CreatableSelect
          isMulti
          placeholder='Type a name or email'
          name='colors'
          className='select-container'
          id='journey-passengers'
                // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          options={allUsers.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name} (${user.email})` }))}
          value={invitees}
          onChange={(selected) => setInvitees(selected)}
        />
      </div>
      <IonRow className='ion-justify-content-end'>
        <IonButton onClick={() => setShowInvitePopup(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        <IonButton onClick={handleAddNewUsers} style={{ '--background': '#0C9500' }}>Add to Workspace</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

interface UserDetails {
  first_name: string,
  final_name: string,
  email: string,
  phone_number: string,
  inv_type?: 'email' | 'sms',
}

const InviteNew : FC = () => {
  const [newUsers, setNewUsers] = useState<UserDetails[]>([{
    first_name: '',
    final_name: '',
    email: '',
    phone_number: '',
  }])
  const { setShowInvitePopup, setLoading, pushNewInvites } = useSettings()
  const { workspace } = useWorkspace()
  const [ionAlert] = useIonAlert()

  const handleInputChange = (value: UserDetails, index: number) => {
    const copy = newUsers.slice()
    copy.splice(index, 1, { ...value })

    setNewUsers(copy)
  }

  const addNewUser = () => {
    const copy = newUsers.slice()
    copy.push({
      first_name: '',
      final_name: '',
      email: '',
      phone_number: '',
    })

    setNewUsers(copy)
  }

  const validateEmail = (email: string) => email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )

  const handleSubmitUsers = () => {
    if (!newUsers.every((val) => val.first_name !== '' && val.final_name !== '' && val.phone_number !== '' && val.email !== '' && val.inv_type)) {
      ionAlert({
        header: 'Empty Fields',
        message: 'Some fields are empty.',
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    if (!newUsers.every((val) => validateEmail(val.email))) {
      ionAlert({
        header: 'Wrong Email Format',
        message: "Some emails don't seem to be in the right format.",
        buttons: [
          { text: 'Ok' },
        ],
      })

      return
    }

    setLoading(true)
    axios.post('/api/v1/domain/invite_new_users', { new_users: newUsers, domain_id: workspace.id })
      .then(({ data }) => {
        if (!data.ok) {
          ionAlert({
            header: 'Unknown Server Error',
            message: 'A server error has ocurred.',
            buttons: [
              { text: 'Ok', handler: () => setShowInvitePopup(false) },
            ],
          })

          return
        }

        pushNewInvites(data.new_users)

        if (data.existing_messages.length > 0) {
          ionAlert({
            header: 'Existing users',
            message: `Some emails or phone numbers already have sherpa accounts. ${data.existing_messages.join(', ')}`,
            buttons: [
              { text: 'Ok', handler: () => setShowInvitePopup(false) },
            ],
          })
          return
        }

        setShowInvitePopup(false)
      }).catch(() => {
        ionAlert({
          header: 'Unknown Server Error',
          message: 'A server error has ocurred.',
          buttons: [
            { text: 'Ok', handler: () => setShowInvitePopup(false) },
          ],
        })
      }).finally(() => setLoading(false))
  }

  return (
    <OverlayContainer style={{ zIndex: 25, wdith: '800px' }}>
      <h5 style={{ color: '#8E151F', marginBottom: '20px' }}>Invite New Users</h5>
      <p style={{ margin: '20px 0' }}>Invite new users to this workspace by adding their details below. An account will be generated for them and they 
        will receive a password to sign in and invite to this workspace.
      </p>
      <p style={{ margin: '20px 0' }}>An email or phone number is required to invite a new user. If both fields are provided, you can choose whether to invite the user via email, or SMS.</p>
      <div style={{
        padding: '20px', backgroundColor: '#FCFCFC', border: '1px solid #747474', margin: '30px 0', borderRadius: '2.5px',
      }}
      >
        {
          newUsers.map((user, index) => (
            <div key={index}>
              <IonRow className='new-users-list'>
                <IonCol size='2'>
                  <input
                    value={user.first_name}
                    type='text'
                    placeholder='First Name'
                    onChange={(e) => handleInputChange({ ...user, first_name: e.target.value }, index)}
                  />
                </IonCol>
                <IonCol size='2'>
                  <input
                    value={user.final_name}
                    type='text'
                    placeholder='Last Name'
                    onChange={(e) => handleInputChange({ ...user, final_name: e.target.value }, index)}
                  />
                </IonCol>
                <IonCol size='5'>
                  <input
                    value={user.email}
                    type='email'
                    placeholder='Email'
                    onChange={(e) => handleInputChange({ ...user, email: e.target.value }, index)}
                  />
                </IonCol>
                <IonCol size='3'>
                  <PhoneInput
                    international
                    defaultCountry={workspace.country && workspace.country}
                    type='text'
                    value={user.phone_number}
                    placeholder='Phone No.'
                    onChange={(e) => handleInputChange({ ...user, phone_number: e }, index)}
                    name='phone_number'
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <Form.Check
                  inline
                  label='email'
                  name={`inv-type-${index}`}
                  value='email'
                  type='radio'
                  onChange={() => handleInputChange({ ...user, inv_type: 'email' }, index)}
                />
                <Form.Check
                  inline
                  label='sms'
                  name={`inv-type-${index}`}
                  value='sms'
                  type='radio'
                  onChange={() => handleInputChange({ ...user, inv_type: 'sms' }, index)}
                />
              </IonRow>
            </div>
          ))
        }
      </div>
      <IonRow style={{ marginBottom: '20px' }} className='ion-justify-content-end'>
        <SimpleButton onClick={addNewUser}>
          Add User <Icon icon='ic:baseline-plus' style={{ fontSize: '2rem' }} />
        </SimpleButton>
      </IonRow>
      <IonRow className='ion-justify-content-end'>
        <IonButton onClick={() => setShowInvitePopup(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        <IonButton onClick={handleSubmitUsers} style={{ '--background': '#0C9500' }}>Send Invite</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}
