import React from 'react'
import { IonGrid, IonRow, IonSpinner } from '@ionic/react'

import styled from 'styled-components'

import KanbanColumn from './KanbanColumn'
import AddTask from './popups/AddTask'
import ExpandTask from './popups/ExpandTask'

import { ObscureBackground, CenterLoader } from '../GlobalContainers'

const KanbanContainer = styled.div`
  border-radius: 10px;
  background-color: #F9F9F9;
  height: 85%;
  position: relative;
`

/**
 *  ================================
 *    Create and assign new tasks
 *  ================================
 */

const KanbanBoard = ({
  tasks, showAddTask, setShowAddTask, showArchived, changeStatus, updateTask, archiveTask, addNewTask,
  setFocussedTask, focussedTask, deleteTask, loading, users,
}) => (
  <KanbanContainer>
    <IonGrid style={{ height: '100%', padding: '20px', boxShadow: '0px 4px 4px 0px #00000040' }}>
      <IonRow style={{ height: '100%' }}>
        <KanbanColumn
          tasks={tasks}
          columnName='To-Do'
          expand={setFocussedTask}
          showArchived={showArchived}
          changeStatus={changeStatus}
        />
        <KanbanColumn
          tasks={tasks}
          columnName='In-Progress'
          expand={setFocussedTask}
          showArchived={showArchived}
          changeStatus={changeStatus}
        />
        <KanbanColumn
          tasks={tasks}
          columnName='Complete'
          expand={setFocussedTask}
          showArchived={showArchived}
          changeStatus={changeStatus}
        />
      </IonRow>
    </IonGrid>
    {
      loading && (
        <>
          <ObscureBackground id='obscure-background-loader' style={{ zIndex: 10 }} />
          <CenterLoader style={{ zIndex: 11 }}>
            <IonSpinner color='light' />
          </CenterLoader>
        </>
      )
    }
    {
        showAddTask
        && (
        <>
          <AddTask
            onClose={() => setShowAddTask(false)}
            onAddTask={addNewTask}
            users={users}
          />
          <ObscureBackground />
        </>
        )
      }
    {
        focussedTask
        && (
          <>
            <ExpandTask
              task={focussedTask}
              showArchived={showArchived}
              onClose={() => setFocussedTask(false)}
              updateTask={updateTask}
              changeStatus={changeStatus}
              archiveTask={archiveTask}
              deleteTask={deleteTask}
            />
            <ObscureBackground />
          </>
        )
      }
  </KanbanContainer>
)

export default KanbanBoard
