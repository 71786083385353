import React, { useState, useEffect } from 'react'
import {
  IonList, IonItem, IonInput, IonButton, IonRow, useIonAlert, IonCol,
} from '@ionic/react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { useAuth } from '../../hooks/useAuth'
import { RegisterContainer } from '../maps/StyledContainers'

import VerifyEmail from './VerifyEmail'
import { SherpaButton } from '../GlobalContainers'

const RegisterForm = ({ loading, setLoading }) => {
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [primaryPhone, setPrimaryPhone] = useState('')
  const [secondaryPhone, setSecondaryPhone] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)

  const [registered, setRegistered] = useState(false)

  const auth = useAuth()
  const [ionAlert] = useIonAlert()

  const handleSubmit = () => {
    if (registerPassword === '' || passwordRepeat === '' || firstName === '' || lastName === '' || primaryPhone === '') {
      ionAlert({
        header: 'Missing params',
        message: 'You have to fill in all fields',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })

      return
    }

    if (registerPassword !== passwordRepeat) {
      ionAlert({
        header: 'Different Password',
        message: 'Both password fields must coincide',
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })

      return
    }

    setLoading(true)
    auth.register(registerEmail, registerPassword, firstName, lastName, primaryPhone, secondaryPhone)
      .then((data) => {
        if (data.ok) {
          setRegistered(true)
          setLoading(false)
        } else {
          ionAlert({
            header: 'Error',
            message: data.message,
            buttons: [
              'Cancel',
              { text: 'Ok' },
            ],
          })
          setLoading(false)
        }
      })
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    setRegisterEmail(atob(queryParams.get('register')))
  }, [])

  return (
    <RegisterContainer>
      {
        (registered)
          ? (
            <VerifyEmail message='Account succesfully created!' />
          )
          : (
            <>
              <div style={{ padding: '0 10px' }}>
                <h2 style={{ color: '#8E151F' }}>Create Account</h2>
                <p>Already have an account? <Link style={{ color: '#8E151F', textDecoration: 'none' }} to='/login'>Log in</Link> </p>
              </div>
              <IonList style={{ '--background': 'white' }}>
                <IonRow>
                  <IonCol size={6}>
                    <IonItem>
                      <IonInput placeholder='First name' type='text' value={firstName} onIonChange={(e) => setFirstName(e.detail.value)} />
                    </IonItem>
                  </IonCol>
                  <IonCol size={6}>
                    <IonItem>
                      <IonInput placeholder='Last name' type='text' value={lastName} onIonChange={(e) => setLastName(e.detail.value)} />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size={6}>
                    <IonItem>
                      <IonInput placeholder='Primary Phone Number' type='text' value={primaryPhone} onIonChange={(e) => setPrimaryPhone(e.detail.value)} />
                    </IonItem>
                  </IonCol>
                  <IonCol size={6}>
                    <IonItem>
                      <IonInput placeholder='Alternative Phone Number' type='text' value={secondaryPhone} onIonChange={(e) => setSecondaryPhone(e.detail.value)} />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonItem>
                  <IonInput placeholder='password' type={(showPassword) ? 'text' : 'password'} title='Password' value={registerPassword} onIonChange={(e) => setRegisterPassword(e.detail.value)} />
                  <SherpaButton className='show-password' onClick={() => setShowPassword(!showPassword)}>{(showPassword)
                    ? <Icon icon='codicon:eye' /> : <Icon icon='codicon:eye-closed' />}
                  </SherpaButton>
                </IonItem>
                <IonItem>
                  <IonInput
                    placeholder='Repeat password'
                    type={(showRepeatPassword) ? 'text' : 'password'}
                    title='Password'
                    value={passwordRepeat}
                    onIonChange={(e) => setPasswordRepeat(e.detail.value)}
                  />
                  <SherpaButton className='show-password' onClick={() => setShowRepeatPassword(!showRepeatPassword)}>{(showRepeatPassword)
                    ? <Icon icon='codicon:eye' /> : <Icon icon='codicon:eye-closed' />}
                  </SherpaButton>
                </IonItem>
              </IonList>
              <IonRow className='ion-justify-content-end'>
                <IonButton style={{ '--background': '#326771' }} disabled={loading} onClick={handleSubmit} title='Register'>Register</IonButton>
              </IonRow>
            </>
          )
      }
    </RegisterContainer>
  )
}

export default RegisterForm
