import * as React from 'react'
import {
  IonList, IonRow, IonButton, IonItem, IonSpinner, IonInput,
  IonContent,
} from '@ionic/react'

import { DisplayAssetAction, EditAssetActionKind } from '../../../hooks/risks/helpers/StateReducers'

import { SimpleButton } from '../../maps/StyledContainers'
import { SettingField } from '../../settings/StyledContainers'
import { CenterForm } from '../StyledContainers'
import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'
import { AssetInputActionKind } from '../../../hooks/risks/helpers/AddAssetReducers'
import { GeoLocation } from '../../types/GlobalTypes'
import { useAddAsset } from '../../../hooks/risks/useAddAsset'

type Props = {
  backForm: () => void,
  handleSubmit: () => void,
}

/**
 *  ================================================
 *      Asset Details for adding or editing
 *  ================================================
 */
const AssetDetails = ({ backForm, handleSubmit } : Props) : JSX.Element => {
  const {
    editing,
    dispatchAssetInput,
    addAssetInput,
    editAssetInput,
    dispatchDisplayAsset,
    dispatchEditingAsset,
    assetTypes,
  } = useRiskRegister()
  const { forgetMarker, assetMarker, imported } = useAddAsset()

  const handleChange = (value: string, target: string) => {
    if (editing) {
      dispatchEditingAsset({ type: EditAssetActionKind.EDIT_DETAILS, payload: { asset: { ...editAssetInput.asset, [target]: value } } })
      return
    }
    dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, [target]: value } })
  }

  const closeForm = (): void => {
    forgetMarker()
    dispatchAssetInput({ type: AssetInputActionKind.CLEAR })
    dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
  }

  const handleCoordinatesChange = (coordinates: GeoLocation) => {
    if (editing) {
      if (editAssetInput.marker) {
        editAssetInput.marker.setLngLat({ ...coordinates })
      }
      dispatchEditingAsset({ type: EditAssetActionKind.CHANGE_LOCATION, payload: { newLocation: coordinates } })
      return
    }
    if (assetMarker) {
      assetMarker.setLngLat({ ...coordinates })
    }
    dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates } })
  }

  if (imported?.geometry?.coordinates?.length > 1) {
    console.log('imported:', imported)
  }

  return (
    (
      <CenterForm>
        <IonContent>
          <IonList className='risk-assessment-list'>
            <div>
              <IonRow style={{ padding: '0 15px' }} className='ion-justify-content-between'>
                <SettingField style={{ width: 'auto' }}><h4>{ (editing) ? 'Edit Key Asset' : 'Add Key Asset' }</h4></SettingField>
                <SimpleButton onClick={closeForm}>Close X</SimpleButton>
              </IonRow>
              <IonItem>
                <SettingField>
                  <h5 className='blue'>Asset Name</h5>
                  <IonInput
                    name='name'
                    type='text'
                    id='add-asset-name'
                    onIonChange={(e) => handleChange(e.detail.value, 'name')}
                    value={(editing) ? editAssetInput.asset.name : addAssetInput.name}
                    placeholder='Name of the asset'
                  />
                </SettingField>
              </IonItem>
              <SettingField>
                <IonRow
                  style={{ padding: '20px' }}
                >
                  <h5 className='blue'>Asset Type</h5>
                  <select
                    value={(editing) ? editAssetInput.asset.type : addAssetInput.type}
                    placeholder='Asset Type'
                    onChange={(e) => handleChange(e.currentTarget.value, 'type')}
                    id='add-asset-type'
                    style={{ marginLeft: '20%' }}
                  >
                    <option value='' disabled>Select Asset Type</option>
                    {
                      assetTypes.map((name) => (
                        <option key={name} value={name}>{ name }</option>
                      ))
                    }
                  </select>
                </IonRow>
              </SettingField>
              <SettingField style={{ paddingLeft: '20px', width: 'auto' }}>
                <IonRow className='ion-align-items-center'>
                  <h5 className='blue'>Location</h5>
                  {
                    (editing) ? (
                      <>
                        {
                          (editAssetInput.asset.location.country) ? (
                            <SettingField>
                              <p>{ editAssetInput.asset.location.country }</p>
                              <p>{ editAssetInput.asset.location.state }</p>
                            </SettingField>
                          ) : (
                            <IonRow className='ion-justify-content-center' style={{ padding: '40px' }}>
                              <IonSpinner />
                            </IonRow>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {
                          (addAssetInput.location.country) ? (
                            <SettingField>
                              <p>{ addAssetInput.location.country }</p>
                              <p>{ addAssetInput.location.state }</p>
                            </SettingField>
                          ) : (
                            <IonRow className='ion-justify-content-center' style={{ padding: '40px' }}>
                              <IonSpinner />
                            </IonRow>
                          )
                        }
                      </>
                    )
                  }
                </IonRow>
              </SettingField>
              <SettingField style={{ paddingLeft: '20px' }}>
                {
                (imported?.geometry?.coordinates?.length > 1) && (
                  <>
                    <h5 className='blue'>Coordinates</h5>
                    <IonRow style={{ width: '85%' }}>
                      <IonInput
                        placeholder='Latitude'
                        value={(editing) ? editAssetInput.newLocation.lat : addAssetInput.coordinates.lat}
                        style={{ border: '1px solid #747474' }}
                        name='latitude'
                        type='number'
                        max='90'
                        min='-90'
                        onIonChange={(e) => {
                          handleCoordinatesChange({ lng: (editing) ? editAssetInput.newLocation.lng : addAssetInput.coordinates.lng, lat: Number(e.detail.value) })
                        }}
                      />
                      <IonInput
                        placeholder='Longitude'
                        style={{ border: '1px solid #747474', margin: '0 10px' }}
                        value={(editing) ? editAssetInput.newLocation.lng : addAssetInput.coordinates.lng}
                        type='number'
                        name='longitude'
                        max='180'
                        min='-180'
                        onIonChange={(e) => {
                          handleCoordinatesChange({ lng: Number(e.detail.value), lat: (editing) ? editAssetInput.newLocation.lat : addAssetInput.coordinates.lat })
                        }}
                      />
                    </IonRow>
                  </>
                )
              }
              </SettingField>
            </div>
            <IonRow style={{ padding: '0 10px' }} className='ion-justify-content-between ion-align-items-center'>
              <IonButton style={{ '--background': '#8E151F' }} onClick={backForm}>Back</IonButton>
              <IonButton id='add-asset-submit-button' style={{ '--background': '#0C9500' }} onClick={handleSubmit}>{ (editing) ? 'Update' : 'Submit' }</IonButton>
            </IonRow>
          </IonList>
        </IonContent>
      </CenterForm>
    )
  )
}

export default AssetDetails
