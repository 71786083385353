import React from 'react'
import { IonPage } from '@ionic/react'

import PageHeader from '../components/PageHeader'
import ComingSoon from '../components/ComingSoon'

/**
 *  =========================
 *          Forms App
 *  =========================
 */
const Forms = () => (
  <IonPage>
    <PageHeader title='Forms' />
    <ComingSoon />
  </IonPage>
)

export default Forms
