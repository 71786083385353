import * as turf from '@turf/turf'
import * as moment from 'moment'
import {
  BackendAsset, BackendRiskAssessment, BackendRiskEvent, ProcessedMeasures,
} from '../types/BackendData'
import { Asset, AssetList } from '../../../components/riskregister/types/Assets'
import { County, User } from '../../../components/types/GlobalTypes'
import {
  RiskAssessment, RiskRating, ControlMeasure, Mitigation, MitigationStatus, MitigationVersion,
} from '../../../components/riskregister/types/RiskAssessments'
import {
  getCategory, getImpactCategory, getLikelihoodCategory, translateToBackend,
} from '../../../components/riskregister/forms/utils/Categories'
import { computeRiskRating } from '../../../components/riskregister/forms/utils/RiskAssessmentCalculations'

/**
 *  Create a hashmap to quickly identify counties
 *
 * @param counties -> Feature collection of counties
 * @returns A hashmap to quickly locate a specific county
 */
export const createCountyMap = (counties: turf.FeatureCollection) : { [countyId: number] : County } => {
  /* County map using id's as hash codes */
  const map = {}
  counties.features.forEach((countyData) => {
    const data = countyData.properties
    map[data.id] = { ...data }
  })
  return map
}

/**
 * Get assets in colcoated format from backend data
 *
 * @param assets -> ASsets in backedn format
 * @param counties -> List of counties in geojson format
 * @returns A list of assets in colocated format
 */
export const processBackendAssets = (assets: BackendAsset[], counties: turf.FeatureCollection) : AssetList => {
  /* Formatted version of assets */
  const assetList = {}

  /* County hashmap for fast retreival */
  const countyMap = createCountyMap(counties)

  assets.forEach((val : BackendAsset) => {
    /** Colocated assets geolocation */
    const colocatedLocation = `${val.longitude},${val.latitude}`
    const county = countyMap[val.county_id]

    if (!assetList[colocatedLocation]) {
      assetList[colocatedLocation] = { assets: [] }
    }

    assetList[colocatedLocation].assets.push({
      id: val.id,
      name: val.name,
      type: val.asset_type,
      coordinates: {
        lat: val.latitude,
        lng: val.longitude,
      },
      location: {
        country: 'South Sudan',
        state: (county) ? county.ADM1_EN : 'Unknown',
        county: (county) ? county.ADM2_EN : 'Unknown',
      },
      county_id: val.county_id,
      highestRisk: 'Unknown',
      updated_at: moment(val.updated_at).format('DD/MM/YY'),
      alert_state: (county) ? county.Alert_state : undefined,
      security_condition: (county) ? county.sc_level : undefined,
    })
  })

  return assetList
}

const calculateRiskProperties = (threatRating: number, vulnerability: number, impactCategories: [number, number, number]) : { likelihood: number, risk: RiskRating } => {
  /* Calculate risk ratings */
  const likelihood = Math.ceil((threatRating * vulnerability) / 5)
  const impact = [getImpactCategory(impactCategories[0]), getImpactCategory(impactCategories[1]), getImpactCategory(impactCategories[2])]
  const risk = computeRiskRating(likelihood, impact)

  return { likelihood, risk }
}

/**
 *
 * @param backendData -> Backend risk assessments
 * @param asset -> Asset that these risk assessments belong to
 * @param users -> Archangel users
 * @param eventTypes -> Risk event types
 * @returns A formatted version of risk assessments for the frontend app
 */
export const processBackendRiskAssessments = (
  backendData: BackendRiskAssessment[],
  asset: Asset,
  users: User[],
  eventTypes: BackendRiskEvent[],
) : RiskAssessment[] => backendData.map((risk: BackendRiskAssessment, index) : RiskAssessment => {
  const owner = users.find(({ id }) => id === risk.user_id)
  const riskEvent = eventTypes.find(({ id }) => id === risk.risk_id)
  const threatRating = Math.ceil((risk.intent * risk.capability) / 5)

  /* Calculate risk properties for inherent and adjusted */
  const inherentData = calculateRiskProperties(threatRating, risk.vulnerability, risk.impact)
  const adjustedData = calculateRiskProperties(threatRating, risk.adjusted_vulnerability, risk.adjusted_impact)

  return {
    visibleId: asset.id * 100 + index + 1,
    id: risk.id,
    intent: getCategory(risk.intent),
    existingMeasures: [],
    newMeasures: [],
    mitigations: false,
    owner,
    inherentVulnerability: getCategory(risk.vulnerability),
    inherentImpactCategories: {
      people: getImpactCategory(risk.impact[0]),
      operations: getImpactCategory(risk.impact[1]),
      property: getImpactCategory(risk.impact[2]),
    },
    impact: getImpactCategory(Math.max(...risk.impact)),
    residualImpact: getImpactCategory(Math.max(...risk.adjusted_impact)),
    residualThreatLikelihood: getLikelihoodCategory(adjustedData.likelihood),
    threatRating: getCategory(threatRating),
    inherentThreatLikelihood: getLikelihoodCategory(inherentData.likelihood),
    riskRating: inherentData.risk,
    residualRisk: adjustedData.risk,
    capability: getCategory(risk.capability),
    riskEvent: riskEvent.name,
    residualImpactCategories: {
      people: getImpactCategory(risk.adjusted_impact[0]),
      operations: getImpactCategory(risk.adjusted_impact[1]),
      property: getImpactCategory(risk.adjusted_impact[2]),
    },
    residualVulnerability: getCategory(risk.vulnerability),
    updated: moment(risk.updated_at).format('DD/MM/YY'),
  }
})

export const formatMitigations = (controlMeasures: ControlMeasure[], mitigations: Mitigation[]) : ProcessedMeasures => {
  const measures = (type: MitigationStatus, version: MitigationVersion) : ControlMeasure[] => (
    mitigations.filter((val: Mitigation) => val.status === type
      && controlMeasures.find((measure) => val.control_measure_id === measure.id).version === version)
      .map((val) => {
        if (version === MitigationVersion.Standard) return controlMeasures.find((measure) => val.control_measure_id === measure.id)

        return controlMeasures.find((measure) => val.control_measure_id === measure.id)
      })
  )

  const existingMeasures = measures(MitigationStatus.Existing, MitigationVersion.Standard)
  const newMeasures = measures(MitigationStatus.New, MitigationVersion.Standard)

  return {
    existingMeasures, newMeasures,
  }
}

/**
 * Format risk assessment creation request
 *
 * @param asset -> Asset used to create this risk assessment
 * @param risk -> Risk assessment to be added
 * @returns Backend request to add new risk assessment
 */
export const formatRequestRisk = (asset: Asset, risk: RiskAssessment) => ({
  asset_id: asset.id,
  risk_event: risk.riskEvent,
  impact: [translateToBackend(risk.inherentImpactCategories.people), translateToBackend(risk.inherentImpactCategories.operations), translateToBackend(risk.inherentImpactCategories.property)],
  residual_impact: [translateToBackend(risk.residualImpactCategories.people), translateToBackend(risk.residualImpactCategories.operations), translateToBackend(risk.residualImpactCategories.property)],
  vulnerability: risk.inherentVulnerability,
  residual_vulnerability: risk.residualVulnerability,
  intent: risk.intent,
  capability: risk.capability,
  description: '',
})
