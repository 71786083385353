import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { useIonAlert } from '@ionic/react'
import axios from '../../../utils/axios'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { TagButton } from '../../incident-management/StyledContainers'
import { IncidentCause, User } from '../../types/GlobalTypes'
import { CommitmentType } from '../../types/OptimizedMaps'
import { Details, DropdownTypes } from '../forms/CommitmentForm'
import { BackButton, PopoverContainer, PopoverField } from '../StyledContainer'
import { useWorkspace } from '../../../hooks/useWorkspace'

const CommitmentScreen : FC = () => {
  const {
    focusedElems, dispatchFocused, editing, saving, setLoading, setSubmittedMessage,
    stakeholders, communities, causes, users, setEditing, updateCommitment, setSaving,
  } = useTerrainMapping()
  const [input, setInput] = useState<Details>({
    title: '',
    type: '',
    date: moment(),
    description: '',
    stakeholders: [],
    areas: [],
  })
  const { workspace } = useWorkspace()
  const [selectedIssues, setSelectedIssues] = useState<(IncidentCause & { selected: boolean })[]>([])
  const { focusedCommitment } = focusedElems
  const [ionAlert] = useIonAlert()

  const errorMessages = {
    title: 'You need to choose a title for this commitment',
    type: 'No type has been selected',
    description: 'Please, briefly describe this commitment',
    owner: 'Choose the owner of this commitment',
  }

  const getAddRemove = (newRecords: ({ id: number, name: string } | IncidentCause)[], oldRecords:
  ({ id: number, name: string } | IncidentCause)[]) : { toRemove: number[], toAdd: number[] } => {
    const toAdd = newRecords.filter(({ id }) => !oldRecords.find((val) => val.id === id))
    const toRemove = oldRecords.filter(({ id }) => !newRecords.find((val) => val.id === id))

    return {
      toAdd: toAdd.map(({ id }) => id),
      toRemove: toRemove.map(({ id }) => id),
    }
  }

  const selectCause = (index: number) => {
    const copy = selectedIssues.slice()
    copy.splice(index, 1, { ...copy[index], selected: !copy[index].selected })

    setSelectedIssues(copy)
  }

  const handleSubmit = () => {
    const keys = Object.keys(errorMessages)
    const keyError = keys.find((val) => !input[val] || input[val] === '')

    if (keyError) {
      ionAlert({
        header: 'Form Error',
        message: errorMessages[keyError],
        buttons: [{ text: 'ok' }],
      })
    }

    const selectedAreas = input.areas.map(({ value, label }) => ({ id: value, name: label }))
    const selectedStakeholders = input.stakeholders.map(({ value, label }) => ({ id: value, name: label }))
    const filteredIssues = selectedIssues.filter(({ selected }) => selected)

    const communityRecords = getAddRemove(selectedAreas, focusedCommitment.communities)
    const stakeholderRecords = getAddRemove(selectedStakeholders, focusedCommitment.stakeholders)
    const issueRecords = getAddRemove(filteredIssues, focusedCommitment.issues)

    setLoading(true)
    axios.put('/api/v2/commitment/edit_commitment', {
      issue_id: focusedCommitment.id,
      domain_id: workspace.id,
      title: input.title,
      received: input.date.format('DD/MM/YY'),
      description: input.description,
      type: input.type,
      issues_add: issueRecords.toAdd,
      issues_remove: issueRecords.toRemove,
      community_add: communityRecords.toAdd,
      community_remove: communityRecords.toRemove,
      stakeholders_add: stakeholderRecords.toAdd,
      stakeholders_remove: stakeholderRecords.toRemove,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server Error',
          message: 'Unknown server error',
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      updateCommitment({
        id: focusedCommitment.id,
        title: input.title,
        commitment_type: input.type as CommitmentType,
        date: input.date.format('DD/MM/YY'),
        description: input.description,
        communities: selectedAreas,
        stakeholders: selectedStakeholders,
        issues: filteredIssues,
        owner: input.owner as User,
      })
      setSubmittedMessage('Commitment form updated successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setLoading(false)
      setEditing(false)
      setSaving(false)
    })
  }

  useEffect(() => {
    if (!saving) { return }
    handleSubmit()
  }, [saving])

  useEffect(() => {
    if (!editing) { return }
    setInput({
      title: focusedCommitment.title,
      type: focusedCommitment.commitment_type,
      date: moment(focusedCommitment.date),
      description: focusedCommitment.description,
      stakeholders: focusedCommitment.stakeholders.map(({ id, name }) => ({ value: id, label: name })),
      areas: focusedCommitment.communities.map(({ id, name }) => ({ value: id, label: name })),
      owner: focusedCommitment.owner,
    })
    setSelectedIssues(causes.map((val) => ({ ...val, selected: !!focusedCommitment.issues.find(({ id }) => val.id === id) })))
  }, [editing])

  return (
    <ListContainer style={{ height: '100%', background: '#DCDCDC' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <BackButton onClick={() => {
          dispatchFocused({ type: FocusedActionKind.FORGET_SELECTION })
          setEditing(false)
        }}
        >Back to List
        </BackButton>
        <PopoverContainer>
          <PopoverField>
            <h6>Title</h6>
            {
              editing ? (
                <input
                  placeholder='Give this issue a title'
                  type='text'
                  value={input.title}
                  onChange={(e) => setInput({ ...input, title: e.target.value })}
                />
              ) : (
                <p>{ focusedCommitment.title }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Commitment Type</h6>
            {
              editing ? (
                <select
                  value={input.type}
                  onChange={(e) => setInput({ ...input, type: e.target.value as CommitmentType })}
                  style={{ padding: '5px 10px' }}
                >
                  <option value='' disabled>Select a type</option>
                  {
                    DropdownTypes.map((val) => (
                      <option key={val} value={val}>{ val }</option>
                    ))
                  }
                </select>
              ) : (
                <p>{ focusedCommitment.commitment_type }</p>

              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Date Received</h6>
            {
              editing ? (
                <Datetime
                  dateFormat='YYYY-MM-DD'
                  inputProps={{
                    placeholder: 'Select Date',
                    readOnly: true,
                    style: { backgroundColor: 'white' },
                  }}
                  value={input.date}
                  onChange={(e) => setInput({ ...input, date: e as moment.Moment })}
                />
              ) : (
                <p>{ moment(focusedCommitment.date).format('DD/MM/YY') }</p>
              )
            }
          </PopoverField>
          <PopoverField className='terrain-form-field form-desc'>
            <h6>Description</h6>
            {
              editing ? (
                <textarea value={input.description} onChange={(e) => setInput({ ...input, description: e.target.value })} />
              ) : (
                <p>{ focusedCommitment.description }</p>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Associated Stakeholders</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Areas'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={stakeholders.map(({ id, name }) => ({ value: id, label: name }))}
                  value={input.stakeholders}
                  onChange={(selected) => setInput({ ...input, stakeholders: selected })}
                />
              ) : (
                <>
                  {
                    focusedCommitment.stakeholders.length === 0 ? (
                      <p>No stakeholders</p>
                    ) : (
                      <ul>
                        {
                          focusedCommitment.stakeholders.map(({ name, id }) => (
                            <li key={id}><p>{ name}</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Relevant Areas</h6>
            {
              editing ? (
                <CreatableSelect
                  isMulti
                  placeholder='Select Areas'
                  name='colors'
                  className='select-container'
                  id='journey-passengers'
                // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={communities.map(({ id, name }) => ({ label: name, value: id }))}
                  value={input.areas}
                  onChange={(selected) => setInput({ ...input, areas: selected })}
                />
              ) : (
                <>
                  {
                    focusedCommitment.communities.length === 0 ? (
                      <p>No Areas</p>
                    ) : (
                      <ul>
                        {
                          focusedCommitment.communities.map(({ name, id }) => (
                            <li key={id}><p>{ name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Underlying Causes</h6>
            {
              editing ? (
                <>
                  {
                    selectedIssues.map((val, index) => (
                      <TagButton
                        key={val.id}
                        onClick={() => selectCause(index)}
                        style={{ backgroundColor: (val.selected) ? '#326771' : 'white', color: (val.selected) ? 'white' : '#326771' }}
                      >
                        { val.name }
                      </TagButton>
                    ))
                  }
                </>
              ) : (
                <>
                  {
                    focusedCommitment.issues.length === 0 ? (
                      <p>No Causes</p>
                    ) : (
                      <ul>
                        {
                          focusedCommitment.issues.map(({ name, id }) => (
                            <li key={id}><p>{ name }</p></li>
                          ))
                        }
                      </ul>
                    )
                  }
                </>
              )
            }
          </PopoverField>
          <PopoverField>
            <h6>Owner</h6>
            {
              editing ? (
                <Select
                  placeholder='Select user'
                  name='supervisor'
                  id='select-supervisor-container'
                  className='select-container'
                  // eslint-disable-next-line no-undef
                  menuPortalTarget={document.body}
                  options={users.map((user) => ({ value: user, label: user.email }))}
                  onChange={(selected) => setInput({ ...input, owner: selected.value })}
                />
              ) : (
                <p>{ focusedCommitment.owner.first_name } { focusedCommitment.owner.final_name }</p>
              )
            }
          </PopoverField>
        </PopoverContainer>
      </div>
    </ListContainer>
  )
}

export default CommitmentScreen
