import React, { useEffect } from 'react'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, SelectionDetail } from '../../StyledContainers'

import { threat, threatLikelihood, impactRating } from '../FormOptions'

/**
 *  =======================
 *      Inherent Summary
 *  =======================
 *  Thread ratings, likelyhood ...
 */
const Summary = ({
  children, onClose, next, message, title, measures, onCreate, input,
  getLikelihoodCategory, inherent,
}) => {
  useEffect(() => {
    onCreate()
  }, [])
  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'> { title } </h5>
              <p> { message } </p>
            </SettingField>
            <IonRow className='ion-align-items-center'>
              <IonCol size={4}>
                <SettingField style={{ width: 'auto' }}>
                  <h5>Threat Rating</h5>
                  <p id='summary-threat-rating'> { input.inherentThreatRating } </p>
                </SettingField>
              </IonCol>
              <IonCol size={8}>
                <SelectionDetail>
                  <p>{ threat[input.inherentThreatRating] }</p>
                </SelectionDetail>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size={4}>
                <SettingField style={{ width: 'auto' }}>
                  <h5>Likelihood Rating</h5>
                  <p id='summary-likelihood-rating'> { getLikelihoodCategory(measures.threatLikelihood) } </p>
                </SettingField>
              </IonCol>
              <IonCol size={8}>
                <SelectionDetail>
                  <p>{ threatLikelihood[measures.threatLikelihood] }</p>
                </SelectionDetail>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size={4}>
                <SettingField style={{ width: 'auto' }}>
                  <h5>Impact Rating</h5>
                  <p id='summary-impact-rating'> { measures.impactRating } </p>
                </SettingField>
              </IonCol>
              <IonCol size={8}>
                <SelectionDetail>
                  <p>{ impactRating[measures.impactRating] }</p>
                </SelectionDetail>
              </IonCol>
            </IonRow>
            <p style={{ marginTop: '15px' }}>Based on the above information, the { (inherent) ? 'Inherent Risk' : 'Residual Risk' } to this asset is calculated. </p>
            <SettingField style={{ width: 'auto' }}>
              <h5 id='summary-assessment-type'> { (inherent) ? 'Inherent Risk' : 'Residual Risk' } Rating</h5>
              <p id='summary-risk-rating'> { measures.risk } </p>
            </SettingField>
          </div>
          <IonRow style={{ paddingRight: '20px' }} className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default Summary
