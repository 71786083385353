import { IonButton, IonRow } from '@ionic/react'
import * as React from 'react'
import Select from 'react-select'
import * as moment from 'moment'
import { useChatsData } from '../../../hooks/chats/useChatsData'
import { useAuth } from '../../../hooks/useAuth'
import { OverlayContainer } from '../StyledContainers'
import axios from '../../../utils/axios'
import { ChatRoom } from '../../types/GlobalTypes'

const AddUsers : React.FC = () => {
  const {
    users, focusedRoom, setShowAddUsers, setLoading,
    updateRoom, setFocusedRoom, pushNewRoom,
  } = useChatsData()
  const [userToAdd, setUserToAdd] = React.useState<number>(null)
  const { user } = useAuth()

  const handleSubmit = () => {
    setShowAddUsers(false)
    const recipient = users.find(({ id }) => id === userToAdd)
    setLoading(true)
    if (focusedRoom.isGroupChat) {
      setLoading(true)
      axios.post('/add_user_to_group_chat', { room_id: focusedRoom.id, user_id: recipient.id })
        .then(({ data }) => {
          if (!data.ok) {
            setLoading(false)
            return
          }

          const copy = focusedRoom.recipients.slice()
          copy.push(recipient)

          const updated = { ...focusedRoom, recipients: copy }
          updateRoom(updated)
          setFocusedRoom(updated)
        })
        .catch(() => {
          reportError('An unexpected server error has ocurred.')
        })
        .finally(() => {
          setLoading(false)
        })
      return
    }

    const userObjs = [...focusedRoom.recipients, { ...user, id: user.user_id }, recipient]
    axios.post('/create_room', { recipients: userObjs.map(({ id }) => id) })
      .then(({ data }) => {
        const chatName = userObjs.map(({ first_name }) => first_name).join(', ')

        const newRoom : ChatRoom = {
          id: data.room_id,
          recipients: userObjs.filter(({ id }) => user?.user_id !== id),
          name: chatName,
          read: true,
          latestMessage: null,
          created_at: moment().format(),
          isGroupChat: userObjs.length > 2,
          recipientUpdates: userObjs.filter(({ id }) => user?.user_id !== id).map(({ id }) => ({ user_id: id, read: false, updated_at: moment().format() })),
        }
        setLoading(false)
        pushNewRoom(newRoom)
        setFocusedRoom(newRoom)
      }).catch((e) => {
        console.log(e)
      }).finally(() => {
        setLoading(false)
      })
  }

  return (
    <OverlayContainer>
      <h4>Add Users</h4>
      <Select
        placeholder='Select user'
        name='supervisor'
        id='select-supervisor-container'
        className='select-container'
              // eslint-disable-next-line no-undef
        menuPortalTarget={document.body}
        options={users.filter(({ id }) => id !== user.user_id && !focusedRoom.recipients.find((val) => val.id === id))
          .map(({ id, first_name, final_name }) => ({ value: id, label: `${first_name} ${final_name}` }))}
        onChange={(selected) => setUserToAdd(selected.value)}
      />
      <IonRow style={{ marginTop: '10px' }}>
        <IonButton onClick={() => setShowAddUsers(false)} style={{ '--background': '#8E151F', color: 'white' }}>Cancel</IonButton>
        <IonButton onClick={handleSubmit} style={{ '--background': '#326771', color: 'white' }}>Save</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default AddUsers
