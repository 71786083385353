import * as React from 'react'

import moment from 'moment'

import { IonCol, IonContent, IonRow, IonLabel, IonGrid, IonButton, IonImg, useIonAlert } from '@ionic/react'

import { PopupBackground } from '../popups/StyledContainers'

import { OverlayContainer } from '../GlobalContainers'

import logo from './logo.png'

import { useReportsData } from '../../hooks/reports/useReportsData'

import { indicators } from '../maps/forms/Indicators.json'

import { useWorkspace } from '../../hooks/useWorkspace'

import { SimpleButton } from '../incident-management/StyledContainers'

import useApi from '../../hooks/testHooks'

const IncidentPopupAlog = () : JSX.Element => {
  const { workspace } = useWorkspace()
  const apiHook = useApi()

  const {
    setShowFieldSitrep,
    name,
    county,
    state,
    insight,
    setShowInternalIncident,
    setStep,
    step,
    data,
    internalIncident,
  } = useReportsData()

  function handleClose() {
    setShowInternalIncident(false)
    setStep(1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    apiHook.downloadInternalIncident({
      domain_id: workspace.id,
      incident_id: internalIncident.id,
    })
      .then((result) => {
        apiHook.downloader2(result, internalIncident.title)
      })
  }

  const handleNext = () => {
    setStep(3)
  }

  const handlePrev = () => {
    setStep(1)
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 0 }} />
      <OverlayContainer style={{ height: '550px', width: '90%' }}>
        <IonRow style={{ height: '10%' }}>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>Incident Report</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonContent style={{ height: '80%', '--ion-background-color': '#FFFFFF' }}>

          <IonRow>
            <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.3em', marginLeft: '1.5em' }}>Action Log</IonLabel>
          </IonRow>

          <IonGrid style={{ marginTop: '1em' }}>

            <IonRow>
              <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#326771', fontSize: '1em', paddingLeft: '0.3em' }}>Date</IonLabel>
              </IonCol>
              <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#326771', fontSize: '1em', paddingLeft: '0.4em' }}>Time</IonLabel>
              </IonCol>
              <IonCol size='4' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#326771', fontSize: '1em', paddingLeft: '0.4em' }}>Log</IonLabel>
              </IonCol>
              <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#326771', fontSize: '1em', paddingLeft: '0.4em' }}>Owner</IonLabel>
              </IonCol>
              <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#326771', fontSize: '1em', paddingLeft: '0.4em' }}>Submitted by</IonLabel>
              </IonCol>
            </IonRow>
            {
              (data.action_log.length === 0) && (
                <IonRow>
                  <IonCol size='12' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                    <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>There are no recorded Actions.</IonLabel>
                  </IonCol>
                </IonRow>
              )
            }
            {
              data.action_log.map(
                (logger) => (
                  <IonRow>
                    <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      <IonLabel style={{ color: '#8E151F', fontSize: '1em', paddingLeft: '0.3em' }}>{moment(logger.created_at).format('DD/MM/YYYY')}</IonLabel>
                    </IonCol>
                    <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{moment(logger.created_at).format('HH:mm')}</IonLabel>
                    </IonCol>
                    <IonCol size='4' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{logger.log}</IonLabel>
                    </IonCol>
                    <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`${(data.users.find((e) => e.id === logger.owner)).first_name} ${(data.users.find((e) => e.id === logger.owner)).final_name}`}</IonLabel>
                    </IonCol>
                    <IonCol size='2' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      <IonLabel style={{ color: '#000000', fontSize: '1em', paddingLeft: '0.4em' }}>{`${(data.users.find((e) => e.id === logger.user_id)).first_name} ${(data.users.find((e) => e.id === logger.user_id)).final_name}`}</IonLabel>
                    </IonCol>
                  </IonRow>
                ),
              )
            }
          </IonGrid>

        </IonContent>

        <IonRow class='ion-align-items-end' style={{marginTop: '1em'}}>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>

      </OverlayContainer>
    </>
  )
}

export default IncidentPopupAlog
