import * as React from 'react'

import moment from 'moment'

import { IonCol, IonContent, IonRow, IonLabel, IonGrid, IonButton, IonImg, useIonAlert } from '@ionic/react'

import { PopupBackground } from './StyledContainers'

import { OverlayContainer } from '../GlobalContainers'

import logo from '../reports/logo.png'

import { useReportsData } from '../../hooks/reports/useReportsData'

import { indicators } from '../maps/forms/Indicators.json'

import { useWorkspace } from '../../hooks/useWorkspace'

const FieldSitrepPopup = ({
  setShowFieldSitrep, name, county, state, insight,
}) => {
  const workspace = useWorkspace()

  function handleClose() {
    setShowFieldSitrep(false)
  }

  const [presentAlert] = useIonAlert()

  const answers = [...insight.needed, ...insight.optional]
  console.log(answers)

  function getColor(level) {
    if (level === 1) {
      return '#14C105'
    } if (level === 2) {
      return '#349DFF'
    } if (level === 3) {
      return '#FFE500'
    } if (level === 4) {
      return '#FF8A00'
    } if (level === 5) {
      return '#FF0000'
    }
  }

  function handleDownload() {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '550px', width: '70%', zIndex: 11 }}>
        <IonRow style={{ height: '10%' }}>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.5em', marginLeft: '1em' }}>Field Sitrep</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right', marginRight: '3em'}}>
            <img src={logo} style={{ width: '8.75em', height: '2.5em' }} alt='logo' />
          </IonCol>
        </IonRow>

        <IonContent style={{ height: '80%', '--ion-background-color': '#FFFFFF' }}>

          <IonRow>
            <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.3em', marginTop: '2em', marginLeft: '1.3em', marginBottom: '1em' }}>Report submitted by</IonLabel>
          </IonRow>

          <IonGrid>
            <IonRow>
              <IonCol class='ion-no-padding'>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>Name:</IonLabel>
              </IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>State:</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class='ion-no-padding'>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{name}</IonLabel>
              </IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{state}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow style={{ paddingTop: '0.5em' }}>
              <IonCol class='ion-no-padding'>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>Date:</IonLabel>
              </IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}>
                <IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>County:</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol class='ion-no-padding'>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{moment(insight.created_at).format('DD/MM/YYYY')}</IonLabel>
              </IonCol>
              <IonCol class='ion-no-padding' style={{ textAlign: 'right', marginRight: '3em' }}>
                <IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{county.name}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow style={{ paddingTop: '0.5em' }}>
              <IonCol class='ion-no-padding'><IonLabel style={{ color: '#8E151F', fontSize: '1em', marginLeft: '2.3em' }}>Organisation:</IonLabel></IonCol>
            </IonRow>
            <IonRow>
              <IonCol class='ion-no-padding'><IonLabel style={{ fontSize: '1em', marginLeft: '2.3em', color: '#000000' }}>{workspace.workspace.name}</IonLabel></IonCol>
            </IonRow>
          </IonGrid>

          <IonRow>
            <IonLabel style={{ color: 'black', fontWeight: 'bold', fontSize: '1.3em', marginTop: '2em', marginLeft: '1.3em' }}>Security Questions</IonLabel>
          </IonRow>

          <IonGrid style={{ marginTop: '1em' }}>

            <IonRow>
              <IonCol size='3' style={{ backgroundColor: '#8E151F', color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#FFFFFF', fontSize: '1em', paddingLeft: '0.3em' }}>Factor</IonLabel>
              </IonCol>
              <IonCol size='9' style={{ backgroundColor: '#8E151F', color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                <IonLabel style={{ color: '#FFFFFF', fontSize: '1em', paddingLeft: '0.4em' }}>Answer</IonLabel>
              </IonCol>
            </IonRow>
            {
              indicators.map(
                (indicator, index) => (
                  <IonRow>
                    <IonCol size='3' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em' }}>
                      <IonLabel style={{ fontSize: '0.9em', color: '#8E151F', fontWeight: 'bold' }}>{indicator.title}</IonLabel>
                    </IonCol>
                    <IonCol size='9' style={{ color: '#C4C4C4', outline: 'solid 1px', paddingTop: '0.9em', paddingBottom: '0.9em', backgroundColor: getColor(answers[index])}}>
                      {
                        answers[index] ? (
                          <IonLabel style={{ fontSize: '0.9em', color: '#000000' }}>{indicator.options[answers[index] - 1]}</IonLabel>
                        ) : <IonLabel style={{ fontSize: '0.9em', color: '#000000' }}>N/A</IonLabel>
                      }
                    </IonCol>
                  </IonRow>
                ),
              )
            }

          </IonGrid>
          {
            insight.description && (
              <IonGrid style={{ }}>
                <IonRow style={{ marginBottom: '0.5em' }}>
                  <IonLabel style={{
                    color: '#8E151F',
                    fontSize: '1em',
                    marginTop: '1em',
                    fontWeight: 'bold',
                  }}
                  >Description
                  </IonLabel>
                </IonRow>
                <IonRow style={{ }}>
                  <IonLabel style={{ fontSize: '0.9em', color: '#000000' }}>{insight.description}</IonLabel>
                </IonRow>
              </IonGrid>

            )
          }
        </IonContent>

        <IonRow class='ion-align-items-end' style={{marginTop: '1em'}}>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
            <IonButton style={{ '--ion-color-primary': '#8E151F' }} onClick={handleClose}>Close</IonButton>
          </IonCol>
        </IonRow>

      </OverlayContainer>
    </>
  )
}

export default FieldSitrepPopup
