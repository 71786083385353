import { Icon } from '@iconify/react'
import * as moment from 'moment'
import * as React from 'react'
import { IonRow } from '@ionic/react'
import { getSafetyColor } from '../../maps/apps/AppMap'
import useApi from '../../../hooks/testHooks'
import { BlueButton } from '../../incident-management/StyledContainers'
import { SafetyUser } from '../../types/OptimizedMaps'

const SafetyCheckPopup : React.FC <{ data: SafetyUser, workspaceId: number }> = ({ data, workspaceId }) => {
  const apiHook = useApi()
  return (
    <div>
      <div className='asset-popup-heading'>
        <p>{ data.first_name } { data.final_name } <Icon style={{ color: 'black' }} icon='bxs:user' /></p>
      </div>
      <p className='asset-popup-property'><span>Location: </span> { data.safety.county.name } County</p>
      <p className='asset-popup-property'><span>Status: </span>
        <span className='alert-state-display county-popup-text' style={{ backgroundColor: getSafetyColor(data.safety.level).background, color: 'white' }}>
          {getSafetyColor(data.safety.level).title}
        </span>
      </p>
      <p className='asset-popup-property'> <span> Last Updated: </span> { moment(data.safety.created_at).format('DD/MM/YY') }</p>
      <IonRow className='ion-justify-content-end'>
        <BlueButton onClick={() => apiHook.promptOneSafety(data.id, workspaceId)}>Request Update</BlueButton>
      </IonRow>
    </div>
  )
}

export default SafetyCheckPopup
