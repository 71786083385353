import { Icon } from '@iconify/react'
import { IonButton, IonRow } from '@ionic/react'
import * as React from 'react'

interface Props {
  onOpen: () => void
}

const DefaultScreen : React.FC<Props> = ({ onOpen }) => (
  <div className='chat-default-screen'>
    <div style={{ maxWidth: '30%' }}>
      <IonRow className='ion-justify-content-center'>
        <Icon icon='jam:messages-f' style={{ color: '#326771', fontSize: '5rem' }} />
      </IonRow>
      <p>You don’t have a conversation selected. Choose an existing conversation or start a new one.  </p>
      <IonRow className='ion-justify-content-center'>
        <IonButton onClick={onOpen} style={{ '--background': '#326771' }}>
          New Message
        </IonButton>
      </IonRow>
    </div>
  </div>
)

export default DefaultScreen
