import { IonRow, useIonAlert } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { TerrainMapElem } from '../../../hooks/terrain-mapping/types/HookInterface'
import { SelectView } from '../../maps/StyledContainers'
import {
  Community, Flashpoint, InternalProject, StakeholderQuadrant,
} from '../../types/OptimizedMaps'
import {
  FactorOption, QuadrantContainer, QuadrantRow, StakeholderPoint, ViewContainer,
} from '../StyledContainers'
import axios from '../../../utils/axios'
import { useWorkspace } from '../../../hooks/useWorkspace'

const FactorElems = [TerrainMapElem.COMMUNITY, TerrainMapElem.FLASHPOINT, 'Project']

type AvailableFactors = TerrainMapElem.COMMUNITY | TerrainMapElem.FLASHPOINT | 'Project'
type StakeholderPin = { name: string, id: number, quadrant: StakeholderQuadrant }
type AvailableElems = Community | Flashpoint | InternalProject

const StakeholderMap : FC = () => {
  const {
    communities, flashpoints, stakeholders, updateStakeholder, setLoading,
    setSubmittedMessage, updateFlashpoint, projects, updateProject,
  } = useEngagementData()
  const { workspace } = useWorkspace()
  const [displayedStakeholders, setDisplayedStakeholders] = useState<StakeholderPin[]>([])
  const [factorSelection, setFactorSelection] = useState<AvailableFactors>(TerrainMapElem.COMMUNITY)
  const [selection, setSelection] = useState<{ elem: AvailableElems | null, type: AvailableFactors | null }>({ elem: null, type: null })
  const [ionAlert] = useIonAlert()

  const handleSelectFactor = (factorType: AvailableFactors, selected: AvailableElems) => {
    setSelection({ elem: selected, type: factorType })

    if (factorType === TerrainMapElem.COMMUNITY) {
      const filteredStakeholders = stakeholders.filter(({ areas }) => areas.find(({ id }) => id === selected.id))
      setDisplayedStakeholders(filteredStakeholders.map(({ id, name, areas }) => ({ id, name, quadrant: areas.find((area) => area.id === selected.id).quadrant })))
      return
    }

    if (factorType === TerrainMapElem.FLASHPOINT) {
      const selectedFlashpoint = selected as Flashpoint
      setDisplayedStakeholders(selectedFlashpoint.primary_groups.map(({ id, name, quadrant }) => ({ id, name, quadrant })))
      return
    }

    if (factorType === 'Project') {
      const selectedProject = selected as InternalProject
      setDisplayedStakeholders(selectedProject.stakeholders.map(({ id, name, quadrant }) => ({ id, name, quadrant })))
    }
  }

  const updateProjectStakeholder = (stakeholderId: number, newQuadrant: StakeholderQuadrant) => {
    setLoading(true)
    axios.put('/api/v2/internal_project/edit_stakeholder_quadrant', {
      domain_id: workspace.id,
      project_id: selection.elem.id,
      stakeholder_id: stakeholderId,
      new_quadrant: newQuadrant,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server error',
          message: data.message,
          buttons: [{ text: 'OK' }],
        })
        return
      }

      const project = selection.elem as InternalProject
      const copy = project.stakeholders.slice()
      const index = copy.findIndex(({ id }) => id === stakeholderId)
      copy.splice(index, 1, { ...copy[index], quadrant: newQuadrant })
      const updated = { ...project, stakeholders: copy }

      updateProject(updated)
      handleSelectFactor(selection.type, updated)
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {

    })
  }

  const updateCommunityStakeholder = (stakeholderId: number, newQuadrant: StakeholderQuadrant) => {
    setLoading(true)
    axios.put('/api/v2/community/edit_stakeholder_quadrant', {
      domain_id: workspace.id,
      stakeholder_id: stakeholderId,
      community_id: selection.elem.id,
      new_quadrant: newQuadrant,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server Error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      const stakeholder = stakeholders.find(({ id }) => id === stakeholderId)
      const areas = stakeholder.areas.slice()

      const communityIndex = areas.findIndex(({ id }) => selection.elem.id === id)

      if (communityIndex < 0) { return }
      areas.splice(communityIndex, 1, { ...areas[communityIndex], quadrant: newQuadrant })
      updateStakeholder({ ...stakeholder, areas })
      setSubmittedMessage('Stakeholder updated successfully')
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'Ok' }],
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const updateFlashpointStakeholder = (stakeholderId: number, newQuadrant: StakeholderQuadrant) => {
    setLoading(true)
    axios.put('/api/v2/flashpoint/edit_stakeholder_quadrant', {
      domain_id: workspace.id,
      new_quadrant: newQuadrant,
      flashpoint_id: selection.elem.id,
      stakeholder_id: stakeholderId,
    }).then(({ data }) => {
      if (!data.ok) {
        ionAlert({
          header: 'Server error',
          message: data.message,
          buttons: [{ text: 'Ok' }],
        })
        return
      }
      const flashpoint = selection.elem as Flashpoint
      const areas = flashpoint.primary_groups.slice()
      const index = areas.findIndex(({ id }) => id === stakeholderId)
      areas.splice(index, 1, { ...areas[index], quadrant: newQuadrant })

      const updatedFlashpoint = { ...flashpoint, primary_groups: areas }
      updateFlashpoint(updatedFlashpoint)
      handleSelectFactor(selection.type, updatedFlashpoint)
    }).catch(() => {
      ionAlert({
        header: 'Server Error',
        message: 'Unknown server error',
        buttons: [{ text: 'OK' }],
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleDrop = (e: DragEvent, newQuadrant: StakeholderQuadrant) => {
    e.preventDefault()
    e.stopPropagation()

    const id = Number(e.dataTransfer.getData('id'))

    if (selection.type === TerrainMapElem.COMMUNITY) {
      updateCommunityStakeholder(id, newQuadrant)
      return
    }

    if (selection.type === TerrainMapElem.FLASHPOINT) {
      updateFlashpointStakeholder(id, newQuadrant)
      return
    }

    if (selection.type === 'Project') {
      updateProjectStakeholder(id, newQuadrant)
    }
  }

  useEffect(() => {
    handleSelectFactor(selection.type, selection.elem)
  }, [stakeholders])

  return (
    <ViewContainer>
      <IonRow style={{ height: '100%' }}>
        <div style={{
          flex: '2', padding: '0px 40px', margin: '40px 0px', borderRight: '1px solid black',
        }}
        >
          <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <div className='quadrant-labels'>
                <p>High Importance/</p>
                <p>Low influence</p>
              </div>
              <div className='quadrant-labels'>
                <p>High Importance/</p>
                <p>High influence</p>
              </div>
            </IonRow>
            <QuadrantContainer>
              <QuadrantRow>
                <QuadrantContainer
                  onDrop={(e) => handleDrop(e, StakeholderQuadrant.HIGH_LOW)}
                  onDragOver={(e) => e.preventDefault()}
                  style={{ backgroundColor: 'rgba(255, 153, 0, 0.25', border: '1px solid black' }}
                >
                  {
                    displayedStakeholders.filter(({ quadrant }) => quadrant === StakeholderQuadrant.HIGH_LOW).map((val) => (
                      <StakeholderPoint
                        key={val.id}
                        draggable
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          top: `${Math.random() * 80}%`,
                          left: `${Math.random() * 75}%`,
                        }}
                        title={val.name}
                        onDragStart={(e) => e.dataTransfer.setData('id', val.id)}
                      >{ val.name }
                      </StakeholderPoint>
                    ))
                  }
                </QuadrantContainer>
                <QuadrantContainer
                  onDrop={(e) => handleDrop(e, StakeholderQuadrant.HIGH_HIGH)}
                  onDragOver={(e) => e.preventDefault()}
                  style={{ backgroundColor: 'rgba(255, 0, 0, 0.25)', borderTop: '1px solid black', borderRight: '1px solid black' }}
                >
                  {
                    displayedStakeholders.filter(({ quadrant }) => quadrant === StakeholderQuadrant.HIGH_HIGH).map((val) => (
                      <StakeholderPoint
                        key={val.id}
                        draggable
                        onDragStart={(e) => e.dataTransfer.setData('id', val.id)}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          top: `${Math.random() * 80}%`,
                          left: `${Math.random() * 75}%`,
                        }}
                        title={val.name}
                      >{ val.name }
                      </StakeholderPoint>
                    ))
                  }
                </QuadrantContainer>
              </QuadrantRow>
              <QuadrantRow>
                <QuadrantContainer
                  onDrop={(e) => handleDrop(e, StakeholderQuadrant.LOW_LOW)}
                  onDragOver={(e) => e.preventDefault()}
                  style={{ backgroundColor: 'rgba(12, 149, 0, 0.25)', borderLeft: '1px solid black', borderBottom: '1px solid black' }}
                >
                  {
                    displayedStakeholders.filter(({ quadrant }) => quadrant === StakeholderQuadrant.LOW_LOW).map((val) => (
                      <StakeholderPoint
                        draggable
                        onDragStart={(e) => e.dataTransfer.setData('id', val.id)}
                        key={val.id}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          top: `${Math.random() * 80}%`,
                          left: `${Math.random() * 75}%`,
                        }}
                        title={val.name}
                      >{ val.name }
                      </StakeholderPoint>
                    ))
                  }
                </QuadrantContainer>
                <QuadrantContainer
                  onDrop={(e) => handleDrop(e, StakeholderQuadrant.LOW_HIGH)}
                  onDragOver={(e) => e.preventDefault()}
                  style={{ backgroundColor: 'rgba(0, 117, 255, 0.25)', border: '1px solid black' }}
                >
                  {
                    displayedStakeholders.filter(({ quadrant }) => quadrant === StakeholderQuadrant.LOW_HIGH).map((val) => (
                      <StakeholderPoint
                        onDragStart={(e) => e.dataTransfer.setData('id', val.id)}
                        key={val.id}
                        draggable
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          top: `${Math.random() * 80}%`,
                          left: `${Math.random() * 75}%`,
                        }}
                        title={val.name}
                      >{ val.name }
                      </StakeholderPoint>
                    ))
                  }
                </QuadrantContainer>
              </QuadrantRow>
            </QuadrantContainer>
            <IonRow className='ion-justify-content-between ion-align-items-center'>
              <div className='quadrant-labels'>
                <p>Low Importance/</p>
                <p>Low influence</p>
              </div>
              <div className='quadrant-labels'>
                <p>Low Importance/</p>
                <p>High influence</p>
              </div>
            </IonRow>
          </div>
        </div>
        <div style={{ flex: 1, padding: '40px 20px' }}>
          <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
            <h4 style={{ fontWeight: 'bold', color: '#326771', fontSize: '1.2rem' }}>Stakeholders</h4>
            <p style={{ margin: '15px 0' }}>Select a key factor to view ths stakeholder data linked to it.</p>
            <SelectView
              style={{ backgroundColor: 'white', color: '#326771', marginBottom: '10px' }}
              value={factorSelection}
              onChange={(e) => setFactorSelection(e.target.value)}
            >
              {
                FactorElems.map((val) => (
                  <option key={val} value={val}>{ val }</option>
                ))
              }
            </SelectView>
            <div style={{ flex: 1, overflow: 'auto' }}>
              {
                factorSelection === TerrainMapElem.COMMUNITY && (
                  <>
                    {
                      communities.map((val) => (
                        <FactorOption
                          className={`${(selection.elem && selection.type === TerrainMapElem.COMMUNITY && val.id === selection.elem.id) ? 'selected-factor' : ''}`}
                          key={val.id}
                          onClick={() => handleSelectFactor(TerrainMapElem.COMMUNITY, val)}
                        >{ val.name }
                        </FactorOption>
                      ))
                    }
                  </>
                )
              }
              {
                factorSelection === TerrainMapElem.FLASHPOINT && (
                  <>
                    {
                      flashpoints.map((val) => (
                        <FactorOption
                          className={`${(selection.elem && selection.type === TerrainMapElem.FLASHPOINT && val.id === selection.elem.id) ? 'selected-factor' : ''}`}
                          key={val.id}
                          onClick={() => handleSelectFactor(TerrainMapElem.FLASHPOINT, val)}
                        >{ val.title }
                        </FactorOption>
                      ))
                    }
                  </>
                )
              }
              {
                factorSelection === 'Project' && (
                  <>
                    {
                      projects.map((val) => (
                        <FactorOption
                          className={`${(selection.elem && selection.type === 'Project' && val.id === selection.elem.id) ? 'selected-factor' : ''}`}
                          key={val.id}
                          onClick={() => handleSelectFactor('Project', val)}
                        >{ val.title }
                        </FactorOption>
                      ))
                    }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </IonRow>
    </ViewContainer>
  )
}

export default StakeholderMap
