import React from 'react'
import { ProvideAuth } from '../hooks/useAuth'
import { ProvideWorkspace } from '../hooks/useWorkspace'
import { ProvideGeolocation } from '../hooks/useGeolocation'
import { ProvideNotification } from '../hooks/useNotification'
import { ProvideSafetyCheck } from '../hooks/safety-checks/useSafetyCheckPopup'
import { ProvideSecCond } from '../hooks/safety-checks/useSecCondPopup'
import { ProvideTrackerPopup } from '../hooks/safety-checks/useTrackerPopup'
import { ProvideFSitRep } from '../hooks/safety-checks/useFSitRepPopup'
/**
 *  ============================
 *     SET-UP ALL PROVIDERS
 *  ============================
 *  Set up all providers in the same file so that
 *  it doesn't look to cluttered.
 */

const Providers = ({ children }) => (
  <ProvideFSitRep>
    <ProvideNotification>
      <ProvideAuth>
        <ProvideGeolocation>
          <ProvideWorkspace>
            <ProvideSafetyCheck>
              <ProvideTrackerPopup>
                <ProvideSecCond>
                  {
                  children
                }
                </ProvideSecCond>
              </ProvideTrackerPopup>
            </ProvideSafetyCheck>
          </ProvideWorkspace>
        </ProvideGeolocation>
      </ProvideAuth>
    </ProvideNotification>
  </ProvideFSitRep>
)

export default Providers
