/* eslint-disable import/no-unresolved */
import React, { useState, useRef } from 'react'
import { IonRow } from '@ionic/react'

import axios from 'axios'
import Map from '../Map'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'

import {
  fetchAlerStates, drawCountyShapesIncidents, updateCountyColors, mapPopup, restrictionBoxIndicator,
} from './AppMap'

import EditMovementBox from '../features/EditMovementBox'

import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import RestrictionBox from '../features/RestrictionBox'
import Route from '../features/Route'

import IncidentList from '../../consultant/IncidentList'

import Incident from '../features/Incident'
import AlertList from '../../consultant/AlertList'
import EditIncident from '../../consultant/EditIncident'
import { createCountyMap } from '../../../hooks/risks/helpers/ProcessBackendData'

/**
 *  ===========================
 *    Consultant's App Map
 *  ===========================
 */
const ConsultantMap = ({
  mapRef, setCounties, incidentList, setIncidents,
  incidentFilters, setIncidentFilters, setRestrictionBoxes, setRoutes,
  setShowRouteForm, showRouteForm, setShowMbForm, showMbForm, routes,
  finish, movementBoxes, showIncidentForm,
  setShowIncidentForm, updateIncidents, showMbs, showIncidents, showRoutes,
  setShowLoading, view, mode, editing, filterIncidents,
  incidentTimeFilter, setPopup, setRegions,
  setCountryList, drawLayers, asFilters, dispatchSelected, selected,
  startIncidentEdit, focusedIncident, disregardIncident, finishIncidentEdit,
  handleDeleteIncident, closeIncidentEdit, countiesArr, updateCountiesArr,
  suggestedReports, setSuggestedReports, setSelectedCounty, formatPhrases,
}) => {
  const apiHook = useApi()

  const [markers, setMarkers] = useState([])
  const [focusedMb, setFocusedMb] = useState(null)
  const { workspace } = useWorkspace()

  /* Show popups when  alert state view */
  const handleClick = (e) => {
    if (mode !== 'Alert States' || e.features[0].properties.Alert_color === 'rgba(255,255,255, 0)') { return }

    // Copy coordinates array.
    const coordinates = e.lngLat
    const { description } = e.features[0].properties

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
    }
    setPopup(new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(description)
      .addTo(mapRef.current))
  }

  const handleClickRef = useRef(handleClick)
  handleClickRef.current = handleClick

  const restrictionBoxPopup = (e) => {
    if (!showIncidentForm && !showMbForm && !showRouteForm) {
      const { properties } = e.features[0]
      /* Filter out box that's currently being edited */
      const boxes = movementBoxes.filter((box) => box.properties.id !== properties.id)

      mapRef.current.getSource('restriction-boxes').setData({
        type: 'FeatureCollection',
        features: boxes,
      })
      setFocusedMb({
        properties: e.features[0].properties,
        coordinates: e.features[0].geometry.coordinates,
      })
    } else {
      mapPopup(e, mapRef)
    }
  }
  const restrictionBoxPopupRef = useRef(restrictionBoxPopup)
  restrictionBoxPopupRef.current = restrictionBoxPopup

  const incidentsPopup = (e) => {
    if (editing && !focusedIncident) {
      const incident = e.features[0]
      startIncidentEdit({ ...incident.properties, latitude: e.lngLat.lat, longitude: e.lngLat.lng })
    } else {
      setPopup(mapPopup(e, mapRef))
    }
  }

  const incidentsPopupRef = useRef(incidentsPopup)
  incidentsPopupRef.current = incidentsPopup

  /* Cross-reference submitters and add COUNTY and REGION names */
  const formatIncidents = (incidents, submitters, countyMap) => {
    const list = incidents.map((incident) => {
      const user = submitters.find((dbUser) => dbUser.id === incident.user_id)
      return {
        ...incident,
        user,
        username: `${user.first_name} ${user.final_nam}`,
        countyName: countyMap[incident.county_id].shapeName,
        regionName: countyMap[incident.county_id].ADM1_EN,
      }
    })

    return { list }
  }

  /**
   *  ============================
   *          Map Listeners
   *  ============================
   */
  const setUpListeners = () => {
    mapRef.current.on('load', async () => {
      mapRef.current.resize()

      const [mapData, { incidents, submitters }, { data }, mb, paths, keyphraseData] = await Promise.all([fetchAlerStates(true, workspace.id, true),
        apiHook.getIncidents(workspace.id), axios.get('/api/v1/types'), apiHook.getMovebox(workspace.id), apiHook.getPaths(workspace.id), apiHook.getKeyphrases()])
      setCounties(mapData.counties)
      // eslint-disable-next-line camelcase
      setRegions(mapData.regions)
      setCountryList(mapData.countryRegions)

      setSuggestedReports(mapData.analyses)
      formatPhrases(keyphraseData.keyphrases, keyphraseData.phrasegroups)
      drawCountyShapesIncidents(mapData.counties, mapRef, null, true, mapData.regions)
      updateCountyColors(asFilters, updateCountiesArr, mapData.counties, mapRef, drawLayers, mapData.regions, setRegions)

      const countyMap = createCountyMap(mapData.counties)
      /* Cross-reference submitters */
      const { list } = formatIncidents(incidents, submitters, countyMap)
      /* Fetch incidents */
      setIncidents(list)

      const checkboxes = Object.fromEntries(data.types.map((type) => [type, true]))
      setIncidentFilters(checkboxes)

      /* Fusion view extra elements */
      const features = mb.boxes.map((val) => {
        const geojson = JSON.parse(val.geoData)
        geojson.properties = {
          senior_manager_approval: val.senior_manager_approval,
          no_night_travel: val.anti_vampire_rules,
          out_of_bounds: val.forbidden,
          check_in_days: val.check_in_days,
          description: val.description,
          id: val.id,
          popupdata: restrictionBoxIndicator(val),
        }
        return geojson
      })
      setRestrictionBoxes(features)

      /* Add feature collection source to the map */
      mapRef.current.addSource('restriction-boxes', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })

      mapRef.current.addLayer({
        id: 'mb-layer',
        source: 'restriction-boxes',
        type: 'fill',
        paint: {
          'fill-color': '#0090ff', // blue color fill
          'fill-opacity': 0.5,
        },
      })

      mapRef.current.addLayer({
        id: 'border-mb',
        type: 'line',
        source: 'restriction-boxes',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#888',
          'line-width': 8,
        },
      })

      const routesFromDb = paths.routes.map((route) => JSON.parse(route.geoData))
      setRoutes(routesFromDb)

      mapRef.current.addSource('routes', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })

      mapRef.current.addLayer({
        id: 'routeline-active',
        type: 'line',
        source: 'routes',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#3887be',
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            12, 3,
            22, 12,
          ],
        },
      })

      setShowLoading(false)
    })

    mapRef.current.on('click', 'counties-layer', (e) => {
      handleClickRef.current(e)
    })

    mapRef.current.on('click', 'incident-markers', (e) => {
      incidentsPopupRef.current(e)
    })

    mapRef.current.on('click', 'incident-markers-fusion', (e) => {
      incidentsPopupRef.current(e)
    })

    mapRef.current.on('click', 'mb-layer', (e) => {
      restrictionBoxPopupRef.current(e)
    })
  }

  const cancelMBEdit = () => {
    mapRef.current.getSource('restriction-boxes').setData({
      type: 'FeatureCollection',
      features: movementBoxes,
    })
    setFocusedMb(null)
  }

  const deleteMb = (id) => {
    setShowLoading(true)
    apiHook.archiveMovementBox({ movement_box_id: id, domain_id: workspace.id }).then(() => {
      /* Remove movement box from map and from array */
      const boxes = movementBoxes.filter((box) => box.properties.id !== id).slice()
      mapRef.current.getSource('restriction-boxes').setData({
        type: 'FeatureCollection',
        features: boxes,
      })
      setShowLoading(false)
      setRestrictionBoxes(boxes)
      setFocusedMb(null)
    })
  }

  const updateMovementBox = (id, feature) => {
    const copy = movementBoxes.slice()
    const index = movementBoxes.findIndex((box) => box.properties.id === id)

    copy[index] = feature
    setRestrictionBoxes(copy)
    mapRef.current.getSource('restriction-boxes').setData({
      type: 'FeatureCollection',
      features: copy,
    })
  }
  return (
    <IonRow className='ion-justify-content-center' style={{ flex: '1 1 auto' }}>
      <Map
        mapRef={mapRef}
        zoom={5}
        listeners={setUpListeners}
        className={`flexible-map ${(editing && view === 'map' && mode === 'Incidents') ? 'overflow-visible' : 'not-applied'}`}
      >
        {
          view === 'list' && mode === 'Incidents' && (
            <IncidentList
              incidents={incidentList}
              setIncidents={setIncidents}
              editing={editing}
              incidentFilters={incidentFilters}
              filterIncidents={(filters, newIncidents) => filterIncidents(filters, incidentTimeFilter, newIncidents)}
              timeFilter={incidentTimeFilter}
              mapRef={mapRef}
              setShowLoading={setShowLoading}
            />
          )
        }
        {
          view === 'list' && mode === 'Alert States' && (
            <AlertList
              countyList={countiesArr}
              dispatchSelected={dispatchSelected}
              selected={selected}
              suggestedReports={suggestedReports}
              setSelectedCounty={setSelectedCounty}
            />
          )
        }
        {
          showRouteForm
          && (
            <Route
              mapRef={mapRef}
              showJourneyForm={false}
              setShowRouteForm={setShowRouteForm}
              routes={routes}
              setRoutes={setRoutes}
              onSubmit={showRoutes}
              onClose={finish}
              domainId={workspace.id}
            />
          )
        }
        {
          showMbForm && (
            <RestrictionBox
              mapRef={mapRef}
              setShowMbForm={setShowMbForm}
              movementBoxes={movementBoxes}
              setMovementBoxes={setRestrictionBoxes}
              onClose={finish}
              domainId={workspace.id}
              showMbs={showMbs}
              setLoading={setShowLoading}
            />
          )
        }
        {
          showIncidentForm && (
            <Incident
              mapRef={mapRef}
              setShowIncidentF={setShowIncidentForm}
              incidentList={incidentList}
              setIncidentList={setIncidents}
              updateIncidents={(filters, newIncident) => updateIncidents(filters, incidentTimeFilter, newIncident, null, true)}
              incidentFilters={incidentFilters}
              onClose={finish}
              setView={showIncidents}
              markers={markers}
              setMarkers={setMarkers}
            />
          )
        }
        {
          focusedMb && (
          <EditMovementBox
            focusedMb={focusedMb}
            mapRef={mapRef}
            onCancel={cancelMBEdit}
            setLoading={setShowLoading}
            updateMovementBox={updateMovementBox}
            deleteMb={deleteMb}
            setFocusedMb={setFocusedMb}
          />
          )
        }
        {
          editing && view === 'map' && mode === 'Incidents' && (
            <EditIncident
              focusedIncident={focusedIncident}
              disregardIncident={disregardIncident}
              finishIncidentedit={finishIncidentEdit}
              deleteIncident={handleDeleteIncident}
              onClose={closeIncidentEdit}
            />
          )
        }
      </Map>
    </IonRow>
  )
}

export default ConsultantMap
