import { IonButton } from '@ionic/react'
import React from 'react'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SimpleButton } from '../maps/StyledContainers'
import { OverlayContainer } from '../tasks/StyledContainers'

/**
 *  ===============================
 *     MOBILE POPUP WHEN SUBMIT
 *  ===============================
 */
const SubmittedPopup = ({ message, onView, dismiss }) => (
  <OverlayContainer style={{ textAlign: 'center', width: '230px' }}>
    <FontAwesomeIcon
      style={{
        fontSize: '60px',
        marginBottom: '40px',
        color: '#326771',
      }}
      icon={faCheckCircle}
    />
    <h4 style={{ color: '#326771', fontSize: '1rem' }}> { message } </h4>
    <IonButton style={{ color: 'white', '--background': '#326771', padding: '0 15px' }} onClick={onView}>View My Journeys</IonButton>
    <SimpleButton onClick={dismiss}>Dismiss</SimpleButton>
  </OverlayContainer>
)

export default SubmittedPopup
