import { IonButton, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { OverlayContainer } from '../../../GlobalContainers'

interface Props {
  onClose: () => void,
  onSubmit: () => void,
}

const DeleteRoom : FC<Props> = ({ onClose, onSubmit }) => (
  <OverlayContainer style={{ zIndex: 20 }}>
    <h4>Delete Chat</h4>
    <p>Are you sure you wish to delete this chat?</p>
    <p>All users in this group will lose access to messages in this chat.</p>
    <IonRow className='ion-justify-content-end'>
      <IonButton onClick={onClose} style={{ '--background': '#8E151F', color: 'white' }}>Cancel</IonButton>
      <IonButton onClick={onSubmit} style={{ '--background': '#326771', color: 'white' }}>Confirm</IonButton>
    </IonRow>
  </OverlayContainer>
)

export default DeleteRoom
