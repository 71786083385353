import { IonRow } from '@ionic/react'
import * as React from 'react'
import { BlueButton } from '../../incident-management/StyledContainers'
import { getCurrentLevel, getDescription } from '../../maps/apps/AppMap'
import { ASRegion, County } from '../../types/OptimizedMaps'

const CountyPopup2 : React.FC<{ data: (County | ASRegion), onClick: () => void }> = ({ data, onClick }) => (
  <div>
    <div className='disp-flex-start asset-popup-heading'>
      <p>{data.name} {('region_id' in data) ? 'County' : 'Region'}</p>
    </div>
    <p className='asset-popup-property'>Alert State {('region_id' in data) ? data.alert_state : data.avg_as}:
      <span className='alert-state-display county-popup-text' style={{ backgroundColor: getCurrentLevel(('region_id' in data) ? data.alert_state : data.avg_as).background }}>
        {getCurrentLevel(('region_id' in data) ? data.alert_state : data.avg_as).title}
      </span>
    </p>
    <p className='asset-popup-property'>{ getDescription(('region_id' in data) ? data.alert_state : data.avg_as) }</p>
    {
      ('region_id' in data) && (
        <IonRow className='ion-justify-content-end'>
          <BlueButton onClick={() => onClick()} style={{ color: '#349DFF' }}>Generate Situation Report</BlueButton>
        </IonRow>
      )
    }
  </div>
)

export default CountyPopup2
