import React, {
  useEffect, useState,
} from 'react'
import { useHistory } from 'react-router'

import {
  IonPage, IonContent, IonTitle,
  IonToolbar, IonSpinner,
} from '@ionic/react'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts'
import { ObscureBackground, CenterLoader } from '../components/GlobalContainers'
import PageHeader from '../components/PageHeader'
import testFunctionality from '../hooks/testHooks'

const AnalyticsDash = () => {
  // history
  const history = useHistory()

  // access test hooks
  const test = testFunctionality()

  const [loading, setLoading] = useState(true)
  const [activeData, setActiveData] = useState()
  const [inactiveData, setInactiveData] = useState()
  const [lostData, setLostData] = useState()

  const processLunar = (ld, data) => {
    let tmp = 0
    const d = []

    for (let i = 0; i < ld.length; i += 1) {
      tmp = tmp + ld[i].months[0].hiker + ld[i].months[0].lurker + ld[i].months[0].sherpa
    }

    d.push({
      name: 'month',
      users: (tmp / ld.length),
    })

    setActiveData(data)
  }

  const processInactive = (ld) => {
    const d = []

    d.push({
      name: 'Inactive Users',
      users: ld,
    })

    setInactiveData(d)
  }

  const processLost = (ld, data) => {
    const d = []

    d.push({
      name: 'month',
      users: ld,
    })

    setLostData(data)
  }

  useEffect(async () => {
    if (JSON.parse(localStorage.getItem('current-workspace')).power !== 'God') return history.push('/dashboard')

    const ld = await test.getLunar()
    const inac = await test.getInactive()
    const lost = await test.getLost()

    processLunar(ld.users, ld.list)
    processInactive(inac.result)
    processLost(lost.result, lost.data)

    // finish loading
    setLoading(false)
    return null
  }, [])

  return (
    (loading) ? (
      <>
        <ObscureBackground style={{ zIndex: '9' }} />
        <CenterLoader style={{ zIndex: '10' }}>
          <IonSpinner color='light' />
        </CenterLoader>
      </>
    ) : (
      <IonPage>
        <IonToolbar>
          <IonTitle>Analytics Dashboard</IonTitle>
        </IonToolbar>
        <PageHeader title='Analytics' />
        <ion-datetime />
        <IonContent>
          <IonTitle>Daily Active Users (Monthly Average)</IonTitle>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={300}
              data={activeData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='month' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey='Users' fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
          <IonTitle>Inactive Users</IonTitle>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={300}
              data={inactiveData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey='users' fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
          <IonTitle>Lost Users</IonTitle>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              width={500}
              height={300}
              data={lostData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='month' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey='Users' fill='#8884d8' />
            </BarChart>
          </ResponsiveContainer>
        </IonContent>
      </IonPage>
    )
  )
}

export default AnalyticsDash
