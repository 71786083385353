import * as React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'

import { IonPage, IonGrid } from '@ionic/react'
import { ProvideReportsData } from '../hooks/reports/useReportsData'
import MobileHeader from '../components/mobile/MobileHeader'

import AppWrapper from '../components/reports/AppWrapper'

const ReportsApp = () => (
  <ProvideReportsData>
    <IonPage>
      <MobileHeader className='mobile-app-header-insights' />
      <IonGrid style={{
        height: '100%',
      }}
      >
        <AppWrapper />
      </IonGrid>
    </IonPage>
  </ProvideReportsData>
)

export default ReportsApp
