import React, { FC } from 'react'
import {
  IonButton,
  IonCheckbox, IonCol, IonRow, IonSearchbar,
} from '@ionic/react'

import { useInsightsData } from '../../../hooks/insights/useInsightsData'
import { OverlayContainer } from '../../GlobalContainers'
import { getColor } from '../../maps/apps/AppMap'
import { ScrollableList, SimpleButton } from '../../maps/StyledContainers'
import { WidgetTitles } from '../../maps/WidgetContainers'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { SafetyUser } from '../../types/OptimizedMaps'
import axios from '../../../utils/axios'

const FSitRepRequest : FC = () => {
  const { setFieldSitrepRequest, workspaceUsers, setSubmittedMessage } = useInsightsData()
  const [checkboxes, setCheckboxes] = React.useState<{ [id: number] : boolean }>(Object.fromEntries(workspaceUsers.map((user) => [user.id, false])))
  const { workspace } = useWorkspace()

  const [selectAll, setSelectAll] = React.useState<boolean>(false)
  const [searchText, setSearchText] = React.useState<string>('')

  const handleSelectAll = (selected: boolean) => {
    const updated = Object.fromEntries(Object.keys(checkboxes).map((val) => [val, selected]))
    setCheckboxes(updated)
    setSelectAll(selected)
  }

  const handleSingle = (selected: boolean, id: number) => {
    const updated = { ...checkboxes, [id]: selected }
    if (Object.values(updated).every((checked) => checked)) {
      setSelectAll(true)
    }
    if (!selected) { setSelectAll(false) }
    setCheckboxes(updated)
  }

  const getUsers = () : SafetyUser[] => {
    const filteredSearch = workspaceUsers.filter((val) => `${val.first_name} ${val.final_name}`.toLowerCase().includes(searchText.toLowerCase()))
    return filteredSearch
  }

  const handleSubmit = () => {
    // TODO: Send ids to server -> Trigger safety check popups
    const ids = Object.keys(checkboxes).filter((id) => checkboxes[id])
    axios.post('/api/v1/insight/request_field_sitreps', { user_ids: ids, domain_id: workspace.id })
    setFieldSitrepRequest(false)
    setSubmittedMessage('Team Safety Update Requested')
  }

  return (
    <OverlayContainer style={{ zIndex: 11, width: '700px' }}>
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <h3>Field SitRep Request</h3>
        <SimpleButton onClick={() => setFieldSitrepRequest(false)}>Close X</SimpleButton>
      </IonRow>
      <p>Select the team members you wish to request a field SitRep from. </p>
      <IonRow>
        <IonSearchbar onIonChange={(e) => setSearchText(e.detail.value)} className='safety-checks-search' />
      </IonRow>
      <div style={{ border: '.5px solid #747474', borderRadius: '2.5px', margin: '10px 0' }}>
        <WidgetTitles style={{ padding: '0', textAlign: 'left' }}>
          <IonRow className='ion-align-item-center'>
            <IonCol size='1'>
              <IonCheckbox checked={selectAll} onClick={(e) => handleSelectAll(e.currentTarget.checked)} />
            </IonCol>
            <IonCol size='11'>
              User
            </IonCol>
          </IonRow>
        </WidgetTitles>
        <ScrollableList style={{ minHeight: '100px' }}>
          {
            getUsers().map((user, index) => (
              <IonRow key={user.id} style={{ backgroundColor: getColor(index) }} className='ion-align-items-center'>
                <IonCol size='1'>
                  <IonCheckbox checked={checkboxes[user.id]} onClick={(e) => handleSingle(e.currentTarget.checked, user.id)} />
                </IonCol>
                <IonCol size='11'>
                  { `${user.first_name} ${user.final_name}` }
                </IonCol>
              </IonRow>
            ))
          }
        </ScrollableList>
      </div>
      <IonRow className='ion-justify-content-end'>
        <IonButton onClick={() => setFieldSitrepRequest(false)} id='cancel-safety-checks' style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        <IonButton onClick={handleSubmit} id='request-safety-checks' style={{ '--background': '#0C9500' }}>Request Field SitRep</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default FSitRepRequest
