import React, { useState } from 'react'
import {
  IonCol, IonRow, IonList, IonItem, IonInput,
  IonLabel, IonToggle, IonButton, useIonLoading, useIonAlert,
} from '@ionic/react'
import Select from 'react-select'
import { SettingField, SettingTitle } from '../StyledContainers'
import { countries } from '../CountryList.json'

import useApi from '../../../hooks/testHooks'

/**
 *  =======================
 *       Edit Workspace
 *  =======================
 */

const workspaceDetails = ({
  domainDetails, setNewWorkspace, setWorkspace, updateDomain,
}) => {
  const [editing, setEditing] = useState(false)
  const [domainFields, setDomainFields] = useState({
    name: domainDetails.name,
    country: countries.find((location) => location.value === domainDetails.country),
  })

  const [present, dismiss] = useIonLoading()
  const [ionAlert] = useIonAlert()
  const apiHook = useApi()

  const onSubmit = async () => {
    /* Check that every field is filled */
    if (domainFields.title === '') {
      ionAlert({
        header: 'Error',
        message: 'You need to specify a name for this workspace',
        buttons: [
          'cancel',
          { text: 'ok' },
        ],
      })

      return
    }

    if (domainFields.country === null) {
      ionAlert({
        header: 'Error',
        message: 'You need to specify a country for this workspace',
        buttons: [
          'cancel',
          { text: 'ok' },
        ],
      })

      return
    }

    present()

    const country = domainFields.country.value
    const updatedWorkspace = { ...domainFields, country }

    const { message } = await apiHook.editWorkspace({ domain_id: domainDetails.id, ...updatedWorkspace })
    ionAlert({
      header: 'Message',
      message,
      buttons: [
        'cancel',
        { text: 'Ok' },
      ],
    })
    dismiss()
    setEditing(false)

    if (message !== 'workspace has been edited') {
      setDomainFields({
        name: domainDetails.name,
        country: countries.find((location) => location.value === domainDetails.country),
      })
      return
    }

    updateDomain(domainDetails.id, updatedWorkspace)
    setWorkspace({ ...domainDetails, ...updatedWorkspace })
  }

  const onCancel = () => {
    setDomainFields({
      name: domainDetails.name,
      country: countries.find((location) => location.value === domainDetails.country),
    })
    setEditing(false)
  }

  const handleEdit = (e) => {
    setDomainFields({ ...domainFields, [e.target.name]: e.detail.value })
  }

  return (
    <>
      <SettingTitle>Workspace Details</SettingTitle>
      <IonRow style={{ height: '90%' }}>
        <IonCol>
          <IonList style={{ '--ion-item-background': 'transparent' }}>
            <IonItem lines={(editing) ? 'inset' : 'none'}>
              <SettingField>
                <h4>Workspace Title</h4>
                {
                (editing)
                  ? (
                    <IonInput
                      type='text'
                      value={domainFields.name}
                      placeholder={domainDetails.name}
                      name='name'
                      onIonChange={handleEdit}
                    />
                  )
                  : <p> {domainDetails.name} </p>
              }
              </SettingField>
            </IonItem>
            <IonItem lines={(editing) ? 'inset' : 'none'}>
              <SettingField>
                <h4>Location</h4>
                {
                (editing)
                  ? (
                    <Select
                      value={domainFields.country}
                      onChange={(selected) => setDomainFields({ ...domainFields, country: selected })}
                      // eslint-disable-next-line no-undef
                      menuPortalTarget={document.body}
                      className='settings-select'
                      options={countries}
                    />
                  )
                  : <p> { (domainFields.country) ? domainFields.country.label : 'No country selected' } </p>
                }
              </SettingField>
            </IonItem>
          </IonList>
        </IonCol>
        <IonCol style={{ position: 'relative', height: '100%' }}>
          <IonItem style={{ '--ion-item-background': 'transparent' }} lines='none'>
            <SettingField>
              <h4>Apps</h4>
              <p>Choose which apps are active in this workspace</p>
              <IonItem lines='none' className='ion-justify-content-between'>
                <IonLabel>Insights</IonLabel>
                <IonToggle disabled={!editing} checked />
              </IonItem>
              <IonItem lines='none' className='ion-justify-content-between'>
                <IonLabel>Journeys</IonLabel>
                <IonToggle disabled={!editing} checked />
              </IonItem>
              <IonItem lines='none' className='ion-justify-content-between'>
                <IonLabel>Tasks</IonLabel>
                <IonToggle disabled={!editing} checked />
              </IonItem>
            </SettingField>
          </IonItem>
          <IonRow style={{ position: 'absolute', bottom: 0, width: '95%' }} className='ion-justify-content-end'>
            {
            (editing)
              ? (
                <>
                  <IonButton onClick={onCancel} style={{ '--background': '#8E151F' }}>cancel</IonButton>
                  <IonButton onClick={onSubmit} style={{ '--background': '#326771' }}>Save Changes</IonButton>
                </>
              )
              : (
                <>
                  <IonButton onClick={() => setEditing(true)} style={{ '--background': '#326771' }}>Edit</IonButton>
                  <IonButton onClick={() => setNewWorkspace(true)} style={{ '--background': '#0C9500' }}>New Workspace</IonButton>
                </>
              )
          }
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  )
}

export default workspaceDetails
