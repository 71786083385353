import React, { FC, useEffect, useState } from 'react'
import { useFSitRepPopup } from '../../hooks/safety-checks/useFSitRepPopup'
import InsightsForm from '../maps/forms/InsightsForm'
import LoadingModal from '../modals/LoadingModal'
import { CountryAreas } from '../types/OptimizedMaps'
import { getFlatRegionsAndCounties, processAreas } from '../utils/MapboxUtils'
import { PopupBackground, PopupContainer } from './StyledContainers'
import axios from '../../utils/axios'
import { useNotification } from '../../hooks/useNotification'

const FSitRepFormPopup: FC = () => {
  const [areas, setAreas] = useState<CountryAreas[]>([])
  const [,hide, isShowing, notification] = useFSitRepPopup()
  const [loading, setLoading] = useState<boolean>(true)
  const [showForm, setShowForm] = useState<boolean>(false)
  const { markNotificationAsRead } = useNotification()

  useEffect(() => {
    if (!isShowing) { return }
    axios.post('/api/v2/county/counties', { domain_id: notification.taskDomain }).then(({ data }) => {
      setAreas(processAreas(data.counties))
      setLoading(false)
      setShowForm(true)
    })
    markNotificationAsRead(notification.id, true)
  }, [isShowing])

  return isShowing ? (
    <>
      <PopupBackground />
      <PopupContainer
        style={{
          minHeight: '300px', height: '70%', width: '60%', '--ion-background-color': 'white',
        }}
        className='safety-check-popup'
      >
        <LoadingModal isOpen={loading} />
        {
          showForm && (
          <InsightsForm
            setLoading={setLoading}
            areas={getFlatRegionsAndCounties(areas)}
            workspaceId={notification.taskDomain}
            onFinish={() => {
              hide()
            }}
            onClose={hide}
          />
          )
        }
      </PopupContainer>
    </>
  ) : null
}

export default FSitRepFormPopup
