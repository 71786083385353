import React, { useState } from 'react'
import {
  IonRow, IonGrid, IonCol, IonItem, IonDatetime, IonLabel,
  IonCheckbox, IonInput, IonButton,
} from '@ionic/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit, faTimes, faClipboardList, faClock, faExclamationCircle, faTrashAlt, faArchive,
} from '@fortawesome/free-solid-svg-icons'
import { OverlayContainer } from '../StyledContainers'
import { SherpaButton } from '../../GlobalContainers'

import { useAuth } from '../../../hooks/useAuth'

const ExpandTask = ({
  task, onClose, changeStatus, updateTask, archiveTask, deleteTask, showArchived,
}) => {
  const [edit, setEdit] = useState(false)
  const { user } = useAuth()
  const [input, setInput] = useState({
    ...task,
  })

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.detail.value })
  }

  const handleSubmit = () => {
    // TODO: Send a request to the server that updates the task
    if (!showArchived) updateTask(input)
    onClose()
  }

  const handleStatusChange = (e) => {
    const { id } = task
    const codedStatus = e.target.value

    changeStatus(id, codedStatus)
    setInput({ ...input, status: e.target.value })
  }

  const handleArchive = () => {
    archiveTask({ ...input, status: input.status })
    onClose()
  }

  const handleDelete = () => {
    deleteTask({ ...input, status: input.status })
    onClose()
  }

  return (
    <OverlayContainer>
      <IonGrid>
        <IonRow style={{ marginBottom: '30px' }} className='ion-justify-content-around ion-align-items-start'>
          <IonCol size='5' style={{ fontSize: '1.3rem', color: '#326771' }}>
            <IonRow className='ion-align-items-start'>
              <FontAwesomeIcon style={{ width: '10%' }} icon={faClipboardList} />
              <div style={{ width: '90%' }} className='task-expand-subtitles'>
                <h3 id='expand-task-title'>{ task.title }</h3>
                <p id='expand-task-column-name'>  { (showArchived) ? 'Complete' : input.status } column </p>
              </div>
            </IonRow>
          </IonCol>
          <IonCol
            size='7'
            style={{
              textAlign: 'end', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
            }}
          >
            {
              !showArchived && (
                <>
                  {
                    ((task.assignee !== 'Unknown' && user.email === task.assignee.email) || (task.assigner !== 'Unknown' && user.email === task.assigner)) ? (
                      <select id='expand-task-status-dropdown' onChange={handleStatusChange} defaultValue={task.status}>
                        <option value='To-Do'>To Do</option>
                        <option value='In-Progress'>In Progress</option>
                        <option value='Complete'>Complete</option>
                      </select>
                    ) : (
                      <p style={{ margin: '0' }}> { task.status } </p>
                    )
                  }
                </>
              )
            }
            {
              edit && (
                <>
                  {
                    !showArchived && (
                      <SherpaButton id='expand-task-delete-button' onClick={handleDelete}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </SherpaButton>
                    )
                  }
                  {
                    task.status === 'Complete' && (
                    <SherpaButton onClick={handleArchive}>
                      <FontAwesomeIcon icon={faArchive} />
                    </SherpaButton>
                    )
                  }
                </>
              )
            }
            {
              !edit && !showArchived && ((task.assignee !== 'Unknown' && user.email === task.assignee.email) || (task.assigner !== 'Unknown' && user.email === task.assigner)) && (
                <SherpaButton id='expand-task-edit-button' onClick={() => setEdit(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </SherpaButton>
              )
            }
            <SherpaButton id='expand-task-close-button' onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </SherpaButton>
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-start edit-task-padding'>
          <FontAwesomeIcon icon={faClock} />
          <div className='task-expand-subtitles'>
            <h3> Due </h3>
            {
              edit && (
                <>
                  <IonItem>
                    <IonDatetime
                      name='due'
                      value={input.due}
                      onIonChange={handleChange}
                      display-format='YYYY-MM-DDTHH:mm'
                      picker-format='DD:MM:YYYYTHH:mm'
                      placeholder='YYYY-MM-DD HH:mm'
                      minuteValues='0,15,30,45'
                    />
                  </IonItem>
                </>
              )
            }
            {
              !edit && (
                <p>  { `${task.due.split('T')[0]}, ${task.due.split('T')[1].split(':')[0]}:${task.due.split('T')[1].split(':')[1]}` }</p>
              )
            }
          </div>
        </IonRow>
        <IonRow className='ion-align-items-start edit-task-padding'>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <div className='task-expand-subtitles'>
            <h3> Priority </h3>
            {
              edit && (
                <IonItem>
                  <IonLabel>High Priority</IonLabel>
                  <IonCheckbox
                    name='priority'
                    color='primary'
                    checked={input.priority}
                    onIonChange={(e) => setInput({ ...input, [e.target.name]: e.target.checked })}
                  />
                </IonItem>
              )
            }
            {
              !edit && (
                <p> { (task.priority) ? 'High Priority' : 'none' } </p>
              )
            }
          </div>
        </IonRow>
        <IonRow className='ion-align-items-start edit-task-padding'>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <div className='task-expand-subtitles' style={{ width: '90%' }}>
            <h3> Description </h3>
            {
              edit && (
                <IonItem>
                  <IonInput id='expand-task-input-description' name='description' value={input.description} type='text' onIonChange={handleChange} />
                </IonItem>
              )
            }
            {
              !edit && (
                <p>  { task.description } </p>
              )
            }
          </div>
        </IonRow>
      </IonGrid>
      {
        edit && (
          <IonButton
            style={{
              '--ion-color-primary': '#0C9500',
              marginLeft: 'auto',
              display: 'block',
              maxWidth: '100px',
              marginTop: '10px',
            }}
            id='expand-task-submit-edit'
            onClick={handleSubmit}
          > Done
          </IonButton>
        )
      }
    </OverlayContainer>
  )
}

export default ExpandTask
