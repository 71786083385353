import { IonButton, IonCol, IonRow } from '@ionic/react'
import * as React from 'react'
import { Icon } from '@iconify/react'
import { CenterForm, ColumnContainer, MeasureContainer } from '../../riskregister/StyledContainers'
import { ControlMeasure } from '../../riskregister/types/RiskAssessments'

interface Props {
  onFinish: (measure: ControlMeasure[]) => void,
  allMeasures: ControlMeasure[],
  selectedMeasures: ControlMeasure[],
  onClose: () => void
}

const BoxMeasures : React.FC<Props> = ({
  allMeasures, onClose, onFinish, selectedMeasures,
}) => {
  const [selected, setSelected] = React.useState<ControlMeasure[]>([...selectedMeasures])
  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Add measure */
  const handleDropAdd = (e) => {
    /* Get measure name */
    const measure : ControlMeasure = JSON.parse(e.dataTransfer.getData('measure'))
    if (selected.find((val) => val.id === measure.id)) return
    const copy = selected.slice()
    copy.push(measure)

    setSelected(copy)
  }

  /* Remove measure */
  const handleDropRemove = (e) => {
    /* Get measure name */
    const measure : ControlMeasure = JSON.parse(e.dataTransfer.getData('measure'))
    const copy = selected.slice()
    const index = copy.findIndex(({ id }) => id === measure.id)

    copy.splice(index, 1)
    setSelected(copy)
  }

  return (
    <CenterForm style={{
      zIndex: '20', width: '90%', height: 'auto', padding: '20px',
    }}
    >
      <h4>Restrictions</h4>
      <p>Choose the restrictions you wish to add by dragging and dropping from the library on the left and adding them to your list. </p>
      <IonRow className='ion-align-items-center' style={{ marginBottom: '20px' }}>
        <IonCol size='5'>
          <ColumnContainer onDragOver={(e) => e.preventDefault()} onDrop={handleDropRemove}>
            {
              allMeasures.filter(({ id }) => !selected.find((val) => val.id === id)).map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val.id}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                  onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(val))}
                >
                  <p> {val.control_measure} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </IonCol>
        <IonCol style={{ textAlign: 'center', fontSize: '2.6rem' }} size='2'>
          <Icon icon='ic:round-swipe-right' />
        </IonCol>
        <IonCol size='5'>
          <ColumnContainer id='cm-existing-drop' onDragOver={(e) => e.preventDefault()} onDrop={handleDropAdd}>
            {
              selected.map((val, index) => (
                <MeasureContainer
                  className='single-control-measure'
                  key={val.id}
                  draggable
                  style={{ backgroundColor: getColor(index) }}
                  onDragStart={(e) => e.dataTransfer.setData('measure', JSON.stringify(val))}
                >
                  <p> {val.control_measure} </p>
                </MeasureContainer>
              ))
            }
          </ColumnContainer>
        </IonCol>
      </IonRow>
      <IonRow className='ion-justify-content-end'>
        <IonButton style={{ '--background': '#8E151F' }} onClick={onClose}>Cancel</IonButton>
        <IonButton style={{ '--background': '#0C9500' }} onClick={() => onFinish(selected)}>Next</IonButton>
      </IonRow>
    </CenterForm>
  )
}

export default BoxMeasures
