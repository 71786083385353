/* eslint-disable import/prefer-default-export */
/**
 *  =========================
 *      Filter options
 *  =========================
 */
const SSstates = [
  'Northern Bahr el Ghazal',
  'Western Bahr el Ghazal',
  'Lakes',
  'Warrap',
  'Western Equatoria',
  'Central Equatoria',
  'Eastern Equatoria',
  'Jonglei',
  'Unity',
  'Upper Nile',
  'Abyei Area',
  'Pibor Area',
  'Ruweng Area',
]

export {
  SSstates,
}
