/* eslint-disable react/require-default-props */
import { Icon } from '@iconify/react'
import * as React from 'react'
import { IconContainerElem } from '../components/messaging/StyledContainers'
import { User } from '../components/types/GlobalTypes'
import { Stakeholder } from '../components/types/OptimizedMaps'
import { getColor } from './GlobalFunctions'

const UserIcon : React.FC<User & { selected?: boolean, remove?: boolean }> = ({
  id, first_name, final_name, selected = false, remove = false,
}) => (
  <div className='app-icon-container' style={{ backgroundColor: getColor(id) }}>
    <p style={{ fontSize: '1.5rem', color: '#747474', margin: '0' }}> {`${first_name.charAt(0)}${final_name.charAt(0)}`} </p>
    {
      selected && (
        <IconContainerElem style={{ backgroundColor: '#14C105' }}>
          <Icon icon='done' style={{ color: 'white' }} />
        </IconContainerElem>
      )
    }
    {
      remove && (
        <IconContainerElem style={{ backgroundColor: '#C30101' }}>
          <Icon icon='close' style={{ color: 'white' }} />
        </IconContainerElem>
      )
    }
  </div>
)

export const StakeholderIcon : React.FC<Stakeholder & { selected?: boolean }> = ({ id, name, selected }) => (
  <div
    className='app-icon-container'
    style={{
      backgroundColor: getColor(id),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '60px',
      height: '60px',
    }}
  >
    <p style={{ fontSize: '1.5rem', color: '#747474', margin: '0' }}> {`${name.charAt(0)}`} </p>
    {
      selected && (
        <IconContainerElem style={{ backgroundColor: '#14C105' }}>
          <Icon icon='done' style={{ color: 'white' }} />
        </IconContainerElem>
      )
    }
  </div>
)

export default UserIcon
