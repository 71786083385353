import React, { useState } from 'react'
import {
  IonCol, IonItem, IonLabel,
} from '@ionic/react'
import { TabBarContainer, ViewMarker } from '../StyledContainers'
import ProfileSettings from './ProfileSettings'
import SecuritySettings from './SecuritySettings'
import WorkspaceVisibility from './WorkspaceVisibility'

/**
 *  ============================
 *       Profile Settings
 *  ============================
 */
const AccountSettings = ({ userDetails, setLoading }) => {
  const [view, setView] = useState('profile')

  return (
    <>
      <IonCol className='ion-no-padding' size={3}>
        <TabBarContainer>
          <IonItem
            button
            className='ion-no-padding'
            style={{ '--background': (view === 'profile') ? 'white' : '#F2F2F2', color: '#8E151F' }}
            onClick={() => setView('profile')}
          >
            {
            view === 'profile' && (
              <ViewMarker />
            )
          }
            <IonLabel className='ion-text-center'>Account Details</IonLabel>
          </IonItem>
          <IonItem
            button
            className='ion-no-padding'
            style={{ '--background': (view === 'security') ? 'white' : '#F2F2F2', color: '#8E151F' }}
            onClick={() => setView('security')}
          >
            {
            view === 'security' && (
              <ViewMarker />
            )
          }
            <IonLabel className='ion-text-center'>Security & Privacy</IonLabel>
          </IonItem>
        </TabBarContainer>
      </IonCol>
      <IonCol size={9}>
        {
          view === 'profile' && (
            <ProfileSettings userDetails={userDetails} setLoading={setLoading} />
          )
        }
        {
          view === 'security' && (
            <SecuritySettings />
          )
        }
        {
          view === 'workspaces' && (
            <WorkspaceVisibility />
          )
        }
      </IonCol>
    </>
  )
}

export default AccountSettings
