import { IonCol, IonRow } from '@ionic/react'
import React, { FC, useState } from 'react'
import moment from 'moment'
import { useIncidentsData } from '../../../hooks/incident-management/useIncidentData'
import DefaultScreen from '../../messaging/DefaultScreen'
import { BreakoutRoom, User } from '../../types/GlobalTypes'
import { ObscureBackground } from '../StyledContainers'
import Conversation from './chats/Conversation'
import TabBarChats from './chats/TabBar'
import AddRoom from './popups/AddRoom'
import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { useAuth } from '../../../hooks/useAuth'
import EditRoom from './popups/EditRoom'
import RemoveUser from '../../messaging/popups/RemoveUser'
import DeleteRoom from './popups/DeleteRoom'
import axios from '../../../utils/axios'
import LeaveRoom from './popups/LeaveRoom'

const BreakoutRooms: FC = () => {
  const {
    focusedRoom, domainUsers, displayView, pushNewRoom, setFocusedRoom,
    setLoading, updateRoom, leaveRoom,
  } = useIncidentsData()
  const [showAddChat, setShowAddChat] = useState<boolean>(false)
  const [userToDelete, setUserToDelete] = React.useState<User | null>(null)
  const [showDeleteChat, setShowDeleteChat] = useState<boolean>(false)
  const [showLeaveGroup, setShowLeaveGroup] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const apiHook = useApi()
  const { workspace } = useWorkspace()
  const { user } = useAuth()

  const handleUpdateRoom = (participants: (User & { new?: boolean })[], title: string, usersToDelete: User[]) => {
    setLoading(true)
    const newMembers = participants.filter((val) => val.new)
    apiHook.editBreakout({
      domain_id: workspace.id,
      add: newMembers.map(({ id }) => id),
      remove: usersToDelete.map(({ id }) => id),
      room_id: focusedRoom.id,
      name: title,
    })
      .then(() => {
        const readUpdates = focusedRoom.recipientUpdates.filter(({ user_id }) => participants.find(({ id }) => user_id === id))
        const allUpdates = [...readUpdates, ...newMembers.map(({ id }) => ({ user_id: id, read: false, updated_at: moment().format() }))]
        const updatedRoom : BreakoutRoom = {
          ...focusedRoom,
          recipients: participants.map((val) => ({ ...val, new: undefined })),
          recipientUpdates: allUpdates,
          name: title,
        }

        updateRoom(updatedRoom)
        setFocusedRoom(updatedRoom)
      }).finally(() => {
        setLoading(false)
        setShowEdit(false)
        setUserToDelete(null)
      })
  }

  const handleDeleteOneUser = () => {
    const participants = focusedRoom.recipients.filter(({ id }) => id !== userToDelete.id)
    handleUpdateRoom(participants, focusedRoom.name, [userToDelete])
  }

  const handleLeaveGroup = () => {
    setLoading(true)
    apiHook.leaveBreakout({ room_id: focusedRoom.id })
      .then(() => {
        leaveRoom(focusedRoom)
      }).catch(() => {
        reportError('An unexpected error has ocurred.')
      }).finally(() => {
        setShowLeaveGroup(false)
        setLoading(false)
      })
  }

  const handleDeleteRoom = () => {
    axios.put('/archive_chat_room', { room_id: focusedRoom.id })
      .then(({ data }) => {
        if (!data.ok) {
          reportError(data.message)
          setLoading(false)
          return
        }
        leaveRoom(focusedRoom)
      })
      .catch(() => {
        reportError('An unexpected error has ocurred.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateRoom = (participants: User[], title: string) => {
    setLoading(true)

    apiHook.createBreakout({
      domain_id: workspace.id,
      name: title,
      recipients: [...participants.map(({ id }) => id), user.user_id],
      incident_id: displayView.focusedIncident.id,
    }).then((data) => {
      const newRoom : BreakoutRoom = {
        id: data.chatroom.id,
        recipients: participants,
        name: title,
        read: true,
        latestMessage: null,
        created_at: moment().format(),
        isGroupChat: true,
        recipientUpdates: participants.filter(({ id }) => user?.user_id !== id).map(({ id }) => ({ user_id: id, read: false, updated_at: moment().format() })),
        host_id: user.user_id as number,
      }
      pushNewRoom(newRoom)
      setFocusedRoom(newRoom)
    }).finally(() => {
      setLoading(false)
      setShowAddChat(false)
    })
  }

  return (
    <IonRow class='ion-justify-content-start' style={{ height: '100%', backgroundColor: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
      {
        showAddChat && (
          <>
            <ObscureBackground />
            <AddRoom users={domainUsers.filter(({ id }) => id !== user.user_id)} onClose={() => setShowAddChat(false)} onSubmit={handleCreateRoom} />
          </>
        )
      }
      {
        showEdit && (
          <>
            <ObscureBackground />
            <EditRoom
              users={domainUsers.filter(({ id }) => id !== user.user_id)}
              onClose={() => setShowEdit(false)}
              onSubmit={handleUpdateRoom}
              name={focusedRoom.name}
              members={[...focusedRoom.recipients]}
            />
          </>
        )
      }
      {
        showLeaveGroup && (
          <>
            <ObscureBackground />
            <LeaveRoom
              onClose={() => setShowLeaveGroup(false)}
              onSubmit={handleLeaveGroup}
            />
          </>
        )
      }
      {
        showDeleteChat && (
          <>
            <ObscureBackground />
            <DeleteRoom
              onClose={() => setShowDeleteChat(false)}
              onSubmit={handleDeleteRoom}
            />
          </>
        )
      }
      {
        userToDelete && (
          <>
            <ObscureBackground />
            <RemoveUser
              onConfirm={handleDeleteOneUser}
              onCancel={() => setUserToDelete(null)}
            />
          </>
        )
      }
      <IonCol
        class='ion-justify-content-start'
        style={{
          height: '100%', width: '10em', padding: '0', margin: '0',
        }}
        size='3'
      >
        <TabBarChats onAddUser={() => setShowAddChat(true)} />
      </IonCol>
      <IonCol
        class='ion-justify-content-end'
        style={{
          height: '100%', padding: '0', margin: '0', display: 'flex', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        }}
        size='9'
      >
        {
          focusedRoom ? (
            <Conversation
              room={focusedRoom}
              onDeleteUser={(userVal) => setUserToDelete(userVal)}
              setShowAddUsers={setShowEdit}
              setShowEditName={setShowEdit}
              setShowDeleteChat={setShowDeleteChat}
              setShowLeaveGroup={setShowLeaveGroup}
            />
          ) : (
            <DefaultScreen onOpen={() => setShowAddChat(true)} />
          )
        }
      </IonCol>
    </IonRow>
  )
}

export default BreakoutRooms
