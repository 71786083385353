import { Icon } from '@iconify/react'
import {
  IonContent, IonPage, IonCol, IonRow, IonItem, IonGrid, IonLabel, IonButton, IonCard, IonCardHeader, IonCardContent,
} from '@ionic/react'
import React from 'react'
import PageHeader from '../../PageHeader'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 */
const JourneysHelp = () => (
  <IonPage>
    <PageHeader title='Help Center' />
    <IonRow style={{ width: '100%' }}>
      <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
        <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

          <IonRow class='ion-justify-content-center'>
            <IonLabel style={{
              color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', paddingTop: '30px',
            }}
            >
              Journeys Help
            </IonLabel>
          </IonRow>

          <IonRow class='ion-justify-content-center' style={{ width: '100%' }}>
            <IonLabel style={{
              width: '100%', fontSize: '16px', textAlign: 'center', padding: '30px',
            }}
            >
              In this section you will find articles relating to the Tasks App
            </IonLabel>
          </IonRow>

          <IonRow justify-content-center class='ion-text-center' style={{ width: '100%' }}>

            <IonItem class='ion-text-center' style={{ '--background': '#CFE5E9', width: '100%', align: 'center' }} lines='none'>
              <IonLabel class='ion-text-center' style={{ width: '100%' }}>
                <input style={{ width: '60%', align: 'center' }} />
                <IonButton fill='clear'>
                  <Icon icon='icon-park-outline:message-search' color='#326771' size='25' />
                </IonButton>
              </IonLabel>

            </IonItem>
          </IonRow>

        </IonCol>
      </IonRow>
    </IonRow>
    <IonGrid style={{
      width: '100%', height: '100%', marginBottom: '10px', boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)',
    }}
    >
      <IonContent style={{ height: '100%', '--background': 'white' }}>
        <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Using the Map - Journeys
              </IonCardHeader>
              <IonItem class='ion-text-center' lines='none'>
                <IonCardContent>This article outlines how to use the map controls and make your work in the Insights App more efficient.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>

          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Adding a Route
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article outlines how to add a new route to the map.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow style={{ paddingTop: '10px' }}>
          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Filters & Views
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article outlines how to change views within the map and use the filter system.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>

          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >What is a Journey Ticket?
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article explains what a Journey Ticket is and how you can use it to manage a journey.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow style={{ paddingTop: '20px' }}>
          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Adding a Journey Request
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article outlines how to create a journey request.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>

          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Authorising a Journey(Managers)
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article outlines how to view and authorise a Journey Request. </IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Creating a Restriction Box
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article outlines how to create and add a Restriction Box to an area of the map.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>

          <IonCol style={{ width: '50%' }} />
        </IonRow>
      </IonContent>

    </IonGrid>
  </IonPage>
)

export default JourneysHelp
