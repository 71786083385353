import { IonCol, IonRow } from '@ionic/react'
import React, { FC } from 'react'
import { FocusedActionKind } from '../../../hooks/terrain-mapping/helpers/StateReducers'
import { useTerrainMapping } from '../../../hooks/terrain-mapping/useTerrainMapping'
import { ListContainer } from '../../consultant/StyledContainers'
import { BlueButton } from '../../incident-management/StyledContainers'
import CommitmentScreen from '../popovers/CommitmentScreen'
import { getColor } from './FlashpointList'

const CommitmentList : FC = () => {
  const { commitments, focusedElems, dispatchFocused } = useTerrainMapping()

  if (focusedElems.focusedCommitment) {
    return (
      <CommitmentScreen />
    )
  }

  return (
    <ListContainer style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden' }} className='risk-assessment-list'>
        <IonRow style={{ padding: '10px' }} className='terrain-list-title  ion-justify-content-between ion-align-items-center'>
          <IonCol size='2'>
            <h5>Title</h5>
          </IonCol>
          <IonCol size='4'>
            <h5>Description</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Groups</h5>
          </IonCol>
          <IonCol size='2'>
            <h5>Associated Areas</h5>
          </IonCol>
          <IonCol size='2' />
        </IonRow>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {
            commitments.map((val, index) => (
              <IonRow
                className='ion-justify-content-between terrain-register-row ion-align-items-center'
                style={{ backgroundColor: getColor(index) }}
                key={val.id}
              >
                <IonCol size='2'>{ val.title }</IonCol>
                <IonCol size='4'>{ val.description }</IonCol>
                <IonCol size='2'>{ (val.stakeholders.length === 0) ? 'No Groups selected' : val.stakeholders.map(({ name }) => name).join(', ') }</IonCol>
                <IonCol size='2'>{ (val.communities.length === 0) ? 'No Areas selected' : val.communities.map(({ name }) => name).join(', ') }</IonCol>
                <IonCol size='2'>
                  <BlueButton onClick={() => dispatchFocused({ type: FocusedActionKind.SELECT_COMMITMENT, commitment: val })}>View</BlueButton>
                </IonCol>
              </IonRow>
            ))
          }
          {
            commitments.length === 0 && (
              <p style={{ textAlign: 'center' }}>No commitments available</p>
            )
          }
        </div>
      </div>
    </ListContainer>
  )
}

export default CommitmentList
