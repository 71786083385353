import { Icon } from '@iconify/react'
import {
  IonButton, IonGrid, IonRow,
} from '@ionic/react'
import React, { FC, useEffect } from 'react'
import { AppView, TerrainMapElem } from '../../hooks/terrain-mapping/types/HookInterface'
import { useTerrainMapping } from '../../hooks/terrain-mapping/useTerrainMapping'
import { SelectView } from '../maps/StyledContainers'
import { useWorkspace } from '../../hooks/useWorkspace'

const MapControls : FC = () => {
  const {
    view, handleViewChange, setShowAddPopup, registerDisplay, setRegisterDisplay,
    focusedElems, editing, stakeholderFilters, setStakeholderFilters, setFilteredStakeholders,
    stakeholders, communities, setShowFlashpointForm, setShowStakeholderForm, setShowEngagementForm,
    setEditing, setSaving, setShowIssueForm, setShowCommitmentForm, setShowFilterPopup, setShowMapFilterPopup,
  } = useTerrainMapping()

  useEffect(() => {
    // Sort the stakeholders array by the 'name' property initially
    const sortedStakeholders = [...stakeholders].sort((a, b) => a.name.localeCompare(b.name))
    setFilteredStakeholders(sortedStakeholders)
    const filteredStakeholders = sortedStakeholders.filter((stakeholder) => {
      if (stakeholderFilters.types?.length > 0 && !stakeholderFilters.types?.some((filter) => filter.value === stakeholder.type)) {
        return false
      }
      if (stakeholderFilters.groups?.length > 0 && !stakeholderFilters.groups?.some((groupFilter) => 
        stakeholder.groups.some((group) => group.id === groupFilter.value || group.name === groupFilter.label),
      )) {
        return false
      }
      if (stakeholderFilters.comms?.length > 0 && !stakeholderFilters.comms?.some((commFilter) => 
        stakeholder.areas.some((area) => area.id === commFilter.value),
      )) {
        return false
      }
      if (stakeholderFilters.orgs?.length > 0 && !stakeholderFilters.orgs?.some((orgFilter) => 
        stakeholder.organisations.some((org) => org.id === orgFilter.value),
      )) {
        return false
      }
      if (stakeholderFilters.parents?.length > 0 && !stakeholderFilters.parents?.some((parentFilter) => 
        stakeholder.parents.some((parent) => parent.id === parentFilter.value),
      )) {
        return false
      }
      return true
    })
    setFilteredStakeholders(filteredStakeholders)
  }, [stakeholderFilters, stakeholders])

  const handleAddSelector = () => {
    console.log('add function:', registerDisplay)
    if (registerDisplay === TerrainMapElem.STAKEHOLDER) {
      setShowStakeholderForm(true)
      handleViewChange(AppView.TERRAIN_MAP)
    } else if (registerDisplay === TerrainMapElem.FLASHPOINT) {
      setShowFlashpointForm(true)
      handleViewChange(AppView.TERRAIN_MAP)
    } else if (registerDisplay === TerrainMapElem.ENGAGEMENT_NOTE) {
      setShowEngagementForm(true)
      handleViewChange(AppView.TERRAIN_MAP)
    } else if (registerDisplay === TerrainMapElem.ISSUE) {
      setShowIssueForm(true)
      handleViewChange(AppView.TERRAIN_MAP)
    } else if (registerDisplay === TerrainMapElem.COMMITMENT_FORM) {
      setShowCommitmentForm(true)
      handleViewChange(AppView.TERRAIN_MAP)
    }
  }

  return (
    <IonGrid
      style={{
        backgroundColor: '#FFFFFF',
        padding: '10px 30px',
        marginBottom: '25px',
        boxShadow: '0px 4px 4px 0px #00000040',
        position: 'relative',
        borderRadius: '5px',
      }}
      className='insight-controls-bar'
    >
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <IonRow className='ion-align-items-center'>
          <SelectView
            value={view}
            onChange={(e) => handleViewChange(e.target.value)}
            id='select-app-view'
          >
            <option value={AppView.TERRAIN_MAP}>{ AppView.TERRAIN_MAP }</option>
            <option value={AppView.REGISTERS}>{ AppView.REGISTERS }</option>
            <option value={AppView.NOTES}>{ AppView.NOTES }</option>
            <option value={AppView.ENGAGEMENT_PLAN}>{ AppView.ENGAGEMENT_PLAN }</option>
          </SelectView>
          {
            view === AppView.REGISTERS && (Object.keys(focusedElems).every((key) => !focusedElems[key])) && (
              <IonRow className='ion-align-items-center' style={{ margin: '0 25px' }}>
                <h5 style={{
                  fontSize: '1.1rem', fontWeight: 'bold', color: '#326771', margin: 0, marginRight: '5px',
                }}
                >Sort by:
                </h5>
                <select onChange={(e) => setRegisterDisplay(e.target.value as TerrainMapElem)} value={registerDisplay} className='terrain-register-dropdown'>
                  <option value={TerrainMapElem.FLASHPOINT}>{ TerrainMapElem.FLASHPOINT }</option>
                  <option value={TerrainMapElem.POINT_OF_INTEREST}>{ TerrainMapElem.POINT_OF_INTEREST }</option>
                  <option value={TerrainMapElem.STAKEHOLDER}>{ TerrainMapElem.STAKEHOLDER }</option>
                  <option value={TerrainMapElem.ENGAGEMENT_NOTE}>{ TerrainMapElem.ENGAGEMENT_NOTE }</option>
                  <option value={TerrainMapElem.ISSUE}>{ TerrainMapElem.ISSUE }</option>
                  <option value={TerrainMapElem.COMMITMENT_FORM}>{ TerrainMapElem.COMMITMENT_FORM }</option>
                </select>
              </IonRow>
            )
          }
        </IonRow>
        {
          view === AppView.REGISTERS && (Object.keys(focusedElems).some((key) => focusedElems[key])) && (
            <IonRow className='ion-align-items-center'>
              {
                editing && (
                  <IonButton onClick={() => setEditing(false)} style={{ '--background': '#8E151F' }}>Cancel</IonButton>
                )
              }
              <IonButton
                onClick={() => {
                  if (editing) {
                    setSaving(true)
                    return
                  }

                  setEditing(true)
                }}
                style={{ '--background': '#326771' }}
              >
                {
                  editing ? (
                    <>Save</>
                  ) : (
                    <>Edit</>
                  )
                }
              </IonButton>
            </IonRow>
          )
        }
        {
          view === AppView.TERRAIN_MAP && (
            <>
              <IonRow className='ion-align-items-center ion-justify-content-between'>
                <IonRow className='ion-align-items-center'>
                  {
                    view === AppView.TERRAIN_MAP && (
                      <>
                        <IonButton onClick={() => setShowMapFilterPopup(true)} style={{ '--background': '#326771' }}>Map Filters<Icon icon='fa:filter' /></IonButton>
                      </>
                    )
                  }
                  <>
                    <IonButton onClick={() => setShowAddPopup(true)} style={{ '--background': '#0C9500' }}>
                      Add <Icon style={{ marginLeft: '10px' }} icon='fa-plus' />
                    </IonButton>
                  </>
                </IonRow>
              </IonRow>
            </>
          )
        }
        {
          view === AppView.REGISTERS && (
            <IonRow className='ion-align-items-center ion-justify-content-end' style={{ marginLeft: 'auto' }}>
                {
                  view === AppView.REGISTERS && registerDisplay === TerrainMapElem.STAKEHOLDER && (
                    <>
                      <IonButton onClick={() => setShowFilterPopup(true)} style={{ '--background': '#326771' }}>Filter</IonButton>
                    </>
                  )
                }
              <IonButton onClick={handleAddSelector} style={{ '--background': '#0C9500' }}>
                Add {registerDisplay}
              </IonButton>
            </IonRow>
          )
        }
      </IonRow>
    </IonGrid>
  )
}

export default MapControls
