import { IonContent, IonGrid, IonPage } from '@ionic/react'
import React, { FC } from 'react'
import AppWrapper from '../components/engagement/AppWrapper'
import PageHeader from '../components/PageHeader'
import { ProvideEngagementData } from '../hooks/engagement/useEngagementData'

const EngagementApp : FC = () => (
  <ProvideEngagementData>
    <IonPage>
      <PageHeader title='Engagement App' />
      <IonContent>
        <IonGrid
          className='mobile-padding risk-assessment-list'
          style={{
            height: '100%',
          }}
        >
          <AppWrapper />
        </IonGrid>
      </IonContent>
    </IonPage>
  </ProvideEngagementData>
)

export default EngagementApp
