import React, { useState } from 'react'

import moment from 'moment'
import { IonLoading } from '@ionic/react'
import ProjectForm from './ProjectForm'
import ProjectDetails from './ProjectDetails'

/**
 *  ========================
 *        Content views
 *  ========================
 *
 *  Shows data and forms to create and edit a project
 */
const TabContent = ({
  selectedProject, creating, setCreating, editing, setEditing,
  users, addProject, updateProject, updateObjective, addObjectives,
  loadingMetrics, irisMetrics, isObjectiveView, setIsObjectiveView,
  focussedObjective, setFocussedObjective, editingObjective, setEditingObjective,
  createObjective, setCreateObjective, setView,
}) => {
  const [loading, setLoading] = useState(false)
  const formatSelected = () => {
    if (selectedProject.objectives) {
      const objectives = selectedProject.objectives.map((objective) => ({
        ...objective,
        startDate: moment(objective.startDate),
        endDate: moment(objective.endDate),
        owner: { value: objective.owner.id, label: objective.owner.email },
      }))
      return {
        ...selectedProject,
        objectives,
        metrics: (selectedProject.type === 'External' && !selectedProject.metrics) ? [] : selectedProject.metrics,
        owner: { label: selectedProject.owner.email, value: selectedProject.owner.id },
        startDate: moment(selectedProject.startDate),
        endDate: moment(selectedProject.endDate),
      }
    }

    return {
      ...selectedProject,
      owner: { label: selectedProject.owner.email, value: selectedProject.owner.id },
      startDate: moment(selectedProject.startDate),
      endDate: moment(selectedProject.endDate),
    }
  }
  return (
    <>
      <IonLoading
        isOpen={loading}
        message='Please wait...'
      />
      {
        (creating) && (
          <ProjectForm
            setLoading={setLoading}
            addProject={addProject}
            users={users}
            setCreating={setCreating}
            title='New Project'
            loadingMetrics={loadingMetrics}
            irisMetrics={irisMetrics}
            setView={setView}
            setCreateObjective={setCreateObjective}
          />
        )
      }
      {
        !creating && !editing && (
        <ProjectDetails
          selectedProject={selectedProject}
          users={users}
          updateObjective={updateObjective}
          updateProject={updateProject}
          addObjectives={addObjectives}
          setLoading={setLoading}
          isObjectiveView={isObjectiveView}
          setIsObjectiveView={setIsObjectiveView}
          focussedObjective={focussedObjective}
          setFocussedObjective={setFocussedObjective}
          editingObjective={editingObjective}
          setEditingObjective={setEditingObjective}
          createObjective={createObjective}
          setCreateObjective={setCreateObjective}
        />
        )
      }
      {
        !creating && editing && (
          <ProjectForm
            setLoading={setLoading}
            users={users}
            setCreating={setEditing}
            title='Edit Project'
            defaultInput={formatSelected()}
            edit
            addProject={updateProject}
            addObjectives={addObjectives}
            loadingMetrics={loadingMetrics}
            irisMetrics={irisMetrics}
            creating={creating}
          />
        )
      }
    </>
  )
}

export default TabContent
