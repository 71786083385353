import React from 'react'
import {
  IonList, IonLabel, IonButton, IonRow,
  IonCol, useIonAlert,
} from '@ionic/react'

import moment from 'moment'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts'
import { OverlayContainer } from '../GlobalContainers'

import { PopupBackground } from './StyledContainers'
import { SimpleButton } from '../maps/StyledContainers'
import { useSituationReport } from '../../hooks/insights/useSituationReport'

//

/**
 *  =======================
 *      1st Popup Slide
 *  =======================
 */
const SitRepPt7 = ({
  step, setStep, county, incidents,
}) => {
  const handleNext = () => {
    setStep(step + 1)
  }

  const handlePrev = () => {
    setStep(step - 1)
  }

  const [presentAlert] = useIonAlert()

  const handleDownload = () => {
    presentAlert({
      header: 'Download Unavailable',
      subHeader: 'Sorry! This feature is still in development.',
      buttons: ['Close'],
    })
  }

  const [, setIsShowing, , setCountyId] = useSituationReport()

  const handleClose = () => {
    setIsShowing(false)
    setCountyId(null)
    setStep(1)
  }

  const elevenmonthsago = moment(moment().subtract(11, 'month')).format('MMM')
  const tenmonthsago = moment(moment().subtract(10, 'month')).format('MMM')
  const ninemonthsago = moment(moment().subtract(9, 'month')).format('MMM')
  const eightmonthsago = moment(moment().subtract(8, 'month')).format('MMM')
  const sevenmonthsago = moment(moment().subtract(7, 'month')).format('MMM')
  const sixmonthsago = moment(moment().subtract(6, 'month')).format('MMM')
  const fivemonthsago = moment(moment().subtract(5, 'month')).format('MMM')
  const fourmonthsago = moment(moment().subtract(4, 'month')).format('MMM')
  const threemonthsago = moment(moment().subtract(3, 'month')).format('MMM')
  const twomonthsago = moment(moment().subtract(2, 'month')).format('MMM')
  const onemonthsago = moment(moment().subtract(1, 'month')).format('MMM')
  const now = moment().format('MMM')

  const data = [
    {
      name: elevenmonthsago,
      Incidents: incidents[0][1],
    },
    {
      name: tenmonthsago,
      Incidents: incidents[1][1],
    },
    {
      name: ninemonthsago,
      Incidents: incidents[2][1],
    },
    {
      name: eightmonthsago,
      Incidents: incidents[3][1],
    },
    {
      name: sevenmonthsago,
      Incidents: incidents[4][1],
    },
    {
      name: sixmonthsago,
      Incidents: incidents[5][1],
    },
    {
      name: fivemonthsago,
      Incidents: incidents[6][1],
    },
    {
      name: fourmonthsago,
      Incidents: incidents[7][1],
    },
    {
      name: threemonthsago,
      Incidents: incidents[8][1],
    },
    {
      name: twomonthsago,
      Incidents: incidents[9][1],
    },
    {
      name: onemonthsago,
      Incidents: incidents[10][1],
    },
    {
      name: now,
      Incidents: incidents[11][1],
    },
  ]

  return (
    <>
      <PopupBackground style={{ zIndex: 10 }} />
      <OverlayContainer style={{ height: '34.7em', width: '95%', zIndex: 11 }}>
        <IonRow>
          <IonCol>
            <IonLabel style={{ color: '#8E151F', fontWeight: 'bold', fontSize: '1.3em' }}>{`Situation Report - ${county.name} County `}</IonLabel>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <SimpleButton onClick={handleClose}>Close X</SimpleButton>
          </IonCol>
        </IonRow>

        <IonList>
          <IonRow style={{ paddingTop: '25px', paddingLeft: '10px' }}>
            <IonCol style={{ textAlign: 'left' }}><IonLabel style={{ fontWeight: 'bold', fontSize: '1.15em' }}>Monthly Serious Security Reports</IonLabel></IonCol>
          </IonRow>
        </IonList>

        <ResponsiveContainer width='95%' height='65%'>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='2 1' />
            <XAxis dataKey='name' />
            <YAxis tickCount='3' />
            <Tooltip />
            <Bar dataKey='Incidents' fill='#186c4c' />
          </BarChart>
        </ResponsiveContainer>

        <IonRow style={{}} class='ion-align-items-end'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#0C9500' }} onClick={handleDownload}>Download PDF</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9' }} onClick={handlePrev}>Back</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771' }} onClick={handleNext}>Next</IonButton>
          </IonCol>
        </IonRow>
      </OverlayContainer>
    </>
  )
}

export default SitRepPt7
