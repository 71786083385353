/* eslint-disable consistent-return */
import {
  IonContent, IonLabel, IonList,
  IonListHeader, IonItem,
  IonInput, IonButton, IonRow,
  IonLoading,
} from '@ionic/react'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'

import moment from 'moment'

import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

import { InstructionButtons, MapInstructions, SelectIncidentType } from '../StyledContainers'
import { DescriptionArea } from '../../incident-management/StyledContainers'

/**
 *  =============================
 *     FILL an incident report
 *  =============================
 */

const IncidentsForm = ({
  location, onCancel, input, setInput, loading, handleSubmit, incidentMarker,
}) => {
  /* Incident types will be retrieved from the server */
  const [incidentTypes, setIncidentTypes] = useState([])
  const [fillForm, setFillForm] = useState(false)

  const contentRef = useRef(null)

  /* Scroll to bottom (Date Picker) */
  const scrollToBottom = () => {
    // eslint-disable-next-line no-unused-expressions
    contentRef.current.scrollToBottom()
  }

  useEffect(() => {
    if (incidentTypes && incidentTypes.length) return

    /* Fecth incidents */
    axios.get('/api/v1/types')
      .then((response) => {
        setIncidentTypes(response.data.types.sort((a, b) => {
          if (a < b) return -1
          if (b < a) return 1
          return 0
        }))
        setInput({ ...input, incident_type: '' })
      })
  }, [])

  const handleChange = (e) => setInput({ ...input, [e.target.name]: e.target.value })
  const updatePin = (longitude, latitude) => {
    incidentMarker.setLngLat({ lat: latitude, lng: longitude })
  }

  return (
    <>
      <IonLoading
        isOpen={loading}
        message='Please wait...'
      />
      {
        (fillForm)
          ? (
            <>
              <IonContent
                ref={contentRef}
                scrollEvents
                style={{ height: '85%' }}
              >
                <IonList style={{ contain: 'initial' }}>
                  <IonListHeader>
                    <h3> Add Security Report </h3>
                  </IonListHeader>
                  <div style={{ padding: '0 10px' }}>
                    <p style={{ marginTop: '0' }}>Use the map to drag and drop a pin at the location of the security event</p>
                  </div>
                  <IonItem>
                    <IonLabel>Coordinates: </IonLabel>
                    <IonRow style={{ flex: 1 }}>
                      <IonInput
                        placeholder='Latitude'
                        value={input.latitude}
                        name='latitude'
                        type='number'
                        max='90'
                        min='-90'
                        onIonChange={(e) => {
                          handleChange(e)
                          updatePin(input.longitude, e.detail.value)
                        }}
                      />
                      <IonInput
                        placeholder='Longitude'
                        value={input.longitude}
                        type='number'
                        name='longitude'
                        max='180'
                        min='-180'
                        onIonChange={(e) => {
                          handleChange(e)
                          updatePin(e.detail.value, input.latitude)
                        }}
                      />
                    </IonRow>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Incident Category</IonLabel>
                    <SelectIncidentType id='select-incident-type' value={input.incident_type} onChange={(e) => setInput({ ...input, incident_type: e.target.value })}>
                      <option value='' disabled>Select Incident</option>
                      {
                        incidentTypes.map((incident) => (
                          <option value={incident} key={incident}>{incident}</option>
                        ))
                      }
                    </SelectIncidentType>
                  </IonItem>
                  <Datetime
                    dateFormat='YYYY-MM-DD'
                    timeFormat={false}
                    inputProps={{
                      placeholder: 'Date of incident',
                      readOnly: true,
                      style: { backgroundColor: 'white' },
                    }}
                    isValidDate={(current) => current.isBefore(moment())}
                    className='incident-form-date'
                    value={input.date}
                    onChange={(e) => setInput({ ...input, date: e })}
                    onOpen={() => scrollToBottom()}
                  />
                  <IonItem>
                    <IonLabel>Fatalities:</IonLabel>
                    <IonInput id='incident-fatalities' name='fatalities' value={input.fatalities} onIonChange={handleChange} type='number' min='0' max='1000' style={{ textAlign: 'right' }} required />
                  </IonItem>
                  <div style={{ margin: '10px' }}>
                    <h5 style={{ color: '#326771', fontSize: '.9rem' }}>Nearest village/town/city to event</h5>
                    <input
                      id='incident-nearest'
                      name='nearest'
                      className='incident-field-input'
                      value={input.nearest}
                      onChange={handleChange}
                      type='text'
                      placeholder='Type a location'
                    />
                  </div>
                  <div style={{ margin: '10px' }}>
                    <h5 style={{ color: '#326771', fontSize: '.9rem' }}>Description of event</h5>
                    <DescriptionArea
                      style={{
                        border: '1px solid #ced4da', borderRadius: '1.5px', width: '100%', resize: 'none',
                      }}
                      placeholder='Type a description'
                      name='description'
                      value={input.description}
                      onChange={handleChange}
                    />
                  </div>
                </IonList>
              </IonContent>
              <IonRow
                className='ion-justify-content-between'
                style={{
                  height: '15%', padding: '0 10px', alignItems: 'center', backgroundColor: 'white',
                }}
              >
                <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel(true)}>Cancel</IonButton>
                <IonButton id='submit-incident' style={{ '--background': '#4197A9' }} onClick={handleSubmit}>Submit</IonButton>
              </IonRow>
            </>
          )
          : (
            <IonContent>
              <MapInstructions>
                <h2 style={{ color: '#326771' }}>Add Incident</h2>
                <div style={{ marginTop: '20px', padding: '0 10px' }}>
                  <p>Click on the map to drop a pin where the incident took place.</p>
                  <p>You can drag and move the pin around to change the location of the incident.</p>
                </div>
                <InstructionButtons>
                  <IonRow className='ion-justify-content-between'>
                    <IonButton style={{ '--background': '#8E151F' }} onClick={() => onCancel(!!location[0])}>Cancel</IonButton>
                    <IonButton id='continue-incident-details' style={{ '--background': '#4197A9' }} onClick={() => setFillForm(true)} disabled={!location[0]}>Next</IonButton>
                  </IonRow>
                </InstructionButtons>
              </MapInstructions>
            </IonContent>
          )
      }

    </>
  )
}

export default IncidentsForm
