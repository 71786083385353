import { Icon } from '@iconify/react'
import {
  IonButton, IonCol, IonInput, IonRow, useIonAlert,
} from '@ionic/react'
import React, { FC, useState } from 'react'
import moment from 'moment'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { useAuth } from '../../../hooks/useAuth'
import { DMCard } from './ChatCard'
import LoadingModal from '../../modals/LoadingModal'
import { TabBarContainer } from '../../settings/StyledContainers'
import DefaultScreen from '../../messaging/DefaultScreen'
import { Stakeholder, StakeholderChat } from '../../types/OptimizedMaps'
import DMConversation from './DMConversation'
import { AppView } from '../../../hooks/engagement/types/HookInterface'
import { ObscureBackground } from '../../GlobalContainers'
import AddChat from '../popups/AddChat'

const DMScreen : FC = () => {
  const [showSMS, setShowSMS] = useState<boolean>(true)
  const {
    loading, handleViewChange, pushNewStakeholderDM, stakeholderDMs,
  } = useEngagementData()
  const [ionAlert] = useIonAlert()
  const [focusedRoom, setFocusedRoom] = useState<StakeholderChat | null>(null)
  const [showAddChat, setShowAddChat] = useState<boolean>(false)

  const handleCreateChat = (stakeholder: Stakeholder, isSMS: boolean) => {
    // TODO: Create new DM chat in backend
    const newDM : StakeholderChat = {
      id: 1,
      stakeholder: stakeholder,
      created_at: moment().format(),
      isSMS,
      recipient: stakeholder,
    }

    //if (stakeholderDMs.find((val) => val.recipient.id === stakeholder.id && val.isSMS === isSMS)) {
    //  ionAlert({
    //    header: 'Chat existing',
    //    message: 'The chat that you are trying to create already exists',
    //    buttons: [{ text: 'Ok' }],
    //  })
    //  return
    //}

    setShowAddChat(false)
    setFocusedRoom(newDM)
    pushNewStakeholderDM(newDM)
  }

  return (
    <IonRow class='ion-justify-content-start' style={{ height: '100%', backgroundColor: 'white', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)' }}>
      <LoadingModal isOpen={loading} />
      {
        showAddChat && (
          <>
            <ObscureBackground style={{ zIndex: '20' }} />
            <AddChat onSelect={handleCreateChat} onClose={() => setShowAddChat(false)} />
          </>
        )
      }
      <IonCol
        class='ion-justify-content-start'
        style={{
          height: '100%', width: '10em', padding: '0', margin: '0',
        }}
        size='3'
      >
        <TabBarChats
          onAddChat={() => setShowAddChat(true)}
          showSMS={showSMS}
          setFocusedRoom={setFocusedRoom}
          switchDMType={() => setShowSMS(!showSMS)}
          focusedRoom={focusedRoom}
          onBack={() => handleViewChange(AppView.HOME)}
        />
      </IonCol>
      <IonCol
        class='ion-justify-content-end'
        style={{
          height: '100%', padding: '0', margin: '0', display: 'flex', boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        }}
        size='9'
      >
        {
          focusedRoom ? (
            <DMConversation dmRoom={focusedRoom} />
          ) : (
            <DefaultScreen onOpen={() => setShowAddChat(true)} />
          )
        }
      </IonCol>
    </IonRow>
  )
}

interface TabBarProps {
  onAddChat: () => void,
  showSMS: boolean,
  switchDMType: () => void,
  setFocusedRoom: React.Dispatch<React.SetStateAction<StakeholderChat>>,
  focusedRoom: StakeholderChat,
  onBack: () => void,
}

const TabBarChats : React.FC<TabBarProps> = ({
  onAddChat, showSMS, switchDMType, setFocusedRoom, focusedRoom, onBack,
}) => {
  const [searchText, setSearchText] = React.useState<string>('')
  const { stakeholderDMs } = useEngagementData()
  const { user } = useAuth()

  return (
    <TabBarContainer style={{ maxHeight: '100%' }}>
      <div style={{ height: '100%', overflow: 'hidden', justifyContent: stakeholderDMs.length === 0 ? 'flex-start' : 'space-between' }} className='risk-assessment-list'>
        <div style={{ padding: '10px', margin: '10px 15px' }}>
          <IonRow className='ion-justify-content-between'>
            <IonButton onClick={onBack}>Back</IonButton>
            <IonButton onClick={switchDMType} style={{ '--background': '#326771' }}>
              {
              showSMS ? (
                <p style={{ margin: '0' }}>SMS <Icon icon='fa-solid:sms' /> </p>
              ) : (
                <p style={{ margin: '0' }}>SMS <Icon icon='fa-solid:sms' /> </p> //TODO: change to whatsaspp when ready icon='ic:baseline-whatsapp'
              )
            }
            </IonButton>
          </IonRow>
          <IonRow className='ion-justify-content-between ion-align-items-center'>
            <h3 className='chat-title'>Chat</h3>
            <IonButton
              fill='clear'
              class='ion-justify-content-end'
              onClick={() => {
                onAddChat()
              }}
            >
              <Icon type='button' icon='entypo:new-message' color='#326771' style={{ fontSize: '1.5em' }} />
            </IonButton>
          </IonRow>
          <IonInput
            type='text'
            placeholder='Search Messages'
            className='chats-input-field'
            name='name'
            value={searchText}
            onIonChange={(e) => setSearchText(e.detail.value)}
          />
        </div>
        {
        stakeholderDMs.length === 0 ? (
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3 style={{ textAlign: 'center' }}>No Current Conversations</h3>
            <p>Your conversations will appear here. </p>
          </div>
        ) : (
          <div style={{ flex: 1, overflowY: 'auto' }}>
            {
              stakeholderDMs.map((val) => (
                <DMCard
                  key={val.id}
                  onClick={() => setFocusedRoom({ ...val, recipient: val.stakeholder })}
                  selected={focusedRoom && focusedRoom.id === val.id}
                  stakeholder={val.stakeholder}
                  created_at={val.created_at}
                  latestMessage={val.message}
                  userId={user.user_id}
                />
              ))
            }
          </div>
        )
      }
      </div>
    </TabBarContainer>
  )
}

export default DMScreen
