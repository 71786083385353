import { Icon } from '@iconify/react'
import React, { FC } from 'react'
import { FileUploader } from 'react-drag-drop-files'

interface Props {
  files: FileList | null,
  setFiles: React.Dispatch<FileList>
}

const DragDropFiles: FC<Props> = ({ files, setFiles }) => (
  <FileUploader
    handleChange={(file) => {
      setFiles(file)
    }}
    name='file'
    className='file-upload-100%'
    types={['JPG', 'JPEG', 'PNG', 'GIF']}
    fileOrFiles={files}
    multiple
  >
    <div style={{
      height: '200px', width: '400px', border: '1px dashed #326771', cursor: 'pointer',
    }}
    >
      {
        files && files.length > 0 && (
          <div style={{
            display: 'flex', maxWidth: '100%', overflowX: 'auto', padding: '20px', alignItems: 'center', height: '100%',
          }}
          >
            {
              Array.from(files).map((val) => (
                <FileSquare key={val.name} name={val.name} />
              ))
            }
          </div>
        )
      }
      {
        (!files || files.length === 0) && (
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
          >
            <h5>Drag and Drop here</h5>
            <p>Or</p>
            <label className='file-submit-button' htmlFor='upload-images'>Browse Computer
              <input multiple style={{ display: 'none' }} name='upload-images' type='file' onChange={(e) => setFiles(e.target.files as any)} />
            </label>
          </div>
        )
      }
    </div>
  </FileUploader>
)

export default DragDropFiles

export const FileSquare : React.FC<{ name: string }> = ({ name }) => (
  <div className='file-container'>
    <Icon fontSize='4rem' icon='material-symbols:image-outline' />
    <p style={{ margin: '0', fontSize: '0.9rem' }}>{ name }</p>
  </div>
)
