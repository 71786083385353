import { Icon } from '@iconify/react'
import * as React from 'react'
import { getDateString } from '../../helpers/GlobalFunctions'
import { Message } from '../types/GlobalTypes'
import { StakeholderMessage } from '../types/OptimizedMaps'

const MessageCard : React.FC<Message & { sessionId: number, read: boolean }> = ({
  sessionId, user_id, content, created_at, read,
}) => (
  <div style={{
    maxWidth: '80%',
    alignSelf: (sessionId === user_id) ? 'flex-end' : 'flex-start',
    backgroundColor: (sessionId === user_id) ? '#B3D5DD' : '#dcddde',
    margin: '10px 0',
    padding: 15,
    borderRadius: '2.5px',
    width: 'fit-content',
  }}
  >
    <p> { content } </p>
    <p style={{ textAlign: 'right', margin: 0, fontSize: '.8rem' }}>
      { getDateString(created_at) }
      {
        sessionId === user_id && (
          <Icon icon={(read) ? 'charm:tick-double' : 'charm:tick'} />
        )
      }
    </p>
  </div>
)

export const DMMessage : React.FC<StakeholderMessage> = ({ content, user, created_at }) => (
  <div style={{
    maxWidth: '80%',
    alignSelf: (user) ? 'flex-end' : 'flex-start',
    backgroundColor: (user) ? '#B3D5DD' : '#dcddde',
    margin: '10px 0',
    padding: 15,
    borderRadius: '2.5px',
    width: 'fit-content',
  }}
  >
    <p> { content } </p>
    <p style={{ textAlign: 'right', margin: 0, fontSize: '.8rem' }}>
      { getDateString(created_at) }
    </p>
  </div>
)

export default MessageCard
