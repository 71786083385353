import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  IonPage, IonContent, IonGrid, IonRow, IonCol,
} from '@ionic/react'
import RecoveryForm from '../components/session/RecoveryForm'

const RecoverPassword = () => {
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ height: '100%' }}>
          <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
            <IonCol offsetMd='3' sizeMd='6' offsetLg='4' sizeLg='4'>
              <RecoveryForm returnTo={from} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default RecoverPassword
