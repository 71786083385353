import { Marker } from 'mapbox-gl'
import { Asset } from '../../../components/riskregister/types/Assets'
import { RiskAssessment } from '../../../components/riskregister/types/RiskAssessments'
import { GeoLocation } from '../../../components/types/GlobalTypes'

export enum FocusedActionKind {
  CHOOSE = 'CHOOSE',
  FORGET = 'FORGET',
}

export interface FocusedAssetAction {
  type: FocusedActionKind,
  chosenAsset?: Asset
}

export interface DisplayAsset {
  showProfile: boolean,
  showRiskMatrix: boolean,
  showRiskOverview: boolean,
  showRiskForm: boolean,
  showAssetForm: boolean,
  showAssetType: boolean,
}

export const focusedAssetReducer = (state: Asset | null, action : FocusedAssetAction) : Asset | null => {
  const { type, chosenAsset } = action

  switch (type) {
    case FocusedActionKind.CHOOSE:
      if (!chosenAsset) return state
      return chosenAsset
    case FocusedActionKind.FORGET:
      return null
    default:
      return null
  }
}

export interface FocusedRiskAction {
  type: FocusedActionKind,
  chosenRisk?: RiskAssessment
}

export const focusedRiskReducer = (state: RiskAssessment | null, action: FocusedRiskAction) : RiskAssessment | null => {
  switch (action.type) {
    case FocusedActionKind.CHOOSE:
      if (!action.chosenRisk) {
        return state
      }
      return action.chosenRisk
    case FocusedActionKind.FORGET:
      return null
    default:
      return null
  }
}

export enum DisplayAssetAction {
  OPEN_PROFILE = 'OPEN_PROFILE',
  OPEN_MATRIX = 'OPEN_MATRIX',
  CLOSE_ALL = 'CLOSE_ALL',
  OPEN_RISK_OVERVIEW = 'RISK_OVERVIEW',
  OPEN_RISK_FORM = 'RISK_FORM',
  OPEN_ASSET_FORM = 'ASSET_FORM',
  OPEN_ASSET_TYPE = 'ASSET_TYPE',
  CLOSE_ASSET_TYPE = 'CLOSE_ASSET_TYPE',
}

export const initialDisplayAsset : DisplayAsset = {
  showProfile: false,
  showRiskMatrix: false,
  showAssetForm: false,
  showRiskOverview: false,
  showRiskForm: false,
}

// TODO: Write reducer for displaying asset profile and its popups.
export const displayAssetReducer = (state: DisplayAsset, action: DisplayAssetAction) : DisplayAsset => {
  switch (action) {
    case DisplayAssetAction.OPEN_ASSET_TYPE:
      return { ...initialDisplayAsset, showAssetType: true }
    case DisplayAssetAction.CLOSE_ASSET_TYPE:
      return { ...initialDisplayAsset, showAssetType: false }
    case DisplayAssetAction.CLOSE_ALL:
      return { ...initialDisplayAsset }
    case DisplayAssetAction.OPEN_PROFILE:
      return { ...state, showProfile: true }
    case DisplayAssetAction.OPEN_RISK_OVERVIEW:
      return { ...state, showRiskOverview: true }
    case DisplayAssetAction.OPEN_RISK_FORM:
      return { ...state, showRiskForm: true }
    case DisplayAssetAction.OPEN_ASSET_FORM:
      return { ...state, showAssetForm: true, showAssetType: false }
    case DisplayAssetAction.OPEN_MATRIX:
      return { ...state, showProfile: false, showRiskMatrix: true }
    default:
      return { ...initialDisplayAsset }
  }
}

export enum FiltersActionKind {
  TOGGLE_ALL = 'TOGGLE ALL',
  TOGGLE_STATE = 'TOGGLE STATE',
}

export interface Filters {
  toggleAll: boolean,
  states: {
    [state: string]: boolean
  }
}

export interface FilterActions {
  type: FiltersActionKind,
  payload?: string
}
// #endregion
export const filtersReducer = (state: Filters, action: FilterActions) : Filters => {
  const { type, payload } = action
  switch (type) {
    case FiltersActionKind.TOGGLE_STATE: {
      if (!payload) {
        return { ...state }
      }
      const updated = { ...state.states, [payload]: !state.states[payload] }
      const toggleAll = (Object.values(updated).findIndex((param) => !param) < 0)
      return { states: { ...updated }, toggleAll }
    }

    case FiltersActionKind.TOGGLE_ALL: {
      const newFilters = Object.fromEntries(Object.keys(state.states).map((key) => [key, !state.toggleAll]))
      return { states: { ...newFilters }, toggleAll: !state.toggleAll }
    }
    default:
      return { ...state }
  }
}

export enum Views {
  MAP_VIEW = 'Map',
  REGISTER_VIEW = 'Register',
}

export enum ListModes {
  ASSETS = 'Assets',
  RISKS = 'Risks',
}

export interface ViewModes {
  view: Views,
  listMode: ListModes
}

export enum ViewsActionKind {
  MAP_VIEW,
  LIST_ASSETS,
  LIST_RISKS,
}

export interface ViewActions {
  type: ViewsActionKind,
}

export const viewsReducer = (state: ViewModes, action: ViewActions) : ViewModes => {
  switch (action.type) {
    case ViewsActionKind.MAP_VIEW:
      return { ...state, view: Views.MAP_VIEW, listMode: ListModes.ASSETS }

    case ViewsActionKind.LIST_ASSETS:
      return { ...state, view: Views.REGISTER_VIEW, listMode: ListModes.ASSETS }

    case ViewsActionKind.LIST_RISKS:
      return { ...state, view: Views.REGISTER_VIEW, listMode: ListModes.RISKS }

    default:
      return { ...state, view: Views.MAP_VIEW, listMode: ListModes.ASSETS }
  }
}

export interface EditAssetState {
  asset?: Asset,
  newLocation?: GeoLocation,
  showEditDetails: boolean,
  marker?: Marker
}

export enum EditAssetActionKind {
  FORGET_ASSET,
  SELECT_ASSET,
  CHANGE_LOCATION,
  SHOW_DETAILS_FORM,
  HIDE_DETAILS_FORM,
  COLOCATED_LOCATION,
  EDIT_DETAILS,
}

export interface EditAssetAction {
  type: EditAssetActionKind,
  payload?: {
    asset?: Asset,
    newLocation?: GeoLocation,
    marker?: Marker
  }
}

export const editAssetReducer = (state: EditAssetState, action: EditAssetAction) : EditAssetState => {
  const { type, payload } = action

  switch (type) {
    case (EditAssetActionKind.FORGET_ASSET):
      return { asset: undefined, newLocation: undefined, showEditDetails: false }

    case (EditAssetActionKind.SELECT_ASSET):
      if (!payload || !payload.asset) {
        return state
      }
      return {
        asset: payload.asset, newLocation: { ...payload.asset.coordinates }, showEditDetails: false, marker: payload.marker,
      }

    case (EditAssetActionKind.CHANGE_LOCATION):
      if (!payload || !payload.newLocation) {
        return state
      }
      return { ...state, newLocation: { ...payload.newLocation } }

    case (EditAssetActionKind.SHOW_DETAILS_FORM):
      return { ...state, showEditDetails: true }

    case (EditAssetActionKind.HIDE_DETAILS_FORM):
      return { ...state, showEditDetails: false }

    case (EditAssetActionKind.COLOCATED_LOCATION):
      if (!payload || !payload.newLocation) {
        return state
      }
      return { ...state, newLocation: { ...payload.newLocation }, showEditDetails: true }

    case (EditAssetActionKind.EDIT_DETAILS):
      if (!payload || !payload.asset) {
        return state
      }
      return { ...state, asset: { ...payload.asset } }
    default:
      return { ...state }
  }
}

export const initialEditAssetInput = {
  name: '',
  type: '',
  location: {
    country: null,
    state: null,
  },
  coordinates: {
    lat: null,
    lng: null,
  },
  county_id: null,
  showDetailsForm: false,
}
