import {
  IonCol, IonRow, IonInput, IonCheckbox, IonGrid,
} from '@ionic/react'
import React from 'react'
import Select from 'react-select'
import Datetime from 'react-datetime'
import moment from 'moment'

import { SettingField } from '../settings/StyledContainers'

/**
 *  ===============================
 *      Handle task form (Objs)
 *  ===============================
 */
const TaskInput = ({
  input, setInput, users, dates,
}) => {
  /* Handle change on input state */
  const handleChange = (key, value) => {
    setInput({ ...input, [key]: value })
  }

  return (
    <>
      <IonGrid style={{ margin: '5px 0px' }}>
        <SettingField>
          <IonRow className='ion-align-items-center'>
            <IonCol size={5}><h5>Title</h5></IonCol>
            <IonCol size={3}><h5>Assigned to</h5></IonCol>
            <IonCol size={4}><h5>Due</h5></IonCol>
          </IonRow>
        </SettingField>
        <SettingField>
          <IonRow style={{ width: '100%', padding: 0 }}>
            <IonCol size={5}>
              <IonInput
                value={input.title}
                name='title'
                placeholder='Add a title to the task'
                type='text'
                onIonChange={(e) => handleChange(e.target.name, e.detail.value)}
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.13)' }}
              />
            </IonCol>
            <IonCol size={3}>
              <Select
                placeholder='Select an assignee'
                name='assignee'
                className='select-container'
                value={input.assignee}
              // eslint-disable-next-line no-undef
                menuPortalTarget={document.body}
                options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                onChange={(selected) => handleChange('assignee', selected)}
              />
            </IonCol>
            <IonCol size={4}>
              <Datetime
                dateFormat='YYYY-MM-DD'
                inputProps={{
                  placeholder: 'Due date',
                  readOnly: true,
                  style: { backgroundColor: 'white' },
                }}
                isValidDate={(dates) ? (current) => (current.isSameOrAfter(dates.startDate) && current.isSameOrBefore(dates.endDate))
                  : (current) => current.isAfter(moment())}
                name='due'
                value={input.due}
                onChange={(e) => handleChange('due', e)}
              />
            </IonCol>
          </IonRow>
        </SettingField>
      </IonGrid>
      <IonRow className='ion-align-items-center' style={{ margin: '5px 0px', padding: '5px 10px' }}>
        <SettingField style={{ width: 'auto' }}><h5 style={{ margin: '0' }}>High Priority</h5></SettingField>
        <IonCheckbox checked={input.highPriority} />
      </IonRow>
      <div style={{ margin: '5px 0px', padding: '5px 10px' }}>
        <SettingField><h5>Description</h5></SettingField>
        <IonInput
          value={input.description}
          name='description'
          placeholder='Add a description to the task'
          type='text'
          onIonChange={(e) => handleChange(e.target.name, e.detail.value)}
          style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.13)' }}
        />
      </div>
    </>
  )
}

export default TaskInput
