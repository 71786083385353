import React from 'react'

import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, MeasureContainer, ColumnContainer } from '../../StyledContainers'

/**
 *  =========================
 *      Measure Summary
 *  =========================
 *  Show every measure without category filters
 */
const MeasureSummary = ({
  input, children, onClose, next,
}) => {
  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Control Measures - Summary</h5>
              <p>The following is a summary of existing and new control measures that you have selected. You can return to the previous sections and adjust measures accordingly if required.</p>
            </SettingField>
            <IonRow className='ion-align-items-center ion-jsutify-content-between'>
              <IonCol size={6}>
                <SettingField><h4>Existing Control Measures</h4></SettingField>
                <ColumnContainer style={{ cursor: 'inherit' }}>
                  {
                    input.existingMeasures.map((measure, index) => (
                      <MeasureContainer className='summary-existing-measure' key={measure.id} style={{ backgroundColor: getColor(index) }}>
                        <p> {measure.control_measure} </p>
                      </MeasureContainer>
                    ))
                  }
                </ColumnContainer>
              </IonCol>
              <IonCol size={6}>
                <SettingField><h4>New Control Measures</h4></SettingField>
                <ColumnContainer style={{ cursor: 'inherit' }}>
                  {
                    input.newMeasures.map((measure, index) => (
                      <MeasureContainer className='summary-residual-measure' key={measure.id} style={{ backgroundColor: getColor(index) }}>
                        <p> {measure.control_measure} </p>
                      </MeasureContainer>
                    ))
                  }
                </ColumnContainer>
              </IonCol>
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default MeasureSummary
