import {
  IonCol, IonItem, IonRow, IonButton,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
  faBell, faEllipsisH, faExclamationTriangle, faSitemap, faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router-dom'
import { useNotification } from '../hooks/useNotification'
import { useWorkspace } from '../hooks/useWorkspace'
import { useAuth } from '../hooks/useAuth'

import { useSafetyCheckPopup } from '../hooks/safety-checks/useSafetyCheckPopup'

import { SimpleButton } from './maps/StyledContainers'
import { useTrackerPopup } from '../hooks/safety-checks/useTrackerPopup'
import { useFSitRepPopup } from '../hooks/safety-checks/useFSitRepPopup'
import { PostCardContainer } from './dashboard/StyleContainers'
/**
 *  ============================
 *     Card for the news feed
 *  ============================
 */

const NotificationCard = ({
  notification, onDisplayPopup, dashboard, onGoToPost,
}) => {
  const [col, setCol] = useState('#e6e6e6')
  const [duration, setDuration] = useState()
  const [format, setFormat] = useState('')
  const [varUrl, setVarUrl] = useState('')
  const notifHook = useNotification()
  const workspace = useWorkspace()
  const [displayPopup] = useSafetyCheckPopup()
  const [displayConsent] = useTrackerPopup()
  const [displayFSitRep] = useFSitRepPopup()
  const [noti, setNoti] = useState(notification)
  const { user } = useAuth()
  const [likes, setLikes] = useState(notification.noti_reactions?.length ?? 0)

  console.log(likes)

  const history = useHistory()

  const start = moment()
  const end = moment(notification.created_at)
  const durationHours = Math.abs(end.diff(start, 'hours'))

  // mark as read + change col
  const markRead = (async () => {
    await notifHook.markNotificationAsRead(notification.id, true)
    setCol('#e6e6e6')
  })

  const icon = (type) => {
    if (type === '/insights') {
      return <FontAwesomeIcon icon={faExclamationTriangle} />
    } if (type === '/workspaces') {
      return <FontAwesomeIcon icon={faSitemap} />
    } if (type === '/journeys') {
      return <FontAwesomeIcon icon={faMapMarkerAlt} />
    }
    return <FontAwesomeIcon icon={faBell} />
  }

  // popover
  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body>
        <SimpleButton style={{ textDecoration: 'underline' }} onClick={() => markRead()}>Mark as read</SimpleButton>
      </Popover.Body>
    </Popover>
  )

  const handleReact = () => {
    const likeUpdate = hasLiked(noti.noti_reactions, user.user_id)
    console.log(likeUpdate)
    console.log(notification)
    console.log(noti)

    if (likeUpdate === false) {
      console.log('upping')
      notifHook.thumbsUp(notification.id)
      setLikes(likes + 1)

      const newReaction = {
        reaction_type: 'thumbs-up',
        user_id: user.user_id,
      }
      const updatedNotification = {
        ...notification,
        noti_reactions: [...notification.noti_reactions, newReaction],
      }
      // eslint-disable-next-line no-param-reassign
      notification = updatedNotification
      setNoti(updatedNotification)
      console.log(notification)
      console.log(noti)
    } else {
      console.log('downing')
      notifHook.thumbsDown(notification.id)
      setLikes(likes - 1)

      const updatedNotiReactions = notification.noti_reactions.filter(
        (reaction) => reaction.user_id !== user.user_id,
      )
      // eslint-disable-next-line no-param-reassign
      notification.noti_reactions = updatedNotiReactions
      setNoti((prevNoti) => ({
        ...prevNoti,
        noti_reactions: updatedNotiReactions,
      }))
      console.log(notification)
      console.log(noti)
    }
  }

  const handleGoToSource = () => {
    notifHook.markNotificationAsRead(notification.id, true)

    if (notification.title === 'Post Reply' && onGoToPost) {
      onGoToPost(notification)
      return
    }

    const domainCheck = workspace.domains.roledata.find((domain) => domain.id === notification.taskDomain)
    if (notification.note_type === '/tasks' && domainCheck) {
      workspace.setDomain(domainCheck)
    }

    if (notification.title === 'Safety') {
      displayPopup(notification.id)
      onDisplayPopup()
      return
    }

    if (notification.title === 'Tracking Request' && !notification.read) {
      displayConsent(notification.id, notification.taskId)
      return
    }

    if (notification.title === 'Field SitRep Request') {
      displayFSitRep(notification)
      onDisplayPopup()
      return
    }

    history.push(varUrl)
  }

  useEffect(() => {
    if (notification.note_type === '/tasks') {
      setVarUrl(`${notification.note_type}?task=${notification.taskId}`)
    } else if (notification.note_type === '/journeys') {
      setVarUrl(`${notification.note_type}?journey=${notification.taskId}`)
    } else if (notification.note_type === '/insights' && notification.title !== 'Field Sitrep Submitted'
    && notification.title !== 'Alert State Change' && notification.title !== 'Safety Check Submitted' && notification.title !== 'Security Condition') {
      setVarUrl(`${notification.note_type}?incident=${notification.taskId}`)
    } else if (notification.note_type === '/insights' && notification.title === 'Field Sitrep Submitted') {
      setVarUrl(`reports?insight=${notification.taskId}`)
    } else if (notification.title === 'Alert State Change') {
      setVarUrl(`${notification.note_type}?alert=${notification.taskId}`)
    } else if (notification.title === 'Safety Check Submitted') {
      setVarUrl(`${notification.note_type}?safety=${notification.taskId}`)
    } else if (notification.title === 'Security Condition') {
      setVarUrl(`${notification.note_type}?sc_county=${notification.taskId}`)
    } else {
      setVarUrl(notification.note_type)
    }

    if (!notification.read) {
      setCol('#97CFDA')
    } else {
      setCol('#e6e6e6')
    }

    if (durationHours < 1) {
      setDuration(Math.abs(end.diff(start, 'minutes')))
      setFormat('minutes')
    } else {
      setDuration(Math.abs(end.diff(start, 'hours')))
      setFormat('hours')
    }

    if (durationHours >= 24) {
      setDuration(Math.abs(end.diff(start, 'days')))
      setFormat('days')
    }

    if (durationHours >= 168) {
      setDuration(Math.abs(end.diff(start, 'weeks')))
      setFormat('weeks')
    }

    if (durationHours >= 720) {
      setDuration(Math.abs(end.diff(start, 'months')))
      setFormat('months')
    }
  }, [])

  useEffect(() => {
    if (!notification.read) {
      setCol('#97CFDA')
    } else {
      setCol('#e6e6e6')
    }
  }, [notification])

  if (!dashboard) {
    // non dashboard default notification
    return (
      <IonItem
        style={{
          margin: '10px 0',
          '--ion-background-color': col,
          '--border-radius': '3px',
          '--color': '#42505A',
        }}
        target='_blank'
        onClick={() => handleGoToSource()}
      >
        <IonRow style={{ width: '100%' }} className='ion-justify-content-between ion-align-items-center'>
          <IonCol size={2}>
            {icon(notification.note_type)}
          </IonCol>
          <IonCol>
            {notification.content}
            <IonRow>
              {duration} {format} ago
            </IonRow>
          </IonCol>
          <OverlayTrigger trigger='click' placement='bottom' overlay={popover}>
            <div className='ion-align-end'>
              <FontAwesomeIcon icon={faEllipsisH} />
            </div>
          </OverlayTrigger>
        </IonRow>
      </IonItem>
    )
  }

  const hasLiked = (noti_reactions, currentUserId) => {
    if (noti_reactions.length > 0) {
      return noti_reactions.some((reaction) => reaction.user_id === currentUserId)
    }
    return false
  }

  // const hasLiked = false
  // dashboard notif
  return (
    <>
      <PostCardContainer id={1}>
        <IonRow style={{ width: '100%' }} className='ion-justify-content-between ion-align-items-center'>
          <IonCol>
            <div className='post-content'>
              <IonRow className='ion-align-items-center' style={{ margin: '15px 0' }}>
                <IonItem lines='none' style={{ '--background': '#FFFFFF' }}>
                  {icon(notification.note_type)}
                </IonItem>
                <IonCol>
                  <h5>{notification.title}</h5>
                  <p className='post-time'>{ moment(notification.created_at).format('DD-MMM-YYYY') }</p>
                </IonCol>
              </IonRow>
              <p style={{ margin: '10px 0', color: 'black' }}> {notification.content}
              </p>
            </div>
          </IonCol>
        </IonRow>
        <IonRow style={{ padding: '5px' }} className='ion-justify-content-between'>
          <IonRow className='ion-align-items-center'>
            <IonButton
              style={{
                '--background': 'white',
                marginRight: '10px',
                color: hasLiked(noti.noti_reactions, user.user_id) ? '#4B99F4' : '#326771',
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
              }}
              onClick={() => handleReact()}
            >
              <Icon icon='mdi:thumbs-up' />
              <p style={{ margin: 0 }}>Like</p>
            </IonButton>
          </IonRow>
          <div className='post-counter'>
            <p style={{ margin: 0, marginRight: '5px' }}>{likes}</p>
            <Icon icon='mdi:thumbs-up' />
          </div>
        </IonRow>
      </PostCardContainer>
    </>
  )
}

export default NotificationCard
