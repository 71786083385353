import styled from 'styled-components'

const BackButton = styled.button`
  padding: 2px 5px;
  color: white;
  background-color: #326771;
  border-radius: 2.5px 2.5px 0px 0px;
  width: fit-content;
`

const PopoverContainer = styled.div`
  background-color: white;
  padding: 20px 40px;
  overflow: auto;
  flex: 1;
`

const PopoverField = styled.div`
  margin: 35px 20px;

  & h6 {
    color: #326771;
    font-weight: bold;
  }

  & p {
    font-size: 0.9rem;
    margin: 0;
  }
`

const TwoOptionButton = styled.div`
  border: 1px solid #326771;
  color: #326771;
  background-color: white;
  padding: 3px;
  border-radius: 2.5px;
  margin: 0 10px;
  cursor: pointer;

  &.selected {
    background-color: #326771;
    color: white;
  }
`

const IssueTag = styled.div`
  border-radius: 2.5px;
  -webkit-appearance: button;
  background-color: #326771;
  display: inline;
  width: fit-content;
  padding: 3px;
  color: white;
  margin: 5px;
`

export {
  BackButton,
  PopoverContainer,
  PopoverField,
  IssueTag,
  TwoOptionButton,
}
