/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import {
  IonButton, IonCol, IonContent, IonRow,
} from '@ionic/react'
import { operations, people, property } from '../../../riskregister/forms/FormOptions'
import { getImpactCategory } from '../../../riskregister/forms/utils/Categories'
import { IncidentInvestigation } from '../../../types/GlobalTypes'

interface Props {
  data: IncidentInvestigation,
  onEdit: () => void,
  archived: boolean,
}

const propertyImpact = { None: 'No Impact on Properties', ...property }
const peopleImpact = { None: 'No Impact on people.', ...people }
const operationsImpact = { None: 'No Impact on operations.', ...operations }

const InvestigationDetails : React.FC<Props> = ({ data, onEdit, archived }) => (
  <div className='risk-assessment-list' style={{ height: '100%' }}>
    <IonContent style={{ '--background': 'white' }}>
      <div className='incident-management-form-spacing'>
        <h4>Investigations</h4>
        <p>The investigations tab is where a review of the incident can take place prior to the closing of the incident</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h4>Investigator Name</h4>
        <p>{ data.name }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h4>Summary of Events</h4>
        <p>{ data.summary }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h4>Actual Consequences</h4>
      </div>
      <div className='incident-management-form-spacing'>
        <h5>People</h5>
        <p>{ peopleImpact[getImpactCategory(data.people)] }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h5>Operations</h5>
        <p>{ operationsImpact[getImpactCategory(data.operations)] }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h5>Property & Equipment</h5>
        <p>{ propertyImpact[getImpactCategory(data.property)] }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h4>People Involved</h4>
        {
          data.peopleInvolved?.map((val, index) => (
            <IonRow key={index}>
              <IonCol size='3'>
                <p>{ `${val.user.first_name} ${val.user.final_name}` }</p>
              </IonCol>
              <IonCol size='3'>
                <p>{ val.type }</p>
              </IonCol>
              <IonCol size='3'>
                <p>{ val.user.email }</p>
              </IonCol>
              <IonCol size='3'>
                <p>{ val.user.phone_number }</p>
              </IonCol>
            </IonRow>
          ))
        }
      </div>
      <div className='incident-management-form-spacing'>
        <h4>Key Findings</h4>
        <p>{ data.findings }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h4>Lessons Learned</h4>
        <p>{ data.lessons }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h4>Remedial Actions Required</h4>
        <p>{ data.remedials }</p>
      </div>
      <div className='incident-management-form-spacing'>
        <h4>Investigator Comments</h4>
        <p>{ data.comments }</p>
      </div>
    </IonContent>
    {
      !archived && (
        <IonRow className='ion-justify-content-end'>
          <IonButton onClick={onEdit} style={{ '--background': '#326771' }}>Edit</IonButton>
        </IonRow>
      )
    }
  </div>
)

export default InvestigationDetails
