import * as moment from 'moment'
import * as React from 'react'
import { getIncidentIcon } from '../../../hooks/insights/helpers/Utils'
import { Incident } from '../../types/OptimizedMaps'

const IncidentPopup : React.FC<{ data: Incident & { submitter: string, category: string } }> = ({ data }) => (
  <div>
    <div className='disp-flex-start asset-popup-heading'>
      <p>{data.incident_type}</p>
      <img alt='Incident category icon' src={getIncidentIcon(data.category)} />
    </div>
    <p className='asset-popup-property'><span>Incident Category: </span> {data.category}</p>
    <p className='asset-popup-property'><span>Date of Incident: </span> {moment(data.reported).format('DD/MM/YY')}</p>
    <p className='asset-popup-property'><span>Fatalities: </span> {data.fatalities}</p>
    <p className='asset-popup-property'><span>Coordinates: </span> {data.longitude.toFixed(4)}, {data.latitude.toFixed(4)}</p>
    <p className='asset-popup-property'><span>Description: </span> {data.description}</p>
  </div>
)

export default IncidentPopup
