/* eslint-disable no-bitwise */
import { ImpactRating, RiskRating } from '../../types/RiskAssessments'
import { getCategory, getNumericalValue } from './Categories'

type ControlMeasureMap = { [category: string] : string[] }

/**
 *  ==================================
 *      BUSINESS LOGIC CALCULATIONS
 *  ==================================
 *
 * Logic for calculating the risk assessment equations such as:
 *
 * - Impact Rating.
 * - Threat Rating.
 * - Risk Rating.
 */

/* Risk rating matrix */
export const RiskRatingMatrix = [
  [1, 1, 1, 1, 2],
  [1, 2, 2, 3, 3],
  [1, 2, 3, 4, 4],
  [2, 3, 4, 4, 5],
  [3, 4, 5, 5, 5],
]

export const computeThreatRating = (intent: RiskRating, capability: RiskRating) : RiskRating => getCategory(Math.ceil((getNumericalValue(intent) * getNumericalValue(capability)) / 5))

export const getImpactRating = (impactList : ImpactRating[]) : number => {
  /* Transform to their numerical values */
  const impactRatings = impactList.map((val) => getNumericalValue(val))
  return Math.max(...impactRatings)
}

/* Calculates (inherent or residual) risk rating for a given threat likelihood and the category with highest impact */
export const computeRiskRating = (threatLikelihood: number, impactList: ImpactRating[]) => {
  const impact = getImpactRating(impactList)
  return getCategory(RiskRatingMatrix[impact - 1][threatLikelihood - 1])
}

/* Formatting function for control measures */
export const formatControlMeasures = (dbMeasures : { category: string, control_measure: string }[]) : ControlMeasureMap => {
  const measures : ControlMeasureMap = {}

  dbMeasures.forEach((measure) => {
    if (!measures[measure.category]) measures[measure.category] = []

    measures[measure.category].push(measure.control_measure)
  })

  return measures
}

const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xFF
  return buf
}

export const downloader = (data, filename: string) => {
  const blob = new Blob([s2ab(atob(data))], {
    type: 'application/xlsx',
  })

  // if using send_data, create file to download with blob
  const link = document.createElement('a')
  link.download = filename
  link.href = URL.createObjectURL(blob)

  // document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
}
