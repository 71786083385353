/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import {
  IonRow, IonContent, IonCol, IonButton,
} from '@ionic/react'
import React, { useState } from 'react'
import moment from 'moment'
import { FrappeGantt } from 'frappe-gantt-react'
import { CircularProgressbar } from 'react-circular-progressbar'

import { ListContainer } from '../consultant/StyledContainers'
import { WidgetTitles } from '../maps/WidgetContainers'
import { BlueButton } from './StyledContainers'
import 'react-circular-progressbar/dist/styles.css'

/**
   *  =============================
   *      TabBar to change views
   *  =============================
   */
const ObjectiveHome = ({
  setCreating, internalProjects, externalProjects, completedProjects, setFocussed, setEditing,
  setView, selectedProject, setFocussedObjective, setIsObjectiveView, setCreateObjective,
}) => {
  const [showChart, setShowChart] = useState(false)

  const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  const popupObjective = (objective) => {
    setFocussedObjective(objective)
    setIsObjectiveView(true)
    setView('Details')
  }

  const handleCreate = () => {
    setCreateObjective(true)
    setIsObjectiveView(false)
    setFocussedObjective(null)
    setView('Details')
  }

  function getStatusColor(status) {
    if (status === 'Planning') {
      return '#326771'
    } if (status === 'Complete') {
      return '#0C9500'
    }
    return '#FF8A00'
  }

  const projects = [...internalProjects, ...externalProjects, ...completedProjects]

  return (
    <>
      <ListContainer style={{ paddingTop: '0px' }}>
        <IonRow>
          <IonCol style={{
            color: '#326771', fontWeight: 'bold', fontSize: '18px', paddingLeft: '40px', paddingTop: '20px',
          }}
          >Objectives
          </IonCol>
          <IonCol
            style={{ textAlign: 'right', paddingRight: '40px', paddingTop: '20px' }}
          >
            <IonButton style={{ '--background': '#326771', textTransform: 'none' }} onClick={() => handleCreate()}>Add Objective</IonButton>
          </IonCol>
        </IonRow>
        {
            !showChart && (
              <WidgetTitles style={{ height: '10%', paddingRight: '15', paddingLeft: '15px' }}>
                <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
                  <IonCol className='ion-text-center' size='3' style={{ fontWeight: 'bold' }}>Title</IonCol>
                  <IonCol className='ion-text-center' size='2' style={{ fontWeight: 'bold' }}>Owner</IonCol>
                  <IonCol className='ion-text-center' size='2' style={{ fontWeight: 'bold' }}>Target Date</IonCol>
                  <IonCol className='ion-text-center' size='2' style={{ fontWeight: 'bold' }}>No. Of Tasks Date</IonCol>
                  <IonCol className='ion-text-center' size='3' style={{ fontWeight: 'bold' }}>Status</IonCol>
                </IonRow>
              </WidgetTitles>
            )
          }
        <div style={{ height: showChart ? '80%' : '70%' }}>
          {
            !showChart && (
              <IonContent
                style={{
                  '--background': 'white', paddingRight: '0', paddingLeft: '0', paddingTop: '0',
                }}
              >
                {
                selectedProject.objectives.map((objective, index) => (
                  <IonRow
                    key={objective.id}
                    style={{
                      margin: '25px 10px', border: '1px solid black', borderRadius: '2.5px', padding: '10px',
                    }}
                    className='ion-align-items-center'
                  >
                    <IonCol className='ion-text-center' size='3'>
                      <BlueButton onClick={() => popupObjective(objective)}>
                        { objective.name }
                      </BlueButton>
                    </IonCol>
                    <IonCol className='ion-text-center' size='2'>
                      {`${objective.owner.first_name} ${objective.owner.final_name}`}
                    </IonCol>
                    <IonCol className='ion-text-center' size='2'>{moment(objective.endDate).format('DD/MM/YY')}</IonCol>
                    <IonCol className='ion-text-center' size='2'>
                      {objective.tasks.length}
                    </IonCol>
                    <IonCol className='ion-text-center' style={{ backgroundColor: getStatusColor(objective.status), color: 'white' }} size='2'>{objective.status}</IonCol>
                    <IonCol size='1'>
                      <div style={{ width: 50, height: 50 }}>
                        <CircularProgressbar
                          value={objective.status === 'Complete' ? 100 : objective.status === 'In-Progress' ? 50 : 0}
                          text={objective.status === 'Complete' ? '100%' : objective.status === 'In-Progress' ? '50%' : '0%'}
                          strokeWidth={15}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                ))
              }
              </IonContent>
            )
          }
        </div>
      </ListContainer>
    </>
  )
}

export default ObjectiveHome
