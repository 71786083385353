import {
  IonButton,
  IonCheckbox, IonCol, IonRow, IonSearchbar,
} from '@ionic/react'
import * as moment from 'moment'
import * as React from 'react'

import { useInsightsData } from '../../../hooks/insights/useInsightsData'
import { OverlayContainer } from '../../GlobalContainers'
import { getColor, getSafetyColor } from '../../maps/apps/AppMap'
import { ScrollableList, SimpleButton } from '../../maps/StyledContainers'
import { WidgetTitles } from '../../maps/WidgetContainers'
import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { SafetyUser } from '../../types/OptimizedMaps'

const TeamRequest : React.FC = () => {
  const { workspaceUsers, setShowSafetyChecks, setSubmittedMessage } = useInsightsData()
  const { workspace } = useWorkspace()
  const apiHook = useApi()
  /* Component states for search and checkboxes */
  const [checkboxes, setCheckboxes] = React.useState<{ [id: number] : boolean }>(Object.fromEntries(workspaceUsers.map((user) => [user.id, false])))
  const [selectAll, setSelectAll] = React.useState<boolean>(false)
  const [searchText, setSearchText] = React.useState<string>('')

  const handleSelectAll = (selected: boolean) => {
    const updated = Object.fromEntries(Object.keys(checkboxes).map((val) => [val, selected]))
    setCheckboxes(updated)
    setSelectAll(selected)
  }

  const handleSingle = (selected: boolean, id: number) => {
    const updated = { ...checkboxes, [id]: selected }
    if (Object.values(updated).every((checked) => checked)) {
      setSelectAll(true)
    }
    if (!selected) { setSelectAll(false) }
    setCheckboxes(updated)
  }

  const getUsers = () : SafetyUser[] => {
    const filteredSearch = workspaceUsers.filter((val) => `${val.first_name} ${val.final_name}`.toLowerCase().includes(searchText.toLowerCase()))
    return filteredSearch
  }

  const handleSubmit = () => {
    // TODO: Send ids to server -> Trigger safety check popups
    const ids = Object.keys(checkboxes).filter((id) => checkboxes[id])
    apiHook.prompUserSafeties(ids, workspace.id)
    setShowSafetyChecks(false)
    setSubmittedMessage('Team Safety Update Requested')
  }

  return (
    <OverlayContainer style={{ zIndex: 11, width: '700px', height: '600px' }}>
      <IonRow className='ion-align-items-center ion-justify-content-between'>
        <h3>Team Safety Request</h3>
        <SimpleButton onClick={() => setShowSafetyChecks(false)}>Close X</SimpleButton>
      </IonRow>
      <p>Select the team members you wish to request a safety update from. </p>
      <IonRow>
        <IonSearchbar onIonChange={(e) => setSearchText(e.detail.value)} className='safety-checks-search' />
      </IonRow>
      <div style={{ border: '.5px solid #747474', borderRadius: '2.5px', margin: '10px 0' }}>
        <WidgetTitles style={{ padding: '0', textAlign: 'left' }}>
          <IonRow className='ion-align-item-center'>
            <IonCol size='1'>
              <IonCheckbox checked={selectAll} onClick={(e) => handleSelectAll(e.currentTarget.checked)} />
            </IonCol>
            <IonCol size='3'>
              User
            </IonCol>
            <IonCol size='3'>
              Location
            </IonCol>
            <IonCol size='3'>
              Current Status
            </IonCol>
            <IonCol size='2'>
              Last Updated
            </IonCol>
          </IonRow>
        </WidgetTitles>
        <ScrollableList style={{ minHeight: '20.5em', maxHeight: '20.5em' }}>
          {
            getUsers().map((user: SafetyUser, index) => (
              <IonRow key={user.id} style={{ backgroundColor: getColor(index) }} className='ion-align-items-center'>
                <IonCol size='1'>
                  <IonCheckbox checked={checkboxes[user.id]} onClick={(e) => handleSingle(e.currentTarget.checked, user.id)} />
                </IonCol>
                <IonCol size='3'>
                  { `${user.first_name} ${user.final_name}` }
                </IonCol>
                <IonCol size='3'>
                  { user.safety.county.name }
                </IonCol>
                <IonCol size='3'>
                  <IonRow
                    className='ion-align-items-center ion-justify-content-start'
                  >
                    <p
                      className='alert-state-display'
                      style={{
                        backgroundColor: getSafetyColor(user.safety.level).background, color: 'white', fontSize: '.6rem', textAlign: 'left',
                      }}
                    > { getSafetyColor(user.safety.level).title }
                    </p>
                  </IonRow>
                </IonCol>
                <IonCol size='2'>
                  { (user.safety.created_at === 'N/A') ? 'N/A' : moment(user.safety.created_at).format('DD/MM/YY') }
                </IonCol>
              </IonRow>
            ))
          }
        </ScrollableList>
      </div>
      <IonRow className='ion-justify-content-end'>
        <IonButton onClick={() => setShowSafetyChecks(false)} id='cancel-safety-checks' style={{ '--background': '#8E151F' }}>Cancel</IonButton>
        <IonButton onClick={handleSubmit} id='request-safety-checks' style={{ '--background': '#0C9500' }}>Request Update</IonButton>
      </IonRow>
    </OverlayContainer>
  )
}

export default TeamRequest
