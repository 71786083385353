import React, { useEffect } from 'react'
import {
  IonContent, IonPage,
} from '@ionic/react'
import PageHeader from '../components/PageHeader'
import { SettingsContainer } from '../components/settings/StyledContainers'

import { ProvideChatsData } from '../hooks/chats/useChatsData'
import AppWrapper from '../components/messaging/AppWrapper'

/**
 *  ==============================
 *         Messaging App
 *  ==============================
 */

//  intent://sherpa#Intent;scheme=sherpaapp;package=com.sherpaandroid;end
const MessagingApp = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const redirectToApp = queryParams.get('sms')

    if (redirectToApp === 'redirect') {
      const isAndroid = /Android/i.test(window.navigator.userAgent)
      if (isAndroid) {
        const appIntent = 'intent://sherpa#Intent;scheme=sherpaapp;package=com.sherpaandroid;end'
        window.location = appIntent
      }
    }
  })

  return (
    <ProvideChatsData>
      <IonPage>
        <PageHeader title='Chat' />
        <IonContent style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
          <SettingsContainer>
            <AppWrapper />
          </SettingsContainer>
        </IonContent>
      </IonPage>
    </ProvideChatsData>
  )
}

export default MessagingApp
