import * as React from 'react'
import { ReportsInterface } from './HookInterface'
import { Incident, Insight, InternalIncident } from '../../components/types/GlobalTypes'

/**
 *  ================================================
 *        HANDLE STATES  FOR INSIGHTS APP
 *  ================================================
 *
 * Implements the business logic and react states for
 * the insights  app.
 */

export const reportsContext = React.createContext<ReportsInterface>(null)

export const useReportsData = () => React.useContext(reportsContext)

const useProvideReportsData = () : ReportsInterface => {
  /* popup toggles */
  const [showFieldSitrep, setShowFieldSitrep] = React.useState<boolean>(false)
  const [showSecurityReport, setShowSecurityReport] = React.useState<boolean>(false)
  const [incidents, setIncidents] = React.useState<Incident[]>([])
  const [showReports, setShowReports] = React.useState<boolean>(false)
  const [dates, setDates] = React.useState<[]>([])

  const [showWeeklys, setShowWeeklys] = React.useState<boolean>(false)
  const [showWeeklyReport, setShowWeeklyReport] = React.useState<boolean>(false)

  const [showSitreps, setShowSitreps] = React.useState<boolean>(false)
  const [showHome, setShowHome] = React.useState<boolean>(true)
  const [incident, setIncident] = React.useState<Incident>()
  const [name, setName] = React.useState<string>('name')
  const [county, setCounty] = React.useState<string>('county')
  const [state, setState] = React.useState<string>('state')

  const [fromUrl, setFromUrl] = React.useState<boolean>(true)

  const [insights, setInsights] = React.useState<Insight[]>([])
  const [insight, setInsight] = React.useState<Insight>()
  const [showInternalIncident, setShowInternalIncident] = React.useState<boolean>(false)
  const [showInternalIncidents, setShowInternalIncidents] = React.useState<boolean>(false)
  const [internalIncidents, setInternalIncidents] = React.useState<InternalIncident[]>([])
  const [internalIncident, setInternalIncident] = React.useState<InternalIncident>()
  const [step, setStep] = React.useState<Number>(1)

  const [data, setData] = React.useState<[]>([])

  return {
    showSecurityReport,
    setShowSecurityReport,
    showFieldSitrep,
    setShowFieldSitrep,
    incidents,
    setIncidents,
    showSitreps,
    setShowSitreps,
    showReports,
    setShowReports,
    showHome,
    setShowHome,
    incident,
    setIncident,
    name,
    setName,
    county,
    setCounty,
    state,
    setState,
    insights,
    setInsights,
    insight,
    setInsight,
    showWeeklyReport,
    setShowWeeklyReport,
    showWeeklys,
    setShowWeeklys,
    dates,
    setDates,
    fromUrl,
    setFromUrl,
    showInternalIncidents,
    setShowInternalIncidents,
    showInternalIncident,
    setShowInternalIncident,
    internalIncidents,
    setInternalIncidents,
    internalIncident,
    setInternalIncident,
    step,
    setStep,
    data,
    setData,
  }
}

export const ProvideReportsData = ({ children }) => {
  const data = useProvideReportsData()
  return (
    <reportsContext.Provider value={data}>
      {
        children
      }
    </reportsContext.Provider>
  )
}
