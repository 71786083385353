import {
  IonList, IonListHeader, IonRow, IonRadioGroup, IonItem, IonLabel, IonButton, IonRadio, IonContent, IonCol,
} from '@ionic/react'
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import moment from 'moment'

import testHook from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'
import { indicators } from './Indicators.json'

import { SelectIncidentType } from '../StyledContainers'
import FormPopup from './FormPopup'
import { useGeolocation } from '../../../hooks/useGeolocation'

const DescriptionArea = styled.textarea`
width: 80%;
height; 25em;
padding: 10px;
`

/**
 *  ===========================
 *     FILL an insight form
 *  ===========================
 *
 *  AKA weekly report
 */

const InsightsForm = ({
  onClose, setLoading, onFinish, areas, workspaceId,
}) => {
  /* State variables */
  const [step, setStep] = useState(0)
  const [input, setInput] = useState({
    1: undefined,
    2: undefined,
    3: undefined,
    4: undefined,
    5: undefined,
    6: undefined,
    7: undefined,
    8: undefined,
    9: undefined,
    10: undefined,
    11: undefined,
    12: undefined,
  })

  const contentRef = useRef(null)
  const scrollToTop = () => {
    // eslint-disable-next-line no-unused-expressions, @typescript-eslint/no-unused-expressions
    contentRef.current && contentRef.current.scrollToTop()
  }

  const [regions, setRegions] = useState([])
  const [counties, setCounties] = useState([])

  /* Select county  */
  const [state, setState] = useState()
  const [county, setCounty] = useState()
  const [questions] = useState(11)

  const { workspace } = useWorkspace()
  const { geolocation } = useGeolocation()

  const [complete] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const apiHook = testHook()
  const [description, setDescription] = useState()

  /* Submit insights report */
  const handleSubmit = () => {
    const answers = []

    for (let i = 4; i < questions - 1; i += 1) answers.push(Object.values(input)[i])

    const needed = Object.values(input).splice(0, 4)
    setLoading(true)
    /* Send a request to the server with the data gathered */
    apiHook.tryInsight('Insights report', description, geolocation.lng, geolocation.lat, moment().format('YYYY-MM-DD HH:mm'), needed, answers, Number(county),
      workspaceId || workspace.id, description).then(() => {
      setLoading(false)
      setSubmitted(true)
    })
  }

  const selectCounty = (e) => {
    if (!e.target.value) return

    setCounty(e.target.value)
  }

  useEffect(() => {
    setRegions(areas.regions)
    setCounties(areas.counties)
    setState(areas.regions[0].id)
  }, [])

  /* Show county selection */
  if (step === 0) {
    return (
      <>
        <IonContent
          scrollEvents
          ref={contentRef}
          style={{ '--padding-top': '20px', height: '83%' }}
        >
          <IonRow style={{ padding: '0 20px', marginBottom: '20px' }} className='ion-justify-content-between'>
            <IonLabel style={{
              fontSize: '1.6em', fontWeight: 'bold', marginTop: '1em', marginLeft: '1em', color: '#8E151F',
            }}
            >Add Field Sitrep
            </IonLabel>
            <IonRow>
              <IonLabel style={{
                color: 'black', fontSize: '1.2em', marginTop: '1em', marginLeft: '1.5em',
              }}
              >
                Select the state and county you wish to complete a Field Sitrep for by using the dropdowns below. Once selected, click ‘Next’ to begin the report.
              </IonLabel>
            </IonRow>
            <IonRow>
              <IonLabel style={{
                color: 'black', fontSize: '1.2em', marginTop: '1em', marginLeft: '1.5em',
              }}
              >
                You will be asked a series of questions and given five answers to select from. Choose the answer that best reflects the conditions in the area you are reporting on.
              </IonLabel>
            </IonRow>
          </IonRow>
          <IonRow className='ion-align-items-center'>
            <div style={{ width: '100%' }}>
              <IonRow>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontSize: '1.5em', marginLeft: '2.1em' }}>Select State:</IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel style={{ color: '#326771', fontSize: '1.5em', marginLeft: '2.1em' }}>Select County:</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <SelectIncidentType
                    style={{ marginLeft: '2em', fontSize: '1.2em', outline: 'solid 1px' }}
                    name='state'
                    value={state}
                    onChange={(e) => {
                      setState(Number(e.target.value))
                      setCounty('')
                    }}
                  >
                    {
                      regions.map(({ id, name }) => (
                        <option value={id} key={id}>{name}</option>
                      ))
                    }
                  </SelectIncidentType>
                </IonCol>
                <IonCol>
                  <SelectIncidentType
                    style={{ marginLeft: '2em', fontSize: '1.2em', outline: 'solid 1px' }}
                    name='county'
                    value={county}
                    onChange={selectCounty}
                    id='select-insights-report-county'
                  >
                    <option value='' disabled>Select County</option>
                    {
                      counties.filter(({ region_id }) => region_id === state).map(({ id, name }) => (
                        <option value={id} key={id}>{ name }</option>
                      ))
                    }
                  </SelectIncidentType>
                </IonCol>
              </IonRow>
            </div>
          </IonRow>
        </IonContent>
        <IonRow style={{ height: '17%', padding: '0 10px', backgroundColor: 'white' }} class='ion-justify-content-around'>
          <IonCol>
            <IonButton style={{ '--ion-color-primary': '#8E151F', marginLeft: '2em' }} onClick={() => onClose()}>Cancel</IonButton>
          </IonCol>
          <IonCol style={{ textAlign: 'right' }}>
            <IonButton style={{ '--ion-color-primary': '#4197A9', marginLeft: '2em' }} disabled>Prev</IonButton>
            <IonButton style={{ '--ion-color-primary': '#326771', marginRight: '2em', marginLeft: '2em' }} onClick={() => setStep(step + 1)} disabled={!county}>Next</IonButton>
          </IonCol>
        </IonRow>
      </>
    )
  }

  const handleNext = () => {
    const newStep = step + 1
    setStep(newStep)
    scrollToTop()
    return null
  }

  const handlePrev = () => {
    setStep(step - 1)
    scrollToTop()
  }

  /* Steps 1 to question No */
  if (step < questions) {
    return (
      <OptionView
        {...indicators[step - 1]}
        inputs={input}
        setInputs={setInput}
        handleNext={handleNext}
        handlePrev={() => {
          setStep(step - 1)
          scrollToTop()
        }}
        step={step}
        questions={questions}
        handleSkip={() => {
          setStep(questions)
          scrollToTop()
        }}
        complete={complete}
        onClose={onClose}
      />
    )
  }
  if (submitted) {
    return (
      <IonContent>
        <IonList style={{ height: '100%' }}>
          <FormPopup
            onClose={onFinish}
            message='You have successfully submitted an insight report.'
          />
        </IonList>
      </IonContent>
    )
  }

  /* if step is 11 get description input */
  if (step === 12) {
    return (
      <IonRow><IonButton>Hello</IonButton></IonRow>
    )
  }

  /*  Show preview in any other case */
  return (
    <>
      <IonContent
        scrollEvents
        ref={contentRef}
        style={{ '--padding-top': '20px', height: '85%' }}
      >
        <div style={{ padding: '0 10px' }}>
          <IonRow style={{ padding: '0 20px' }} className='ion-justify-content-between'>
            <IonCol>
              <IonLabel style={{
                fontSize: '1.6em', fontWeight: 'bold', marginTop: '1em', marginLeft: '1em', color: '#326771',
              }}
              >Additional Comments
              </IonLabel>
            </IonCol>
            <IonCol style={{
              textAlign: 'right', marginTop: '1em', marginLeft: '1em', marginRight: '1em',
            }}
            >
              <IonLabel style={{ fontSize: '1.2em', color: '#326771', fontWeight: 'bold' }}>{`${step} of 11`}</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonLabel style={{
              color: 'black', fontSize: '1.2em', marginTop: '1em', marginLeft: '1.5em',
            }}
            >
              If you wish to add additional comments to your report you can do so below.
            </IonLabel>
          </IonRow>
        </div>

        <IonRow className='ion-align-items-center'>
          <DescriptionArea
            style={{
              border: '1px solid #ced4da', borderRadius: '1.5px', width: '100%', resize: 'none', marginLeft: '2em', marginRight: '2em', height: '25em',
            }}
            placeholder='Additional comments(optional)'
            name='description'
            value={description}
            type='text'
            onChange={(e) => setDescription(e.target.value)}
          />
        </IonRow>
      </IonContent>
      <IonRow style={{ height: '15%', padding: '0 10px', backgroundColor: 'white' }} class='ion-justify-content-around'>
        <IonCol>
          <IonButton style={{ '--ion-color-primary': '#8E151F', marginLeft: '2em' }} onClick={() => onClose()}>Cancel</IonButton>
        </IonCol>
        <IonCol style={{ textAlign: 'right' }}>
          <IonButton style={{ '--ion-color-primary': '#4197A9', marginLeft: '2em' }} id='prev-screen-insights-report' onClick={handlePrev}>Prev</IonButton>
          <IonButton style={{ '--ion-color-primary': '#0C9500', marginRight: '2em', marginLeft: '2em' }} id='submit-insights-report' onClick={handleSubmit}>Submit Sitrep</IonButton>
        </IonCol>
      </IonRow>
    </>
  )
}

/* Step by step process screen */
const OptionView = ({
  title, options, inputs, setInputs, handleNext, handlePrev,
  step, handleSkip, complete, onClose, id,
}) => {
  const colorCodeRadio = (index) => ({
    0: '#48C933',
    1: '#33A5C9',
    2: '#e3e312',
    3: '#F18302',
    4: '#E92D21',
  }[index])

  return (
    <>
      <IonContent style={{ height: '85%', '--padding-top': '20px' }}>
        <IonList>
          <div style={{ fontWeight: 'bold', padding: '0 10px' }}>
            <IonRow style={{ padding: '0 20px' }} className='ion-justify-content-between'>
              <IonCol>
                <IonLabel style={{
                  fontSize: '1.6em', fontWeight: 'bold', marginTop: '1em', marginLeft: '1em', color: '#326771',
                }}
                >{title}
                </IonLabel>
              </IonCol>
              <IonCol style={{
                textAlign: 'right', marginTop: '1em', marginLeft: '1em', marginRight: '1em',
              }}
              >
                <IonLabel style={{ fontSize: '1.2em', color: '#326771' }}>{`${step} of 11`}</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonLabel style={{ marginLeft: '2em', color: 'black' }} />
            </IonRow>
          </div>
          <IonRadioGroup name={id} value={inputs[id]} onIonChange={(e) => setInputs({ ...inputs, [e.target.name]: e.detail.value })}>
            <IonListHeader>
              <IonLabel style={{ marginLeft: '2em', color: 'black' }}>Choose the answer that best reflects the conditions in the county you are reporting on.</IonLabel>
            </IonListHeader>
            {
              options.slice().reverse().map((opt, index) => (
                <IonItem
                  id={`insights-option-${index}`}
                  button
                  onClick={() => {
                    setInputs({ ...inputs, [id]: 5 - index })
                  }}
                  style={{ '--min-height': '5px', marginLeft: '2em' }}
                  key={opt}
                >
                  <IonRow style={{ width: '100%' }} className='ion-align-items-center ion-justify-content-between'>
                    <IonCol size={11}>
                      <p style={{ fontSize: '.9rem', marginBottom: '0', color: 'black' }} className='item-text-wrap'>{opt}</p>
                    </IonCol>
                    <IonCol size={1}>
                      <IonRadio style={{ '--color': colorCodeRadio(4 - index) }} value={5 - index} />
                    </IonCol>
                  </IonRow>
                </IonItem>
              ))
            }
          </IonRadioGroup>
        </IonList>
      </IonContent>
      <IonRow
        style={{
          alignItems: 'center', padding: '0 10px', height: '15%', backgroundColor: 'white',
        }}
        className={(complete) ? 'ion-justify-content-end' : 'ion-justify-content-around'}
      >
        {
          (complete) ? <IonButton onClick={handleSkip}>Update</IonButton>
            : (
              <>
                <IonCol>
                  <IonButton style={{ '--ion-color-primary': '#8E151F', marginLeft: '2em' }} onClick={() => onClose()}>Cancel</IonButton>
                </IonCol>
                <IonCol style={{ textAlign: 'right' }}>
                  <IonButton style={{ '--ion-color-primary': '#4197A9', marginLeft: '2em' }} id='prev-screen-insights-report' onClick={handlePrev}>Prev</IonButton>
                  <IonButton
                    style={{ '--ion-color-primary': '#326771', marginLeft: '2em', marginRight: '2em' }}
                    id='next-screen-insights-report'
                    onClick={handleNext}
                    disabled={!inputs[id]}
                  >Next
                  </IonButton>
                </IonCol>
              </>
            )
        }
      </IonRow>
    </>
  )
}

export default InsightsForm
