import { IonCol, IonRow } from '@ionic/react'
import * as React from 'react'
import { Dropdown } from 'react-bootstrap'

import violentConflict from '../../maps/incidents/icons/violent_conflict.png'
import violentCrime from '../../maps/incidents/icons/violent_crime.png'
import criminality from '../../maps/incidents/icons/criminality.png'
import socialUnrest from '../../maps/incidents/icons/social_unrest.png'
import travel from '../../maps/incidents/icons/travel.png'
import people from '../../riskregister/icon-vectors/people.png'
import facilities from '../../riskregister/icon-vectors/facilities.png'
import operations from '../../riskregister/icon-vectors/operations.png'
import equipment from '../../riskregister/icon-vectors/equipment.png'
import product from '../../riskregister/icon-vectors/product.png'
import reputation from '../../riskregister/icon-vectors/reputation.png'
import project from '../../riskregister/icon-vectors/project.png'

/* Map Legend component -> Shows what each icon corresponds to incident types */
const MapLegend = () => {
  const [show, setShow] = React.useState<boolean>(false)
  const onToggleHandler = (isOpen : boolean, metadata: { source: string }) => {
    if (metadata.source !== 'select') {
      setShow(isOpen)
    }
  }
  return (
    <Dropdown align='start' style={{ margin: '0 10px' }} show={show} onToggle={(isOpen : boolean, metadata : { source: string }) => onToggleHandler(isOpen, metadata)}>
      <Dropdown.Toggle style={{ backgroundColor: '#326771' }} id='toggle-assets-filter-dropdown'>Map Key</Dropdown.Toggle>
      <Dropdown.Menu style={{ width: '450px' }}>
        <IonRow>
          <IonCol style={{ padding: '20px', paddingRight: '0' }} size='5'>
            <h5 style={{paddingBottom: '0.6em'}} className='blue-bold-title'>Security Events</h5>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Violent Conflict</h6>
              <img alt='Violent Conflict icon' src={violentConflict} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Violent Crime</h6>
              <img alt='Violent Crime icon' src={violentCrime} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Criminality</h6>
              <img alt='Criminality icon' src={criminality} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Social Unrest</h6>
              <img alt='Social Unrest icon' src={socialUnrest} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Travel</h6>
              <img alt='Travel icon' src={travel} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Other</h6>
              <img alt='Other icon' src={violentConflict} />
            </IonRow>
          </IonCol>
          <IonCol size='2'>
            <div style={{
              width: '1px', backgroundColor: 'black', height: '100%', margin: 'auto',
            }}
            />
          </IonCol>
          <IonCol style={{ padding: '20px', paddingLeft: '0' }} size='5'>
            <h5 style={{paddingBottom: '0.6em'}} className='blue-bold-title'>Assets</h5>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>People</h6>
              <img alt='People icon' src={people} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Facilities</h6>
              <img alt='Facilities icon' src={facilities} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Operations</h6>
              <img alt='Operations icon' src={operations} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Equipment</h6>
              <img alt='Equipment icon' src={equipment} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Product</h6>
              <img alt='Product icon' src={product} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Reputation</h6>
              <img alt='Reputation icon' src={reputation} />
            </IonRow>
            <IonRow className='ion-justify-content-between map-legend-entry'>
              <h6>Project</h6>
              <img alt='Project icon' src={project} />
            </IonRow>
          </IonCol>
        </IonRow>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default MapLegend
