import { IonButton, IonRow } from '@ionic/react'
import React, { FC, useState } from 'react'
import { useTrackerPopup } from '../../hooks/safety-checks/useTrackerPopup'
import FormPopup from '../maps/forms/FormPopup'
import { SettingField } from '../settings/StyledContainers'
import { PopupBackground, PopupContainer } from './StyledContainers'
import axios from '../../utils/axios'
import { useNotification } from '../../hooks/useNotification'

const TrackerPopupHandler: FC = () => {
  const [,hide, show, notificationId, trackerId] = useTrackerPopup()
  const [submitted, setSubmitted] = useState(false)
  const notification = useNotification()

  const handleAccept = () => {
    setSubmitted(true)
    axios.post('api/v1/movement_box/trackerResponse', { tracker_id: trackerId, response: 'accepted' })
    notification.markNotificationAsRead(notificationId, true)
  }

  const handleDecline = () => {
    setSubmitted(true)
    axios.post('api/v1/movement_box/trackerResponse', { tracker_id: trackerId, response: 'rejected' })
    notification.markNotificationAsRead(notificationId, true)
  }

  return show ? (
    <>
      <PopupBackground />
      <PopupContainer>
        {
          (submitted) ? (
            <FormPopup
              message='Tracker Response sent.'
              onClose={() => {
                setSubmitted(false)
                hide()
              }}
            />
          ) : (
            <>
              <SettingField style={{ width: 'auto' }}> <h4 style={{ margin: '0' }}>Consent to Tracking</h4> </SettingField>
              <p style={{ margin: '15px 0' }}>A manager wishes to track your movement when entering/exiting a restriction zone. To do so, you must consent to having your movement tracked. </p>
              <p style={{ margin: '15px 0' }}>If you consent, you will be notified when you enter/exit the restriction zone in question. This will ensure your safety when travelling. </p>
              <IonRow className='ion-justify-content-between safety-options' style={{ marginTop: '10px' }}>
                <IonButton style={{ '--background': '#8E151F' }} onClick={handleDecline}>I Do Not Consent</IonButton>
                <IonButton style={{ '--background': '#326771' }} onClick={handleAccept}>I Do Consent</IonButton>
              </IonRow>
            </>
          )
        }
      </PopupContainer>
    </>
  ) : null
}

export default TrackerPopupHandler
