import * as React from 'react'
import * as turf from '@turf/turf'
import Select from 'react-select'
import { IonRow, IonCol, IonLabel, IonCheckbox, IonButton } from '@ionic/react'
import InsightsMap from '../maps/apps/InsightsMap2'
import MapControls from './MapControls'
import SitRepHandler from '../popups/SitRepHandler'
import { useSituationReport } from '../../hooks/insights/useSituationReport'
import { useInsightsData } from '../../hooks/insights/useInsightsData'
import { ObscureBackground, OverlayContainer, SherpaButton } from '../GlobalContainers'
import TeamRequest from './safety-checks/TeamRequest'
import FormPopup from '../maps/forms/FormPopup'
import { CenterForm } from '../riskregister/StyledContainers'
import LoadingModal from '../modals/LoadingModal'
import FSitRepRequest from './popups-2.0/FSitRepRequest'
import { Dropdown } from 'react-bootstrap'
import { FilterUpdate } from '../../hooks/terrain-mapping/helpers/StateReducers'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { assetsToGeoJSON, pulsepointsToGeoJSON } from '../../hooks/insights/helpers/MapUtils'
import { BackendAsset } from '../../hooks/risks/types/BackendData'
import Incident from '../maps/features/Incident'
import { incidentCategories } from '../maps/apps/incident_types.json'
import { processRoutes } from '../../hooks/insights/helpers/Utils'
import { communitiesToGeoJSON } from '../../hooks/terrain-mapping/helpers/Utils'
import { useWorkspace } from '../../hooks/useWorkspace'

const AppWrapper : React.FC = () => {
  const [isShowing] = useSituationReport()
  const {
    showSafetyChecks, submittedMessage, setSubmittedMessage, loading, showFieldSitrepRequest,
    showMapFilterPopup, setShowMapFilterPopup, allAssets, allRoutes, incidentFilters,
    pulsepoints, users, mapRef, incidents, communities, filteredIncidents, setPulsepoints,
  } = useInsightsData()

  const { workspace } = useWorkspace()

  const [selectedMapAssets, setSelectedMapAssets] = useState([])
  const [selectedMapComms, setSelectedMapComms] = useState([])
  const [selectedMapRoutes, setSelectedMapRoutes] = useState([])
  const [selectedMapTypes, setSelectedMapTypes] = useState([])

  const [selectedPulseRange, setSelectedPulseRange] = useState()
  const [selectedPulseAtmospherics, setSelectedPulseAtmospherics] = useState([])
  const [selectedPulseSubmitters, setSelectedPulseSubmitters] = useState([])

  const [selectedSafetyRange, setSelectedSafetyRange] = useState([])

  const [incidentTypes, setIncidentTypes] = useState([])

  const createGeoJSONData = (incidents: any[]): GeoJSON.FeatureCollection<GeoJSON.Geometry> => ({
    type: 'FeatureCollection',
    features: incidents.map((val) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          val.longitude, val.latitude,
        ],
      },
      properties: {
        category: Object.keys(incidentCategories).find((key) => incidentCategories[key].find((type: string) => type === val.incident_type)),
        ...val,
      },
    })),
  })

  const assetsToPointGeojson = (assets: BackendAsset[]) : turf.FeatureCollection<turf.Point, BackendAsset> => ({
    type: 'FeatureCollection',
    features: assets.map((val) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          val.longitude, val.latitude,
        ],
      },
      properties: {
        ...val,
        type: val.asset_type,
      },
    })),
  })

  const applyMapFilters = async () => {
    if (selectedMapAssets.length > 0) {
      if (mapRef.current) {
        // Type assertions and null checks for sources
        const assetsSource = mapRef.current.getSource('assets-source') as mapboxgl.GeoJSONSource | undefined
        const linesSource = mapRef.current.getSource('assets-lines') as mapboxgl.GeoJSONSource | undefined
        const polygonsSource = mapRef.current.getSource('assets-polygons') as mapboxgl.GeoJSONSource | undefined
        // Ensure sources are not undefined before calling setData
        const filteredAssets = allAssets.filter((asset) => selectedMapAssets.some((selected) => selected.value === asset.id))
        const { featureLines, featurePolygons } = assetsToGeoJSON(filteredAssets)
        if (assetsSource) {
          assetsSource.setData(assetsToPointGeojson(filteredAssets))
        }
        if (linesSource) {
          linesSource.setData(featureLines)
        }
        if (polygonsSource) {
          polygonsSource.setData(featurePolygons)
        }
      }
    }

    if (selectedMapTypes.length > 0) {
      if (mapRef.current) {
        // Type assertions and null checks for sources
        const incidentsSource = mapRef.current.getSource('incidents') as mapboxgl.GeoJSONSource | undefined
        const filtered = filteredIncidents.filter((incident) => selectedMapTypes.some((selected) => selected.value === incident.incident_type))
        if (incidentsSource) {
          incidentsSource.setData(createGeoJSONData(filtered))
        }
      }
    }

    if (selectedMapRoutes.length > 0) {
      if (mapRef.current) {
        const routesSource = mapRef.current.getSource('routes-source') as mapboxgl.GeoJSONSource | undefined
        const symbolSource = mapRef.current.getSource('route-symbols') as mapboxgl.GeoJSONSource | undefined
        const filteredRoutes = allRoutes.filter((route) => selectedMapRoutes.some((selected) => selected.value === route.id))
        const formattedRoutes = processRoutes(filteredRoutes)
        const routeSymbols : turf.Feature<turf.Point>[] = formattedRoutes.features.map(({ properties, geometry }) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: geometry.coordinates[0],
          },
          properties: {
            icon: properties.type,
          },
        }))
        if (routesSource) {
          routesSource.setData(formattedRoutes)
        }
        if (symbolSource) {
          symbolSource.setData({
            type: 'FeatureCollection',
            features: routeSymbols,
          })
        }
      }
    }

    if (selectedMapComms.length > 0) {
      if (mapRef.current) {
        // Type assertions and null checks for sources
        const polygonsSource = mapRef.current.getSource('community-areas') as mapboxgl.GeoJSONSource | undefined
        // Ensure sources are not undefined before calling setData
        const filteredComms = communities.filter((comm) => selectedMapComms.some((selected) => selected.value === comm.id))
        const geoJSONCommunities = communitiesToGeoJSON(filteredComms)
        if (polygonsSource) {
          polygonsSource.setData(geoJSONCommunities)
        }
      }
    }

    if (selectedPulseRange) {
      const [pulsepointData] = await Promise.all([
        axios.post('api/v2/pulsepoint/getPulsepoints', { domain_id: workspace?.id, time_frame: selectedPulseRange.value }),
      ])
      setPulsepoints(pulsepointData.data.pulsepoints)
      if (mapRef.current) {
        const pulsepointsSource = mapRef.current.getSource('pulsepoints-source') as mapboxgl.GeoJSONSource | undefined
        if (pulsepointsSource) {
          pulsepointsSource.setData(pulsepointsToGeoJSON(pulsepointData.data.pulsepoints))
        }
      }
    }

    if (selectedPulseSubmitters.length > 0 || selectedPulseAtmospherics.length > 0) {
      if (mapRef.current) {
        const pulsepointsSource = mapRef.current.getSource('pulsepoints-source') as mapboxgl.GeoJSONSource | undefined
        const filteredBySubmittersAndAtmospherics = pulsepoints.filter((pulsepoint) => {
          const matchesSubmitters = selectedPulseSubmitters.length === 0 || selectedPulseSubmitters.some((selected) => selected.value === pulsepoint.user_id)
          const matchesAtmospherics = selectedPulseAtmospherics.length === 0 || selectedPulseAtmospherics.some((selected) => selected.value === pulsepoint.level)
          return matchesSubmitters && matchesAtmospherics
        })
        if (pulsepointsSource) {
          pulsepointsSource.setData(pulsepointsToGeoJSON(filteredBySubmittersAndAtmospherics))
        }
      }
    }
    setShowMapFilterPopup(false)
  }

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#326771', // Set background color to #326771
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff', // Set text color to white
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff', // Set remove icon color to white
      ':hover': {
        backgroundColor: '#ff0000', // Change this to your desired hover background color
        color: '#fff', // Set hover text color to white
      },
    }),
  }

  const atmospherics = [
    { label: 'Red', value: 'Red' },
    { label: 'Amber', value: 'Amber' },
    { label: 'Yellow', value: 'Yellow' },
    { label: 'Green', value: 'Green' },
    { label: 'Blue', value: 'Blue' },
  ]

  const dateRanges = [
    { label: 'Last 24 hours', value: 'day' },
    { label: 'Last 7 days', value: 'week' },
    { label: 'Last 30 days', value: 'month' },
    { label: 'Last 90 days', value: '9 months' },
    { label: 'Last 180 days', value: '6 months' },
    { label: 'Last 365 days', value: 'year' },
  ]

  useEffect(() => {
    /* Fecth incidents */
    axios.get('/api/v1/types')
      .then((response) => {
        setIncidentTypes(response.data.types.sort((a, b) => {
          if (a < b) return -1
          if (b < a) return 1
          return 0
        }))
      })
  }, [])

  console.log(pulsepoints)

  return (
    <>
      <LoadingModal isOpen={loading} />
      <MapControls />
      <InsightsMap />
      {
        isShowing && (
          <SitRepHandler />
        )
      }
      {
        showFieldSitrepRequest && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <FSitRepRequest />
          </>
        )
      }
      {
        showSafetyChecks && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <TeamRequest />
          </>
        )
      }
      {
        showMapFilterPopup && (
          <>
            <ObscureBackground style={{ zIndex: 20 }} />
            <OverlayContainer style={{ zIndex: 25, width: '35%', height: '72%', borderRadius: '1em', overflowY: 'auto', display: 'flex', flexDirection: 'column' }} className='terrain-add-popup'>
              <IonRow>
                <IonCol size='9'>
                  <h5 style={{ fontSize: 14 }}>Map Filter</h5>
                </IonCol>
                <IonCol size='3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <SherpaButton style={{ fontSize: '1rem' }} onClick={() => setShowMapFilterPopup(false)}>
                    Close X
                  </SherpaButton>
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Security Reports</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Type(s)'
                    name='colors'
                    className='select-container'
                    id='incident-type'
                    options={incidentTypes.map((incident) => ({ value: incident, label: `${incident}` }))}
                    value={selectedMapTypes}
                    onChange={(selected) => setSelectedMapTypes(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Key Assets</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Key Asset(s)'
                    name='colors'
                    className='select-container'
                    id='assets'
                    options={allAssets.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => ({ value: id, label: name }))}
                    value={selectedMapAssets}
                    onChange={(selected) => setSelectedMapAssets(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Communities</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Communitie(s)'
                    name='colors'
                    className='select-container'
                    id='communities'
                    options={communities.map((val) => ({ value: val.id, label: val.name }))}
                    value={selectedMapComms}
                    onChange={(selected) => setSelectedMapComms(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Route</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    isMulti
                    placeholder='Select Route(s)'
                    name='colors'
                    className='select-container'
                    id='routes'
                    options={allRoutes.map((route) => ({ value: route.id, label: `${route.name}` }))}
                    value={selectedMapRoutes}
                    onChange={(selected) => setSelectedMapRoutes(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Pulse Points</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    placeholder='Select Date Range'
                    name='colors'
                    className='select-container'
                    id='routes'
                    options={dateRanges}
                    value={selectedPulseRange}
                    onChange={(selected) => setSelectedPulseRange(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                  <Select
                    isMulti
                    placeholder='Select Atmospheric(s)'
                    name='colors'
                    className='select-container'
                    id='routes'
                    options={atmospherics}
                    value={selectedPulseAtmospherics}
                    onChange={(selected) => setSelectedPulseAtmospherics(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={{
                      ...customStyles,
                      container: (provided) => ({
                        ...provided,
                        paddingTop: '10px',
                      }),
                    }}
                  />
                  <Select
                    isMulti
                    placeholder='Select Submitter(s)'
                    name='colors'
                    className='select-container'
                    id='routes'
                    options={users.map((user) => ({ value: user.id, label: `${user.first_name} ${user.final_name}` }))}
                    value={selectedPulseSubmitters}
                    onChange={(selected) => setSelectedPulseSubmitters(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={{
                      ...customStyles,
                      container: (provided) => ({
                        ...provided,
                        paddingTop: '10px',
                      }),
                    }}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingTop: '10px', margin: '0', padding: '0' }}>
                <IonCol size='4'>
                  <IonLabel style={{ marginRight: '10px', fontSize: 14, color: '#326771' }}>Safety Updates</IonLabel>
                </IonCol>
                <IonCol size='8'>
                  <Select
                    placeholder='Select Date Range'
                    name='colors'
                    className='select-container'
                    id='routes'
                    options={dateRanges}
                    value={selectedSafetyRange}
                    onChange={(selected) => setSelectedSafetyRange(selected)}
                    isCreatable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </IonCol>
              </IonRow>
              <IonRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
                <IonCol>
                  <IonButton onClick={() => setShowMapFilterPopup(false)} style={{ '--background': '#8E151F' }}>
                    Cancel
                  </IonButton>
                </IonCol>
                <IonCol style={{ textAlign: 'right' }}>
                  <IonButton onClick={applyMapFilters} style={{ '--background': '#4197A9' }}>
                    Filter
                  </IonButton>
                </IonCol>
              </IonRow>
            </OverlayContainer>
          </>
        )
      }
      {
        submittedMessage && (
          <>
            <ObscureBackground style={{ zIndex: '10' }} />
            <CenterForm style={{ height: 'auto', width: '30%' }}>
              <FormPopup message={submittedMessage} onClose={() => setSubmittedMessage(null)} />
            </CenterForm>
          </>
        )
      }
    </>
  )
}

export default AppWrapper
