/* eslint-disable react/no-array-index-key, @typescript-eslint/naming-convention  */
import {
  IonCol, IonContent, IonRow, IonLabel, IonButton,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import React from 'react'
import moment from 'moment'
import { WidgetContainer, WidgetTitles, ViewButton } from '../maps/WidgetContainers'

import { useAuth } from '../../hooks/useAuth'
import { useJourneysData2 } from '../../hooks/journeys/useJourneysData2'
import { JourneyStatus } from '../types/GlobalTypes'
import { Journey } from '../types/OptimizedMaps'

const JourneysList = () => {
  const auth = useAuth()

  /* Current logged in user's email */
  /* @typescript-eslint/naming-convention */
  const { user_id } = auth.user

  const {
    view, loading, showAirJourneyForm, showForm,
    journeys, qgjourneys,
  } = useJourneysData2()

  const getColor = (index: number) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')

  /* Returns the color related to a journey status */
  function getStatusColor(status: JourneyStatus | 'Approved') {
    let color = ''
    switch (status) {
      case 'Pending':
        color = '#326771'
        break
      case 'Pre-Departure':
        color = '#4197A9'
        break
      case 'In-Transit':
        color = '#FF8A00'
        break
      case 'Approved':
        color = '#14C105'
        break
      case 'Complete':
        color = '#0C9500'
        break
      default:
        color = ''
        break
    }
    return color
  }

  function sortJourneysByDepartureDateAscending(journeysArr: Journey[]) {
    journeysArr.sort((a, b) => new Date(a.departure_time) - new Date(b.departure_time))
    return journeysArr
  }

  function sortJourneysByDepartureDateDescending(journeysArr) {
    sortJourneysByDepartureDateAscending(journeysArr)
    return journeysArr.reverse()
  }

  return (
    <IonRow
      style={{
        display: (view === 'Air Travel' || showAirJourneyForm) ? 'none' : 'flex',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <WidgetContainer style={{ '--ion-background-color': 'white', zIndex: 11 }}>
        {
            loading
            && (
              <p>Loading ...</p>
            )
          }
        {
          !loading && view === 'Road Travel'
          && (
            <>
              <WidgetTitles style={{ paddingRight: '0', height: '10%' }}>
                <IonRow class='ion-align-items-center' style={{ height: '100%', padding: '0' }} className='ion-align-items-center'>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> Start </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> End </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> Requested by </IonCol>
                  <IonCol class='ion-align-items-center' style={{ fontWeight: 'bold' }} size='2'>
                    <IonLabel style={{ marginTop: '0.5em' }}>Departure Date</IonLabel>
                    <IonButton
                      onClick={sortJourneysByDepartureDateDescending(journeys)}
                      size='small'
                      fill='clear'
                      style={{ color: '#326771', paddingBottom: '0.9em' }}
                    >
                      <Icon icon='bxs:down-arrow' />
                    </IonButton>
                  </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='1'> Arrival Date </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> Status </IonCol>
                  <IonCol size='1' />
                </IonRow>
              </WidgetTitles>
              <IonContent style={{ height: '90%', '--color': '#326771', position: 'relative' }}>
                {
                  journeys.map((journey, index) => (
                    <IonRow style={{ backgroundColor: getColor(index), height: '10%', fontSize: '.8rem' }} className='ion-align-items-center widget-row ion-text-center' key={journey.id}>
                      <IonCol className='journey-start-location' size='2'> { journey.departure_location } </IonCol>
                      <IonCol className='journey-end-location' size='2'> { journey.arrival_location } </IonCol>
                      <IonCol size='2'> { `${journey.submitter.first_name} ${journey.submitter.final_name}` } </IonCol>
                      <IonCol size='2'> { moment(journey.departure_time).format('DD/MM/YY')} </IonCol>
                      <IonCol size='1'> { moment(journey.arrival_time).format('DD/MM/YY') } </IonCol>
                      <IonCol size='2'>
                        <IonLabel style={{
                          backgroundColor: getStatusColor(journey.status), color: '#FFFFFF', padding: '0.3em', borderRadius: '0.2em',
                        }}
                        >
                          {journey.status}
                        </IonLabel>
                      </IonCol>
                      <IonCol size='1'>
                        <ViewButton id={`view-journey-${journey.departure_location}`} onClick={() => showForm(journey)}> {(journey.approver.id === user_id && journey.status === 'Pending')
                          ? 'Authorise' : 'View'}
                        </ViewButton>
                      </IonCol>
                    </IonRow>
                  ))
                }
                {
                  journeys.length < 3 && (
                    [...Array(3 - journeys.length)].map((val, index) => (
                      <IonRow style={{ backgroundColor: getColor(journeys.length + index), height: '25%' }} key={index} />
                    ))
                  )
                }
              </IonContent>
            </>
          )
        }
        {
          !loading && view === 'QuickGo Journeys'
          && (
            <>
              <WidgetTitles style={{ paddingRight: '0', height: '10%' }}>
                <IonRow class='ion-align-items-center' style={{ height: '100%', padding: '0' }} className='ion-align-items-center'>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> Start </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> End </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> Requested by </IonCol>
                  <IonCol class='ion-align-items-center' style={{ fontWeight: 'bold' }} size='2'>
                    <IonLabel style={{ marginTop: '0.5em' }}>Departure Date</IonLabel>
                    <IonButton
                      onClick={sortJourneysByDepartureDateDescending(journeys)}
                      size='small'
                      fill='clear'
                      style={{ color: '#326771', paddingBottom: '0.9em' }}
                    >
                      <Icon icon='bxs:down-arrow' />
                    </IonButton>
                  </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='1'> Arrival Date </IonCol>
                  <IonCol style={{ fontWeight: 'bold' }} size='2'> Status </IonCol>
                  <IonCol size='1' />
                </IonRow>
              </WidgetTitles>
              <IonContent style={{ height: '90%', '--color': '#326771', position: 'relative' }}>
                {
                  qgjourneys.map((journey, index) => (
                    <IonRow style={{ backgroundColor: getColor(index), height: '10%', fontSize: '.8rem' }} className='ion-align-items-center widget-row ion-text-center' key={journey.id}>
                      <IonCol className='journey-start-location' size='2'> { journey.departure_location } </IonCol>
                      <IonCol className='journey-end-location' size='2'> { journey.arrival_location } </IonCol>
                      <IonCol size='2'> { `${journey.submitter.first_name} ${journey.submitter.final_name}` } </IonCol>
                      <IonCol size='2'> { moment(journey.departure_time).format('DD/MM/YY')} </IonCol>
                      <IonCol size='1'> { moment(journey.arrival_time).format('DD/MM/YY') } </IonCol>
                      <IonCol size='2'>
                        <IonLabel style={{
                          backgroundColor: getStatusColor(journey.status), color: '#FFFFFF', padding: '0.3em', borderRadius: '0.2em',
                        }}
                        >
                          {journey.status}
                        </IonLabel>
                      </IonCol>
                      <IonCol size='1'>
                        <ViewButton id={`view-journey-${journey.departure_location}`} onClick={() => showForm(journey)}> {(journey.approver.id === user_id && journey.status === 'Pending')
                          ? 'Authorise' : 'View'}
                        </ViewButton>
                      </IonCol>
                    </IonRow>
                  ))
                }
                {
                  journeys.length < 3 && (
                    [...Array(3 - journeys.length)].map((val, index) => (
                      <IonRow style={{ backgroundColor: getColor(journeys.length + index), height: '25%' }} key={index} />
                    ))
                  )
                }
              </IonContent>
            </>
          )
        }
      </WidgetContainer>
    </IonRow>
  )
}

export default JourneysList
