import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  IonRow, IonLabel, IonCheckbox,
} from '@ionic/react'
import { Icon } from '@iconify/react'
import { Dropdown } from 'react-bootstrap'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LineDivider } from '../GlobalContainers'
import { SelectView } from '../maps/StyledContainers'

import { useJourneysData2 } from '../../hooks/journeys/useJourneysData2'

/**
 *  ===========================================
 *      JOURNEY MAP CONTROLS
 *  ===========================================
 */

const MapControls = () => {
  const {
    view, views, handleViewChange, startMB, startRoute, startJourney, startAirJourney, filters,
    setFilters, paintMBs, drawRoutes, drawAssets, drawAlertStates, showSecurityHeatMap,
    showRouteForm, showTicket, showMbForm, showJourneyAuthorization, showJourneyForm, showEditJourney,
    showJourneyChoice, setShowAirTravelList,
  } = useJourneysData2()

  const [toggleAll, setToggleAll] = useState(Object.values(filters).findIndex((param) => !param) < 0)

  /* Dropdown handlers */
  const [show, setShow] = useState(false)

  const showAddButton = !showRouteForm && !showTicket && !showMbForm && !showJourneyAuthorization && !showJourneyForm && !showEditJourney && !showJourneyChoice

  /* Show/hide movement boxes */
  const handleFilter = (e) => {
    if (e.stopPropagation) e.stopPropagation()
    console.log(e.target.name)

    const tmpFilters = { ...filters, [e.target.name]: e.target.checked }
    setFilters(tmpFilters)

    if (!e.target.checked) setToggleAll(false)

    if (e.target.name === 'mb') {
      paintMBs(e.target.checked)
    }

    if (e.target.name === 'routes') {
      drawRoutes(e.target.checked)
    }

    if (e.target.name === 'keyAssets') {
      drawAssets(e.target.checked)
    }

    if (e.target.name === 'securityHeatMap') {
      showSecurityHeatMap(e.target.checked)
    }

    if (e.target.name === 'alertStates') {
      drawAlertStates(e.target.checked)
    }

    if (Object.values(tmpFilters).findIndex((param) => !param) < 0) setToggleAll(true)
  }

  const handleToggleAll = (e) => {
    if (e.stopPropagation) e.stopPropagation()

    paintMBs(e.target.checked)
    drawRoutes(e.target.checked)
    drawAssets(e.target.checked)
    showSecurityHeatMap(e.target.checked)
    drawAlertStates(e.target.checked)

    setFilters(Object.fromEntries(Object.keys(filters).map((key) => [key, e.target.checked])))
    setToggleAll(e.target.checked)
  }

  useEffect(() => {
    setToggleAll(Object.values(filters).findIndex((param) => !param) < 0)
  }, [filters])

  /* Toggle fitler dropdown handler */
  const onToggleHandler = (isOpen, metadata) => {
    if (metadata.source !== 'select') {
      setShow(isOpen)
    }
  }

  /* Filters for map filters button */
  const filterChoices = (
    <>
      <Dropdown.Item onClick={() => handleToggleAll({ target: { checked: !toggleAll } })} className='task-filter-option'>
        <IonLabel style={{ padding: '0 10px' }}>Toggle all</IonLabel>
        <IonCheckbox name='toggle-all' checked={toggleAll} onClick={handleToggleAll} />
      </Dropdown.Item>

      <Dropdown.Item onClick={() => handleFilter({ target: { name: 'mb', checked: !filters.mb } })} className='task-filter-option'>
        <IonLabel style={{ padding: '0 10px' }}> Restriction Zones </IonLabel>
        <IonCheckbox name='mb' checked={filters.mb} onClick={handleFilter} />
      </Dropdown.Item>

      <Dropdown.Item onClick={() => handleFilter({ target: { name: 'routes', checked: !filters.routes } })} className='task-filter-option'>
        <IonLabel style={{ padding: '0 10px' }}> Routes </IonLabel>
        <IonCheckbox name='routes' checked={filters.routes} onClick={handleFilter} />
      </Dropdown.Item>

      <Dropdown.Item onClick={() => handleFilter({ target: { name: 'keyAssets', checked: !filters.keyAssets } })} className='task-filter-option'>
        <IonLabel style={{ padding: '0 10px' }}> Key Assets </IonLabel>
        <IonCheckbox name='keyAssets' checked={filters.keyAssets} onClick={handleFilter} />
      </Dropdown.Item>

      <LineDivider />

      <Dropdown.Item onClick={() => handleFilter({ target: { name: 'securityHeatMap', checked: !filters.securityHeatMap } })} className='task-filter-option'>
        <IonLabel style={{ padding: '0 10px' }}> Security Heat Map </IonLabel>
        <IonCheckbox name='securityHeatMap' checked={filters.securityHeatMap} onClick={handleFilter} />
      </Dropdown.Item>

      <Dropdown.Item onClick={() => handleFilter({ target: { name: 'alertStates', checked: !filters.alertStates } })} className='task-filter-option'>
        <IonLabel style={{ padding: '0 10px' }}> Alert States </IonLabel>
        <IonCheckbox name='alertStates' checked={filters.alertStates} onClick={handleFilter} />
      </Dropdown.Item>
    </>
  )

  /* Forms to fill */
  const formButtons = (
    <>
      <Dropdown.Item
        onClick={() => {
          startRoute()
        }}
      > Route
      </Dropdown.Item>
      <Dropdown.Item
        className='add-button-journeys'
        onClick={() => {
          startMB()
          setShowAirTravelList(false)
        }}
      > Restriction Zone
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          startJourney()
          setShowAirTravelList(false)
        }}
      >Road Travel Request
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          startAirJourney()
          setShowAirTravelList(false)
        }}
      >Air Travel Request
      </Dropdown.Item>
    </>
  )

  return (
    <IonRow
      style={{
        backgroundColor: '#FFFFFF',
        padding: '10px 30px',
        marginBottom: '10px',
        boxShadow: '0px 4px 4px 0px #00000040',
        position: 'relative',
        borderRadius: '5px',
      }}
      className='ion-align-items-center ion-justify-content-between'
    >
      <SelectView
        style={{
          padding: '10px 20px',
          borderRadius: '5px',
          color: 'white',
        }}
        value={view}
        onChange={(e) => handleViewChange(e.target.value)}
        className='desktop-insights-dropdown'
      >
        {
          views.map((val) => (
            <option key={val} value={val}>{val}</option>
          ))
        }
      </SelectView>
      <IonRow class='ion-align-items-center'>
        <Dropdown style={{ margin: '0 20px' }} show={show} onToggle={(isOpen, metadata) => onToggleHandler(isOpen, metadata)}>
          <Dropdown.Toggle style={{ backgroundColor: '#326771' }} id='toggle-assets-filter-dropdown'>Map Filters <Icon icon='fa:filter' /></Dropdown.Toggle>
          <Dropdown.Menu id='filters-dropdown-list' style={{ minWidth: '15rem' }}>
            {
              filterChoices
            }
          </Dropdown.Menu>
        </Dropdown>
        {
          showAddButton && (
          <Dropdown>
            <Dropdown.Toggle style={{ backgroundColor: '#0C9500' }} className='map-button remove-arrow'> Add <FontAwesomeIcon icon={faPlus} /></Dropdown.Toggle>
            <Dropdown.Menu>
              {
                formButtons
              }
            </Dropdown.Menu>
          </Dropdown>
          )
        }
      </IonRow>
    </IonRow>
  )
}

export default MapControls
