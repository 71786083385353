import React, { useState } from 'react'
import { User } from '../../components/types/GlobalTypes'
import { HookInterface } from './types/HookInterface'
import useApi from '../testHooks'
import { useWorkspace } from '../useWorkspace'
import axios from 'axios'

export const settingsContext = React.createContext({} as HookInterface)
export const useSettings = () => React.useContext(settingsContext)

export const useProvideSettings = () : HookInterface => {
  const [showInvitePopup, setShowInvitePopup] = useState<boolean>(false)
  const [workspaceUsers, setWorkspaceUsers] = useState<User[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const apiHook = useApi()
  const { workspace } = useWorkspace()
  const [fetchedUsers, setFetchedUsers] = useState<User[]>([])

  const pushNewInvites = (newUsers: User[]) => {
    /* Remove added users from list of dropdown users */
    const copy = users.filter(({ id }) => !newUsers.find((val) => val.id === id))

    setUsers(copy)
    setWorkspaceUsers([...workspaceUsers, ...newUsers])
  }

  const removeUser = (user: User) => {
    const copy = users.slice()
    copy.push(user)

    const workspaceCopy = workspaceUsers.slice()
    const index = workspaceCopy.findIndex((val) => val.id === user.id)
    workspaceCopy.splice(index, 1)

    setWorkspaceUsers(workspaceCopy)
    setUsers(copy)
  }

  const fetchUsers = () => {
    Promise.all([
      axios.post('/api/v1/domain/get_domain_users', { domain_id: workspace.id }),
      axios.post('/get_users', { domain_id: workspace.id }),
    ]).then(([dbUsers, existingUsers]) => {
      console.log('dbusers', dbUsers.data)
      const copy = dbUsers.data.users.filter(({ id }) => !existingUsers.data.users.find((val) => val.id === id))
      setUsers(copy)
      console.log('existing users', existingUsers)
      setAllUsers(existingUsers.data.users)
      setFetchedUsers(copy)
    })
    console.log(users)
  }

  return {
    showInvitePopup,
    workspaceUsers,
    setWorkspaceUsers,
    setShowInvitePopup,
    users,
    pushNewInvites,
    loading,
    setLoading,
    setUsers,
    removeUser,
    fetchUsers,
    fetchedUsers,
    allUsers,
  }
}

export const ProvideSettings = ({ children }) => {
  const data = useProvideSettings()

  return (
    <settingsContext.Provider value={data}>
      {
        children
      }
    </settingsContext.Provider>
  )
}
