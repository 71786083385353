import { IonCol, IonContent } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import IncidentWidget from './IncidentWidget'

import useApi from '../../hooks/testHooks'
import JourneyWidget from './JourneyWidget'
import { useWorkspace } from '../../hooks/useWorkspace'
import IncidentData from './IncidentData'

/**
 *  ==========================================
 *    Other apps widget for the Dashboard
 *  ==========================================
 */

const Widgets = () => {
  /* Data to display */
  const [incidents, setIncidents] = useState([])
  const [journeys, setJourneys] = useState({
    'Pre-Departure': 0,
    'In-Transit': 0,
    Pending: 0,
  })

  /* Api hooks */
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  useEffect(async () => {
    const [incidentList, journeyList] = await Promise.all([apiHook.getIncidents(workspace.id), apiHook.getJourneys(workspace.id)])
    const counter = {
      'Pre-Departure': 0,
      'In-Transit': 0,
      Pending: 0,
    }
    journeyList.journeys.forEach(({ status }) => {
      // eslint-disable-next-line no-plusplus
      counter[status]++
    })

    setJourneys(counter)
    /* Display only the for latest incidents */
    setIncidents(incidentList.incidents.sort((val1, val2) => new Date(val2.created_at) - new Date(val1.created_at)).slice(0, 4))
  }, [])

  return (
    <IonCol className='dashboard-widgets' size={4} style={{ padding: '0 30px' }}>
      <IonContent>
        {
          workspace.is_incident_domain ? (
            <IncidentData />
          ) : (
            <>
              <IncidentWidget
                incidents={incidents}
              />
              <JourneyWidget
                predepature={journeys['Pre-Departure']}
                transit={journeys['In-Transit']}
                pending={journeys.Pending}
              />
            </>
          )
        }
      </IonContent>
    </IonCol>
  )
}

export default Widgets
