export enum RiskRating {
  VeryLow = 'Very Low',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Extreme = 'Extreme',
}

export enum ImpactRating {
  None = 'None',
  Insignificant = 'Insignificant',
  Minor = 'Minor',
  Moderate = 'Moderate',
  Major = 'Major',
  Severe = 'Severe',
}

export enum LikelihoodRating {
  Rare = 'Rare',
  Unlikely = 'Unlikely',
  Possible = 'Possible',
  Likely = 'Likely',
  AlmostCertain = 'Almost Certain',
}

interface ImpactCategories {
  people: ImpactRating,
  operations: ImpactRating,
  property: ImpactRating
}

export interface RiskAssessment {
  visibleId: number,
  id: number,
  inherentImpactCategories: ImpactCategories,
  inherentVulnerability: RiskRating,
  intent: RiskRating,
  existingMeasures: ControlMeasure[],
  newMeasures: ControlMeasure[],
  mitigations: boolean,
  owner?: {
    email: string,
    id: number
  },
  impact: ImpactRating,
  residualImpact: ImpactRating,
  residualThreatLikelihood: LikelihoodRating,
  threatRating: RiskRating,
  inherentThreatLikelihood: LikelihoodRating,
  riskRating: RiskRating,
  residualRisk: RiskRating,
  capability: RiskRating,
  riskEvent: string,
  residualImpactCategories: ImpactCategories,
  residualVulnerability: RiskRating,
  updated: string,
}

export enum MitigationVersion {
  Standard = 'Standard',
  UserActive = 'User Active',
}

export interface ControlMeasure {
  id: number,
  control_measure: string,
  category: string,
  version: MitigationVersion
}

export enum MitigationStatus {
  Existing = 'Existing',
  New = 'New',
}

export interface Mitigation {
  id: number,
  asset_risk_id: number,
  status: MitigationStatus,
  control_measure_id: number
}
