import {
  Feature, featureCollection, LineString, Point, Polygon,
} from '@turf/turf'
import * as turf from '@turf/turf'
import { LinePOIFeatures, POIFeaturePoints, PolygonFeatures } from '../../../components/types/GeoJSONTypes'
import { GeoLocation, POIType } from '../../../components/types/GlobalTypes'
import {
  CommunitiesGeoJSON, Community, Stakeholder, TerrainPOI,
} from '../../../components/types/OptimizedMaps'

/**
 * Get GeoJSON formatted collection of community areas
 *
 * @param communities -> List of communities
 * @returns A feature collection containing all polygons
 */
export const communitiesToGeoJSON = (communities: Community[]) : CommunitiesGeoJSON => {
  const features : Feature<Polygon, { id: number }>[] = communities.map(({ geoData, id }) => ({ ...geoData, properties: { id } }))
  return featureCollection(features)
}

/**
 * Get GeoJSON data for POIs
 *
 * @param POIs -> List of point of interests
 * @returns FeatureCollection of points and linestrings
 */
export const poisToGeoJSON = (POIs: TerrainPOI[]) : {
  featurePoints: POIFeaturePoints,
  featureLines: LinePOIFeatures,
  featurePolygons: PolygonFeatures,
} => {
  const pointFeatures : Feature<Point, { id: number, name: string }>[] = POIs.map((val) => {
    if (val.type === POIType.PIN) {
      return { ...val.geoData as Feature<Point>, properties: { id: val.id, name: val.name, category: val.category } }
    }

    if (val.type === POIType.POLYGON) {
      const center = turf.center(val.geoData, { properties: { id: val.id, name: val.name } })
      return center
    }

    return {
      type: 'Feature',
      properties: { id: val.id, name: val.name },
      geometry: {
        type: 'Point',
        coordinates: val.geoData && val.geoData.geometry && val.geoData.geometry.coordinates
          ? (val.geoData as Feature<LineString>).geometry.coordinates[0]
          : null,
      },
    }
  })
  const lineFeatures : Feature<LineString, { id: number }>[] = POIs.filter(({ type }) => type === POIType.LINE_MARKER).map(({ geoData, id }) => ({
    ...geoData as Feature<LineString>, properties: { id },
  }))

  const polygonFeatures : Feature<Polygon, { id: number }>[] = POIs.filter(({ type }) => type === POIType.POLYGON).map(({ geoData, id }) => ({
    ...geoData as Feature<Polygon>, properties: { id },
  }))

  return {
    featureLines: featureCollection(lineFeatures),
    featurePoints: featureCollection(pointFeatures),
    featurePolygons: featureCollection(polygonFeatures),
  }
}

/**
 * Get point of interest location
 *
 * @param poi -> Point of Interest location
 * @returns GeoLocation object
 */
export const getPoILocation = (poi: TerrainPOI) : GeoLocation => {
  if (poi.type === POIType.PIN) {
    return {
      lat: (poi.geoData as Feature<Point>).geometry.coordinates[1],
      lng: (poi.geoData as Feature<Point>).geometry.coordinates[0],
    }
  }

  if (poi.type === POIType.LINE_MARKER) {
    return {
      lat: (poi.geoData as Feature<LineString>).geometry.coordinates[0][1],
      lng: (poi.geoData as Feature<LineString>).geometry.coordinates[0][0],
    }
  }

  const center = turf.center(poi.geoData)
  return {
    lat: center.geometry.coordinates[1],
    lng: center.geometry.coordinates[0],
  }
}

export const updateCommunityStakeholders = (records: Stakeholder[], community: Community, allStakeholders: Stakeholder[]) => {
  const updatedStakeholders = allStakeholders.reduce<Stakeholder[]>((list, current) => {
    const existentCommunity = current.areas.some(({ id }) => id === community.id)
    const shouldBeAdded = allStakeholders.some(({ id }) => records.find((val) => val.id === id))

    if (!existentCommunity && !shouldBeAdded) {
      return [...list, current]
    }

    if (existentCommunity && !shouldBeAdded) {
      const updatedAreas = current.areas.filter(({ id }) => id !== community.id)
      return [...list, { ...current, areas: updatedAreas }]
    }

    if (!existentCommunity && shouldBeAdded) {
      const updatedAreas = current.areas.slice()
      updatedAreas.push(community)

      return [...list, { ...current, areas: updatedAreas }]
    }

    const updatedAreas = current.areas.slice()
    const index = updatedAreas.findIndex(({ id }) => id === community.id)
    updatedAreas.splice(index, 1, { ...community })

    return [...list, { ...current, areas: updatedAreas }]
  }, [])

  return updatedStakeholders
}
