import React, { useEffect } from 'react'
import Select from 'react-select'
import {
  IonList, IonContent, IonRow, IonButton, IonCol,
} from '@ionic/react'

import { SettingField } from '../../../settings/StyledContainers'
import { CenterForm, SelectionDetail } from '../../StyledContainers'

/**
 *  =============================
 *      INHERENT VULNERABILITY
 *  =============================
 */
const InherentVulnerability = ({
  children, input, edit, vulnerability, setInput, onClose, next, getNumericalValue, getCategory,
}) => {
  const handleChange = (selected) => {
    const residualThreatLikelihood = Math.ceil((getNumericalValue(input.inherentThreatRating) * getNumericalValue(selected.value)) / 5)
    setInput({
      ...input, residualVulnerability: selected, edit: true, residualThreatLikelihood,
    })
  }

  useEffect(() => {
    if (input.inherentThreatRating && input.residualVulnerability) {
      const residualThreatLikelihood = Math.ceil((getNumericalValue(input.inherentThreatRating) * getNumericalValue(input.residualVulnerability.value)) / 5)
      setInput({ ...input, residualThreatLikelihood })
    }
  }, [])

  return (
    <CenterForm style={{ zIndex: '20', width: '90%', height: '105%' }}>
      <IonContent>
        <IonList className='risk-assessment-list' style={{ padding: '20px', height: '100%' }}>
          <div>
            {
              children
            }
            <SettingField>
              <h5 className='blue'>Residual Risk - Vulnerability Assessment</h5>
              {
                (edit) ? (
                  <>
                    <p>This is how Vulnerability was scored in the previous assessment, factoring in any new control measures that will be put in place.
                      You can adjust it accordingly as part of this review.
                    </p>
                  </>
                ) : (
                  <>
                    <p>Residual Risk refers to the amount of risk that remains after new control measures are accounted for. Threat does not change,
                      but you do need to reevaluate the Vulnerability of your asset, taking into account any new control measures that you have selected in the previous section.
                    </p>
                  </>
                )
              }
            </SettingField>
            <IonRow>
              <IonCol size={4}>
                <SettingField>
                  <h5>Vulnerability</h5>
                  <Select
                    placeholder='Select Vulnerability'
                    name='intent'
                    id='ra-residual-vulnerability'
                    className='select-container'
                    value={input.residualVulnerability}
                      // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    options={Object.keys(vulnerability).map((level) => ({ label: level, value: level }))}
                    onChange={handleChange}
                  />
                </SettingField>
              </IonCol>
              {
                input.residualVulnerability && (
                  <IonCol size={8}>
                    <SelectionDetail>
                      <p>{ vulnerability[input.residualVulnerability.value] }</p>
                    </SelectionDetail>
                  </IonCol>
                )
              }
            </IonRow>
            <IonRow>
              <SettingField style={{ width: 'auto' }}>
                <h5>Likelihood Rating</h5>
                <p id='residual-vulnerability'> { getCategory(input.residualThreatLikelihood) } </p>
              </SettingField>
            </IonRow>
          </div>
          <IonRow className='ion-justify-content-end'>
            <IonButton style={{ '--background': '#4197A9' }} onClick={onClose}>Back</IonButton>
            <IonButton style={{ '--background': '#326771' }} onClick={next} disabled={!input.residualVulnerability}>Next</IonButton>
          </IonRow>
        </IonList>
      </IonContent>
    </CenterForm>
  )
}

export default InherentVulnerability
