import React, { useState, useEffect } from 'react'
import { IonRow, IonButton, useIonAlert } from '@ionic/react'
import { FormContainer } from '../maps/forms/MapForm'
import { MapInstructions, SimpleButton } from '../maps/StyledContainers'
import IncidentDetails from './IncidentDetails'
import axios from '../../utils/axios'
import { CenterForm } from '../riskregister/StyledContainers'
import FormPopup from '../maps/forms/FormPopup'
/**
 *  ===================================
 *    FIRST EDIT PIN THEN OTHER DATA
 *  ===================================
 */
const EditIncident = ({
  focusedIncident, disregardIncident, finishIncidentedit, deleteIncident, onClose,
}) => {
  /* Input details of the incident */
  const [input, setInput] = useState({})
  const [showDetails, setShowDetails] = useState(false)
  const [message, setMessage] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [types, setTypes] = useState([])
  const [ionAlert] = useIonAlert()

  const handleNext = () => {
    setShowDetails(true)
  }

  const onCloseDetails = () => {
    setShowDetails(false)
  }

  const handleDeleteIncident = () => {
    ionAlert({
      header: 'Delete Incident?',
      message: 'Are you sure you want to delete this incident?',
      buttons: [
        { text: 'No' },
        {
          text: 'Yes',
          handler: () => {
            deleteIncident(input, () => {
              setSubmitted(true)
              setMessage('Incident has been deleted.')
            })
          },
        },
      ],
    })
  }

  const returnMessage = (key) => ({
    incident_type: 'Please specify an incident type',
    date: 'Please indicate the date of the incident',
    fatalities: 'please select a number of fatalities',
  }[key])

  const handleSubmit = () => {
    const notFilled = Object.keys(input).find((key) => input[key] === undefined)

    if (notFilled) {
      return ionAlert({
        header: 'Error',
        message: returnMessage(notFilled),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }

    if (!input.incident_type) {
      return ionAlert({
        header: 'Error',
        message: returnMessage('incident_type'),
        buttons: [
          'Cancel',
          { text: 'Ok' },
        ],
      })
    }
    finishIncidentedit(input, () => {
      setSubmitted(true)
      setMessage('Incident informtion has been updated.')
    })
    return null
  }

  useEffect(() => {
    axios.get('/api/v1/types')
      .then((response) => {
        setTypes(response.data.types.sort((a, b) => {
          if (a < b) return -1
          if (b < a) return 1
          return 0
        }))
        setInput({ ...input, incident_type: '' })
      })
  }, [])

  useEffect(() => {
    if (input.description) {
      setInput({ ...input, latitude: focusedIncident.latitude, longitude: focusedIncident.longitude })
    } else {
      setInput({ ...focusedIncident })
    }
  }, [focusedIncident])
  return (
    <>
      {
        (!submitted) ? (
          <>
            {
              (showDetails) ? (
                <IncidentDetails
                  input={input}
                  setInput={setInput}
                  onClose={onCloseDetails}
                  incidentTypes={types}
                  onSubmit={handleSubmit}
                />
              ) : (
                <FormContainer style={{ height: 'auto', backgroundColor: 'white' }}>
                  <MapInstructions style={{ padding: '15px' }}>
                    <h2 style={{ color: '#326771' }}>Edit Incident</h2>
                    <p style={{ margin: '10px 0' }}>Click and drag the incident pin you wish to edit to change its position on the map.</p>
                    <p style={{ margin: '10px 0' }}>Click ‘Next’ to edit the incident’s details.</p>
                  </MapInstructions>
                  <IonRow style={{ padding: '0 10px' }} className={(focusedIncident) ? 'ion-justify-content-between' : 'ion-justify-content-end'}>
                    {
                      focusedIncident && (
                        <SimpleButton onClick={handleDeleteIncident} style={{ color: '#E70000FE' }}>Delete Incident</SimpleButton>
                      )
                    }
                    <IonRow style={{ margin: '10px 0' }}>
                      <IonButton style={{ '--background': '#4197A9' }} onClick={() => disregardIncident()}>Cancel</IonButton>
                      <IonButton style={{ '--background': '#326771' }} disabled={!focusedIncident} onClick={handleNext}>Next</IonButton>
                    </IonRow>
                  </IonRow>
                </FormContainer>
              )
            }
          </>
        ) : (
          <CenterForm style={{ height: 'auto', width: '30%' }}>
            <FormPopup
              message={message}
              onClose={onClose}
            />
          </CenterForm>
        )
      }
    </>
  )
}

export default EditIncident
