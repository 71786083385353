import { Icon } from '@iconify/react'
import {
  IonContent, IonPage, IonCol, IonRow, IonItem, IonGrid, IonLabel, IonButton, IonCard, IonCardHeader, IonCardContent,
} from '@ionic/react'
import React from 'react'

import PageHeader from '../../PageHeader'

/**
 *  ==============================
 *      App Coming soon message
 *  ==============================
 */
const ConsultantHelp = () => (
  <IonPage>
    <PageHeader title='Help Center' />
    <IonRow style={{ width: '100%' }}>
      <IonRow style={{ width: '100%' }} class='ion-justify-content-center'>
        <IonCol style={{ width: '100%', background: '#CFE5E9' }} size='12'>

          <IonRow class='ion-justify-content-center'>
            <IonLabel style={{
              color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center', paddingTop: '30px',
            }}
            >
              Consultant Help
            </IonLabel>
          </IonRow>

          <IonRow class='ion-justify-content-center' style={{ width: '100%' }}>
            <IonLabel style={{
              width: '100%', fontSize: '16px', textAlign: 'center', padding: '30px',
            }}
            >
              In this section you will find articles relating to the Consultant App
            </IonLabel>
          </IonRow>

          <IonRow justify-content-center class='ion-text-center' style={{ width: '100%' }}>

            <IonItem class='ion-text-center' style={{ '--background': '#CFE5E9', width: '100%', align: 'center' }} lines='none'>
              <IonLabel class='ion-text-center' style={{ width: '100%' }}>
                <input style={{ width: '60%', align: 'center' }} />
                <IonButton fill='clear'>
                  <Icon icon='icon-park-outline:message-search' color='#326771' size='25' />
                </IonButton>
              </IonLabel>

            </IonItem>
          </IonRow>

        </IonCol>
      </IonRow>
    </IonRow>
    <IonGrid style={{
      width: '100%', height: '100%', marginBottom: '10px', boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)',
    }}
    >
      <IonContent style={{ height: '100%', '--background': 'white' }}>
        <IonRow style={{ boxShadow: '-9px -1px 7px -12px rgb(0 0 0 / 75%)' }}>
          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Using the Consultant App
              </IonCardHeader>
              <IonItem class='ion-text-center' lines='none'>
                <IonCardContent>This article gives an overview of the Consultant App and its key features.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>

          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >What are Crowd Insights?
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article explains the Crowd Insights filter and how it affects Alert States.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>
        </IonRow>

        <IonRow style={{ paddingTop: '10px' }}>
          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Adding an Incident
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article outlines how to add an incident pin to the map and submit an incident report.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>

          <IonCol style={{ width: '50%' }} />
        </IonRow>

        <IonRow style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <IonCol style={{ width: '50%' }}>
            <IonCard style={{ height: '100%', '--background': 'white' }}>
              <IonCardHeader
                class='ion-text-center'
                style={{
                  color: '#8E151F', fontSize: '16px', fontWeight: 'bold', textAlign: 'center',
                }}
              >Editing/Deleting an Incident Report
              </IonCardHeader>
              <IonItem lines='none'>
                <IonCardContent>This article explains how to use the sort by and filter buttons to manage tasks.</IonCardContent>
              </IonItem>
            </IonCard>
          </IonCol>

          <IonCol style={{ width: '50%' }} />
        </IonRow>

      </IonContent>

    </IonGrid>
  </IonPage>
)

export default ConsultantHelp
