/* eslint-disable import/no-webpack-loader-syntax */
import {
  IonRow, IonButton,
} from '@ionic/react'
import * as React from 'react'

import axios from 'axios'
import { FormContainer } from '../../maps/forms/MapForm'
import { MapInstructions } from '../../maps/StyledContainers'
import AssetDetails from './AssetDetails'
import { useRiskRegister } from '../../../hooks/risks/useRiskRegister'
import { AssetInputActionKind } from '../../../hooks/risks/helpers/AddAssetReducers'
import { useAddAsset } from '../../../hooks/risks/useAddAsset'
import { DisplayAssetAction } from '../../../hooks/risks/helpers/StateReducers'
import { ObscureBackground } from '../../GlobalContainers'
import ImportAsset from './ImportAsset'

/**
 *  ===========================
 *        Create Asset Form
 *  ===========================
 */
const AddAsset = () : JSX.Element => {
  /* Application hooks */
  const {
    addAssetInput, dispatchAssetInput, mapRef, dispatchDisplayAsset, assetType, setLoading, loading,
  } = useRiskRegister()
  const {
    handleClickRef, forwardForm, canFillForm, handleSubmit, backForm, forgetMarker, setShowFilePopup, showFilePopup,
    setImported, imported,
  } = useAddAsset()

  /* Determine county when dropping marker */
  const handleFindCounty = (e) : void => {
    if (e.features.length === 0) return

    /* Get County details */
    const { properties } = e.features[0]
    dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, county_id: properties.id } })
  }
  const handleFindCountyRef = React.useRef(handleFindCounty)
  handleFindCountyRef.current = handleFindCounty

  /* Determine region when dropping marker */
  const handleFindRegion = (e) : void => {
    if (e.features.length === 0) return
    /* Get region details */
    const { properties } = e.features[0]
    dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, location: { ...addAssetInput.location, state: properties.shapeName } } })
  }
  const handleFindRegionRef = React.useRef(handleFindRegion)
  handleFindRegionRef.current = handleFindRegion

  const closeForm = () => {
    dispatchDisplayAsset(DisplayAssetAction.CLOSE_ALL)
    forgetMarker()
  }

  React.useEffect(() => {
    const onClick = (e) => {
      handleClickRef.current(e)
    }

    const countiesCallback = (e) => {
      handleFindCountyRef.current(e)
    }

    const regionsCallback = (e) => {
      handleFindRegionRef.current(e)
    }

    mapRef.current.resize()

    mapRef.current.on('mouseup', 'counties-background', countiesCallback)
    mapRef.current.on('mouseup', 'regions-background', regionsCallback)
    mapRef.current.on('click', onClick)

    return () => {
      mapRef.current.off('click', onClick)
      mapRef.current.off('mouseup', 'counties-background', countiesCallback)
      mapRef.current.off('mouseup', 'regions-background', regionsCallback)

      dispatchAssetInput({ type: AssetInputActionKind.CLEAR })
    }
  }, [])

  const handleSubmitImages = (files: FileList) => {
    const formData = new FormData()
    Array.from(files).forEach((val) => {
      formData.append('files[]', val)
    })
    setLoading(true)
    axios.post('/api/v1/asset/upload_asset', formData)
      .then(({ data }) => {
        console.log('coords: ', data.coords)
        const coordinates = [1, 2]
        dispatchAssetInput({ type: AssetInputActionKind.EDIT, payload: { ...addAssetInput, coordinates } })
        setImported(data.coords)
        setShowFilePopup(false)
        setLoading(false)
      })
  }

  return (
    <>
      {
        (!addAssetInput.showDetailsForm && assetType === 'pin') && (
          <FormContainer style={{ height: 'auto', backgroundColor: 'white' }}>
            <MapInstructions>
              <h2 style={{ color: '#326771' }}>Add Key Asset</h2>
              <div style={{ marginTop: '20px', padding: '0 10px' }}>
                <p>Drop a pin to mark the new asset’s location on the map. You can move the pin to adjust its position.</p>
                <p>Once your asset is in place, click ‘Next’ to continue.</p>
                <p>If the new asset you are adding is in the same location as an existing asset (for example a vehicle within an office garage),
                  you can click on the original pin and add the new asset to that same location.
                </p>
                <p>
                  <button type='button' onClick={() => {forwardForm(), setShowFilePopup(true)}} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>
                    Import Asset
                  </button>
                </p>
              </div>
              <IonRow className='ion-justify-content-between'>
                <IonButton style={{ '--background': '#8E151F' }} onClick={closeForm}>Cancel</IonButton>
                <IonButton id='next-to-asset-details' style={{ '--background': '#0C9500' }} onClick={forwardForm} disabled={!canFillForm}>Next</IonButton>
              </IonRow>
            </MapInstructions>
          </FormContainer>
        )
      }
      {
        (!addAssetInput.showDetailsForm && assetType === 'line') && (
          <FormContainer style={{ height: 'auto', backgroundColor: 'white' }}>
            <MapInstructions>
              <h2 style={{ color: '#326771' }}>Add Key Asset</h2>
              <div style={{ marginTop: '20px', padding: '0 10px' }}>
                <p>Drop a line to mark the new asset’s location on the map. You can move the pin to adjust its position.</p>
                <p>Once your asset is in place, click ‘Next’ to continue.</p>
                <p>If the new asset you are adding is in the same location as an existing asset (for example a vehicle within an office garage),
                  you can click on the original pin and add the new asset to that same location.
                </p>
              </div>
              <IonRow className='ion-justify-content-between'>
                <IonButton style={{ '--background': '#8E151F' }} onClick={closeForm}>Cancel</IonButton>
                <IonButton id='next-to-asset-details' style={{ '--background': '#0C9500' }} onClick={forwardForm} disabled={!canFillForm}>Next</IonButton>
              </IonRow>
            </MapInstructions>
          </FormContainer>
        )
      }
      {
        (!addAssetInput.showDetailsForm && assetType === 'polygon') && (
          <FormContainer style={{ height: 'auto', backgroundColor: 'white' }}>
            <MapInstructions>
              <h2 style={{ color: '#326771' }}>Add Key Asset</h2>
              <div style={{ marginTop: '20px', padding: '0 10px' }}>
                <p>Drop a polygon to mark the new asset’s location on the map. You can move the pin to adjust its position.</p>
                <p>Once your asset is in place, click ‘Next’ to continue.</p>
                <p>If the new asset you are adding is in the same location as an existing asset (for example a vehicle within an office garage),
                  you can click on the original pin and add the new asset to that same location.
                </p>
              </div>
              <IonRow className='ion-justify-content-between'>
                <IonButton style={{ '--background': '#8E151F' }} onClick={closeForm}>Cancel</IonButton>
                <IonButton id='next-to-asset-details' style={{ '--background': '#0C9500' }} onClick={forwardForm} disabled={!canFillForm}>Next</IonButton>
              </IonRow>
            </MapInstructions>
          </FormContainer>
        )
      }
      {
        (showFilePopup && addAssetInput.showDetailsForm) && (
          <>
            <ObscureBackground />
            <ImportAsset
              onClose={() => setShowFilePopup(false)}
              onSubmit={handleSubmitImages}
            />
          </>
        )
      }
      {
        (addAssetInput.showDetailsForm && !showFilePopup) && (
          <AssetDetails
            backForm={backForm}
            handleSubmit={handleSubmit}
          />
        )
      }
    </>
  )
}

export default AddAsset
