import {
  IonButton,
  IonContent, IonInput, IonList, IonRow,
} from '@ionic/react'
import moment from 'moment'
import React, { FC } from 'react'
import { getWebSocketURL } from '../../../channels/consumer'
import { StakeholderIcon } from '../../../helpers/UserIcon'
import { useActionCable } from '../../../hooks/channels/useActionCable'
import { useChannel } from '../../../hooks/channels/useChannel'
import { useEngagementData } from '../../../hooks/engagement/useEngagementData'
import { useAuth } from '../../../hooks/useAuth'
import { DMMessage } from './MessageCard'
import { StakeholderChat, StakeholderMessage } from '../../types/OptimizedMaps'
import useApi from '../../../hooks/testHooks'
import { useWorkspace } from '../../../hooks/useWorkspace'

interface ConversationProps {
  dmRoom: StakeholderChat,
}

const DMConversation : FC<ConversationProps> = ({ dmRoom }) => {
  const [comment, setComment] = React.useState<string>('')
  const [roomId, setRoomId] = React.useState<number>(null)
  const [messages, setMessages] = React.useState<StakeholderMessage[]>([])

  /* Use this hooks to connect to socket server -> Have a look at chats to see implementation */
  const { actionCable } = useActionCable(getWebSocketURL())
  const { subscribe, unsubscribe, subscribed } = useChannel(actionCable)
  const { pushLatestMessage } = useEngagementData()
  const { user } = useAuth()
  const messagesScroll = React.useRef(null)
  const apiHook = useApi()
  const { workspace } = useWorkspace()

  const handleSendMessage = () => {
    if (comment === '') return
    /* TODO: Send message to backend */
    const newMessage = {
      id: 1,
      message: comment,
      user: { ...user, id: user.user_id },
      created_at: moment().format(),
      sender: 'domain',
    }

    const sendmessage = apiHook.sendDM({ user_id: user.user_id, domain_id: workspace.id, stakeholder: dmRoom.recipient.id, message: comment }).then((result) => {
      console.log(result)
    })

    pushLatestMessage(newMessage, dmRoom)
    setComment('')

    /* If using socket server then there's no need to update this state as the socket server will send an array of messages just like chats */
    setMessages([...messages, newMessage])
  }

  React.useEffect(() => {
    // TODO: Connect to new room using hook
    // Have a look at the way chats connect to their respective room in Conversation.tsx
  }, [subscribed])

  React.useEffect(() => {
    // Every time the room changes then trigger connection to other room's socket
    if (dmRoom.id === roomId) {
      return
    }
    setRoomId(dmRoom.id)

    /* Will trigger the useEfect above, which will take care of connecting to a different socket room */
    unsubscribe()
  }, [dmRoom])

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    messagesScroll.current && messagesScroll.current.scrollToBottom()
  }, [messages])

  React.useEffect(() => {
    // Set the initial value for the messages state
    const messages = apiHook.getDMs({ domain_id: workspace.id, stakeholder: dmRoom.recipient.id }).then((result) => {
      setMessages(result.dms)
    })
  }, [dmRoom.recipient])

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      padding: '20px',
    }}
    >
      <IonRow className='ion-justify-content-between ion-align-items-center' style={{ borderBottom: '0.5px solid #747474', padding: '10px', marginBottom: '10px' }}>
        <IonRow className='ion-align-items-center long-and-truncated'>
          <StakeholderIcon {...dmRoom.recipient} selected />
          <h5 style={{
            color: 'black', fontSize: '1rem', flex: 1, marginLeft: '10px', maxWidth: 'fit-content',
          }}
          >{ dmRoom.recipient.name }
          </h5>
        </IonRow>
      </IonRow>
      <IonContent ref={messagesScroll} style={{ '--background': 'white' }} scrollEvents>
        <IonList style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column' }}>
          {
            messages.map((val) => (
              <DMMessage
                content={val.message}
                user={val.sender}
                created_at={val.created_at}
                sender={val.sender}
              />
            ))
          }
        </IonList>
      </IonContent>
      <IonRow style={{ margin: '10px 0' }}>
        <IonInput
          placeholder='Type message...'
          style={{ flex: 1 }}
          value={comment}
          className='chats-input-field'
          onIonChange={(e) => setComment(e.detail.value)}
        />
        <IonButton onClick={handleSendMessage} style={{ '--background': '#0C9500', color: 'white', margin: '0 10px' }}>Send</IonButton>
      </IonRow>
    </div>
  )
}

export default DMConversation
