import { IonRow } from '@ionic/react'
import React, { FC, useEffect, useState } from 'react'
import { MobilePopupContainer } from './StyledContainers'

const AndroidPopup : FC = () => {
  const [showPopup, setShowPopup] = useState<boolean>(false)

  useEffect(() => {
    const agent = navigator.userAgent.toLowerCase()
    if (agent.indexOf('android') > -1) {
      setShowPopup(true)
    }
  }, [])

  return showPopup ? (
    <MobilePopupContainer>
      <h3>Sherpa is better on the app.</h3>
      <p>Switch to the Sherpa app to get the best possible experience. </p>
      <IonRow className='ion-justify-content-between'>
        <button onClick={() => setShowPopup(false)} type='button' className='dismiss-button'>Dismiss</button>
        <a href='intent://sherpa#Intent;scheme=sherpaapp;package=com.sherpaandroid;end' className='open-app-button'>Open the app</a>
      </IonRow>
    </MobilePopupContainer>
  ) : null
}

export default AndroidPopup
