import React from 'react'
import {
  IonRow, IonButton, IonCol, IonGrid,
} from '@ionic/react'
import { ColumnContainer } from '../riskregister/StyledContainers'
import { SettingField } from '../settings/StyledContainers'
import { getCurrentLevel } from '../maps/apps/AppMap'
import { SherpaButton } from '../GlobalContainers'

const getColor = (index) => ((index % 2 === 0) ? 'rgba(65, 151, 169, 0.25)' : 'white')
/**
 *  ===============================
 *      SINGLE CATEGORY'S DATA
 *  ===============================
 */
const SuggestedCategory = ({
  category, keyphrases, next, prev, first, last, reports, suggestedAS, county, onClose,
}) => (
  <>
    <div>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <h4 className='red-font-color' style={{ margin: '0' }}>{ county.shapeName } - Overview</h4>
        <SherpaButton style={{ fontSize: '1rem' }} onClick={onClose}>Close X</SherpaButton>
      </IonRow>
      <SettingField style={{ width: 'auto', margin: 0 }}>
        <p style={{ margin: '20px 0', fontSize: '.9rem' }}>The suggested level for this county is
          <span
            style={{
              backgroundColor: getCurrentLevel(suggestedAS).background, color: getCurrentLevel(suggestedAS).color, marginLeft: '10px', padding: '2px',
            }}
          >{`Alert State ${suggestedAS} - ${getCurrentLevel(suggestedAS).name}`}
          </span>
        </p>
        <h5 style={{ margin: '20px 0' }} className='blue'>{ category.name }</h5>
        <p style={{ fontSize: '.9rem' }}>The following <span style={{ color: 'black' }}>{category.name}</span> factors have been identified by analysts in this county.</p>
      </SettingField>
      <IonGrid style={{ border: '1px solid #747474', padding: '0px' }}>
        <ColumnContainer style={{ maxHeight: '225px', height: 'auto', border: 'none' }}>
          <IonRow className='sticky-table-title' style={{ borderBottom: '1px solid #747474' }}>
            <IonCol style={{ borderRight: '1px solid #747474' }} size='9'>Factor</IonCol>
            <IonCol size='3'>% Reporting</IonCol>
          </IonRow>
          {
            keyphrases.filter(({ phrasegroups_id }) => phrasegroups_id === category.id).map((phrase, index) => (
              <IonRow key={phrase.id} className='ion-align-items-center' style={{ backgroundColor: getColor(index), minHeight: '10%' }}>
                <IonCol size={9} style={{ borderRight: '1px solid #747474' }}> { phrase.tag } </IonCol>
                <IonCol size={3} style={{ textAlign: 'center' }}>{ Math.ceil((phrase.count * 100) / reports) }%</IonCol>
              </IonRow>
            ))
          }
        </ColumnContainer>
      </IonGrid>
    </div>
    <IonRow className='ion-justify-content-between'>
      <IonButton style={{ '--background': '#4197A9' }} disabled={first} onClick={prev}>Prev</IonButton>
      <IonButton style={{ '--background': '#326771' }} disabled={last} onClick={next}>Next</IonButton>
    </IonRow>
  </>
)

export default SuggestedCategory
